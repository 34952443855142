import React from 'react';
import { NewsForm } from './NewsForm';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useHistory } from 'react-router-dom';

/**
 * @description Редактирование новостей
 * @param props
 * @returns {React.ReactNode}
 */
export const NewsItem = props => {
  const [strings] = useLanguageContext();
  const {
    match: {
      params: { id }
    }
  } = props;
  const { goBack } = useHistory();

  return (
    <Container>
      <StPageHeader title={id ? strings.EDITING_NEWS : strings.CREATING_NEWS} onBack={goBack} />
      {id ? <NewsForm id={id} /> : <NewsForm />}
    </Container>
  );
};

export default { NewsItem };
