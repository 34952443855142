import React, { useCallback, useEffect, useState } from 'react';
import CacheService from 'helpers/services/CacheService';
import { Auth } from 'api';
import { defineRoles } from 'helpers/defineRoles';
import { getUserFullName } from 'helpers/getUserFullName';
import * as Sentry from '@sentry/browser';
import { User } from 'types/user';
import { useStorageService } from 'contexts/StorageContext';
import { httpService } from 'api/core';
import { isLocalLaunch } from 'helpers';
import { useLocation, useHistory } from 'react-router-dom';
import { AVAILABLE_LANGS, useLanguageContext } from './LanguageContext';
import { useLangRepository } from 'repos/LangRepository';
import { AnimateChameleon } from 'Global/components/Chameleon';
import styled from 'styled-components';
import { useUserAccessRepository } from 'repos/UserAccessRepostirory';
import { useUserRepository } from 'repos/UserRepository';
import { defLang } from 'Global/constants';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import { EN_KEY, LV_KEY } from '../Admin/Translations';

const UserContext = React.createContext(null);
const cacheService = new CacheService();

export const defaultUser = {
  branch: { id: 1, name: '' },
  name: '',
  surname: '',
  isLogin: false,
  locale: 'en',
  roles: [],
  unreadThreads: 0,
  hasRoles: {
    admin: false,
    topManager: false,
    teacherAccessInfo: false,
    user: false,
    teacher: false,
    contractInfoEdit: false,
    branchDirector: false,
    student: false,
    branchOperationDepartament: false,
    academicExpert: false,
    parent: false
  }
};

const StyledAppLoader = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #e5556f;
    }
    70% {
      box-shadow: 0 0 0 25px rgba(204, 169, 44, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
  }
  svg {
    width: 50px;
    height: 50px;
    box-shadow: 0 0 0 #e5556f;
    border-radius: 50%;
  }
`;

const AUTH_CODE_PARAM = 'authCode';

export const UserContextProvider = props => {
  const location = useLocation();
  const history = useHistory();
  const [user, setUser] = useState<User | any>(defaultUser);
  const [hasLoadUser, setHasLoadUser] = useState<boolean>(false);
  const langRepo = useLangRepository();
  const globalRequestsRepository = useGlobalRequestsRepository();
  const userRepository = useUserRepository();
  const storageService = useStorageService();
  const [, lang, setNewLanguage, setLangStrings, setStrings, , LOCALE_STORAGE_LANG_KEY] = useLanguageContext();
  const userAccessRepo = useUserAccessRepository();
  const [userAccess, setUserAccess] = useState([]);

  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(location.search);
      const hasIframe = urlParams && urlParams.get(AUTH_CODE_PARAM);
      if (hasIframe) {
        const {
          data: { token, refreshToken }
        } = await globalRequestsRepository.getJwtTokenByNN(urlParams.get(AUTH_CODE_PARAM));
        storageService.setAccessToken(token);
        storageService.setRefreshToken(refreshToken);
        history.push('/');
      }
    })();
  }, [location]);

  const prepareLangStrings = useCallback(langData => {
    return Object.keys(langData).reduce((result, langKey) => {
      const translations = langData[langKey];
      translations.forEach(({ key, translation }) => {
        result[langKey] = { ...result[langKey], [key]: translation };
      });
      return result;
    }, {});
  }, []);

  const logout = useCallback(async () => {
    let usersLanguages = JSON.parse(localStorage.getItem('usersLanguages')) || {};
    usersLanguages[user.id] = lang;
    localStorage.setItem('usersLanguages', JSON.stringify(usersLanguages));
    localStorage.removeItem('language');
    await userRepository.userLogout();
    setUser(defaultUser);
    history.push('/auth');
  }, [user.id]);

  useEffect(() => {
    (async () => {
      if (storageService.getAccessToken()) {
        try {
          setHasLoadUser(true);
          /* User */
          const { data } = await Auth.getUserInfo();
          const hasRoles = defineRoles(data.roles);
          const usr = { ...user, ...data, hasRoles, isLogin: true };
          const userBranch = usr?.branch;

          cacheService.setCache(usr, 'user');
          setUser(usr);
          /* User locale */
          const { data: langData } = await langRepo.getTranslations();
          const stringsByLangs = prepareLangStrings(langData);
          setLangStrings(stringsByLangs);

          // Если пользователю доступна вкладка Admin Access, то получим список доступов
          if (usr?.access?.adminAccess) {
            try {
              const { data: userAccessData } = await userAccessRepo.getUserAccess(usr.id);
              setUserAccess(userAccessData);
            } catch {
              setUserAccess([]);
            }
          }

          /* У админа всегда английский язык */
          if (usr?.hasRoles?.admin) {
            setNewLanguage(AVAILABLE_LANGS[EN_KEY], userBranch);
            setStrings(stringsByLangs[EN_KEY]);
            /* У остальных пользователей может быть сохраненный язык в localStorage */
          } else {
            if (localStorage.getItem(LOCALE_STORAGE_LANG_KEY)) {
              const isoLocale = AVAILABLE_LANGS[localStorage.getItem(LOCALE_STORAGE_LANG_KEY)];
              setNewLanguage(localStorage.getItem(LOCALE_STORAGE_LANG_KEY), userBranch);
              if (stringsByLangs[isoLocale]) {
                setStrings(stringsByLangs[isoLocale]);
              } else {
                setStrings(stringsByLangs[defLang]);
              }
              /* Иначе английский */
            } else {
              setNewLanguage(AVAILABLE_LANGS[EN_KEY], userBranch);
              setStrings(stringsByLangs[defLang]);
            }
          }
        } catch {
        } finally {
          setHasLoadUser(false);
        }
      }
    })();
  }, [storageService.getAccessToken()]);

  useEffect(() => {
    (async () => {
      const { host } = window.location;
      if (host === 'amigo.londongates.org') {
        const {
          data: {
            response: { access }
          }
        } = await httpService.post('https://ami.londongates.org/api/amigo/check', {
          method: 'lgegCrm.check_amigo_access'
        });

        if (access === 0) {
          window.location.href = 'https://ami.londongates.org/admin_access/amigo/coming';
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (!isLocalLaunch && user) {
      Sentry.configureScope(scope => {
        scope.setTag('roles', user?.roles?.join(', '));
        scope.setTag('fullname', getUserFullName(user));
        scope.setUser({ email: user?.email ?? 'No email' });
      });
    }
  }, [isLocalLaunch, user]);

  const provider = [user, setUser, hasLoadUser, userAccess, logout];

  if (hasLoadUser) {
    return (
      <StyledAppLoader>
        <AnimateChameleon />
      </StyledAppLoader>
    );
  }

  return <UserContext.Provider value={provider}>{props.children}</UserContext.Provider>;
};

export const useUserContext = () => {
  const service = React.useContext(UserContext);

  if (!service) {
    throw new Error('User context is not available');
  }

  return service;
};
