export const BASE_TEACHERS = '/teachers';
export const BASE_TEACHER_ADD = '/teachers/add';
export const BASE_TEACHER = '/teachers/:id';
export const BASE_TEACHER_EDIT = '/teachers/:id/edit';
export const ADD_INDIVIDUAL_WAGE = '/teachers/new-individual';

export default {
  BASE_TEACHER_ADD,
  BASE_TEACHERS,
  BASE_TEACHER,
  BASE_TEACHER_EDIT,
  ADD_INDIVIDUAL_WAGE
};
