import React from 'react';
import { useLanguageContext } from 'contexts/LanguageContext';
import { checkYoutubeUrl, getYoutubeVideoUrl, splitYoutubeUrl } from 'helpers';

interface VideoAssessmentProps {
  video: string;
}

/**
 * @description VideoAssessment component
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
const VideoAssessment = (props: VideoAssessmentProps) => {
  const [strings] = useLanguageContext();
  const { video } = props;

  const youtubeUrls = splitYoutubeUrl(video);

  return (
    <>
      {checkYoutubeUrl(video) ? (
        <>
          {youtubeUrls?.map((url: string) => (
            <div key={url} style={{ marginBottom: 10 }}>
              <iframe
                style={{ maxWidth: '100%' }}
                width="560"
                height="315"
                src={getYoutubeVideoUrl(url)}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="show"
              />
            </div>
          ))}
        </>
      ) : (
        <div style={{ marginBottom: 10 }}>
          <iframe
            style={{ maxWidth: '100%' }}
            width="560"
            height="315"
            src={video}
            frameBorder="0"
            allowFullScreen
            title="file"
          />
          <a href={video}>{video}</a>
        </div>
      )}
    </>
  );
};

export default VideoAssessment;
