import React, { useMemo, useContext } from 'react';
import { httpService } from 'api/core';
import {
  FamiliesFilterParams,
  FamilyProfilePostParams,
  FamilyProfilePutParams,
  IParentsFilter,
  ParentParams
} from 'types/Family';
import { downloadSomeFile } from '../helpers';

const FamilyRepositoryContext = React.createContext<IFamilyRepository>(null);

interface IFamilyRepository {
  getFamilyList: (params: FamiliesFilterParams) => Promise<any>;
  getFamilyById: (id: number | string) => Promise<any>;
  getParentById: (id: number | string) => Promise<any>;
  getParentsByFilter: (params: IParentsFilter) => Promise<any>;
  createParent: (params: ParentParams) => Promise<any>;
  editParent: (parentId, params: ParentParams) => Promise<any>;
  signСontract: () => Promise<any>;
  getActiveFamilyContract: () => Promise<any>;
  getTutors: () => Promise<any>;
  editFamily: (id: number | string, params: FamilyProfilePutParams) => Promise<any>;
  createFamily: (params: FamilyProfilePostParams) => Promise<any>;
  downloadActiveFamilyContract: (id: number | string, name?: string) => Promise<any>;
  exportFamiliesToExcel: (params: FamiliesFilterParams) => Promise<any>;
  getFamilyFinance: () => Promise<any>;
}

class FamilyRepository implements IFamilyRepository {
  getFamilyList(params: FamiliesFilterParams) {
    return httpService.get(`/api/v1/families`, params, null, true);
  }

  getFamilyById(id: number | string) {
    return httpService.get(`/api/v1/families/${id}`, null, null, true);
  }

  getParentById(id: number | string) {
    return httpService.get(`/api/v1/parent/${id}`, null, null, true);
  }

  getParentsByFilter(params: IParentsFilter) {
    return httpService.get('/api/v1/parents', params, null, true);
  }

  createParent(params: ParentParams) {
    return httpService.post(`/api/v1/user/parent`, params, null, null, true);
  }

  editParent(parentId, params: ParentParams) {
    return httpService.put(`/api/v1/user/parent/${parentId}`, params, null, null, true);
  }

  signСontract() {
    return httpService.post(`/api/v1/parent/accept-active-contract`, null, null, null, true);
  }

  getActiveFamilyContract() {
    return httpService.get(`/api/v1/families/contracts/my`, null, null, true);
  }

  getTutors() {
    return httpService.get(`/api/v1/tutors/my`, null, null, true);
  }
  editFamily(id: number | string, params: FamilyProfilePutParams) {
    return httpService.put(`/api/v1/families/${id}`, params, null, null, true);
  }

  createFamily(params: FamilyProfilePostParams) {
    return httpService.post(`/api/v1/families`, params, null, null, true);
  }

  async downloadActiveFamilyContract(id: number | string, name?: string) {
    await downloadSomeFile(`/api/v1/families/contract/${id}/download`, name, null, null, true);
  }

  exportFamiliesToExcel(params: FamiliesFilterParams) {
    return httpService.get(`/api/v1/families/export/excel`, params, null, true);
  }
  getFamilyFinance() {
    return httpService.get(`/api/v1/families/finance/my`, null, null, true);
  }
}

export const useFamilyRepository = () => {
  const service = useContext(FamilyRepositoryContext);

  if (!service) {
    throw new Error('Family repository is unavailable');
  }

  return service;
};

export const FamilyRepositoryProvider = props => {
  const service = useMemo(() => new FamilyRepository(), []);

  return <FamilyRepositoryContext.Provider value={service}>{props.children}</FamilyRepositoryContext.Provider>;
};
