import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Button, Col, Modal, Row, Select, Table, Spin, message, Input, Form, Flex } from 'antd';
import { LessonInfo, StSlotCollapse } from 'Global/modules/Schedule/styles';
import { dateFormatForBackend } from 'helpers/dates';
import { columns } from 'Global/modules/Schedule/columns';
import { ModalMode, Subject } from 'types';
import { DeleteBulkParams, ScheduleLesson, SearchSlotsParams } from 'types/Schedule';
import { AxiosResponse } from 'axios';
import { Schedule } from 'api/Schedule';
import { useUserContext } from 'contexts/UserContext';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { getDateForSchedule } from 'helpers/getDateForSchedule';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { useScheduleRepository } from 'repos/ScheduleRepostirory';
import TimeRoomSlotFields from './SlotFields/TimeRoomSlotFields';
import { StageSelect, TeacherSelect, CourseGroupSelect } from 'Global/components/FormComponents';
import { ProlongUntilSlotFields } from './SlotFields/ProlongUntilSlotFields';

const { Option } = Select;

interface LessonModalProps {
  mode: ModalMode;
  hideModal: () => void;
  loading?: boolean;
  values: ScheduleLesson;
  getLessonById: (lessonId: number) => Promise<any>;
  getEvents: (params: Partial<SearchSlotsParams>) => Promise<any>;
}

/**
 * @description модальное окно урока
 * @param {object} props объект props
 * @return {React.ReactNode}
 */
export const LessonModal = React.memo(({ hideModal, values, loading, mode, getEvents }: LessonModalProps) => {
  const [user] = useUserContext();
  const [strings] = useLanguageContext();
  const [form] = Form.useForm();
  const scheduleRepository = useScheduleRepository();
  const hasTeacher = user?.hasRoles?.teacher;
  const [{ confirm }, contextHolder] = Modal.useModal();
  const { locationSearchParams } = useLocationSearchParams();
  const { branch } = locationSearchParams;

  const { subjects } = useGlobalCollectionsContext();
  const subjectOptions = useMemo(
    () =>
      subjects.map((subject: Subject) => (
        <Option value={subject.id} key={subject.id}>
          {subject.name}
        </Option>
      )),
    [subjects]
  );

  const {
    id,
    slot: { id: slotId, startAt, endAt, room },
    editable,
    group,
    subject,
    stage,
    classRoster,
    teacher,
    assistent,
    assistant1,
    assistant2
  } = values;

  const [hasLoadingSubmit, setHasLoadingSubmit] = useState<boolean>(false);

  const handleSubmit = async (values): Promise<any> => {
    // Если фин. период окончен, выведем сообщение, прервем функцию
    if (!editable) {
      message.error(strings.YOU_CAN_NО_LONGER_APPLY_CHANGES_TO_THIS_DATE_THE_FINANCIAL_PERIOD_IS_CLOSED, 3);
      return;
    }

    const { day, startTime, endTime } = values;
    const newDateStart = getDateForSchedule(day, startTime);
    const newDateEnd = getDateForSchedule(day, endTime);
    delete values['day'];
    delete values['startTime'];
    delete values['endTime'];
    const params = { ...values, dateStart: newDateStart, dateEnd: newDateEnd };

    // Если присутствует - отформатируем Prolong until
    if (values.dateSequence) {
      params['dateSequence'] = dayjs(values.dateSequence).format(dateFormatForBackend);
    }

    setHasLoadingSubmit(true);
    try {
      const { status } = await scheduleRepository.editSlotByLessonId(slotId, params);
      if (status === 200) {
        await getEvents(locationSearchParams);
        message.success(strings.LESSON_EDITED, 2);
        hideModal();
        form.resetFields();
      }
    } catch {
      message.error(strings.LESSON_EDITING_ERROR, 2);
    } finally {
      setHasLoadingSubmit(false);
    }
  };

  /**
   * @description Запрос на удаление урока
   * @param {number} slotId
   * @return {Promise<any>}
   */
  const deleteLesson = async (slotId: number): Promise<any> => {
    // Если фин. период окончен, выведем сообщение, прервем функцию
    if (!editable) {
      message.error(strings.YOU_CAN_NО_LONGER_APPLY_CHANGES_TO_THIS_DATE_THE_FINANCIAL_PERIOD_IS_CLOSED, 3);
      return;
    }

    const hasApplyToAll = form.getFieldValue('applyToAll');
    const success = (res: AxiosResponse): void => {
      if (res.status === 200) {
        message.success(`${strings.SUCCESSFULLY_DELETED} :)`);
        hideModal();
        getEvents(locationSearchParams);
      }
    };
    if (hasApplyToAll) {
      const params: DeleteBulkParams = { slots: [slotId], applyToAll: 1 };
      try {
        const res = await Schedule.deleteBulkSlots(params);
        success(res);
      } catch {}
    } else {
      try {
        const res = await Schedule.deleteLessonById(slotId);
        success(res);
      } catch {}
    }
  };

  /**
   * @description Удаление урока (прокидывается айди слота, а не урока)
   * @return {void}
   */
  const handleDelete = useCallback(() => {
    confirm({
      title: strings.DO_YOU_WANT_TO_DELETE_THIS_LESSON,
      content: <b style={{ color: 'rgb(246 70 70)' }}>{dayjs(values.slot.startAt).format('dddd, MMMM D, YYYY')}</b>,
      onOk: async (): Promise<any> => {
        await deleteLesson(slotId);
      },
      onCancel() {}
    });
  }, [slotId]);

  const handleHideModal = useCallback(() => {
    hideModal();
    form.resetFields();
  }, [hideModal, form]);

  const defaultValues = useMemo(
    () =>
      mode === ModalMode.Edit && values
        ? {
            branch: branch || user?.branch?.id,
            stage: stage?.id,
            subject: subject?.id,
            group: group?.id,
            room: room?.id,
            teacher: teacher?.id,
            assistantOne: assistent?.id,
            assistantTwo: assistant1?.id,
            assistantThree: assistant2?.id,
            startAt,
            endAt
          }
        : {},
    [mode, values]
  );

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [defaultValues]);

  return (
    <Modal
      title={
        <a href={`/le/${id}`} target="_blank" rel="noopener noreferrer">
          {group?.name} - {subject?.name}
        </a>
      }
      style={{ top: 50, overflowY: 'auto' }}
      footer={null}
      open={mode !== ModalMode.Hidden}
      onCancel={handleHideModal}
      width={950}
      destroyOnClose={true}
    >
      <LessonInfo>
        <Spin tip={`${strings.LOADING}...`} spinning={loading}>
          {contextHolder}
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <TimeRoomSlotFields
              form={form}
              dateStart={startAt}
              dateEnd={endAt}
              room={room}
              branch={branch || user?.branch?.id}
            />
            <Row gutter={[10, 10]}>
              <Col lg={6} xs={24}>
                <Form.Item name="branch" hidden={true}>
                  <Input />
                </Form.Item>
                <Form.Item name="stage" label={strings.STAGE} rules={[{ required: true }]}>
                  <StageSelect branch={defaultValues?.branch} />
                </Form.Item>
              </Col>
              <Col lg={8} xs={24}>
                <Form.Item name="subject" label={strings.SUBJECT}>
                  <Select placeholder={strings.SUBJECT} disabled>
                    {subjectOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={10} xs={24}>
                <Form.Item name="group" label={strings.GROUP}>
                  <CourseGroupSelect branch={defaultValues?.branch} disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 10]} align="middle">
              {!hasTeacher && (
                <Col lg={6} xs={24}>
                  <Form.Item name="teacher" label={strings.TEACHER}>
                    <TeacherSelect
                      initOptions={teacher && [teacher]}
                      filterValues={{
                        dateStart: startAt,
                        dateEnd: endAt
                      }}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col lg={6} xs={24}>
                <Form.Item name="assistantOne" label={strings.ASSISTANT_ONE}>
                  <TeacherSelect
                    initOptions={assistent && [values.assistent]}
                    disabled={hasTeacher}
                    filterValues={{
                      dateStart: startAt,
                      dateEnd: endAt
                    }}
                  />
                </Form.Item>
              </Col>
              <Col lg={6} xs={24}>
                <Form.Item name="assistantTwo" label={strings.ASSISTANT_TWO}>
                  <TeacherSelect
                    initOptions={assistant1 && [values.assistant1]}
                    disabled={hasTeacher}
                    filterValues={{
                      dateStart: startAt,
                      dateEnd: endAt
                    }}
                  />
                </Form.Item>
              </Col>
              <Col lg={6} xs={24}>
                <Form.Item name="assistantThree" label={strings.ASSISTANT_THREE}>
                  <TeacherSelect
                    initOptions={assistant2 && [values.assistant2]}
                    disabled={hasTeacher}
                    filterValues={{
                      dateStart: startAt,
                      dateEnd: endAt
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <StSlotCollapse
              ghost
              size="small"
              defaultActiveKey={['1', '2']}
              items={[
                {
                  key: '1',
                  label: <strong>{strings.PROLONG_UNTIL}</strong>,
                  children: <ProlongUntilSlotFields slotId={slotId} hasApplyToAll={true} />
                },
                {
                  key: '2',
                  label: <strong>{strings.CLASS_ROSTER}</strong>,
                  children: (
                    <Table
                      dataSource={classRoster}
                      columns={columns}
                      size="small"
                      pagination={false}
                      bordered={false}
                    />
                  )
                }
              ]}
            />
            <Flex justify="end" gap={5}>
              <Button key="back" onClick={hideModal}>
                {strings.CANCEL}
              </Button>
              {!hasTeacher && (
                <Button key="remove" danger onClick={handleDelete}>
                  {strings.REMOVE}
                </Button>
              )}
              <a href={`/le/${id}`} target="_blank" rel="noopener noreferrer">
                <Button key="register" color="primary" variant="filled">
                  {strings.REGISTER}
                </Button>
              </a>
              {!hasTeacher && (
                <Button key="save" htmlType="submit" type="primary" loading={hasLoadingSubmit}>
                  {strings.SAVE}
                </Button>
              )}
            </Flex>
          </Form>
        </Spin>
      </LessonInfo>
    </Modal>
  );
});

export default { LessonModal };
