import React, { useState, useEffect } from 'react';
import { CheckCircleTwoTone, EditOutlined, MinusCircleTwoTone } from '@ant-design/icons';
import { Table, Button, Tag } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useHistory } from 'react-router-dom';
import { SemesterFormModal } from './SemesterFormModal';
import { IGeneratorReport, ReportBranches, ReportTrimester } from 'types/Reports';
import dayjs from 'dayjs';
import { dateFormat } from 'helpers';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';
import { BtnGenerateForExtraGroups } from './BtnGenerateForExtraGroups';
import { BtnGenerateForBranches } from './BtnGenerateForBranches';
import { BtnGeneratePDF } from './BtnGeneratePDF';

export const ProgressReportGenerator = () => {
  const [strings] = useLanguageContext();
  const reportsRepository = useProgressReportsRepository();
  const { goBack } = useHistory();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [semesterList, setSemesterList] = useState<IGeneratorReport[]>([]);
  const [selectedSemester, setSelectedSemester] = useState<number>(null);
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);

  const fetchProgressReportInfoForGenerator = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await reportsRepository.getProgressReportsGenerator();
      setSemesterList(data);
    } finally {
      setHasLoading(false);
    }
  };

  const columns = [
    {
      title: '',
      dataIndex: 'trimester',
      render: trimester => (
        <Button
          icon={<EditOutlined />}
          onClick={() => {
            setSelectedSemester(trimester?.id);
            setHasShowModal(true);
          }}
        />
      )
    },
    {
      title: strings.NAME,
      dataIndex: 'trimester',
      key: 'name',
      render: trimester => trimester?.name
    },
    {
      title: strings.PERIOD,
      key: 'combinedDates',
      dataIndex: 'trimester',
      render: trimester => {
        const { startAt, endAt } = trimester;
        const startDateVal = startAt && dayjs(startAt).format(dateFormat);
        const endDateVal = endAt && dayjs(endAt).format(dateFormat);
        return `${startDateVal} - ${endDateVal}`;
      }
    },
    {
      title: strings.ACTIVE,
      dataIndex: 'trimester',
      key: 'active',
      className: 'clmn-center',
      render: (trimester: ReportTrimester) =>
        trimester?.active ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ) : (
          <MinusCircleTwoTone twoToneColor="#f5222d" />
        )
    },
    {
      title: strings.GENERATED_BRANCH,
      key: 'combinedReportBranches',
      width: 320,
      render: record => {
        const { reportBranches = [], reportNewBranches = [] } = record;
        return (
          <>
            {reportBranches.map(({ branch, finishDate }: ReportBranches) => (
              <span key={branch?.id}>
                {branch?.name}{' '}
                {finishDate && <span style={{ fontSize: 'smaller' }}>({dayjs(finishDate).format(dateFormat)})</span>}
                <br />
              </span>
            ))}
            {reportNewBranches.map(({ branch, pdfCreatedAt }) => (
              <span key={branch?.id}>
                {branch?.name}{' '}
                {pdfCreatedAt && (
                  <span style={{ fontSize: 'smaller' }}>({dayjs(pdfCreatedAt).format(dateFormat)})</span>
                )}{' '}
                <Tag>new</Tag>
                <br />
              </span>
            ))}
          </>
        );
      }
    },

    {
      title: 'Generate',
      key: 'actions',
      render: (record: IGeneratorReport) => {
        const {
          reportBranches,
          reportNewBranches,
          trimester: { id, active }
        } = record;
        return (
          <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
            <BtnGenerateForExtraGroups id={id} reportBranches={reportBranches} />
            <BtnGenerateForBranches id={id} handler={fetchProgressReportInfoForGenerator} />
            {active && (
              <BtnGeneratePDF handler={fetchProgressReportInfoForGenerator} reportNewBranches={reportNewBranches} />
            )}
          </div>
        );
      }
    }
  ];

  useEffect(() => {
    fetchProgressReportInfoForGenerator();
  }, []);

  return (
    <Container>
      <SemesterFormModal
        id={selectedSemester}
        hasShowModal={hasShowModal}
        setHasShowModal={setHasShowModal}
        handler={fetchProgressReportInfoForGenerator}
      />
      <StPageHeader
        onBack={goBack}
        title={strings.PROGRESS_REPORT_GENERATOR}
        extra={[
          <Button
            onClick={() => {
              setSelectedSemester(null);
              setHasShowModal(true);
            }}
          >
            {strings.ADD_NEW_SEMESTER}
          </Button>
        ]}
      />
      <Table loading={hasLoading} dataSource={semesterList} rowKey="id" columns={columns} pagination={false} />
    </Container>
  );
};

export default { ProgressReportGenerator };
