import React from 'react';
import { Tooltip } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';

const ICON_SIZE = 32;

interface IIcon {
  lessonId: number;
  handleNavigate: (lessonId: number) => void;
}
export const PreviousIcon = ({ lessonId, handleNavigate }: IIcon) => {
  const [strings] = useLanguageContext();

  return (
    <Tooltip title={strings.PREVIOUS_LESSON}>
      <svg
        style={{ position: 'absolute', top: '45%', left: '-12%', cursor: 'pointer' }}
        onClick={() => handleNavigate(lessonId)}
        width={ICON_SIZE}
        height={ICON_SIZE}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="11.5" fill="white" stroke="#D9D9D9" />
        <path
          d="M8.60223 12.3372L14.6397 17.0528C14.6555 17.0652 14.6745 17.073 14.6944 17.0751C14.7144 17.0772 14.7346 17.0737 14.7526 17.0649C14.7706 17.056 14.7858 17.0423 14.7964 17.0253C14.807 17.0082 14.8126 16.9885 14.8125 16.9684V15.9332C14.8125 15.8675 14.7817 15.8046 14.7308 15.7644L9.90937 11.9997L14.7308 8.23495C14.783 8.19478 14.8125 8.13183 14.8125 8.0662V7.03094C14.8125 6.9412 14.7094 6.89165 14.6397 6.94656L8.60223 11.6622C8.55092 11.7022 8.5094 11.7534 8.48085 11.8119C8.4523 11.8704 8.43746 11.9346 8.43746 11.9997C8.43746 12.0648 8.4523 12.129 8.48085 12.1875C8.5094 12.246 8.55092 12.2972 8.60223 12.3372Z"
          fill="black"
          fillOpacity="0.65"
        />
      </svg>
    </Tooltip>
  );
};

export const NextIcon = ({ lessonId, handleNavigate }: IIcon) => {
  const [strings] = useLanguageContext();

  return (
    <Tooltip title={strings.NEXT_LESSON}>
      <svg
        style={{ position: 'absolute', top: '45%', right: '-12%', cursor: 'pointer' }}
        width={ICON_SIZE}
        height={ICON_SIZE}
        onClick={() => handleNavigate(lessonId)}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="11.5" transform="rotate(-180 12 12)" fill="white" stroke="#D9D9D9" />
        <path
          d="M15.3978 11.6628L9.36027 6.94719C9.34449 6.93477 9.32553 6.92705 9.30557 6.92492C9.2856 6.92279 9.26544 6.92633 9.2474 6.93514C9.22936 6.94395 9.21417 6.95768 9.20357 6.97473C9.19298 6.99179 9.18741 7.01149 9.1875 7.03156V8.06683C9.1875 8.13246 9.2183 8.1954 9.2692 8.23558L14.0906 12.0003L9.2692 15.765C9.21697 15.8052 9.1875 15.8682 9.1875 15.9338V16.9691C9.1875 17.0588 9.29063 17.1083 9.36027 17.0534L15.3978 12.3378C15.4491 12.2978 15.4906 12.2466 15.5191 12.1881C15.5477 12.1296 15.5625 12.0654 15.5625 12.0003C15.5625 11.9352 15.5477 11.871 15.5191 11.8125C15.4906 11.754 15.4491 11.7028 15.3978 11.6628Z"
          fill="black"
          fillOpacity="0.65"
        />
      </svg>
    </Tooltip>
  );
};
