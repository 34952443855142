import React from 'react';
import { Link } from 'react-router-dom';
import { BASE_FAMILIES } from 'Global/routes';
import { IParent, IStudent, Status, StudyProgram } from 'types';
import AvatarWithUserName from 'Global/components/AvatarWithUserName';
import { User } from 'types/user';

export const getColumns = (strings, handleUserRefetch, hasSearchResult) => [
  {
    title: strings.NAME,
    dataIndex: 'user',
    key: 'name',
    width: 200,
    render: (user: User, student: IStudent) => {
      const { familyId, id, mainPhoto } = student;

      return (
        <AvatarWithUserName
          name={user?.name}
          surname={user?.surname}
          link={`${BASE_FAMILIES}/${familyId}/student/${id}`}
          photo={mainPhoto}
        />
      );
    }
  },
  hasSearchResult && {
    title: strings.FAMILY,
    dataIndex: 'familyId',
    key: 'familyId',
    render: (familyId: number) => <Link to={`${BASE_FAMILIES}/${familyId}`}>{familyId}</Link>
  },
  {
    title: strings.STATUS,
    dataIndex: 'status',
    key: 'status',
    width: 120,
    render: (status: Status) => <p>{status?.name}</p>
  },
  {
    title: strings.STAGE,
    dataIndex: 'studyProgram',
    key: 'studyProgram',
    width: 140,
    render: (studyProgram: StudyProgram) => <p>{studyProgram?.name}</p>
  },
  !hasSearchResult && {
    title: strings.SEX,
    dataIndex: 'user',
    key: 'gender',
    render: user => (user?.gender === 0 ? 'Male' : 'Female')
  },
  !hasSearchResult && {
    title: strings.AGE,
    dataIndex: 'user',
    key: 'age',
    render: user => user?.age
  },
  {
    title: strings.TUTOR,
    dataIndex: 'tutor',
    key: 'tutor',
    render: tutor => (
      <p>
        {tutor?.user?.name} {tutor?.user?.surname}
      </p>
    )
  },
  hasSearchResult && {
    title: strings.CONTACTS,
    dataIndex: 'user',
    key: 'contacts',
    render: user => {
      const { email, phone } = user;
      return (
        <>
          {email && <p>{email}</p>}
          {phone && <p>{phone}</p>}
        </>
      );
    }
  },
  hasSearchResult && {
    title: strings.PARENTS,
    dataIndex: 'parents',
    key: 'parents',
    render: (parents: IParent[], student: IStudent) => {
      const { familyId } = student;
      // TODO На удивление родителей может и не быть. Возможно баг на бэке
      if (parents) {
        return parents.map((parent: IParent) => (
          <span key={parent.id}>
            <Link to={`${BASE_FAMILIES}/${familyId}/parent/${parent?.id}`}>
              {parent?.user?.name} {parent?.user?.patronymic}
            </Link>
            <br />
          </span>
        ));
      }
    }
  }
];

export default { getColumns };
