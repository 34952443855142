import styled from 'styled-components';

export const StyledTable = styled.div`
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 5px 7px;
  }

  @media (max-width: 991px) {
    table {
      min-width: 100% !important;
    }
  }

  @media (min-width: 1200px) {
    table {
      min-width: 100% !important;
    }
  }
  .ant-table-wrapper .ant-btn,
  .ant-select {
    margin: 0;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoContainer = styled.div`
  width: 100%:
  text-align: center;
  margin: 15px 0;
  img {
    width: 220px;
    object-fit: cover;
  }
`;

export default { StyledTable, InfoContainer, LogoContainer };
