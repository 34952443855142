import React from 'react';
import dayjs from 'dayjs';
import { Message, MessageInfo, MessageName, MessageContent, MessageBubble, MessageRole, MessageDate } from './styles';
import teacherImg from 'img/teacher.png';
import parentImg from 'img/parent.png';
import { dateFormat } from 'helpers/dates';

// Типы
import { ThreadMessage as IThreadMessage } from 'types/CRM/messages';

interface ThreadMessageProps {
  message: IThreadMessage;
}

/**
 * @description Сообщение треда
 * @param {ThreadMessageProps} props
 * @return {React.ReactNode}
 */
export const ThreadMessage = (props: ThreadMessageProps) => {
  const {
    message: {
      body,
      createdAt,
      sender: { fullName, role }
    }
  } = props;
  return (
    <Message reverse={role === 'teacher'}>
      <MessageInfo>
        <img src={role === 'parent' ? parentImg : teacherImg} alt="Face person" />
        <MessageName>{fullName}</MessageName>
        <MessageRole>{role}</MessageRole>
        {createdAt && <MessageDate>{dayjs(createdAt).format(dateFormat)}</MessageDate>}
      </MessageInfo>
      <MessageContent>
        <MessageBubble>{body}</MessageBubble>
      </MessageContent>
    </Message>
  );
};

export default { ThreadMessage };
