import BaseFilter from '../BaseFilter';
import { FAMILIES_LIMIT } from '../../Global/constants';
import { Rate } from '../../types';
import { find } from 'lodash';

export default class FamilyService extends BaseFilter {
  static limit = FAMILIES_LIMIT;
  /**
   * @description Метод для получения стоимости рейта по идентификатору.
   * @param {number} id
   * @param {Rate[]} rates
   * @return {number}
   */
  public getRatePriceById(id: number, rates: Rate[]): number {
    const rate = find(rates, (rate: Rate) => rate.id === id);
    return (rate && rate.price) || 0;
  }

  /**
   * @param {number} id
   * @param {Rate[]} rates
   * @return {boolean}
   */
  public getRateById(id: number, rates: Rate[]): Rate {
    return find(rates, (rate: Rate) => rate.id === id);
  }

  /**
   * @description Метод для получения общей суммы c учетом скидки
   * @param {number} price
   * @param {number} discount
   * @return {number}
   */
  public getAmountWithDiscount(price: number, discount: number): number {
    if (!discount) return price;
    return price - Math.floor((discount / 100) * price);
  }
}
