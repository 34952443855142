import React, { useMemo } from 'react';
import { Radio } from 'antd';
import { Flex } from 'antd';
import { StyledColorRadioGroup } from './GradeList';
import { OriginalLessonOutcomeItem } from 'types/TeacherAccount/Courses';
import { ColumnProps } from 'antd/lib/table';
import { gradeList, primaryGradeList } from '../constants';
import { AvatarWithUserNameMemo } from 'Global/components/AvatarWithUserName';
import { IStudent } from 'types';
import { BASE_FAMILIES } from 'Global/routes';

interface OutcomeColumnsProps {
  outcomesList: OriginalLessonOutcomeItem[];
  isRowSelected: boolean;
  setValueForSelection: (value: any, outcomeId: number) => void;
  hasPrimaryOrSchool: boolean;
  renderOutcomeCell: (item: OriginalLessonOutcomeItem, index: number) => (record: any) => JSX.Element;
}
interface StudentColumnProps {
  strings: any;
  outcomesList: OriginalLessonOutcomeItem[];
}

export const getStudentColumn = ({ strings, outcomesList }: StudentColumnProps): ColumnProps<any>[] => {
  const columns = useMemo((): ColumnProps<IStudent>[] => {
    return [
      {
        title: <div style={{ textAlign: 'center' }}>{strings.STUDENTS}</div>,
        dataIndex: 'student',
        key: 'student',
        width: 200,
        render: (student: IStudent) => (
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <AvatarWithUserNameMemo
              name={student?.user?.name}
              surname={student?.user?.surname}
              link={`${BASE_FAMILIES}/${student?.familyId}/student/${student?.id}`}
              photo={student?.mainPhoto}
            />
          </div>
        )
      }
    ];
  }, [outcomesList]);

  return columns;
};

export const getOutcomeColumns = ({
  outcomesList,
  isRowSelected,
  setValueForSelection,
  hasPrimaryOrSchool,
  renderOutcomeCell
}: OutcomeColumnsProps): ColumnProps<any>[] => {
  return useMemo(() => {
    return outcomesList?.map((item, index) => {
      const RadioGroupComponent = hasPrimaryOrSchool ? StyledColorRadioGroup : Radio.Group;

      const title = (
        <Flex align="center" gap={10} vertical style={{ minWidth: 200 }}>
          <div style={{ fontSize: 14 }} dangerouslySetInnerHTML={{ __html: item?.description }} />
          <RadioGroupComponent
            disabled={!isRowSelected}
            size="small"
            onChange={e => setValueForSelection(e.target.value, item.id)}
          >
            {hasPrimaryOrSchool
              ? primaryGradeList.map(grade => <Radio.Button value={grade.value} key={grade.value} />)
              : gradeList.map(grade => (
                  <Radio.Button value={grade.value} key={grade.value}>
                    {grade.title}
                  </Radio.Button>
                ))}
          </RadioGroupComponent>
        </Flex>
      );

      return {
        title,
        className: 'clmn-center clmn-bottom',
        dataIndex: '',
        key: `outcome_${index}`,
        render: renderOutcomeCell(item, index)
      };
    });
  }, [outcomesList, isRowSelected]);
};

export default { getOutcomeColumns, getStudentColumn };
