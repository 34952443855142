import React, { useCallback } from 'react';
import { Flex, InputNumber } from 'antd';
import { generateColumn } from '../../helpers';
import { THandleSetFieldByChildId } from 'types/TeacherAccount/Courses';
import { useLanguageContext } from 'contexts/LanguageContext';
import { StInputPercent } from '../../styles';
import { ILessonRegister } from 'types/lesson';
import { useLessonRegisterStore } from 'stores/useLessonRegisterStore';
import { Child } from 'types';

/**
 * @description Рендеринг заголовка ввода максимального значения теста
 */
const TitleTaskPercent = () => {
  const [strings] = useLanguageContext();
  const maxTaskGrade = useLessonRegisterStore(state => state.maxTaskGrade);
  const setMaxTaskGrade = useLessonRegisterStore(state => state.setMaxTaskGrade);
  const task = useLessonRegisterStore(state => state.extraRegisterData?.task);

  const handleChangeMaxTaskGrade = useCallback((val: number) => {
    setMaxTaskGrade(val);
  }, []);

  return (
    <Flex vertical gap={5} align="center">
      <span>{task?.topic}</span>
      <StInputPercent>
        <span>{strings.MAX_PERCENT}</span>
        <InputNumber
          name="taskPercent"
          size="small"
          id="taskPercent"
          onChange={handleChangeMaxTaskGrade}
          value={maxTaskGrade}
        />
      </StInputPercent>
    </Flex>
  );
};

/**
 * @description Рендеринг колонки ввода процентов
 * @param {number} taskGrade
 * @param {number} taskPercent
 * @param {object} student
 * @param {function} handleSetFieldByChildId
 * @return {React.ReactNode}
 */
const RegisterTaskPercent = React.memo(
  ({
    taskGrade,
    student,
    handleSetFieldByChildId
  }: {
    taskGrade: number;
    student: Child;
    handleSetFieldByChildId: THandleSetFieldByChildId;
  }) => {
    const maxTaskGrade = useLessonRegisterStore(state => state.maxTaskGrade);
    const handleChangeTestPercent = useCallback((val: number) => {
      handleSetFieldByChildId('taskGrade', student.id, val);
    }, []);

    const calculatePercentage = useCallback(
      (value: number): string => {
        if (!maxTaskGrade || maxTaskGrade === 0) {
          return `%`;
        }
        const percentage = String(Math.floor((value / maxTaskGrade) * 100)).substring(0, 3);
        return `${percentage}%`;
      },
      [maxTaskGrade]
    );

    return (
      <StInputPercent>
        <InputNumber value={taskGrade} onChange={handleChangeTestPercent} />
        <span>{calculatePercentage(taskGrade)}</span>
      </StInputPercent>
    );
  }
);

/**
 * @description Генерация колонкки процента за тест
 * @param {function} handleSetFieldByChildId
 * @return {object}
 */
export const getTaskPercentColumn = (handleSetFieldByChildId: THandleSetFieldByChildId) => {
  return {
    ...generateColumn(() => <TitleTaskPercent />, 'taskGrade', 'taskGrade', 'table-border-right', null, 110),
    render: (taskGrade: number, { student }: ILessonRegister) => (
      <RegisterTaskPercent student={student} taskGrade={taskGrade} handleSetFieldByChildId={handleSetFieldByChildId} />
    )
  };

  return {};
};
