import React from 'react';
import dayjs from 'dayjs';
import { CloseCircleOutlined, DeleteOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip, Button, Flex } from 'antd';
import { dateFormat } from 'helpers/dates';
import { Link } from 'react-router-dom';
import { hasRole } from 'helpers';
import { ROLE_TOP_MANAGER, ROLE_BRANCH_DIRECTOR } from 'Global/roles';
import { User } from 'types/user';
import { CourseGroup } from 'types/education';
/**
 * @description Получение колонок для таблицы с группам
 * @param {number} id
 * @param {function} showModal
 * @param {function} showDeleteConfirm
 * @param {object} user
 * @return {array}
 */
export const getColumns = (
  id: number,
  showModal: (id: number) => void,
  showDeleteConfirm: (id: number, student: number) => void,
  user: User,
  showDisenrollConfirm: (id: number, student: number) => void,
  showLogsModal: (id: number) => void
) => [
  {
    title: 'Enrollment date',
    dataIndex: 'startDate',
    key: 'startAt',
    width: 180,
    render: (date: string) => date && dayjs(date).format(dateFormat)
  },
  {
    title: 'Disenrollment date',
    dataIndex: 'endDate',
    key: 'endAt',
    width: 180,
    render: (date: string) => date && dayjs(date).format(dateFormat)
  },
  {
    title: 'Group',
    dataIndex: 'group',
    key: 'group',
    width: 280,
    render: (group: CourseGroup) => (
      <Flex>
        <Link to={`/group/${group?.id}`}>{group?.groupName}</Link>
        <Tooltip title="Logs">
          <Button icon={<InfoCircleOutlined />} style={{ marginInlineStart: 8 }} onClick={() => showLogsModal(id)} />
        </Tooltip>
      </Flex>
    )
  },
  {
    title: 'Action',
    key: 'action',
    dataIndex: 'group',
    width: 120,
    render: group => {
      return (
        <Flex>
          <Button icon={<EditOutlined />} onClick={() => showModal(group.id)} />
          {hasRole(user, [ROLE_BRANCH_DIRECTOR, ROLE_TOP_MANAGER]) && (
            <>
              <Button
                icon={<CloseCircleOutlined />}
                onClick={() => showDisenrollConfirm(group.id, id)}
                type="primary"
                style={{ marginInlineStart: 10 }}
              />
              <Button
                icon={<DeleteOutlined />}
                danger
                onClick={() => showDeleteConfirm(group.id, id)}
                style={{ marginInlineStart: 8 }}
              />
            </>
          )}
        </Flex>
      );
    }
  }
];

export default { getColumns };
