import React from 'react';
import styled from 'styled-components';

import { Button } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Room } from 'types/Schedule';

const StZoomInfo = styled.p`
  span {
    font-size: 14px;
    line-height: 16px;
    color: #979797;
    padding-inline-end: 10px;
  }
  color: #000;
`;

const StZoomWidget = styled.div`
  display: flex;
  align-items: baseline;
  gap: 20px;
  flex-wrap: wrap;
`;

/**
 * @description Виджет zoom
 * @param {object} room
 * @return {React.ReactNode}
 */
export const ZoomWidget = ({ room }: { room: Room }) => {
  const [strings] = useLanguageContext();
  return (
    <StZoomWidget>
      <a href={room.webLink} rel="noopener noreferrer" target="_blank">
        <Button>{strings.JOIN_CALL}</Button>
      </a>
      <StZoomInfo>
        <span>{strings.ZOOM_LOGIN}</span>
        {room.webLogin}
      </StZoomInfo>
      <StZoomInfo>
        <span>{strings.ZOOM_PASSWORD}</span>
        {room.webPassword}
      </StZoomInfo>
    </StZoomWidget>
  );
};
