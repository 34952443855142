import React from 'react';
import { Modal, Spin } from 'antd';

interface PreviewFileModalProps {
  hasShowModal: boolean;
  handleHideModal: () => void;
  previewUrl: string | ArrayBuffer;
  fileName: string;
  hasLoadPreviewAttachment: boolean;
  hasImageFile?: boolean;
}

/**
 * @description Модальное окно превью материала
 */
export const PreviewFileModal = (props: PreviewFileModalProps) => {
  const { hasShowModal, handleHideModal, previewUrl, fileName, hasLoadPreviewAttachment, hasImageFile } = props;

  const modalProps = {
    width: 1000,
    visible: hasShowModal,
    onCancel: handleHideModal,
    title: fileName,
    footer: []
  };

  const iframeProps = {
    src: `${previewUrl}#toolbar=0&statusbar=0`,
    style: {
      width: '100%',
      height: '600px',
      border: 'none'
    }
  };

  return (
    <Modal destroyOnClose={true} {...modalProps} style={{ textAlign: 'center', objectFit: 'cover', minHeight: 100 }}>
      <Spin spinning={hasLoadPreviewAttachment}>
        {previewUrl && (
          <>
            {hasImageFile ? (
              // @ts-ignore
              <img src={previewUrl} style={{ maxHeight: '600px', maxWidth: '100%' }} />
            ) : (
              // @ts-ignore
              <iframe {...iframeProps} />
            )}
          </>
        )}
      </Spin>
    </Modal>
  );
};

export default { PreviewFileModal };
