import React, { useCallback, useMemo, useState } from 'react';
import { CheckCircleTwoTone, EditOutlined, ExclamationCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import { Badge, Button, Flex } from 'antd';
import { generateColumn } from '../../helpers';
import { FeedbackForSupervisorModal } from '../RegisterModals/FeedbackForOfficeModal';
import { attentionBadgeProps, successBadgeProps } from '../index';
import { ILessonRegister } from 'types/lesson';
import { StTitle } from '../styles';

/**
 * @description Рендеринг колонки Feedback for office
 * @param {object} register
 * @param {function} setRegisters
 * @param {function} handleSetFieldByChildId
 * @param {number} lessonId
 * @return {React.ReactNode}
 */
const RegisterFeedbackForSupervisor = React.memo(({ register }: { register: ILessonRegister }) => {
  const { needFeedbackForSupervisorStatus, commentForSupervisor, commentForSupervisorAudio } = register;
  const [hasShowFeedbackForSupervisorModal, setHasShowFeedbackForSupervisorModal] = useState<boolean>(false);

  const handleShowModal = useCallback(() => setHasShowFeedbackForSupervisorModal(true), []);

  const buttonProps = useMemo(
    () => ({
      style: { width: 48 },
      onClick: handleShowModal
    }),
    [handleShowModal]
  );

  const feedbackModal = useMemo(
    () => (
      <FeedbackForSupervisorModal
        register={register}
        hasShowFeedbackForSupervisorModal={hasShowFeedbackForSupervisorModal}
        setHasShowFeedbackForSupervisorModal={setHasShowFeedbackForSupervisorModal}
      />
    ),
    [hasShowFeedbackForSupervisorModal, register]
  );

  /**
   * Если комментария нет и заполнять не надо - просто плюс
   * Если не заполнено, а просят заполнить - бейдж с восклицательным знаком
   * Если заполнено - галочка и эдит
   * needFeedbackForSupervisorStatus: 0 - не нужен, 1 - запрос коммента обычный, по расписанию, 3 - запрос по запросу
   */
  const renderButton = useMemo(() => {
    if (!commentForSupervisor && !commentForSupervisorAudio) {
      return needFeedbackForSupervisorStatus === 1 || needFeedbackForSupervisorStatus === 2 ? (
        <Badge count={<ExclamationCircleTwoTone {...attentionBadgeProps} />}>
          <Button icon={<PlusOutlined />} {...buttonProps} />
        </Badge>
      ) : (
        <Button icon={<PlusOutlined />} {...buttonProps} />
      );
    }
    return (
      <Badge count={<CheckCircleTwoTone {...successBadgeProps} />}>
        <Button icon={<EditOutlined />} {...buttonProps} />
      </Badge>
    );
  }, [commentForSupervisor, commentForSupervisorAudio, needFeedbackForSupervisorStatus, buttonProps]);

  return (
    <Flex justify="center">
      {hasShowFeedbackForSupervisorModal && feedbackModal}
      {renderButton}
    </Flex>
  );
});

/**
 * @description Генерация колонки feedback for supervisor
 * @param {string[]} registerColumns
 * @param {number} lessonId
 * @return {object}
 */
export const getFeedbackForSupervisorColumn = (registerColumns: string[], strings) => {
  if (registerColumns.includes('commentForSupervisor')) {
    return {
      ...generateColumn(
        <StTitle>{strings.OFFICE}</StTitle>,
        'commentForSupervisor',
        'commentForSupervisor',
        'table-border-right',
        null,
        100
      ),
      render: (commentForSupervisor: boolean, register: ILessonRegister) => (
        <RegisterFeedbackForSupervisor register={register} />
      )
    };
  }
};
