import React from 'react';
import dayjs from 'dayjs';
import { AVAILABLE_LANGS, useLanguageContext } from 'contexts/LanguageContext';
import { StyledLessonItem } from 'Student&Parent/Main/styles';
import { getSubjectNameByLang } from '../../helpers';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { EN_KEY, LV_KEY } from 'Admin/Translations';

export const UpcomingLessonItem = ({ slot }) => {
  const [, lang] = useLanguageContext();
  const { subjectsTranslation } = useGlobalCollectionsContext();
  const { startAt, type, color } = slot;
  const startTime = dayjs(startAt)
    .locale(lang === AVAILABLE_LANGS[LV_KEY] ? AVAILABLE_LANGS[EN_KEY] : lang) // на латышский нет пока переводов
    .format('Do MMM, HH:mm');

  const subject = slot.lesson?.course?.subject;

  const title = type === 'event' ? slot?.event?.name : getSubjectNameByLang(subjectsTranslation, lang, subject?.id);

  return (
    <StyledLessonItem style={{ background: color }}>
      <span>{startTime}</span>
      <p>{title}</p>
    </StyledLessonItem>
  );
};
