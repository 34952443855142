import forEach from 'lodash/forEach';
import { message } from 'antd';
import { Global } from '../api';

/**
 * @description - Функция для проверки полей при создании детей
 * @param {Child} child
 * @return {null}
 */
export const checkChildrenErrors = (child: any) => {
  let isErrors = false;
  forEach(child, children => {
    const { name, id } = children;
    const errorMessage = `Error children - ${id + 1}. Name is required field`;
    if (!name) {
      message.error(errorMessage, 5);
      isErrors = true;
    }
  });
  return isErrors;
};

/**
 * @description Функция для проверки полей при создании родителей
 * @param payers
 * @return {boolean}
 */
export const checkPayersErrors = (payers: any) => {
  let isErrors = false;
  forEach(payers, payer => {
    const { email, phone, id } = payer;
    const errorMessage = `Error payer - ${id + 1}. Email or phone is required field`;
    if (!email && !phone) {
      message.error(errorMessage, 5);
      isErrors = true;
    }
  });
  return isErrors;
};

/**
 * @description Проверяет поля формы на наличие хоть одной ошибки
 * @param {object} fieldsError
 * @return {boolean}
 */
export const hasErrorsFields = (fieldsError: object): boolean => {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
};

/**
 * @description - проверки на наличие телефона в базе
 */
export const checkPhone = async (rule, value, callback): Promise<any> => {
  if (value && value.length) {
    try {
      await Global.validatePhone(value);
      callback();
    } catch (error) {
      callback(error.data);
    }
  }
};
