import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { message, Upload, Button } from 'antd';
import { UploadFile } from 'types/global';
import { getFormData } from 'helpers';
import { useAttachmentRepository } from 'repos/AttachmentRepository';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Global } from 'api';

export function UploadMaterialButton({ type, materialType, lessonId, getOriginalCourseFiles }) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const attachmentRepository = useAttachmentRepository();
  const [strings] = useLanguageContext();
  const moveMaterial = async (materialId: number): Promise<any> => {
    try {
      await Global.editAttachment({ mark: true }, materialId);
      message.success(strings.SUCCESSFULLY_MOVE, 2);
      await getOriginalCourseFiles();
    } catch {
      message.error(`${strings.ERROR_TRY_AGAIN_LATER} :(`, 2);
    }
  };
  const uploadProps = {
    name: 'file',
    multiple: false,
    beforeUpload: async (file: UploadFile): Promise<any> => {
      setLoading(true);
      try {
        const {
          data: { id },
          status
        } = await attachmentRepository.uploadAttachment(
          getFormData({
            type: materialType,
            id: lessonId, // id lesson original
            file: file
          }),
          true
        );
        if (status === 200) {
          message.success(strings.FILE_UPLOAD, 2);
          //если загружаем в approved, сразу ставим звездочку
          if (type === 'approved') {
            await moveMaterial(id);
          } else {
            await getOriginalCourseFiles();
          }
        }
      } catch {
        message.error(strings.UPLOAD_FILE_ERROR, 2);
      } finally {
        setLoading(false);
      }
      return false;
    },
    showUploadList: false
  };

  return (
    <Upload {...uploadProps}>
      <Button
        icon={<UploadOutlined />}
        type="primary"
        style={{ marginBottom: 0 }}
        disabled={isLoading}
        loading={isLoading}
      >
        {strings.UPLOAD}
      </Button>
    </Upload>
  );
}
