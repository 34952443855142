import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Spin, Typography, Table, Modal, message, Row, Button, Switch, Flex } from 'antd';
import { Courses } from 'api';
import { addStudentToSupergroup } from 'api/Families/child';
import { getColumns } from './columns';

import { GroupModal } from './GroupModal';
import { LogsModal } from './LogsModal';
import { AddToSupergroup } from './AddToSupergroup';
import { AddToGroup } from './AddToGroup';

import { reversedDateFormatForBackend } from 'helpers/dates';
import { useUserContext } from 'contexts/UserContext';
import { useStudentRepository } from 'repos/StudentRepository';
import { useLanguageContext } from 'contexts/LanguageContext';
import { CourseGroup } from 'types/education';
import { StPageHeader } from 'Global/GlobalStyle';

const { Title } = Typography;

interface GroupsProps {
  id: number; // Child id
}

/**
 * @description Child groups
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const Groups = (props: GroupsProps) => {
  const [oldGroups, setOldGroups] = useState<CourseGroup[]>([]);
  const [activeGroups, setActiveGroups] = useState<CourseGroup[]>([]);
  const [showGroups, setShowGroups] = useState<boolean>(false);
  const [showOldGroups, setShowOldGroups] = useState<boolean>(false);
  const [groupId, setGroupId] = useState<number | null>(null);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [areLogsShown, toggleLogsModal] = useState<boolean>(false);
  const [hasOpenAddToSupegroup, setOpenAddToSupegroup] = useState<boolean>(false);
  const [hasOpenAddToGroup, setOpenAddToGroup] = useState<boolean>(false);
  const [user] = useUserContext();
  const { id } = props;
  const studentRepository = useStudentRepository();
  const [strings] = useLanguageContext();
  const [{ confirm }, contextHolder] = Modal.useModal();

  /**
   * @description Получение групп студента по id. Разделение на актуальные и неактуальные группы.
   * @return {Promise<any>}
   * */
  const fetchGroupsById = async (): Promise<any> => {
    try {
      const { data } = await studentRepository.getChildGroups(id);
      setOldGroups(data.filter(item => !item.group.isActive || item.endDate));
      setActiveGroups(data.filter(item => item.group.isActive && !item.endDate));
      setShowGroups(true);
    } catch {
      setShowGroups(false);
    }
  };

  /**
   * @description Открытие модального окна и установка id текущей группы
   * @param {number} id
   * @return {void}
   */
  const showModal = (id: number): void => {
    setGroupId(id);
    setVisibleModal(true);
  };

  const showLogsModal = (id: number): void => {
    setGroupId(id);
    toggleLogsModal(true);
  };

  /**
   * @description Удаление группы студента
   * @param {number} id - айди гsруппы
   * @param {number} studentId - айди студента
   * @return {Promise<any>}
   */
  const deleteGroupById = async (id: number, studentId: number): Promise<any> => {
    setShowGroups(false);
    await Courses.deleteGroupStudent(id, studentId).then(async ({ status }) => {
      if (status === 200) {
        await fetchGroupsById();
        setShowGroups(true);
        message.success(strings.GROUP_SUCCESSFULLY_DELETED);
      }
    });
  };

  /**
   * @description Отчисление студента из группы
   * @param {number} id - айди гsруппы
   * @param {studentId} studentId - айди студента
   * @return {void}
   */
  const disenrollStudentFromGroup = async (id: number, studentId: number): Promise<any> => {
    setShowGroups(false);
    await Courses.disenrollStudentFromGroup(id, studentId, {
      // В качестве даты отчисления сегодяшняя дата
      endAt: dayjs().format(reversedDateFormatForBackend)
    }).then(async ({ status }) => {
      if (status === 200) {
        await fetchGroupsById();
        setShowGroups(true);
        message.success(strings.STUDENT_SUCCESSFULLY_EXPELLED);
      }
    });
  };

  /**
   * @description Открытие confirm на УДАЛЕНИЕ студента
   * @param {number} id - айди группы
   * @param {number} studentId  - айди студента
   * @return {void}
   */
  const showDeleteConfirm = (id: number, studentId: number): void => {
    confirm({
      title: strings.DO_YOU_REALLY_WANT_TO_DELETE_THIS_INFORMATION,
      centered: true,
      onOk: async (): Promise<any> => {
        await deleteGroupById(id, studentId);
      }
    });
  };

  /**
   * @description Открытие confirm на ОТЧИСЛЕНИЕ студента
   * @param {number} id
   * @param {number} studentId
   * @return {void}
   */
  const showDisenrollConfirm = (id: number, studentId: number) => {
    confirm({
      title: strings.DISENROLL_STARTING_FROM_TODAY,
      centered: true,
      onOk: async (): Promise<any> => {
        await disenrollStudentFromGroup(id, studentId);
      }
    });
  };

  /**
   * @description Переключатель для таблицы с неактульными группами
   * @param {boolean} checked
   * @return {void}
   */
  const handleSwitchHistory = (checked: boolean): void => {
    setShowOldGroups(checked);
  };

  /**
   * @description Добавление студента в супергруппу
   * @param {object} data
   * @return {Promise<any>}
   */
  const handleAddToSupergroup = async (data): Promise<any> => {
    if (data.id && data.group) {
      try {
        await addStudentToSupergroup(data.group, data.id, { date: data.date });
        message.success(strings.SUPERGROUP_SUCCESSFULLY_ADDED);
        setOpenAddToSupegroup(false);
        fetchGroupsById();
      } catch {
        message.error(strings.ERROR_ADD_STUDENT_TO_A_SUPERGROUP);
      }
    }
  };

  useEffect(() => {
    fetchGroupsById();
  }, [id]);

  const chosenGroup = [...oldGroups, ...activeGroups].find(({ group }) => group.id === groupId);

  return (
    <Spin spinning={!showGroups} tip={strings.LOADING_CHILDREN_GROUPS}>
      {contextHolder}
      <AddToSupergroup
        onCancel={() => setOpenAddToSupegroup(false)}
        onOk={handleAddToSupergroup}
        id={id}
        hasOpen={hasOpenAddToSupegroup}
      />
      <AddToGroup
        studentId={id}
        onCancel={() => setOpenAddToGroup(false)}
        hasOpen={hasOpenAddToGroup}
        fetchGroupsById={fetchGroupsById}
      />
      <StPageHeader
        title={strings.GROUPS}
        extra={
          <Flex gap={10}>
            <Button onClick={() => setOpenAddToSupegroup(true)} type="primary">
              {strings.ADD_TO_A_SUPERGROUP}
            </Button>
            <Button onClick={() => setOpenAddToGroup(true)}>{strings.ADD_TO_A_GROUP}</Button>
          </Flex>
        }
      />
      <div>
        <Table
          rowKey="id"
          dataSource={activeGroups}
          columns={getColumns(id, showModal, showDeleteConfirm, user, showDisenrollConfirm, showLogsModal)}
          pagination={false}
          bordered={false}
        />
        <br />

        <Switch
          checkedChildren="History"
          unCheckedChildren="History"
          onClick={handleSwitchHistory}
          style={{ marginBottom: '20px' }}
        />
        {showOldGroups && (
          <Table
            rowKey="id"
            dataSource={oldGroups}
            columns={getColumns(id, showModal, showDeleteConfirm, user, showDisenrollConfirm, showLogsModal)}
            pagination={false}
            bordered={false}
          />
        )}
        {chosenGroup && (
          <>
            <GroupModal
              id={groupId}
              visible={visibleModal}
              hideModal={() => setVisibleModal(false)}
              student={id}
              fetchGroupsById={fetchGroupsById}
              setShowGroups={setShowGroups}
              group={chosenGroup}
            />
            <LogsModal visible={areLogsShown} hideModal={() => toggleLogsModal(false)} group={chosenGroup} />
          </>
        )}
      </div>
    </Spin>
  );
};

export default { Groups };
