export const BASE_FAMILIES = '/family';
export const BASE_FAMILY = `${BASE_FAMILIES}/:id`;
export const BASE_FAMILY_TAB = `${BASE_FAMILIES}/:id/:tab`;
export const BASE_STUDENT = `${BASE_FAMILY}/student/:userId`;
export const BASE_PAYER = `${BASE_FAMILY}/parent/:userId`;
export const EDIT_FAMILY = `${BASE_FAMILIES}/:id/edit`;
export const EDIT_STUDENT = `${BASE_STUDENT}/edit`;
export const EDIT_PAYER = `${BASE_PAYER}/edit`;
export const CREATE_FAMILY_CONTRACT = '/family/contract/new';
export const EDIT_FAMILY_CONTRACT = '/family/:id/contracts/:contractId/edit';
export const BASE_ONLINE_ACTIVITIES = '/online-activities';
export const BASE_ADD_ONLINE_ACTIVITY = '/online-activities/add';
export const BASE_HOMEWORKS_BASIC = '/online-activities/homework/basic';
export const BASE_HOMEWORKS_ADVANCED = '/online-activities/homework/advanced';
export const BASE_PEOPLE = '/people';
export const NEW_PROFILE_FAMILY = '/profile-family/new';
export const ADD_STUDENT_TO_FAMILY = '/family/:id/add-students';
export const ADD_PARENT_TO_FAMILY = '/family/:id/add-parents';
export const BASE_CHAT = `/chat`;
export const BASE_SEARCH = `/search`;
export const BASE_SUPPORT = `/support`;

export default {
  BASE_FAMILIES,
  BASE_STUDENT,
  BASE_PAYER,
  BASE_FAMILY,
  BASE_FAMILY_TAB,
  EDIT_FAMILY,
  EDIT_STUDENT,
  EDIT_PAYER,
  CREATE_FAMILY_CONTRACT,
  EDIT_FAMILY_CONTRACT,
  BASE_ONLINE_ACTIVITIES,
  BASE_ADD_ONLINE_ACTIVITY,
  BASE_HOMEWORKS_BASIC,
  BASE_HOMEWORKS_ADVANCED,
  BASE_PEOPLE,
  ADD_STUDENT_TO_FAMILY,
  ADD_PARENT_TO_FAMILY,
  BASE_CHAT,
  BASE_SEARCH
};
