import React, { useEffect, useState } from 'react';
import { Row, Table, Button, Spin, Flex, Popconfirm } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Link } from 'react-router-dom';
import { ICommentaryCalendarData } from 'types/commentary';
import { TEACHER_STATUS_NEW, BASE_TEACHER_STATUS } from '../../routes';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { getTeacherColumns } from '../columns';
import { useStatusesRepository } from 'repos/StatusesRepository';

export const TeacherStatuses = () => {
  const [strings] = useLanguageContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [statusesList, setStatusesList] = useState<ICommentaryCalendarData[]>([]);
  const statusesRepository = useStatusesRepository();

  const getStatusesList = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await statusesRepository.getTeacherStatuses({ withDetails: 1 });
      setStatusesList(data);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  const handleDelete = async (id: number): Promise<any> => {
    setHasLoading(true);
    try {
      await statusesRepository.deleteTeacherStatus(id);
      getStatusesList();
    } finally {
      setHasLoading(false);
    }
  };

  const getActionColumns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'action',
      render: (id: number) => (
        <Flex gap={10}>
          <Link to={`${BASE_TEACHER_STATUS}/${id}/edit`}>
            <Button icon={<EditOutlined />} size="small" />
          </Link>
          <Popconfirm
            title={strings.ARE_YOU_SURE}
            placement="left"
            okText={strings.YES}
            cancelText={strings.NO}
            onConfirm={() => handleDelete(id)}
          >
            <Button icon={<DeleteOutlined />} size="small" danger />
          </Popconfirm>
        </Flex>
      )
    }
  ];

  useEffect(() => {
    getStatusesList();
  }, []);

  return (
    <>
      <Flex justify="end">
        <Link to={TEACHER_STATUS_NEW}>
          <Button type="primary">{strings.ADD}</Button>
        </Link>
      </Flex>
      <Spin spinning={hasLoading}>
        <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
          <Table
            dataSource={statusesList}
            columns={[...getTeacherColumns(strings), ...getActionColumns]}
            pagination={false}
          />
        </Row>
      </Spin>
    </>
  );
};

export default { TeacherStatuses };
