import React from 'react';
import { Link } from 'react-router-dom';
import { ILesson } from 'types/courses';

export const columns = strings => [
  {
    title: strings.NUMBER,
    dataIndex: 'id',
    key: 'id',
    render: (id: number, lesson: ILesson, index: number) => <Link to={`/le/${id}`}>{index + 1}</Link>
  },
  {
    title: strings.CLASS_TOPIC,
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: strings.DATE,
    dataIndex: 'startAt',
    key: 'date'
  }
];

export default { columns };
