import React, { useState, useEffect } from 'react';
import { match } from 'react-router';
import { Col, Spin, Button, message, Input, Row, Select, Form, InputNumber, Checkbox, Divider, Typography } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { Branch } from 'types';
import { BASE_BRANCHES } from '../../routes';
import { useBranchesRepository } from 'repos/BranchesRepository';
import { BranchType, IBranchPostParams } from 'types/branches';
import { LANGS } from 'Admin/Translations';
import { useThemeContext } from 'contexts/ThemeContext';
const { Option } = Select;
const { Title } = Typography;

export const BranchForm = () => {
  const { activeTheme } = useThemeContext();
  const [strings] = useLanguageContext();
  const { goBack, push } = useHistory();
  const [form] = Form.useForm();
  const branchRepository = useBranchesRepository();
  const [hasLoading, setHasLoading] = useState<boolean>(false);

  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();

  const getBranchById = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data }: { data: Branch } = await branchRepository.getBranchById(id);
      form.setFieldsValue(data);
    } finally {
      setHasLoading(false);
    }
  };

  const onSubmit = async (params: IBranchPostParams): Promise<any> => {
    setHasLoading(true);
    try {
      if (id) {
        await branchRepository.editBranch(id, params);
        message.success(strings.SUCCESSFULLY_UPDATED, 2);
      } else {
        await branchRepository.addBranch(params);
        message.success(strings.SUCCESSFULLY_CREATED, 2);
      }
      push(`${BASE_BRANCHES}?tab=branches`);
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getBranchById();
    }
  }, [id]);

  return (
    <Container>
      <StPageHeader onBack={goBack} title={id ? strings.EDIT : strings.CREATE} />
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Spin spinning={hasLoading}>
          <Divider orientation="left" plain>
            <Title level={5} style={{ color: activeTheme.primaryColor }}>
              {strings.GENERAL_INFO}
            </Title>
          </Divider>
          <Row gutter={[10, 10]} align="middle">
            <Col xs={24} lg={8}>
              <Form.Item
                name="name"
                label={strings.NAME}
                rules={[{ required: true, message: `${strings.NAME} ${strings.IS_REQUIRED}` }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={4}>
              <Form.Item name="type" label={strings.TYPE}>
                <Select placeholder={strings.TYPE} allowClear>
                  {Object.values(BranchType).map((item: string) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={4}>
              <Form.Item name="parentLanguage" label={strings.PARENT_LANGUAGE}>
                <Select placeholder={strings.PARENT_LANGUAGE} allowClear>
                  {Object.values(LANGS).map((item: string) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item
                name="daysFeedbackForNewStudent"
                label={strings.NEW_STUDENT_DAYS_FOR_FIRST_FEEDBACK_FOR_PARENTS}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item name="invoiceMonthFinanceSystem" valuePropName="checked">
                <Checkbox>{strings.FINANCE_SYSTEM_WITH_MONTH_INVOICES}</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} lg={4}>
              <Form.Item name="active" valuePropName="checked" initialValue={true}>
                <Checkbox>{strings.ACTIVE}</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left" plain>
            <Title level={5} style={{ color: activeTheme.primaryColor }}>
              {strings.EMAILS}
            </Title>
          </Divider>
          <Row gutter={[10, 10]} align="middle">
            <Col xs={24} lg={6}>
              <Form.Item
                name="academicEmail"
                label={strings.ACADEMIC_DEPARTMENT_EMAIL}
                rules={[{ type: 'email', message: strings.EMAIL_IS_NOT_VALID }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                name="operationEmail"
                label={strings.OPERATION_DEPARTMENT_EMAIL}
                rules={[{ type: 'email', message: strings.EMAIL_IS_NOT_VALID }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                name="hrEmail"
                label={strings.HR_EMAIL}
                rules={[{ type: 'email', message: strings.EMAIL_IS_NOT_VALID }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                name="invoicesEmail"
                label={strings.INVOICES_EMAIL}
                rules={[{ type: 'email', message: strings.EMAIL_IS_NOT_VALID }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                name="noReplyEmail"
                label={strings.NO_REPLY_EMAIL}
                rules={[{ type: 'email', message: strings.EMAIL_IS_NOT_VALID }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item name="noReplyName" label={strings.NO_REPLY_NAME}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left" plain>
            <Title level={5} style={{ color: activeTheme.primaryColor }}>
              {strings.FINANCE_DISPLAY_SETTINGS}
            </Title>
          </Divider>
          <Row gutter={[10, 10]} align="middle">
            <Col xs={24} lg={6}>
              <Form.Item name="disableCabinetLock" valuePropName="checked">
                <Checkbox>{strings.DISABLE_NEGATIVE_BALANCE_LOCK}</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item name="hideTeacherFinance" valuePropName="checked">
                <Checkbox>{strings.DISABLE_TEACHERS_FINANCE}</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item name="hideParentFinance" valuePropName="checked">
                <Checkbox>{strings.DISABLE_FAMILY_FINANCE}</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                name="CURRENCY"
                label={strings.CURRENCY}
                layout="horizontal"
                labelAlign="left"
                labelCol={{ span: 7 }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left" plain>
            <Title level={5} style={{ color: activeTheme.primaryColor }}>
              {strings.OTHER}
            </Title>
          </Divider>
          <Row gutter={[10, 10]} align="middle">
            <Col xs={24} lg={24}>
              <Form.Item name="hideTeacherAssessment" valuePropName="checked">
                <Checkbox>
                  {
                    strings.TEACHER_ASSESSMENT_MARKS_WILL_BE_HIDDEN_FROM_TEACHERS_WHEN_SELECTED_OTHER_ASSESSMENT_COMMENTS_WILL_STILL_BE_VISIBLE
                  }
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={6}>
              <Button type="primary" size="large" htmlType="submit" loading={hasLoading}>
                {id ? strings.EDIT : strings.ADD}
              </Button>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Container>
  );
};
