export const mockStudents = [
  {
    name: 'Oleg novoselov'
  },
  {
    name: 'Oleg novoselov'
  },
  {
    name: 'Oleg novoselov'
  }
];

export const mockOriginalCourses = [
  {
    title: 'D1 Science  (7-8 years).',
    description: 'Plants, Mathematics 1, Animals, Mathematics 2, Materials, Electricity and Energy, Rev...',
    id: 1281421,
    stage: 'D',
    subject: 'Science'
  },
  {
    title: 'D1 Science  (7-8 years).',
    description: 'Plants, Mathematics 1, Animals, Mathematics 2, Materials, Electricity and Energy, Rev...',
    id: 1281421,
    stage: 'D',
    subject: 'Science'
  },
  {
    title: 'D1 Science  (7-8 years).',
    description: 'Plants, Mathematics 1, Animals, Mathematics 2, Materials, Electricity and Energy, Rev...',
    id: 1281421,
    stage: 'D',
    subject: 'Science'
  }
];

export const mockGroupsWorkProgress = [
  {
    id: 123123,
    group: 'D1',
    subject: 'Science',
    originalCourse: 'D1 Science (7-8 years)',
    description: 'Plants, Mathematics 1, Animals, Mathematics 2, Mathema...',
    year: '2019'
  },
  {
    id: 123123,
    group: 'D1',
    subject: 'Science',
    originalCourse: 'D1 Science (7-8 years)',
    description: 'Plants, Mathematics 1, Animals, Mathematics 2, Mathema...',
    year: '2019'
  },
  {
    id: 123123,
    group: 'D1',
    subject: 'Science',
    originalCourse: 'D1 Science (7-8 years)',
    description: 'Plants, Mathematics 1, Animals, Mathematics 2, Mathema...',
    year: '2019'
  },
  {
    id: 123123,
    group: 'D1',
    subject: 'Science',
    originalCourse: 'D1 Science (7-8 years)',
    description: 'Plants, Mathematics 1, Animals, Mathematics 2, Mathema...',
    year: '2019'
  },
  {
    id: 123123,
    group: 'D1',
    subject: 'Science',
    originalCourse: 'D1 Science (7-8 years)',
    description: 'Plants, Mathematics 1, Animals, Mathematics 2, Mathema...',
    year: '2019'
  },
  {
    id: 123123,
    group: 'D1',
    subject: 'Science',
    originalCourse: 'D1 Science (7-8 years)',
    description: 'Plants, Mathematics 1, Animals, Mathematics 2, Mathema...',
    year: '2019'
  },
  {
    id: 123123,
    group: 'D1',
    subject: 'Science',
    originalCourse: 'D1 Science (7-8 years)',
    description: 'Plants, Mathematics 1, Animals, Mathematics 2, Mathema...',
    year: '2019'
  }
];

export const mockGroupsTrainings = [
  {
    id: 1,
    title: 'Developmental Psychology: From 2 to 7.',
    description:
      'This training event includes an online course and a face-to-face discussion with the training facilitators. It informs the teachers about the key developmental lines of our students from early childhood to adolescence.',
    link: 'www.openlearning.com'
  },
  {
    id: 2,
    title: 'Developmental Psychology: From 2 to 7.',
    description:
      'This training event will present the basic principles of teaching, classroom management and lesson planning',
    link: 'www.openlearning.com'
  },
  {
    id: 3,
    title: `This training event is a continuation of 'Interactive teaching'. It will lead the participants through different stages of lesson planning and delivery, such as selection of material, iplanning of activities, warm-up and lead-in, checking homework, presentation, practice and assessment. The presenter will demonstrate and give the teachers a chance to experience different games and classroom activities that can be used at different stages of the lessons.`,
    description: `This training event is a continuation of 'Interactive teaching'. It will lead the participants through different stages of lesson planning and delivery, such as selection of material, iplanning of activities, warm-up and lead-in, checking homework, presentation, practice and assessment. The presenter will demonstrate and give the teachers a chance to experience different games and classroom activities that can be used at different stages of the lessons.`,
    link: 'www.openlearning.com'
  }
];
export const mockCourse = {
  course: {
    id: null,
    name: ''
  },
  units: []
};

export const mockFinances = {
  summary: {
    start_balance: '26850',
    end_balance: '26850'
  },
  currentMonth: [],
  previousMonths: [
    {
      id: 431368,
      date: '2019-10-19T09:45:00+03:00',
      sum: 2200,
      comment: 'Teacher - Msk_G2_Che_Trushin',
      product: {
        id: 8,
        name: 'Online G'
      }
    },
    {
      id: 427360,
      date: '2019-10-13T19:00:00+03:00',
      sum: 2275,
      comment: 'Teacher - Online_G1_Che_Nasonov',
      product: {
        id: 8,
        name: 'Online G'
      }
    },
    {
      id: 431282,
      date: '2019-10-13T17:00:00+03:00',
      sum: 2200,
      comment: 'Teacher - Msk_G2_Che_Trushin',
      product: {
        id: 8,
        name: 'Online G'
      }
    },
    {
      id: 431128,
      date: '2019-10-04T17:45:00+03:00',
      sum: 2200,
      comment: 'Teacher - Msk_G2_Che_Trushin',
      product: {
        id: 8,
        name: 'Online G'
      }
    },
    {
      id: 421786,
      date: '2019-10-03T17:15:00+03:00',
      sum: 2275,
      comment: 'Teacher - Online_G1_Che_Nasonov',
      product: {
        id: 8,
        name: 'Online G'
      }
    },
    {
      id: 416553,
      date: '2019-10-01T16:00:00+03:00',
      sum: 2275,
      comment: 'Teacher - Online_G1_Che_Nasonov',
      product: {
        id: 8,
        name: 'Online G'
      }
    }
  ]
};

// TODO: этот мок надо убрать, скорее всего. пока вставил хардкодом
export const branchesWithNoFinances = [
  'London',
  'Tel-Aviv',
  'Milan',
  'London Hebrew',
  'Limassol',
  'Prague',
  'Budapest',
  'Madrid',
  'Geneva',
  'Berlin',
  'RG Worldwide',
  'Test',
  'LGOnline',
  'Brussels',
  'Jyvaskyla',
  'MilanRG',
  'Zurich',
  'Dublin',
  'Oxford',
  'Prime',
  'Toronto',
  'Camp',
  'LGFranchise'
];

export default {
  mockStudents,
  mockOriginalCourses,
  mockGroupsWorkProgress,
  mockFinances,
  mockCourse,
  mockGroupsTrainings,
  branchesWithNoFinances
};
