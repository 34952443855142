import React, { useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { Button, Table, Row, Modal } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container } from 'Global/GlobalStyle';
import { Link, useHistory } from 'react-router-dom';
import { getColumns } from './columns';
import { ASSESSMENT_STATUSES_NEW, BASE_ASSESSMENT_STATUSES } from '../routes';
import { AssessmentStatusesItem } from 'types/accessment';
import { useAssessmentsRepository } from 'repos/AssessmentsRepository';

export const AssessmentStatuses = () => {
  const [strings] = useLanguageContext();
  const assessmentsRepository = useAssessmentsRepository();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [assessmentStatuses, setAssessmentStatuses] = useState<AssessmentStatusesItem[]>([]);

  const { goBack, push } = useHistory();
  const [{ confirm }, contextHolder] = Modal.useModal();

  /**
   * @description Удалить Assessment Status
   * @param {object} params
   * @return {Promise<any>}
   */
  const handleDeleteStatus = (id: number): void => {
    confirm({
      title: strings.ARE_YOU_SURE,
      okType: 'danger',
      async onOk() {
        setHasLoading(true);
        try {
          await assessmentsRepository.deleteAssessmentStatus(id);
          fetchAssessmentStatuses();
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  const fetchAssessmentStatuses = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await assessmentsRepository.getAssessmentStatuses();
      setAssessmentStatuses(data);
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    fetchAssessmentStatuses();
  }, []);

  const getActionColumns = () => [
    {
      title: '',
      dataIndex: 'id',
      key: 'action',
      render: (id: number) => (
        <Row align="middle" style={{ gap: 5 }}>
          <Link to={`${BASE_ASSESSMENT_STATUSES}/${id}/edit`}>
            <Button icon={<EditOutlined />} />
          </Link>
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
              handleDeleteStatus(id);
            }}
          />
        </Row>
      )
    }
  ];

  return (
    <Container>
      {contextHolder}
      <PageHeader
        style={{ padding: 0, marginBottom: 20 }}
        onBack={goBack}
        title={strings.ASSESSMENT_STATUSES}
        extra={[
          <Button key="1" onClick={() => push(ASSESSMENT_STATUSES_NEW)}>
            {strings.ADD}
          </Button>
        ]}
      />
      <Table
        dataSource={assessmentStatuses}
        columns={[...getColumns(strings), ...getActionColumns()]}
        pagination={false}
        loading={{ spinning: hasLoading }}
      />
    </Container>
  );
};

export default { AssessmentStatuses };
