import React from 'react';
import * as parent from './routes';
import { User } from 'types/user';
import { hasMoscowBranch, hasInvoiceMonthFinanceSystem } from 'helpers';
import { HOW_TO_LG_LINK } from './routes';
import { MenuItem } from 'Global/components/MenuItem';
import {
  BookOutlined,
  CalendarOutlined,
  DollarOutlined,
  FileTextOutlined,
  InfoOutlined,
  QrcodeOutlined,
  TeamOutlined,
  ScheduleOutlined
} from '@ant-design/icons';
import { cleanPath } from '../Global/components/LeftMenu/helper';

/**
 * @description Функция формирования левого меню для родителя и студента
 * @return {array}
 */
export const menuListForPeople = (strings, user: User) => {
  const hasStudent = user?.hasRoles?.student;
  const branch = user?.branch;
  const isCabinetLocked = user?.isCabinetLocked;
  const manualBlock = user?.manualBlock;
  const hideParentFinance = branch?.hideParentFinance;

  const baseMenu: { key: number; title: React.ReactElement }[] = [
    {
      key: cleanPath(parent.SCHEDULE.substring(1)),
      title: <MenuItem title={strings.SCHEDULE} link={parent.SCHEDULE} icon={<ScheduleOutlined />} />
    },
    {
      key: cleanPath(parent.JOURNAL.substring(1)),
      title: <MenuItem title={strings.JOURNAL} link={parent.JOURNAL} icon={<TeamOutlined />} />
    },
    {
      key: cleanPath(parent.UPLOAD_HOMEWORK.substring(1)),
      title: <MenuItem title={strings.HOMEWORK} link={parent.UPLOAD_HOMEWORK} icon={<BookOutlined />} />
    },
    user?.hasRoles?.parent &&
      user?.activeContractAccepted &&
      branch?.id === 5 && {
        key: cleanPath(parent.CONTRACT.substring(1)),
        title: <MenuItem title={strings.CONTRACT} link={parent.CONTRACT} icon={<FileTextOutlined />} />
      }
  ];

  /** Родитель и студент заходят под своим аккаунтом в AMI, только для филиала Москва
  Видят пункт меню How to London Gates и Google calendar и инвойсы **/
  if (hasMoscowBranch(branch) && user?.menuHowToLG) {
    baseMenu.push({
      key: cleanPath(HOW_TO_LG_LINK.substring(1)),
      title: <MenuItem title={strings.HOW_TO_LG} isNativeLink={false} link={HOW_TO_LG_LINK} icon={<InfoOutlined />} />
    });
  }

  //  Студент не видит финансов и прогресс репорта
  if (hasStudent) {
    if (!isCabinetLocked) {
      return [...baseMenu];
    }
    return [];
  }

  // Если есть флаг invoiceMonthFinanceSystem И это родитель. Скрываем инвойсы для Риги
  if (hasInvoiceMonthFinanceSystem(branch) && branch?.id !== 5 && !hasStudent) {
    baseMenu.push({
      key: cleanPath(parent.INVOICES.substring(1)),
      title: <MenuItem title={strings.INVOICES} link={parent.INVOICES} icon={<QrcodeOutlined />} />
    });
  }

  /*
    Если у родителя стоит ручной блок, или залочен кабинет
    из-за минусового баланса показываем один пункт в меню финансы
    @link: https://app.clickup.com/t/74qtbh
  */
  if (manualBlock || isCabinetLocked) {
    return [
      {
        key: cleanPath(parent.BASE_FINANCE.substring(1)),
        title: <MenuItem title={strings.FINANCE} link={parent.BASE_FINANCE} icon={<DollarOutlined />} />
      }
    ];
  }

  // Если у родителя скрыты финансы, то не показываем пункт в меню
  return [
    ...baseMenu,
    !hideParentFinance && {
      key: cleanPath(parent.BASE_FINANCE.substring(1)),
      title: <MenuItem title={strings.FINANCE} link={parent.BASE_FINANCE} icon={<DollarOutlined />} />
    },
    {
      key: cleanPath(parent.BASE_REPORT_ON_PROGRESS.substring(1)),
      title: (
        <MenuItem
          title={strings.REPORT_ON_PROGRESS}
          link={parent.BASE_REPORT_ON_PROGRESS}
          icon={<CalendarOutlined />}
        />
      )
    }
  ];
};

export default { menuListForPeople };
