import React, { useState } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { Spin, Result, Checkbox, Flex } from 'antd';
import { SuggestedResults } from './SuggestedResults';
import { Results } from './Results';
import { Stats } from './Stats';
import { IParent, Admin, IStudent } from 'types';
import { Teacher } from 'types/Teachers/teacher';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { useUserContext } from 'contexts/UserContext';
import { useSearchContext } from 'contexts/SearchContext';
import { QuickTips } from '../QuickTips';
import { QUICK_TIPS_NEW_ACCOUNT } from '../../constants';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';

const StSearchResults = styled.div`
  margin: 20px;
  padding: 30px 20px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 0 46px -15px rgb(0 0 0 / 12%);
  min-height: calc(100vh - 85px); // Вычитаю размер шапки + 10px отступ
`;

interface SearchResultsProps {}

/**
 * @description Компонент результатов поиска
 * @param {object} props
 * @return {React.ReactNode}
 */
export const SearchResults = (props: SearchResultsProps) => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const meAdminId = user?.meAdminId;
  const { queryTerm, searchData, fetchSearchResults, isLoadSearchData } = useSearchContext();
  const { admins, teachers, children, parents, contracts } = searchData;
  const hasRoleTopManager = user?.hasRoles?.topManager;
  const suggestedTeachers = teachers?.filter((teacher: Teacher) => teacher?.tutor?.id === meAdminId);
  const suggestedStudents = children?.filter((child: IStudent) => child?.tutor?.id === meAdminId);
  const hasRiga = user?.branch?.id === 5;

  const { locationSearchParams } = useLocationSearchParams();
  const { showAll } = locationSearchParams || true;

  const countUsers = (admins: Admin[], parents: IParent[], teachers: Teacher[], students: IStudent[]): number => {
    const adminsCount = admins?.length || 0;
    const parentsCount = parents?.length || 0;
    const teachersCount = teachers?.length || 0;
    const studentsCount = students?.length || 0;

    return adminsCount + parentsCount + teachersCount + studentsCount;
  };

  const totalCount = countUsers(admins, parents, teachers, children);

  const handleShowAll = ({ target: { checked } }): void => {
    fetchSearchResults(queryTerm, checked);
  };

  /**
   * @description Обновить данные юзеров
   * @returns {void}
   */
  const handleUserRefetch = (): void => {
    fetchSearchResults(queryTerm, true);
  };

  return (
    <ErrorBoundary>
      <Helmet>
        <title>AMI: Search</title>
      </Helmet>
      <StSearchResults>
        <Spin spinning={isLoadSearchData} tip={strings.LOADING_SEARCH_RESULT} size="large">
          <div style={{ minHeight: isLoadSearchData ? 'calc(100vh - 145px)' : '100%' }}>
            {totalCount > 0 && (
              <>
                <Stats
                  admins={admins}
                  parents={parents}
                  students={children}
                  teachers={teachers}
                  contracts={contracts}
                  totalCount={totalCount}
                  hasRoleTopManager={hasRoleTopManager}
                  isLoadSearchData={isLoadSearchData}
                />
                <SuggestedResults
                  teachers={suggestedTeachers}
                  students={suggestedStudents}
                  handleUserRefetch={handleUserRefetch}
                />
              </>
            )}
            <Flex style={{ marginBottom: '1rem', gap: 20 }}>
              {!hasRiga && <QuickTips label={strings.QUICK_TIPS} url={QUICK_TIPS_NEW_ACCOUNT} />}
              <Checkbox onChange={handleShowAll} checked={showAll} style={{ alignItems: 'center' }}>
                {strings.SHOW_ALL_USERS}
              </Checkbox>
            </Flex>
            {totalCount > 0 && (
              <>
                <Results
                  admins={admins}
                  teachers={teachers}
                  parents={parents}
                  students={children}
                  countUsers={countUsers}
                  handleUserRefetch={handleUserRefetch}
                />
              </>
            )}
          </div>
          {!isLoadSearchData && totalCount === 0 && <Result title="Sorry, nothing found :(" />}
        </Spin>
      </StSearchResults>
    </ErrorBoundary>
  );
};

export default { SearchResults };
