import React, { useState, useEffect, useMemo, useCallback } from 'react';
import pickBy from 'lodash/pickBy';
import { Select, DatePicker, Row, Col, Checkbox, Form } from 'antd';
import { dateFormat } from 'helpers/dates';
import { momentFormatValues } from 'helpers/momentFormatValues';
import { booleanFormatValues } from 'helpers/booleanFomatValues';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Branch, Product, Status } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { FilterButtons } from 'Global/components/FilterButtons';
import { ReportFinanceFamily, ReportFinanceFamilyApi } from 'types/Family';
import { FormItemWithoutMargin } from 'Global/styles';
import dayjs from 'dayjs';

const { Option } = Select;

interface FamilyFinanceFilterProps {
  fetchReportFinance: (params?: ReportFinanceFamilyApi) => Promise<any>;
  setProducts: (val: Product[]) => void;
  setFamilies: (val: ReportFinanceFamily[]) => void;
  defValues: ReportFinanceFamilyApi;
}

/**
 * @description Фильтр мониторинг финансов учителя
 * @param {FamilyFinanceFilterProps} props
 * @return {React.ReactNode}
 */
const Filter: React.FC<FamilyFinanceFilterProps> = ({ fetchReportFinance, setProducts, setFamilies, defValues }) => {
  const [form] = Form.useForm();
  const [strings] = useLanguageContext();
  const { branches, familyStatuses } = useGlobalCollectionsContext();
  const { locationSearchParams } = useLocationSearchParams();
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);

  const clearForm = useCallback(async (): Promise<any> => {
    form.resetFields();
    setProducts([]);
    setFamilies([]);
    await fetchReportFinance(defValues);
  }, [fetchReportFinance]);

  const handleSubmit = useCallback(async values => {
    try {
      const formatValues = momentFormatValues(pickBy(booleanFormatValues(values)));
      setHasSubmitLoading(true);
      await fetchReportFinance(formatValues);
    } finally {
      setHasSubmitLoading(false);
    }
  }, []);

  useEffect(() => {
    const { end, start } = locationSearchParams;
    const initValues = {
      ...locationSearchParams,
      end: end && dayjs(end),
      start: start && dayjs(start)
    };
    form.setFieldsValue(initValues);
  }, [locationSearchParams]);

  const sortedFamilyStatuses = useMemo(() => {
    if (familyStatuses) {
      return familyStatuses.sort((a, b) => a.name.localeCompare(b.name));
    }
    return [];
  }, [familyStatuses]);

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Row gutter={[10, 10]} align="middle">
        <Col xs={24} lg={4}>
          <FormItemWithoutMargin name="start">
            <DatePicker format={dateFormat} />
          </FormItemWithoutMargin>
        </Col>
        <Col xs={24} lg={4}>
          <FormItemWithoutMargin name="end">
            <DatePicker format={dateFormat} />
          </FormItemWithoutMargin>
        </Col>
        <Col xs={24} lg={6}>
          <FormItemWithoutMargin name="branch">
            <Select size="large" placeholder={strings.BRANCH}>
              {branches.map((branch: Branch) => (
                <Option key={branch.id} value={branch.id}>
                  {branch.name}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col xs={24} lg={6}>
          <FormItemWithoutMargin name="status">
            <Select placeholder={strings.STATUS} mode="multiple" maxTagCount={1}>
              {sortedFamilyStatuses.map((status: Status) => (
                <Option key={status.id} value={status.id}>
                  {status.name}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col xs={24} lg={4}>
          <FormItemWithoutMargin name="doubts" valuePropName="checked">
            <Checkbox>{strings.ERROR_REPORTS}</Checkbox>
          </FormItemWithoutMargin>
        </Col>
      </Row>
      <FilterButtons handleClear={clearForm} hasSubmitLoading={hasSubmitLoading} />
    </Form>
  );
};

const FilterMemo = React.memo(Filter);

export default FilterMemo;
