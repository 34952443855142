import React, { useMemo, useState } from 'react';
import { TutorSelect } from 'Global/components/FormComponentsCompatible/Selects/TutorSelect';
import { Form } from '@ant-design/compatible';
import { Row, Col, Select, Input, Button, InputNumber } from 'antd';
import { useHistory } from 'react-router-dom';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { ACTIVE_CLIENT_FAMILY_STATUS } from 'Global/constants';
import { maxTagPlaceholder } from 'helpers';
import FamilyService from 'helpers/services/FamilyService';
import { FamiliesFilterParams } from 'types/Family';
import { Branch } from 'types';
import { useUserContext } from 'contexts/UserContext';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { FilterButtons } from 'Global/components/FilterButtons';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';

const { Option } = Select;

interface FamilyFilterProps extends FormComponentProps {
  setCurrentFilter: (fields: FamiliesFilterParams) => void;
  getFamilies: (params?: FamiliesFilterParams) => Promise<any>;
}

/**
 * @description Фильтр списка семей
 * @param {FamilyFilterProps} props
 * @return {React.ReactNode}
 */
const FilterTemplate = (props: FamilyFilterProps) => {
  const {
    form: { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldsValue },
    getFamilies
  } = props;

  const { push } = useHistory();
  const { branches, familyStatuses, familyTags: familyTagsList } = useGlobalCollectionsContext();
  const [user] = useUserContext();
  const [strings] = useLanguageContext();

  const sortedFamilyStatuses = useMemo(() => {
    if (familyStatuses) {
      return familyStatuses.sort((a, b) => a.name.localeCompare(b.name));
    }
    return [];
  }, [familyStatuses]);

  const family = new FamilyService();
  const { locationSearchParams } = useLocationSearchParams();
  const hasTopManagerRole = user?.hasRoles?.topManager;

  const { branch, learnedFrom, contactLaterPeriod, tutor, status, balance_from, balance_to, address, familyTags } =
    locationSearchParams || '';
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);

  // Очистка формы
  const handleClear = async (): Promise<any> => {
    resetFields();
    push({ search: '' });
    await getFamilies();
  };

  // Получение семей
  const fetchFamilies = values => {
    const limit = FamilyService.limit;
    const { orderby, direction } = locationSearchParams;
    const params = family.getFilterParams({ ...values, orderby, direction }, 1, null, limit);
    getFamilies(params);
  };

  /**
   * @description Отправка формы
   * @param {React.FormEvent} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFields(async (err, values): Promise<any> => {
      if (!err) {
        try {
          setHasSubmitLoading(true);
          await fetchFamilies(values);
        } catch {
        } finally {
          setHasSubmitLoading(false);
        }
      }
    });
  };

  // Фильтр My Active для админа
  const handleActiveFamilies = () => {
    const { isTutorActiveFamilies } = user;

    if (isTutorActiveFamilies) {
      setFieldsValue({
        status: [String(ACTIVE_CLIENT_FAMILY_STATUS)],
        tutor: user?.meAdminId
      });
    } else {
      setFieldsValue({
        status: [String(ACTIVE_CLIENT_FAMILY_STATUS)]
      });
    }
    const fields = getFieldsValue();
    fetchFamilies(fields);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col>
          <Button onClick={handleActiveFamilies} size="small" style={{ fontSize: 14 }}>
            {strings.FILTER_BY_MY_ACTIVE}
          </Button>
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        {hasTopManagerRole && (
          <Col xs={24} lg={6}>
            {getFieldDecorator('branch', {
              initialValue: branch && Number(branch),
              rules: [{ required: false }]
            })(
              <Select placeholder={strings.BRANCH} allowClear>
                {branches.map((branch: Branch) => (
                  <Option value={branch.id} key={branch.id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            )}
          </Col>
        )}
        <Col xs={24} lg={6}>
          {getFieldDecorator('status', {
            initialValue: status,
            rules: [{ required: false }]
          })(
            <Select mode="multiple" maxTagCount="responsive" placeholder={strings.STATUS}>
              {sortedFamilyStatuses?.map(status => (
                <Option value={String(status.id)} key={status.id}>
                  {status.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col xs={24} lg={6}>
          {getFieldDecorator('familyTags', {
            initialValue: familyTags,
            rules: [{ required: false }]
          })(
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              tokenSeparators={[',']}
              placeholder={strings.TAGS}
              maxTagCount={0}
              maxTagPlaceholder={maxTagPlaceholder}
            >
              {familyTagsList?.map(tag => (
                <Option value={String(tag.id)} key={tag.id}>
                  {tag.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col xs={24} lg={6}>
          <TutorSelect initTutor={tutor} getFieldDecorator={getFieldDecorator} name="tutor" className="no-form-item" />
        </Col>
        <Col xs={24} lg={6}>
          {getFieldDecorator('learnedFrom', {
            initialValue: learnedFrom,
            rules: [{ required: false }]
          })(
            <Select placeholder={strings.SOURCE_OF_REFERENCE}>
              <Option value="">&nbsp;</Option>
              <Option value="12">Мероприятие</Option>
              <Option value="13">Другое</Option>
              <Option value="22">Друзья, коллеги</Option>
              <Option value="23">Сотрудник LGEG</Option>
              <Option value="24">Интернет</Option>
              <Option value="25">Социальные сети</Option>
              <Option value="26">СМИ (радио, журнал, ТВ)</Option>
            </Select>
          )}
        </Col>
        <Col xs={24} lg={4}>
          {getFieldDecorator('balance_from', {
            initialValue: balance_from,
            rules: [{ required: false }]
          })(<InputNumber placeholder={strings.BALANCE_FROM} />)}
        </Col>
        <Col xs={24} lg={4}>
          {getFieldDecorator('balance_to', {
            initialValue: balance_to,
            rules: [{ required: false }]
          })(<InputNumber placeholder={strings.BALANCE_TO} />)}
        </Col>
        <Col xs={24} lg={4}>
          {getFieldDecorator('contactLaterPeriod', {
            initialValue: contactLaterPeriod,
            rules: [{ required: false }]
          })(
            <Select placeholder={strings.CONTACT_LATER_PERIOD}>
              <Option value="">&nbsp;</Option>
              <Option value="lastPeriod">{strings.PAST}</Option>
              <Option value="nextPeriod">{strings.FUTURE}</Option>
              <Option value="today">{strings.TODAY}</Option>
            </Select>
          )}
        </Col>
        <Col xs={24} lg={6}>
          {getFieldDecorator('address', {
            initialValue: address,
            rules: [{ required: false }]
          })(<Input placeholder={strings.ADDRESS} />)}
        </Col>
      </Row>
      <FilterButtons handleClear={handleClear} hasSubmitLoading={hasSubmitLoading} />
    </Form>
  );
};

export const Filter = Form.create<FamilyFilterProps>({
  onValuesChange: (props: FamilyFilterProps, changedValues, allValues) => {
    const { setCurrentFilter } = props;
    setCurrentFilter({ ...allValues, ...changedValues });
  }
})(FilterTemplate);

export default { Filter };
