import React, { useState } from 'react';
import { Modal, Button, Form, Flex } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import TextArea from 'antd/es/input/TextArea';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';
import { FeedbackType } from 'types/Reports';

interface EditCommentsModalProps {
  hasShowModal: boolean;
  hasNeedApprove?: boolean;
  setHasShowModal: (hasShow: boolean) => void;
  refetchReports: () => void;
  type: FeedbackType;
  registerId: number;
  commentForStudent?: string;
  commentForParent?: string;
  commentForParent2?: string;
}

export const EditCommentsModal = ({
  hasShowModal,
  setHasShowModal,
  type,
  registerId,
  commentForParent,
  commentForParent2,
  commentForStudent,
  refetchReports,
  hasNeedApprove
}: EditCommentsModalProps) => {
  const [strings] = useLanguageContext();
  const [form] = Form.useForm();
  const reportsRepository = useProgressReportsRepository();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const handleSubmit = async (values): Promise<any> => {
    setHasLoading(true);
    try {
      await reportsRepository.editRegisterComment(registerId, { type, ...values });
      if (hasNeedApprove) {
        await reportsRepository.registerCommentApprove(registerId, type);
      }
      refetchReports();
      setHasShowModal(false);
    } finally {
      setHasLoading(false);
    }
  };
  const handleClose = () => {
    setHasShowModal(false);
    form.resetFields();
  };

  return (
    <Modal
      title={strings.EDIT_COMMENT}
      open={hasShowModal}
      onCancel={handleClose}
      destroyOnClose={true}
      footer={null}
      width={600}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        {type === 'student' && (
          <Form.Item name="comment" label={strings.COMMENT} initialValue={commentForStudent}>
            <TextArea placeholder={strings.COMMENT} />
          </Form.Item>
        )}
        {type === 'parent' && (
          <>
            <Form.Item
              name="comment"
              label={strings.OVERALL_PROGRESS_SPECIFIC_DETAILS_1_2}
              initialValue={commentForParent}
            >
              <TextArea placeholder={strings.COMMENT} />
            </Form.Item>
            <Form.Item name="comment2" label={strings.GOAL_WAY_TO_ACHIEVE_IT} initialValue={commentForParent2}>
              <TextArea placeholder={strings.COMMENT} />
            </Form.Item>
          </>
        )}
        <Flex justify="end">
          <Button key="addBtn" type="primary" htmlType="submit" loading={hasLoading}>
            {strings.SUBMIT}
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};
export default { EditCommentsModal };
