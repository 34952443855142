import React, {useRef, useEffect} from 'react';
import { Message } from './Message';
import { Thread, Message as IMessage } from 'types/ParentJournal';
import { useUserContext } from 'contexts/UserContext';
import { IW_KEY } from 'Admin/Translations';
import { useLanguageContext } from 'contexts/LanguageContext';

interface ListMessagesProps {
  thread: Thread;
}

/**
 * @description Сообщения чата
 * @param {ListMessagesProps} props
 * @return {React.ReactNode}
 */
export const ListMessages = ({ thread }: ListMessagesProps) => {
  const [user] = useUserContext();
  const [, lang] = useLanguageContext();

  const messagesContainer = useRef<HTMLDivElement>(null);
  const getName = sender => {
    return lang === IW_KEY && sender?.nameHebrew ? sender?.nameHebrew : sender?.name;
  };
  const getSurname = sender => {
    return lang === IW_KEY && sender?.surnameHebrew ? sender?.surnameHebrew : sender?.surname;
  };

  // Скролл чата в самый низ
  const scrollDownChat = () => {
    if (messagesContainer.current) {
      const scroll = messagesContainer.current;
      scroll.scrollTop = scroll.scrollHeight;
      if (scroll.hasOwnProperty('animate')) scroll.animate({ scrollTop: scroll.scrollHeight });
    }
  };

  useEffect(() => {
    if (thread) {
      scrollDownChat();
    }
  }, [thread]);

  return (
    <>
      {thread?.messages.map((message: IMessage) => (
        <Message
          key={message.id}
          message={message}
          name={getName(message.sender)}
          surname={getSurname(message.sender)}
          reverse={user?.id === message.sender.id}
          photo={thread?.parent?.mainPhoto || thread?.teacher?.mainPhoto}
        />
      ))}
    </>
  );
};

export default { ListMessages };
