export const columns = strings => [
  {
    title: strings.ID,
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: strings.BRANCH,
    dataIndex: 'branch',
    key: 'branch'
  },
  {
    title: strings.TITLE,
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: strings.TARGET_GROUP,
    dataIndex: 'targetGroup',
    key: 'targetGroup'
  },
  {
    title: strings.DATE,
    dataIndex: 'date',
    key: 'date'
  },
  {
    title: strings.REGISTRED,
    dataIndex: 'registred',
    key: 'registred'
  }
];

export default { columns };
