import React from 'react';
import { Button, Form, FormInstance, Row, Flex, Card } from 'antd';
import styled from 'styled-components';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { ParentForm } from './ParentForm';

export const StRow = styled(Row)`
  flex: 1;
  width: 100%;
`;

interface IAddParentsProps {
  familyBranch?: number;
  form?: FormInstance;
}

export const AddParents: React.FC<IAddParentsProps> = ({ familyBranch }) => {
  return (
    <>
      <Form.List name="parents">
        {(fields, { add, remove }) => (
          <Flex vertical gap={20}>
            {fields.map((field, index) => (
              <Flex gap={10}>
                {fields.length > 1 && (
                  <Button type="primary" shape="circle" icon={<MinusOutlined />} onClick={() => remove(field.name)} />
                )}
                <Card key={field.key}>
                  <ParentForm familyBranch={familyBranch} field={field} />
                </Card>
              </Flex>
            ))}
            <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={() => add()} />
          </Flex>
        )}
      </Form.List>
    </>
  );
};
export default { AddParents };
