import dayjs from 'dayjs';
import { dateFormat } from 'helpers/dates';
import { ScheduleLogCreatedBy } from 'types/Schedule';

export const columns = strings => [
  {
    title: strings.CONTENT,
    dataIndex: 'content'
  },
  {
    title: strings.DATE,
    dataIndex: 'createdAt',
    render: (createdAt: string) => createdAt && dayjs(createdAt).format(dateFormat)
  },
  {
    title: strings.USER,
    dataIndex: 'createdBy',
    render: (createdBy: ScheduleLogCreatedBy) => createdBy?.name ?? '-'
  }
];

export default { columns };
