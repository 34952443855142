import React, { SyntheticEvent, useState } from 'react';
import { isEmpty } from 'lodash';
import { CloseOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Input, Button, Col, Row, Tag, Empty, Spin, message as antMessage, TimePicker, Checkbox } from 'antd';
import { Sms } from 'api';
import { useLanguageContext } from 'contexts/LanguageContext';
import styled from 'styled-components';
import { DatePicker } from 'antd/lib';
import { dateFormat } from 'helpers';
import dayjs, { Dayjs } from 'dayjs';
import { dateTimeFormatForBackend } from 'helpers/dates';

const StContactBlock = styled(Row)`
  overflow: auto;
  height: 180px;
  background: #f5f5f5;
  border-radius: 5px;
`;

export interface IRecipient {
  id: number;
  name: string;
  surname: string;
  smsSubscribed?: boolean;
  parents?: any[];
}
interface ISmsForm {
  currentPeople: string;
  recipientsList: IRecipient[];
  setRecipientsList: (value: IRecipient[]) => void;
  setLoading: (value: boolean) => void;
  hasRecipientsLoading: boolean;
}

/**
 * @description Компонент отправки смс
 * @param {object} props - component props
 */
export const SmsForm: React.FC<ISmsForm> = ({
  currentPeople,
  recipientsList,
  setRecipientsList,
  setLoading,
  hasRecipientsLoading
}) => {
  const [strings] = useLanguageContext();
  const [phones, setPhones] = useState<string>('');
  const [testPhones, setTestPhones] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [hasPendingSms, setHasPendingSms] = useState<boolean>(false);
  const [sendDate, setSendDate] = useState<Dayjs>(dayjs());
  const [sendTime, setSendTime] = useState<Dayjs>(dayjs().add(10, 'minute'));

  const clearRecipients = () => setRecipientsList([]);

  const hasStudent = currentPeople === 'students';
  const hasActiveSendButton = (recipientsList.isNotEmpty() || phones.length) !== 0 && message.length !== 0;
  const hasActiveTestSendButton = testPhones.length !== 0 && message.length !== 0;

  const sendFormDistribution = async (params: object): Promise<any> => {
    try {
      await Sms.sendFormDistribution(params);
      antMessage.success(strings.MESSAGES_HAVE_BEEN_SENT_SUCCESSFULLY);
      setPhones('');
      setMessage('');
      clearRecipients();
    } catch (e) {
      antMessage.error(strings.ERROR_OCCURRED_DURING_SENDING_MESSAGES);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent<EventTarget>): Promise<any> => {
    e.preventDefault();
    setLoading(true);
    let filteredRecipients = recipientsList;
    if (currentPeople === 'parents') filteredRecipients = recipientsList.filter(recipient => recipient.smsSubscribed);
    const params = {
      userType: currentPeople,
      message: message,
      phones: phones.split('\n')
    };
    if (hasPendingSms) {
      params['sendAt'] = sendDate.hour(sendTime.hour()).minute(sendTime.minute()).format(dateTimeFormatForBackend);
    }
    if (filteredRecipients) {
      params['ids'] = filteredRecipients.map(recipient => recipient.id);
    }
    await sendFormDistribution(params);
  };

  /**
   * @description Тестовая отправка смс
   * @return {Promise<any>}
   */
  const handleSendTestSms = async (e: React.FormEvent<EventTarget>): Promise<any> => {
    e.preventDefault();
    setLoading(true);
    const params = {
      message: message,
      phones: [testPhones]
    };
    if (hasPendingSms) {
      params['sendAt'] = sendDate.hour(sendTime.hour()).minute(sendTime.minute()).format(dateTimeFormatForBackend);
    }
    try {
      await Sms.sendFormDistribution(params);
      antMessage.success(strings.MESSAGES_HAVE_BEEN_SENT_SUCCESSFULLY);
    } catch (e) {
      antMessage.error(strings.ERROR_OCCURRED_DURING_SENDING_MESSAGES);
    } finally {
      setLoading(false);
    }
  };

  const handleSendParentsToStudents = async (): Promise<void> => {
    const parentIds = [];
    recipientsList
      .map(recipient => recipient.parents.filter(parent => parent.smsSubscribed).map(parent => parent.id))
      .map(ids => ids.map(parentId => parentIds.push(parentId)));

    const params = {
      userType: 'parents',
      message: message,
      ids: parentIds,
      phones: phones.split('\n')
    };
    if (hasPendingSms) {
      params['sendAt'] = sendDate.hour(sendTime.hour()).minute(sendTime.minute()).format(dateTimeFormatForBackend);
    }
    await sendFormDistribution(params);
  };

  const handleDeleteParticipant = (id: number): void => {
    const filteredList = recipientsList.filter(item => item.id !== id);
    setRecipientsList(filteredList);
  };

  const handleMessageChange = (e: SyntheticEvent): void => {
    const { value } = e.target as HTMLInputElement;
    setMessage(value);
  };

  const handlePhonesChange = (e: SyntheticEvent): void => {
    const { value } = e.target as HTMLInputElement;
    setPhones(value);
  };

  const messageCount = message.length > 500;

  const placeholder = `${strings.PASTE_PHONES_WITH_LINE_BREAK_AS_A_SEPARATOR_FOR_EXAMPLE}:
  791133322299
  791133322288
  791133322277
  etc`;

  return (
    <Form layout="vertical">
      <Row gutter={[10, 10]}>
        <Col lg={12} xs={24}>
          <Row gutter={[10, 10]}>
            <Col lg={24} xs={24}>
              <MyFormItem label={strings.PHONES} style={{ margin: 0 }}>
                <Input.TextArea
                  value={phones}
                  onChange={handlePhonesChange}
                  placeholder={placeholder}
                  autoSize={{ minRows: 4 }}
                />
              </MyFormItem>
            </Col>
            <Col lg={8} xs={24}>
              <Checkbox
                onChange={e => setHasPendingSms(e.target.checked)}
                style={{ fontSize: 16, height: 40, display: 'flex', alignItems: 'center' }}
              >
                {strings.PENDING_SMS}
              </Checkbox>
            </Col>
            {hasPendingSms && (
              <>
                <Col lg={8} xs={24}>
                  <DatePicker
                    value={sendDate}
                    onChange={val => setSendDate(val)}
                    format={dateFormat}
                    allowClear={false}
                  />
                </Col>
                <Col lg={8} xs={24}>
                  <TimePicker
                    value={sendTime}
                    allowClear={false}
                    onChange={val => setSendTime(val)}
                    format={'HH:mm'}
                    needConfirm={false}
                  />
                </Col>
              </>
            )}
          </Row>
        </Col>
        <Col lg={12} xs={24}>
          <Row gutter={[10, 10]}>
            <Col lg={24} xs={24}>
              <Spin spinning={hasRecipientsLoading}>
                <MyFormItem label={strings.RECIPIENTS} style={{ margin: 0 }}>
                  {isEmpty(recipientsList) ? (
                    <Empty style={{ margin: 'auto' }} />
                  ) : (
                    <StContactBlock>
                      {recipientsList.map(recipient => {
                        if (recipient.name) {
                          return (
                            <Tag
                              key={recipient.id}
                              color={`${currentPeople === 'parents' && !recipient.smsSubscribed ? 'red' : 'blue'}`}
                              style={{ margin: '10px 5px', fontSize: 14 }}
                            >
                              <CloseOutlined onClick={() => handleDeleteParticipant(recipient.id)} />
                              {recipient.name} {recipient.surname}
                              {`${currentPeople === 'parents' && !recipient.smsSubscribed ? '(blocked)' : ''}`}
                            </Tag>
                          );
                        }
                      })}
                    </StContactBlock>
                  )}
                </MyFormItem>
              </Spin>
            </Col>
          </Row>
        </Col>
        <Col lg={24} xs={24}>
          <MyFormItem
            label={strings.MESSAGE}
            style={{ margin: 0 }}
            validateStatus={messageCount && 'error'}
            help={messageCount && strings.YOUR_MESSAGE_IS_TOO_LONG_THE_SYMBOL_LIMIT_IS_500}
          >
            <Input.TextArea
              value={message}
              onChange={handleMessageChange}
              placeholder={strings.MESSAGE}
              autoSize={{ minRows: 4 }}
            />
          </MyFormItem>
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
        <Col lg={12} xs={24} style={{ display: 'flex', gap: 10 }}>
          <Button
            type="primary"
            disabled={!hasActiveSendButton || messageCount}
            htmlType="submit"
            size="large"
            onClick={handleSubmit}
          >
            {strings.SEND}
          </Button>
          {hasStudent && (
            <Button
              type="primary"
              disabled={!hasActiveSendButton || messageCount}
              htmlType="submit"
              size="large"
              onClick={handleSendParentsToStudents}
            >
              {strings.SEND_PARENTS_TO_STUDENTS}
            </Button>
          )}
        </Col>
        <Col lg={12} xs={24} style={{ display: 'flex', gap: 10 }}>
          <Button type="primary" disabled={!hasActiveTestSendButton} size="large" onClick={handleSendTestSms}>
            {strings.SEND_TEST_SMS}
          </Button>
          <Input onChange={({ target: { value } }) => setTestPhones(value)} placeholder={strings.PHONE} />
        </Col>
      </Row>
    </Form>
  );
};
export default { SmsForm };
