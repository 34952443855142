import React, { useState, useEffect } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { Button, Input, Table, Row, Col, Popconfirm } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container } from 'Global/GlobalStyle';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import { useHistory } from 'react-router-dom';

export function PriceGroup() {
  const [strings] = useLanguageContext();
  const globalRequestsRepository = useGlobalRequestsRepository();
  const { goBack } = useHistory();
  const [editableId, setEditableId] = useState<number>(null);
  const [deletedId, setDeletedId] = useState<number>(null);
  const [newName, setNewName] = useState<string>('');
  const [newGroup, setNewGroup] = useState<string>('');
  const [hasAddLoading, setHasAddLoading] = useState<boolean>(false);
  const [hasEditLoading, setHasEditLoading] = useState<boolean>(false);
  const [hasDeleteLoading, setHasDeleteLoading] = useState<boolean>(false);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [priceGroupList, setPriceGroupList] = useState<{ id: number; name: string }[]>([]);

  const fetchPriceGroup = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await globalRequestsRepository.getPriceGroupList();
      setPriceGroupList(data);
    } finally {
      setHasLoading(false);
    }
  };

  const addPriceGroup = async (): Promise<any> => {
    setHasAddLoading(true);
    try {
      await globalRequestsRepository.addPriceGroup({ name: newGroup });
      setNewGroup('');
      fetchPriceGroup();
    } finally {
      setHasAddLoading(false);
    }
  };

  const editPriceGroup = async (): Promise<any> => {
    setHasEditLoading(true);
    try {
      await globalRequestsRepository.editPriceGroup(editableId, { name: newName });
      fetchPriceGroup();
      setEditableId(null);
      setNewName('');
    } finally {
      setHasEditLoading(false);
    }
  };

  const deletePriceGroup = async (id): Promise<any> => {
    setDeletedId(id);
    setHasDeleteLoading(true);
    try {
      await globalRequestsRepository.deletePriceGroup(id);
      fetchPriceGroup();
    } finally {
      setHasDeleteLoading(false);
      setDeletedId(null);
    }
  };

  const columns = [
    {
      title: strings.NAME,
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => {
        return editableId !== record?.id ? (
          <p>{name}</p>
        ) : (
          <Input
            defaultValue={name}
            style={{ margin: 0 }}
            onChange={({ target: { value } }) => {
              setNewName(value);
            }}
          />
        );
      }
    },
    {
      title: '',
      dataIndex: 'id',
      width: 200,
      render: (id, record) => {
        return editableId !== id ? (
          <div style={{ display: 'flex', gap: 10 }}>
            <Button
              disabled={editableId !== null}
              onClick={() => {
                setEditableId(id);
              }}
            >
              {strings.EDIT}
            </Button>
            <Popconfirm
              title={strings.ARE_YOU_SURE}
              onConfirm={() => deletePriceGroup(id)}
              okText={strings.YES}
              cancelText={strings.NO}
            >
              <Button type="primary" loading={hasDeleteLoading && deletedId === id} disabled={editableId !== null}>
                {strings.DELETE}
              </Button>
            </Popconfirm>
          </div>
        ) : (
          <div style={{ display: 'flex', gap: 10 }}>
            <Button loading={hasEditLoading} disabled={newName.length === 0} onClick={editPriceGroup}>
              {strings.SAVE}
            </Button>
            <Button
              onClick={() => {
                setEditableId(null);
                setNewName('');
              }}
            >
              {strings.CANCEL}
            </Button>
          </div>
        );
      }
    }
  ];

  useEffect(() => {
    fetchPriceGroup();
  }, []);

  return (
    <Container>
      <PageHeader style={{ padding: 0, marginBottom: 20 }} onBack={goBack} title={strings.PRICE_GROUP}>
        <Row gutter={10}>
          <Col lg={6}>
            <Input
              value={newGroup}
              placeholder={strings.NAME}
              onChange={({ target: { value } }) => setNewGroup(value)}
              style={{ margin: 0 }}
            />
          </Col>
          <Col lg={4}>
            <Button size="large" disabled={newGroup.length === 0} onClick={addPriceGroup} loading={hasAddLoading}>
              {strings.ADD}
            </Button>
          </Col>
        </Row>
      </PageHeader>
      <Table loading={hasLoading} dataSource={priceGroupList} rowKey="id" columns={columns} pagination={false} />
    </Container>
  );
}
