import React from 'react';
import { Button, Modal, Input } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';

/**
 * @param {object} props
 * @return {React.ReactNode}
 */
export const AddUnit = (props: any) => {
  const { show, cancel } = props;
  const [strings] = useLanguageContext();
  return (
    (<Modal
      title={strings.CREATE_A_NEW_UNIT}
      footer={[
        <Button key="back" onClick={cancel}>
          Cancel
        </Button>
      ]}
      open={show}
      onCancel={cancel}
    >
      <Input placeholder={strings.NAME} />
      <Button type="primary" style={{ marginTop: '20px' }}>
        Create
      </Button>
    </Modal>)
  );
};

export default { AddUnit };
