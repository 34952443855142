import React, { useMemo } from 'react';
import { httpService } from 'api/core';
import { IPerformerParams, IRateParams, ITeacherWagesFilterParams, ITeacherWagesParams } from '../types/finance';
import { PricesParams, SearchParams } from '../types/API/global';
import { IStagesGetParams } from '../types/global';

const GlobalRequestsRepositoryContext = React.createContext<IGlobalRequestsRepository>(null);
interface IGlobalRequestsRepository {
  getRates: (params: PricesParams) => Promise<any>;
  getRateById: (id: number | string) => Promise<any>;
  createRate: (params: IRateParams) => Promise<any>;
  editRate: (id: number | string, params: IRateParams) => Promise<any>;
  getPerformers: (params: IPerformerParams) => Promise<any>;
  uploadAmiFile: (uploadData) => Promise<any>;
  uploadExcelFile: (uploadData) => Promise<any>;
  getGlobalCollections: () => Promise<any>;
  getRoles: () => Promise<any>;
  getProducts: (branch?: number | string) => Promise<any>;
  getOrgOneData: () => Promise<any>;
  sendAuthSms: (params) => Promise<any>;
  authSmsCheck: (params) => Promise<any>;
  uploadAvatar: (params) => Promise<any>;
  uploadAttach: (params: FormData) => Promise<any>;
  search: (query: string, params?: SearchParams) => Promise<any>;
  getTeacherWages: (params: ITeacherWagesFilterParams) => Promise<any>;
  getTeacherWageById: (id: number | string) => Promise<any>;
  createTeacherWage: (params: ITeacherWagesParams) => Promise<any>;
  editTeacherWage: (id: number | string, params: ITeacherWagesParams) => Promise<any>;
  deleteTeacherWage: (id: number | string) => Promise<any>;
  deactivateRates: (params: { prices: number[] }) => Promise<any>;
  getPriceGroupList: () => Promise<any>;
  getPriceGroupById: (id: number | string) => Promise<any>;
  addPriceGroup: (params: { name: string }) => Promise<any>;
  editPriceGroup: (id: number | string, params: { name: string }) => Promise<any>;
  deletePriceGroup: (id: number | string) => Promise<any>;
  getJwtTokenByNN: (authCode: string) => Promise<any>;
}

class GlobalRequestsRepository implements IGlobalRequestsRepository {
  getRates(params: PricesParams) {
    return httpService.get('/api/v1/prices', params, null, true);
  }

  getRateById(id: number | string) {
    return httpService.get(`/api/v1/prices/${id}`, null, null, true);
  }

  createRate(params: IRateParams) {
    return httpService.post(`/api/v1/prices`, params, null, null, true);
  }

  editRate(id: number | string, params: IRateParams) {
    return httpService.put(`/api/v1/prices/${id}`, params, null, null, true);
  }

  getPerformers(params: IPerformerParams) {
    return httpService.get(`/api/v1/performers`, params, null, true);
  }

  uploadAmiFile(uploadData) {
    return httpService.post('/api/v1/upload', uploadData, null, true, true);
  }

  getGlobalCollections() {
    return httpService.get('/api/v1/adminGlobalCollections', null, null, true);
  }

  getRoles() {
    return httpService.get(`/api/v1/access/role`, null, null, true);
  }

  uploadExcelFile(uploadData) {
    return httpService.post('/api/v1/prices/load', uploadData, null, true, true);
  }

  getProducts(branch?: number | string) {
    return httpService.get(`/api/v1/products`, branch ? { branch } : null, null, true);
  }

  getOrgOneData() {
    return httpService.get('/api/v1/org1/data', null, null, true, false);
  }

  sendAuthSms(data) {
    return httpService.post('/api/login/sms', data);
  }

  authSmsCheck(data) {
    return httpService.post('/api/login/sms/check', data);
  }

  uploadAvatar(params) {
    return httpService.post('/api/v1/upload/avatar', params, null, true, true);
  }

  uploadAttach(params: FormData) {
    return httpService.post('/api/v1/attachment/upload', params, null, true, true);
  }

  search(query: string, params?: SearchParams) {
    return httpService.get(`/api/v1/peoples/search`, { query: query, ...params }, null, true);
  }
  /**
   * @description Список зарплат учителей
   * @param {object} params
   * @return {Promise<any>}
   */
  getTeacherWages(params: ITeacherWagesFilterParams) {
    return httpService.get('/api/v1/costs', params, null, true);
  }

  /**
   * @description Получение wage учителя по id
   * @param {number} id
   * @return {Promise<any>}
   */
  getTeacherWageById(id: number | string) {
    return httpService.get(`/api/v1/costs/${id}`, null, null, true);
  }

  /**
   * @description Создание wage учителя
   * @param {number} id
   * @return {Promise<any>}
   */
  createTeacherWage(params: ITeacherWagesParams) {
    return httpService.post(`/api/v1/costs`, params, null, null, true);
  }

  /**
   * @description Редактирование wage учителя
   * @param {number} id
   * @return {Promise<any>}
   */
  editTeacherWage(id: number | string, params: ITeacherWagesParams) {
    return httpService.put(`/api/v1/costs/${id}`, params, null, null, true);
  }

  /**
   * @description Редактирование wage учителя
   * @param {number} id
   * @return {Promise<any>}
   */
  deleteTeacherWage(id: number | string) {
    return httpService.delete(`/api/v1/costs/${id}`, null, null, true);
  }

  /**
   * @description Деактивация списка рейтов
   * @param {number[]} prices
   * @return {Promise<any>}
   */
  deactivateRates(params: { prices: number[] }) {
    return httpService.post(`/api/v1/prices/bulk-deactivate`, params, null, null, true);
  }

  /**
   * @description Получение список Price Group
   * @param {number} id
   * @return {Promise<any>}
   */
  getPriceGroupList() {
    return httpService.get(`/api/v1/price-groups`, null, null, true);
  }

  /**
   * @description Получение Price Group item
   * @param {number} id
   * @return {Promise<any>}
   */
  getPriceGroupById(id: number | string) {
    return httpService.get(`/api/v1/price-groups/${id}`, null, null, true);
  }

  /**
   * @description Добавить Price Group item
   * @param {number} id
   * @return {Promise<any>}
   */
  addPriceGroup(params: { name: string }) {
    return httpService.post(`/api/v1/price-groups`, params, null, null, true);
  }

  /**
   * @description Редактировать Price Group item
   * @param {number} id
   * @return {Promise<any>}
   */
  editPriceGroup(id: number | string, params: { name: string }) {
    return httpService.put(`/api/v1/price-groups/${id}`, params, null, null, true);
  }

  /**
   * @description Удалить Price Group item
   * @param {number} id
   * @return {Promise<any>}
   */
  deletePriceGroup(id: number | string) {
    return httpService.delete(`/api/v1/price-groups/${id}`, null, null, true);
  }

  getJwtTokenByNN(authCode: string) {
    return httpService.get('/api/login/nornikel/token', { token: authCode });
  }
}

export const useGlobalRequestsRepository = () => {
  const service = React.useContext(GlobalRequestsRepositoryContext);

  if (!service) {
    throw new Error('GlobalRequests repository is unavailable');
  }

  return service;
};

export const GlobalRequestsRepositoryProvider = props => {
  const service = useMemo(() => new GlobalRequestsRepository(), []);

  return (
    <GlobalRequestsRepositoryContext.Provider value={service}>
      {props.children}
    </GlobalRequestsRepositoryContext.Provider>
  );
};
