import React from 'react';
import { BASE_ADMINS } from 'Admin/People/Administrators/routes';
import AvatarWithUserName from 'Global/components/AvatarWithUserName';

export const columns = strings => [
  {
    title: strings.NAME,
    dataIndex: 'user',
    key: 'name',
    width: 200,
    render: (user, admin) => {
      const name = user?.name;
      const surname = user?.surname;
      const photo = admin?.mainPhoto;
      return <AvatarWithUserName name={name} surname={surname} link={`${BASE_ADMINS}/${admin?.id}`} photo={photo} />;
    }
  },
  {
    title: strings.BRANCH,
    dataIndex: 'user',
    key: 'user',
    render: (user, admin) => (user?.branch ? user?.branch?.name : admin?.branch?.name),
    ellipsis: true,
    width: 200
  },
  {
    title: strings.POSITION,
    dataIndex: 'position',
    key: 'position',
    width: 200,
    render: position => <p>{position}</p>
  },
  {
    title: strings.COMMENTS,
    dataIndex: 'comment',
    key: 'comment',
    width: 250,
    render: comment => <p>{comment}</p>
  }
];

export default { columns };
