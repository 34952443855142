import dayjs from 'dayjs';
import { dateFormatForBackend } from 'helpers/dates';
import { dateMonthAgo } from 'helpers/dates';
import { InvoiceFilterParams } from 'types/invoice';

/**
 * @description Получение изначальных параметров для фильтра
 * @return {object}
 */
export const getInitialFilterParams = (params?: InvoiceFilterParams) => {
  const { branch } = params;

  return {
    periodStart: dateMonthAgo.format(dateFormatForBackend),
    periodEnd: dayjs().format(dateFormatForBackend),
    branch: branch,
    page: 1
  };
};
