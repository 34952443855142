import React, { useState, useEffect } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table, Row, Spin, Modal, message } from 'antd';
import { columns } from './columns';
import { BASE_TRAININGS, TRAINING_NEW } from '../routes';
import { useUserContext } from 'contexts/UserContext';
import { Link } from 'react-router-dom';
import { Location, History } from 'history';
import { useTrainingsRepository } from 'repos/TrainingsRepository';
import { TrainingsFilterParams, TrainingsItem } from 'types/education';
import { isEmpty } from 'lodash';
import { Filter } from './Filter';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import queryString from 'query-string';
import { Container, StPageHeader } from 'Global/GlobalStyle';

interface TrainingProps {
  location: Location;
  history: History;
}

/**
 * @description Раздел трейнинг
 * @param {TrainingProps} props
 * @return {React.ReactNode}
 */
export const Training = (props: TrainingProps) => {
  const {
    history: { goBack, push }
  } = props;
  const [strings] = useLanguageContext();
  const [{ confirm }, contextHolder] = Modal.useModal();

  const { locationSearchParams } = useLocationSearchParams();
  const [user] = useUserContext();
  const trainingsRepository = useTrainingsRepository();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [hasAccessToActions, setHasAccessToActions] = useState<boolean>(true);
  const [trainings, setTrainings] = useState<TrainingsItem[]>([]);

  const actionsClmn = [
    {
      title: strings.ACTION,
      key: 'action',
      dataIndex: 'id',
      render: (id: number) => (
        <Row align="middle" style={{ gap: 5, flexWrap: 'nowrap' }}>
          <Link to={`${BASE_TRAININGS}/${id}/edit`}>
            <Button icon={<EditOutlined />} />
          </Link>
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
              onDeleteTraining(id);
            }}
          />
        </Row>
      )
    }
  ];

  const getColumns = hasAccessToActions ? [...columns(strings), ...actionsClmn] : columns(strings);

  /**
   * @description Удаление тренинга
   * @param {number} trainingId
   * @return {Promise<any>}
   */
  const onDeleteTraining = (trainingId: number) => {
    confirm({
      title: strings.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_TRAINING,
      okText: 'Delete',
      // okType: 'danger',
      async onOk() {
        try {
          await trainingsRepository.deleteTraining(trainingId);
          message.success(strings.TRAINING_WAS_SUCCESSFULLY_DELETED, 4);
          await fetchTrainings();
        } catch {}
      }
    });
  };

  /**
   * @description Получение списка тренингов
   * @return {Promise<any>}
   */
  const fetchTrainings = async (params?: TrainingsFilterParams): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await trainingsRepository.getTrainings(params);
      setTrainings(data);
      push({ search: queryString.stringify(params) });
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    if (isEmpty(locationSearchParams)) {
      fetchTrainings();
    } else {
      fetchTrainings(locationSearchParams);
    }
  }, [user]);

  return (
    <Container>
      {contextHolder}
      <StPageHeader
        onBack={goBack}
        title={strings.TRAININGS}
        extra={
          hasAccessToActions && (
            <Button type="primary">
              <Link to={TRAINING_NEW}>{strings.ADD}</Link>
            </Button>
          )
        }
      >
        <Filter onFilter={fetchTrainings} />
      </StPageHeader>
      <Spin spinning={hasLoading}>
        <Row gutter={[10, 10]}>
          <Table dataSource={trainings} columns={getColumns} pagination={false} rowKey="id" />
        </Row>
      </Spin>
    </Container>
  );
};

export default { Training };
