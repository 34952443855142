import React, { useCallback, useMemo, useState } from 'react';
import { PhoneOutlined } from '@ant-design/icons';
import { Button, Tooltip, Flex } from 'antd';
import { Link } from 'react-router-dom';
import { ModalDisciplines } from 'Global/modules/Lesson/ModalDisciplines';
import { mapTooltips } from 'Global/modules/Lesson/constants';
import { DescriptionLessonItem, JournalStatusColor } from 'Global/modules/Lesson/styles';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { BASE_ORIGINAL_COURSES } from 'Admin/Courses/routes';
import { BASE_TEACHER_ORIGINAL_COURSES } from 'Teacher/routes';
import { useLessonInfoStore } from 'stores/useLessonInfoStore';
import { useMediaQuery } from 'react-responsive';

/**
 * @description Урл на курс оригинал
 * @param {boolean} hasTeacher
 * @param {number} courseId
 * @return {string}
 */
const getOriginalCourseUrl = (hasTeacher: boolean, courseId: number): string => {
  if (hasTeacher) {
    return `${BASE_TEACHER_ORIGINAL_COURSES}/${courseId}`;
  }
  return `${BASE_ORIGINAL_COURSES}/${courseId}`;
};

/**
 * @description Шапка журнала, основная информация
 * @return {React.ReactNode}
 */
export const LessonHeader = React.memo(() => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [hasShowDisciplinesModal, setHasShowDisciplinesModal] = useState<boolean>(false);
  const lessonInfo = useLessonInfoStore(state => state.lessonInfo);
  const { studentsTutors, studentsCount, lessonFilledInColor, lesson } = lessonInfo;
  const { id, teacher, assistant, title, courseGroup } = lesson || {};
  const tooltipText = useMemo(() => mapTooltips.get(lessonFilledInColor), [lessonFilledInColor]);

  const teacherFullName = useMemo(() => teacher && `${teacher.user.name} ${teacher.user.surname}`, [teacher]);
  const hasTeacher = user?.hasRoles?.teacher;

  const DescriptionItem = React.memo(
    ({ title, hasShow, children }: { title: string; hasShow: unknown; children: React.ReactNode }) => {
      return (
        <DescriptionLessonItem>
          <span>{title}: </span>
          {hasShow ? children : <span>-</span>}
        </DescriptionLessonItem>
      );
    }
  );

  const getTutorPhone = useCallback(tutor => {
    if (tutor?.user?.phone) {
      return (
        <a href={`tel:${tutor?.user?.phone}`}>
          <PhoneOutlined style={{ marginInlineEnd: 5 }} />
        </a>
      );
    }
    return null;
  }, []);

  const assistantName = useMemo(() => assistant && `${assistant?.user?.surname} ${assistant?.user?.name}`, [assistant]);

  const originalCourseUrl = useMemo(
    () => getOriginalCourseUrl(user?.hasRoles?.teacher, courseGroup?.originalCourse?.id),
    [user, courseGroup]
  );

  const tutorElements = useMemo(
    () =>
      studentsTutors?.map((tutor, index) => (
        <React.Fragment key={tutor.id}>
          {index > 0 && ', '}
          {hasTeacher ? (
            <>
              {getTutorPhone(tutor)}
              {tutor?.user?.name} {tutor?.user?.surname}
            </>
          ) : (
            <Link to={`/admin/${tutor.id}`}>
              {tutor?.user?.name} {tutor?.user?.surname}
            </Link>
          )}
        </React.Fragment>
      )),
    [studentsTutors, hasTeacher, getTutorPhone]
  );

  return (
    <Flex style={{ flex: 1, margin: 20 }} gap={20} justify="space-around" vertical={isMobile}>
      {hasShowDisciplinesModal && (
        <ModalDisciplines
          lessonId={id}
          hasShowDisciplinesModal={hasShowDisciplinesModal}
          setHasShowDisciplinesModal={setHasShowDisciplinesModal}
        />
      )}
      <Flex vertical gap={20}>
        <DescriptionLessonItem>
          <Tooltip title={tooltipText}>
            <JournalStatusColor color={lessonFilledInColor} />
          </Tooltip>
        </DescriptionLessonItem>
        <DescriptionLessonItem>{title}</DescriptionLessonItem>
        <DescriptionLessonItem>
          <Link to={`/group/${courseGroup?.id}`}>{courseGroup?.groupName} </Link>
          {studentsCount && (
            <span>
              - {studentsCount} {strings.STUDENTS}
            </span>
          )}
        </DescriptionLessonItem>
      </Flex>
      <Flex vertical gap={10}>
        <DescriptionItem title={strings.TEACHER} hasShow={teacher?.id}>
          {hasTeacher ? <span>{teacherFullName}</span> : <Link to={`/teachers/${teacher?.id}`}>{teacherFullName}</Link>}
        </DescriptionItem>
        <DescriptionItem title={strings.TUTORS} hasShow={studentsTutors?.length !== 0}>
          {tutorElements}
        </DescriptionItem>
        <DescriptionItem title={strings.ASSISTANT} hasShow={assistant?.user?.name}>
          {hasTeacher ? (
            <span>{assistantName}</span>
          ) : (
            assistant && (
              <Link to={`/teachers/${assistant?.id}`}>{`${assistant?.user?.surname} ${assistant?.user?.name}`}</Link>
            )
          )}
        </DescriptionItem>
      </Flex>
      <Flex vertical gap={20}>
        <Link to={originalCourseUrl}>{strings.SEE_THE_WHOLE_PROGRAMME}</Link>
        <Button onClick={() => setHasShowDisciplinesModal(true)}>{strings.DISCIPLINES}</Button>
      </Flex>
    </Flex>
  );
});
export default LessonHeader;
