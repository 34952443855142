import React, { useEffect, useState } from 'react';
import { Modal, Table, Button } from 'antd';
import { InvoicesLogsFilterParams, InvoicesLogsListItem } from 'types/logs';
import { Logs } from 'api/Logs';

import { columns } from 'Admin/Monitoring/InvoicesLogs/columns';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Column } = Table;

interface InvoicePayModalProps {
  invoiceId: number;
  hasVisible: boolean;
  onCancel: (hasShow: boolean) => void;
}

/**
 * @description Модальное окно оплаты инвойса PayKeeper
 * @param {object} props
 * @return {React.ReactNode}
 */
export const InvoiceLogModal = (props: InvoicePayModalProps) => {
  const [strings] = useLanguageContext();
  const { hasVisible, onCancel, invoiceId } = props;
  const [invoicesLogsList, setInvoicesLogsList] = useState<InvoicesLogsListItem[]>([]);
  const [hasLoadInvoicesLogsList, setHasLoadInvoicesLogsList] = useState<boolean>(false);

  /**
   * @description Получение списка InvoicesLogs Logs
   * @param {InvoicesLogsFilterParams} params
   * @return {Promise<any>}
   */
  const getInvoicesLogsList = async (params: InvoicesLogsFilterParams): Promise<any> => {
    setHasLoadInvoicesLogsList(true);
    try {
      const {
        data: { items }
      } = await Logs.getInvoicesLogsList({ ...params });
      setInvoicesLogsList(items);
    } finally {
      setHasLoadInvoicesLogsList(false);
    }
  };

  useEffect(() => {
    if (invoiceId) {
      getInvoicesLogsList({ invoice: invoiceId });
    }
  }, [invoiceId]); // eslint-disable-line

  return (
    (<Modal
      title={strings.INVOICE_LOG}
      open={hasVisible}
      onCancel={() => onCancel(false)}
      width={820}
      bodyStyle={{ padding: 0 }}
      footer={[
        <Button key="back" onClick={() => onCancel(false)}>
          Ok
        </Button>
      ]}
    >
      <Table dataSource={invoicesLogsList} pagination={false} bordered={false} loading={hasLoadInvoicesLogsList}>
        {columns(strings).map(col => (
          <Column key={col.key} {...col} />
        ))}
      </Table>
    </Modal>)
  );
};

export default { InvoiceLogModal };
