import React from 'react';
import { dateFormat } from 'helpers/dates';
import dayjs from 'dayjs';
import { Tag } from 'antd';

export const columnsSent = strings => [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: strings.PHONE,
    dataIndex: 'phone',
    key: 'phone',
    render: phone => <a href={`tel:${phone}`}>+{phone}</a>
  },
  {
    title: strings.COMMENT,
    dataIndex: 'comment',
    key: 'comment'
  },
  {
    title: strings.TEXT,
    dataIndex: 'text',
    key: 'text',
    width: 650
  },
  {
    title: strings.STATUS,
    dataIndex: 'status',
    key: 'status',
    render: status => {
      const statusColor = status === 'success' ? 'green' : 'red';
      return <Tag color={statusColor}>{status}</Tag>;
    }
  },
  {
    title: strings.DATE,
    dataIndex: 'createdAt',
    key: 'date',
    render: date => date && dayjs(date).format(dateFormat)
  }
];

export default { columnsSent };
