import React, { useMemo } from 'react';
import { httpService } from 'api/core';
import { IRepositoryProvider } from './RepositoryProvider';
import { SupergroupListParams } from 'types/courses';
import { ISupergroupPostParams } from 'types/API/education';

const SupergroupRepositoryContext = React.createContext<ISupergroupRepository>(null);

interface ISupergroupRepository {
  getSupergroups: (params: SupergroupListParams) => Promise<any>;
  getSupergroupById: (id: number | string) => Promise<any>;
  editSupergroup: (id: number | string, params: ISupergroupPostParams) => Promise<any>;
  createSupergroup: (params: ISupergroupPostParams) => Promise<any>;
  askOfficeFeedbackBySupergroup: (id: number, params: { students: number[] }) => Promise<any>;
}

class SupergroupRepository implements ISupergroupRepository{
  getSupergroups(params: SupergroupListParams) {
    return httpService.get('/api/v1/supergroups', params, null, true);
  }

  getSupergroupById(id: number | string) {
    return httpService.get(`/api/v1/supergroups/${id}`, null, null, true);
  }

  editSupergroup(id: number, params: ISupergroupPostParams) {
    return httpService.put(`/api/v1/supergroups/${id}`, params, null, null, true);
  }

  createSupergroup(params: ISupergroupPostParams) {
    return httpService.post(`/api/v1/supergroups`, params, null, null, true);
  }

  askOfficeFeedbackBySupergroup(id: number, params: { students: number[] }) {
    return httpService.post(`/api/v1/supergroups/${id}/ask-feedback-office`, params, null, null, true);
  }
}

export const useSupergroupRepository = () => {
  const service = React.useContext(SupergroupRepositoryContext);

  if (!service) {
    throw new Error('Supergroup repository is unavailable');
  }

  return service;
};

export const SupergroupRepositoryProvider: React.FC<IRepositoryProvider> = ({ children }) => {
  const service = useMemo(() => new SupergroupRepository(), []);

  return <SupergroupRepositoryContext.Provider value={service}>{children}</SupergroupRepositoryContext.Provider>;
};
