import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { OriginalCourse } from 'types/courses';
import { Stage, Branch } from 'types';

export const columns = (hasActionColumn: boolean) => [
  {
    title: 'Title',
    dataIndex: 'name',
    key: 'name',
    render: (name: string, record: OriginalCourse) => {
      return (
        <div>
          <Link to={`/original_courses/${record.id}`}>{name}</Link>
          <div style={{ fontSize: 12, color: '#999' }}>{record.description}</div>
        </div>
      );
    }
  },
  {
    title: 'Branch',
    dataIndex: 'branch',
    key: 'branch',
    render: (branch: Branch) => branch?.name ?? '-'
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration'
  },
  {
    title: 'Stage',
    dataIndex: 'stage',
    key: 'stage',
    render: (stage: Stage) => stage?.name ?? '-'
  },
  hasActionColumn
    ? {
        title: '',
        dataIndex: 'id',
        key: 'actions',
        render: (id: number) => (
          <Link to={`/original_courses/${id}/edit`}>
            <Button icon={<EditOutlined />} />
          </Link>
        )
      }
    : {}
];

export default { columns };
