import { fromPairs } from 'lodash';
import { httpService } from 'api/core';
import * as finance from './finance';
import * as material from './material';
import * as reports from './reports';

/**
 * @description Получение списка учителей
 * @param {object} params
 * @return {Promise<any>}
 */
export const getTeachers = (params?: object): Promise<any> => httpService.get(`/api/teachers`, params);

/**
 * @description Получение учителя по id
 * @param {number} teacherId
 * @return {Promise<any>}
 */
export const getTeacherById = (teacherId: number | string): Promise<any> =>
  httpService.get(`/api/teachers/${teacherId}`);

/**
 * @description Получить excel файл со списком учителей, прочитавших неформальный договор
 * @returns {Promise<any>}
 */
export const exportTeachersAgreementReport = async (): Promise<any> =>
  httpService.get('/api/report/teachers/agreement/export');

/**
 * @description Возвращает историю сотрудничества учителя по дате
 * @param {number} teacherId
 * @return {Promise<any>}
 */
export const getCollaborationHistory = (teacherId: number, params: any): Promise<any> =>
  httpService.get(`/api/teachers/${teacherId}/history`, params);

/**
 * @description Удаление записи контакт в истории учителя
 * @param {number} teacherId
 * @param {number} contactId
 * @return {Promise<any>}
 */
export const deleteTeacherContact = (teacherId: number, contactId: number): Promise<any> =>
  httpService.delete(`/api/teachers/${teacherId}/contacts/${contactId}`);

/**
 * @description Создание записи контакт в истории учителя
 * @param {number} teacherId
 * @param {object} params
 * @return {Promise<any>}
 */
export const createTeacherContact = (teacherId: number | string, params: FormData) =>
  httpService.post(`/api/v1/teachers/${teacherId}/contacts`, params, null, null, true);

/**
 * @description Получение subjects согласно id учителея
 * @param teacherId
 * @return {Promise<any>}
 */
export const getSubjectsByTeacherId = (teacherId: number): Promise<any> =>
  httpService.get(`/api/subjects/teacher/${teacherId}`);

/**
 * @description Получение тегов учителей
 * @return {Promise<any>}
 */
export const getTeacherTags = (): Promise<any> => httpService.get('api/tags/teacher');

/**
 * @description Добавление тега к учителю
 * @param {number} teacherId
 * @param {object} params
 */
export const addTeacherTag = (teacherId: number, params: any): Promise<any> =>
  httpService.post(`/api/teacher/${teacherId}/tags`, params);

/**
 * @description Удаление тегов учителя
 * @param {number} teacherId
 * @param {number} tagId
 * @return {Promise<any>}
 */
export const deleteTeacherTag = (teacherId: number, tagId: number): Promise<any> =>
  httpService.delete(`/api/teacher/${teacherId}/tags/${tagId}`);

/**
 * @description Добавление Main Subject к учителю
 * @param {number} teacherId
 * @param {object} params
 * @return {Promise<any>}
 */
export const addMainSubjectToTeacher = (teacherId: number, params: any): Promise<any> =>
  httpService.post(`/api/teacher/${teacherId}/main-subject`, params);

/**
 * @description Удаление Main Subject у учителя
 * @param teacherId
 * @param subjectId
 */
export const deleteMainSubjectFromTeacher = (teacherId: number, subjectId: number): Promise<any> =>
  httpService.delete(`/api/teacher/${teacherId}/main-subject/${subjectId}`);

/**
 * @description Добавление Additional Subject  к учителю
 * @param {number} teacherId
 * @param {object} params
 * @return {Promise<any>}
 */
export const addAdditionalSubjectToTeacher = (teacherId: number, params: any): Promise<any> =>
  httpService.post(`/api/teacher/${teacherId}/additional-subject`, params);

/**
 * @description Удаление Additional Subject  к учителю
 * @param {number} teacherId
 * @param {number} subjectId
 * @return {Promise<any>}
 */
export const deleteAdditionalSubjectFromTeacher = (teacherId: number, subjectId: number): Promise<any> =>
  httpService.delete(`/api/teacher/${teacherId}/additional-subject/${subjectId}`);

/**
 * @description Получить статистику уроков для учителя
 * @param {string[]} dates
 * @returns {Promise<any>}
 */
const getLessonsStatisticsForTeacher = (dates: string[]): Promise<any> =>
  // TODO: вынести в хелпер маппер дат
  httpService.get(`/api/lessons/statistics/current-teacher?${dates.map(date => 'date[]=' + date).join('&')}`);

/**
 * @description Получить журналы учителя
 * @returns {Promise<any>}
 */
const getTeacherLastNotFilledJournal = (): Promise<any> => httpService.get('/api/lessons/journal/not-filled');

/**
 * @description Получить слоты для учителя
 * @param {number} teacherId
 * @param {string} dateStart
 * @param {string} dateEnd
 * @returns {Promise<any>}
 */
const getSlotsByTeacher = (teacherId: number, dateStart: string, dateEnd: string): Promise<any> =>
  httpService.get(`/api/slots?dateStart=${dateStart}&dateEnd=${dateEnd}&teacher[]=${teacherId}`);

/**
 * @description Получить комментарии по урокам учителя
 * @param {string[]} dates
 * @returns {Promise<any>}
 */
const getLessonCommentsForTeacher = async (dates: string[]): Promise<any> => {
  try {
    const uriDates = dates.map(date => 'date[]=' + date).join('&');

    const { data: liked, status: statusLiked } = await httpService.get(
      `/api/lessons/comments/current-teacher?type=liked&${uriDates}`
    );
    const { data: disliked, status: statusDisliked } = await httpService.get(
      `/api/lessons/comments/current-teacher?type=disliked&${uriDates}`
    );
    const { data: parentLiked, status: statusParentLiked } = await httpService.get(
      `/api/lessons/comments/current-teacher?type=disliked&${uriDates}`
    );

    if ((statusLiked && statusDisliked && statusParentLiked) === 200) {
      return Promise.resolve(
        fromPairs(
          dates.map(date => [
            date,
            {
              liked: liked[date],
              disliked: disliked[date],
              parentLiked: parentLiked[date]
            }
          ])
        )
      );
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * @description Получить грейдинг по учителю
 * @param {any} params
 * @returns {Promise<any>}
 */
const getWorkProgress = async (params: any): Promise<any> => httpService.get('/api/teacher/my/work-progress', params);

/**
 * @description Скрыть/показать аудиокомментарий
 * @param {number} teacherId
 * @returns {Promise<any>}
 */
const hideJuliaComment = (teacherId: number): Promise<any> =>
  httpService.post(`/api/teacher/${teacherId}/hide-julia-comment`);

/**
 * @description Получить список дизлайкнутых комментариев учителя
 * @return {Promise<any>}
 */
const getDislikedComments = (): Promise<any> => httpService.get('/api/teacher/my/comments/disliked');

/**
 * @description Получить комментарии, не понравившиеся учителю
 * @param {object} params
 * @returns {Promise<any>}
 */
const getDislikedCommentsByTeacherId = async (teacherId: number, params?: object): Promise<any> => {
  return httpService.get(`/api/report/teachers/${teacherId}/comments/disliked`, params);
};

const getExperts = (query?: string): Promise<any> => httpService.get('/api/experts', { query });

export const Teachers = {
  ...finance.default,
  ...material.default,
  ...reports.default,
  getTeachers,
  getTeacherById,
  getCollaborationHistory,
  getSubjectsByTeacherId,
  deleteTeacherContact,
  createTeacherContact,
  getTeacherTags,
  addTeacherTag,
  deleteTeacherTag,
  addMainSubjectToTeacher,
  deleteMainSubjectFromTeacher,
  addAdditionalSubjectToTeacher,
  deleteAdditionalSubjectFromTeacher,
  getLessonsStatisticsForTeacher,
  getTeacherLastNotFilledJournal,
  getSlotsByTeacher,
  getLessonCommentsForTeacher,
  getWorkProgress,
  hideJuliaComment,
  getDislikedComments,
  getExperts,
  exportTeachersAgreementReport,
  getDislikedCommentsByTeacherId
};

export default { Teachers };
