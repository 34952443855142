import { create } from 'zustand';
import { ILessonRegister, ILessonTask } from 'types/lesson';

interface IExtraRegisterData {
  homeworkRequiredForCurrentLesson?: boolean;
  task?: ILessonTask;
  hwButtons?: boolean;
  feedbackForParentsCountPresence?: boolean;
}

interface ILessonRegisterStore {
  hasRegistersLoading: boolean;
  setHasRegistersLoading: (val: boolean) => void;
  registers: ILessonRegister[];
  setRegisters: (val: ILessonRegister[]) => void;
  updateSingleRegister: (studentId: number, field: string, value: unknown) => void;
  mockRegisters: ILessonRegister[];
  setMockRegisters: (val: ILessonRegister[]) => void;
  registerColumns: string[];
  setRegisterColumns: (val: string[]) => void;
  extraRegisterData: IExtraRegisterData;
  setExtraRegisterData: (val: IExtraRegisterData) => void;
  maxHwGrade: number;
  setMaxHwGrade: (val: number) => void;
  maxTaskGrade: number;
  setMaxTaskGrade: (val: number) => void;
  registerUpdatedAt: string;
  setRegisterUpdatedAt: (val: string) => void;
  hasRegistersUpdate: boolean;
  setHasRegistersUpdate: (val: boolean) => void;
  hasRegistersFetch: boolean;
  setHasRegistersFetch: (val: boolean) => void;
}

export const useLessonRegisterStore = create<ILessonRegisterStore>(set => ({
  hasRegistersLoading: false,
  setHasRegistersLoading: val => set({ hasRegistersLoading: val }),
  registers: [],
  setRegisters: val => set({ registers: val }),
  updateSingleRegister: (studentId, field, value) =>
    set(state => ({
      registers: state.registers.map(register =>
        register.student.id === studentId ? { ...register, [field]: value } : register
      )
    })),
  mockRegisters: [],
  setMockRegisters: val => set({ mockRegisters: val }),
  registerColumns: [],
  setRegisterColumns: val => set({ registerColumns: val }),
  extraRegisterData: {},
  setExtraRegisterData: val => set({ extraRegisterData: val }),
  maxHwGrade: 0,
  setMaxHwGrade: val => set({ maxHwGrade: val }),
  maxTaskGrade: 0,
  setMaxTaskGrade: val => set({ maxTaskGrade: val }),
  registerUpdatedAt: '',
  setRegisterUpdatedAt: val => set({ registerUpdatedAt: val }),
  hasRegistersUpdate: false,
  setHasRegistersUpdate: val => set({ hasRegistersUpdate: val }),
  hasRegistersFetch: false,
  setHasRegistersFetch: val => set({ hasRegistersFetch: val })
}));
