import React, { useState } from 'react';
import { EyeTwoTone } from '@ant-design/icons';
import { List, Tooltip, Spin } from 'antd';
import dayjs from 'dayjs';
import { AssessmentMaterial, AssessmentMaterialDownload } from 'types/accessment';
import { dateFormat, dateFormatForLesson } from 'helpers/dates';
import { downloadFileByUrl, getAttachmentLink, getAttachmentIdByUrl } from 'helpers/downloadFiles';
import { DownloadFile, MaterialLesson, MaterialsContainer } from '../styles';
import { Html } from 'Global/components/Html';
import { PreviewFileModal } from 'Global/components/FilePreview';
import { IMAGE_TYPES_LIST } from 'Global/components/FilePreview/constants';
import { getPreviewUrl } from 'Global/components/FilePreview/helpers';
import { Link } from 'react-router-dom';
import { useLanguageContext } from 'contexts/LanguageContext';

interface MaterialsProps {
  lessons: AssessmentMaterial[];
  hasShadow?: boolean;
}

const initialAttachment = {
  id: null,
  name: ''
};

/**
 * @description Materials for 3 last lessons
 * @param {MaterialsProps} props
 * @return {React.ReactNode}
 */
export const Materials = (props: MaterialsProps) => {
  const [strings] = useLanguageContext();
  const [currentAttachment, setCurrentAttachment] = useState<{ id: number; name: string }>(initialAttachment);
  const [hasLoadPreviewAttachment, setHasLoadPreviewAttachment] = useState<boolean>(false);
  const [hasShowPreviewModal, setHasShowPreviewModal] = useState<boolean>(false);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer>('');
  const { lessons, hasShadow } = props;

  const downloadMaterial = async (e: React.SyntheticEvent, material: AssessmentMaterialDownload) => {
    e.preventDefault();
    const attachmentId: number = getAttachmentIdByUrl(material.path);
    try {
      setHasLoading(true);
      await downloadFileByUrl(getAttachmentLink(attachmentId), material.downloadName);
    } finally {
      setHasLoading(false);
    }
  };

  const handleShowModal = async (attachmentId: number, attachmentName: string): Promise<any> => {
    setCurrentAttachment({
      id: attachmentId,
      name: attachmentName
    });
    setHasLoadPreviewAttachment(true);
    await getPreviewUrl(attachmentId, setPreviewUrl, setHasShowPreviewModal);
    setHasLoadPreviewAttachment(false);
  };

  const renderMaterial = (material: AssessmentMaterialDownload) => {
    // TODO: Попросить на бэке присылать нормальный ID материала
    const materialId = Number(material.path.split('/')[3]);

    return (
      <div key={material.path}>
        <a onClick={(e: React.SyntheticEvent) => downloadMaterial(e, material)}>{material.displayName}</a>
        {IMAGE_TYPES_LIST.some((attachmentType: string) => material.displayName.includes(attachmentType)) && (
          <Tooltip title={strings.VIEW_PREVIEW}>
            <EyeTwoTone
              onClick={() => handleShowModal(materialId, material.displayName)}
              style={{ fontSize: 16, margin: '0 6px 0 8px', cursor: 'pointer' }}
            />
          </Tooltip>
        )}

        <DownloadFile>
          {material.createdBy} {dayjs(material.updatedAt).format(`${dateFormat} ${dateFormatForLesson}`)}
        </DownloadFile>
      </div>
    );
  };

  return (
    <MaterialsContainer hasShadow={hasShadow}>
      <Spin spinning={hasLoading}>
        <PreviewFileModal
          hasLoadPreviewAttachment={hasLoadPreviewAttachment}
          fileName={currentAttachment.name}
          previewUrl={previewUrl}
          handleHideModal={() => setHasShowPreviewModal(false)}
          hasShowModal={hasShowPreviewModal}
        />
        <List
          dataSource={lessons}
          itemLayout="vertical"
          renderItem={(lesson: AssessmentMaterial, id: number) => {
            const lessonMaterials = lesson.materials.filter(
              (material: AssessmentMaterialDownload) => material.type === 'lessonMaterial'
            );
            const homework = lesson.materials.filter(
              (material: AssessmentMaterialDownload) => material.type === 'lessonHomework'
            );

            return (
              <List.Item key={id}>
                <h3>
                  <Link to={`/le/${lesson.id}`} target="_blank">
                    {lesson.title}
                  </Link>
                </h3>

                <h4>{strings.LESSON_MATERIALS}</h4>
                <Html html={lesson.titleMaterial} />
                {lessonMaterials.length > 0 && (
                  <MaterialLesson>
                    {lessonMaterials.map((material: AssessmentMaterialDownload) => renderMaterial(material))}
                  </MaterialLesson>
                )}
                <hr />
                <MaterialLesson dangerouslySetInnerHTML={{ __html: lesson.titleHomework }} />
                <h4>{strings.HOMEWORK_FILES}</h4>
                {homework.length > 0 && (
                  <MaterialLesson>
                    {homework.map((material: AssessmentMaterialDownload) => renderMaterial(material))}
                  </MaterialLesson>
                )}
              </List.Item>
            );
          }}
        />
      </Spin>
    </MaterialsContainer>
  );
};

export default { Materials };
