import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Row, Col, Button, Select, Input, Checkbox, Tooltip, DatePicker } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { IGetWeeklyEmailParams } from 'types/Mailing';
import { Supergroup } from 'types/education';
import { filterSelect } from 'helpers/filterSelect';
import dayjs from 'dayjs';
import { dateFormat } from 'helpers';
import { TutorSelect } from 'Global/components/FormComponentsCompatible/Selects/TutorSelect';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { FilterButtons } from '../../../Global/components/FilterButtons';

const { Option } = Select;

interface FilterProps extends FormComponentProps {
  onFilter: (params: IGetWeeklyEmailParams) => Promise<any>;
  defFilterValues?: IGetWeeklyEmailParams;
}

/**
 * @description Фильр Original courses
 * @return {React.ReactNode}
 */
export const Filter = Form.create<FilterProps>({})((props: FilterProps) => {
  const {
    form: { getFieldDecorator, resetFields, validateFieldsAndScroll },
    onFilter,
    defFilterValues
  } = props;

  const [strings] = useLanguageContext();
  const { locationSearchParams } = useLocationSearchParams();
  const coursesRepository = useCoursesRepository();
  const [superGroups, setSuperGroups] = useState<Supergroup[]>([]);
  const [hasSuperGroupsLoading, setHasSuperGroupsLoading] = useState<boolean>(false);
  const { title, supergroups, dateFrom, dateTo, tutor } = locationSearchParams || {};
  const [selectedTutor, setSelectedTutor] = useState<number>();
  const [selectedSupergroups, setSelectedSupergroups] = useState<number[]>();
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);

  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values): Promise<any> => {
      if (!errors) {
        try {
          setHasSubmitLoading(true);
          await onFilter(values);
        } catch {
        } finally {
          setHasSubmitLoading(false);
        }
      }
    });
  };

  const handleClear = async (): Promise<any> => {
    resetFields();
    await onFilter(defFilterValues);
  };

  /**
   * @description Получение списка супер групп
   * @return {Promise<any>}
   */
  const getSuperGroups = async (): Promise<any> => {
    setHasSuperGroupsLoading(true);
    try {
      const params = { isActive: true, ...(selectedTutor && { tutor: selectedTutor }) };
      const { data } = await coursesRepository.getSuperGroups(params);
      setSuperGroups(data);
      if (selectedTutor) {
        setSelectedSupergroups(data.map(item => item.id));
      } else {
        setSelectedSupergroups([]);
      }
    } catch {
    } finally {
      setHasSuperGroupsLoading(false);
    }
  };

  useEffect(() => {
    getSuperGroups();
  }, [, selectedTutor]);

  useEffect(() => {
    if (supergroups) {
      setSelectedSupergroups(supergroups);
    }
  }, [supergroups]);

  useEffect(() => {
    if (tutor) {
      setSelectedTutor(tutor);
    }
  }, [tutor]);

  return (
    <ErrorBoundary>
      <Form onSubmit={handleSubmit}>
        <Row gutter={[10, 10]}>
          <Col lg={4}>
            {getFieldDecorator('dateFrom', {
              initialValue: dateFrom && dayjs(dateFrom),
              rules: [{ required: false }]
            })(<DatePicker format={dateFormat} placeholder={strings.FROM} />)}
          </Col>
          <Col lg={4}>
            {getFieldDecorator('dateTo', {
              initialValue: dateTo && dayjs(dateTo),
              rules: [{ required: false }]
            })(<DatePicker format={dateFormat} placeholder={strings.TILL} />)}
          </Col>
          <Col lg={4}>
            {getFieldDecorator('title', {
              initialValue: title,
              rules: [{ required: false }]
            })(<Input placeholder={strings.TITLE} />)}
          </Col>
          <Col lg={6}>
            <TutorSelect
              initTutor={selectedTutor}
              getFieldDecorator={getFieldDecorator}
              name="tutor"
              placeholder={strings.TUTOR}
              handleChange={value => setSelectedTutor(value)}
              style={{ margin: 0 }}
            />
          </Col>
          <Col lg={6}>
            {getFieldDecorator('supergroups', {
              rules: [{ required: false }],
              initialValue: selectedSupergroups
            })(
              <Select
                showSearch
                filterOption={filterSelect}
                optionFilterProp="children"
                loading={hasSuperGroupsLoading}
                placeholder={strings.SUPERGROUPS}
                maxTagCount={2}
                mode="multiple"
                allowClear={true}
              >
                {superGroups.map((superGroup: Supergroup) => (
                  <Option key={superGroup.id} value={superGroup.id}>
                    <Tooltip title={superGroup.name} overlayStyle={{ zIndex: 9999 }}>
                      {superGroup.name}
                    </Tooltip>
                  </Option>
                ))}
              </Select>
            )}
          </Col>
        </Row>
        <FilterButtons handleClear={handleClear} hasSubmitLoading={hasSubmitLoading} />
      </Form>
    </ErrorBoundary>
  );
});

export default { Filter };
