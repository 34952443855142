import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button } from 'antd';
import { Help as StHelp } from 'Global/modules/Auth/styles';
import { BtnAndSupportModal } from './BtnAndSupportModal';

const { Title } = Typography;

export const Help = () => {
  return (
    <StHelp>
      <Title level={4}>Contact support if you cannot access the database</Title>
      <div style={{ display: 'flex', gap: 10 }}>
        <BtnAndSupportModal />
        <Link to="/auth/forgot">
          <Button type="primary" style={{ marginTop: '1rem' }}>
            Forgot your password ?
          </Button>
        </Link>
      </div>
    </StHelp>
  );
};
export default { Help };
