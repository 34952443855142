import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, Button, Col, Row, Select, DatePicker, message } from 'antd';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Branch } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { dateFormat } from 'helpers';
import { momentFormatValues } from 'helpers/momentFormatValues';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';

const { Option } = Select;

interface BtnGenerateForBranchesProps {
  form: WrappedFormUtils;
  id: number;
  handler: () => Promise<any>;
}

/**
 * @description - Компонент модального окна Generate For Extra Groups
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const BtnGenerateForBranchesTemplate = (props: BtnGenerateForBranchesProps) => {
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const { branches } = useGlobalCollectionsContext();
  const reportsRepository = useProgressReportsRepository();

  const {
    form: { getFieldDecorator, validateFieldsAndScroll },
    id,
    handler
  } = props;

  const [strings] = useLanguageContext();

  const closeModal = (): void => {
    setHasShowModal(false);
  };

  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        setHasLoading(true);
        try {
          const params = momentFormatValues(values);
          await reportsRepository.generateProgressReporByBranches(id, params);
          message.success(strings.SUCCESSFULLY_CREATED);
          handler();
          closeModal();
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  const getFooter = () => {
    const buttons = [
      <Button key="back" onClick={closeModal}>
        {strings.CANCEL}
      </Button>,
      <Button type="primary" key="action" loading={hasLoading} htmlType="submit" onClick={handleSubmit}>
        {strings.GENERATE}
      </Button>
    ];
    return buttons;
  };

  const form = (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Row gutter={6} style={{ marginTop: 20 }}>
        <Col lg={16}>
          <MyFormItem label={strings.BRANCHES}>
            {getFieldDecorator('branches', { rules: [{ required: true }] })(
              <Select mode="multiple">
                {branches.map((branch: Branch) => (
                  <Option value={branch.id} key={branch.id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            )}
          </MyFormItem>
        </Col>
        <Col lg={8}>
          <MyFormItem label={strings.DATE}>
            {getFieldDecorator('finishDate', {
              rules: [{ required: false }]
            })(<DatePicker format={dateFormat} />)}
          </MyFormItem>
        </Col>
      </Row>
    </Form>
  );
  return (<>
    <Button type="primary" onClick={() => setHasShowModal(true)} style={{ margin: 0 }}>
      {strings.FOR_BRANCHES}
    </Button>
    <Modal
      title={strings.DO_YOU_WANT_TO_GENERATE_PROGRESS_REPORTS}
      footer={getFooter()}
      open={hasShowModal}
      onCancel={() => setHasShowModal(false)}
      style={{ top: 20 }}
      width={650}
      destroyOnClose={true}
    >
      {form}
    </Modal>
  </>);
};

export const BtnGenerateForBranches = Form.create<BtnGenerateForBranchesProps>({})(BtnGenerateForBranchesTemplate);

export default { BtnGenerateForBranches };
