import React from 'react';
import { Button, Col, Flex, Form, Input, Radio, Row, Tooltip } from 'antd';
import { MetaGoalItem } from 'types/courses';
import { MetaGrade } from 'types/TeacherAccount/Courses';
import { useLanguageContext } from 'contexts/LanguageContext';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

interface MetaGoalsProps {
  metaGoals: MetaGoalItem[];
  metaGoalsGrades: MetaGrade[];
  prevMetaGoalsGrades: MetaGrade[];
  setHasMetaGoalsChanged?: (val: boolean) => void;
}

const GradeSt = styled.p`
  min-width: 100px;
`;
const GradeValueSt = styled.span`
  color: #cc4454;
  font-weight: 500;
  padding-inline-end: 5px;
`;

const getLabelMetaGoal = (grade?: number | null): string | number => {
  //(grade !== null && grade !== undefined) ? grade : '-'
  const label = grade ?? '-';
  return grade === -2 ? 'N/A' : label;
};

export const MetaGoals = ({
  metaGoals,
  metaGoalsGrades,
  prevMetaGoalsGrades,
  setHasMetaGoalsChanged
}: MetaGoalsProps) => {
  const [strings] = useLanguageContext();
  const gradeList = [
    { value: '2', title: strings.CAN_CONFIDENTLY_DO_IT_WITHOUT_ASSISTANCE_WITHOUT_MISTAKES_CAN_HELP_OTHERS },
    { value: '1', title: strings.CAN_DO_WITH_LIMITED_ASSISTANCE_LITTLE_MISTAKES },
    { value: '0', title: strings.CAN_ONLY_DO_WITH_PROMPTING_AND_EXTENSIVE_ASSISTANCE_LOTS_OF_MISTAKES },
    { value: '-1', title: strings.CANT_DO_IT_YET }
  ];

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <>
      <Row>
        {metaGoals.map((item: MetaGoalItem, index) => {
          const prevGoal =
            prevMetaGoalsGrades?.isNotEmpty() && prevMetaGoalsGrades.find(goal => goal.id === item.id)?.grade;
          const goalValue = metaGoalsGrades.find(goal => goal.id === item.id)?.grade?.toString() || null;
          return (
            <Row gutter={[10, 10]} style={{ width: '100%', marginBottom: 10 }}>
              <Col lg={14}>{item?.description}</Col>
              <Col lg={8}>
                <Form.Item
                  name={['goals', index, 'grade']}
                  initialValue={goalValue}
                  rules={[{ required: true, message: `${strings.META_GOALS} ${strings.ARE_REQUIRED}` }]}
                >
                  <Radio.Group onChange={() => setHasMetaGoalsChanged && setHasMetaGoalsChanged(true)}>
                    {gradeList.map(item => (
                      <Radio.Button value={item.value}>{item.value}</Radio.Button>
                    ))}
                    <Radio.Button value="-2">{strings.N_A}</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form.Item name={['goals', index, 'id']} hidden={true} initialValue={item.id}>
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={1}>
                <Tooltip title={strings.PREVIOUS_GRADE}>
                  <Button disabled>{getLabelMetaGoal(prevGoal)}</Button>
                </Tooltip>
              </Col>
            </Row>
          );
        })}
      </Row>
      <Flex gap={10} style={{ fontSize: 14, opacity: 0.6, ...(isMobile && { flexDirection: 'column' }) }}>
        {gradeList.map(item => {
          return (
            <GradeSt>
              <GradeValueSt>{item.value}</GradeValueSt>
              {item.title}
            </GradeSt>
          );
        })}
        <GradeSt>
          <GradeValueSt>{strings.N_A}</GradeValueSt>
          {strings.NOT_AVAILABLE}
        </GradeSt>
      </Flex>
    </>
  );
};
const MetaGoalsMemo = React.memo(MetaGoals);
export default MetaGoalsMemo;
