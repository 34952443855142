import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, Select, message, Col, Spin, Row } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { useLanguageContext } from 'contexts/LanguageContext';
import { ExpertPostParams, ExpertReverseItem } from 'types/education';
import { useAdminRepository } from 'repos/AdminRepository';
import { Branch, Subject } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { StageSelect } from 'Global/components/FormComponentsCompatible/Selects/StageSelect';
import { TutorSelect } from 'Global/components/FormComponentsCompatible/Selects/TutorSelect';

const { Option } = Select;

export enum ExpertFormMode {
  edit = 'edit',
  create = 'create',
  addSubject = 'addSubject'
}

interface ModalExpertFormProps extends FormComponentProps {
  hasShowModal: boolean;
  mode: ExpertFormMode;
  setHasShowModal: (hasShow: boolean) => void;
  expert?: ExpertReverseItem;
  expertId?: number;
  fetchExperts: () => Promise<any>;
}

/**
 * @description Добавление/редактирование reserve для classroom
 * @param {ModalExpertFormProps} props
 * @return {React.ReactNode}
 */
const ModalExpertFormTemplate = (props: ModalExpertFormProps) => {
  const [strings] = useLanguageContext();
  const adminRepository = useAdminRepository();
  const { branches, subjects } = useGlobalCollectionsContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const {
    form,
    form: { getFieldDecorator, resetFields, validateFieldsAndScroll },
    hasShowModal,
    setHasShowModal,
    expert,
    fetchExperts,
    mode
  } = props;

  const { id, name, surname, branchId, subjectId, stages: expertStages } = expert || {};
  const initExpert = expert ? { id, name, surname } : undefined;

  /**
   * @description Отправка формы
   * @param {React.FormEvent} e
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (err, values: ExpertPostParams) => {
      if (!err) {
        setHasLoading(true);
        try {
          await adminRepository.editExpert(values);
          if (mode === ExpertFormMode.create) {
            message.success(strings.SUCCESSFULLY_CREATED, 2);
          } else {
            message.success(strings.SUCCESSFULLY_UPDATED, 2);
          }
          await fetchExperts();
          resetFields();
          setHasShowModal(false);
        } catch {
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  const closeModal = () => {
    setHasShowModal(false);
  };

  return (
    <Modal
      title={id ? `${strings.EDIT_EXPERT} ${surname} ${name}` : strings.NEW_EXPERT}
      open={hasShowModal}
      onCancel={closeModal}
      okText={id ? strings.EDIT : strings.ADD}
      onOk={handleSubmit}
      destroyOnClose={true}
    >
      <Spin spinning={hasLoading}>
        <Form layout="vertical">
          <Row gutter={[6, 6]}>
            <Col lg={12}>
              <TutorSelect
                label={strings.EXPERT}
                required={true}
                initTutor={initExpert}
                placeholder=" "
                disabled={mode === ExpertFormMode.edit || mode === ExpertFormMode.addSubject ? true : false}
                message={`${strings.EXPERT} ${strings.IS_REQUIRED}`}
                getFieldDecorator={getFieldDecorator}
                name="user"
                style={{ margin: 0 }}
                hasUseUserId={true}
              />
            </Col>
            <Col lg={12}>
              <MyFormItem label={strings.SUBJECT}>
                {getFieldDecorator('subject', {
                  initialValue: mode === ExpertFormMode.edit ? subjectId : undefined,
                  rules: [{ required: true, message: `${strings.SUBJECT} ${strings.IS_REQUIRED}` }]
                })(
                  <Select disabled={mode === ExpertFormMode.edit ? true : false}>
                    {subjects.map((subject: Subject) => (
                      <Option value={subject.id} key={subject.id}>
                        {subject.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </MyFormItem>
            </Col>
            <Col lg={12}>
              <MyFormItem label={strings.BRANCH}>
                {getFieldDecorator('branch', {
                  initialValue: mode === ExpertFormMode.edit ? branchId : undefined,
                  rules: [{ required: true, message: `${strings.BRANCH} ${strings.IS_REQUIRED}` }]
                })(
                  <Select disabled={mode === ExpertFormMode.edit ? true : false}>
                    {branches?.map((branch: Branch) => (
                      <Option value={branch.id} key={branch.id}>
                        {branch.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </MyFormItem>
            </Col>
            <Col lg={12}>
              <StageSelect
                label={strings.STAGES}
                isRequired
                form={form}
                initialValue={mode === ExpertFormMode.edit ? expertStages?.map(item => item.id) : []}
                mode="multiple"
                placeholder=" "
              />
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export const ModalExpertForm = Form.create<ModalExpertFormProps>({})(ModalExpertFormTemplate);

export default { ModalExpertForm };
