import React, { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { TeachersTitle } from 'Admin/People/Teachers/Title';
import { TableForTeachers } from 'Admin/People/Teachers/TableForTeachers';
import { PaginationInfo } from 'types/global';
import { ITeacherGetParams, Teacher } from 'types/Teachers/teacher';
import { useTeacherRepository } from 'repos/TeacherRepository';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container } from 'Global/GlobalStyle';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { FIRST_YEAR_ACTIVE, LONG_TERM_ACTIVE, SECOND_YEAR_ACTIVE } from './constants';
import { isEmpty } from 'lodash';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';

const defStatuses = [FIRST_YEAR_ACTIVE, SECOND_YEAR_ACTIVE, LONG_TERM_ACTIVE];
const defValues = { status: defStatuses };

/**
 * @description Компонент страницы с учителями
 * @param {Object} props
 * @return {React.ReactNode}
 */
export const Teachers = () => {
  const { push } = useHistory();
  const { locationSearchParams } = useLocationSearchParams();

  const [strings] = useLanguageContext();
  const teacherRepository = useTeacherRepository();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo | undefined>(null);

  /**
   * @description Получить список учителей с пагинацией
   * @returns {Promise<any>}
   */
  const fetchTeachers = useCallback(async (params?: ITeacherGetParams): Promise<any> => {
    setIsLoading(true);
    try {
      const {
        data: { items, pagination }
      } = await teacherRepository.getTeachers({ ...params, limit: 50 });
      setTeachers(items);
      setPaginationInfo(pagination);
      push({ search: queryString.stringify(params, { arrayFormat: 'bracket' }) });
    } catch {
      message.error(strings.SORRY_AN_UNEXPECTED_ERROR_OCCURED_WHEN_TRYING_TO_FETCH_TEACHERS_PLEASE_TRY_AGAIN_LATER);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleUserRefetch = useCallback((): void => {
    fetchTeachers(locationSearchParams);
  }, []);

  useEffect(() => {
    if (isEmpty(locationSearchParams)) {
      fetchTeachers(defValues);
    } else {
      fetchTeachers(locationSearchParams);
    }
  }, []);

  return (
    <Container>
      <TeachersTitle
        total={paginationInfo && paginationInfo?.totalCount}
        fetchTeachers={fetchTeachers}
        defValues={defValues}
      />
      <TableForTeachers
        teachers={teachers}
        paginationInfo={paginationInfo}
        isLoading={isLoading}
        getTeachers={fetchTeachers}
        defValues={defValues}
        handleUserRefetch={handleUserRefetch}
      />
    </Container>
  );
};

export default { Teachers };
