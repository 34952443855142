import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useHistory } from 'react-router-dom';
import { Form, Tabs, Row, Col, Select, Button, message } from 'antd';
import { TutorSelect } from 'Global/components/FormComponents';
import { useAdminRepository } from 'repos/AdminRepository';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { Branch } from 'types';
import React, { useState } from 'react';
import { IChangeTutorParams } from 'types/global';

const { TabPane } = Tabs;
const { Option } = Select;

export const ChangeTutor = () => {
  const [strings] = useLanguageContext();
  const { goBack } = useHistory();
  const [familiesTutorForm] = Form.useForm();
  const [teachersTutorForm] = Form.useForm();

  const adminRepository = useAdminRepository();
  const { branches } = useGlobalCollectionsContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);

  const submitFamilyTutorForm = async (values: IChangeTutorParams): Promise<any> => {
    setHasLoading(true);
    try {
      await adminRepository.changeFamiliesTutor(values);
      message.success(`${strings.SUCCESSFULLY_CHANGED} :)`, 2);
      familiesTutorForm.resetFields();
    } catch {
    } finally {
      setHasLoading(false);
    }
  };
  const submitTeachersTutorForm = async (values: IChangeTutorParams): Promise<any> => {
    setHasLoading(true);
    try {
      await adminRepository.changeTeachersTutor(values);
      message.success(`${strings.SUCCESSFULLY_CHANGED} :)`, 2);
      teachersTutorForm.resetFields();
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  return (
    <Container>
      <StPageHeader onBack={goBack} title={strings.CHANGE_TUTOR} />
      <Tabs>
        <TabPane key="familyTutor" tab={strings.FAMILY_TUTOR}>
          <Form form={familiesTutorForm} onFinish={submitFamilyTutorForm} layout="vertical">
            <Row gutter={10} align="middle">
              <Col lg={6} xs={24}>
                <Form.Item
                  name="branch"
                  label={strings.BRANCH}
                  rules={[{ required: true, message: `${strings.BRANCH} ${strings.IS_REQUIRED}` }]}
                >
                  <Select placeholder={strings.BRANCH} allowClear>
                    {branches.map((branch: Branch) => (
                      <Option key={branch.id} value={branch.id}>
                        {branch.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={6} xs={24}>
                <Form.Item
                  name="oldTutor"
                  label={strings.SELECT_FAMILY_TUTOR}
                  rules={[{ required: true, message: `${strings.TUTOR} ${strings.IS_REQUIRED}` }]}
                >
                  <TutorSelect />
                </Form.Item>
              </Col>
              <Col lg={6} xs={24}>
                <Form.Item
                  name="newTutor"
                  label={strings.SELECT_NEW_TUTOR}
                  rules={[{ required: true, message: `${strings.TUTOR} ${strings.IS_REQUIRED}` }]}
                >
                  <TutorSelect />
                </Form.Item>
              </Col>
              <Col lg={6} xs={24} style={{ marginTop: 8 }}>
                <Button type="primary" size="large" htmlType="submit" loading={hasLoading}>
                  {strings.SAVE}
                </Button>
              </Col>
            </Row>
          </Form>
        </TabPane>
        <TabPane key="admTutor" tab={strings.ADM}>
          <Form form={teachersTutorForm} onFinish={submitTeachersTutorForm} layout="vertical">
            <Row gutter={10} align="middle">
              <Col lg={6} xs={24}>
                <Form.Item
                  name="branch"
                  label={strings.BRANCH}
                  rules={[{ required: true, message: `${strings.BRANCH} ${strings.IS_REQUIRED}` }]}
                >
                  <Select placeholder={strings.BRANCH} allowClear>
                    {branches.map((branch: Branch) => (
                      <Option key={branch.id} value={branch.id}>
                        {branch.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={6} xs={24}>
                <Form.Item
                  name="oldTutor"
                  label={strings.SELECT_ADM}
                  rules={[{ required: true, message: `${strings.ADM} ${strings.IS_REQUIRED}` }]}
                >
                  <TutorSelect />
                </Form.Item>
              </Col>
              <Col lg={6} xs={24}>
                <Form.Item
                  name="newTutor"
                  label={strings.SELECT_NEW_ADM}
                  rules={[{ required: true, message: `${strings.ADM} ${strings.IS_REQUIRED}` }]}
                >
                  <TutorSelect />
                </Form.Item>
              </Col>
              <Col lg={6} xs={24} style={{ marginTop: 8 }}>
                <Button type="primary" size="large" htmlType="submit" loading={hasLoading}>
                  {strings.SAVE}
                </Button>
              </Col>
            </Row>
          </Form>
        </TabPane>
      </Tabs>
    </Container>
  );
};
