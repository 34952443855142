import React from 'react';
import { dateFormat } from 'helpers/dates';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { ChildGroup } from 'types/education';

export const getColumns = strings => [
  {
    title: strings.STUDENT,
    dataIndex: 'student',
    key: 'student',
    width: 240,
    render: (
      { familyId, id },
      {
        student: {
          user: { name, surname }
        }
      }
    ) => {
      return (
        <Link to={`/family/${familyId}/student/${id}`}>
          {name} {surname}
        </Link>
      );
    }
  },
  {
    title: strings.ONE_GROUP,
    dataIndex: 'group',
    key: 'group',
    render: (group: ChildGroup) => group.groupName
  },
  {
    title: strings.START_DATE,
    dataIndex: 'startDate',
    key: 'startDate',
    width: 140,
    render: (date: string) => date && dayjs(date).format(dateFormat)
  },
  {
    title: strings.END_DATE,
    dataIndex: 'endDate',
    key: 'endDate',
    width: 140,
    render: (date: string) => date && dayjs(date).format(dateFormat)
  },
  {
    title: strings.COMMENT,
    dataIndex: 'comment',
    key: 'comment'
  }
];
