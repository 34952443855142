import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import 'whatwg-fetch';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { Application } from './App';
import { isLocalLaunch } from './helpers';
import 'intersection-observer';
import ResizeObserver from 'resize-observer-polyfill';

if (!isLocalLaunch) {
  Sentry.init({
    dsn: 'https://5e366169f5b642ae9b5526f500bc38ca@sentry.io/1837214',
    environment: process.env.REACT_APP_ENV,
    ignoreErrors: [
      'Request failed with status code 401',
      'Failed to fetch',
      'Сетевое соединение потеряно',
      'Network Error',
      'cancelled',
      'отменено',
      'The request timed out',
      'NetworkError when attempting to fetch resource.',
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications'
    ]
  });
}

const urlParams = new URLSearchParams(window.location.search);
const hasIframe = urlParams && urlParams.get('authCode');

if (hasIframe) {
  const resizeObserver = new ResizeObserver(entries => {
    const scrollHeight = entries[0].target.scrollHeight;
    if (scrollHeight !== 0 && scrollHeight > 100) {
      const messageObject = { scrollHeight: scrollHeight };
      window.parent.postMessage(messageObject, '*');
    }
  });
  resizeObserver.observe(document.body);
}

ReactDOM.render(
  <BrowserRouter>
    <Application />
  </BrowserRouter>,
  document.getElementById('root')
);
