import React, { useCallback, useState } from 'react';
import {
  CaretRightOutlined,
  CopyOutlined,
  DeleteOutlined,
  DragOutlined,
  EditOutlined,
  RollbackOutlined
} from '@ant-design/icons';
import { message, Button, Collapse, Row, Tooltip, Switch, Flex } from 'antd';
import { Courses } from 'api/Courses';
import { ModalDeleteLesson } from './ModalDeleteLesson';
import { ModalTransferLesson } from './ModalTransferLesson';
import { OriginalLesson } from 'types/TeacherAccount/Courses';
import { UnitName } from 'types/courses';
import { LessonMaterials } from 'types/materials';
import { ShowOriginalCourseLesson } from '../OriginalCourseLesson/ShowOriginalCourseLesson';
import { Global } from 'api';
import { copyingText } from 'helpers';
import { useHistory } from 'react-router-dom';
import { useLanguageContext } from 'contexts/LanguageContext';
import { SortableHandle } from 'react-sortable-hoc';
import { useCoursesRepository } from '../../../../repos/CoursesRepository';

const { Panel } = Collapse;

interface OriginalCourseLessonProps {
  lesson: OriginalLesson;
  originalCourseId: number | string;
  unitId: number;
  getOriginalCourseById: (bool?: boolean) => Promise<any>;
  unitNames: UnitName[];
  hasTeacherRole: boolean;
}

export const initialMaterialState = {
  approvedMaterials: [],
  customMaterials: [],
  testMaterials: [],
  bonusMaterials: [],
  hiddenMaterials: []
};

export const OriginalCourseLesson = React.memo(
  ({
    originalCourseId,
    unitId,
    unitNames,
    getOriginalCourseById,
    hasTeacherRole,
    lesson,
    lesson: { topic, id: lessonId, revision }
  }: OriginalCourseLessonProps) => {
    const [strings] = useLanguageContext();
    const coursesRepository = useCoursesRepository();
    const [hasLoadDelete, setHasLoadDelete] = useState<boolean>(false);
    const [visibleModal, setVisibleModal] = useState<boolean>(false);
    const [visibleModalTransfer, setVisibleModalTransfer] = useState<boolean>(false);
    const [fetchToMove, setFetchToMove] = useState<boolean>(false);
    const [lessonMaterials, setLessonMaterials] = useState<LessonMaterials>({
      suggestedActivities: { ...initialMaterialState },
      suggestedHomework: { ...initialMaterialState }
    });

    const { host } = window.location;
    const { push } = useHistory();
    const DragHandle = SortableHandle(() => <DragOutlined style={{ cursor: 'move', padding: 4 }} />);

    const getOriginalCourseFiles = useCallback(async (): Promise<any> => {
      try {
        const { data } = await Courses.getOriginalLessonMaterials(lessonId);
        setLessonMaterials(data);
      } catch {}
    }, [lessonId]);

    const deleteOriginalLesson = useCallback(async (unitId: number, originalLessonId: number): Promise<any> => {
      setHasLoadDelete(true);
      try {
        await Courses.deleteOriginalLesson(unitId, originalLessonId);
        message.success(strings.THE_LESSON_WAS_DELETED_SUCCESSFULLY, 2);
        await getOriginalCourseById();
      } catch {
        setHasLoadDelete(false);
      }
    }, []);

    const moveLessonToAnotherUnit = useCallback(async (unitId: number) => {
      try {
        setFetchToMove(true);
        await Courses.moveLessonAnotherUnit(originalCourseId, lessonId, unitId);
        setVisibleModalTransfer(false);
        message.success(strings.LESSON_MOVE_TO_ANOTHER_UNIT_SUCCESSFULLY);
        await getOriginalCourseById();
      } catch {
        setFetchToMove(false);
      }
    }, []);

    const copyOriginalLessonLink = useCallback(
      async e => {
        e.stopPropagation();
        await copyingText(`${host}/original_lesson/${lessonId}`, result => {
          if (result) {
            message.success(strings.LESSON_LINK_IS_SUCCESSFULLY_COPIED);
          } else {
            message.error(strings.FAIL_COPIED);
          }
        });
      },
      [lessonId]
    );
    const handleRevision = async (val: boolean) => {
      try {
        await coursesRepository.toggleOriginalLessonRevision(lessonId, { revision: val });
        message.success(strings.SUCCESSFULLY_CHANGED);
      } catch {
        message.error(strings.SORRY_ERROR, 2);
        await getOriginalCourseById();
      }
    };

    const Extra = (
      <Flex align="center" gap={5}>
        <Flex align="center" gap={5}>
          <Switch size="small" defaultChecked={revision} onChange={handleRevision} />
          {strings.REVISION}
        </Flex>
        <DragHandle />
        <Tooltip title="Copy lesson link" placement="left">
          <Button icon={<CopyOutlined />} size="small" onClick={copyOriginalLessonLink} />
        </Tooltip>
        <Button
          icon={<EditOutlined />}
          size="small"
          onClick={() => push(`/original-lesson/${lessonId}/edit/course/${originalCourseId}`)}
        />
        <Button
          icon={<RollbackOutlined />}
          size="small"
          type="primary"
          onClick={e => {
            e.stopPropagation();
            setVisibleModalTransfer(true);
          }}
        />

        <Button
          icon={<DeleteOutlined />}
          size="small"
          danger
          onClick={e => {
            e.stopPropagation();
            setVisibleModal(true);
          }}
        />
      </Flex>
    );

    const moveMaterial = async (materialId: number, isMark: boolean): Promise<any> => {
      try {
        await Global.editAttachment({ mark: Number(!isMark) }, materialId);
        message.success(strings.SUCCESSFULLY_MOVE, 2);
        await getOriginalCourseFiles();
      } catch {
        message.error(`${strings.ERROR_TRY_AGAIN_LATER} :(`, 2);
      }
    };

    return (
      <>
        <Collapse
          style={{ marginBottom: 10 }}
          collapsible="icon"
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        >
          <Panel header={topic} key={lessonId} extra={!hasTeacherRole && Extra}>
            <ShowOriginalCourseLesson
              lesson={lesson}
              lessonMaterials={lessonMaterials}
              moveMaterial={moveMaterial}
              getOriginalCourseFiles={getOriginalCourseFiles}
            />
          </Panel>
        </Collapse>
        <ModalDeleteLesson
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          loading={hasLoadDelete}
          deleteOriginalLesson={deleteOriginalLesson}
          originalCourseId={originalCourseId}
          lessonId={lessonId}
        />
        <ModalTransferLesson
          unitNames={unitNames}
          visibleModalTransfer={visibleModalTransfer}
          setVisibleModalTransfer={setVisibleModalTransfer}
          unitId={unitId}
          fetchToMove={fetchToMove}
          moveLessonToAnotherUnit={moveLessonToAnotherUnit}
        />
      </>
    );
  }
);
