import React from 'react';
import { Row, DatePicker, Button, Col, Select, Form } from 'antd';
import dayjs from 'dayjs';
import { dateFormat } from 'helpers/dates';
import { useLanguageContext } from 'contexts/LanguageContext';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface FilterHistoryProps {
  types: {
    label: string;
    value: string;
  }[];
  setSelectedType: (value: string) => void;
  setSelectedDateStart: (value: any) => void;
  setSelectedDateEnd: (value: any) => void;
}

/**
 * @description Filter Collaboration History
 * @param props - component props
 * @return {React.ReactNode}
 */
export const FilterHistory: React.FC<FilterHistoryProps> = ({
  types,
  setSelectedDateEnd,
  setSelectedDateStart,
  setSelectedType
}) => {
  const [strings] = useLanguageContext();
  const [form] = Form.useForm();

  /**
   * @description Очистка фильтра
   * @return {void}
   */
  const clearFilter = (): void => {
    form.resetFields();
    setSelectedType(defaultValues.type);
    setSelectedDateStart(defaultValues.date[0]);
    setSelectedDateEnd(defaultValues.date[1]);
  };

  /**
   * @description Изменение type
   * @param {string} новое значение type
   * @return {void}
   */
  const handleTypeChange = (value: string): void => {
    setSelectedType(value);
  };

  /**
   * @description Изменение даты
   * @param {any[]} новое значение даты
   * @return {void}
   */
  const handleDateChange = (date: any[]): void => {
    const [start, end] = date;
    setSelectedDateStart(start);
    setSelectedDateEnd(end);
    form.setFieldsValue({ type: defaultValues.type });
    setSelectedType('all');
  };

  const defaultValues = {
    type: 'all',
    date: [dayjs().subtract(1, 'months'), dayjs()]
  };

  return (
    <Form form={form} initialValues={defaultValues} layout="vertical">
      <Row gutter={10}>
        <Col lg={8}>
          <Form.Item name="type" label={strings.FILTER_BY_TYPE}>
            <Select onChange={handleTypeChange}>
              <Option key="all" value="all">
                {strings.ALL}
              </Option>
              {types.map(({ label, value }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={8}>
          <Form.Item name="date" label={strings.FILTER_BY_DATE}>
            <RangePicker onChange={handleDateChange} format={dateFormat} />
          </Form.Item>
        </Col>
        <Col lg={6}>
          <Form.Item style={{ marginTop: 32 }}>
            <Button size="large" onClick={clearFilter}>
              {strings.CLEAR_FILTER}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default { FilterHistory };
