import React, { useState } from 'react';
import { Typography, Button, Card, Row, Col, Tag } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { EditCommentsModal } from './EditCommentsModal';
import { IReportRegister } from 'types/TeacherAccount/Courses';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';
import { RejectCommentModal } from './RejectCommentModal';
import styled from 'styled-components';
import { FeedbackType } from 'types/Reports';

interface FeedbackItemProps {
  id: number;
  type: FeedbackType;
  comment: string;
  isApproved: boolean;
  refetchReports: () => void;
  register: IReportRegister;
}

const { Text } = Typography;

export const StParagraph = styled.p`
  font-weight: normal;
  margin-bottom: 5px;
`;

/**
 * @description Компонент для рендеренга колонки feedback
 * @param {object} props
 * @return {React.ReactNode}
 */
export const FeedbackItem = (props: FeedbackItemProps) => {
  const { type, isApproved, id, refetchReports, register } = props;
  const [strings] = useLanguageContext();
  const [hasShowEditModal, setHasShowEditModal] = useState<boolean>(false);
  const [hasShowRejectModal, setHasShowRejectModal] = useState<boolean>(false);
  const reportsRepository = useProgressReportsRepository();

  /**
   * @description Approve записи
   * @param {string} type
   */
  const handleApprove = async (type: FeedbackType): Promise<any> => {
    await reportsRepository.registerCommentApprove(id, type);
    await refetchReports();
  };

  const getTag = () => {
    if (isApproved === true) {
      return <Tag color="green">{strings.APPROVED}</Tag>;
    } else if (isApproved === false) {
      return <Tag color="red">{strings.REJECTED}</Tag>;
    }
    return null;
  };

  return (
    <>
      <EditCommentsModal
        hasShowModal={hasShowEditModal}
        setHasShowModal={setHasShowEditModal}
        registerId={register?.id}
        commentForParent={register?.commentForParent}
        commentForParent2={register?.commentForParent2}
        commentForStudent={register?.commentForStudent}
        refetchReports={refetchReports}
        type={type}
      />
      <RejectCommentModal
        hasShowModal={hasShowRejectModal}
        setHasShowModal={setHasShowRejectModal}
        register={register}
        refetchReports={refetchReports}
        type={type}
      />
      <Card.Grid style={{ borderTop: '1px solid #e8e8e8' }}>
        <Row gutter={8} align="middle">
          <Col lg={17} md={24} xs={24}>
            {type === 'student' && (
              <>
                <div>
                  <Text strong style={{ paddingInlineEnd: 10 }}>
                    {strings.FEEDBACK_FOR_STUDENT}
                  </Text>
                  {getTag()}
                </div>
                <StParagraph>{register?.commentForStudent}</StParagraph>
              </>
            )}
            {type === 'parent' && (
              <>
                <div>
                  <Text strong style={{ paddingInlineEnd: 10 }}>
                    {strings.FEEDBACK_FOR_PARENTS}
                  </Text>
                  {getTag()}
                </div>
                <StParagraph>{register?.commentForParent}</StParagraph>
                <StParagraph>{register?.commentForParent2}</StParagraph>
                <StParagraph>{register?.commentForParent3}</StParagraph>
              </>
            )}
            {type === 'supervisor' && (
              <>
                <div>
                  <Text strong style={{ paddingInlineEnd: 10 }}>
                    {strings.FEEDBACK_FOR_OFFICE}
                  </Text>
                </div>
                <StParagraph>{register?.commentForSupervisor}</StParagraph>
              </>
            )}
          </Col>
          <Col
            lg={7}
            md={12}
            xs={24}
            style={{ display: 'flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'end' }}
          >
            {type !== 'supervisor' ? (
              <>
                <Button type="primary" disabled={isApproved ? true : false} onClick={() => handleApprove(type)}>
                  {strings.APPROVE}
                </Button>
                <Button
                  danger
                  disabled={isApproved === false ? true : false}
                  onClick={() => setHasShowRejectModal(true)}
                >
                  {strings.REJECT}
                </Button>
                <Button onClick={() => setHasShowEditModal(true)}>{strings.EDIT}</Button>
              </>
            ) : (
              <Button type="primary" disabled={isApproved ? true : false} onClick={() => handleApprove(type)}>
                {strings.MARK_AS_READ}
              </Button>
            )}
          </Col>
        </Row>
      </Card.Grid>
    </>
  );
};

export default { FeedbackItem };
