import React, { useState } from 'react';
import dayjs from 'dayjs';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, DatePicker, Button, message, Row, Col } from 'antd';
import { dateFormatForBackend, dateFormat } from 'helpers/dates';
import { GroupSelect } from 'Global/components/FormComponentsCompatible/Selects/GroupSelect';
import { addStudentToGroup } from 'api/Families/child';
import { useLanguageContext } from 'contexts/LanguageContext';

interface AddToGroupProps extends FormComponentProps {
  onCancel?: () => void;
  hasOpen?: boolean;
  studentId: number;
  fetchGroupsById: () => void;
}

/**
 * @description Модалка для добавление студента в группу
 * @param {AddToGroupProps} props
 * @return {React.ReactNode}
 */
export const AddToGroupTemplate = (props: AddToGroupProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    onCancel,
    studentId,
    hasOpen,
    fetchGroupsById,
    form: { getFieldDecorator, validateFields, resetFields }
  } = props;
  const [strings] = useLanguageContext();

  /**
   * @description Добавление студента в группу
   * @param {string} date
   * @param {number} groupId
   * @return {Promise<any>}
   */
  const addGroupToStudent = async (date: string, groupId: number): Promise<any> => {
    setLoading(true);
    try {
      const { status } = await addStudentToGroup(groupId, studentId, { startAt: date });
      if (status === 200) {
        resetFields();
        onCancel();
        message.success(strings.SUCCESS_ADDED_STUDENT_TO_A_GROUP);
        fetchGroupsById();
      }
    } finally {
      setLoading(false);
    }
  };

  /**
   * @description Отправка формы
   * @param {React.MouseEvent<HTMLElement, MouseEvent>} e
   */
  const handleSubmit = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        const { startAt, group } = values;
        const backendFormatDate = dayjs(startAt).format(dateFormatForBackend);
        addGroupToStudent(backendFormatDate, group);
      }
    });
  };

  /**
   * @description Кнопки для модалки
   */
  const footerButtons = [
    <Button onClick={onCancel} key="cancelBtn">
      {strings.CANCEL}
    </Button>,
    <Button type="primary" onClick={handleSubmit} loading={loading} disabled={loading} key="addToGroup">
      {strings.ADD}
    </Button>
  ];

  return (
    (<Modal title={strings.ADD_TO_A_GROUP} open={hasOpen} onCancel={onCancel} footer={footerButtons}>
      <Form layout="vertical">
        <Row gutter={2}>
          <Col lg={12}>
            <GroupSelect
              name="group"
              required
              getFieldDecorator={getFieldDecorator}
              placeholder={`${strings.SEARCH_GROUP}`}
            />
          </Col>
          <Col lg={12}>
            <MyFormItem label={strings.DATE}>
              {getFieldDecorator('startAt', {
                initialValue: dayjs(),
                rules: [{ required: true, message: strings.SELECT_DATE }]
              })(<DatePicker placeholder={`${strings.SELECT_DATE}`} format={dateFormat} />)}
            </MyFormItem>
          </Col>
        </Row>
      </Form>
    </Modal>)
  );
};

export const AddToGroup = Form.create<AddToGroupProps>({})(AddToGroupTemplate);

export default { AddToGroup };
