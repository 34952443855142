import React from 'react';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Button, Input, Row, Col, Radio, message, Tooltip, Typography } from 'antd';
import { IDisciplineAssessment } from 'types/accessment';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { scrollTop } from 'helpers/scroll';
import { useLanguageContext } from 'contexts/LanguageContext';
import { isEmpty } from 'lodash';
import { useAssessmentsRepository } from 'repos/AssessmentsRepository';
import { useUserContext } from 'contexts/UserContext';
import { StyledForm } from './index';
import {checkTypeForInput} from "../../../../helpers/booleanFomatValues";

const { Text } = Typography;

export const getRadioBtnGroup = () => (
  <Radio.Group>
    <Radio.Button value="ni">Ni</Radio.Button>
    <Radio.Button value="s">S</Radio.Button>
    <Radio.Button value="g">G</Radio.Button>
  </Radio.Group>
);

interface DisciplineAssessmentProps extends FormComponentProps {
  discipline: IDisciplineAssessment;
}

/**
 * @description DisciplineAssessment component
 * @return {React.ReactNode}
 */
const DisciplineAssessmentTemplate = (props: DisciplineAssessmentProps) => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const assessmentsRepository = useAssessmentsRepository();

  const {
    form: { getFieldDecorator, setFieldsValue, validateFieldsAndScroll },
    discipline: {
      type,
      id,
      fillingInRegisters,
      comingOnTime,
      progressReports,
      qualityOfFeedback,
      summaryComment,
      finalMark
    }
  } = props;

  /**
   * @description Очистка формы
   * @param {string} fieldName
   * @return {void}
   */
  const clearFieldByName = (fieldName: string): void => setFieldsValue({ [fieldName]: '' });

  /**
   * @description Обработчик на submit формы
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values) => {
      message.loading(`${strings.DISCIPLINE} ${strings.UPDATING}...`, 1);
      if (isEmpty(errors)) {
        try {
          await assessmentsRepository.editAssessmentByLessonId(id, { type, ...values });
          message.success(strings.SUCCESSFULLY_UPDATED);
          scrollTop();
        } catch {}
      }
    });
  };

  return (
    <StyledForm layout="vertical" onSubmit={handleSubmit}>
      <Row>
        <Col lg={24}>
          <MyFormItem
            label={
              <>
                {strings.FILLING_IN_REGISTERS}
                <Tooltip title={strings.G_MORE_THAN_90_S_80_90_NI_LESS_THAN_80}>
                  <InfoCircleTwoTone twoToneColor="#1890ff" style={{ fontSize: 22, margin: 10 }} />
                </Tooltip>
              </>
            }
          >
            {getFieldDecorator('fillingInRegisters', {
              initialValue: fillingInRegisters,
              rules: [{ required: false }]
            })(getRadioBtnGroup())}
            <Button
              size="middle"
              style={{ marginInlineStart: 10 }}
              onClick={() => clearFieldByName('fillingInRegisters')}
            >
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem
            label={
              <>
                {strings.COMING_ON_TIME}
                <Tooltip title={strings.G_ALWAYS_S_1_TIME_LATE_NI_2_OR_MORE_TIMES_LATE}>
                  <InfoCircleTwoTone twoToneColor="#1890ff" style={{ fontSize: 22, margin: 10 }} />
                </Tooltip>
              </>
            }
          >
            {getFieldDecorator('comingOnTime', {
              initialValue: comingOnTime,
              rules: [{ required: false }]
            })(getRadioBtnGroup())}
            <Button size="middle" style={{ marginInlineStart: 10 }} onClick={() => clearFieldByName('comingOnTime')}>
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem
            label={
              <>
                {strings.PROGRESS_REPORTS}
                <Tooltip title={strings.G_ALL_ON_TIME_S_1_2_DAYS_AFTER_DEADLINE_NI_3_OR_MORE_DAYS_AFTER_DEADLINE}>
                  <InfoCircleTwoTone twoToneColor="#1890ff" style={{ fontSize: 22, margin: 10 }} />
                </Tooltip>
              </>
            }
          >
            {getFieldDecorator('progressReports', {
              initialValue: progressReports,
              rules: [{ required: false }]
            })(getRadioBtnGroup())}
            <Button size="middle" style={{ marginInlineStart: 10 }} onClick={() => clearFieldByName('progressReports')}>
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem
            label={
              <>
                {strings.QUALITY_OF_FEEDBACK}
                <Tooltip
                  title={
                    strings.G_ALL_ARE_FINE_TYPOS_OR_MINOR_MISTAKES_S_NO_COPYPASTA_NOT_ALWAYS_INFORMATIVE_RARE_MISTAKES_NI_COPYPASTA_AND_OR_NOT_INFORMATIVE_AND_OR_FREQUENT_MISTAKES
                  }
                >
                  <InfoCircleTwoTone twoToneColor="#1890ff" style={{ fontSize: 22, margin: 10 }} />
                </Tooltip>
              </>
            }
          >
            {getFieldDecorator('qualityOfFeedback', {
              initialValue: qualityOfFeedback,
              rules: [{ required: false }]
            })(getRadioBtnGroup())}
            <Button
              size="middle"
              style={{ marginInlineStart: 10 }}
              onClick={() => clearFieldByName('qualityOfFeedback')}
            >
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
      </Row>
      <Row>
        <Col lg={24} style={{ background: '#e6f7ff', padding: '10px 20px 30px 20px', borderRadius: '5px' }}>
          <MyFormItem
            label={
              <>
                {strings.SUMMARY_COMMENTS}
                <Text type="secondary" style={{ float: 'right', fontSize: '12px' }}>
                  {strings.ONLY_THIS_PART_IS_VISIBLE_TO_THE_TEACHER}
                </Text>
              </>
            }
          >
            {getFieldDecorator('summaryComment', {
              initialValue: checkTypeForInput(summaryComment),
              rules: [{ required: false }]
            })(<Input.TextArea style={{ height: 300 }} placeholder={strings.COMMENT} />)}
          </MyFormItem>
          <MyFormItem label={strings.FINAL_MARK}>
            {getFieldDecorator('finalMark', {
              initialValue: finalMark,
              rules: [{ required: false }]
            })(getRadioBtnGroup())}
            <Button size="middle" style={{ marginInlineStart: 10 }} onClick={() => clearFieldByName('finalMark')}>
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Col lg={24}>
          <Button type="primary" size="large" htmlType="submit">
            {strings.SAVE}
          </Button>
        </Col>
      </Row>
    </StyledForm>
  );
};

export const DisciplineAssessment = Form.create<DisciplineAssessmentProps>({})(DisciplineAssessmentTemplate);

export default { DisciplineAssessment };
