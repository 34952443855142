import React from 'react';
import { Menu } from 'antd';
import { switchUser } from 'helpers/switchUser';
import { ConnectAccount } from 'types/user';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Link } from 'react-router-dom';
import { EDIT_PARENT_PROFILE } from '../../../Student&Parent/routes';

/**
 * @description Пользовательское меню
 * @return {React.ReactNode}
 */
export const UserMenu = () => {
  const [user] = useUserContext();
  const [strings] = useLanguageContext();
  const hasParent = user?.hasRoles?.parent;

  return (
    <Menu className="ant-dropdown-menu">
      {hasParent && (
        <Menu.Item
          key="edit-account"
          className="dropdown-menu-item-line"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Link to={EDIT_PARENT_PROFILE}>{strings.EDIT_SUBSCRIPTIONS}</Link>
        </Menu.Item>
      )}
      {user?.myConnectedAccounts?.map((connectAccount: ConnectAccount) => (
        <Menu.Item
          key={connectAccount.user.email}
          onClick={() => switchUser(connectAccount.user.email)}
          className="dropdown-menu-item-line"
        >
          <div style={{ lineHeight: '16px' }}>
            {connectAccount.user.name} {connectAccount.user.surname}
            <br />
            <span style={{ color: '#999', fontSize: 10 }}>
              {strings.LOG_IN_AS} {connectAccount.type}
            </span>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default { UserMenu };
