import { RouteProps } from 'react-router';
import * as parent from 'Student&Parent/routes';
import { FULL_NEWS } from 'Admin/News/routes';
import { NewsFull } from 'Global/modules/Home/News/NewsFull';
import { BASE_CHAT } from 'Global/routes';
import { schedule } from '../schedule';
import { MainPageParent, Journal, HowToLg, UploadHomework, Chat } from 'Student&Parent';
import { withCabinetLockedGuard } from 'guards/cabinetLockedGuard';
import { Contract } from 'Student&Parent/Contract';

export const parentRoutes: RouteProps[] = [
  ...schedule,
  {
    path: parent.UPLOAD_HOMEWORK,
    component: withCabinetLockedGuard(UploadHomework),
    exact: true
  },
  {
    path: parent.HOW_TO_LG_LINK,
    component: withCabinetLockedGuard(HowToLg),
    exact: true
  },
  {
    path: parent.PARENT_MAIN,
    component: withCabinetLockedGuard(MainPageParent),
    exact: true
  },
  {
    path: FULL_NEWS,
    component: withCabinetLockedGuard(NewsFull)
  },
  {
    path: parent.JOURNAL,
    component: withCabinetLockedGuard(Journal),
    exact: true
  },
  {
    path: BASE_CHAT,
    component: withCabinetLockedGuard(Chat),
    exact: true
  },
  {
    path: parent.CONTRACT,
    component: withCabinetLockedGuard(Contract),
    exact: true
  }
];

export default { parentRoutes };
