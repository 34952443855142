import React from 'react';
import { CheckCircleTwoTone, MinusCircleTwoTone } from '@ant-design/icons';
import { ITraining } from 'types/education';
import { ShowMoreList } from '../../../Global/components/ShowMoreList';

export const getColumns = strings => [
  {
    title: strings.NAME,
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: strings.NAME_FOR_RG,
    dataIndex: 'nameRG',
    key: 'nameRG',
    width: 200
  },
  {
    title: strings.COMMENT,
    dataIndex: 'comment',
    key: 'comment'
  },
  {
    title: strings.ACTIVE,
    dataIndex: 'isActive',
    key: 'isActive',
    className: 'clmn-center',
    render: (isActive: boolean) =>
      isActive ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <MinusCircleTwoTone twoToneColor="#f5222d" />
  },
  {
    title: strings.TRAININGS,
    dataIndex: 'trainings',
    key: 'color',
    render: (trainings: ITraining[]) => trainings?.isNotEmpty() && <ShowMoreList items={trainings?.map(({ name }) => name)} />
  }
];
export default { getColumns };
