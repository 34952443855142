import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, Button, Col, Row, message, Input, Upload } from 'antd';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { useSupportRepository } from 'repos/SupportRepository';
import { Chameleon } from '../../components/Chameleon';
import { getAllFormData } from 'helpers';

interface BtnAndSupportModalProps {
  form: WrappedFormUtils;
}

/**
 * @description - Компонент модального окна Тех поддрежки на странице авторизации
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const BtnAndSupportModalTemplate = (props: BtnAndSupportModalProps) => {
  const supportRepository = useSupportRepository();
  const [files, setFiles] = useState<any[]>([]);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);

  const {
    form: { getFieldDecorator, validateFieldsAndScroll, resetFields }
  } = props;

  const closeModal = (): void => {
    setHasShowModal(false);
  };

  /**
   * @description Сабмит формы, запрос на создание акканута
   * @param {Event} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        setHasLoading(true);
        try {
          if (files.isNotEmpty()) {
            values['attachments'] = files;
          }
          const params = getAllFormData(values);
          await supportRepository.createTicketFromAuthPage(params);
          message.success('Successfully sent :)');
          resetFields();
          closeModal();
        } catch {
          message.error('Ticket creation error, try again later :(');
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  const getFooter = () => {
    const buttons = [
      <Button key="back" onClick={closeModal}>
        Cancel
      </Button>,
      <Button type="primary" key="action" loading={hasLoading} htmlType="submit" onClick={handleSubmit}>
        Send
      </Button>
    ];
    return buttons;
  };
  const handleUpload = () => {
    return false;
  };

  const uploadProps = {
    name: 'files',
    multiple: true,
    beforeUpload: handleUpload,
    onChange: ({ fileList }) => {
      setFiles(
        fileList.map(function (file) {
          if (file.hasOwnProperty('originFileObj')) {
            return file.originFileObj;
          }
          return file;
        })
      );
    },
    fileList: files
  };

  const form = (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Row gutter={[10, 10]}>
        <Col lg={12} xs={24}>
          <MyFormItem label="Email" colon={false}>
            {getFieldDecorator('email', {
              rules: [{ required: true, message: 'Email is required' }]
            })(<Input />)}
          </MyFormItem>
        </Col>
        <Col lg={12} xs={24}>
          <MyFormItem label="Topic" colon={false}>
            {getFieldDecorator('subject', {
              rules: [{ required: true, message: 'Topic is required' }]
            })(<Input />)}
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem label="Message" colon={false}>
            {getFieldDecorator('message', {
              rules: [{ required: true, message: 'Message is required' }]
            })(<Input.TextArea style={{ height: '200px' }} />)}
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem label="Attachments" colon={false}>
            <Upload {...uploadProps}>
              <Button>
                <UploadOutlined /> Upload
              </Button>
            </Upload>
          </MyFormItem>
        </Col>
      </Row>
    </Form>
  );
  return (
    <>
      <Button type="primary" style={{ marginTop: '1rem' }} onClick={() => setHasShowModal(true)}>
        Contact support
      </Button>
      <Modal
        title={
          <>
            <span className="anticon" style={{ position: 'relative', paddingInlineEnd: 5 }}>
              <Chameleon />
            </span>
            <span>Contact AMI team</span>
          </>
        }
        footer={getFooter()}
        open={hasShowModal}
        onCancel={() => setHasShowModal(false)}
        style={{ top: 20 }}
        width={650}
        destroyOnClose={true}
      >
        {form}
      </Modal>
    </>
  );
};

export const BtnAndSupportModal = Form.create<BtnAndSupportModalProps>({})(BtnAndSupportModalTemplate);

export default { BtnAndSupportModal };
