import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Badge, Modal, Flex } from 'antd';
import { BASE_CHAT } from 'Global/routes';
import { ModalMessageToYulia } from 'Global/components/ModalMessage';
import { ModalTutor } from 'Global/components/ModalTutor';
import { HeaderDesktop } from 'Global/components/Header/HeaderDesktop';
import { HeaderMobile } from 'Global/components/Header/HeaderMobile';
import { RejectedCommentsModal } from 'Global/components/Header/RejectedCommentsModal';
import { useUserContext } from 'contexts/UserContext';
import { useThreadsContext } from 'contexts/ThreadsContext';
import { FeedbackModal } from 'Global/modules/Support/Modal';
import { useLanguageContext } from 'contexts/LanguageContext';
import { BranchBruner } from 'types/branches';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const StParagraph = styled.p`
  margin: 5px;
  line-height: 1.5;
`;

export const Header = React.memo(() => {
  const { push } = useHistory();
  const [strings] = useLanguageContext();
  const [user, , , , logout] = useUserContext();
  const [unreadThreads] = useThreadsContext();
  const hasMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [hasShowMessageToYulia, setHasShowMessageToYulia] = useState<boolean>(false);
  const [hasShowSupport, setHasShowSupport] = useState<boolean>(false);
  const [hasShowTutor, setHasShowTutor] = useState<boolean>(false);
  const [hasShowPopover, setHasShowPopover] = useState<boolean>(false);
  const [hasShowDropdown, setHasShowDropdown] = useState<boolean>(false);
  const [hasShowRejectedCommentsModal, setHasShowRejectedCommentsModal] = useState<boolean>(false);
  const hasBruner = user?.branch.id === BranchBruner.id;
  const hasParent = user?.hasRoles?.parent;
  const hasTeacher = user?.hasRoles?.teacher;
  const messageForUser = !user?.hasRoles?.teacher ? strings.MESSAGES_FROM_TEACHERS : strings.MESSAGES_FROM_PARENTS;

  const [{ confirm }, contextHolder] = Modal.useModal();

  const goToChat = () => {
    // если это мобилка, то при клике закрываем меню (popover)
    if (hasMobile) {
      setHasShowPopover(false);
    }

    push(BASE_CHAT);
  };

  const popoverContent = !user?.hasRoles?.student && (
    <Flex vertical className="popover-content hover-links">
      {hasTeacher && (
        <Badge
          count={Number(user.rejectedCommentsCount || 0)}
          showZero
          style={{ backgroundColor: user?.rejectedCommentsCount === '0' ? '#52c41a' : '#f5222d' }}
        >
          <StParagraph onClick={() => setHasShowRejectedCommentsModal(true)}>{strings.REJECTED_COMMENTS}</StParagraph>
        </Badge>
      )}
      {hasParent && (
        <StParagraph onClick={() => handleClickContent(setHasShowTutor)}>{strings.MESSAGE_TO_YOUR_TUTOR}</StParagraph>
      )}
      {!(hasBruner && (hasParent || hasTeacher)) && (
        <StParagraph onClick={() => handleClickContent(setHasShowMessageToYulia)}>
          {strings.MESSAGE_FOR_JULIA_DESIATNIKOVA}
        </StParagraph>
      )}
      <Badge count={unreadThreads}>
        <StParagraph onClick={goToChat}>{messageForUser}</StParagraph>
      </Badge>
      <StParagraph onClick={() => handleClickContent(setHasShowSupport)}>{strings.TECHNICAL_SUPPORT}</StParagraph>
    </Flex>
  );

  /**
   * @description Функция изменения show модалок
   * @param {(boolean) => void} func функция которую нужно установить как true
   */
  const handleClickContent = func => {
    setHasShowMessageToYulia(false);
    setHasShowSupport(false);
    setHasShowTutor(false);
    setHasShowPopover(false);
    func(true);
  };

  /**
   * @description Подтвердить выход
   * @returns {void}
   */
  const confirmLogout = (): void => {
    confirm({
      title: strings.DO_YOU_WANT_LOGOUT,
      onOk() {
        logout();
      },
      onCancel() {},
      cancelText: strings.CANCEL,
      okText: strings.LOGOUT
    });
  };

  const modals = () => {
    return (
      <>
        <ModalMessageToYulia
          title={strings.MESSAGE_FOR_JULIA_DESIATNIKOVA}
          visible={hasShowMessageToYulia}
          onClose={() => setHasShowMessageToYulia(false)}
          user={user}
        />
        <FeedbackModal onHide={() => setHasShowSupport(false)} hasShow={hasShowSupport} />
        <ModalTutor visible={hasShowTutor} onClose={() => setHasShowTutor(false)} />
        <RejectedCommentsModal
          onClose={() => setHasShowRejectedCommentsModal(false)}
          hasShow={hasShowRejectedCommentsModal}
        />
      </>
    );
  };

  if (hasMobile) {
    return (
      <HeaderMobile
        handleClickContent={handleClickContent}
        hasShowPopover={hasShowPopover}
        setHasShowPopover={setHasShowPopover}
        badgeCount={unreadThreads}
        setHasShowTutor={setHasShowTutor}
        popoverContent={popoverContent}
        setHasShowDropdown={setHasShowDropdown}
        hasShowDropdown={hasShowDropdown}
        confirmLogout={confirmLogout}
      >
        {contextHolder}
        {modals()}
      </HeaderMobile>
    );
  } else {
    return (
      <HeaderDesktop
        handleClickContent={handleClickContent}
        popoverContent={popoverContent}
        setHasShowTutor={setHasShowTutor}
        setHasShowDropdown={setHasShowDropdown}
        confirmLogout={confirmLogout}
      >
        {contextHolder}
        {modals()}
      </HeaderDesktop>
    );
  }
});
