import React from 'react';
import dayjs from 'dayjs';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { StyledUpcomingSlot, StyledLessonTime, StyledRoom, StyledRoomColor, StyledGroupName } from './styles';
import { useLanguageContext } from 'contexts/LanguageContext';

/**
 * @description Карточка урока (слота) на главной учителя
 * @param {object} slot
 * @return {React.ReactNode}
 */
export const UpcomingSlot = ({ slot }) => {
  const [strings] = useLanguageContext();
  const {
    startAt,
    endAt,
    room,
    lesson: {
      id: lessonId,
      course,
      course: { subject }
    },
    studentsCount
  } = slot;

  const time = `${dayjs(startAt).format('HH:mm')} - ${dayjs(endAt).format('HH:mm')}`;

  return (
    <Link to={`/le/${lessonId}`}>
      <StyledUpcomingSlot>
        <div>
          <Row>
            <Col xs={24}>
              <StyledLessonTime>{time}</StyledLessonTime>
            </Col>
          </Row>
          <Row>
            <Col xs={14}>
              <StyledRoom>
                <StyledRoomColor color={room.color} />
                <p>{room.name}</p>
              </StyledRoom>
            </Col>
            <Col xs={10}>
              <p>{subject.name}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={14}>
              <StyledGroupName to={`/group/${course.id}`}>{course?.groupName}</StyledGroupName>
            </Col>
            <Col xs={10}>
              <p>
                {studentsCount} {strings.STUDENTS}
              </p>
            </Col>
          </Row>
        </div>
      </StyledUpcomingSlot>
    </Link>
  );
};
