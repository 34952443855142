import React, { useEffect, useState } from 'react';
import { Table, message, Typography } from 'antd';
import { Loader } from 'Global/components/Loader/Loader';
import { Families } from 'api/Families';
import { columns } from './columns';
import { DepositForm } from './Form';
import { DepositModal } from './Modal';
import { IDeposit } from 'types/Family';
import { downloadSomeFile } from 'helpers';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Title } = Typography;
interface DepositProps {
  id: number;
  setFinanceShouldUpdate: any;
}

/**
 * @description Family deposits
 * @param {object} props
 * @return {React.ReactNode}
 */
export const Deposit = (props: DepositProps) => {
  const [strings] = useLanguageContext();
  const [deposits, setDeposits] = useState<IDeposit[]>([]);
  const [totalSum, setTotalSum] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingCancel, setLoadingCancel] = useState<number | null>(null);
  const [loadingPaid, setLoadingPaid] = useState<number | null>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [depositId, setDepositId] = useState<number | null>(null);
  const { id, setFinanceShouldUpdate } = props;

  /**
   * @description Получает депозит по id семьи
   * @param {number} familyId
   * @return {Promise<any>}
   */
  const getDepositById = async (familyId: number): Promise<any> => {
    setLoading(false);
    await Families.getDepositById(familyId)
      .then(({ data }) => {
        setDeposits(data.deposits);
        setTotalSum(data.sum);
        setLoading(true);
      })
      .catch(() => {});
  };

  /**
   * @description Добавляет депозит в семью
   * @param {number} familyId
   * @param {number} sum
   * @param {boolean} addToHistory
   * @return {Promise<any>}
   */
  const addDepositFamily = async (
    familyId: number,
    sum: number,
    addToHistory: boolean,
    downloadFilePdf?: boolean
  ): Promise<any> => {
    if (downloadFilePdf) {
      return await downloadSomeFile(`/api/families/${familyId}/deposit/manual`, null, { sum, addToHistory })
        .then(() => {
          message.success('Deposit added successfully!');
          getDepositById(id);
        })
        .catch(() => {});
    } else {
      return await Families.addDeposit(familyId, sum, addToHistory)
        .then(() => {
          message.success('Deposit added successfully!');
          getDepositById(id);
        })
        .catch(() => {});
    }
  };

  /**
   * @description Отменить депозит
   * @param {number} depositId
   * @return {Promise<any>}
   */
  const cancelDepositFamily = async (depositId: number): Promise<any> => {
    return await Families.cancelDeposit(depositId)
      .then(data => {
        message.success('Deposit canceled');
        getDepositById(id);
      })
      .catch(() => {});
  };

  /**
   * @description Оплатить депозит
   * @param {number} depositId
   * @return {Promise<any>}
   */
  const paidDepositFamily = async (depositId: number): Promise<any> => {
    return await Families.paidDeposit(depositId)
      .then(data => {
        message.success('Deposit paid fully!');
        getDepositById(id);
      })
      .catch(() => {});
  };

  /**
   * @description Оплатить депозит частично
   * @param {number} depositId
   * @param {object} params
   * @return {Promise<any>}
   */
  const partiallyDepositFamily = async (depositId: number, params: object): Promise<any> => {
    return await Families.partiallyDeposit(depositId, params)
      .then(data => {
        message.success('Deposit paid partially!');
        getDepositById(id);
      })
      .catch(() => {});
  };

  /**
   * @description Перенос депозита в финансы
   * @param {number} familyId
   * @param {number} sum
   * @return {Promise<any>}
   */
  const transferDepositToFinance = async (familyId: number, sum: number): Promise<any> => {
    return await Families.transferDepositToFinance(familyId, sum)
      .then(() => {
        message.success('Transfer was successful!');
        setFinanceShouldUpdate();
        getDepositById(id);
      })
      .catch(() => {});
  };

  const hideModal = () => setModalVisible(false);

  useEffect(() => {
    getDepositById(id);
  }, [id]);

  return (
    <Loader spinning={!loading}>
      <DepositForm
        addDepositFamily={addDepositFamily}
        transferDepositToFinance={transferDepositToFinance}
        familyId={id}
      />
      <Title level={4} style={{ padding: 5 }}>
        {strings.TOTAL_SUM} <span style={{ color: totalSum >= 0 ? '#52c41a' : 'inherit' }}>{totalSum}€</span>
      </Title>
      <Table
        dataSource={deposits}
        rowKey="id"
        columns={columns(
          cancelDepositFamily,
          loadingCancel,
          setLoadingCancel,
          paidDepositFamily,
          loadingPaid,
          setLoadingPaid,
          setDepositId,
          setModalVisible
        )}
        bordered
        pagination={false}
      />
      <DepositModal
        partiallyDepositFamily={partiallyDepositFamily}
        depositId={depositId}
        visible={modalVisible}
        hideModal={hideModal}
      />
    </Loader>
  );
};

export default { Deposit };
