import styled from 'styled-components';
import { Flex } from 'antd';
import { responsive } from 'helpers';

export const MaterialItem = styled.div`
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
`;

export const MaterialItemDownload = styled.div`
  font-size: 12px;
  margin-bottom: 2px;
  padding-inline-start: 22px;
  display: flex;
  align-items: baseline;

  & label {
    margin-inline-end: 6px;
  }

  & .material-name {
    color: #1890ff;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const MaterialItemLabel = styled.span`
  cursor: pointer;
  user-select: none;
`;

export const LessonInfoContainer = styled.div`
  padding-inline-start: 40px;

  @media (max-width: ${responsive.MD}) {
    padding-inline-start: 0;
  }
`;

export const DescriptionLessonItem = styled.p``;

export const JournalStatusColor = styled.div<{ color: string }>`
  background: ${props => props.color};
  width: 15px;
  height: 15px;
  border-radius: 50%;
`;
export const StInputPercent = styled(Flex)`
  width: 120px;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  flex-wrap: nowrap;
  .ant-input-number {
    width: 60px;
  }
`;

export default {
  MaterialItem,
  MaterialItemDownload,
  MaterialItemLabel,
  LessonInfoContainer,
  DescriptionLessonItem,
  JournalStatusColor
};
