const fieldsName = 'customFields';
// Поле бранча
export const CUSTOM_FIELD_BRANCH_ID: string = `${fieldsName}[10001597219602]`;
// Поле типа обращения
export const CUSTOM_FIELD_TICKET_TYPE_ID: string = `${fieldsName}[10001639478162]`;
// Поле важности обращения
export const CUSTOM_FIELD_SEVERITY_ID: string = `${fieldsName}[9987417153042]`;
// Поле кол-ва юзеров, которых затрагивает обращение
export const CUSTOM_FIELD_USERS_AFFECTED_ID: string = `${fieldsName}[10001680061586]`;
// Роль пользователя (только для ЛК родителя и студента)
export const CUSTOM_FIELD_MY_ROLE_ID: string = `${fieldsName}[10001602199186]`;

export const CUSTOM_FIELD_STATUS_ID = 9973976481170;

export const CUSTOM_FIELD_ROLE_WITHOUT_FIELD = 10001602199186;

export default {
  CUSTOM_FIELD_BRANCH_ID,
  CUSTOM_FIELD_TICKET_TYPE_ID,
  CUSTOM_FIELD_SEVERITY_ID,
  CUSTOM_FIELD_USERS_AFFECTED_ID,
  CUSTOM_FIELD_MY_ROLE_ID,
  CUSTOM_FIELD_STATUS_ID,
  CUSTOM_FIELD_ROLE_WITHOUT_FIELD
};
