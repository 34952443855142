import React from 'react';
import { Tag, Typography, Row } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
const { Text } = Typography;

interface TextCommentaryProps {
  feedbackForSupervisor: string;
  setFeedbackForSupervisor: (text: string) => void;
}

/**
 * @description Tекстовое поле фидбэка для офиса
 * @return {React.ReactNode}
 */
export const TextCommentary = ({ feedbackForSupervisor, setFeedbackForSupervisor }: TextCommentaryProps) => {
  const [strings] = useLanguageContext();
  const { hashTags } = useGlobalCollectionsContext();

  const onSelectTag = tag => {
    const text = feedbackForSupervisor !== null ? feedbackForSupervisor : '';
    setFeedbackForSupervisor(`${text} ${tag}`);
  };

  return (
    <Row style={{ marginBottom: 20 }}>
      <Text strong style={{ width: '100%' }}>
        {strings.COMMENTARY}
      </Text>
      <div
        dangerouslySetInnerHTML={{
          __html:
            feedbackForSupervisor &&
            feedbackForSupervisor.replace(/#(\S+)/gi, '<span style="background: #fe8ca436;">#$1</span>').trim() +
              '<span>&nbsp;</span>'
        }}
        onBlur={e => setFeedbackForSupervisor(e.currentTarget.textContent)}
        contentEditable="true"
        style={{
          border: '1px solid #ececec',
          borderRadius: 5,
          padding: 10,
          margin: '12px 0',
          lineHeight: '1.5',
          height: 120,
          overflow: 'auto',
          width: '100%'
        }}
      />
      <div style={{ lineHeight: '2' }}>
        {hashTags.map(item => (
          <Tag color="#fe8ca4" key={item.id} onClick={() => onSelectTag(item.name)} style={{ cursor: 'pointer' }}>
            {item.name}
          </Tag>
        ))}
      </div>
    </Row>
  );
};
