import { httpService } from 'api/core';

/**
 * @description Получить депозит семьи
 * @param {number} familyId
 * @return {Promise<any>}
 */
export const getDepositById = (familyId: number): Promise<any> => httpService.get(`/api/families/${familyId}/deposit`);

/**
 * @description Добавить депозит в семью
 * @param {number} familyId
 * @param {number} sum
 * @param {boolean} addToHistory
 * @return {Promise<any>}
 */
export const addDeposit = (familyId: number, sum: number, addToHistory: boolean): Promise<any> =>
  httpService.post(`/api/families/${familyId}/deposit/manual`, { sum, addToHistory });

/**
 * @description Отменить депозит
 * @param {number} depositId
 * @return {Promise<any>}
 */
export const cancelDeposit = (depositId: number): Promise<any> =>
  httpService.post(`/api/families/deposit/${depositId}/cancel`);

/**
 * @description Оплатить депозит
 * @param {number} depositId
 * @return {Promise<any>}
 */
export const paidDeposit = (depositId: number): Promise<any> =>
  httpService.post(`/api/families/deposit/${depositId}/paid`);

/**
 * @description Частично оплатить депозит
 * @param {number} depositId
 * @param {object} params
 * @return {Promise<any>}
 */
export const partiallyDeposit = (depositId: number, params: object): Promise<any> =>
  httpService.post(`/api/families/deposit/${depositId}/partially`, params);

/**
 * @description Перенос депозита в финансы
 * @param {number} familyId
 * @param {number} sum
 * @return {Promise<any>}
 */
export const transferDepositToFinance = (familyId: number, sum: number): Promise<any> =>
  httpService.post(`/api/families/${familyId}/deposit/transfer`, { sum });

export default { getDepositById, addDeposit, cancelDeposit, paidDeposit, partiallyDeposit, transferDepositToFinance };
