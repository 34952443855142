import dayjs from 'dayjs';
import {Admin, Stage} from '../types';
import {Teacher as ITeacher} from '../types/Teachers/teacher';
import {TeacherWage} from '../types/Teachers/wages';
import {ClassRoster, Group} from 'types/education';
import {RegisterType, ReportingType} from "../types/global";

export const mockAdmin: Admin = {
  id: 0,
  branch: {
    id: 1,
    name: 'Moscow',
    type: 'lg'
  },
  email: '',
  name: '',
  surname: '',
  mobile: '',
  position: '',
  sex: 0,
  tags: [],
  tagsAge: [],
  tasksCounter: 0,
  value: '',
  isAccessClickUp: true,
  hasClickUpToken: true
};

export const mockSubject = {
  id: 0,
  name: ''
};

export const mockStage: Stage = {
  id: 0,
  name: '',
  registerType: RegisterType.school,
  reportType: ReportingType.school,
  performer: {
    id: 0,
    name: ''
  }
};

export const mockGroup: Group = {
  id: 0,
  name: '',
  groupName: '',
  version: '',
  duration: 0,
  isActive: false,
  subject: {
    id: 0,
    name: ''
  },
  branch: {
    id: 0,
    name: '',
    type: 'lg'
  }
};

export const mockTeacher: ITeacher = {
  additionalSubjects: [
    {
      id: 0,
      cost: 0,
      name: ''
    }
  ],
  approvedByJulia: false,
  approvedByJuliaAudio: '',
  approvedByJuliaComment: '',
  birthDate: '',
  branch: {
    id: 0,
    name: '',
    type: 'lg'
  },
  email: '',
  experts: [mockAdmin],
  id: 0,
  individualCosts: [
    {
      cost: {
        id: 0,
        cost: 0,
        name: ''
      },
      daily_salary: false,
      individual: 0
    }
  ],
  mainSubjects: [
    {
      id: 1,
      cost: 0,
      name: ''
    }
  ],
  name: '',
  phone: '',
  prefix1c: '',
  skype: '',
  status: {
    id: 0,
    name: '',
    weight: null
  },
  surname: '',
  teacherTag: [
    {
      id: 0,
      name: '',
      comment: ''
    }
  ],
  tutor: mockAdmin,
  mentor: mockAdmin,
  patronymic: '',
  photo: '',
  loginAs: '',
  cv: [
    {
      id: 0,
      name: '',
      created_by: '',
      updated_at: ''
    }
  ],
  nda: [
    {
      id: 0,
      name: '',
      created_by: '',
      updated_at: ''
    }
  ],
  accessCreateAccount: false
};


export const mockChild = {
  id: null,
  family: {
    id: null,
    status: {
      id: null,
      name: '',
      weight: null
    },
    learnedFrom: {
      id: null,
      title: '',
      isActive: false,
      position: null
    },
    commentLearnedFrom: '',
    oldPrice: false,
    balance: null,
    prefix1c: '',
    parents: [],
    created: '',
    updated: '',
    familyIndex: '',
    branch: {
      id: null,
      name: '',
      type: 'lg'
    },
    personalCode: '',
    tax: '',
    familyTags: [],
    clickupLink: ''
  },
  familyId: null,
  status: {
    id: null,
    name: '',
    weight: null
  },
  name: '',
  school: '',
  patronymic: '',
  surname: '',
  sex: null,
  birthDate: '',
  purpose: '',
  entryTestResults: '',
  studyProgram: {
    id: null,
    name: ''
  },
  comment: '',
  extraInfo: '',
  hobby: '',
  mobile: '',
  mobileCanonical: '',
  email: '',
  additionalEmail: '',
  skype: '',
  created: '',
  updated: '',
  tutor: {
    id: null,
    name: '',
    surname: '',
    sex: null,
    comment: '',
    branch: {
      id: null,
      name: '',
      type: 'lg'
    },
    megaplanId: null,
    mobile: '',
    sipuniNum: '',
    email: '',
    skype: '',
    tags: [],
    tagsAge: [],
    position: '',
    tasksCounter: null,
    isAccessClickUp: true,
    hasClickUpToken: true
  },
  parents: null, // FIXME
  age: '',
  mainPhoto: '',
  photo: [],
  supergroups: [],
  interviewBy: {}
};

export const mockClassRoster: ClassRoster[] = [
  {
    student: mockChild,
    startAt: ''
  }
];

export const mockThreads = [
  {
    thread_id: 3728,
    messages: [
      {
        id: 6959,
        sender: {
          id: 15390
        },
        body: 'Текст сообщения',
        createdAt: '2019-11-23T13:43:05+00:00'
      }
    ],
    lastMessageCreatedAt: '2019-11-23T13:43:05+00:00',
    teacher: {
      id: 261,
      name: 'Sasha',
      surname: 'Pasternak'
    },
    getNotPremoderatedMessagesCount: 1,
    notApprovedMessagesCount: 0
  },
  {
    thread_id: 3727,
    messages: [
      {
        id: 6951,
        sender: {
          id: 15390
        },
        body: 'Текст сообщения',
        createdAt: '2019-11-23T11:12:53+00:00'
      },
      {
        id: 6952,
        sender: {
          id: 15390
        },
        body: 'Текст сообщения',
        createdAt: '2019-11-23T11:13:06+00:00'
      },
      {
        id: 6953,
        sender: {
          id: 15390
        },
        body: 'Текст сообщения',
        createdAt: '2019-11-23T12:51:51+00:00'
      },
      {
        id: 6954,
        sender: {
          id: 15390
        },
        body: 'Текст сообщения',
        createdAt: '2019-11-23T12:53:19+00:00'
      },
      {
        id: 6955,
        sender: {
          id: 15390
        },
        body: 'Текст сообщения',
        createdAt: '2019-11-23T12:53:30+00:00'
      },
      {
        id: 6956,
        sender: {
          id: 15390
        },
        body: 'Текст сообщения',
        createdAt: '2019-11-23T12:53:45+00:00'
      },
      {
        id: 6957,
        sender: {
          id: 15390
        },
        body: 'Текст сообщения',
        createdAt: '2019-11-23T13:42:25+00:00'
      },
      {
        id: 6958,
        sender: {
          id: 15390
        },
        body: 'Текст сообщения',
        createdAt: '2019-11-23T13:42:43+00:00'
      }
    ],
    lastMessageCreatedAt: '2019-11-23T11:12:53+00:00',
    teacher: {
      id: 261,
      name: 'Sasha',
      surname: 'Pasternak'
    },
    getNotPremoderatedMessagesCount: 1,
    notApprovedMessagesCount: 0
  },
  {
    thread_id: 3726,
    messages: [
      {
        id: 6950,
        sender: {
          id: 15390
        },
        body: 'Текст сообщения',
        createdAt: '2019-11-23T11:12:26+00:00'
      }
    ],
    lastMessageCreatedAt: '2019-11-23T11:12:26+00:00',
    teacher: {
      id: 261,
      name: 'Sasha',
      surname: 'Pasternak'
    },
    getNotPremoderatedMessagesCount: 1,
    notApprovedMessagesCount: 0
  }
];

const date = dayjs();
export const mockEvent: any = {
  id: 305790,
  startAt: date.hour(8).minute(0).second(0).toDate(),
  // startAt: date.set({ hour: 8, minute: 0, second: 0 }).toDate(),
  endAt: date.hour(10).minute(0).second(0).toDate(),
  // endAt: date.set({ hour: 10, minute: 0, second: 0 }).toDate(),
  room: {
    id: 83,
    name: 'Bristol',
    color: '#ffa500',
    webLink: 'https://zoom.us/j/5459911063?pwd=eTNuZUFQR2I1SDNkWHFHUldBZTZYQT09',
    webLogin: 'Online7@londongates.org',
    webPassword: 'TvbM901AS'
  },
  type: 'event',
  event: {
    id: 46539,
    name: 'Тренинг по Wrapping'
  },
  color: '#ffa500',
  editable: false,
  conductorName: 'Korenev Alex'
};

export const mockRegister: any = {
  id: 1217375,
  lessonId: 236873,
  startAt: '2020-06-27 10:50:00',
  lessonTitle: '',
  taskTitle: null,
  taskGrade: null,
  taskMaxGrade: null,
  attendance: null,
  classroomParticipation: null,
  accuracyAndUnderstanding: null,
  homeworkDone: null,
  homeworkGrade: null,
  commentForParent: null,
  commentForParentLikedByParent: null,
  commentForStudent: null,
  homeworkRequired: true,
  teacher: {
    id: 10974,
    name: 'Лейла',
    surname: 'Мухтарова',
    patronymic: null,
    email: 'Leylamukhtarova_nd@mail.ru',
    mainPhoto: '/upload/teacher/me/0a9/33/07/5894d2fccffdaa2a492a4a12da.jpg'
  },
  homeworkStatus: 0,
  course: { id: 8049, name: 'Bud_Epsilon_19_Rus' }
};

export const editInventoryMock: any = {
  name: '',
  rate: null,
  oldRate: null,
  type: {}
};

export default {
  mockAdmin,
  mockClassRoster,
  mockTeacher,
  mockThreads,
  mockChild,
  mockEvent,
  mockRegister,
  editInventoryMock
};
