import React from 'react';
import { Link } from 'react-router-dom';
import { BASE_COURSE_GROUP } from '../routes';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { CourseGroup } from 'types/education';

export const columns = [
  {
    title: 'Group name',
    dataIndex: 'groupName',
    key: 'groupName',
    render: (groupName: string, { id }: CourseGroup) => <Link to={`${BASE_COURSE_GROUP}/${id}`}>{groupName}</Link>
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (name: string, { id }: CourseGroup) => <Link to={`${BASE_COURSE_GROUP}/${id}`}>{name}</Link>
  },
  {
    title: 'Subject',
    dataIndex: 'subject',
    key: 'subject',
    render: (subject: any) => <p>{subject && subject.name}</p>
  },
  {
    title: 'Version',
    dataIndex: 'version',
    key: 'version'
  },
  {
    title: 'Branch',
    dataIndex: 'branch',
    key: 'branch',
    render: (branch: any) => <p>{branch && branch.name}</p>
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    render: (id, group) => {
      return (
        <Link to={`${BASE_COURSE_GROUP}/${group.id}/edit`}>
          <Button icon={<EditOutlined />} type="primary" />
        </Link>
      );
    }
  }
];

export default { columns };
