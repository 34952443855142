import React, { useState, useEffect } from 'react';
import { Select, Tag, Tooltip } from 'antd';
import { Rate } from 'types';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';

const { Option } = Select;

type AsyncSelectProps = {
  mode?: 'multiple' | 'tags';
  value?: number;
  branch?: number;
  onChange?: (value: number) => void;
  placeholder?: string;
};

const RateSelect: React.FC<AsyncSelectProps> = React.memo(({ value, mode, branch, onChange, placeholder }) => {
  const [strings] = useLanguageContext();
  const globalRequestsRepository = useGlobalRequestsRepository();
  const [options, setOptions] = useState<Rate[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<Rate[]>([]);
  const [hasLoading, setHasLoading] = useState(false);

  const handleChange = newValue => {
    onChange(newValue);
  };
  const handleSearch = (search: string) => {
    const searchLower = search.toLowerCase();

    setFilteredOptions(
      options.filter(({ name, price }: Rate) => {
        const nameMatch = name.toLowerCase().includes(searchLower);
        const priceMatch = price?.toString().includes(searchLower);
        return nameMatch || priceMatch;
      })
    );
  };

  useEffect(() => {
    (async () => {
      try {
        setHasLoading(true);
        const { data } = await globalRequestsRepository.getRates({ isActive: 1, branch });
        setOptions(data);
        setFilteredOptions(data);
      } finally {
        setHasLoading(false);
      }
    })();
  }, [branch]);

  return (
    <Select
      value={value}
      onChange={handleChange}
      mode={mode}
      loading={hasLoading}
      filterOption={false}
      showSearch
      onSearch={handleSearch}
      placeholder={placeholder || strings.RATE}
      maxTagCount="responsive"
      allowClear
    >
      {filteredOptions
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((item: Rate) => (
          <Option key={item.id} value={item.id}>
            <Tooltip title={item.price + ' ' + item.name} placement="top" overlayStyle={{ zIndex: 9999 }}>
              <Tag>{item.price}</Tag> {item.name}
            </Tooltip>
          </Option>
        ))}
    </Select>
  );
});

export default RateSelect;
