import React, { useState } from 'react';
import { ReportTrimester } from 'types/Reports';
import dayjs from 'dayjs';
import { dateFormat, downloadSomeFile } from 'helpers';
import { useLanguageContext } from 'contexts/LanguageContext';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Row, Select, Form } from 'antd';
import { FilterDate } from '../styles';
import { StageSelect } from 'Global/components/FormComponents';
import StudentSelect from 'Global/components/FormComponents/StudentSelect';
import queryString from 'query-string';
const { Option } = Select;

interface ProgressReportForParentsProps {
  trimesters: ReportTrimester[];
  loadingTrimesters: boolean;
}
/**
 * @description tab Progress Report for Parents
 * @param {object} props
 * @return {React.ReactNode}
 */
export const ProgressReportForParents = (props: ProgressReportForParentsProps) => {
  const [strings] = useLanguageContext();
  const [form] = Form.useForm();
  const [hasLoadind, setHasLoadind] = useState<boolean>(false);
  const { trimesters, loadingTrimesters } = props;

  /**
   * @description Загрузка pdf
   */
  const handleSubmit = async (values): Promise<any> => {
    const { trimester, stage, student, students } = values;
    const trimesterData = trimesters.find(item => item.id === Number(trimester));
    const startAt = dayjs(trimesterData.startAt).format(dateFormat);
    const endAt = dayjs(trimesterData.endAt).format(dateFormat);
    const stageName = stage ? `-stage-${stage}` : '';
    const filename = `report-trimester-${trimester}${stageName}-${startAt}-${endAt}.pdf`;
    const queryParams = queryString.stringify(values, { arrayFormat: 'bracket' });
    setHasLoadind(true);
    try {
      await downloadSomeFile(`/api/v1/progress-reports/download?${queryParams}`, filename, null, null, true);
    } catch {
    } finally {
      setHasLoadind(false);
    }
  };

  return (
    <Form form={form} layout="vertical" style={{ marginBottom: 20 }} onFinish={handleSubmit}>
      <Row gutter={[10, 10]}>
        <Col lg={12} xs={24}>
          <Form.Item name="trimester" label={strings.SEMESTER} rules={[{ required: true }]}>
            <Select placeholder={strings.SEMESTER} loading={loadingTrimesters}>
              <Option value={undefined}>&nbsp;</Option>
              {trimesters.map((trimester: ReportTrimester) => (
                <Option value={String(trimester.id)} key={trimester.id}>
                  {trimester.name}
                  <FilterDate m>{dayjs(trimester.startAt).format(dateFormat)}</FilterDate>
                  <FilterDate>{dayjs(trimester.endAt).format(dateFormat)}</FilterDate>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={6} xs={24}>
          <Form.Item name="stage" label={strings.STAGE}>
            <StageSelect />
          </Form.Item>
        </Col>
        <Col lg={6} xs={24}>
          <Form.Item name="students" label={strings.STUDENT}>
            <StudentSelect mode="multiple" />
          </Form.Item>
        </Col>
        <Col lg={6} xs={24}>
          <Form.Item>
            <Button type="primary" loading={hasLoadind} htmlType="submit" icon={<DownloadOutlined />} size="large">
              {strings.DOWNLOAD}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ProgressReportForParents;
