import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { CheckCircleTwoTone, CloseCircleTwoTone, DeleteTwoTone, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Collapse, Table, Button, Flex } from 'antd';
import { AddStudentModal } from './AddStudentModal';
import { DeleteStudentModal } from './DeleteStudentModal';
import { BASE_FAMILIES } from 'Global/routes';
import { SupergroupCounter, SupergroupCount } from '../styles';
import { GetListSupergroupsParams } from 'types/API/courses';
import { Subject } from 'types';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Supergroup, SupegroupStudent, SupergroupGroup } from 'types/education';
import { useLanguageContext } from 'contexts/LanguageContext';
import { BranchBruner } from 'types/branches';
import { useUserContext } from 'contexts/UserContext';
import { useSupergroupRepository } from '../../../repos/SupergroupRepository';

export const TableContainer = styled.div`
  margin: 5px 0;

  @media (max-width: 991px) {
    table {
      min-width: 100% !important;
    }
  }

  @media (min-width: 1200px) {
    table {
      min-width: 100% !important;
    }
  }
`;

const { Panel } = Collapse;

interface SuperGroupProps extends FormComponentProps {
  supergroup: Supergroup;
  currentBranchId: number;
  getSuperGroups: (params?: GetListSupergroupsParams) => Promise<any>;
}

/**
 * @description SuperGroup components
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
const SuperGroupTemplate = (props: SuperGroupProps) => {
  const [strings] = useLanguageContext();
  const supergroupRepository = useSupergroupRepository();
  const [user] = useUserContext();
  const hasBruner = user?.branch?.id === BranchBruner.id;
  const [currentStudentId, setCurrentStudentId] = useState<number>(0);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [hasShowAddStudentModal, setHasShowAddStudentModal] = useState<boolean>(false);
  const [hasLoadingFeedback, setHasLoadingFeedback] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = selectedRowKeys => setSelectedRowKeys(selectedRowKeys);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  const { supergroup, currentBranchId, getSuperGroups } = props;

  const lenStudents = supergroup?.students?.length;
  const { name, id, students, subjects } = supergroup || {};

  const handleHideDeleteModal = () => setShowDeleteModal(false);

  /**
   * @description Генерация колонок для таблицы из тем
   * @param {Subject[]} subjects
   */
  const generateColumns = (subjects: Subject[]) => {
    return subjects?.map((subject: Subject) => {
      return {
        title: subject.name,
        render: (student: SupegroupStudent) => {
          const inGroupToday = student.groups.some((group: SupergroupGroup) => {
            return group.inGroupToday && group.subject.name === subject.name;
          });

          return inGroupToday ? (
            <CheckCircleTwoTone style={{ fontSize: 22 }} twoToneColor="#52c41a" />
          ) : (
            <CloseCircleTwoTone style={{ fontSize: 22 }} twoToneColor="#f5222d" />
          );
        }
      };
    });
  };

  const handleDelete = (id: number): void => {
    setCurrentStudentId(id);
    setShowDeleteModal(true);
  };

  const handleTeacherFeedback = async (): Promise<any> => {
    try {
      setHasLoadingFeedback(true);
      await supergroupRepository.askOfficeFeedbackBySupergroup(id, { students: selectedRowKeys });
      setSelectedRowKeys([]);
    } catch {
    } finally {
      setHasLoadingFeedback(false);
    }
  };

  const extra = (
    <SupergroupCounter>
      <SupergroupCount>
        students <mark>{lenStudents}</mark>
      </SupergroupCount>
    </SupergroupCounter>
  );

  const studentColumn = {
    title: 'Student',
    dataIndex: 'name',
    key: 'name',
    render: (name: string, student: SupegroupStudent) => (
      <Link to={`${BASE_FAMILIES}/${student.familyId}/student/${student && student.id}`}>{name}</Link>
    )
  };

  const deleteColumn = {
    title: '',
    dataIndex: '',
    key: 'actions',
    render: (student: SupegroupStudent) => (
      <DeleteTwoTone twoToneColor="#f5222d" style={{ fontSize: 22 }} onClick={() => handleDelete(student.id)} />
    )
  };

  const generatedColumns = generateColumns(subjects);
  const getColumns = generatedColumns
    ? [studentColumn, ...generatedColumns, deleteColumn]
    : [studentColumn, deleteColumn];

  return (
    <TableContainer>
      <DeleteStudentModal
        isShow={showDeleteModal}
        hideModal={handleHideDeleteModal}
        id={id}
        studentId={currentStudentId}
        currentBranchId={currentBranchId}
        getSuperGroups={getSuperGroups}
      />
      <Collapse key={id}>
        <Panel header={name} key={id} extra={extra}>
          <Table
            dataSource={students}
            columns={getColumns}
            pagination={false}
            rowSelection={rowSelection}
            rowKey={record => record.id}
          />
          <Flex justify="space-between" style={{ marginTop: 20 }}>
            <Button
              type="primary"
              loading={hasLoadingFeedback}
              disabled={selectedRowKeys.length === 0}
              onClick={handleTeacherFeedback}
            >
              {strings.ASK_TEACHER_FOR_FEEDBACK}
            </Button>
            <Button onClick={() => setHasShowAddStudentModal(true)} icon={<PlusOutlined />}>
              {strings.ADD_A_STUDENT_TO_THE_SUPERGROUP}
            </Button>
          </Flex>
          <AddStudentModal
            superGroupId={id}
            handler={getSuperGroups}
            hasShowAddStudentModal={hasShowAddStudentModal}
            setHasShowAddStudentModal={setHasShowAddStudentModal}
          />
        </Panel>
      </Collapse>
    </TableContainer>
  );
};

export const SuperGroup = Form.create<SuperGroupProps>({})(SuperGroupTemplate);

export default { SuperGroup };
