import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useUserAccessRepository } from 'repos/UserAccessRepostirory';
import { Col, Row, Spin } from 'antd';
import { StyledUserAccess } from '../index';
import { UserAccessItem } from './UserAccessItem';
import { useLanguageContext } from 'contexts/LanguageContext';

export const ALL_BRANCHES_ID = -1;

interface IUserAccessProps {
  userId: number;
}

const StyledTitle = styled(Row)`
  background: #fff;
  padding: 10px 25px;
  border-radius: 5px;
  h3 {
    margin: 0;
  }
`;

export function UserAccess({ userId }: IUserAccessProps) {
  const [strings] = useLanguageContext();
  const [accessObjects, setAccessObjects] = useState([]);
  const [userAccess, setUserAccess] = useState([]);
  const userAccessRepo = useUserAccessRepository();
  const [isLoadedAccess, setIsLoadedAccess] = useState<boolean>(false);

  const fetchObjectAccess = async (): Promise<any> => {
    try {
      setIsLoadedAccess(true);
      const {
        data: { items }
      } = await userAccessRepo.getObjectAccess();
      setAccessObjects(items);
    } finally {
      setIsLoadedAccess(false);
    }
  };

  const fetchUserAccess = async (): Promise<any> => {
    try {
      setIsLoadedAccess(true);
      const { data: userAccessObjects } = await userAccessRepo.getUserAccess(userId);

      const accessList = accessObjects.map(({ id, name }) => {
        const userAccessObject = userAccessObjects[name];

        if (userAccessObject) {
          return { id, name, isActive: true, accessObjects: userAccessObject };
        }

        return { id, name, isActive: false, accessObjects: [] };
      });
      setUserAccess(accessList.sort((a, b) => a.name.localeCompare(b.name)));
      setIsLoadedAccess(false);
    } catch (e) {
      setIsLoadedAccess(false);
    }
  };

  useEffect(() => {
    fetchObjectAccess();
  }, []);

  useEffect(() => {
    if (userId && accessObjects.isNotEmpty()) {
      fetchUserAccess();
    }
  }, [userId, accessObjects]);

  return (
    <StyledUserAccess>
      <Spin spinning={isLoadedAccess}>
        <StyledTitle>
          <Col lg={12}>
            <h3>{strings.ACCESS}</h3>
          </Col>
          <Col lg={12}>
            <h3>{strings.BRANCH}</h3>
          </Col>
        </StyledTitle>
        <Row>
          {userAccess.map(access => (
            <UserAccessItem
              accessId={access.id}
              refetchAccess={fetchUserAccess}
              userId={userId}
              name={access.name}
              accessObjects={access.accessObjects}
              isActive={access.isActive}
            />
          ))}
        </Row>
      </Spin>
    </StyledUserAccess>
  );
}
