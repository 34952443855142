import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import dayjs, { Dayjs, isDayjs } from 'dayjs';
import { Form } from '@ant-design/compatible';
import { Select, Button, Row, Col, DatePicker, Checkbox, message } from 'antd';
import { dateFormat } from 'helpers';
import { TutorSelect } from 'Global/components/FormComponentsCompatible/Selects/TutorSelect';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { Branch, Status } from 'types';
import { dateFormatForBackend } from 'helpers/dates';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { GroupSelect } from '../../../Global/components/FormComponentsCompatible/Selects/GroupSelect';

const { Option } = Select;
const ALLOWED_TEACHER_STATUSES_IDS = [8, 9, 10, 11, 12, 13, 14, 19];

interface filterProps extends FormComponentProps {
  onFilter: (filter: object) => Promise<any>;
  loading: boolean;
  setDateFilter: (obj) => any;
  setGroupsFilter: (val: number[]) => void;
}

/**
 * @description Filter teachers report table
 * @param {object} filterProps - component props
 * @return {React.ReactNode}
 */
export const Filter = Form.create<filterProps>({})((props: filterProps) => {
  const [strings] = useLanguageContext();
  const [dateStart, setDateStart] = useState<any>(dayjs().subtract(1, 'month').format(dateFormatForBackend));
  const [dateEnd, setDateEnd] = useState<any>(dayjs().format(dateFormatForBackend));
  const {
    form: { getFieldDecorator, validateFieldsAndScroll },
    onFilter,
    loading,
    setDateFilter,
    setGroupsFilter
  } = props;

  const { branches, teacherStatuses: teacherStatusesList } = useGlobalCollectionsContext();
  const teacherStatuses = teacherStatusesList.filter(status => ALLOWED_TEACHER_STATUSES_IDS.includes(status.id));
  const setDateStartValue = date => {
    setDateStart(dayjs(date).format(dateFormatForBackend));
  };

  const setDateEndValue = date => {
    setDateEnd(dayjs(date).format(dateFormatForBackend));
  };

  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();

    setDateFilter({ dateStart, dateEnd });

    validateFieldsAndScroll((errors, values) => {
      const { groups } = values;
      if (groups) {
        setGroupsFilter(groups);
      }

      if (isEmpty(errors)) {
        for (let i in values) {
          if (isDayjs(values[i])) {
            values[i] = dayjs(values[i]).format(dateFormatForBackend);
          }
        }

        onFilter(values);
      } else {
        message.error(strings.PLEASE_FILL_IN_TEACHER_STATUSES);
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={[10, 10]}>
        <Col lg={4}>
          {getFieldDecorator('dateStart', {
            rules: [{ required: false }],
            initialValue: dayjs().subtract(1, 'month')
          })(<DatePicker onChange={setDateStartValue} format={dateFormat} />)}
        </Col>
        <Col lg={4}>
          {getFieldDecorator('dateEnd', {
            rules: [{ required: false }],
            initialValue: dayjs()
          })(<DatePicker onChange={setDateEndValue} format={dateFormat} />)}
        </Col>
        <Col lg={4}>
          <GroupSelect getFieldDecorator={getFieldDecorator} name="groups" mode="multiple" />
        </Col>
        <Col lg={4}>
          {getFieldDecorator('branch', {
            rules: [{ required: false }]
          })(
            <Select placeholder={strings.BRANCH} allowClear>
              {branches.map((branch: Branch) => (
                <Option value={branch.id} key={branch.id}>
                  {branch.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col lg={6}>
          <TutorSelect
            name="tutor"
            getFieldDecorator={getFieldDecorator}
            placeholder={strings.ADM}
            style={{ margin: 0 }}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginTop: 10 }} align="middle">
        <Col lg={16}>
          {getFieldDecorator('teacherStatus', {
            rules: [{ required: true }],
            initialValue: teacherStatuses?.map((status: Status) => status.id)
          })(
            <Select
              placeholder={strings.TEACHER_STATUSES}
              mode="multiple"
              maxTagCount="responsive"
              style={{ height: 'auto' }}
            >
              {teacherStatuses.map((status: Status) => (
                <Option value={status.id} key={status.id}>
                  {status.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col lg={3}>
          {getFieldDecorator('withLike', {
            rules: [{ required: false }]
          })(<Checkbox>{strings.WITH_LIKE}</Checkbox>)}
        </Col>
        <Col lg={3}>
          {getFieldDecorator('withDislike', {
            rules: [{ required: false }]
          })(<Checkbox>{strings.WITH_DISLIKE}</Checkbox>)}
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
        <Col lg={24}>
          <Button disabled={loading} size="large" type="primary" htmlType="submit">
            {strings.FILTER}
          </Button>
        </Col>
      </Row>
    </Form>
  );
});
