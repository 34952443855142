import { GetInventoriesParams, UpdateInventoryParams } from 'types/CRM/materials';
import { httpService } from '../core';

/**
 * @description Получить список инвентарей
 * @param {GetInventoriesParams} params
 * @returns {Promise<any>}
 */
export const getInventories = (params: GetInventoriesParams) => httpService.get('/api/inventories', params);

/**
 * @description Получить инвентарь по ID
 * @param {number} id
 * @returns {Promise<any>}
 */
export const getInventoryById = (id: number | string) => httpService.get(`/api/inventory/${id}`);

/**
 * @description Обновить инвентарь
 * @param {number} id
 * @param {UpdateInventoryParams} params
 * @returns {Promise<any>}
 */
export const updateInventory = (id: number | string, params: UpdateInventoryParams) =>
  httpService.put(`/api/inventory/${id}`, params);

/**
 * @description Удалить инвентарь
 * @param {number} id
 * @returns {Promise<any>}
 */
export const deleteInventory = (id: number | string) => httpService.delete(`/api/inventory/${id}`);

/**
 * @description Получить типы инвентарей
 * @returns {Promise<any>}
 */
export const getInventoryTypes = () => httpService.get('/api/inventories/types');

/**
 * @description Добавить инвентарь
 * @param {object} params
 * @returns {Promise<any>}
 */
export const addInventory = (params: object) => httpService.post('/api/inventory', params);

export default {
  getInventories,
  getInventoryById,
  updateInventory,
  deleteInventory,
  getInventoryTypes,
  addInventory
};
