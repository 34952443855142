import React, { useEffect, useState } from 'react';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Table, Row, Col } from 'antd';
import { Filter } from './Filter';
import { isEmpty } from 'lodash';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import {
  IDataLessonOutcomesBySubject,
  ILessonOutcomesBySubject,
  ILessonOutcomesFilterParams,
  IReverseLessonOutcomesBySubject
} from 'types/Student';
import { useStudentRepository } from 'repos/StudentRepository';
import { getColumns } from './columns';

interface LessonOutcomesProps {
  studentId: number;
}

export const LessonOutcomes = ({ studentId }: LessonOutcomesProps) => {
  const [user] = useUserContext();
  const [strings] = useLanguageContext();
  const { locationSearchParams } = useLocationSearchParams();
  const studentRepository = useStudentRepository();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [lessonOutcomes, setLessonOutcomes] = useState<ILessonOutcomesBySubject[]>([]);

  const fetchLessonOutcomes = async (params?: ILessonOutcomesFilterParams): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await studentRepository.getLessonOutcomesByStudentId(studentId, params);
      setLessonOutcomes(data);
    } finally {
      setHasLoading(false);
    }
  };

  const transformArray = (data: IDataLessonOutcomesBySubject[]): IReverseLessonOutcomesBySubject[] => {
    let result = [];

    data.forEach(lessonItem => {
      const { lesson, outcomes } = lessonItem;
      const outcomesCount = outcomes.length;

      outcomes.forEach((outcomeItem, outcomeIndex) => {
        const { outcome, grade } = outcomeItem;

        result.push({
          lessonId: lesson.id,
          lessonDate: lesson.startAt,
          lessonTitle: lesson.title,
          reportingType: lesson?.courseGroup?.stage?.reportType,
          outcomeDescription: outcome.description,
          outcomeGrade: grade,
          outcomesCount: outcomesCount,
          outcomeIndex: outcomeIndex,
          itemIndex: outcomeIndex + 1,
          itemCount: outcomesCount
        });
      });
    });

    return result;
  };

  useEffect(() => {
    if (isEmpty(locationSearchParams)) {
      fetchLessonOutcomes();
    } else {
      fetchLessonOutcomes(locationSearchParams);
    }
  }, [user]);

  return (
    <>
      <Filter onFilter={fetchLessonOutcomes} />
      {lessonOutcomes.isEmpty() ? (
        <p style={{ padding: 20 }}>{strings.NO_DATA}</p>
      ) : (
        lessonOutcomes.map((item: ILessonOutcomesBySubject) => (
          <Row style={{ marginBottom: 20 }}>
            <Col lg={24}>
              <h4>{item?.subject?.name}</h4>
            </Col>
            <Col lg={24}>
              <Table
                bordered
                dataSource={transformArray(item?.data)}
                columns={getColumns(strings)}
                pagination={false}
                loading={{ spinning: hasLoading }}
              />
            </Col>
          </Row>
        ))
      )}
    </>
  );
};
export default { LessonOutcomes };
