import * as teachers from 'Admin/People/Teachers/routes';
import { Teachers } from 'Admin/People/Teachers';
import { Teacher } from 'Admin/People/Teachers/Teacher';
import { TeacherForm } from 'Admin/People/Teachers/Teacher/TeacherForm';

export const teachersArr = [
  {
    path: teachers.BASE_TEACHERS,
    component: Teachers,
    exact: true
  },
  {
    path: teachers.BASE_TEACHER_ADD,
    component: TeacherForm,
    exact: true
  },
  {
    path: `${teachers.BASE_TEACHERS}/:id/edit`,
    component: TeacherForm,
    exact: true
  },
  {
    path: `${teachers.BASE_TEACHERS}/:id`,
    component: Teacher,
    exact: true
  }
];

export default { teachersArr };
