import React, { useEffect, useMemo, useState } from 'react';
import { Form, Select, Row, Col, Button, Checkbox, Input } from 'antd';
import { pickBy, identity } from 'lodash';
import { Branch } from 'types';
import { PricesParams } from 'types/API/global';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { FilterButtons } from 'Global/components/FilterButtons';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { FormItemWithoutMargin } from 'Global/styles';
import { useUserContext } from 'contexts/UserContext';

const { Option } = Select;

interface ServiceRateFilterProps {
  onFilter: (params?: PricesParams) => Promise<any>;
  handleDeactivateRates: () => Promise<any>;
  defFilterValues: PricesParams;
  hasSelectedRates: boolean;
  hasDeactivateLoading: boolean;
}

const ServiceRateFilter: React.FC<ServiceRateFilterProps> = ({
  onFilter,
  hasSelectedRates,
  handleDeactivateRates,
  defFilterValues,
  hasDeactivateLoading
}) => {
  const [strings] = useLanguageContext();
  const [form] = Form.useForm();
  const [user] = useUserContext();
  const hasTopManager = user?.hasRoles?.topManager;
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);

  const { branches, priceGroups } = useGlobalCollectionsContext();
  const { locationSearchParams } = useLocationSearchParams();

  const { branch, isActive, priceGroup, name } = locationSearchParams || {};

  const handleSubmit = async (values): Promise<void> => {
    try {
      const params = pickBy(values, identity);
      setHasSubmitLoading(true);
      await onFilter(params);
    } finally {
      setHasSubmitLoading(false);
    }
  };

  const handleClear = async (): Promise<void> => {
    await form.resetFields();
    await onFilter(defFilterValues);
  };

  useEffect(() => {
    form.setFieldsValue(locationSearchParams);
  }, [locationSearchParams]);

  const extraBtn = useMemo(
    () => (
      <Button
        danger
        size="large"
        loading={hasDeactivateLoading}
        disabled={!hasSelectedRates}
        onClick={handleDeactivateRates}
      >
        {strings.DEACTIVATE}
      </Button>
    ),
    [hasSelectedRates, hasDeactivateLoading]
  );

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <Row gutter={10} align="middle">
        <Col xs={24} lg={6}>
          <FormItemWithoutMargin name="branch" hidden={!hasTopManager}>
            <Select placeholder={strings.BRANCH}>
              {branches.map((branch: Branch) => (
                <Option value={branch.id} key={branch.id}>
                  {branch.name}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col xs={24} lg={6}>
          <FormItemWithoutMargin name="priceGroup">
            <Select placeholder={strings.PRICE_GROUP} allowClear>
              {priceGroups &&
                priceGroups.map(item => (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col xs={24} lg={6}>
          <FormItemWithoutMargin name="name">
            <Input placeholder={strings.NAME} />
          </FormItemWithoutMargin>
        </Col>
        <Col xs={24} lg={6}>
          <FormItemWithoutMargin name="isActive" valuePropName="checked">
            <Checkbox>{strings.SHOW_ONLY_CURRENT}</Checkbox>
          </FormItemWithoutMargin>
        </Col>
      </Row>
      <FilterButtons handleClear={handleClear} hasSubmitLoading={hasSubmitLoading} extra={extraBtn} />
    </Form>
  );
};

export default ServiceRateFilter;
