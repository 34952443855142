import React, { useState, useEffect } from 'react';
import { isEmpty, first, round } from 'lodash';
import BaseFilter from 'helpers/BaseFilter';
import { Typography, Row } from 'antd';
import { Filter } from './Filter';
import { ACADEMIC_PROGRESS_LIMIT } from '../constants';
import { initialStateProgress } from './constants';
import { AcademicProgressCard } from './Card';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';
import Pagination from 'Global/components/Pagination';
import { scrollTop } from 'helpers';
import { IReportRegister } from 'types/TeacherAccount/Courses';
import { Loader } from 'Global/components/Loader/Loader';
import { AcademicProgressParams } from 'types';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { useHistory } from 'react-router-dom';
import { StAcademicProgress } from '../styles';
import { useUserContext } from 'contexts/UserContext';
import dayjs from 'dayjs';
import { momentFormatValues } from 'helpers/momentFormatValues';

const { Title } = Typography;

/**
 * @description Academic progress
 * @param {object} props
 * @return {React.ReactNode}
 */
export const AcademicProgress = () => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const { goBack, push } = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [hasShowReadComments, setHasShowReadComments] = useState<boolean>(false);
  const [filters, setFilters] = useState<AcademicProgressParams>({});
  const [progress, setProgress] = useState<any>(initialStateProgress);
  const [totalCommentsCount, setTotalCommentsCount] = useState<number>(0);
  const [approvedCommentsCount, setApprovedCommentsCount] = useState<number>(0);
  const FilterService = new BaseFilter();
  const { locationSearchParams } = useLocationSearchParams();
  const reportsRepository = useProgressReportsRepository();

  const now = dayjs();
  const nearestPastAugustFirst = now.isAfter(dayjs(`${now.year()}-08-01`))
    ? dayjs(`${now.year()}-08-01`)
    : dayjs(`${now.year() - 1}-08-01`);

  const defaultValues = {
    limit: ACADEMIC_PROGRESS_LIMIT,
    commentType: 1,
    commentApproveType: 0,
    startAt: nearestPastAugustFirst,
    branch: user?.branch?.id
  };

  const { commentType } = locationSearchParams;
  //1 - all, 2 - for parents, 3 - for students, 4 - for office
  const [filterFeedbackType, setFilterFeedbackType] = useState<number>((commentType && commentType) || 1);

  /**
   * @description Запрос на получение репортов
   * @param {object} params
   * @return {Promise<any>}
   */
  const getAllReports = async (params: AcademicProgressParams = {}): Promise<any> => {
    setLoading(true);
    setFilters(params);
    if (params?.commentType) {
      setFilterFeedbackType(params?.commentType);
    }
    try {
      const {
        data,
        data: {
          paginationInfo: { totalCount }
        }
      } = await reportsRepository.getReports(params);
      setProgress(data);
      setTotalCommentsCount(totalCount);
      push({ search: FilterService.getQueryStringParams(params) });
    } catch {
    } finally {
      setLoading(false);
    }
  };
  /**
   * @description Фильтр репортов
   * @param {object} params
   * @return {Promise<any>}
   */
  const onFilter = async (params: AcademicProgressParams): Promise<any> => {
    const filterParams = momentFormatValues(params);
    await getAllReports(filterParams);
    if (params.isCommentForParentRead) {
      setHasShowReadComments(Boolean(params.isCommentForParentRead));
      //для комментов, прочитанных родителями, выводим статистику - кол-во прочитанных комментов и общее кол-во approved комментов за выбранный период
      if (Boolean(params.isCommentForParentRead)) {
        //нужны только approved комменты (commentApproveType = 1)
        const registersCountParams = {
          ...params,
          commentApproveType: 1
        };
        //удаляем из параметров для получения кол-ва approved комментов limit, isCommentForParentRead, page
        delete registersCountParams.limit;
        delete registersCountParams.page;
        delete registersCountParams.isCommentForParentRead;
        try {
          const {
            data: { count }
          } = await reportsRepository.getRegistersCount(registersCountParams);
          setApprovedCommentsCount(Number(count));
        } catch {}
      }
    } else {
      setHasShowReadComments(false);
    }
  };

  /**
   * @description Пагинация
   * @param {number} page
   * @return {void}
   */
  const handlePage = async (page: number): Promise<any> => {
    const params = FilterService.getFilterParams(locationSearchParams, page, null, ACADEMIC_PROGRESS_LIMIT);
    await getAllReports({ page, ...params });
    scrollTop();
  };

  const refetchReports = () => {
    getAllReports(filters);
  };

  /**
   * @description получаем статистику прочитанных комментов из всех approved комментов
   * @return {string}
   */
  const getStatistic = (): string => {
    if (approvedCommentsCount !== 0) {
      return `${strings.TOTAL_COMMENTS_READ} ${totalCommentsCount} / ${approvedCommentsCount} (${round(
        (totalCommentsCount / approvedCommentsCount) * 100,
        2
      )}%)`;
    }
    return strings.TOTAL_COMMENTS_READ_0_0;
  };

  useEffect(() => {
    if (!isEmpty(locationSearchParams)) {
      onFilter(locationSearchParams);
    } else {
      onFilter(defaultValues);
    }
  }, [user]);

  return (
    <Container>
      <StPageHeader onBack={goBack} title={strings.FEEDBACK_MANAGEMENT}>
        <Filter onFilter={onFilter} defFilterValues={defaultValues} />
      </StPageHeader>
      <StAcademicProgress>
        <Loader spinning={loading}>
          <Row gutter={[10, 10]}>
            {hasShowReadComments && (
              <Title level={4}>
                <span>{getStatistic()}</span>
              </Title>
            )}
            {progress?.items.map((register: IReportRegister) => (
              <AcademicProgressCard
                register={register}
                filterFeedbackType={filterFeedbackType}
                refetchReports={refetchReports}
              />
            ))}
            <Pagination
              count={progress?.paginationInfo?.pageCount}
              current={progress?.paginationInfo?.current}
              pageChange={handlePage}
            />
          </Row>
        </Loader>
      </StAcademicProgress>
    </Container>
  );
};

export default { AcademicProgress };
