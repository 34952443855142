import React, { useContext, useMemo } from 'react';
import { httpService } from 'api/core';
import { TrainingsFilterParams, TrainingsParams } from '../types/education';

const TrainingsRepositoryContext = React.createContext<ITrainingsRepository>(null);

interface ITrainingsRepository {
  getTrainings: (params?: TrainingsFilterParams) => Promise<any>;
  getTrainingById: (id: number | string) => Promise<any>;
  createTraining: (params: TrainingsParams) => Promise<any>;
  editTraining: (id: number | string, params: TrainingsParams) => Promise<any>;
  deleteTraining: (id: number | string) => Promise<any>;
  getTeacherTraining: (id: number | string) => Promise<any>;
  addTrainingToTeacher: (teacherId: number | string, params: { trainings: number[] }) => Promise<any>;
  editTeacherTrainingMark: (
    teacherId: number | string,
    id: number | string,
    params: { essential?: boolean; done?: boolean }
  ) => Promise<any>;
  markTrainingDoneByTeacher: (id: number | string, params: { done?: boolean }) => Promise<any>;
  deleteTrainingFromTeacher: (teacherId: number | string, id: number | string) => Promise<any>;
}
class TrainingsRepository implements ITrainingsRepository{
  getTrainings(params?: TrainingsFilterParams) {
    return httpService.get('/api/v1/trainings', params, null, true);
  }

  getTrainingById(id: number | string) {
    return httpService.get(`/api/v1/trainings/${id}`, null, null, true);
  }

  createTraining(params: TrainingsParams) {
    return httpService.post(`/api/v1/trainings`, params, null, null, true);
  }

  editTraining(id: number | string, params: TrainingsParams) {
    return httpService.put(`/api/v1/trainings/${id}`, params, null, null, true);
  }

  deleteTraining(id: number | string) {
    return httpService.delete(`/api/v1/trainings/${id}`, null, null, true);
  }

  getTeacherTraining(id: number | string) {
    return httpService.get(`/api/v1/teachers/${id}/trainings`, null, null, true);
  }

  addTrainingToTeacher(teacherId: number | string, params: { trainings: number[] }) {
    return httpService.post(`/api/v1/teachers/${teacherId}/trainings`, params, null, null, true);
  }
  editTeacherTrainingMark(
    teacherId: number | string,
    id: number | string,
    params: { essential?: boolean; done?: boolean }
  ) {
    return httpService.put(`/api/v1/teachers/${teacherId}/trainings/${id}`, params, null, null, true);
  }

  markTrainingDoneByTeacher(id: number | string, params: { done?: boolean }) {
    return httpService.put(`/api/v1/teachers/my/trainings/${id}/edit `, params, null, null, true);
  }

  deleteTrainingFromTeacher(teacherId: number | string, id: number | string) {
    return httpService.delete(`/api/v1/teachers/${teacherId}/trainings/${id}`, null, null, true);
  }
}

export const useTrainingsRepository = () => {
  const service = useContext(TrainingsRepositoryContext);

  if (!service) {
    throw new Error('Trainings repository is unavailable');
  }

  return service;
};

export const TrainingsRepositoryProvider = props => {
  const service = useMemo(() => new TrainingsRepository(), []);

  return <TrainingsRepositoryContext.Provider value={service}>{props.children}</TrainingsRepositoryContext.Provider>;
};
