import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ExternalCodeService from 'helpers/services/ExternalCodeService';
import ym from 'react-yandex-metrika';
import dayjs from 'dayjs';

import {
  CreditCardOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  LinkOutlined,
  MessageOutlined,
  RightOutlined
} from '@ant-design/icons';

import {
  Modal,
  Button,
  Select,
  Table,
  message,
  Progress,
  Tooltip,
  Empty,
  Badge,
  InputNumber,
  Row,
  Typography,
  Radio,
  Flex
} from 'antd';
import { getInvoicesColumns } from './columns';
import { copyingText } from 'helpers';
import { invoiceStatus, invoiceStatuses } from 'Global/constants';
import { generateLink } from 'api/Families/invoices';
import { Letter } from 'Global/components/ModalLetter';
import { Comment } from 'Global/components/ModalComment';
import { InvoicePayModal } from './PayModal';
import { IComment, InvoiceListItem, InvoiceFilterParams } from 'types/invoice';
import { User } from 'types/user';
import { IParent } from 'types';
import { useUserContext } from 'contexts/UserContext';
import { InvoiceLogModal } from './InvoiceLogModal';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useInvoicesRepository } from 'repos/InvoicesRepository';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';

const { Text } = Typography;

export interface InvoicesTableProps {
  user?: User;
  isLoadInvoices: boolean;
  invoicesList: InvoiceListItem[];
  getInvoiceMoscow?: (params: InvoiceFilterParams) => Promise<any>;
  isFamily: boolean; // Для семьи ли таблица
  refetchInvoices?: () => void;
  isPagination: undefined | false;
  currentFilterParams?: InvoiceFilterParams;
  isRowSelection?: boolean;
}

const externalCodeService = new ExternalCodeService();

const { Column } = Table;
const { Option } = Select;

let disableSendTimeout;

/**
 * @description Таблица инвойсов
 * @param {object} props
 * @return {React.ReactNode}
 */
export const InvoicesTable = React.memo(
  ({
    isFamily,
    invoicesList,
    isLoadInvoices,
    refetchInvoices,
    isPagination,
    getInvoiceMoscow,
    isRowSelection
  }: InvoicesTableProps) => {
    const [invoiceId, setInvoiceId] = useState<number>(null);
    const [parents, setParents] = useState<IParent[]>([]);
    const [invoiceComment, setInvoiceComment] = useState<IComment>({});
    const [hasShowModalSend, setShowModalSend] = useState<boolean>(false);
    const [hasShowModalComment, setShowModalComment] = useState<boolean>(false);
    const [disabledInvoiceId, disableInvoiceSending] = useState<number>(null);
    const [hasShowInvoiceLogModal, setHasShowInvoiceLogModal] = useState<boolean>(false);
    const [selectedInvoice, setSelectedInvoice] = useState<number>(null);
    const [hasShowPayModal, setHasShowPayModal] = useState<boolean>(false);
    const [hasShowPartiallyPayModal, setHasShowPartiallyPayModal] = useState<boolean>(false);
    const [hasLoadPartiallyPay, setHasLoadPartiallyPay] = useState<boolean>(false);
    const [partiallyPaidInvoiceId, setPartiallyPaidInvoiceId] = useState<number>(null);
    const [partiallyPaidSum, setPartiallyPaidSum] = useState<number>(null);
    const [invoicePayLink, setInvoicePayLink] = useState<string>('');
    const [hasInvoicesPayLinkLoaded, setHasInvoicesPayLinkLoaded] = useState<boolean>(false);

    const [hasLoadingBulkSelect, setHasLoadingBulkSelect] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [bulkSelectAction, setBulkSelectAction] = useState<'off' | 'draft' | 'pending'>('off');

    const [user] = useUserContext();
    const [strings] = useLanguageContext();
    const hasTopManager = user?.hasRoles?.topManager;
    const hasBranchDirector = user?.hasRoles?.branchDirector;
    const isAbleToEditInvoiceStatus = hasTopManager || hasBranchDirector;
    const invoicesRepository = useInvoicesRepository();
    const { locationSearchParams } = useLocationSearchParams();

    const [{ confirm }, contextHolder] = Modal.useModal();

    const rowSelection = useMemo(
      () =>
        isRowSelection && bulkSelectAction !== 'off'
          ? {
              selectedRowKeys,
              onChange: items => setSelectedRowKeys(items),
              getCheckboxProps: record => ({
                disabled: bulkSelectAction === 'draft' ? record.status !== 5 : record.status !== 1
              })
            }
          : null,
      [isRowSelection, bulkSelectAction, selectedRowKeys]
    );

    const handleBulkSelect = useCallback(e => {
      setBulkSelectAction(e.target.value);
      setSelectedRowKeys([]);
    }, []);

    /**
     * @description В зависимости от action Меняем статус Draft на Pending или делаем массовую рассылку инвойсов
     * @return {Promise<any>}
     */
    const handlerBulkSelect = () => {
      confirm({
        title: strings.ARE_YOU_SURE,
        okType: 'danger',
        async onOk() {
          try {
            setHasLoadingBulkSelect(true);
            if (bulkSelectAction === 'draft') {
              invoicesRepository.bulkTurnDraftToPending({ invoices: selectedRowKeys });
              getInvoiceMoscow(locationSearchParams);
            } else {
              invoicesRepository.bulkMailingInvoices({ invoices: selectedRowKeys });
              setTimeout(() => getInvoiceMoscow(locationSearchParams), 3000);
            }
            setSelectedRowKeys([]);
          } finally {
            setHasLoadingBulkSelect(false);
          }
        }
      });
    };

    const hideModalSend = (id?: number): void => {
      setShowModalSend(false);

      if (id) {
        disableInvoiceSending(id);
        disableSendTimeout = setTimeout(() => {
          disableInvoiceSending(null);
        }, 120000);
      }
    };

    // Скрыть комментарий
    const hideModalComment = () => setShowModalComment(false);

    /**
     * @description Открытие модального окна письма
     * @param {object} invoice
     * @return {void}
     */
    const showLetter = (invoice: InvoiceListItem): void => {
      // Локально метрики нет
      if (!externalCodeService.isLocalEnvironment()) {
        // Цель яндекс метрики по клику на иконку банковской карты
        ym('reachGoal', 'payOnlineParent');
      }
      setInvoiceId(invoice.id);
      //в инвойсах Москвы с бэка приходит invoice.child.family.parents, в инвойсах семьи - invoice.family.parents
      setParents(invoice?.family?.parents);
      setShowModalSend(true);
    };

    /**
     * @description Показать комментарий
     * @param {number} id
     * @param {IComment} comment
     * @return {void}
     */
    const showComment = (id: number, comment: IComment): void => {
      setInvoiceId(id);
      setInvoiceComment(comment);
      setShowModalComment(true);
    };

    /**
     * @description Загрузка инвойса
     * @param {string | number} id инвойса
     * @return {Promise<any>}
     */
    const handleDownloadInvoice = async (id: number | string): Promise<any> => {
      await invoicesRepository.downloadInvoice(id);
    };

    /**
     * @description Внесение частичной оплаты инвойса
     * @return {Promise<any>}
     */
    const handlePartiallyPay = async (): Promise<any> => {
      try {
        setHasLoadPartiallyPay(true);
        const { status } = await invoicesRepository.updateInvoiceStatus({
          id: partiallyPaidInvoiceId,
          status: 1,
          partiallyPaid: partiallyPaidSum
        });
        if (status === 200) {
          message.success(strings.PARTIALLY_PAID_SUCCESSFULLY, 2);
          if (isFamily) {
            await refetchInvoices();
          } else {
            await getInvoiceMoscow(locationSearchParams);
          }
        }
      } finally {
        setHasLoadPartiallyPay(false);
        setHasShowPartiallyPayModal(false);
      }
    };

    /**
     * @description Открытие модального окна для внесения частичной оплаты инвойса
     * @param {number} invoiceId
     * @return {void}
     */
    const openModalPartiallyPay = (invoiceId: number): void => {
      setHasShowPartiallyPayModal(true);
      setPartiallyPaidInvoiceId(invoiceId);
    };

    /**
     * @description Открытие модального окна invoice log
     * @param {number} invoiceId
     * @return {void}
     */
    const openInvoiceLogModal = (invoiceId: number): void => {
      setSelectedInvoice(invoiceId);
      setHasShowInvoiceLogModal(true);
    };

    /**
     * @description Функция копирования ссылки (админ)
     * @param {string} hashLink
     * @return {void}
     */
    const copyHashLink = (hashLink: string) => {
      // Локально метрики нет
      if (!externalCodeService.isLocalEnvironment()) {
        ym('reachGoal', 'payOnlineAdmin');
      }
      copyingText(hashLink, result => {
        if (result) message.success('Payment link is successfully copied!');
        else message.error('Fail copied!');
      });
    };

    /**
     * @description Изменить статус
     * @param {object} params
     * @return {Promise<any>}
     */
    const handleChangeStatus = (invoiceId: number, statusId: number): void => {
      const newStatus = invoiceStatuses.find(item => item.id === statusId).name;
      confirm({
        title: `${strings.ARE_YOU_SURE_THAT_YOU_WANT_TO_CHANGE_STATUS_OF_AN_INVOICE_TO} ${newStatus}`,
        okType: 'danger',
        async onOk() {
          try {
            const { status } = await invoicesRepository.updateInvoiceStatus({ id: invoiceId, status: statusId });
            if (status === 200) {
              message.success(strings.STATUS_UPDATED, 3);
              if (isFamily) {
                await refetchInvoices();
              } else {
                await getInvoiceMoscow(locationSearchParams);
              }
            }
          } catch {}
        }
      });
    };

    const parentActions = {
      title: '',
      key: 'actions',
      render: (invoice: InvoiceListItem) => {
        const { id, status, performer } = invoice;
        const statusWidth = 34;
        // ссылка на систему оплаты через Paykeeper доступна только для ИП Лихачёв(id=18)
        const accessToPayLink = performer?.id === 18;
        return (
          <Flex align="center" gap={10}>
            <Button onClick={() => handleDownloadInvoice(id)} icon={<DownloadOutlined />} />
            {status === invoiceStatus.pending && <Progress type="circle" percent={50} width={statusWidth} />}
            {status === invoiceStatus.paid && <Progress type="circle" percent={100} width={statusWidth} />}
            {(status === invoiceStatus.cancelled || status === invoiceStatus.returned) && (
              <Progress type="circle" percent={100} width={statusWidth} status="exception" />
            )}
            {status !== invoiceStatus.paid && accessToPayLink && (
              <Button type="primary" onClick={() => handlePayInvoice(id)} icon={<CreditCardOutlined />} />
            )}
          </Flex>
        );
      }
    };

    // Cмена статуса инвойса
    const status = {
      title: 'Status',
      key: 'status',
      render: (invoice: InvoiceListItem) => {
        const { status, id } = invoice;
        // Если статус равен paid или canceled или returned
        const disabled =
          status === invoiceStatus.paid || status === invoiceStatus.cancelled || status === invoiceStatus.returned;

        /**
         * Если есть доступ isInvoiceUnlimitedAccess, то юзер может редактировать любой статус и в списке доступен статус Returned(id=4).
         * Если нет доступа isInvoiceUnlimitedAccess, то юзер может редактировать только Pending(id=1) и Draft(id=5) и в списке недоступен Returned(id=4).
         **/
        return (
          <Select
            disabled={user?.isInvoiceUnlimitedAccess ? false : disabled}
            value={status}
            style={{ margin: 0, width: 140 }}
            onChange={(value: number) => handleChangeStatus(id, value)}
          >
            <Option value={1}>
              <Badge
                status="processing"
                text={invoiceStatuses.find(item => item.id === invoiceStatus.pending).name}
                style={{ paddingInlineStart: 5 }}
              />
            </Option>
            <Option value={2} disabled={!isAbleToEditInvoiceStatus}>
              <Badge
                status="success"
                text={invoiceStatuses.find(item => item.id === invoiceStatus.paid).name}
                style={{ paddingInlineStart: 5 }}
              />
            </Option>
            <Option value={3}>
              <Badge
                status="error"
                text={invoiceStatuses.find(item => item.id === invoiceStatus.cancelled).name}
                style={{ paddingInlineStart: 5 }}
              />
            </Option>
            <Option disabled={!user?.isInvoiceUnlimitedAccess} value={4}>
              <Badge
                status="warning"
                text={invoiceStatuses.find(item => item.id === invoiceStatus.returned).name}
                style={{ paddingInlineStart: 5 }}
              />
            </Option>
            <Option disabled value={5}>
              <Badge
                status="default"
                text={invoiceStatuses.find(item => item.id === invoiceStatus.draft).name}
                style={{ paddingInlineStart: 5 }}
              />
            </Option>
          </Select>
        );
      }
    };

    const actions = {
      title: '',
      key: 'actions',
      className: 'clmn-center',
      render: (invoice: InvoiceListItem) => {
        const { id, comment, hashLink } = invoice || {};
        let title = strings.NOT_SENT_YET;
        if (invoice?.sentAt) {
          title = `Sent ${dayjs(invoice.sentAt).format('DD.MM.YYYY HH:mm')} ${
            invoice.sentBy ? `by ${invoice?.sentBy?.user?.surname} ${invoice?.sentBy?.user?.name}` : ''
          }`;
        }
        return (
          <Flex gap={5}>
            <Tooltip title={strings.DOWNLOAD}>
              <Button icon={<DownloadOutlined />} onClick={() => handleDownloadInvoice(id)} />
            </Tooltip>
            <Tooltip title={title}>
              <Button
                icon={<RightOutlined />}
                className={invoice?.sentAt ? 'activated' : ''}
                onClick={() => showLetter(invoice)}
                disabled={disabledInvoiceId === id}
              />
            </Tooltip>
            <Button icon={<MessageOutlined />} onClick={() => showComment(id, comment)} />
            {invoice?.performer?.id === 18 && (
              <Tooltip title={strings.PAYMENT_LINK}>
                <Button type="primary" icon={<LinkOutlined />} onClick={() => copyHashLink(hashLink)} />
              </Tooltip>
            )}
            <Tooltip title={strings.INVOICE_LOG}>
              <Button icon={<InfoCircleOutlined />} onClick={() => openInvoiceLogModal(id)} />
            </Tooltip>
          </Flex>
        );
      }
    };

    /**
     * @description Обработчик на клик по банковской карте
     * @param {number} id
     * @return {void}
     */
    const handlePayInvoice = useCallback(
      async (id: number) => {
        if (!externalCodeService.isLocalEnvironment()) {
          ym('reachGoal', 'payOnlineParent');
        }

        setHasInvoicesPayLinkLoaded(true);
        setHasShowPayModal(true);

        try {
          const {
            data: { url }
          } = await generateLink(id);
          setInvoicePayLink(url);
        } catch {
        } finally {
          setHasInvoicesPayLinkLoaded(false);
        }
      },
      [setHasShowPayModal, generateLink]
    );

    /**
     * @description Функция для получения колонок для роли родителя и админа
     * @return {array}
     */
    const columnsByRole = useMemo(() => {
      const adminActions = [status, actions];
      const baseColumns = getInvoicesColumns(user?.hasRoles?.parent, openModalPartiallyPay, strings);

      // Если пользователь — родитель, возвращаем базовые колонки и колонки для родителя
      if (user?.hasRoles?.parent) {
        return [...baseColumns, parentActions];
      }

      // Если пользователь — администратор, возвращаем базовые колонки и колонки для администратора
      return [...baseColumns, ...adminActions];
    }, [user?.hasRoles?.parent, openModalPartiallyPay]);

    useEffect(() => {
      return () => {
        clearTimeout(disableSendTimeout);
      };
    }, [disableSendTimeout]);

    if (!isLoadInvoices && invoicesList?.length === 0) {
      return <Empty description={strings.NO_DATA} />;
    }

    return (
      <>
        {contextHolder}
        {hasShowPartiallyPayModal && (
          <Modal
            title={strings.PARTIALLY_PAID}
            width={320}
            open={hasShowPartiallyPayModal}
            onCancel={() => setHasShowPartiallyPayModal(false)}
            footer={[
              <Button key="add" type="primary" loading={hasLoadPartiallyPay} onClick={handlePartiallyPay}>
                {strings.ADD}
              </Button>
            ]}
          >
            <InputNumber
              width={100}
              value={partiallyPaidSum}
              onChange={(value: number) => setPartiallyPaidSum(value)}
            />
          </Modal>
        )}
        {hasShowPayModal && (
          <InvoicePayModal
            hasVisible={hasShowPayModal}
            onCancel={setHasShowPayModal}
            hasInvoicesPayLinkLoaded={hasInvoicesPayLinkLoaded}
            invoicePayLink={invoicePayLink}
          />
        )}
        {hasShowInvoiceLogModal && (
          <InvoiceLogModal
            invoiceId={selectedInvoice}
            hasVisible={hasShowInvoiceLogModal}
            onCancel={setHasShowInvoiceLogModal}
          />
        )}
        <Letter id={invoiceId} parents={parents} show={hasShowModalSend} cancel={hideModalSend} />
        <Comment
          id={invoiceId}
          refetchInvoices={refetchInvoices}
          show={hasShowModalComment}
          cancel={hideModalComment}
          comment={invoiceComment}
        />
        {isRowSelection && (
          <Row align="middle" style={{ marginBottom: 20, gap: 10 }}>
            <Text strong>{strings.BULK_SELECT}:</Text>
            <Radio.Group defaultValue={bulkSelectAction} onChange={handleBulkSelect} style={{ padding: '8px 0' }}>
              <Radio value="off">{strings.OFF}</Radio>
              <Radio value="draft">{strings.TURN_TO_PENDING_DRAFT_ONLY}</Radio>
              <Radio value="pending">{strings.SEND_TO_PAYER_PENDING_ONLY}</Radio>
            </Radio.Group>
            {selectedRowKeys && bulkSelectAction && bulkSelectAction !== 'off' && (
              <Button
                style={{ marginInlineStart: 20 }}
                loading={hasLoadingBulkSelect}
                onClick={handlerBulkSelect}
                disabled={selectedRowKeys.isEmpty()}
                type="primary"
                size="large"
              >
                {bulkSelectAction === 'draft' ? strings.TURN_TO_PENDING : strings.SEND_TO_PAYER}
              </Button>
            )}
          </Row>
        )}
        <Table
          rowSelection={rowSelection}
          pagination={isPagination}
          size="middle"
          dataSource={invoicesList}
          loading={isLoadInvoices}
          rowKey="id"
          locale={{ emptyText: strings.NO_DATA }}
        >
          {columnsByRole
            .filter(col => col)
            .map(col => (
              <Column key={col.key} {...col} />
            ))}
        </Table>
      </>
    );
  }
);
