import React from 'react';
// import moment from 'moment-timezone';
import { EventSlot, LessonSlot, Room } from 'types/Schedule';
import { AdminScheduleLessonItem } from 'Global/modules/Schedule/ScheduleLessonItem/AdminScheduleLessonItem';
import { TeacherScheduleLessonItem } from 'Global/modules/Schedule/ScheduleLessonItem/TeacherScheduleLessonItem';
import { StudentScheduleLessonItem } from 'Global/modules/Schedule/ScheduleLessonItem/StudentScheduleLessonItem';
import { useUserContext } from 'contexts/UserContext';
import dayjs from 'dayjs';

interface LessonItemProps {
  event?: EventSlot & LessonSlot;
  hasBulkMode: boolean;
  bulkLessons: number[];
  rooms: Room[];
}

const TIME_FORMAT = 'HH:mm';

export function ScheduleLessonItem(props: LessonItemProps) {
  const {
    event: {
      title,
      room: { name: roomName, id: roomId }
    }
  } = props;
  const [user] = useUserContext();
  const startTime = dayjs(props.event.startAt).format(TIME_FORMAT);
  const endTime = dayjs(props.event.endAt).format(TIME_FORMAT);

  const hasEvent = props.event.hasOwnProperty('event');
  const roomMetadata = props.rooms.find(room => room.id === roomId);
  const hasZoom = roomMetadata?.webLink;
  const getPopoverTitle = () => {
    if (!title) {
      return roomName;
    }

    return title.trim().length === 0 ? roomName : title;
  };

  const lessonItemProps = {
    startTime: startTime,
    endTime: endTime,
    popoverTitle: getPopoverTitle(),
    roomMetadata: roomMetadata,
    hasZoom: hasZoom,
    hasEvent: hasEvent,
    event: props.event
  };

  if (user?.hasRoles?.teacher) {
    return <TeacherScheduleLessonItem {...lessonItemProps} />;
  }

  if (user?.hasRoles?.parent || user?.hasRoles?.student) {
    return <StudentScheduleLessonItem hasZoom={hasZoom} event={props.event} endTime={endTime} startTime={startTime} />;
  }

  return (
    <AdminScheduleLessonItem {...lessonItemProps} hasBulkMode={props.hasBulkMode} bulkLessons={props.bulkLessons} />
  );
}
