import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select, Button, Col, Row, Checkbox, Tooltip } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { TutorSelect } from 'Global/components/FormComponentsCompatible/Selects/TutorSelect';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { hasErrorsFields } from 'helpers';
import { isEmpty } from 'lodash';
import { Branch, IParent, Status } from 'types';
import { Supergroup } from 'types/education';
import { filterSelect } from 'helpers/filterSelect';
import { useTeacherRepository } from 'repos/TeacherRepository';
import { useStudentRepository } from 'repos/StudentRepository';
import { IRecipient } from './index';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useAdminRepository } from 'repos/AdminRepository';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useFamilyRepository } from 'repos/FamilyRepository';
import { useCoursesRepository } from 'repos/CoursesRepository';

const { Option } = Select;

interface EmailFilterProps {
  form: WrappedFormUtils;
  setRecipientsList: (value: IRecipient[]) => void;
  setHasRecipientsLoading: (value: boolean) => void;
  hasRecipientsLoading: boolean;
  setCurrentPeople: (value: string) => void;
  currentPeople: string;
}

/**
 * @description Компонент отправки email
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
const EmailFilterTemplate = (props: EmailFilterProps) => {
  const [superGroups, setSuperGroups] = useState<Supergroup[]>([]);
  const [statuses, setStatuses] = useState<Status[]>([]);
  const [hasSuperGroupsLoading, setHasSuperGroupsLoading] = useState<boolean>(false);

  const teacherRepository = useTeacherRepository();
  const studentRepository = useStudentRepository();
  const adminRepository = useAdminRepository();
  const familyRepository = useFamilyRepository();
  const coursesRepository = useCoursesRepository();
  const [strings] = useLanguageContext();

  const {
    form: { getFieldDecorator, getFieldsError, validateFields, resetFields },
    setRecipientsList,
    setHasRecipientsLoading,
    hasRecipientsLoading,
    setCurrentPeople,
    currentPeople
  } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const { branches, familyStatuses, childStatuses, teacherStatuses } = useGlobalCollectionsContext();

  const CLEAR_FIELDS = ['statuses', 'supergroup', 'tutor'];

  const clearRecipients = () => setRecipientsList([]);

  const hasStudent = currentPeople === 'students';
  const hasTeacher = currentPeople === 'teachers';
  const hasParent = currentPeople === 'parents';

  const hasGetUsersDisabled = hasErrorsFields(getFieldsError());

  const handleGetRecipients = (): void => {
    validateFields(async (errors, values) => {
      if (isEmpty(errors)) {
        setHasRecipientsLoading(true);
        if (currentPeople === 'students') {
          await getStudentRecipients(values);
        } else if (currentPeople === 'parents') {
          await getParentRecipients(values);
        } else if (currentPeople === 'teachers') {
          await getTeacherRecipients(values);
        } else {
          await getAdminRecipients(values);
        }
      }
    });
  };

  /**
   * @description Список админов
   * @param {object} params
   * @return {Promise<any>}
   */
  const getAdminRecipients = async (params): Promise<any> => {
    try {
      const {
        data: { admins }
      } = await adminRepository.getAllAdmins({ ...params, isActive: 1 });
      const recipients = admins
        .filter(recipient => recipient?.user?.email)
        .map(item => {
          return { id: item.id, name: item?.user?.name, surname: item?.user?.surname };
        });
      setRecipientsList(recipients);
    } catch {
      setRecipientsList([]);
    } finally {
      setHasRecipientsLoading(false);
    }
  };

  /**
   * @description Список учителей
   * @param {object} params
   * @return {Promise<any>}
   */
  const getTeacherRecipients = async (params): Promise<any> => {
    try {
      const {
        data: { items }
      } = await teacherRepository.getTeachers({ ...params, mainTeacher: 1, limit: 1000 });
      const recipients = items.map(item => {
        return { id: item.id, name: item.user.name, surname: item.user.surname };
      });
      setRecipientsList(recipients);
    } catch {
      setRecipientsList([]);
    } finally {
      setHasRecipientsLoading(false);
    }
  };

  /**
   * @description Список родителей
   * @param {object} params
   * @return {Promise<any>}
   */
  const getParentRecipients = async (params): Promise<any> => {
    try {
      const { data } = await familyRepository.getParentsByFilter(params);
      const recipients = data.map((item: IParent) => {
        return { id: item.id, name: item?.user?.name, surname: item?.user?.surname };
      });
      setRecipientsList(recipients);
    } catch {
      setRecipientsList([]);
    } finally {
      setHasRecipientsLoading(false);
    }
  };

  /**
   * @description Список студентов
   * @param {object} params
   * @return {Promise<any>}
   */
  const getStudentRecipients = async (params): Promise<any> => {
    try {
      const { data } = await studentRepository.getStudents({ ...params, withParents: true });
      const recipients = data.map(item => {
        return { id: item.id, name: item.user.name, surname: item.user.surname, parents: item.parents };
      });
      setRecipientsList(recipients);
    } catch {
      setRecipientsList([]);
    } finally {
      setHasRecipientsLoading(false);
    }
  };

  const handlePeopleChange = (people: string): void => {
    setCurrentPeople(people);
    resetFields(CLEAR_FIELDS);
    clearRecipients();
    if (people === 'students') {
      setStatuses(childStatuses);
    } else if (people === 'parents') {
      setStatuses(familyStatuses);
    } else if (people === 'teachers') {
      setStatuses(teacherStatuses);
    }
  };

  const getSuperGroupsByBranch = async (branchId: number): Promise<any> => {
    setHasSuperGroupsLoading(true);
    try {
      const { data } = await coursesRepository.getSuperGroups({ branch: branchId, byStages: 0 });
      setSuperGroups(data);
    } catch {
    } finally {
      setHasSuperGroupsLoading(false);
    }
  };

  const handleChangeBranch = async (branchId: number): Promise<any> => {
    await getSuperGroupsByBranch(branchId);
  };

  return (
    <Form layout="vertical">
      {hasParent && (
        <Row gutter={[10, 10]}>
          <Col>
            {getFieldDecorator('invoiceSubscriber', {
              rules: [{ required: false }]
            })(<Checkbox>Include only invoice subscribers</Checkbox>)}
            {getFieldDecorator('newsletterSubscriber', {
              rules: [{ required: false }]
            })(<Checkbox>Include only marketing email subscribers</Checkbox>)}
            {getFieldDecorator('debtors', {
              rules: [{ required: false }]
            })(<Checkbox>Include only debtors</Checkbox>)}
          </Col>
        </Row>
      )}
      <Row gutter={[10, 10]}>
        <Col lg={4}>
          <MyFormItem label={strings.BRANCH}>
            {getFieldDecorator('branch', {
              rules: [{ required: true }]
            })(
              <Select placeholder={strings.BRANCH} onChange={handleChangeBranch}>
                {branches.map((branch: Branch) => (
                  <Option value={branch.id} key={branch.id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            )}
          </MyFormItem>
        </Col>
        <Col lg={4}>
          <MyFormItem label={strings.PEOPLE}>
            {getFieldDecorator('people', {
              rules: [{ required: true }]
            })(
              <Select placeholder={strings.FUNCTION} onChange={handlePeopleChange}>
                <Option value="students">{strings.STUDENT}</Option>
                <Option value="parents">{strings.PARENT}</Option>
                <Option value="teachers">{strings.TEACHER}</Option>
                <Option value="admins">{strings.ADMINISTRATOR}</Option>
              </Select>
            )}
          </MyFormItem>
        </Col>
        {(hasStudent || hasParent || hasTeacher) && (
          <Col lg={4}>
            <MyFormItem label={strings.STATUSES}>
              {getFieldDecorator('statuses', {
                rules: [{ required: false }]
              })(
                <Select
                  size="large"
                  style={{ width: '100%' }}
                  placeholder={strings.STATUSES}
                  mode="multiple"
                  maxTagCount={2}
                >
                  {statuses.map((status: Status) => (
                    <Option value={String(status.id)} key={status.id}>
                      <Tooltip title={status.name} overlayStyle={{ zIndex: 9999 }}>
                        {status.name}
                      </Tooltip>
                    </Option>
                  ))}
                </Select>
              )}
            </MyFormItem>
          </Col>
        )}
        {(hasStudent || hasParent) && (
          <>
            <Col lg={4}>
              <MyFormItem label={strings.TUTORS}>
                <TutorSelect mode="multiple" name="tutor" getFieldDecorator={getFieldDecorator} />
              </MyFormItem>
            </Col>
            <Col lg={4}>
              <MyFormItem label={strings.SUPERGROUPS}>
                {getFieldDecorator('supergroup', {
                  rules: [{ required: false }]
                })(
                  <Select
                    filterOption={filterSelect}
                    loading={hasSuperGroupsLoading}
                    placeholder={strings.SUPERGROUPS}
                    maxTagCount={2}
                    mode="multiple"
                  >
                    {superGroups.map((superGroup: Supergroup) => (
                      <Option key={superGroup.id} value={superGroup.id}>
                        <Tooltip title={superGroup.name} overlayStyle={{ zIndex: 9999 }}>
                          {superGroup.name}
                        </Tooltip>
                      </Option>
                    ))}
                  </Select>
                )}
              </MyFormItem>
            </Col>
          </>
        )}
        <Col lg={4}>
          <Button
            type="primary"
            loading={hasRecipientsLoading}
            disabled={hasGetUsersDisabled}
            size="large"
            onClick={handleGetRecipients}
            style={{ marginTop: isMobile ? 0 : 48 }}
          >
            {strings.GET} {currentPeople ? `${currentPeople}` : strings.USERS}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export const EmailFilter = Form.create<EmailFilterProps>({})(EmailFilterTemplate);

export default { EmailFilter };
