import React, { useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { BACKEND_LOCATION, downloadFile } from 'helpers';
import { FamiliesFilterParams } from 'types/Family';
import { PaginationInfo } from 'types/global';
import { useUserContext } from 'contexts/UserContext';
import { useHistory } from 'react-router-dom';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Filter } from './Filter';
import { momentFormatValues } from 'helpers/momentFormatValues';
import { useFamilyRepository } from 'repos/FamilyRepository';
import { StPageHeader } from 'Global/GlobalStyle';

interface FamiliesTitleProps {
  pagination: PaginationInfo;
  currentFilter: FamiliesFilterParams;
  setCurrentFilter: (fields: FamiliesFilterParams) => void;
  getFamilies: (params?: FamiliesFilterParams) => Promise<any>;
}

export const FamiliesTitle = (props: FamiliesTitleProps) => {
  const [strings] = useLanguageContext();
  const { goBack } = useHistory();
  const { pagination, currentFilter, setCurrentFilter, getFamilies } = props;
  const [user] = useUserContext();
  const familyRepository = useFamilyRepository();
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const hasTopManager = user?.hasRoles?.topManager;
  const userBranch = user?.branch?.id;
  const { totalCount } = pagination || {};

  /**
   * @description Запрос на экспорт
   * @return {Promise<any>}
   */
  const exportFamiliesToExcel = async (): Promise<any> => {
    /**
     * Если роль топ менеджер то он выбирает нужный филиал в списке.
     * Если нет, то филиал текущего пользователя
     */
    const branch: number = hasTopManager && currentFilter?.branch ? currentFilter.branch : userBranch;
    const params = { ...momentFormatValues(currentFilter), branch };
    setExportLoading(true);
    try {
      const {
        data: { url }
      } = await familyRepository.exportFamiliesToExcel(params);
      downloadFile(`${BACKEND_LOCATION}/${url}`, '');
      message.success(strings.EXCEL_WAS_CREATED_SUCCESSFULLY, 2);
    } catch (err) {
      message.error(err.response.data.message);
    } finally {
      setExportLoading(false);
    }
  };

  const getSubtitle = () => {
    if (totalCount) {
      return `${strings.TOTAL_NUMBER_OF_FAMILIES} ${totalCount}`;
    }

    return null;
  };

  return (
    <StPageHeader
      onBack={() => goBack()}
      title={strings.FAMILIES}
      subTitle={getSubtitle()}
      extra={[
        <Button onClick={exportFamiliesToExcel} loading={exportLoading} icon={<DownloadOutlined />} type="primary">
          {strings.EXPORT_TO_EXCEL}
        </Button>
      ]}
    >
      <Filter setCurrentFilter={setCurrentFilter} getFamilies={getFamilies} />
    </StPageHeader>
  );
};

export default { FamiliesTitle };
