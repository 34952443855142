import React, { useState, useEffect } from 'react';
import { UploadHomeworks } from './UploadHomeworksModal';
import { RegistersContainer } from './styles';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { RegisterTable } from './RegisterTable';
import { AddTestModal } from './RegisterModals/AddTestModal';
import { MetaGoalItem } from 'types/courses';
import { useMetaGoalsRepository } from 'repos/MetaGoalsRepository';
import { registersFormat } from 'helpers/registerFormat';
import { message, Modal } from 'antd';
import { useLessonRepository } from 'repos/LessonRepository';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useLessonRegisterStore } from 'stores/useLessonRegisterStore';
import { ILessonRegisterFullPostRarams } from 'types/lesson';
import { sortBy } from 'lodash';

interface RegisterProps {
  lessonId: number | string;
}
export const successBadgeProps = {
  twoToneColor: '#52c41a',
  style: { fontSize: 18 }
};

export const attentionBadgeProps = {
  twoToneColor: '#f5222d',
  style: { fontSize: 18 }
};

/**
 * @description Register tab
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const Register = (props: RegisterProps) => {
  const [strings] = useLanguageContext();
  const [{ confirm }, contextHolder] = Modal.useModal();
  const [hasShowUploadHomeWork, setHasShowUploadHomework] = useState<boolean>(false);
  const [hasShowTestModal, setHasShowTestModal] = useState<boolean>(false);
  const [lessonsMetaGoals, setLessonsMetaGoals] = useState<MetaGoalItem[]>([]);

  const metaGoalsRepo = useMetaGoalsRepository();
  const lessonRepository = useLessonRepository();

  const { lessonId } = props;
  const registers = useLessonRegisterStore(state => state.registers);
  const setRegisters = useLessonRegisterStore(state => state.setRegisters);
  const hasRegistersUpdate = useLessonRegisterStore(state => state.hasRegistersUpdate);
  const setHasRegistersUpdate = useLessonRegisterStore(state => state.setHasRegistersUpdate);
  const setHasRegistersLoading = useLessonRegisterStore(state => state.setHasRegistersLoading);
  const setMaxTaskGrade = useLessonRegisterStore(state => state.setMaxTaskGrade);
  const setExtraRegisterData = useLessonRegisterStore(state => state.setExtraRegisterData);
  const setRegisterColumns = useLessonRegisterStore(state => state.setRegisterColumns);
  const hasRegistersFetch = useLessonRegisterStore(state => state.hasRegistersFetch);
  const setHasRegistersFetch = useLessonRegisterStore(state => state.setHasRegistersFetch);
  const setMockRegisters = useLessonRegisterStore(state => state.setMockRegisters);
  const registerUpdatedAt = useLessonRegisterStore(state => state.registerUpdatedAt);
  const setRegisterUpdatedAt = useLessonRegisterStore(state => state.setRegisterUpdatedAt);

  const getLessonRegisters = async (id: number | string): Promise<any> => {
    setHasRegistersLoading(true);
    try {
      const { data, status } = await lessonRepository.getLessonRegisters(id);
      if (status === 200) {
        const {
          registers,
          registerColumns,
          hwButtons,
          task,
          lesson: { homeworkRequiredForCurrentLesson },
          updatedAt,
          feedbackForParentsCountPresence
        } = data;
        const extraData = { homeworkRequiredForCurrentLesson, task, hwButtons, feedbackForParentsCountPresence };
        setExtraRegisterData(extraData);
        setRegisterUpdatedAt(updatedAt);
        const registersSortBySurname = sortBy(registers, 'student.surname');
        setRegisters(registersSortBySurname);
        if (task) {
          setMaxTaskGrade(task.maxGrade);
        }
        // Запишем первоначальный register для дальнейшего сравнивания
        setMockRegisters(registers);
        setRegisterColumns(registerColumns);
      }
    } catch {
      message.error(strings.GETTING_REGISTER_ERROR, 2);
    } finally {
      setHasRegistersLoading(false);
      setHasRegistersFetch(false);
    }
  };
  const updateLessonRegisters = async (lessonId: number | string, ignoreUpdated = false): Promise<any> => {
    const registersValues = registersFormat(registers);
    const params: ILessonRegisterFullPostRarams = {
      registers: registersValues,
      updatedAt: registerUpdatedAt,
      ignoreUpdated
    };
    try {
      const response = await lessonRepository.updateLessonRegisters(lessonId, params);
      const { data } = response;
      setRegisterUpdatedAt(data.updatedAt);
      return response;
    } catch (error) {
      const { status, data } = error;
      if (status === 422) {
        confirm({
          title: strings.DO_YOU_WANT_TO_SAVE_THE_CURRENT_CHANGES,
          content: data.message,
          okText: strings.SAVE,
          okType: 'danger',
          async onOk() {
            try {
              await updateLessonRegisters(lessonId, true);
            } catch {}
          }
        });
      } else {
        message.error(data.message, 2);
      }
    } finally {
      setHasRegistersUpdate(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (lessonId) {
        setHasRegistersFetch(true);
        const { data } = await metaGoalsRepo.getLessonsMetaGoals(lessonId);
        setLessonsMetaGoals(data);
      }
    })();
  }, [lessonId]);

  useEffect(() => {
    if (hasRegistersUpdate) {
      updateLessonRegisters(lessonId);
    }
  }, [hasRegistersUpdate]);

  useEffect(() => {
    if (hasRegistersFetch) {
      getLessonRegisters(lessonId);
    }
  }, [hasRegistersFetch]);

  return (
    <ErrorBoundary>
      {contextHolder}
      <RegistersContainer>
        {hasShowUploadHomeWork && (
          <UploadHomeworks hasShow={hasShowUploadHomeWork} setHasShowUploadHomework={setHasShowUploadHomework} />
        )}
        {hasShowTestModal && (
          <AddTestModal
            lessonId={lessonId}
            setHasShowTestModal={setHasShowTestModal}
            hasShowTestModal={hasShowTestModal}
          />
        )}
        <RegisterTable
          lessonId={lessonId}
          setHasShowUploadHomework={setHasShowUploadHomework}
          setHasShowTestModal={setHasShowTestModal}
          lessonsMetaGoals={lessonsMetaGoals}
        />
      </RegistersContainer>
    </ErrorBoundary>
  );
};

const RegisterMemo = React.memo(Register);
export default RegisterMemo;
