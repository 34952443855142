import { LIST_ROLES } from 'Global/roles';
import { RoleWithPrefix } from 'types';

/**
 * @description Возвращает объект с ролями
 * @param {string[]} roles
 */
export const defineRoles = (roles: string[]) => {
  let hasRoles = {};
  roles.forEach((role: string) => {
    const findRole = LIST_ROLES.find((roleWithPrefix: RoleWithPrefix) => roleWithPrefix.role === role);

    // Если нужно чтобы у ключа был префикс has рас комментируй нижнюю строку
    // const prefix = 'has' + upperFirst(findRole.prefix);

    if (findRole) {
      Object.assign(hasRoles, {
        ...hasRoles,
        [findRole.prefix]: true
      });
    }
  });

  return hasRoles;
};
