import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, DatePicker, Button, Col, Select, Form } from 'antd';
import dayjs from 'dayjs';
import { useLanguageContext } from 'contexts/LanguageContext';
import { ICommentaryCalendarParams } from 'types/commentary';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { Branch, Subject } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import StageSelect from 'Global/components/FormComponents/StageSelect';
import { FormItemWithoutMargin } from 'Global/styles';
import { dateFormat } from 'helpers';
import { CourseGroupSelect, TutorSelect } from 'Global/components/FormComponents';
import TeacherSelect from 'Global/components/FormComponents/TeacherSelect';
import OriginalCourseSelect from 'Global/components/FormComponents/OriginalCourseSelect';
import { ProgressReportParams } from 'types/Reports';

const { Option } = Select;

interface FilterProps {
  defValues: ICommentaryCalendarParams;
  onFilter: (params: ICommentaryCalendarParams) => Promise<any>;
}

export const Filter: React.FC<FilterProps> = React.memo(({ defValues, onFilter }) => {
  const [strings] = useLanguageContext();
  const [form] = Form.useForm();
  const { locationSearchParams } = useLocationSearchParams();
  const { branch, tutor, teacher, dateTo, stage, subject, originalCourse, courseGroup } = locationSearchParams || {};

  const { branches, subjects } = useGlobalCollectionsContext();

  const [selectedBranch, setSelectedBranch] = useState<number>(branch);
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);

  const initValues: ProgressReportParams = useMemo(
    () => ({ branch, tutor, teacher, dateTo: dateTo && dayjs(dateTo), stage, subject, originalCourse, courseGroup }),
    [locationSearchParams]
  );
  const handleClear = useCallback(() => {
    form.resetFields();
    onFilter(defValues);
  }, [form, defValues, onFilter]);

  const handleSubmit = useCallback(
    async (values: ICommentaryCalendarParams) => {
      setHasSubmitLoading(true);
      try {
        await onFilter(values);
      } finally {
        setHasSubmitLoading(false);
      }
    },
    [onFilter]
  );

  useEffect(() => {
    form.setFieldsValue(initValues);
  }, [initValues]);

  useEffect(() => {
    branch && setSelectedBranch(branch);
  }, [branch]);

  return (
    <Form form={form} onFinish={handleSubmit} layout="vertical">
      <Row gutter={[10, 10]}>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="branch">
            <Select placeholder={strings.BRANCH} onChange={val => setSelectedBranch(val)}>
              {branches.map((branch: Branch) => (
                <Option key={branch.id} value={branch.id}>
                  {branch.name}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="stage">
            <StageSelect branch={selectedBranch} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="subject">
            <Select placeholder={strings.SUBJECT} allowClear>
              {subjects.map((sub: Subject) => (
                <Option key={sub.id} value={sub.id}>
                  {sub.name}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="dateTo">
            <DatePicker placeholder={`${strings.SELECT_DATE}`} format={dateFormat} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="originalCourse">
            <OriginalCourseSelect placeholder={strings.ORIGINAL_COURSES} initOriginalCourse={originalCourse} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="courseGroup">
            <CourseGroupSelect branch={selectedBranch} placeholder={strings.GROUP} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="tutor">
            <TutorSelect placeholder={strings.ADM} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="teacher">
            <TeacherSelect placeholder={strings.TEACHER} />
          </FormItemWithoutMargin>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col xs={24} lg={24} style={{ display: 'flex', gap: 10, marginTop: 10 }}>
          <Button onClick={handleClear} size="large">
            {strings.CLEAR_FILTER}
          </Button>
          <Button type="primary" htmlType="submit" size="large" loading={hasSubmitLoading}>
            {strings.FILTER}
          </Button>
        </Col>
      </Row>
    </Form>
  );
});

export default { Filter };
