import React, { useEffect, useState } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { Table, Button, Modal } from 'antd';
import { Loader } from 'Global/components/Loader/Loader';
import { getColumns } from './columns';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';

import { useTrainingsRepository } from 'repos/TrainingsRepository';
import { ITeacherTraining } from 'types/education';
import { AddTrainingModal } from './Add';
import { useLanguageContext } from 'contexts/LanguageContext';
import { StCard } from 'Global/GlobalStyle';

const { Column } = Table;

interface TeacherTrainingProps {
  teacherId: number | string;
}

/**
 * @description Тренинги учителя
 * @param {TrainingProps} props
 * @return {React.ReactNode}
 */
export const TeacherTraining = (props: TeacherTrainingProps) => {
  const [strings] = useLanguageContext();
  const { teacherId } = props;
  const trainingsRepository = useTrainingsRepository();
  const [training, setTraining] = useState<ITeacherTraining[]>([]);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [hasModalVisible, setHasModalVisible] = useState<boolean>(false);
  const [{ confirm }, contextHolder] = Modal.useModal();

  /**
   * @description Получение списка тренингов учителя
   * @return {Promise<any>}
   */
  const fetchTeacherTraining = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await trainingsRepository.getTeacherTraining(teacherId);
      setTraining(data);
    } finally {
      setHasLoading(false);
    }
  };

  /**
   * @description Edit teacher training mark (обязательный/необязательный, пройден/не пройден)
   * @param {number} teacherId
   * @param {number} trainingId
   * @param { essential?: boolean; done?: boolean } params
   * @return {Promise<any>}
   */
  const editTeacherTrainingMark = async (
    training: ITeacherTraining,
    typeParam: 'done' | 'essential',
    value: boolean
  ): Promise<any> => {
    const {
      training: { id, name }
    } = training;
    let markParam = {};
    let text = '';
    if (typeParam === 'done') {
      markParam = { done: Number(value) };
      text = value ? 'finished' : 'not finished';
    } else {
      markParam = { essential: Number(value) };
      text = value ? 'essential' : 'optional';
    }

    confirm({
      title: `Do you want to mark training "${name}" as "${text}"?`,
      async onOk() {
        try {
          setHasLoading(true);
          await trainingsRepository.editTeacherTrainingMark(teacherId, id, markParam);
          fetchTeacherTraining();
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  const deleteTrainingFromTeacher = (training: ITeacherTraining) => {
    const {
      training: { id, name }
    } = training;
    confirm({
      title: `Do you want to delete training "${name}"?`,
      okType: 'danger',
      okText: 'Delete',
      onOk: async () => {
        try {
          setHasLoading(true);
          await trainingsRepository.deleteTrainingFromTeacher(teacherId, id);
          fetchTeacherTraining();
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  useEffect(() => {
    fetchTeacherTraining();
  }, [teacherId]);

  return (
    <ErrorBoundary>
      {contextHolder}
      <AddTrainingModal
        fetchTeacherTraining={fetchTeacherTraining}
        visible={hasModalVisible}
        teacherId={teacherId}
        addedTrainingList={training.map(item => item.training.id)}
        hideModal={() => setHasModalVisible(false)}
      />
      <Loader size="small" spinning={hasLoading}>
        <StCard>
          <PageHeader
            style={{ padding: 0 }}
            title={strings.TRAININGS}
            extra={
              <Button type="primary" onClick={() => setHasModalVisible(true)}>
                {strings.ADD_TRAININGS}
              </Button>
            }
          >
            <Table dataSource={training} pagination={false}>
              {getColumns(editTeacherTrainingMark, deleteTrainingFromTeacher).map(col => (
                <Column key={col.key} {...col} />
              ))}
            </Table>
          </PageHeader>
        </StCard>
      </Loader>
    </ErrorBoundary>
  );
};

export default { TeacherTraining };
