import { Branch, Product, StudyProgram } from 'types';
import { Tag } from 'antd';
import React from 'react';
import { CheckCircleTwoTone, MinusCircleTwoTone } from '@ant-design/icons';

export const getFamilyColumns = strings => [
  {
    title: strings.NAME,
    key: 'name',
    dataIndex: 'name'
  },
  {
    title: strings.COMMENT,
    key: 'comment',
    dataIndex: 'comment'
  },
  {
    title: strings.WEIGHT,
    key: 'weight',
    dataIndex: 'weight'
  },
  {
    title: strings.ACTIVE,
    dataIndex: 'isActive',
    key: 'isActive',
    className: 'clmn-center',
    render: (isActive: boolean) =>
      isActive ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <MinusCircleTwoTone twoToneColor="#f5222d" />
  }
];

export const getTeacherColumns = strings => [
  {
    title: strings.NAME,
    key: 'name',
    dataIndex: 'name'
  },
  {
    title: strings.COMMENT,
    key: 'comment',
    dataIndex: 'comment'
  },
  {
    title: strings.WEIGHT,
    key: 'weight',
    dataIndex: 'weight'
  },
  {
    title: strings.ACTIVE,
    dataIndex: 'isActive',
    key: 'isActive',
    className: 'clmn-center',
    render: (isActive: boolean) =>
      isActive ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <MinusCircleTwoTone twoToneColor="#f5222d" />
  },
  {
    title: strings.LOGIN_ENABLED,
    dataIndex: 'loginEnabled',
    key: 'loginEnabled',
    className: 'clmn-center',
    render: (loginEnabled: boolean) => <span>{loginEnabled ? strings.YES : strings.NO}</span>
  }
];

export const getStudentColumns = strings => [
  {
    title: strings.NAME,
    key: 'name',
    dataIndex: 'name'
  },
  {
    title: strings.COMMENT,
    key: 'comment',
    dataIndex: 'comment'
  },
  {
    title: strings.ACTIVE,
    dataIndex: 'isEnabled',
    key: 'isEnabled',
    className: 'clmn-center',
    render: (isActive: boolean) =>
      isActive ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <MinusCircleTwoTone twoToneColor="#f5222d" />
  },
  {
    title: strings.VIEW_IN_THE_STATUS_REPORT,
    dataIndex: 'showInReport',
    key: 'showInReport',
    className: 'clmn-center',
    render: (showInReport: boolean) => <span>{showInReport ? strings.YES : strings.NO}</span>
  }
];

export default { getFamilyColumns, getTeacherColumns, getStudentColumns };
