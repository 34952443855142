import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Input, Row, Col, Button, Typography } from 'antd';
import { AddLesson as StAddLesson } from '../styles';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { History } from 'history';
import { useLastLocation } from 'react-router-last-location';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Title } = Typography;

interface addLessonProps {
  form: WrappedFormUtils;
  history: History;
}

export const AddLesson = Form.create()((props: addLessonProps) => {
  const [strings] = useLanguageContext();
  const {
    form: { getFieldDecorator },
    history: { goBack }
  } = props;
  const lastLocation = useLastLocation();
  const handleBack = () => goBack();
  return (
    <StAddLesson>
      <Form layout="vertical">
        <Row>
          <Col lg={8}>
            <Title>
              {lastLocation && <ArrowLeftOutlined onClick={handleBack} />} {strings.ADDING_A_LESSON}
            </Title>
          </Col>
        </Row>
        <Row>
          <Col lg={10}>
            {getFieldDecorator('lessonTopic', {
              rules: [{ required: true }]
            })(<Input.TextArea placeholder={strings.LESSON_TOPIC} />)}

            {getFieldDecorator('lessonOutcomes', {
              rules: [{ required: false }]
            })(<Input.TextArea placeholder={strings.LESSON_OUTCOMES} />)}

            {getFieldDecorator('keyVocabulary', {
              rules: [{ required: false }]
            })(<Input.TextArea placeholder={strings.KEY_VOCABULARY} />)}

            {getFieldDecorator('resources', {
              rules: [{ required: false }]
            })(<Input.TextArea placeholder={strings.RESOURCES} />)}
          </Col>
          <Col lg={10}>
            {getFieldDecorator('suggestedActivities', {
              rules: [{ required: false }]
            })(<Input.TextArea placeholder={strings.SUGGESTED_ACTIVITIES} />)}

            {getFieldDecorator('suggestedHomework', {
              rules: [{ required: false }]
            })(<Input.TextArea placeholder={strings.SUGGESTED_HOMEWORK} />)}

            {getFieldDecorator('comment', {
              rules: [{ required: false }]
            })(<Input.TextArea placeholder={strings.COMMENT} />)}
          </Col>
        </Row>
        <Row style={{ margin: '20px 0' }}>
          <Col lg={24}>
            <Button type="primary">{strings.ADD}</Button>
          </Col>
        </Row>
      </Form>
    </StAddLesson>
  );
});

export default { AddLesson };
