import React, { useState, useEffect } from 'react';
import { match } from 'react-router';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Link, useRouteMatch } from 'react-router-dom';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Spin, Button, Modal, message, Flex } from 'antd';
import { INews } from 'types';
import { dateFormat } from 'helpers/dates';
import { useNewsRepository } from 'repos/NewsRepository';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useHistory } from 'react-router-dom';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { getImageUrl } from 'helpers/getImageUrl';
import { BASE_NEWS } from 'Admin/News/routes';

const StyledNewHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledCover = styled.img`
  border-radius: 5px;
  margin: 30px auto;
  height: 350px;
  max-width: 600px;
`;

const StyledNewsContent = styled.div`
  font-size: 16px;
  line-height: 1.8;
`;
const StyledDate = styled.span`
  font-size: 14px;
  opacity: 0.8;
`;

/**
 * @description Полная новость
 * @returns {React.ReactNode}
 */
export const NewsFull = () => {
  const newsRepository = useNewsRepository();
  const [loadingNews, setLoadingNews] = useState<boolean>(false);
  const [news, setNews] = useState<INews>(null);
  const [user] = useUserContext();
  const [strings] = useLanguageContext();
  const hasAdmin = user?.hasRoles?.admin;
  const { push, goBack } = useHistory();
  const [{ confirm }, contextHolder] = Modal.useModal();

  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();

  /**
   * @description Новость по ID
   * @param newsId
   * @return {Promise<any>}
   */
  const getNews = async (): Promise<any> => {
    try {
      setLoadingNews(true);
      const { data } = await newsRepository.getNewsById(id);
      setNews(data);
      setLoadingNews(false);
    } catch {
      setLoadingNews(false);
    }
  };

  const onDeleteNews = () => {
    confirm({
      title: strings.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_POST,
      async onOk() {
        await newsRepository.deleteNews(id);
        message.success(strings.NEWS_SUCCESSFULLY_DELETED);
        push(BASE_NEWS);
      }
    });
  };

  useEffect(() => {
    if (id) {
      getNews();
    }
  }, [id]);

  return (
    <Spin spinning={loadingNews} tip={strings.NEWS_LOADING}>
      <Container>
        {contextHolder}
        <StPageHeader
          title={
            <Flex align="center" gap={5} wrap="wrap">
              <span>{news?.header}</span>
              <StyledDate>{news && dayjs(news?.date).format(dateFormat)}</StyledDate>
            </Flex>
          }
          onBack={goBack}
          extra={
            hasAdmin && (
              <Flex gap={10}>
                <Link to={`/news/edit/${news?.id}`}>
                  <Button icon={<EditOutlined />}>{strings.EDIT}</Button>
                </Link>
                <Button type="primary" icon={<DeleteOutlined />} onClick={onDeleteNews}>
                  {strings.DELETE}
                </Button>
              </Flex>
            )
          }
        />
        <StyledNewHead>{news?.image && <StyledCover src={getImageUrl(news.image)} />}</StyledNewHead>
        <StyledNewsContent dangerouslySetInnerHTML={{ __html: news?.body }} />
      </Container>
    </Spin>
  );
};
export default { NewsFull };
