import React from 'react';
import { Link } from 'react-router-dom';
import { BASE_FAMILIES } from 'Global/routes';
import { FamilyUsers } from 'Admin/People/Families/styles';
import { Branch, Status, IParent, IStudent, IFamily } from 'types';

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    width: 110,
    render: (id: number) => <Link to={`${BASE_FAMILIES}/${id}`}>{id}</Link>
  },
  {
    title: 'Parent',
    dataIndex: 'parents',
    key: 'parents',
    render: (parents: IParent[], family: IFamily) => (
      <FamilyUsers>
        {parents?.map((parent: IParent) => {
          return (
            <li className="list-style-type-none" key={parent?.id}>
              <Link to={`${BASE_FAMILIES}/${family.id}/parent/${parent?.id}`}>
                {parent?.user?.name} {parent?.user?.surname}
              </Link>
            </li>
          );
        })}
      </FamilyUsers>
    )
  },
  {
    title: 'Branch',
    dataIndex: 'branch',
    key: 'branch',
    render: (branch: Branch) => branch?.name
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    sorter: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: Status) => status?.name ?? ''
  },
  {
    title: 'Student',
    dataIndex: 'students',
    key: 'students',
    render: (student: IStudent[], family: IFamily) => (
      <FamilyUsers>
        {student?.map((child: IStudent) => {
          return (
            <li key={child?.id} className="list-style-type-none">
              <Link to={`${BASE_FAMILIES}/${family.id}/student/${child?.id}`}>
                {child?.user?.name} {child?.user?.surname}
              </Link>
            </li>
          );
        })}
      </FamilyUsers>
    )
  }
];

export default { columns };
