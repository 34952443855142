import React, { useEffect, useState } from 'react';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, message, Modal, Row, Table } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { getColumns } from './columns';
import { Filter } from './Filter';
import queryString from 'query-string';
import { isEmpty } from 'lodash';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { BASE_PERFORMERS, PERFORMERS_NEW } from '../../routes';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { IPerformer, IPerformerFilterParams } from 'types/performers';
import { usePerformersRepository } from 'repos/PerformersRepository';

export const Performers = () => {
  const [user] = useUserContext();
  const [strings] = useLanguageContext();
  const { goBack, push } = useHistory();
  const { locationSearchParams } = useLocationSearchParams();
  const { branches } = useGlobalCollectionsContext();
  const performersRepository = usePerformersRepository();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [performers, setPerformers] = useState<IPerformer[]>([]);
  const defFilterParams = { isActive: 1 };
  const [{ confirm }, contextHolder] = Modal.useModal();

  const fetchPerformers = async (params: IPerformerFilterParams = {}): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await performersRepository.getPerformers(params);
      setPerformers(data);
      push({ search: queryString.stringify(params) });
    } finally {
      setHasLoading(false);
    }
  };

  /**
   * @description Удалить performer
   * @param {object} params
   * @return {Promise<any>}
   */
  const handleDeletePerformer = (id: number): void => {
    confirm({
      title: strings.ARE_YOU_SURE,
      okType: 'danger',
      async onOk() {
        setHasLoading(true);
        try {
          await performersRepository.deletePerformer(id);
          fetchPerformers();
        } catch (err) {
          message.error(err.response.data.message);
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  const getActionColumns = () => [
    {
      title: '',
      dataIndex: 'id',
      key: 'action',
      render: (id: number) => (
        <Row align="middle" style={{ gap: 5, flexWrap: 'nowrap' }}>
          <Link to={`${BASE_PERFORMERS}/${id}/edit`}>
            <Button icon={<EditOutlined />} />
          </Link>
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
              handleDeletePerformer(id);
            }}
          />
        </Row>
      )
    }
  ];

  useEffect(() => {
    if (branches.isNotEmpty()) {
      if (isEmpty(locationSearchParams)) {
        fetchPerformers(defFilterParams);
      } else {
        fetchPerformers(locationSearchParams);
      }
    }
  }, [branches]);

  return (
    <Container>
      {contextHolder}
      <StPageHeader
        onBack={goBack}
        title={strings.PERFORMERS}
        extra={[
          <Link to={PERFORMERS_NEW}>
            <Button type="primary">{strings.ADD}</Button>
          </Link>
        ]}
      >
        <Filter onFilter={fetchPerformers} />
      </StPageHeader>
      <Row gutter={[10, 10]}>
        <Table
          dataSource={performers}
          columns={[...getColumns(strings), ...getActionColumns()]}
          pagination={false}
          loading={{ spinning: hasLoading }}
        />
      </Row>
    </Container>
  );
};
export default { Performers };
