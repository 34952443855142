import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, Input, Button } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';

interface DepositModalProps extends FormComponentProps {
  visible: boolean;
  hideModal: () => void;
  depositId: number | null;
  partiallyDepositFamily: (depositId: number, params: object) => void;
}

/**
 * @description Deposit Modal
 * @param {object} props
 * @return {React.ReactNode}
 */
export const DepositModalTemplate = (props: DepositModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    form: { getFieldDecorator, resetFields, validateFields },
    visible,
    hideModal,
    depositId,
    partiallyDepositFamily
  } = props;

  const getFooter = () => {
    const buttons = [
      <Button key="back" onClick={hideModal}>
        Cancel
      </Button>,
      <Button loading={loading} type="primary" htmlType="submit" onClick={handleSubmit}>
        Pay
      </Button>
    ];

    return buttons;
  };

  const handleCloseModal = () => {
    hideModal();
    resetFields();
  };

  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        await partiallyDepositFamily(depositId, values);
        setLoading(false);
        handleCloseModal();
      }
    });
  };

  return (
    (<Modal title="Paid partially deposit" open={visible} footer={getFooter()} onCancel={() => handleCloseModal()}>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <MyFormItem>
          {getFieldDecorator('partially', {
            rules: [{ required: true, message: 'Please input your sum!' }]
          })(<Input />)}
        </MyFormItem>
      </Form>
    </Modal>)
  );
};

export const DepositModal = Form.create<DepositModalProps>({})(DepositModalTemplate);

export default { DepositModal };
