import React from 'react';
import { Card, Row, Col, Typography } from 'antd';
import { IReportRegister } from 'types/TeacherAccount/Courses';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { dateFormatForBackend } from 'helpers';
import { useLanguageContext } from 'contexts/LanguageContext';
import { FeedbackItem } from './FeedbackItem';

const { Paragraph } = Typography;

interface AcademicProgressCardProps {
  register: IReportRegister;
  filterFeedbackType: number /*1 - all, 2 - for parents, 3 - for students, 4 - for office*/;
  refetchReports: () => void;
}

/**
 * @description Таблица Academic Progress
 * @param {object} props
 * @return {React.ReactNode}
 */
export const AcademicProgressCard = (props: AcademicProgressCardProps) => {
  const { register, filterFeedbackType, refetchReports } = props;
  const {
    lesson: { teacher },
    lesson,
    student
  } = register;
  const [strings, lang] = useLanguageContext();

  const registerDate = dayjs(lesson?.startAt, dateFormatForBackend).locale(lang).format('MMMM Do, YYYY');
  //какой feedback показывать 1 - all, 2 - for parents, 3 - for students, 4 - for office
  const hasShowFeedbackParent = Number(filterFeedbackType) === 1 || Number(filterFeedbackType) === 2;
  const hasShowFeedbackStudent = Number(filterFeedbackType) === 1 || Number(filterFeedbackType) === 3;
  const hasShowFeedbackSupervisor = Number(filterFeedbackType) === 1 || Number(filterFeedbackType) === 4;

  return (
    <>
      <Card
        title={
          <Link to={`/le/${lesson.id}`} target="_blank">
            {lesson?.courseGroup?.subject?.name + ' - ' + registerDate}
          </Link>
        }
        headStyle={{
          textTransform: 'uppercase',
          fontSize: '14px',
          fontWeight: 'normal',
          borderBottom: '0'
        }}
        bodyStyle={{ padding: 0 }}
        style={{ marginBottom: '20px', borderRadius: '5px', width: '100%' }}
      >
        <Card.Grid hoverable={false}>
          <Row gutter={8}>
            <Col lg={6} xs={24}>
              {strings.STUDENT}
              <Paragraph>
                <Link to={`/family/${student?.familyId}/student/${student?.id}`} target="_blank">
                  {student?.user?.name} {student?.user?.surname}
                </Link>
              </Paragraph>
            </Col>
            <Col lg={6} xs={24}>
              {strings.COMMENT_AUTHOR}
              <Paragraph>
                <Link to={`/teachers/${teacher?.id}`} target="_blank">
                  {teacher?.user?.name} {teacher?.user?.surname}
                </Link>
              </Paragraph>
            </Col>
            <Col lg={6} xs={24}>
              {strings.GROUP}
              <Paragraph>
                <Link to={`/group/${lesson?.courseGroup?.id}`} target="_blank">
                  {lesson?.courseGroup?.name}
                </Link>
              </Paragraph>
            </Col>
            <Col lg={6} xs={24}>
              {strings.TUTOR}
              <Paragraph>
                {student?.tutor ? (
                  <Link to={`/admin/${student?.tutor?.id}`} target="_blank">
                    {student?.tutor?.user?.name} {student?.tutor?.user?.surname}
                  </Link>
                ) : (
                  strings.NO
                )}
              </Paragraph>
            </Col>
          </Row>
        </Card.Grid>
        {register?.commentForStudent && hasShowFeedbackStudent && (
          <FeedbackItem
            id={register?.id}
            type="student"
            isApproved={register?.commentForStudentApproved}
            comment={register?.commentForStudent}
            register={register}
            refetchReports={refetchReports}
          />
        )}
        {register?.commentForParent && hasShowFeedbackParent && (
          <FeedbackItem
            id={register?.id}
            type="parent"
            isApproved={register?.commentForParentApproved}
            comment={register?.commentForParent}
            register={register}
            refetchReports={refetchReports}
          />
        )}
        {register?.commentForSupervisor && hasShowFeedbackSupervisor && (
          <FeedbackItem
            id={register?.id}
            type="supervisor"
            isApproved={register?.commentForSupervisorApproved}
            comment={register?.commentForSupervisor}
            register={register}
            refetchReports={refetchReports}
          />
        )}
      </Card>
    </>
  );
};

export default { AcademicProgressCard };
