import React from 'react';
import { Button, Modal, Input } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';

/**
 * @param {object} props
 * @return {React.ReactNode}
 */
export const EditUnit = (props: any) => {
  const [strings] = useLanguageContext();
  const { show, cancel } = props;
  return (
    (<Modal
      title={strings.EDITING_ORIGINAL_COURSE_UNIT}
      footer={[
        <Button key="back" onClick={cancel}>
          Cancel
        </Button>
      ]}
      open={show}
      onCancel={cancel}
    >
      <Input placeholder={strings.NAME} />
      <Button type="primary" style={{ marginTop: '20px' }}>
        {strings.EDIT}
      </Button>
    </Modal>)
  );
};

export default { EditUnit };
