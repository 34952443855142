import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import FamilyService from 'helpers/services/FamilyService';
import { FamiliesFilterParams } from 'types/Family';
import { IFamily } from 'types';
import { PaginationInfo } from 'types/global';
import { FamiliesTitle } from 'Admin/People/Families/Title';
import { FamilyTable } from 'Admin/People/Families/Table';
import { useFamilyRepository } from 'repos/FamilyRepository';
import { Container } from 'Global/GlobalStyle';
import { Row } from 'antd';

export const Families = () => {
  const [currentFilter, setCurrentFilter] = useState<FamiliesFilterParams>({});
  const [families, setFamilies] = useState<IFamily[]>([]);
  const [pagination, setPagination] = useState<PaginationInfo>(null);
  const [loadingFamilies, setLoadingFamilies] = useState<boolean>(false);
  const location = useLocation();
  const { push } = useHistory();
  const familyRepository = useFamilyRepository();

  const family = new FamilyService();
  const limit = FamilyService.limit;
  const locationSearchParams = family.getLocationSearchParams(location, { arrayFormat: 'bracket' });

  /**
   * @description Список семей
   * @param {FamiliesFilterParams} params
   * @return {Promise<any>}
   */
  const getFamilies = async (params?: FamiliesFilterParams): Promise<any> => {
    setLoadingFamilies(true);
    setFamilies([]);
    try {
      const {
        data: { families: familiesData, paginationInfo }
      } = await familyRepository.getFamilyList(params);
      //antd ui воспринимает children как expandedRow, поэтому переименовываем children в students
      const familyList = familiesData.map((item: IFamily) => {
        return { ...item, students: item.children, children: null };
      });

      setFamilies(familyList);
      setPagination(paginationInfo);
      push({ search: family.getQueryStringParams({ limit, ...params }, { arrayFormat: 'bracket' }) });
    } catch {
      setFamilies([]);
    } finally {
      setLoadingFamilies(false);
    }
  };

  useEffect(() => {
    const params = { limit, ...locationSearchParams };
    setCurrentFilter(params);
    getFamilies(params);
  }, []);

  return (
    <Container>
      <FamiliesTitle
        currentFilter={currentFilter}
        pagination={pagination}
        setCurrentFilter={setCurrentFilter}
        getFamilies={getFamilies}
      />
      <Row gutter={[10, 10]}>
        <FamilyTable
          families={families}
          pagination={pagination}
          loadingFamilies={loadingFamilies}
          getFamilies={getFamilies}
        />
      </Row>
    </Container>
  );
};

export default { Families };
