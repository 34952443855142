import { Supergroup } from 'types';
import { Badge, Button, Tag } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { dateFormat } from 'helpers';

export const columns = strings => [
  {
    title: strings.DATE,
    dataIndex: 'date',
    key: 'date',
    render: date => <p>{date && dayjs(date).format(dateFormat)}</p>
  },
  {
    title: strings.SUPERGROUP,
    dataIndex: 'supergroup',
    key: 'supergroup',
    render: (supergroup: Supergroup) => <p>{supergroup.name}</p>
  },
  {
    title: strings.STATUS,
    key: 'status',
    render: ({ countAllComments, countNotFilledComments }) => {
      if (countNotFilledComments === 0) {
        return (
          <p>
            <Badge color="#87d068" style={{ paddingInlineEnd: 10 }} />
            {countAllComments} / {countAllComments} students
          </p>
        );
      }

      return (
        <p>
          <Badge color="red" style={{ paddingInlineEnd: 10 }} />
          {countAllComments - countNotFilledComments} / {countAllComments} students
        </p>
      );
    }
  },
  {
    title: strings.ASSISTANT,
    key: 'assistant',
    dataIndex: 'assistant',
    render: assistant => (
      <p>
        {assistant?.user?.name} {assistant?.user?.surname}
      </p>
    )
  },
  {
    title: strings.TAG,
    key: 'tag',
    dataIndex: 'tag',
    render: tag => tag && <Tag>{tag?.name}</Tag>
  },
  {
    title: '',
    render: feedback => (
      <Link to={`/assistants-feedback/${feedback.id}`} target="_blank">
        <Button type="primary" icon={feedback?.canEdit ? <EditOutlined /> : <EyeOutlined />} />
      </Link>
    )
  }
];
