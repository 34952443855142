import React, { useState, useEffect, useMemo } from 'react';
import { EditOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Button, Form, message, Row, Spin, Alert, Flex } from 'antd';
import * as Sentry from '@sentry/browser';
import { useStudentRepository } from 'repos/StudentRepository';
import { getAllFormData } from 'helpers';
import { booleanFormatValues } from 'helpers/booleanFomatValues';
import Breadcrumbs from 'Global/components/Breadcrumbs';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import { formatDateValues } from 'helpers/dates';
import { useFamilyRepository } from 'repos/FamilyRepository';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { IFamily } from 'types';
import { match } from 'react-router';
import { StPageHeader } from 'Global/GlobalStyle';
import { AddParents, AddStudents, FamilyProfileForm } from './Forms';
import { Skeleton } from 'antd/lib';
import { FamilyProfilePutParams } from '../../../../../types/Family';

export enum EditFamilyModes {
  AddStudents = 'addStudents',
  AddParents = 'addParents',
  EditFamilyProfile = 'editFamilyProfile'
}

interface EditFamilyProps {
  mode: EditFamilyModes;
}

/**
 * @description Корневой компонент редактирования семьи
 * @param {object} props
 * @return {React.ReactNode}
 */
export const EditFamily: React.FC<EditFamilyProps> = props => {
  const [strings] = useLanguageContext();
  const { mode } = props;

  const {
    params: { id: familyId }
  }: match<{ id: string }> = useRouteMatch();
  const [form] = Form.useForm();

  const studentRepository = useStudentRepository();
  const familyRepository = useFamilyRepository();
  const globalRequestsRepository = useGlobalRequestsRepository();
  const { goBack, push } = useHistory();
  const location = useLocation();

  const [action, setAction] = useState<string>('');
  const [pageTitle, setPageTitle] = useState<string>('');
  const [currentFamily, setCurrentFamily] = useState<IFamily>(null);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const familyBranch = currentFamily?.branch?.id;

  /**
   * @description Запрос на добавление аватарки студенту
   * @return {Promise<any>}
   */
  const uploadAvatar = async (params: { id: number; file: File; type: string }): Promise<any> => {
    try {
      await globalRequestsRepository.uploadAvatar(getAllFormData(params));
    } catch {}
  };

  /**
   * @description Запрос на создание студента
   * @param {number} familyId
   * @param {object} children
   * @return {Promise<any>}
   */
  const createChildren = async (students): Promise<any> => {
    setHasLoading(true);
    return await Promise.all([
      ...students.map(async (student): Promise<any> => {
        const { avatar } = student;
        try {
          const params = booleanFormatValues(formatDateValues(student));
          const {
            data: { id },
            status
          } = await studentRepository.createStudent({
            ...params,
            family: Number(familyId),
            isActive: 1
          });
          // Если аватарка есть и студент успешно создан, то загрузим ее
          if (avatar) {
            await uploadAvatar({ id, file: avatar.file, type: 'student' });
          }
          if (status === 200) {
            message.success(strings.STUDENT_SUCCESSFULLY_CREATED, 2);
            push(`/family/${familyId}`);
          }
        } catch (err) {
          message.error(strings.STUDENT_CREATE_ERROR, 2);
        } finally {
          setHasLoading(false);
        }
      })
    ]);
  };

  /**
   * @description Запрос на создание родителя
   * @param {number} familyId
   * @param {object} children
   * @return {Promise<any>}
   */
  const createParents = async (parents): Promise<any> => {
    return await Promise.all([
      ...parents.map(async (parent): Promise<any> => {
        const { avatar } = parent;
        try {
          const params = booleanFormatValues(formatDateValues(parent));
          const {
            data: { id },
            status
          } = await familyRepository.createParent({
            ...params,
            family: Number(familyId),
            isActive: 1
          });
          // Если аватарка есть и родитель успешно создан, то загрузим ее
          if (avatar) {
            await uploadAvatar({ id, file: avatar.file, type: 'parent' });
          }
          if (status === 200) {
            message.success(strings.PARENT_SUCCESSFULLY_CREATED, 2);
            push(`/family/${familyId}`);
          }
        } catch (err) {
          message.error(strings.PARENT_CREATE_ERROR, 2);
        }
      })
    ]);
  };

  /**
   * @description Запрос на редактирование семьи
   * @param {object} family
   * @return {Promise<any>}
   */
  const editFamilyProfile = async (family: FamilyProfilePutParams): Promise<any> => {
    setHasLoading(true);
    try {
      const { status } = await familyRepository.editFamily(familyId, family);
      if (status === 200) {
        push(`/family/${familyId}`);
        message.success(strings.EDIT_FAMILY_SUCCESSFULLY, 2);
      }
    } finally {
      setHasLoading(true);
    }
  };

  /**
   * @description Отправка формы
   * @return {void}
   */
  const handleSubmit = values => {
    const { students, parents, family } = values;
    if (mode === EditFamilyModes.AddStudents) {
      createChildren(students);
    } else if (mode === EditFamilyModes.AddParents) {
      createParents(parents);
    } else if (mode === EditFamilyModes.EditFamilyProfile) {
      editFamilyProfile(family);
    }
  };

  /**
   * @description Получение информации о текущей семье
   * @return {Promise<any>}
   */
  const getCurrentFamily = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await familyRepository.getFamilyById(familyId);
      setCurrentFamily(data);
    } finally {
      setHasLoading(false);
    }
  };

  /**
   * @description Навигация по табам
   * @param {string} tab
   * @return {void}
   */
  const goToEditFamilyProfile = () => push(`/family/${familyId}/edit`);
  const goToAddStudent = () => push(`/family/${familyId}/add-students`);
  const goToAddParent = () => push(`/family/${familyId}/add-parents`);

  useEffect(() => {
    // Создание новой семьи
    if (mode === EditFamilyModes.EditFamilyProfile) {
      setAction(strings.EDIT_FAMILY_PROFILE);
      setPageTitle(strings.EDIT_FAMILY_PROFILE);
      // Добавление студентов в существующую семью
    } else if (mode === EditFamilyModes.AddStudents) {
      setAction(strings.ADD_STUDENTS);
      setPageTitle(strings.ADD_STUDENTS_TO_FAMILY);
      // Добавление родителей
    } else if (mode === EditFamilyModes.AddParents) {
      setAction(strings.ADD_PARENTS);
      setPageTitle(strings.ADD_PARENTS_TO_FAMILY);
    }
  }, []);

  const familyDefValues = useMemo(() => {
    if (currentFamily) {
      return {
        branch: currentFamily?.branch?.id,
        callAdmin: currentFamily?.callAdmin?.id,
        interviewBy: currentFamily?.interviewBy?.id,
        personalCode: currentFamily?.personalCode,
        status: currentFamily?.status?.id,
        product: currentFamily?.product?.id,
        learnedFrom: currentFamily?.learnedFrom?.id,
        commentLearnedFrom: currentFamily?.commentLearnedFrom,
        prefix1c: currentFamily?.prefix1c,
        amoLeadId: currentFamily?.amoLeadId,
        familyTags: currentFamily?.familyTags?.map(tag => tag.id)
      };
    }
    return {};
  }, [currentFamily]);

  useEffect(() => {
    if (familyId) {
      getCurrentFamily();
    }
  }, [familyId]);

  const onFinishFailed = (errorInfo: any) => {
    message.warning(strings.REQUIRED_FIELDS_ARE_NOT_FILLED_IN);
  };

  return (
    <Flex vertical style={{ margin: 20 }}>
      <Breadcrumbs location={location} />
      <StPageHeader
        title={
          <span>
            {pageTitle} <Link to={`/family/${familyId}`}>{familyId}</Link>
          </span>
        }
        onBack={goBack}
        extra={
          familyId && [
            mode !== EditFamilyModes.EditFamilyProfile && (
              <Button icon={<EditOutlined />} onClick={() => goToEditFamilyProfile()}>
                {strings.GO_TO_EDIT_PROFILE}
              </Button>
            ),
            mode !== EditFamilyModes.AddParents && (
              <Button icon={<UsergroupAddOutlined />} onClick={() => goToAddParent()}>
                {strings.ADD_PARENTS}
              </Button>
            ),
            mode !== EditFamilyModes.AddStudents && (
              <Button icon={<UsergroupAddOutlined />} onClick={() => goToAddStudent()}>
                {strings.ADD_STUDENTS}
              </Button>
            )
          ]
        }
      />
      <Spin spinning={hasLoading} tip={strings.LOADING_FAMILY}>
        <Flex>
          {currentFamily ? (
            <Form
              form={form}
              layout="vertical"
              style={{ width: '100%' }}
              onFinish={handleSubmit}
              onFinishFailed={onFinishFailed}
              initialValues={{ students: [{}], parents: [{}], family: familyDefValues }}
            >
              {mode === EditFamilyModes.AddStudents && <AddStudents familyBranch={familyBranch} />}
              {mode === EditFamilyModes.AddParents && <AddParents familyBranch={familyBranch} />}
              {mode === EditFamilyModes.EditFamilyProfile && <FamilyProfileForm family={currentFamily} />}
              <Button
                loading={hasLoading}
                disabled={hasLoading}
                type="primary"
                htmlType="submit"
                style={{ float: 'right', marginTop: 20 }}
              >
                {action}
              </Button>
            </Form>
          ) : (
            <Skeleton />
          )}
        </Flex>
      </Spin>
    </Flex>
  );
};

export default { EditFamily };
