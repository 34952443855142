import React from 'react';
import { EyeOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { dateFormat } from 'helpers/dates';
import { BASE_EMAIL } from '../routes';

export const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status, email) => email && `Messages ${email.Send.length}`
  },
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'date',
    render: date => date && dayjs(date).format(dateFormat)
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    render: (actions, email) => (
      <Link to={`${BASE_EMAIL}/${email.id}`}>
        <Button type="primary" icon={<EyeOutlined />} />
      </Link>
    )
  }
];

export default { columns };
