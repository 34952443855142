import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Row, Table, Button, message, Spin, Popconfirm } from 'antd';
import { Filter } from './Filter';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { Link, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { isEmpty } from 'lodash';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import dayjs from 'dayjs';
import { dateFormatForBackend, dateMonthFormat, formatDateValues } from 'helpers/dates';
import { PaginationInfo } from 'types/global';
import Pagination from 'Global/components/Pagination';
import { ICommentaryCalendarData, ICommentaryCalendarHeader, ICommentaryCalendarParams } from 'types/commentary';
import { useCommentaryCalendarRepository } from 'repos/CommentaryCalendarRepository';
import { Group } from 'types/education';
import { RenderCellContent } from './RenderCellContent';
import { useUserContext } from 'contexts/UserContext';

export const CommentaryCalendar = () => {
  const [strings] = useLanguageContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [commentaryCalendarData, setCommentaryCalendarData] = useState<ICommentaryCalendarData[]>([]);
  const [commentaryCalendarHeader, setCommentaryCalendarHeader] = useState<ICommentaryCalendarHeader[]>([]);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const selectedRowKeysRef = useRef([]);

  const { goBack, push } = useHistory();

  const [user] = useUserContext();
  const defFilterValues = useMemo(() => ({ limit: 10, page: 1, branch: user?.branch?.id }), [user]);
  const commentaryRepository = useCommentaryCalendarRepository();
  const { locationSearchParams } = useLocationSearchParams();
  const locationSearchParamsRef = useRef(locationSearchParams);

  const onSelectChange = useCallback(newSelectedRowKeys => {
    setIsRowSelected(newSelectedRowKeys.length > 0);
    setSelectedRowKeys(newSelectedRowKeys);
    selectedRowKeysRef.current = newSelectedRowKeys;
  }, []);

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onChange: onSelectChange
    }),
    [selectedRowKeys, onSelectChange]
  );

  const getCommentaryCalendar = useCallback(
    async (values: ICommentaryCalendarParams): Promise<any> => {
      const { page } = values;
      const params = { ...formatDateValues(values), limit: 10, page: page || 1 };
      push({ search: queryString.stringify(params, { arrayFormat: 'bracket' }) });
      setHasLoading(true);
      try {
        const {
          data: { data, header, paginationInfo }
        } = await commentaryRepository.getCommentaryCalendar(params);
        setCommentaryCalendarData(data);
        setCommentaryCalendarHeader(header);
        setPaginationInfo(paginationInfo);
      } catch (e) {
        message.error(e.message);
      } finally {
        setHasLoading(false);
      }
    },
    [commentaryRepository]
  );

  const handleChangePage = useCallback(
    async (page: number) => {
      await getCommentaryCalendar({ ...locationSearchParams, page });
    },
    [getCommentaryCalendar, locationSearchParams]
  );

  const getColumns = useMemo(
    () => [
      {
        title: strings.GROUP_COURSE,
        key: 'group',
        dataIndex: 'group',
        render: (group: Group) => <Link to={`group/${group.id}`}>{group.groupName}</Link>,
        width: 200,
        fixed: true
      }
    ],
    []
  );

  const handleAddCommentRequest = useCallback(async (groups, date) => {
    try {
      await commentaryRepository.addCommentRequest({ groups, date });
      getCommentaryCalendar(locationSearchParamsRef.current);
      setSelectedRowKeys([]);
      selectedRowKeysRef.current = [];
    } catch (e) {
      message.error(e.message);
    }
  }, []);

  const handleDeleteCommentRequest = useCallback(async (courseGroup, date) => {
    try {
      await commentaryRepository.deleteCommentRequest({ courseGroup, date });
      getCommentaryCalendar(locationSearchParamsRef.current);
    } catch (e) {
      message.error(e.message);
    }
  }, []);

  const getTitleForMutableColumns = useMemo(() => {
    return commentaryCalendarHeader.map((item, index) => {
      const weekDates = `${dayjs(item.start).format(dateMonthFormat)}-${dayjs(item.end).format(dateMonthFormat)}`;
      return (
        <>
          <p>
            {`week ${item.weekNumber} `}
            <span style={{ fontSize: 12, opacity: 0.8 }}>({weekDates})</span>
          </p>
          <Popconfirm
            title={`${strings.DO_YOU_WANT_TO_REQUEST_COMMENT_FOR} ${weekDates}?`}
            onConfirm={() =>
              handleAddCommentRequest(selectedRowKeysRef.current, dayjs(item.start).format(dateFormatForBackend))
            }
            okText={strings.YES}
            cancelText={strings.NO}
          >
            <Button size="small" type="primary" disabled={!isRowSelected}>
              {strings.REQUEST_COMMENT}
            </Button>
          </Popconfirm>
        </>
      );
    });
  }, [commentaryCalendarHeader, isRowSelected]);

  const renderCellForMutableColumns = useCallback(
    (item: ICommentaryCalendarHeader, index: number) => (record: ICommentaryCalendarData) => {
      const commentaryData = record.data[index];
      const groupId = record?.group?.id;
      const weekDates = `${dayjs(item.start).format(dateMonthFormat)}-${dayjs(item.end).format(dateMonthFormat)}`;
      const commentDate = dayjs(item.start).format(dateFormatForBackend);

      return (
        <RenderCellContent
          commentaryData={commentaryData}
          weekDates={weekDates}
          groupId={groupId}
          commentDate={commentDate}
          handleDeleteCommentRequest={handleDeleteCommentRequest}
          handleAddCommentRequest={handleAddCommentRequest}
        />
      );
    },
    []
  );

  const getMutableColumns = (commentaryCalendarHeader, isRowSelected, renderCellForMutableColumns) => {
    return useMemo(() => {
      return commentaryCalendarHeader.map((item: ICommentaryCalendarHeader, index) => {
        return {
          title: getTitleForMutableColumns[index],
          key: `data_${index}`,
          width: 180,
          align: 'center' as 'center',
          render: renderCellForMutableColumns(item, index)
        };
      });
    }, [commentaryCalendarHeader, isRowSelected]);
  };

  useEffect(() => {
    if (isEmpty(locationSearchParams)) {
      getCommentaryCalendar(defFilterValues);
    } else {
      getCommentaryCalendar(locationSearchParams);
    }
  }, []);

  useEffect(() => {
    locationSearchParamsRef.current = locationSearchParams;
  }, [locationSearchParams]);

  return (
    <Container>
      <StPageHeader onBack={goBack} title={strings.COMMENTARY_CALENDAR}>
        <Filter onFilter={getCommentaryCalendar} defValues={defFilterValues} />
      </StPageHeader>
      <Spin spinning={hasLoading}>
        <Row gutter={[10, 10]}>
          <Table
            scroll={{ x: '100%' }}
            dataSource={commentaryCalendarData}
            tableLayout="fixed"
            pagination={false}
            bordered
            rowSelection={rowSelection}
            rowKey={record => record.group?.id}
            columns={[
              ...getColumns,
              ...getMutableColumns(commentaryCalendarHeader, isRowSelected, renderCellForMutableColumns)
            ]}
          />
          {paginationInfo && (
            <Pagination
              count={paginationInfo?.pageCount}
              current={paginationInfo?.current}
              pageChange={handleChangePage}
            />
          )}
        </Row>
      </Spin>
    </Container>
  );
};

export default { CommentaryCalendar };
