import React from 'react';
import { Table } from 'antd';
import { IMetaGoalsBySubject } from 'types/Student';
import { ColumnProps } from 'antd/lib/table/Column';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Link } from 'react-router-dom';

interface SubjectTableProps {
  metaGoalsBySubject: IMetaGoalsBySubject;
}

/**
 * @description Meta Goals
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const SubjectTable = ({ metaGoalsBySubject }: SubjectTableProps) => {
  const { subject, header, data } = metaGoalsBySubject;
  const [strings] = useLanguageContext();

  const getColumn = (): ColumnProps<any>[] => {
    const headerClmns = header?.map((header, index) => {
      const { courseGroup, semester } = header;
      const title = (
        <p>
          <Link to={`/group/${courseGroup?.id}`}>{courseGroup?.nameSys}</Link>
          <br />
          <span>
            {strings.SEMESTER} {semester}
          </span>
        </p>
      );
      return {
        title: title,
        className: 'clmn-center',
        dataIndex: `data`,
        key: `data[${index}]`,
        render: data => {
          const grade = data[index];
          return <span>{Number(grade) === -2 ? strings.N_A : grade}</span>;
        }
      };
    });
    return [
      {
        title: <strong>{subject?.name}</strong>,
        width: 500,
        dataIndex: 'metaGoal',
        key: 'description',
        render: ({ description }) => description
      },
      ...headerClmns
    ];
  };

  return <Table dataSource={data} columns={getColumn()} pagination={false} bordered style={{ marginTop: 20 }} />;
};

export default { SubjectTable };
