import { httpService } from 'api/core';
import { GetAttachmentParams, UploadAttachmentParams, SearchChildByNameParams, UserLocalParams } from 'types/global';
import { WagesParams, SearchParams } from 'types/API/global';

/**
 * @description Получение списка филиалов
 * @param {object} params
 * @return {Promise<any>}
 */
const getBranches = (params?: { all: boolean }): Promise<any> => httpService.get(`/api/branches`, params);

/**
 * @description Получение списка филиалов для расписания
 * @return {Promise<any>}
 */
const getBranchesForSchedule = (): Promise<any> => httpService.get('/api/branches?schedule');

/**
 * @description Получение списка филиалов c assessment
 * @return {Promise<any>}
 */
const getBranchesForAssessment = (): Promise<any> => httpService.get('/api/branches?assessment');

/**
 * @description Получение stages по учителю
 * @param {number} teacherId
 * @return {Promise<any>}
 */
export const getTeacherStages = (teacherId: number): Promise<any> =>
  httpService.get(`/api/stages?teacher=${teacherId}`);

/**
 * @description Получение study programs
 * @return {Promise<any>}
 */
const getStudyPrograms = (): Promise<any> => httpService.get('/api/studyprograms');

/**
 * @description Получить последних исполнителей согласно Family Id
 * @param {number} id
 * @return {Promise<any>}
 */
const getLastPerformersForFamily = (id: number): Promise<any> => {
  return httpService.get(`/api/family/${id}/performers/last`);
};

/**
 * @description Получить дополнительных исполнителей согласно Branch Id
 * @param {number} id
 * @return {Promise<any>}
 */
const getAdditionalPerformers = (id: number | string): Promise<any> => {
  return httpService.get(`/api/family/${id}/performers/additional`);
};

/**
 * @description Поиск студента по имени
 * @param {SearchChildByNameParams} params
 * @return {Promise<any>}
 */
const searchChildByName = (params: SearchChildByNameParams): Promise<any> =>
  httpService.get(`/api/peoples/search/child`, params);

/**
 * @description Получение тегов учителей
 * @return {Promise<any>}
 */
const getTeachersTags = (): Promise<any> => httpService.get('/api/tags/teacher');

/**
 * @description Получить материалы
 * @param {object} params
 * @returns {Promise<any>}
 */
const getMaterials = (params?: object): Promise<any> => httpService.get('/api/materials', params);

/**
 * @description Метод удаления универсальный, и для уроков и для ивентов.
 * @param {number} attachmentId
 * @return {Promise<any>}
 */
const deleteAttachment = (attachmentId: number): Promise<any> => httpService.delete(`/api/attachment/${attachmentId}`);

/**
 * @description Редактирование прикрепленного материала
 * @param {object} params
 * @param {number} attachmentId
 * @return {Promise<any>}
 */
export const editAttachment = (params: Partial<any>, attachmentId: number): Promise<any> =>
  httpService.put(`/api/attachment/${attachmentId}`, params);

/**
 * @description Отправиить файл на печать
 * @param {string} message
 * @param {number} attachmentId
 * @return {Promise<any>}
 */
export const printAttachment = (message: string, attachmentId: number): Promise<any> =>
  httpService.post(`/api/attachment/${attachmentId}/print`, { message });

/**
 * @description эндпоинт для проверки на существование телефона
 * @param {string} phone
 * @return {Promise<any>}
 */
export const validatePhone = (phone: string) => {
  return httpService.post(`/api/v1/user/phone_validate`, { phone: phone }, null, null, true);
};

/**
 * @description Файл из ссылки в рассылке ДЗ на почту родителям
 * @param {string} hash
 * @return {Promise<any>}
 */
export const getAttachmentByHash = (hash: string) => {
  return httpService.get(`/api/v1/attachment/hash`, { hash }, { responseType: 'blob' }, true);
};

export const Global = {
  getBranchesForSchedule,
  getBranches,
  getTeacherStages,
  getAdditionalPerformers,
  getLastPerformersForFamily,
  getStudyPrograms,
  getTeachersTags,
  getMaterials,
  deleteAttachment,
  searchChildByName,
  editAttachment,
  getBranchesForAssessment,
  printAttachment,
  validatePhone,
  getAttachmentByHash
};

export default { Global };
