import React from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select } from 'antd';
import { GetFieldDecoratorOptions } from '@ant-design/compatible/lib/form/Form';
import { CUSTOM_FIELD_MY_ROLE_ID } from 'Global/modules/Support/Modal/constants';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Option } = Select;

interface RoleSelectProps {
  getFieldDecorator<T extends Object = {}>(
    id: keyof T,
    options?: GetFieldDecoratorOptions
  ): (node: React.ReactNode) => React.ReactNode;
  initialValue?: string;
}

/**
 * @description Селект тип ролей для ЛК админа
 * @param {object} param
 * @return {React.ReactNode}
 */
export const RoleSelect = ({ getFieldDecorator, initialValue }: RoleSelectProps) => {
  const [strings] = useLanguageContext();

  return (
    <MyFormItem label={strings.ROLE} colon={false}>
      {getFieldDecorator(CUSTOM_FIELD_MY_ROLE_ID, {
        rules: [{ required: false }],
        initialValue: initialValue
      })(
        <Select placeholder={strings.SELECT_ROLE}>
          <Option value="">&nbsp;</Option>
          <Option value="Admin" key="0">
            {strings.ADMIN}
          </Option>
          <Option value="Admin-ADM" key="1">
            {strings.ADMIN_ADM}
          </Option>
          <Option value="Admin-Family-Tutor" key="2">
            {strings.ADMIN_FAMILY_TUTOR}
          </Option>
          <Option value="Teacher" key="3">
            {strings.TEACHER}
          </Option>
          <Option value="Teacher-Assistant" key="4">
            {strings.TEACHER_ASSISTANT}
          </Option>
          <Option value="Parent" key="5">
            {strings.PARENT}
          </Option>
          <Option value="Student" key="6">
            {strings.STUDENT}
          </Option>
          <Option value="Other-role" key="7">
            {strings.OTHER}
          </Option>
        </Select>
      )}
    </MyFormItem>
  );
};

export default { RoleSelect };
