import React from 'react';
import { Collapse, Flex, Typography } from 'antd';
import { AdminsTable } from '../Tables/Admins';
import { ParentsTable } from '../Tables/Parents';
import { TeachersTable } from '../Tables/Teachers';
import { TableWrapper, TableContainer } from './styles';
import { Admin, IParent, IStudent } from 'types';
import { Teacher } from 'types/Teachers/teacher';
import { useLanguageContext } from 'contexts/LanguageContext';
import { StudentsTable } from '../Tables/Children';
import styled from 'styled-components';
import { CaretRightOutlined } from '@ant-design/icons';

const { Title } = Typography;

interface ResultsProps {
  admins: Admin[];
  parents: IParent[];
  students: IStudent[];
  teachers: Teacher[];
  inactive?: boolean;
  countUsers: (admins: Admin[], parents: IParent[], teachers: Teacher[], students: IStudent[]) => number;
  handleUserRefetch: () => void;
}

const StCollapse = styled(Collapse)`
  .ant-collapse-header,
  .ant-collapse-content {
    padding: 0px !important;
  }
`;

/**
 * @description Отображение результата поиска только активных пользователей
 * @param {ResultsProps} props
 * @return {React.ReactNode}
 */
export const Results = (props: ResultsProps) => {
  const { admins, parents, students, inactive, teachers, countUsers, handleUserRefetch } = props;
  const count = countUsers(admins, parents, teachers, students);
  const [strings] = useLanguageContext();

  return (
    <>
      {count > 0 && (
        <Flex vertical gap={20}>
          {admins && admins?.isNotEmpty() && (
            <StCollapse
              ghost
              defaultActiveKey={['1']}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              items={[
                {
                  key: '1',
                  label: <Title level={4}>{strings.ADMINS}</Title>,
                  children: <AdminsTable admins={admins} pagination={false} handleUserRefetch={handleUserRefetch} />
                }
              ]}
            />
          )}
          {students && students?.isNotEmpty() && (
            <StCollapse
              ghost
              defaultActiveKey={['1']}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              items={[
                {
                  key: '1',
                  label: <Title level={4}>{strings.STUDENTS}</Title>,
                  children: (
                    <StudentsTable
                      students={students.filter((item: IStudent) => item?.user)}
                      handleUserRefetch={handleUserRefetch}
                      hasSearchResult={true}
                    />
                  )
                }
              ]}
            />
          )}
          {parents && parents?.isNotEmpty() && (
            <StCollapse
              ghost
              defaultActiveKey={['1']}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              items={[
                {
                  key: '1',
                  label: <Title level={4}>{strings.PARENTS}</Title>,
                  children: (
                    <ParentsTable
                      parents={parents.filter((item: IParent) => item?.user)}
                      pagination={false}
                      handleUserRefetch={handleUserRefetch}
                    />
                  )
                }
              ]}
            />
          )}
          {teachers && teachers?.isNotEmpty() && (
            <StCollapse
              ghost
              defaultActiveKey={['1']}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              items={[
                {
                  key: '1',
                  label: <Title level={4}>{strings.TEACHERS}</Title>,
                  children: <TeachersTable teachers={teachers} handleUserRefetch={handleUserRefetch} />
                }
              ]}
            />
          )}
        </Flex>
      )}
    </>
  );
};

export default { Results };
