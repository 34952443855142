import React, { useMemo } from 'react';
import { httpService } from 'api/core';
import {
  generateInvoiceParams,
  IInvoiceBulk,
  ILetterTemplatePostParams,
  SendInvoiceType
} from 'Admin/People/Families/Family/Invoices/types';
import { InvoiceFilterParams } from 'types/invoice';
import { downloadSomeFile } from 'helpers';

const InvoicesRepositoryContext = React.createContext<IInvoicesRepository>(null);
interface IInvoicesRepository {
  getRatesTypes: () => Promise<any>;
  getNewRatesByChildId: (id: number | string, params) => Promise<any>; //TODO описать params
  editFamilyDiscountById: (id: number | string, params) => Promise<any>; //TODO описать params
  createFamilyDiscount: (id: number | string, params) => Promise<any>; //TODO описать params
  downloadRigaInvoicesBunchGenerator: (params) => Promise<any>; //TODO описать params
  generateFamilyInvoice: (id: number, params) => Promise<any>; //TODO описать params
  generateFinalInvoice: (id: number | string, params: generateInvoiceParams) => Promise<any>;
  generateBooksInvoice: (id: number | string, params: generateInvoiceParams) => Promise<any>;
  generateMainInvoice: (id: number | string, params: generateInvoiceParams) => Promise<any>;
  getInvoicesByFamilyId: (id: number | string) => Promise<any>;
  getInvoiceMonthSystemList: (params: InvoiceFilterParams) => Promise<any>;
  bulkTurnDraftToPending: (params: IInvoiceBulk) => Promise<any>;
  bulkMailingInvoices: (params: IInvoiceBulk) => Promise<any>;
  downloadInvoice: (id: string | number) => Promise<any>;
  sendInvoice: (id: number, params: SendInvoiceType) => Promise<any>;
  updateInvoiceStatus: (params: { id: number; status: number; partiallyPaid?: number }) => Promise<any>;
  getLetterTemplates: () => Promise<any>;
  getLetterTemplateById: (id: number | string) => Promise<any>;
  createLetterTemplate: (params: ILetterTemplatePostParams) => Promise<any>;
  editLetterTemplate: (id: number | string, params: ILetterTemplatePostParams) => Promise<any>;
  deleteLetterTemplate: (id: number | string) => Promise<any>;
}
class InvoicesRepository implements IInvoicesRepository {
  getRatesTypes() {
    return httpService.get('/api/v1/prices/types', null, null, true);
  }

  getNewRatesByChildId(id: number | string, params) {
    return httpService.get(`/api/v1/prices/child/${id}`, params, null, true);
  }

  editFamilyDiscountById(id: number | string, params) {
    return httpService.put(`/api/v1/families/discounts/${id}`, params, null, null, true);
  }

  createFamilyDiscount(id: number | string, params) {
    return httpService.post(`/api/v1/families/${id}/discounts`, params, null, null, true);
  }

  downloadRigaInvoicesBunchGenerator(params) {
    return httpService.get('/api/v1/invoices/riga_invoice_bunch_generator', params, null, true);
  }

  generateFinalInvoice(id: number | string, params: generateInvoiceParams) {
    return httpService.post(`/api/v1/invoices/family/${id}/generate/final-invoice`, params, null, null, true);
  }

  generateBooksInvoice(id: number | string, params: generateInvoiceParams) {
    return httpService.post(`/api/v1/invoices/family/${id}/generate/books-invoice`, params, null, null, true);
  }

  generateMainInvoice(id: number | string, params: generateInvoiceParams) {
    return httpService.post(`/api/v1/invoices/family/${id}/generate/main`, params, null, null, true);
  }

  getInvoicesByFamilyId(id: number | string) {
    return httpService.get(`/api/v1/invoices/family/${id}`, null, null, true);
  }

  getInvoiceMonthSystemList(params: InvoiceFilterParams) {
    return httpService.get('/api/v1/report/month-invoices', params, null, true);
  }

  bulkTurnDraftToPending(params: IInvoiceBulk) {
    return httpService.post('/api/v1/invoices/bulk-draft-pending', params, null, null, true);
  }

  bulkMailingInvoices(params: IInvoiceBulk) {
    return httpService.post('/api/v1/invoices/bulk-mailing', params, null, null, true);
  }

  async downloadInvoice(id: string | number) {
    await downloadSomeFile(`/api/v1/invoices/${id}/download`, null, null, null, true);
  }

  sendInvoice(id: number, params: SendInvoiceType) {
    return httpService.post(`/api/v1/invoices/${id}/send`, params, null, null, true);
  }

  updateInvoiceStatus(params: { id: number; status: number; partiallyPaid?: number }) {
    const { id, status, partiallyPaid } = params;
    return httpService.put(
      `/api/v1/invoices/${id}/status/${status}`,
      partiallyPaid ? { partiallyPaid: partiallyPaid } : null,
      null,
      null,
      true
    );
  }
  generateFamilyInvoice(id: number, params) {
    return httpService.post(`/api/v1/invoices/family/${id}`, params, null, null, true);
  }
  getLetterTemplates() {
    return httpService.get('/api/v1/invoices/letters-templates', null, null, true);
  }
  getLetterTemplateById(id: number | string) {
    return httpService.get(`/api/v1/invoices/letters-templates/${id}`, null, null, true);
  }
  createLetterTemplate(params: ILetterTemplatePostParams) {
    return httpService.post(`/api/v1/invoices/letters-templates`, params, null, null, true);
  }
  editLetterTemplate(id: number | string, params: ILetterTemplatePostParams) {
    return httpService.put(`/api/v1/invoices/letters-templates/${id}`, params, null, null, true);
  }

  deleteLetterTemplate(id: number | string) {
    return httpService.delete(`/api/v1/invoices/letters-templates/${id}`, null, null, true);
  }
}

export const useInvoicesRepository = () => {
  const service = React.useContext(InvoicesRepositoryContext);

  if (!service) {
    throw new Error('Invoices repository is unavailable');
  }

  return service;
};

export const InvoicesRepositoryProvider = props => {
  const service = useMemo(() => new InvoicesRepository(), []);

  return <InvoicesRepositoryContext.Provider value={service}>{props.children}</InvoicesRepositoryContext.Provider>;
};
