import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Typography, Table, Button } from 'antd';
import { useLastLocation } from 'react-router-last-location';
import { Container } from 'Global/styles';
import { Families } from 'api/Families';
import { columns } from './columns';
import { SOURCE_OF_REFERENCE_NEW } from '../routes';
import { useLanguageContext } from 'contexts/LanguageContext';

// Типы
import { LearnedFrom } from 'types/Family';

const { Title } = Typography;

/**
 * @description Источнки ссылки
 * @return {React.ReactNode}
 */
export const SourceReference = () => {
  const [strings] = useLanguageContext();
  const [learnedFromList, setLearnedFromList] = useState<LearnedFrom[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);

  // Hooks
  const { goBack } = useHistory();
  const lastLocation = useLastLocation();

  /**
   * @description Get list learned from
   * @param {boolean | undefined} active
   * @return {Promise<any>}
   */
  const getLearnedFrom = async (active?: boolean | undefined): Promise<any> => {
    setLoading(true);
    await Families.learnedFrom(active)
      .then(({ data }) => {
        setLearnedFromList(data.items);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  // Кнопка для переключения между активными и всего списка
  const showActive = () => {
    setActive(active => !active);
  };

  useEffect(() => {
    if (active) {
      getLearnedFrom(active);
    } else {
      getLearnedFrom(undefined);
    }
  }, [active]); // eslint-disable-line

  useEffect(() => {
    getLearnedFrom();
  }, []); // eslint-disable-line
  return (
    <Container>
      <Title level={3}>
        {lastLocation && <ArrowLeftOutlined onClick={goBack} style={{ marginInlineEnd: 6 }} />}
        {strings.SOURCE_OF_REFERENCE}
        <Button style={{ margin: '0 6px' }} size="small" type="primary">
          <Link to={SOURCE_OF_REFERENCE_NEW}>{strings.ADD_NEW}</Link>
        </Button>
        <Button onClick={showActive} disabled={loading} size="small">
          {strings.SHOW} {active ? strings.ALL : strings.ACTIVE}
        </Button>
      </Title>
      <Table
        dataSource={learnedFromList}
        columns={columns(strings)}
        loading={{
          spinning: loading,
          tip: `${strings.LOADING} ${strings.SOURCE_OF_REFERENCE}`
        }}
        rowKey="id"
        pagination={false}
      />
    </Container>
  );
};

export default { SourceReference };
