import React from 'react';
import { dateFormat } from 'helpers/dates';
import dayjs from 'dayjs';
import { CreatedBy, Family } from 'types/finance';
import { Link } from 'react-router-dom';
import { BASE_FAMILIES } from 'Global/routes';

export const columns = strings => [
  {
    title: strings.DATE,
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 120,
    render: (date: Date) => <p>{dayjs(date).format(dateFormat)}</p>
  },
  {
    title: strings.ACTION,
    dataIndex: 'type',
    key: 'type'
  },
  {
    title: strings.INVOICE,
    dataIndex: 'invoiceId',
    key: 'invoiceId'
  },
  {
    title: strings.FAMILY,
    dataIndex: 'family',
    key: 'family',
    render: (family: Family | false) => family && <Link to={`${BASE_FAMILIES}/${family?.id}`}>{family?.id}</Link>
  },
  {
    title: strings.MESSAGE,
    dataIndex: 'content',
    key: 'content'
  },
  {
    title: strings.ADMIN,
    dataIndex: 'createdBy',
    key: 'createdBy',
    render: (createdBy: CreatedBy) => (
      <p>
        {createdBy?.name} {createdBy?.surname}
      </p>
    )
  }
];

export default { columns };
