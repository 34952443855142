import React from 'react';
import dayjs from 'dayjs';
import { TeacherHistorySms } from 'types/Teachers/History';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { Row, Tag } from 'antd';
import { HistoryItem } from 'Admin/People/Teachers/styles';
import { dateTimeFormat } from 'helpers/dates';
import { useLanguageContext } from '../../../../../contexts/LanguageContext';

export interface SmsProps extends TeacherHistorySms {}

/**
 * @description Компонент смски
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const Sms = (props: SmsProps) => {
  const [strings] = useLanguageContext();
  const { date, text, title, status, admin } = props;
  return (
    <HistoryItem>
      <h4>
        {status === 'success' && (
          <CheckCircleTwoTone twoToneColor="#52c41a" style={{ marginInlineEnd: 10, fontSize: 25 }} />
        )}
        <Tag color="blue">{title}</Tag>
        <span style={{ marginInlineStart: 10 }}>{dayjs(date).format(dateTimeFormat)}</span>
      </h4>
      <p>{text}</p>
      <span style={{ opacity: '.7' }}>
        {strings.ADMIN}: {admin?.user?.name} {admin?.user?.surname}
      </span>
    </HistoryItem>
  );
};

export default { Sms };
