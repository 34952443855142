import React from 'react';
import zoomIconImage from 'img/zoom.png';

export const ZoomIcon = () => (
  <img
    src={zoomIconImage}
    alt="zoom link"
    width={16}
    height={16}
    style={{
      position: 'relative',
      top: '-1px'
    }}
  />
);
