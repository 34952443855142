import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useLanguageContext } from 'contexts/LanguageContext';

/**
 * @description Страница "не найдено" в журнале
 * @return {React.ReactNode}
 */
export const LessonNotFound = () => {
  const [strings] = useLanguageContext();
  return (
    <Result
      status="404"
      title="404"
      subTitle={strings.LESSON_NOT_FOUND}
      extra={
        <Button type="primary" size="large" icon={<ArrowLeftOutlined />}>
          <Link to="/" style={{ color: '#fff', paddingInlineStart: '10px' }}>
            {strings.BACK_HOME}
          </Link>
        </Button>
      }
    />
  );
};
