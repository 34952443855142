import { httpService } from 'api/core';

/**
 * @description Получить колонки учителей курс групп
 * @param {number} courseId id coursegroup
 * @return {Promise<any>}
 */
export const getCourseGroupColumns = (courseId: number): Promise<any> =>
  httpService.get(`/api/coursegroup/${courseId}/teachers/block`);

/**
 * @description перетаскивания учителей
 * @param {number} idCourse
 * @param {number} idTeacher
 * @return {Promise<any>}
 */
const moveTeacher = (idCourse: number, idTeacher: number, params: any): Promise<any> =>
  httpService.post(`/api/coursegroup/${idCourse}/teacher/${idTeacher}`, params);

/**
 * @description перетаскивания удаления учителей
 * @param {number} idCourse
 * @param {number} idTeacher
 * @return {Promise<any>}
 */
const moveDeleteTeacher = (idCourse: number, idTeacher: number, params: any): Promise<any> =>
  httpService.delete(`/api/coursegroup/${idCourse}/teacher/${idTeacher}`, params);

export const DraggbleApi = {
  getCourseGroupColumns,
  moveDeleteTeacher,
  moveTeacher
};

export default {
  getCourseGroupColumns,
  moveTeacher,
  moveDeleteTeacher
};
