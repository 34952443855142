import * as monitoringRoutes from 'Admin/Monitoring/routes';
import {
  ReportTeachers,
  Lessons,
  AcademicProgress,
  Grading,
  ProgressReport,
  ViewProgressReport,
  MarketingEvents,
  AddMarketingEvents,
  MessagesFromParents,
  ShowThread,
  SummaryTable,
  ShowAssessment,
  SummaryCalendar,
  ManualInput,
  InvoiceMoscow,
  OriginalCoursesAccess,
  StudentsPayingReport,
  TeacherFinance,
  FamilyFinance,
  InvoicesGenerator,
  ProgressReportGenerator,
  ProgressReportEditForm,
  CommentaryCalendar,
  NewProgressReports,
  EditNewProgressReport
} from 'Admin/Monitoring';

export const monitoring = [
  {
    path: monitoringRoutes.BASE_REPORT_TEACHERS,
    component: ReportTeachers,
    exact: true
  },
  {
    path: monitoringRoutes.BASE_LESSONS,
    component: Lessons,
    exact: true
  },
  {
    path: monitoringRoutes.BASE_FEEDBACK_MANAGEMENT,
    component: AcademicProgress,
    exact: true
  },
  {
    path: monitoringRoutes.BASE_GRADING,
    component: Grading,
    exact: true
  },
  {
    path: monitoringRoutes.BASE_PROGRESS_REPORT,
    component: ProgressReport,
    exact: true
  },
  {
    path: monitoringRoutes.PROGRESS_REPORT_GENERATOR,
    component: ProgressReportGenerator,
    exact: true
  },
  {
    path: `${monitoringRoutes.BASE_PROGRESS_REPORT}/edit/:id`,
    component: ProgressReportEditForm,
    exact: true
  },
  {
    path: `${monitoringRoutes.BASE_PROGRESS_REPORT}/:id`,
    component: ViewProgressReport,
    exact: true
  },
  {
    path: monitoringRoutes.BASE_MARKETING_EVENTS,
    component: MarketingEvents,
    exact: true
  },
  {
    path: monitoringRoutes.ADD_MARKETING_EVENTS,
    component: AddMarketingEvents,
    exact: true
  },
  {
    path: monitoringRoutes.BASE_MESSAGES_FROM_PARENTS,
    component: MessagesFromParents,
    exact: true
  },
  {
    path: monitoringRoutes.BASE_ORIGINAL_COURSES_ACCESS,
    component: OriginalCoursesAccess,
    exact: true
  },
  {
    path: monitoringRoutes.BASE_REPORT_MANUAL_INPUT,
    component: ManualInput,
    exact: true
  },
  {
    path: monitoringRoutes.BASE_INVOICE_MONTH_SYSTEM,
    component: InvoiceMoscow,
    exact: true
  },
  {
    path: `${monitoringRoutes.BASE_MESSAGES_FROM_PARENTS}/:id`,
    component: ShowThread,
    exact: true
  },
  {
    path: monitoringRoutes.BASE_SUMMARY_TABLE,
    component: SummaryTable,
    exact: true
  },
  {
    path: `${monitoringRoutes.BASE_SUMMARY_TABLE}/:id`,
    component: ShowAssessment,
    exact: true
  },
  {
    path: monitoringRoutes.BASE_SUMMARY_CALENDAR,
    component: SummaryCalendar,
    exact: true
  },
  {
    path: monitoringRoutes.BASE_FINANCE_TEACHERS,
    component: TeacherFinance,
    exact: true
  },
  {
    path: monitoringRoutes.BASE_FINANCE_FAMILIES,
    component: FamilyFinance,
    exact: true
  },
  {
    path: monitoringRoutes.BASE_REPORT_STUDENTS_PAYING,
    component: StudentsPayingReport,
    exact: true
  },
  {
    path: monitoringRoutes.INVOICES_GENERATOR,
    component: InvoicesGenerator,
    exact: true
  },
  {
    path: monitoringRoutes.BASE_COMMENTARY_CALENDAR,
    component: CommentaryCalendar,
    exact: true
  },
  {
    path: monitoringRoutes.NEW_PROGRESS_REPORTS,
    component: NewProgressReports,
    exact: true
  },
  {
    path: monitoringRoutes.EDIT_NEW_PROGRESS_REPORTS,
    component: EditNewProgressReport,
    exact: true
  }
];

export default { monitoring };
