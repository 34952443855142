import React, { useState, useEffect } from 'react';
import { HeaderContainer, HeaderLogoTitle } from './Header/styles';
import { Link } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';
import { Row, Col, Button, Spin } from 'antd';
import { match } from 'react-router';
import { Logo } from './Logo';
import { Global } from 'api';
import { downloadFile } from 'helpers';
import { decodeDispositionFilename } from 'helpers/dispositionFilename';

interface FileFromLetterProps extends React.ComponentProps<any> {
  match: match<{ id: string }>;
}
export const FileFromLetter = (props: FileFromLetterProps) => {
  const {
    match: {
      params: { id }
    }
  } = props;
  const [fileUrl, setPreviewUrl] = useState<string>();
  const [fileName, setFileName] = useState<string>('');
  const [hasLoading, setHasLoading] = useState<boolean>(true);

  const iframeProps = {
    src: `${fileUrl}#toolbar=0&statusbar=0`,
    style: {
      width: '100%',
      height: '100vh',
      border: 'none'
    }
  };

  const getAttachmentByHash = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data, headers } = await Global.getAttachmentByHash(id);
      const blob = new Blob([data], { type: data.type });
      const fileURL = URL.createObjectURL(blob);
      setPreviewUrl(fileURL);
      const disposition = headers['content-disposition'];
      const dispositionFileName = decodeDispositionFilename(disposition);
      document.title = dispositionFileName;
      setFileName(dispositionFileName);
    } catch {
      setPreviewUrl(undefined);
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    getAttachmentByHash();
  }, []);

  return (
    <Spin spinning={hasLoading}>
      <HeaderContainer>
        <Row align="middle" style={{ gap: 10 }}>
          <Col>
            <HeaderLogoTitle>
              <Link to="/">
                <Logo />
              </Link>
            </HeaderLogoTitle>
          </Col>
          {fileUrl && (
            <Col style={{ flex: 1 }}>
              <Button style={{ float: 'right' }} type="primary" onClick={() => downloadFile(fileUrl, fileName)}>
                Download <DownloadOutlined />
              </Button>
            </Col>
          )}
        </Row>
      </HeaderContainer>
      <div style={{ paddingTop: 70 }}>
        {fileUrl && <iframe {...iframeProps}></iframe>}
        {!fileUrl && !hasLoading && (
          <div style={{ padding: 20 }}>
            This file is no longer available, you can find it in <Link to="/">AMI</Link>
          </div>
        )}
      </div>
    </Spin>
  );
};

export default { FileFromLetter };
