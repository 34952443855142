import React, { useCallback, memo } from 'react';
import { Tooltip, Tag, Popconfirm, Button } from 'antd';
import { CheckOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useLanguageContext } from 'contexts/LanguageContext';

interface RenderCellContentProps {
  commentaryData: boolean;
  weekDates: string;
  groupId: number | undefined;
  commentDate: string;
  handleDeleteCommentRequest: (groupId: number | undefined, commentDate: string) => void;
  handleAddCommentRequest: (groupIds: number[], commentDate: string) => void;
}

export const RenderCellContent: React.FC<RenderCellContentProps> = memo(
  ({ commentaryData, weekDates, groupId, commentDate, handleDeleteCommentRequest, handleAddCommentRequest }) => {
    const [strings] = useLanguageContext();

    return (
      <Tooltip title={weekDates}>
        <div style={{ display: 'flex', justifyContent: 'center', gap: 5 }}>
          {commentaryData ? (
            <>
              <Tag color="green" style={{ width: 115 }}>
                {strings.REQUESTED} <CheckOutlined />
              </Tag>
              <Popconfirm
                title={`${strings.DO_YOU_WANT_TO_DELETE_REQUEST_FOR} ${weekDates}?`}
                onConfirm={() => handleDeleteCommentRequest(groupId, commentDate)}
                okText={strings.YES}
                cancelText={strings.NO}
              >
                <Button size="small" shape="circle" icon={<MinusOutlined />} />
              </Popconfirm>
            </>
          ) : (
            <>
              <Tag style={{ width: 115 }}>{strings.NOT_REQUESTED}</Tag>
              <Popconfirm
                title={`${strings.DO_YOU_WANT_TO_REQUEST_COMMENT_FOR} ${weekDates}?`}
                onConfirm={() => handleAddCommentRequest([groupId!], commentDate)} // добавлен оператор "!" чтобы указать что значение не undefined
                okText={strings.YES}
                cancelText={strings.NO}
              >
                <Button size="small" shape="circle" icon={<PlusOutlined />} />
              </Popconfirm>
            </>
          )}
        </div>
      </Tooltip>
    );
  }
);
