import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Typography, Col, Row, message } from 'antd';
import { Loader } from 'Global/components/Loader/Loader';
import { VideoNews as StVideoNews, VideoNewsInfo, NewsItemWrapper } from './styles';
import Helmet from 'react-helmet';
import Pagination from 'Global/components/Pagination';
import { PaginationInfo } from 'types/global';
import { INews } from 'types';
import { useNewsRepository } from 'repos/NewsRepository';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Title, Paragraph } = Typography;

const NewsItem = ({ item }) => {
  return (
    <NewsItemWrapper>
      <Title level={4}>{item.header}</Title>
      <Paragraph>
        <div
          dangerouslySetInnerHTML={{
            __html: item.body
          }}
        />
      </Paragraph>
      <Paragraph
        style={{
          fontSize: '.8rem',
          color: '#777',
          fontWeight: 600
        }}
      >
        {item.date}
      </Paragraph>
    </NewsItemWrapper>
  );
};

export const VideoNews = () => {
  const [strings] = useLanguageContext();
  const [loading, setLoading] = useState<boolean>(true);
  const newsRepository = useNewsRepository();
  const [news, setNews] = useState<INews[]>([]);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo | undefined>();

  const handlePageChange = async (page?: number) => {
    setLoading(true);
    const { data } = await newsRepository.getNews({ video: true, ...(page && { page }) });

    try {
      setPaginationInfo(data.paginationInfo);
      setNews(
        data.items
          .filter((news: INews) => news.hasVideo)
          .map((news: INews) => ({
            ...news,
            date: dayjs(news.date).format('DD.MM.YYYY')
          }))
      );
    } catch (e) {
      message.error(strings.UNEXPECTED_ERROR_WHEN_FETCHING_NEWS);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handlePageChange();
  }, []);

  return (
    <>
      <Helmet>
        <title>AMI: {strings.VIDEO_NEWS}</title>
      </Helmet>
      <Loader spinning={loading}>
        <StVideoNews>
          <Row>
            <Col lg={12}>
              <Title level={3}>{strings.VIDEO_NEWS_LIST}</Title>
            </Col>
          </Row>
          <Row>
            <Col lg={24}>
              <VideoNewsInfo>
                {news.map(item => (
                  <NewsItem key={item.id} item={item} />
                ))}
              </VideoNewsInfo>
            </Col>
          </Row>

          {paginationInfo && (
            <Pagination
              current={paginationInfo.current}
              count={paginationInfo.totalCount}
              pageSize={paginationInfo.numItemsPerPage}
              pageChange={handlePageChange}
            />
          )}
        </StVideoNews>
      </Loader>
    </>
  );
};

export default { VideoNews };
