import styled from 'styled-components';

export const Education = styled.div`
  margin: 20px;
  padding: 30px 20px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 46px -15px rgb(0 0 0 / 12%);
  .ant-col {
    padding: 0 6px;
  }
  .ant-select {
    width: 100%;
  }
  .ant-checkbox-wrapper {
    width: 100%;
    margin: 0;
    padding: 10px 0;
  }
`;

export const TeachingMaterials = styled(Education)``;
export const EditTeachingMaterials = styled(Education)``;
export const TeachingMaterial = styled(Education)``;
export const Materials = styled(Education)``;
export const FilterMaterials = styled.div``;
export const LessonTopic = styled(Education)`
  .ant-btn {
    margin-inline-start: 10px;
  }
`;

export const InfoForm = styled.div`
  .ant-btn {
    margin-inline-start: 0;
  }
`;

export default {
  TeachingMaterials,
  EditTeachingMaterials,
  TeachingMaterial,
  Materials,
  FilterMaterials,
  LessonTopic,
  InfoForm
};
