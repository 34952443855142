import React from 'react';
import { isNull } from 'lodash';
import { Radio } from 'antd';
import { RadioContainer } from 'Admin/Courses/styles';
import { generateColumn } from '../../helpers';
import { THandleSetFieldByChildId } from 'types/TeacherAccount/Courses';
import { useLanguageContext } from 'contexts/LanguageContext';
import { ILessonRegister } from 'types/lesson';
import { StTitle } from '../styles';

/**
 * @description Рендеринг колонки посещения
 * @param {boolean} presence
 * @param {object} register
 * @param {function} handleSetFieldByChildId
 * @return {React.ReactNode}
 */
export const RegisterPresence = React.memo(
  ({
    presence,
    register,
    handleSetFieldByChildId
  }: {
    presence: boolean;
    register: ILessonRegister;
    handleSetFieldByChildId: THandleSetFieldByChildId;
  }) => {
    const [strings] = useLanguageContext();
    const { student } = register;

    const checked = React.useMemo(() => {
      if (!isNull(presence)) return Boolean(presence);

      return null;
    }, [presence, register]);

    const handleChange = e => {
      const value = e.target.value;
      handleSetFieldByChildId('presence', student.id, value);
    };

    return (
      <RadioContainer>
        <Radio.Group size="small" onChange={handleChange} value={checked} buttonStyle="solid">
          <Radio.Button value={false}>{strings.NO}</Radio.Button>
          <Radio.Button value={true}>{strings.YES}</Radio.Button>
        </Radio.Group>
      </RadioContainer>
    );
  }
);

/**
 * @description Генерация колонки посещения
 * @param {function} handleSetFieldByChildId
 * @return {object}
 */
export const getAttendanceColumn = (handleSetFieldByChildId, className, strings) => ({
  ...generateColumn(<StTitle>{strings.ATTENDANCE}</StTitle>, 'presence', 'presence', className, null, 100),
  render: (presence: boolean, register: ILessonRegister) => (
    <RegisterPresence presence={presence} register={register} handleSetFieldByChildId={handleSetFieldByChildId} />
  )
});
