import React, { useState } from 'react';
import styled from 'styled-components';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, Input, Button, message } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Courses } from 'api/Courses';
import { useLanguageContext } from 'contexts/LanguageContext';

interface ModalEditUnitProps extends FormComponentProps {
  modalVisible: boolean;
  handleCloseModal: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  editnameUnitid: number | null;
  originalCourseId: number | string;
  getOriginalCourseById: (bool: boolean) => Promise<any>;
}

const FormItemContainer = styled.div`
  .ant-legacy-form-item {
    margin-bottom: 0;
  }
`;

/**
 * @description Редактирование имени юнита
 * @param {ModalEditUnitProps} props
 * @return {React.ReactNode}
 */
export const ModalEditUnitTemplate = (props: ModalEditUnitProps) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [strings] = useLanguageContext();
  const {
    form: { getFieldDecorator, validateFields, resetFields },
    modalVisible,
    handleCloseModal,
    editnameUnitid,
    originalCourseId,
    getOriginalCourseById
  } = props;

  const handleSubmit = (e: React.MouseEvent<HTMLElement, MouseEvent> | React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (!err) {
        setDisabled(true);
        await Courses.editOriginalCourseUnitName(originalCourseId, editnameUnitid, values.name)
          .then(() => {
            message.success(strings.UNIT_NAME_SUCCESSFULLY_CHANGED);
            getOriginalCourseById(false);
            handleCloseModal();
            resetFields();
          })
          .catch(() => {})
          .finally(() => setDisabled(false));
      }
    });
  };

  const handleCloseForm = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    resetFields();
    handleCloseModal(e);
  };

  const footer = [
    <Button onClick={handleCloseForm} key="cancelBtn">
      {strings.CANCEL}
    </Button>,
    <Button type="primary" onClick={handleSubmit} disabled={disabled} loading={disabled} key="editUnitBtn">
      {strings.EDIT_UNIT}
    </Button>
  ];

  return (
    <Modal title="New unit name" open={modalVisible} onCancel={handleCloseForm} footer={footer}>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <FormItemContainer>
          <MyFormItem>
            {getFieldDecorator('name', {
              rules: [{ required: true, message: strings.UNIT_NAME_IS_REQUIRED }]
            })(<Input placeholder={strings.ENTER_A_NEW_NAME_FOR_THE_UNIT} />)}
          </MyFormItem>
        </FormItemContainer>
      </Form>
    </Modal>
  );
};

export const ModalEditUnit = Form.create<ModalEditUnitProps>({})(ModalEditUnitTemplate);

export default { ModalEditUnit };
