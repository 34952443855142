import React, { useEffect, useMemo, useState } from 'react';
import { Button, DatePicker, Row, Col, Checkbox, Input, Modal, Spin, Form, Flex } from 'antd';
import { dateFormat } from 'helpers/dates';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';
import { Semester } from 'types/Reports';
import dayjs from 'dayjs';
import { momentFormatValues } from 'helpers/momentFormatValues';
import { useUserContext } from 'contexts/UserContext';
import { BranchBruner } from 'types/branches';
import { FormItemWithoutMargin } from 'Global/styles';

interface SemesterFormProps {
  handler: () => Promise<any>;
  id?: number;
  hasShowModal: boolean;
  setHasShowModal: (val: boolean) => void;
}

export const SemesterFormModal = (props: SemesterFormProps) => {
  const { handler, id, hasShowModal, setHasShowModal } = props;

  const [form] = Form.useForm();
  const [user] = useUserContext();
  const hasTopManagerRole = user?.hasRoles?.topManager;
  const hasBruner = user?.branch?.id === BranchBruner.id;

  const [strings] = useLanguageContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [semester, setSemester] = useState<Semester>(null);
  const reportsRepository = useProgressReportsRepository();

  const handleSubmit = async (values): Promise<any> => {
    setHasLoading(true);
    try {
      const params = momentFormatValues(values);
      if (id) {
        await reportsRepository.editTrimester(id, params);
      } else {
        await reportsRepository.createTrimester(params);
      }
      handler();
      closeModal();
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  const closeModal = (): void => {
    setHasShowModal(false);
  };

  const getSemesterInfo = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await reportsRepository.getTrimesterById(id);
      setSemester(data);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    if (hasShowModal) {
      if (id) {
        getSemesterInfo();
      } else {
        setSemester(null);
      }
    }
  }, [hasShowModal]);

  const initValues = useMemo(
    () => ({
      name: semester?.name,
      nameForRG: semester?.nameForRG,
      nameHebrew: semester?.nameHebrew,
      headerForRG: semester?.headerForRG,
      headerHebrew: semester?.headerHebrew,
      reportHeaderText: semester?.reportHeaderText,
      startAt: semester?.startAt && dayjs(semester?.startAt),
      endAt: semester?.endAt && dayjs(semester?.endAt),
      active: id ? semester?.active : true
    }),
    [semester]
  );

  useEffect(() => {
    form.setFieldsValue(initValues);
  }, [initValues]);

  return (
    <Modal
      title={id ? `${strings.EDIT} ${semester?.name}` : strings.ADD_NEW_SEMESTER}
      footer={false}
      open={hasShowModal}
      onCancel={closeModal}
      style={{ top: 20 }}
      bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
      width={650}
      destroyOnClose={true}
    >
      <Spin spinning={hasLoading}>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Row gutter={[10, 10]}>
            <Col lg={12}>
              <Form.Item name="name" label={strings.NAME} rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item name="nameForRG" label={strings.NAME_FOR_RG}>
                <Input placeholder={strings.NAME_FOR_RG} />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item name="headerForRG" label={strings.HEADER_FOR_RG}>
                <Input placeholder={strings.HEADER_FOR_RG} />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item name="reportHeaderText" label={strings.REPORT_HEADER_TEXT}>
                <Input placeholder={strings.REPORT_HEADER_TEXT} />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item name="startAt" label={strings.START_DATE} rules={[{ required: true }]}>
                <DatePicker allowClear={false} format={dateFormat} placeholder={strings.START_DATE} />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item name="endAt" label={strings.END_DATE} rules={[{ required: true }]}>
                <DatePicker allowClear={false} format={dateFormat} placeholder={strings.END_DATE} />
              </Form.Item>
            </Col>
            {(hasTopManagerRole || hasBruner) && (
              <Col lg={12}>
                <Form.Item name="nameHebrew" label={strings.NAME_FOR_BRUNER}>
                  <Input placeholder={strings.NAME_FOR_BRUNER} />
                </Form.Item>
              </Col>
            )}
            <Col lg={24}>
              <FormItemWithoutMargin name="active" valuePropName="checked">
                <Checkbox>{strings.ACTIVE}</Checkbox>
              </FormItemWithoutMargin>
            </Col>
          </Row>
          <Flex justify="end" gap={10}>
            <Button key="back" onClick={closeModal}>
              {strings.CANCEL}
            </Button>
            <Button type="primary" htmlType="submit">
              {id ? strings.EDIT : strings.ADD}
            </Button>
          </Flex>
        </Form>
      </Spin>
    </Modal>
  );
};

export default { SemesterFormModal };
