import { httpService } from 'api/core';

/**
 * @description отправка сообщения Юлии
 * @param {string} message
 * @return {Promise<any>}
 */
export const sendMessageJulia = (message: string): Promise<any> =>
  httpService.post(`/api/mail/send-to-julia?text=${message}`);

export const Messages = {
  sendMessageJulia
};

export default { Messages };
