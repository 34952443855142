import React from 'react';
import { Card, Col } from 'antd';
import { dateTimeWithoutSecondFormat } from 'helpers/dates';
import dayjs, {Dayjs} from 'dayjs';
import { ListItem } from 'Admin/People/Families/Family/CollaborationHistory/Types/styles';
import { Task as ITask } from 'types/Family/collaborationHistory';

interface TaskProps {
  data: ITask;
}

/**
 * @description Chronicle type register
 * @param props - component props
 * @return {React.ReactNode}
 */
export const Task = (props: TaskProps) => {
  const {
    data: { title, date, text, admin }
  } = props;
  const formatDate = dayjs(date).format(dateTimeWithoutSecondFormat);
  return (
    <Col lg={24}>
      <Card title={title || 'Task'} extra={formatDate}>
        {text && <div dangerouslySetInnerHTML={{ __html: text }} />}
        {admin && (
          <ListItem>
            <span>Admin:</span>
            <p>
              {admin?.name} {admin?.surname}
            </p>
          </ListItem>
        )}
      </Card>
    </Col>
  );
};

export default { Task };
