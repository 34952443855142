import React, { useState, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Button, Input, Checkbox, Row, Col, Spin, message } from 'antd';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { match } from 'react-router';
import { Container } from 'Global/styles';
import { Title } from 'Global/components/Title';
import { Families } from 'api/Families';
import { useLanguageContext } from 'contexts/LanguageContext';

import { FormComponentProps } from '@ant-design/compatible/es/form';
import { LearnedFrom } from 'types/Family';

interface SourceReferenceEditProps extends FormComponentProps {}

/**
 * @description Редактирование источника ссылки
 * @param {SourceReferenceEditProps} props
 * @return {React.ReactNode}
 */
const SourceReferenceEditTemplate = (props: SourceReferenceEditProps) => {
  const [strings] = useLanguageContext();
  const [learnedFrom, setLearnedFrom] = useState<LearnedFrom>();
  const [loading, setLoading] = useState<boolean>(false);
  const [send, setSend] = useState<boolean>(false);
  const {
    form: { getFieldDecorator, validateFields }
  } = props;
  // Hooks
  const { goBack } = useHistory();
  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();

  const { title, position, isActive, showInForm } = learnedFrom || {};

  /**
   * @description Get learned from by id
   * @param {number} id
   * @return {Promise<any>}
   */
  const getLearnedFrom = async (id: number | string): Promise<any> => {
    setLoading(true);
    await Families.learnedFromById(id)
      .then(({ data }) => {
        setLearnedFrom(data);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  /**
   * @description Отправка формы
   * @param {React.FormEvent<HTMLFormElement>} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    validateFields(async (err, values) => {
      if (!err) {
        setSend(true);
        await Families.editLearnedFrom(id, values)
          .then(() => {
            message.success(strings.LEARNED_FROM_EDITED_SUCCESSFULLY);
          })
          .catch(() => {})
          .finally(() => setSend(false));
      }
    });
  };

  useEffect(() => {
    getLearnedFrom(id);
  }, [id]); // eslint-disable-line
  return (
    <Container>
      <Spin tip={`${strings.LOADING} ${strings.LEARNED_FROM}`} spinning={loading}>
        <Title goBack={goBack}>{strings.SOURCE_OF_REFERENCE_EDIT}</Title>
        <Form layout="vertical" onSubmit={handleSubmit}>
          <MyFormItem label={strings.TITLE}>
            {getFieldDecorator('title', {
              initialValue: title,
              rules: [{ required: true, message: `${strings.TITLE} ${strings.IS_REQUIRED}` }]
            })(<Input />)}
          </MyFormItem>
          <MyFormItem label={strings.POSITION}>
            {getFieldDecorator('position', {
              initialValue: position
            })(<Input />)}
          </MyFormItem>
          <Row>
            <Col lg={6}>
              <MyFormItem label={strings.IS_ACTIVE}>
                {getFieldDecorator('isActive', {
                  initialValue: isActive,
                  valuePropName: 'checked'
                })(<Checkbox />)}
              </MyFormItem>
            </Col>
            <Col lg={6}>
              <MyFormItem label={strings.SHOW_IN_FORM}>
                {getFieldDecorator('showInForm', {
                  initialValue: showInForm,
                  valuePropName: 'checked'
                })(<Checkbox />)}
              </MyFormItem>
            </Col>
          </Row>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            disabled={send}
            loading={send}
            style={{ marginTop: 20 }}
          >
            {strings.EDIT}
          </Button>
        </Form>
      </Spin>
    </Container>
  );
};

export const SourceReferenceEdit = Form.create<SourceReferenceEditProps>({})(SourceReferenceEditTemplate);

export default { SourceReferenceEdit };
