import React from 'react';
import styled from 'styled-components';
import { Dayjs } from 'dayjs';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, DatePicker, Row, Typography, Spin } from 'antd';
import { dateFormat } from 'helpers/dates';
import { ColControl, ColPeriod } from 'Global/modules/Schedule/styles';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Text } = Typography;

interface ToolbarProps {
  handleDate: (date: Date | Dayjs) => void;
  toolbar: any;
  setView: (view: string) => void;
  view: string;
  hasLoadEvents: boolean;
  datePickerValue: Dayjs;
  setDate: (t: Dayjs) => void;
}

const StyledToolbar = styled(Row)`
  background: #fff;
  padding: 30px 15px 20px 15px;
  box-shadow: 0px 0px 46px -15px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  margin-bottom: 20px;
`;

/**
 * @description Toolbar календаря
 * @param {object} props
 * @return {React.ReactNode}
 */
export const Toolbar = (props: ToolbarProps) => {
  const { handleDate, toolbar, setView, view, hasLoadEvents, datePickerValue, setDate } = props;
  const [strings] = useLanguageContext();

  const onChangePicker = date => {
    setDate(date);
    handleDate(date);
  };

  const datePickerProps = {
    format: dateFormat,
    placeholder: strings.DATE,
    value: datePickerValue,
    style: { width: 150 }
  };

  const goToWeekView = (): void => {
    setView('week');
    toolbar.onView('week');
  };

  const goToDayView = (): void => {
    setView('day');
    toolbar.onView('day');
  };

  const goToBack = (): void => {
    toolbar.onNavigate('PREV');
  };

  const goToNext = (): void => {
    toolbar.onNavigate('NEXT');
  };

  const goToCurrent = (): void => {
    const date = new Date();
    handleDate(date);
  };

  const weekButtonType = view === 'week' ? 'primary' : 'default';
  const dayButtonType = view === 'day' ? 'primary' : 'default';

  return (
    <StyledToolbar justify="space-between" className="schedule-toolbar">
      <ColControl>
        <Button onClick={goToBack} icon={<LeftOutlined />} size="large" />
        <Button onClick={goToNext} icon={<RightOutlined />} size="large" />
        <Button onClick={goToCurrent} size="large">
          {strings.TODAY}
        </Button>
        <DatePicker {...datePickerProps} onChange={onChangePicker} />
        {hasLoadEvents && <Spin style={{ marginInlineStart: 10 }} />}
      </ColControl>
      <ColPeriod>
        <Text className="button-control" style={{ fontSize: 20, padding: '0 20px', marginTop: 15 }}>
          {toolbar?.label}
        </Text>
      </ColPeriod>
      <ColControl>
        <div className="nav-container">
          <Button type={weekButtonType} size="large" onClick={goToWeekView}>
            {strings.WEEK}
          </Button>
          <Button type={dayButtonType} size="large" onClick={goToDayView}>
            {strings.DAY}
          </Button>
        </div>
      </ColControl>
    </StyledToolbar>
  );
};

export default { Toolbar };
