export const BASE_REPORT_TEACHERS = '/report-teacher';
export const BASE_LESSONS = '/lessons';
export const BASE_FEEDBACK_MANAGEMENT = '/feedback-management';
export const BASE_GRADING = '/report-grading';
export const BASE_PROGRESS_REPORT = '/student-progress';
export const EDIT_PROGRESS_REPORT = '/student-progress/edit/:id';
export const BASE_MARKETING_EVENTS = '/meeting';
export const ADD_MARKETING_EVENTS = '/meeting/add';
export const BASE_MESSAGES_FROM_PARENTS = '/report-im';
export const BASE_SUMMARY_TABLE = '/summary';
export const BASE_SUMMARY_CALENDAR = '/assessment-calendar';
export const BASE_REPORT_MANUAL_INPUT = '/report-manual';
export const BASE_REPORT_STUDENTS_PAYING = '/report-paying';
export const BASE_INVOICE_MONTH_SYSTEM = '/invoice-month-system';
export const BASE_INVOICES_LOGS = '/invoice-logs';
export const BASE_REGISTERS_LOGS = '/register-logs';
export const BASE_FINANCE_FAMILIES = '/finance-family';
export const BASE_FINANCE_TEACHERS = '/finance-teacher';
export const BASE_ORIGINAL_COURSES_ACCESS = '/original-courses-access';
export const BASE_ASSISTANTS_FEEDBACK = '/assistants-feedback';
export const ASSISTANTS_COMMENTS = `${BASE_ASSISTANTS_FEEDBACK}/:id`;
export const INVOICES_GENERATOR = '/riga-invoices-generator';
export const PROGRESS_REPORT_GENERATOR = '/progress-report-generator';
export const BASE_COMMENTARY_CALENDAR = '/commentary-calendar';
export const NEW_PROGRESS_REPORTS = '/new-progress-reports';
export const EDIT_NEW_PROGRESS_REPORTS = `${NEW_PROGRESS_REPORTS}/student/:studentId/semester/:semesterId`;

export default {
  BASE_REPORT_TEACHERS,
  BASE_LESSONS,
  BASE_FEEDBACK_MANAGEMENT,
  BASE_GRADING,
  BASE_PROGRESS_REPORT,
  BASE_MARKETING_EVENTS,
  ADD_MARKETING_EVENTS,
  BASE_MESSAGES_FROM_PARENTS,
  BASE_SUMMARY_TABLE,
  BASE_SUMMARY_CALENDAR,
  BASE_REPORT_MANUAL_INPUT,
  BASE_REPORT_STUDENTS_PAYING,
  BASE_INVOICE_MONTH_SYSTEM,
  BASE_INVOICES_LOGS,
  BASE_REGISTERS_LOGS,
  BASE_FINANCE_FAMILIES,
  BASE_FINANCE_TEACHERS,
  BASE_ORIGINAL_COURSES_ACCESS,
  ASSISTANTS_COMMENTS,
  BASE_ASSISTANTS_FEEDBACK,
  INVOICES_GENERATOR,
  PROGRESS_REPORT_GENERATOR,
  BASE_COMMENTARY_CALENDAR,
  NEW_PROGRESS_REPORTS,
  EDIT_NEW_PROGRESS_REPORTS
};
