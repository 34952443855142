import React, { useState } from 'react';
import { Collapse, Button, message } from 'antd';
import { Material } from './Material';
import { MaterialItem } from 'types/courses';
import { Material as IMaterial } from 'types/materials';
import { useLanguageContext } from 'contexts/LanguageContext';
import { ModalLessonsComments } from 'Global/components/ModalLessonsComments';
import { useAttachmentRepository } from 'repos/AttachmentRepository';

const { Panel } = Collapse;

interface MaterialsProps {
  data: MaterialItem;
  lessonId: number;
  originalLessonId: number;
  type: string;
  stateUpdate?: () => void;
}

/**
 * @description Материалы урока
 * @param {object} props
 * @return {React.ReactNode}
 */
export const Materials = (props: MaterialsProps) => {
  const [strings] = useLanguageContext();
  const [approvedFiles, setApprovedFiles] = useState<number[]>([]);
  const [customFiles, setCustomFiles] = useState<number[]>([]);
  const [disabledBtn, setDisabledBtn] = useState<boolean>(false);
  const [disabledType, setDisabledType] = useState<string>('');
  const [visibleModalComments, setVisibleModalComments] = useState<boolean>(false);
  const [selectedTypeComments, setSelectedTypeComments] = useState<'hw' | 'classwork'>(null);
  const attachmentRepository = useAttachmentRepository();

  const {
    data: { approvedMaterials, customMaterials },
    lessonId,
    originalLessonId,
    stateUpdate,
    type
  } = props;

  /**
   * @description Выбирает файлы для отправки
   * @param {number[]} checkedValues
   */
  const handleChangeApprovedCheckbox = (value: number) => {
    setApprovedFiles(
      approvedFiles.includes(value) ? approvedFiles.filter((v: number) => v !== value) : [...approvedFiles, value]
    );
  };

  /**
   * @description Выбирает файлы для отправки
   * @param {number[]} checkedValues
   */
  const handleChangeCustomCheckbox = (value: number) => {
    setCustomFiles(
      customFiles.includes(value) ? customFiles.filter((v: number) => v !== value) : [...customFiles, value]
    );
  };

  /**
   * @description Отправка файлов
   * @param {number[]} files
   * @param {string} typeFile
   */
  const handleClickMaterials = async (files: number[], typeFiles: string) => {
    if (files.isNotEmpty()) {
      try {
        setDisabledBtn(true);
        setDisabledType(typeFiles);
        await attachmentRepository.uploadAttachment({ type, id: lessonId, filesId: files }, false);
        message.success(strings.TRANSFER_FILES, 2);
      } catch {
        message.error(strings.SOME_FILES_HAVENT_BEEN_UPLOADED_PLEASE_TRY_AGAIN_OR_CONTACT_SUPPORT);
      } finally {
        stateUpdate();
        setDisabledBtn(false);
        setDisabledType('');
        if (typeFiles === 'custom') {
          setCustomFiles([]);
        } else {
          setApprovedFiles([]);
        }
      }
    } else {
      message.error(strings.SELECT_AT_LEAST_ONE_FILE);
    }
  };

  /**
   * @description Подсчитывает количество материалов
   * @param {IMaterial[]} materials
   * @return {number}
   */
  const lengthMaterials = (materials: IMaterial[]): number => {
    return materials.reduce((acc: number, material: IMaterial) => {
      return material.materials.length + acc;
    }, 0);
  };

  const getExtra = () => {
    return (
      <Button
        type="link"
        style={{ padding: '0', marginTop: -10 }}
        onClick={e => {
          e.stopPropagation();
          if (type === 'lessonHomework') {
            setSelectedTypeComments('hw');
          } else {
            setSelectedTypeComments('classwork');
          }
          setVisibleModalComments(true);
        }}
      >
        {strings.SHOW_ALL_COMMENTS}
      </Button>
    );
  };

  return (
    <>
      {visibleModalComments && (
        <ModalLessonsComments
          lessonId={originalLessonId}
          typeComments={selectedTypeComments}
          visibleModalComments={visibleModalComments}
          setVisibleModalComments={setVisibleModalComments}
        />
      )}
      <Collapse style={{ marginBottom: 24 }}>
        {approvedMaterials?.length > 0 && (
          <Panel
            header={`${strings.APPROVED_MATERIALS} (${lengthMaterials(approvedMaterials)})`}
            key="1"
            extra={getExtra()}
          >
            {approvedMaterials.map((material: IMaterial) => (
              <Material
                data={material}
                key={material.createdById}
                filesList={approvedFiles}
                onChange={handleChangeApprovedCheckbox}
              />
            ))}
            <div style={{ marginTop: 5 }}>
              <Button
                onClick={() => handleClickMaterials(approvedFiles, 'approved')}
                disabled={disabledBtn && disabledType === 'approved'}
                loading={disabledBtn && disabledType === 'approved'}
              >
                {strings.ADD_CHECKED_MATERIALS_TO_THE_LESSON}
              </Button>
            </div>
          </Panel>
        )}
        {customMaterials?.length > 0 && (
          <Panel
            header={`${strings.CUSTOM_MATERIALS} (${lengthMaterials(customMaterials)})`}
            key="2"
            extra={getExtra()}
          >
            {customMaterials.map((material: IMaterial) => (
              <Material
                data={material}
                key={material.createdById}
                filesList={customFiles}
                onChange={handleChangeCustomCheckbox}
              />
            ))}
            <div style={{ marginTop: 5 }}>
              <Button
                onClick={() => handleClickMaterials(customFiles, 'custom')}
                disabled={disabledBtn && disabledType === 'custom'}
                loading={disabledBtn && disabledType === 'custom'}
              >
                {strings.ADD_CHECKED_MATERIALS_TO_THE_LESSON}
              </Button>
            </div>
          </Panel>
        )}
      </Collapse>
    </>
  );
};

const MaterialsMemo = React.memo(Materials);
export default MaterialsMemo;
