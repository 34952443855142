import * as emailRoutes from '../../../Admin/Website/Mailing/routes';
import { NewEmail, EmailHistory, ShowEmail } from '../../../Admin/Website/Mailing';

export const mail = [
  {
    path: emailRoutes.BASE_EMAIL,
    component: NewEmail,
    exact: true
  },
  {
    path: emailRoutes.SHOW_HISTORY,
    component: EmailHistory,
    exact: true
  },
  {
    path: emailRoutes.SHOW_EMAIL,
    component: ShowEmail,
    exact: true
  }
];

export default { mail };
