import React, { useState, useEffect } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Typography, Table, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { Container } from 'Global/styles';
import { Schedule } from 'api/Schedule';
import { columns } from './columns';
import Pagination from 'Global/components/Pagination';
import BaseFilter from 'helpers/BaseFilter';
import { scrollTop } from 'helpers/scroll';
import { TimetableChangesFilter } from './Filter';
import { Forbidden } from 'Global/modules/Errors/403';
import { ScheduleLog, ScheduleLogParams } from 'types/Schedule';
import { PaginationInfo } from 'types/global';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Title } = Typography;

export const TimetableChanges = () => {
  const [logs, setLogs] = useState<ScheduleLog[]>([]);
  const [paginationInfo, setPagination] = useState<PaginationInfo>();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<number>();
  const location = useLocation();
  const { goBack, push } = useHistory();
  const lastLocation = useLastLocation();
  const [user] = useUserContext();
  const [strings] = useLanguageContext();

  const LIMIT_LOGS = 50;
  const { pageCount, current } = paginationInfo || {};
  const FilterService = new BaseFilter();
  const locationParams = FilterService.getLocationSearchParams(location);

  /**
   * @description Изменения страницы
   * @param {number} page
   */
  const handleChangePage = (page: number) => {
    getLogs({ ...locationParams, page, limit: LIMIT_LOGS });
    scrollTop();
  };

  /**
   * @description Логи расписания
   * @param {params} ScheduleLogParams
   * @return {Promise<any>}
   */
  const getLogs = async (params?: ScheduleLogParams): Promise<any> => {
    setLoading(true);
    await Schedule.getLogs(params)
      .then(({ data }) => {
        setLogs(data.items);
        setPagination(data.paginationInfo);
        push({ search: FilterService.getQueryStringParams(params) });
      })
      .catch(e => setErrorCode(e?.response?.data?.code))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    // Если есть роль топ менеджера делаем обычный запрос. Иначе по бранчу юзера отображаем логи
    if (user?.roles) {
      if (user?.hasRoles?.topManager) {
        getLogs({ ...locationParams, limit: LIMIT_LOGS });
      } else {
        getLogs({ ...locationParams, limit: LIMIT_LOGS, branch: user?.branch?.id });
      }
    }
  }, [user]);

  if (errorCode === 403) {
    return (
      <Forbidden subTitle={strings.SORRY_YOU_DO_NOT_HAVE_ACCESS_TO_THIS_PAGE}>
        <Button size="large" type="primary" icon={<ArrowLeftOutlined />}>
          <Link to="/" style={{ color: '#fff', paddingInlineStart: '10px' }}>
            {strings.BACK_TO_HOME}
          </Link>
        </Button>
      </Forbidden>
    );
  }

  return (
    <Container>
      <Title level={3}>
        {lastLocation && <ArrowLeftOutlined style={{ marginInlineEnd: 6 }} onClick={goBack} />}
        {strings.TIMETABLE_UPDATES}
      </Title>
      <TimetableChangesFilter getLogs={getLogs} location={location} limitLogs={LIMIT_LOGS} />
      <Table
        dataSource={logs}
        columns={columns(strings)}
        pagination={false}
        rowKey="id"
        loading={{ spinning: loading, tip: strings.LOADING_SCHEDULE_LOGS }}
      />
      {pageCount > 1 && <Pagination count={pageCount} current={current} pageChange={handleChangePage} />}
    </Container>
  );
};

export default { TimetableChanges };
