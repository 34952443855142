import React, { useEffect, useMemo, useState } from 'react';
import { ArrowLeftOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Table, Row, message } from 'antd';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Forbidden } from 'Global/modules/Errors/403';
import { useUserContext } from 'contexts/UserContext';
import { isEmpty } from 'lodash';
import BaseFilter from 'helpers/BaseFilter';
import { PricesParams } from 'types/API/global';
import { Rate } from 'types';
import { getColumns } from './columns';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import { SERVICE_RATE_NEW } from '../../routes';
import { CreateFromExcelButton } from './CreateFromExcelButton';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import Filter from './Filter';
import { downloadSomeFile } from 'helpers';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';

/**
 * @description ServiceRates
 * @return {React.ReactNode}
 */
export const ServiceRates = () => {
  const [strings] = useLanguageContext();
  const { goBack, push } = useHistory();
  const FilterService = new BaseFilter();
  const { locationSearchParams } = useLocationSearchParams();
  const [user] = useUserContext();
  const { branches } = useGlobalCollectionsContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [hasDeactivateLoading, setDeactivateLoading] = useState<boolean>(false);
  const [hasTemplateLoading, setHasTemplateLoading] = useState<boolean>(false);
  const [rates, setRates] = useState<Rate[]>([]);
  const [selectedRates, setSelectedRates] = useState<Rate[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [defFilterValues, setDefFilterValues] = useState<PricesParams>({});
  const hasTopManager = user?.hasRoles?.topManager;
  const hasBranchDirector = user?.hasRoles?.branchDirector;
  const globalRequestsRepository = useGlobalRequestsRepository();
  const hasSelectedRates = useMemo(() => {
    return selectedRates.isNotEmpty();
  }, selectedRates);

  const onFilter = async (params: PricesParams): Promise<any> => {
    setLoading(true);
    try {
      const { data } = await globalRequestsRepository.getRates(params);
      setRates(data);
      push({ search: FilterService.getQueryStringParams(params) });
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadTemplate = async (): Promise<any> => {
    try {
      setHasTemplateLoading(true);
      await downloadSomeFile(`/api/v1/prices/template`, null, null, null, true);
      message.success(strings.EXCEL_FILE_HAS_BEEN_DOWNLOADED, 2);
    } catch {
      message.error(strings.SORRY_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN);
    } finally {
      setHasTemplateLoading(false);
    }
  };

  const handleDeactivateRates = async (): Promise<any> => {
    try {
      setDeactivateLoading(true);
      setLoading(true);
      const ids = selectedRates.map(item => item.id);
      await globalRequestsRepository.deactivateRates({ prices: ids });
      onFilter(locationSearchParams);
      setSelectedRowKeys([]);
      setSelectedRates([]);
    } finally {
      setDeactivateLoading(false);
      setLoading(false);
    }
  };

  const defaultValues = {
    isActive: 1,
    branch: user?.branch?.id
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys, selectedRows) => {
      setSelectedRowKeys(selectedKeys);
      setSelectedRates(selectedRows);
    }
  };

  useEffect(() => {
    if (!isEmpty(locationSearchParams)) {
      onFilter(locationSearchParams);
    } else {
      onFilter(defaultValues);
    }
  }, [user]);

  if (!hasTopManager && !hasBranchDirector) {
    return (
      <Forbidden subTitle={strings.SORRY_YOU_DO_NOT_HAVE_ACCESS_TO_THIS_PAGE}>
        <Button size="large" type="primary" icon={<ArrowLeftOutlined />}>
          <Link to="/" style={{ color: '#fff', paddingInlineStart: '10px' }}>
            {strings.BACK_TO_HOME}
          </Link>
        </Button>
      </Forbidden>
    );
  }

  return (
    <Container>
      <StPageHeader
        onBack={goBack}
        title={strings.SERVICE_RATES}
        extra={[
          <Button key="1" type="primary" onClick={() => push(SERVICE_RATE_NEW)}>
            {strings.ADD}
          </Button>,
          <CreateFromExcelButton key="2" fetchList={onFilter} defaultValues={defaultValues} />,
          <Button key="3" loading={hasTemplateLoading} icon={<DownloadOutlined />} onClick={handleDownloadTemplate}>
            {strings.EXCEL_TEMPLATE}
          </Button>
        ]}
      >
        <Filter
          onFilter={onFilter}
          defFilterValues={defFilterValues}
          hasSelectedRates={hasSelectedRates}
          handleDeactivateRates={handleDeactivateRates}
          hasDeactivateLoading={hasDeactivateLoading}
        />
      </StPageHeader>
      <Row gutter={[10, 10]}>
        <Table
          rowSelection={rowSelection}
          rowKey="id"
          dataSource={rates}
          columns={getColumns(strings)}
          pagination={false}
          loading={{ spinning: loading }}
        />
      </Row>
    </Container>
  );
};

export default { ServiceRates };
