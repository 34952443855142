import React, { useState, useEffect, useContext } from 'react';
import { FilePdfOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { message, Modal, Button } from 'antd';
import { useUserRepository } from 'repos/UserRepository';
import { useUserContext } from 'contexts/UserContext';
import { Family, IFamily } from 'types';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useFamilyRepository } from 'repos/FamilyRepository';
import { IRepositoryProvider } from 'repos/RepositoryProvider';

const FamilyContext = React.createContext(null);

export const FamilyContextProvider: React.FC<IRepositoryProvider> = ({ children }) => {
  const userRepository = useUserRepository();
  const familyRepository = useFamilyRepository();
  const [user, setUser, , , logout] = useUserContext();
  const [strings] = useLanguageContext();
  const [family, setFamily] = useState<IFamily>(null);
  const [hasLoadFamily, setHasLoadFamily] = useState<boolean>(false);
  const provider = [family, hasLoadFamily];

  const fetchFamilyContract = async (): Promise<any> => {
    const {
      data: { contract }
    } = await familyRepository.getActiveFamilyContract();

    Modal.warning({
      title: strings.THIS_IS_A_WARNING_MESSAGE,
      okText: strings.I_AGREE,
      onOk: handleSignСontract,
      content: (
        <div>
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => downloadContract(contract?.id, contract?.contractNumber)}
          >
            {strings.HERE}
            <FilePdfOutlined />
          </Button>{' '}
          {strings.YOU_CAN_ACCESS}
        </div>
      )
    });
  };

  const handleSignСontract = async (): Promise<any> => {
    try {
      const { status } = await familyRepository.signСontract();
      if (status === 200) {
        setUser({ ...user, activeContractAccepted: true });
      }
    } catch {
      message.error(strings.SORRY_ERROR);
    }
  };

  const downloadContract = async (id, name): Promise<any> => {
    familyRepository.downloadActiveFamilyContract(id, name);
  };

  const warning = () => {
    Modal.warning({
      title: strings.THIS_IS_A_WARNING_MESSAGE,
      onOk: logout,
      content: (
        <div>
          {strings.YOUR_ACCOUNT_IS_BLOCKED_PLEASE_CONTACT_YOUR_TUTOR}
          <br />
          <PhoneOutlined style={{ marginInlineEnd: 10 }} />
          <a className="phone" href={`tel:${strings.PHONE_RIGA_TUTOR}`}>
            {strings.PHONE_RIGA_TUTOR}
          </a>
          <br />
          <MailOutlined style={{ marginInlineEnd: 10 }} />
          <a href={`mailto:${strings.EMAIL_RIGA_TUTOR}`}>{strings.EMAIL_RIGA_TUTOR}</a>
        </div>
      )
    });
  };

  useEffect(() => {
    (async () => {
      if (user?.hasRoles?.parent) {
        setHasLoadFamily(true);
        const { data } = await userRepository.getMyFamily();
        setFamily(data);
        setHasLoadFamily(false);
      }

      //для родителей и студентов Риги, не подписавших договор(!activeContractAccepted)
      if (
        (user?.hasRoles?.parent || user?.hasRoles?.student) &&
        user?.branch?.id === 5 &&
        !user?.activeContractAccepted
      ) {
        //если юзер - держатель контракта, выводим предупреждение для подписания нового контракта
        if (user?.activeContractHolder) {
          await fetchFamilyContract();
        } else {
          warning();
        }
      }
    })();
  }, [user]);

  return <FamilyContext.Provider value={provider}>{children}</FamilyContext.Provider>;
};

export const useFamilyContext = () => {
  const service = useContext(FamilyContext);

  if (!service) {
    throw new Error('Family context is unavailable');
  }

  return service;
};
