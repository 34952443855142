import React, { useState, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Spin, Button, Table } from 'antd';
import { ProgressReportChild } from 'types/Reports';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { ProgressReportFilter } from 'Admin/People/Families/Student/ProgressReport/Filter';
import { columns } from 'Admin/People/Families/Student/ProgressReport/columns';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';
import { useLanguageContext } from 'contexts/LanguageContext';

interface ProgressReportProps extends FormComponentProps {
  studentId: number;
}

/**
 * @description Progress Report tab
 * @param {object} props
 * @return {React.ReactNode}
 */
export const ProgressReportTemplate = (props: ProgressReportProps) => {
  const [strings] = useLanguageContext();
  const reportsRepository = useProgressReportsRepository();
  const [loading, setLoading] = useState<boolean>(false);
  const [reports, setReports] = useState<ProgressReportChild[]>([]);

  const { studentId } = props;

  const handleSubmit = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
  };

  /**
   * @description Формируем массив данных для отправки на бэк
   * @param {string} field
   * @param {number} rowId
   * @param {any} value
   * @return {void}
   */
  const setFieldValue = (field: string, rowId: number, value: any): void => {
    setReports(
      reports.map((report: ProgressReportChild) => {
        if (report.id === rowId) {
          return {
            ...report,
            [field]: value
          };
        }

        return report;
      })
    );
  };

  /**
   * @description Строки студента в прогресс репорте
   * @param {number} studentId
   * @param {number} trimester
   * @return {Promise<any>}
   */
  const fetchProgressReportRows = async (studentId: number, trimester?: number): Promise<any> => {
    setLoading(true);
    await reportsRepository.getProgressReportRowsChild(studentId, trimester).then(({ data }) => {
      setReports(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchProgressReportRows(studentId);
  }, [studentId]);

  return (
    <Spin tip="Loading progress report.." spinning={loading}>
      <ProgressReportFilter studentId={studentId} fetchProgressReportRows={fetchProgressReportRows} />
      <Table dataSource={reports} columns={columns(strings)} rowKey="id" bordered={false} pagination={false} />
    </Spin>
  );
};

export const ProgressReport = Form.create<ProgressReportProps>({})(ProgressReportTemplate);

export default { ProgressReport };
