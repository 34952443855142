import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select, Button, message, Modal, DatePicker, Row, Col, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { Global } from 'api/Global';
import { SearchChild } from 'types/global';
import { useLanguageContext } from 'contexts/LanguageContext';
import { dateFormat, dateFormatForBackend } from 'helpers';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import dayjs from 'dayjs';
import { Courses } from 'api';
import { Branch } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useUserContext } from 'contexts/UserContext';

const { Option } = Select;

interface AddStudentModalProps extends FormComponentProps {
  superGroupId: number;
  handler?: () => Promise<any>;
  hasShowAddStudentModal: boolean;
  setHasShowAddStudentModal: (val: boolean) => void;
}

/**
 * @description Добавление студента в группу
 * @param {AddStudentModalProps} props
 * @return {React.ReactNode}
 */
export const AddStudentModalTemplate = ({
  superGroupId,
  handler,
  form: { getFieldDecorator, validateFields, resetFields },
  hasShowAddStudentModal,
  setHasShowAddStudentModal
}: AddStudentModalProps) => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const { branches } = useGlobalCollectionsContext();

  const [selectedBranch, setSelectedBranch] = useState<number>(user?.branch?.id);
  const [searchStudentsList, setSearchStudentsList] = useState<SearchChild[]>([]);
  const [hasLoadingStudentsList, setHasLoadingStudentsList] = useState<boolean>(false);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [typing, setTyping] = useState<Boolean>(false);

  /**
   * @description Добавление студента в список класса
   * @return {Promise<any>}
   */
  const handleSubmit = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();

    validateFields(async (err, values) => {
      if (!err) {
        const { date, student } = values;
        const backendFormatDate = dayjs(date).format(dateFormatForBackend);
        setHasLoading(true);
        try {
          await Courses.addStudentToSuperGroup(student, superGroupId, { date: backendFormatDate });
          handler();
          message.success(strings.STUDENT_SUCCESSFULLY_ADDED);
          setHasShowAddStudentModal(false);
        } catch (err) {
          message.error(err.response.data.message);
        } finally {
          setSearchStudentsList([]);
          setHasLoading(false);
          resetFields();
        }
      }
    });
  };

  /**
   * @description Поиск студента по имени
   * @param {string} query
   * @return {Promise<any>}
   */
  const searchByChildName = async (query: string): Promise<any> => {
    setSearchValue(query)
    if (query) {
      setHasLoadingStudentsList(true);
      try {
        const {
          data: { items }
        } = await Global.searchChildByName({ query, limit: 1000, branch: selectedBranch });
        setSearchStudentsList(items);
      } catch {
      } finally {
        setHasLoadingStudentsList(false);
      }
    }
  };

  useEffect(() => {
    if (searchValue) {
      setTyping(true);
    } else {
      setTyping(false);
    }
  }, [searchValue]);

  const footer = [
    <Button
      onClick={() => {
        setHasShowAddStudentModal(false);
        resetFields();
      }}
      key="cancelBtn"
    >
      {strings.CANCEL}
    </Button>,
    <Button type="primary" onClick={handleSubmit} key="addToGroup" loading={hasLoading}>
      {strings.ADD}
    </Button>
  ];

  return (
    <Modal
      title={strings.ADD_A_STUDENT_TO_THE_SUPERGROUP}
      open={hasShowAddStudentModal}
      destroyOnClose={true}
      onCancel={() => {
        setHasShowAddStudentModal(false);
        resetFields();
      }}
      footer={footer}
    >
      <Form layout="vertical">
        <Row gutter={10}>
          {branches.length > 1 && (
            <Col lg={12} xs={24}>
              <MyFormItem label={strings.BRANCH}>
                <Select
                  defaultValue={user?.branch?.id}
                  placeholder={strings.BRANCH}
                  onChange={val => setSelectedBranch(Number(val))}
                >
                  {branches.map((branch: Branch) => (
                    <Option value={branch.id} key={branch.id}>
                      {branch.name}
                    </Option>
                  ))}
                </Select>
              </MyFormItem>
            </Col>
          )}
          <Col lg={12} xs={24}>
            <MyFormItem label={strings.DATE}>
              {getFieldDecorator('date', {
                initialValue: dayjs(),
                rules: [{ required: true, message: strings.SELECT_DATE }]
              })(<DatePicker placeholder={`${strings.SELECT_DATE}`} format={dateFormat} />)}
            </MyFormItem>
          </Col>
          <Col lg={branches.length > 1 ? 24 : 12} xs={24}>
            <MyFormItem label={strings.STUDENT}>
              {getFieldDecorator('student', {
                rules: [{ required: true }]
              })(
                <Select
                  showSearch
                  placeholder={strings.SEARCH_STUDENT}
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  onSearch={debounce(searchByChildName, 800)}
                  notFoundContent={
                    setHasLoadingStudentsList ? (
                      <Spin size="small" />
                    ) : (
                      <p>{!typing ? strings.START_TYPING : strings.NOT_FOUND}</p>
                    )
                  }
                  loading={hasLoadingStudentsList}
                >
                  {searchStudentsList.map((student: SearchChild) => (
                    <Option value={student?.id} key={student?.id}>
                      {student?.name} {student?.surname}
                    </Option>
                  ))}
                </Select>
              )}
            </MyFormItem>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export const AddStudentModal = Form.create<AddStudentModalProps>({})(AddStudentModalTemplate);
