export const SCHEDULE_CHAGES = `/schedule-changes`;
export const SERVICE_RATES = '/prices';
export const SERVICE_RATE_NEW = '/prices/new';
export const BASE_TEACHERS_WAGES = '/costs';
export const BASE_TEACHERS_WAGES_EDIT = `${BASE_TEACHERS_WAGES}/:id(\\d+)/edit`;
export const BASE_TEACHERS_WAGES_NEW = `${BASE_TEACHERS_WAGES}/new`;
export const WEEKLY_EMAIL = '/weekly-email';
export const WEEKLY_EMAIL_NEW = `${WEEKLY_EMAIL}/new`;
export const WEEKLY_EMAIL_EDIT = `${WEEKLY_EMAIL}/:id(\\d+)/edit`;
export const PRICE_GROUP = `/price-group`;
export const ADDITIONAL_ACCOUNTS = `/additional-accounts`;
export const BASE_PERFORMERS = '/performers';
export const PERFORMERS_EDIT = `${BASE_PERFORMERS}/:id(\\d+)/edit`;
export const PERFORMERS_NEW = `${BASE_PERFORMERS}/new`;

export default {
  SCHEDULE_CHAGES,
  SERVICE_RATES,
  BASE_TEACHERS_WAGES,
  BASE_TEACHERS_WAGES_EDIT,
  BASE_TEACHERS_WAGES_NEW,
  WEEKLY_EMAIL,
  WEEKLY_EMAIL_NEW,
  WEEKLY_EMAIL_EDIT,
  PRICE_GROUP,
  ADDITIONAL_ACCOUNTS,
  BASE_PERFORMERS,
  PERFORMERS_EDIT,
  PERFORMERS_NEW
};
