import React from 'react';
import { Col, Form, FormListFieldData, Row, Select, Input } from 'antd';
import { Inventory } from 'types';
import { NamePath } from 'antd/lib/form/interface';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Option } = Select;

type InventoryFormProps = {
  field?: FormListFieldData;
  inventories: Inventory[];
};

export const InventoryForm: React.FC<InventoryFormProps> = ({ field, inventories }) => {
  const [strings] = useLanguageContext();
  const getName = (name): NamePath => (field ? [field.name, name] : name);

  return (
    <Row gutter={[10, 10]} style={{ flex: 1 }}>
      <Col lg={12} xs={24}>
        <Form.Item name={getName('inventory')} label={strings.TEACHING_MATERIALS}>
          <Select>
            {inventories.map(({ id, name }: Inventory) => (
              <Option key={id} value={id}>
                {name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default { InventoryForm };
