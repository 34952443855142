import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useAssistantFeedbackRepository } from 'repos/AssistantsFeedbackRepository';
import { columnsAssistantsFeedback } from 'Admin/People/Families/Student/AcademicProgress/columns';
import { Supergroup } from 'types';
import styled from 'styled-components';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Column } = Table;

interface AssistantsFeedbackProps {
  studentId: number;
}
interface AssistantsCommentsbyStudentProps {
  comment: string;
  generalComment: string;
  header: {
    date: string;
    supergroup: Supergroup;
    assistant: {
      user: {
        id: number;
        name: string;
        surname: string;
      };
    };
  };
}

export const StTable = styled.div`
  .ant-table-tbody > tr > td {
    vertical-align: baseline;
  }
`;

/**
 * @description Вкладка Assistant's Feedback
 * @param {AssistantsFeedbackProps} props
 * @return {React.ReactNode}
 */
export const AssistantsFeedback = (props: AssistantsFeedbackProps) => {
  const { studentId } = props;
  const [strings] = useLanguageContext();

  const assistantFeedbackRepo = useAssistantFeedbackRepository();
  const [hasLoadAssistantComments, setHasLoadAssistantComments] = useState<boolean>(false);
  const [assistantComments, setAssistantsComments] = useState<AssistantsCommentsbyStudentProps[]>([]);

  /**
   * @description Запрос assistants comments c фильтром по студенту
   * @return {Promise<any>}
   */
  const getAssistantComments = async () => {
    try {
      setHasLoadAssistantComments(true);
      const { data } = await assistantFeedbackRepo.getAssistantCommentsByStudent(studentId);
      setAssistantsComments(data);
    } finally {
      setHasLoadAssistantComments(false);
    }
  };
  useEffect(() => {
    getAssistantComments();
  }, []);

  return (
    <StTable>
      <Table dataSource={assistantComments} pagination={false} loading={hasLoadAssistantComments}>
        {columnsAssistantsFeedback(strings).map(col => (
          <Column key={col.key} {...col} />
        ))}
      </Table>
    </StTable>
  );
};

export default { AssistantsFeedback };
