import React, { useState, Fragment } from 'react';
import { DeleteTwoTone, EditOutlined } from '@ant-design/icons';
import { Card, Typography, Flex, message, Modal, Button, Tag } from 'antd';
import { ChangeMessage } from './ChangeMessage';
import dayjs from 'dayjs';
import { dateFormat } from 'helpers/dates';
import { ThreadMessage, ThreadsParams } from 'types/CRM/messages';
import { CardDate } from './styles';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { useThreadsRepository } from 'repos/ThreadsRepository';
import { ROLE_TEACHER } from 'Global/roles';

const { Paragraph } = Typography;

interface MessageProps {
  messageInfo: ThreadMessage;
  currentPage: number;
  branchId: number;
  getThreads: (params?: ThreadsParams) => Promise<any>;
}

/**
 * @description Компонент сообщения
 * @param {props} MessageProps
 * @return {React.ReactNode}
 */
export const Message = (props: MessageProps) => {
  const [strings] = useLanguageContext();
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const [{ confirm }, contextHolder] = Modal.useModal();
  const { locationSearchParams } = useLocationSearchParams();
  const messagesFromParentRepository = useThreadsRepository();

  const { messageInfo, branchId, getThreads, currentPage } = props;
  const { id, createdAt, sender, body, approved } = messageInfo || {};
  const { name, surname, roles } = sender || {};
  const hasTeacherMsg = roles.some(role => role === ROLE_TEACHER);

  const hideModal = () => setHasShowModal(false);
  const showModal = () => setHasShowModal(true);

  const deleteMessage = () => {
    confirm({
      title: strings.DO_YOU_WANT_TO_DELETE_MESSAGE_IN_THREAD,
      async onOk() {
        try {
          await messagesFromParentRepository.deleteMessage(id);
          message.success(strings.MESSAGE_SUCCESSFULLY_DELETE, 2);
          getThreads(locationSearchParams);
        } catch {
          message.error(strings.MESSAGE_DELETE_ERROR, 2);
        }
      }
    });
  };

  const approveMessage = (): void => {
    confirm({
      title: strings.DO_YOU_WANT_TO_APPROVE_THIS_MESSAGE,
      async onOk() {
        try {
          await messagesFromParentRepository.approveMessage(id);
          message.success(`${strings.SUCCESS} !`);
          getThreads(locationSearchParams);
        } catch {
          message.error(strings.SORRY_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN);
        }
      },
      maskClosable: true
    });
  };

  const cardProps = {
    style: { margin: '0 0 10px', minWidth: 400 },
    title: (
      <Flex align="center" justify="space-between" gap={10}>
        <Flex gap={10} align="baseline">
          <CardDate>{dayjs(createdAt).format(dateFormat)}</CardDate>
          <span>
            {name} {surname}
          </span>
          {approved && <Tag>{strings.APPROVED}</Tag>}
        </Flex>
        {hasTeacherMsg && (
          <Flex gap={10}>
            {!approved && (
              <Button type="primary" size="small" onClick={approveMessage}>
                {strings.APPROVE}
              </Button>
            )}
            <EditOutlined onClick={showModal} />
            <DeleteTwoTone twoToneColor="red" onClick={deleteMessage} />
          </Flex>
        )}
      </Flex>
    )
  };

  return (
    <Fragment>
      {contextHolder}
      <ChangeMessage id={id} getThreads={getThreads} message={body} hasShow={hasShowModal} hideModal={hideModal} />
      <Card {...cardProps} bodyStyle={{ padding: 16 }} headStyle={{ padding: 16 }}>
        <Paragraph>{body}</Paragraph>
      </Card>
    </Fragment>
  );
};

export default { Message };
