import styled from 'styled-components';
import { Divider, Row } from 'antd';

export const MainBlock = styled.div`
  @media (min-width: 320px) {
    margin-top: 35px;
    padding: 0px;
  }

  @media (min-width: 960px) {
    margin: 20px;
    // padding: 30px;
  }

  .ant-col {
    padding: 0 6px;
  }

  .ant-modal {
    max-width: 1200px;
  }
`;

export const Header = styled(Row)`
  margin-bottom: 20px;
`;

export const StDivider = styled(Divider)`
  .ant-divider-inner-text {
    color: #595959;
    font-size: 16px;
    font-weight: 700;
  }
`;
