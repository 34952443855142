import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { ErrorBoundaryHoc } from 'Global/components/ErrorBoundary';
import * as auth from 'Global/modules/Auth/routes';
import { Layout } from 'antd';
import { GlobalStyle } from 'Global/GlobalStyle';
import { Root } from 'Root';
import { Auth } from 'Global/modules/Auth';
import { LastLocationProvider } from 'react-router-last-location';
import { history } from 'browserHistory';
import { LanguageContextProvider } from 'contexts/LanguageContext';
import { RepositoryProvider } from 'repos/RepositoryProvider';
import { UserContextProvider } from 'contexts/UserContext';
import { ChildContextProvider } from 'contexts/ChildContext';
import { StorageContextProvider } from 'contexts/StorageContext';
import { ThreadsContextProvider } from 'contexts/ThreadsContext';
import { NewsContextProvider } from 'contexts/NewsContext';
import { withAuthGuard } from 'guards/authGuard';
import 'dayjs/locale/ka';
import 'dayjs/locale/he';
import 'dayjs/locale/lv';
import 'dayjs/locale/ru';
import 'dayjs/locale/en-gb';
import { FamilyContextProvider } from 'contexts/FamilyContext';
import { GlobalCollectionsContextProvider } from 'contexts/GlobalCollectionsContext';
import { ConstantsContextProvider } from './contexts/ConstantsContext';
import { FileFromLetter } from './Global/components/FileFromLetter';
import HelmetTitle from './Global/components/HelmetTitle';
import { SearchContextProvider } from './contexts/SearchContext';
import { GroupContextProvider } from './contexts/GroupContext';
import '@ant-design/compatible/assets/index.css';
import { ThemeContextProvider } from './contexts/ThemeContext';

const Forgot = () => <Auth isForgot={true} />;

export const Application = () => {
  return (
    <RepositoryProvider>
      <ConstantsContextProvider>
        <StorageContextProvider>
          <LanguageContextProvider>
            <Router history={history}>
              <UserContextProvider>
                <ThemeContextProvider>
                  <SearchContextProvider>
                    <GlobalCollectionsContextProvider>
                      <ErrorBoundaryHoc>
                        <FamilyContextProvider>
                          <NewsContextProvider>
                            <ChildContextProvider>
                              <ThreadsContextProvider>
                                <GroupContextProvider>
                                  <Layout style={{ backgroundColor: 'transparent' }} className="fullheight">
                                    <GlobalStyle />
                                    <LastLocationProvider>
                                      <HelmetTitle />
                                      <Switch>
                                        <Route path={auth.FILE_FROM_LETTER} component={FileFromLetter} />
                                        <Route path={auth.AUTH_FORGOT} component={Forgot} />
                                        <Route path={auth.BASE_AUTH} component={Auth} />
                                        <Route component={withAuthGuard(Root)} />
                                      </Switch>
                                    </LastLocationProvider>
                                  </Layout>
                                </GroupContextProvider>
                              </ThreadsContextProvider>
                            </ChildContextProvider>
                          </NewsContextProvider>
                        </FamilyContextProvider>
                      </ErrorBoundaryHoc>
                    </GlobalCollectionsContextProvider>
                  </SearchContextProvider>
                </ThemeContextProvider>
              </UserContextProvider>
            </Router>
          </LanguageContextProvider>
        </StorageContextProvider>
      </ConstantsContextProvider>
    </RepositoryProvider>
  );
};
