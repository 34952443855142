import { isEqual, forOwn, sortBy } from 'lodash';

export function getHasEqualsJournalFields(lessonInfo, mockLessonInfo, registers, mockRegisters) {
  for (let i = 0; i < registers.length; i++) {
    forOwn(registers[i], (value, key) => {
      if (value === '') {
        registers[i][key] = null;
      }
    });
  }

  const isEqualLesson = isEqual(lessonInfo, mockLessonInfo);
  const isEqualRegister = isEqual(sortBy(registers, 'student.surname'), sortBy(mockRegisters, 'student.surname'));

  return !isEqualLesson || !isEqualRegister;
}

/**
 * @description Редактирование поля homeworkMarkGrade
 * @param {number} hwMarkGrade
 * @param {number} maxHwGrade
 */
export const changeFieldHwMarkGrade = (hwMarkGrade: number, maxHwGrade: number) => {
  // При заполнении  HW Grade автоматически заполнять HW % значением: (hwGrade /  maxHWgrade) * 100.
  const formula = Math.round((100 / Number(maxHwGrade)) * Number(hwMarkGrade));

  return {
    homeworkGrade: formula, // ЭТО ПРОЦЕНТЫ
    homeworkMarkGrade: hwMarkGrade // ЭТО ОЦЕНКА В ЧИСЛЕ
  };
};

/**
 * @description Редактирование поля presence
 * @param {number} hwGrade
 * @param {number} maxHwGrade
 */
export const changeFieldPresence = (presence: boolean, classroomParticipationSmiles: boolean) => {
  if (classroomParticipationSmiles && presence) {
    return {
      presence,
      classroomParticipationGrade: 5
    };
  }

  return {
    presence
  };
};

export const generateColumn = (title, dataIndex, key, className, order, width) => ({
  title,
  dataIndex,
  key,
  className,
  order,
  width
});

export default {
  changeFieldHwMarkGrade,
  changeFieldPresence
};
