import React, { useEffect, useMemo, useState } from 'react';
import { Form, Row, Col, Button, Input, Select, message, DatePicker, Tabs, Spin } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import {
  FamilyContractStatus,
  FamilyContractTemplate,
  IFamily,
  Inventory,
  IParent,
  IStudent,
  Performer,
  Rate
} from 'types';
import { Courses, Families, Global } from 'api';
import { match } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import { useFamilyRepository } from 'repos/FamilyRepository';
import { dateFormat } from 'helpers';
import { contractTypes } from 'Global/constants';
import { useConstantsContext } from 'contexts/ConstantsContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import dayjs from 'dayjs';
import { StudentContractForm } from './StudentContractForm';
import { usePerformersRepository } from 'repos/PerformersRepository';

const { Option } = Select;
const { TabPane } = Tabs;

/**
 * @return {React.ReactNode}
 */
export const ContractCreation: React.FC = () => {
  const [strings] = useLanguageContext();
  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();
  const { goBack, push } = useHistory();

  const globalRequestsRepository = useGlobalRequestsRepository();
  const familyRepository = useFamilyRepository();
  const performersRepository = usePerformersRepository();
  const { ACTIVE_STUDENT_STATUS_ID } = useConstantsContext();

  const [form] = Form.useForm();
  const [family, setFamily] = useState<IFamily | null>(null);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [contractStatuses, setContractStatuses] = useState<FamilyContractStatus[]>([]);
  const [performers, setPerformers] = useState<Performer[]>([]);
  const [contractTemplates, setContractTemplates] = useState<FamilyContractTemplate[]>([]);
  const [rates, setRates] = useState<Rate[]>(undefined);
  const [inventories, setInventories] = useState<Inventory[]>([]);
  const defVal = { template: 0, contractDate: dayjs() };
  const [hasGSCEContractType, setHasGSCEContractType] = useState<boolean>(Boolean(defVal.template));
  const [selectedStudents, setSelectedStudents] = useState<number[]>([]);

  const studentList: IStudent[] = useMemo(
    () => family?.children?.filter((child: IStudent) => child?.status?.id === ACTIVE_STUDENT_STATUS_ID) || [],
    [family?.children]
  );
  const studentTabList: IStudent[] = useMemo(
    () => studentList.filter((item: IStudent) => selectedStudents.includes(item.id)) || [],
    [selectedStudents]
  );

  const onFinish = async values => {
    if (selectedStudents.isEmpty()) {
      return message.error(strings.PLEASE_SELECT_AT_LEAST_ONE_STUDENT);
    }
    setHasLoading(true);
    try {
      await Families.createFamilyContract(family?.id, values);
      message.success(strings.CONTRACT_WAS_SUCCESSFULLY_CREATED);
      push(`/family/${id}/contracts`);
    } catch (e) {
      message.error(strings.SORRY_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN);
    } finally {
      setHasLoading(false);
    }
  };

  /**
   * @description Получить необходимые данные по семье для создания контракта
   * @returns {Promise<any>}
   */
  const fetchMetadata = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data: statuses } = await Families.getFamilyContractStatuses();
      setContractStatuses(statuses);

      const { data: drafts } = await Families.getFamilyContractTemplates(id);
      setContractTemplates(drafts);

      const { data: familyPerformers } = await performersRepository.getFamilyPerformers(id);
      const activePerformers = familyPerformers.filter((item: Performer) => item?.isActive);
      setPerformers(activePerformers);

      const { data } = await familyRepository.getFamilyById(id);
      setFamily(data);

      const { data: rates } = await globalRequestsRepository.getRates({
        branch: family?.branch?.id
      });
      setRates(rates);

      const { data: inventories } = await Courses.getListInventories({
        branch: family?.branch?.id,
        active: 1
      });
      setInventories(inventories);
    } catch (e) {
      return message.error(strings.SORRY_SOMETHING_WENT_WRONG_WHEN_FETCHING_FAMILY_METADATA_PLEASE_TRY_AGAIN);
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    fetchMetadata();
  }, []);

  return (
    <Container>
      <StPageHeader onBack={goBack} title={`${strings.FAMILY} [${id}] ${strings.CONTRACT_CREATE}`} />
      <Spin spinning={hasLoading}>
        <Form layout="vertical" form={form} onFinish={onFinish} initialValues={defVal}>
          <Row gutter={10}>
            <Col lg={6} xs={24}>
              <Form.Item name="contractNumber" label={strings.CONTRACT_NUMBER}>
                <Input />
              </Form.Item>
            </Col>
            <Col lg={6} xs={24}>
              <Form.Item
                name="holded"
                label={strings.HOLDER}
                rules={[{ required: true, message: strings.HOLDER_IS_REQUIRED }]}
              >
                <Select>
                  {family?.parents.map((parent: IParent) => (
                    <Option key={parent.id} value={parent.id}>
                      {parent?.user?.name} {parent?.user?.surname}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={6} xs={24}>
              <Form.Item
                name="contractDate"
                label={strings.CONTRACT_DATE}
                rules={[{ required: true, message: strings.CONTRACT_DATE_IS_REQUIRED }]}
              >
                <DatePicker format={dateFormat} />
              </Form.Item>
            </Col>
            <Col lg={6} xs={24}>
              <Form.Item name="status" label={strings.STATUS}>
                <Select>
                  {contractStatuses.map(({ id, status }: FamilyContractStatus) => (
                    <Option key={id} value={id}>
                      {status}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={6} xs={24}>
              <Form.Item label={strings.STUDENTS} required>
                <Select mode="multiple" maxTagCount="responsive" onChange={(val: number[]) => setSelectedStudents(val)}>
                  {studentList.map((student: IStudent) => (
                    <Option key={student.id} value={student.id}>
                      {student.user.name} {student.user.surname}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={6} xs={24}>
              <Form.Item label={strings.CONTRACT_TYPE} name="template" required>
                <Select onChange={val => (val === 1 ? setHasGSCEContractType(true) : setHasGSCEContractType(false))}>
                  {contractTypes.map(item => (
                    <Option value={item.value}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={6} xs={24}>
              <Form.Item
                name="performer"
                label={strings.PERFORMER}
                rules={[{ required: true, message: `${strings.PERFORMER} ${strings.IS_REQUIRED}` }]}
              >
                <Select>
                  {performers.map((performer: Performer) => (
                    <Option key={performer.id} value={performer.id}>
                      {performer.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Tabs>
            {studentTabList.map((child: IStudent, index) => (
              <TabPane tab={`${child?.user?.name} ${child?.user?.surname}`} key={child.id.toString()} forceRender>
                <StudentContractForm
                  studentId={child.id}
                  hasGSCEContractType={hasGSCEContractType}
                  contractTemplates={contractTemplates}
                  rates={rates}
                  inventories={inventories}
                  index={index}
                />
              </TabPane>
            ))}
          </Tabs>
          <Row style={{ marginTop: 20 }}>
            <Col lg={24} xs={24} style={{ display: 'flex', justifyContent: 'end' }}>
              <Button type="primary" htmlType="submit">
                {strings.CREATE}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Container>
  );
};
