import React, { useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Table, Button, Spin, Row, Modal } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { getColumns } from './columns';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { IGetWeeklyEmailParams, IWeeklyEmailItem } from 'types/Mailing';
import { Filter } from './Filter';
import { dateFormatForBackend, dateMonthAgo, today } from 'helpers/dates';
import { useMailingRepository } from 'repos/MailingRepository';
import { convertBooleanToNumber } from 'helpers';
import BaseFilter from 'helpers/BaseFilter';
import { momentFormatValues } from 'helpers/momentFormatValues';
import { isEmpty, isUndefined, omitBy } from 'lodash';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { useUserContext } from 'contexts/UserContext';
import { WEEKLY_EMAIL, WEEKLY_EMAIL_NEW } from '../routes';
import { coursesRepository } from 'repos/CoursesRepository';
import { PreviewWeeklyEmailModal } from './PreviewWeeklyEmailModal';
import { QuickTips } from 'Global/components/QuickTips';
import { QUICK_TIPS_WEEKLY_EMAIL } from 'Global/constants';

/**
 * @description WeeklyEmail
 * @return {React.ReactNode}
 */
export const WeeklyEmail = () => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const hasAcademicExpert = user?.hasRoles?.academicExpert;
  const mailingRepository = useMailingRepository();
  const { locationSearchParams } = useLocationSearchParams();
  const { goBack, push } = useHistory();
  const FilterService = new BaseFilter();
  const [weeklyEmailList, setWeeklyEmailList] = useState<IWeeklyEmailItem[]>([]);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const [selectedWeeklyEmail, setSelectedWeeklyEmail] = useState<number>();
  const [defFilterValues, setDefFilterValues] = useState<IGetWeeklyEmailParams>({});
  const hasRiga = user?.branch?.id === 5;
  const [{ confirm }, contextHolder] = Modal.useModal();

  /**
   * @description Получение списка рассылок
   * @param {IGetWeeklyEmailParams} params
   * @return {Promise<any>}
   */
  const getWeeklyEmail = async (params: IGetWeeklyEmailParams): Promise<any> => {
    setHasLoading(true);
    try {
      //по умолчанию показываем рассылки с инвойсами
      params['invoices'] = 1;
      const values = momentFormatValues(convertBooleanToNumber(params));
      const { data } = await mailingRepository.getMailingList(omitBy(values, isUndefined));
      setWeeklyEmailList(data);
      push({ search: FilterService.getQueryStringParams(values, { arrayFormat: 'bracket' }) });
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  /**
   * @description Удалить рассылку
   * @param {number} id
   * @return {Promise<any>}
   */
  const onDeleteWeeklyEmail = (id: number): void => {
    confirm({
      title: strings.ARE_YOU_SURE,
      okType: 'danger',
      async onOk() {
        setHasLoading(true);
        try {
          await mailingRepository.deleteMailing(id);
          getWeeklyEmail(locationSearchParams);
        } catch {
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  const getActionColumns = () => [
    {
      title: '',
      dataIndex: 'id',
      key: 'action',
      render: (id: number) => (
        <Row align="middle" style={{ gap: 5 }}>
          <Button
            size="small"
            icon={<EyeOutlined />}
            style={{ fontSize: 14 }}
            onClick={() => {
              setSelectedWeeklyEmail(id);
              setHasShowModal(true);
            }}
          />
          <Link to={`${WEEKLY_EMAIL}/${id}/edit`}>
            <Button size="small" icon={<EditOutlined />} style={{ fontSize: 14 }} />
          </Link>
          {!hasAcademicExpert && (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => {
                onDeleteWeeklyEmail(id);
              }}
              style={{ fontSize: 14 }}
            />
          )}
        </Row>
      )
    }
  ];
  const columns = [...getColumns(strings), ...getActionColumns()];

  /**
   * @description Получение списка супер групп
   * @return {Promise<any>}
   */
  const getDefaultSuperGroupsParam = async (tutor): Promise<any> => {
    try {
      const params = { isActive: true, tutor: tutor };
      const { data } = await coursesRepository.getSuperGroups(params);
      return data.map(item => item.id);
    } catch {
      return [];
    }
  };

  useEffect(() => {
    (async () => {
      if (user) {
        const defaultValues = {
          dateFrom: dateMonthAgo.format(dateFormatForBackend),
          dateTo: today.format(dateFormatForBackend)
        };
        if (user.isTutorActiveFamilies) {
          const tutor = user.meAdminId;
          defaultValues['tutor'] = tutor;
          defaultValues['supergroups'] = await getDefaultSuperGroupsParam(tutor);
        }
        setDefFilterValues(defaultValues);
        if (isEmpty(locationSearchParams)) {
          getWeeklyEmail(defaultValues);
        } else {
          getWeeklyEmail(locationSearchParams);
        }
      }
    })();
  }, [user]);

  return (
    <Container>
      {contextHolder}
      <PreviewWeeklyEmailModal
        id={selectedWeeklyEmail}
        hasShowModal={hasShowModal}
        hideModal={() => setHasShowModal(false)}
      />
      <StPageHeader
        onBack={goBack}
        title={strings.WEEKLY_EMAIL}
        subTitle={!hasRiga && <QuickTips url={QUICK_TIPS_WEEKLY_EMAIL} />}
        extra={[
          !hasAcademicExpert && (
            <Button type="primary">
              <Link to={WEEKLY_EMAIL_NEW}>{strings.ADD_WEEKLY_EMAIL}</Link>
            </Button>
          )
        ]}
      >
        <Filter onFilter={getWeeklyEmail} defFilterValues={defFilterValues} />
      </StPageHeader>
      <Spin spinning={hasLoading}>
        <Row gutter={[10, 10]}>
          <Table dataSource={weeklyEmailList} columns={columns.filter(col => col)} pagination={false}></Table>
        </Row>
      </Spin>
    </Container>
  );
};

export default { WeeklyEmail };
