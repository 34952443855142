import { pickBy } from 'lodash';

/**
 * @description Формирование объекта FormData
 * @param {object} obj
 * @link https://developer.mozilla.org/ru/docs/Web/API/FormData/Using_FormData_Objects
 * @return {FormData} data
 */
export const getFormData = (obj: object, hasEmptyValues?: boolean): FormData => {
  const data = new FormData();

  for (let i in hasEmptyValues ? obj : pickBy(obj)) {
    const value = obj[i];
    if (value instanceof Array) {
      value.forEach((val: any) => {
        data.append(`${i}[]`, val);
      });
    } else if (value.fileList) {
      // Если текущее свойство объекта === файлу
      value.fileList.forEach(file => {
        data.append(i, file.originFileObj);
      });
    } else {
      data.append(i, value);
    }
  }

  return data;
};

// TODO Нужно сформировать одну функцию, пройтись по проекту и проверить что везде данные формируются корректно
/**
 * @description Формирование Form Data
 * @param {object} obj
 * @param {string} rootName
 * @param {Array} ignoreList
 * @link https://developer.mozilla.org/ru/docs/Web/API/FormData/Using_FormData_Objects
 */
export function getAllFormData(obj: object, rootName = '', ignoreList?: string[]): FormData {
  const formData = new FormData();

  /**
   * @description Функция для добавления свойства объекта в FormData
   * @param {object} data
   * @param {string} root
   * @return {void}
   */
  function appendFormData(data: any, root: string) {
    // Если не содержится в ignore list
    if (!ignore(root)) {
      root = root || '';
      if (data instanceof File) {
        formData.append(root, data);
      } else if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          appendFormData(data[i], root + '[' + i + ']');
        }
      } else if (typeof data === 'object' && data) {
        for (let key in data) {
          if (data.hasOwnProperty(key)) {
            if (root === '') {
              appendFormData(data[key], key);
            } else {
              appendFormData(data[key], `${root}[${key}]`);
            }
          }
        }
      } else {
        if (data !== null && typeof data !== 'undefined') {
          formData.append(root, data);
        }
      }
    }
  }

  const ignore = (root: string) => Array.isArray(ignoreList) && ignoreList.some(x => x === root);

  appendFormData(obj, rootName);

  return formData;
}
