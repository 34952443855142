import React, { useEffect, useMemo, useState } from 'react';
import { Button, Row, Col, Input, Modal, Spin, Form, Flex, Alert } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useBranchesRepository } from 'repos/BranchesRepository';

interface TagsFormProps {
  id: number;
  hasShowModal: boolean;
  setHasShowModal: (val: boolean) => void;
}

export const WelcomeLetterModal = ({ id, hasShowModal, setHasShowModal }: TagsFormProps) => {
  const [form] = Form.useForm();
  const [strings] = useLanguageContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const branchRepository = useBranchesRepository();

  const handleSubmit = async (params): Promise<any> => {
    setHasLoading(true);
    try {
      await branchRepository.editWelcomeLetter(id, params);
      closeModal();
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  const closeModal = (): void => {
    setHasShowModal(false);
    form.resetFields();
  };

  const getWelcomeLetter = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await branchRepository.getWelcomeLetter(id);
      form.setFieldsValue(data);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    if (hasShowModal) {
      getWelcomeLetter();
    }
  }, [hasShowModal]);

  const alertText = useMemo(() => {
    return (
      <p style={{ fontSize: 14, opacity: 0.8 }}>
        {strings.YOU_CAN_USE_THE_FOLLOWING_PLACEHOLDERS}
        <Flex gap={5}>
          <strong>{strings.FIRSTNAME_MARKER}</strong>
          <strong>{strings.LASTNAME_MARKER}</strong>
          <strong>{strings.LINK_MARKER}</strong>
          <strong>{strings.SITE_MARKER}</strong>
        </Flex>
      </p>
    );
  }, []);

  return (
    <Modal
      title={strings.WELCOME_EMAIL}
      footer={false}
      open={hasShowModal}
      onCancel={closeModal}
      style={{ top: 20 }}
      width={600}
      destroyOnClose={true}
    >
      <Spin spinning={hasLoading}>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Row gutter={[10, 10]}>
            <Col lg={24} xs={24}>
              <Alert message={alertText} type="info" showIcon />
            </Col>
            <Col lg={24}>
              <Form.Item
                name="title"
                label={strings.TITLE}
                rules={[{ required: true, message: `${strings.TITLE} ${strings.IS_REQUIRED}` }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item
                name="body"
                label={strings.TEXT}
                rules={[{ required: true, message: `${strings.TEXT} ${strings.IS_REQUIRED}` }]}
              >
                <Input.TextArea rows={6} />
              </Form.Item>
            </Col>
          </Row>
          <Flex justify="end" gap={10}>
            <Button key="back" onClick={closeModal}>
              {strings.CANCEL}
            </Button>
            <Button type="primary" htmlType="submit">
              {strings.EDIT}
            </Button>
          </Flex>
        </Form>
      </Spin>
    </Modal>
  );
};

export default { WelcomeLetterModal };
