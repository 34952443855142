import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { PageHeader } from '@ant-design/pro-layout';
import { Row, Col, Select, Button, Checkbox, Input, message } from 'antd';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { Container } from 'Global/GlobalStyle';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { match } from 'react-router';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import { Branch } from 'types';
import { BASE_TEACHERS_WAGES } from '../../routes';
import { convertBooleanToNumber } from 'helpers';
import { ITeacherWagesParams } from '../../../../types/finance';
const { Option } = Select;

interface TeachersWagesTeacherWageFormProps {
  form: WrappedFormUtils;
}

/**
 * @description Фильтр для инвентарей
 * @param {TeacherWageFormProps} props
 * @returns {React.ReactNode}
 */
export const TeacherWageFormTemplate = props => {
  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();

  const {
    form: { getFieldDecorator, validateFieldsAndScroll }
  } = props;

  const [strings] = useLanguageContext();
  const { branches } = useGlobalCollectionsContext();
  const globalRequestsRepository = useGlobalRequestsRepository();
  const { goBack, push } = useHistory();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [defaulValues, setDefaultValues] = useState(null);

  /**
   * @description Submit формы
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (err, values: ITeacherWagesParams) => {
      if (!err) {
        setHasLoading(true);

        const params = convertBooleanToNumber(values) as ITeacherWagesParams;
        try {
          if (id) {
            await globalRequestsRepository.editTeacherWage(id, params);
          } else {
            await globalRequestsRepository.createTeacherWage(params);
          }
          push(BASE_TEACHERS_WAGES);
        } catch (error) {
          message.error(error?.data?.message || strings.UNEXPECTED_ERROR);
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  function getActionString() {
    if (id) {
      return strings.EDIT;
    }

    return strings.ADD;
  }

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          const { data } = await globalRequestsRepository.getTeacherWageById(id);
          setDefaultValues(data);
        } catch {}
      })();
    }
  }, [id]);

  return (
    <Container>
      <PageHeader
        onBack={goBack}
        style={{ padding: 0 }}
        title={strings.TEACHER_S_WAGES}
        extra={[
          <Button type="primary" loading={hasLoading} onClick={handleSubmit} size="large">
            {getActionString()}
          </Button>
        ]}
      />
      <Form layout="vertical" style={{ marginTop: 20 }}>
        <Row align="middle" style={{ flexWrap: 'wrap' }}>
          <Col lg={10} xs={24}>
            <MyFormItem label={strings.NAME}>
              {getFieldDecorator('name', {
                initialValue: defaulValues?.name,
                rules: [{ required: true, message: strings.NAME_IS_REQUIRED }]
              })(<Input />)}
            </MyFormItem>
          </Col>
          <Col lg={10} xs={24}>
            <MyFormItem label={strings.VALUE}>
              {getFieldDecorator('cost', {
                initialValue: defaulValues?.cost,
                rules: [{ required: true, message: `${strings.VALUE} ${strings.IS_REQUIRED}` }]
              })(<Input />)}
            </MyFormItem>
          </Col>
          <Col lg={10} xs={24}>
            <MyFormItem label={strings.BRANCH}>
              {getFieldDecorator('branch', {
                initialValue: defaulValues?.branch && defaulValues?.branch?.id,
                rules: [{ required: false }]
              })(
                <Select placeholder={strings.BRANCH}>
                  {branches &&
                    branches.map((branch: Branch) => (
                      <Option value={branch.id} key={branch.id}>
                        {branch.name}
                      </Option>
                    ))}
                </Select>
              )}
            </MyFormItem>
          </Col>
          <Col lg={10} xs={24}>
            {getFieldDecorator('isActive', {
              initialValue: defaulValues?.isActive,
              rules: [{ required: false }],
              valuePropName: 'checked'
            })(<Checkbox>{strings.CURRENT}</Checkbox>)}
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export const TeacherWageForm = Form.create<TeachersWagesTeacherWageFormProps>({})(TeacherWageFormTemplate);
export default { TeacherWageForm };
