import isBoolean from 'lodash/isBoolean';

/**
 * @description Форматирование булевых данных под апи
 * @param {object} values
 * @return {object}
 */
export const booleanFormatValues = values => {
  for (let i in values) {
    if (isBoolean(values[i])) {
      values[i] = Number(values[i]);
    }
  }

  return values;
};

export const checkTypeForInput = (value: string | boolean) => {
  if (typeof value === 'string') {
    return value;
  } else {
    return '';
  }
};
