import React, { useState, useEffect } from 'react';
import { lowerCase } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Row, Table, Button } from 'antd';
import { Loader } from 'Global/components/Loader/Loader';
import { CollapseItem } from '../global/CollapseItem';
import Pagination from 'Global/components/Pagination';
import { Stages } from './Stages';
import BaseFilter from 'helpers/BaseFilter';
import { Global } from 'api/Global';
import { columns } from './columns';
import { Stage } from 'types';
import { PaginationInfo } from 'types/global';
import { OriginalCourse, OriginalCoursesParams } from 'types/courses';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import queryString from 'query-string';

/**
 * @description Курсы оригиналы
 * @returns {React.ReactNode}
 */
export const OriginalCourses = () => {
  const [coursesLoading, setCoursesLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>();
  const [stages, setStages] = useState<Stage[]>([]);
  const [selectedStageId, setSelectedStageId] = useState<number | undefined>(undefined);
  const [courses, setCourses] = useState<OriginalCourse[]>([]);
  const { goBack, push } = useHistory();
  const location = useLocation();
  const [strings] = useLanguageContext();
  const [{ meTeacherId }] = useUserContext();

  const { pageCount, current } = paginationInfo || {};
  const filterService = new BaseFilter();
  const locationSearchParams = filterService.getLocationSearchParams(location);
  const { stage } = locationSearchParams || {};
  const coursesRepository = useCoursesRepository();

  /**
   * @description Выбираем стейдж
   * @param {number} stageId
   */
  const onSelectStage = (stageId: number) => {
    setSelectedStageId(stageId);
    getOriginalCourses({ stage: stageId });
  };

  /**
   * @description Меняет страницу
   * @param {number} page
   */
  const handlePage = (page: number) => {
    const stage = selectedStageId ? selectedStageId : undefined;
    getOriginalCourses({ page, stage });
  };

  // Выводит группы по новому
  const clearFilter = () => {
    getOriginalCourses();
    setSelectedStageId(undefined);
  };

  /**
   * @description Стейджи учителя
   * @param {number} teacherId
   * @return {Promise<any>}
   */
  const getTeacherStages = async (teacherId: number): Promise<any> => {
    setLoading(true);
    try {
      const { data } = await Global.getTeacherStages(teacherId);
      setStages(data);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  /**
   * @description Список курс оригиналов
   * @param {any} params
   * @return {Promise<any>}
   */
  const getOriginalCourses = async (params?: OriginalCoursesParams): Promise<any> => {
    setCoursesLoading(true);
    try {
      const { data } = await coursesRepository.getOriginalCourses({ ...params, limit: 50 });
      setCourses(data.items);
      setPaginationInfo(data.pagination);
      push({ search: queryString.stringify(params) });
    } catch {
    } finally {
      setCoursesLoading(false);
    }
  };

  useEffect(() => {
    if (meTeacherId) {
      getTeacherStages(meTeacherId);
      getOriginalCourses(locationSearchParams);
    }
  }, [meTeacherId]);

  return (
    <>
      <Container>
        <StPageHeader
          onBack={goBack}
          title={strings.ORIGINAL_COURSES}
          subTitle={
            <Button onClick={clearFilter} disabled={!stage}>
              {strings.CLEAR} {lowerCase(strings.FILTER)}
            </Button>
          }
        />
        <Row>
          <Col lg={16}>
            <Loader spinning={coursesLoading}>
              <Table dataSource={courses} columns={columns(strings)} pagination={false} />
              {pageCount > 1 && <Pagination count={pageCount} current={current} pageChange={handlePage} />}
            </Loader>
          </Col>
          <Col lg={8}>
            <Loader spinning={loading}>
              <CollapseItem header={strings.GROUP} defaultOpen>
                <Stages stages={stages} selectedStageId={selectedStageId} onSelectStage={onSelectStage} />
              </CollapseItem>
            </Loader>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default { OriginalCourses };
