import React, { useState, useEffect } from 'react';
import { match } from 'react-router';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Row, Col, Input, Checkbox, Button, Spin, message, InputNumber } from 'antd';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useHistory } from 'react-router-dom';
import { AssessmentStatusesItem } from 'types/accessment';
import { useAssessmentsRepository } from 'repos/AssessmentsRepository';
import { Container } from 'Global/GlobalStyle';
import { Title } from 'Global/components/Title';
import { convertBooleanToNumber } from 'helpers';
import { BASE_ASSESSMENT_STATUSES } from '../routes';

interface AssessmentStatusFormProps {
  match: match<{ id: string }>;
  form: WrappedFormUtils;
}

export const AssessmentStatusForm = Form.create({})((props: AssessmentStatusFormProps) => {
  const {
    form: { getFieldDecorator, validateFieldsAndScroll },
    match: {
      params: { id }
    }
  } = props;

  const { goBack, push } = useHistory();
  const [strings] = useLanguageContext();
  const assessmentsRepository = useAssessmentsRepository();

  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [hasDisplayColorPicker, setHasDisplayColorPicker] = useState<boolean>(false);
  const [color, setColor] = useState<string>('#DEDEDE');
  const [assessmentStatus, setAssessmentStatus] = useState<AssessmentStatusesItem>(null);

  /**
   * @description Обновить инвентарь
   * @param {React.FormEvent<EventTarget>} e
   * @returns {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        setHasLoading(true);
        try {
          const params = convertBooleanToNumber(values) as AssessmentStatusesItem;
          if (id) {
            await assessmentsRepository.editAssessmentStatus(id, params);
          } else {
            await assessmentsRepository.createAssessmentStatus(params);
          }
          push(BASE_ASSESSMENT_STATUSES);
          if (id) {
            message.success(strings.STATUS_SUCCESSFULLY_EDITED, 2);
          } else {
            message.success(strings.STATUS_SUCCESSFULLY_CREATED, 2);
          }
        } catch (err) {
          message.error(err.message);
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  /**
   * @description Получить инвентарь по ID
   * @returns {Promise<void>}
   */
  const fetchAssessmentStatus = async (): Promise<void> => {
    setHasLoading(true);
    try {
      const { data } = await assessmentsRepository.getAssessmentStatusById(id);
      setAssessmentStatus(data);
      const statusColor = data?.color.match('#') ? data?.color : '#' + data.color;
      setColor(statusColor);
    } catch {
      message.error(strings.SORRY_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN);
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchAssessmentStatus();
    }
  }, [id]);

  return (
    <Container>
      <Spin spinning={hasLoading} tip={strings.LOADING}>
        <Title goBack={goBack} level={4}>
          {id ? strings.ASSESSMENT_STATUS_EDIT : strings.ASSESSMENT_STATUS_CREATION}
        </Title>
        <Form layout="vertical" onSubmit={handleSubmit}>
          <Row>
            <Col lg={6}>
              <MyFormItem label={strings.NAME}>
                {getFieldDecorator('name', {
                  initialValue: assessmentStatus?.name,
                  rules: [{ required: true, message: `${strings.NAME} ${strings.IS_REQUIRED}` }]
                })(<Input placeholder={strings.NAME} />)}
              </MyFormItem>
            </Col>
            <Col lg={6}>
              <MyFormItem label={strings.SORT}>
                {getFieldDecorator('sort', {
                  initialValue: assessmentStatus?.sort,
                  rules: [{ required: true, message: `${strings.SORT} ${strings.IS_REQUIRED}` }]
                })(<InputNumber placeholder={strings.SORT} />)}
              </MyFormItem>
            </Col>
            <Col lg={6}>
              <MyFormItem label={strings.COLOR}>
                {getFieldDecorator('color', {
                  initialValue: color,
                  rules: [{ required: true, message: `${strings.COLOR} ${strings.IS_REQUIRED}` }]
                })(<input type="color" className="ant-input" style={{ width: 80, cursor: 'pointer' }} value={color} />)}
              </MyFormItem>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <MyFormItem>
                {getFieldDecorator('unblock', {
                  initialValue: assessmentStatus?.unblock,
                  valuePropName: 'checked',
                  rules: [{ required: false }]
                })(<Checkbox style={{ padding: 0 }}>{strings.UNBLOCK}</Checkbox>)}
              </MyFormItem>
            </Col>
            <Col lg={6}>
              <MyFormItem>
                {getFieldDecorator('active', {
                  initialValue: assessmentStatus?.active,
                  valuePropName: 'checked',
                  rules: [{ required: false }]
                })(<Checkbox style={{ padding: 0 }}>{strings.ACTUAL}</Checkbox>)}
              </MyFormItem>
            </Col>
          </Row>
          <Row>
            <Button type="primary" size="large" htmlType="submit">
              {id ? strings.EDIT : strings.ADD}
            </Button>
          </Row>
        </Form>
      </Spin>
    </Container>
  );
});

export default { AssessmentStatusForm };
