import React from 'react';
import { IReverseLessonOutcomesBySubject } from 'types/Student';
import dayjs from 'dayjs';
import { dateMonthFormat } from 'helpers/dates';
import { Link } from 'react-router-dom';
import { gradeList, primaryGradeList } from 'Global/modules/Lesson/constants';
import { ReportingType } from 'types/global';

export const getColumns = strings => [
  {
    title: strings.DATE,
    dataIndex: '',
    key: 'lessonDate',
    width: 120,
    render: (row: IReverseLessonOutcomesBySubject) => {
      const { itemIndex, itemCount, lessonId, lessonDate } = row;
      const obj = {
        children: <Link to={`/le/${lessonId}`}>{dayjs(lessonDate).format(dateMonthFormat)}</Link>,
        props: { rowSpan: itemIndex === 1 ? itemCount : 0 }
      };
      return obj;
    }
  },
  {
    title: strings.TITLE,
    dataIndex: '',
    key: 'lessonTitle',
    width: 300,
    render: (row: IReverseLessonOutcomesBySubject) => {
      const { itemIndex, itemCount, lessonTitle } = row;
      const obj = {
        children: lessonTitle,
        props: { rowSpan: itemIndex === 1 ? itemCount : 0 }
      };
      return obj;
    }
  },
  {
    title: strings.GOAL,
    dataIndex: 'outcomeDescription',
    key: 'outcomeDescription',
    render: outcomeDescription => <div dangerouslySetInnerHTML={{ __html: outcomeDescription }} />
  },
  {
    title: strings.GRADE,
    dataIndex: 'outcomeGrade',
    key: 'outcomeGrade',
    width: 140,
    render: (outcomeGrade, record: IReverseLessonOutcomesBySubject) => {
      const { reportingType } = record;
      if (reportingType === ReportingType.primary || reportingType === ReportingType.school) {
        return <span>{primaryGradeList.find(item => item.value === outcomeGrade)?.title}</span>;
      }
      return <span>{gradeList.find(item => item.value === outcomeGrade)?.title}</span>;
    }
  }
];

export default { getColumns };
