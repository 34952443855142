import React, { useState, useEffect } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Spin, Row, Col, Button, Typography } from 'antd';
import { History, Location } from 'history';
import { useLastLocation } from 'react-router-last-location';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { TrainingsItem } from 'types/education';
import { useTrainingsRepository } from 'repos/TrainingsRepository';
import { Education as StEducation } from '../styles';
import { AttachmentItem } from 'types/global';
import { PreviewFileModal } from 'Global/components/FilePreview';
import { AttachmentType, initialAttachment } from '../../Monitoring/SummaryTable/Show/AttachmentsList';
import { getPreviewUrl } from 'Global/components/FilePreview/helpers';
import { downloadFileByUrl, getAttachmentLink } from 'helpers';
import { useUserContext } from 'contexts/UserContext';
import { BASE_TRAININGS } from '../routes';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useAttachmentRepository } from 'repos/AttachmentRepository';

const { Title, Paragraph } = Typography;

interface TrainingItemProps extends FormComponentProps {
  match: any;
  history: History;
  location: Location;
}

export const TrainingItem = (props: TrainingItemProps) => {
  const [strings] = useLanguageContext();
  const lastLocation = useLastLocation();
  const trainingsRepository = useTrainingsRepository();
  const attachmentRepository = useAttachmentRepository();
  const [user] = useUserContext();
  const hasAdmin = user?.hasRoles?.admin;

  const [training, setTraining] = useState<TrainingsItem>();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [hasAttachLoading, setHasAttachLoading] = useState<boolean>(false);
  const [attachmentList, setAttachmentList] = useState<AttachmentItem[]>([]);
  //для превью файла
  const [hasShowPreviewModal, setHasShowPreviewModal] = useState<boolean>(false);
  const [hasLoadPreviewAttachment, setHasLoadPreviewAttachment] = useState<boolean>(false);
  const [currentAttachment, setCurrentAttachment] = useState<AttachmentType>(initialAttachment);
  const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer>('');

  const {
    match: {
      params: { id }
    },
    history: { goBack, push }
  } = props;

  const { name, description } = training || {};

  /**
   * @description Превью в модалке
   * @param {number} attachmentId
   * @param {string} attachmentName
   * @return {Promise<any>}
   */
  const handleShowModal = async (attachmentId: number, attachmentName: string): Promise<any> => {
    setCurrentAttachment({
      id: attachmentId,
      name: attachmentName
    });
    setHasAttachLoading(true);
    setHasLoadPreviewAttachment(true);
    try {
      await getPreviewUrl(attachmentId, setPreviewUrl, setHasShowPreviewModal);
    } catch {
    } finally {
      setHasAttachLoading(false);
      setHasLoadPreviewAttachment(false);
    }
  };

  /**
   * @description Получение тренинга по id
   * @param {number} id
   * @return {Promise<any>}
   */
  const getTrainingById = async (id: number): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await trainingsRepository.getTrainingById(id);
      setTraining(data);
    } finally {
      setHasLoading(false);
    }
  };

  /**
   * @description Получить список файлов
   * @return {Promise<any>}
   */
  const getAttachmentList = async (): Promise<any> => {
    setHasAttachLoading(true);
    try {
      const { data } = await attachmentRepository.getAttachmentList({ type: 'trainingAttach', id: id });
      setAttachmentList(data.list);
    } catch {
      setAttachmentList([]);
    } finally {
      setHasAttachLoading(false);
    }
  };

  /**
   * @description Скачивание файла
   * @param {number} id
   * @param {string} name
   * @return {Promise<any>}
   */
  const onDownloadFile = async (id: number, name): Promise<any> => {
    setHasAttachLoading(true);
    const attachLink: string = getAttachmentLink(id);
    try {
      await downloadFileByUrl(attachLink, name);
    } finally {
      setHasAttachLoading(false);
    }
  };

  useEffect(() => {
    getTrainingById(id);
    getAttachmentList();
  }, [id]);

  return (
    <StEducation>
      <Row justify="space-between" className="no-padding">
        <Col lg={12}>
          <Title level={3}>
            {lastLocation && <ArrowLeftOutlined onClick={goBack} />} {name}
          </Title>
        </Col>
        {hasAdmin && (
          <Col lg={12} style={{ textAlign: 'right', width: '100%' }}>
            <Button onClick={() => push(`${BASE_TRAININGS}/${id}/edit`)}>{strings.EDIT}</Button>
          </Col>
        )}
      </Row>
      <PreviewFileModal
        hasLoadPreviewAttachment={hasLoadPreviewAttachment}
        fileName={currentAttachment.name}
        previewUrl={previewUrl}
        handleHideModal={() => setHasShowPreviewModal(false)}
        hasShowModal={hasShowPreviewModal}
      />
      <Spin spinning={hasLoading} tip={`${strings.LOADING} ${strings.TRAINING}`}>
        <Row className="no-padding">
          <Col lg={24}>
            <Paragraph>
              <b>{strings.DESCRIPTION}:</b>
              <br />
              {description}
            </Paragraph>
          </Col>
          <Col lg={24}>
            <Spin spinning={hasAttachLoading}>
              <Row align="middle" style={{ gap: 5, marginTop: 10 }} className="no-padding">
                <b>{strings.ATTACHMENTS}:</b>
                {attachmentList.map(attach => {
                  const createdBy = `${attach?.user?.surname} ${attach?.user?.name}`;
                  return (
                    <Col lg={24}>
                      <Button type="link" onClick={() => onDownloadFile(attach.id, attach.name)}>
                        {attach.name}
                      </Button>{' '}
                      <span style={{ fontSize: 12, color: '#bbb' }}>
                        {strings.UPLOADED_AT} {attach.updatedAt} {strings.BY} {createdBy}
                      </span>
                    </Col>
                  );
                })}
              </Row>
            </Spin>
          </Col>
        </Row>
      </Spin>
    </StEducation>
  );
};

export default { TrainingItem };
