import React, { useState, useEffect } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Typography, Spin } from 'antd';
import { Location, History } from 'history';
import { useRouteMatch } from 'react-router-dom';
import { match } from 'react-router';
import { useLastLocation } from 'react-router-last-location';
import { Container } from 'Global/styles';
import Breadcrumbs from 'Global/components/Breadcrumbs';
import { ThreadMessage } from './ThreadMessage';
import { CRM } from 'api/CRM';
import { useLanguageContext } from 'contexts/LanguageContext';

// Типы
import { Thread, ThreadMessage as IThreadMessage } from 'types/CRM/messages';

const { Title } = Typography;

interface ShowThreadProps {
  location: Location;
  history: History;
}

/**
 * @description Отображаю тред
 * @param {ShowThreadProps} props
 * @return {React.ReactNode}
 */
export const ShowThread = (props: ShowThreadProps) => {
  const [strings] = useLanguageContext();
  const [thread, setThread] = useState<Thread>();
  const [loadingThread, setLoadingThread] = useState<boolean>(false);
  const {
    history: { goBack },
    location
  } = props;

  const { messages } = thread || {};

  // Hooks
  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();
  const lastLocation = useLastLocation();

  /**
   * @description Тред
   * @param {number} threadId
   * @return {Promise<any>}
   */
  const getThread = async (threadId: number): Promise<any> => {
    setLoadingThread(true);
    await CRM.getThread(threadId)
      .then(({ data }) => {
        setThread(data.thread);
      })
      .catch(() => {})
      .finally(() => setLoadingThread(false));
  };

  useEffect(() => {
    getThread(Number(id));
  }, [id]); // eslint-disable-line

  return (
    <Container>
      <Breadcrumbs location={location} />
      <Title level={3}>
        {lastLocation && <ArrowLeftOutlined onClick={goBack} />} {strings.THREAD} {id}
      </Title>
      <div style={{ textAlign: loadingThread ? 'center' : 'left' }}>
        <Spin tip={`${strings.LOADING} ${strings.MESSAGES}`} spinning={loadingThread}>
          {messages?.map((message: IThreadMessage) => (
            <ThreadMessage message={message} />
          ))}
        </Spin>
      </div>
    </Container>
  );
};

export default { ShowThread };
