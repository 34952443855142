import React from 'react';
import { Select } from 'antd';

export const columns = strings => [
  {
    title: strings.TEACHERS,
    dataIndex: 'teachers',
    key: 'teachers'
  },
  {
    title: strings.RATES,
    dataIndex: 'rates',
    key: 'rates'
  },
  {
    title: strings.LESSON,
    dataIndex: 'lesson',
    key: 'lesson'
  },
  {
    title: strings.HW,
    dataIndex: 'hw',
    key: 'hw'
  },
  {
    title: strings.EXPERT,
    dataIndex: 'expert',
    key: 'expert'
  },
  {
    title: strings.ON_TIME,
    dataIndex: 'onTime',
    key: 'oneTime'
  },
  {
    title: '',
    dataIndex: '',
    key: 'select',
    render: () => <Select />
  },
  {
    title: strings.COMMENTS_GRADED,
    dataIndex: 'commentsGraded',
    key: 'commentsGraded'
  },
  {
    title: strings.LIKED,
    dataIndex: 'liked',
    key: 'liked'
  },
  {
    title: strings.PERCENT_DISLIKED,
    dataIndex: 'disliked',
    key: 'disliked'
  },
  {
    title: strings.PARENT_LIKES,
    dataIndex: 'parentLikes',
    key: 'parentLikes'
  },
  {
    title: '',
    dataIndex: '',
    key: 'two select',
    render: () => <Select />
  }
];

export default { columns };
