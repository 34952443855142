import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { PageHeader } from '@ant-design/pro-layout';
import { Button, Col, Input, InputNumber, message, Row, Select, Spin } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { Container } from 'Global/GlobalStyle';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { match } from 'react-router';
import { useLanguageContext } from 'contexts/LanguageContext';
import { convertBooleanToNumber } from 'helpers';
import { BASE_CLASSROOMS } from '../routes';
import { ClassroomItem, ClassroomPostParams } from 'types/education';
import { Branch } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useClassroomsRepository } from 'repos/ClassroomsRepository';

const { Option } = Select;

interface ClassroomFormProps extends FormComponentProps {}

export const ClassroomForm = Form.create<ClassroomFormProps>({})((props: ClassroomFormProps) => {
  const {
    form: { getFieldDecorator, validateFieldsAndScroll }
  } = props;
  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();
  const { goBack, push } = useHistory();
  const [strings] = useLanguageContext();
  const { branches } = useGlobalCollectionsContext();
  const classroomsRepository = useClassroomsRepository();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [classroom, setClassroom] = useState<ClassroomItem>({});
  const { name, color, comment, placesCount, webLink, webLogin, webPasscode, webPassword, branch } = classroom;

  const fetchClassroom = async (): Promise<void> => {
    setHasLoading(true);
    try {
      const { data } = await classroomsRepository.getClassroomById(id);
      setClassroom(data);
    } catch {
      message.error(strings.SORRY_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN);
    } finally {
      setHasLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        setHasLoading(true);
        try {
          const params = convertBooleanToNumber(values) as ClassroomPostParams;
          if (id) {
            await classroomsRepository.editClassroom(id, params);
          } else {
            await classroomsRepository.createClassroom(params);
          }
          push(BASE_CLASSROOMS);
          if (id) {
            message.success(`${strings.CLASSROOM} ${strings.SUCCESSFULLY_EDITED}`, 2);
          } else {
            message.success(`${strings.CLASSROOM} ${strings.SUCCESSFULLY_CREATED}`, 2);
          }
        } catch (err) {
          message.error(err.message);
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  useEffect(() => {
    if (id) {
      fetchClassroom();
    }
  }, [id]);

  return (
    <Container>
      <PageHeader style={{ padding: 0 }} onBack={goBack} title={id ? strings.EDIT : strings.ADD} />
      <Spin spinning={hasLoading} tip={strings.LOADING}>
        <Form layout="vertical" onSubmit={handleSubmit}>
          <Row>
            <Col lg={6}>
              <MyFormItem label={strings.NAME}>
                {getFieldDecorator('name', {
                  initialValue: name,
                  rules: [{ required: true, message: `${strings.NAME} ${strings.IS_REQUIRED}` }]
                })(<Input />)}
              </MyFormItem>
            </Col>
            <Col lg={6}>
              <MyFormItem label={strings.BRANCH}>
                {getFieldDecorator('branch', { initialValue: branch?.id, rules: [{ required: true }] })(
                  <Select>
                    {branches.map((branch: Branch) => (
                      <Option value={branch.id} key={branch.id}>
                        {branch.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </MyFormItem>
            </Col>
            <Col lg={4}>
              <MyFormItem label={strings.NUMBER_OF_SEATS}>
                {getFieldDecorator('placesCount', {
                  initialValue: placesCount,
                  rules: [{ required: true, message: `${strings.NUMBER_OF_SEATS} ${strings.IS_REQUIRED}` }]
                })(<InputNumber />)}
              </MyFormItem>
            </Col>
            <Col lg={2}>
              <MyFormItem label={strings.COLOR}>
                {getFieldDecorator('color', {
                  initialValue: color,
                  rules: [{ required: false }]
                })(<input type="color" className="ant-input" style={{ cursor: 'pointer' }} value={color} />)}
              </MyFormItem>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <MyFormItem label={strings.ZOOM_LOGIN}>
                {getFieldDecorator('webLogin', {
                  initialValue: webLogin,
                  rules: [{ required: false }]
                })(<Input />)}
              </MyFormItem>
            </Col>
            <Col lg={6}>
              <MyFormItem label={strings.ZOOM_WEB_PASSCODE}>
                {getFieldDecorator('webPasscode', {
                  initialValue: webPasscode,
                  rules: [{ required: false }]
                })(<Input />)}
              </MyFormItem>
            </Col>
            <Col lg={6}>
              <MyFormItem label={strings.ZOOM_PASSWORD}>
                {getFieldDecorator('webPassword', {
                  initialValue: webPassword,
                  rules: [{ required: false }]
                })(<Input />)}
              </MyFormItem>
            </Col>
            <Col lg={18}>
              <MyFormItem label={strings.ZOOM_LINK}>
                {getFieldDecorator('webLink', {
                  initialValue: webLink,
                  rules: [{ required: false }]
                })(<Input />)}
              </MyFormItem>
            </Col>
          </Row>
          <Row>
            <Col lg={18}>
              <MyFormItem label={strings.COMMENT}>
                {getFieldDecorator('comment', {
                  initialValue: comment
                })(<Input.TextArea placeholder={strings.COMMENT} />)}
              </MyFormItem>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Button type="primary" size="large" htmlType="submit">
                {id ? strings.EDIT : strings.ADD}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Container>
  );
});
export default { ClassroomForm };
