import React, { useEffect, useState } from 'react';
import { Table, Row } from 'antd';
import { Logs } from 'api/Logs';
import { mockPagination, MANUAL_INPUT_LIMIT } from 'Global/constants';
import { scrollTop } from 'helpers/scroll';
import { LessonsRegistersLogsListItem, LessonsRegistersLogsFilterParams } from 'types/logs';
import { PaginationInfo } from 'types/global';
import Pagination from 'Global/components/Pagination';
import { Filter } from './Filter';
import { columns } from './columns';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useHistory } from 'react-router-dom';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import queryString from 'query-string';

const { Column } = Table;

/**
 * @description  RegistersLogs component
 * @param {object} props
 * @return {React.ReactNode}
 */
export const RegistersLogs = () => {
  const [strings] = useLanguageContext();

  const [lessonsRegistersLogsList, setLessonsRegistersLogsList] = useState<LessonsRegistersLogsListItem[]>([]);
  const [hasLoadLessonsRegistersLogsList, setHasLoadLessonsRegistersLogsList] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationInfo>(mockPagination);

  const [user] = useUserContext();
  const { push, goBack } = useHistory();

  const limit = MANUAL_INPUT_LIMIT;
  const { locationSearchParams } = useLocationSearchParams();
  const { pageCount, current } = pagination;

  /**
   * @description Пагинация
   * @param {number} page
   * @return {void}
   */
  const handlePage = async (page: number): Promise<any> => {
    const params = { ...locationSearchParams, page, limit };
    await getLessonsRegistersLogsList(params);
    scrollTop();
  };

  /**
   * @description Получение списка LessonsRegistersLogs Logs
   * @param {LessonsRegistersLogsFilterParams} params
   * @return {Promise<any>}
   */
  const getLessonsRegistersLogsList = async (params: LessonsRegistersLogsFilterParams): Promise<any> => {
    setHasLoadLessonsRegistersLogsList(true);
    await Logs.getLessonsRegistersLogsList({ ...params })
      .then(({ data: { items, paginationInfo } }) => {
        setLessonsRegistersLogsList(items);
        setPagination(paginationInfo);
        setHasLoadLessonsRegistersLogsList(false);
        push({ search: queryString.stringify(params) });
      })
      .catch(() => {});
  };

  useEffect(() => {
    getLessonsRegistersLogsList(locationSearchParams);
  }, [user]); // eslint-disable-line

  return (
    <Container>
      <StPageHeader onBack={goBack} title={strings.REGISTER_LOGS}>
        <Filter getLessonsRegistersLogsList={getLessonsRegistersLogsList} />
      </StPageHeader>
      <Row gutter={[10, 10]}>
        <Table
          dataSource={lessonsRegistersLogsList}
          pagination={false}
          bordered={false}
          loading={hasLoadLessonsRegistersLogsList}
        >
          {columns(strings).map(col => (
            <Column key={col.key} {...col} />
          ))}
        </Table>
        <Pagination count={pageCount} current={current} pageChange={handlePage} />
      </Row>
    </Container>
  );
};

export default { RegistersLogs };
