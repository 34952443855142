import React, { useState, useEffect } from 'react';
import { scrollTop } from 'helpers/scroll';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { match } from 'react-router';
import { Form } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import { Row, Col, Spin, Button, Collapse } from 'antd';
import { ExpertAssessment, ExpertAssessmentOld } from './ExpertAssessment';
import { HwAssessment } from './HwAssessment';
import { FeedbackManagement } from './FeedbackManagement';
import { LessonAssessment } from './LessonAssessment';
import { Materials } from './AssessmentMaterials';
import { AssessmentItem, AssessmentMaterial } from 'types/accessment';
import { downloadFile } from 'helpers';
import { getUrlEnvironment } from 'helpers/url';
import { LessonRecording } from '../LessonRecording';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container } from 'Global/GlobalStyle';
import { useAssessmentsRepository } from 'repos/AssessmentsRepository';
import HeaderAssessment from './HeaderAssessment';
import VideoAssessment from './VideoAssessment';
import styled from 'styled-components';
import { DisciplineAssessment } from './DisciplineAssessment';

const { Panel } = Collapse;

export const StyledForm = styled(Form)`
  & .ant-form-legacy-item-label {
    white-space: pre-wrap;
    line-height: 2;
    text-align: left;
  }
  & .anticon-info-circle {
    font-size: 22px !important;
    margin: 5px;
    cursor: pointer;
  }
`;

/**
 * @description Компонент просмотра Assessment
 * @param {object} props
 * @return {React.ReactNode}
 */
export const ShowAssessment = () => {
  const [strings] = useLanguageContext();
  const assessmentsRepository = useAssessmentsRepository();
  const [assessmentItem, setAssessmentItem] = useState<AssessmentItem>(null);
  const [hasLoadAssessmentItem, setHasLoadAssessmentItem] = useState<boolean>(false);
  const [isExportLoader, setExportLoader] = useState<boolean>(false);
  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();
  const { goBack } = useHistory();

  const {
    header,
    lesson,
    lessonAssessment,
    hwAssessment,
    expertAssessment,
    feedbackAssessment,
    hwMaterials,
    hideTeacherAssessment,
    video,
    stage,
    materialsLastLessons,
    disciplineAssessment
  } = assessmentItem || {};

  /**
   * @description Получение Assessment по id
   * @return {Promise<any>}
   */
  const getAssessmentById = async (): Promise<any> => {
    setHasLoadAssessmentItem(true);
    try {
      const { data, status } = await assessmentsRepository.getAssessmentByLessonId(id);
      if (status === 200) {
        setAssessmentItem(data);
      }
    } finally {
      setHasLoadAssessmentItem(false);
    }
  };

  /**
   * @description Удаляет уроки у которых нет заголовков
   * @param {AssessmentMaterial[]} lessons
   * @return {AssessmentMaterial[]}
   */
  const removeEmptyLessons = (lessons: AssessmentMaterial[]): AssessmentMaterial[] => {
    return lessons.filter((lesson: AssessmentMaterial) => lesson?.title?.length);
  };

  const lessonAssessmentMark = header?.lessonAssessment.mark.length ? header?.lessonAssessment.mark : '✖';
  const hwAssessmentMark = header?.hwAssessment.mark.length ? header?.hwAssessment.mark : '✖';
  const expertAssessmentMark = header?.expertAssessment.mark.length ? header?.expertAssessment.mark : '✖';
  const disciplineAssessmentMark = header?.disciplineAssessment.mark.length ? header?.disciplineAssessment.mark : '✖';
  const envURL = getUrlEnvironment(window.location.hostname);

  const exportAssessment = async (lessonId: number | string): Promise<any> => {
    setExportLoader(true);
    try {
      const {
        data: { url }
      } = await assessmentsRepository.exportAssessmentByLessonId(lessonId);
      downloadFile(`${envURL}${url}`, 'lesson', true);
    } finally {
      setExportLoader(false);
    }
  };

  useEffect(() => {
    getAssessmentById();
    scrollTop();
  }, [id]);

  return (
    <Spin spinning={hasLoadAssessmentItem}>
      <Container>
        <PageHeader
          style={{ padding: 0, marginBottom: 20 }}
          title={strings.LESSON_ASSESSMENT}
          onBack={goBack}
          extra={[
            <Button
              type="primary"
              onClick={() => exportAssessment(id)}
              disabled={isExportLoader}
              loading={isExportLoader}
            >
              {strings.EXPORT}
            </Button>,
            assessmentItem?.prevLessonForAssessment && (
              <Link to={`/summary/${assessmentItem?.prevLessonForAssessment}`}>
                <Button type="primary" disabled={isExportLoader} loading={isExportLoader}>
                  {strings.PREVIOUSLY_ASSESSED_LESSON}
                </Button>
              </Link>
            )
          ]}
        />
        {header && <HeaderAssessment header={header} lesson={lesson} type={lessonAssessment.type} />}
        <Row gutter={[10, 10]}>
          <Col lg={10} xs={24}>
            <div style={{ position: 'sticky', top: 90 }}>
            <VideoAssessment video={video} />
              {assessmentItem && (
                <LessonRecording
                  assessmentItem={assessmentItem}
                  onSuccessUpdate={getAssessmentById}
                  id={assessmentItem.lessonAssessment.id}
                />
              )}
            </div>
          </Col>
          <Col lg={14} xs={24}>
            <Collapse>
              {lessonAssessment && (
                <Panel
                  header={strings.LESSON_ASSESSMENT}
                  key="1"
                  disabled={hideTeacherAssessment}
                  extra={lessonAssessmentMark}
                >
                  <LessonAssessment lessonAssessment={lessonAssessment} />
                </Panel>
              )}
              {hwAssessment && stage?.reportType !== 'primary' && (
                <Panel
                  header={strings.HW_DESIGN}
                  key="2"
                  forceRender
                  disabled={hideTeacherAssessment}
                  extra={hwAssessmentMark}
                >
                  <HwAssessment
                    hwAssessment={hwAssessment}
                    lessons={removeEmptyLessons(materialsLastLessons)}
                    hwMaterials={hwMaterials}
                  />
                </Panel>
              )}
              {expertAssessment && (
                <Panel header={strings.EXPERT_ASSESSMENT} key="3" forceRender extra={expertAssessmentMark}>
                  {expertAssessment.type === 'expertV2' ? (
                    <ExpertAssessment expertAssessment={expertAssessment} />
                  ) : (
                    <ExpertAssessmentOld expertAssessment={expertAssessment} />
                  )}
                </Panel>
              )}
              {disciplineAssessment && (
                <Panel header={strings.DISCIPLINE} key="4" extra={disciplineAssessmentMark}>
                  <DisciplineAssessment discipline={disciplineAssessment} />
                </Panel>
              )}
              {materialsLastLessons && (
                <Panel header={strings.MATERIALS_FOR_3_LAST_LESSONS} key="5">
                  <Materials lessons={removeEmptyLessons(materialsLastLessons)} />
                </Panel>
              )}
              {feedbackAssessment && (
                <Panel header={strings.FEEDBACK_MANAGEMENT} key="6" forceRender>
                  <FeedbackManagement feedbackAssessment={feedbackAssessment} />
                </Panel>
              )}
            </Collapse>
          </Col>
        </Row>
      </Container>
    </Spin>
  );
};

export default { ShowAssessment };
