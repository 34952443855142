import React from 'react';
import { administrators } from './administrators';
import { families } from './families';
import { education } from './education';
import { sms } from './sms';
import { mail } from './mail';
import { website } from './website';
import { schedule } from '../schedule';
import { monitoring } from './monitoring';
import { teachersArr as teachers } from './teachers';
import { coursesArr as courses } from './courses';
import { EDIT_NEWS, CREATE_NEWS, BASE_NEWS } from 'Admin/News/routes';
import { News } from 'Global/modules/Home/News';
import { NewsItem } from 'Admin/News';
import { SearchResults } from 'Global/components/SearchResults';
import { Translations } from 'Admin/Translations';

export const adminRoutes = [
  ...administrators,
  ...families,
  ...teachers,
  ...courses,
  ...education,
  ...sms,
  ...mail,
  ...monitoring,
  ...schedule,
  ...website,
  {
    path: '/translations',
    component: Translations,
    exact: true
  },
  {
    path: EDIT_NEWS,
    component: NewsItem,
    exact: false
  },
  {
    path: CREATE_NEWS,
    component: NewsItem,
    exact: false
  },
  {
    path: BASE_NEWS,
    component: () => <News isPage={true} />,
    exact: true
  },
  {
    path: '/search',
    component: SearchResults,
    exact: false
  }
];

export default { adminRoutes };
