import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import { pickBy, identity } from 'lodash';
import Helmet from 'react-helmet';
import { Row, Tabs, Button, Col, Spin, Divider, Form, Badge, Flex } from 'antd';
import { ErrorHandler } from 'helpers/services/ErrorHandler';
import CalendarWidgetMemo from 'Global/components/CalendarWidget';
import LessonInfoMemo from './LessonInfo';
import { ZoomWidget } from './LessonInfo/ZoomWidget';
import RegisterMemo from './LessonRegister';
import { LessonContainer } from 'Admin/Courses/styles';
import { ILessonInfo } from 'types/courses';
import LessonHeader from './LessonHeader';
import { LessonForbidden } from './LessonForbidden';
import { LessonNotFound } from './LessonNotFound';
import { LessonConfirm } from './LessonConfirm';
import { INFO_TAB, REGISTER_TAB } from './constants';
import { getHasEqualsJournalFields } from './helpers';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useLessonRepository } from 'repos/LessonRepository';
import { match } from 'react-router';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { Legend } from './LessonOutcomes/Legend';
import { ReportingType } from 'types/global';
import { useSaveLesson } from './hook/useSaveLesson';
import LessonOutcomesMemo from './LessonOutcomes';
import { useLessonRegisterStore } from 'stores/useLessonRegisterStore';
import { useLessonInfoStore } from 'stores/useLessonInfoStore';
import { useLessonOutcomesStore } from 'stores/useLessonOutcomesStore';
import { useMediaQuery } from 'react-responsive';

const { TabPane } = Tabs;

const StyledContainer = styled.div`
  padding: 20px 20px;
  box-shadow: 0px 0px 46px -15px rgba(0, 0, 0, 0.12);
  background: #fff;
  border-radius: 5px;
`;

export const Lesson = () => {
  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();
  return <LessonContent key={id} id={id} />; // Используем key для сброса Lesson при изменении id
};

const LessonContent = React.memo(({ id }: { id: string }) => {
  const [strings] = useLanguageContext();

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const lessonInfo = useLessonInfoStore(state => state.lessonInfo);
  const setLessonInfo = useLessonInfoStore(state => state.setLessonInfo);

  const registers = useLessonRegisterStore(state => state.registers);
  const setRegisters = useLessonRegisterStore(state => state.setRegisters);
  const mockRegisters = useLessonRegisterStore(state => state.mockRegisters);
  const setMaxHwGrade = useLessonRegisterStore(state => state.setMaxHwGrade);
  const maxTaskGrade = useLessonRegisterStore(state => state.maxTaskGrade);
  const maxHwGrade = useLessonRegisterStore(state => state.maxHwGrade);
  const extraRegisterData = useLessonRegisterStore(state => state.extraRegisterData);
  const hasRegistersLoading = useLessonRegisterStore(state => state.hasRegistersLoading);
  const setHasRegistersLoading = useLessonRegisterStore(state => state.setHasRegistersLoading);
  const registerUpdatedAt = useLessonRegisterStore(state => state.registerUpdatedAt);
  const setHasRegistersFetch = useLessonRegisterStore(state => state.setHasRegistersFetch);

  const setOriginalLessonOutcomes = useLessonOutcomesStore(state => state.setOriginalLessonOutcomes);
  const originalLessonOutcomes = useLessonOutcomesStore(state => state.originalLessonOutcomes);
  const setHasLessonOutcomesChanged = useLessonOutcomesStore(state => state.setHasLessonOutcomesChanged);
  const hasLessonOutcomesChanged = useLessonOutcomesStore(state => state.hasLessonOutcomesChanged);

  const { task } = extraRegisterData || {};
  const { lesson, prevLesson, nextLesson, outcomesFilled } = lessonInfo || {};
  const { courseGroup, slot } = lesson || {};
  const reportType = courseGroup?.stage?.reportType;
  const hasPrimaryOrSchool = reportType === ReportingType.primary || reportType === ReportingType.school;

  const [mockLessonInfo, setMockLessonInfo] = useState<ILessonInfo>(null);
  const [hasLessonsInfoLoading, setHasLessonsInfoLoading] = useState<boolean>(false);
  const [hasSaveLoading, setHasSaveLoading] = useState<boolean>(false);
  const [hasForbidden, setHasForbidden] = useState<boolean>(false);
  const [hasNotFound, setHasNotFound] = useState<boolean>(false);

  // Таб по умолчанию
  const [activeTab, setActiveTabs] = useState<string>('info');

  const location = useLocation();
  const tabLocation = queryString.parse(location?.search).tab;

  const [lessonInfoForm] = Form.useForm();
  const [outcomesForm] = Form.useForm();

  const lessonRepository = useLessonRepository();
  const hasJournalModified = getHasEqualsJournalFields(
    lessonInfoForm.getFieldsValue(),
    mockLessonInfo,
    registers,
    mockRegisters
  );

  const fetchLesson = async (lessonId: number | string): Promise<any> => {
    try {
      setHasLessonsInfoLoading(true);
      const { data } = await lessonRepository.getLessonsInfoById(lessonId);
      const { lesson } = data;
      setMaxHwGrade(lesson?.homeworkMaxGrade || 100);
      setLessonInfo(data);
      lessonInfoForm.resetFields();
      // Запишем первоначальную информацию по уроку для дальнейшего сравнивания
      const mockValues = lessonInfoForm.getFieldsValue() as ILessonInfo;
      setMockLessonInfo(mockValues);
    } catch (err) {
      if (ErrorHandler.checkStatusCode(err, 404)) {
        setHasNotFound(true);
      } else if (ErrorHandler.checkStatusCode(err, 403)) {
        setHasForbidden(true);
      }
    } finally {
      setHasLessonsInfoLoading(false);
    }
  };

  const getLessonById = async (lessonId: number | string, hasLoader: boolean = true): Promise<any> => {
    try {
      hasLoader && setHasLessonsInfoLoading(true);
      await fetchLesson(lessonId);
      hasLoader && setHasLessonsInfoLoading(false);
    } catch (err) {
      if (ErrorHandler.checkStatusCode(err, 403)) {
        setHasForbidden(true);
      }
    }
  };

  const onChangeActiveTab = useCallback((tab: string): void => {
    setActiveTabs(tab);
  }, []);

  const { onSaveLesson, contextHolder } = useSaveLesson({
    lessonInfoForm,
    outcomesForm,
    id,
    task,
    registers,
    registerUpdatedAt,
    maxTaskGrade,
    maxHwGrade,
    hasLessonOutcomesChanged,
    getLessonById,
    activeTab,
    onChangeActiveTab,
    setHasLessonOutcomesChanged,
    setHasRegistersFetch,
    setHasLessonsInfoLoading,
    setHasSaveLoading,
    setHasRegistersLoading,
    strings
  });

  useEffect(() => {
    // обнуляем все вкладки при смене урока
    if (id) {
      setActiveTabs('info');
      getLessonById(id);
      setRegisters([]);
      setOriginalLessonOutcomes([]);
    }
  }, [id]);

  useEffect(() => {
    if (tabLocation) {
      onChangeActiveTab(String(tabLocation));
    }
  }, []);

  if (hasNotFound) return <LessonNotFound />;
  if (hasForbidden) return <LessonForbidden />;

  return (
    <>
      {contextHolder}
      <Helmet>
        <title>{courseGroup?.groupName}</title>
      </Helmet>
      <LessonConfirm hasJournalModified={hasJournalModified} />
      <LessonContainer>
        <StyledContainer>
          <Spin spinning={hasLessonsInfoLoading}>
            {lessonInfo && (
              <Flex gap={20} align="center" vertical={isMobile}>
                <Flex vertical gap={20} align="center" justify="center">
                  <CalendarWidgetMemo
                    prevLessonId={prevLesson?.id}
                    nextLessonId={nextLesson?.id}
                    startAt={slot?.start}
                    endAt={slot?.end}
                  />
                  <Button type="primary" onClick={() => onSaveLesson(false)} loading={hasSaveLoading}>
                    {strings.SAVE_CHANGES}
                  </Button>
                </Flex>
                <LessonHeader />
              </Flex>
            )}
          </Spin>
        </StyledContainer>
        <Tabs activeKey={activeTab} onChange={onChangeActiveTab}>
          <TabPane key="info" tab={strings.INFO}>
            <Spin spinning={hasLessonsInfoLoading}>
              {lessonInfo && (
                <StyledContainer>
                  {slot?.room?.webLink && (
                    <>
                      <ZoomWidget room={slot.room} /> <Divider style={{ margin: '10px 0' }} />
                    </>
                  )}
                  <Form form={lessonInfoForm} layout="vertical">
                    <LessonInfoMemo lessonId={id} />
                  </Form>
                </StyledContainer>
              )}
            </Spin>
          </TabPane>
          <TabPane key="register" tab={strings.REGISTER}>
            <Spin spinning={hasRegistersLoading}>
              <RegisterMemo lessonId={id} />
            </Spin>
          </TabPane>
          {originalLessonOutcomes.isNotEmpty() && (
            <TabPane
              key="outcomes"
              tab={
                <Badge dot={outcomesFilled === false} offset={[10, 0]}>
                  <span>{strings.LESSON_OUTCOMES}</span>
                </Badge>
              }
            >
              {hasPrimaryOrSchool && <Legend />}
              <Form form={outcomesForm}>
                <LessonOutcomesMemo lessonId={id} hasSaveLoading={hasSaveLoading} />
              </Form>
            </TabPane>
          )}
        </Tabs>
      </LessonContainer>
    </>
  );
});
export default { Lesson };
