import dayjs from 'dayjs';
import { dateFormat, dateFormatForLesson } from 'helpers/dates';
import { Lesson } from 'types/education';

export const columnsAssessment = strings => {
  return [
    {
      title: strings.DATE,
      dataIndex: 'lesson',
      render: (lesson: Lesson) =>
        lesson?.startAt && dayjs(lesson.startAt).format(`${dateFormat} ${dateFormatForLesson}`)
    },
    {
      title: strings.SUBJECT,
      dataIndex: 'lesson',
      key: 'subject',
      render: (lesson: Lesson) => lesson?.course?.subject?.name
    },
    {
      title: strings.GROUP,
      dataIndex: 'lesson',
      key: 'group',
      render: (lesson: Lesson) => lesson?.course?.groupName
    },
    {
      title: strings.LESSON,
      dataIndex: 'lessonFinalMark',
      render: (lesson: string) => lesson?.toUpperCase()
    },
    {
      title: strings.HW,
      dataIndex: 'hwFinalMark',
      render: (hw: string) => hw?.toUpperCase()
    },
    {
      title: strings.EXPERT,
      dataIndex: 'expertFinalMark',
      render: (expert: string) => expert?.toUpperCase()
    },
    {
      title: strings.LANGUAGE,
      dataIndex: 'language'
    }
  ];
};

export default { columnsAssessment };
