import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select, Col, Row, Button, DatePicker } from 'antd';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { isEmpty, pickBy } from 'lodash';
import dayjs from 'dayjs';
import { dateFormat } from 'helpers/dates';
import { lessonsRegisterLogsEvents } from 'Global/constants';
import { LessonsRegistersLogsFilterParams } from 'types/logs';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { momentFormatValues } from 'helpers/momentFormatValues';
import { FilterButtons } from 'Global/components/FilterButtons';

const { Option } = Select;

interface FilterProps {
  form: WrappedFormUtils;
  getLessonsRegistersLogsList: (params?: LessonsRegistersLogsFilterParams) => Promise<any>;
}

/**
 * @description Filter LessonsRegistersLogs table
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const FilterTemplate = (props: FilterProps) => {
  const [strings] = useLanguageContext();
  const {
    form: { getFieldDecorator, validateFieldsAndScroll, resetFields },
    getLessonsRegistersLogsList
  } = props;

  const { locationSearchParams } = useLocationSearchParams();
  const { dateStart, dateEnd, event } = locationSearchParams || {};
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);

  /**
   * @description Form Submit
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values) => {
      if (isEmpty(errors)) {
        const filterParams = pickBy(momentFormatValues(values));
        try {
          setHasSubmitLoading(true);
          await getLessonsRegistersLogsList(filterParams);
        } finally {
          setHasSubmitLoading(false);
        }
      }
    });
  };

  /**
   * @description Очистка фильтра
   */
  const handleClear = async (): Promise<any> => {
    resetFields();
    await getLessonsRegistersLogsList();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={[10, 10]}>
        <Col lg={5}>
          {getFieldDecorator('dateStart', {
            initialValue: dateStart ? dayjs(dateStart) : null
          })(<DatePicker format={dateFormat} placeholder={strings.FROM} />)}
        </Col>
        <Col lg={5}>
          {getFieldDecorator('dateEnd', {
            initialValue: dateEnd ? dayjs(dateEnd) : null
          })(<DatePicker format={dateFormat} placeholder={strings.TILL} />)}
        </Col>
        <Col lg={5}>
          {getFieldDecorator('event', {
            initialValue: event && String(event)
          })(
            <Select placeholder={strings.ACTION}>
              <Option value={undefined}>&nbsp;</Option>
              {lessonsRegisterLogsEvents.map(type => (
                <Option key={type.value} value={type.value}>
                  {type.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <FilterButtons handleClear={handleClear} isSameRow={true} hasSubmitLoading={hasSubmitLoading} />
      </Row>
    </Form>
  );
};

export const Filter = Form.create<FilterProps>({})(FilterTemplate);
export default { Filter };
