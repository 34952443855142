import React, { useEffect, useState } from 'react';
import { CaretRightFilled, ClockCircleFilled } from '@ant-design/icons';
import { Row, Button, Col, Typography } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import styled from 'styled-components';
const { Text } = Typography;

const StIcon = styled(ClockCircleFilled)`
  color: #cc1e44;
  opacity: 0.6;
  font-size: 20px;
  &:hover {
    opacity: 1;
  }
`;

const StopSvg = ({ color }: { color: string }) => (
  <svg width="1em" height="1em" fill={color} aria-hidden="true" focusable="false" className="" viewBox="0 0 1024 1024">
    <path d="M864 64H160C107 64 64 107 64 160v704c0 53 43 96 96 96h704c53 0 96-43 96-96V160c0-53-43-96-96-96z"></path>
  </svg>
);

/**
 * @description Запись аудиокоммента в модальном окне фидбэка
 * @param {object} student
 * @return {React.ReactNode}
 */
export const AudioCommentary = (props: {
  defAudioValue?: string;
  setAudio: (value: File) => void;
  setHasRemoveAudio: (value: boolean) => void;
}) => {
  const [strings] = useLanguageContext();
  const { setAudio, defAudioValue, setHasRemoveAudio } = props;

  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>(null);
  const [hasRecording, setHasRecording] = useState<boolean>(false);
  const [previewAudio, setPreviewAudio] = useState<string>(null);
  const [minDuration, setMinDuration] = useState(0);
  const [secDuration, setSecDuration] = useState(0);
  const [storedTimer, setStoredTimer] = useState(null);

  /**
   * @description Обработчик audio
   * @param {File} file
   * @return {Promise<any>}
   **/
  const handleRecord = (): void => {
    if (!hasRecording) {
      mediaRecorder.start();
      startHandler();
    } else {
      mediaRecorder.stop();
    }
    setHasRecording(!hasRecording);
  };

  const onRecordingReady = async ({ data }) => {
    setHasRemoveAudio(false);
    setPreviewAudio(URL.createObjectURL(data));
    const file = new File([data], 'audioCommentForOffice', {
      type: data.type
    });
    setAudio(file);
  };

  const getDuration = val => {
    const duration = val < 10 ? '0' + val : val;
    return duration;
  };
  const getBtnText = () => {
    if (hasRecording) {
      return (
        <>
          <span>{`${getDuration(minDuration)}: ${getDuration(secDuration)}`}</span>
          <span>{strings.STOP_A_RECORD}</span>
        </>
      );
    } else {
      if (previewAudio) {
        return strings.REWRITE_THE_AUDIO;
      } else {
        return strings.START_A_RECORD;
      }
    }
  };

  const startHandler = () => {
    if (storedTimer) {
      clearInterval(storedTimer);
      setMinDuration(0);
      setSecDuration(0);
    }
    const newTimer = setInterval(() => {
      setSecDuration(prevSecs => {
        if (prevSecs === 60) {
          setMinDuration(prevMins => prevMins + 1);
          return 0;
        } else return prevSecs + 1;
      });
    }, 1000);

    setStoredTimer(newTimer);
  };

  const onRemoveAudio = (): void => {
    setHasRemoveAudio(true);
    setPreviewAudio(null);
    setAudio(null);
  };

  useEffect(() => {
    (async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true
        });
        if (stream) {
          const recorder = new MediaRecorder(stream);
          // listen to dataavailable, which gets triggered whenever we have
          // an audio blob available
          recorder.addEventListener('dataavailable', onRecordingReady);
          setMediaRecorder(recorder);
        }
      } catch {}
    })();
  }, []);
  useEffect(() => {
    setPreviewAudio(defAudioValue);
  }, [defAudioValue]);

  return (
    <Row style={{ marginBottom: 20 }}>
      <Text strong style={{ width: '100%' }}>
        {strings.AUDIO_COMMENTARY}
      </Text>
      <Row align="middle" gutter={[10, 10]}>
        <Col lg={8} xs={24}>
          <Button
            style={{
              display: 'flex',
              alignItems: 'center',
              minWidth: 220,
              gap: 10,
              color: ' #595959',
              margin: '8px 0',
              fontSize: 16
            }}
            onClick={handleRecord}
            size="large"
            disabled={!mediaRecorder}
          >
            {hasRecording ? (
              <StopSvg color="#cc1e44" />
            ) : (
              <span style={{ fontSize: 24 }}>
                <CaretRightFilled />
              </span>
            )}
            {getBtnText()}
          </Button>
        </Col>
        {previewAudio && (
          <Col lg={16} xs={24}>
            <Row align="middle" style={{ gap: 5 }}>
              <audio style={{ flex: 1 }} controls={true} src={previewAudio} />
              <StIcon onClick={onRemoveAudio} />
            </Row>
          </Col>
        )}
      </Row>
      {!mediaRecorder && (
        <span style={{ color: '#cc1e44' }}>{strings.GIVE_PERMISSION_TO_USE_A_MICROPHONE_TO_RECORD_AUDIO}</span>
      )}
    </Row>
  );
};
