import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Button } from 'antd';
import Helmet from 'react-helmet';
import { useLanguageContext } from 'contexts/LanguageContext';

export const OnlineActivities = () => {
  const [strings] = useLanguageContext();

  return (
    <>
      <Helmet>
        <title>{strings.ONLINE_ACTIVITIES}</title>
      </Helmet>
      <div style={{ padding: '40px 20px' }}>
        <Row
          justify="end"
          style={{
            marginBottom: '1rem'
          }}
        >
          <Link to="/online-activities/add">
            <Button type="primary">{strings.ADD}</Button>
          </Link>
        </Row>
        <iframe
          className="airtable-embed"
          src="https://airtable.com/embed/shrxawNfSK91JhjM1?backgroundColor=cyan&viewControls=on"
          frameBorder="0"
          width="100%"
          height="533"
          title="online-activities"
          style={{ background: 'transparent', border: '1px solid #ccc', height: '100vh' }}
        />
      </div>
    </>
  );
};

/**
 * @description Добавление online activity
 * @param props
 * @returns {React.ReactNode}
 */
export const AddOnlineActivities = props => {
  const { history } = props;

  return (
    <div style={{ padding: '40px 20px' }}>
      <Button
        type="primary"
        onClick={() => history.goBack()}
        style={{
          marginBottom: '1rem'
        }}
      >
        Back
      </Button>
      <iframe
        className="airtable-embed"
        src="https://airtable.com/embed/shrqMmKXiDdkBglbG"
        frameBorder="0"
        width="100%"
        height="533"
        title="online-activities"
        style={{ background: 'transparent', border: '1px solid #ccc', height: '100vh' }}
      />
    </div>
  );
};

/**
 * @description Homeworks
 * @param props
 * @returns {React.ReactNode}
 */
export const Homeworks = ({ location }) => {
  const isBasic = location.pathname.includes('basic');
  return (
    <>
      <Helmet>
        <title>AMI: Homework</title>
      </Helmet>
      <div style={{ padding: '40px 20px' }}>
        {isBasic && (
          <iframe
            className="airtable-embed"
            src="https://airtable.com/embed/shrokVdRFamwRCJRQ?backgroundColor=red&viewControls=on"
            frameBorder="0"
            width="100%"
            title="online-activities"
            style={{ background: 'transparent', border: '1px solid #ccc', height: '100vh' }}
          />
        )}
      </div>
    </>
  );
};

export default { OnlineActivities, AddOnlineActivities, Homeworks };
