import React, { useState } from 'react';
import { LoadingOutlined, LoginOutlined, PhoneOutlined } from '@ant-design/icons';
import { Input, Typography, Button, Spin, Flex, Form } from 'antd';
import { Help } from 'Global/modules/Auth/Help';
import { StForm } from 'Global/modules/Auth/styles';
import { useUserRepository } from 'repos/UserRepository';
import { useHistory } from 'react-router-dom';
import { useUserContext } from 'contexts/UserContext';
import { LoginByPhone } from './LoginByPhone';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Title, Paragraph } = Typography;
export const passwordRules = [
  {
    required: true,
    message: 'Please input your password!'
  },
  {
    min: 6,
    message: 'Password must be at least 6 characters'
  }
];
const Login = () => {
  const [strings] = useLanguageContext();
  const [form] = Form.useForm();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const userRepository = useUserRepository();
  const { push } = useHistory();
  const [user, setUser] = useUserContext();
  const [isPhoneLogin, setIsPhoneLogin] = useState<boolean>(false);

  const authTitle = 'Log in';
  const buttonTitle = 'Log in';
  const pass = 'Password';

  const onPhoneLoginClick = () => {
    setIsPhoneLogin(true);
  };

  const handleSubmit = async (values: { email: string; password: string }): Promise<any> => {
    try {
      setHasLoading(true);
      await userRepository.getAuthToken(values.email, values.password);
      setUser({ ...user, isLogin: true });
      push('/');
    } finally {
      setHasLoading(false);
    }
  };

  if (isPhoneLogin) {
    return <LoginByPhone setIsPhoneLogin={setIsPhoneLogin} />;
  }

  return (
    <div>
      {hasLoading ? (
        <StForm>
          <Flex align="center" justify="center" style={{ height: '100%' }}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} size="large">
              <Paragraph>Looks like it takes longer than it should</Paragraph>
            </Spin>
          </Flex>
        </StForm>
      ) : (
        <StForm>
          <Form layout="vertical" form={form} onFinish={handleSubmit}>
            <Title level={3}>{authTitle}</Title>
            <Form.Item
              name="email"
              label="Login"
              rules={[
                {
                  required: true,
                  message: 'Please input your login!'
                }
              ]}
            >
              <Input name="email" placeholder={strings.EMAIL} />
            </Form.Item>
            <Form.Item name="password" label={pass} rules={passwordRules}>
              <Input.Password name="password" placeholder={pass} />
            </Form.Item>
            <Flex gap={10} justify="space-between" style={{ paddingTop: 10 }}>
              <Button size="large" type="primary" icon={<LoginOutlined />} htmlType="submit">
                {buttonTitle}
              </Button>
              <Button size="large" onClick={onPhoneLoginClick} icon={<PhoneOutlined />} htmlType="submit">
                Log in by phone
              </Button>
            </Flex>
          </Form>
        </StForm>
      )}
      <Help />
    </div>
  );
};
export default Login;
