import React, { useState, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, Select, DatePicker, Button, Row, Col } from 'antd';
import { CourseGroup } from 'types/education';
import { dateFormat, reversedDateFormatForBackend } from 'helpers/dates';
import dayjs from 'dayjs';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { filterSelect } from 'helpers/filterSelect';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { Branch } from 'types';
import { useCoursesRepository } from 'repos/CoursesRepository';

const { Option } = Select;

interface AddToSupergroupProps extends FormComponentProps {
  onOk?: (obj: object) => void;
  onCancel?: () => void;
  hasOpen?: boolean;
  id: number;
}

/**
 * @description Добавление супергруппы
 * @param {AddToSupergroupProps} props
 * @return {React.ReactNode}
 */
export const AddToSupergroupTemplate = (props: AddToSupergroupProps) => {
  const [user] = useUserContext();
  const [strings] = useLanguageContext();
  const { branches } = useGlobalCollectionsContext();
  const coursesRepository = useCoursesRepository();

  const [selectedBranch, setSelectedBranch] = useState<number>(user?.branch?.id);
  const [superGroups, setSuperGroups] = useState<CourseGroup[]>([]);
  const [hasSuperGroupsLoading, setHasSuperGroupsLoading] = useState<boolean>(false);

  const {
    onOk,
    onCancel,
    id,
    hasOpen,
    form: { getFieldDecorator, validateFields, resetFields }
  } = props;

  /**
   * @description Получение групп студента по id. Разделение на актуальные и неактуальные группы.
   * @return {Promise<any>}
   * */
  const fetchGroups = async (branchId: number): Promise<any> => {
    resetFields(['group']);
    setHasSuperGroupsLoading(true);
    try {
      const { data } = await coursesRepository.getSuperGroups({ branch: branchId });
      setSuperGroups(data);
    } catch {
    } finally {
      setHasSuperGroupsLoading(false);
    }
  };

  /**
   * @description Отправка формы
   * @param {React.MouseEvent<HTMLElement, MouseEvent>} e
   */
  const handleSubmit = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const { date, group } = values;
        const backendFormatDate = dayjs(date).format(reversedDateFormatForBackend);
        onOk({ date: backendFormatDate, group, id });
      }
    });
  };

  /**
   * @description Кнопки для модалки
   */
  const footerButtons = [
    <Button onClick={onCancel} key="cancelBtn">
      {strings.CANCEL}
    </Button>,
    <Button type="primary" onClick={handleSubmit} key="addToGroup">
      {strings.ADD}
    </Button>
  ];

  useEffect(() => {
    fetchGroups(selectedBranch);
  }, [selectedBranch]); // eslint-disable-line

  return (
    <Modal
      title={strings.ADD_TO_A_SUPERGROUP}
      destroyOnClose={true}
      open={hasOpen}
      onCancel={onCancel}
      footer={footerButtons}
    >
      <Form layout="vertical">
        <Row gutter={2}>
          {branches.length > 1 && (
            <Col lg={12}>
              <MyFormItem label={strings.BRANCH}>
                <Select
                  defaultValue={user?.branch?.id}
                  placeholder={strings.BRANCH}
                  onChange={val => setSelectedBranch(Number(val))}
                >
                  {branches.map((branch: Branch) => (
                    <Option value={branch.id} key={branch.id}>
                      {branch.name}
                    </Option>
                  ))}
                </Select>
              </MyFormItem>
            </Col>
          )}
          <Col lg={12}>
            <MyFormItem label={strings.SUPERGROUP}>
              {getFieldDecorator('group', {
                rules: [{ required: true, message: strings.SELECT_A_SUPERGROUP }]
              })(
                <Select
                  loading={hasSuperGroupsLoading}
                  placeholder={strings.SEARCH_SUPERGROUP}
                  showSearch
                  filterOption={filterSelect}
                >
                  {superGroups.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              )}
            </MyFormItem>
          </Col>
          <Col lg={12}>
            <MyFormItem label={strings.DATE}>
              {getFieldDecorator('date', {
                initialValue: dayjs(),
                rules: [{ required: true, message: strings.SELECT_DATE }]
              })(<DatePicker placeholder={`${strings.SELECT_DATE}`} format={dateFormat} />)}
            </MyFormItem>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export const AddToSupergroup = Form.create<AddToSupergroupProps>({})(AddToSupergroupTemplate);

export default { AddToSupergroup };
