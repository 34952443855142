import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { MetaGoalItem } from 'types/courses';
import { useMetaGoalsRepository } from 'repos/MetaGoalsRepository';

const StyledLevel = styled.div`
  margin: 20px 0;
`;

export function MetaLevelInfo(props: { metaLevel: { id: number; name: string } }) {
  const {
    metaLevel: { id, name }
  } = props;
  const [metaGoals, setMetaGoals] = useState<MetaGoalItem[]>([]);
  const metaGoalsRepo = useMetaGoalsRepository();

  const fetchMetaGoals = async () => {
    try {
      const { data } = await metaGoalsRepo.getMetaLevelById(id);
      setMetaGoals(data.goals);
    } catch {
      setMetaGoals([]);
    }
  };

  useEffect(() => {
    if (id) {
      fetchMetaGoals();
    }
  }, [id]);

  return (
    <StyledLevel>
      <h3 style={{ paddingInlineStart: 12 }}>{name}</h3>
      <Row style={{ borderBottom: 'solid 1px #e8e8e8' }}>
        <Col lg={24}>
          {metaGoals.map(({ id, type, description }) => (
            <Row style={{ marginBottom: 12 }}>
              <Col lg={8}>
                <b>{type}</b>
              </Col>
              <Col lg={14}>{description}</Col>
            </Row>
          ))}
        </Col>
      </Row>
    </StyledLevel>
  );
}
