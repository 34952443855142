import React, { useEffect, useState } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { Layout, Tabs, Button, Spin, Modal, message, Tooltip, Flex } from 'antd';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';
import { BASE_FAMILIES } from 'Global/routes';
import Breadcrumbs from 'Global/components/Breadcrumbs';
import { Families } from 'api';
import { IStudent, Subject } from 'types';
import { useStudentRepository } from 'repos/StudentRepository';
import { MainInformation } from 'Admin/People/Families/Student/Main';
import { Groups } from 'Admin/People/Families/Student/Groups';
import { TeachingMaterials } from 'Admin/People/Families/Student/TeachingMaterials';
import { AcademicProgress } from 'Admin/People/Families/Student/AcademicProgress';
import { CollaborationHistory } from 'Admin/People/Families/Student/CollaborationHistory';
import { ProgressReport } from 'Admin/People/Families/Student/ProgressReport';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container } from 'Global/GlobalStyle';
import { useParentJournalRepository } from 'repos/ParentJournalRepository';
import { match } from 'react-router';
import { MetaGoals } from './MetaGoals';
import { LessonOutcomes } from './LessonOutcomes';
import { CopyOutlined, LoginOutlined } from '@ant-design/icons';
import { switchUserModal } from '../../../../helpers/switchUser';

interface ChildProps {
  studentId?: number;
  handleChangeTab?: (key: string) => void;
  isNoMargingSt?: boolean;
}

const { Content } = Layout;
const { TabPane } = Tabs;

export const Child = (props: ChildProps) => {
  const [strings] = useLanguageContext();
  const studentRepository = useStudentRepository();
  const [child, setChild] = useState<IStudent>();
  const [showChild, setShowChild] = useState<boolean>(true);
  const { studentId, handleChangeTab } = props;
  const [{ confirm }, contextHolder] = Modal.useModal();

  const {
    params: { userId }
  }: match<{ userId: string }> = useRouteMatch();

  const { goBack } = useHistory();
  const location = useLocation();

  const id = (userId && Number(userId)) || studentId;
  const [user] = useUserContext();
  const hasTeacher = user?.hasRoles?.teacher;
  const hasTopManager = user?.hasRoles?.topManager;
  const hasBranchDirector = user?.hasRoles?.branchDirector;
  const hasExtraAccess = hasTopManager || hasBranchDirector;
  const parentJournalRepository = useParentJournalRepository();
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const name = child?.user?.name || '';
  const surname = child?.user?.surname || '';
  const fullName = `${name} ${surname}`;
  const familyId = child?.familyId;
  const activeTab = queryString.parse(location?.search).tab;

  /**
   * @description Получение детей по id
   * @param {number} id
   * @return {Promise<any>}
   */
  const fetchChildById = async (id: number) => {
    setShowChild(false);
    try {
      const { data } = await studentRepository.getStudentById(id);
      setChild(data);
    } finally {
      setShowChild(true);
    }
  };
  /**
   * @description Получение предметов студента
   * @return {Promise<any>}
   */
  const getSubjectsByStudentId = async (): Promise<any> => {
    try {
      const { data } = await parentJournalRepository.getSubjects(id);
      if (hasTeacher) {
        const mySubjects = user?.mainSubjects.map(item => item.id);
        const sortedSubjects = [];
        data.forEach(item => {
          mySubjects.includes(item.id) ? sortedSubjects.unshift(item) : sortedSubjects.push(item);
        });
        setSubjects(sortedSubjects);
      } else {
        setSubjects(data);
      }
    } catch {
      setSubjects([]);
    }
  };

  // Удаление студента
  const deleteStudent = () => {
    confirm({
      title: strings.DO_YOU_WANT_TO_DELETE_THIS_STUDENT,
      okText: strings.DELETE,
      okType: 'danger',
      async onOk() {
        try {
          const { status } = await Families.deleteStudent(id);
          if (status === 200) {
            message.success(strings.STUDENT_DELETED_SUCCESSFULLY);
            goBack();
          }
        } catch {}
      }
    });
  };
  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(userId);
      message.success(strings.STUDENT_ID_COPIED_TO_CLIPBOARD);
    } catch {
      message.error(strings.FAILED_TO_COPY_STUDENT_ID);
    }
  };
  const getSubtitle = (
    <Flex gap={5} align="center">
      <span>{userId}</span>
      <Tooltip title={strings.COPY_STUDENT_ID}>
        <Button size="small" icon={<CopyOutlined />} onClick={handleCopy}></Button>
      </Tooltip>
      {child?.loginAs && hasExtraAccess && (
        <Tooltip title={`${strings.LOGIN_AS} ${fullName}`}>
          <Button size="small" icon={<LoginOutlined />} onClick={() => switchUserModal(child?.loginAs, confirm)} />
        </Tooltip>
      )}
    </Flex>
  );

  // Если юзера зашел в роли админа
  const adminTabs = [
    <TabPane tab={strings.GROUPS} key="groups">
      <Groups id={id} />
    </TabPane>,
    <TabPane tab={strings.TEACHING_MATERIALS} key="materials">
      <TeachingMaterials id={id} />
    </TabPane>,
    <TabPane tab={strings.PROGRESS_REPORTS} key="report">
      <ProgressReport studentId={id} />
    </TabPane>
  ];

  useEffect(() => {
    fetchChildById(id);
    getSubjectsByStudentId();
  }, [id]);

  return (
    <Spin spinning={!showChild} tip={strings.LOADING_STUDENT}>
      <Container>
        {contextHolder}
        {!hasTeacher && <Breadcrumbs location={location} />}
        <PageHeader
          style={{ padding: 0 }}
          onBack={goBack}
          title={fullName}
          subTitle={getSubtitle}
          extra={
            !hasTeacher && [
              <Button onClick={deleteStudent} danger style={{ marginInlineEnd: 8 }}>
                {strings.DELETE}
              </Button>,
              <Button type="primary">
                <Link to={`${BASE_FAMILIES}/${familyId}/student/${id}/edit`}>{strings.EDIT}</Link>
              </Button>
            ]
          }
        />
        <Content>
          <Tabs
            defaultActiveKey={String(activeTab) || 'main'}
            onChange={(key: string) => handleChangeTab && handleChangeTab(key)}
          >
            <TabPane tab={strings.MAIN_INFORMATION} key="main">
              <MainInformation child={child} />
            </TabPane>
            <TabPane tab={strings.COLLABORATION_HISTORY} key="history">
              <CollaborationHistory studentId={id} />
            </TabPane>
            {!hasTeacher && [...adminTabs]}
            <TabPane tab={strings.GOALS} key="golas">
              <Tabs>
                <TabPane tab={strings.META_GOALS} key="metagolas">
                  <MetaGoals studentId={id} />
                </TabPane>
                <TabPane tab={strings.LESSON_OUTCOMES} key="outcomes">
                  <LessonOutcomes studentId={id} />
                </TabPane>
              </Tabs>
            </TabPane>
            <TabPane tab={strings.ACADEMIC_PROGRESS} key="progress">
              <AcademicProgress studentId={id} subjects={subjects} />
            </TabPane>
          </Tabs>
        </Content>
      </Container>
    </Spin>
  );
};

export default { Child };
