import React from 'react';
import { Branch } from 'types';
import { Tag } from 'antd';
import { BASE_CLASSROOMS } from '../routes';
import { Link } from 'react-router-dom';
import { ClassroomItem } from 'types/education';
import { weekdays } from 'Global/constants';

export const getColumns = strings => [
  {
    title: strings.NAME,
    dataIndex: 'name',
    key: 'name',
    render: (name: string, row: ClassroomItem) => <Link to={`${BASE_CLASSROOMS}/${row.id}`}>{name}</Link>
  },
  {
    title: strings.COMMENT,
    dataIndex: 'comment',
    key: 'comment'
  },
  {
    title: strings.NUMBER_OF_SEATS,
    dataIndex: 'placesCount',
    key: 'placesCount'
  },
  {
    title: strings.COLOR,
    dataIndex: 'color',
    key: 'color',
    render: (color: string) => <Tag color={color}>{color}</Tag>
  },
  {
    title: 'Branch',
    dataIndex: 'branch',
    key: 'branch',
    render: (branch: Branch) => branch?.name
  }
];
export const getReservedColumns = strings => [
  {
    title: strings.WEEKDAY,
    dataIndex: 'weekday',
    key: 'weekday',
    render: (weekday: number) => weekdays[weekday]
  },
  {
    title: strings.START_TIME,
    dataIndex: 'start',
    key: 'start'
  },
  {
    title: strings.END_TIME,
    dataIndex: 'end',
    key: 'end'
  }
];

export default { getColumns, getReservedColumns };
