import React, { useContext, useState, useEffect } from 'react';
import * as Types from 'types';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import { Price, SupergroupGroup } from 'types/education';
import { TeacherTag } from 'types/Teachers/teacher';
import { useUserContext } from './UserContext';
import { useLangRepository } from 'repos/LangRepository';
import { ISubjectTranslation } from 'types/global';
import { IRepositoryProvider } from '../repos/RepositoryProvider';

const GlobalCollectionsContext = React.createContext(null);

interface IGlobalCollections {
  roles: Types.AdminRole[];
  branches: Types.Branch[];
  adminTags: Types.AdminTag[];
  teacherTags: TeacherTag[];
  familyTags: Types.FamilyTag[];
  parentTags: Types.FamilyTag[];
  ageTags: Types.AgeTag[];
  childStatuses: Types.ChildStatus[];
  familyStatuses: Types.FamilyStatus[];
  teacherStatuses: Types.TeacherStatus[];
  products: Types.Product[];
  stages: Types.Stage[];
  studyPrograms: Types.StudyProgram[];
  subjects: Types.Subject[];
  supergroups: SupergroupGroup[];
  teacherWages: Types.TeacherWageType[]; //
  trainings: Types.Training[];
  hashTags: Types.ITag[];
  priceGroups: Types.ITag[];
  subjectsTranslation?: ISubjectTranslation[]; //extra data
}

const defaultState: IGlobalCollections = {
  roles: [],
  branches: [],
  adminTags: [],
  teacherTags: [],
  familyTags: [],
  parentTags: [],
  ageTags: [],
  childStatuses: [],
  familyStatuses: [],
  teacherStatuses: [],
  products: [],
  stages: [],
  studyPrograms: [],
  subjects: [],
  supergroups: [],
  teacherWages: [],
  trainings: [],
  hashTags: [],
  priceGroups: []
};

export const ROLES_MAP = {
  ROLE_USER: 'User',
  ROLE_STUDENT: 'Student',
  ROLE_PARENT: 'Parent',
  ROLE_TEACHER: 'Teacher',
  ROLE_ADMIN: 'Admin',
  ROLE_TOP_MANAGER: 'Top manager',
  ROLE_BRANCH_DIRECTOR_OPERATION_DEPARTMENT: 'Branch Director of Operational Department',
  ROLE_BRANCH_MANAGER: 'Branch Director',
  ROLE_ACADEMIC_EXPERT: 'Academic Expert'
};

export const GlobalCollectionsContextProvider: React.FC<IRepositoryProvider> = ({ children }) => {
  const globalRequestsRepository = useGlobalRequestsRepository();
  const [globalCollections, setGlobalCollections] = useState<IGlobalCollections>(defaultState);
  const [hasLoadGlobalCollections, setHasLoadGlobalCollections] = useState<boolean>(false);
  const [subjectsTranslation, setSubjectsTranslation] = useState<ISubjectTranslation[]>([]);
  const provider = { ...globalCollections, hasLoadGlobalCollections, subjectsTranslation };
  const [user] = useUserContext();
  const hasParentOrStudent = user?.hasRoles?.student || user?.hasRoles?.parent;
  const langRepo = useLangRepository();

  const getSubjectsTranslation = async (): Promise<any> => {
    try {
      const { data } = await langRepo.getSubjectsTranslation();
      setSubjectsTranslation(data);
    } catch {
      setSubjectsTranslation([]);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (user && (user?.hasRoles?.admin || user?.hasRoles?.teacher)) {
          setHasLoadGlobalCollections(true);
          const { data } = await globalRequestsRepository.getGlobalCollections();
          const collections = {
            ...data,
            roles: data.adminRoles.filter(role => role.id !== 5)
          };

          setGlobalCollections(collections);
          setHasLoadGlobalCollections(false);
        }
      } catch (e) {
        setHasLoadGlobalCollections(false);
      }
    })();

    if (user && hasParentOrStudent) {
      getSubjectsTranslation();
    }
  }, [user]);

  return <GlobalCollectionsContext.Provider value={provider}>{children}</GlobalCollectionsContext.Provider>;
};

export const useGlobalCollectionsContext = () => {
  const service = useContext<IGlobalCollections>(GlobalCollectionsContext);

  if (!service) {
    throw new Error('Global collections context is unavailable');
  }

  return service;
};
