import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Select, Button, Tag, Flex } from 'antd';
import { User } from 'types/user';
import { Access as IAccess } from 'types/courses';
import { accessLevels } from 'Global/constants';

const { Option } = Select;

/**
 * @description Получить колонки для Original Courses access
 * @param {any} args
 */
export const getColumns = ({ handleDeleteAccessItem, handleChangeAccessLevel, strings }) => [
  {
    dataIndex: 'user',
    key: 'user',
    render: (user: User) => {
      const { name, surname, roles } = user;
      const hasAdmin = roles.includes('ROLE_ADMIN');
      return (
        <Flex gap={5}>
          {name} {surname} <Tag>{hasAdmin ? strings.ADMIN : strings.TEACHER}</Tag>
        </Flex>
      );
    }
  },
  {
    dataIndex: '',
    key: 'action',
    width: 300,
    render: (record: IAccess) => {
      const { user } = record;
      const hasAdmin = user?.roles.includes('ROLE_ADMIN');
      return (
        <Flex gap={10} align="center">
          <Select
            placeholder={strings.ACCESS_LEVEL}
            defaultValue={record.level}
            disabled={!hasAdmin}
            onChange={val => handleChangeAccessLevel(record.id, val)}
          >
            {accessLevels.map(level => (
              <Option key={level.value} value={level.value}>
                {level.name}
              </Option>
            ))}
          </Select>
          <Button icon={<DeleteOutlined />} danger onClick={() => handleDeleteAccessItem(record.id)} />
        </Flex>
      );
    }
  }
];
