import React, { useEffect, useState } from 'react';
import { Tabs, Spin, Alert } from 'antd';
import { StCard } from 'Global/GlobalStyle';
import { Subject } from 'types';
import { useParentJournalRepository } from 'repos/ParentJournalRepository';
import { SubjectTabsContent } from './SubjectTabsContent';
import { ReportingType } from 'types/global';
import { useLanguageContext } from 'contexts/LanguageContext';

const { TabPane } = Tabs;

export const SubjectTabs = ({ studentId, reportType }: { studentId: number; reportType?: ReportingType }) => {
  const [strings] = useLanguageContext();
  const parentJournalRepository = useParentJournalRepository();

  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [hasLoading, setHasLoading] = useState<boolean>(false);

  const fetchSubjects = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await parentJournalRepository.getSubjects(studentId);
      setSubjects(data);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    if (studentId) {
      fetchSubjects();
    }
  }, [studentId]);

  return (
    <StCard>
      <Spin spinning={hasLoading}>
        {subjects?.isEmpty() && <Alert message={strings.NO_ACTIVE_GROUPS} type="info" showIcon />}
        <Tabs>
          {subjects.map((item: Subject) => (
            <TabPane tab={item.name} key={String(item.id)}>
              <SubjectTabsContent studentId={studentId} subjectId={item.id} reportType={reportType} />
            </TabPane>
          ))}
        </Tabs>
      </Spin>
    </StCard>
  );
};
