import React, { useEffect, useMemo, useState } from 'react';
import { Row, Button, Col, Select, Form } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { ITeacherGetParams, TeacherTag } from 'types/Teachers/teacher';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { Branch, Subject, Status } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { TutorSelect } from 'Global/components/FormComponents';
import { useHistory } from 'react-router-dom';
import { FormItemWithoutMargin } from 'Global/styles';
import { Global } from 'api';

const { Option } = Select;

interface FilterProps {
  onFilter: (params?: ITeacherGetParams) => Promise<any>;
  defValues: ITeacherGetParams;
}

export const Filter: React.FC<FilterProps> = React.memo(({ onFilter, defValues }) => {
  const [strings] = useLanguageContext();
  const [form] = Form.useForm();
  const { push } = useHistory();
  const { locationSearchParams } = useLocationSearchParams();
  const { branch, status, tag, tutor, expert, mentor, subject, mainSubject } = locationSearchParams || {};

  const { branches, subjects, teacherStatuses } = useGlobalCollectionsContext();
  const [teachersTags, setTeachersTags] = useState<TeacherTag[]>([]);
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);

  const initValues: ITeacherGetParams = useMemo(
    () => ({
      branch,
      status,
      tag,
      tutor,
      expert,
      mentor,
      subject,
      mainSubject
    }),
    [locationSearchParams]
  );

  const getTeachersTags = async (): Promise<any> => {
    try {
      const { data } = await Global.getTeachersTags();
      setTeachersTags(data);
    } catch {}
  };

  const handleClear = (): void => {
    form.resetFields();
    push({ search: '' });
    onFilter(defValues);
  };

  const handleSubmit = async (values: ITeacherGetParams): Promise<any> => {
    try {
      setHasSubmitLoading(true);
      await onFilter(values);
    } finally {
      setHasSubmitLoading(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue(initValues);
  }, [initValues, form]);

  useEffect(() => {
    getTeachersTags();
  }, []);

  return (
    <Form form={form} onFinish={handleSubmit} layout="vertical">
      <Row gutter={[10, 10]}>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="branch">
            <Select placeholder={strings.BRANCH} allowClear>
              {branches.map((branch: Branch) => (
                <Option key={branch.id} value={branch.id}>
                  {branch.name}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="tutor">
            <TutorSelect placeholder={strings.ADM} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="expert">
            <TutorSelect mode="multiple" placeholder={strings.TEACHERS_EXPERT} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="mentor">
            <TutorSelect placeholder={strings.TEACHERS_MENTOR} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="tag">
            <Select maxTagCount="responsive" mode="multiple" placeholder={strings.TAG}>
              {teachersTags.map((teacherTag: TeacherTag) => (
                <Option key={teacherTag.id} value={Number(teacherTag.id)}>
                  {teacherTag.name}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="status">
            <Select mode="multiple" placeholder={strings.STATUS} maxTagCount="responsive">
              {teacherStatuses.map((status: Status) => (
                <Option key={status.id} value={status.id}>
                  {status.name}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="mainSubject">
            <Select mode="multiple" placeholder={strings.MAIN_SUBJECTS} maxTagCount="responsive">
              {subjects.map((subject: Subject) => (
                <Option key={subject.id} value={subject.id}>
                  {subject.name}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="subject">
            <Select mode="multiple" placeholder={strings.ADDITIONAL_SUBJECTS} maxTagCount="responsive">
              {subjects.map((subject: Subject) => (
                <Option key={subject.id} value={subject.id}>
                  {subject.name}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col xs={24} lg={24} style={{ display: 'flex', gap: 10, marginTop: 10 }}>
          <Button onClick={handleClear} size="large">
            {strings.CLEAR_FILTER}
          </Button>
          <Button type="primary" htmlType="submit" size="large" loading={hasSubmitLoading}>
            {strings.FILTER}
          </Button>
        </Col>
      </Row>
    </Form>
  );
});

export default { Filter };
