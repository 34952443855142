import { httpService } from 'api/core';

/**
 * @description Получить дисциплины учителей
 * @param {object} params
 * @returns {Promise<any>}
 */
export const getTeacherDisciplines = async (teacherId: number, params: object): Promise<any> =>
  httpService.get(`/api/report/teachers/${teacherId}/disciplines`, params);

/**
 * @description Получить комментарии, понравившиеся учителю
 * @param {object} params
 * @returns {Promise<any>}
 */
export const getLikedComments = async (teacherId: number, params: object): Promise<any> =>
  httpService.get(`/api/report/teachers/${teacherId}/comments/liked`, params);

/**
 * @description Получить комментарии, не понравившиеся учителю
 * @param {object} params
 * @returns {Promise<any>}
 */
export const getDislikedComments = async (teacherId: number, params?: object): Promise<any> =>
  httpService.get(`/api/report/teachers/${teacherId}/comments/disliked`, params);

/**
 * @description Получить комментарии родителей, не понравившиеся учителю
 * @param {object} params
 * @returns {Promise<any>}
 */
export const getParentDislikedComments = async (teacherId: number, params: object): Promise<any> =>
  httpService.get(`/api/report/teachers/${teacherId}/comments/parent-disliked`, params);

/**
 * @description Считать / не считать дисциплины
 * @param {object} params
 * @returns {Promise<any>}
 */
export const excludeDisciplines = async (
  id: number,
  lessonId: number,
  hasExclude: boolean,
  type: string
): Promise<any> =>
  httpService.post(`/api/report/teachers/${id}/lesson/${lessonId}/exclude-disciplines`, { value: hasExclude, type });

export default {
  getTeacherDisciplines,
  getLikedComments,
  getDislikedComments,
  getParentDislikedComments,
  excludeDisciplines
};
