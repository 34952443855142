export const BASE_GREEN = '#5cb85c';
export const BASE_GRAY = '#eeeeee';
export const BASE_GRAY_TEXT = '#acaaaa';

export interface themeObj {
  primaryColor: string;
  lightPrimaryColor: string;
}

export const lgegTheme: themeObj = {
  primaryColor: '#CC1E44',
  lightPrimaryColor: '#fd6f8e'
};

export const brunerTheme: themeObj = {
  primaryColor: '#262c98',
  lightPrimaryColor: '#FFCD74'
};

export const gymnasiumTheme: themeObj = {
  primaryColor: '#552b8e',
  lightPrimaryColor: '#e6556f'
};

export default { BASE_GREEN, BASE_GRAY };
