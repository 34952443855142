import React from 'react';
import { Card, Col } from 'antd';
import { dateFormat, dateTimeWithoutSecondFormat } from 'helpers/dates';
import dayjs from 'dayjs';
import {
  StatusChanged,
  InvoiceCreated,
  InvoiceStatusChanged,
  InvoiceCommented
} from 'types/Family/collaborationHistory';

interface ChroniclesItemProps {
  data: StatusChanged | InvoiceCreated | InvoiceStatusChanged | InvoiceCommented;
}

/**
 * @description Collaboration history item компонент
 * @param {object} props
 * @return {React.ReactNode}
 */
export const ChroniclesItem = (props: ChroniclesItemProps) => {
  // В InvoiceCommented приходит поле text, в остальных description
  const {
    // @ts-ignore
    data: { title, text, description, date }
  } = props;

  const formatDatetime = dayjs(date).format(dateTimeWithoutSecondFormat);
  return (
    <Col lg={24}>
      <Card title={title} extra={formatDatetime}>
        {text && <div dangerouslySetInnerHTML={{ __html: text }} />}
        {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
      </Card>
    </Col>
  );
};

export default { ChroniclesItem };
