import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Button, Modal, DatePicker, message } from 'antd';
import { Courses } from 'api';
import { dateFormatForBackend } from 'helpers/dates';
import { Dayjs } from 'dayjs';
import { GetListSupergroupsParams } from 'types/API/courses';
import { useLanguageContext } from 'contexts/LanguageContext';

interface DeleteStudentProps extends React.ComponentProps<any> {
  hideModal: () => void;
  isShow: boolean;
  id: number; // SuperGroup id
  studentId: number;
  currentBranchId: number;
  getSuperGroups: (params?: GetListSupergroupsParams) => Promise<any>;
}

/**
 * @description Модальное окно удаления студента
 * @param {DeleteStudentProps} props
 * @return {React.ReactNode}
 */
export const DeleteStudentModal = (props: DeleteStudentProps) => {
  const { hideModal, isShow, id, studentId, currentBranchId, getSuperGroups } = props;
  const [date, setDate] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [strings] = useLanguageContext();

  /**
   * @param {React.FormEvent} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
  };

  /**
   * @description Обработчик на выбор даты
   * @param {Dayjs} date
   * @return {void}
   */
  const handleDateChange = (date: Dayjs): void => {
    setDate(date.format(dateFormatForBackend));
  };

  /**
   * @description Удаление студента
   * @return {Promise<any>}
   */
  const handleDeleteStudent = async (): Promise<any> => {
    setLoading(true);
    await Courses.removeStudentFromSuperGroup(studentId, id, date)
      .then(() => {
        message.success(strings.STUDENT_SUCCESSFULLY_DELETED);
        hideModal();
        getSuperGroups({ branch: currentBranchId });
      })
      .catch(() => {
        message.error(strings.STUDENT_DELETE_ERROR);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      title={strings.DELETE_STUDENT}
      footer={[
        <Button key="back" onClick={hideModal}>
          {strings.CANCEL}
        </Button>,
        <Button
          key="deleteBtn"
          type="primary"
          htmlType="submit"
          onClick={handleDeleteStudent}
          loading={loading}
          disabled={loading}
        >
          {strings.DISENROLLMENT}
        </Button>
      ]}
      open={isShow}
      onCancel={hideModal}
    >
      <Form layout="vertical" onSubmit={handleSubmit}>
        <DatePicker onChange={handleDateChange} placeholder={strings.DISENROLLMENT_DATE} />
      </Form>
    </Modal>
  );
};

export default { DeleteStudentModal };
