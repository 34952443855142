import React, { useEffect, useState } from 'react';
import { CheckCircleTwoTone, MinusCircleTwoTone } from '@ant-design/icons';
import { Modal, Button, Table, Alert, Spin } from 'antd';
import { Discipline } from 'types/courses';
import { dateFormat } from 'helpers/dates';
import dayjs from 'dayjs';
import { useLanguageContext } from 'contexts/LanguageContext';
import { ErrorHandler } from 'helpers/services/ErrorHandler';
import { useLessonRepository } from 'repos/LessonRepository';

interface ModalDisciplinesProps {
  lessonId: number | string;
  hasShowDisciplinesModal: boolean;
  setHasShowDisciplinesModal: (hasShow: boolean) => void;
}

/**
 * @description Модалка с дисциплинами
 * @param {object} props
 * @return {React.ReactNode}
 */
export const ModalDisciplines = (props: ModalDisciplinesProps) => {
  const [strings] = useLanguageContext();
  const lessonRepository = useLessonRepository();
  const { hasShowDisciplinesModal, setHasShowDisciplinesModal, lessonId } = props;

  const [disciplines, setDisciplines] = useState<Discipline[]>([]);
  const [hasDisciplinesForbidden, setHasDisciplinesForbidden] = useState<boolean>(false);
  const [hasLoading, setHasLoading] = useState<boolean>(false);

  const fetchDisciplines = async (): Promise<any> => {
    setHasLoading(true);
    setHasDisciplinesForbidden(false);
    try {
      const { data } = await lessonRepository.getLessonDisciplines(lessonId);
      setDisciplines(data);
    } catch (err) {
      if (ErrorHandler.checkStatusCode(err, 403)) {
        setHasDisciplinesForbidden(true);
      }
    } finally {
      setHasLoading(false);
    }
  };
  const hideModal = () => setHasShowDisciplinesModal(false);

  useEffect(() => {
    if (lessonId && hasShowDisciplinesModal) {
      fetchDisciplines();
    }
  }, [hasShowDisciplinesModal]);

  // Формируем заголовки для таблицы на основе ключей с API
  const titles = {
    commentOffice: strings.COMMENTS_FOR_OFFICE,
    comment: strings.COMMENTARIES,
    grade: strings.HOMEWORK_GRADES,
    homeworkGet: strings.HOMEWORK,
    homeworkSet: strings.HOME_ASSIGNMENT,
    material: strings.CLASSROOM_MATERIALS,
    originalLesson: strings.LESSON_TOPIC_FROM_SYLLABUS,
    presence: strings.ATTENDANCE,
    theme: strings.LESSON_TOPIC,
    outcomesModular: strings.LESSON_OUTCOMES,
    commentModular: strings.MODULAR_COMMENT,
    metagoals: strings.META_GOALS
  };

  const columns = [
    {
      title: strings.TITLE,
      dataIndex: 'type',
      key: 'name',
      render: (type: string) => <div style={{ whiteSpace: 'nowrap' }}>{titles[type]}</div>
    },
    {
      title: strings.WEIGHT,
      dataIndex: 'weight',
      key: 'weight',
      render: (weight: number) => <div style={{ width: 70 }}>{weight}</div>
    },
    {
      title: strings.DONE_IN_TIME,
      dataIndex: 'done',
      key: 'done',
      width: 120,
      render: (done: boolean) =>
        done ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <MinusCircleTwoTone twoToneColor="#f5222d" />
    },
    {
      title: strings.FILLED,
      dataIndex: 'filled',
      key: 'filled',
      render: (filled: boolean) =>
        filled ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <MinusCircleTwoTone twoToneColor="#f5222d" />
    },
    {
      title: strings.EXPIRED,
      dataIndex: 'expired',
      key: 'expired',
      render: (expired: string) => expired && dayjs(expired).format(dateFormat)
    }
  ];

  return (
    <Modal
      title={strings.DISCIPLINES}
      destroyOnClose={true}
      open={hasShowDisciplinesModal}
      footer={[
        <Button type="primary" onClick={hideModal} key="btnOk">
          {strings.OK}
        </Button>
      ]}
      width={660}
      onCancel={hideModal}
    >
      <Spin spinning={hasLoading}>
        {hasDisciplinesForbidden ? (
          <Alert message={strings.NO_ACCESS} description={strings.UNFORTUNATELY_DISCIPLINES} type="warning" showIcon />
        ) : (
          <Table dataSource={disciplines} columns={columns} rowKey="id" pagination={false} />
        )}
      </Spin>
    </Modal>
  );
};

export default { ModalDisciplines };
