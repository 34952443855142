import React from 'react';
import { Subject } from '../../../../types';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';

import { GetFieldDecoratorOptions } from '@ant-design/compatible/lib/form/Form';
import { filterSelect } from 'helpers/filterSelect';

const { Option } = Select;

interface SubjectSelectProps {
  mode?: 'multiple' | 'tags';
  maxTagCount?: number;
  getFieldDecorator<T extends Object = {}>(
    id: keyof T,
    options?: GetFieldDecoratorOptions
  ): (node: React.ReactNode) => React.ReactNode;
  initialValue?: number | number[];
  isRequired?: boolean;
  label?: string;
  onChange?: (branchId: number) => void;
  name?: string;
}

export const SubjectSelect: React.FC<SubjectSelectProps> = ({
  mode,
  maxTagCount,
  getFieldDecorator,
  isRequired,
  initialValue,
  label,
  onChange,
  name
}) => {
  const [strings] = useLanguageContext();
  const { subjects } = useGlobalCollectionsContext();

  const decoratorFields = {
    initialValue: initialValue
  };

  if (isRequired) {
    decoratorFields['rules'] = [{ required: true, message: strings.SUBJECT_IS_REQUIRED }];
  }

  const select = (
    <>
      {getFieldDecorator(
        name || 'subject',
        decoratorFields
      )(
        <Select
          allowClear={true}
          mode={mode}
          maxTagCount={maxTagCount}
          placeholder={strings.SUBJECT}
          onChange={onChange}
          filterOption={filterSelect}
          showSearch={true}
        >
          <Option value="">&nbsp;</Option>
          {subjects
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((subject: Subject) => (
              <Option key={subject.id} value={subject.id}>
                {subject.name}
              </Option>
            ))}
        </Select>
      )}
    </>
  );

  if (label) {
    return <MyFormItem label={label}>{select}</MyFormItem>;
  }

  return select;
};
