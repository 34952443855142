import React from 'react';
import { Col, Modal, Row, Typography } from 'antd';
import { checkYoutubeUrl, dateFormat, getYoutubeVideoUrl } from 'helpers';
import dayjs from 'dayjs';
import { useLanguageContext } from 'contexts/LanguageContext';
import { TeacherGradingAssessment } from 'types/TeacherAccount/types';

const { Text, Paragraph } = Typography;
const transformText = text => text.replace(/\n/g, '<br />');

interface GradingModalProps {
  selectedGrading: TeacherGradingAssessment;
  hasShowModal: boolean;
  setHasShowModal: (flag: boolean) => void;
}
export const GradingModal = (props: GradingModalProps) => {
  const [strings] = useLanguageContext();
  const { selectedGrading, hasShowModal, setHasShowModal } = props;
  const { video, lesson, lessonAssessment, hwAssessment, expertAssessment, disciplineAssessment } = selectedGrading;

  return (
    (<Modal
      width={800}
      title={`${strings.LESSON_ASSESSMENT} - ${lesson.title}`}
      footer={null}
      open={hasShowModal}
      onCancel={() => setHasShowModal(false)}
    >
      <Row align="middle" className="no-padding" gutter={[16, 16]}>
        {video && (
          <Col lg={18} md={24} sm={24}>
            <iframe
              style={{ maxWidth: '100%' }}
              width="100%"
              height="315"
              src={checkYoutubeUrl(video) ? getYoutubeVideoUrl(video) : video}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="show"
            />
            <br />
            <Text strong type="danger" style={{ fontSize: '12px' }}>
              {strings.PLEASE_DO_NOT_SHARE_VIDEO}
            </Text>
          </Col>
        )}
        <Col lg={6} md={24} sm={24}>
          <Paragraph style={{ marginTop: '-15px' }}>
            {lesson.courseGroup.subject && (
              <Text>
                {strings.SUBJECT}: <strong>{lesson.courseGroup.subject.name}</strong>
                <br />
              </Text>
            )}
            {lesson.courseGroup && (
              <Text>
                {strings.GROUP}: <strong>{lesson.courseGroup.groupName}</strong>
                <br />
              </Text>
            )}
            {lesson.startAt && (
              <Text>
                {strings.DATE}: <strong>{dayjs(lesson.startAt).format(dateFormat)}</strong>
              </Text>
            )}
          </Paragraph>
        </Col>
      </Row>
      <Row style={{ paddingTop: '10px' }}>
        {lessonAssessment.lessonRecommendation && (
          <Paragraph>
            <Text strong>{strings.LESSON}</Text>
            <br />
            <div dangerouslySetInnerHTML={{ __html: transformText(lessonAssessment.lessonRecommendation) }} />
          </Paragraph>
        )}
        {hwAssessment.hwRecommendation && (
          <Paragraph>
            <Text strong>{strings.HW}</Text>
            <br />
            <div dangerouslySetInnerHTML={{ __html: transformText(hwAssessment.hwRecommendation) }} />
          </Paragraph>
        )}
        {expertAssessment.expertRecommendation && (
          <Paragraph>
            <Text strong>{strings.EXPERT}</Text>
            <br />
            <div dangerouslySetInnerHTML={{ __html: transformText(expertAssessment.expertRecommendation) }} />
          </Paragraph>
        )}
        {disciplineAssessment.disciplineSummary && (
          <Paragraph>
            <Text strong>{strings.DISCIPLINE}</Text>
            <br />
            <div dangerouslySetInnerHTML={{ __html: transformText(disciplineAssessment.disciplineSummary) }} />
          </Paragraph>
        )}
      </Row>
    </Modal>)
  );
};
