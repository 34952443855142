import React, { useEffect, useState } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, Spin } from 'antd';
import { IStudent, Supergroup } from 'types';
import { useMailingRepository } from 'repos/MailingRepository';
const { Panel } = Collapse;

/**
 * @description Супергруппы со списком студентов
 * @param {{ supergroups: Supergroup[] }} props
 * @return {React.ReactNode}
 */
export const CollapseSupergroups = ({ supergroups, mailingId }: { mailingId: number; supergroups: Supergroup[] }) => {
  return (
    <Collapse
      style={{ background: 'none' }}
      bordered={false}
      defaultActiveKey={['1']}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
    >
      {supergroups.map(item => (
        <Panel header={item.name} key={item.id} style={{ border: 0 }}>
          <StudentList supergroupId={item.id} mailingId={mailingId} />
        </Panel>
      ))}
    </Collapse>
  );
};

/**
 * @description Панель со списком студентов
 * @param {{ supergroup: Supergroup }} props
 * @return {React.ReactNode}
 */
export const StudentList = ({ supergroupId, mailingId }: { supergroupId: number; mailingId: number }) => {
  const [studentList, setStudentList] = useState<IStudent[]>([]);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const mailingRepository = useMailingRepository();

  /**
   * @description Список студентов
   * @return {Promise<any>}
   */
  const getStudentRecipients = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await mailingRepository.getStudentsBySupergroup(mailingId, supergroupId);
      setStudentList(data);
    } catch {
      setStudentList([]);
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    getStudentRecipients();
  }, []);

  return (
    <Spin spinning={hasLoading}>
      <ul className="list-style-type-none">
        {studentList.map(item => (
          <li>
            {item?.user?.surname} {item?.user?.name}
          </li>
        ))}
      </ul>
    </Spin>
  );
};

export default { CollapseSupergroups };
