import styled from 'styled-components';

export const StForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 420px;
  padding: 30px 45px;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  text-align: left;
  box-shadow: 0px 3px 26px -11px rgba(0, 0, 0, 0.25);

  @media (min-width: 768px) {
    margin: 0;
  }
`;

export const ForgotPassword = styled.div`
  margin-top: 20px;
  font-size: 16px;
  color: #333;
`;

export const AuthSt = styled.div`
  display: flex;
  align-items: center;
  height: max-content;
  @media (min-width: 1100px) {
    height: 100vh;
  }
`;

export const Help = styled.div`
  max-width: 100%;
  text-align: left;
  border-radius: 3px;
  padding: 0 20px;
  margin-inline-start: 20px;
  margin-top: 25px;
`;

export const StFallback = styled.div`
  margin: 55px 0;
  text-align: center;
  p {
    font-size: 18px;
    font-weight: 600;
  }
  .ant-spin {
    margin-bottom: 25px;
  }
`;

export const SityItem = styled.div``;

export const Sity = styled.span`
  font-size: 16px;
  color: #333;
`;

export const Phone = styled.a``;

export default {
  StForm,
  ForgotPassword,
  Help,
  SityItem,
  Sity,
  Phone,
  AuthSt,
  StFallback
};
