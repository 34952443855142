import * as educationRoutes from 'Admin/Education/routes';
import {
  TeachingMaterials,
  EditTeachingMaterials,
  AddTeachingMaterial,
  TeachingMaterial,
  Materials,
  LessonTopic,
  Training,
  TrainingItem,
  TrainingFormEdit,
  TrainingFormAdd,
  SourceReference,
  SourceReferenceEdit,
  SourceReferenceFull,
  AssessmentStatuses,
  AssessmentStatusForm,
  Classrooms,
  ClassroomForm,
  ClassroomView,
  Stages,
  StageForm,
  Subjects,
  SubjectForm,
  Experts
} from 'Admin/Education';

export const education = [
  {
    path: educationRoutes.BASE_TEACHING_MATERIALS,
    component: TeachingMaterials,
    exact: true
  },
  {
    path: `${educationRoutes.BASE_TEACHING_MATERIALS}/:id/edit`,
    component: EditTeachingMaterials,
    exact: true
  },
  {
    path: `${educationRoutes.BASE_TEACHING_MATERIALS}/new`,
    component: AddTeachingMaterial,
    exact: true
  },
  {
    path: `${educationRoutes.BASE_TEACHING_MATERIALS}/:id`,
    component: TeachingMaterial,
    exact: true
  },
  {
    path: educationRoutes.BASE_MATERIALS,
    component: Materials,
    exact: true
  },
  {
    path: `${educationRoutes.BASE_LESSON_TOPIC}/:id`,
    component: LessonTopic,
    exact: true
  },
  {
    path: educationRoutes.BASE_TRAININGS,
    component: Training,
    exact: true
  },
  {
    path: `${educationRoutes.TRAINING_ITEM}`,
    component: TrainingItem,
    exact: true
  },
  {
    path: `${educationRoutes.TRAINING_EDIT}`,
    component: TrainingFormEdit,
    exact: true
  },
  {
    path: `${educationRoutes.TRAINING_NEW}`,
    component: TrainingFormAdd,
    exact: true
  },
  {
    path: educationRoutes.BASE_SOURCE_OF_REFERENCE,
    component: SourceReference,
    exact: true
  },
  {
    path: educationRoutes.SOURCE_OF_REFERENCE_EDIT,
    component: SourceReferenceEdit,
    exact: true
  },
  {
    path: educationRoutes.SOURCE_OF_REFERENCE_FULL,
    component: SourceReferenceFull,
    exact: true
  },
  {
    path: educationRoutes.BASE_ASSESSMENT_STATUSES,
    component: AssessmentStatuses,
    exact: true
  },
  {
    path: educationRoutes.ASSESSMENT_STATUSES_NEW,
    component: AssessmentStatusForm,
    exact: true
  },
  {
    path: educationRoutes.ASSESSMENT_STATUSES_EDIT,
    component: AssessmentStatusForm,
    exact: true
  },
  {
    path: educationRoutes.BASE_EXPERTS,
    component: Experts,
    exact: true
  },
  {
    path: educationRoutes.BASE_CLASSROOMS,
    component: Classrooms,
    exact: true
  },
  {
    path: educationRoutes.CLASSROOMS_NEW,
    component: ClassroomForm,
    exact: true
  },
  {
    path: educationRoutes.CLASSROOMS_EDIT,
    component: ClassroomForm,
    exact: true
  },
  {
    path: educationRoutes.CLASSROOMS_VIEW,
    component: ClassroomView,
    exact: true
  },
  {
    path: educationRoutes.BASE_SUBJECTS,
    component: Subjects,
    exact: true
  },
  {
    path: educationRoutes.SUBJECT_NEW,
    component: SubjectForm,
    exact: true
  },
  {
    path: educationRoutes.SUBJECT_EDIT,
    component: SubjectForm,
    exact: true
  },
  {
    path: educationRoutes.BASE_STAGES,
    component: Stages,
    exact: true
  },
  {
    path: educationRoutes.STAGES_NEW,
    component: StageForm,
    exact: true
  },
  {
    path: educationRoutes.STAGES_EDIT,
    component: StageForm,
    exact: true
  }
];

export default { education };
