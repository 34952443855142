import React from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Button, Col, Radio, Input, Row, message } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { FeedbackAssessment as IFeedbackAssessment } from 'types/accessment';
import { isEmpty, toNumber } from 'lodash';
import { scrollTop } from 'helpers/scroll';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useAssessmentsRepository } from 'repos/AssessmentsRepository';

interface FeedbackManagementProps extends FormComponentProps {
  feedbackAssessment: IFeedbackAssessment;
}

/**
 * @description FeedbackManagement component
 * @param {object} props
 * @return {React.ReactNode}
 */
const FeedbackManagementTemplate = (props: FeedbackManagementProps) => {
  const [strings] = useLanguageContext();
  const assessmentsRepository = useAssessmentsRepository();
  const {
    form: { getFieldDecorator, getFieldsValue, validateFieldsAndScroll },
    feedbackAssessment: {
      type,
      teacherGotFeedback,
      teacherRepliedToFeedbackComment,
      teacherRepliedToFeedback,
      reportedToCollabhistory,
      visibleForTeacher,
      id
    }
  } = props;

  /**
   * @description Обработчик на submit формы
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values) => {
      message.loading(strings.FEEDBACK_MANAGEMENT_UPDATING, 1);
      if (isEmpty(errors)) {
        try {
          await assessmentsRepository.editAssessmentByLessonId(id, { type, ...values });
          message.success(strings.FEEDBACK_MANAGEMENT_UPDATE);
          scrollTop();
        } catch {}
      }
    });
  };

  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Row>
        <Col xs={24} lg={12}>
          <MyFormItem label={strings.TEACHER_GOT_FEEDBACK}>
            {getFieldDecorator('teacherGotFeedback', {
              initialValue: toNumber(teacherGotFeedback),
              rules: [{ required: false }]
            })(
              <Radio.Group>
                <Radio.Button value={1}>{strings.YES}</Radio.Button>
                <Radio.Button value={0}>{strings.NO}</Radio.Button>
              </Radio.Group>
            )}
          </MyFormItem>
        </Col>
      </Row>

      <Row>
        <Col xs={24} lg={12}>
          <MyFormItem label={strings.TEACHER_REPLIED_TO_FEEDBACK}>
            {getFieldDecorator('teacherRepliedToFeedback', {
              initialValue: toNumber(teacherRepliedToFeedback),
              rules: [{ required: false }]
            })(
              <Radio.Group>
                <Radio.Button value={1}>{strings.YES}</Radio.Button>
                <Radio.Button value={0}>{strings.NO}</Radio.Button>
              </Radio.Group>
            )}
          </MyFormItem>
        </Col>
        <Col xs={24} lg={12}>
          <MyFormItem label={strings.COMMENT}>
            {getFieldDecorator('teacherRepliedToFeedbackComment', {
              initialValue: teacherRepliedToFeedbackComment,
              rules: [{ required: false }]
            })(<Input.TextArea placeholder={strings.COMMENT} />)}
          </MyFormItem>
        </Col>
      </Row>

      <Row>
        <Col xs={24} lg={12}>
          <MyFormItem label={strings.REPORTED_TO_COLLABHISTORY}>
            {getFieldDecorator('reportedToCollabhistory', {
              initialValue: toNumber(reportedToCollabhistory),
              rules: [{ required: false }]
            })(
              <Radio.Group>
                <Radio.Button value={1}>{strings.YES}</Radio.Button>
                <Radio.Button value={0}>{strings.NO}</Radio.Button>
              </Radio.Group>
            )}
          </MyFormItem>
        </Col>
      </Row>

      <Row>
        <Col xs={24} lg={12}>
          <MyFormItem label={strings.VISIBLE_TO_TEACHER}>
            {getFieldDecorator('visibleForTeacher', {
              initialValue: toNumber(visibleForTeacher),
              rules: [{ required: false }]
            })(
              <Radio.Group>
                <Radio.Button value={1}>{strings.YES}</Radio.Button>
                <Radio.Button value={0}>{strings.NO}</Radio.Button>
              </Radio.Group>
            )}
          </MyFormItem>
        </Col>
      </Row>

      <Row style={{ marginTop: '20px' }}>
        <Col xs={24} lg={24}>
          <Button type="primary" size="large" htmlType="submit">
            {strings.SAVE}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export const FeedbackManagement = Form.create<FeedbackManagementProps>({})(FeedbackManagementTemplate);

export default { FeedbackManagement };
