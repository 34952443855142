import React from 'react';
import styled from 'styled-components';
import { Tag, Tooltip, Button, Row } from 'antd';
import { Link } from 'react-router-dom';
import { BASE_PROGRESS_REPORT } from '../routes';
import { ReportTrimester, ProgressReport, ReportStatus } from 'types/Reports';
import { Group } from 'types/education';
import { Teacher } from 'types/Teachers/teacher';
import { BASE_COURSE_GROUP } from 'Admin/Courses/routes';
import {
  supergroupsStatusesMap,
  supergroupsStatusesColorMap,
  READY_FOR_SENDING_STATUS,
  PENDING_STATUS,
  SENT_STATUS
} from './constants';

const StyledStatusColor = styled.div<{ color: string }>`
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: ${({ color }) => color};
  margin-inline-end: 8px;
`;

const StyledStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const getActiveStyles = (isActive: boolean) => ({ color: isActive ? '#333' : '#999', minWidth: 200 });

const getStatusColor = (status: string) => {
  switch (status) {
    case 'approved':
      return '#87d068';
    case 'filled':
      return '#2db7f5';
    case 'rejected':
    case 'new':
      return '#f50';
  }
};

export const getColumns = (statuses: ReportStatus[], strings) => [
  {
    title: strings.SEMESTER,
    dataIndex: 'trimester',
    render: (trimester: ReportTrimester, report: ProgressReport) => (
      <p style={getActiveStyles(report.active)}>{trimester?.name}</p>
    )
  },
  {
    title: strings.GROUP_NUMBER,
    dataIndex: 'group',
    key: 'groupNumber',
    render: (group: Group) => (
      <Link target="_blank" to={`${BASE_COURSE_GROUP}/${group?.id}`}>
        {group?.groupName}
      </Link>
    )
  },
  {
    title: strings.TITLE,
    dataIndex: 'name',
    render: (name: string, report: ProgressReport) => (
      <Link to={`${BASE_PROGRESS_REPORT}/edit/${report?.id}`}>{name}</Link>
    )
  },
  {
    title: strings.THE_TEACHER_WHO_HAS_FILLED_IN_THE_JOURNAL,
    dataIndex: 'teacher',
    width: 170,
    render: (teacher: Teacher, report: ProgressReport) =>
      teacher && (
        <div style={{ width: 170, ...getActiveStyles(report.active) }}>{`${teacher?.user?.name ?? ''} ${
          teacher?.user?.surname ?? ''
        }`}</div>
      )
  },
  {
    title: strings.STATUS,
    dataIndex: 'status',
    render: (status: string) => {
      const reportStatus = statuses.find((item: ReportStatus) => item.value === status);
      return reportStatus && <Tag color={getStatusColor(reportStatus.value)}>{reportStatus.text}</Tag>;
    }
  }
];

export const getColumnsForTutors = onSendEmails => [
  {
    title: 'Supergroup',
    dataIndex: 'supergroup',
    key: 'supergroup',
    render: ({ name }) => <p>{name}</p>
  },
  {
    title: 'Approved reports',
    dataIndex: 'approved',
    key: 'approved',
    render: (approved, { total }) => (
      <p>
        {approved} / {total}
      </p>
    )
  },
  {
    title: 'Available to parents',
    dataIndex: 'status',
    key: 'status',
    render: status => (
      <StyledStatus>
        <StyledStatusColor color={supergroupsStatusesColorMap.get(status)} />
        {supergroupsStatusesMap.get(status)}
      </StyledStatus>
    )
  },
  {
    title: '',
    dataIndex: 'status',
    key: 'button',
    render: (status, { supergroup }) => {
      const getTooltipText = () => {
        switch (status) {
          case PENDING_STATUS:
            return 'All reports should be approved before sending';
          case SENT_STATUS:
            return 'You have sent all the approved reports';
        }
      };

      const handleClick = () => onSendEmails([supergroup.id]);

      return (
        <Tooltip title={getTooltipText()}>
          <Button type="primary" onClick={handleClick} disabled={status !== READY_FOR_SENDING_STATUS}>
            Send email to parents
          </Button>
        </Tooltip>
      );
    }
  }
];

export default { getColumns };
