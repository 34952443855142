import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { HeartOutlined, MessageOutlined, ProfileOutlined } from '@ant-design/icons';
import { Row, Col, Skeleton } from 'antd';
import performicaLOGO from 'img/performicaLOGO.png';
import { getSubtractDate } from 'helpers/getSubstractDate';
import ExternalCodeService from 'helpers/services/ExternalCodeService';
import ym from 'react-yandex-metrika';
import { useUserContext } from 'contexts/UserContext';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import { useLanguageContext } from 'contexts/LanguageContext';

const StyledWidget = styled(Row)`
  padding: 0 20px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 46px -15px rgba(0, 0, 0, 0.12);
  background: #fff;
  border-radius: 5px;
  width: 100%;
  span {
    font-size: 16px;
    padding-inline-start: 10px;
  }
`;

const StyledOrgWidget = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const StyledIcon = styled.div`
  padding: 10px 0;
`;

type Surveys = {
  new_surveys: number;
  outstanding_surveys: number;
  days_after_deadline: number;
};

type Comments = {
  new_comments: number;
  total_comments: number;
};

type Praises = {
  new_praises: number;
  total_praises: number;
};

const externalCodeService = new ExternalCodeService();

const MY_SURVEYS_LINK = 'https://lg.org1.io/mysurveys/';
const ORG1_LINK = 'https://lg.org1.io';
const PERFORMICA_LINK = 'https://lg.performi.ca';

/**
 * @description Виджет ORG1 для ЛК учителя и админа
 * @return {React.ReactNode}
 */
export const OrgWidget = () => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const [hasLoadData, setHasLoadData] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [surveys, setSurveys] = useState<Surveys>({
    new_surveys: null,
    outstanding_surveys: null,
    days_after_deadline: null
  });
  const [comments, setComments] = useState<Comments>({ new_comments: null, total_comments: null });
  const [praises, setPraises] = useState<Praises>({ new_praises: null, total_praises: null });

  const globalRequestsRepository = useGlobalRequestsRepository();

  const getOrgData = async () => {
    try {
      setHasLoadData(true);
      const {
        data: { feedback, praises, outstanding }
      } = await globalRequestsRepository.getOrgOneData();
      setSurveys(outstanding);
      setComments(feedback);
      setPraises(praises);
      setHasError(false);
    } catch {
      setHasError(true);
    } finally {
      setHasLoadData(false);
    }
  };

  const { outstanding_surveys, days_after_deadline } = surveys;
  const { new_praises } = praises;
  const { new_comments } = comments;

  useEffect(() => {
    if (user?.org1Id) {
      getOrgData();
    }
  }, [user?.org1Id]);

  const iconStyle = {
    fontSize: 18
  };

  const handleYandexReachGoal = () => {
    if (!externalCodeService.isLocalEnvironment()) {
      ym('reachGoal', 'org1Click');
    }
  };

  if (hasLoadData) {
    return <Skeleton active={true} />;
  }

  if (user?.org1Id) {
    return !hasError ? (
      <StyledWidget align="middle" justify="space-between">
        <a href={PERFORMICA_LINK} target="_blank">
          <img src={performicaLOGO} style={{ width: 100 }} onClick={handleYandexReachGoal} />
        </a>
        <StyledIcon>
          <ProfileOutlined style={iconStyle} />
          <span>
            {strings.OUTSTANDING_SURVEYS}
            <a href={MY_SURVEYS_LINK} target="_blank" style={{ marginInlineStart: 5 }} onClick={handleYandexReachGoal}>
              {outstanding_surveys}
            </a>
            {days_after_deadline && days_after_deadline !== 0 && (
              <span style={{ color: '#f5222d', marginInlineStart: 10, marginBottom: 0 }}>
                {strings.DUE} {getSubtractDate(days_after_deadline, 'days', 'YYYY.MM.DD')}
              </span>
            )}
          </span>
        </StyledIcon>
        <StyledIcon>
          <HeartOutlined style={iconStyle} />
          <span>
            {strings.NEW_PRAISES}
            <a
              onClick={handleYandexReachGoal}
              target="_blank"
              href={`https://lg.org1.io/user/${user?.org1Id}/dashboard/#praises`}
              style={{ marginInlineStart: 5 }}
            >
              {new_praises}
            </a>
          </span>
        </StyledIcon>
        <StyledIcon>
          <MessageOutlined style={iconStyle} />
          <span>
            {strings.NEW_COMMENTS}
            <a
              onClick={handleYandexReachGoal}
              target="_blank"
              href={`https://lg.org1.io/user/${user?.org1Id}/dashboard/#peer-feedback`}
              style={{ marginInlineStart: 5 }}
            >
              {new_comments}
            </a>
          </span>
        </StyledIcon>
      </StyledWidget>
    ) : (
      <StyledWidget>
        <div style={{ textAlign: 'center', padding: 5 }}>
          {strings.SORRY_SERVICE_PERFOMICA_TEMPORARILY_UNAVAILABLE_PLEASE_TRY_LATER_AGAIN}
        </div>
      </StyledWidget>
    );
  }

  return null;
};
