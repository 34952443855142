import React, { useState, useEffect } from 'react';
import { Select, Spin } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import debounce from 'lodash/debounce';
import { Group } from 'types/education';
import { OriginalCourse } from 'types/courses';
import { useCoursesRepository } from 'repos/CoursesRepository';

const { Option } = Select;

type AsyncSelectProps = {
  value?: number;
  placeholder?: string;
  onChange?: (value: number) => void;
  initOriginalCourse?: number;
  mode?: 'multiple' | 'tags';
};

const OriginalCourseSelect: React.FC<AsyncSelectProps> = ({ value, placeholder, onChange, initOriginalCourse }) => {
  const [strings] = useLanguageContext();
  const coursesRepository = useCoursesRepository();
  const [searchValue, setSearchValue] = useState<string>('');
  const [typing, setTyping] = useState<Boolean>(false);
  const [loadingOriginalCourses, setLoadingOriginalCourses] = useState<boolean>(false);
  const [originalCourses, setOriginalCourses] = useState<OriginalCourse[]>([]);

  const fetchOriginalCourses = async (course: string) => {
    setSearchValue(course);
    if (course) {
      try {
        setLoadingOriginalCourses(true);
        const params = { title: course, isActive: 1 };
        const {
          data: { items }
        } = await coursesRepository.getOriginalCourses(params);
        setOriginalCourses(items);
      } catch {
        setOriginalCourses([]);
      } finally {
        setLoadingOriginalCourses(false);
      }
    }
  };

  const getOriginalCourseById = async (id: string | number): Promise<any> => {
    try {
      const { data } = await coursesRepository.getOriginalCourseById(id);
      setOriginalCourses([data]);
    } catch {}
  };

  useEffect(() => {
    if (initOriginalCourse) {
      getOriginalCourseById(initOriginalCourse);
    }
  }, [initOriginalCourse]);

  useEffect(() => {
    if (searchValue) {
      setTyping(true);
    } else {
      setTyping(false);
    }
  }, [searchValue]);

  return (
    <Select
      showSearch
      allowClear
      value={value}
      onChange={val => onChange(val)}
      placeholder={placeholder}
      defaultActiveFirstOption={false}
      filterOption={false}
      onSearch={debounce(fetchOriginalCourses, 800)}
      style={{ width: '100%' }}
      notFoundContent={
        loadingOriginalCourses ? <Spin size="small" /> : <p>{!typing ? strings.START_TYPING : strings.NOT_FOUND}</p>
      }
    >
      {originalCourses.map((course: Group) => (
        <Option value={course.id} key={course.id}>
          {course.name}
        </Option>
      ))}
    </Select>
  );
};

export default OriginalCourseSelect;
