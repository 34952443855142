import React, { useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Row, Button } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';

export const ShowMoreText = ({
  text,
  lettersCount,
  action,
  id,
  hasDangerouslySetInnerHTML
}: {
  text: string;
  lettersCount?: number;
  action?: (id: number) => void;
  id?: number;
  hasDangerouslySetInnerHTML?: boolean;
}) => {
  const defLettersCount = 15;
  const [hasShowFullText, setHasShowFullText] = useState<boolean>(false);
  const [strings] = useLanguageContext();
  const briefText = text.slice(0, lettersCount ? lettersCount : defLettersCount);

  return (
    <Row align="middle">
      {!hasShowFullText ? (
        <p style={{ flex: 1, paddingInlineEnd: 10 }}>
          {hasDangerouslySetInnerHTML ? <div dangerouslySetInnerHTML={{ __html: briefText }} /> : `${briefText}...`}
          <Button
            type="link"
            style={{ padding: '5px' }}
            onClick={() => {
              setHasShowFullText(true);
              if (action) {
                action(id);
              }
            }}
          >
            {strings.SEE_MORE}
            <DownOutlined />
          </Button>
        </p>
      ) : (
        <p style={{ flex: 1, paddingInlineEnd: 10 }}>
          {hasDangerouslySetInnerHTML ? <div dangerouslySetInnerHTML={{ __html: text }} /> : text}
          <Button type="link" style={{ padding: '5px' }} onClick={() => setHasShowFullText(false)}>
            <UpOutlined />
          </Button>
        </p>
      )}
    </Row>
  );
};

export default { ShowMoreText };
