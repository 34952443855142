import React, { useState, useEffect } from 'react';
import { match } from 'react-router';
import { Col, Spin, Button, message, Input, Select, Row, Checkbox, Form, ColorPicker } from 'antd';
import { Branch } from 'types';
import { useUserContext } from 'contexts/UserContext';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useSupergroupRepository } from 'repos/SupergroupRepository';

const { Option } = Select;

/**
 * @description Редактирование супергруппы
 * @return {React.ReactNode}
 */
export const SupergroupForm = () => {
  const [strings] = useLanguageContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [colour, setColour] = useState<string>(null);
  const { goBack } = useHistory();
  const [form] = Form.useForm();

  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();

  const supergroupRepository = useSupergroupRepository();

  const { branches } = useGlobalCollectionsContext();
  const [user] = useUserContext();
  const hasTopManager = user?.hasRoles?.topManager;

  /**
   * @description Получение супергруппы по id
   * @param {number} groupId
   * @return {Promise<any>}
   */
  const getSupergroupById = async (): Promise<any> => {
    setLoading(true);
    try {
      const { data } = await supergroupRepository.getSupergroupById(id);
      const { name, isActive, branch, colour } = data;
      const initVal = { name, isActive, branch: branch.id };
      form.setFieldsValue(initVal);
      setColour(colour);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (params): Promise<any> => {
    setEditLoading(true);
    try {
      await supergroupRepository.editSupergroup(id, { ...params, colour: colour });
      message.success(strings.SUPERGROUP_EDITED_SUCCESSFULLY);
    } catch {
    } finally {
      setEditLoading(false);
    }
  };

  useEffect(() => {
    getSupergroupById();
  }, [id]);

  return (
    <Container>
      <StPageHeader onBack={goBack} title={strings.EDIT_SUPERGROUP} />
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Spin
          spinning={loading || editLoading}
          tip={editLoading ? strings.WAIT_WE_ARE_UPDATING_SUPERGROUP : strings.LOADING_SUPERGROUP}
        >
          <Row gutter={[10, 10]} align="middle">
            <Col xs={24} lg={8}>
              <Form.Item
                name="name"
                label={strings.NAME}
                rules={[{ required: true, message: `${strings.NAME} ${strings.IS_REQUIRED}` }]}
              >
                <Input />
              </Form.Item>
            </Col>
            {hasTopManager && (
              <Col xs={24} lg={6}>
                <Form.Item
                  name="branch"
                  label={strings.BRANCH}
                  rules={[
                    {
                      required: true,
                      message: `${strings.BRANCH} ${strings.IS_REQUIRED}`
                    }
                  ]}
                >
                  <Select>
                    {branches.map((branch: Branch) => (
                      <Option key={branch.id} value={branch.id}>
                        {branch.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col xs={12} style={{ maxWidth: 50 }}>
              <Form.Item label={strings.COLOR}>
                <ColorPicker
                  allowClear
                  value={colour}
                  size="large"
                  onClear={() => setColour(null)}
                  onChange={(val, hex) => setColour(hex)}
                />
              </Form.Item>
            </Col>
            <Col xs={12} lg={4}>
              <Form.Item name="isActive" valuePropName="checked" style={{ marginTop: 30 }}>
                <Checkbox>{strings.CURRENT}</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={6}>
              <Button type="primary" size="large" htmlType="submit" disabled={editLoading} loading={editLoading}>
                {strings.EDIT}
              </Button>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Container>
  );
};

export default { SupergroupForm };
