import * as courses from 'Admin/Courses/routes';
import {
  GroupCourse,
  Group,
  OriginalCourses,
  AddCourseCroup,
  ChooseOriginal,
  SuperGroups,
  Placement,
  Units,
  AddLesson,
  EditCourseGroup,
  OriginalCourseFull,
  OriginalCourseForm,
  OriginalLessonEdit,
  OriginalLessonAdd,
  SupergroupsGenerator,
  SupergroupFull,
  SupergroupForm
} from 'Admin/Courses';

export const coursesArr = [
  {
    path: courses.EDIT_COURSE_GROUP,
    component: EditCourseGroup,
    exact: true
  },
  {
    path: courses.BASE_COURSE_GROUP,
    component: GroupCourse,
    exact: true
  },
  {
    path: courses.CHOOSE_ORIGINAL_COURSE,
    component: ChooseOriginal,
    exact: true
  },
  {
    path: courses.BASE_COURSE_GROUP_ADD,
    component: AddCourseCroup,
    exact: true
  },
  {
    path: `${courses.BASE_COURSE_GROUP}/:id`,
    component: Group,
    exact: true
  },
  {
    path: courses.BASE_ORIGINAL_COURSES,
    component: OriginalCourses,
    exact: true
  },
  {
    path: courses.ORIGINAL_COURSE,
    component: OriginalCourseFull,
    exact: true
  },

  {
    path: courses.ORIGINAL_COURSE_EDIT,
    component: OriginalCourseForm,
    exact: true
  },
  {
    path: courses.ORIGINAL_COURSE_NEW,
    component: OriginalCourseForm,
    exact: true
  },
  {
    path: courses.BASE_SUPER_GROUPS,
    component: SuperGroups,
    exact: true
  },
  {
    path: courses.BASE_PLACEMENT,
    component: Placement,
    exact: true
  },
  {
    path: courses.BASE_ORIGINAL_COURSES_UNITS,
    component: Units,
    exact: true
  },
  {
    path: courses.BASE_ORIGINAL_COURSES_UNITS_NEW,
    component: AddLesson,
    exact: true
  },
  {
    path: courses.ORIGINAL_LESSON_EDIT,
    component: OriginalLessonEdit,
    exact: true
  },
  {
    path: courses.ORIGINAL_LESSON_NEW,
    component: OriginalLessonAdd,
    exact: true
  },
  {
    path: courses.SUPERGROUPS_GENERATOR,
    component: SupergroupsGenerator,
    exact: true
  },
  {
    path: courses.EDIT_SUPER_GROUP,
    component: SupergroupForm,
    exact: true
  },
  {
    path: courses.FULL_SUPER_GROUP,
    component: SupergroupFull,
    exact: true
  }
];

export default { coursesArr };
