import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, message, Button, Col, Row, Select, Spin, Radio } from 'antd';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { useLanguageContext } from 'contexts/LanguageContext';
import { StageSelect } from 'Global/components/FormComponentsCompatible/Selects/StageSelect';
import { SubjectSelect } from 'Global/components/FormComponentsCompatible/Selects/SubjectsSelect';
import { Group } from 'types/education';
import debounce from 'lodash/debounce';
import { momentFormatValues } from 'helpers/momentFormatValues';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';
import { ReportBranches } from 'types/Reports';
import { useCoursesRepository } from 'repos/CoursesRepository';
import BranchDateFields from './BranchDateFields';

const { Option } = Select;

interface BtnGenerateForExtraGroupsProps {
  form: WrappedFormUtils;
  id: number;
  reportBranches: ReportBranches[];
}

/**
 * @description - Компонент модального окна Generate For Extra Groups
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const BtnGenerateForExtraGroupsTemplate = (props: BtnGenerateForExtraGroupsProps) => {
  const [strings] = useLanguageContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const [searchGroupsList, setSearchGroupsList] = useState<Group[]>([]);
  const [hasLoadingGroupsList, setHasLoadingGroupsList] = useState<boolean>(false);
  const [typing, setTyping] = useState<Boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const reportsRepository = useProgressReportsRepository();
  const [hasGroupsByFilter, setHasGroupsByFilter] = useState<boolean>(false);
  const coursesRepository = useCoursesRepository();

  const {
    form,
    form: { getFieldDecorator, getFieldValue, validateFieldsAndScroll },
    id,
    reportBranches
  } = props;

  const closeModal = (): void => {
    setHasShowModal(false);
  };

  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        setHasLoading(true);
        try {
          const params = momentFormatValues(values);
          await reportsRepository.generateProgressReporByGroups(id, params);
          message.success(strings.SUCCESSFULLY_CREATED);
          closeModal();
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  /**
   * @description Поиск группы по имени и бранчу
   * @param {string} groupName
   * @return {Promise<any>}
   */
  const searchCourseGroups = async (groupName: string): Promise<any> => {
    setSearchValue(groupName);
    if (groupName) {
      //задаем большой лимит, чтобы получить все страницы
      const params = { name: groupName, branch: getFieldValue('branch'), active: 1, limit: 2000 };
      setHasLoadingGroupsList(true);
      try {
        const {
          data: { items }
        } = await coursesRepository.getCourseGroups(params);
        const sortingGroups = items.sort((a, b) => a.groupName.localeCompare(b.groupName));
        setSearchGroupsList(sortingGroups);
      } catch {
      } finally {
        setHasLoadingGroupsList(false);
      }
    }
  };

  const getFooter = () => {
    const buttons = [
      <Button key="back" onClick={closeModal}>
        {strings.CANCEL}
      </Button>,
      <Button type="primary" key="action" loading={hasLoading} htmlType="submit" onClick={handleSubmit}>
        {strings.GENERATE}
      </Button>
    ];
    return buttons;
  };

  useEffect(() => {
    if (searchValue) {
      setTyping(true);
    } else {
      setTyping(false);
    }
  }, [searchValue]);

  const extraGroupForm = (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Row style={{ marginBottom: 10 }}>
        <Col lg={24}>
          <Radio.Group
            defaultValue="name"
            onChange={e => {
              const val = e.target.value;
              if (val === 'name') {
                setHasGroupsByFilter(false);
              } else {
                setHasGroupsByFilter(true);
              }
            }}
          >
            <Radio.Button value="name">{strings.GROUPS_BY_NAME}</Radio.Button>
            <Radio.Button value="filter">{strings.GROUPS_BY_FILTER}</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      <BranchDateFields
        getFieldDecorator={getFieldDecorator}
        getFieldValue={getFieldValue}
        reportBranches={reportBranches}
      />
      {!hasGroupsByFilter && (
        <Row gutter={4}>
          <Col lg={24}>
            <MyFormItem label={strings.GROUPS}>
              {getFieldDecorator('groups', {
                rules: [{ required: true }]
              })(
                <Select
                  showSearch
                  allowClear
                  mode="multiple"
                  placeholder={strings.SEARCH_GROUP}
                  optionLabelProp="title"
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  onSearch={debounce(searchCourseGroups, 800)}
                  notFoundContent={
                    hasLoadingGroupsList ? (
                      <Spin size="small" />
                    ) : (
                      <p>{!typing ? strings.START_TYPING : strings.NOT_FOUND}</p>
                    )
                  }
                >
                  {searchGroupsList.map((group: Group) => (
                    <Option value={group?.id} key={group?.id} title={group?.name}>
                      <div>
                        <div>{group?.name}</div>
                        <div style={{ fontSize: 11 }}>{group?.groupName}</div>
                        <div style={{ fontSize: 11 }}>
                          <i>{group?.subject?.name}</i> {group?.branch?.name}
                        </div>
                      </div>
                    </Option>
                  ))}
                </Select>
              )}
            </MyFormItem>
          </Col>
        </Row>
      )}
      {hasGroupsByFilter && (
        <Row gutter={[6, 6]}>
          <Col lg={12}>
            <StageSelect name="stages" mode="multiple" label={strings.STAGES} form={form} />
          </Col>
          <Col lg={12}>
            <SubjectSelect
              name="subjects"
              mode="multiple"
              getFieldDecorator={getFieldDecorator}
              label={strings.SUBJECT}
            />
          </Col>
        </Row>
      )}
    </Form>
  );
  return (
    <>
      <Button type="primary" onClick={() => setHasShowModal(true)} style={{ margin: 0 }}>
        {strings.FOR_EXTRA_GROUPS}
      </Button>
      <Modal
        title={strings.DO_YOU_WANT_TO_GENERATE_PROGRESS_REPORTS}
        footer={getFooter()}
        open={hasShowModal}
        onCancel={() => setHasShowModal(false)}
        style={{ top: 20 }}
        width={650}
        destroyOnClose={true}
      >
        {extraGroupForm}
      </Modal>
    </>
  );
};

export const BtnGenerateForExtraGroups = Form.create<BtnGenerateForExtraGroupsProps>({})(
  BtnGenerateForExtraGroupsTemplate
);

export default { BtnGenerateForExtraGroups };
