import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { UploadOutlined } from '@ant-design/icons';
import { Row, Select, Input, Col, DatePicker, Button, Spin, Checkbox, Upload, message, Form } from 'antd';
import { Families } from 'api/Families';
import { Global } from 'api/Global';
import { momentFormatValues } from 'helpers/momentFormatValues';
import { IFamily, FamilyContractStatus, Performer, AdditionalPerformer, IParent } from 'types';
import { Contract, FamilyContractPutParams } from 'types/Family';
import { dateFormat } from 'helpers/dates';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useFamilyRepository } from 'repos/FamilyRepository';
import { usePerformersRepository } from 'repos/PerformersRepository';
import { match } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Container, StPageHeader } from 'Global/GlobalStyle';

const { Option } = Select;

/**
 * @description Редактирование контракта семьи
 * @returns {React.ReactNode}
 */
export const EditContract = () => {
  const [form] = Form.useForm();

  const {
    params: { id: familyId, contractId }
  }: match<{ id: string; contractId: string }> = useRouteMatch();

  const { push, goBack } = useHistory();

  const [family, setFamily] = useState<IFamily | null>(null);
  const [contractStatuses, setContractStatuses] = useState<FamilyContractStatus[]>([]);
  const [performers, setPerformers] = useState<Performer[]>([]);
  const [additionalPerformers, setAdditionalPerformers] = useState<AdditionalPerformer[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadedContract, setUploadedContract] = useState<any>(undefined);
  const [strings] = useLanguageContext();

  const familyRepository = useFamilyRepository();
  const performersRepository = usePerformersRepository();

  const handleSubmit = async (values: FamilyContractPutParams) => {
    setIsLoading(true);
    const params = momentFormatValues(values);
    try {
      const { status } = await Families.editFamilyContract(contractId, {
        ...params,
        ...(uploadedContract && { uploadContractFile: uploadedContract })
      });
      if (status === 200) {
        message.success(strings.CONTRACT_WAS_SUCCESSFULLY_SAVED);
        push(`/family/${familyId}/contracts`);
      }
    } catch (e) {
      message.error(strings.SORRY_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFamily = async (): Promise<any> => {
    try {
      const { data } = await familyRepository.getFamilyById(familyId);
      setFamily(data);
    } catch {}
  };
  const fetchContract = async (): Promise<any> => {
    try {
      const { data } = await Families.getFamilyContracts(familyId);
      const familyContract = data.find((familyContract: Contract) => familyContract.id === Number(contractId));
      const { performer, holded, contract_number, contract_date, status } = familyContract;
      form.setFieldsValue({
        performer: performer?.id,
        holded: holded?.id,
        contractNumber: contract_number,
        contractDate: contract_date && dayjs(contract_date),
        status: status?.id
      });
    } catch {}
  };
  const fetchContractStatuses = async (): Promise<any> => {
    try {
      const { data } = await Families.getFamilyContractStatuses();
      setContractStatuses(data);
    } catch {}
  };
  const fetchFamilyPerformers = async (): Promise<any> => {
    try {
      const { data } = await performersRepository.getFamilyPerformers(familyId);
      const activePerformers = data.filter((item: Performer) => item?.isActive);
      setPerformers(activePerformers);
    } catch {}
  };
  const fetchAdditionalFamilyPerformers = async (): Promise<any> => {
    try {
      const { data } = await Global.getAdditionalPerformers(familyId);
      setAdditionalPerformers(data);
    } catch {}
  };

  /**
   * @description Получить необходимые данные по семье для создания контракта
   * @returns {Promise<any>}
   */
  const fetchMetadata = async (): Promise<any> => {
    setIsLoading(true);
    try {
      fetchContract();
      fetchContractStatuses();
      fetchFamilyPerformers();
      fetchAdditionalFamilyPerformers();
      fetchFamily();
    } catch (e) {
      return message.error(strings.SORRY_SOMETHING_WENT_WRONG_WHEN_FETCHING_FAMILY_METADATA_PLEASE_TRY_AGAIN);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMetadata();
  }, []);

  const uploadNewContractProps = {
    accept: '.pdf',
    beforeUpload() {
      return false;
    },
    async onChange(upload: any) {
      if (!upload.file) return;

      setUploadedContract(upload.file);
      message.success(strings.FILE_ATTACHED_SUCCESSFULLY);
    }
  };

  return (
    <Container>
      <StPageHeader onBack={goBack} title={strings.EDIT_EXISTING_CONTRACT} />
      <Spin spinning={isLoading}>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Row gutter={[10, 10]}>
            <Col lg={10} xs={24}>
              <Form.Item name="contractNumber" label={strings.CONTRACT_NUMBER}>
                <Input placeholder={strings.CONTRACT_NUMBER} />
              </Form.Item>
              <Form.Item
                name="holded"
                label={strings.HOLDER}
                rules={[{ required: true, message: strings.HOLDER_IS_REQUIRED }]}
              >
                <Select placeholder={strings.HOLDER}>
                  {family?.parents.map((parent: IParent) => (
                    <Option key={parent.id} value={parent.id}>
                      {parent?.user?.name} {parent?.user?.surname}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="performer"
                label={strings.PERFORMER}
                rules={[{ required: true, message: strings.PERFORMER_IS_REQUIRED }]}
              >
                <Select placeholder={strings.PERFORMER}>
                  {performers.map((performer: Performer) => (
                    <Option key={performer.id} value={performer.id}>
                      {performer.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={10} xs={24}>
              <Form.Item name="additionalPerformer" label={strings.ADDITIONAL_PERFORMER}>
                <Select placeholder={strings.ADDITIONAL_PERFORMER}>
                  <Option value={null}>&nbsp;</Option>
                  {additionalPerformers.map((performer: AdditionalPerformer) => (
                    <Option key={performer.id} value={performer.id}>
                      {performer.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="contractDate"
                label={strings.CONTRACT_DATE}
                rules={[{ required: true, message: strings.CONTRACT_DATE_IS_REQUIRED }]}
              >
                <DatePicker format={dateFormat} placeholder={strings.CONTRACT_DATE} />
              </Form.Item>
              <Form.Item name="status" label={strings.STATUS}>
                <Select placeholder={strings.STATUS}>
                  {contractStatuses.map(({ id, status }: FamilyContractStatus) => (
                    <Option key={id} value={id}>
                      {status}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={10} xs={24}>
              <Form.Item name="deleteContractFile" valuePropName="checked">
                <Checkbox>{strings.DELETE_PREVIOUS_CONTRACT_FILE}</Checkbox>
              </Form.Item>
            </Col>
            <Col lg={10} xs={24}>
              <Form.Item>
                <Upload {...uploadNewContractProps}>
                  <span style={{ cursor: 'pointer' }}>
                    <UploadOutlined style={{ marginInlineEnd: 8 }} />
                    {strings.UPLOAD_NEW_CONTRACT_FILE}
                  </span>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Button type="primary" size="large" htmlType="submit">
            {strings.SAVE}
          </Button>
        </Form>
      </Spin>
    </Container>
  );
};
