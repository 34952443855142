import React, { useEffect, useState } from 'react';
import { useUserContext } from 'contexts/UserContext';
import { Group } from '../types/education';
import { IRepositoryProvider } from 'repos/RepositoryProvider';

const GroupContext = React.createContext(null);

export const GroupContextProvider: React.FC<IRepositoryProvider> = ({ children }) => {
  const [user] = useUserContext();
  const [selectedOriginalCourse, setSelectedOriginalCourse] = useState<number[]>([]);
  const [newSuperGroup, setNewSuperGroup] = useState<Group>(null);
  const [prefixGenerateCourses, setPrefixGenerateCourses] = useState<string>('');

  useEffect(() => {}, [user]);

  const provider = [
    {
      selectedOriginalCourse,
      setSelectedOriginalCourse,
      prefixGenerateCourses,
      setPrefixGenerateCourses,
      newSuperGroup,
      setNewSuperGroup
    }
  ];

  return <GroupContext.Provider value={provider}>{children}</GroupContext.Provider>;
};

export const useGroupContext = () => {
  const service = React.useContext(GroupContext);

  if (!service) {
    throw new Error('Group context is unavailable');
  }

  return service;
};
