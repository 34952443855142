import { gradeList, primaryGradeList } from '../constants';
import { Badge, Form, Radio } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormItemWithoutMargin } from 'Global/styles';
import { NamePath } from 'antd/lib/form/interface';
import { BASE_GRAY_TEXT, BASE_GREEN } from 'Global/colors';

interface IGradeList {
  setHasLessonOutcomesChanged: (val: boolean) => void;
  name: NamePath;
  outcomesRequired: boolean;
  hasColorGradelist?: boolean;
  outcomesFilled?: boolean;
}

export const StyledColorRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper-disabled {
    opacity: 0.5;
  }
  .ant-radio-button-wrapper {
    border-color: orange !important;
    border: 1px solid;
    width: 20px;
  }
  .ant-radio-button-wrapper-checked,
  .ant-radio-button-wrapper:hover {
    background-color: orange !important;
  }
  .ant-radio-button-wrapper:first-child {
    border-color: ${BASE_GRAY_TEXT} !important;
    border-right: none;
  }
  .ant-radio-button-wrapper:first-child.ant-radio-button-wrapper-checked,
  .ant-radio-button-wrapper:first-child:hover {
    background-color: ${BASE_GRAY_TEXT} !important;
  }
  .ant-radio-button-wrapper:last-child {
    border-color: ${BASE_GREEN} !important;
    border-left: none;
  }
  .ant-radio-button-wrapper:last-child.ant-radio-button-wrapper-checked,
  .ant-radio-button-wrapper:last-child:hover {
    background-color: ${BASE_GREEN} !important;
  }
  .ant-radio-button-wrapper-checked {
    opacity: 1;
  }
  .ant-radio-button-wrapper::before {
    content: none;
  }
`;
const StyledRadioGroup = styled(Radio.Group)`
  .radio-button-green.ant-radio-button-wrapper-checked {
    border-color: green !important;
    color: green !important;
  }

  .radio-button-orange.ant-radio-button-wrapper-checked {
    border-color: orange !important;
    color: orange !important;
  }

  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    border: 1px solid;
  }
  .ant-radio-button-wrapper::before {
    content: none;
  }
`;

export const GradeList = ({
  name,
  setHasLessonOutcomesChanged,
  outcomesRequired,
  hasColorGradelist = false
}: IGradeList) => {
  const form = Form.useFormInstance();
  const val = form.getFieldValue(name);
  const [hasBadge, setHasBadge] = useState<boolean>(false);

  const handleChange = useCallback(() => {
    setHasLessonOutcomesChanged(true);
    setHasBadge(false);
  }, [setHasLessonOutcomesChanged]);

  useEffect(() => {
    if (outcomesRequired) {
      setHasBadge(!val);
    }
  }, [val, outcomesRequired]);

  return (
    <Badge dot={hasBadge} offset={[0, 5]}>
      <FormItemWithoutMargin name={name}>
        {hasColorGradelist ? (
          <StyledColorRadioGroup size="small" onChange={handleChange}>
            {primaryGradeList.map(item => (
              <Radio.Button key={item.value} value={item.value} />
            ))}
          </StyledColorRadioGroup>
        ) : (
          <StyledRadioGroup size="small" onChange={handleChange}>
            {gradeList.map(item => (
              <Radio.Button
                key={item.value}
                value={item.value}
                className={`radio-button-${item.value === 1 ? 'green' : item.value === 2 ? 'orange' : ''}`}
              >
                {item.title}
              </Radio.Button>
            ))}
          </StyledRadioGroup>
        )}
      </FormItemWithoutMargin>
    </Badge>
  );
};
const GradeListMemo = React.memo(GradeList);
export default GradeListMemo;
