export const data = `Straighten your back.

Thank you for your work!

Wow, you were very productive today.

You know that you can learn new languages in LGEG?

Merci d'avoir attendu = thank you for waiting!

Why are you hiding your smile?? Stop it 😊

Every decision in your life led you to this moment and this stupid sentence.

Seeing a teacher outside of school is the lowest level of celebrity sighting.

In the Harry Potter universe, teachers were armed.

Driving is the adult version of the children's game "the floor is lava", except it's on expert mode and way more things are lava.

Human thoughts before language must have been weird.

 Getting consecutive green lights on your way home is the modern equivalent of “the winds were kind” when on a long voyage.

Of all the spiderman universes you could have been born in, you were born in the one where he is a fictional character.

Your money's not yours. It's just your turn with it.

Take a picture of this line, send it to our support team and receive a postcard.

Did you know that AMI stands for Academic Management Interface? 

The first AMI was called ASUD and appeared in early 2010s

AMI is your friend. Please do not offend it even if it makes you wait.

Say something good to a colleague while waiting.

You’re not the first one to wait for AMI to download and you’re not the last.

During last 5 seconds I’ve downloaded half of a page and what have you done! 

Try googling our art teachers while waiting, it’s worth it, promise.

If you thought that you can endlessly stare at other people at work, you were wrong - only downloading AMI can be admired eternally.

You’d better wait for an internet connection in Chrome - can play dinosaurs there. 

If I told you that a flower bloomed in a dark room, would you trust it? And if I told you that LGEG exists in at least 8 countries and only Moscow office has more than 750 kids?

My fav song is Fresh Strawberries by Franz Ferdinand`.match(/.+\b/g);

export const randomTextTip = () => {
  const index = Math.floor(Math.random() * data.length);
  return data[index] || 'Loading...';
};
