import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Select, Col, Row, DatePicker, Form } from 'antd';
import { dateFormat } from 'helpers/dates';
import { TutorSelect, StudentSelect } from 'Global/components/FormComponents';
import { Branch, Performer } from 'types';
import { InvoiceFilterParams } from 'types/invoice';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { invoiceStatuses } from 'Global/constants';
import { FilterButtons } from 'Global/components/FilterButtons';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { FormItemWithoutMargin } from 'Global/styles';

const { Option } = Select;

interface FilterProps {
  getInvoiceMoscow: (params?: InvoiceFilterParams) => Promise<any>;
  defFilterParams?: InvoiceFilterParams;
}

/**
 * @description Filter Invoice Moscow
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const Filter = React.memo(({ getInvoiceMoscow, defFilterParams }: FilterProps) => {
  const [strings] = useLanguageContext();
  const [form] = Form.useForm();
  const [performers, setPerformers] = useState<Performer[]>([]);

  const globalRequestsRepository = useGlobalRequestsRepository();

  const { branches } = useGlobalCollectionsContext();
  const { locationSearchParams } = useLocationSearchParams();
  const { created, periodStart, periodEnd, status, performer, paidFrom, paidTo, type, branch, tutor, child } =
    locationSearchParams || {};

  const [selectedBranch, setSelectedBranch] = useState<number>(branch);
  const [hasLoadingFilter, setHasLoadingFilter] = useState<boolean>(false);

  // Получить всех перформеров согласно selectedBranch
  const fetchPerformers = useCallback(async () => {
    if (!selectedBranch) return;
    try {
      const { data } = await globalRequestsRepository.getPerformers({ branch: selectedBranch, isActive: 1 });
      setPerformers(data);
    } catch {}
  }, [selectedBranch]);

  const handleSubmit = useCallback(
    async (values: InvoiceFilterParams) => {
      setHasLoadingFilter(true);
      try {
        await getInvoiceMoscow(values);
      } catch {
      } finally {
        setHasLoadingFilter(false);
      }
    },
    [getInvoiceMoscow]
  );

  const handleClear = async (): Promise<any> => {
    await getInvoiceMoscow(defFilterParams);
    form.resetFields();
  };

  //при смене филиала сменяется список Performers
  useEffect(() => {
    if (selectedBranch) {
      fetchPerformers();
      form.setFieldsValue({ performer: undefined });
    }
  }, [selectedBranch]);

  useEffect(() => {
    const initValues = {
      branch,
      status,
      tutor,
      child,
      performer,
      type,
      created: created && dayjs(created),
      periodStart: periodStart && dayjs(periodStart),
      periodEnd: periodEnd && dayjs(periodEnd),
      paidFrom: paidFrom && dayjs(paidFrom),
      paidTo: paidTo && dayjs(paidTo)
    };
    form.setFieldsValue(initValues);

    setSelectedBranch(branch);
  }, [locationSearchParams]);

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Row gutter={[10, 10]}>
        <Col lg={5}>
          <FormItemWithoutMargin name="branch">
            <Select
              disabled={branches.length === 1}
              placeholder={strings.BRANCH}
              onChange={(branchId: number) => setSelectedBranch(branchId)}
            >
              {branches
                .filter((item: Branch) => item?.invoiceMonthFinanceSystem)
                .map((branch: Branch) => (
                  <Option value={branch.id} key={branch.id}>
                    {branch.name}
                  </Option>
                ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={5}>
          <FormItemWithoutMargin name="created">
            <DatePicker format={dateFormat} placeholder={strings.CREATED} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={5}>
          <FormItemWithoutMargin name="periodStart">
            <DatePicker format={dateFormat} placeholder={strings.STARTING_DATE} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={5}>
          <FormItemWithoutMargin name="periodEnd">
            <DatePicker format={dateFormat} placeholder={strings.CLOSING_DATE} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={5}>
          <FormItemWithoutMargin name="status">
            <Select placeholder={strings.STATUS} allowClear>
              {invoiceStatuses.map(item => (
                <Option value={item.id}>{item.name}</Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={5}>
          <FormItemWithoutMargin name="performer">
            <Select placeholder={strings.PERFORMER} allowClear>
              {performers.map((performer: Performer) => (
                <Option value={performer.id} key={performer.id}>
                  {performer.name}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={5}>
          <FormItemWithoutMargin name="child">
            <StudentSelect placeholder={strings.STUDENT} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={5}>
          <FormItemWithoutMargin name="tutor">
            <TutorSelect placeholder={strings.TUTOR} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={5}>
          <FormItemWithoutMargin name="paidFrom">
            <DatePicker format={dateFormat} placeholder={strings.PAID_FROM} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={5}>
          <FormItemWithoutMargin name="paidTo">
            <DatePicker format={dateFormat} placeholder={strings.PAID_TO} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={5}>
          <FormItemWithoutMargin name="type">
            <Select placeholder={strings.TYPE} allowClear>
              <Option value="month">{strings.MONTH}</Option>
              <Option value="trimester">{strings.TRIMESTER}</Option>
              <Option value="year">{strings.YEAR}</Option>
            </Select>
          </FormItemWithoutMargin>
        </Col>
      </Row>
      <FilterButtons handleClear={handleClear} hasSubmitLoading={hasLoadingFilter} />
    </Form>
  );
});
export default { Filter };
