import React, { useState } from 'react';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { List, Button, Empty, message, Popconfirm } from 'antd';
import { EventFile } from 'types/Schedule';
import { getAttachmentLink, downloadFileByUrl } from 'helpers';
import { Global } from 'api';
import { useLanguageContext } from 'contexts/LanguageContext';

/**
 * @description Список приложений к эвентов
 * @return {React.ReactNode}
 */
export const EventAttachments = ({
  attachmentList,
  getEventAttachments
}: {
  attachmentList: EventFile[];
  getEventAttachments?: () => Promise<any>;
}) => {
  const [strings] = useLanguageContext();
  const [deleteItem, setDeleteItem] = useState<number>();

  /**
   * @description Скачивание материала с урока
   * @param {number} attachmentId
   * @param {string} fileName
   * @return {Promise<any>}
   */
  const downloadEventAttachment = async (attachmentId: number, fileName: string): Promise<any> => {
    const url = getAttachmentLink(attachmentId);
    await downloadFileByUrl(url, fileName);
  };

  /**
   * @description Удаление файла
   * @param {number} attachmentId
   * @return {Promise<any>}
   */
  const deleteEventAttachment = async (attachmentId: number): Promise<any> => {
    await Global.deleteAttachment(attachmentId)
      .then(() => {
        message.success(strings.FILE_DELETED_SUCCESS);
        getEventAttachments();
      })
      .catch(() => {});
  };

  /**
   * @description Подтверждение на удаление файла
   */
  const confirmDelete = () => {
    deleteEventAttachment(deleteItem);
  };

  return (
    <>
      {attachmentList.isEmpty() && <Empty description={strings.NO_FILES} />}
      {attachmentList.isNotEmpty() && (
        <List>
          {attachmentList.map((file: EventFile) => (
            <List.Item key={file.id}>
              {file.name}
              <Button
                size="small"
                onClick={() => downloadEventAttachment(file.id, file.name)}
                icon={<DownloadOutlined />}
                type="primary"
                style={{ margin: '10px 10px 0 10px' }}
              />
              {getEventAttachments && (
                <Popconfirm title={strings.ARE_YOU_SURE} onConfirm={confirmDelete}>
                  <Button size="small" onClick={() => setDeleteItem(file.id)} icon={<DeleteOutlined />} danger />
                </Popconfirm>
              )}
            </List.Item>
          ))}
        </List>
      )}
    </>
  );
};

export default { EventAttachments };
