import { message } from 'antd';
import * as mimeTypes from './constants';
import { storageService } from 'contexts/StorageContext';
import { decodeDispositionFilename } from 'helpers/dispositionFilename';
import { AMI_5_LOCATION } from 'helpers';

/**
 * @description Генерация ссылки
 * @param {string} fileExtension
 * @param {string} fileUrl
 * @link https://developer.mozilla.org/ru/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 * @return {string}
 */
const getUrlByMimeType = (fileExtension: string, fileUrl: string | ArrayBuffer) => {
  switch (fileExtension) {
    case mimeTypes.PNG_MIME_TYPE:
    case mimeTypes.JPG_MIME_TYPE:
    case mimeTypes.JPEG_MIME_TYPE:
    case mimeTypes.GIF_MIME_TYPE:
    case mimeTypes.PDF_MIME_TYPE:
      return fileUrl;

    default:
      return 'not supported';
  }
};

/**
 * @description Превью офисных форматов (на будущее)
 * @param {string} url
 * @return {string}
 */
export const getMicrosoftFilePreviewUrl = (url: string) => {
  return `https://view.officeapps.live.com/op/embed.aspx?src=${url}`;
};

export const getLessonAttachmentPreview = async (
  attachmentId: number,
  setPreviewUrl: (url: string | ArrayBuffer) => void,
  setHasShowPreviewModal: (hasShow: boolean) => void,
  strings
): Promise<any> => {
  let token = storageService.getAccessToken();

  const response = await fetch(`${AMI_5_LOCATION}/api/v1/attachment/preview/${attachmentId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });
  const disposition = response.headers.get('content-disposition');
  const contentType = response.headers.get('content-type').split(';')[0];
  // Возьмем кастомное название файла, если оно определенно, иначе берем из заголовка ответа content-disposition
  const filename = decodeDispositionFilename(disposition);
  if (response.status === 200) {
    let receivedLength = 0;
    let chunks = [];
    const reader = await response.body.getReader();
    const contentLength = +response.headers.get('Content-Length');

    while (true) {
      const { done, value } = await reader.read();

      if (done) {
        break;
      }
      chunks.push(value);
      receivedLength += value.length;
    }
    const blob = new Blob(chunks, { type: contentType });
    setHasShowPreviewModal(true);
    if (blob) {
      const fileURL = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));

      setPreviewUrl(fileURL);
    } else {
      message.error(strings.COULD_NOT_DOWNLOAD_FILE, 2);
    }
  } else if (response.status === 404) {
    message.error(strings.FILE_NOT_FOUND, 3);
  }
};

/**
 * @param {number} attachmentId
 * @param {function} setPreviewUrl
 * @param {function} setHasShowPreviewModal
 * @return {Promise<any>}
 */
export const getPreviewUrl = async (
  attachmentId: number,
  setPreviewUrl: (url: string | ArrayBuffer) => void,
  setHasShowPreviewModal: (hasShow: boolean) => void
): Promise<any> => {
  // TODO: тут надо передать тоже и перевести File not found
  let token = storageService.getAccessToken();

  return await fetch(`${AMI_5_LOCATION}/api/v1/attachment/download/${attachmentId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
    .then((response: Response) => {
      return response?.blob().then(blob => {
        if (response.status === 200) {
          setHasShowPreviewModal(true);
        }
        if (response.status === 404) {
          message.error('File not found', 3);
        }

        if (blob) {
          const mimiType = blob.type;
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            setPreviewUrl(getUrlByMimeType(mimiType, reader.result)); // base64 строка
          };
        }
      });
    })
    .catch(() => {});
};
