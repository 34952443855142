import { User } from 'types/user';

/**
 * @description Сервис для подстановки в приложение различного стороннего кода (Яндекс метрика и т.д)
 * @link https://help.userguiding.com
 * @link https://help.userguiding.com/en/articles/3420081-javascript-api
 * @link https://github.com/narkq/react-yandex-metrika
 */
export default class ExternalCodeService {
  // admin-en — все филиалы, на админов
  private readonly GUIDE_ADMIN_EN_LANGUAGE_ID: string = '61893764ID';
  // teacher-en — учителя в Москва, Рига, Питер, Лондон (по умолчанию для учителя этот)
  private readonly GUIDE_TEACHER_EN_LANGUAGE_ID: string = '04893894ID';
  // teacher-ru — учителя Париж
  private readonly GUIDE_TEACHER_RU_LANGUAGE_ID: string = '99894007ID';
  // parent-en - родители Рига
  private readonly GUIDE_PARENT_EN_LANGUAGE_ID: string = '58894382ID';
  // parent-ru - все остальные родители (по умолчанию для родителей этот)
  private readonly GUIDE_PARENT_RU_LANGUAGE_ID: string = '42894447ID';

  /**
   * @description Проверка не является ли текущая среда локальной
   * @return {boolean}
   */
  public isLocalEnvironment(): boolean {
    const href = window.location.href;
    const paths = ['localhost', '127.0.0.1', '0.0.0.0'];
    return paths.some(e => href.includes(e));
  }

  /**
   * @description Инициализация user guide
   * @param {number} guideId
   * @return {void}
   */
  private getUserGuideCode(guideId: string): void {
    (function (u, s, e, r, g) {
      u[r] = u[r] || [];
      u[r].push({
        'ug.start': new Date().getTime(),
        event: 'embed.js'
      });
      let f = s.getElementsByTagName(e)[0],
        j: any = s.createElement(e);
      j.async = true;
      j.src = 'https://static.userguiding.com/media/user-guiding-' + g + '-embedded.js';
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'userGuidingLayer', guideId);
  }

  /**
   * @description Получение базовых гайдов без привязки к языку
   * @param {object} user
   * @return {void}
   */
  public getGuideByBranchAndRole(user: User) {
    if (!this.isLocalEnvironment()) {
      const hasTeacher = user?.hasRoles?.teacher;
      const hasParent = user?.hasRoles?.parent;
      const hasAdmin = user?.hasRoles?.admin;

      const hasMoscow = user.branch.id === 1;
      const hasRiga = user.branch.id === 5;
      const hasLondon = user.branch.id === 4;
      const hasParis = user.branch.id === 3;
      const hasSaintPetersburg = user.branch.id === 2;

      if (hasTeacher && (hasMoscow || hasSaintPetersburg || hasRiga || hasLondon)) {
        this.getUserGuideCode(this.GUIDE_TEACHER_EN_LANGUAGE_ID);
      }

      if (hasTeacher && hasParis) {
        this.getUserGuideCode(this.GUIDE_TEACHER_RU_LANGUAGE_ID);
      }

      if (hasParent && hasRiga) {
        this.getUserGuideCode(this.GUIDE_PARENT_EN_LANGUAGE_ID);
      }

      if (hasParent && !hasRiga) {
        this.getUserGuideCode(this.GUIDE_PARENT_RU_LANGUAGE_ID);
      }

      if (hasAdmin) {
        this.getUserGuideCode(this.GUIDE_ADMIN_EN_LANGUAGE_ID);
      }
    }
  }
}
