import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import styled from 'styled-components';
import { CaretRightOutlined, PlusOutlined } from '@ant-design/icons';
import { Typography, Button, Table, Statistic, Row, Col, Card, Switch as SwitchBtn, Collapse } from 'antd';
import { Loader } from 'Global/components/Loader/Loader';
import { Families } from 'api';
import { getActionsColumn, getColumns, performersColumns } from 'Admin/People/Families/Family/Finance/Finance/columns';
import { FinanceModal } from 'Admin/People/Families/Family/Finance/Finance/FinanceModal';
import { ROLE_TOP_MANAGER, ROLE_BRANCH_DIRECTOR, ROLE_BRANCH_DIRECTOR_OPERATION_DEPARTMENT } from 'Global/roles';
import { hasRole, hasInvoiceMonthFinanceSystem } from 'helpers';
import { dateFormatForBackend, lastDayOfPrevMonth } from 'helpers/dates';
import { PreviousFinancesTable } from 'Admin/People/Families/Family/Finance/Finance/PreviousFinancesTable';
import { AddFreeDay } from 'Admin/People/Families/Family/Finance/Finance/AddFreeDay';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { Branch, IStudent, ModalMode } from 'types';
import { FamilyFinance, FamilyFinanceItem, FamilyFinanceSummary } from 'types/Family';
import { useUserContext } from 'contexts/UserContext';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Title, Text } = Typography;
const { Column } = Table;
const { Panel } = Collapse;

const StCard = styled(Card)`
  border-radius: 5px;
`;

const customPanelStyle = {
  borderRadius: 5,
  border: 0
};

interface FinanceProps {
  id: number; // Family id
  students: IStudent[];
  branch: Branch;
  created: string;
  financeShouldUpdate: boolean;
  handleFinanceUpdated: () => any;
}

/**
 * @description Финансы семьи
 * @param {object} props
 * @return {React.ReactNode}
 */
export const Finance = (props: FinanceProps) => {
  const [strings] = useLanguageContext();
  const [finance, setFinance] = useState<FamilyFinance>();
  const [financeLoaded, setFinanceLoaded] = useState<boolean>(false);
  const [showFinance, setShowFinance] = useState<boolean>(false);
  const [currentFinance, setCurrentFinance] = useState<FamilyFinanceItem | undefined>();
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Hidden);
  const [showPrevious, setShowPrevious] = useState<boolean>(false);
  const [previousFinance, setPreviousFinance] = useState([]);
  const [previousFinanceSummary, setPreviousFinanceSummary] = useState<FamilyFinanceSummary>();
  const [previousLoaded, setPreviousLoaded] = useState<boolean>(false);
  const [visibleFreeDay, setVisibleFreeDay] = useState<boolean>(false);
  const [hasHideNullAmont, setHasHideNullAmont] = useState<boolean>(false);
  const { id, students, branch, created, financeShouldUpdate, handleFinanceUpdated } = props;
  const { finances, accountStates, summary, dateStart, dateEnd, deposit } = finance || {};

  const [user] = useUserContext();
  const { products } = useGlobalCollectionsContext();
  const { end_balance, start_balance } = summary || {};
  const hideModal = () => setModalMode(ModalMode.Hidden);

  const hasAdmin = user?.hasRoles?.admin;

  // Когда вносим новую транзакцию и выбираем тип счета: Balance/ Deposit,  эти операции отображаются
  // в общей ленте финансов семьи. Те операции,  которые касаются депозитного счета,
  // должны отображаться иначе, чтобы их можно было отличить от основного счета.

  // Кому доступно: топ, бранч директор, бранч директор of operational department
  // Выводить только на семьи Москвы
  const hasOperationTypeColumn =
    hasInvoiceMonthFinanceSystem(branch) &&
    hasRole(user, [ROLE_TOP_MANAGER, ROLE_BRANCH_DIRECTOR_OPERATION_DEPARTMENT, ROLE_BRANCH_DIRECTOR]);

  const hasBranchDirectorOrTopManager = user?.hasRoles?.branchDirector || user?.hasRoles?.topManager;

  // Текущие значения операции для редактирования
  const values = finances && currentFinance && finances.find(f => f.id === currentFinance.id);

  // В случае если это финансы за предыдущий период
  const valuesByPreviousFinance =
    previousFinance && currentFinance && previousFinance.find(f => f.id === currentFinance.id);

  /**
   * @description Список финансов
   * @return {Promise<any>}
   */
  const fetchFinanceById = async (): Promise<any> => {
    setFinanceLoaded(true);
    await Families.getFamilyFinances(id)
      .then(({ data }) => {
        setFinanceLoaded(false);
        setFinance(data);
        setShowFinance(true);
        handleFinanceUpdated();
      })
      .catch(() => {});
  };

  const first = dayjs(lastDayOfPrevMonth).subtract(5, 'months').startOf('month');
  const last = lastDayOfPrevMonth;

  /**
   * @description Получим все операции за предыдущие месяца
   * @param {Dayjs} dateStart
   * @param {Dayjs} dateEnd
   * @return {Promise<any>}
   */
  const fetchFinancePreviousMonth = async (dateStart: Dayjs = first, dateEnd: Dayjs = last): Promise<any> => {
    setPreviousLoaded(true);

    const params = {
      dateStart: dateStart.format(dateFormatForBackend),
      dateEnd: dateEnd.format(dateFormatForBackend)
    };

    await Families.getFamilyFinances(id, params)
      .then(({ data: { finances, summary } }) => {
        setPreviousLoaded(false);
        setPreviousFinance(finances);
        setPreviousFinanceSummary(summary);
      })
      .catch(() => {});
  };

  /**
   * @description Открывает модалку для редактирование формы
   * @param {FamilyFinanceItem} finance
   * @returns {void}
   */
  const showEditForm = (finance: FamilyFinanceItem): void => {
    setCurrentFinance(finance);
    setModalMode(ModalMode.Edit);
  };

  /**
   * @description Открывает модалку для удаление формы
   * @param {FamilyFinanceItem} financeId
   * @returns {void}
   */
  const showDeleteForm = (finance: FamilyFinanceItem): void => {
    setCurrentFinance(finance);
    setModalMode(ModalMode.Delete);
  };

  const formatStartDate = dateStart && dateStart.split('-').reverse().join('.');

  const formatEndDate = dateEnd && dateEnd.split('-').reverse().join('.');

  const Start = () => (
    <>
      <span>Start balance</span> (<span>{formatStartDate}</span>)
    </>
  );

  const End = () => (
    <>
      <span>End balance</span> (<span>{formatEndDate}</span>)
    </>
  );

  const handleShowPrevious = () => setShowPrevious(!showPrevious);

  /**
   * @description Открывает модалку для добавлении компенсации
   * @param {FamilyFinanceItem} finance
   * @returns {void}
   */
  const addCompensation = (finance: FamilyFinanceItem): void => {
    setCurrentFinance(finance);
    setModalMode(ModalMode.AddCompensation);
  };

  /**
   * @description Фильтруем финансы по amount
   * @return {FamilyFinanceItem[]}
   */
  const filteredFinanceCurrentMonth = () => {
    if (finances && hasHideNullAmont) {
      return finances.filter((item: FamilyFinanceItem) => item?.amount !== 0);
    } else {
      return finances;
    }
  };

  useEffect(() => {
    fetchFinanceById();
  }, [id]); // eslint-disable-line

  useEffect(() => {
    if (financeShouldUpdate) fetchFinanceById();
  }, [financeShouldUpdate]); // eslint-disable-line

  return (
    <ErrorBoundary>
      <Loader spinning={!showFinance}>
        <Row align="middle" style={{ margin: '20px 0' }}>
          <Button
            onClick={() => setModalMode(ModalMode.Add)}
            style={{ marginInlineEnd: 10 }}
            type="primary"
            icon={<PlusOutlined />}
            disabled={!hasBranchDirectorOrTopManager}
          >
            {strings.CREATE_NEW_TRANSACTION}
          </Button>
          <Button icon={<PlusOutlined />} type="dashed" onClick={() => setVisibleFreeDay(true)}>
            {strings.ADD_A_FREE_DAY}
          </Button>
        </Row>
        {hasInvoiceMonthFinanceSystem(branch) && (
          <Table dataSource={accountStates} rowKey={(record: any) => record.performer.id} pagination={false}>
            {performersColumns(strings).map(col => (
              <Column key={col.key} {...col} />
            ))}
          </Table>
        )}
        <Collapse bordered={false} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
          <Panel header={strings.OLD_BALANCE} key="1" style={customPanelStyle}>
            <Row gutter={8} className="no-padding">
              <Col lg={8}>
                <StCard>
                  <Statistic
                    title={<Start />}
                    value={start_balance}
                    precision={2}
                    valueStyle={{
                      color: start_balance < 0 ? '#cf1322' : '#3f8600'
                    }}
                    style={{
                      minHeight: '4rem'
                    }}
                  />
                </StCard>
              </Col>
              <Col lg={8}>
                <StCard>
                  <Statistic
                    title={<End />}
                    value={end_balance}
                    precision={2}
                    valueStyle={{ color: end_balance < 0 ? '#cf1322' : '#3f8600' }}
                    style={{
                      minHeight: '4rem'
                    }}
                  />
                </StCard>
              </Col>
              <Col lg={8}>
                <StCard>
                  <Statistic
                    title="Deposit"
                    value={deposit}
                    style={{
                      minHeight: '4rem'
                    }}
                  />
                </StCard>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <Row style={{ margin: '20px 0' }}>
          <SwitchBtn checked={hasHideNullAmont} onChange={checked => setHasHideNullAmont(checked)} />
          <Text style={{ paddingInlineStart: 5 }}>Hide Null amount</Text>
        </Row>
        <Table
          className="transactions-table"
          dataSource={filteredFinanceCurrentMonth()}
          rowKey="id"
          pagination={false}
          loading={financeLoaded}
        >
          {getColumns(hasAdmin, hasOperationTypeColumn)
            .filter(col => col)
            .map(col => (
              <Column key={col.key} {...col} />
            ))}
          {getActionsColumn(showEditForm, showDeleteForm, addCompensation)
            .filter(col => col)
            .map(col => (
              <Column key={col.key} {...col} />
            ))}
        </Table>

        <Button style={{ margin: '20px 0' }} type="primary" onClick={handleShowPrevious}>
          {strings.SPENT_IN_PREVIOUS_MONTHS}
        </Button>

        {showPrevious && (
          <PreviousFinancesTable
            id={id}
            first={first}
            last={last}
            created={created}
            showEditForm={showEditForm}
            showDeleteForm={showDeleteForm}
            addCompensation={addCompensation}
            previousLoaded={previousLoaded}
            previousFinance={previousFinance}
            previousFinanceSummary={previousFinanceSummary}
            hasAdmin={hasAdmin}
            hasOperationTypeColumn={hasOperationTypeColumn}
            fetchFinancePreviousMonth={fetchFinancePreviousMonth}
          />
        )}
        <FinanceModal
          id={id}
          mode={modalMode}
          students={students}
          hideModal={hideModal}
          fetchFinanceById={fetchFinanceById}
          fetchFinancePreviousMonth={fetchFinancePreviousMonth}
          valuesByPreviousFinance={valuesByPreviousFinance}
          currentFinance={currentFinance}
          values={values || valuesByPreviousFinance}
          products={products}
          accountStates={accountStates}
          branch={branch}
        />
        <AddFreeDay
          visibleFreeDay={visibleFreeDay}
          setVisibleFreeDay={setVisibleFreeDay}
          students={students}
          familyId={id}
        />
      </Loader>
    </ErrorBoundary>
  );
};

export default { Finance };
