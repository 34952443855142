import React, { useEffect, useState } from 'react';
import { message, Skeleton, Row } from 'antd';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { match } from 'react-router';
import { ShowOriginalCourseLesson } from './ShowOriginalCourseLesson';
import { Courses, Global } from 'api';
import { LessonMaterials } from 'types/materials';
import { initialMaterialState } from '../Full/Lesson';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { Container, StPageHeader } from 'Global/GlobalStyle';

export function OriginalCourseLesson() {
  const [lesson, setLesson] = useState(null);
  const [hasLoadLesson, setHasLoadLesson] = useState<boolean>(false);
  const coursesRepository = useCoursesRepository();
  const { goBack } = useHistory();

  const [lessonMaterials, setLessonMaterials] = useState<LessonMaterials>({
    suggestedActivities: initialMaterialState,
    suggestedHomework: initialMaterialState
  });

  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();

  const getOriginalCourseFiles = async (): Promise<any> => {
    try {
      const { data } = await Courses.getOriginalLessonMaterials(id);
      setLessonMaterials(data);
    } catch {}
  };

  const moveMaterial = async (materialId: number, isMark: boolean): Promise<any> => {
    try {
      await Global.editAttachment({ mark: Number(!isMark) }, materialId);
      message.success('Successfully move :)', 2);
      await getOriginalCourseFiles();
    } catch {
      message.error('Error, please try again later :(', 2);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setHasLoadLesson(true);
        const { data } = await coursesRepository.getOriginalLessonById(id);
        // await getOriginalCourseFiles();
        setLesson(data);
        setHasLoadLesson(false);
      } catch (e) {
        setHasLoadLesson(false);
      }
    })();
  }, []);

  if (hasLoadLesson) {
    return (
      <Container>
        {Array.from(Array(5).keys()).map(i => (
          <Skeleton paragraph={{ rows: 2 }} active key={i.toString()} />
        ))}
      </Container>
    );
  }

  return (
    <Container>
      <StPageHeader onBack={goBack} title={lesson?.topic} />
      {lesson && (
        <ShowOriginalCourseLesson
          lesson={lesson}
          lessonMaterials={lessonMaterials}
          moveMaterial={moveMaterial}
          getOriginalCourseFiles={getOriginalCourseFiles}
        />
      )}
    </Container>
  );
}
