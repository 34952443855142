import React from 'react';
import dayjs from 'dayjs';
import { dateFormat } from 'helpers';
import { ILessonGrading } from 'types/TeacherAccount/types';

export const getColumns = strings => [
  {
    title: strings.DATE,
    dataIndex: 'lesson',
    key: 'date',
    render: (lesson: ILessonGrading) => lesson.startAt && dayjs(lesson.startAt).format(dateFormat)
  },
  {
    title: strings.LESSON,
    dataIndex: 'lessonAssessment',
    key: 'lessonAssessment',
    render: lessonAssessment =>
      lessonAssessment?.lessonFinalMark ? lessonAssessment?.lessonFinalMark.toUpperCase() : '-'
  },
  {
    title: strings.HW,
    dataIndex: 'hwAssessment',
    key: 'hwFinalMark',
    render: hwAssessment => (hwAssessment?.hwFinalMark ? hwAssessment?.hwFinalMark.toUpperCase() : '-')
  },
  {
    title: strings.EXPERT,
    dataIndex: 'expertAssessment',
    key: 'expertFinalMark',
    render: expertAssessment =>
      expertAssessment?.expertFinalMark ? expertAssessment?.expertFinalMark.toUpperCase() : '-'
  },
  {
    title: strings.DISCIPLINE,
    dataIndex: 'disciplineAssessment',
    key: 'disciplineFinalMark',
    render: disciplineAssessment =>
      disciplineAssessment?.disciplineFinalMark ? disciplineAssessment?.disciplineFinalMark.toUpperCase() : '-'
  }
];

export default {
  getColumns
};
