import React, { useContext, useMemo } from 'react';
import { getAllFormData } from 'helpers';
import { httpService } from 'api/core';
import { IGetNewsParams, IPostNewsParams } from 'types/news';

const NewsRepositoryContext = React.createContext<INewsRepository>(null);

interface INewsRepository {
  getNews: (params?: IGetNewsParams) => Promise<any>;
  getNewsRoleList: () => Promise<any>;
  getNewsById: (newsId: number | string) => Promise<any>;
  updateNewsById: (newsId: number | string, params: IPostNewsParams) => Promise<any>;
  createNews: (params: IPostNewsParams) => Promise<any>;
  setWatchNews: (newsId: number | string) => Promise<any>;
  deleteNews: (newsId: number | string) => Promise<any>;
}

class NewsRepository implements INewsRepository {
  getNews(params?: IGetNewsParams) {
    return httpService.get('/api/v1/news', params, null, true);
  }

  getNewsRoleList() {
    return httpService.get('/api/news/roles/list');
  }

  getNewsById(newsId: number | string) {
    return httpService.get(`/api/v1/news/${newsId}`, null, null, true);
  }

  updateNewsById(newsId: number | string, params: IPostNewsParams) {
    const formData = getAllFormData({ ...params, _method: 'PUT' });
    return httpService.post(`/api/v1/news/${newsId}`, formData, true, true, true);
  }

  createNews(params: IPostNewsParams) {
    return httpService.post('/api/v1/news', getAllFormData(params), null, true, true);
  }

  setWatchNews(newsId: number | string) {
    return httpService.post(`/api/news/${newsId}/watched`);
  }

  deleteNews(newsId: number | string) {
    return httpService.delete(`/api/v1/news/${newsId}`, null, null, true);
  }
}

export const useNewsRepository = () => {
  const service = useContext(NewsRepositoryContext);

  if (!service) {
    throw new Error('News repository is unavailable');
  }

  return service;
};

export const NewsRepositoryProvider = props => {
  const service = useMemo(() => new NewsRepository(), []);

  return <NewsRepositoryContext.Provider value={service}>{props.children}</NewsRepositoryContext.Provider>;
};
