export const mockData = [
  {
    parent: 'Михайлина Марина',
    teacher: 'Kirilov Denis',
    messages: [
      {
        id: 12241,
        name: 'Михайлина Марина',
        createdAt: 'Sep 19, 2019, 12:28:21 PM',
        message:
          'Денис, добрый день! Впервые вижу такой смайлик (ранее смотрела был другой), ' +
          'что это значит? Как Миша М. работает на уроке? Что скажите про Мишу М.? С уважением,' +
          ' Марина Михайлина, мама Миши'
      }
    ],
    new: 1,
    updatedAt: 'Sep 19, 2019, 12:28:21 PM',
    branch: {
      id: 123,
      name: 'Moscow'
    }
  },
  {
    parent: 'Михайлина Марина',
    teacher: 'Kirilov Denis',
    messages: [
      {
        id: 12241,
        name: 'Михайлина Марина',
        createdAt: 'Sep 19, 2019, 12:28:21 PM',
        message:
          'Денис, добрый день! Впервые вижу такой смайлик (ранее смотрела был другой), ' +
          'что это значит? Как Миша М. работает на уроке? Что скажите про Мишу М.? С уважением,' +
          ' Марина Михайлина, мама Миши'
      },
      {
        id: 12241,
        name: 'Михайлина Марина',
        createdAt: 'Sep 19, 2019, 12:28:21 PM',
        message:
          'Денис, добрый день! Впервые вижу такой смайлик (ранее смотрела был другой), ' +
          'что это значит? Как Миша М. работает на уроке? Что скажите про Мишу М.? С уважением,' +
          ' Марина Михайлина, мама Миши'
      }
    ],
    new: 1,
    updatedAt: 'Sep 19, 2019, 12:28:21 PM',
    branch: {
      id: 123,
      name: 'Moscow'
    }
  }
];

export const mockMessage = {
  id: 12241,
  name: 'Михайлина Марина',
  createdAt: 'Sep 19, 2019, 12:28:21 PM',
  message:
    'Денис, добрый день! Впервые вижу такой смайлик (ранее смотрела был другой), ' +
    'что это значит? Как Миша М. работает на уроке? Что скажите про Мишу М.? С уважением,' +
    ' Марина Михайлина, мама Миши'
};

export const mockDataSummary = [
  {
    id: 123,
    date: '26.09.2019',
    added: '27.09.2019',
    branch: 'Saint-Petersburg'
  }
];

export const mockAssessmentMaterials = [
  {
    title: '4 Ancient Rome: Caesar',
    docs: [
      {
        title: 'F1_Humanities_History_L4_Caesar.pptx',
        link: null,
        author: 'Kirilov Denis',
        date: '29.09.2019 15:33'
      }
    ]
  },
  {
    title: '4 Ancient Rome: Caesar',
    docs: [
      {
        title: 'F1_Humanities_History_L4_Caesar.pptx',
        link: null,
        author: 'Kirilov Denis',
        date: '29.09.2019 15:33'
      }
    ]
  }
];

export const mockDataSummaryCalendar = [
  {
    week: 'Afanasov Ivan'
  },
  {
    week: 'Afanasov Ivan2'
  },
  {
    week: 'Afanasov Ivan3'
  }
];

export const mockDataLessonModal = {
  date: 'Jul 09',
  name: 'Unit 1. Bonding. Covalent and metallic bonding',
  group: 'Online_G1_Che_Nasonov'
};

export const ACADEMIC_PROGRESS_LIMIT = 10;

export default {
  mockAssessmentMaterials,
  mockData,
  mockMessage,
  mockDataSummary,
  mockDataSummaryCalendar,
  mockDataLessonModal,
  ACADEMIC_PROGRESS_LIMIT
};
