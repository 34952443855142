import React, { useState } from 'react';
import { Table, Spin, message, Modal, Row } from 'antd';
import { Filter } from './Filter';
import { columns } from './columns';
import { TeacherReport } from 'types/Teachers';
import { Teachers } from 'api/Teachers';
import { TABLE_TYPES } from './constants';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useTeacherRepository } from 'repos/TeacherRepository';
import { useHistory } from 'react-router-dom';
import { TeacherReportsFilterParams } from 'types/Teachers/teacher';
import { Container, StPageHeader } from 'Global/GlobalStyle';

const { Column } = Table;

/**
 * @param {object} props
 * @return {React.ReactNode}
 */
export const ReportTeachers = () => {
  const [strings] = useLanguageContext();
  const [reports, setReports] = useState<TeacherReport[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [registersTitle, setRegistersTitle] = useState<string>('');
  const [dateFilter, setDateFilter] = useState<any>({ dateStart: null, dateEnd: null });
  const [groupsFilter, setGroupsFilter] = useState<number[]>([]);
  const [dataTable, setDataTable] = useState<any>([]);
  const [isDataTableLoading, setIsDataTableLoading] = useState<boolean>(false);
  const [dataTableType, setDataTableType] = useState<string>('');
  const teacherRepository = useTeacherRepository();

  const { goBack } = useHistory();

  const mapReports = (reports: TeacherReport[]) =>
    reports.map((report: TeacherReport) => {
      const {
        teacher,
        disciplineCount,
        disciplineOnTimePercent,
        disciplineTotalPercent,
        commentsCount,
        likedCommentsPercent,
        dislikedCommentsPercent,
        parentLikedCommentsPercent,
        useful,
        interest: interesting,
        childrenGradesTotal,
        uniqueVotersCount,
        votersSharePercent
      } = report;

      return {
        teacher,
        discipline: {
          registers: disciplineCount,
          onTime: disciplineOnTimePercent,
          total: disciplineTotalPercent
        },
        feedback: {
          commentsGraded: commentsCount,
          liked: likedCommentsPercent,
          disliked: dislikedCommentsPercent,
          parentLiked: parentLikedCommentsPercent
        },
        karma: null,
        students: {
          useful,
          interesting,
          gradesTotal: childrenGradesTotal,
          uniqueVoters: uniqueVotersCount,
          votersShare: votersSharePercent
        },
        handleOpenModal: (id: number, type: string) => loadModalData(id, type)
      };
    });

  const excludeDisciplines = async (id: number, lessonId: number, hasExclude: boolean, type: string) => {
    await Teachers.excludeDisciplines(id, lessonId, hasExclude, type);
    await loadTableData(id, TABLE_TYPES.registers.apiMethod, TABLE_TYPES.registers.responseField);
  };

  const loadModalData = (id: number, type: string) => {
    loadTableData(id, TABLE_TYPES[type].apiMethod, TABLE_TYPES[type].responseField);

    setIsModalVisible(true);
    setRegistersTitle(TABLE_TYPES[type].tableTitle);
    setDataTableType(type);
  };

  const loadTableData = async (id: number, type: string, responseField?: string) => {
    setIsDataTableLoading(true);
    try {
      const params = dateFilter;
      if (groupsFilter.isNotEmpty()) {
        params['groups'] = groupsFilter;
      }
      const { data } = await Teachers[type](id, params);
      const tableDataFromResponse = responseField ? data[responseField] : data;
      if (type === TABLE_TYPES.registers.apiMethod) {
        setDataTable(mapRegistersTable(tableDataFromResponse));
      } else {
        setDataTable(tableDataFromResponse);
      }
    } finally {
      setIsDataTableLoading(false);
    }
  };

  const mapRegistersTable = registersTable =>
    registersTable.map(register => {
      return {
        ...register,
        excludeDisciplines: (id: number, lessonId: number, hasExclude: boolean, type: string) =>
          excludeDisciplines(id, lessonId, hasExclude, type)
      };
    });

  const closeModal = () => {
    setIsModalVisible(false);
    setDataTable([]);
  };

  /**
   * @description Фетч репортов
   * @param {object} params - фильтры
   * @returns {Promise<any>}
   */
  const getReports = async (params: TeacherReportsFilterParams = {}): Promise<any> => {
    try {
      setLoading(true);
      const { data } = await teacherRepository.getTeacherReports(params);
      setReports(data.filter((report: TeacherReport) => report.teacher?.name));
    } catch {
      message.error('Sorry! Unexpected error has occured when fetching reports.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <StPageHeader onBack={goBack} title={strings.TEACHERS}>
        <Filter
          onFilter={getReports}
          loading={loading}
          setDateFilter={setDateFilter}
          setGroupsFilter={setGroupsFilter}
        />
      </StPageHeader>
      <Modal width={720} title={registersTitle} open={isModalVisible} onOk={closeModal} onCancel={closeModal}>
        <Table dataSource={dataTable} pagination={false} bordered loading={isDataTableLoading} size="small">
          {TABLE_TYPES[dataTableType]?.columns?.map(col => (
            <Column key={col.key} {...col} />
          ))}
        </Table>
      </Modal>
      <Spin spinning={loading}>
        <Row gutter={[10, 10]}>
          <Table dataSource={mapReports(reports)} columns={columns(strings)} pagination={false} bordered />
        </Row>
      </Spin>
    </Container>
  );
};

export default { ReportTeachers };
