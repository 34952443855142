import React, { useEffect, useState } from 'react';
import { Table, Row } from 'antd';
import { Filter } from './Filter';
import { getColumns } from './columns';
import { AssessmentsFilterParams, ISummaryListItem } from 'types/accessment';
import Pagination from 'Global/components/Pagination';
import { PaginationInfo } from 'types/global';
import { scrollTop } from 'helpers/scroll';
import { mockPagination } from 'Global/constants';
import { TablePaginationConfig } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useAssessmentsRepository } from 'repos/AssessmentsRepository';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useHistory } from 'react-router-dom';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import queryString from 'query-string';
import { isEmpty } from 'lodash';

const { Column } = Table;

/**
 * @description Summary table component
 * @return {React.ReactNode}
 */
export const SummaryTable = () => {
  const [strings] = useLanguageContext();
  const { goBack, push } = useHistory();
  const { locationSearchParams } = useLocationSearchParams();
  const { sortByVideoDate, direction } = locationSearchParams || {};
  const defFilterParams = { sortByVideoDate: 'desc', page: 1, assessmentStatus: [6] };
  const [pagination, setPagination] = useState<PaginationInfo>(mockPagination);
  const { pageCount, current } = pagination;

  const [summaryList, setSummaryList] = useState<ISummaryListItem[]>([]);
  const [hasLoadSummaryList, setHasLoadSummaryList] = useState<boolean>(false);
  const assessmentsRepository = useAssessmentsRepository();

  /**
   * @description Получение списка Assessments
   * @return {Promise<any>}
   */
  const getAssessmentsList = async (params: AssessmentsFilterParams): Promise<any> => {
    setHasLoadSummaryList(true);
    try {
      const {
        data: { items, paginationInfo }
      } = await assessmentsRepository.getAssessmentList(params);
      setSummaryList(items);
      setPagination(paginationInfo);
      setHasLoadSummaryList(false);
      push({ search: queryString.stringify(params, { arrayFormat: 'bracket' }) });
    } catch {
      setHasLoadSummaryList(false);
    }
  };

  /**
   * @description Пагинация
   * @param {number} page
   * @return {void}
   */
  const handlePage = async (page: number): Promise<any> => {
    scrollTop();
    // setParamsPage(page);
    getAssessmentsList({ ...locationSearchParams, page });
  };

  /**
   * @description Обработчик на действия в таблице
   * @param pagination
   * @param filters
   * @param sorter
   */
  const onTableChange = async (
    pagination: TablePaginationConfig,
    filters: Record<keyof any, string[]>,
    sorter: SorterResult<any>
  ): Promise<any> => {
    let sortParam;
    const { order, field } = sorter;
    switch (field) {
      case 'lesson.startAt':
        sortParam = { direction: order === 'ascend' ? 'asc' : 'desc' };
        break;
      case 'lesson.videoAvailable':
        sortParam = { sortByVideoDate: order === 'ascend' ? 'asc' : 'desc' };
        break;
      default:
    }
    // setParamsSort(param);
    getAssessmentsList({ ...locationSearchParams, ...sortParam });
  };

  useEffect(() => {
    if (isEmpty(locationSearchParams)) {
      getAssessmentsList(defFilterParams);
    } else {
      getAssessmentsList(locationSearchParams);
    }
  }, []);

  const columns = getColumns(strings, { sortByVideoDate, direction });
  return (
    <Container>
      <StPageHeader onBack={goBack} title={strings.ASSESSMENT_SUMMARY_TABLE}>
        <Filter getAssessmentsList={getAssessmentsList} defValues={defFilterParams} />
      </StPageHeader>
      <Row gutter={[10, 10]}>
        <Table
          pagination={false}
          onChange={onTableChange}
          dataSource={summaryList}
          loading={hasLoadSummaryList}
          rowKey="id"
        >
          {columns.map(col => (
            <Column key={col.key} {...col} />
          ))}
        </Table>
        <Pagination count={pageCount} current={current} pageChange={handlePage} />
      </Row>
    </Container>
  );
};

export default { SummaryTable };
