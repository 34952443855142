import dayjs from 'dayjs';
import { ISubjectTranslation } from 'types/global';

export const getLessonDate = (date, lang) => {
  const nextLessonDate = date && dayjs(date);
  const day = nextLessonDate.locale(lang).format('D');
  const month = nextLessonDate.locale(lang).format('MMM');
  return `${day} ${month}`;
};

export const getSubjectNameByLang = (subjectsTranslations: ISubjectTranslation[], lang, subjectId: number) => {
  const subject = subjectsTranslations.find(item => item.id === subjectId);
  return (subject && subject[lang]) || subject?.en;
};
