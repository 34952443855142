import React, { useEffect, useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Progress, message, Upload, Spin, Button } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { AttachmentsList } from './AttachmentsList';
import { LESSON_MATERIAL_TYPE } from '../constants';
import { getFormData } from 'helpers';
import { MaterialItem } from 'types/global';
import { useAttachmentRepository } from 'repos/AttachmentRepository';

const { Dragger } = Upload;

interface MaterialsUploaderProps {
  lessonId: number;
  type: string;
  text?: string;
  isDragger?: boolean;
  hasDataBeenUpdated?: boolean;
  setHasDataBeenUpdated?: (flag: boolean) => void;
}

/**
 * @description Компонент загрузки материалов
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const MaterialsUploader = (props: MaterialsUploaderProps) => {
  const [strings] = useLanguageContext();
  const [attachments, setAttachments] = useState<MaterialItem>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [progressPercent, setProgressPercent] = useState<number>(0);
  const { lessonId, type, text, isDragger, hasDataBeenUpdated, setHasDataBeenUpdated } = props;
  const attachmentRepository = useAttachmentRepository();

  const getHasShowPercent = () => progressPercent !== 0 && progressPercent !== 100;

  const draggerProps = {
    style: { margin: '10px 0' },
    showUploadList: false,
    beforeUpload: () => false,
    multiple: true,
    async onChange(upload: any) {
      if (!upload.file) return;
      try {
        setLoading(true);
        const { data } = await attachmentRepository.uploadAttachment(
          getFormData({
            type,
            id: lessonId,
            file: upload.file
          }),
          true,
          {
            onUploadProgress: function (progressEvent) {
              setProgressPercent(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            }
          }
        );
        if (data.success) {
          message.success(strings.FILES_SUCCESSFULLY_UPLOADED);
          getAttachmentList();
        }
      } catch {
        message.error(strings.FILE_UPLOAD_ERROR);
        setLoading(false);
      }
    }
  };

  /**
   * @description Список аттачментов по типу
   * @param {string} type
   * @param {number} originalLessonId
   */
  const getAttachmentList = async (): Promise<any> => {
    setLoading(true);
    try {
      const { data } = await attachmentRepository.getAttachmentList({ type, id: lessonId });
      setAttachments({ list: data.list, print: data.print });
    } catch {
      message.error(strings.ERROR_GET_ATTACHMENTS, 2);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (lessonId) {
      getAttachmentList();
    }
  }, [lessonId]);

  useEffect(() => {
    if (hasDataBeenUpdated) {
      getAttachmentList();
      setHasDataBeenUpdated(false);
    }
  }, [hasDataBeenUpdated]);

  return (
    <>
      {isDragger ? (
        <Dragger {...draggerProps} id="upload-classroom-materials">
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p style={{ padding: '0 20%', fontWeight: 500 }}>{text}</p>
        </Dragger>
      ) : (
        <Upload {...draggerProps} style={{ marginBottom: 8 }}>
          <Button>{text}</Button>
        </Upload>
      )}
      {getHasShowPercent() && <Progress percent={progressPercent} />}
      <Spin spinning={loading}>
        {attachments && (
          <AttachmentsList
            attachments={attachments}
            type={LESSON_MATERIAL_TYPE}
            updateAttachments={getAttachmentList}
          />
        )}
      </Spin>
    </>
  );
};


const MaterialsUploaderMemo = React.memo(MaterialsUploader);
export default MaterialsUploaderMemo;
