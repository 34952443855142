import React from 'react';
import { List } from 'antd';
import { Stage } from 'types';

interface StagesProps {
  onSelectStage: (stageId: number) => void;
  selectedStageId: number;
  stages: Stage[];
}

export const Stages = (props: StagesProps) => {
  const { onSelectStage, selectedStageId, stages } = props;
  return (
    <List>
      {stages.map((stage: Stage) => (
        <List.Item
          key={stage.id}
          onClick={() => onSelectStage(stage.id)}
          style={{
            cursor: 'pointer',
            ...(selectedStageId === stage.id && { color: '#006D75' })
          }}
        >
          {stage.name}
        </List.Item>
      ))}
    </List>
  );
};

export default { Stages };
