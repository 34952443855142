import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select, Button, message, Modal, DatePicker, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { Group } from 'types/education';
import { useLanguageContext } from 'contexts/LanguageContext';
import { dateFormat, dateFormatForBackend } from 'helpers';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import dayjs from 'dayjs';
import { useCoursesRepository } from 'repos/CoursesRepository';

const { Option } = Select;

interface CopyStudetsFromGroupProps extends FormComponentProps {
  groupId: number;
  fetchClassRoster: (groupId: number) => Promise<any>;
}

/**
 * @description Копирование студентов из группы
 * @param {AddStudentProps} props
 * @return {React.ReactNode}
 */
export const CopyStudentsFromGroupTemplate = ({
  groupId,
  fetchClassRoster,
  form: { getFieldDecorator, validateFields, resetFields }
}: CopyStudetsFromGroupProps) => {
  const [strings] = useLanguageContext();
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const [searchGroupsList, setSearchGroupsList] = useState<Group[]>([]);
  const [hasLoadingGroupsList, setHasLoadingGroupsList] = useState<boolean>(false);
  const [typing, setTyping] = useState<Boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const coursesRepository = useCoursesRepository();

  /**
   * @description Поиск группы по имени
   * @param {string} groupName
   * @return {Promise<any>}
   */
  const searchGroupByName = async (groupName: string): Promise<any> => {
    setSearchValue(groupName);
    if (groupName) {
      setHasLoadingGroupsList(true);
      try {
        const {
          data: { items }
        } = await coursesRepository.getCourseGroups({ name: groupName, active: 1 });
        setSearchGroupsList(items);
      } catch (err) {
        message.error(err.response.data.message);
      } finally {
        setHasLoadingGroupsList(false);
      }
    }
  };

  /**
   * @description Копирование студентов
   * @return {Promise<any>}
   */
  const handleSubmit = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();

    validateFields(async (err, values) => {
      if (!err) {
        const { startAt, group } = values;
        const backendFormatDate = dayjs(startAt).format(dateFormatForBackend);
        try {
          await coursesRepository.copyStudentsFromGroup(groupId, group, { startAt: backendFormatDate });
          fetchClassRoster(groupId);
          message.success(strings.STUDENT_SUCCESSFULLY_ADDED);
          setHasShowModal(false);
        } catch (err) {
          if (err?.response?.data?.message) return message.error(err?.response?.data?.message);
          message.error(`${strings.ERROR_TRY_AGAIN_LATER} :(`, 2);
        } finally {
          setSearchGroupsList([]);
        }
      }
    });
  };

  const footer = [
    <Button
      onClick={() => {
        setHasShowModal(false);
        resetFields();
      }}
      key="cancelBtn"
    >
      {strings.CANCEL}
    </Button>,
    <Button type="primary" onClick={handleSubmit} key="addToGroup">
      {strings.COPY_FROM_A_GROUP}
    </Button>
  ];

  useEffect(() => {
    if (searchValue) {
      setTyping(true);
    } else {
      setTyping(false);
    }
  }, [searchValue]);

  return (
    <>
      <Modal
        title={strings.COPY_FROM_A_GROUP}
        open={hasShowModal}
        onCancel={() => {
          setHasShowModal(false);
          resetFields();
        }}
        footer={footer}
      >
        <Form layout="vertical">
          <MyFormItem label={strings.GROUP}>
            {getFieldDecorator('group', {
              rules: [{ required: true }]
            })(
              <Select
                showSearch
                allowClear
                placeholder={strings.SEARCH_GROUP}
                optionLabelProp="title"
                defaultActiveFirstOption={false}
                filterOption={false}
                onSearch={debounce(searchGroupByName, 800)}
                notFoundContent={
                  hasLoadingGroupsList ? (
                    <Spin size="small" />
                  ) : (
                    <p>{!typing ? strings.START_TYPING : strings.NOT_FOUND}</p>
                  )
                }
              >
                {searchGroupsList.map((group: Group) => (
                  <Option value={group?.id} key={group?.id} title={group?.name}>
                    <div>
                      <div>{group?.name}</div>
                      <div style={{ fontSize: 11 }}>{group?.groupName}</div>
                      <div style={{ fontSize: 11 }}>
                        <i>{group?.subject?.name}</i> {group?.branch?.name}
                      </div>
                    </div>
                  </Option>
                ))}
              </Select>
            )}
          </MyFormItem>
          <MyFormItem label={strings.DATE}>
            {getFieldDecorator('startAt', {
              initialValue: dayjs(),
              rules: [{ required: true, message: strings.SELECT_DATE }]
            })(<DatePicker placeholder={`${strings.SELECT_DATE}`} format={dateFormat} />)}
          </MyFormItem>
        </Form>
      </Modal>
      <Button onClick={() => setHasShowModal(true)} type="primary">
        {strings.COPY_FROM_A_GROUP}
      </Button>
    </>
  );
};

export const CopyStudentsFromGroup = Form.create<CopyStudetsFromGroupProps>({})(CopyStudentsFromGroupTemplate);

export default { CopyStudentsFromGroup };
