import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { useSupportRepository } from 'repos/SupportRepository';
import { useUserContext } from 'contexts/UserContext';
import { ChatDialog } from 'Global/modules/Support/Chat/ChatDialog';
import { ChatTickets } from 'Global/modules/Support/Chat/ChatTickets';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { useMediaQuery } from 'react-responsive';
import { first } from 'lodash';
import { TransparentContainer } from 'Global/GlobalStyle';
import { ChatContainer } from 'Global/components/Chat/styles';

export const SupportChat = () => {
  const [user] = useUserContext();
  const supportRepo = useSupportRepository();
  const hasMobile = useMediaQuery({ query: '(max-width: 960px)' });
  const [tickets, setTickets] = useState<[]>([]);
  const [hasLoadTickets, setHasLoadTickets] = useState<boolean>(false);
  const [hasLoadDialog, setHasLoadDialog] = useState<boolean>(false);
  const [hasDialog, setHasDialog] = useState<boolean>(false);
  const [ticket, setTicket] = useState(null);

  const setCurrentTicket = async ticketId => {
    try {
      setHasDialog(true);
      setHasLoadDialog(true);
      const {
        data: { comments }
      } = await supportRepo.getTicketComments(ticketId);
      setHasLoadDialog(false);
      // @ts-ignore
      const ticket = tickets.find(t => t.id === ticketId);
      // @ts-ignore
      setTicket({ ...ticket, comments });
    } catch {
      setHasLoadDialog(false);
      setTicket(null);
    }
  };

  useEffect(() => {
    (async () => {
      // Сделаем первый тикет активным
      if (tickets && Array.isArray(tickets) && tickets.isNotEmpty()) {
        // @ts-ignore
        const firstTicketId = first(tickets).id;
        await setCurrentTicket(firstTicketId);
      }
    })();
  }, [tickets]);

  const fetchTickets = async () => {
    if (user && user?.email) {
      try {
        setHasLoadTickets(true);
        const {
          data: { results }
        } = await supportRepo.getZendeskTicketsByEmail(user.email);
        setTickets(results);
        setHasLoadTickets(false);
      } catch {
        setHasLoadTickets(false);
        message.error('Get tickets error, try again later :(', 2);
      }
    }
  };

  const chatTickets = (
    <ChatTickets
      ticket={ticket}
      refetchTickets={fetchTickets}
      setCurrentTicket={setCurrentTicket}
      tickets={tickets}
      hasLoadTickets={hasLoadTickets}
    />
  );

  const chatDialog = (
    <ChatDialog
      setTicket={setTicket}
      hasDialog={hasDialog}
      ticket={ticket}
      hasLoadDialog={hasLoadDialog}
      refetchTicket={() => setCurrentTicket(ticket?.id)}
      refetchTickets={fetchTickets}
    />
  );

  useEffect(() => {
    fetchTickets();
  }, [user, user?.email]);

  if (hasMobile) {
    return (
      <ErrorBoundary>
        {chatTickets}
        {ticket && chatDialog}
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <TransparentContainer>
        <ChatContainer threadActive={Boolean(ticket)}>
          <ChatTickets
            ticket={ticket}
            refetchTickets={fetchTickets}
            setCurrentTicket={setCurrentTicket}
            tickets={tickets}
            hasLoadTickets={hasLoadTickets}
          />
          <ChatDialog
            setTicket={setTicket}
            hasDialog={hasDialog}
            ticket={ticket}
            hasLoadDialog={hasLoadDialog}
            refetchTicket={() => setCurrentTicket(ticket?.id)}
            refetchTickets={fetchTickets}
          />
        </ChatContainer>
      </TransparentContainer>
    </ErrorBoundary>
  );
};
