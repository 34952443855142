import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useUserContext } from 'contexts/UserContext';
import { Courses } from 'api';
import { DeleteOutlined, DragOutlined, EditOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Button, Collapse, message, Popconfirm, Row } from 'antd';
import queryString from 'query-string';
import { OriginalLesson, Unit } from 'types/TeacherAccount/Courses';
import { OriginalCourseLesson } from '../Lesson';
import { useHistory } from 'react-router-dom';
import { UnitName } from 'types/courses';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
  SortableHandle
} from 'react-sortable-hoc';
import { useLanguageContext } from 'contexts/LanguageContext';
import { arrayMoveImmutable } from 'array-move';

interface LessonPlanProps {
  units: Unit[];
  getOriginalCourseById: (hasLoader?: boolean) => Promise<any>;
  courseId: number | string;
  setModalEditNameVisible: (isVisible: boolean) => void;
  setSelectedUnitId: (unitId: number | null) => void;
  selectedUnitId: number | null;
  unitNames: UnitName[];
}
interface LessonPanelProps {
  unit: Unit;
  getOriginalCourseById: (hasLoader?: boolean) => Promise<any>;
  courseId: number | string;
  unitNames: UnitName[];
}

const { Panel } = Collapse;

const StContainer = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-top: 20px;
`;

const StCollapse = styled(Collapse)`
  .unit-collapse > .ant-collapse-content > .ant-collapse-content-box,
  .ant-collapse-borderless > .ant-collapse-item:last-child {
    background: #fff;
    border-top: 1px solid #d9d9d9;
  }

  .unit-collapse > .ant-collapse-header,
  .unit-collapse.ant-collapse-item-active > .ant-collapse-content-box {
    border-bottom: 1px solid #d9d9d9;
  }
  .unit-collapse.ant-collapse-item-active > .ant-collapse-header {
    border-bottom: none;
  }
`;

function LessonPanel({ unit, unitNames, courseId, getOriginalCourseById }: LessonPanelProps) {
  const [strings] = useLanguageContext();
  const { id: unitId, lessons } = unit;

  const [user] = useUserContext();
  const [lessonsList, setLessonsList] = useState<OriginalLesson[]>(lessons);
  const SortableItem: React.ComponentClass<{ readonly value?: any } & SortableElementProps> = SortableElement(
    ({ value }) => <>{value}</>
  );

  const onSortEnd = async ({ oldIndex, newIndex }): Promise<any> => {
    if (oldIndex === newIndex) {
      return false;
    }
    const newArray: OriginalLesson[] = arrayMoveImmutable(lessonsList, oldIndex, newIndex);

    const lessonsStringify = queryString.stringify(
      { lessons: newArray.map(lesson => lesson.id) },
      { arrayFormat: 'index' }
    );
    setLessonsList(newArray);
    try {
      await Courses.originalLessonSort(courseId, lessonsStringify);
      message.success(strings.SUCCESSFULLY_MOVED, 2);
    } catch (e) {
      message.error(e.message);
      getOriginalCourseById();
    }
  };

  const SortableLessonsList: React.ComponentClass<{ readonly items?: any } & SortableContainerProps> =
    SortableContainer(({ items }) => {
      return (
        <div style={{ marginTop: 20 }}>
          {items?.map((lesson: OriginalLesson, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              value={
                <OriginalCourseLesson
                  key={lesson.id}
                  lesson={lesson}
                  unitId={unitId}
                  originalCourseId={courseId}
                  unitNames={unitNames}
                  getOriginalCourseById={getOriginalCourseById}
                  hasTeacherRole={user?.hasRoles?.teacher}
                />
              }
            />
          ))}
        </div>
      );
    });

  return <SortableLessonsList pressDelay={125} items={lessonsList} onSortEnd={onSortEnd} useDragHandle />;
}

export const LessonPlan: React.FC<LessonPlanProps> = ({
  units,
  getOriginalCourseById,
  courseId,
  setModalEditNameVisible,
  setSelectedUnitId,
  selectedUnitId,
  unitNames
}: LessonPlanProps) => {
  const [toggleLoader, setToggleLoader] = useState<boolean>(false);
  const [unitsList, setUnitsList] = useState<Unit[]>([]);
  const [user] = useUserContext();
  const [strings] = useLanguageContext();
  const { push } = useHistory();

  const DragHandle = SortableHandle(() => <DragOutlined style={{ cursor: 'move', padding: 4 }} />);
  const SortableItem: React.ComponentClass<{ readonly value?: any } & SortableElementProps> = SortableElement(
    ({ value }) => <>{value}</>
  );

  const SortableUnitList: React.ComponentClass<{ readonly children?: any } & SortableContainerProps> =
    SortableContainer(({ children }) => {
      return <>{children}</>;
    });

  const goToNewLesson = (e: React.MouseEvent<HTMLElement, MouseEvent>, unitId: number) => {
    e.stopPropagation();
    push(`/original-lesson/new/${courseId}?unitId=${unitId}`);
  };

  const deleteUnit = async (unitId: number): Promise<any> => {
    try {
      await Courses.deleteOriginalCourseUnit(courseId, unitId);
      message.success(strings.THE_UNIT_WAS_SUCCESSFULLY_REMOVED_FROM_THIS_ORIGINAL_COURSE, 2);
      await getOriginalCourseById();
    } catch {
      message.error(strings.UNIT_REMOVE_ERROR_TRY_AGAIN_LATER, 2);
    }
  };

  const panelExtra = (unitId: number, lessonsLength: number, isActive: boolean) => {
    const confirmDeleteUnit = async e => {
      e.stopPropagation();
      await deleteUnit(unitId);
    };

    const handleClickEditNameUnit = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
      e.stopPropagation();
      setModalEditNameVisible(true);
      setSelectedUnitId(unitId);
    };

    const handleClickToggle = async (e: React.MouseEvent<HTMLElement, MouseEvent>): Promise<any> => {
      e.stopPropagation();
      setSelectedUnitId(unitId);
      setToggleLoader(true);

      try {
        const { data } = await Courses.toggleOriginalCourseUnit(courseId, unitId);
        message.success(`Unit ${data.name} ${data.isActive ? 'show' : 'hide'}`);
        getOriginalCourseById(false);
        setToggleLoader(false);
        setSelectedUnitId(null);
      } catch (e) {
        setToggleLoader(false);
        setSelectedUnitId(null);
      }
    };

    if (!user?.hasRoles?.teacher) {
      return (
        <Row align="middle" style={{ gap: 5 }}>
          <DragHandle />
          <Button
            onClick={handleClickToggle}
            icon={isActive ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            size="small"
            loading={toggleLoader && selectedUnitId === unitId}
            disabled={selectedUnitId && toggleLoader}
          />
          <Button icon={<EditOutlined />} type="primary" size="small" onClick={handleClickEditNameUnit} />
          <Popconfirm
            title={strings.ARE_YOU_SURE}
            placement="left"
            okText={strings.YES}
            cancelText={strings.NO}
            onConfirm={confirmDeleteUnit}
          >
            <Button icon={<DeleteOutlined />} size="small" danger onClick={e => e.stopPropagation()} />
          </Popconfirm>
          <Button size="small" onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => goToNewLesson(e, unitId)}>
            {strings.ADD_A_LESSON}
          </Button>
        </Row>
      );
    }
  };

  const onSortUnitEnd = async ({ oldIndex, newIndex }): Promise<any> => {
    if (oldIndex === newIndex) {
      return false;
    }
    const newArray: Unit[] = arrayMoveImmutable(unitsList, oldIndex, newIndex);

    const unitsStringify = queryString.stringify({ units: newArray.map(unit => unit.id) }, { arrayFormat: 'index' });
    setUnitsList(newArray);
    try {
      await Courses.originalCourseSortUnit(courseId, unitsStringify);
      message.success(strings.SUCCESSFULLY_MOVED, 2);
    } catch (e) {
      message.error(e.message);
      getOriginalCourseById();
    }
  };

  useEffect(() => {
    setUnitsList(units);
  }, [units]);

  return (
    <SortableUnitList pressDelay={125} onSortEnd={onSortUnitEnd} useDragHandle>
      <StContainer>
        {unitsList.map((unit: Unit, index) => {
          const { id: unitId, name, lessons, isActive } = unit;
          const lessonsLength = lessons?.length;

          if (!isActive && user?.hasRoles?.teacher) {
            return null;
          }

          return (
            <SortableItem
              key={`item-${index}`}
              index={index}
              value={
                <StCollapse expandIconPosition="right" bordered={false}>
                  <Panel
                    header={`${name} (${lessonsLength}) `}
                    key={unitId}
                    extra={panelExtra(unitId, lessonsLength, isActive)}
                    disabled={lessonsLength === 0}
                    className="unit-collapse"
                  >
                    <LessonPanel
                      unit={unit}
                      unitNames={unitNames}
                      courseId={courseId}
                      getOriginalCourseById={getOriginalCourseById}
                    />
                  </Panel>
                </StCollapse>
              }
            />
          );
        })}
      </StContainer>
    </SortableUnitList>
  );
};
