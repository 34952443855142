import React, { useState, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import { Spin, Input, Select, Row, Col, Button, Upload, message, Modal } from 'antd';
import { useLastLocation } from 'react-router-last-location';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Subject } from 'types';
import { TrainingsItem } from 'types/education';
import { useTrainingsRepository } from 'repos/TrainingsRepository';
import { Global } from 'api';
import { downloadFileByUrl, getAttachmentLink, getFormData } from 'helpers';
import { pickBy, identity } from 'lodash';
import { BASE_TRAININGS } from '../routes';
import { AttachmentItem } from 'types/global';
import { PreviewFileModal } from 'Global/components/FilePreview';
import { AttachmentType, initialAttachment } from '../../Monitoring/SummaryTable/Show/AttachmentsList';
import { getPreviewUrl } from 'Global/components/FilePreview/helpers';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { StageSelect } from 'Global/components/FormComponentsCompatible/Selects/StageSelect';
import { useAttachmentRepository } from 'repos/AttachmentRepository';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { match } from 'react-router';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';

const { Option } = Select;

interface TrainingFormEditProps extends FormComponentProps {}

export const TrainingFormEditTemplate = (props: TrainingFormEditProps) => {
  const [strings] = useLanguageContext();
  const lastLocation = useLastLocation();
  const trainingsRepository = useTrainingsRepository();
  const attachmentRepository = useAttachmentRepository();

  const [training, setTraining] = useState<TrainingsItem>();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [hasAttachLoading, setHasAttachLoading] = useState<boolean>(false);
  const [hasUploading, setHasUploading] = useState<boolean>(false);
  const [attachmentList, setAttachmentList] = useState<AttachmentItem[]>([]);
  //для превью файла
  const [hasShowPreviewModal, setHasShowPreviewModal] = useState<boolean>(false);
  const [hasLoadPreviewAttachment, setHasLoadPreviewAttachment] = useState<boolean>(false);
  const [currentAttachment, setCurrentAttachment] = useState<AttachmentType>(initialAttachment);
  const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer>('');

  const {
    form,
    form: { getFieldDecorator, validateFields }
  } = props;

  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();

  const { goBack, push } = useHistory();

  const { subjects: subjectsList } = useGlobalCollectionsContext();
  const [{ confirm }, contextHolder] = Modal.useModal();

  const { name, description, subjects, stages } = training || {};

  /**
   * @description Превью в модалке
   * @param {number} attachmentId
   * @param {string} attachmentName
   * @return {Promise<any>}
   */
  const handleShowModal = async (attachmentId: number, attachmentName: string): Promise<any> => {
    setCurrentAttachment({
      id: attachmentId,
      name: attachmentName
    });
    setHasLoadPreviewAttachment(true);
    try {
      await getPreviewUrl(attachmentId, setPreviewUrl, setHasShowPreviewModal);
    } catch {
    } finally {
      setHasLoadPreviewAttachment(false);
    }
  };

  /**
   * @description upload file
   * @param {number} id
   * @return {Promise<any>}
   */
  const uploadFile = async (upload): Promise<any> => {
    const { file } = upload;
    if (file) {
      setHasUploading(true);
      try {
        await attachmentRepository.uploadAttachment(
          getFormData({
            type: 'trainingAttach',
            id: id,
            file: file
          }),
          true
        );
        message.success(strings.FILE_UPLOAD, 2);
        getAttachmentList();
      } catch {
        message.error(strings.UPLOAD_FILE_ERROR, 2);
      } finally {
        setHasUploading(false);
      }
    }
    return false;
  };

  const uploadProps = {
    name: 'file',
    multiple: true,
    beforeUpload: () => false,
    showUploadList: false,
    onChange: uploadFile
  };

  /**
   * @description Получение тренинга по id
   * @param {number} id
   * @return {Promise<any>}
   */
  const getTrainingById = async (id: number | string): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await trainingsRepository.getTrainingById(id);
      setTraining(data);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  /**
   * @description Получить список файлов
   * @return {Promise<any>}
   */
  const getAttachmentList = async (): Promise<any> => {
    setHasAttachLoading(true);
    try {
      const { data } = await attachmentRepository.getAttachmentList({ type: 'trainingAttach', id: id });
      setAttachmentList(data.list);
    } catch {
      setAttachmentList([]);
    } finally {
      setHasAttachLoading(false);
    }
  };

  const handleSubmit = () => {
    validateFields(async (err, values) => {
      if (!err) {
        const params = pickBy(values, identity);
        setHasLoading(true);
        try {
          await trainingsRepository.editTraining(id, params);
          if (lastLocation) {
            goBack();
          } else {
            push(BASE_TRAININGS);
          }
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  /**
   * @description Удаление файла
   *  @return {Promise<any>}
   */
  const handleDelete = async attachmentId => {
    confirm({
      title: strings.DO_YOU_WANT_TO_DELETE_THIS_FILE,
      okText: strings.DELETE,
      okType: 'danger',
      async onOk() {
        try {
          await Global.deleteAttachment(attachmentId);
          message.success(strings.FILE_DELETED_SUCCESS);
          getAttachmentList();
        } catch {
          message.error(strings.FILE_DELETION_ERROR);
        }
      }
    });
  };

  /**
   * @description Скачивание файла
   * @param {number} id
   * @param {string} name
   * @return {Promise<any>}
   */
  const onDownloadFile = async (id: number, name): Promise<any> => {
    setHasAttachLoading(true);
    const attachLink: string = getAttachmentLink(id);
    try {
      await downloadFileByUrl(attachLink, name);
    } finally {
      setHasAttachLoading(false);
    }
  };

  useEffect(() => {
    getTrainingById(id);
    getAttachmentList();
  }, [id]);

  return (
    <Container>
      {contextHolder}
      <PreviewFileModal
        hasLoadPreviewAttachment={hasLoadPreviewAttachment}
        fileName={currentAttachment.name}
        previewUrl={previewUrl}
        handleHideModal={() => setHasShowPreviewModal(false)}
        hasShowModal={hasShowPreviewModal}
      />
      <StPageHeader
        onBack={goBack}
        title={strings.EDITING_TRAINING}
        extra={
          <Button key="submit" type="primary" htmlType="submit" onClick={handleSubmit}>
            {strings.SAVE}
          </Button>
        }
      />
      <Spin spinning={hasLoading} tip={`${strings.LOADING} ${strings.TRAINING}`}>
        <Form layout="vertical">
          <Row gutter={[8, 8]}>
            <Col lg={8}>
              <MyFormItem label={strings.TITLE}>
                {getFieldDecorator('name', {
                  initialValue: name
                })(<Input type="text" />)}
              </MyFormItem>
            </Col>
            <Col lg={8}>
              <StageSelect
                label={strings.STAGES}
                form={form}
                initialValue={stages && stages.map(item => item.id)}
                mode="multiple"
              />
            </Col>
            <Col lg={8}>
              <MyFormItem label={strings.SUBJECTS}>
                {getFieldDecorator('subjects', {
                  initialValue: subjects && subjects.map(item => item.id)
                })(
                  <Select mode="multiple" placeholder={strings.SELECT_SUBJECTS}>
                    {subjectsList.map((subject: Subject) => (
                      <Option value={subject.id} key={subject.id}>
                        {subject.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </MyFormItem>
            </Col>
            <Col lg={24}>
              <MyFormItem label={strings.DESCRIPTION}>
                {getFieldDecorator('description', {
                  initialValue: description
                })(<Input.TextArea placeholder={strings.ENTER_DESCRIPTION} />)}
              </MyFormItem>
            </Col>
            <Col lg={24}>
              <Upload {...uploadProps}>
                <Button icon={hasUploading ? <LoadingOutlined /> : <UploadOutlined />}>
                  {strings.CLICK_TO_UPLOAD}
                </Button>
              </Upload>
              <Spin spinning={hasAttachLoading}>
                <Row gutter={[8, 8]} align="middle" style={{ gap: 5, marginTop: 10 }}>
                  {attachmentList.map(attach => {
                    const createdBy = `${attach?.user?.surname} ${attach?.user?.name}`;
                    return (
                      <Col lg={24}>
                        <Button type="link" onClick={() => onDownloadFile(attach.id, attach.name)}>
                          {attach.name}
                        </Button>{' '}
                        <span style={{ fontSize: 12, color: '#bbb' }}>
                          uploaded at {attach.updatedAt} by {createdBy}
                        </span>
                      </Col>
                    );
                  })}
                </Row>
              </Spin>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Container>
  );
};

export const TrainingFormEdit = Form.create<TrainingFormEditProps>({})(TrainingFormEditTemplate);

export default { TrainingFormEdit };
