import styled from 'styled-components';
import { BASE_GRAY } from '../../../colors';

export const StyledTicketItems = styled.div`
  height: 100vh;
  overflow: hidden;
`;

export const StyledTicketItemsScroll = styled.div`
  width: 100%;
  height: 80%;
  overflow-y: scroll;
  box-sizing: content-box;
  padding-inline-end: 17px;
`;

export const StyledSkeletonContainer = styled.div`
  padding: 20px 15px;
  height: 400px;
`;

export const StyledNewTicket = styled.div`
  height: 65px;
  border-bottom: 1px solid #d9d9d9;
  width: 100%;
  padding: 20px 15px;
`;

export const StyledSendIcon = styled.div`
  cursor: pointer;
`;

export const StyledStatusColor = styled.span`
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  background: #555;
`;

export const StyledTicketSubject = styled.p`
  font-size: 14px;
  color: #000;
  padding: 0 0 5px 0;
  margin: 0;
  font-weight: 500;
`;

export const StyledShortTicketItem = styled.div<{ hasActive: boolean }>`
  width: 100%;
  padding: 20px 30px 20px 15px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  transition: all 0.2s ease-in;
  background: ${({ hasActive }) => (hasActive ? BASE_GRAY : 'transparent')};
  &:hover {
    background-color: #f5f5f5;
  }
  p {
    margin: 0;
  }
`;
export const StyledTicketBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledInfo = styled.div`
  width: 100%;
  text-align: center;
`;
