import { httpService } from 'api/core';

/**
 * @description ...
 * @param {object} params
 * @return {Promise<any>}
 */
export const sendMailDistribution = (params: object): Promise<any> => httpService.post('/api/mail', params);

/**
 * @description ...
 * @return {Promise<any>}
 */
export const getMailSessionsList = (page: number): Promise<any> => httpService.get(`/api/mail/sessions?page=${page}`);

/**
 * @description ...
 * @param {number} id
 * @return {Promise<any>}
 */
export const getMailSessionById = (id: number): Promise<any> => httpService.get(`/api/mail/${id}/session`);

export const Mail = {
  sendMailDistribution,
  getMailSessionsList,
  getMailSessionById
};

export default { Mail };
