import React from 'react';
import { Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { BASE_TEACHING_MATERIALS } from '../routes';
import { Inventory } from 'types/CRM/materials';

export const getColumns = ({ deleteInventory, hasActionsAccess, strings }) => [
  {
    title: strings.ISBN,
    dataIndex: 'isbn',
    key: 'isbn'
  },
  {
    title: strings.TITLE,
    dataIndex: 'name',
    key: 'name',
    render: (title: string, record: Inventory) => (
      <Link to={`${BASE_TEACHING_MATERIALS}/${record && record.id}`}>{title}</Link>
    )
  },
  {
    title: strings.ACTUAL,
    dataIndex: 'actual',
    key: 'actual',
    render: (actual: boolean) => (actual ? 'Yes' : 'No')
  },
  {
    title: strings.TYPE,
    dataIndex: 'type',
    key: 'type',
    render: type => type?.name
  },
  {
    title: strings.RATE,
    dataIndex: 'rate',
    key: 'rate'
  },
  {
    title: strings.OLD_RATE,
    dataIndex: 'oldRate',
    key: 'oldRate'
  },
  {
    title: strings.LEFT,
    dataIndex: 'left',
    key: 'left',
    sorter: (a, b) => a.left - b.left,
    render: (left: number) => <span style={{ color: left <= 0 ? 'red' : 'black' }}>{left}</span>
  },
  {
    title: strings.NEEDS_TO_BE_GIVEN,
    dataIndex: 'needsToGiven',
    key: 'needsToGiven',
    sorter: (a, b) => a.needsToGiven - b.needsToGiven
  },
  {
    title: strings.DEFICIT,
    dataIndex: 'deficit',
    key: 'deficit'
  },
  {
    title: strings.GIVEN_TOTAL,
    dataIndex: 'givenTotal',
    key: 'givenTotal',
    sorter: (a, b) => a.givenTotal - b.givenTotal
  },
  hasActionsAccess && {
    title: 'Action',
    dataIndex: '',
    key: '',
    render: (record: Inventory) => (
      <div style={{ display: 'flex' }}>
        <Link to={`${BASE_TEACHING_MATERIALS}/${record.id}/edit`} style={{ marginInlineEnd: 5 }}>
          <Button type="primary" icon={<EditOutlined />} />
        </Link>
        <Button danger icon={<DeleteOutlined />} onClick={() => deleteInventory(record.id)} />
      </div>
    )
  }
];

export default { getColumns };
