import React, { useEffect, useState } from 'react';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, message, Modal, Row, Table } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { getColumns } from './columns';
import { BASE_SUBJECTS, SUBJECT_NEW } from '../routes';
import { ISubject, SubjectsFilterParams } from 'types/education';
import { useSubjectsRepository } from 'repos/SubjectsRepository';

export const Subjects = () => {
  const [user] = useUserContext();
  const [strings] = useLanguageContext();
  const { goBack } = useHistory();
  const subjectsRepository = useSubjectsRepository();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [subjects, setSubjects] = useState<ISubject[]>([]);
  const hasBranchOperationDepartament = user?.hasRoles?.branchOperationDepartament;
  const [{ confirm }, contextHolder] = Modal.useModal();

  const fetchSubjects = async (): Promise<any> => {
    const params: SubjectsFilterParams = { withDetails: true, withTrainings: true };
    setHasLoading(true);
    try {
      const { data } = await subjectsRepository.getSubjects(params);
      setSubjects(data);
    } finally {
      setHasLoading(false);
    }
  };

  const handleDeleteSubject = (id: number): void => {
    confirm({
      title: strings.ARE_YOU_SURE,
      okType: 'danger',
      async onOk() {
        setHasLoading(true);
        try {
          await subjectsRepository.deleteSubject(id);
          fetchSubjects();
        } catch (err) {
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  const getActionColumns = () => [
    {
      title: '',
      dataIndex: 'id',
      key: 'action',
      render: (id: number) => (
        <Row align="middle" style={{ gap: 5, flexWrap: 'nowrap' }}>
          <Link to={`${BASE_SUBJECTS}/${id}/edit`}>
            <Button icon={<EditOutlined />} />
          </Link>
          {!hasBranchOperationDepartament && (
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                handleDeleteSubject(id);
              }}
            />
          )}
        </Row>
      )
    }
  ];

  useEffect(() => {
    fetchSubjects();
  }, []);

  return (
    <Container>
      {contextHolder}
      <StPageHeader
        onBack={goBack}
        title={strings.SUBJECTS}
        extra={[
          <Link to={SUBJECT_NEW}>
            <Button type="primary">{strings.ADD}</Button>
          </Link>
        ]}
      />
      <Row gutter={[10, 10]}>
        <Table
          dataSource={subjects}
          columns={[...getColumns(strings), ...getActionColumns()]}
          pagination={false}
          loading={{ spinning: hasLoading }}
        />
      </Row>
    </Container>
  );
};
export default { Subjects };
