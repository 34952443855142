import React, { useState } from 'react';
import styled from 'styled-components';
import { Skeleton, Button, Empty, Flex } from 'antd';
import { ShortTicketItem } from 'Global/modules/Support/Chat/ShortTicketItem';
import {
  StyledTicketItems,
  StyledTicketItemsScroll,
  StyledSkeletonContainer,
  StyledNewTicket
} from 'Global/modules/Support/Chat/styles';
import { FeedbackModal } from 'Global/modules/Support/Modal';
import { useLanguageContext } from 'contexts/LanguageContext';
import { ChatHeader, SideBar } from 'Global/components/Chat/styles';
import { LoadingItems } from '../../../components/LoadingItems';

const StyledEmpty = styled(Empty)`
  margin: 10px 0;
`;

export const ChatTickets = ({ ticket, tickets, hasLoadTickets, setCurrentTicket, refetchTickets }) => {
  const [strings] = useLanguageContext();
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);

  const handleClick = ticketId => {
    setCurrentTicket(ticketId);
  };

  return (
    <SideBar vertical>
      <FeedbackModal hasShow={hasShowModal} onHide={() => setHasShowModal(false)} refetchTickets={refetchTickets} />
      <ChatHeader>
        <Button type="primary" onClick={() => setHasShowModal(true)}>
          {strings.CREATE_NEW_REQUEST}
        </Button>
      </ChatHeader>
      <Flex vertical style={{ overflow: 'auto' }}>
        {!hasLoadTickets && tickets.isEmpty() && (
          <StyledEmpty description={<span>{strings.YOU_HAVE_NO_REQUESTS_YET}</span>} />
        )}
        {hasLoadTickets ? (
          <LoadingItems count={4} />
        ) : (
          tickets.map(({ subject, created_at, status, id }) => {
            return (
              <ShortTicketItem
                onClick={() => handleClick(id)}
                subject={subject}
                date={created_at}
                status={status}
                key={id.toString()}
                id={id}
                ticket={ticket}
              />
            );
          })
        )}
      </Flex>
    </SideBar>
  );
};
