import React, { useState, useEffect } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Typography, List, Spin, Flex, Button, Select, message, Modal, Alert, Tag, Form, Popconfirm } from 'antd';
import { Families } from 'api';
import styled from 'styled-components';
import { Inventory } from 'types';
import { filterSelect } from 'helpers/filterSelect';
import { useLanguageContext } from 'contexts/LanguageContext';

const StInventory = styled.div``;
const { Title } = Typography;
const { Item } = List;
const { Option } = Select;

interface TeachingMaterialsProps {
  id: number;
}

/**
 * @description Child Teaching materials component
 * @param {TeachingMaterialsProps} props
 * @return {React.ReactNode}
 */
export const TeachingMaterials = ({ id }: TeachingMaterialsProps) => {
  const [strings] = useLanguageContext();
  const [form] = Form.useForm();
  const [inventory, setInventory] = useState<Inventory[]>([]);
  const [neededInventory, setNeededInventory] = useState<Inventory[]>([]);
  const [autorizeInventory, setAutorizeInventory] = useState<Inventory[]>([]);
  const [hasLoadingInventory, setHasLoadingInventory] = useState<boolean>(false);
  const [showInventory, setShowInventory] = useState<boolean>(false);
  const [showNInventory, setShowNInventory] = useState<boolean>(false);

  /**
   * @description Список что надо выдать (левый список)
   * @return {Promise<any>}
   */
  const fetchInventoryById = async (): Promise<any> => {
    setShowInventory(false);
    try {
      const { data } = await Families.getChildInventory(id);
      setInventory(data);
      setShowInventory(true);
    } catch {
      message.error(strings.SORRY_GET_INVENTORY_ERROR);
    }
  };

  /**
   * @description Запрос на удаление.
   * @return {Promise<any>}
   */
  const deleteInventoryById = async (inventoryId: number): Promise<any> => {
    try {
      await Families.deleteInventoryFromChild(id, inventoryId);
      message.success(strings.INVENTORY_DELETED);
      await fetchInventoryById();
    } catch {
      message.error(strings.SORRY_REMOVE_INVENTORY_ERROR);
    }
  };

  const fetchAuthorizedChildInventory = async (): Promise<any> => {
    setHasLoadingInventory(true);
    try {
      const { data } = await Families.getAuthorizedChildInventory(id);
      setAutorizeInventory(data);
    } catch {
      message.error(strings.SORRY_GET_AUTORIZED_INVENTORY_ERROR);
    } finally {
      setHasLoadingInventory(false);
    }
  };

  /**
   * @description Список что надо выдать (правый список)
   * @return {Promise<any>}
   */
  const fetchNeededChildInventory = async (): Promise<any> => {
    try {
      const { data } = await Families.getNeededChildInventory(id);
      setNeededInventory(data);
      setShowNInventory(true);
    } catch {
      message.error(strings.SORRY_GET_NEEDED_INVENTORY_ERROR);
    }
  };

  const handleSubmit = async ({ material }): Promise<any> => {
    if (material) {
      try {
        await Families.createInventoryToTheChild(id, material);
        await fetchInventoryById();
      } finally {
        form.resetFields();
      }
    }
  };

  useEffect(() => {
    fetchInventoryById();
    fetchNeededChildInventory();
    fetchAuthorizedChildInventory();
  }, [id]);

  return (
    <StInventory>
      <Form onFinish={handleSubmit}>
        <Flex gap={10}>
          <Form.Item name="material" label={strings.TEACHING_MATERIALS} style={{ width: 600 }}>
            <Select
              placeholder={strings.CHOICE_MATERIAL}
              showSearch
              filterOption={filterSelect}
              loading={hasLoadingInventory}
            >
              {autorizeInventory.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Button type="primary" htmlType="submit" size="large" icon={<PlusOutlined />}>
            {strings.ADD}
          </Button>
        </Flex>
      </Form>
      <Spin spinning={!showInventory}>
        <List style={{ minHeight: 200 }}>
          {inventory.map(item => (
            <Item key={item.id}>
              <Item.Meta
                title={
                  <Flex gap={5} align="center">
                    <Popconfirm
                      title={strings.ARE_SURE_YOU_WANT_REMOVE_INVENTORY}
                      onConfirm={() => deleteInventoryById(item.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button danger size="small" icon={<DeleteOutlined />} />
                    </Popconfirm>
                    <span>{item.name}</span>
                  </Flex>
                }
              />
            </Item>
          ))}
        </List>
      </Spin>
      <Spin spinning={!showNInventory}>
        {neededInventory?.length > 0 && (
          <>
            <Title level={4}>Need to give: {neededInventory.length}</Title>
            <List style={{ minHeight: 200 }}>
              {neededInventory.map(item => (
                <Item key={item.id}>{item.name}</Item>
              ))}
            </List>
          </>
        )}
      </Spin>
    </StInventory>
  );
};

export default { TeachingMaterials };
