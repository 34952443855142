import React from 'react';
import * as family from 'Global/routes';
import { Families } from 'Admin/People/Families';
import { Family } from 'Admin/People/Families/Family';
import { Payer } from 'Admin/People/Families/Payer';
import { EditContract } from 'Admin/People/Families/Family/ExistingContracts/Edit';
import { EditFamily, EditFamilyModes } from 'Admin/People/Families/Family/EditFamily';
import { EditStudent, EditParent } from 'Admin/People/Families/Family/EditFamily/Forms';
import { NewFamily } from 'Admin/NewFamily';
import { ContractCreation } from 'Admin/People/Families/Family/ExistingContracts/Create';
import { BASE_STUDENTS } from 'Admin/People/Students/routes';
import { Students } from 'Admin/People/Students';

export const families = [
  {
    // Редактирование студента
    path: family.EDIT_STUDENT,
    component: EditStudent,
    exact: true
  },
  {
    // Добавление студента
    path: family.ADD_STUDENT_TO_FAMILY,
    component: props => <EditFamily {...props} mode={EditFamilyModes.AddStudents} />,
    exact: true
  },
  {
    // Добавление родителя
    path: family.ADD_PARENT_TO_FAMILY,
    component: props => <EditFamily {...props} mode={EditFamilyModes.AddParents} />,
    exact: true
  },
  {
    // Редактирование родителя
    path: family.EDIT_PAYER,
    component: EditParent,
    exact: true
  },
  {
    // Создание семьи
    path: family.NEW_PROFILE_FAMILY,
    component: NewFamily,
    exact: true
  },
  {
    // Редактировании семьи
    path: family.EDIT_FAMILY,
    component: props => <EditFamily {...props} mode={EditFamilyModes.EditFamilyProfile} />,
    exact: true
  },

  {
    path: [family.BASE_FAMILY, family.BASE_FAMILY_TAB, `${family.BASE_FAMILY}/create-invoice`],
    component: Family,
    exact: true
  },
  {
    path: family.BASE_FAMILIES,
    component: Families,
    exact: true
  },
  {
    path: `${family.BASE_PAYER}`,
    component: Payer,
    exact: true
  },

  {
    path: `${family.CREATE_FAMILY_CONTRACT}/:id`,
    component: ContractCreation,
    exact: true
  },
  {
    path: family.EDIT_FAMILY_CONTRACT,
    component: EditContract,
    exact: true
  },
  {
    path: family.EDIT_FAMILY_CONTRACT,
    component: EditContract,
    exact: true
  },
  {
    path: BASE_STUDENTS,
    component: Students,
    exact: true
  }
];

export default { families };
