import styled from 'styled-components';
import { slideInLeft } from 'Global/animations';
import { Card } from 'antd';

export const FamiliesContainer = styled.div`
  margin: 20px;
  padding: 30px 20px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 46px -15px rgb(0 0 0 / 12%);

  .ant-col:not(.ant-form-legacy-item-control-wrapper) {
    padding: 0 5px;
  }

  .ant-calendar {
    width: 315px !important;
  }

  // FIXME
  .ant-table-row-expand-icon {
    display: none;
  }

  .ant-tabs {
    overflow: unset;
  }
`;

export const FamilyContainer = styled.div`
  margin: 20px;
  padding: 30px;
  box-shadow: 0px 0px 46px -15px rgba(0, 0, 0, 0.12);
  .ant-btn {
    margin: 0px 10px 20px 0;
  }
  .ant-select {
    width: 100%;
  }
  .ant-col {
    padding: 0 5px;
  }
  .ant-table {
    margin: 20px 0;
  }
`;

type childrenProps = {
  isNoMargingSt?: boolean;
};

export const Children = styled.div`
  margin: ${(props: childrenProps) => (props.isNoMargingSt ? '0' : '20px')};
  padding: 30px;
  animation: ${slideInLeft} 0.6s ease-in;
  .ant-col {
    padding: 0 5px;
  }
  .ant-tabs {
    overflow: unset;
  }
`;

type PayerType = {
  isNewPayer?: boolean;
};

export const PayerContainer = styled.div<{ isNewPayer?: boolean }>`
  margin: ${(props: PayerType) => (props.isNewPayer ? '20px 0' : '20px')};
  padding: ${props => (props.isNewPayer ? '30px 0' : '30px')};
  animation: ${slideInLeft} 0.6s ease-in;
  .ant-col {
    padding: 0 5px;
  }
  .ant-checkbox-wrapper {
    width: 100%;
    margin: 0;
    padding: 14px 0;
  }

  .ant-tabs {
    overflow: unset;
  }
`;

export const Edit = styled(FamilyContainer)`
  animation: ${slideInLeft} 0.6s ease-in;
  .ant-col {
    padding: 0 5px;
  }
  .ant-input-number {
    width: 100%;
  }
  form textarea.ant-input {
    margin-bottom: 10px;
  }
`;

export const Invoice = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 15px 0;
  margin: 25px 0 0 0;
  border: 2px dashed #eee;
  background: #faf6f6;
  div.ant-typography,
  .ant-typography p {
    font-size: 14px;
  }
  .ant-input {
    margin-bottom: 0;
  }
`;

export const InvoiceButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;

export const TitleWithButtons = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;

  & h3 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0;
  }

  & .ant-btn {
    margin: 0 0 0 6px;
    font-size: 12px;
    padding: 0 10px;
  }
`;

export const FamilyUsers = styled.ul`
  margin: 0;
  padding: 0;
`;

export const Info = styled.div`
  display: flex;

  @media (max-width: 920px) {
    flex-direction: column;
  }
`;

export const AvaInfo = styled.div<{ width?: number }>`
  flex: 0 0 ${props => (props.width ? `${props.width}px` : '150px')};

  @media (max-width: 920px) {
    flex: auto;
    margin-bottom: 40px;
  }
`;

export const ItemInfo = styled.div<{ width?: number }>`
  flex: 0 0 ${props => (props.width ? `${props.width}%` : '25%')};
  max-width: ${props => (props.width ? `${props.width}%` : '25%')};
  margin-bottom: 24px;

  @media (max-width: 1340px) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  @media (max-width: 1100px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (max-width: 920px) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  & .box {
    padding: 0 10px;
  }
`;

export const ItemInfoTitle = styled.h3`
  font-size: 12px;
  font-weight: 700;
  margin: 0 0 8px;
  text-transform: uppercase;
  color: #777;
`;

export const ItemInfoContent = styled.div`
  display: flex;
  font-size: 16px;
  color: #222;
  flex-wrap: wrap;
  padding: 0 10px 0 0;
  .ant-tag {
    margin-bottom: 8px;
  }
`;

export const StyledCard = styled(Card)`
  border-radius: 5px;
  margin-bottom: 20px;
  background: #f6f6f6;
  border: none;
`;

export default {
  FamiliesContainer,
  FamilyContainer,
  Children,
  PayerContainer,
  Edit,
  Invoice,
  TitleWithButtons,
  FamilyUsers,
  Info,
  AvaInfo,
  ItemInfo,
  ItemInfoTitle,
  ItemInfoContent,
  StyledCard
};
