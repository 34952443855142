import styled from 'styled-components';
import { Layout } from 'antd';

const { Content } = Layout;

export const RowStyle = { width: '100%' };

export const StyledContent = styled(Content)<{ hasCollapse?: boolean }>`
  padding-top: 70px;
  padding-inline-start: ${({ hasCollapse }) => (hasCollapse ? '80px' : '220px')};
  overflow-x: initial;
  width: 100%;

  @media (max-width: 991px) {
    table {
      //min-width: 960px;
    }
  }

  @media (max-width: 576px) {
    padding-inline-start: 0;
  }

  table {
    overflow-x: scroll;
  }
`;

export default { RowStyle, StyledContent };
