import React from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select } from 'antd';
import { GetFieldDecoratorOptions } from '@ant-design/compatible/lib/form/Form';
import { CUSTOM_FIELD_BRANCH_ID } from 'Global/modules/Support/Modal/constants';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Option } = Select;

interface BranchSelectProps {
  getFieldDecorator<T extends Object = {}>(
    id: keyof T,
    options?: GetFieldDecoratorOptions
  ): (node: React.ReactNode) => React.ReactNode;
  initialValue?: string;
}

/**
 * @description Селект выбора филиалов
 * @param {object} props
 * @return {React.ReactNode}
 */
export const BranchSelect = (props: BranchSelectProps) => {
  const { getFieldDecorator, initialValue } = props;
  const [strings] = useLanguageContext();

  // Вынужденный костыль
  const getInitialBranchValue = () => {
    if (initialValue === 'Saint-Petersburg') {
      return 'spb';
    }
    return initialValue;
  };

  return (
    <MyFormItem label={strings.BRANCH} colon={false}>
      {getFieldDecorator(CUSTOM_FIELD_BRANCH_ID, {
        rules: [{ required: false }],
        initialValue: getInitialBranchValue()
      })(
        <Select placeholder={strings.SELECT_BRANCH}>
          <Option value="">&nbsp;</Option>
          <Option value="Moscow" key="0">
            {strings.MOSCOW}
          </Option>
          <Option value="spb" key="1">
            {strings.SAINT_PETERSBURG}
          </Option>
          <Option value="Riga" key="2">
            {strings.RIGA}
          </Option>
          <Option value="Paris" key="3">
            {strings.PARIS}
          </Option>
          <Option value="London" key="4">
            {strings.LONDON}
          </Option>
          <Option value="tbilisi" key="5">
            {strings.TBILISI}
          </Option>
          <Option value="Other" key="6">
            {strings.OTHER_BRANCH}
          </Option>
        </Select>
      )}
    </MyFormItem>
  );
};

export default { BranchSelect };
