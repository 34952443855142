import { isEmpty } from 'lodash';
import queryString from 'query-string';
import { Location } from 'history';

export default class BaseFilter {
  /**
   * @description Получение текущего объекта с параметрами
   * @param {object} filter - Объект с полями фильтра
   * @param {number|null} page - Текущая страница
   * @param {object} sort - Объект с полями сортировки
   * @param {number|null} limit - Лимит на получение семей
   * @return {*}
   */
  public getFilterParams(filter: any, page: number, sort: any, limit: number): any {
    return {
      ...filter,
      ...sort,
      limit: limit,
      page: page
    };
  }

  /**
   * @description - Получение текущего фильтра в виде строки с get параметрами
   * @param {object} params - параметры
   * @return {string} queryString - строка с get параметрами
   */
  public getQueryStringParams(params: any, options?: any): string {
    return queryString.stringify(params, options);
  }

  /**
   * @description - Получение текущего фильтра из адресной строки, либо если пусто {}
   * @return {boolean|object} params
   */
  public getLocationSearchParams(location: Location, options?: any): any | boolean {
    const { search } = location;
    const params = queryString.parse(search, options);
    return isEmpty(params) ? {} : params;
  }
}
