import React from 'react';
import { Table, Row } from 'antd';
import Pagination from 'Global/components/Pagination';
import { Loader } from 'Global/components/Loader/Loader';
import { scrollTop } from 'helpers/scroll';
import { columns } from './columns';
import { OriginalCourse, OriginalCoursesParams } from 'types/courses';
import { PaginationInfo } from 'types/global';
import CoursesService from 'helpers/services/CoursesService';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';

interface TableProps {
  fetchOriginalCourses: (params: OriginalCoursesParams) => Promise<any>;
  originalCourses: OriginalCourse[];
  paginationInfo: PaginationInfo;
  loading: boolean;
  handleRowClick?: (course: OriginalCourse) => void;
  hasActionColumn?: boolean;
}

/**
 * @description Таблица оригинального курса
 * @param {object} props
 * @return {React.ReactNode}
 */
export const OriginalCoursesTable = (props: TableProps) => {
  const { fetchOriginalCourses, originalCourses, paginationInfo, loading, handleRowClick, hasActionColumn } = props;

  const services = new CoursesService();
  const { locationSearchParams } = useLocationSearchParams();
  const { pageCount, current }: any = paginationInfo || '';

  /**
   * @param {number} page
   * @return {void}
   */
  const handleChangePage = async (page: number) => {
    const search = services.getFilterParams(locationSearchParams, page, null, 30);
    await fetchOriginalCourses(search);
    scrollTop();
  };

  return (
    <Loader spinning={loading}>
      <Row gutter={[10, 10]}>
        <Table
          rowKey="id"
          dataSource={originalCourses}
          columns={columns(hasActionColumn)}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => handleRowClick && handleRowClick(record)
            };
          }}
        />
        {pageCount > 1 && <Pagination count={pageCount} current={current} pageChange={handleChangePage} />}
      </Row>
    </Loader>
  );
};

export default { OriginalCoursesTable };
