import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, Row, Input, Button, Checkbox, Typography, message, Spin } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import JuliaPhoto from 'img/julia.jpeg';
import { User } from 'types/user';
import { sendMessageJulia } from 'api/Messages';
import { useLanguageContext } from 'contexts/LanguageContext';
import { hasMoscowBranch } from 'helpers';

const { TextArea } = Input;
const { Paragraph } = Typography;

interface ModalMessageProps extends FormComponentProps {
  onClose?: () => void;
  visible: boolean;
  title: string;
  user?: User;
}

/**
 * @description Модальное окно сообщения Юлии Десятниковой
 * @param {function} onClose функция для закрытия модального окна
 * @param {boolean} visible флаг для скрытия/открытия окна
 * @param {string} title - заголовок
 * @param {object} form форма
 * @param {User} user юзер
 * @return {React.ReactNode}
 */
const ModalMessageTemplate = ({ onClose, visible, title, form, user }: ModalMessageProps) => {
  const [strings] = useLanguageContext();
  const [hasLoadSendMessage, setLoadSendMessage] = useState<boolean>(false);
  const { getFieldDecorator, getFieldsValue } = form;
  const hasParent = user?.hasRoles?.parent;

  /**
   * @description Form Submit
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    const values = getFieldsValue();

    if (values.message) {
      setLoadSendMessage(true);
      sendMessageJulia(values.message)
        .then(() => {
          message.success(strings.THE_MESSAGE_HAS_BEEN_SENT_SUCCESSFULLY);
          onClose();
        })
        .catch(error => {
          message.error(error.response.data.message);
        })
        .finally(() => setLoadSendMessage(false));
    }
  };

  return (
    (<Modal
      width={700}
      open={visible}
      title={title}
      onCancel={onClose}
      footer={[
        <Button type="primary" size="large" onClick={handleSubmit}>
          {strings.SEND}
        </Button>
      ]}
    >
      <Spin spinning={hasLoadSendMessage} tip={`${strings.SENDING_MESSAGE}...`}>
        <Row>
          {hasParent && hasMoscowBranch(user?.branch) && (
            <>
              <img
                src={JuliaPhoto}
                alt={strings.JULIA_DESIATNIKOVA}
                style={{ width: '100%', opacity: 0.8, marginBottom: '1rem' }}
              />
              <Paragraph>{strings.TEXT_MESSAGE_FOR_JULIA_DESIATNIKOVA}</Paragraph>
            </>
          )}
          <Form layout="vertical" onSubmit={handleSubmit} style={{ width: '100%' }}>
            <MyFormItem label={strings.MESSAGE}>
              {getFieldDecorator('message', {
                rules: [{ required: false }]
              })(<TextArea rows={10} placeholder={strings.TELL_US_ABOUT_YOUR_PROBLEM} />)}
            </MyFormItem>

            <MyFormItem>
              {getFieldDecorator('hasSendMessage', {
                rules: [{ required: false }]
              })(<Checkbox>{strings.SEND_A_COPY_OF_THE_MESSAGE_TO_YOUR_TUTOR}</Checkbox>)}
            </MyFormItem>
          </Form>
        </Row>
      </Spin>
    </Modal>)
  );
};

export const ModalMessageToYulia = Form.create<ModalMessageProps>({})(ModalMessageTemplate);

export default { ModalMessageToYulia };
