import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { Modal } from 'antd';
import { useUserContext } from 'contexts/UserContext';

export function BirthdayModal({ hasShowBirthday, setHasShowBirthday }) {
  const [user] = useUserContext();

  const checkStudentBirthday = () => {
    const birthday = user?.birthday;
    if (birthday) {
      const currentDayjs = dayjs(),
        momentBirthday = dayjs(birthday).year(currentDayjs.year());
      const diff = currentDayjs.diff(momentBirthday, 'days');
      if (diff >= 0 && diff <= 7) {
        setHasShowBirthday(true);
      }
    }
  };

  useEffect(() => {
    if (user?.hasRoles?.student) {
      checkStudentBirthday();
    }
  }, []);

  return (
    (<Modal title="Happy Birthday!" open={hasShowBirthday} footer={[]} onCancel={() => setHasShowBirthday(false)}>Dear friend!<br />Happy Birthday!<br />We hope that LGEG has helped you reach new heights, discover new opportunities and meet interesting people!<br />We wish you success in everything! Keep moving forward and fear nothing, we are always there for you!<br />
      <br />Julia Desyatnikova and the LG team</Modal>)
  );

  return null;
}
