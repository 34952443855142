import React from 'react';
import { Row, Col, Statistic } from 'antd';
import { Admin, IParent, IStudent, SearchContract } from 'types';
import { Teacher } from 'types/Teachers/teacher';

interface StatsProps {
  admins: Admin[];
  students: IStudent[];
  parents: IParent[];
  teachers: Teacher[];
  contracts: SearchContract[];
  isLoadSearchData: boolean;
  hasRoleTopManager: boolean;
  totalCount: number;
}

/**
 * @description Результаты поиска статистика
 * @param {StatsProps} props
 * @return {React.ReactNode}
 */
export const Stats = (props: StatsProps) => {
  const { admins, students, parents, totalCount, teachers, isLoadSearchData, hasRoleTopManager } = props;

  return (
    <div style={{ minHeight: totalCount > 0 ? 90 : 0, textAlign: isLoadSearchData ? 'center' : 'left' }}>
      {!isLoadSearchData && (
        <>
          <Row style={{ marginBottom: '30px' }}>
            {hasRoleTopManager && admins && admins?.length > 0 && (
              <Col lg={4}>
                <Statistic title="Admins" value={admins.length} />
              </Col>
            )}

            {students && students?.length > 0 && (
              <Col lg={4}>
                <Statistic title="Students" value={students.length} />
              </Col>
            )}

            {parents && parents.length > 0 && (
              <Col lg={4}>
                <Statistic title="Parents" value={parents.length} />
              </Col>
            )}

            {teachers && teachers?.length > 0 && (
              <Col lg={4}>
                <Statistic title="Teachers" value={teachers.length} />
              </Col>
            )}

            {totalCount > 0 && (
              <Col lg={4}>
                <Statistic title="Total" value={totalCount} />
              </Col>
            )}
          </Row>
        </>
      )}
    </div>
  );
};

export default { Stats };
