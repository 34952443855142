import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select, Button, message, Modal, DatePicker, Row, Col, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { Global } from 'api/Global';
import { addStudentToGroup } from 'api/Families/child';
import { SearchChild } from 'types/global';
import { useLanguageContext } from 'contexts/LanguageContext';
import { dateFormat, dateFormatForBackend } from 'helpers';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import dayjs from 'dayjs';
import { Branch } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useUserContext } from 'contexts/UserContext';

const { Option } = Select;

interface AddStudentProps extends FormComponentProps {
  groupId: number;
  handler?: () => Promise<any>;
}

/**
 * @description Добавление студента в группу
 * @param {AddStudentProps} props
 * @return {React.ReactNode}
 */
export const AddStudentTemplate = ({
  groupId,
  handler,
  form: { getFieldDecorator, validateFields, resetFields }
}: AddStudentProps) => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const { branches } = useGlobalCollectionsContext();

  const [selectedBranch, setSelectedBranch] = useState<number>(user?.branch?.id);
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const [searchStudentsList, setSearchStudentsList] = useState<SearchChild[]>([]);
  const [hasLoadingStudentsList, setHasLoadingStudentsList] = useState<boolean>(false);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [typing, setTyping] = useState<Boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  /**
   * @description Добавление студента в список класса
   * @return {Promise<any>}
   */
  const handleSubmit = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();

    validateFields(async (err, values) => {
      if (!err) {
        const { startAt, student } = values;
        const backendFormatDate = dayjs(startAt).format(dateFormatForBackend);
        setHasLoading(true);
        try {
          await addStudentToGroup(groupId, student, { startAt: backendFormatDate });
          handler();
          message.success(strings.STUDENT_SUCCESSFULLY_ADDED);
          setHasShowModal(false);
        } catch (err) {
          message.error(err.response.data.message);
        } finally {
          setSearchStudentsList([]);
          setHasLoading(false);
        }
      }
    });
  };

  /**
   * @description Поиск студента по имени
   * @param {string} query
   * @return {Promise<any>}
   */
  const searchByChildName = async (query: string): Promise<any> => {
    setSearchValue(query);
    if (query) {
      setHasLoadingStudentsList(true);
      try {
        const {
          data: { items }
        } = await Global.searchChildByName({ query, limit: 1000, branch: selectedBranch });
        setSearchStudentsList(items);
      } catch {
      } finally {
        setHasLoadingStudentsList(false);
      }
    }
  };

  useEffect(() => {
    if (searchValue) {
      setTyping(true);
    } else {
      setTyping(false);
    }
  }, [searchValue]);

  const footer = [
    <Button
      onClick={() => {
        setHasShowModal(false);
        resetFields();
      }}
      key="cancelBtn"
    >
      {strings.CANCEL}
    </Button>,
    <Button type="primary" onClick={handleSubmit} key="addToGroup" loading={hasLoading}>
      {strings.ADD_TO_A_SUPERGROUP}
    </Button>
  ];

  return (
    <>
      <Modal
        title={strings.ADD_A_NEW_STUDENT}
        open={hasShowModal}
        onCancel={() => {
          setHasShowModal(false);
          resetFields();
        }}
        footer={footer}
      >
        <Form layout="vertical">
          <Row gutter={[10, 10]}>
            {branches.length > 1 && (
              <Col lg={12} xs={12}>
                <MyFormItem label={strings.BRANCH}>
                  <Select
                    defaultValue={user?.branch?.id}
                    placeholder={strings.BRANCH}
                    onChange={val => setSelectedBranch(Number(val))}
                  >
                    {branches.map((branch: Branch) => (
                      <Option value={branch.id} key={branch.id}>
                        {branch.name}
                      </Option>
                    ))}
                  </Select>
                </MyFormItem>
              </Col>
            )}
            <Col lg={12} xs={12}>
              <MyFormItem label={strings.STUDENT}>
                {getFieldDecorator('student', {
                  rules: [{ required: true }]
                })(
                  <Select
                    showSearch
                    allowClear
                    placeholder={strings.SEARCH_STUDENT}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onSearch={debounce(searchByChildName, 800)}
                    notFoundContent={
                      hasLoadingStudentsList ? (
                        <Spin size="small" />
                      ) : (
                        <p>{!typing ? strings.START_TYPING : strings.NOT_FOUND}</p>
                      )
                    }
                    loading={hasLoadingStudentsList}
                  >
                    {searchStudentsList.map((student: SearchChild) => (
                      <Option value={student?.id} key={student?.id}>
                        {student?.name} {student?.surname}
                      </Option>
                    ))}
                  </Select>
                )}
              </MyFormItem>
            </Col>
            <Col lg={12} xs={12}>
              <MyFormItem label={strings.DATE}>
                {getFieldDecorator('startAt', {
                  initialValue: dayjs(),
                  rules: [{ required: true, message: strings.SELECT_DATE }]
                })(<DatePicker placeholder={`${strings.SELECT_DATE}`} format={dateFormat} />)}
              </MyFormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Button onClick={() => setHasShowModal(true)} type="primary">
        {strings.ADD_A_NEW_STUDENT}
      </Button>
    </>
  );
};

export const AddStudent = Form.create<AddStudentProps>({})(AddStudentTemplate);

export default { AddStudent };
