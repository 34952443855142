import React, { useEffect, useState } from 'react';
import { Col, Select, Row, Collapse } from 'antd';
import { Loader } from 'Global/components/Loader/Loader';
import { FilterContainer } from '../styles';
import { lowerCase } from 'lodash';
import { SuperGroup } from './Supergroup';
import { SupergroupCounter, SupergroupCount } from '../styles';
import { Supergroups, Supergroup } from 'types/education';
import { Branch } from 'types';
import { useUserContext } from 'contexts/UserContext';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useHistory } from 'react-router-dom';
import { useCoursesRepository } from 'repos/CoursesRepository';

const { Panel } = Collapse;
const { Option } = Select;

/**
 * @description Placement component
 * @return {React.ReactNode}
 */
export const Placement = () => {
  const [superGroups, setSuperGroups] = useState<Supergroups[]>([]);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const { branches } = useGlobalCollectionsContext();
  const [user] = useUserContext();
  const [branch, setBranch] = useState<number>(user?.branch?.id);
  const [strings] = useLanguageContext();
  const { goBack } = useHistory();
  const coursesRepository = useCoursesRepository();

  const hasTopManagerRole = user?.hasRoles?.topManager;
  const hasDirector =
    user?.hasRoles?.branchDirector || user?.hasRoles?.branchOperationDepartament || user?.hasRoles?.academicExpert;

  /**
   * @description Обработчик на выбор филиала
   * @param {number} branchId
   * @return {void}
   */
  const handleChange = (branchId: number): void => {
    setBranch(branchId);
  };

  /**
   * @description Список супергрупп
   * @param {GetListSupergroupsParams} params
   * @return {Promise<any>}
   */
  const getSuperGroups = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await coursesRepository.getPlacement({ branch });
      setSuperGroups(data);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    if (branch) {
      getSuperGroups();
    }
  }, [branch]);

  return (
    <Container>
      <StPageHeader onBack={goBack} title={strings.PLACEMENT} />
      {hasTopManagerRole && !hasDirector && (
        <Row>
          <Col lg={7}>
            <FilterContainer>
              <Select placeholder={strings.SELECT_BRANCH} onChange={handleChange} defaultValue={branch}>
                {branches.map((branch: Branch) => (
                  <Option value={branch.id} key={branch.id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            </FilterContainer>
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: 10 }}>
        <Col lg={24}>
          <Loader spinning={hasLoading}>
            <Collapse style={{ minHeight: 500 }}>
              {superGroups.map((group: Supergroups) => {
                const { supergroups, name, id } = group;
                const lenSupergroups = supergroups?.length;
                const lenStudents = supergroups?.reduce(
                  (acc, supergroup: Supergroup) => acc + supergroup.students.length,
                  0
                );
                const extra = () => (
                  <SupergroupCounter>
                    <SupergroupCount>
                      {lowerCase(strings.SUPERGROUPS)} <mark>{lenSupergroups}</mark>
                    </SupergroupCount>
                    <SupergroupCount>
                      {lowerCase(strings.STUDENTS)} <mark>{lenStudents}</mark>
                    </SupergroupCount>
                  </SupergroupCounter>
                );

                return (
                  <Panel header={name} key={id} disabled={!lenSupergroups} extra={extra()}>
                    {supergroups?.map((supergroup: Supergroup) => {
                      return (
                        <SuperGroup
                          key={supergroup.id}
                          supergroup={supergroup}
                          currentBranchId={branch}
                          getSuperGroups={getSuperGroups}
                        />
                      );
                    })}
                  </Panel>
                );
              })}
            </Collapse>
          </Loader>
        </Col>
      </Row>
    </Container>
  );
};

export default { Placement };
