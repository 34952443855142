import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, Button, DatePicker, InputNumber, Select, message, Row, Col } from 'antd';
import { isEmpty, first } from 'lodash';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { Families } from 'api/Families';
import { ISmallChild, IStudent, ModalMode } from 'types';
import { ModeActionMap } from 'Global/constants';
import { momentFormatValues } from 'helpers/momentFormatValues';
import { useInvoicesRepository } from 'repos/InvoicesRepository';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Option } = Select;

interface IDiscount {
  discount: number;
  startAt: string;
  endAt: string;
  child?: ISmallChild;
}

interface AddDiscountProps extends React.ComponentProps<any> {
  form: WrappedFormUtils;
  id: number;
  currentDiscountId: number;
  show: boolean;
  students: IStudent[];
  hideModal: () => () => any;
  reloadDiscounts: () => any;
  values?: IDiscount;
  mode: ModalMode;
}

/**
 * @description - Компонент модального окна добавления скидки
 * @param {props} - object - component props
 * @param {ModalMode} - mode - Режим формы - Спрятана, Добавить, Изменить, Удалить
 * @param {function} - hideModal - Метод для закрытия окна
 * @return {React.ReactNode}
 */
export default Form.create<AddDiscountProps>({})((props: AddDiscountProps) => {
  const {
    form: { getFieldDecorator, validateFields },
    mode,
    id,
    currentDiscountId,
    students,
    hideModal,
    reloadDiscounts,
    values
  } = props;

  const [strings] = useLanguageContext();
  const invoicesRepository = useInvoicesRepository();
  const childrenOnlyIds = students?.map((child: IStudent) => child.id);
  const [selectStudents, setSelectStudents] = useState<number[]>([]);

  const closeModal = () => {
    reloadDiscounts();
    hideModal();
  };

  const handleDelete = async () => {
    try {
      const { status } = await Families.removeFamilyDiscountById(currentDiscountId);
      if (status === 200) {
        setTimeout(() => {
          message.success(strings.DISCOUNT_DELETED, 2);
        }, 2000);
      }
    } catch {}
    closeModal();
  };

  const handleStudentChange = (id: number) => {
    //id === 0 имеет значение All students
    if (id === 0) {
      setSelectStudents(childrenOnlyIds);
    } else {
      setSelectStudents([id]);
    }
  };

  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    validateFields(async (errors, values) => {
      if (isEmpty(errors)) {
        message.loading(`${strings.DISCOUNT} ${ModeActionMap[mode]}ing...`, 4);
        const params = momentFormatValues(values);
        if (mode === ModalMode.Add) {
          try {
            params['children'] = selectStudents;
            const { status } = await invoicesRepository.createFamilyDiscount(id, params);
            if (status === 200) {
              setTimeout(() => {
                message.success(strings.DISCOUNT_ADDED, 2);
              }, 2000);
            }
          } catch {}
        } else if (mode === ModalMode.Edit) {
          try {
            params['child'] = first(selectStudents);
            const { status } = await invoicesRepository.editFamilyDiscountById(currentDiscountId, params);
            if (status === 200) {
              setTimeout(() => {
                message.success(strings.DISCOUNT_UPDATED, 2);
              }, 2000);
            }
          } catch {}
        }
        closeModal();
      }
    });
  };

  const getFooter = () => {
    const buttons = [
      <Button key="back" onClick={hideModal}>
        {strings.CANCEL}
      </Button>
    ];
    if (mode === ModalMode.Add) {
      buttons.push(
        <Button key="createBtn" type="primary" htmlType="submit" onClick={handleSubmit}>
          {strings.CREATE}
        </Button>
      );
    } else if (mode === ModalMode.Edit) {
      buttons.push(
        <Button key="updateBtn" type="primary" htmlType="submit" onClick={handleSubmit}>
          {strings.UPDATE}
        </Button>
      );
    } else if (mode === ModalMode.Delete) {
      buttons.push(
        <Button key="removeBtn" danger htmlType="submit" onClick={handleDelete}>
          {strings.REMOVE}
        </Button>
      );
    }
    return buttons;
  };

  const modalTitle =
    mode === ModalMode.Add
      ? strings.ADDING_NEW_DISCOUNT
      : mode === ModalMode.Edit
      ? strings.EDIT_DISCOUNT
      : mode === ModalMode.Delete
      ? strings.DELETE_DISCOUNT
      : '';

  const form = (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Row gutter={24}>
        <Col span={24}>
          <MyFormItem label={strings.DISCOUNT_FACTOR}>
            {getFieldDecorator('discount', {
              rules: [{ required: true, message: strings.DISCOUNT_FACTOR }],
              initialValue: values ? values.discount : 0
            })(<InputNumber placeholder={strings.DISCOUNT_FACTOR} />)}
          </MyFormItem>
        </Col>
        <Col span={12}>
          <MyFormItem label={strings.DATE_START}>
            {getFieldDecorator('startAt', {
              rules: [{ required: true, message: strings.DATE_IS_REQUIRED_FIELD }],
              initialValue: values ? dayjs(values.startAt) : dayjs()
            })(<DatePicker placeholder={strings.DATE_START} />)}
          </MyFormItem>
        </Col>
        <Col span={12}>
          <MyFormItem label={strings.DATE_END}>
            {getFieldDecorator('endAt', {
              rules: [{ required: true, message: strings.DATE_IS_REQUIRED_FIELD }],
              initialValue: values ? dayjs(values.endAt) : dayjs()
            })(<DatePicker placeholder={strings.END_DATE} />)}
          </MyFormItem>
        </Col>
        <Col span={24}>
          <MyFormItem label={strings.STUDENTS_WHO_HAVE_A_DISCOUNT}>
            <Select
              defaultValue={values && values?.child ? values?.child.id : 0}
              onChange={handleStudentChange}
              placeholder={strings.CHOOSE_STUDENT}
            >
              {mode === ModalMode.Add && (
                <Option value={0} key="add-discount-child-id-0">
                  {strings.ALL_STUDENTS}
                </Option>
              )}
              {students &&
                students?.map((child: IStudent) => (
                  <Option value={child.id} key={`add-discount-child-id-${child.id}`}>
                    {child.user.surname} {child.user.name}
                  </Option>
                ))}
            </Select>
          </MyFormItem>
        </Col>
      </Row>
    </Form>
  );
  useEffect(() => {
    //при создании discount в поле "Student who have a discount" по умолчанию значение "All students"
    if (mode === ModalMode.Add) {
      setSelectStudents(childrenOnlyIds);
    } else {
      const { child } = values || {};
      if (child) {
        setSelectStudents([child.id]);
      }
    }
  }, [values, mode]);

  return (
    (<Modal title={modalTitle} footer={getFooter()} open={mode !== ModalMode.Hidden} onCancel={hideModal}>
      {mode === ModalMode.Add || mode === ModalMode.Edit ? form : strings.ARE_YOU_SURE_WANT_TO_DELETE_DISCOUNT}
    </Modal>)
  );
});
