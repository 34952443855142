import React from 'react';
import { PROGRESS_REPORT_TIPS } from '../constants';
import { CHAMELEON_WITH_LAMP } from 'img/chameleonWithLamp';
import ExternalCodeService from 'helpers/services/ExternalCodeService';
import ym from 'react-yandex-metrika';
import { Tooltip } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';

const externalCodeService = new ExternalCodeService();

/**
 * @description - Кнопка FAQ для прогресс репортов (quick tips)
 * @return {React.ReactNode}
 */
export const QuickTips = (props: { label?: string; url?: string }) => {
  const [strings] = useLanguageContext();
  const { label, url = PROGRESS_REPORT_TIPS } = props;

  const handleYandexReachGoal = () => {
    if (!externalCodeService.isLocalEnvironment()) {
      ym('reachGoal', 'quickTips');
    }
  };

  if (label) {
    return (
      <a href={url} target="_blank" onClick={handleYandexReachGoal} style={{ display: 'flex', alignItems: 'center' }}>
        <img style={{ width: 40, marginInlineEnd: 5 }} src={CHAMELEON_WITH_LAMP} />
        {label}
      </a>
    );
  }
  return (
    <Tooltip title={strings.QUICK_TIPS}>
      <a href={url} target="_blank" onClick={handleYandexReachGoal} style={{ display: 'flex', alignItems: 'center' }}>
        <img style={{ width: 40, marginInlineEnd: 5 }} src={CHAMELEON_WITH_LAMP} />
      </a>
    </Tooltip>
  );
};

export default { QuickTips };
