import React from 'react';
import styled from 'styled-components';
import { FrownOutlined } from '@ant-design/icons';
import { useLanguageContext } from 'contexts/LanguageContext';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;

  & .anticon {
    font-size: 30px;
  }

  & span {
    margin-inline-start: 12px;
    font-size: 16px;
    color: #777;
  }
`;

/**
 * @description Если нет пользователей, показывать сообщением об этом
 * @return {React.ReactNode}
 */
export const NoUsers = () => {
  const [strings] = useLanguageContext();
  return (
    <Container>
      <FrownOutlined />
      <span>{strings.YOU_DONT_HAVE_ACTIVE_CONVERSATIONS}</span>
    </Container>
  );
};

export default { NoUsers };
