import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import { Checkbox, Col, Row, Select } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useHistory } from 'react-router-dom';
import { Branch } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { IPerformerFilterParams } from 'types/performers';
import { FilterButtons } from 'Global/components/FilterButtons';

const { Option } = Select;

interface PerformersFilterProps extends FormComponentProps {
  onFilter: (params?: IPerformerFilterParams) => Promise<any>;
}

export const Filter = Form.create<PerformersFilterProps>({})((props: PerformersFilterProps) => {
  const [strings] = useLanguageContext();
  const {
    form: { getFieldDecorator, resetFields, validateFieldsAndScroll },
    onFilter
  } = props;
  const { locationSearchParams } = useLocationSearchParams();
  const { branches } = useGlobalCollectionsContext();

  const { push } = useHistory();
  const { branch, isActive } = locationSearchParams || {};
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);

  /**
   * @description Очистка фильтра
   * @return {void}
   */
  const handleClear = async (): Promise<any> => {
    resetFields();
    push({ search: '' });
    await onFilter();
  };

  /**
   * @description Submit формы
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values): Promise<any> => {
      if (!errors) {
        try {
          setHasSubmitLoading(true);
          await onFilter(values);
        } finally {
          setHasSubmitLoading(false);
        }
      }
    });
  };

  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Row gutter={[10, 10]}>
        <Col lg={6}>
          {getFieldDecorator('branch', {
            initialValue: branch,
            rules: [{ required: false }]
          })(
            <Select placeholder={strings.BRANCH}>
              {branches &&
                branches.map((branch: Branch) => (
                  <Option value={branch.id} key={branch.id}>
                    {branch.name}
                  </Option>
                ))}
            </Select>
          )}
        </Col>
        <Col lg={3}>
          {getFieldDecorator('isActive', {
            initialValue: isActive,
            valuePropName: 'checked'
          })(<Checkbox>{strings.ACTIVE}</Checkbox>)}
        </Col>
        <FilterButtons handleClear={handleClear} isSameRow hasSubmitLoading={hasSubmitLoading} />
      </Row>
    </Form>
  );
});
export default { Filter };
