import React, { useMemo, useState } from 'react';
import { Tabs } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useHistory, useLocation } from 'react-router-dom';
import { TeacherTagsTab } from './TeacherTagsTab';
import { FamilyTagsTab } from './FamilyTagsTab';
const { TabPane } = Tabs;

export const Tags = () => {
  const [strings] = useLanguageContext();
  const { goBack, push } = useHistory();

  const location = useLocation();
  const initialTab = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('tab') || 'teacher';
  }, []);

  const [activeTab, setActiveTab] = useState(initialTab);

  const handleTabChange = key => {
    setActiveTab(key);
    push({ search: `?tab=${key}` });
  };

  return (
    <Container>
      <StPageHeader onBack={goBack} title={strings.TAGS} />
      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab={strings.TEACHER} key="teacher">
          <TeacherTagsTab />
        </TabPane>
        <TabPane tab={strings.FAMILY} key="family">
          <FamilyTagsTab />
        </TabPane>
      </Tabs>
    </Container>
  );
};

export default { Tags };
