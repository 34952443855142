import React, { useMemo, useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Row, Button, Flex } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';

export const ShowMoreList = ({ items, itemsCount }: { items: string[]; itemsCount?: number }) => {
  const defItemsCount = 2;
  const [hasShowFullList, setHasShowFullList] = useState<boolean>(false);
  const [strings] = useLanguageContext();
  const briefList = items.slice(0, itemsCount ? itemsCount : defItemsCount);
  const list = useMemo(() => {
    return hasShowFullList ? items : briefList;
  }, [hasShowFullList]);

  return (
    <Flex vertical align="middle">
      <ul style={{ flex: 1, paddingInlineEnd: 10 }}>
        {list?.map(text => (
          <li>{text}</li>
        ))}
      </ul>
      <Button
        type="link"
        onClick={() => {
          setHasShowFullList(prevState => !prevState);
        }}
      >
        <Flex gap={5} align="center" justify="end">
          {hasShowFullList ? (
            <>
              {strings.SHOW_LESS}
              <UpOutlined />
            </>
          ) : (
            <>
              {strings.SHOW_MORE}
              <DownOutlined />
            </>
          )}
        </Flex>
      </Button>
    </Flex>
  );
};

export default { ShowMoreList };
