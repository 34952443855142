import React from 'react';
import styled from 'styled-components';
import { Badge, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { Assistant } from 'types/education';
import { BASE_TEACHERS } from 'Admin/People/Teachers/routes';
import ZoomIcon from 'img/zoom.png';
import { useUserContext } from 'contexts/UserContext';

const StyledAssistants = styled.div`
  width: 100%;
`;
const { Paragraph } = Typography;
export const PopoverInfoLine = ({ title, children }) => (
  <p>
    <b>{title}:</b> {children}
  </p>
);

/**
 * @description Zoom информация в popover
 * @param hasZoom
 * @param roomMetadata
 * @constructor
 */
function LessonZoomPopoverContent({ hasZoom, roomMetadata }) {
  const [user] = useUserContext();

  if (hasZoom) {
    return (
      <>
        {user?.hasRoles?.admin && (
          <>
            <PopoverInfoLine title="Zoom login">{roomMetadata.webLogin || 'N/A'}</PopoverInfoLine>
            <PopoverInfoLine title="Zoom password">{roomMetadata.webPassword || 'N/A'}</PopoverInfoLine>
          </>
        )}
        <p>
          <a href={roomMetadata.webLink} rel="noopener noreferrer" target="_blank">
            <img src={ZoomIcon} alt="zoom link" width={18} height={18} />
            <span style={{ marginInlineStart: 10 }}>Go to zoom</span>
          </a>
        </p>
      </>
    );
  }

  return null;
}

/**
 * @description Обертка (popover) над плашками для ЛК админа|учителя
 * @param hasEvent
 * @param startTime
 * @param hasZoom
 * @param endTime
 * @param event
 * @param roomMetadata
 */
export function LessonItemPopover({ hasEvent, startTime, hasZoom, endTime, event, roomMetadata }) {
  const {
    room: { name: roomName, color },
    studentsCount
  } = event;
  const [user] = useUserContext();

  if (hasEvent) {
    return (
      <>
        <PopoverInfoLine title="Time">
          {startTime} - {endTime}
        </PopoverInfoLine>
        <PopoverInfoLine title="Conductor">{event.conductorName}</PopoverInfoLine>
        <PopoverInfoLine title="Room">
          <span style={{ margin: '0 5px ' }}>{roomName}</span>
          <Badge color={color} />
        </PopoverInfoLine>
        <LessonZoomPopoverContent hasZoom={hasZoom} roomMetadata={roomMetadata} />
      </>
    );
  }

  return (
    <>
      <PopoverInfoLine title="Time">
        {startTime} - {endTime}
      </PopoverInfoLine>
      <PopoverInfoLine title="Students count">{studentsCount}</PopoverInfoLine>
      <PopoverInfoLine title="Subject">{event?.lesson?.course?.subject?.name}</PopoverInfoLine>
      <PopoverInfoLine title="Group">{event.lesson.course.groupName}</PopoverInfoLine>
      <PopoverInfoLine title="Assistants">
        <StyledAssistants>
          {event.lesson.assistants.map((assistant: Assistant) => (
            <p>
              {assistant.name} {assistant.surname}
            </p>
          ))}
        </StyledAssistants>
      </PopoverInfoLine>
      <PopoverInfoLine title="Room">
        <span style={{ margin: '0 5px' }}>{roomName}</span>
        <Badge color={color} />
      </PopoverInfoLine>
      {event.lesson.hasOwnProperty('teacher') && (
        <PopoverInfoLine title="Teacher">
          <Link to={`${BASE_TEACHERS}/${event?.lesson?.teacher?.id}`}>
            {event.lesson?.teacher?.name} {event?.lesson?.teacher?.surname}
          </Link>
        </PopoverInfoLine>
      )}
      {user.hasRoles.teacher && event?.lesson?.title && (
        <p>
          <b>Lesson topic: </b>
          <Paragraph>{event?.lesson?.title}</Paragraph>
        </p>
      )}
      <LessonZoomPopoverContent hasZoom={hasZoom} roomMetadata={roomMetadata} />
    </>
  );
}
