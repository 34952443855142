import React, { useState, useEffect } from 'react';
import { Stage } from 'types';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';

import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { useStagesRepository } from 'repos/StagesRepository';

const { Option } = Select;

interface StageSelectProps {
  mode?: 'multiple' | 'tags';
  maxTagCount?: number;
  initialValue?: number | number[];
  isRequired?: boolean;
  label?: string;
  name?: string;
  placeholder?: string;
  form?: WrappedFormUtils;
  branchName?: string;
}

export const StageSelect: React.FC<StageSelectProps> = ({
  mode,
  maxTagCount,
  isRequired,
  initialValue,
  label,
  name,
  placeholder,
  form,
  branchName
}) => {
  const [strings] = useLanguageContext();
  const { stages } = useGlobalCollectionsContext();
  const stagesRepository = useStagesRepository();
  const [stagesList, setStagesList] = useState<Stage[]>([]);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

  const decoratorFields = {
    initialValue: initialValue
  };

  if (isRequired) {
    decoratorFields['rules'] = [{ required: true, message: strings.STAGE_IS_REQUIRED }];
  }
  const select = (
    <>
      {getFieldDecorator(
        name || 'stage',
        decoratorFields
      )(
        <Select
          loading={hasLoading}
          allowClear={true}
          mode={mode}
          maxTagCount={maxTagCount}
          placeholder={placeholder || strings.STAGE}
        >
          {stagesList
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((stage: Stage) => (
              <Option key={stage.id} value={stage.id}>
                {stage.name}
              </Option>
            ))}
        </Select>
      )}
    </>
  );

  useEffect(() => {
    setStagesList(stages);
  }, [stages]);

  useEffect(() => {
    const branchId = getFieldValue(branchName || 'branch');
    //в фильтре в расписании и в Materials All branches имеет значение -1
    if (branchId && branchId !== -1) {
      (async () => {
        const branchIds = typeof branchId === 'string' || typeof branchId === 'number' ? [branchId] : branchId;
        try {
          setHasLoading(true);
          const { data } = await stagesRepository.getStages({ branch: branchIds });
          setStagesList(data);
        } finally {
          setHasLoading(false);
        }
      })();
    } else {
      setStagesList(stages);
    }
  }, [getFieldValue(branchName || 'branch')]);

  if (label) {
    return <MyFormItem label={label}>{select}</MyFormItem>;
  }
  return select;
};
