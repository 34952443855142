import styled from 'styled-components';

export const StCalendarWidget = styled.div`
  width: 110px;
  height: 120px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
`;

export const WidgetDay = styled.p`
  text-align: center;
  width: 100%;
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #000;
`;

export const WidgetMonth = styled(WidgetDay)``;

export const WidgetHeader = styled.div`
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  width: 100%;
  height: 37px;
  background: #cc1e44;
  text-align: center;
  color: #fff;
  padding-top: 7px;
`;

export const WidgetTime = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #595959;
  width: 100%;
  display: flex;
  justify-content: center;
`;
