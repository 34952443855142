import React, { useState, useEffect } from 'react';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { Button, Descriptions, Spin, Modal, message } from 'antd';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { match } from 'react-router';
import { Container } from 'Global/styles';
import { Title } from 'Global/components/Title';
import { Families } from 'api/Families';
import { BASE_SOURCE_OF_REFERENCE } from '../routes';
import { useLanguageContext } from 'contexts/LanguageContext';
import { LearnedFrom } from 'types/Family';

/**
 * @description Информация об источнике ссылки
 * @return {React.ReactNode}
 */
export const SourceReferenceFull = () => {
  const [learnedFrom, setLearnedFrom] = useState<LearnedFrom>();
  const [loading, setLoading] = useState<boolean>(false);
  // Hooks
  const { goBack, push } = useHistory();
  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();
  const [strings] = useLanguageContext();
  const [{ confirm }, contextHolder] = Modal.useModal();

  const { title, showInForm, position, isActive } = learnedFrom || {};
  const checkIcon = <CheckCircleTwoTone style={{ fontSize: 20 }} twoToneColor="#52c41a" />;

  // Удаление learned from
  const deleteLearnedFrom = () => {
    confirm({
      title: strings.ARE_YOU_SURE_YOU_WANT_DELETE_THIS_LEARN_FROM,
      okText: strings.YES_DELETE,
      okType: 'danger',
      maskClosable: true,
      async onOk() {
        await Families.deleteLearnedFrom(id)
          .then(() => {
            message.success(strings.LEARNED_FROM_DELETED_SUCCESSFULLY);
            push(BASE_SOURCE_OF_REFERENCE);
          })
          .catch(() => {});
      }
    });
  };

  /**
   * @description Get learned from by id
   * @param {number} id
   * @return {Promise<any>}
   */
  const getLearnedFrom = async (id: number | string): Promise<any> => {
    setLoading(true);
    await Families.learnedFromById(id)
      .then(({ data }) => {
        setLearnedFrom(data);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getLearnedFrom(id);
  }, [id]); // eslint-disable-line
  return (
    <Container>
      {contextHolder}
      <Spin tip={`${strings.LOADING} ${strings.LEARNED_FROM}`} spinning={loading}>
        <Title goBack={goBack}>
          {strings.SOURCE_OF_REFERENCE} {id}
          <Button
            size="small"
            onClick={() => push(`${BASE_SOURCE_OF_REFERENCE}/${id}/edit`)}
            style={{ marginInlineStart: 6 }}
          >
            {strings.EDIT}
          </Button>
        </Title>
        <Descriptions column={2}>
          <Descriptions.Item label={strings.TITLE}>{title ?? '-'}</Descriptions.Item>
          <Descriptions.Item label={strings.IS_ACTIVE}>{isActive ? checkIcon : '-'}</Descriptions.Item>
          <Descriptions.Item label={strings.POSITION}>{position ?? '-'}</Descriptions.Item>
          <Descriptions.Item label={strings.SHOW_IN_FORM}>{showInForm ? checkIcon : '-'}</Descriptions.Item>
        </Descriptions>
        <Button danger onClick={deleteLearnedFrom}>
          {strings.DELETE}
        </Button>
      </Spin>
    </Container>
  );
};

export default { SourceReferenceFull };
