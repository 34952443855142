import React from 'react';
import dayjs from 'dayjs';
import { dateFormat } from 'helpers/dates';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';
import isNull from 'lodash/isNull';
import { Link } from 'react-router-dom';
import { BACKEND_LOCATION } from 'helpers';
import { Teacher } from 'types/Teachers/teacher';
import { ShowMoreText } from 'Global/components/ShowMoreText';
import emptyIcon from 'Global/img/empty.png';

const renderDate = (date: string, { lessonId }) => {
  if (!date) return null;

  return <Link to={`/le/${lessonId}`}>{dayjs(date).format(dateFormat)}</Link>;
};

export const columnsTasks = strings => [
  {
    title: strings.DATE_OF_CLASS,
    dataIndex: 'startAt',
    key: 'startAt',
    width: 180,
    render: renderDate
  },
  {
    title: `${strings.TEST} %`,
    dataIndex: 'percent',
    key: 'percent',
    width: 80,
    render: (precent: number) => (precent || precent === 0 ? `${precent}%` : '')
  },
  {
    title: strings.ATTENDANCE,
    dataIndex: 'presence',
    render: (presence: boolean) =>
      presence ? (
        <CheckCircleTwoTone style={{ fontSize: 22 }} twoToneColor="#52c41a" />
      ) : (
        <CloseCircleTwoTone style={{ fontSize: 22 }} twoToneColor="#f5222d" />
      ),
    key: 'presence',
    className: 'clmn-center',
    width: 120
  },
  {
    title: strings.TOPIC,
    dataIndex: 'topic',
    key: 'topic'
  }
];

export const columnsLesson = strings => [
  {
    title: strings.DATE_OF_CLASS,
    dataIndex: 'startAt',
    key: 'startAt',
    width: 100,
    className: 'th-center',
    render: renderDate
  },
  {
    title: `${strings.TEACHER}`,
    dataIndex: 'teacher',
    key: 'teacher',
    width: 120,
    render: (teacher: Teacher) => (
      <span>
        {teacher?.user?.surname} {teacher?.user?.name}
      </span>
    )
  },
  {
    title: strings.TOPIC,
    dataIndex: 'lessonTitle',
    key: 'lessonTitle',
    className: 'th-center',
    width: 120
  },
  {
    title: strings.ATTENDANCE,
    dataIndex: 'attendance',
    className: 'clmn-center',
    render: (attendance: boolean) =>
      attendance ? (
        <CheckCircleTwoTone style={{ fontSize: 22 }} twoToneColor="#52c41a" />
      ) : (
        <CloseCircleTwoTone style={{ fontSize: 22 }} twoToneColor="#f5222d" />
      ),
    key: 'attendance',
    width: 90
  },
  {
    title: strings.HW,
    className: 'clmn-center',
    dataIndex: 'homeworkDone',
    render: (homework: boolean, record) => {
      const { homeworkRequired } = record;
      if (homeworkRequired) {
        //еще не зафиксировано, сдана ли домашка
        if (isNull(homework)) {
          return <CheckCircleTwoTone style={{ fontSize: 22 }} twoToneColor="#bfbfbf" />;
        }

        if (homework) {
          return <CheckCircleTwoTone style={{ fontSize: 22 }} twoToneColor="#52c41a" />;
        } else {
          return <CloseCircleTwoTone style={{ fontSize: 22 }} twoToneColor="#f5222d" />;
        }
      } else {
        //дз не задано
        return (
          <Tooltip title={strings.NO_HOMEWORK_WAS_ASSIGNED}>
            <img src={emptyIcon} style={{ width: 22, opacity: 0.4 }} />
          </Tooltip>
        );
      }
    },
    key: 'noHomework',
    width: 60
  },
  {
    title: `${strings.HW} %`,
    dataIndex: 'homeworkGrade',
    key: 'homeworkGrade',
    className: 'clmn-center',
    width: 80,
    render: (grade: number) => (grade || grade === 0 ? `${grade}%` : grade === null ? 'N/A' : '')
  },
  {
    title: strings.HOME_ASSIGNMENT,
    dataIndex: 'homework',
    key: 'homework',
    className: 'th-center',
    width: 200,
    render: assignedHW => assignedHW && <ShowMoreText text={assignedHW} lettersCount={50} hasDangerouslySetInnerHTML />
  },
  {
    title: strings.FEEDBACK_FOR_PARENT,
    dataIndex: '',
    key: 'commentForParent',
    className: 'th-center',
    width: 300,
    render: record => {
      const { commentForParent, commentForParent2, commentForParent3 } = record;
      const comments = [commentForParent, commentForParent2, commentForParent3]
        .filter(comment => comment != null)
        .join(' ');
      return <div style={{ textAlign: 'justify' }}>{comments}</div>;
    }
  },
  {
    title: strings.FEEDBACK_FOR_STUDENT,
    dataIndex: 'commentForStudent',
    key: 'commentForStudent',
    className: 'th-center',
    width: 200,
    render: (comment: string) => <div style={{ textAlign: 'justify' }}>{comment}</div>
  },
  {
    title: strings.FEEDBACK_FOR_OFFICE,
    dataIndex: '',
    key: 'commentForSupervisor',
    className: 'th-center',
    width: 200,
    render: record => {
      const { commentForSupervisor, commentForSupervisorAudio } = record;
      return (
        <>
          <div
            style={{ textAlign: 'justify' }}
            dangerouslySetInnerHTML={{
              __html:
                commentForSupervisor &&
                commentForSupervisor.replace(/#(\S+)/gi, '<span style="background: #fe8ca436;">#$1</span>')
            }}
          />
          {commentForSupervisorAudio && (
            <audio
              style={{ width: '100%', height: 40 }}
              src={`${BACKEND_LOCATION}${commentForSupervisorAudio}`}
              controls={true}
            />
          )}
        </>
      );
    }
  }
];

export const columnsAssistantsFeedback = strings =>
  [
    {
      title: strings.DATE,
      key: 'date',
      dataIndex: 'header',
      width: 120,
      render: header => header?.date && dayjs(header.date).format(dateFormat)
    },
    {
      title: strings.SUPERGROUP,
      key: 'supergroup',
      dataIndex: 'header',
      width: 160,
      render: header => header?.supergroup?.name
    },
    {
      title: strings.ASSISTANT,
      key: 'assistant',
      dataIndex: 'header',
      width: 180,
      render: header => (
        <p>
          {header?.assistant?.user?.name} {header?.assistant?.user?.surname}
        </p>
      )
    },
    {
      title: strings.GENERAL_COMMENT,
      key: 'generalComment',
      dataIndex: 'header',
      render: header => header?.generalComment
    },
    {
      title: strings.INDIVIDUAL_COMMENT,
      dataIndex: 'comment',
      key: 'comment',
      render: comment => <p>{comment}</p>
    }
  ].filter(col => col);

export default { columnsTasks, columnsLesson, columnsAssistantsFeedback };
