import React, { useState } from 'react';
import styled from 'styled-components';
import { CheckCircleTwoTone, DownloadOutlined, EditOutlined, UploadOutlined } from '@ant-design/icons';
import { Tooltip, Button, message, Badge, Flex } from 'antd';
import { generateColumn } from 'Global/modules/Lesson/helpers';
import { Child } from 'types';
import { Register } from 'types/TeacherAccount/Courses';
import { UploadHomeworkStudentModal } from '../RegisterModals/UploadHomeworkStudentModal';
import { downloadSomeFile } from 'helpers';
import { useLanguageContext } from 'contexts/LanguageContext';
import { successBadgeProps } from '../index';
import { useLessonRegisterStore } from 'stores/useLessonRegisterStore';
import { StTitle } from '../styles';

const StRegisterHomeworkUpload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .circle-badge {
    position: absolute;
    top: -4px;
    right: -10px;
    z-index: 2;
  }
`;

/**
 * @description Рендеринг заголовка таблицы с загрузкой (Bulk) домашнего задания
 * @param {number} lessonId
 * @param {function} setHasShowUploadHomework
 * @return {React.ReactNode}
 */
const TitleHomeworkUpload = React.memo(
  ({
    lessonId,
    setHasShowUploadHomework
  }: {
    lessonId: number | string;
    setHasShowUploadHomework: (val: boolean) => void;
  }) => {
    const [strings] = useLanguageContext();
    const [hasLoad, setHasLoad] = useState<boolean>(false);

    /**
     * @description Скачивание всей проверенной домашки по уроку для всех студентов разом
     * @return {React.ReactNode}
     */
    const downloadAllHomeworkByLesson = async () => {
      try {
        setHasLoad(true);
        await downloadSomeFile(`/api/v2/lesson/${lessonId}/homework/checked`);
        setHasLoad(false);
      } catch {
        message.error(strings.HW_DOWNLOAD_ERROR);
        setHasLoad(false);
      }
    };

    return (
      <Flex vertical gap={5}>
        <Flex justify="space-around" align="middle" wrap="nowrap" gap={5}>
          <Tooltip title={strings.DOWNLOAD_ALL_CHECKED_HOMEWORKS}>
            <Button
              loading={hasLoad}
              onClick={() => downloadAllHomeworkByLesson()}
              shape="circle"
              icon={<DownloadOutlined />}
            />
          </Tooltip>
          <Tooltip title={strings.UPLOAD_ALL_HOMEWORKS}>
            <Button onClick={() => setHasShowUploadHomework(true)} shape="circle" icon={<UploadOutlined />} />
          </Tooltip>
        </Flex>
        <StTitle>{strings.CHECKED}</StTitle>
      </Flex>
    );
  }
);

/**
 * @description Рендеринг колонки загрузки учителем проверенного ДЗ
 * @param {object} student
 * @param {object} register
 * @return {React.React.Node}
 */
const RegisterHomeworkUpload = React.memo(({ register }: { register: Register }) => {
  const { checkedHwList } = register;
  const [hasShowUploadModal, setHasShowUploadModal] = useState<boolean>(false);
  const [strings] = useLanguageContext();
  const setHasRegistersUpdate = useLessonRegisterStore(context => context.setHasRegistersUpdate);

  const handleUploadBtn = async (): Promise<any> => {
    setHasShowUploadModal(true);
    setHasRegistersUpdate(true);
  };

  const getUploadButton = () => {
    /**
     * Если учитель отправил проверенную домашнюю работу, или загрузил хотя бы один файл, то кнопка upload
     * превращается в кнопку attach (меняется иконка). При наведении на кнопку отображен тултип с информацией
     * о количестве отправленных файлов. You’ve uploaded 2 files. Click to edit.
     * При нажатии на нее отображается модальное окно с возможностью
     * просмотреть, удалить отправленные файлы, отправить новые
     */
    if (checkedHwList?.isNotEmpty()) {
      return (
        <Tooltip title={`${strings.YOU_UPLOADED} ${checkedHwList.length} ${strings.CLICK_EDIT_FILES}`}>
          <Badge count={<CheckCircleTwoTone {...successBadgeProps} />}>
            <Button icon={<EditOutlined />} onClick={handleUploadBtn} />
          </Badge>
        </Tooltip>
      );
    }

    /**
     * Если домашняя работа была отправлена студентом, учитель видит кнопку отправить проверенную домашнюю
     * работу в соседней колонке.Отображен тултип Upload checked HW
     */
    return (
      <Tooltip title={strings.UPLOAD_CHECKED_HW}>
        <Button icon={<UploadOutlined />} onClick={handleUploadBtn} />
      </Tooltip>
    );
  };

  return (
    <StRegisterHomeworkUpload>
      {hasShowUploadModal && (
        <UploadHomeworkStudentModal
          register={register}
          hasShowModal={hasShowUploadModal}
          onCancel={() => setHasShowUploadModal(false)}
        />
      )}
      {getUploadButton()}
    </StRegisterHomeworkUpload>
  );
});

/**
 * @description Генерация колонки загрузки домашки
 * @return {object}
 */
export const getHomeworkUploadColumn = (setHasShowUploadHomework, lessonId) => ({
  ...generateColumn(
    () => <TitleHomeworkUpload lessonId={lessonId} setHasShowUploadHomework={setHasShowUploadHomework} />,
    'student',
    'checked',
    null,
    null,
    80
  ),
  render: (student: Child, register: Register) => {
    return <RegisterHomeworkUpload register={register} />;
  }
});
