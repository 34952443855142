import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Row, Col, Button, Select, DatePicker } from 'antd';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import { momentFormatValues } from 'helpers/momentFormatValues';
import { Schedule } from 'api/Schedule';
import BaseFilter from 'helpers/BaseFilter';
import { TutorSelect } from 'Global/components/FormComponentsCompatible/Selects/TutorSelect';
import { reversedDateFormatForBackend } from 'helpers/dates';
import { Location } from 'history';
import { Branch } from 'types';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { ScheduleLogParams, ScheduleAction } from 'types/Schedule';
import { useUserContext } from 'contexts/UserContext';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Option } = Select;

interface TimetableChangesProps extends FormComponentProps {
  getLogs: (params?: ScheduleLogParams) => Promise<any>;
  limitLogs: number;
  location: Location;
}

/**
 * @description Фильтр изменений в расписании
 * @param {TimetableChangesProps} props
 * @return {React.ReactNode}
 */
const TimetableChangesFilterTemplate = (props: TimetableChangesProps) => {
  const [actions, setActions] = useState<ScheduleAction[]>([]);
  const {
    form: { getFieldDecorator, validateFields, resetFields },
    getLogs,
    limitLogs,
    location
  } = props;

  const [user] = useUserContext();
  const { branches } = useGlobalCollectionsContext();
  const [strings] = useLanguageContext();

  const hasTopManager = user?.hasRoles?.topManager;
  const FilterService = new BaseFilter();
  const locationParams = FilterService.getLocationSearchParams(location);
  const { dateStart, dateEnd, branch, admin, type, action } = locationParams || {};

  // Очистка фильтра
  const clearFilter = () => {
    resetFields();
    getLogs({ limit: limitLogs });
  };

  /**
   * @description Отправить форму
   * @param {React.FormEvent<HTMLFormElement>} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        const fields: any = pickBy(momentFormatValues(values), identity);
        getLogs({ ...fields, limit: limitLogs });
      }
    });
  };

  /**
   * @description Список экшенов
   * @return {Promise<any>}
   */
  const getActionList = async (): Promise<any> => {
    await Schedule.getActionList()
      .then(({ data }) => {
        let actions = [];
        for (let i in data) {
          actions.push({ id: i, name: data[i] });
        }

        setActions(actions);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getActionList();
  }, []);

  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Row>
        <Col lg={8}>
          <MyFormItem label={strings.FROM}>
            {getFieldDecorator('dateStart', {
              initialValue: dateStart && dayjs(dateStart)
            })(<DatePicker format={reversedDateFormatForBackend} />)}
          </MyFormItem>
        </Col>
        <Col lg={8}>
          <MyFormItem label={strings.TILL}>
            {getFieldDecorator('dateEnd', {
              initialValue: dateEnd && dayjs(dateEnd)
            })(<DatePicker format={reversedDateFormatForBackend} />)}
          </MyFormItem>
        </Col>
        {hasTopManager && (
          <Col lg={8}>
            <MyFormItem label={strings.BRANCH}>
              {getFieldDecorator('branch', {
                initialValue: branch && Number(branch)
              })(
                <Select placeholder={strings.SELECT_BRANCH}>
                  <Option key="all" value="">
                    All branches
                  </Option>
                  {branches.map(({ id, name }: Branch) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              )}
            </MyFormItem>
          </Col>
        )}
        <Col lg={8}>
          <TutorSelect
            getFieldDecorator={getFieldDecorator}
            label={strings.ADMINISTRATOR}
            name="admin"
            initTutor={admin}
          />
        </Col>
        <Col lg={8}>
          <MyFormItem label={strings.TYPE}>
            {getFieldDecorator('type', {
              initialValue: type
            })(
              <Select placeholder={strings.SELECT_TYPE}>
                <Option key="all" value="">
                  All types
                </Option>
                <Option key="lesson" value="lesson">
                  Lesson
                </Option>
                <Option key="event" value="event">
                  Event
                </Option>
              </Select>
            )}
          </MyFormItem>
        </Col>
        <Col lg={8}>
          <MyFormItem label={strings.ACTION}>
            {getFieldDecorator('action', {
              initialValue: action
            })(
              <Select placeholder={strings.SELECT_ACTION}>
                {actions.map((action: ScheduleAction) => (
                  <Option key={action.id} value={action.id}>
                    {action.name}
                  </Option>
                ))}
              </Select>
            )}
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <Button size="large" onClick={clearFilter} style={{ marginInlineEnd: 8 }}>
            {strings.CLEAR}
          </Button>
          <Button size="large" htmlType="submit" type="primary">
            {strings.FILTER}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export const TimetableChangesFilter = Form.create<TimetableChangesProps>({})(TimetableChangesFilterTemplate);

export default { TimetableChangesFilter };
