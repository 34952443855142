import React, { useMemo } from 'react';
import { httpService } from 'api/core';
import { IRepositoryProvider } from './RepositoryProvider';

const MetaGoalsRepositoryContext = React.createContext<IMetaGoalsRepositoryContext>(null);

interface IMetaGoalsRepositoryContext {
  getMetaGoalsList: () => Promise<any>;
  getMetaLevelPictureBranches: () => Promise<any>;
  setMetaLevelPictureBranches: (params?: { branches: number[] }) => Promise<any>;
  getMetaLevelById: (id: number | string) => Promise<any>;
  getLessonsMetaGoals: (id: number | string) => Promise<any>;
  deleteMetaGoal: (id: number | string) => Promise<any>;
  deleteMetaLevel: (id: number | string) => Promise<any>;
  getMetaLevelPicture: (id: number | string) => Promise<any>;
  updateMetaGoal: (id: number | string, params) => Promise<any>; //TODO описать params
  updateMetaLevel: (id: number | string, params) => Promise<any>; //TODO описать params
  createMetaGoalItem: (id: number | string, params) => Promise<any>; //TODO описать params
  createMetaGoalsLevel: ({ name, stage }) => Promise<any>; //TODO описать params
  getMetaGoalsSummaryByStudentId: (id: number | string) => Promise<any>;
}

class MetaGoalsRepository implements IMetaGoalsRepositoryContext{
  getMetaGoalsList() {
    return httpService.get('/api/v1/metagoals', null, null, true);
  }

  deleteMetaGoal(id: number | string) {
    return httpService.delete(`/api/v1/metagoals/goals/${id}`, null, null, true);
  }

  deleteMetaLevel(id) {
    return httpService.delete(`/api/v1/metagoals/level/${id}`, null, null, true);
  }

  updateMetaGoal(id, params) {
    return httpService.put(`/api/v1/metagoals/goals/${id}`, params, null, null, true);
  }

  updateMetaLevel(id, params) {
    return httpService.put(`/api/v1/metagoals/level/${id}`, params, null, null, true);
  }

  createMetaGoalsLevel({ name, stage }) {
    return httpService.post('/api/v1/metagoals/level', { name, stage }, null, null, true);
  }

  createMetaGoalItem(id, params) {
    return httpService.post(`/api/v1/metagoals/level/${id}/goal`, params, null, null, true);
  }

  getMetaLevelPicture(id) {
    return httpService.get(`/api/v1/lessons/${id}/metalevel-picture`, null, null, true);
  }

  /**
   * @description Получить бранчи, для которых показывать Meta Level Picture
   * @return {React.ReactNode}
   */
  getMetaLevelPictureBranches() {
    return httpService.get(`/api/v1/metagoals/branches`, null, null, true);
  }
  /**
   * @description Установить бранчи, для которых показывать Meta Level Picture
   * @param {number[]} params
   * @return {React.ReactNode}
   */
  setMetaLevelPictureBranches(params?: { branches: number[] }) {
    return httpService.post(`/api/v1/metagoals/branches`, params, null, null, true);
  }

  getMetaLevelById(id: number) {
    return httpService.get(`/api/v1/metalevel/${id}`, null, null, true);
  }

  getLessonsMetaGoals(id: number) {
    return httpService.get(`/api/v1/lessons/${id}/metagoals`, null, null, true);
  }

  getMetaGoalsSummaryByStudentId(studentId: number | string) {
    return httpService.get(`/api/v1/children/${studentId}/metagoals-summary`, null, null, true);
  }
}

export const MetaGoalsRepositoryProvider: React.FC<IRepositoryProvider> = ({ children }) => {
  const service = useMemo(() => new MetaGoalsRepository(), []);

  return <MetaGoalsRepositoryContext.Provider value={service}>{children}</MetaGoalsRepositoryContext.Provider>;
};

export const useMetaGoalsRepository = () => {
  const service = React.useContext(MetaGoalsRepositoryContext);

  if (!service) {
    throw new Error('Meta goals repository is unavailable');
  }

  return service;
};
