import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import { Row, Col, Select, Checkbox } from 'antd';
import { Branch } from 'types';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { ITeacherWagesFilterParams } from 'types/finance';
import { FilterButtons } from 'Global/components/FilterButtons';

const { Option } = Select;

interface TeachersWagesFilterProps {
  form: WrappedFormUtils;
  onFilter?: (params?: ITeacherWagesFilterParams) => Promise<any>;
  defFilterValues?: ITeacherWagesFilterParams;
}

/**
 * @description Фильтр для инвентарей
 * @param {FilterProps} props
 * @returns {React.ReactNode}
 */
export const FilterTemplate = (props: TeachersWagesFilterProps) => {
  const {
    form: { getFieldDecorator, validateFieldsAndScroll, resetFields },
    defFilterValues,
    onFilter
  } = props;

  const [strings] = useLanguageContext();
  const { branches } = useGlobalCollectionsContext();
  const { locationSearchParams } = useLocationSearchParams();
  const { branch, active } = locationSearchParams || {};
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);

  /**
   * @description Submit формы
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values): Promise<any> => {
      if (!errors) {
        try {
          setHasSubmitLoading(true);
          await onFilter(values);
        } finally {
          setHasSubmitLoading(false);
        }
      }
    });
  };

  /**
   * @description Очистка фильтра
   * @return {Promise<any>}
   */
  const handleClear = async (): Promise<any> => {
    resetFields();
    await onFilter(defFilterValues);
  };

  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Row>
        {branches.length > 1 && (
          <Col xs={24} lg={8}>
            {getFieldDecorator('branch', {
              initialValue: branch
            })(
              <Select placeholder={strings.BRANCH}>
                {branches &&
                  branches.map((branch: Branch) => (
                    <Option value={branch.id} key={branch.id}>
                      {branch.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Col>
        )}
        <Col xs={24} lg={5}>
          {getFieldDecorator('active', {
            initialValue: active,
            valuePropName: 'checked'
          })(<Checkbox>{strings.SHOW_ONLY_CURRENT}</Checkbox>)}
        </Col>
        <FilterButtons handleClear={handleClear} isSameRow={true} hasSubmitLoading={hasSubmitLoading} />
      </Row>
    </Form>
  );
};

export const Filter = Form.create<TeachersWagesFilterProps>({})(FilterTemplate);
export default { Filter };
