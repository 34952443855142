import React from 'react';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { isNull } from 'lodash';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, Input, Button, message, Row, Col } from 'antd';
import { Families } from 'api/Families';
import { IComment, InvoiceFilterParams } from 'types/invoice';
import { useLanguageContext } from 'contexts/LanguageContext';

export interface CommentProps extends FormComponentProps {
  cancel: () => void;
  show: boolean;
  id: number;
  comment: IComment;
  refetchInvoices: () => void;
  paramsGetInvoices?: InvoiceFilterParams;
}

export interface CommentInvoiceType {
  text: string;
}

/**
 * @description - Компонент модального окна добавления комментария
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
const CommentTemplate = (props: CommentProps) => {
  const {
    form: { getFieldDecorator, validateFields, resetFields },
    show,
    cancel,
    id,
    comment,
    refetchInvoices
  } = props;
  const [strings] = useLanguageContext();
  /**
   * @description Добавление комментария
   * @param {object} values
   * @return {Promise<any>}
   */
  const addCommentToInvoice = async (values: CommentInvoiceType): Promise<any> => {
    await Families.addCommentToInvoice(id, values)
      .then(() => {
        message.success(strings.A_COMMENT_TO_INVOICE_HAS_BEEN_ADDED, 2);
        cancel();
        refetchInvoices();
      })
      .catch(() => {});
  };

  /**
   * @param {Event} e
   * @return {void}
   */
  const handleSubmit = async (e: React.FormEvent<EventTarget>): Promise<any> => {
    e.preventDefault();
    validateFields((errors, values) => {
      if (isNull(errors)) {
        addCommentToInvoice(values);
        //без resetFields() не обновляется initialValue
        resetFields();
      }
    });
  };

  return (
    <Modal title={strings.INVOICE_COMMENT} style={{ top: 100 }} open={show} onCancel={cancel} width={820} footer={[]}>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <MyFormItem label={strings.TEXT}>
          {getFieldDecorator('text', {
            rules: [{ required: true }],
            initialValue: comment?.comment
          })(<Input.TextArea style={{ height: '250px' }} />)}
        </MyFormItem>
        <Row>
          <Col lg={12}>
            <Button key="back" size="large" onClick={cancel}>
              {strings.CLOSE}
            </Button>
          </Col>
          <Col lg={12} style={{ textAlign: 'right' }}>
            <Button key="add" size="large" type="primary" htmlType="submit">
              {strings.ADD}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export const Comment = Form.create<CommentProps>({})(CommentTemplate);
