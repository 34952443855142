import React from 'react';
import dayjs from 'dayjs';
import { Tag, Descriptions, Flex } from 'antd';
import { Link } from 'react-router-dom';
import { dateFormat } from 'helpers/dates';
import { IStudent } from 'types';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useMediaQuery } from 'react-responsive';
import { StDescription } from './index';
import { useUserContext } from 'contexts/UserContext';

type BrunerMainInfoProps = {
  child: IStudent;
};

export const BrunerMainInfo: React.FC<BrunerMainInfoProps> = ({ child }) => {
  const {
    user: studentUser,
    status,
    supergroups,
    tutor,
    comment,
    englishLevelComment,
    allergies,
    medicalConditions,
    psychologistEvaluation,
    takingPhotos,
    schoolTrips
  } = child || {};

  const { name, surname, gender, birthDate, age, phone, surnameHebrew, nameHebrew } = studentUser || {};

  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const hasTeacher = user?.hasRoles?.teacher;
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Flex wrap={isMobile ? 'wrap' : 'nowrap'} gap={20}>
      <StDescription title={strings.GENERAL_INFO} column={1}>
        <Descriptions.Item label={strings.NAME}>
          {name} {surname ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label={strings.HEBREW_NAME}>{nameHebrew ?? '-'}</Descriptions.Item>
        <Descriptions.Item label={strings.HEBREW_SURNAME}>{surnameHebrew ?? '-'}</Descriptions.Item>
        <Descriptions.Item label={strings.DATE_OF_BIRTH}>
          {birthDate && dayjs(birthDate).format(dateFormat)}
        </Descriptions.Item>
        <Descriptions.Item label={strings.AGE}>{age ?? '-'}</Descriptions.Item>
        <Descriptions.Item label={strings.SEX}>{gender === 1 ? strings.FEMALE : strings.MALE}</Descriptions.Item>
        {!hasTeacher && (
          <>
            <Descriptions.Item label={strings.MOBILE}>
              {phone ? <a href={`tel:${phone}`}>{phone}</a> : '-'}
            </Descriptions.Item>
            <Descriptions.Item label={strings.COMMENT}>{comment ?? '-'}</Descriptions.Item>
          </>
        )}
      </StDescription>
      <StDescription title={strings.STUDYING_INFO} column={1}>
        <Descriptions.Item label={strings.STATUS}>{status?.name ?? '-'}</Descriptions.Item>
        <Descriptions.Item label={strings.TUTOR}>
          {tutor?.user?.name} {tutor?.user?.surname ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label={strings.LANGUAGE_EXPERIENCE}>{englishLevelComment ?? '-'}</Descriptions.Item>
        <Descriptions.Item label={strings.SUPERGROUPS}>
          <Flex wrap="wrap" gap={5}>
            {supergroups?.map(({ id, name, activeForStudent }) => (
              <Tag color={activeForStudent ? 'green' : 'blue'} key={id} style={{ margin: 0 }}>
                <Link to={`/supergroup/${id}`}>{name}</Link>
              </Tag>
            ))}
          </Flex>
          {supergroups?.length === 0 && '-'}
        </Descriptions.Item>
      </StDescription>
      <StDescription title={strings.EXTRA_INFO} column={1}>
        <Descriptions.Item label={strings.ALLERGIES}>{allergies ?? '-'}</Descriptions.Item>
        {!hasTeacher && (
          <>
            <Descriptions.Item label={strings.OTHER_MEDICAL_CONDITIONS}>{medicalConditions ?? '-'}</Descriptions.Item>
            <Descriptions.Item label={strings.PSYCHOLOGISTS_EVALUATION}>
              {psychologistEvaluation ?? '-'}
            </Descriptions.Item>
            <Descriptions.Item label={strings.SCHOOL_TRIPS}>{schoolTrips ? strings.YES : strings.NO}</Descriptions.Item>
          </>
        )}
        <Descriptions.Item label={strings.TAKING_PHOTOS}>{takingPhotos ? strings.YES : strings.NO}</Descriptions.Item>
      </StDescription>
    </Flex>
  );
};

export default { BrunerMainInfo };
