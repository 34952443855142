import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Table, Select, Typography, Button, Row, Col } from 'antd';
import { MarketingEvents as StMarketingEvents } from '../styles';
import { columns } from './columns';
import { ADD_MARKETING_EVENTS } from '../routes';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { Location, History } from 'history';
import { useLastLocation } from 'react-router-last-location';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Title } = Typography;
const { Column } = Table;
const { Option } = Select;

interface showEmailProps {
  form: WrappedFormUtils;
  location: Location;
  history: History;
}

export const MarketingEvents = Form.create({})((props: showEmailProps) => {
  const {
    form: { getFieldDecorator },
    history: { goBack },
    location
  } = props;
  const [strings] = useLanguageContext();

  const { branches } = useGlobalCollectionsContext();

  const lastLocation = useLastLocation();
  const handleBack = () => goBack();

  /**
   * @param {React.FormEvent} e
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
  };

  const tableProps = {
    dataSource: []
  };

  return (
    <StMarketingEvents>
      <Row>
        <Col lg={12}>
          <Title level={3}>
            {lastLocation && <ArrowLeftOutlined onClick={handleBack} />} {strings.MARKETING_EVENTS}
          </Title>
        </Col>
        <Col lg={12} style={{ textAlign: 'right' }}>
          <Title level={4}>{strings.TOTAL_MEETINGS} 257</Title>
        </Col>
      </Row>

      <Form layout="vertical" onSubmit={handleSubmit}>
        <Row>
          <Col lg={5}>
            {getFieldDecorator('branch', {
              rules: [{ required: false }]
            })(
              <Select placeholder={strings.BRANCH}>
                {branches
                  ? branches.map(branch => (
                      <Option value={branch.id} key={branch.id}>
                        {branch.name}
                      </Option>
                    ))
                  : null}
              </Select>
            )}
          </Col>
          <Col lg={5}>
            <Button type="primary" size="large" htmlType="submit">
              {strings.FILTER}
            </Button>
          </Col>
        </Row>
      </Form>
      <Row style={{ margin: '20px 0' }}>
        <Col lg={24}>
          <Button type="primary" size="large">
            <Link to={ADD_MARKETING_EVENTS}>{strings.ADD}</Link>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col lg={24}>
          <Table {...tableProps}>
            {columns(strings).map(col => (
              <Column key={col.key} {...col} />
            ))}
          </Table>
        </Col>
      </Row>
    </StMarketingEvents>
  );
});

export default { MarketingEvents };
