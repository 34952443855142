import React from 'react';
import { dateFormat } from 'helpers/dates';
import { Tag } from 'antd';
import dayjs from 'dayjs';

export const columns = strings => [
  {
    title: strings.ADMINISTRATOR,
    dataIndex: 'admin',
    key: 'admin',
    render: admin => (
      <p>
        {admin?.name} {admin?.surname}
      </p>
    )
  },
  {
    title: strings.DATE,
    dataIndex: 'opDate',
    key: 'opDate',
    width: 120,
    render: (date: Date) => <p>{dayjs(date).format(dateFormat)}</p>
  },
  {
    title: strings.USER_TYPE,
    dataIndex: 'userType',
    key: 'userType'
  },
  {
    title: strings.USER,
    dataIndex: 'userName',
    key: 'userName'
  },
  {
    title: strings.OPERATION_TYPE,
    dataIndex: 'performerOperationType',
    key: 'performerOperationType',
    width: 150,
    render: performerOperationType => {
      switch (performerOperationType) {
        case 'Incoming payment':
          return <Tag color="green">{performerOperationType}</Tag>;
        case 'Outgoing payment':
          return <Tag color="blue">{performerOperationType}</Tag>;
        case 'Cancel':
          return <Tag color="magenta">{performerOperationType}</Tag>;
      }
    }
  },
  {
    title: strings.PAYMENT_TYPE,
    dataIndex: 'paymentType',
    key: 'paymentType'
  },
  {
    title: strings.SUM,
    dataIndex: 'sum',
    key: 'sum'
  },
  {
    title: strings.COMMENTS,
    dataIndex: 'comment',
    key: 'comment'
  }
];

export default { columns };
