import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Col, Row, DatePicker, Spin, InputNumber, Checkbox, Flex, Tag, message, Form, FormInstance } from 'antd';
import { dateFormat, dateFormatForBackend, dateTimeWithoutSecondFormat } from 'helpers/dates';
import { useLanguageContext } from 'contexts/LanguageContext';
import { getDateForSchedule } from 'helpers/getDateForSchedule';
import { useScheduleRepository } from 'repos/ScheduleRepostirory';
import { useUserContext } from 'contexts/UserContext';
import { FormItemWithoutMargin } from 'Global/styles';

interface ProlongUntilSlotFieldsProps {
  hasApplyToAll?: boolean;
  slotId?: number;
}
interface SequenceDate {
  date: string;
  type: string;
  message: string;
}

export const ProlongUntilSlotFields = React.memo(({ slotId, hasApplyToAll }: ProlongUntilSlotFieldsProps) => {
  const form = Form.useFormInstance();
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const hasTeacher = user?.hasRoles?.teacher;
  const scheduleRepository = useScheduleRepository();
  const [hasLoadingSequenceDates, setHasLoadingSequenceDates] = useState<boolean>(false);
  const [sequenceDates, setSequenceDates] = useState<SequenceDate[]>([]);

  const dateSequenceValue = Form.useWatch('dateSequence', form);
  const dateStartValue = Form.useWatch('day', form);

  const handleChangeDateSequence = useCallback(
    date => {
      if (!date) {
        setSequenceDates([]);
        return;
      }
      const frequency = form.getFieldValue('frequency');
      getPreviewSequenceDates(date, frequency);
    },
    [form]
  );

  const handleChangeFrequency = useCallback(
    frequency => {
      getPreviewSequenceDates(dateSequenceValue, frequency);
    },
    [dateSequenceValue]
  );
  const getPreviewSequenceDates = async (date: Date, frequency: number): Promise<any> => {
    try {
      form.validateFields();
      const values = form.getFieldsValue();

      const { day, startTime, endTime } = values;
      const newDateStart = getDateForSchedule(day, startTime);
      const newDateEnd = getDateForSchedule(day, endTime);

      const dateSequence = dayjs(date).format(dateFormatForBackend);
      const params = { ...values, dateStart: newDateStart, dateEnd: newDateEnd, dateSequence, frequency, preview: 1 };
      setHasLoadingSequenceDates(true);
      try {
        if (slotId) {
          const { data } = await scheduleRepository.getPreviewSlotByLessonId(slotId, params);
          setSequenceDates(data);
        } else {
          const { data } = await scheduleRepository.getPreviewSlotForNewLesson(params);
          setSequenceDates(data);
        }
      } catch {
      } finally {
        setHasLoadingSequenceDates(false);
      }
    } catch {
      const clearDateSequence = setInterval(() => {
        form.setFieldsValue({ dateSequence: null });
        message.info(strings.PLEASE_FILL_IN_THE_REQUIRED_FIELDS, 2);
        clearInterval(clearDateSequence);
      }, 100);
    }
  };

  const SequenceTags = React.memo(({ dates }: { dates: SequenceDate[] }) => (
    <Flex gap={5} wrap="wrap">
      {dates.map(({ date }) => (
        <Tag color="blue" key={date}>
          {dayjs(date).format(dateTimeWithoutSecondFormat)}
        </Tag>
      ))}
    </Flex>
  ));
  const resetProlongUntilSlotFields = () => {
    setSequenceDates([]);
    form.resetFields(['dateSequence', 'frequency']);
  };

  useEffect(() => {
    if (dateStartValue) {
      resetProlongUntilSlotFields();
    }
  }, [dateStartValue]);

  return (
    <Spin spinning={hasLoadingSequenceDates}>
      <Row gutter={[10, 10]} align="middle">
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="dateSequence" initialValue={null}>
            <DatePicker
              minDate={dateStartValue}
              disabled={hasTeacher}
              format={dateFormat}
              onChange={handleChangeDateSequence}
            />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="frequency" initialValue={1}>
            <InputNumber
              addonBefore="Every"
              addonAfter="week"
              disabled={!dateSequenceValue}
              defaultValue={1}
              max={4}
              min={1}
              onChange={handleChangeFrequency}
            />
          </FormItemWithoutMargin>
        </Col>
        {!hasTeacher && hasApplyToAll && (
          <Col lg={6} xs={24}>
            <FormItemWithoutMargin name="applyToAll" initialValue={false} valuePropName="checked">
              <Checkbox>{strings.APPLY_TO_ALL}</Checkbox>
            </FormItemWithoutMargin>
          </Col>
        )}
        <Col lg={24} xs={24}>
          <SequenceTags dates={sequenceDates} />
        </Col>
      </Row>
    </Spin>
  );
});

export default { ProlongUntilSlotFields };
