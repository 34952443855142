import dayjs, { isDayjs } from 'dayjs';
import { isObject } from 'lodash';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);

// Ни в коем случае не менять.
export const dateFormat = 'DD.MM.YYYY';
export const dateMonthFormat = 'DD.MM';
export const dateFormatForLesson = 'HH:mm';
export const dateFormatForBackend = 'YYYY-MM-DD';
export const reversedDateFormatForBackend = 'DD-MM-YYYY';
export const dateTimeFormatForBackend = 'YYYY-MM-DD HH:mm:ss';
export const dateTimeWithoutSecondFormatForBackend = 'YYYY-MM-DD HH:mm';
export const dateTimeFormat = 'DD.MM.YYYY HH:mm:ss';
export const dateTimeWithoutSecondFormat = 'DD.MM.YYYY HH:mm';
export const timeFormat = 'DD.MM.YYYY HH:mm:ss';
export const dayFormat = 'DD';

/**
 * @description Первый день месяца
 */
export const firstDayOfMonth = dayjs().startOf('month');

/**
 * @description Сегодняшний день
 */
export const today = dayjs(new Date(), dateFormat);

/**
 * @description Первый день прошлого месяца
 */
export const firstDayOfPrevMonth = dayjs().subtract(1, 'month').startOf('month');

/**
 * @description Последний день прошлого месяца
 */
export const lastDayOfPrevMonth = dayjs().subtract(1, 'month').endOf('month');

/**
 * @desrciption Дата равная дате ровно месяц назад
 */
export const dateMonthAgo = dayjs().subtract(1, 'months');

/**
 * @description Получить дату начала и конца недели согласно дате
 * @param {Date} date
 */
export const getStartAndEndDatesOfWeek = (date: Date) => ({
  startDate: dayjs(date).startOf('isoWeek').format(dateFormatForBackend),
  endDate: dayjs(date).endOf('isoWeek').format(dateFormatForBackend)
});

/**
 * @description Склонять месяц
 * @param {string} date
 * @param {string} locale
 * @return {string}
 */
export const inclineMonth = (date: string, locale?: string): string => {
  return dayjs(date).locale(locale).format('D MMMM').split(' ')[1];
};

/**
 * @description Форматирование дат
 * @param {object} obj
 * @return {Promise<any>}
 */
export const formatDateValues = obj => {
  // Отформатируем дату рождения
  for (let i in obj) {
    if (isDayjs(obj[i])) {
      obj[i] = dayjs(obj[i])
        .locale('en')
        // 01-01-1970
        .format(dateFormatForBackend);
    }

    if (isObject(obj[i])) {
      formatDateValues(obj[i]);
    }
  }

  return obj;
};

export default {
  firstDayOfMonth,
  today,
  firstDayOfPrevMonth,
  lastDayOfPrevMonth,
  inclineMonth,
  formatDateValues
};
