import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Row, Typography, message, Col, Skeleton, Empty } from 'antd';
import { dateFormatForBackend } from 'helpers';
import { Slot } from 'types';
import { Teachers } from 'api/Teachers';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { UpcomingSlot } from './UpcomingSlot';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Title } = Typography;

/**
 * @description Виджет ближайших уроков
 * @return {React.ReactNode}
 */
export const UpcomingLessons = () => {
  const [hasLoad, setHasLoad] = useState<boolean>(false);
  const [upcomingLessons, setUpcomingLessons] = useState<Slot[]>(null);
  const [{ meTeacherId }] = useUserContext();
  const [strings, lang] = useLanguageContext();

  const renderUpcomingLessons = useMemo(() => {
    const upcomingLessonsList = _(upcomingLessons)
      .filter(slot => slot.type === 'lesson')
      .groupBy(slot => {
        const displayDate = dayjs(slot.startAt).locale(lang).format('dd. MMMM D');

        return `${displayDate}`;
      })
      .map((slots, date) => ({ date: _.toUpper(date), slots: slots }))
      .value();

    return upcomingLessonsList?.map(lesson => (
      <Col lg={24} xs={24} key={lesson.date}>
        <p>{lesson.date}</p>
        {lesson?.slots?.map(slot => (
          <UpcomingSlot slot={slot} key={slot.id.toString()} />
        ))}
      </Col>
    ));
  }, [upcomingLessons, lang]);

  const fetchSlotsByTeacher = async (): Promise<any> => {
    const today = dayjs().format(dateFormatForBackend);
    const threeDays = dayjs().add(3, 'days').format(dateFormatForBackend);
    try {
      setHasLoad(true);
      const { data } = await Teachers.getSlotsByTeacher(meTeacherId, today, threeDays);
      setUpcomingLessons(data.slots);
      setHasLoad(false);
    } catch {
      message.error(strings.ERROR_GETTING_THE_NEAREST_LESSONS, 2);
    }
  };

  useEffect(() => {
    fetchSlotsByTeacher();
  }, []);

  if (hasLoad) {
    return <Skeleton active={true} />;
  }

  return (
    <ErrorBoundary>
      <Row gutter={[10, 10]} style={{ marginBottom: 20 }}>
        <Col lg={24} xs={24}>
          <Title level={4}>{strings.UPCOMING_LESSONS}</Title>
        </Col>
        {upcomingLessons?.length === 0 && (
          <Col lg={24} xs={24}>
            <Empty description={strings.NO_UPCOMING_LESSONS} />
          </Col>
        )}
        {renderUpcomingLessons}
      </Row>
    </ErrorBoundary>
  );
};
