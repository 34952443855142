import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { useLastLocation } from 'react-router-last-location';

const { Title: TitleAnt } = Typography;

interface TitleProps {
  children: React.ReactNode;
  goBack: any;
  level?: 4 | 1 | 2 | 3;
}

export const Title = (props: TitleProps) => {
  const { level, children, goBack } = props;
  const lastLocation = useLastLocation();
  return (
    <TitleAnt level={level ? level : 3}>
      {lastLocation && <ArrowLeftOutlined onClick={goBack} style={{ marginInlineEnd: 6 }} />}
      {children}
    </TitleAnt>
  );
};
