import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Table, Row } from 'antd';
import { useLastLocation } from 'react-router-last-location';
import { Link, useHistory } from 'react-router-dom';
import { Logs } from 'api/Logs';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { mockPagination, MANUAL_INPUT_LIMIT } from 'Global/constants';
import { scrollTop } from 'helpers/scroll';
import { InvoicesLogsListItem, InvoicesLogsFilterParams } from 'types/logs';
import { PaginationInfo } from 'types/global';
import { Forbidden } from 'Global/modules/Errors/403';
import Pagination from 'Global/components/Pagination';
import { Filter } from './Filter';
import { columns } from './columns';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { first } from 'lodash';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import queryString from 'query-string';

const { Column } = Table;

interface InvoicesLogsProps {}

/**
 * @description  InvoicesLogs component
 * @param {object} props
 * @return {React.ReactNode}
 */
export const InvoicesLogs = (props: InvoicesLogsProps) => {
  const [invoicesLogsList, setInvoicesLogsList] = useState<InvoicesLogsListItem[]>([]);
  const [hasLoadInvoicesLogsList, setHasLoadInvoicesLogsList] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationInfo>(mockPagination);

  const [user] = useUserContext();
  const [strings] = useLanguageContext();
  const { goBack, push } = useHistory();
  const limit = MANUAL_INPUT_LIMIT;
  const { locationSearchParams } = useLocationSearchParams();
  const { pageCount, current } = pagination;

  const hasTopManager = user?.hasRoles?.topManager;
  const hasBranchDirector = user?.hasRoles?.branchDirector;
  const hasBranchDirectorOrTopManager = hasTopManager || hasBranchDirector;
  const { branches } = useGlobalCollectionsContext();

  /**
   * @description Пагинация
   * @param {number} page
   * @return {void}
   */
  const handlePage = async (page: number): Promise<any> => {
    const params = { ...locationSearchParams, page, limit };
    await getInvoicesLogsList(params);
    scrollTop();
  };

  const handleBack = (): void => goBack();
  const lastLocation = useLastLocation();

  /**
   * @description Получение списка InvoicesLogs Logs
   * @param {InvoicesLogsFilterParams} params
   * @return {Promise<any>}
   */
  const getInvoicesLogsList = async (values?: InvoicesLogsFilterParams): Promise<any> => {
    const params = { ...values };
    setHasLoadInvoicesLogsList(true);
    if (branches.length === 1) {
      // @ts-ignore
      params['branch'] = first(branches)?.id;
    }
    try {
      const {
        data: { items, paginationInfo }
      } = await Logs.getInvoicesLogsList(params);
      setInvoicesLogsList(items);
      setPagination(paginationInfo);
      push({ search: queryString.stringify(params) });
    } finally {
      setHasLoadInvoicesLogsList(false);
    }
  };

  useEffect(() => {
    if (branches.isNotEmpty()) {
      const params = locationSearchParams;
      getInvoicesLogsList(params);
    }
  }, [branches]); // eslint-disable-line

  if (!hasBranchDirectorOrTopManager)
    return (
      <Forbidden subTitle={strings.SORRY_YOU_DO_NOT_HAVE_ACCESS_TO_THE_REPORT}>
        <Button size="large" type="primary" icon={<ArrowLeftOutlined />}>
          <Link to="/" style={{ color: '#fff', paddingInlineStart: '10px' }}>
            {strings.BACK_TO_HOME}
          </Link>
        </Button>
      </Forbidden>
    );

  return (
    <Container>
      <StPageHeader onBack={goBack} title={strings.INVOICE_LOGS_MOSCOW}>
        <Filter getInvoicesLogsList={getInvoicesLogsList} />
      </StPageHeader>
      <Row gutter={[10, 10]}>
        <Table dataSource={invoicesLogsList} pagination={false} bordered={false} loading={hasLoadInvoicesLogsList}>
          {columns(strings).map(col => (
            <Column key={col.key} {...col} />
          ))}
        </Table>
        <Pagination count={pageCount} current={current} pageChange={handlePage} />
      </Row>
    </Container>
  );
};

export default { InvoicesLogs };
