import React, { useState } from 'react';
import { Button, Col, Flex, Form, Modal, Row, Select, Spin } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { TeacherSelect, TutorSelect } from 'Global/components/FormComponents';
import { accessLevels, AccessLevelsValue } from 'Global/constants';
import { OriginalCourseAccessParams } from 'types/courses';
import { Courses } from 'api';
import { FIRST_YEAR_ACTIVE, LONG_TERM_ACTIVE, SECOND_YEAR_ACTIVE } from 'Admin/People/Teachers/constants';

const { Option } = Select;

interface AccessFormProps {
  handler: () => Promise<any>;
  hasShowModal: boolean;
  setHasShowModal: (val: boolean) => void;
  originalCourseId: number | string;
}

enum AccessUserRole {
  admin = 'admin',
  teacher = 'teacher'
}

interface FieldData {
  key: number;
  name: number;
  isListField: boolean;
  fieldKey: number;
}

const filterValues = { status: [FIRST_YEAR_ACTIVE, SECOND_YEAR_ACTIVE, LONG_TERM_ACTIVE] };

export const AccessFormModal = ({ handler, hasShowModal, setHasShowModal, originalCourseId }: AccessFormProps) => {
  const [form] = Form.useForm();
  const [strings] = useLanguageContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [fieldTypes, setFieldTypes] = useState<{ [key: number]: AccessUserRole }>({});

  const handleSubmit = async (values: { users: OriginalCourseAccessParams[] }): Promise<any> => {
    const { users } = values;
    if (!users || users?.isEmpty()) {
      closeModal();
      return false;
    }
    setHasLoading(true);
    try {
      await Promise.all(
        users.map((params: OriginalCourseAccessParams) => Courses.addOriginalCoursesAccess(originalCourseId, params))
      );
      handler();
      closeModal();
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  const closeModal = (): void => {
    setHasShowModal(false);
    form.resetFields();
    setFieldTypes({});
  };

  const handleAdd = (type: AccessUserRole) => {
    form.setFieldsValue({ users: form.getFieldValue('users') || [] });
    setFieldTypes(prev => ({
      ...prev,
      [Object.keys(prev).length]: type
    }));
  };

  const handleRemove = (remove: (name: number | number[]) => void, index: number) => {
    remove(index);
    setFieldTypes(prev => {
      const newTypes = { ...prev };
      delete newTypes[index];
      const updatedTypes: { [key: number]: AccessUserRole } = {};

      Object.keys(newTypes).forEach(key => {
        const numKey = parseInt(key, 10);
        if (numKey > index) {
          updatedTypes[numKey - 1] = newTypes[numKey];
        } else {
          updatedTypes[numKey] = newTypes[numKey];
        }
      });
      return updatedTypes;
    });
  };

  return (
    <Modal
      title={strings.ADD_NEW_ACCESS}
      footer={false}
      open={hasShowModal}
      onCancel={closeModal}
      style={{ top: 20 }}
      width={600}
      destroyOnClose={true}
    >
      <Spin spinning={hasLoading}>
        <Form form={form} onFinish={handleSubmit} style={{ maxWidth: 600 }}>
          <Form.List name="users">
            {(fields, { add, remove }, { errors }) => (
              <>
                <Flex gap={10} align="center" style={{ marginBottom: 20 }}>
                  <Button
                    block
                    type="dashed"
                    onClick={() => {
                      handleAdd(AccessUserRole.teacher);
                      add();
                    }}
                    icon={<PlusOutlined />}
                  >
                    {strings.ADD_TEACHER}
                  </Button>
                  <Button
                    block
                    type="dashed"
                    onClick={() => {
                      handleAdd(AccessUserRole.admin);
                      add();
                    }}
                    icon={<PlusOutlined />}
                  >
                    {strings.ADD_ADMIN_SM}
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Flex>
                {fields.map((field: FieldData, index) => {
                  const type = fieldTypes[index];
                  return (
                    <Form.Item required={false} key={field.key}>
                      <Row gutter={[10, 10]}>
                        <Col lg={14} xs={24}>
                          <Form.Item
                            labelCol={{ style: { width: 80 } }}
                            name={[field.name, type]}
                            label={type === AccessUserRole.teacher ? strings.TEACHER : strings.ADMIN}
                            rules={[{ required: true }]}
                          >
                            {type === AccessUserRole.teacher ? (
                              <TeacherSelect filterValues={filterValues} />
                            ) : (
                              <TutorSelect />
                            )}
                          </Form.Item>
                        </Col>
                        <Col lg={8} xs={22}>
                          <Form.Item
                            name={[field.name, 'level']}
                            initialValue={type === AccessUserRole.teacher ? AccessLevelsValue.v : AccessLevelsValue.e}
                            rules={[{ required: true }]}
                          >
                            <Select disabled={type === AccessUserRole.teacher}>
                              {accessLevels.map(level => (
                                <Option key={level.value} value={level.value}>
                                  {level.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col lg={2} xs={2}>
                          <Form.Item>
                            <MinusCircleOutlined onClick={() => handleRemove(remove, index)} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  );
                })}
              </>
            )}
          </Form.List>
          <Flex justify="end" style={{ marginTop: 20 }}>
            <Button type="primary" htmlType="submit" loading={hasLoading}>
              {strings.SUBMIT}
            </Button>
          </Flex>
        </Form>
      </Spin>
    </Modal>
  );
};

export default { AccessFormModal };
