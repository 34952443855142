import { message } from 'antd';
import { Auth } from 'api/Auth';
import { storageService } from 'contexts/StorageContext';

/**
 * @description Смена пользователя
 * @param {string} email
 * @return {Promise<any>}
 */
export const switchUser = async (email: string): Promise<any> => {
  return await Auth.switchUser(email)
    .then(({ data }) => {
      storageService.setAccessToken(data.token);
      window.location.href = '/';
    })
    .catch(() => {
      message.error('Cannot switch, an error occurred..');
    });
};

/**
 * @description Модалка для переключения пользователей
 * @param {string} email - из поля loginAs !
 * @return {void}
 */
export const switchUserModal = (email: string, confirm): void => {
  confirm({
    title: 'Are you sure you want to change the user?',
    async onOk() {
      await switchUser(email);
    },
    okText: 'Switch user',
    cancelText: 'No',
    maskClosable: true,
    width: 300
  });
};

export default { switchUser, switchUserModal };
