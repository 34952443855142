import React from 'react';
import dayjs from 'dayjs';
import { dateFormat } from 'helpers/dates';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ChildDiscount } from 'types';

/**
 * @description Колонки для таблицы
 * @param {Function} showEditForm
 * @param {Function} showDeleteForm
 */
export const getColumns = (
  showEditForm: (discountId: number) => void,
  showDeleteForm: (discountId: number) => void
) => [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Discount',
    dataIndex: 'discount',
    key: 'discount'
  },
  {
    title: 'Student',
    dataIndex: 'child',
    key: 'child',
    render: (child: ChildDiscount) => `${child?.name ?? ''} ${child?.surname ?? ''}`
  },
  {
    title: 'Start at',
    dataIndex: 'startAt',
    key: 'startAt',
    render: (date: string) => date && <p>{dayjs(date).format(dateFormat)}</p>
  },
  {
    title: 'End at',
    dataIndex: 'endAt',
    key: 'endAt',
    render: (date: string) => date && <p>{dayjs(date).format(dateFormat)}</p>
  },
  {
    title: 'Actions',
    dataIndex: 'id',
    key: 'actions',
    render: (id: number) => (
      <div style={{ display: 'flex', gap: 8 }}>
        <Button icon={<EditOutlined />} onClick={() => showEditForm(id)} />
        <Button danger icon={<DeleteOutlined />} onClick={() => showDeleteForm(id)} />
      </div>
    )
  }
];

export default { getColumns };
