import React, { useState } from 'react';
import styled from 'styled-components';
import { UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Button, Input, Modal, Upload, Col, Spin, Row, message } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { isNull } from 'lodash';
import { getAllFormData } from 'helpers';
import { FeedbackParent } from 'Global/modules/Support/Modal/FeedackParent';
import { FeedbackAdmin } from 'Global/modules/Support/Modal/FeedbackAdmin';
import { FeedbackTeacher } from 'Global/modules/Support/Modal/FeedbackTeacher';
import { Link } from 'react-router-dom';
import { CUSTOM_FIELD_STATUS_ID, CUSTOM_FIELD_ROLE_WITHOUT_FIELD } from 'Global/modules/Support/Modal/constants';
import { CreateZendeskTicketParams } from 'types/Zendesk';
import { useUserContext } from 'contexts/UserContext';
import { useSupportRepository } from 'repos/SupportRepository';
import { SUPPORT } from 'Global/modules/Support/routes';
import { useLanguageContext } from 'contexts/LanguageContext';
import { QuickTips } from 'Global/components/QuickTips';

interface FeedbackModalProps extends FormComponentProps {
  onHide: () => void;
  hasShow: boolean;
  refetchTickets?: () => Promise<any>;
}

const StFeedbackForm = styled.div`
  .ant-col {
    padding-inline-start: 0 !important;
  }
`; // FIXME

const StyledP = styled.p`
  font-size: 14px;
  color: #dedede;
`;

/**
 * Компонент модального окна обратной связи
 * @return {React.ReactNode}
 */
export const FeedbackModalTemplate = React.memo(({ onHide, hasShow, refetchTickets, form }: FeedbackModalProps) => {
  const [user] = useUserContext();
  const supportRepo = useSupportRepository();
  const [files, setFiles] = useState<any[]>([]);
  const [hasLoading, setLoading] = useState<boolean>(false);
  const { getFieldDecorator, validateFields, resetFields } = form;
  const [strings] = useLanguageContext();
  const hasRiga = user?.branch?.id === 5;

  const sendFeedBack = async (params): Promise<any> => {
    try {
      setLoading(true);
      const response = await supportRepo.sendZendeskFeedback(params);
      if (response.status === 200) {
        setLoading(false);
        resetFields();
        setFiles([]);
        onHide();

        if (refetchTickets) {
          await refetchTickets();
        }

        message.success(strings.SUCCESSFULLY_SENT, 2);
      }
    } catch {
      message.error(strings.TICKET_CREATION_ERROR_TRY_AGAIN_LATER);
    }
  };

  const getFeedbackRequestData = (values: CreateZendeskTicketParams) => {
    let customFields = [];
    const { subject } = values;
    // Если тема письма не заполнена, то берем 120 букв от тела письма
    if (!subject || subject.length === 0) {
      values['subject'] = values.message.substr(0, 120);
    }
    // Преобразуем кастомные поля в нужный формат для зендеска
    for (const [key, value] of Object.entries(values.customFields)) {
      customFields.push({ id: key, value: value });
    }
    /**
     * Для РОДИТЕЛЕЙ убираем параметр role, но передаем на бэк.
     * Если текущий юзер - родитель то роль parent, если student, то студент
     */
    if (user?.hasRoles?.parent) {
      customFields.push({
        id: CUSTOM_FIELD_ROLE_WITHOUT_FIELD,
        value: 'parent'
      });
    }

    if (user?.hasRoles?.student) {
      customFields.push({
        id: CUSTOM_FIELD_ROLE_WITHOUT_FIELD,
        value: 'student'
      });
    }

    // Добавим ко всем заявкам статус submitted
    customFields.push({
      id: CUSTOM_FIELD_STATUS_ID,
      value: 'submitted'
    });
    // Уберем поля из формы
    delete values['customFields'];

    return {
      ...values,
      // Добавим исправленные
      customFields: customFields,
      originUrl: window.location.href,
      attachments: files
    };
  };

  const handleSend = (): void => {
    validateFields((errors, values) => {
      if (isNull(errors)) {
        // Образуем объект формы и отправим
        sendFeedBack(getAllFormData(getFeedbackRequestData(values)));
      }
    });
  };

  const handleUpload = () => {
    return false;
  };

  const uploadProps = {
    name: 'files',
    multiple: true,
    beforeUpload: handleUpload,
    onChange: ({ fileList }) => {
      setFiles(
        fileList.map(function (file) {
          if (file.hasOwnProperty('originFileObj')) {
            return file.originFileObj;
          }
          return file;
        })
      );
    },
    fileList: files
  };

  /**
   * @description Генерация формы фидбэка в зависимости от роли
   * @return {React.ReactNode}
   */
  const getFeedbackFormByRole = () => {
    if (user?.hasRoles?.parent || user?.hasRoles?.student) {
      return <FeedbackParent getFieldDecorator={getFieldDecorator} />;
    }
    if (user?.hasRoles?.teacher) {
      return <FeedbackTeacher getFieldDecorator={getFieldDecorator} />;
    }

    return <FeedbackAdmin getFieldDecorator={getFieldDecorator} />;
  };

  return (
    <Modal
      title={strings.CONTACT_AMI_TEAM}
      footer={[
        <Button key="back" onClick={onHide}>
          {strings.CANCEL}
        </Button>,
        <Button key="submit" type="primary" htmlType="submit" onClick={handleSend}>
          {strings.SEND}
        </Button>
      ]}
      open={hasShow}
      onCancel={onHide}
      width={600}
    >
      <Spin spinning={hasLoading} tip={`${strings.SENDING}...`}>
        <Form layout="vertical">
          <StFeedbackForm>
            <Row gutter={[10, 10]} style={{ marginBottom: 20 }}>
              <Col xs={24} lg={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Link to={SUPPORT} onClick={() => onHide()}>
                  {strings.MY_OLDER_REQUESTS}
                </Link>
                {!user?.hasRoles?.parent && !user?.hasRoles?.student && !hasRiga && (
                  <QuickTips label={strings.PROGRESS_REPORTS_QUICK_TIPS} />
                )}
              </Col>
            </Row>
            {getFeedbackFormByRole()}
            <Row gutter={[10, 10]}>
              <Col xs={24} lg={24}>
                <MyFormItem label={strings.TOPIC} colon={false}>
                  {getFieldDecorator('subject', {
                    rules: [{ required: false }]
                  })(<Input />)}
                </MyFormItem>
              </Col>
              <Col xs={24} lg={24}>
                <MyFormItem label={strings.MESSAGE} colon={false}>
                  {getFieldDecorator('message', {
                    rules: [{ required: true, message: strings.MESSAGE_IS_REQUIRED }]
                  })(<Input.TextArea style={{ height: '200px' }} />)}
                </MyFormItem>
              </Col>
              <Col xs={24} lg={24}>
                <MyFormItem label={strings.ATTACHMENTS} colon={false}>
                  <Upload {...uploadProps}>
                    <Button>
                      <UploadOutlined /> {strings.ATTACH}
                    </Button>
                  </Upload>
                  <StyledP>{strings.YOU_CAN_UPLOAD_MULTIPLE_FILES_AT_ONCE}</StyledP>
                </MyFormItem>
              </Col>
            </Row>
          </StFeedbackForm>
        </Form>
      </Spin>
    </Modal>
  );
});

export const FeedbackModal = Form.create<FeedbackModalProps>()(FeedbackModalTemplate);

export default { FeedbackModal };
