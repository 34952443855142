import React from 'react';
import { Select, Form, Col, DatePicker, Flex, Typography, Input, Switch, FormListFieldData } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { dateFormat } from 'helpers/dates';
import { useThemeContext } from 'contexts/ThemeContext';
import { IStudent, Status } from 'types';
import { useMediaQuery } from 'react-responsive';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { InputForMobile, TutorSelect } from 'Global/components/FormComponents';
import { AvatarUploader } from './AvatarUploader';
import { StRow } from './EditStudent';
import { languageExperience } from 'helpers';
import { NamePath } from 'antd/lib/form/interface';

const { Option } = Select;
const { Title } = Typography;

type BrunerStudentFormProps = {
  student?: IStudent;
  field?: FormListFieldData;
};

export const BrunerStudentForm: React.FC<BrunerStudentFormProps> = ({ student, field }) => {
  const [strings] = useLanguageContext();
  const { activeTheme } = useThemeContext();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { childStatuses } = useGlobalCollectionsContext();
  const { mainPhoto, tutor } = student || {};

  const getName = (name): NamePath => (field ? [field.name, name] : name);

  return (
    <Flex justify="space-between" align="start" gap={20} vertical={isMobile}>
      <AvatarUploader name={getName('avatar')} mainPhoto={mainPhoto ? mainPhoto : ''} />
      <StRow>
        <Col lg={24} xs={24}>
          <Title level={5} style={{ color: activeTheme.primaryColor }}>
            {strings.GENERAL_INFO}
          </Title>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item
            name={getName('name')}
            label={strings.NAME}
            rules={[{ required: true, message: `${strings.NAME} ${strings.IS_REQUIRED}` }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('surname')} label={strings.SURNAME}>
            <Input />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('nameHebrew')} label={strings.HEBREW_NAME}>
            <Input />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('surnameHebrew')} label={strings.HEBREW_SURNAME}>
            <Input />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('birthday')} label={strings.DATE_OF_BIRTH}>
            <DatePicker format={dateFormat} />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('gender')} label={strings.SEX}>
            <Select>
              <Option value={0}>{strings.MALE}</Option>
              <Option value={1}>{strings.FEMALE}</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('phone')} label={strings.MOBILE}>
            <InputForMobile />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('comment')} label={strings.COMMENT}>
            <Input.TextArea />
          </Form.Item>
        </Col>
      </StRow>
      <StRow>
        <Col lg={24} xs={24}>
          <Title level={5} style={{ color: activeTheme.primaryColor }}>
            {strings.STUDYING_INFO}
          </Title>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('status')} label={strings.STATUS}>
            <Select placeholder={strings.SELECT_STATUS}>
              {childStatuses?.map((status: Status) => (
                <Option value={status.id} key={status.id}>
                  {status.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('tutor')} label={strings.TUTOR}>
            <TutorSelect initOptions={tutor && [tutor]} />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('englishLevelComment')} label={strings.LANGUAGE_EXPERIENCE}>
            <Select allowClear>
              {languageExperience.map(level => (
                <Option key={`level-${level}`} value={level}>
                  {level}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </StRow>
      <StRow>
        <Col lg={24} xs={24}>
          <Title level={5} style={{ color: activeTheme.primaryColor }}>
            {strings.EXTRA_INFO}
          </Title>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('allergies')} label={strings.ALLERGIES}>
            <Input.TextArea />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('medicalConditions')} label={strings.OTHER_MEDICAL_CONDITIONS}>
            <Input.TextArea />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('psychologistEvaluation')} label={strings.PSYCHOLOGISTS_EVALUATION}>
            <Input.TextArea />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Flex gap={10} align="baseline">
            <span>{strings.TAKING_PHOTOS}</span>
            <Form.Item name={getName('takingPhotos')} valuePropName="checked">
              <Switch checkedChildren={strings.YES} unCheckedChildren={strings.NO} />
            </Form.Item>
          </Flex>
        </Col>
        <Col lg={24} xs={24}>
          <Flex gap={10} align="baseline">
            <span>{strings.SCHOOL_TRIPS}</span>
            <Form.Item name={getName('schoolTrips')} valuePropName="checked">
              <Switch checkedChildren={strings.YES} unCheckedChildren={strings.NO} />
            </Form.Item>
          </Flex>
        </Col>
      </StRow>
    </Flex>
  );
};
export default { BrunerStudentForm };
