import React, { useContext, useMemo } from 'react';
import { httpService } from 'api/core';
import { ICreateEditWeeklyEmailParams, IGetWeeklyEmailParams } from '../types/Mailing';
import { IRepositoryProvider } from './RepositoryProvider';

const MailingRepositoryContext = React.createContext<IMailingRepository>(null);

interface IMailingRepository {
  getMailingList: (params: IGetWeeklyEmailParams) => Promise<any>;
  getMailingById: (id: number | string) => Promise<any>;
  deleteMailing: (id: number | string) => Promise<any>;
  getPreviewMailingById: (id: number | string) => Promise<any>;
  createMailing: (params: FormData) => Promise<any>;
  editMailing: (id: number | string, params: FormData) => Promise<any>;
  getStudentsBySupergroup: (mailingId: number, supergroupId: number) => Promise<any>;
}

class MailingRepository implements IMailingRepository{
  getMailingList(params: IGetWeeklyEmailParams) {
    return httpService.get(`/api/v1/parents-mailing`, params, null, true);
  }

  getMailingById(id: number) {
    return httpService.get(`/api/v1/parents-mailing/${id}`, null, null, true);
  }

  getPreviewMailingById(id: number) {
    return httpService.get(`/api/v1/parents-mailing/${id}/preview`, null, null, true);
  }

  createMailing(params: FormData) {
    return httpService.post(`/api/v1/parents-mailing`, params, null, null, true);
  }

  editMailing(id, params: FormData) {
    return httpService.post(`/api/v1/parents-mailing/${id}`, params, null, true, true);
  }

  deleteMailing(id: number) {
    return httpService.delete(`/api/v1/parents-mailing/${id}`, null, null, true);
  }

  getStudentsBySupergroup(mailingId: number, supergroupId: number) {
    return httpService.get(
      `/api/v1/parents-mailing/${mailingId}/supergroup/${supergroupId}/students`,
      null,
      null,
      true
    );
  }
}

export const MailingRepositoryProvider: React.FC<IRepositoryProvider> = ({ children }) => {
  const service = useMemo(() => new MailingRepository(), []);

  return <MailingRepositoryContext.Provider value={service}>{children}</MailingRepositoryContext.Provider>;
};

export const useMailingRepository = () => {
  const service = useContext(MailingRepositoryContext);

  if (!service) {
    throw new Error('Mailing repository is unavailable');
  }

  return service;
};
