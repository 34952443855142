import React, { useContext, useState, useEffect } from 'react';
import { useUserContext } from 'contexts/UserContext';
import { useStudentRepository } from 'repos/StudentRepository';

const ChildContext = React.createContext(null);

export const ChildContextProvider = props => {
  const [user] = useUserContext();
  const studentRepository = useStudentRepository();
  const [examineTypes, setExamineTypes] = useState(null);
  const provider = [examineTypes];

  useEffect(() => {
    if (user?.hasRoles?.admin || user?.hasRoles?.teacher) {
      (async () => {
        try {
          const { data } = await studentRepository.getExamineTypes();
          const map = new Map(Object.entries(data));
          setExamineTypes(map);
        } catch {}
      })();
    }
  }, [user]);

  return <ChildContext.Provider value={provider}>{props.children}</ChildContext.Provider>;
};

export const useChildContext = () => {
  const service = useContext(ChildContext);

  if (!service) {
    throw new Error('Child context is unavailable');
  }

  return service;
};
