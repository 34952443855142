import React from 'react';
import dayjs from 'dayjs';
import { CheckCircleTwoTone, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { BASE_TEACHERS } from 'Admin/People/Teachers/routes';
import { reversedDateFormatForBackend } from 'helpers/dates';

// Types
import { Branch } from 'types';
import { Teacher } from 'types/Teachers/teacher';
import { LessonListItemFilledIn, LessonsListItem, ChildGroup } from 'types/education';

export const columns = strings => [
  {
    title: strings.DATE,
    dataIndex: 'date',
    key: 'date',
    width: 120,
    render: (date: string) => date && dayjs(date).format(reversedDateFormatForBackend)
  },
  {
    title: strings.BRANCH,
    dataIndex: 'branch',
    key: 'branch',
    render: (branch: Branch, lesson: LessonsListItem) => <p>{lesson?.teacher?.branch?.name}</p>
  },
  {
    title: strings.TEACHER,
    dataIndex: 'teacher',
    key: 'teacher',
    render: (teacher: Teacher) => (
      <Link to={`${BASE_TEACHERS}/${teacher.id}`}>
        {teacher?.name} {teacher?.surname}
      </Link>
    )
  },
  {
    title: strings.GROUP,
    dataIndex: 'course',
    key: 'course',
    render: (course: ChildGroup) => <Link to={`/group/${course?.id}`}>{course?.name}</Link>
  },
  {
    title: strings.TOPIC,
    dataIndex: 'title',
    key: 'title',
    width: 200,
    render: (title: string, lesson: LessonsListItem) => title && <Link to={`/le/${lesson.id}`}>{title}</Link>
  },
  {
    title: strings.FILLED_IN,
    dataIndex: 'filledInColor',
    key: 'filledInColor',
    render: (filledInColor: string) => {
      const iconColor = (filledInColor: string): string => {
        if (filledInColor === 'green') {
          return '#52c41a';
        } else if (filledInColor === 'red') {
          return '#eb2f96';
        } else if (filledInColor === 'yellow') {
          return '#FFC107';
        }
      };

      return <CheckCircleTwoTone twoToneColor={iconColor(filledInColor)} />;
    }
  },
  {
    title: strings.ACTION,
    dataIndex: 'id',
    key: 'action',
    render: (id: number) => (
      <Link to={`/le/${id}`}>
        <EditOutlined />
      </Link>
    )
  }
];

export default { columns };
