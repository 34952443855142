import React, { useEffect, useState } from 'react';
import { Table, Spin, Row } from 'antd';
import { isEmpty } from 'lodash';
import { OriginalCourse } from 'types/courses';
import { GetCourseGroupsParams } from 'types/education';
import Pagination from 'Global/components/Pagination';
import { scrollTop } from 'helpers/scroll';
import { Filter } from './Filter';
import { getColumns } from './columns';
import { PaginationInfo } from 'types/global';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { useHistory } from 'react-router-dom';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import queryString from 'query-string';
import { coursesRepository } from 'repos/CoursesRepository';

export const GroupCourses = () => {
  const [groups, setGroups] = useState<OriginalCourse[]>([]);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>();
  const [hasLoading, setLoading] = useState<boolean>(false);
  const [{ meTeacherId }] = useUserContext();
  const { pageCount, current } = paginationInfo || {};
  const { goBack, push } = useHistory();
  const { locationSearchParams } = useLocationSearchParams();
  const [strings] = useLanguageContext();

  const getCourseGroups = async (params?: GetCourseGroupsParams): Promise<any> => {
    params['teacher'] = meTeacherId;
    setLoading(true);
    try {
      const {
        data: { items, paginationInfo }
      } = await coursesRepository.getCourseGroups(params);

      setGroups(items);
      setPaginationInfo(paginationInfo);
      push({ search: queryString.stringify(params) });
      scrollTop();
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const handlePage = (page: number) => {
    getCourseGroups({ ...locationSearchParams, page });
  };

  const onFilter = async (params: GetCourseGroupsParams): Promise<any> => {
    await getCourseGroups(params);
  };

  useEffect(() => {
    if (isEmpty(locationSearchParams)) {
      //поле active по умолчанию true
      getCourseGroups({ active: 1 });
    } else {
      getCourseGroups(locationSearchParams);
    }
  }, [meTeacherId]);

  return (
    <Container>
      <StPageHeader onBack={goBack} title={strings.GROUP_COURSES}>
        <Filter onFilter={onFilter} />
      </StPageHeader>
      <Spin spinning={hasLoading} tip={`${strings.LOADING}...`}>
        <Row gutter={[10, 10]}>
          <Table columns={getColumns(strings)} dataSource={groups} pagination={false} />
          {pageCount > 1 && <Pagination count={pageCount} current={current} pageChange={handlePage} />}
        </Row>
      </Spin>
    </Container>
  );
};
