import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select, Input, Button, Typography, Col, Row, DatePicker, TimePicker } from 'antd';
import { AddMarketingEvents as StAddMarketingEvents } from '../styles';
import { dateFormat } from 'helpers/dates';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { History } from 'history';
import { useLastLocation } from 'react-router-last-location';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Title } = Typography;
const { Option } = Select;

interface addMarketingEventsProps {
  form: WrappedFormUtils;
  history: History;
}

export const AddMarketingEvents = Form.create({})((props: addMarketingEventsProps) => {
  const {
    form: { getFieldDecorator },
    history: { goBack }
  } = props;
  const [strings] = useLanguageContext();
  const lastLocation = useLastLocation();
  const handleBack = () => goBack();
  const { branches } = useGlobalCollectionsContext();

  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
  };

  return (
    <StAddMarketingEvents>
      <Title level={3}>
        {lastLocation && <ArrowLeftOutlined onClick={handleBack} />} {strings.ADDING_AN_EVENT}
      </Title>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Row>
          <Col lg={5}>
            {getFieldDecorator('title', {
              rules: [{ required: true, message: `${strings.TITLE} ${strings.IS_REQUIRED}` }]
            })(<Input placeholder={strings.TITLE} />)}
          </Col>
          <Col lg={5}>
            {getFieldDecorator('locale', {
              rules: [{ required: true, message: `${strings.LOCALE} ${strings.IS_REQUIRED}` }]
            })(<Select placeholder={strings.LOCALE} />)}
          </Col>
          <Col lg={5}>
            {getFieldDecorator('branch', {
              rules: [{ required: false }]
            })(
              <Select placeholder={strings.BRANCH}>
                {branches
                  ? branches.map(branch => (
                      <Option value={branch.id} key={branch.id}>
                        {branch.name}
                      </Option>
                    ))
                  : null}
              </Select>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            {getFieldDecorator('promoBudgetRub', {
              rules: [{ required: false }]
            })(<Select placeholder={strings.PROMO_BUDGET_RUB} />)}
          </Col>
          <Col lg={5}>
            {getFieldDecorator('overallBudgetRub', {
              rules: [{ required: false }]
            })(<Select placeholder={strings.OVERALL_BUDGET_RUB} />)}
          </Col>
          <Col lg={5}>
            {getFieldDecorator('targetGroup', {
              rules: [{ required: false }]
            })(<Select placeholder={strings.TARGET_GROUP} />)}
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            {getFieldDecorator('date', {
              rules: [{ required: false }]
            })(<DatePicker format={dateFormat} placeholder={strings.DATE} />)}
          </Col>
          <Col lg={5}>
            {getFieldDecorator('time', {
              rules: [{ required: false }]
            })(<TimePicker placeholder={strings.TIME} format="HH:mm" needConfirm={false} />)}
          </Col>
          <Col lg={5}>
            {getFieldDecorator('plannedNumber', {
              rules: [{ required: false }]
            })(<Input placeholder={strings.PLANNED_NUMBER_OF_PARTICIPANTS} />)}
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            {getFieldDecorator('targetGroupAge', {
              rules: [{ required: false }]
            })(<Input placeholder={strings.TARGET_GROUP_AGE} />)}
          </Col>
          <Col lg={5}>
            {getFieldDecorator('registrationStarts', {
              rules: [{ required: false }]
            })(<Input placeholder={strings.REGISTRATION_STARTS} />)}
          </Col>
          <Col lg={5}>
            {getFieldDecorator('registrationEnds', {
              rules: [{ required: false }]
            })(<Input placeholder={strings.REGISTRATION_ENDS} />)}
          </Col>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Col lg={15}>
            <Button type="primary" htmlType="submit">
              {strings.ADD_EVENT}
            </Button>
          </Col>
        </Row>
      </Form>
    </StAddMarketingEvents>
  );
});

export default { AddMarketingEvents };
