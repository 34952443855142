import React, { useMemo, useContext } from 'react';
import { httpService } from 'api/core';
import { IPerformerFilterParams, IPerformerPostParams } from 'types/performers';
import { IRepositoryProvider } from './RepositoryProvider';

const PerformersRepositoryContext = React.createContext<IPerformersRepository>(null);
interface IPerformersRepository {
  getPerformers: (params: IPerformerFilterParams) => Promise<any>;
  getPerformerById: (id: number | string) => Promise<any>;
  editPerformer: (id: number | string, data: IPerformerPostParams) => Promise<any>;
  createPerformer: (data: IPerformerPostParams) => Promise<any>;
  deletePerformer: (id: number | string) => Promise<any>;
  getLastPerformersByStudentId: (studentId: number | string) => Promise<any>;
  getFamilyPerformers: (familyId: number | string) => Promise<any>;
}

class PerformersRepository implements IPerformersRepository {
  getPerformers(params: IPerformerFilterParams) {
    return httpService.get('/api/v1/performers', params, null, true);
  }
  getPerformerById(id: number | string) {
    return httpService.get(`/api/v1/performers/${id}`, null, null, true);
  }

  editPerformer(id: number | string, data: IPerformerPostParams) {
    return httpService.put(`/api/v1/performers/${id}`, data, null, null, true);
  }

  createPerformer(data: IPerformerPostParams) {
    return httpService.post(`/api/v1/performers`, data, null, null, true);
  }

  deletePerformer(id: number | string) {
    return httpService.delete(`/api/v1/performers/${id}`, null, null, true);
  }
  getLastPerformersByStudentId(studentId: number | string) {
    return httpService.get(`/api/v1/child/${studentId}/last-performer`, null, null, true);
  }
  getFamilyPerformers(familyId: number | string) {
    return httpService.get(`/api/v1/family/${familyId}/performers`, null, null, true);
  }
}

export const usePerformersRepository = () => {
  const service = useContext(PerformersRepositoryContext);

  if (!service) {
    throw new Error('Performers repository is unavailable');
  }

  return service;
};

export const PerformersRepositoryProvider: React.FC<IRepositoryProvider> = ({ children }) => {
  const service = useMemo(() => new PerformersRepository(), []);

  return <PerformersRepositoryContext.Provider value={service}>{children}</PerformersRepositoryContext.Provider>;
};
