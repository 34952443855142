import styled from 'styled-components';
import { Row, Typography } from 'antd';
import { slideInLeft } from '../Global/animations';
const { Text } = Typography;

export const Teacher = styled.div`
  margin: 20px;
  padding: 30px 20px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 46px -15px rgb(0 0 0 / 12%);
  .ant-col {
    padding: 0 6px;
  }
`;

export const MainBlock = styled.div`
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
`;

export const CollapseItem = styled.div`
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 10px;
  .ant-collapse-item {
    margin-bottom: 0 !important;
  }
`;

export const Counter = styled.div`
  width: 1rem;
  height: 1rem;
  background: #006d75;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  color: white;
  border-radius: 50%;
  font-weight: 400;
  font-size: 0.75rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Senders = styled.div``;
export const Sender = styled.div<{
  isActive: boolean;
}>`
  ${props => props.isActive && 'background: rgba(187, 255, 255, 0.6);'}

  padding: 1rem;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid #dadada;
  }
`;

export const MessageBox = styled.div`
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 1rem;
`;

export const MessageBoxMessage = styled(Row)`
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;
export const MessageBoxText = styled(MessageBox)`
  padding: 0.5rem;
`;
export const MessageInput = styled.input`
  background: none;
  outline: none;
  box-shadow: none;

  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0.5rem;

  width: 100%;
`;

export const MessageText = styled(Text)`
  text-overflow: ellipsis;
`;

const Courses = styled.div`
  margin: 20px;
  padding: 30px 20px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 46px -15px rgb(0 0 0 / 12%);
  .ant-col {
    padding: 0 6px;
  }
  .ant-select {
    width: 100%;
  }

  .no-padding {
    & .ant-col {
      padding: 0;
    }
  }
`;

export const GroupCourse = styled(Courses)`
  animation: ${slideInLeft} 0.6s ease-in;
  box-shadow: 0px 0px 46px -15px rgba(0, 0, 0, 0.12);
  .ant-col {
    padding: 0 6px;
  }
  .ant-select {
    width: 100%;
  }
  .ant-table-row {
    cursor: pointer;
  }
`;

export const FilterGroupCourse = styled.div`
  margin: 30px 0;
`;

export default {
  Teacher,
  MainBlock,
  CollapseItem,
  Counter,
  Senders,
  MessageBoxMessage,
  MessageBoxText,
  MessageInput,
  MessageText,
  MessageBox,
  Sender,
  GroupCourse,
  FilterGroupCourse
};
