import React, { useEffect, useMemo, useState } from 'react';
import { Select, Button, DatePicker, Row, Col, Checkbox, Form } from 'antd';
import dayjs from 'dayjs';
import { dateFormat } from 'helpers/dates';
import { pickBy, identity } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { AcademicProgressParams, Subject } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { FilterButtons } from 'Global/components/FilterButtons';
import { useUserContext } from 'contexts/UserContext';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { FormItemWithoutMargin } from 'Global/styles';
import { ProgressReportParams } from 'types/Reports';
import { StageSelect, StudentSelect, TutorSelect, TeacherSelect } from 'Global/components/FormComponents';

const { Option } = Select;

interface AcademicProgressFilterProps {
  onFilter: (params?: AcademicProgressParams) => Promise<any>;
  defFilterValues: AcademicProgressParams;
}

export const Filter = ({ onFilter, defFilterValues }: AcademicProgressFilterProps) => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const hasTopManager = user?.hasRoles?.topManager;
  const [form] = Form.useForm();
  const { branches, subjects } = useGlobalCollectionsContext();
  const [isVisibleFilter, setIsVisibleFilter] = useState<boolean>(false);
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);
  const { locationSearchParams } = useLocationSearchParams();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const { startAt, endAt, branch, commentType } = locationSearchParams || {};

  const [selectedBranch, setSelectedBranch] = useState<number>(branch);

  //  для типа 'For parents' показываем чекбокс 'Read by parent', иначе скрываем
  const [hasShowReadByParent, setHasShowReadByParent] = useState<boolean>(
    commentType && commentType === 2 ? true : false
  );

  /**
   * @description Submit формы поиска
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = async (values): Promise<any> => {
    // Удаляем ключи со значением undefined и конвертируем даты под бэкенд формат
    const fields = pickBy(values, identity);
    try {
      setHasSubmitLoading(true);
      await onFilter({ ...fields, page: 1 });
    } finally {
      setHasSubmitLoading(false);
    }
  };

  /**
   * @description Очистка фильтра
   * @return {Promise<any>}
   */
  const handleClear = async (): Promise<any> => {
    await onFilter(defFilterValues);
    form.resetFields();
  };

  const initValues: ProgressReportParams = useMemo(
    () => ({ ...locationSearchParams, startAt: startAt && dayjs(startAt), endAt: endAt && dayjs(endAt) }),
    [locationSearchParams]
  );

  useEffect(() => {
    branch && setSelectedBranch(branch);
  }, [branch]);

  useEffect(() => {
    form.setFieldsValue(initValues);
  }, [initValues]);

  return (
    <>
      {isMobile && (
        <Button type="link" size="large" block onClick={() => setIsVisibleFilter(!isVisibleFilter)}>
          {!isVisibleFilter ? strings.HIDE_FILTER : strings.SHOW_FILTER}
          {!isVisibleFilter ? <CaretUpOutlined /> : <CaretDownOutlined />}
        </Button>
      )}
      {!isVisibleFilter && (
        <Form form={form} onFinish={handleSubmit} initialValues={initValues}>
          <Row gutter={[10, 10]}>
            <Col xs={24} lg={4}>
              <FormItemWithoutMargin name="startAt">
                <DatePicker format={dateFormat} placeholder={strings.START} />
              </FormItemWithoutMargin>
            </Col>
            <Col xs={24} lg={4}>
              <FormItemWithoutMargin name="endAt">
                <DatePicker format={dateFormat} placeholder={strings.END} />
              </FormItemWithoutMargin>
            </Col>
            <Col xs={24} lg={4}>
              <FormItemWithoutMargin name="teacherTutor">
                <TutorSelect placeholder={strings.ADM} />
              </FormItemWithoutMargin>
            </Col>
            <Col xs={24} lg={4}>
              <FormItemWithoutMargin name="studentTutor">
                <TutorSelect placeholder={strings.STUDENT_TUTOR} />
              </FormItemWithoutMargin>
            </Col>
            <Col xs={hasTopManager ? 24 : 0} lg={hasTopManager ? 4 : 0}>
              <FormItemWithoutMargin name="branch" hidden={!hasTopManager}>
                <Select placeholder={strings.BRANCH} allowClear onChange={val => setSelectedBranch(val)}>
                  {branches.map(branch => (
                    <Option value={branch.id} key={branch.id}>
                      {branch.name}
                    </Option>
                  ))}
                </Select>
              </FormItemWithoutMargin>
            </Col>
            <Col xs={24} lg={4}>
              <FormItemWithoutMargin name="stage">
                <StageSelect branch={selectedBranch} />
              </FormItemWithoutMargin>
            </Col>
            <Col xs={24} lg={4}>
              <FormItemWithoutMargin name="commentType">
                <Select
                  placeholder={strings.TYPE}
                  onChange={(value: number) =>
                    //  для типа 'For parents' показываем чекбокс 'Read by parent', иначе скрываем
                    value === 2 ? setHasShowReadByParent(true) : setHasShowReadByParent(false)
                  }
                >
                  <Option value={1} key="1">
                    {strings.ALL}
                  </Option>
                  <Option value={2} key="2">
                    {strings.FOR_PARENTS}
                  </Option>
                  <Option value={3} key="3">
                    {strings.FOR_STUDENTS}
                  </Option>
                  <Option value={4} key="4">
                    {strings.FOR_OFFICE}
                  </Option>
                </Select>
              </FormItemWithoutMargin>
            </Col>
            <Col xs={24} lg={4}>
              <FormItemWithoutMargin name="subject">
                <Select placeholder={strings.SUBJECT} allowClear>
                  {subjects.map((subject: Subject) => (
                    <Option value={subject.id} key={subject.id}>
                      {subject.name}
                    </Option>
                  ))}
                </Select>
              </FormItemWithoutMargin>
            </Col>
            <Col xs={24} lg={4}>
              <FormItemWithoutMargin name="teacher">
                <TeacherSelect placeholder={strings.COMMENT_AUTHOR} />
              </FormItemWithoutMargin>
            </Col>
            <Col xs={24} lg={4}>
              <FormItemWithoutMargin name="student">
                <StudentSelect placeholder={strings.STUDENT} />
              </FormItemWithoutMargin>
            </Col>
            <Col xs={24} lg={4}>
              <FormItemWithoutMargin name="commentApproveType">
                <Select placeholder={strings.APPROVED}>
                  <Option value={0} key="0">
                    {strings.ALL}
                  </Option>
                  <Option value={1} key="1">
                    {strings.APPROVED}
                  </Option>
                  <Option value={2} key="2">
                    {strings.UNAPPROVED}
                  </Option>
                </Select>
              </FormItemWithoutMargin>
            </Col>
            {hasShowReadByParent && (
              <Col xs={24} lg={4}>
                <FormItemWithoutMargin name="isCommentForParentRead" valuePropName="checked">
                  <Checkbox>{strings.READ_BY_PARENT}</Checkbox>
                </FormItemWithoutMargin>
              </Col>
            )}
          </Row>
          <FilterButtons handleClear={handleClear} hasSubmitLoading={hasSubmitLoading} />
        </Form>
      )}
    </>
  );
};

export default { Filter };
