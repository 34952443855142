import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select, Button, Col, Row } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { Branch } from 'types';
import { SupergroupListParams } from 'types/courses';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useUserContext } from 'contexts/UserContext';
import { FilterButtons } from 'Global/components/FilterButtons';
import { useLocationSearchParams } from '../../../hooks/useLocationSearchParams';

const { Option } = Select;

interface FilterProps extends FormComponentProps {
  getSupergroups: (params?: SupergroupListParams) => Promise<any>;
  onDeactivateSuperGroups: () => Promise<any>;
  selectedRowKeys: number[];
}

/**
 * @description Фильтр супергрупп
 * @param {FilterProps} props
 * @return {React.ReactNode}
 */
const FilterTemplate = (props: FilterProps) => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const {
    form: { getFieldDecorator, validateFields, resetFields },
    getSupergroups,
    onDeactivateSuperGroups,
    selectedRowKeys
  } = props;

  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);
  const { locationSearchParams } = useLocationSearchParams();
  const { branch } = locationSearchParams || {};

  const { branches } = useGlobalCollectionsContext();

  const clearForm = async (): Promise<any> => {
    resetFields();
    await getSupergroups();
  };

  /**
   * @description Отправка формы
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFields(async (err, values): Promise<any> => {
      if (!err) {
        try {
          setHasSubmitLoading(true);
          await getSupergroups({ branch: values.branch });
        } catch {
        } finally {
          setHasSubmitLoading(false);
        }
      }
    });
  };

  const extraBtn = selectedRowKeys.length !== 0 && (
    <Button danger size="large" onClick={onDeactivateSuperGroups}>
      {strings.DEACTIVATE}
    </Button>
  );

  if (user?.hasRoles?.topManager || user?.hasRoles?.branchDirector) {
    return (
      <Form onSubmit={handleSubmit}>
        <Row gutter={[10, 10]}>
          <Col xs={24} lg={6}>
            {getFieldDecorator('branch', {
              initialValue: branch
            })(
              <Select placeholder={strings.BRANCH}>
                <Option value={undefined}>&nbsp;</Option>
                {branches.map((branch: Branch) => (
                  <Option value={branch.id} key={branch.id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            )}
          </Col>
          <FilterButtons
            handleClear={clearForm}
            isSameRow={true}
            extra={extraBtn}
            hasSubmitLoading={hasSubmitLoading}
          />
        </Row>
      </Form>
    );
  }

  return null;
};

export const Filter = Form.create<FilterProps>({})(FilterTemplate);

export default { Filter };
