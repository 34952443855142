import React from 'react';
import { Subject } from 'types';
import { Link } from 'react-router-dom';
import { CourseGroup } from 'types/TeacherAccount/Courses';

export const getColumns = strings => [
  {
    title: strings.TITLE,
    key: 'title',
    render: (subject: CourseGroup) => {
      return <Link to={`/group/${subject.id}`}>{subject.groupName}</Link>;
    }
  },
  {
    title: strings.DURATION_CLASSROOM_HOUR,
    key: 'duration',
    dataIndex: 'duration'
  },
  {
    title: strings.SUBJECT,
    key: 'subject',
    dataIndex: 'subject',
    render: (subject: Subject) => subject?.name ?? '-'
  },
  {
    title: strings.ORIGINAL_COURSE_SYLLABUS,
    key: 'course',
    render: ({ groupName, version }) => {
      // Если нет _ тогда по пробелу разделяем
      const prefix = groupName.split('_').join(', ').split(' ')[1];
      return `${prefix ?? ''} ${strings.ENGLISH} > ${version}`;
    }
  },
  {
    title: strings.NUMBER_OF_CLASSES_PER_WEEK,
    key: 'number',
    dataIndex: 'version'
  }
];
