import React, { useState, useEffect } from 'react';
import { Typography, Table } from 'antd';
import { EmailHistory as StEmailHistory } from '../styles';
import { columns } from './columns';
import { Location } from 'history';
import { scrollTop } from 'helpers/scroll';
import { Mail } from 'api/Mail';
// FIXME: нужен name export (используется много где)
import Pagination from 'Global/components/Pagination';

const { Title } = Typography;
const { Column } = Table;

interface emailHistoryProps {
  location: Location;
}

export const EmailHistory = (props: emailHistoryProps) => {
  const [loadSessionsList, setLoadSessionsList] = useState<boolean>(false);
  const [mailSessionsList, setMailSessionsList] = useState([]);
  const [pagination, setPagination] = useState({});
  const { pageCount, current }: any = pagination || '';

  /**
   * @description Получение Sms history
   * @return {Promise<any>}
   */
  const getMailHistory = async (page?: number): Promise<any> => {
    setLoadSessionsList(true);
    await Mail.getMailSessionsList(page)
      .then(({ data: { sessions, paginationInfo } }) => {
        setMailSessionsList(sessions);
        setPagination(paginationInfo);
        setLoadSessionsList(false);
      })
      .catch(() => {});
  };

  const handlePage = (page: number): void => {
    getMailHistory(page);
    scrollTop();
  };

  useEffect(() => {
    getMailHistory(1);
  }, []);

  return (
    <StEmailHistory>
      <Title level={3}>Email history</Title>
      <Table pagination={false} dataSource={mailSessionsList} loading={loadSessionsList}>
        {columns.map(col => (
          <Column key={col.key} {...col} />
        ))}
      </Table>
      <Pagination count={pageCount} current={current} pageChange={handlePage} />
    </StEmailHistory>
  );
};

export default { EmailHistory };
