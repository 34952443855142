import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { Table, Button, Typography, Modal, Input, Space, Row, Col } from 'antd';
import { useLangRepository } from 'repos/LangRepository';
import _ from 'lodash';
import { AddTranslation } from './AddTranslation';
import { debounce } from 'lodash';
import { copyingText } from 'helpers';
import { useLanguageContext } from 'contexts/LanguageContext';

const StyledTransitions = styled.div`
  margin: 30px;
  .ant-col {
    padding: 0 5px;
  }
`;

const { Title } = Typography;

export const EN_KEY = 'en';
export const RU_KEY = 'ru';
export const LV_KEY = 'lv';
export const GE_KEY = 'ka';
export const IW_KEY = 'iw';

export const LANGS = [EN_KEY, RU_KEY, LV_KEY, GE_KEY, IW_KEY] as const;

export type Lang = (typeof LANGS)[number];

function TranslationInput({ id, lang, defaultValue, getTranslations, key }) {
  const langRepo = useLangRepository();
  const [value, setValue] = useState<string>(defaultValue);

  const updateTranslation = async (): Promise<void> => {
    await langRepo.updateTranslation(id, { translation: value, lang: lang, key: key });

    await getTranslations();
  };
  return (
    <div key={id} style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', gap: 5 }}>
      <Input value={value} onChange={({ target: { value } }) => setValue(value)} style={{ margin: 0, width: 200 }} />
      <Button size="large" onClick={updateTranslation}>
        Update
      </Button>
    </div>
  );
}

export function Translations() {
  const [strings] = useLanguageContext();
  const langRepo = useLangRepository();
  const [translations, setTranslations] = useState([]);
  const [hasLoadTranslations, setHasLoadTranslations] = useState<boolean>(false);
  const [{ confirm }, contextHolder] = Modal.useModal();

  const getTranslations = async () => {
    try {
      setHasLoadTranslations(true);
      const { data } = await langRepo.getTranslations();
      setTranslations(_(data).toArray().flattenDeep().orderBy('id', 'desc').value());
    } catch {
      setTranslations([]);
    } finally {
      setHasLoadTranslations(false);
    }
  };

  const onDeleteTranslation = (id: number) => {
    confirm({
      title: 'Are you sure',
      okType: 'danger',
      onOk: async () => {
        try {
          await langRepo.deleteTranslation(id);
          await getTranslations();
        } catch {}
      }
    });
  };

  const handleChange = debounce(async value => {
    try {
      setHasLoadTranslations(true);
      const { data } = await langRepo.getTranslations({ translation: value });
      setTranslations(_.flattenDeep(_.toArray(data)));
    } catch {
      setTranslations([]);
    } finally {
      setHasLoadTranslations(false);
    }
  }, 500);

  const handleChangeKey = debounce(async value => {
    try {
      setHasLoadTranslations(true);
      const { data } = await langRepo.getTranslations({ key: value.toUpperCase() });
      setTranslations(_.flattenDeep(_.toArray(data)));
    } catch {
      setTranslations([]);
    } finally {
      setHasLoadTranslations(false);
    }
  }, 500);

  const columns = [
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
      width: 250,
      render: key => {
        return (
          <>
            <span>{key}</span>
            <Button
              style={{ marginInlineStart: 10 }}
              icon={<CopyOutlined />}
              size="small"
              onClick={async () => {
                await copyingText(`strings.${key}`, () => {});
              }}
            />
          </>
        );
      }
    },
    {
      title: 'Lang',
      dataIndex: 'lang',
      key: 'lang',
      editable: true
    },
    {
      title: 'Translation',
      dataIndex: 'translation',
      key: 'translation',
      editable: true,
      render: (translation, { id, lang, key }) => (
        <TranslationInput getTranslations={getTranslations} id={id} key={key} defaultValue={translation} lang={lang} />
      )
    },
    {
      title: 'Operations',
      dataIndex: 'id',
      key: 'id',
      render: (id: number) => (
        <Button size="large" danger onClick={() => onDeleteTranslation(id)} icon={<DeleteOutlined />}>
          Delete
        </Button>
      )
    }
  ];

  useEffect(() => {
    getTranslations();
  }, []);

  return (
    <StyledTransitions>
      {contextHolder}
      <Title level={4}>Translations</Title>
      <AddTranslation getTranslations={getTranslations} />
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col lg={24}>
          <Input
            type="search"
            placeholder={strings.SEARCH_BY_TRANSLATION}
            onChange={e => handleChange(e.target.value)}
          />
        </Col>
        <Col lg={24}>
          <Input type="search" placeholder={strings.SEARCH_BY_KEY} onChange={e => handleChangeKey(e.target.value)} />
        </Col>
      </Row>
      <Table
        bordered={true}
        rowKey="id"
        pagination={{ pageSize: 50 }}
        dataSource={translations}
        loading={hasLoadTranslations}
        columns={columns}
      />
    </StyledTransitions>
  );
}
