import React, { useMemo } from 'react';
import { httpService } from 'api/core';
import { GetCourseGroupsParams } from '../types/education';
import { IOutcomesByOriginalLesson, OriginalCoursesParams, SupergroupListParams } from '../types/courses';
import { IRepositoryProvider } from './RepositoryProvider';

const CoursesRepositoryContext = React.createContext<ICoursesRepository>(null);

interface ICoursesRepository {
  courseGroupsBulkDeactivate: (coursegroups: number[]) => Promise<any>;
  superGroupsBulkDeactivate: (coursegroups: number[]) => Promise<any>;
  uploadOriginalCourseFromFile: (params) => Promise<any>;
  getCourseGroups: (params: GetCourseGroupsParams) => Promise<any>;
  getCourseGroupById: (id: number | string) => Promise<any>;
  getTeachersByGroup: (groupId: number | string) => Promise<any>;
  getLessonsPlanByGroup: (groupId: number | string) => Promise<any>;
  getSuperGroupListByGroup: (groupId: number | string) => Promise<any>;
  getStudentsByGroup: (groupId: number, isActive?: boolean) => Promise<any>;
  getGroupsByTeacher: (teacherId: number | string) => Promise<any>;
  getOriginalCourseById: (courseId: number | string) => Promise<any>;
  getOriginalCourses: (params: OriginalCoursesParams) => Promise<any>;
  deleteOriginalCourse: (courseId: number | string) => Promise<any>;
  copyStudentsFromGroup: (groupTo: number, groupFrom: number, params?: { startAt: string }) => Promise<any>;
  getSuperGroups: (params?: SupergroupListParams) => Promise<any>;
  editOriginalCourse: (courseId: number | string, params: any) => Promise<any>; //TODO описать params
  createOriginalCourse: (params: any) => Promise<any>; //TODO описать params
  getOriginalLessonById: (id: number | string) => Promise<any>;
  getOutcomesByOriginalLesson: (id: number | string) => Promise<any>;
  addOutcomesByOriginalLesson: (id: number | string, params: IOutcomesByOriginalLesson) => Promise<any>;
  deleteOutcomesById: (id: number) => Promise<any>;
  deleteSupergroup: (id: number | string) => Promise<any>;
  editOutcomesById: (id: number, params: IOutcomesByOriginalLesson) => Promise<any>;
  addOriginalCourseUnit: (
    id: number | string,
    params: {
      unitName?: string;
      unitId?: number;
    }
  ) => Promise<any>;
  getPlacement: (params: { branch?: number }) => Promise<any>;
  toggleOriginalLessonRevision: (id: number | string, params: { revision: boolean }) => Promise<any>;
}

class CoursesRepository implements ICoursesRepository {
  courseGroupsBulkDeactivate(coursegroups: number[]) {
    return httpService.post('/api/coursegroups/deactivate', { coursegroups });
  }

  superGroupsBulkDeactivate(supergroups: number[]) {
    return httpService.post('/api/supergroups/deactivate', { supergroups });
  }

  /**
   @description Метод создания оргинального курса из excel файла
   Параметры: file - файл, обязательный, дальше на выбор, либо, originalCourse (int) - если грузим
   в конкретный оригинал курс, до достаточно только его id, либо stage(int), subjects(int[]), если создаем новый оригинал курс.
   * @param params
   * @return {Promise<any>}
   */
  uploadOriginalCourseFromFile(params) {
    return httpService.post('/api/v1/originalcourses/load', params, {}, true, true);
  }

  /**
   * @description Список CourseGroups
   * @param {object} params
   * @return {Promise<any>}
   */
  getCourseGroups(params: GetCourseGroupsParams) {
    return httpService.get('/api/v1/coursegroups', params, null, true);
  }

  getCourseGroupById(id: number | string) {
    return httpService.get(`/api/v1/coursegroups/${id}`, null, null, true);
  }

  getTeachersByGroup(groupId: number | string) {
    return httpService.get(`/api/v1/coursegroups/${groupId}/teachers`, null, null, true);
  }

  getLessonsPlanByGroup(groupId: number | string) {
    return httpService.get(`/api/v1/coursegroup/${groupId}/lessons`, null, null, true);
  }

  getSuperGroupListByGroup(groupId: number | string) {
    return httpService.get(`/api/v1/coursegroup/${groupId}/supergroups`, null, null, true);
  }

  getStudentsByGroup(groupId: number, isActive?: boolean) {
    return httpService.get(
      `/api/v1/coursegroups/${groupId}/students`,
      { active: isActive ? 1 : undefined },
      null,
      true
    );
  }

  getGroupsByTeacher(teacherId: number | string) {
    return httpService.get(`/api/v1/coursegroups/teacher/${teacherId}`, null, null, true);
  }

  getOriginalCourseById(courseId: number | string) {
    return httpService.get(`/api/v1/originalcourses/${courseId}`, null, null, true);
  }

  getOriginalCourses(params: OriginalCoursesParams) {
    return httpService.get(`/api/v1/originalcourses`, params, null, true);
  }

  deleteOriginalCourse(courseId: number | string) {
    return httpService.delete(`/api/originalcourses/${courseId}`);
  }

  /**
   * @description Копирует студентов из одной группы в другую
   * @param {number} groupId
   * @param {number} groupFrom
   * @return {Promise<any>}
   */
  copyStudentsFromGroup(groupTo: number, groupFrom: number, params?: { startAt: string }) {
    return httpService.post(`api/v1/coursegroup/${groupTo}/copy-students/${groupFrom}`, params, null, null, true);
  }

  /**
   * @description Получить Supergroups
   * @param {SupergroupListParams} params
   * @return {Promise<any>}
   */
  getSuperGroups(params?: SupergroupListParams) {
    return httpService.get(`/api/v1/supergroups`, params, null, true);
  }

  /**
   * @description Обновление оригинального курса
   * @param {number} courseId
   * @param {object} params
   * @return {Promise<any>}
   */
  editOriginalCourse(courseId: number, params: any) {
    return httpService.put(`/api/v1/originalcourses/${courseId}`, params, null, null, true);
  }

  /**
   * @description Cоздание оригинального курса
   * @param {object} params
   * @return {Promise<any>}
   */
  createOriginalCourse(params: any) {
    return httpService.post(`/api/v1/originalcourses`, params, null, null, true);
  }

  getOriginalLessonById(id: number | string) {
    return httpService.get(`/api/v1/originallesson/${id}`, null, null, true);
  }

  getOutcomesByOriginalLesson(id: number | string) {
    return httpService.get(`/api/v1/originallesson/${id}/outcomes`, null, null, true);
  }

  addOutcomesByOriginalLesson(id: number, params: IOutcomesByOriginalLesson) {
    return httpService.post(`/api/v1/originallesson/${id}/outcomes`, params, null, null, true);
  }

  deleteOutcomesById(id: number) {
    return httpService.delete(`/api/v1/originallesson/outcomes/${id}`, null, null, true);
  }

  editOutcomesById(id: number, params: IOutcomesByOriginalLesson) {
    return httpService.post(`/api/v1/originallesson/outcomes/${id}`, params, null, null, true);
  }

  addOriginalCourseUnit(
    id: number,
    params: {
      unitName?: string;
      unitId?: number;
    }
  ) {
    return httpService.post(`/api/v1/originalcourses/${id}/units`, params, null, null, true);
  }

  deleteSupergroup(id: number | string) {
    return httpService.delete(`/api/v1/supergroups/${id}`, null, null, true);
  }

  getPlacement(params: { branch?: number }) {
    return httpService.get(`/api/v1/placement`, params, null, true);
  }
  toggleOriginalLessonRevision(id: number | string, params: { revision: boolean }) {
    return httpService.post(`/api/v1/originallesson/${id}/revision`, params, null, null, true);
  }
}

export const useCoursesRepository = () => {
  const service = React.useContext(CoursesRepositoryContext);

  if (!service) {
    throw new Error('Courses repository is unavailable');
  }

  return service;
};

export const CourseRepositoryProvider: React.FC<IRepositoryProvider> = ({ children }) => {
  const service = useMemo(() => new CoursesRepository(), []);

  return <CoursesRepositoryContext.Provider value={service}>{children}</CoursesRepositoryContext.Provider>;
};

export const coursesRepository = new CoursesRepository();
