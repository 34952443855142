/**
 * Проверка на локальную разработку
 * @param {string} href
 * @return {boolean}
 **/
export const getIsLocalRef = (href: string): boolean => {
  const paths = ['localhost', '127.0.0.1', '0.0.0.0', 'bat.londongates.org'];
  return paths.some(e => href.includes(e));
};

export const isLocalLaunch = getIsLocalRef(window.location.href);
