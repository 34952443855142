import React from 'react';
import { Modal, Button } from 'antd';

interface ModalDeleteLessonProps {
  visibleModal: boolean;
  loading: boolean;
  setVisibleModal: (bool: boolean) => void;
  deleteOriginalLesson: (originalCourseId: number | string, lessonId: number) => Promise<any>;
  originalCourseId: number | string;
  lessonId: number;
}

/**
 * @description Модалка для удаления юнита
 * @param {ModalDeleteLessonProps} props
 * @return {React.ReactNode}
 */
export const ModalDeleteLesson = (props: ModalDeleteLessonProps) => {
  const { visibleModal, setVisibleModal, loading, deleteOriginalLesson, originalCourseId, lessonId } = props;

  const handleOk = async () => {
    await deleteOriginalLesson(originalCourseId, lessonId);
    setVisibleModal(false);
  };

  const getFooter = (
    <>
      <Button onClick={() => setVisibleModal(false)}>Cancel</Button>
      <Button danger loading={loading} disabled={loading} onClick={handleOk}>
        Delete
      </Button>
    </>
  );
  return (
    (<Modal
      title="Delete lesson"
      open={visibleModal}
      confirmLoading={loading}
      footer={getFooter}
      onCancel={() => setVisibleModal(false)}
    >
      <div>Are you sure delete this lesson?</div>
    </Modal>)
  );
};

export default { ModalDeleteLesson };
