import { createContext } from 'react';
import { Thread } from 'types/ParentJournal';

type ContextProps = {
  threads: Thread[];
  hasLoadThreads: boolean;
  activeThreadId: number;
  setActiveThreadId: (threadId: number) => void;
  getThreads: (withLoading: boolean, threadId: number) => void;
  hasParent: boolean | undefined;
  hasStudent: boolean | undefined;
  hasTeacher: boolean | undefined;
  readThread: (threadId: number) => void;
};

export default createContext<ContextProps>(null);
