import { keyframes } from 'styled-components';

export const slideInLeft = keyframes`
  0% {
    transform: translateX(-10px);
    opacity: 0
  }
  50% {
    transform: translateX(0);
    opacity: 0.5
  }
  100% {
    transform: translateX(0);
    opacity: 1
  }
`;

export const slideInUp = keyframes`
  0% {
    transform: translateY(-10px);
    opacity: 0
  }
  50% {
    transform: translateY(0);
    opacity: 0.5
  }
  100% {
    transform: translateY(0);
    opacity: 1
  }
`;

export const scale = keyframes`
  0% {
    transform: scale(0.99);
    opacity: 0
  }
  
  100% {
    transform: scale(1);
    opacity: 1
  }
`;

export default {
  slideInLeft,
  scale
};
