import React, { useState } from 'react';
import dayjs from 'dayjs';
import { MessageOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Checkbox, Button, Modal } from 'antd';
import { MaterialItem, MaterialItemDownload, MaterialItemLabel } from '../styles';
import { dateTimeFormat } from 'helpers/dates';
import { PreviewFileModal } from 'Global/components/FilePreview';
import { Material as IMaterial, MaterialFile } from 'types/materials';
import { AttachmentName } from './AttachmentName';
import { initialAttachment, AttachmentType } from '../constants';
import { useLanguageContext } from 'contexts/LanguageContext';

interface MaterialProps {
  data: IMaterial;
  disabledCheckbox?: boolean;
  filesList?: number[];
  onChange?: (v: number) => any;
}

/**
 * @description Материал урока для отображения в списке
 * @param {object} props
 * @return {React.ReactNode}
 */
export const Material = (props: MaterialProps) => {
  const [{ confirm }, contextHolder] = Modal.useModal();
  const [strings] = useLanguageContext();
  const [toggle, setToggle] = useState<boolean>(false);
  const [hasShowPreviewModal, setHasShowPreviewModal] = useState<boolean>(false);
  const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer>('');
  const [currentAttachment, setCurrentAttachment] = useState<AttachmentType>(initialAttachment);
  const [hasLoadPreviewAttachment, setHasLoadPreviewAttachment] = useState<boolean>(false);

  const {
    data: { createdBy, materials },
    disabledCheckbox,
    filesList,
    onChange
  } = props;

  const hideModal = () => setHasShowPreviewModal(false);

  /**
   * @description Открытие комментария к уроку
   * @param {string} comment
   * @return {void}
   */
  const handleComment = (comment: string): void => {
    confirm({
      title: strings.COMMENT_FROM_LESSON_BY_MATERIAL,
      content: <div dangerouslySetInnerHTML={{ __html: comment }} />,
      icon: <MessageOutlined />
    });
  };

  return (
    <MaterialItem>
      {contextHolder}
      <MaterialItemLabel onClick={() => setToggle(state => !state)}>
        {toggle ? <PlusOutlined style={{ marginInlineEnd: 8 }} /> : <MinusOutlined style={{ marginInlineEnd: 8 }} />}
        {createdBy.name} {createdBy.surname}
      </MaterialItemLabel>
      <PreviewFileModal
        hasLoadPreviewAttachment={hasLoadPreviewAttachment}
        fileName={currentAttachment.name}
        previewUrl={previewUrl}
        handleHideModal={hideModal}
        hasShowModal={hasShowPreviewModal}
      />
      <div style={{ display: toggle ? 'block' : 'none', marginTop: 8 }}>
        {materials.map((material: MaterialFile) => {
          const { attach, createdAt, hasComment, comment } = material;

          return (
            <MaterialItemDownload key={attach.id}>
              {!disabledCheckbox && (
                <Checkbox
                  onChange={() => onChange && onChange(attach.id)}
                  checked={filesList && filesList.includes(attach.id)}
                />
              )}
              <AttachmentName
                attachment={attach}
                setPreviewUrl={setPreviewUrl}
                currentAttachment={currentAttachment}
                setCurrentAttachment={setCurrentAttachment}
                setHasShowPreviewModal={setHasShowPreviewModal}
                setHasLoadPreviewAttachment={setHasLoadPreviewAttachment}
              />
              <span style={{ marginInlineStart: 6 }}>{dayjs(createdAt).format(dateTimeFormat)}</span>
              {hasComment && (
                <Button
                  icon={<MessageOutlined />}
                  size="small"
                  style={{ marginInlineStart: 6 }}
                  onClick={() => handleComment(comment)}
                />
              )}
            </MaterialItemDownload>
          );
        })}
      </div>
    </MaterialItem>
  );
};

export default { Material };
