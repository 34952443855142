import React, { useState, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Input } from 'antd';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { useLanguageContext } from 'contexts/LanguageContext';

interface PhoneInputProps {
  form: WrappedFormUtils;
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  initValue?: string;
  message?: string;
}

/**
 * @description Компонент поиска по учителям
 * @param {object} props
 * @return {React.ReactNode}
 */
export const PhoneInput = (props: PhoneInputProps) => {
  const {
    message,
    label,
    name,
    required,
    initValue,
    form: { getFieldDecorator, setFieldsValue }
  } = props;

  const [strings] = useLanguageContext();
  const [phoneInput, setPhoneInput] = useState<string>(initValue);

  const onChange = e => {
    const { value } = e.target;
    const text = value.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '');
    const phoneRe = /^\+?([0-9\b]+$|\+$)/;
    if (text.match(phoneRe) || text.length === 0) {
      setPhoneInput(text);
      const phoneObj = {};
      phoneObj[name] = text;
      setFieldsValue(phoneObj);
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (initValue) {
      setPhoneInput(initValue.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', ''));
    }
  }, [initValue]);

  return (
    (<MyFormItem label={label}>
      {getFieldDecorator(name, {
        initialValue: initValue,
        rules: [
          { required: required, message: message ? message : `${label} ${strings.IS_REQUIRED}` },
          { pattern: new RegExp(/^\+?[0-9\b]+$/), message: strings.INVALID_FORMAT }
        ]
      })(<></>)}
      <Input value={phoneInput} onChange={onChange} />
    </MyFormItem>)
  );
};

export default { PhoneInput };
