import React from 'react';
import { Popover } from 'antd';
import { StyledEvent, StyledEventParagraph } from 'Global/modules/Schedule/styles';
import { LessonItemPopover } from 'Global/modules/Schedule/ScheduleLessonItem/LessonItemPopover';
import { ZoomIcon } from 'Global/modules/Schedule/ScheduleLessonItem/ZoomIcon';

/**
 * @description Плашка с уроком у админа
 * @param popoverTitle
 * @param roomMetadata
 * @param hasZoom
 * @param hasEvent
 * @param startTime
 * @param endTime
 * @param bulkLessons
 * @param hasBulkMode
 * @param event
 */
export const AdminScheduleLessonItem = ({
  popoverTitle,
  roomMetadata,
  hasZoom,
  hasEvent,
  startTime,
  endTime,
  bulkLessons,
  hasBulkMode,
  event
}) => {
  const {
    id,
    room: { name: roomName },
    studentsCount,
    participantsCount,
    type
  } = event;

  return (
    <Popover
      title={popoverTitle}
      placement="leftBottom"
      content={
        <LessonItemPopover
          endTime={endTime}
          hasZoom={hasZoom}
          hasEvent={hasEvent}
          startTime={startTime}
          event={event}
          roomMetadata={roomMetadata}
        />
      }
    >
      <StyledEvent hasBorder={bulkLessons.includes(id) && hasBulkMode}>
        <StyledEventParagraph>
          {startTime[0] === '0' ? startTime.replace('0', '') : startTime}-{endTime}{' '}
        </StyledEventParagraph>
        {event.hasOwnProperty('event') && <StyledEventParagraph>{event?.event?.name}</StyledEventParagraph>}
        <StyledEventParagraph>{event?.lesson?.course?.groupName}</StyledEventParagraph>
        <StyledEventParagraph>
          {hasZoom && <ZoomIcon />}
          {roomName} ({type === 'event' ? participantsCount : studentsCount})
        </StyledEventParagraph>
        <StyledEventParagraph>
          {event.lesson?.teacher?.name} {event?.lesson?.teacher?.surname}
        </StyledEventParagraph>
        {event.hasOwnProperty('event') && <StyledEventParagraph>{event?.conductorName}</StyledEventParagraph>}
      </StyledEvent>
    </Popover>
  );
};
