import React, { useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select, Col, Row, Button } from 'antd';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { isEmpty, pickBy } from 'lodash';
import { Branch } from 'types';
import { ROLE_TOP_MANAGER, ROLE_BRANCH_DIRECTOR } from 'Global/roles';
import { hasRole } from 'helpers';
import { useUserContext } from 'contexts/UserContext';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Option } = Select;

interface FilterProps {
  form: WrappedFormUtils;
  onFilter: (filter: { branch: number } | null) => any;
}

/**
 * @description Фильтр доступов к курсам оригиналам
 * @param {FilterProps} props
 * @return {React.ReactNode}
 */
export const FilterTemplate = (props: FilterProps) => {
  const [strings] = useLanguageContext();
  const {
    form: { getFieldDecorator, validateFieldsAndScroll },
    onFilter
  } = props;

  const [user] = useUserContext();
  const { branches } = useGlobalCollectionsContext();

  const hasBranchesRole = hasRole(user, [ROLE_TOP_MANAGER, ROLE_BRANCH_DIRECTOR]);
  const hasBranchDirector = hasRole(user, [ROLE_BRANCH_DIRECTOR]);

  /**
   * @description Form Submit
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values) => {
      if (isEmpty(errors)) {
        const filterParams: any = pickBy(values);
        onFilter(filterParams);
      }
    });
  };

  useEffect(() => {
    onFilter({
      branch: user?.branch?.id
    });
  }, []);

  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      {hasBranchesRole && (
        <Row gutter={[10, 10]}>
          <Col lg={6}>
            <MyFormItem label={strings.BRANCH}>
              {getFieldDecorator('branch', {
                rules: [{ required: true }],
                initialValue: user?.branch?.id
              })(
                <Select placeholder={strings.BRANCH} disabled={hasBranchDirector}>
                  {branches.map((branch: Branch) => (
                    <Option value={branch.id} key={branch.id}>
                      {branch.name}
                    </Option>
                  ))}
                </Select>
              )}
            </MyFormItem>
          </Col>
          <Col lg={4}>
            <MyFormItem>
              <Button type="primary" htmlType="submit" size="large" style={{ marginTop: 48 }}>
                {strings.FILTER}
              </Button>
            </MyFormItem>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export const Filter = Form.create<FilterProps>({})(FilterTemplate);
export default { Filter };
