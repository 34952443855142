import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { Card, Col } from 'antd';
import { dateTimeWithoutSecondFormat } from 'helpers/dates';
import dayjs from 'dayjs';
import { Call as ICall } from 'types/Family/collaborationHistory';
import { ListItem } from 'Admin/People/Families/Family/CollaborationHistory/Types/styles';

interface CallProps {
  data: ICall;
}

/**
 * @description Chronicle type call
 * @param props - component props
 * @return {React.ReactNode}
 */
export const Call = (props: CallProps) => {
  const {
    data: { date, title, link, parent }
  } = props;
  const formatDate = dayjs(date).format(dateTimeWithoutSecondFormat);
  return (
    <Col lg={24}>
      <Card title={title || 'Call'} extra={formatDate}>
        {link && <ReactAudioPlayer src={link} controls />}
        {parent && (
          <ListItem>
            <span>Parent:</span>
            <p>
              {parent?.name} {parent?.surname}
            </p>
          </ListItem>
        )}
      </Card>
    </Col>
  );
};

export default { Call };
