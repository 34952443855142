import React, { useState } from 'react';
import { DeleteOutlined, DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { Modal, Upload, Button, Progress, message, Popconfirm, Spin, Flex } from 'antd';
import { useAttachment } from 'hooks/useAttachment';
import { downloadSomeFile, getFormData } from 'helpers';
import { Global } from 'api';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Register } from 'types/TeacherAccount/Courses';
import { UploadChangeParam } from 'antd/es/upload';
import { RcFile } from 'antd/lib/upload';
import { useLessonRegisterStore } from 'stores/useLessonRegisterStore';

const { Dragger } = Upload;

/**
 * @description Модальное окно загрузки домашнего задания
 * @param {boolean} hasShowModal
 * @param {array} fileList
 * @return {React.ReactNode}
 */
export const UploadHomeworkStudentModal = ({
  hasShowModal,
  onCancel,
  register
}: {
  hasShowModal: boolean;
  onCancel: () => void;
  register: Register;
}) => {
  const { uploadAttachment, uploadPercent } = useAttachment(`/api/v2/lessonregister/${register.id}/homework`);
  const [hasDownloading, setHasDownloading] = useState<boolean>(false);
  const [hasUploading, setHasUploading] = useState<boolean>(false);
  const { checkedHwList } = register;
  const [strings] = useLanguageContext();
  const setHasRegistersFetch = useLessonRegisterStore(state => state.setHasRegistersFetch);
  const hasRegistersLoading = useLessonRegisterStore(state => state.hasRegistersLoading);

  const handleUploadFiles = async (files: RcFile[]) => {
    setHasUploading(true);
    try {
      await uploadAttachment(getFormData({ files }));
      setHasRegistersFetch(true);
    } catch {
      message.error(strings.HOMEWORK_UPLOAD_FAILED, 2);
    } finally {
      setHasUploading(false);
    }
    return false;
  };

  const handleChange = ({ file, fileList }: UploadChangeParam) => {
    if (file.uid === fileList[fileList.length - 1].uid) {
      const newFiles = fileList.map(f => f.originFileObj);
      handleUploadFiles(newFiles);
    }
  };

  const handleDelete = async hwId => {
    setHasUploading(true);
    try {
      await Global.deleteAttachment(hwId);
      message.success(strings.SUCCESSFULLY_DELETED);
      setHasRegistersFetch(true);
    } catch (error) {
      message.error(strings.FILE_DELETION_ERROR);
    } finally {
      setHasUploading(false);
    }
  };

  const downloadAllHomework = async () => {
    try {
      setHasDownloading(true);
      await downloadSomeFile(`/api/v2/lessonregister/${register.id}/homework/checked`);
      setHasDownloading(false);
    } catch {
      message.error(strings.HW_DOWNLOAD_ERROR);
      setHasDownloading(false);
    }
  };

  const downloadHomework = async (fileId: number, fileName: string): Promise<any> => {
    try {
      await downloadSomeFile(`/api/v1/attachment/download/${fileId}`, fileName, null, null, true);
    } catch {
      message.error(strings.HW_DOWNLOAD_ERROR);
    }
  };

  return (
    <Modal
      title={strings.CHECKED_HOMEWORK}
      width={530}
      open={hasShowModal}
      onCancel={onCancel}
      footer={[
        <>
          {checkedHwList?.isNotEmpty() && (
            <Button
              loading={hasDownloading}
              style={{ marginBottom: 20 }}
              type="primary"
              icon={<DownloadOutlined />}
              onClick={() => downloadAllHomework()}
            >
              {strings.DOWNLOAD_ALL}
            </Button>
          )}
        </>,
        <Button onClick={onCancel}>{strings.OK}</Button>
      ]}
    >
      <Spin spinning={hasUploading || hasRegistersLoading} tip={strings.LOADING}>
        <Flex justify="start" align="center" vertical gap={10} style={{ marginBottom: 10 }}>
          {checkedHwList.map(homework => (
            <Flex justify="start" align="center" gap={10} style={{ width: '100%' }}>
              <Popconfirm
                title={strings.ARE_YOU_SURE_DELETE_THIS_MATERIAL}
                onConfirm={() => handleDelete(homework.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button size="small" icon={<DeleteOutlined />} danger />
              </Popconfirm>
              <span
                style={{ cursor: 'pointer', fontSize: 12 }}
                onClick={() => downloadHomework(homework.id, homework.name)}
              >
                {homework?.name}
              </span>
            </Flex>
          ))}
        </Flex>
      </Spin>
      <Dragger
        multiple={true}
        beforeUpload={() => false}
        fileList={[]}
        disabled={hasUploading}
        onChange={handleChange}
        showUploadList={false}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text" style={{ padding: '0 25px' }}>
          {strings.DRAG_CHECKED_HW}
        </p>
      </Dragger>
      {uploadPercent !== 0 && uploadPercent !== 100 && <Progress percent={uploadPercent} />}
    </Modal>
  );
};
