import React, { useState } from 'react';
import { DoubleRightOutlined, DownloadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Button, Row, DatePicker, Modal, message, Col } from 'antd';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { convertBooleanToNumber, downloadSomeFile } from 'helpers';
import { dateFormat, firstDayOfPrevMonth, lastDayOfPrevMonth } from 'helpers/dates';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { generateInvoiceParams } from 'Admin/People/Families/Family/Invoices/types';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useInvoicesRepository } from 'repos/InvoicesRepository';

const { RangePicker } = DatePicker;

interface ExtraCreateInvoiceProps {
  form: WrappedFormUtils;
  familyId: number; //familyId
}

/**
 * @description Форма для создания доп инвойсов
 * @param props - component props
 * @return {React.ReactNode}
 */
const ExtraCreateInvoiceTemplate = (props: ExtraCreateInvoiceProps) => {
  const {
    form: { getFieldDecorator, validateFieldsAndScroll },
    familyId
  } = props;
  const [strings] = useLanguageContext();
  const invoicesRepository = useInvoicesRepository();
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const [hasGenerateInvoiceLoaded, setHasGenerateInvoiceLoaded] = useState<boolean>(false);
  const [{ confirm }, contextHolder] = Modal.useModal();

  /**
   * @description Download/send invoice
   * @param {any} param
   * @return {void}
   */
  const handleGenerateInvoice = (param: { hasSend: boolean }): void => {
    validateFieldsAndScroll(async (errors, values) => {
      const { date } = values;
      if (isEmpty(errors)) {
        values['dateStart'] = dayjs(date[0]).format(dateFormat);
        values['dateEnd'] = dayjs(date[1]).format(dateFormat);
        delete values.date;
        if (param?.hasSend) {
          values['send'] = true;
          confirm({
            title: strings.ARE_YOU_SURE_YOU_WANT_TO_SEND_THE_INVOICE_TO_EMAIL,
            onOk: async () => {
              try {
                const { status } = await sendInvoice(values);
                if (status === 200) {
                  message.success(strings.THE_INVOICE_HAS_BEEN_SENT, 2);
                }
              } catch {
                message.error(strings.SORRY_ERROR);
              }
            },
            onCancel() {}
          });
        } else {
          setHasGenerateInvoiceLoaded(true);
          try {
            await downloadInvoice(values);
          } finally {
            setHasGenerateInvoiceLoaded(false);
          }
        }
      }
    });
  };

  /**
   * @description generate and download invoice by type
   * @param {generateInvoiceParams} params
   * @return {Promise<any>}
   */
  const downloadInvoice = async (values: generateInvoiceParams): Promise<any> => {
    const params = convertBooleanToNumber(values);
    return await downloadSomeFile(
      `/api/v1/invoices/family/${familyId}/generate/books-invoice`,
      null,
      params,
      null,
      true
    );
  };
  /**
   * @description generate and sent invoice by type
   * @param {generateInvoiceParams} params
   * @return {Promise<any>}
   */
  const sendInvoice = async (params: generateInvoiceParams): Promise<any> => {
    return await invoicesRepository.generateBooksInvoice(familyId, params);
  };

  return (
    <>
      <Button onClick={() => setHasShowModal(true)}>{strings.CREATE_BOOKS_INVOICE}</Button>
      <Modal
        title={strings.CREATE_BOOKS_INVOICE}
        open={hasShowModal}
        onCancel={() => setHasShowModal(false)}
        footer={false}
      >
        {contextHolder}
        <Row>
          <MyFormItem label={strings.PERIOD}>
            {getFieldDecorator('date', {
              rules: [{ required: true, message: strings.PLEASE_PICK_START_AND_END_DATE }],
              initialValue: [firstDayOfPrevMonth, lastDayOfPrevMonth]
            })(<RangePicker format={dateFormat} />)}
          </MyFormItem>
        </Row>
        <Row justify="end" style={{ gap: 10 }}>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            loading={hasGenerateInvoiceLoaded}
            style={{ margin: 0 }}
            onClick={() => handleGenerateInvoice({ hasSend: false })}
          >
            {strings.DOWNLOAD}
          </Button>
          <Button
            type="default"
            icon={<DoubleRightOutlined />}
            style={{ margin: 0 }}
            onClick={() => handleGenerateInvoice({ hasSend: true })}
          >
            {strings.SEND}
          </Button>
        </Row>
      </Modal>
    </>
  );
};

export const ExtraCreateInvoice = Form.create<ExtraCreateInvoiceProps>({})(ExtraCreateInvoiceTemplate);

export default { ExtraCreateInvoice };
