import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select, Input, DatePicker, Row, Col, Tag, message, Spin, Tooltip } from 'antd';
import { FilterMaterials } from 'Admin/Education/styles';
import { dateFormat } from 'helpers/dates';
import { Teachers } from 'api';
import { GroupSelect } from 'Global/components/FormComponentsCompatible/Selects/GroupSelect';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { Branch, Subject } from 'types';
import { useUserContext } from 'contexts/UserContext';
import { useTeacherRepository } from 'repos/TeacherRepository';
import { useAdminRepository } from 'repos/AdminRepository';
import { useLanguageContext } from 'contexts/LanguageContext';
import { StageSelect } from 'Global/components/FormComponentsCompatible/Selects/StageSelect';
import debounce from 'lodash/debounce';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { FilterButtons } from 'Global/components/FilterButtons';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { ITeacherGetParams } from '../../../types/Teachers/teacher';

const { Option } = Select;

interface FilterProps {
  form: WrappedFormUtils;
  onFilter: (params: object) => Promise<any>;
  branches: Branch[];
}
interface IPeopleUploadedBy {
  type: string;
  id: number;
  name: string;
  surname: string;
}

/**
 * @description Компонент фильтра материалов
 * @param {object} props
 * @return {React.ReactNode}
 */
export const MaterialsFilterTemplate = ({
  form,
  form: { getFieldDecorator, resetFields, getFieldValue, validateFields },
  onFilter,
  branches
}: FilterProps) => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const hasTeacher = user?.hasRoles?.teacher;
  const [uploadedByList, setUploadedByList] = useState<IPeopleUploadedBy[]>([]);
  const [hasLoadUsers, setHasLoadUsers] = useState<boolean>(false);
  const [teacherSubjects, setTeacherSubjects] = useState<Subject[]>([]);
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [typing, setTyping] = useState<Boolean>(false);

  const { subjects } = useGlobalCollectionsContext();
  const teacherRepository = useTeacherRepository();
  const adminRepository = useAdminRepository();

  const { locationSearchParams } = useLocationSearchParams();

  const { lessonTopic, fileName, type, branch, subject, stage, group, startAt, endAt } = locationSearchParams || {};

  /**
   * @description Функция для получения subjects
   * @return {Promise<any>}
   */
  const getTeachersSubjects = async (): Promise<any> => {
    try {
      const {
        data: { mainSubjects, additionalSubjects }
      } = await Teachers.getSubjectsByTeacherId(user?.meTeacherId);
      setTeacherSubjects([...mainSubjects, ...additionalSubjects]);
    } catch (e) {
      message.error(e.message);
    }
  };

  /**
   * @description Поиск по админам и учителям
   * @param {string} userName
   * @return {Promise<any>}
   */
  const fetchUsers = async (userName: string): Promise<any> => {
    setSearchValue(userName);
    if (userName) {
      setHasLoadUsers(true);
      const branch = getFieldValue('branch');
      const params: ITeacherGetParams = { query: userName, limit: 1000, isActive: 1 };
      if (branch) {
        params['branch'] = branch;
      }
      const {
        data: { items }
      } = await teacherRepository.getTeachers(params);
      const {
        data: { admins }
      } = await adminRepository.getAllAdmins(params);
      const teacherList = items?.map(item => {
        return {
          id: item?.user?.id,
          name: item?.user?.name,
          surname: item?.user?.surname,
          type: 'Teacher'
        };
      });
      const adminList = admins?.map(item => {
        return {
          id: item?.user?.id,
          name: item?.user?.name,
          surname: item?.user?.surname,
          type: 'Admin'
        };
      });
      try {
        await Promise.all([teacherList, adminList]);
        const sortUploadedByList = [...teacherList, ...adminList].sort((a, b) =>
          `${a.surname} ${a.name}`.localeCompare(`${b.surname} ${b.name}`)
        );
        setUploadedByList(sortUploadedByList);
      } finally {
        setHasLoadUsers(false);
      }
    }
  };

  // Очистка фильтра
  const resetFilter = async (): Promise<any> => {
    resetFields();
    await onFilter({});
  };

  /**
   * @description Submit формы поиска
   * @param {React.FormEvent} e
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();

    validateFields(async (err, values) => {
      if (!err) {
        try {
          setHasSubmitLoading(true);
          await onFilter({ ...values, page: 1 });
        } finally {
          setHasSubmitLoading(false);
        }
      }
    });
  };
  useEffect(() => {
    if (hasTeacher) {
      getTeachersSubjects();
    }
  }, [user]);

  useEffect(() => {
    if (searchValue) {
      setTyping(true);
    } else {
      setTyping(false);
    }
  }, [searchValue]);

  return (
    <FilterMaterials>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Row gutter={[10, 10]}>
          <Col lg={4}>
            {getFieldDecorator('startAt', {
              initialValue: startAt && dayjs(startAt)
            })(<DatePicker format={dateFormat} placeholder={strings.FROM} />)}
          </Col>
          <Col lg={4}>
            {getFieldDecorator('endAt', {
              initialValue: endAt && dayjs(endAt)
            })(<DatePicker format={dateFormat} placeholder={strings.TILL} />)}
          </Col>
          <Col lg={4}>
            <StageSelect initialValue={stage} form={form} />
          </Col>
          <Col lg={6}>
            {getFieldDecorator('branch', {
              initialValue: branch
            })(
              <Select placeholder={strings.BRANCH}>
                {branches.map((branch: Branch) => (
                  <Option value={branch.id} key={branch.id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            )}
          </Col>
          <Col lg={6}>
            {getFieldDecorator('subject', {
              rules: [{ required: false }],
              initialValue: subject && Number(subject)
            })(
              <Select placeholder={strings.SUBJECT} allowClear>
                {hasTeacher &&
                  teacherSubjects.map((subject: Subject) => (
                    <Option value={subject?.id} key={subject?.id}>
                      {subject?.name}
                    </Option>
                  ))}
                {!hasTeacher &&
                  subjects.map((subject: Subject) => (
                    <Option value={subject?.id} key={subject?.id}>
                      {subject?.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Col>
          <Col lg={6}>
            {getFieldDecorator('lessonTopic', {
              initialValue: lessonTopic
            })(<Input placeholder={strings.LESSON_TOPIC} />)}
          </Col>
          <Col lg={6}>
            {getFieldDecorator('fileName', {
              initialValue: fileName
            })(<Input placeholder={strings.FILE_NAME} />)}
          </Col>
          <Col lg={6}>
            {getFieldDecorator('type', {
              rules: [{ required: false }],
              initialValue: type
            })(
              <Select placeholder={strings.TYPE}>
                <Option value="">&nbsp;</Option>
                <Option value="lesson_material" key={0}>
                  {strings.CM_CLASS_MATERIALS}
                </Option>
                <Option value="lesson_homework" key={1}>
                  {strings.HM_HOMEWORK}
                </Option>
                <Option value="lesson_test" key={2}>
                  {strings.T_TESTS}
                </Option>
                <Option value="lesson_bonus" key={3}>
                  {strings.AM_ADDITIONAL_MATERIALS}
                </Option>
                <Option value="lesson_hidden" key={4}>
                  {strings.HM_HIDDEN_MATERIALS}
                </Option>
              </Select>
            )}
          </Col>
          <Col lg={6}>
            <GroupSelect getFieldDecorator={getFieldDecorator} name="group" initGroup={group} />
          </Col>
          <Col lg={6}>
            {getFieldDecorator('uploadedBy')(
              <Select
                allowClear
                notFoundContent={
                  hasLoadUsers ? <Spin size="small" /> : <p>{!typing ? strings.START_TYPING : strings.NOT_FOUND}</p>
                }
                placeholder={strings.UPLOADED_BY}
                onSearch={debounce(fetchUsers, 800)}
                showSearch
                filterOption={false}
              >
                {uploadedByList.map((item: IPeopleUploadedBy) => (
                  <Option key={item.id} value={item?.id}>
                    <Tooltip
                      title={`${item?.surname} ${item?.name} - ${item?.type}`}
                      placement="top"
                      overlayStyle={{ zIndex: 9999 }}
                    >
                      {item?.surname} {item?.name} <Tag>{item?.type}</Tag>
                    </Tooltip>
                  </Option>
                ))}
              </Select>
            )}
          </Col>
        </Row>
        <FilterButtons handleClear={resetFilter} hasSubmitLoading={hasSubmitLoading} />
      </Form>
    </FilterMaterials>
  );
};

export const MaterialsFilter = Form.create<FilterProps>({})(MaterialsFilterTemplate);

export default { MaterialsFilter };
