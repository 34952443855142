import React, { useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Upload, Modal, Select, Button, message, Typography, Empty, Spin } from 'antd';
import { Homework } from 'api/Homework';
import { getFormData } from 'helpers';
import { UploadFile } from 'antd/lib/upload/interface';
import { useLanguageContext } from 'contexts/LanguageContext';
import { ILessonRegister } from 'types/lesson';
import { useLessonRegisterStore } from 'stores/useLessonRegisterStore';

const { Dragger } = Upload;
const { Option } = Select;
const { Text } = Typography;

interface UploadDrawerProps {
  hasShow: boolean;
  setHasShowUploadHomework: (hasShow: boolean) => void;
}
/**
 * @description Форма загрузки домашки учителем
 * @param {object} props
 * @return {React.ReactNode}
 */
export const UploadHomeworks = ({ setHasShowUploadHomework, hasShow }: UploadDrawerProps) => {
  const registers = useLessonRegisterStore(state => state.registers);
  const setHasRegistersFetch = useLessonRegisterStore(state => state.setHasRegistersFetch);
  const setHasRegistersUpdate = useLessonRegisterStore(state => state.setHasRegistersUpdate);

  const [checkedHwFileList, setCheckedHwFileList] = useState<UploadFile[]>([]);
  const [redistersIdsSelectedStudents, setRedistersIdsSelectedStudents] = useState<number[]>([]);
  const [hasAttachLoading, setHasAttachLoading] = useState<boolean>(false);
  const [strings] = useLanguageContext();

  const onChangeHomeworkUploaded = info => {
    const { fileList } = info;
    const files = fileList.map(file => file.originFileObj);
    setCheckedHwFileList(files);
  };

  const draggerProps = {
    name: 'file',
    multiple: true,
    beforeUpload: () => false,
    onChange: onChangeHomeworkUploaded,
    fileList: checkedHwFileList
  };

  /**
   * @var {array} students - список студентов с указанием register id
   */
  const students = registers
    .map((register: ILessonRegister) => ({
      registerId: register.id,
      ...register.student,
      homeworkStatus: register.homeworkStatus
    }))
    .filter(student => student.homeworkStatus !== 0);

  const hasShowUpload = students.isNotEmpty();

  /**
   * @description Добавить файлы проверенной домашки выбранным студентам
   * @return {Promise<any>}
   */
  const addCheckedHWFilesToSomeStudents = async (): Promise<any> => {
    if (redistersIdsSelectedStudents?.isEmpty()) {
      return message.error(strings.PLEASE_SELECT_AT_LEAST_ONE_STUDENT);
    }
    setHasAttachLoading(true);
    setHasRegistersUpdate(true);
    try {
      await Promise.all([
        redistersIdsSelectedStudents.map(registerId => {
          return Homework.addHomeWorkFiles(registerId, getFormData({ files: checkedHwFileList }));
        })
      ]);
      setTimeout(() => {
        setHasAttachLoading(false);
        resetModal();
        setHasRegistersFetch(true);
        message.success(strings.SUCCESSFULLY_UPLOADED, 2);
      }, 1500);
    } catch {
      setHasAttachLoading(false);
      resetModal();
      message.error(strings.UPLOAD_FAILED, 2);
    }
  };

  const resetModal = () => {
    setCheckedHwFileList([]);
    setRedistersIdsSelectedStudents([]);
    setHasShowUploadHomework(false);
  };

  return (
    <Modal
      title={strings.UPLOAD_HOMEWORK}
      onCancel={() => resetModal()}
      open={hasShow}
      destroyOnClose={true}
      width={600}
      footer={
        hasShowUpload && [
          <Button key="cancel" onClick={() => resetModal()}>
            {strings.CANCEL}
          </Button>,
          <Button key="save" type="primary" onClick={addCheckedHWFilesToSomeStudents}>
            {strings.SAVE}
          </Button>
        ]
      }
    >
      <Spin spinning={hasAttachLoading} tip="Loading...">
        {!hasShowUpload && <Empty description={<Text>{strings.NO_STUDENT_HOMEWORK}</Text>} />}
        {hasShowUpload && (
          <>
            <Dragger {...draggerProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text" style={{ padding: '0 25px' }}>
                {strings.DRAG_CHECKED_HW}
              </p>
            </Dragger>
            <Select
              mode="multiple"
              placeholder={strings.CHOOSE_STUDENT}
              onChange={(value: number[]) => {
                setRedistersIdsSelectedStudents(value);
              }}
              value={redistersIdsSelectedStudents}
              style={{ marginTop: 20 }}
            >
              {students.map(student => (
                <Option value={student.registerId} key={student.id}>
                  {student?.name} {student?.surname}
                </Option>
              ))}
            </Select>
          </>
        )}
      </Spin>
    </Modal>
  );
};
