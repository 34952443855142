import React from 'react';
import { Row, Col, InputNumber, Checkbox, message, Button } from 'antd';
import { Teachers } from 'api';
import { AxiosResponse } from 'axios';
import { useLanguageContext } from 'contexts/LanguageContext';

interface AddCoefficientProps {
  teacherId: number | string;
  getIndividualWagesList: () => Promise<any>;
  changeCoefficient: (coefficient: number) => any;
  coefficient: number;
}
/**
 * @description Компонент добавления коэффицента
 * @return {React.ReactNode}
 */
export const AddCoefficient = (props: AddCoefficientProps) => {
  const { teacherId, getIndividualWagesList, changeCoefficient, coefficient} = props;
  const [strings] = useLanguageContext();
  /**
   * @description Установка коэффицента
   * @return {Promise<any>}
   */
  const setIndividualCoefficient = async (): Promise<any> => {
    try {
      await Teachers.setIndividualCoefficient(teacherId, {
        coefficient,
        enabled: 1
      });
      message.success(strings.INDIVIDUAL_COEFFICIENT_SET_SUCCESSFULLY, 2);
      await getIndividualWagesList();
    } catch {}
  };

  /**
   * @description Обработчик на ввод коэффицента
   * @param {number} value
   * @return {void}
   */
  const handleNumberChange = (value: number) => changeCoefficient(value);

  return (
    <Row gutter={[10, 10]}>
      <Col lg={6}>
        <InputNumber precision={2} onChange={handleNumberChange} placeholder={strings.COEFFICIENT} />
      </Col>
      <Col lg={12}>
        <Button type="primary" size="large" onClick={setIndividualCoefficient}>
          {strings.SET_INDIVIDUAL_COEFFICIENT}
        </Button>
      </Col>
    </Row>
  );
};

export default { AddCoefficient };
