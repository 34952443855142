import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { DatePicker, Row, Col, Button, message, Select, Result } from 'antd';
import { Loader } from 'Global/components/Loader/Loader';
import { dateFormatForBackend } from 'helpers/dates';
import { ErrorHandler } from 'helpers/services/ErrorHandler';
import { Dayjs } from 'dayjs';
import { ModalMode, Status } from 'types';
import { TeacherHistorySms } from 'types/Teachers/History';
import { AddHistory } from 'Admin/People/Teachers/Teacher/CollaborationHistory/Add';
import { Sms } from 'Admin/People/Teachers/Teacher/CollaborationHistory/Sms';
import { Note } from 'Admin/People/Teachers/Teacher/CollaborationHistory/Note';
import { LessonAssessment } from 'Admin/People/Teachers/Teacher/CollaborationHistory/LessonAssessment';
import { StatusChanged } from 'Admin/People/Teachers/Teacher/CollaborationHistory/StatusChanged';
import { Link } from 'react-router-dom';
import { BASE_TEACHERS } from 'Admin/People/Teachers/routes';
import { Forbidden } from 'Global/modules/Errors/403';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { useTeacherRepository } from 'repos/TeacherRepository';
import { useLanguageContext } from 'contexts/LanguageContext';
import { StCard, StPageHeader } from 'Global/GlobalStyle';

const { Option } = Select;
const { RangePicker } = DatePicker;

interface CollaborationHistoryProps {
  id: number | string; // Teacher id
  status: Status; // Teacher status
}

/**
 * @description Компонент Teacher Collaboration History
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const CollaborationHistory = (props: CollaborationHistoryProps) => {
  const [strings] = useLanguageContext();
  const teacherRepository = useTeacherRepository();
  const [teacherHistory, setTeacherHistory] = useState<TeacherHistorySms[]>([]);
  const [teacherFilterData, setTeacherFilterData] = useState<TeacherHistorySms[]>([]);
  const [hasLoadTeacherHistory, setHasLoadTeacherHistory] = useState<boolean>(false);
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Hidden);
  const [hasForbidden, setHasForbidden] = useState<boolean>(false);
  const [currentType, setCurrentType] = useState('text');
  const [currentDates, setCurrentDates] = useState<string[]>([]);

  const { id, status } = props;

  const handleFilterChange = (type: string) => {
    setCurrentType(type);
    setTeacherFilterData(filterHistory(type, teacherHistory));
  };

  const filterEmptyRegister = (arr: TeacherHistorySms[]) => {
    return arr.filter((item: TeacherHistorySms) => !item.typeEmptyRegister);
  };

  /**
   * @description Открытие модального окна добавления history
   * @return {void}
   */
  const handleShowModal = (): void => {
    setHasShowModal(true);
    setModalMode(ModalMode.Add);
  };

  /**
   * @description Закрытие модального окна добавления history
   * @return {void}
   */
  const handleHideModal = (): void => setHasShowModal(false);

  const date = new Date();
  const start = date.setDate(date.getDate() - 360);

  /**
   * @description Получение истории учителя согласно id и даты начала и даты конца
   * @link http://wombat.londongates.org/teacher/10357#contact_
   * @param {string} dateStart
   * @param {string} dateEnd
   * @return {Promise<any>}
   */
  const getTeacherCollaborationHistory = async (dateStart?: string, dateEnd?: string): Promise<any> => {
    const params = {
      start: dateStart || dayjs(start).format(dateFormatForBackend),
      end: dateEnd || dayjs().format(dateFormatForBackend)
    };
    setHasLoadTeacherHistory(true);
    try {
      const { data, status } = await teacherRepository.getCollaborationHistory(id, params);
      if (status === 200) {
        setHasLoadTeacherHistory(false);
        const filterData = filterEmptyRegister(data);
        setTeacherHistory(filterData);
        setTeacherFilterData(filterHistory(currentType, filterData));
      }
    } catch (err) {
      if (ErrorHandler.checkStatusCode(err, 403)) {
        setHasForbidden(true);
      }
      message.error(strings.SORRY_GET_COLLABORATION_HISTORY_ERROR);
    }
  };

  /**
   * @description Обработчик на выбор новых дат для фильтра
   * @param {Dayjs[]} dates
   * @return {Promise<any>}
   */
  const handleDate = async (dates): Promise<any> => {
    const formattedDates = dates.map((date: Dayjs) => date.format(dateFormatForBackend));
    setCurrentDates(formattedDates);
    await getTeacherCollaborationHistory(...formattedDates);
  };

  const onFilter = async (): Promise<any> => {
    await getTeacherCollaborationHistory(...currentDates);
  };

  const filterHistory = (type: string, data: TeacherHistorySms[]) => {
    return data.filter((item: TeacherHistorySms) => {
      if (type === 'text') {
        return item?.type === type || item.type === 'statusChanged';
      } else {
        return type === 'all' || type === item.type;
      }
    });
  };

  useEffect(() => {
    if (id) {
      getTeacherCollaborationHistory();
    }
  }, [id]);

  if (hasForbidden) {
    return (
      <Forbidden subTitle={strings.SORRY_YOU_DO_NOT_HAVE_ACCESS_TO_THE_HISTORY_OF_COOPERATION_OF_THIS_TEACHER}>
        <Button size="large" type="primary" icon={<ArrowLeftOutlined />}>
          <Link to={BASE_TEACHERS} style={{ color: '#fff' }}>
            {strings.BACK_TO_TEACHER_LIST}
          </Link>
        </Button>
      </Forbidden>
    );
  }

  return (
    <ErrorBoundary>
      <Loader spinning={hasLoadTeacherHistory}>
        <AddHistory
          hasShow={hasShowModal}
          reloadHistory={getTeacherCollaborationHistory}
          hideModal={handleHideModal}
          mode={modalMode}
          id={id}
          status={status}
        />
        <StCard>
          <StPageHeader
            title={strings.COLLABORATION_HISTORY}
            extra={[
              <Button type="primary" size="middle" onClick={handleShowModal}>
                {strings.ADD_HISTORY_ITEM}
              </Button>
            ]}
          >
            <Form layout="vertical">
              <Row align="middle" gutter={[10, 10]}>
                <Col lg={10}>
                  <MyFormItem label={strings.FILTER_BY_TYPE}>
                    <Select onChange={handleFilterChange} defaultValue="all">
                      <Option value="all">{strings.ALL}</Option>
                      <Option value="sms">{strings.SMS}</Option>
                      <Option value="lessonAssessment">{strings.LESSON_ASSESSMENT}</Option>
                      <Option value="text">
                        {strings.NOTE} {strings.AND} {strings.STATUS_CHANGED}
                      </Option>
                    </Select>
                  </MyFormItem>
                </Col>
                <Col lg={10}>
                  <MyFormItem label={strings.DATES}>
                    <RangePicker
                      defaultValue={[dayjs(start), dayjs(new Date())]}
                      format={dateFormatForBackend}
                      onChange={handleDate}
                    />
                  </MyFormItem>
                </Col>
                <Col lg={4}>
                  <Button size="large" onClick={onFilter} type="primary" style={{ marginTop: 15 }}>
                    {strings.FILTER}
                  </Button>
                </Col>
              </Row>
            </Form>
            <Row>
              {teacherFilterData.length === 0 && <Result title="No entries" />}
              {teacherFilterData.map((item: any) => {
                if (item.type === 'text') {
                  return (
                    <Col lg={24} style={{ margin: 0 }} key={item.id}>
                      <Note {...item} />
                    </Col>
                  );
                }
                if (item.type === 'sms') {
                  return (
                    <Col lg={24} style={{ margin: 0 }} key={item.id}>
                      <Sms {...item} />
                    </Col>
                  );
                }

                if (item.type === 'lessonAssessment') {
                  return (
                    <Col lg={24} style={{ margin: 0 }} key={item.id}>
                      <LessonAssessment lessonAssessment={item} admin={item.admin} />
                    </Col>
                  );
                }

                if (item.type === 'statusChanged') {
                  return (
                    <Col lg={24} style={{ margin: 0 }} key={item.id}>
                      <StatusChanged {...item} teacherId={id} reloadHistory={getTeacherCollaborationHistory} />
                    </Col>
                  );
                }
              })}
            </Row>
          </StPageHeader>
        </StCard>
      </Loader>
    </ErrorBoundary>
  );
};

export default { CollaborationHistory };
