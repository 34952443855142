import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { useLastLocation } from 'react-router-last-location';
import { useHistory } from 'react-router-dom';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { dateFormatForBackend, today } from 'helpers/dates';
import { Family } from '../../../types';
import { useFamilyContext } from '../../../contexts/FamilyContext';

const StGoogleCalendar = styled.div`
  margin: 40px;
  iframe {
    width: 100%;
    height: 100vh;
    background: transparent;
    border: 1px solid #ccc;
    margin-top: 30px;
  }
`;

const { Title } = Typography;

/**
 * @description Компонент с выводом  google календаря
 * @link https://app.clickup.com/t/7prdwq
 * @return {React.ReactNode}
 */
export const GoogleCalendar = () => {
  const lastLocation = useLastLocation();
  const { goBack } = useHistory();
  const [strings] = useLanguageContext();
  const date = today.format(dateFormatForBackend);
  const teachersIframeUrl = `https://airtable.com/embed/shr3KfTGLiUAY21p9?backgroundColor=yellowLight&viewControls=on&date=${date}`;

  return (
    <StGoogleCalendar>
      <Helmet>
        <title>{strings.EVENT_HOLIDAYS}</title>
      </Helmet>
      <Title level={3}>
        {lastLocation && <ArrowLeftOutlined onClick={goBack} style={{ marginInlineEnd: 8 }} />}
        {strings.EVENT_HOLIDAYS}
      </Title>
      <iframe src={teachersIframeUrl} style={{ border: 0 }} frameBorder="0" scrolling="no" />
    </StGoogleCalendar>
  );
};

export default { GoogleCalendar };
