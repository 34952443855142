import React, { useState, useEffect } from 'react';
import { Layout, Spin, Tabs, Button } from 'antd';
import { OriginalCourses } from '../../styles';
import Breadcrumbs from 'Global/components/Breadcrumbs';
import { Courses } from 'api/Courses';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { AboutCourse } from './AboutCourse';
import { Information } from './Information';
import { AddUnitModal } from './AddUnit';
import { ModalEditUnit } from './ModalEditUnit';
import { Access } from './Access';
import { Groups } from './Groups';
import { Unit } from 'types/TeacherAccount/Courses';
import { OriginalCourse, UnitName } from 'types/courses';
import { Inventory } from 'types';
import { useUserContext } from 'contexts/UserContext';
import { CreateFromExcelButton } from 'Admin/Courses/OriginalCourses/CreateFromExcelButton';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { LessonPlan } from './LessonPlan';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { MetaLevelInfo } from './MetaGoals/MetaLevelInfo';
import { useLanguageContext } from 'contexts/LanguageContext';
import { match } from 'react-router';

const { Content } = Layout;
const { TabPane } = Tabs;

/**
 * @description Original course, общий компонент для админов и учителей. Вынести в Global
 * @param { OriginalCourseProps } props
 * @returns {React.ReactNode}
 */
export const OriginalCourseFull = () => {
  const [strings] = useLanguageContext();
  const [course, setCourse] = useState<OriginalCourse>();
  const [units, setUnits] = useState<Unit[]>([]);
  const [unitNames, setUnitNames] = useState<UnitName[]>([]);
  const [listInventories, setListInventories] = useState<Inventory[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [addUnitVisible, setAddUnitVisible] = useState<boolean>(false);
  const [hasLoadingInventories, setHasLoadingInventories] = useState<boolean>(false);
  const [modalEditnameVisible, setModalEditnameVisible] = useState<boolean>(false);
  const [selectedUnitId, setSelectedUnitId] = useState<number | null>(null);
  const coursesRepository = useCoursesRepository();

  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();

  const location = useLocation();

  const [user] = useUserContext();
  const hasTeacher = user?.hasRoles?.teacher;

  const { description, inventories, methodology, objectives, access, actualGroups, metalevel } = course || {};

  const formatUnits = (units: Unit[]): UnitName[] => {
    return units.map((unit: Unit) => {
      return {
        id: unit.id,
        name: unit.name
      };
    });
  };

  const getOriginalCourseById = async (hasLoader: boolean = true): Promise<any> => {
    if (hasLoader) {
      setLoading(true);
    }
    try {
      const { data } = await coursesRepository.getOriginalCourseById(id);
      setCourse(data);
      setUnits(data.units);
      if (hasLoader) {
        setUnitNames(formatUnits(data.units));
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchInventories = async (): Promise<any> => {
    setHasLoadingInventories(true);
    try {
      const { data } = await Courses.getListInventories();
      setListInventories(data);
    } finally {
      setHasLoadingInventories(false);
    }
  };

  const handleCloseEditnameModal = (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (e) {
      e.stopPropagation();
    }
    setModalEditnameVisible(false);
    setSelectedUnitId(null);
  };

  const handleClickAddUnit = () => {
    setAddUnitVisible(true);
  };

  useEffect(() => {
    getOriginalCourseById();
    fetchInventories();
  }, [id]);

  return (
    <ErrorBoundary>
      <Spin spinning={loading} tip={strings.LOADING}>
        <Content>
          <OriginalCourses>
            {!hasTeacher && <Breadcrumbs location={location} />}
            <AboutCourse course={course} originalCourseId={id} />
            <Tabs>
              <TabPane tab="Lesson plan" key="1">
                {!hasTeacher && (
                  <Button onClick={handleClickAddUnit} type="primary" style={{ marginInlineEnd: 10 }}>
                    {strings.ADD_UNIT}
                  </Button>
                )}
                <CreateFromExcelButton getOriginalCourseById={getOriginalCourseById} originalCourseId={id} />
                <AddUnitModal
                  addUnitVisible={addUnitVisible}
                  setAddUnitVisible={setAddUnitVisible}
                  originalCourseId={id}
                  getOriginalCourseById={getOriginalCourseById}
                />
                <ModalEditUnit
                  modalVisible={modalEditnameVisible}
                  editnameUnitid={selectedUnitId}
                  handleCloseModal={handleCloseEditnameModal}
                  originalCourseId={id}
                  getOriginalCourseById={getOriginalCourseById}
                />
                <LessonPlan
                  units={units}
                  getOriginalCourseById={getOriginalCourseById}
                  courseId={id}
                  setModalEditNameVisible={setModalEditnameVisible}
                  setSelectedUnitId={setSelectedUnitId}
                  selectedUnitId={selectedUnitId}
                  unitNames={unitNames}
                />
              </TabPane>
              <TabPane tab="Objectives" key="objectives">
                <div dangerouslySetInnerHTML={{ __html: objectives }} />
              </TabPane>
              <TabPane tab="Methodology" key="methodology">
                <div dangerouslySetInnerHTML={{ __html: methodology }} />
              </TabPane>
              {!hasTeacher && (
                <TabPane tab="Groups" key="groups">
                  <Groups actualGroups={actualGroups} originCourseId={id} />
                </TabPane>
              )}
              {!hasTeacher && (
                <TabPane tab="Description" key="4">
                  <p>{description}</p>
                </TabPane>
              )}
              {metalevel && (
                <TabPane tab="Meta-goals" key="metagoals">
                  <MetaLevelInfo metaLevel={metalevel} />
                </TabPane>
              )}
              {!hasTeacher && (
                <TabPane tab="Information" key="5">
                  <Information
                    listInventories={listInventories}
                    inventories={inventories}
                    hasLoadingInventories={hasLoadingInventories}
                  />
                </TabPane>
              )}
              {!hasTeacher && (
                <TabPane tab="Access" key="access">
                  <Access access={access} originalCourseId={id} getOriginalCourseById={getOriginalCourseById} />
                </TabPane>
              )}
            </Tabs>
          </OriginalCourses>
        </Content>
      </Spin>
    </ErrorBoundary>
  );
};

export default { OriginalCourseFull };
