import React, { useCallback, useMemo, useState } from 'react';
import { EditOutlined, PlusOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import { Button, Badge, Tooltip } from 'antd';
import { generateColumn } from '../../helpers';
import { FeedbackForParentModal } from '../RegisterModals/FeedbackForParentModal';
import { useLanguageContext } from 'contexts/LanguageContext';
import { MetaGoalItem } from 'types/courses';
import { successBadgeProps } from '../index';
import { ILessonRegister } from 'types/lesson';
import { useLessonRegisterStore } from 'stores/useLessonRegisterStore';
import { StTitle } from '../styles';

const stWhiteBadge = {
  backgroundColor: '#fff',
  color: '#999',
  boxShadow: '0 0 0 1px #d9d9d9 inset'
};

/**
 * @description Рендеринг колонки Feedback for parent/student
 * @param {object} register
 * @param {function} setRegisters
 * @param {number} lessonId
 * @param {array} registers
 * @return {React.ReactNode}
 */
const RegisterFeedbackForParent = React.memo(
  ({ register, lessonsMetaGoals }: { register: ILessonRegister; lessonsMetaGoals: MetaGoalItem[] }) => {
    const [strings] = useLanguageContext();
    const feedbackForParentsCountPresence = useLessonRegisterStore(
      context => context?.extraRegisterData?.feedbackForParentsCountPresence
    );
    const [hasShowFeedbackForParentModal, setHasShowFeedbackForParentModal] = useState<boolean>(false);
    const { lastFeedback, needFeedbackForParentsStatus, commentForParent, commentForStudent } = register;

    // Документация лежит в src/Student&Parent/Journal/documentation.md
    // Запрос фидбэка от системы: регулярный модульный(запрошенный из календаря комментариев), обычный регулярный(по количеству недель)
    // если feedbackForParentsCountPresence=true,то не учитывать присутствие на уроке и отображать индикатор
    const hasNeedRegularFeedback = useMemo(
      () => needFeedbackForParentsStatus === 1 && (feedbackForParentsCountPresence || register?.presence),
      [needFeedbackForParentsStatus, feedbackForParentsCountPresence, register?.presence]
    );

    // Запрос фидбэка от родителя или админа + учитывается присутствие на уроке
    const hasNeedRequestFeedback = useMemo(
      () => (needFeedbackForParentsStatus === 2 || needFeedbackForParentsStatus === 3) && register?.presence,
      [needFeedbackForParentsStatus, register?.presence]
    );

    // Комментарий для нового студента
    const hasNeedFeedbackForNewStudent = useMemo(
      () => needFeedbackForParentsStatus === 4,
      [needFeedbackForParentsStatus]
    );

    const handleShowModal = useCallback(() => {
      setHasShowFeedbackForParentModal(true);
    }, []);

    const buttonProps = {
      style: { width: 48 },
      onClick: handleShowModal
    };

    const getButtonWhenNoComment = useMemo(() => {
      const BadgeStyle =
        hasNeedRegularFeedback || hasNeedRequestFeedback || hasNeedFeedbackForNewStudent ? {} : stWhiteBadge;
      return (
        <Badge count={lastFeedback} showZero style={BadgeStyle}>
          <Button icon={<PlusOutlined />} {...buttonProps} />
        </Badge>
      );
    }, [hasNeedRegularFeedback, hasNeedRequestFeedback, lastFeedback]);

    const tooltipText = useMemo(() => {
      if (needFeedbackForParentsStatus === 2) return strings.PARENT_ASKED_FOR_A_FEEDBACK;
      if (needFeedbackForParentsStatus === 3) return strings.ADMIN_ASKED_FOR_A_FEEDBACK;
      if (needFeedbackForParentsStatus === 4) return strings.NEW_STUDENT_COMMENT;
      if (lastFeedback === 0) return strings.CLICK_TO_ADD_FEEDBACK;
      return `${strings.CLICK_TO_ADD_FEEDBACK}. ${strings.TIME_TO_FEEDBACK}.`;
    }, [needFeedbackForParentsStatus, lastFeedback, strings]);

    const feedbackModal = useMemo(
      () => (
        <FeedbackForParentModal
          hasNeedFeedback={hasNeedRegularFeedback || hasNeedRequestFeedback || hasNeedFeedbackForNewStudent}
          register={register}
          hasShowFeedbackForParentModal={hasShowFeedbackForParentModal}
          setHasShowFeedbackForParentModal={setHasShowFeedbackForParentModal}
          lessonsMetaGoals={lessonsMetaGoals}
        />
      ),
      [hasNeedRegularFeedback, hasNeedRequestFeedback, register, hasShowFeedbackForParentModal, lessonsMetaGoals]
    );

    /**
     * Если комментария нет - getBadgeStyle
     * Если заполнен - галочка и эдит
     */
    return (
      <div style={{ textAlign: 'center' }}>
        {hasShowFeedbackForParentModal && feedbackModal}
        {commentForParent || commentForStudent ? (
          <Badge count={<CheckCircleTwoTone {...successBadgeProps} />}>
            <Button icon={<EditOutlined />} {...buttonProps} />
          </Badge>
        ) : (
          <Tooltip title={tooltipText}>{getButtonWhenNoComment}</Tooltip>
        )}
      </div>
    );
  }
);

/**
 * @description Генерация колонки feedback for parent
 * @param {string[]} registerColumns
 * @param {function} setRegisters
 * @param {function} handleSetFieldByChildId
 * @param {number} lessonId
 * @param {array} registers
 * @return {object}
 */
export const getFeedbackForParentColumn = (registerColumns: string[], strings, lessonsMetaGoals: MetaGoalItem[]) => {
  if (registerColumns.includes('commentForParent') || registerColumns.includes('commentForStudent')) {
    return {
      ...generateColumn(
        <StTitle style={{ whiteSpace: 'wrap' }}>{strings.PARENTS_STUDENTS}</StTitle>,
        'commentForParent',
        'commentForParent',
        'table-border-left',
        null,
        100
      ),
      render: (comment: string, register: ILessonRegister) => (
        <RegisterFeedbackForParent register={register} lessonsMetaGoals={lessonsMetaGoals} />
      )
    };
  }
};
