import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Spin, Input, Select, Row, Col, Button, Upload, message } from 'antd';
import { useLastLocation } from 'react-router-last-location';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Subject } from 'types';
import { useTrainingsRepository } from 'repos/TrainingsRepository';
import { getFormData } from 'helpers';
import { pickBy, identity } from 'lodash';
import { BASE_TRAININGS } from '../routes';
import { UploadFile } from 'antd/lib/upload/interface';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useAttachmentRepository } from 'repos/AttachmentRepository';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { StageSelect } from 'Global/components/FormComponentsCompatible/Selects/StageSelect';
import { useHistory } from 'react-router-dom';
import { TrainingsParams } from 'types/education';

const { Option } = Select;

interface TrainingFormAddProps extends FormComponentProps {}

export const TrainingFormAddTemplate = (props: TrainingFormAddProps) => {
  const [strings] = useLanguageContext();
  const lastLocation = useLastLocation();
  const trainingsRepository = useTrainingsRepository();
  const attachmentRepository = useAttachmentRepository();

  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [uploadingFileList, setUploadingFileList] = useState<UploadFile[]>([]);

  const {
    form,
    form: { getFieldDecorator, validateFields }
  } = props;

  const { goBack, push } = useHistory();

  const { subjects: subjectsList } = useGlobalCollectionsContext();

  const uploadProps = {
    name: 'files',
    multiple: true,
    beforeUpload: () => false,
    onChange: ({ file, fileList }) => {
      setUploadingFileList(fileList.map(file => file.originFileObj));
    }
  };

  /**
   * @description upload FileList
   * @param {number} id
   * @return {Promise<any>}
   */
  const uploadFileList = trainingId => {
    try {
      uploadingFileList.forEach(file => {
        attachmentRepository.uploadAttachment(
          getFormData({
            type: 'trainingAttach',
            id: trainingId,
            file: file
          }),
          true
        );
      });
    } catch {
      message.error(strings.UPLOAD_FILE_ERROR, 2);
    }
  };

  /**
   * @description Создание тренинга и загрузка файлов после получения id
   * @param {TrainingsParams} params
   * @return {Promise<any>}
   */
  const handleSubmit = () => {
    validateFields(async (err, values: TrainingsParams) => {
      if (!err) {
        const params = pickBy(values, identity);
        setHasLoading(true);
        try {
          const {
            data: { id }
          } = await trainingsRepository.createTraining(params);
          uploadFileList(id);
          if (lastLocation) {
            goBack();
          } else {
            push(BASE_TRAININGS);
          }
        } catch {
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  return (
    <Container>
      <StPageHeader
        onBack={goBack}
        title={strings.CREATING_A_NEW_TRAINING}
        extra={
          <Button key="submit" type="primary" htmlType="submit" onClick={handleSubmit}>
            {strings.SAVE}
          </Button>
        }
      />
      <Spin spinning={hasLoading} tip={`${strings.LOADING} ${strings.TRAINING}`}>
        <Form layout="vertical">
          <Row gutter={[8, 8]}>
            <Col lg={8}>
              <MyFormItem label={strings.TITLE}>{getFieldDecorator('name')(<Input type="text" />)}</MyFormItem>
            </Col>
            <Col lg={8}>
              <StageSelect label={strings.STAGES} form={form} mode="multiple" />
            </Col>
            <Col lg={8}>
              <MyFormItem label={strings.SUBJECTS}>
                {getFieldDecorator('subjects')(
                  <Select mode="multiple" placeholder={strings.SUBJECTS}>
                    {subjectsList.map((subject: Subject) => (
                      <Option value={subject.id} key={subject.id}>
                        {subject.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </MyFormItem>
            </Col>
            <Col lg={24}>
              <MyFormItem label={strings.DESCRIPTION}>
                {getFieldDecorator('description')(<Input.TextArea placeholder={strings.ENTER_DESCRIPTION} />)}
              </MyFormItem>
            </Col>
            <Col lg={12}>
              <Upload {...uploadProps}>
                <Button>
                  <UploadOutlined /> {strings.CLICK_TO_UPLOAD}
                </Button>
              </Upload>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Container>
  );
};

export const TrainingFormAdd = Form.create<TrainingFormAddProps>({})(TrainingFormAddTemplate);

export default { TrainingFormAdd };
