import React from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select } from 'antd';
import { GetFieldDecoratorOptions } from '@ant-design/compatible/lib/form/Form';
import { CUSTOM_FIELD_SEVERITY_ID } from 'Global/modules/Support/Modal/constants';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Option } = Select;

interface SeveritySelectProps {
  getFieldDecorator<T extends Object = {}>(
    id: keyof T,
    options?: GetFieldDecoratorOptions
  ): (node: React.ReactNode) => React.ReactNode;
}

/**
 * @description Селект тип срочности
 * @param {object} param
 * @return {React.ReactNode}
 */
export const SeveritySelect = ({ getFieldDecorator }: SeveritySelectProps) => {
  const [strings] = useLanguageContext();

  return (
    <MyFormItem label={strings.SEVERITY} colon={false}>
      {getFieldDecorator(CUSTOM_FIELD_SEVERITY_ID, {
        rules: [{ required: false }]
      })(
        <Select placeholder={strings.SELECT_SEVERITY}>
          <Option value="">&nbsp;</Option>
          <Option value="can-not-work" key="0">
            {strings.I_AM_BLOCKED_BY_THIS_PROBLEM}
          </Option>
          <Option value="slowed-down" key="1">
            {strings.THIS_PROBLEM_SLOWS_ME_DOWN}
          </Option>
          <Option value="annoyed" key="2">
            {strings.I_CAN_LIVE_WITH_IT_BUT_ITS_ANNOYING}
          </Option>
        </Select>
      )}
    </MyFormItem>
  );
};

export default { SeveritySelect };
