import React from 'react';
import { StyledCardTitle } from 'Global/styles';
import { Avatar, Flex } from 'antd';
import { FEMALE } from 'Global/constants';
import iconWoman from 'Global/img/icons8-woman-60.png';
import iconMan from 'Global/img/icons8-man-60.png';

export function CardTitle(props) {
  const { name, surname, patronymic, phone, gender, previewAvatar, nameHebrew, surnameHebrew } = props;
  return (
    <StyledCardTitle>
      <Avatar src={previewAvatar} size={64} style={{ flex: '0 0 auto' }}>
        {name?.charAt(0)}
        {surname?.charAt(0)}
      </Avatar>
      <div className="name">
        <Flex justify="center" align="center" gap={10}>
          <Flex vertical>
            <span style={{ fontSize: 20 }}>
              {name} {patronymic} {surname}
            </span>
            {(nameHebrew || surnameHebrew) && (
              <span style={{ fontSize: 16 }}>
                {nameHebrew} {surnameHebrew}
              </span>
            )}
          </Flex>
          <img alt="sex" style={{ width: 25, opacity: '.7' }} src={Number(gender) === FEMALE ? iconWoman : iconMan} />
        </Flex>
        {phone && (
          <a className="phone" href={`tel:${phone}`}>
            {phone}
          </a>
        )}
      </div>
    </StyledCardTitle>
  );
}
