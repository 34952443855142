import React, { useEffect, useState } from 'react';
import { FilterTeachers } from './FilterTeachers';
import { DeleteOutlined } from '@ant-design/icons';
import { Table, Modal, message, Row, Button, Tooltip, Radio, Typography } from 'antd';
import { getColumns } from './columns';
import Pagination from 'Global/components/Pagination';
import { ProgressReport as IProgressReport, ProgressReportParams, ReportStatus, ReportTrimester } from 'types/Reports';
import { Link, useHistory } from 'react-router-dom';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { useUserContext } from 'contexts/UserContext';
import { convertStatusesToArray, scrollTop } from 'helpers';
import { first } from 'lodash';
import { BASE_PROGRESS_REPORT } from '../routes';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';
import queryString from "query-string";

interface ProgressReportForTeacherProps {
  trimesters: ReportTrimester[];
  loadingTrimesters: boolean;
}
enum BulkSelectAction {
  OFF = 'off',
  DELETE = 'delete',
  CHANGE_STATUS = 'change_status'
}

const ProgressReportForTeacher = ({ trimesters, loadingTrimesters }: ProgressReportForTeacherProps) => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const { locationSearchParams } = useLocationSearchParams();
  const { push } = useHistory();
  const reportsRepository = useProgressReportsRepository();
  const [{ confirm }, contextHolder] = Modal.useModal();

  const [loading, setLoading] = useState<boolean>(false);
  const [paginationInfo, setPaginationInfo] = useState<any>();
  const [statuses, setStatuses] = useState<ReportStatus[]>([]);
  const [reports, setReports] = useState<IProgressReport[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [bulkSelectAction, setBulkSelectAction] = useState<string>(BulkSelectAction.OFF);
  const [hasLoadingBulkSelect, setHasLoadingBulkSelect] = useState<boolean>(false);

  const hasTeacherRole = user?.hasRoles?.teacher;

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection =
    bulkSelectAction !== BulkSelectAction.OFF
      ? {
          selectedRowKeys,
          onChange: onSelectChange,
          getCheckboxProps: record => ({
            disabled: bulkSelectAction === BulkSelectAction.DELETE ? !record.canDelete : record.status !== 'approved'
          })
        }
      : null;

  /**
   * @description Навигация по репортам
   * @param {number} page
   * @return {Promise<any>}
   */
  const handlePage = async (page: number): Promise<any> => {
    await fetchProgressReports({ ...locationSearchParams, page });
    scrollTop();
  };

  /**
   * @description Список репортов
   * @param {object} params
   * @return {Promise<any>}
   */
  const fetchProgressReports = async (params?: ProgressReportParams): Promise<any> => {
    setLoading(true);
    try {
      const {
        data: { items, paginationInfo }
      } = await reportsRepository.getProgressReports(params);
      setPaginationInfo(paginationInfo);
      setReports(items);
      push({ search: queryString.stringify(params, { arrayFormat: 'bracket' }) });
    } finally {
      setLoading(false);
    }
  };

  /**
   * @description Получение репортов по location search params
   * @return {Promise<any>}
   */
  const getProgressReportsByLocation = async (): Promise<any> => {
    await fetchProgressReports(locationSearchParams);
  };

  /**
   * @description Удаление прогресс репорта
   * @param {number} reportId
   * @return {void}
   */
  const deleteProgressReport = (reportId: number): void => {
    confirm({
      title: strings.ARE_YOU_SURE_YOU_WANT_DELETE_THIS_REPORT,
      okText: strings.DELETE,
      okType: 'danger',
      cancelText: strings.CANCEL,
      async onOk() {
        try {
          const { status } = await reportsRepository.deleteProgressReport(reportId);
          if (status === 200) {
            message.success(strings.REPORT_HAS_BEEN_SUCCESSFULLY_DELETED);
            getProgressReportsByLocation();
          }
        } catch {
          message.error(strings.AN_ERROR_OCCURRED_UNFORTUNATELY_YOUR_REPORT_WAS_NOT_SENT);
        }
      }
    });
  };

  /**
   * @description Статусы для фильтра
   * @return {Promise<any>}
   */
  const fetchStatuses = async (): Promise<any> => {
    try {
      const { data } = await reportsRepository.getStatusesList();
      setStatuses(convertStatusesToArray(data));
    } catch {
      setStatuses([]);
    }
  };

  const actionClmn = {
    title: '',
    dataIndex: '',
    key: 'actions',
    render: (report: IProgressReport) => {
      return (
        <Row align="middle" style={{ gap: 5, flexWrap: 'nowrap' }}>
          <Link to={`${BASE_PROGRESS_REPORT}/${report?.id}`} target="_blank">
            <Button style={{ margin: 0 }} type="primary" size="small">
              {strings.VIEW}
            </Button>
          </Link>
          {!hasTeacherRole && report.canDelete && (
            <Tooltip title={strings.DELETE_REPORT}>
              <Button
                style={{ margin: 0 }}
                icon={<DeleteOutlined />}
                danger
                onClick={() => deleteProgressReport(report.id)}
                size="small"
              />
            </Tooltip>
          )}
        </Row>
      );
    }
  };

  const changeBulkSelectAction = e => {
    setBulkSelectAction(e.target.value);
    setSelectedRowKeys([]);
  };
  /**
   * @description В зависимости от action Меняем статус Draft на Pending или делаем массовую рассылку инвойсов
   * @return {Promise<any>}
   */
  const handleBulkSelect = () => {
    confirm({
      title: strings.ARE_YOU_SURE,
      okType: 'danger',
      async onOk() {
        try {
          setHasLoadingBulkSelect(true);
          if (bulkSelectAction === BulkSelectAction.DELETE) {
            await onBulkDeleteReports();
          } else {
            await onBulkChangeStatusReports();
          }
          fetchProgressReports(locationSearchParams);
          setSelectedRowKeys([]);
        } finally {
          setHasLoadingBulkSelect(false);
        }
      }
    });
  };

  const onBulkChangeStatusReports = async () => {
    try {
      await reportsRepository.makeAvailableToParentByBulkSelect({ headers: selectedRowKeys });
      message.success(strings.SUCCESSFULLY);
    } catch {
      message.error(strings.SORRY_ERROR, 2);
    }
  };

  const onBulkDeleteReports = async () => {
    try {
      await reportsRepository.deleteProgressReportsBulk(selectedRowKeys);
      message.success(strings.SUCCESSFULLY_DELETED);
    } catch {
      message.error(strings.REPORTS_DELETE_ERROR);
    }
  };

  useEffect(() => {
    fetchStatuses();
  }, []);

  useEffect(() => {
    if (trimesters.isNotEmpty()) {
      if (!hasTeacherRole) {
        getProgressReportsByLocation();
      } else {
        // Если перешли по ссылке с параметром триместр, показываем нужный триместр иначе по умолачнию 1 триместр
        const initialTrimester = locationSearchParams?.trimester
          ? locationSearchParams?.trimester
          : first(trimesters).id;
        fetchProgressReports({ trimester: initialTrimester, teacher: user?.meTeacherId });
      }
    }
  }, [trimesters]);

  return (
    <>
      <FilterTeachers
        fetchProgressReports={fetchProgressReports}
        trimesters={trimesters}
        statuses={statuses}
        loadingTrimesters={loadingTrimesters}
      />
      {contextHolder}
      {!hasTeacherRole && (
        <Row align="middle" style={{ marginBottom: 10, marginTop: 20 }}>
          <Typography.Text style={{ marginInlineEnd: 20 }} strong>
            {strings.BULK_SELECT}:
          </Typography.Text>
          <Radio.Group defaultValue={bulkSelectAction} onChange={changeBulkSelectAction} style={{ padding: '8px 0' }}>
            <Radio value={BulkSelectAction.OFF}>{strings.OFF}</Radio>
            <Radio value={BulkSelectAction.DELETE}>{strings.DELETE}</Radio>
            <Radio value={BulkSelectAction.CHANGE_STATUS}>{strings.MAKE_AVAILABLE_TO_PARENT_APPROVED_ONLY}</Radio>
          </Radio.Group>
          {bulkSelectAction !== BulkSelectAction.OFF && (
            <Button
              style={{ marginInlineStart: 20 }}
              loading={hasLoadingBulkSelect}
              onClick={handleBulkSelect}
              disabled={selectedRowKeys.isEmpty()}
              type="primary"
              size="large"
            >
              {bulkSelectAction === BulkSelectAction.DELETE
                ? strings.DELETE_THE_SELECTED_REPORTS
                : strings.CHANGE_STATUS}
            </Button>
          )}
        </Row>
      )}
      <Row style={{ marginTop: 20 }}>
        <Table
          rowSelection={rowSelection}
          dataSource={reports}
          columns={[...getColumns(statuses, strings), actionClmn]}
          loading={loading}
          rowKey="id"
          bordered={false}
          pagination={false}
          rowClassName={(record: IProgressReport) => !record.active && 'row-unactive'}
        />
        <Pagination count={paginationInfo?.pageCount} current={paginationInfo?.current} pageChange={handlePage} />
      </Row>
    </>
  );
};

export default ProgressReportForTeacher;
