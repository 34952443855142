import React, { useState, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, DatePicker, Select, Button, message, Table } from 'antd';
import { Families } from 'api/Families';
import { reversedDateFormatForBackend } from 'helpers/dates';
import { momentFormatValues } from 'helpers/momentFormatValues';
import { freeDaysColumns } from 'Admin/People/Families/Family/Finance/Finance/columns';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { IStudent } from 'types';
import { FreeDay } from 'types/Family';
import { useLanguageContext } from 'contexts/LanguageContext';
import { lowerCase } from 'lodash';

const { Option } = Select;

interface AddFreeDayProps extends FormComponentProps {
  visibleFreeDay: boolean;
  setVisibleFreeDay: (bool: boolean) => void;
  students: IStudent[];
  familyId: number;
}

/**
 * @description Добавление бесплатного дня
 * @param {AddFreeDayProps} props
 * @return {React.ReactNode}
 */
const AddFreeDayTemplate = (props: AddFreeDayProps) => {
  const [freeDays, setFreeDays] = useState<FreeDay[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    form: { getFieldDecorator, validateFields, resetFields },
    visibleFreeDay,
    setVisibleFreeDay,
    students,
    familyId
  } = props;
  const [strings] = useLanguageContext();
  const hasStudents = students?.length > 0;

  /**
   * @description Список бесплатных дней у семьи
   * @param {number} familyId
   * @return {Promise<any>}
   */
  const getFreeDays = async (familyId: number): Promise<any> => {
    setLoading(true);
    await Families.getFreeDays(familyId)
      .then(({ data }) => {
        setFreeDays(data);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  /**
   * @description Удаление бесплатного дня
   * @param {number} freeDayId
   * @return {Promise<any>}
   */
  const deleteFreeDay = async (freeDayId: number): Promise<any> => {
    await Families.deleteFreeDay(freeDayId)
      .then(() => {
        message.success(strings.FREE_DAY_DELETED_SUCCESSFULLY);
        getFreeDays(familyId);
      })
      .catch(() => {});
  };

  /**
   * @description Отправка форма
   * @param {React.MouseEvent<HTMLElement, MouseEvent>} e
   * @return {void}
   */
  const handleSubmit = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    e.preventDefault();

    validateFields(async (err, values) => {
      if (!err) {
        const { date, child } = values;
        const params = {
          date,
          child: child === 'all' ? undefined : child
        };

        await Families.addNewFreeDay(familyId, momentFormatValues(params))
          .then(() => {
            resetFields();
            getFreeDays(familyId);
            message.success(strings.FREE_DAYS_ADDED_SUCCESSFULLY);
          })
          .catch(() => {});
      }
    });
  };

  // Закрытие модалки
  const hideModal = () => setVisibleFreeDay(false);

  const buttons = [
    <Button key="cancelBtn" onClick={hideModal}>
      {strings.CANCEL}
    </Button>,
    <Button key="addBtn" type="primary" onClick={handleSubmit} disabled={students?.length === 0}>
      {hasStudents ? strings.ADD : strings.NO_STUDENTS_FOR_ADD_FREE_DAY}
    </Button>
  ];

  useEffect(() => {
    getFreeDays(familyId);
  }, [familyId]);

  return (
    <Modal title={strings.ADD_A_FREE_DAY} open={visibleFreeDay} onCancel={hideModal} footer={buttons}>
      <Form layout="vertical">
        <MyFormItem label={strings.CHOOSE_DATE}>
          {getFieldDecorator('date', {
            rules: [{ required: true, message: strings.DATE_IS_REQUIRED_FIELD }]
          })(<DatePicker format={reversedDateFormatForBackend} />)}
        </MyFormItem>
        <MyFormItem label={strings.CHOOSE_STUDENT}>
          {getFieldDecorator('child', {
            initialValue: hasStudents ? lowerCase(strings.ALL) : strings.NO_STUDENTS
          })(
            <Select disabled={!hasStudents}>
              {hasStudents && (
                <Option key="allStudents" value="all">
                  {strings.ALL_STUDENTS}
                </Option>
              )}
              {students?.map(({ id, user: { name, surname } }: IStudent) => (
                <Option key={id} value={id}>
                  {name} {surname ?? ''}
                </Option>
              ))}
            </Select>
          )}
        </MyFormItem>
      </Form>
      {freeDays.length > 0 && (
        <div style={{ overflowY: 'auto', maxHeight: 330 }}>
          <Table
            dataSource={freeDays}
            columns={freeDaysColumns(deleteFreeDay)}
            pagination={false}
            showHeader={false}
            loading={{
              tip: strings.LOADING_FREE_DAYS,
              spinning: loading
            }}
          />
        </div>
      )}
    </Modal>
  );
};

export const AddFreeDay = Form.create<AddFreeDayProps>({})(AddFreeDayTemplate);

export default { AddFreeDay };
