import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Table, Typography, Row } from 'antd';
import { columnsPending } from './columns';
import { ShowEmail as StShowEmail } from '../styles';
import { Location, History } from 'history';
import Breadcrumbs from 'Global/components/Breadcrumbs';
import { Mail } from 'api';
import { useLastLocation } from 'react-router-last-location';

const { Title } = Typography;
const { Column } = Table;

interface ShowEmailProps {
  location: Location;
  history: History;
  match: any;
}

/**
 * @description Компонент просмотра email
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const ShowEmail = (props: ShowEmailProps) => {
  const [mail, setMail] = useState([]);
  const [loadMail, setLoadMail] = useState<boolean>(false);
  const {
    location,
    history: { goBack },
    match: {
      params: { id }
    }
  } = props;
  const handleBack = () => goBack();
  const lastLocation = useLastLocation();

  /**
   * @description Получение сообщений по id
   * @return {Promise<any>}
   */
  const getMailById = async (): Promise<any> => {
    setLoadMail(true);
    await Mail.getMailSessionById(id)
      .then(({ data: { Send } }) => {
        setMail(Send);
        setLoadMail(false);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getMailById();
  }, []); // eslint-disable-line

  return (
    <StShowEmail>
      <Breadcrumbs location={location} />
      <Title level={4}>{lastLocation && <ArrowLeftOutlined onClick={handleBack} />} View Email mailing</Title>
      <Row style={{ margin: '20px 0' }}>
        <Table loading={loadMail} dataSource={mail}>
          {columnsPending.map(col => (
            <Column key={col.key} {...col} />
          ))}
        </Table>
      </Row>
    </StShowEmail>
  );
};

export default { ShowEmail };
