import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Button } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';

interface SearchFilterProps {
  isLoading?: boolean;
  getUsers?: (params?: object) => void;
  hasFamily?: boolean;
}

/**
 * @description Поиск по таблице
 * @param {SearchFilterProps} props
 * @returns {React.ReactNode}
 */
export const SearchFilter = ({ getUsers, isLoading, hasFamily }: SearchFilterProps) => {
  const [strings] = useLanguageContext();
  const { locationSearchParams } = useLocationSearchParams();
  const [search, setSearch] = useState<string>('');
  const hasSearchValue = search?.length > 0;

  /**
   * @description Отправка формы
   * @param {React.MouseEvent<HTMLElement, MouseEvent>} e
   * @return {void}
   */
  const handleSubmit = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    e.preventDefault();
    getUsers({ ...locationSearchParams, ...(hasFamily ? { keyword: search } : { query: search }) });
  };

  // Поиск по клику на энтер
  const handleEnter = () => {
    if (search?.length > 0) {
      getUsers({ ...locationSearchParams, ...(hasFamily ? { keyword: search } : { query: search }) });
    }
  };

  useEffect(() => {
    const { query } = locationSearchParams;
    setSearch(query);
  }, [locationSearchParams]);

  return (
    <Row gutter={[10, 10]} align="middle" style={{ marginBottom: 10, flexWrap: 'wrap', width: '100%' }}>
      <Col style={{ minWidth: 200, flex: 1 }}>
        <Input
          onChange={e => setSearch(e.target.value)}
          onPressEnter={handleEnter}
          placeholder={strings.NAME_SURNAME}
          disabled={isLoading}
          value={search}
        />
      </Col>
      <Col lg={4} xs={12}>
        <Button
          onClick={handleSubmit}
          disabled={!hasSearchValue || isLoading}
          loading={isLoading}
          size="large"
          type="primary"
          block
        >
          {strings.SEARCH}
        </Button>
      </Col>
    </Row>
  );
};
