import React from 'react';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { InvoicesTable } from 'Global/components/Tables/Invoices';
import { useFamilyInvoices } from 'hooks/useFamilyInvoices';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useHistory } from 'react-router-dom';

/**
 * @description Эквайринг ЛК родителя
 * @param {object} props
 * @return {React.ReactNode}
 */
export const ParentInvoices = () => {
  const [user] = useUserContext();
  const { invoicesList, isLoadInvoices } = useFamilyInvoices(user?.myFamilyId);
  const { goBack } = useHistory();
  const [strings] = useLanguageContext();

  return (
    <ErrorBoundary>
      <Container>
        <StPageHeader onBack={goBack} title={strings.INVOICES} />
        <InvoicesTable
          isFamily={true}
          isPagination={false}
          invoicesList={invoicesList}
          isLoadInvoices={isLoadInvoices}
        />
      </Container>
    </ErrorBoundary>
  );
};

export default { ParentInvoices };
