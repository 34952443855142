import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Select, Tabs } from 'antd';
import { Subject } from 'types';
import { AssistantsFeedback } from './AssistantsFeedback';
import { useLanguageContext } from 'contexts/LanguageContext';
import { SubjectTab } from './SubjectTab';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { useStudentRepository } from 'repos/StudentRepository';
import { FormItemWithoutMargin } from 'Global/styles';
const { TabPane } = Tabs;
const { Option } = Select;

interface AcademicProgressProps {
  studentId: number;
  subjects: Subject[];
}

export const StTable = styled.div`
  th.th-center {
    padding: 16px 5px;
  }
  td.th-center {
    padding: 16px 5px;
  }
  th.clmn-center {
    text-align: center;
    padding: 16px 5px;
  }
  td.clmn-center {
    text-align: center;
    padding: 16px 5px;
  }
`;

// TODO: Нужно вынести в глобал + сделать переводы, так как экран доступ учителю и админу

/**
 * @description Child Academic Progress
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const AcademicProgress = (props: AcademicProgressProps) => {
  const [strings] = useLanguageContext();
  const { studentId, subjects } = props;
  const [activeKey, setActiveKey] = useState<string>('feedback');
  const [hasLoadingAcademicYears, setHasLoadingAcademicYears] = useState<boolean>(false);
  const [academicYears, setAcademicYears] = useState<{ value: number; title: string }[]>([]);
  const [selectedAcademicYears, setSelectedAcademicYears] = useState<number>();
  const { locationSearchParams } = useLocationSearchParams();
  const { subject } = locationSearchParams || {};
  const studentRepository = useStudentRepository();

  const getAcademicYears = async (): Promise<any> => {
    try {
      setHasLoadingAcademicYears(true);
      const { data } = await studentRepository.getAcademicYearsByStudent(studentId);
      const academicYearsList = convertToYearRangeArray(data);
      setAcademicYears(academicYearsList);
      const lastYear = Math.max(...Object.keys(data).map(key => Number(key)));
      setSelectedAcademicYears(lastYear);
    } finally {
      setHasLoadingAcademicYears(false);
    }
  };
  const convertToYearRangeArray = (obj: { [key: string]: string }): { value: number; title: string }[] => {
    return Object.keys(obj).map(key => ({
      value: Number(key),
      title: obj[key]
    }));
  };

  useEffect(() => {
    if (subjects.isNotEmpty()) {
      if (subject) {
        setActiveKey(String(subject));
      } else {
        setActiveKey(String(subjects[0]?.id));
      }
    }
  }, [subjects]);

  useEffect(() => {
    getAcademicYears();
  }, []);

  return (
    <>
      <FormItemWithoutMargin label="Academic year">
        <Select
          value={selectedAcademicYears}
          style={{ width: 200 }}
          loading={hasLoadingAcademicYears}
          onChange={val => setSelectedAcademicYears(val)}
        >
          {academicYears.map(({ value, title }) => (
            <Option key={value} value={value}>
              {title}
            </Option>
          ))}
        </Select>
      </FormItemWithoutMargin>
      <Tabs activeKey={activeKey} style={{ minHeight: 300 }} onChange={key => setActiveKey(key)}>
        {subjects.map((item: Subject) => (
          <TabPane tab={item.name} key={item.id.toString()}>
            <SubjectTab subjectId={item.id} studentId={studentId} academicYear={selectedAcademicYears} />
          </TabPane>
        ))}
        <TabPane tab={strings.ASSISTANTS_FEEDBACK} key="feedback">
          <AssistantsFeedback studentId={studentId} />
        </TabPane>
      </Tabs>
    </>
  );
};

export default { AcademicProgress };
