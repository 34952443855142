import React from 'react';
import { Redirect } from 'react-router';
import * as parent from 'Student&Parent/routes';
import { useHistory } from 'react-router-dom';
import { hasBranch } from 'helpers';
import { BRANCH_MOSCOW } from 'Global/branches';
import { useUserContext } from 'contexts/UserContext';

export const withCabinetLockedGuard = Component => {
  return function (props) {
    const history = useHistory();
    const [user] = useUserContext();
    const hasBranchMoscow = hasBranch(user?.branch?.id, [BRANCH_MOSCOW]);
    const isCabinetLocked = user?.isCabinetLocked;
    const parentsNotMoscow =
      user.manualBlock &&
      history.location.pathname !== parent.BASE_FINANCE &&
      user?.hasRoles?.parent &&
      !hasBranchMoscow;
    const parentCabinetLock = isCabinetLocked && user?.hasRoles?.parent;

    // Для родителей, которые не из Москвы
    // Если баланс в минусе у родителя и кабинет заблокирован по данному бранч делаем редирект
    if (parentsNotMoscow || parentCabinetLock) {
      return <Redirect to={parent.BASE_FINANCE} />;
    }

    return <Component {...props} />;
  };
};
