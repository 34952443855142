import React from 'react';
import { Col, Row } from 'antd';
import { OrgWidget } from 'Global/components/Org';
import { UpcomingLessons } from './UpcomingLessons';
import { WorkProgressWidget } from 'Teacher/Main/WorkProgress';
import { News } from 'Global/modules/Home/News';
import { hasMoscowBranch } from 'helpers';
import { useUserContext } from 'contexts/UserContext';
import { InformalAgreement } from './InformalAgreement/InformalAgreement';
// import { SnowFall } from 'Global/components/Snow';
import { Container } from 'Global/GlobalStyle';

export const Main = () => {
  const [user] = useUserContext();

  return (
    <Container>
      <Row gutter={[20, 20]}>
        {/*<SnowFall />*/}
        <OrgWidget />
        <Col lg={16}>
          <WorkProgressWidget />
          <UpcomingLessons />
          {hasMoscowBranch(user?.branch) && <InformalAgreement />}
        </Col>
        <Col lg={8}>
          <News />
        </Col>
      </Row>
    </Container>
  );
};

export default { Main };
