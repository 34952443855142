import React from 'react';
import { Collapse, Row } from 'antd';
import { isNull, toNumber } from 'lodash';
import { CollapseItem as StCollapseItem } from '../styles';

const customPanelStyle = {
  background: 'transparent',
  marginBottom: 24,
  border: 0,
  overflow: 'hidden'
};

const { Panel } = Collapse;

interface collapseItemProps {
  header: string;
  children?: any;
  active?: boolean;
  defaultOpen?: boolean;
}

/**
 * @param {string} header
 * @param {React.ReactChildren}
 * @param {boolean} active?
 * @return {React.ReactNode}
 */
export const CollapseItem = ({ header, children, active, defaultOpen }: collapseItemProps) => {
  const collapseProps = {
    bordered: false,
    defaultActiveKey: defaultOpen ? '1' : !isNull(active) && toNumber(active)
  };

  return (
    <StCollapseItem>
      <Row>
        <Collapse {...collapseProps}>
          <Panel header={header} key="1" style={customPanelStyle}>
            {children}
          </Panel>
        </Collapse>
      </Row>
    </StCollapseItem>
  );
};

export default { CollapseItem };
