import dayjs from 'dayjs';
import { Child } from 'types';

export const useStudent = (student: Child) => {
  return {
    fullName: `${student?.name} ${student?.surname}`,
    birthday: student?.birthDate ? dayjs(new Date(student.birthDate)).year(Number(dayjs().format('YYYY'))) : null,
    yearsOld: student?.age,
    studentLink: `/family/${student.familyId}/student/${student.id}`,
    ...student
  };
};
