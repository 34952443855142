import React, { useState, useEffect } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Modal, Button, Row, Col, Tag, Upload, message, Spin } from 'antd';
import { Event, EventFile } from 'types/Schedule';
import { EventAttachments } from 'Global/modules/Schedule/SlotModals/SlotFields/Attachments';
import { ZoomContainer, ZoomItem, ZoomLabel } from 'Global/modules/Schedule/styles';
import { UploadFile } from 'antd/lib/upload/interface';
import { getFormData } from 'helpers';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useAttachmentRepository } from 'repos/AttachmentRepository';

const { Dragger } = Upload;

interface EventTeacherModalProps {
  event: Event;
  hideModal: () => void;
  hasShow: boolean;
}

/**
 * @description Модальное окно события (для учителя)
 * @param hideModal
 * @param hasShow
 * @return {React.ReactNode}
 */
export const EventTeacherModal = ({ hideModal, hasShow, event }: EventTeacherModalProps) => {
  const [attachmentList, setAttachmentList] = useState<EventFile[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [hasUploadFile, setHasUploadFile] = useState<boolean>(false);
  const [strings] = useLanguageContext();
  const attachmentRepository = useAttachmentRepository();

  /**
   * @description Получение вложений к эвенту
   * @return {Promise<any>}
   */
  const getEventAttachments = async (): Promise<any> => {
    try {
      const {
        data: { list }
      } = await attachmentRepository.getAttachmentList({
        id: event.id,
        type: 'eventFiles'
      });
      setAttachmentList(list);
    } catch {}
  };

  /**
   * @description Обработчик на загрузку файлов
   * @param {object} e
   * @return {Promise<any>}
   */
  const handleChangeFiles = async (e: UploadFile & { file: UploadFile; fileList: UploadFile[] }): Promise<any> => {
    const { file } = e;
    if (!file) return;
    setHasUploadFile(true);
    try {
      const { status } = await attachmentRepository.uploadAttachment(
        getFormData({
          type: 'eventFiles',
          id: event.id,
          file: file
        }),
        true
      );
      if (status === 200) {
        message.success(strings.FILE_UPLOAD, 2);
        setFileList([]);
        getEventAttachments();
      }
    } catch {
      message.error(strings.UPLOAD_FILE_ERROR, 2);
    } finally {
      setHasUploadFile(false);
    }
  };

  useEffect(() => {
    if (event.id !== 0) {
      getEventAttachments();
    }
  }, [event.id]);

  return (
    (<Modal
      title={event.name}
      width={900}
      footer={[
        <Button key="back" onClick={hideModal}>
          {strings.CANCEL}
        </Button>
      ]}
      open={hasShow}
      onCancel={hideModal}
    >
      <Row>
        <p>
          <b>{strings.NAME}:</b> {event?.name}
        </p>
        <p>
          <b>{strings.STAGE}:</b> {event?.stage?.name}
        </p>
        <p>
          <b>{strings.ROOM}:</b> {event?.slot?.room?.name}
        </p>
        <p>
          <b>{strings.CONDUCTOR}:</b> {event?.conductor?.name}
        </p>
        <p>
          <b>{strings.PARTICIPANTS}:</b>
          {event?.participants.map(participant => (
            <span style={{ margin: '0 15px' }} key={participant.id}>
              <Tag color="blue">{participant.type}</Tag>
              {participant?.user ? participant?.user?.name : participant?.name}{' '}
              {participant?.user ? participant?.user?.surname : participant?.surname}
            </span>
          ))}
        </p>
        {event?.slot?.room && (
          <>
            <p>
              <strong>Zoom:</strong>
            </p>
            <ZoomContainer nopad>
              <ZoomItem>
                <ZoomLabel>{strings.LINK}</ZoomLabel>
                <p>
                  <a href={event?.slot?.room?.webLink} rel="noopener noreferrer" target="_blank">
                    {event?.slot?.room?.webLink}
                  </a>
                </p>
              </ZoomItem>
              <ZoomItem>
                <ZoomLabel>{strings.LOGIN}</ZoomLabel>
                <p>{event?.slot?.room?.webLogin}</p>
              </ZoomItem>
              <ZoomItem>
                <ZoomLabel>{strings.PASSWORD}</ZoomLabel>
                <p>{event?.slot?.room?.webPassword}</p>
              </ZoomItem>
            </ZoomContainer>
          </>
        )}

        <Spin spinning={hasUploadFile}>
          <div style={{ marginBottom: 8 }}>
            <strong>Files:</strong>
          </div>
          <Row style={{ margin: '0 -10px' }}>
            <Col lg={12}>
              <Dragger beforeUpload={() => false} fileList={fileList} onChange={handleChangeFiles}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">{strings.LOAD_FILES}</p>
              </Dragger>
            </Col>
            <Col lg={12}>
              <EventAttachments attachmentList={attachmentList} getEventAttachments={getEventAttachments} />
            </Col>
          </Row>
        </Spin>
      </Row>
    </Modal>)
  );
};

export default { EventTeacherModal };
