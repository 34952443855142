import React from 'react';
import { message } from 'antd';

/**
 * @description Класс для обработки HTTP ошибок
 */
export class ErrorHandler {
  private code: number;
  private msg: string;
  private route: string;

  constructor(error: any) {
    this.code = error?.response?.data?.code;
    this.msg = error?.response?.data?.message;
    this.route = error.response?.config?.url;

    this.init();
  }

  private sessionExpired() {
    // TODO Logout
    if (!this.route.includes('login_check')) {
      message.warning('Session expired. Try to log in again');
    }
  }

  init() {
    if (this.code === 401) {
      this.sessionExpired();
    }

    if (this.code === 422 || this.code === 405 || this.code === 400) {
      message.error(this.msg, 2);
    }
  }

  static checkStatusCode(err: any, code: number) {
    return err.hasOwnProperty('response') && err.response?.status === code;
  }

  static showErrorMessage(err: any) {
    const errors = err.response.data.message.split(';');
    const mapErrors = errors.map((errorText, idx) => <div key={idx}>{errorText}</div>);

    message.error(mapErrors);
  }
}

export default { ErrorHandler };
