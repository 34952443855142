import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Input, Select, Card, InputNumber } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Branch, IFamily, LearnedFrom, Product } from 'types';
import { useUserContext } from 'contexts/UserContext';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { TutorSelect } from 'Global/components/FormComponents';
import { FamilyStatus } from 'types/Family';
import { Families } from 'api';

const { Option } = Select;

type FamilyProfileFormProps = {
  family?: IFamily;
};

export const FamilyProfileForm: React.FC<FamilyProfileFormProps> = ({ family }) => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const { products, branches, familyTags, familyStatuses } = useGlobalCollectionsContext();
  const hasTopManagerRole = user?.hasRoles?.topManager;
  const [learnedFromList, setLearnedFrom] = useState<LearnedFrom[]>([]);
  const { callAdmin, interviewBy } = family || {};

  useEffect(() => {
    (async () => {
      // Список от кого узнали про LG
      try {
        const { data } = await Families.familyLearnedFrom();
        setLearnedFrom(data);
      } catch {}
    })();
  }, []);

  return (
    <Card>
      <Row gutter={[10, 10]}>
        <Col lg={6} xs={24}>
          <Form.Item
            name={['family', 'branch']}
            label={strings.BRANCH}
            rules={[{ required: true, message: `${strings.BRANCH} ${strings.IS_REQUIRED}` }]}
          >
            <Select disabled={!hasTopManagerRole} placeholder={strings.SELECT_BRANCH}>
              {branches.map((branch: Branch) => (
                <Option value={branch.id} key={branch.id}>
                  {branch.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={6} xs={24}>
          <Form.Item name={['family', 'callAdmin']} label={strings.LEAD_PHONE_CALL_BY}>
            <TutorSelect initOptions={callAdmin && [callAdmin]} />
          </Form.Item>
        </Col>
        <Col lg={6} xs={24}>
          <Form.Item name={['family', 'personalCode']} label={strings.ENTER_PERSONAL_CODE}>
            <Input />
          </Form.Item>
        </Col>
        <Col lg={6} xs={24}>
          <Form.Item name={['family', 'status']} label={strings.STATUS}>
            <Select placeholder={strings.SELECT_STATUS}>
              {familyStatuses?.map((status: FamilyStatus) => (
                <Option value={status.id} key={status.id}>
                  {status.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={6} xs={24}>
          <Form.Item name={['family', 'product']} label={strings.PRODUCT}>
            <Select placeholder={strings.SELECT_PRODUCT}>
              {products.map((product: Product) => (
                <Option value={product.id}>{product.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={6} xs={24}>
          <Form.Item name={['family', 'prefix1c']} label={strings.PREFIX_1_C}>
            <Input />
          </Form.Item>
        </Col>
        <Col lg={6} xs={24}>
          <Form.Item name={['family', 'familyTags']} label={strings.TAGS}>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              tokenSeparators={[',']}
              maxTagCount="responsive"
              placeholder={strings.SELECT_TAGS}
            >
              {familyTags?.map(tag => (
                <Option value={tag.id} key={tag.id}>
                  {tag.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={6} xs={24}>
          <Form.Item name={['family', 'learnedFrom']} label={strings.SOURCE_OF_REFERENCE}>
            <Select placeholder={strings.SELECT_SOURCE_OF_REFERENCE}>
              {learnedFromList.map((learnedFrom: LearnedFrom) => (
                <Option value={learnedFrom.id} key={learnedFrom.id}>
                  {learnedFrom.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={6} xs={24}>
          <Form.Item name={['family', 'commentLearnedFrom']} label={strings.SOURCE_OF_REFERENCE_COMMENT}>
            <Input />
          </Form.Item>
        </Col>
        <Col lg={6} xs={24}>
          <Form.Item name={['family', 'interviewBy']} label={strings.INTERVIEWED_BY}>
            <TutorSelect initOptions={interviewBy && [interviewBy]} />
          </Form.Item>
        </Col>
        <Col lg={6} xs={24}>
          <Form.Item name={['family', 'amoLeadId']} label={strings.AMO_CRM_ID}>
            <InputNumber />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};
export default { FamilyProfileForm };
