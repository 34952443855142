import { httpService } from 'api/core';

/**
 * @description получение слотов
 * @param params параметры фильтра
 * @return {Promise<any>}
 */
const getSlots = (params: any): Promise<any> => httpService.get('/api/v1/slots', params, null, true);

/**
 * @description получение слота
 * @param {number} id слота
 * @return {Promise<any>}
 */
const getSlotById = (id: number): Promise<any> => httpService.get(`/api/slot/${id}`);

/**
 * @description Удаление слота
 * @param {number} id слота
 * @return {Promise<any>}
 */
const deleteSlotById = (id: number): Promise<any> => httpService.delete(`/api/slot/${id}`);

export default {
  getSlots,
  getSlotById,
  deleteSlotById
};
