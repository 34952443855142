import React, { useContext, useMemo } from 'react';
import { httpService } from 'api/core';
import {
  ITeacherGetParams,
  ITeacherIndividualWagePostParams,
  ITeacherPostParams,
  TeacherReportsFilterParams
} from '../types/Teachers/teacher';
import { ApiFinanceReportTeachers, ApiGetTeacherFinance } from '../types/Teachers/finance';

const TeacherRepositoryContext = React.createContext<ITeacherRepository>(null);

interface ITeacherRepository {
  getTeachers: (params: ITeacherGetParams) => Promise<any>;
  createTeacher: (params: ITeacherPostParams) => Promise<any>;
  getTeacherById: (teacherId: number | string) => Promise<any>;
  editTeacher: (teacherId: number, params: ITeacherPostParams) => Promise<any>;
  getCollaborationHistory: (teacherId: number | string, params) => Promise<any>; //TODO describe params
  deleteTeacher: (teacherId: number) => Promise<any>;
  getNotFilledWorkProgress: () => Promise<any>;
  getArchiveWorkProgress: () => Promise<any>;
  getTeacherContracts: () => Promise<any>;
  getTeacherGrading: () => Promise<any>;
  getTeacherScheduleStatistics: (teacherId: number | string) => Promise<any>;
  getTeacherFinance: (teacherId: number | string, params?: ApiGetTeacherFinance) => Promise<any>;
  getTeacherReports: (params: TeacherReportsFilterParams) => Promise<any>;
  getFinanceReportTeachers: (params: ApiFinanceReportTeachers) => Promise<any>;
  getTeacherRejectedComments: () => Promise<any>;
  exportTeachers: (params: ITeacherGetParams) => Promise<any>;
  getTeacherIndividualWageList: (teacherId: number | string) => Promise<any>;
  addTeacherIndividualWage: (teacherId: number | string, params: ITeacherIndividualWagePostParams) => Promise<any>;
  editTeacherIndividualWage: (
    teacherId: number | string,
    wageId: number | string,
    params: ITeacherIndividualWagePostParams
  ) => Promise<any>;
  deleteTeacherIndividualWage: (teacherId: number | string, wageId: number | string) => Promise<any>;
}

class TeacherRepository implements ITeacherRepository {
  getTeachers(params: ITeacherGetParams) {
    return httpService.get(`/api/v1/teachers`, { ...params, direction: 'ASC', orderBy: 'user.lastName' }, null, true);
  }
  getTeacherById(teacherId: number | string) {
    return httpService.get(`/api/v1/teachers/${teacherId}`, null, null, true);
  }
  getNotFilledWorkProgress() {
    return httpService.get('/api/teacher/my/work-progress/not-filled');
  }
  getArchiveWorkProgress() {
    return httpService.get('/api/teacher/my/work-progress/archive');
  }
  deleteTeacher(teacherId: number) {
    return httpService.delete(`/api/teachers/${teacherId}`);
  }

  createTeacher(params: ITeacherPostParams) {
    return httpService.post(`/api/v1/user/teacher`, params, null, null, true);
  }

  editTeacher(teacherId, params: ITeacherPostParams) {
    return httpService.put(`/api/v1/user/teacher/${teacherId}`, params, null, null, true);
  }
  getCollaborationHistory(teacherId: number, params) {
    return httpService.get(`/api/v1/teachers/${teacherId}/history`, params, null, true);
  }
  getTeacherContracts() {
    return httpService.get(`/api/v1/teachers/contracts`, null, null, true);
  }

  getTeacherScheduleStatistics(teacherId: number | string) {
    return httpService.get(`/api/v1/teacher/${teacherId}/schedule-statistics`, null, null, true);
  }

  getTeacherFinance(teacherId: number | string, params?: ApiGetTeacherFinance) {
    return httpService.get(`/api/v1/teacher/${teacherId}/finance`, params, null, true);
  }

  getTeacherReports(params: TeacherReportsFilterParams) {
    return httpService.get('/api/v1/report/teachers', params, null, true);
  }

  getFinanceReportTeachers(params: ApiFinanceReportTeachers) {
    return httpService.get(`/api/v1/report/finance/teacher`, params, null, true);
  }

  getTeacherGrading() {
    return httpService.get(`/api/v1/teacher/grading`, null, null, true);
  }

  getTeacherRejectedComments() {
    return httpService.get(`/api/v1/teacher/my/comments/rejected`, null, null, true);
  }

  exportTeachers(params: ITeacherGetParams) {
    return httpService.get(
      `/api/v1/teachers/export/excel`,
      { ...params, direction: 'ASC', orderBy: 'user.lastName' },
      null,
      true
    );
  }
  getTeacherIndividualWageList(teacherId: number | string) {
    return httpService.get(`/api/v1/teacher/${teacherId}/wage`, null, null, true);
  }
  addTeacherIndividualWage(teacherId: number | string, params: ITeacherIndividualWagePostParams) {
    return httpService.post(`/api/v1/teacher/${teacherId}/wage`, params, null, null, true);
  }
  editTeacherIndividualWage(
    teacherId: number | string,
    wageId: number | string,
    params: ITeacherIndividualWagePostParams
  ) {
    return httpService.put(`/api/v1/teacher/${teacherId}/wage/${wageId}`, params, null, null, true);
  }
  deleteTeacherIndividualWage(teacherId: number | string, wageId: number | string) {
    return httpService.delete(`/api/v1/teacher/${teacherId}/wage/${wageId}`, null, null, true);
  }
}

export const TeacherRepositoryProvider = props => {
  const service = useMemo(() => new TeacherRepository(), []);

  return <TeacherRepositoryContext.Provider value={service}>{props.children}</TeacherRepositoryContext.Provider>;
};

export const useTeacherRepository = () => {
  const service = useContext(TeacherRepositoryContext);

  if (!service) {
    throw new Error('Teacher repository is unavailable');
  }

  return service;
};
