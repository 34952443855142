import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Typography, Select, Checkbox, Form } from 'antd';
import { OriginalLessonItem, Unit } from 'types/courses';
import { FormItemContainer } from 'Admin/Courses/styles';
import {
  LESSON_BONUS_MATERIAL_TYPE,
  LESSON_HIDDEN_MATERIAL_TYPE,
  LESSON_HOMEWORK_MATERIAL_TYPE,
  LESSON_MATERIAL_TYPE,
  LESSON_TEST_MATERIAL_TYPE
} from 'Global/modules/Lesson/constants';

import { uniqBy } from 'lodash';
import { useLanguageContext } from 'contexts/LanguageContext';
import MaterialsUploaderMemo from './MaterialsLoader';
import { Courses } from 'api';
import { MyJoditEditorMemo } from 'Global/components/MyJoditEditor';
import { useLessonInfoStore } from 'stores/useLessonInfoStore';

const { Title } = Typography;
const { Option } = Select;

interface LessonMainInfoProps {
  handleChangeUnitList(unitId: number): Promise<any>;
  handleChangeOriginalLesson: (originalLessonId: number) => void;
  originalLessons: OriginalLessonItem[];
  hasClassroomMaterialsUpdated?: boolean;
  setHasClassroomMaterialsUpdated?: (flag: boolean) => void;
  hasHwMaterialsUpdated?: boolean;
  setHasHwMaterialsUpdated?: (flag: boolean) => void;
}

export const LessonMainInfo = (props: LessonMainInfoProps) => {
  const form = Form.useFormInstance();
  const {
    handleChangeUnitList,
    handleChangeOriginalLesson,
    originalLessons,
    hasClassroomMaterialsUpdated,
    setHasClassroomMaterialsUpdated,
    hasHwMaterialsUpdated,
    setHasHwMaterialsUpdated
  } = props;

  const [strings] = useLanguageContext();
  const lessonInfo = useLessonInfoStore(state => state.lessonInfo);

  const { lesson } = lessonInfo;
  const { number, noHomework, title, id, homework, material, courseGroup, unit, originalLesson } = lesson || {};

  const [unitList, setUnitList] = useState<Unit[]>([]);

  const getFormData = (file: File) => {
    let formData = new FormData();
    formData.append('file[]', file);
    formData.append('object', 'lesson');
    formData.append('id', String(lesson?.id));
    return formData;
  };

  const getUnits = async (id: number): Promise<any> => {
    try {
      const { data, status } = await Courses.getUnitList({ type: 'course', id, isActive: 1 });
      if (status === 200) {
        setUnitList(
          uniqBy(
            [...data, unit].filter(i => i),
            unit => unit.id
          )
        );
      }
    } catch {
      setUnitList([]);
    }
  };

  useEffect(() => {
    if (courseGroup?.id) {
      getUnits(courseGroup.id);
    }
  }, [courseGroup]);

  return (
    <>
      <Row gutter={6}>
        <Col lg={6}>
          <Form.Item label={strings.NUMBER} name="lessonNumber" initialValue={number}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col lg={18}>
          <Form.Item label={strings.UNIT} name="unit" initialValue={unit?.id} rules={[{ required: false }]}>
            <Select onChange={handleChangeUnitList}>
              {unitList?.map((unit: Unit) => (
                <Option key={unit.id} value={unit.id}>
                  {unit.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label={strings.LESSON_TOPIC_FROM_SYLLABUS}
        name="originalLesson"
        initialValue={unit?.id && originalLesson?.id}
        rules={[{ required: true, message: strings.LESSON_TOPIC_SYLLABUS_REQUIRED }]}
      >
        <Select onChange={(originalLessonId: number) => handleChangeOriginalLesson(originalLessonId)}>
          {originalLessons.map((lesson: OriginalLessonItem) => (
            <Option key={lesson.id} value={lesson.id}>
              {lesson.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={strings.LESSON_TOPIC}
        name="title"
        initialValue={title}
        rules={[{ required: true, message: strings.LESSON_TOPIC_REQUIRED }]}
      >
        <Input />
      </Form.Item>
      <FormItemContainer>
        <Form.Item
          label={
            <>
              {strings.CLASSROOM_ACTIVITIES}
              <span style={{ opacity: 0.5 }}> ({strings.THIS_FIELD_IS_VISIBLE_TO_PARENTS_STUDENTS})</span>
            </>
          }
          style={{ marginBottom: 20 }}
          name="material"
          initialValue={material}
        >
          <MyJoditEditorMemo
            key="material"
            height={350}
            value={material}
            onChange={value => form.setFieldsValue({ material: value })}
            getFormData={getFormData}
          />
        </Form.Item>
        <MaterialsUploaderMemo
          isDragger={true}
          lessonId={id}
          type={LESSON_MATERIAL_TYPE}
          text={strings.DRAG_CLASSROOM_MATERIALS}
          hasDataBeenUpdated={hasClassroomMaterialsUpdated}
          setHasDataBeenUpdated={setHasClassroomMaterialsUpdated}
        />
      </FormItemContainer>
      <Title level={4}>{strings.HOMEWORK}</Title>
      <Form.Item name="noHomework" valuePropName="checked" initialValue={noHomework}>
        <Checkbox>{strings.NO_HOMEWORK_ASSIGNED}</Checkbox>
      </Form.Item>
      <FormItemContainer>
        <Form.Item label={strings.HOME_ASSIGNMENT} style={{ marginBottom: 20 }} name="homework" initialValue={homework}>
          <MyJoditEditorMemo
            key="homework"
            value={homework}
            height={350}
            onChange={value => form.setFieldsValue({ homework: value })}
            getFormData={getFormData}
          />
        </Form.Item>
        <MaterialsUploaderMemo
          isDragger={true}
          lessonId={id}
          type={LESSON_HOMEWORK_MATERIAL_TYPE}
          text={strings.DRAG_HOMEWORK_MATERIALS}
          hasDataBeenUpdated={hasHwMaterialsUpdated}
          setHasDataBeenUpdated={setHasHwMaterialsUpdated}
        />
      </FormItemContainer>
      <Title level={4}>{strings.FILES_ATTACHMENT}</Title>
      <FormItemContainer>
        <MaterialsUploaderMemo lessonId={id} type={LESSON_TEST_MATERIAL_TYPE} text={strings.ADD_TEST} />
      </FormItemContainer>
      <FormItemContainer>
        <MaterialsUploaderMemo
          lessonId={id}
          type={LESSON_BONUS_MATERIAL_TYPE}
          text={strings.ADD_ADDITIONAL_MATERIALS}
        />
      </FormItemContainer>
      <FormItemContainer>
        <MaterialsUploaderMemo lessonId={id} type={LESSON_HIDDEN_MATERIAL_TYPE} text={strings.ADD_HIDDEN_MATERIALS} />
      </FormItemContainer>
    </>
  );
};
