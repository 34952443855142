import React, { useEffect, useState } from 'react';
import { Form, Row, Button, Flex, Spin, message } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useStudentRepository } from 'repos/StudentRepository';
import { match } from 'react-router';
import { IStudent, Performer } from 'types';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import styled from 'styled-components';
import { BrunerStudentForm } from './BrunerStudentForm';
import { BranchBelgrade, BranchBruner, BranchSPB } from 'types/branches';
import { BaseStudentForm } from './BaseStudentForm';
import dayjs from 'dayjs';
import { booleanFormatValues } from 'helpers/booleanFomatValues';
import { formatDateValues } from 'helpers/dates';
import { StudentParams } from 'types/Family';
import { getAllFormData } from 'helpers';
import { Global } from 'api';

export const StRow = styled(Row)`
  flex: 1;
  width: 100%;
`;

export const EditStudent = () => {
  const [strings] = useLanguageContext();
  const { goBack, push } = useHistory();
  const studentRepository = useStudentRepository();
  const globalRequestsRepository = useGlobalRequestsRepository();

  const {
    params: { userId: id }
  }: match<{ userId: string }> = useRouteMatch();

  const [form] = Form.useForm();
  const [student, setStudent] = useState<IStudent>();
  const [hasLoading, setHasLoading] = useState<boolean>(false);

  const {
    familyId,
    classN,
    school,
    tutor,
    studyProgram,
    status,
    target,
    hobby,
    candidateNumber,
    comment,
    performer,
    englishLevelComment,
    englishLevel,
    examineType,
    entryTestResults,
    firstNameEnglish,
    lastNameEnglish,
    UCINumber,
    autogeneratedInvoices,
    allergies,
    medicalConditions,
    psychologistEvaluation,
    takingPhotos,
    schoolTrips
  } = student || {};
  const { name, surname, phone, phoneSecond, email, patronymic, birthDate, branch, gender } = student?.user || {};

  const hasBruner = branch?.id === BranchBruner.id;
  const defValues = {
    name,
    surname,
    patronymic,
    phone,
    phoneSecond,
    email,
    birthday: birthDate && dayjs(birthDate),
    branch: branch?.id,
    gender,
    status: status?.id,
    tutor: tutor?.id,
    studyProgram: studyProgram?.id,
    englishLevel: englishLevel?.id,
    englishLevelComment,
    examsResults: entryTestResults,
    school,
    classN,
    hobby,
    target,
    comment,
    candidateNumber,
    examineType,
    firstNameEnglish,
    lastNameEnglish,
    UCINumber,
    performer: performer?.id,
    autogeneratedInvoices,
    allergies,
    medicalConditions,
    psychologistEvaluation,
    takingPhotos,
    schoolTrips
  };

  /**
   * @description Получение студента по id
   * @return {Promise<any>}
   */
  const fetchStudentById = async () => {
    setHasLoading(true);
    try {
      const { data } = await studentRepository.getStudentById(id);
      setStudent(data);
    } finally {
      setHasLoading(false);
    }
  };

  /**
   * @description Отправка формы
   * @return {void}
   */
  const handleSubmit = async (values: StudentParams): Promise<any> => {
    try {
      setHasLoading(true);
      const params = booleanFormatValues(formatDateValues(values));
      const { status } = await studentRepository.editStudent(id, {
        ...params,
        family: Number(familyId)
      });
      if (status === 200) {
        const { avatar } = values;
        //если изменили аватар
        if (avatar) {
          uploadAvatar(avatar.file);
        }
        message.success(strings.EDIT_CHILDREN_SUCCESSFULLY, 2);
        push(`/family/${familyId}/student/${id}`);
      }
    } catch (err) {
      message.error(strings.EDIT_CHILDREN_ERROR_TRY_AGAIN_LATER, 2);
    } finally {
      setHasLoading(false);
    }
  };

  /**
   * @description Запрос на добавление аватарки студенту
   * @return {Promise<any>}
   */
  const uploadAvatar = async (avatar: File): Promise<any> => {
    try {
      await globalRequestsRepository.uploadAvatar(getAllFormData({ id: id, type: 'student', file: avatar }));
    } catch {}
  };

  useEffect(() => {
    fetchStudentById();
  }, []);

  return (
    <Container>
      <StPageHeader title={strings.EDIT_STUDENT} onBack={goBack} />
      <Spin spinning={hasLoading}>
        {student && (
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={defValues}
            style={{ marginBottom: 20 }}
          >
            {hasBruner ? (
              <BrunerStudentForm student={student} />
            ) : (
              <BaseStudentForm student={student} familyId={familyId} familyBranch={branch?.id} />
            )}
            <Flex justify="end">
              <Button htmlType="submit" type="primary">
                {strings.EDIT_STUDENT}
              </Button>
            </Flex>
          </Form>
        )}
      </Spin>
    </Container>
  );
};
export default { EditStudent };
