import styled from 'styled-components';

export const MaterialTitle = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #4091f7;
  cursor: pointer;
`;

export const CreatedBy = styled.span`
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #4091f7;
  opacity: 0.7;
`;
