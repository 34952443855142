import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { message, Spin } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { IDataNewReportByStudent, InfoNewReportByStudent } from 'types/Reports';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';
import { match } from 'react-router';
import EditNewProgressReportItem from './EditNewProgressReportItem';

export const EditNewProgressReport = () => {
  const {
    params: { studentId, semesterId }
  }: match<{ studentId: string; semesterId: string }> = useRouteMatch();
  const [strings] = useLanguageContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [reportByStudent, setReportByStudent] = useState<InfoNewReportByStudent>(null);
  const [dataList, setDataList] = useState<IDataNewReportByStudent[]>([]);
  const { goBack, push } = useHistory();
  const reportsRepository = useProgressReportsRepository();

  const studentName = useMemo(
    () => `${reportByStudent?.student?.user?.surname} ${reportByStudent?.student?.user?.name}`,
    [reportByStudent]
  );

  const getReportByStudent = useCallback(async (): Promise<any> => {
    setHasLoading(true);
    try {
      const {
        data: { data, ...info }
      } = await reportsRepository.getNewReportByStudent({
        student: Number(studentId),
        trimester: Number(semesterId)
      });
      setDataList(data);
      setReportByStudent(info);
    } catch (e) {
      message.error(e.message);
    } finally {
      setHasLoading(false);
    }
  }, []);

  useEffect(() => {
    getReportByStudent();
  }, []);

  return (
    <Container>
      <StPageHeader onBack={goBack} title={`${strings.NEW_PROGRESS_REPORT_FOR} ${studentName}`}></StPageHeader>
      <Spin spinning={hasLoading}>
        {dataList.map(item => (
          <EditNewProgressReportItem {...item} refetchReports={getReportByStudent} status={reportByStudent?.status} />
        ))}
      </Spin>
    </Container>
  );
};

export default { EditNewProgressReport };
