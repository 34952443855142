import React, { useEffect, useState } from 'react';
import { Row, Table, Button, message, Spin, Flex } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useBranchesRepository } from 'repos/BranchesRepository';
import { Branch } from 'types';
import { CheckCircleTwoTone, EditOutlined, MinusCircleTwoTone } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { BASE_BRANCHES_CLICKUP, BRANCHES_CLICKUP_NEW } from '../../routes';
import { IBranchClickupItem } from 'types/branches';

export const BranchesClickupTab = () => {
  const [strings] = useLanguageContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [dataList, setDataList] = useState<IBranchClickupItem[]>([]);
  const branchRepository = useBranchesRepository();

  const fetchBranches = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await branchRepository.getBranchesClickup();
      setDataList(data);
    } catch (e) {
      message.error(e.message);
    } finally {
      setHasLoading(false);
    }
  };

  const getColumns = [
    {
      title: 'Branch',
      dataIndex: 'branch',
      key: 'branch',
      render: (branch: Branch) => branch?.name
    },
    {
      title: strings.TYPE,
      key: 'type',
      dataIndex: 'type'
    },
    {
      title: strings.INFO,
      key: 'info',
      render: (record: IBranchClickupItem) => {
        const { taskList, taskStatus, taskAssignee, sourceUrl } = record;
        return (
          <Flex vertical gap={5}>
            {taskList && (
              <p>
                {strings.LIST}: {taskList}
              </p>
            )}
            {taskStatus && (
              <p>
                {strings.STATUS}: {taskStatus}
              </p>
            )}
            {taskAssignee && (
              <p>
                {strings.ASSIGNEE}: {taskAssignee}
              </p>
            )}
            {sourceUrl && (
              <a href={sourceUrl} target="_blank">
                {sourceUrl}
              </a>
            )}
          </Flex>
        );
      }
    },
    {
      title: strings.ACTIVE,
      key: 'isActive',
      dataIndex: 'isActive',
      className: 'clmn-center',
      render: (active: boolean) =>
        active ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <MinusCircleTwoTone twoToneColor="#f5222d" />
    }
  ];

  const getActionColumns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'action',
      render: (id: number) => (
        <Flex gap={10} justify="end">
          <Link to={`${BASE_BRANCHES_CLICKUP}/${id}/edit`}>
            <Button icon={<EditOutlined />} size="small" />
          </Link>
        </Flex>
      )
    }
  ];

  useEffect(() => {
    fetchBranches();
  }, []);

  return (
    <>
      <Flex justify="end">
        <Link to={BRANCHES_CLICKUP_NEW}>
          <Button type="primary">{strings.ADD}</Button>
        </Link>
      </Flex>
      <Spin spinning={hasLoading}>
        <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
          <Table dataSource={dataList} columns={[...getColumns, ...getActionColumns]} pagination={false} />
        </Row>
      </Spin>
    </>
  );
};

export default { BranchesClickupTab };
