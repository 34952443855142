import { Lang, LANGS } from 'Admin/Translations';
import { Branch } from './index';

export enum BranchMoscow {
  id = 1,
  name = 'Moscow'
}

export enum BranchSPB {
  id = 2,
  name = 'Saint-Petersburg'
}

export enum BranchParis {
  id = 3,
  name = 'Paris'
}

export enum BranchRiga {
  id = 5,
  name = 'Riga'
}

export enum BranchBruner {
  id = 37,
  name = 'Bruner'
}

export enum BranchBelgrade {
  id = 36,
  name = 'Belgrade'
}
export enum BranchType {
  lgeg = 'lgeg',
  rg = 'rg'
}
export enum SmsEngineType {
  zagruzka = 'zagruzka',
  gateway = 'gateway'
}

export interface IBranchPostParams {
  mane: string;
  active: boolean;
  type?: BranchType;
  academicEmail?: string;
  operationEmail?: string;
  hrEmail?: string;
  adminLanguage?: Lang;
  parentLanguage?: Lang;
  hideTeacherAssessment?: boolean;
  daysFeedbackForNewStudent?: number;
  invoiceMonthFinanceSystem?: boolean;
  hideTeacherFinance?: boolean;
  disableCabinetLock?: boolean;
  metalevelPicturesVisible?: boolean;
  noReplyEmail?: string;
  invoicesEmail?: string;
  noReplyName?: string;
  smsSender?: string;
  smsEngine?: SmsEngineType;
}
export interface IBranchClickupItem {
  id: number;
  type: string;
  isActive: true;
  token: string;
  taskList: string;
  taskStatus: string;
  taskAssignee: string;
  sourceUrl: string;
  checklistName: string;
  checklistItems: string;
  subtasks: string;
  branch: Branch;
}

export enum BranchClickupType {
  siteFamily = 'site_family',
  hrVacancy = 'hr_vacancy_any',
  hrVacancyTeacher = 'hr_vacancy_teacher',
  hrVacancyAssistant = 'hr_vacancy_assistant'
}
export interface IBranchClickupPostParams {
  branch: number;
  type: BranchClickupType;
  isActive: true;
  token: string;
  taskList: string;
  taskStatus: string;
  taskAssignee?: string;
  sourceUrl?: string;
  checklistName?: string;
  checklistItems?: string;
  subtasks?: string;
}

export interface IBranchWelcomeLetterParams {
  body: string;
  title: string;
}
export interface IBranchWelcomeLetterItem {
  id: number;
  body: string;
  title: string;
}
