import React, { useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DownloadOutlined, DownOutlined } from '@ant-design/icons';
import { Menu, Button, Checkbox, Dropdown, DatePicker } from 'antd';
import { ScheduleExportExcel } from 'types/Schedule';
import { BACKEND_LOCATION, downloadFileByUrl } from 'helpers';
import { dateFormatForBackend, reversedDateFormatForBackend } from 'helpers/dates';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';

/**
 * @description Компонент экспорта расписания в эксель
 * @return {React.ReactNode}
 */
export const TimeTable = () => {
  const { locationSearchParams } = useLocationSearchParams();
  const [date, setDate] = useState<string>(null);
  const [hasUseScale, setHasUseScale] = useState<boolean>(false);
  const [hasInverse, setHasInverse] = useState<boolean>(false);
  const [hasDivideByLevels, setHasDivideByLevels] = useState<boolean>(false);

  /**
   * @description Функция для запроса на экспорт
   * @return {Promise<any>}
   */
  const handleDownLoad = async (): Promise<any> => {
    const exportParams: ScheduleExportExcel = {
      ...locationSearchParams,
      scale10: Number(hasUseScale),
      divideByLevels: Number(hasDivideByLevels),
      inverse: Number(hasInverse),
      date
    };

    await downloadFileByUrl(`${BACKEND_LOCATION}/api/schedule/download`, `schedule ${date}.xls`, exportParams);
  };

  useEffect(() => {
    const { dateStart } = locationSearchParams;
    if (dateStart) {
      setDate(dateStart);
    }
  }, [locationSearchParams]);

  const timeTableDropDown = (
    <Menu>
      <div style={{ padding: 10 }}>
        <div style={{ marginBottom: 10 }}>
          <DatePicker
            onChange={(d: Dayjs) => setDate(d.format(dateFormatForBackend))}
            defaultValue={date && dayjs(date)}
            format={reversedDateFormatForBackend}
          />
        </div>
        <div style={{ marginBottom: 10 }}>
          <Checkbox onChange={e => setHasUseScale(e.target.checked)}>Use 10 min scale</Checkbox>
        </div>
        <div style={{ marginBottom: 10 }}>
          <Checkbox onChange={e => setHasInverse(e.target.checked)}>Inverse</Checkbox>
        </div>
        <div style={{ marginBottom: 10 }}>
          <Checkbox onChange={e => setHasDivideByLevels(e.target.checked)}>Divide by levels</Checkbox>
        </div>
        <div>
          <Button type="primary" block onClick={handleDownLoad}>
            Download <DownloadOutlined />
          </Button>
        </div>
      </div>
    </Menu>
  );
  return (
    <Dropdown overlay={timeTableDropDown}>
      <Button>
        Timetable for printing <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default { TimeTable };
