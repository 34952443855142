import React from 'react';
import dayjs from 'dayjs';
import { UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, Button, Row, Col, DatePicker, Upload, Checkbox, message } from 'antd';
import { ModalMode, Status } from 'types';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { dateFormat, getFormData } from 'helpers';
import { Teachers } from 'api/Teachers';
import { isEmpty } from 'lodash';
import { useLanguageContext } from 'contexts/LanguageContext';
import { momentFormatValues } from 'helpers/momentFormatValues';
import MyJoditEditor from 'Global/components/MyJoditEditor';

interface AddHistoryProps extends FormComponentProps {
  id?: number | string;
  status?: Status;
  hasShow: boolean;
  hideModal: () => void;
  mode: ModalMode;
  reloadHistory: (dateStart?: string, dateEnd?: string) => Promise<any>;
}

/**
 * @description Компонент добавления записи в историю учителя
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const AddHistoryTemplate = (props: AddHistoryProps) => {
  const {
    form: { getFieldDecorator, validateFieldsAndScroll, setFieldsValue },
    hasShow,
    hideModal,
    mode,
    status,
    id: teacherId,
    reloadHistory
  } = props;

  const [strings] = useLanguageContext();

  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values) => {
      if (isEmpty(errors)) {
        const params = getFormData(momentFormatValues(values));
        try {
          await Teachers.createTeacherContact(teacherId, params);
          message.success(strings.CONTACT_SUCCESSFULLY_COMPLETED, 2);
          hideModal();
          reloadHistory();
        } catch {}
      }
    });
  };

  const handleDelete = (): void => {};

  const uploadProps = {
    name: 'files',
    multiple: true,
    beforeUpload: () => false
  };

  /**
   * @description Функция для получения массива кнопок (действий)
   * @return {React.ReactNode[]}
   */
  const getFooter = (): React.ReactNode[] => {
    const buttons = [
      <Button key="back" onClick={hideModal}>
        {strings.CANCEL}
      </Button>
    ];
    if (mode === ModalMode.Add) {
      buttons.unshift(
        <Button type="primary" htmlType="submit" onClick={handleSubmit}>
          {strings.CREATE}
        </Button>
      );
    } else if (mode === ModalMode.Edit) {
      buttons.unshift(
        <Button type="primary" htmlType="submit" onClick={handleSubmit}>
          {strings.UPDATE}
        </Button>
      );
    } else if (mode === ModalMode.Delete) {
      buttons.unshift(
        <Button type="primary" htmlType="submit" onClick={handleDelete}>
          {strings.REMOVE}
        </Button>
      );
    }
    return buttons;
  };

  return (
    <Modal width={620} title="Adding new history item" footer={getFooter()} open={hasShow} onCancel={hideModal}>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Row>
          <Col span={12}>
            <MyFormItem label={strings.DATE}>
              {getFieldDecorator('contacted_at', {
                rules: [{ required: true, message: `${strings.DATE} ${strings.IS_REQUIRED}` }],
                initialValue: dayjs()
              })(<DatePicker placeholder={strings.DATE} format={dateFormat} />)}
            </MyFormItem>
          </Col>
          <Col lg={12}>
            <MyFormItem label={strings.AUDIO_FILE}>
              {getFieldDecorator('audio', {
                rules: [{ required: false }]
              })(
                <Upload {...uploadProps}>
                  <Button>
                    <UploadOutlined /> {strings.CLICK_TO_UPLOAD}
                  </Button>
                </Upload>
              )}
            </MyFormItem>
          </Col>
          {hasShow && (
            <Col span={24}>
              <MyFormItem label={strings.DESCRIPTION}>
                {getFieldDecorator('description', {
                  rules: [{ required: status?.name === 'Approved by Julia' }]
                })(
                  <MyJoditEditor
                    key="description-editor"
                    height={200}
                    onChange={value => setFieldsValue({ description: value })}
                  />
                )}
              </MyFormItem>
            </Col>
          )}
          <Col lg={24}>
            <MyFormItem>
              {getFieldDecorator('visibleToTeacher', {
                rules: [{ required: false }]
              })(<Checkbox>{strings.MAKE_IT_VISIBLE_TO_THE_TEACHER}</Checkbox>)}
            </MyFormItem>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export const AddHistory = Form.create<AddHistoryProps>({})(AddHistoryTemplate);

export default { AddHistory };
