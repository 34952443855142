import React, { useState, useEffect } from 'react';
import { Table, Typography } from 'antd';
import { Loader } from 'Global/components/Loader/Loader';
import { ILesson } from 'types/courses';
import { columns } from './columns';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Title } = Typography;

interface LessonPlanProps {
  groupId: number;
}

export const LessonPlan = (props: LessonPlanProps) => {
  const [strings] = useLanguageContext();
  const [lessons, setLessons] = useState<ILesson[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { groupId } = props;
  const coursesRepository = useCoursesRepository();

  /**
   * @description Возвращает те уроки, у которых присутствует дата
   * @param {lessonsHasDate} lessons
   * @return {ILesson[]}
   */
  const lessonsHasDate = (lessons: ILesson[]): ILesson[] => lessons.filter((lesson: ILesson) => lesson.date !== '');

  const fetchLessons = async (groupId: number): Promise<any> => {
    setLoading(true);
    try {
      const { data } = await coursesRepository.getLessonsPlanByGroup(groupId);
      setLessons(lessonsHasDate(data));
    } catch {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLessons(groupId);
  }, [groupId]);

  return (
    <Loader spinning={loading}>
      <Title level={4}>{strings.LESSON_PLAN}</Title>
      <Table dataSource={lessons} columns={columns(strings)} pagination={false} bordered rowKey="id" />
    </Loader>
  );
};

export default { LessonPlan };
