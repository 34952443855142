import React, { useEffect, useState } from 'react';
import { Button, Col, Flex, Form, Input, Modal, Row, Select, Spin } from 'antd';
import { useTemplatesRepository } from 'repos/TemplatesRepository';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Branch } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useUserContext } from 'contexts/UserContext';
const { Option } = Select;

interface IModalContractTemplateForm {
  id?: number;
  hasShowModal: boolean;
  setHasShowModal: (val: boolean) => void;
  handler: () => Promise<any>;
}
interface IContractTemplatePostParams {
  name: string;
  branch: number;
}
export const ModalContractTemplateForm: React.FC<IModalContractTemplateForm> = ({
  handler,
  id,
  hasShowModal,
  setHasShowModal
}: IModalContractTemplateForm) => {
  const [strings] = useLanguageContext();

  const [form] = Form.useForm();
  const [user] = useUserContext();
  const hasTopManagerRole = user?.hasRoles?.topManager;
  const templatesRepository = useTemplatesRepository();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const { branches } = useGlobalCollectionsContext();

  const handleSubmit = async (values: IContractTemplatePostParams): Promise<any> => {
    setHasLoading(true);
    try {
      if (id) {
        await templatesRepository.editContractTemplate(id, values);
      } else {
        await templatesRepository.addContractTemplate(values);
      }
      handler();
      closeModal();
    } catch {
    } finally {
      setHasLoading(false);
    }
  };
  const closeModal = (): void => {
    setHasShowModal(false);
  };
  const getTemplateInfo = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await templatesRepository.getContractTemplateById(id);
      form.setFieldsValue({ name: data?.name, branch: data?.branch?.id });
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    if (hasShowModal) {
      if (id) {
        getTemplateInfo();
      } else {
        form.setFieldsValue({ branch: user?.branch?.id });
      }
    }
  }, [hasShowModal]);

  return (
    <Modal
      title={id ? strings.EDIT : strings.NEW_TEMPLATE}
      footer={false}
      open={hasShowModal}
      onCancel={closeModal}
      style={{ top: 20 }}
      bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
      width={400}
      destroyOnClose={true}
    >
      <Spin spinning={hasLoading}>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Row gutter={[10, 10]}>
            <Col lg={24} xs={24}>
              <Form.Item name="name" label={strings.NAME} rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col lg={24} xs={24}>
              <Form.Item name="branch" label={strings.BRANCH} rules={[{ required: true }]}>
                <Select disabled={!hasTopManagerRole}>
                  {branches.map((branch: Branch) => (
                    <Option key={branch.id} value={branch.id}>
                      {branch.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Flex justify="end" gap={10}>
            <Button key="back" onClick={closeModal}>
              {strings.CANCEL}
            </Button>
            <Button type="primary" htmlType="submit">
              {id ? strings.EDIT : strings.ADD}
            </Button>
          </Flex>
        </Form>
      </Spin>
    </Modal>
  );
};
export default ModalContractTemplateForm;
