import React, { Fragment, useState } from 'react';
import { Table, Modal, message, Button, Flex } from 'antd';
import { Courses } from 'api/Courses';

import { Access as IAccess } from 'types/courses';

import { getColumns } from './columns';
import { useLanguageContext } from 'contexts/LanguageContext';
import { AccessFormModal } from './AccessFormModal';

interface AccessProps {
  access: IAccess[];
  originalCourseId: number | string;
  getOriginalCourseById: () => Promise<any>;
}

/**
 * @description Access component
 * @param {AccessProps} component props
 * @return {React.ReactNode}
 */

export const Access = ({ access, originalCourseId, getOriginalCourseById }: AccessProps) => {
  const [strings] = useLanguageContext();
  const [{ confirm }, contextHolder] = Modal.useModal();
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);

  const handleDeleteAccessItem = (userId: number): void => {
    confirm({
      title: strings.ARE_YOU_SURE,
      okText: strings.DELETE,
      okType: 'danger',
      async onOk() {
        try {
          const {
            data: { status }
          } = await Courses.deleteOriginalCoursesAccess(originalCourseId, userId);
          status === 'success' && message.success(strings.USER_WAS_SUCCESSFULLY_DELETED, 4);
          getOriginalCourseById();
        } catch {
          message.error(strings.SORRY_ERROR);
        }
      }
    });
  };

  const handleChangeAccessLevel = async (userId: number, level: string): Promise<any> => {
    await Courses.changeOriginalCoursesAccessLevel(userId, { level: level }).catch(() =>
      message.error(strings.SORRY_ERROR)
    );
  };

  const columns = getColumns({ handleDeleteAccessItem, handleChangeAccessLevel, strings });

  const computedAccess = access.filter((record: IAccess) => record.user);

  return (
    <Fragment>
      {contextHolder}
      <AccessFormModal
        handler={getOriginalCourseById}
        hasShowModal={hasShowModal}
        setHasShowModal={setHasShowModal}
        originalCourseId={originalCourseId}
      />
      <Flex justify="end" style={{ marginBottom: 20 }}>
        <Button type="primary" onClick={() => setHasShowModal(true)}>
          {strings.ADD_NEW_ACCESS}
        </Button>
      </Flex>
      <Table dataSource={computedAccess} columns={columns} bordered pagination={false} />
    </Fragment>
  );
};
