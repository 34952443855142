import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { useUserContext } from 'contexts/UserContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useLanguageContext } from 'contexts/LanguageContext';
import { FeedbackModal } from 'Global/modules/Support/Modal';
import { Button, Table, Tabs } from 'antd';
import Finance from 'api/Families/finances';
import { ExtraBalance, Operation } from 'types/Finances';
import { getExtraBalanceColumns, getFinanceColumn } from './columns';
import { isEmpty } from 'lodash';
import {useFamilyRepository} from "../../repos/FamilyRepository";
const { TabPane } = Tabs;
const { Column } = Table;

/**
 * @description Страница финансов
 * @param {User} user объект юзера
 * @return {React.ReactNode}
 */
export const ParentFinance = () => {
  const [strings] = useLanguageContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [extraBalance, setExtraBalance] = useState<ExtraBalance[]>([]);
  const [currentFinanceOperations, setCurrentFinanceOperations] = useState<Operation[]>([]);
  const [previousFinanceOperations, setPreviousFinanceOperations] = useState<Operation[]>([]);
  const [hasShowSupportModal, setHasShowSupportModal] = useState<boolean>(false);
  const [user] = useUserContext();
  const { goBack } = useHistory();
  const hideParentFinance = user?.branch?.hideParentFinance;
  const isCabinetLocked = user?.isCabinetLocked;
  const familyRepository = useFamilyRepository();

  const getSubtitle = () => (
    <span>
      {strings.CURRENT_BALANCE}: {user?.balance}
    </span>
  );

  // Если отключены финансы у родителя то скрываем раздел финансов.
  if (hideParentFinance && !isCabinetLocked) {
    return <Redirect to="/" />;
  }
  const getFinance = async () => {
    setHasLoading(true);
    try {
      const {
        data: { financesOperations }
      } = await familyRepository.getFamilyFinance();
      const { currentMonth, previous } = financesOperations;
      setCurrentFinanceOperations(currentMonth?.operations);
      setPreviousFinanceOperations(previous?.operations);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  const getExtraFinance = async () => {
    try {
      const { data } = await Finance.getExtraBalance();
      const balance = data
        .filter(item => item.balance !== 0 || item.deposit !== 0)
        .map((item, id) => ({ ...item, id, students: [...item.children] }));
      balance.forEach(item => delete item.children);
      setExtraBalance(balance);
    } catch {}
  };

  useEffect(() => {
    if (user) {
      getFinance();
      getExtraFinance();
    }
  }, [user]);

  return (
    <ErrorBoundary>
      <Container>
        <FeedbackModal onHide={() => setHasShowSupportModal(false)} hasShow={hasShowSupportModal} />
        <StPageHeader
          onBack={goBack}
          title={strings.FINANCE}
          subTitle={getSubtitle()}
          extra={
            <Button type="primary" onClick={() => setHasShowSupportModal(true)}>
              {strings.REPORT_AN_ERROR}
            </Button>
          }
        />
        {!isEmpty(extraBalance) && (
          <Table dataSource={extraBalance} pagination={false} style={{ marginBottom: 20 }}>
            {getExtraBalanceColumns(strings).map(col => (
              <Column key={col.key} {...col} />
            ))}
          </Table>
        )}
        <Tabs>
          <TabPane tab={strings.CURRENT_MONTH} key="current">
            <Table loading={hasLoading} dataSource={currentFinanceOperations} columns={getFinanceColumn(strings)} />
          </TabPane>
          <TabPane tab={strings.PREVIOUS_MONTHS} key="previous">
            <Table loading={hasLoading} dataSource={previousFinanceOperations} columns={getFinanceColumn(strings)} />
          </TabPane>
        </Tabs>
      </Container>
    </ErrorBoundary>
  );
};

export default { ParentFinance };
