import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Row, Col, Select, Input, message } from 'antd';
import { Branch } from 'types';
import { isEmpty, omitBy, isUndefined } from 'lodash';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { CRM } from 'api/CRM';
import { GetInventoriesParams } from 'types/CRM/materials';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { FilterButtons } from 'Global/components/FilterButtons';
const { Option } = Select;

interface TeachingMaterialsFilterProps {
  form: WrappedFormUtils;
  onFilter: (params?: GetInventoriesParams) => Promise<any>;
  defFilterValues?: GetInventoriesParams;
}

/**
 * @description Фильтр для инвентарей
 * @param {FilterProps} props
 * @returns {React.ReactNode}
 */
export const FilterTemplate = (props: TeachingMaterialsFilterProps) => {
  const {
    form: { getFieldDecorator, validateFieldsAndScroll, resetFields },
    defFilterValues,
    onFilter
  } = props;
  const [strings] = useLanguageContext();

  const [inventoryTypes, setInventoryTypes] = useState<{ id: number; name: string }[]>([]);
  const { branches } = useGlobalCollectionsContext();
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);

  const { locationSearchParams } = useLocationSearchParams();
  const { active, branch, type, name } = locationSearchParams || {};

  /**
   * @description Получить type
   * @returns {Promise<any>}
   */
  const fetchInventoryTypes = async (): Promise<void> => {
    try {
      const { data } = await CRM.getInventoryTypes();
      setInventoryTypes(data);
    } catch {
      message.error(strings.SORRY_SOMETHING_WENT_WRONG_WHEN_FETCHING_INVENTORY_TYPES);
    }
  };

  /**
   * @description Submit формы
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values): Promise<any> => {
      if (!errors) {
        // Удаляем ключи со значением undefined
        const fields = omitBy(values, isUndefined);
        try {
          setHasSubmitLoading(true);
          await onFilter(fields);
        } finally {
          setHasSubmitLoading(false);
        }
      }
    });
  };

  /**
   * @description Очистка фильтра
   * @return {Promise<any>}
   */
  const handleClear = async (): Promise<any> => {
    resetFields();
    await onFilter(defFilterValues);
  };

  useEffect(() => {
    if (branches.isNotEmpty) {
      if (isEmpty(locationSearchParams)) {
        onFilter(defFilterValues);
      } else {
        onFilter(locationSearchParams);
      }
    }
  }, [branches]);

  useEffect(() => {
    fetchInventoryTypes();
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={[10, 10]}>
        {branches.length > 1 && (
          <Col lg={6}>
            {getFieldDecorator('branch', {
              initialValue: branch
            })(
              <Select placeholder={strings.BRANCH}>
                {branches &&
                  branches.map((branch: Branch) => (
                    <Option value={branch.id} key={branch.id}>
                      {branch.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Col>
        )}
        <Col lg={6}>
          {getFieldDecorator('active', {
            initialValue: active,
            rules: [{ required: false }]
          })(
            <Select placeholder={strings.ACTUAL}>
              <Option value={0} key="0">
                {strings.ACTUAL}
              </Option>
              <Option value={1} key="1">
                {strings.NOT_ACTUAL}
              </Option>
              <Option value={2} key="2">
                {strings.ALL}
              </Option>
            </Select>
          )}
        </Col>
        <Col lg={6}>
          {getFieldDecorator('type', {
            initialValue: type,
            rules: [{ required: false }]
          })(
            <Select placeholder={strings.TYPE}>
              <Option value="">{strings.ALL}</Option>
              {inventoryTypes.map((type: Branch) => (
                <Option value={type.id} key={type.id}>
                  {type.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col lg={6}>
          {getFieldDecorator('name', {
            initialValue: name,
            rules: [{ required: false }]
          })(<Input placeholder={strings.TITLE} />)}
        </Col>
      </Row>
      <FilterButtons handleClear={handleClear} hasSubmitLoading={hasSubmitLoading} />
    </Form>
  );
};

export const Filter = Form.create<TeachingMaterialsFilterProps>({})(FilterTemplate);
export default { Filter };
