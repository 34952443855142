import styled from 'styled-components';

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const FilterWrapper = styled.div`
  display: flex;

  & .ant-select {
    width: 240px;
  }

  & .ant-btn {
    margin-inline-start: 12px;
  }
`;

export const StFilterButtons = styled.div`
  & .ant-btn {
    margin-inline-start: 12px;

    &:first-child {
      margin-inline-start: 0;
    }
  }
`;

export const SupergroupContainer = styled.div`
  padding: 0 6px;
`;

export const GroupItem = styled.div`
  border-bottom: 1px solid #eee;
  padding-bottom: 12px;
  margin-bottom: 12px;
  font-size: 16px;
  display: flex;
  align-items: center;
`;

export const GroupItems = styled.div`
  ${GroupItem} {
    &:last-child {
      margin-bottom: 0;
      padding: 0;
      border-bottom: none;
    }
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  margin-bottom: 12px;

  & .ant-select {
    width: 340px;
  }

  & .ant-btn {
    margin-inline-start: 10px;
  }
`;

export const GroupLink = styled.div`
  flex: 1;
`;

export default {
  FilterContainer,
  FilterWrapper,
  GroupItem,
  GroupItems,
  SupergroupContainer,
  SelectContainer,
  GroupLink
};
