import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useUserAccessRepository } from 'repos/UserAccessRepostirory';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, message, Modal, Row, Select, Tag } from 'antd';
import { ACCESS_TYPES_MAPPING } from '../../../../Website/CRM/AdminAccess/AdminAccessFilter';
import { ALL_BRANCHES_ID } from './index';
import { UserAccessObject } from 'types/AdminAcess';

const StyledUserAccessItem = styled.div`
  margin-top: 10px;
  box-shadow: 0 0 46px -15px rgb(0 0 0 / 12%);
  background: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  width: 100%;
`;

const StyledAccessName = styled.span<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? '#CC1E44' : '#333')};
  cursor: pointer;
`;

interface IUserAccessItemProps {
  accessId: number;
  userId: number;
  refetchAccess: () => Promise<any>;
  name: string;
  isActive: boolean;
  accessObjects: UserAccessObject[];
}
const { Option } = Select;

export function UserAccessItem(props: IUserAccessItemProps) {
  const userAccessRepo = useUserAccessRepository();
  const [strings] = useLanguageContext();
  const { branches } = useGlobalCollectionsContext();
  const { accessId, userId, refetchAccess, name, isActive, accessObjects } = props;
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const [currentBranch, setCurrentBranch] = useState<number>(null);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const currentBranchesList = useMemo(() => accessObjects.map(accessObject => accessObject.branch.id), [accessObjects]);

  const onDeleteAccess = async (accessId: number): Promise<any> => {
    try {
      await userAccessRepo.deleteUserAccess(accessId);
      await refetchAccess();
      message.success(strings.SUCCESSFULLY_DELETED);
    } catch (error) {}
  };

  const onAddAccess = async (accessId: number, branchId: number): Promise<any> => {
    try {
      setHasLoading(true);
      await userAccessRepo.createUserAccess({
        accessObject: accessId,
        branch: branchId,
        user: userId
      });
      await refetchAccess();
      setHasShowModal(false);
      message.success(strings.SUCCESSFULLY_CREATED);
    } finally {
      setHasLoading(false);
    }
  };

  const handleChange = (branchId: number) => {
    setCurrentBranch(branchId);
  };

  return (
    <StyledUserAccessItem>
      <Modal title={strings.CREATE_ACCESS} open={hasShowModal} onCancel={() => setHasShowModal(false)} footer={false}>
        <Row gutter={[10, 10]} style={{ marginTop: 20 }}>
          <Col lg={14}>
            <Select placeholder={strings.CHOOSE_BRANCH} onChange={handleChange}>
              <Option
                key={ALL_BRANCHES_ID}
                value={ALL_BRANCHES_ID}
                disabled={currentBranchesList.includes(ALL_BRANCHES_ID)}
              >
                ALL
              </Option>
              {branches?.map(branch => (
                <Option key={branch.id} value={branch.id} disabled={currentBranchesList.includes(branch.id)}>
                  {branch.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col lg={10}>
            <Button
              loading={hasLoading}
              type="primary"
              size="large"
              onClick={() => onAddAccess(accessId, currentBranch)}
              disabled={!currentBranch}
            >
              {strings.ADD_NEW_ACCESS}
            </Button>
          </Col>
        </Row>
      </Modal>
      <Row>
        <Col lg={12}>
          <StyledAccessName isActive={isActive}>{ACCESS_TYPES_MAPPING[name]}</StyledAccessName>
        </Col>
        <Col lg={12} style={{ display: 'flex' }}>
          {accessObjects.map(access => (
            <Tag color="blue" closable onClose={() => onDeleteAccess(access.id)} key={access.id}>
              {access?.branch?.name}
            </Tag>
          ))}
          <Button onClick={() => setHasShowModal(true)} size="small" icon={<PlusOutlined />} style={{ fontSize: 14 }} />
        </Col>
      </Row>
    </StyledUserAccessItem>
  );
}
