import React, { useMemo, useContext } from 'react';
import { httpService } from 'api/core';
import { getAllFormData } from '../helpers';
import { IStagesGetParams, IStagePostParams } from 'types/global';

const StagesRepositoryContext = React.createContext<IStagesRepository>(null);
interface IStagesRepository {
  getStages: (params: IStagesGetParams) => Promise<any>;
  getStageById: (id: number | string) => Promise<any>;
  editStage: (id: number | string, data: IStagePostParams) => Promise<any>;
  createStage: (data: IStagePostParams) => Promise<any>;
  deleteStage: (id: number | string) => Promise<any>;
}

class StagesRepository implements IStagesRepository {
  getStages(params: IStagesGetParams) {
    return httpService.get('/api/v1/stages', params, null, true);
  }

  getStageById(id: number | string) {
    return httpService.get(`/api/v1/stages/${id}`, null, null, true);
  }

  editStage(id: number | string, data: IStagePostParams) {
    const params = getAllFormData(data);
    return httpService.put(`/api/v1/stages/${id}`, data, null, null, true);
  }

  createStage(data: IStagePostParams) {
    return httpService.post(`/api/v1/stages`, data, null, null, true);
  }

  deleteStage(id: number | string) {
    return httpService.delete(`/api/v1/stages/${id}`, null, null, true);
  }
}

export const useStagesRepository = () => {
  const service = useContext(StagesRepositoryContext);

  if (!service) {
    throw new Error('Stages repository is unavailable');
  }

  return service;
};

export const StagesRepositoryProvider = props => {
  const service = useMemo(() => new StagesRepository(), []);

  return <StagesRepositoryContext.Provider value={service}>{props.children}</StagesRepositoryContext.Provider>;
};
