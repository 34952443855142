import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select, Col, Row, Button, DatePicker } from 'antd';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { Branch } from 'types';
import dayjs from 'dayjs';
import { dateFormat, dateFormatForBackend } from 'helpers/dates';
import { isEmpty, pickBy, identity } from 'lodash';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { momentFormatValues } from '../../../helpers/momentFormatValues';
import { FilterButtons } from '../../../Global/components/FilterButtons';

const { Option } = Select;

interface FilterProps {
  form: WrappedFormUtils;
  fetchStudentsPayingReport: (params: { startAt: string; endAt: string; branch?: number }) => Promise<any>;
}

/**
 * @description Filter
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const FilterTemplate = (props: FilterProps) => {
  const [strings] = useLanguageContext();
  const {
    form: { getFieldDecorator, validateFieldsAndScroll, resetFields },
    fetchStudentsPayingReport
  } = props;

  const { branches } = useGlobalCollectionsContext();
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);

  /**
   * @description Form Submit
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values) => {
      if (isEmpty(errors)) {
        values = pickBy(momentFormatValues(values), identity);
        try {
          setHasSubmitLoading(true);
          await fetchStudentsPayingReport(values);
        } finally {
          setHasSubmitLoading(false);
        }
      }
    });
  };

  /**
   * @description Очистка фильтра
   */
  const handleClear = async (): Promise<any> => {
    resetFields();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={[10, 10]}>
        <Col lg={5}>
          {getFieldDecorator('startAt', {
            rules: [{ required: true }],
            initialValue: dayjs().subtract(8, 'days')
          })(<DatePicker format={dateFormat} placeholder={strings.FROM} />)}
        </Col>
        <Col lg={5}>
          {getFieldDecorator('endAt', {
            rules: [{ required: true }],
            initialValue: dayjs().subtract(1, 'days')
          })(<DatePicker format={dateFormat} placeholder={strings.TO} />)}
        </Col>

        <Col lg={5}>
          {getFieldDecorator('branch', {
            rules: [{ required: false }]
          })(
            <Select placeholder={strings.BRANCH}>
              <Option value={undefined}>&nbsp;</Option>
              {branches.map((branch: Branch) => (
                <Option value={branch.id} key={branch.id}>
                  {branch.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <FilterButtons handleClear={handleClear} isSameRow={true} hasSubmitLoading={hasSubmitLoading} />
      </Row>
    </Form>
  );
};

export const Filter = Form.create<FilterProps>({})(FilterTemplate);
export default { Filter };
