import React from 'react';
import { Branch } from 'types';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { Tag } from 'antd';

export const getColumns = strings => [
  {
    title: strings.NAME,
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: strings.BRANCH,
    dataIndex: 'branch',
    key: 'branch',
    render: (branch: Branch) => branch?.name
  },
  {
    title: strings.DEFAULT,
    dataIndex: 'default',
    key: 'default',
    width: 100,
    render: (val: boolean) => (val ? <CheckCircleTwoTone style={{ fontSize: 24 }} twoToneColor="#52c41a" /> : null)
  },
  {
    title: strings.ACTIVE,
    dataIndex: 'isActive',
    key: 'current',
    width: 100,
    render: (isActive: boolean) =>
      isActive ? <CheckCircleTwoTone style={{ fontSize: 24 }} twoToneColor="#52c41a" /> : null
  }
];

export default { getColumns };
