import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { Stage } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useStagesRepository } from 'repos/StagesRepository';

const { Option } = Select;

type AsyncSelectProps = {
  mode?: 'multiple' | 'tags';
  value?: number;
  branch?: number;
  onChange?: (value: number) => void;
  placeholder?: string;
};

const StageSelect: React.FC<AsyncSelectProps> = ({ value, mode, branch, onChange, placeholder }) => {
  const { stages } = useGlobalCollectionsContext();
  const [strings] = useLanguageContext();
  const stagesRepository = useStagesRepository();
  const [options, setOptions] = useState<Stage[]>([]);
  const [hasLoading, setHasLoading] = useState(false);

  const handleChange = newValue => {
    onChange(newValue);
  };

  useEffect(() => {
    //в фильтре в расписании и в Materials All branches имеет значение -1
    if (branch && branch !== -1) {
      (async () => {
        const branchIds = typeof branch === 'string' || typeof branch === 'number' ? [branch] : branch;
        try {
          setHasLoading(true);
          const { data } = await stagesRepository.getStages({ branch: branchIds });
          setOptions(data);
        } finally {
          setHasLoading(false);
        }
      })();
    } else {
      setOptions(stages);
    }
  }, [branch, stages]);

  return (
    <Select
      value={value}
      onChange={handleChange}
      mode={mode}
      loading={hasLoading}
      placeholder={placeholder || strings.STAGE}
      maxTagCount="responsive"
      allowClear
    >
      {options
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((stage: Stage) => (
          <Option key={stage.id} value={stage.id}>
            {stage.name}
          </Option>
        ))}
    </Select>
  );
};

export default StageSelect;
