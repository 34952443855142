import { UnitListParams } from 'types/API/education';
import { httpService } from 'api/core';

/**
 * @description Получение юнит списка
 * @param {object} params
 * @return {Promise<any>}
 */
const getUnitList = (params: UnitListParams): Promise<any> =>
  httpService.get(`/api/v1/originalcourseunits`, params, null, true);

/**
 * @description Удаление юнита из оригинального курса
 * @param {number} originalCourseId
 * @param {number} unitId
 * @return {Promise<any>}
 */
const deleteOriginalCourseUnit = (originalCourseId: number | string, unitId: number): Promise<any> =>
  httpService.delete(`/api/originalcourses/${originalCourseId}/units/${unitId}`);

/**
 * @description Редактирование имени юнита в оригинальном курсе
 * @param {number} originalCourseId
 * @param {number} unitId
 * @param {string} unitName
 * @return {Promise<any>}
 */
const editOriginalCourseUnitName = (
  originalCourseId: number | string,
  unitId: number,
  unitName: string
): Promise<any> => httpService.put(`/api/originalcourses/${originalCourseId}/units/${unitId}`, { name: unitName });

/**
 * @description Активация и деактивация юнита
 * @param {number} originalCourseId
 * @param {number} unitId
 * @return {Promise<any>}
 */
const toggleOriginalCourseUnit = (originalCourseId: number | string, unitId: number): Promise<any> =>
  httpService.get(`/api/originalcourses/${originalCourseId}/units/${unitId}/toggle`);

/**
 * @description Поиск юнита
 * @param {string} name
 * @return {Promise<any>}
 */
const searchUnit = (name: string): Promise<any> =>
  httpService.get(`/api/v1/units/search?name=${name}`, null, null, true);

/**
 * @description Перенос урока в другой юнит
 * @param {number} originalCourseId
 * @param {number} originalLessonId
 * @param {number} unitId
 * @return {Promise<any>}
 */
const moveLessonAnotherUnit = (
  originalCourseId: number | string,
  originalLessonId: number | string,
  unitId: number
): Promise<any> =>
  httpService.post(`/api/originalcourses/${originalCourseId}/originallesson/${originalLessonId}/move`, {
    unitId
  });

export default {
  getUnitList,
  editOriginalCourseUnitName,
  toggleOriginalCourseUnit,
  deleteOriginalCourseUnit,
  searchUnit,
  moveLessonAnotherUnit
};
