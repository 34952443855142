import React from 'react';
import { dateFormat } from 'helpers/dates';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

export const columns = strings => [
  {
    title: strings.DATE,
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: Date) => <p>{dayjs(date).format(dateFormat)}</p>
  },
  {
    title: strings.ACTION,
    dataIndex: 'action',
    key: 'action'
  },
  {
    title: strings.LESSON,
    dataIndex: 'lesson',
    key: 'lesson',
    render: (id: number) => <Link to={`/le/${id}`}>{id}</Link>
  },
  {
    title: strings.USER,
    dataIndex: 'createdBy',
    key: 'createdBy',
    render: createdBy => (
      <p>
        {createdBy?.role}
        <br />
        {createdBy?.user?.name} {createdBy?.user?.surname}
      </p>
    )
  },
  {
    title: strings.DATE,
    dataIndex: 'data',
    key: 'data'
  }
];

export default { columns };
