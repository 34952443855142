import React from 'react';
import dayjs from 'dayjs';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { dateFormat } from 'helpers/dates';
import { Doubt, TeacherFinanceItem } from 'types/Teachers/finance';

const { Text } = Typography;

const renderContent = (value: any, row: TeacherFinanceItem) => {
  const obj: any = {
    children: value,
    props: {}
  };
  if (row.allSum) {
    obj.props.colSpan = 0;
  }
  return obj;
};

const renderDoubtContent = (
  doubt: Doubt,
  id: number,
  setOperationId: (id: number) => void,
  setIsModalVisible: (isModalVisible: boolean) => void,
  strings
) => {
  if (doubt) {
    const { comment, isSolved } = doubt;
    const styleIcon = { fontSize: 22, paddingInlineEnd: 10 };

    return isSolved ? (
      <p>
        <CheckCircleTwoTone twoToneColor="#52c41a" style={styleIcon} />
        {comment}
      </p>
    ) : (
      <p>
        <CloseCircleTwoTone style={styleIcon} twoToneColor="#f5222d" />
        {comment}
      </p>
    );
  }

  return (
    <Button
      type="primary"
      onClick={() => {
        setOperationId(id);
        setIsModalVisible(true);
      }}
    >
      {strings.REPORT_AN_ERROR}
    </Button>
  );
};

export const getColumns = (
  strings,
  isDoubt: boolean,
  locale: string,
  setOperationId?: (id: number) => void,
  setIsModalVisible?: (isModalVisible: boolean) => void
) => [
  {
    title: strings.DATE,
    key: 'date',
    dataIndex: 'date',
    width: 140,
    render: (date: string) => <Text>{dayjs(date).format(dateFormat)}</Text>
  },
  {
    title: strings.SUM,
    key: 'sum',
    dataIndex: 'sum',
    width: 140,
    render: renderContent
  },
  {
    title: strings.OPERATION,
    key: 'product',
    dataIndex: 'product.name',
    width: 250,
    render: renderContent
  },
  {
    title: strings.COMMENT,
    key: 'comment',
    dataIndex: 'comment',
    render: renderContent
  },
  isDoubt
    ? {
        title: strings.DOUBT,
        dataIndex: 'doubt',
        key: 'doubt',
        render: (doubt: Doubt, { id }: TeacherFinanceItem) =>
          renderDoubtContent(doubt, id, setOperationId, setIsModalVisible, strings)
      }
    : {}
];

export const getGlobalDoubtsColumns = strings => [
  {
    title: strings.DATE,
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: string) => date && dayjs(date).format(dateFormat)
  },
  {
    title: strings.COMMENT,
    dataIndex: 'comment',
    key: 'comment'
  }
];

export default { getGlobalDoubtsColumns, getColumns };
