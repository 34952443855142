/**
 * @description Создает из тестовых ссылок, html теги <a href="">text</a>
 * @param {string} text
 * @return {RegExp}
 */
export const createTextLinks = (text: string, hasBlock?: boolean) => {
  if (!text) return '';
  return text
    .replace(/<br \/>/g, '<br/>')
    .replace(/([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi, function (match, space, url) {
      let hyperlink = url;
      if (hyperlink.includes('<br/>')) {
        hyperlink = hyperlink.replace('<br/>', '');
      }

      if (!hyperlink.match('^https?://')) {
        hyperlink = 'http://' + hyperlink;
      }

      if (hasBlock) {
        return space + '<div><a href="' + hyperlink + '">' + url + '</a></div>';
      }

      return space + '<a href="' + hyperlink + '">' + url + '</a>';
    });
};
