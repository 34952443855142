import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Col,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
  Typography,
  Form,
  FormListFieldData
} from 'antd';
import { Branch, Subject } from 'types';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { CourseGroup } from 'types/education';
import { useUserContext } from 'contexts/UserContext';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { RateSelect, StageSelect, WageSelect } from 'Global/components/FormComponents';
import { StyledSeparator } from 'Global/styles';
import { Courses } from 'api';
import { Link } from 'react-router-dom';
import { NamePath } from 'antd/lib/form/interface';

const { Option } = Select;
const { Text } = Typography;

interface GroupCourseProps extends React.ComponentProps<any> {
  originalCourseId?: number | string;
  courseGroupId?: number | string;
  prefix?: string;
  field?: FormListFieldData;
  index?: number;
  nameList?: string;
}

/**
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const GroupForm = ({ field, index, nameList, originalCourseId, courseGroupId, prefix }: GroupCourseProps) => {
  const form = Form.useFormInstance();
  const [user] = useUserContext();
  const hasTopManager = user?.hasRoles?.topManager;
  const [strings] = useLanguageContext();
  const { branches, subjects } = useGlobalCollectionsContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [courseGroup, setCourseGroup] = useState<CourseGroup>();
  const [selectedBranch, setSelectedBranch] = useState<number>(user?.branch?.id);

  const systemName = prefix?.length ? `${prefix}_${courseGroup?.subject.name.substring(0, 3)}` : '';

  const coursesRepository = useCoursesRepository();

  const getName = (name): NamePath => (field ? [field.name, name] : name);

  const handleChangeBranch = (branchId: number) => {
    setSelectedBranch(branchId);
    //при смене филиала очищаем поля Rates, Conductor wage, Conductor wage
    const values = {
      assistentCost: null,
      cost: null,
      price: null
    };
    if (nameList) {
      form.setFieldsValue({
        [nameList]: {
          [index]: values
        }
      });
    } else {
      form.setFieldsValue(values);
    }
  };

  /**
   * @description Информация о курсе группе при создании новой группы
   * @param {number} originalCourseId
   * @return {Promise<any>}
   */
  const getCourseGroupInit = async (originalCourseId: unknown): Promise<any> => {
    setLoading(true);
    try {
      const { data } = await Courses.getCourseGroupInitById(originalCourseId);
      setCourseGroup(data);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const getCourseGroupById = async (id: number | string): Promise<any> => {
    setLoading(true);
    try {
      const { data } = await coursesRepository.getCourseGroupById(id);
      setCourseGroup(data);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (courseGroup) {
      setSelectedBranch(courseGroup?.branch?.id);
      const initValues = {
        branch: courseGroup?.branch?.id,
        originalCourse: courseGroup?.originalCourse?.id,
        name: courseGroup?.name || courseGroup?.originalCourse?.name,
        name_sys: courseGroup?.groupName || systemName,
        stage: courseGroup?.stage?.id,
        subject: courseGroup?.subject?.id,
        weekCountNeedFeedbackForParents: courseGroup?.weekCountNeedFeedbackForParents,
        minStudentsToUseCoefficient: courseGroup?.minStudentsToUseCoefficient,
        groupSizeCoefficient: courseGroup?.groupSizeCoefficient,
        is_active: courseGroup?.isActive,
        duration: courseGroup?.duration,
        duration_measure: courseGroup?.durationMeasure,
        week_lessons_count: courseGroup?.originalCourse?.weekLessonsCount,
        price: courseGroup?.price?.id,
        cost: courseGroup?.cost?.id,
        assistentCost: courseGroup?.assistentCost?.id,
        ignoreDiscount: courseGroup?.ignoreDiscount,
        monthRate: courseGroup?.monthRate,
        monthRateNoDiscount: courseGroup?.monthRateNoDiscount
      };

      if (nameList) {
        form.setFieldsValue({ [nameList]: { [index]: initValues } });
      } else {
        form.setFieldsValue(initValues);
      }
    }
  }, [courseGroup]);

  useEffect(() => {
    if (originalCourseId) {
      getCourseGroupInit(originalCourseId);
    }
    if (courseGroupId) {
      getCourseGroupById(courseGroupId);
    }
  }, []);

  return (
    <Spin spinning={loading} tip={strings.LOADING_DATA}>
      <Text strong>
        {strings.BASED_ON_THE_ORIGINAL_COURSE}{' '}
        {courseGroup?.originalCourse ? (
          <Link to={`/original_courses/${courseGroup?.originalCourse?.id}`}>{courseGroup?.originalCourse?.name}</Link>
        ) : (
          '-'
        )}
      </Text>
      <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
        <Form.Item {...field} name={getName('originalCourse')} hidden={true}>
          <Input />
        </Form.Item>
        <Col xs={24} lg={6}>
          <Form.Item
            {...field}
            name={getName('name')}
            label={strings.NAME}
            rules={[{ required: true, message: `${strings.NAME} ${strings.IS_REQUIRED}` }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item
            {...field}
            name={getName('name_sys')}
            label={strings.SYSTEM_NAME}
            rules={[{ required: true, message: `${strings.SYSTEM_NAME} ${strings.IS_REQUIRED}` }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={hasTopManager ? 24 : 0} lg={hasTopManager ? 6 : 0}>
          <Form.Item
            hidden={!hasTopManager}
            {...field}
            name={getName('branch')}
            initialValue={user?.branch?.id}
            label={strings.BRANCH}
            rules={[{ required: true, message: `${strings.BRANCH} ${strings.IS_REQUIRED}` }]}
          >
            <Select placeholder={strings.BRANCH} allowClear onChange={handleChangeBranch}>
              {branches.map((branch: Branch) => (
                <Option value={branch.id} key={branch.id}>
                  {branch.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item
            {...field}
            name={getName('stage')}
            label={strings.STAGE}
            rules={[{ required: true, message: `${strings.STAGE} ${strings.IS_REQUIRED}` }]}
          >
            <StageSelect branch={selectedBranch} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item
            {...field}
            name={getName('subject')}
            label={strings.SUBJECT}
            rules={[{ required: true, message: `${strings.SUBJECT} ${strings.IS_REQUIRED}` }]}
          >
            <Select placeholder={strings.SUBJECT}>
              {subjects.map((sub: Subject) => (
                <Option key={sub.id} value={sub.id}>
                  {sub.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item
            {...field}
            name={getName('weekCountNeedFeedbackForParents')}
            label={strings.MAX_COUNT_OF_WEEKS_BETWEEN_FEEDBACK_FOR_PARENTS}
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item
            {...field}
            name={getName('minStudentsToUseCoefficient')}
            label={strings.MINIMUM_NUMBER_OF_STUDENTS_TO_USE_COEFFICIENT}
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item {...field} name={getName('groupSizeCoefficient')} label={strings.GROUP_SIZE_COEFFICIENT}>
            <InputNumber />
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item {...field} name={getName('is_active')} valuePropName="checked" style={{ marginTop: 30 }}>
            <Checkbox>{strings.CURRENT}</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <StyledSeparator />
      <Row gutter={[10, 10]}>
        <Col xs={24} lg={8}>
          <Form.Item
            {...field}
            name={getName('duration')}
            label={strings.DURATION}
            rules={[{ required: true, message: `${strings.DURATION} ${strings.IS_REQUIRED}` }]}
          >
            <InputNumber placeholder={strings.DURATION} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item {...field} name={getName('duration_measure')} label={strings.HOUR_DURATION}>
            <InputNumber placeholder={strings.HOUR_DURATION} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            {...field}
            name={getName('week_lessons_count')}
            label={strings.LESSON_PER_WEEKS}
            rules={[{ required: true, message: `${strings.LESSON_PER_WEEKS} ${strings.IS_REQUIRED}` }]}
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            {...field}
            name={getName('price')}
            label={strings.RATES}
            rules={[{ required: true, message: `${strings.RATES} ${strings.IS_REQUIRED}` }]}
          >
            <RateSelect branch={selectedBranch} placeholder={strings.RATE} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            {...field}
            name={getName('cost')}
            label={strings.CONDUCTOR_WAGE}
            rules={[{ required: true, message: `${strings.CONDUCTOR_WAGE} ${strings.IS_REQUIRED}` }]}
          >
            <WageSelect branch={selectedBranch} placeholder={strings.CONDUCTOR_WAGE} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item {...field} name={getName('assistentCost')} label={strings.ASSISTANT_WAGE}>
            <WageSelect branch={selectedBranch} placeholder={strings.ASSISTANT_WAGE} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[10, 10]} align="middle">
        <Col xs={24} lg={8}>
          <Form.Item {...field} name={getName('ignoreDiscount')} valuePropName="checked">
            <Checkbox>{strings.IGNORE_DISCOUNT}</Checkbox>
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item {...field} name={getName('monthRate')} valuePropName="checked">
            <Checkbox>{strings.MONTH_RATE}</Checkbox>
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item {...field} name={getName('monthRateNoDiscount')} valuePropName="checked">
            <Checkbox>{strings.MONTH_RATE_W_O_DISCOUNT}</Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </Spin>
  );
};

export default { GroupForm };
