import React from 'react';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Button, Col, Input, Row, Radio, message, Tooltip, Typography } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { HwAssessment as IHwAssessment, AssessmentMaterial } from 'types/accessment';
import { scrollTop } from 'helpers/scroll';
import { Materials } from './AssessmentMaterials';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useAssessmentsRepository } from 'repos/AssessmentsRepository';
import { isEmpty } from 'lodash';
import {checkTypeForInput} from "../../../../helpers/booleanFomatValues";

const { Text } = Typography;

interface HwAssessmentProps extends FormComponentProps {
  hwAssessment: IHwAssessment;
  lessons: AssessmentMaterial[];
  hwMaterials: any;
}

/**
 * @description HwAssessment component
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
const HwAssessmentTemplate = (props: HwAssessmentProps) => {
  const [strings] = useLanguageContext();
  const assessmentsRepository = useAssessmentsRepository();
  const {
    form: { getFieldDecorator, setFieldsValue, validateFieldsAndScroll },
    hwAssessment: {
      type,
      hwFinalMark,
      hwAvailability,
      hwUnderstandability,
      hwUnderstandabilityComment,
      hwAesthetics,
      hwAestheticsComment,
      hwRecommendation,
      id
    },
    lessons,
    hwMaterials
  } = props;

  /**
   * @description Очистка формы
   * @param {string} fieldName
   * @return {void}
   */
  const clearFieldByName = (fieldName: string): void => setFieldsValue({ [fieldName]: '' });

  /**
   * @description Обработчик на submit формы
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values) => {
      message.loading(strings.HW_DESIGN_UPDATING, 1);
      if (isEmpty(errors)) {
        try {
          await assessmentsRepository.editAssessmentByLessonId(id, { type, ...values });
          message.success(strings.HW_DESIGN_UPDATE);
          scrollTop();
        } catch {}
      }
    });
  };

  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Row>
        <Col lg={24}>
          <Materials lessons={lessons} hasShadow />
        </Col>
        <Col lg={24}>
          {hwMaterials.map((lessons: AssessmentMaterial[], id: number) => (
            <Materials lessons={lessons} hasShadow key={id} />
          ))}
        </Col>
        <Col lg={24}>
          <MyFormItem label={strings.HOMEWORK_AVAILABILITY}>
            {getFieldDecorator('hwAvailability', {
              initialValue: hwAvailability,
              rules: [{ required: false }]
            })(
              <Radio.Group>
                <Radio.Button value="0">0</Radio.Button>
                <Radio.Button value="1">1</Radio.Button>
                <Radio.Button value="2">2</Radio.Button>
                <Radio.Button value="3">3</Radio.Button>
              </Radio.Group>
            )}
            <Button size="middle" style={{ marginInlineStart: 10 }} onClick={() => clearFieldByName('hwAvailability')}>
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
      </Row>
      <Row>
        <Col lg={24}>
          <MyFormItem
            label={
              <>
                {strings.UNDERSTANDABILITY}
                <Tooltip title={strings.THE_TEACHER_GIVES_AT_LEAST_ONE_EVIDENCE_FOR_EACH_CHILD}>
                  <InfoCircleTwoTone style={{ fontSize: 22, margin: 10 }} twoToneColor="#1890ff" />
                </Tooltip>
              </>
            }
          >
            {getFieldDecorator('hwUnderstandability', {
              initialValue: hwUnderstandability,
              rules: [{ required: false }]
            })(
              <Radio.Group>
                <Radio.Button value="0">0</Radio.Button>
                <Radio.Button value="1">1</Radio.Button>
                <Radio.Button value="2">2</Radio.Button>
              </Radio.Group>
            )}
            <Button
              size="middle"
              style={{ marginInlineStart: 10 }}
              onClick={() => clearFieldByName('hwUnderstandability')}
            >
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem label={strings.UNDERSTANDABILITY_COMMENT}>
            {getFieldDecorator('hwUnderstandabilityComment', {
              initialValue: hwUnderstandabilityComment,
              rules: [{ required: false }]
            })(<Input.TextArea placeholder={strings.COMMENT} />)}
          </MyFormItem>
        </Col>
      </Row>
      <Row>
        <Col lg={24}>
          <MyFormItem
            label={
              <>
                {strings.AESTHETICS}
                <Tooltip
                  title={`${strings.STARTING_AT_3_POINTS}<br/>${strings.UNIFORM_DESIGN_OF_HOMEWORKS}<br/>${strings.SIGNS_OF_EMOTIONAL_ATTITUDE_OF_THE_TEACHER_E_G_DEAR_FRIENDS}<br/>${strings.LOW_QUALITY_IMAGES_OR_LAYOUT_OR_LOGO_IS_NOT_USED}<br/>${strings.NO_HEADINGS_SUBJECT}<br/>${strings.UNREASONABLE_SPLITTING_OF_HOME}`}
                >
                  <InfoCircleTwoTone style={{ fontSize: 22, margin: 10 }} twoToneColor="#1890ff" />
                </Tooltip>
              </>
            }
          >
            {getFieldDecorator('hwAesthetics', {
              initialValue: hwAesthetics,
              rules: [{ required: false }]
            })(
              <Radio.Group>
                <Radio.Button value="0">0</Radio.Button>
                <Radio.Button value="1">1</Radio.Button>
                <Radio.Button value="2">2</Radio.Button>
                <Radio.Button value="3">3</Radio.Button>
                <Radio.Button value="4">4</Radio.Button>
                <Radio.Button value="5">5</Radio.Button>
              </Radio.Group>
            )}
            <Button size="middle" style={{ marginInlineStart: 10 }} onClick={() => clearFieldByName('hwAesthetics')}>
              clear
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem label={strings.AESTHETICS_COMMENT}>
            {getFieldDecorator('hwAestheticsComment', {
              initialValue: hwAestheticsComment,
              rules: [{ required: false }]
            })(<Input.TextArea placeholder={strings.COMMENT} />)}
          </MyFormItem>
        </Col>
      </Row>
      <Row>
        <Col lg={24} style={{ background: '#e6f7ff', padding: '10px 20px 30px 20px', borderRadius: '5px' }}>
          <MyFormItem
            label={
              <>
                {strings.SUMMARY_COMMENTS}
                <Text type="secondary" style={{ float: 'right', fontSize: '12px' }}>
                  {strings.ONLY_THIS_PART_IS_VISIBLE_TO_THE_TEACHER}
                </Text>
              </>
            }
          >
            {getFieldDecorator('hwRecommendation', {
              initialValue: checkTypeForInput(hwRecommendation),
              rules: [{ required: false }]
            })(<Input.TextArea style={{ height: 300, width: '100%' }} placeholder={strings.COMMENT} />)}
          </MyFormItem>
          <MyFormItem label={strings.FINAL_MARK}>
            {getFieldDecorator('hwFinalMark', {
              initialValue: hwFinalMark,
              rules: [{ required: false }]
            })(
              <Radio.Group>
                <Radio.Button value="ni">Ni</Radio.Button>
                <Radio.Button value="s">S</Radio.Button>
                <Radio.Button value="g">G</Radio.Button>
              </Radio.Group>
            )}
            <Button size="middle" style={{ marginInlineStart: 10 }} onClick={() => clearFieldByName('hwFinalMark')}>
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Col lg={24}>
          <Button type="primary" size="large" htmlType="submit">
            {strings.SAVE}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export const HwAssessment = Form.create<HwAssessmentProps>({})(HwAssessmentTemplate);

export default { HwAssessment };
