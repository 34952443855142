import React from 'react';
import { isNull } from 'lodash';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { Radio } from 'antd';
import { RadioContainer } from 'Admin/Courses/styles';
import { generateColumn } from '../../helpers';
import { Child, LessonRegister } from 'types';
import { useLanguageContext } from 'contexts/LanguageContext';
import { THandleSetFieldByChildId } from 'types/TeacherAccount/Courses';
import { StTitle } from '../styles';
/**
 * @description Рендеринг колонки homework done
 * @param {function} handleSetFieldByChildId
 * @param {object} student
 * @param {boolean} homeworkDone
 * @return {React.ReactNode}
 */
export const RegisterHomeworkDone = React.memo(
  ({
    student,
    homeworkDone,
    handleSetFieldByChildId
  }: {
    student: Child;
    homeworkDone: boolean;
    handleSetFieldByChildId: THandleSetFieldByChildId;
  }) => {
    const [strings] = useLanguageContext();
    const checked = React.useMemo(() => {
      if (!isNull(homeworkDone)) return Boolean(homeworkDone);

      return null;
    }, [homeworkDone, student]);

    /**
     * @description Обработчик на ввод значения HW
     * @return {void}
     */
    const handleChangeHwButtons = event => {
      handleSetFieldByChildId('homeworkDone', student.id, event.target.value);
    };

    return (
      <RadioContainer>
        <Radio.Group size="small" onChange={handleChangeHwButtons} value={checked} buttonStyle="solid">
          <Radio.Button value={false}>{strings.NO}</Radio.Button>
          <Radio.Button value={true}>{strings.YES}</Radio.Button>
        </Radio.Group>
      </RadioContainer>
    );
  }
);

/**
 * @description Генерация колони homework done
 * @param {string[]} registerColumns
 * @param {boolean} isHwButtons
 * @param {function} handleSetFieldByChildId
 * @return {object}
 */
export const getHomeworkDoneColumn = (
  strings,
  hwButtons: boolean,
  handleSetFieldByChildId: THandleSetFieldByChildId
) => {
  return {
    ...generateColumn(<StTitle>{strings.HW_SUBMITTED}</StTitle>, 'homeworkDone', 'homeworkDone', null, null, 130),
    render: (homeworkDone: boolean, { student, homeworkRequired }: LessonRegister) => {
      if (!homeworkRequired) {
        return (
          <p style={{ textAlign: 'center' }}>
            <InfoCircleTwoTone twoToneColor="#1890ff" style={{ marginInlineEnd: 5 }} />
            {strings.STUDENT_WAS_NOT_ENROLLED_YET}
          </p>
        );
      }
      if (hwButtons) {
        return (
          <RegisterHomeworkDone
            student={student}
            homeworkDone={homeworkDone}
            handleSetFieldByChildId={handleSetFieldByChildId}
          />
        );
      }
      return null;
    }
  };
};
