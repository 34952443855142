import React, { useEffect, useState } from 'react';
import { Select, Form, Col, DatePicker, Flex, Typography, Input, Switch, Row, FormListFieldData } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { dateFormat } from 'helpers/dates';
import { useThemeContext } from 'contexts/ThemeContext';
import { IStudent, Performer, Status, StudyProgram } from 'types';
import { useMediaQuery } from 'react-responsive';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { InputForMobile, TutorSelect } from 'Global/components/FormComponents';
import { englishLevels } from 'Global/constants';
import { AvatarUploader } from './AvatarUploader';
import { StRow } from './EditStudent';
import { languageExperience } from 'helpers';
import { useChildContext } from 'contexts/ChildContext';
import { BranchBelgrade, BranchSPB } from 'types/branches';
import type { NamePath } from 'antd/lib/form/interface';
import { usePerformersRepository } from 'repos/PerformersRepository';

const { Option } = Select;
const { Title } = Typography;

type BaseStudentFormProps = {
  student?: IStudent;
  familyBranch?: number;
  familyId: number | string;
  field?: FormListFieldData;
};

export const BaseStudentForm: React.FC<BaseStudentFormProps> = ({ student, familyBranch, field, familyId }) => {
  const [strings] = useLanguageContext();
  const { activeTheme } = useThemeContext();
  const [examineTypes] = useChildContext();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { childStatuses, studyPrograms } = useGlobalCollectionsContext();
  const { mainPhoto, tutor } = student || {};
  const hasSpb = familyBranch === BranchSPB.id;
  const hasBelgrade = familyBranch === BranchBelgrade.id;
  const performersRepository = usePerformersRepository();

  const [performers, setPerformers] = useState<Performer[]>([]);

  const getPerformersForFamily = async (): Promise<any> => {
    try {
      const { data } = await performersRepository.getFamilyPerformers(familyId);
      const activePerformers = data.filter((item: Performer) => item?.isActive);
      setPerformers(activePerformers);
    } catch {}
  };
  const getPerformersByBranch = async (): Promise<any> => {
    try {
      const { data } = await performersRepository.getPerformers({ branch: familyBranch });
      const activePerformers = data.filter((item: Performer) => item?.isActive);
      setPerformers(activePerformers);
    } catch {}
  };

  useEffect(() => {
    if (hasSpb || hasBelgrade) {
      if (familyId) {
        getPerformersForFamily();
      } else {
        getPerformersByBranch();
      }
    }
  }, [familyId, familyBranch]);

  const getName = (name): NamePath => (field ? [field.name, name] : name);

  return (
    <Flex justify="space-between" align="start" gap={20} vertical={isMobile}>
      <AvatarUploader name={getName('avatar')} mainPhoto={mainPhoto ? mainPhoto : ''} />
      <StRow>
        <Col lg={24} xs={24}>
          <Title level={5} style={{ color: activeTheme.primaryColor }}>
            {strings.GENERAL_INFO}
          </Title>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item
            name={getName('name')}
            label={strings.NAME}
            rules={[{ required: true, message: `${strings.NAME} ${strings.IS_REQUIRED}` }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('surname')} label={strings.SURNAME}>
            <Input />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('patronymic')} label={strings.PATRONYMIC}>
            <Input />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('birthday')} label={strings.DATE_OF_BIRTH}>
            <DatePicker format={dateFormat} />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('gender')} label={strings.SEX}>
            <Select>
              <Option value={0}>{strings.MALE}</Option>
              <Option value={1}>{strings.FEMALE}</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('phone')} label={strings.MOBILE}>
            <InputForMobile />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('phoneSecond')} label={strings.MOBILE_SECOND}>
            <InputForMobile />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item
            name={getName('email')}
            label={strings.EMAIL}
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!'
              }
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('comment')} label={strings.COMMENT}>
            <Input.TextArea />
          </Form.Item>
        </Col>
        {(hasSpb || hasBelgrade) && (
          <Col lg={24} xs={24}>
            <Form.Item
              name={getName('performer')}
              label={strings.PERFORMER}
              rules={[{ required: true, message: `${strings.PERFORMER} ${strings.IS_REQUIRED}` }]}
            >
              <Select placeholder={strings.PERFORMER}>
                {performers.map((performer: Performer) => (
                  <Option key={performer.id} value={performer.id}>
                    {performer.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        <Col lg={24} xs={24}>
          <Flex gap={10} align="baseline">
            <span>{strings.SIMPLE_INVOICE}</span>
            <Form.Item name={getName('autogeneratedInvoices')} valuePropName="checked">
              <Switch checkedChildren={strings.YES} unCheckedChildren={strings.NO} />
            </Form.Item>
          </Flex>
        </Col>
      </StRow>
      <StRow gutter={10}>
        <Col lg={24} xs={24}>
          <Title level={5} style={{ color: activeTheme.primaryColor }}>
            {strings.STUDYING_INFO}
          </Title>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('status')} label={strings.STATUS}>
            <Select placeholder={strings.SELECT_STATUS}>
              {childStatuses.map((status: Status) => (
                <Option value={status.id} key={status.id}>
                  {status.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('tutor')} label={strings.TUTOR}>
            <TutorSelect initOptions={tutor && [tutor]} />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('studyProgram')} label={strings.STUDY_PROGRAM}>
            <Select placeholder={strings.STUDY_PROGRAM}>
              {studyPrograms.map((studyProgram: StudyProgram) => (
                <Option value={studyProgram.id} key={studyProgram.id}>
                  {studyProgram.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('englishLevel')} label={strings.LEVEL_OF_LANGUAGE_PROFICIENCY}>
            <Select>
              <Option value={undefined}>&nbsp;</Option>
              {englishLevels.map(level => (
                <Option key={level.value} value={level.value}>
                  {level.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('englishLevelComment')} label={strings.LANGUAGE_EXPERIENCE}>
            <Select>
              <Option value={undefined}>&nbsp;</Option>
              {languageExperience.map(level => (
                <Option key={`level-${level}`} value={level}>
                  {level}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('examsResults')} label={strings.ENTRY_TEST_RESULTS}>
            <Input />
          </Form.Item>
        </Col>
        <Col lg={12} xs={12}>
          <Form.Item name={getName('school')} label={strings.SCHOOL}>
            <Input />
          </Form.Item>
        </Col>
        <Col lg={12} xs={12}>
          <Form.Item name={getName('classN')} label={strings.CLASS}>
            <Input />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('hobby')} label={strings.HOBBY}>
            <Input />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('target')} label={strings.WHY_STUDY_WITH_US}>
            <Input.TextArea />
          </Form.Item>
        </Col>
      </StRow>
      <StRow>
        <Col lg={24} xs={24}>
          <Title level={5} style={{ color: activeTheme.primaryColor }}>
            {strings.PERSON_EDEXCEL_INFO}
          </Title>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('UCINumber')} label={strings.UCI_NUMBER}>
            <Input />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('candidateNumber')} label={strings.CANDIDATE_NUMBER}>
            <Input />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('firstNameEnglish')} label={strings.FIRST_NAME_ENGLISH}>
            <Input />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('lastNameEnglish')} label={strings.LAST_NAME_ENGLISH}>
            <Input />
          </Form.Item>
        </Col>
        <Col lg={24} xs={24}>
          <Form.Item name={getName('examineType')} label={strings.EXAMINE_TYPE}>
            <Select>
              {Array.from(examineTypes || []).map((value: string[]) => (
                <Option value={Number(value[0])} key={value[0]}>
                  {value[1]}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </StRow>
    </Flex>
  );
};
export default { BaseStudentForm };
