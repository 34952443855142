import React from 'react';
import { ITeacherTraining } from 'types/education';
import { CheckOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { Switch, Typography, Button } from 'antd';
import { Link } from 'react-router-dom';
import * as educationRoutes from '../../../../Education/routes';
const { Text } = Typography;

export const getColumns = (
  editTeacherTrainingMark: (training: ITeacherTraining, typeParam: 'done' | 'essential', value: boolean) => void,
  deleteTrainingFromTeacher: (training: ITeacherTraining) => void
) => [
  {
    title: 'Name',
    key: 'name',
    dataIndex: '',
    render: (training: ITeacherTraining) => {
      const {
        training: { name, id }
      } = training;
      return <Link to={`${educationRoutes.BASE_TRAININGS}/${id}`}>{name}</Link>;
    }
  },
  {
    title: 'Description',
    key: 'description',
    dataIndex: 'training',
    render: ({ description }) => description
  },
  {
    title: () => (
      <div>
        Essential/
        <br />
        Optional
      </div>
    ),
    key: 'essential',
    dataIndex: 'essential',
    width: 120,
    render: (essential: boolean, training: ITeacherTraining) => {
      return (
        <>
          <Text style={{ whiteSpace: 'nowrap' }}>{essential ? 'Essential' : 'Optional'}</Text>
          <br />
          <Switch
            checked={essential}
            onClick={checked => {
              editTeacherTrainingMark(training, 'essential', checked);
            }}
          />
        </>
      );
    }
  },
  {
    title: () => (
      <div>
        Finished/
        <br />
        Not finished
      </div>
    ),
    dataIndex: 'done',
    key: 'done',
    width: 120,
    render: (done: boolean, training: ITeacherTraining) => {
      return (
        <>
          <Text style={{ whiteSpace: 'nowrap' }}>{done ? 'Finished' : 'Not finished'}</Text>
          <br />
          <Switch
            checked={done}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onClick={checked => {
              editTeacherTrainingMark(training, 'done', checked);
            }}
          />
        </>
      );
    }
  },
  {
    title: '',
    dataIndex: '',
    key: 'delete',
    render: (training: ITeacherTraining) => {
      const {
        done,
        training: { id }
      } = training;
      return (
        <Button icon={<DeleteOutlined />} danger disabled={done} onClick={() => deleteTrainingFromTeacher(training)} />
      );
    }
  }
];

export default { getColumns };
