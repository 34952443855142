import queryString from 'query-string';
import { message } from 'antd';
import { downloadSomeFile } from 'helpers';
import { INITIAL_STATUS_IDS } from './constants';
import { Branch } from 'types';

import { momentFormatValues } from 'helpers/momentFormatValues';
import { booleanFormatValues } from 'helpers/booleanFomatValues';

/**
 * @description Форматирует данные формы под нужный формат АПИ
 * @param {any} valuesForm
 */
export const formatDayjsDates = (valuesForm: any) => {
  let formatValues = momentFormatValues(valuesForm);

  return {
    ...formatValues,
    doubts: formatValues.doubts ? 1 : 0
  };
};

/**
 * @description Поиск филиала
 * @param {Branch[]} branches
 * @param {number} findBranchId
 */
export const findBranch = (branches: Branch[], findBranchId: number | string) =>
  branches?.find((branch: Branch) => branch.id === Number(findBranchId))?.name;

/**
 * @description Скачивание файла в excel
 * @param {string} type
 * @param {any} getFieldsValue
 * @return {Promise<any>}
 */
export const downloadExcel = async (type: string, getFieldsValue: any, branchName?: string): Promise<any> => {
  const fields = momentFormatValues(booleanFormatValues(getFieldsValue()));
  const params = queryString.stringify(fields, { skipNull: true, arrayFormat: 'bracket' });

  message.loading(`File with finance ${type} downloading..`, 1);
  await downloadSomeFile(
    `/api/finance/${type}/export?${params}`,
    `finance-${type}${branchName ? '-' + branchName : ''}.xls`
  );
};

/**
 * @description Форматирует статус(ы) в массив ид
 * @param {any} statuses
 * @return {number[]}
 */
export const initalStatuses = (statuses: any): number[] => {
  if (Array.isArray(statuses)) {
    return statuses.map(Number);
  } else if (typeof statuses === 'string') {
    return [Number(statuses)];
  } else {
    return INITIAL_STATUS_IDS;
  }
};

export default { formatDayjsDates, downloadExcel, findBranch, initalStatuses };
