import React, { useEffect, useState } from 'react';
import { Button, Typography, Modal, Row, Space } from 'antd';
import { useUserRepository } from 'repos/UserRepository';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { InformalAgreementContent } from './InformalAgreementContent';

const { Title } = Typography;

export const InformalAgreement = () => {
  const [strings] = useLanguageContext();
  const [hasOpenModal, setHasOpenModal] = useState<boolean>(false);

  const setVisibleModal = (flag: boolean): void => {
    setHasOpenModal(flag);
  };

  return (
    <Row>
      <InformalAgreementModal hasOpenModal={hasOpenModal} setVisibleModal={setVisibleModal} />
      <Space align="baseline">
        <Title level={4}>{strings.INFORMAL_AGREEMENT}</Title>
        <Button onClick={() => setHasOpenModal(true)}>{strings.OPEN_FILE}</Button>
      </Space>
    </Row>
  );
};

// Модальное окно открывается по кнопке в виджете InformalAgreement на главной странице
// и автоматически открывается на странице Расписание, если учитель еще не читал договор (!user.readAgreement)
export const InformalAgreementModal = (props: { hasOpenModal: boolean; setVisibleModal?: (flag: boolean) => void }) => {
  const { hasOpenModal, setVisibleModal } = props;
  const [strings] = useLanguageContext();
  const [user, setUser] = useUserContext();
  const userRepository = useUserRepository();
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);

  const setReadAgreement = async () => {
    try {
      await userRepository.setUserReadAgreement();
      setUser({ ...user, readAgreement: true });
    } finally {
      setVisibleModal ? setVisibleModal(false) : setHasShowModal(false);
    }
  };

  const footer = [
    <Button
      key="later"
      size="large"
      onClick={() => (setVisibleModal ? setVisibleModal(false) : setHasShowModal(false))}
    >
      {strings.READ_LATER}
    </Button>,
    <Button key="read" size="large" type="primary" onClick={() => setReadAgreement()}>
      {strings.READ}
    </Button>
  ];
  useEffect(() => {
    setHasShowModal(hasOpenModal);
  }, [hasOpenModal]);

  return (
    (<Modal
      width={800}
      style={{ top: 20 }}
      title={strings.INFORMAL_AGREEMENT}
      open={hasShowModal}
      onCancel={() => (setVisibleModal ? setVisibleModal(false) : setHasShowModal(false))}
      footer={!user?.readAgreement && footer}
    >
      <InformalAgreementContent />
    </Modal>)
  );
};

export default { InformalAgreementModal, InformalAgreement };
