import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, Button } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { isEmpty } from 'lodash';
import { useLanguageContext } from 'contexts/LanguageContext';
import TextArea from 'antd/es/input/TextArea';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';
import { IReportRegister } from 'types/TeacherAccount/Courses';

interface RejectCommentModalProps extends FormComponentProps {
  hasShowModal: boolean;
  setHasShowModal: (hasShow: boolean) => void;
  refetchReports: () => void;
  type: string;
  register: IReportRegister;
}

/**
 * @description RejectCommentModal
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const RejectCommentModalTemplate = (props: RejectCommentModalProps) => {
  const {
    hasShowModal,
    setHasShowModal,
    form: { validateFields, getFieldDecorator },
    type,
    register,
    refetchReports
  } = props;
  const [strings] = useLanguageContext();
  const reportsRepository = useProgressReportsRepository();
  const [hasLoading, setHasLoading] = useState<boolean>(false);

  /**
   * @description Submit формы
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFields(async (errors, values) => {
      if (isEmpty(errors)) {
        setHasLoading(true);
        try {
          await reportsRepository.registerCommentReject(register?.id, { type, ...values });
          refetchReports();
          setHasShowModal(false);
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  return (
    (<Modal
      title={strings.EDIT_COMMENT}
      open={hasShowModal}
      onCancel={() => setHasShowModal(false)}
      destroyOnClose={true}
      footer={
        <Button key="addBtn" type="primary" loading={hasLoading} onClick={handleSubmit}>
          {strings.SUBMIT}
        </Button>
      }
    >
      <Form layout="vertical">
        <MyFormItem label={strings.COMMENT}>
          {getFieldDecorator('comment', {
            rules: [{ required: false }]
          })(<TextArea placeholder={strings.TYPE_A_REASON} />)}
        </MyFormItem>
      </Form>
    </Modal>)
  );
};

export const RejectCommentModal = Form.create<RejectCommentModalProps>({})(RejectCommentModalTemplate);

export default { RejectCommentModal };
