import React, { useMemo, useContext } from 'react';
import { httpService } from 'api/core';
import { IMessage, ThreadsParams } from 'types/CRM/messages';
import { IRepositoryProvider } from './RepositoryProvider';
import { ICreateThread, IReplyThread } from 'types/ParentJournal';
import { getFormData } from 'helpers';

const ThreadsRepositoryContext = React.createContext<IThreadsRepository>(null);

interface IThreadsRepository {
  getThreads: (params: ThreadsParams) => Promise<any>;
  editMessage: (id: number | string, params: IMessage) => Promise<any>;
  approveMessage: (id: number | string) => Promise<any>;
  deleteMessage: (id: number | string) => Promise<any>;
  getMyThreads: (params?: { limit?: number }) => Promise<any>;
  getThreadById: (id: number | string) => Promise<any>;
  createThread: (params: ICreateThread) => Promise<any>;
  replyThread: (id: number, params: IReplyThread) => Promise<any>;
}

class ThreadsRepository implements IThreadsRepository {
  getThreads(params: ThreadsParams) {
    return httpService.get(`/api/v1/threads`, params, null, true);
  }
  editMessage(id: number | string, params: IMessage) {
    return httpService.put(`/api/v1/threads/message/${id}`, params, null, null, true);
  }
  approveMessage(id: number | string) {
    return httpService.post(`/api/v1/threads/message/${id}/approve`, null, null, null, true);
  }
  deleteMessage(id: number | string) {
    return httpService.delete(`/api/v1/threads/message/${id}`, null, null, true);
  }
  getMyThreads(params?: { limit?: number }) {
    return httpService.get(`/api/v1/threads/my`, params, null, true);
  }
  getThreadById(id: number | string) {
    return httpService.get(`/api/v1/threads/${id}`, null, null, true);
  }
  createThread(params: ICreateThread) {
    return httpService.post(`/api/threads`, params);
  }

  replyThread(id: number, params: IReplyThread) {
    const formDataParams = getFormData(params);
    return httpService.post(`/api/v1/threads/${id}/reply`, formDataParams, null, null, true);
  }
}

export const ThreadsRepositoryProvider: React.FC<IRepositoryProvider> = ({ children }) => {
  const service = useMemo(() => new ThreadsRepository(), []);

  return <ThreadsRepositoryContext.Provider value={service}>{children}</ThreadsRepositoryContext.Provider>;
};

export const useThreadsRepository = () => {
  const service = useContext(ThreadsRepositoryContext);

  if (!service) {
    throw new Error('MessagesFromParent repository is unavailable');
  }

  return service;
};
