import React from 'react';
import { ArrowLeftOutlined, CalendarOutlined, HistoryOutlined } from '@ant-design/icons';
import { Typography, Button, Modal, Tooltip } from 'antd';
import { OriginalCourse } from 'types/courses';
import { useUserContext } from 'contexts/UserContext';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useLastLocation } from 'react-router-last-location';
import { useHistory } from 'react-router-dom';

interface AboutCourseProps {
  originalCourseId: number | string;
  course: OriginalCourse;
}

const { Title } = Typography;

/**
 * @description Информация о курсе
 * @param {AboutCourseProps} props
 * @return {React.ReactNode}
 */
export const AboutCourse = (props: AboutCourseProps) => {
  const { originalCourseId, course } = props;
  const { name, duration, weekLessonsCount } = course || {};
  const [user] = useUserContext();
  const hasTeacher = user?.hasRoles?.teacher;
  const coursesRepo = useCoursesRepository();
  const [strings] = useLanguageContext();
  const lastLocation = useLastLocation();
  const { goBack, push } = useHistory();
  const [{ confirm }, contextHolder] = Modal.useModal();

  const handleDelete = () => {
    confirm({
      title: strings.ARE_YOU_SURE,
      okType: 'danger',
      async onOk() {
        try {
          await coursesRepo.deleteOriginalCourse(course.id);
          push('/original_courses');
        } catch {}
      }
    });
  };

  const getDeleteButton = () => {
    const buttonProps = {
      onClick: handleDelete,
      style: { marginInlineStart: 10 }
    };

    if (course?.canBeDeleted) {
      return (
        <Button danger {...buttonProps}>
          Delete
        </Button>
      );
    }

    return (
      <Tooltip title={strings.YOU_CANT_DELETE_THIS_ORIGINAL_COURSE_BECAUSE_THERE_ARE_SOME_GROUPS_BASED_ON_IT}>
        <Button disabled {...buttonProps}>
          {strings.DELETE}
        </Button>
      </Tooltip>
    );
  };

  return (
    <>
      {contextHolder}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5em' }}>
        <Title level={3} style={{ marginBottom: 0 }}>
          {lastLocation && <ArrowLeftOutlined style={{ marginInlineEnd: '10px' }} onClick={goBack} />}
          {name}
        </Title>
        <div>
          {!hasTeacher && (
            <Button
              type="primary"
              onClick={() => push(`/original_courses/${originalCourseId}/edit`)}
              style={{ marginInlineStart: 8 }}
            >
              {strings.EDIT}
            </Button>
          )}
          {getDeleteButton()}
        </div>
      </div>
      <div>
        <CalendarOutlined style={{ marginInlineEnd: 4 }} />
        {duration} {strings.CLASSROOM_HOURS}
        <HistoryOutlined style={{ margin: '0 4px 0 12px' }} />
        {weekLessonsCount} {strings.PER_WEEK}
      </div>
    </>
  );
};

export default { AboutCourse };
