import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select, Spin } from 'antd';
import { debounce } from 'lodash';
import { Teachers } from 'api';
import { GetFieldDecoratorOptions } from '@ant-design/compatible/lib/form/Form';
import { AxiosResponse } from 'axios';
import { useLanguageContext } from '../../../../contexts/LanguageContext';

const { Option } = Select;

interface ExpertSelectProps {
  handleChange?: (tutor: any) => any;
  initExperts?: number[];
  placeholder?: string;
  name?: string;
  getFieldDecorator?<T extends Object = {}>(
    id: keyof T,
    options?: GetFieldDecoratorOptions
  ): (node: React.ReactNode) => React.ReactNode;
  required?: boolean;
  errorMsg?: string;
  label?: string;
  style?: object;
  className?: string;
}

/**
 * @description Компонент для выбора Expert
 * @param {object} props
 * @return {React.ReactNode}
 */
export const ExpertSelect = (props: ExpertSelectProps) => {
  // TODO именованный экспорт нужен
  const {
    handleChange,
    initExperts,
    placeholder,
    getFieldDecorator,
    required,
    errorMsg,
    label,
    name,
    style,
    className
  } = props;
  const [areExpertsLoading, setLoadExperts] = useState<Boolean>(false);
  const [experts, setExperts] = useState<any[]>([]);
  const [typing, setTyping] = useState<Boolean>(false);
  const [strings] = useLanguageContext();

  /**
   * @description Получение админов
   * @param {string} expert
   * @return {Promise<void>}
   */
  const fetchExperts = async (expert: any): Promise<any> => {
    if (expert.length && !typing) setTyping(true);
    setLoadExperts(true);
    await Teachers.getExperts(expert)
      .then((res: AxiosResponse) => {
        const { data, status } = res;
        if (status === 200) {
          setExperts(data);
          setLoadExperts(false);
        }
      })
      .catch(() => {});
  };

  const getExpertsByIds = async (expertIds): Promise<any> => {
    await Teachers.getExperts()
      .then((res: AxiosResponse) => {
        const { data, status } = res;
        if (status === 200) {
          const filteredExperts = [];
          data.forEach(expert => {
            expertIds.forEach(id => {
              if (expert?.id === id) {
                filteredExperts.push(expert);
              }
            });
          });
          setExperts(filteredExperts);
          setLoadExperts(false);
        }
      })
      .catch(() => {});
  };

  const options = experts.map((expert: any) => {
    return (
      <Option value={expert.id} key={expert.id}>
        {expert.name} {expert.surname}
      </Option>
    );
  });

  const select = (
    <Select
      showSearch
      mode="multiple"
      placeholder={placeholder || 'Tutor'}
      defaultActiveFirstOption={false}
      filterOption={false}
      onChange={handleChange}
      onSearch={debounce(fetchExperts, 800)}
      style={{ width: '100%' }}
      notFoundContent={
        areExpertsLoading ? <Spin size="small" /> : <p>{!typing ? strings.START_TYPING : strings.NOT_FOUND}</p>
      }
      maxTagCount={2}
    >
      {!required && <Option value="">&nbsp;</Option>}
      {options}
    </Select>
  );
  useEffect(() => {
    if (initExperts) {
      getExpertsByIds(initExperts);
    }
  }, []); // eslint-disable-line

  if (getFieldDecorator) {
    if (required) {
      return (
        <MyFormItem label={label} style={style} className={className}>
          {getFieldDecorator(name, {
            initialValue: initExperts && initExperts,
            rules: [{ required: required, message: errorMsg || `${name} is required` }]
          })(select)}
        </MyFormItem>
      );
    }

    return (
      <MyFormItem label={label} style={style} className={className}>
        {getFieldDecorator(name, {
          initialValue: initExperts && initExperts,
          rules: [{ required: false }]
        })(select)}
      </MyFormItem>
    );
  }
  return select;
};
