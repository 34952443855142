import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useConstantsContext } from 'contexts/ConstantsContext';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {Input, Button, Alert, Flex} from 'antd';
import { isEmpty, toArray } from 'lodash';
import { storageService } from 'contexts/StorageContext';
import { useUserContext } from 'contexts/UserContext';
import { useHistory } from 'react-router-dom';

const StyledForm = styled.form`
  display: flex;
  gap: 20px;
  input {
    height: 100px;
    font-size: 30px !important;
    font-weight: 500;
    text-align: center;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export function CodeForm({ phone, setIsShowCode }) {
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const formRef = useRef(null);
  const { RETURN_KEY_CODE } = useConstantsContext();
  const [inputs, setInputs] = useState({ '1': null, '2': null, '3': null, '4': null });
  const globalRequestsRepository = useGlobalRequestsRepository();
  const [user, setUser] = useUserContext();
  const { push } = useHistory();

  const getInputElementByIndex = (index: number) => {
    if (formRef.current) {
      return formRef.current.querySelector(`[data-index='${index}']`);
    }

    return null;
  };

  const handleChange = ({ target }) => {
    if (!isEmpty(target.value)) {
      const currentIndex = Number(target.dataset.index);
      const newValues = { ...inputs };
      newValues[currentIndex] = target.value;
      setInputs(newValues);
      getInputElementByIndex(currentIndex + 1)?.focus();
    }
  };

  const onKeyDown = event => {
    if (event.keyCode === RETURN_KEY_CODE) {
      const newValues = { ...inputs };
      const currentIndex = Number(event.target.dataset.index);
      const index = currentIndex === 1 ? 1 : currentIndex - 1;
      const previousInput = getInputElementByIndex(index);
      newValues[currentIndex] = null;
      setInputs(newValues);
      previousInput.focus();
    }
  };

  function getInputsList() {
    return Object.keys(inputs).map((key: string) => (
      <Input
        key={key}
        onKeyDown={onKeyDown}
        type="text"
        value={inputs[key]}
        onChange={handleChange}
        data-index={key}
        maxLength={1}
      />
    ));
  }

  const onCancel = () => {
    setIsShowCode(false);
  };

  useLayoutEffect(() => {
    getInputElementByIndex(1).focus();
  }, []);

  useEffect(() => {
    const joinedNumbers = toArray(inputs).join('');
    if (joinedNumbers.length === 4) {
      (async () => {
        try {
          const {
            data: { token, refreshToken }
          } = await globalRequestsRepository.authSmsCheck({ phone: phone, code: joinedNumbers });
          storageService.setAccessToken(token);
          storageService.setRefreshToken(refreshToken);
          setUser({ ...user, isLogin: true });
          push('/');
        } catch (error) {
          if (error.hasOwnProperty('data')) {
            setIsError(true);
            setErrorMessage(error.data.message);
          }
        }
      })();
    }
  }, [inputs, globalRequestsRepository, phone?.current]);

  return (
    <Flex vertical gap={20}>
      <StyledForm ref={formRef}>{getInputsList()}</StyledForm>
      {isError && <Alert message={errorMessage} type="error" showIcon />}
      <Button
        onClick={onCancel}
        icon={<ArrowLeftOutlined />}
        size="large"
      >
        Back
      </Button>
    </Flex>
  );
}
