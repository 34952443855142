import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Row, Col, Button, Select, Input } from 'antd';
import { Subject } from 'types';
import BaseFilter from 'helpers/BaseFilter';
import { Location } from 'history';
import { pickBy, identity } from 'lodash';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { TrainingsFilterParams } from 'types/education';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { StageSelect } from 'Global/components/FormComponentsCompatible/Selects/StageSelect';
import { useLocationSearchParams } from '../../../hooks/useLocationSearchParams';
import { FilterButtons } from '../../../Global/components/FilterButtons';
const { Option } = Select;

interface TrainingsFilterProps {
  form: WrappedFormUtils;
  onFilter: (params?: TrainingsFilterParams) => Promise<any>;
}

/**
 * @description Фильтр для тренингов
 * @param {FilterProps} props
 * @returns {React.ReactNode}
 */
export const FilterTemplate = (props: TrainingsFilterProps) => {
  const {
    form,
    form: { getFieldDecorator, validateFieldsAndScroll, resetFields },
    onFilter
  } = props;
  const [strings] = useLanguageContext();

  const { subjects } = useGlobalCollectionsContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);

  const { locationSearchParams } = useLocationSearchParams();
  const { subject, name, stage } = locationSearchParams || {};

  /**
   * @description Submit формы
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values): Promise<any> => {
      if (!errors) {
        const params = pickBy(values, identity);
        setHasLoading(true);
        try {
          await onFilter(params);
        } catch {
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  /**
   * @description Очистка фильтра
   * @return {Promise<any>}
   */
  const handleClear = async (): Promise<any> => {
    resetFields();
    await onFilter();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={[10, 10]}>
        <Col lg={4}>
          {getFieldDecorator('subject', {
            initialValue: subject,
            rules: [{ required: false }]
          })(
            <Select placeholder={strings.SUBJECT}>
              <Option value={undefined}>&nbsp;</Option>
              {subjects.map((subject: Subject) => (
                <Option value={subject.id} key={subject.id}>
                  {subject.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col lg={4}>
          <StageSelect form={form} initialValue={stage} />
        </Col>
        <Col lg={4}>
          {getFieldDecorator('name', {
            initialValue: name,
            rules: [{ required: false }]
          })(<Input placeholder={strings.TITLE} />)}
        </Col>
        <FilterButtons handleClear={handleClear} isSameRow={true} hasSubmitLoading={hasLoading} />
      </Row>
    </Form>
  );
};

export const Filter = Form.create<TrainingsFilterProps>({})(FilterTemplate);
export default { Filter };
