export function filterSelect(input, option) {
  const optionStr = option.props.children?.props
    ? option.props.children.props.children.toString().toLowerCase()
    : option.props.children.toString().toLowerCase();

  return optionStr.includes(input.toLowerCase());
}
export const filterOption = (input, option) => {
  let optionText = '';

  if (option?.children) {
    const children = option.children instanceof Array ? option.children : [option.children];
    optionText = children.reduce((acc, child) => acc + (child.props?.children || child), '');
  }

  return optionText.toLowerCase().includes(input.toLowerCase());
};
