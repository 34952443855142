import React, { useState, useEffect } from 'react';
import { Select, Spin } from 'antd';
import { Admin } from 'types';
import { useLanguageContext } from 'contexts/LanguageContext';
import debounce from 'lodash/debounce';
import { useStudentRepository } from 'repos/StudentRepository';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import queryString from 'query-string';

const { Option } = Select;

// Обобщенный тип, который создает подтип из User с заданными ключами
type Subset<T> = {
  [K in keyof T]?: T[K];
};

type AdminSubset = Subset<Admin>;

type AsyncSelectProps = {
  initOptions?: AdminSubset[];
  value?: number;
  placeholder?: string;
  onChange?: (value: number) => void;
  mode?: 'multiple' | 'tags';
};

const StudentSelect: React.FC<AsyncSelectProps> = ({ value, initOptions, placeholder, onChange, mode }) => {
  const [strings] = useLanguageContext();
  const studentRepository = useStudentRepository();
  const globalRequestsRepository = useGlobalRequestsRepository();
  const [typing, setTyping] = useState<Boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [options, setOptions] = useState<AdminSubset[]>([]);
  const [hasLoading, setHasLoading] = useState(false);

  const fetchStudentsByQuery = async (query: string): Promise<any> => {
    setSearchValue(query);
    if (query) {
      setHasLoading(true);
      try {
        const {
          data: { children }
        } = await globalRequestsRepository.search(query);
        setOptions(children);
      } catch {
      } finally {
        setHasLoading(false);
      }
    }
  };
  const getStudentById = async (value: string | number): Promise<any> => {
    try {
      const { data } = await studentRepository.getStudentById(value);
      setOptions([data]);
    } catch {}
  };

  const getStudentsByIds = async (value): Promise<any> => {
    try {
      const {
        data: { items }
      } = await studentRepository.getStudents({ ids: value, limit: 1000 });
      setOptions(items);
    } catch {}
  };

  useEffect(() => {
    if (initOptions) {
      setOptions(initOptions);
    }
  }, [initOptions]);

  useEffect(() => {
    if (searchValue) {
      setTyping(true);
    } else {
      setTyping(false);
    }
  }, [searchValue]);

  useEffect(() => {
    if (value) {
      if (mode === 'multiple') {
        getStudentsByIds(value);
      } else {
        getStudentById(value);
      }
    }
  }, [JSON.stringify(value)]);

  return (
    <Select
      showSearch
      value={value}
      onChange={val => onChange(val)}
      allowClear
      placeholder={placeholder}
      onSearch={debounce(fetchStudentsByQuery, 800)}
      loading={hasLoading}
      mode={mode}
      maxTagCount="responsive"
      filterOption={false}
      notFoundContent={hasLoading ? <Spin size="small" /> : <p>{!typing ? strings.START_TYPING : strings.NOT_FOUND}</p>}
    >
      {options?.map(option => (
        <Option key={option.id} value={option.id}>
          {option.user.surname} {option.user.name}
        </Option>
      ))}
    </Select>
  );
};

export default StudentSelect;
