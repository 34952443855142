import React from 'react';
import { Button, Modal, Row, Table, Tooltip } from 'antd';
import { getColumns } from './columns';
import { IStudent } from 'types';
import { useLanguageContext } from 'contexts/LanguageContext';
import { User } from 'types/user';
import { Link } from 'react-router-dom';
import { EditOutlined, LoginOutlined } from '@ant-design/icons';
import { switchUserModal } from 'helpers/switchUser';
import { BtnCreateAccount } from '../../BtnCreateAccount';
import { getCallPhoneButton } from 'helpers';
const { Column } = Table;

interface StudentsTableProps {
  students: IStudent[];
  style?: React.CSSProperties;
  handleUserRefetch: () => void;
  hasSearchResult?: boolean;
}

/**
 * @description Таблица детей
 * @param {object} props
 * @return {React.ReactNode}
 */
export const StudentsTable = (props: StudentsTableProps) => {
  const { students, style, handleUserRefetch, hasSearchResult } = props;
  const [strings] = useLanguageContext();
  const [{ confirm }, contextHolder] = Modal.useModal();

  const optionsCol = {
    title: '',
    dataIndex: 'user',
    key: 'action',
    width: 200,
    render: (user: User, student: IStudent) => {
      const { id, loginAs, familyId } = student;
      const { email, phone, id: userId } = user || {};

      return (
        <Row align="middle" style={{ gap: 8, flexWrap: 'nowrap' }}>
          {contextHolder}
          <Link to={`/family/${familyId}/student/${id}/edit/`}>
            <Button style={{ margin: 0 }} icon={<EditOutlined />} />
          </Link>
          {loginAs && (
            <Tooltip title={strings.LOGIN_AS_STUDENT}>
              <Button
                style={{ margin: 0 }}
                icon={<LoginOutlined />}
                onClick={() => switchUserModal(loginAs, confirm)}
              />
            </Tooltip>
          )}
          <BtnCreateAccount
            id={id}
            userId={userId}
            email={email}
            loginAs={loginAs}
            type="student"
            handleUserRefetch={handleUserRefetch}
            hasLoginEqualsEmail={true}
          />
          {getCallPhoneButton(phone)}
        </Row>
      );
    }
  };

  return (
    <Table
      className="students-table"
      dataSource={students}
      bordered={false}
      pagination={false}
      style={style}
      rowKey="id"
    >
      {[...getColumns(strings, handleUserRefetch, hasSearchResult), optionsCol]
        .filter(col => col)
        .map(col => (
          <Column key={col.key} {...col} />
        ))}
    </Table>
  );
};

export default { StudentsTable };
