import React, { useState, useEffect } from 'react';
import { match } from 'react-router';
import { Row, Col, Input, Select, Checkbox, Button, Spin, message, DatePicker, InputNumber, Form } from 'antd';
import { Branch } from 'types';
import { SERVICE_RATES } from '../../routes';
import { convertBooleanToNumber, dateFormat } from 'helpers';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import { IRate, IRateParams } from 'types/finance';
import dayjs from 'dayjs';
import { first } from 'lodash';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useInvoicesRepository } from 'repos/InvoicesRepository';
import { momentFormatValues } from 'helpers/momentFormatValues';
import { useLanguageContext } from 'contexts/LanguageContext';
import { StageSelect } from 'Global/components/FormComponents';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useUserContext } from 'contexts/UserContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useMediaQuery } from 'react-responsive';
const { Option } = Select;

export const RateForm = () => {
  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();
  const [form] = Form.useForm();
  const [user] = useUserContext();
  const hasTopManager = user?.hasRoles?.topManager;
  const { goBack, push, length } = useHistory();
  const [strings] = useLanguageContext();
  const [selectedBranch, setSelectedBranch] = useState<number>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [rateTypes, setRateTypes] = useState<{ id: number; name: string }[]>([]);
  const { branches, priceGroups } = useGlobalCollectionsContext();
  const globalRequestsRepository = useGlobalRequestsRepository();
  const invoicesRepository = useInvoicesRepository();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const getRateById = async (): Promise<void> => {
    setLoading(true);
    try {
      const { data } = await globalRequestsRepository.getRateById(id);
      const { startAt, endAt, stage, type, priceGroup, branch } = data || {};
      const initValues = {
        ...data,
        branch: branch.id,
        type: type?.id,
        startAt: startAt && dayjs(startAt),
        endAt: endAt && dayjs(endAt),
        stage: stage?.id,
        priceGroup: priceGroup?.id
      };
      form.setFieldsValue(initValues);
      setSelectedBranch(branch.id);
    } finally {
      setLoading(false);
    }
  };

  const fetchRateTypes = async (): Promise<void> => {
    try {
      const { data } = await invoicesRepository.getRatesTypes();
      setRateTypes(data);
    } catch {
      message.error(strings.SORRY_SOMETHING_WENT_WRONG_WHEN_FETCHING_INVENTORY_TYPES);
    }
  };

  const handleSubmit = async (values: IRateParams): Promise<any> => {
    setLoading(true);
    try {
      const params = momentFormatValues(convertBooleanToNumber(values));
      if (id) {
        const sanitizedValues = Object.entries(params).reduce((acc, [key, value]) => {
          acc[key] = value === undefined ? null : value;
          return acc;
        }, {} as IRateParams);
        await globalRequestsRepository.editRate(id, sanitizedValues);
        message.success(strings.SUCCESSFULLY_UPDATED, 2);
      } else {
        await globalRequestsRepository.createRate(params);
        message.success(strings.RATE_WAS_CREATED_SUCCESSFULLY);
      }
      if (length > 1) {
        goBack();
      } else {
        push(SERVICE_RATES);
      }
    } catch {
      message.error(strings.SORRY_SOMETHING_WENT_WRONG_WHEN_CREATING_AN_RATE_PLEASE_TRY_AGAIN);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getRateById();
    }
    fetchRateTypes();
  }, []);

  useEffect(() => {
    if (!id) {
      const initBranch = user?.branch?.id;
      setSelectedBranch(initBranch);
      form.setFieldsValue({ branch: initBranch });
    }
  }, [user]);

  return (
    <Container>
      <StPageHeader onBack={goBack} title={id ? strings.EDITING_RATE : strings.CREATING_NEW_RATE} />
      <Spin spinning={isLoading} tip={`${strings.LOADING}...`}>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Row gutter={[10, 10]}>
            <Col xs={24} lg={12}>
              <Form.Item label={strings.NAME} name="name" rules={[{ required: true }]}>
                <Input placeholder={strings.NAME} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={4}>
              <Form.Item label={strings.PRICE} name="price" rules={[{ required: true }]}>
                <InputNumber placeholder={strings.PRICE} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item label={strings.TYPE} name="type" rules={[{ required: true }]}>
                <Select placeholder={strings.TYPE}>
                  {rateTypes.map((type: Branch) => (
                    <Option value={type.id} key={type.id}>
                      {type.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item name="invoiceText" label={strings.INVOICES_TEXT}>
                <Input placeholder={strings.INVOICES_TEXT} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={4}>
              <Form.Item name="priceGroup" label={strings.PRICE_GROUP}>
                <Select placeholder={strings.PRICE_GROUP} allowClear>
                  {priceGroups?.map(item => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={4}>
              <Form.Item name="startAt" label={strings.START}>
                <DatePicker format={dateFormat} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={4}>
              <Form.Item name="endAt" label={strings.END}>
                <DatePicker format={dateFormat} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[10, 10]} align="middle">
            <Col xs={hasTopManager ? 24 : 0} lg={hasTopManager ? 6 : 0}>
              <Form.Item
                name="branch"
                label={strings.BRANCH}
                hidden={!hasTopManager}
                rules={[{ required: true, message: strings.BRANCH_IS_REQUIRED }]}
              >
                <Select placeholder={strings.BRANCH}>
                  {branches.map(branch => (
                    <Option value={branch.id} key={branch.id}>
                      {branch.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item name="stage" label={strings.STAGES}>
                <StageSelect branch={selectedBranch} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={4} style={{ marginTop: isMobile ? 0 : 30 }}>
              <Form.Item name="isActive" valuePropName="checked">
                <Checkbox>{strings.CURRENT}</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} style={{ marginTop: isMobile ? 0 : 30 }}>
              <Form.Item name="monthRateNoDiscount" valuePropName="checked">
                <Checkbox>{strings.MONTH_RATE_W_O_DISCOUNT}</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} lg={12}>
              <Form.Item name="sequence" label={strings.COMMENT}>
                <Input.TextArea placeholder={strings.COMMENT} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item name="commentDatePeriod" label={strings.COMMENT_FOR_PERIOD}>
                <Input.TextArea placeholder={strings.COMMENT_FOR_PERIOD} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col xs={24} lg={6}>
              <Button type="primary" size="large" htmlType="submit">
                {id ? strings.EDIT : strings.ADD}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Container>
  );
};

export default { RateForm };
