import React, { useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, Button, Upload, Select, Col, Row, message } from 'antd';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { getFormData } from 'helpers';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Option } = Select;
const { Dragger } = Upload;

export function CreateFromExcelButton({ originalCourseId, getOriginalCourseById }) {
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const [stageId, setStageId] = useState<number>(null);
  const [subjects, setSubjects] = useState<number[]>([]);
  const [file, setFile] = useState(null);
  const coursesRepo = useCoursesRepository();
  const { stages, subjects: subjectsList } = useGlobalCollectionsContext();
  const [hasLoad, setHasLoad] = useState<boolean>(false);
  const [strings] = useLanguageContext();

  const handleClick = () => {
    setHasShowModal(true);
  };

  const handleChange = async upload => {
    if (!upload.file) return;
    setFile(upload.file);
    return false;
  };

  const handleCreateFromExcel = async () => {
    try {
      if (!stageId || subjects.isEmpty() || !file) {
        message.error(strings.PLEASE_FILLED_ALL_FIELDS_AND_UPLOAD_FILE, 2);
        return;
      }
      setHasLoad(true);
      const params = getFormData({
        stage: stageId,
        subjects: subjects,
        file: file
      });
      await coursesRepo.uploadOriginalCourseFromFile(params);
      message.success(`${strings.SUCCESS} :)`, 2);
      setHasLoad(false);
      setHasShowModal(false);
    } catch {
      setHasLoad(false);
      message.error(`${strings.ERROR_TRY_AGAIN_LATER} :(`, 2);
    }
  };

  const uploadFile = async ({ file }) => {
    try {
      setHasLoad(true);
      await coursesRepo.uploadOriginalCourseFromFile(
        getFormData({
          file: file,
          originalCourse: originalCourseId
        })
      );
      message.success(`${strings.SUCCESS} :)`, 2);
      await getOriginalCourseById(originalCourseId);
      setHasLoad(false);
    } catch {
      setHasLoad(false);
      message.error(`${strings.ERROR_TRY_AGAIN_LATER} :(`, 2);
    }
  };

  return (
    <>
      {!originalCourseId && (
        <Modal
          footer={[
            <Button loading={hasLoad} type="primary" size="large" onClick={handleCreateFromExcel}>
              {strings.CREATE}
            </Button>
          ]}
          title={strings.CREATE_FROM_EXCEL}
          open={hasShowModal}
          onCancel={() => setHasShowModal(false)}
        >
          <Form layout="vertical">
            <Row gutter={10}>
              <Col lg={12}>
                <MyFormItem required label={strings.SUBJECTS}>
                  <Select
                    mode="multiple"
                    maxTagCount={2}
                    placeholder={strings.SELECT_SUBJECTS}
                    onChange={(value: number[]) => {
                      setSubjects(value);
                    }}
                  >
                    {subjectsList.map(subject => (
                      <Option value={subject.id} key={subject.id}>
                        {subject.name}
                      </Option>
                    ))}
                  </Select>
                </MyFormItem>
              </Col>
              <Col lg={12}>
                <MyFormItem required label={strings.STAGE}>
                  <Select placeholder={strings.SELECT_STAGE} onChange={stage => setStageId(Number(stage))}>
                    {stages.map(stage => (
                      <Option value={stage.id} key={stage.id}>
                        {stage.name}
                      </Option>
                    ))}
                  </Select>
                </MyFormItem>
              </Col>
              <Col lg={24}>
                <Dragger beforeUpload={() => false} showUploadList={true} onChange={handleChange} maxCount={1}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p>{strings.UPLOAD_EXCEL_FILE}</p>
                </Dragger>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
      {!originalCourseId && <Button onClick={handleClick}>{strings.CREATE_FROM_EXCEL}</Button>}
      {originalCourseId && (
        <Upload showUploadList={false} customRequest={uploadFile} multiple={false}>
          <Button loading={hasLoad}>{strings.CREATE_FROM_EXCEL}</Button>
        </Upload>
      )}
    </>
  );
}
