import React from 'react';
import { Result, Button } from 'antd';
import { FeedbackModal } from 'Global/modules/Support/Modal';
import { isLocalLaunch } from 'helpers';
import * as Sentry from '@sentry/browser';

interface ErrorBoundaryState {
  hasError: boolean;
  showModal: boolean;
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

/**
 * @description Компонент для отлова ошибок в дочерних компонентах
 */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      showModal: false
    };
  }

  hideModal = () => {
    this.setState({ showModal: false });
  };

  openModal = () => {
    this.setState({ showModal: true });
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  /**
   * @param {error} error
   * @param {string} errorInfo
   * @return {void}
   */
  componentDidCatch(error, errorInfo): void {
    if (!isLocalLaunch) {
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <Result status="warning" title="There are some problems with your operation. Try later" />
          <div style={{ textAlign: 'center', marginTop: '-25px' }}>
            <Button onClick={this.openModal} size="large" type="primary">
              Send feedback
            </Button>
          </div>
          <FeedbackModal onHide={this.hideModal} hasShow={this.state.showModal} />
        </>
      );
    }

    return this.props.children;
  }
}

export class ErrorBoundaryHoc extends React.Component<{ children: React.ReactNode }, { hasAppError: boolean }> {
  constructor(props) {
    super(props);
    this.state = { hasAppError: null };
  }

  componentDidCatch(error, errorInfo): void {
    if (!isLocalLaunch) {
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }
    this.setState({ hasAppError: true });
  }

  render() {
    if (this.state.hasAppError) {
      return (
        <Result
          style={{ marginTop: 150 }}
          status="error"
          title="Sorry, App error :("
          subTitle="An error has occurred in the application. We already know about it. Try later"
        />
      );
    }

    return this.props.children;
  }
}

export default { ErrorBoundary, ErrorBoundaryHoc };
