import React from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Space } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import MyJoditEditor from 'Global/components/MyJoditEditor';

export const SubjectGoalsForAddLesson = () => {
  const [strings] = useLanguageContext();

  return (
    <Form.Item label={strings.LESSON_OUTCOMES}>
      <Form.List name="goals" initialValue={['']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...field }, index) => (
              <Flex key={key} style={{ marginBottom: 10 }} gap={10} align="baseline">
                <Form.Item {...field} name={[name]} style={{ flex: 1 }}>
                  <MyJoditEditor height={100} />
                </Form.Item>
                {index > 0 && (
                  <Button
                    size="small"
                    type="primary"
                    shape="circle"
                    icon={<MinusOutlined />}
                    danger
                    onClick={() => remove(name)}
                  />
                )}
                {index === 0 && (
                  <Button
                    type="primary"
                    size="small"
                    shape="circle"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      add('', 0);
                    }}
                  />
                )}
              </Flex>
            ))}
          </>
        )}
      </Form.List>
    </Form.Item>
  );
};

export default { SubjectGoalsForAddLesson };
