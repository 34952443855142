import { httpService } from 'api/core';
import { getFormData } from '../../helpers';
import {FamilyContractPutParams} from "../../types/Family";
/**
 * @description Получить семью по id
 * @param {number} id
 * @param {any} params
 * @return {Promise<any>}
 */
export const getFamilyById = (id: number, params?: any): Promise<any> => httpService.get(`/api/families/${id}`, params);

/**
 * @description Получить контракты семьи
 * @param {number} id
 * @return {Promise<any>}
 */
export const getFamilyContracts = (id: number | string): Promise<any> =>
  httpService.get(`/api/families/${id}/contracts`);

/**
 * @description Получить контракты семьи по статусу контракта
 * @param {number} id
 * @param {number} statusId
 * @return {Promise<any>}
 */
export const getFamilyContractsByStatusId = (id: number, statusId: number): Promise<any> =>
  httpService.get(`/api/families/${id}/contracts/${statusId}`);

/**
 * @description Получить Family Collaboration History
 * @param {number} id
 * @param {object} params
 * @return {Promise<any>}
 */
export const getCollaborationHistory = (id: number, params: any) =>
  httpService.get(`/api/families/${id}/history`, params);

/**
 * @description Редактирование контракта семьи
 * @param {number} id
 * @param {object} params
 * @returns {Promise<any>}
 */
export const editFamilyContract = (id: number | string, params: FamilyContractPutParams): Promise<any> => {
  const formDataParams = getFormData({ _method: 'PUT', ...params });
  return httpService.post(`/api/families/contracts/${id}`, formDataParams, true);
};

/**
 * @description Удаление семьи по id
 * @param {number} familyId
 * @return {Promise<any>}
 */
export const deleteFamily = (familyId: number): Promise<any> => httpService.delete(`/api/families/${familyId}`);

/**
 * @description Удаление базовой аватарки студента
 * @param {number} childId
 * @return {Promise<any>}
 */
export const deleteChildMainAvatar = (childId: number): Promise<any> =>
  httpService.delete('/api/image/family', {
    isMain: true,
    student: childId
  });

/**
 * @description Удаление базовой автарки родителя
 * @param {number} parentId
 * @return {Promise<any>}
 */
export const deleteMainParentAvatar = (parentId: number): Promise<any> =>
  httpService.delete('/api/image/family', {
    isMain: true,
    parent: parentId
  });

export default {
  getFamilyById,
  getFamilyContracts,
  getCollaborationHistory,
  editFamilyContract,
  deleteFamily,
  deleteChildMainAvatar,
  deleteMainParentAvatar,
  getFamilyContractsByStatusId
};
