import { Branch, Performer, Tutor } from './index';
import { User } from './user';

export interface PaginationInfo {
  last: number;
  current: number;
  numItemsPerPage: number;
  first: number;
  pageCount: number;
  totalCount: number;
  pageRange: number;
  startPage: number;
  endPage: number;
  previous: number;
  next: number;
  pagesInRange: number[];
  firstPageInRange: number;
  lastPageInRange: number;
  currentItemCount: number;
  firstItemNumber: number;
  lastItemNumber: number;
}

export type UploadFileStatus = 'error' | 'success' | 'done' | 'uploading' | 'removed';

export interface UploadFile<T = any> {
  uid: string;
  size: number;
  name: string;
  fileName?: string;
  lastModified?: number;
  lastModifiedDate?: Date;
  url?: string;
  status?: UploadFileStatus;
  percent?: number;
  thumbUrl?: string;
  originFileObj?: File | Blob;
  response?: T;
  error?: any;
  linkProps?: any;
  type: string;
}

export interface GetAttachmentParams {
  type: string; //lessonHomework, eventFiles, trainingAttach, metalevelPicture
  id: number;
}

export interface UserLocalParams {
  locale: string;
}

export interface UploadAttachmentParams {
  type: string;
  id: number;
  file?: File;
  filesId?: number[];
}

export interface AttachmentItem {
  id: number;
  name: string;
  created_by?: string;
  updated_at?: string;
  updatedAt?: string;
  printed: boolean;
  isPreview: boolean;
  user?: User;
}

export interface MaterialItem {
  list: AttachmentItem[];
  print: boolean;
}

export interface SearchChildByNameParams {
  query: string;
  branch?: number;
  limit: 1000;
}

export interface SearchChild {
  id: number;
  familyId: number;
  name: string;
  surname?: string;
  tutor: Tutor;
  age?: string;
}

export interface ISubjectTranslation {
  id: number;
  ru: string;
  en: string;
}

export interface IStagesGetParams {
  branch?: number[];
  teacher?: number;
  withDetails?: 0 | 1;
}

export enum RegisterType {
  main = 'main',
  preschool = 'preschool',
  school = 'school'
}
export enum ReportingType {
  main = 'main',
  preschool = 'preschool',
  school = 'school',
  primary = 'primary'
}
export interface IStagePostParams {
  name: string;
  registerType?: RegisterType;
  reportType?: ReportingType;
  position?: number;
  product?: number;
  studyProgram?: number;
  performer?: number;
  branches?: number[];
}

export interface IChangeTutorParams {
  branch: number;
  oldTutor: number;
  newTutor: number;
}
