import React, { Fragment, useCallback, useState } from 'react';
import { Col, Row, Input, Select, Checkbox, DatePicker, Flex, Form } from 'antd';
import { Branch, Product, Training } from 'types';
import { Participant } from 'types/Schedule';
import { dateFormat } from 'helpers/dates';
import { SmsForm } from 'Global/modules/Schedule/SlotModals/SlotFields/Sms';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { ParticipantsFields } from '../SlotFields/ParticipantsFields';
import { TrainingFields } from '../SlotFields/TrainingFields';
import { UserSelect, StageSelect, RateSelect, WageSelect } from 'Global/components/FormComponents';
import { StSlotCollapse } from '../../styles';
import { useMediaQuery } from 'react-responsive';

interface EventFormProps {
  setSmsText: (smsText: string) => void;
  setHasSendToParent: (selected: boolean) => void;
  readonly hasShouldClearSms: boolean;
  setHasShouldClearSms?: (value: boolean) => void;
  setEventParticipants: (items: Participant[]) => void;
  eventParticipants: Participant[];
  setTrainingsList: (items: Training[]) => void;
  trainingsList: Training[];
  userBranch: number;
}

const { Option } = Select;

/**
 * @description Форма создания события
 * @param {object} props
 * @return {React.ReactNode}
 */
export const EventForm = ({
  trainingsList = [],
  setTrainingsList,
  setSmsText,
  setHasSendToParent,
  setEventParticipants,
  eventParticipants = [],
  hasShouldClearSms,
  setHasShouldClearSms,
  userBranch
}: EventFormProps) => {
  const [strings] = useLanguageContext();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [selectedBranch, setSelectedBranch] = useState<number>(userBranch);

  const { branches, stages, products, trainings } = useGlobalCollectionsContext();
  const onChangeBranch = useCallback(val => {
    setSelectedBranch(val);
  }, []);

  return (
    <Fragment>
      <Row gutter={[10, 10]} align="middle">
        <Col lg={10} xs={24}>
          <Form.Item name="name" label={strings.NAME} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col lg={4} xs={24}>
          <Form.Item name="branch" label={strings.BRANCH} initialValue={userBranch} rules={[{ required: true }]}>
            <Select onChange={onChangeBranch}>
              {branches.map((branch: Branch) => (
                <Option key={branch.id} value={branch.id}>
                  {branch.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={4} xs={24}>
          <Form.Item name="stage" label={strings.STAGE} rules={[{ required: true }]}>
            <StageSelect branch={selectedBranch} />
          </Form.Item>
        </Col>
        <Col lg={6} xs={24}>
          <Form.Item name="product" label={strings.PRODUCT} rules={[{ required: true }]}>
            <Select placeholder={strings.PRODUCT}>
              {products.map((product: Product) => (
                <Option value={product.id} key={product.id}>
                  {product.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[10, 10]} align="middle">
        <Col lg={10} xs={24}>
          <Form.Item name="price" label={strings.RATE}>
            <RateSelect branch={selectedBranch} />
          </Form.Item>
        </Col>
        <Col lg={8} xs={24}>
          <Form.Item name="cost" label={strings.CONDUCTOR_WAGE}>
            <WageSelect branch={selectedBranch} />
          </Form.Item>
        </Col>
        <Col lg={6} xs={24}>
          <Form.Item name="discount" style={{ display: 'contents' }} valuePropName="checked">
            <Checkbox style={{ ...(!isMobile && { paddingTop: 6 }) }}>{strings.ACTIVATE_DISCOUNT}</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[10, 10]} align="middle">
        <Col lg={10} xs={24}>
          <Form.Item name="conductorId" label={strings.CONDUCTOR} rules={[{ required: true }]}>
            <UserSelect nameExtraTypeField="conductorType" />
          </Form.Item>
          <Form.Item name="conductorType" hidden>
            <Input />
          </Form.Item>
        </Col>
        <Col lg={8} xs={24}>
          <Form.Item name="dateSequence" label={strings.PROLONG_UNTIL}>
            <DatePicker format={dateFormat} placeholder={strings.PROLONG_UNTIL} />
          </Form.Item>
        </Col>
        <Col lg={6} xs={24}>
          <Form.Item name="applyToAll" style={{ display: 'contents' }} valuePropName="checked">
            <Checkbox style={{ ...(!isMobile && { paddingTop: 6 }) }}>{strings.APPLY_TO_ALL}</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <StSlotCollapse
        ghost
        size="small"
        defaultActiveKey={['1', '2']}
        items={[
          {
            key: '1',
            label: <strong>{strings.PARTICIPANTS}</strong>,
            children: (
              <Flex vertical gap={10}>
                <ParticipantsFields
                  eventParticipants={eventParticipants}
                  setEventParticipants={setEventParticipants}
                  selectedBranch={selectedBranch}
                />
                <SmsForm
                  smsTextChanged={setSmsText}
                  sendToParentChanged={setHasSendToParent}
                  hasShouldClearSms={hasShouldClearSms}
                  setHasShouldClearSms={setHasShouldClearSms}
                />
              </Flex>
            )
          },
          {
            key: '2',
            label: <strong>{strings.TRAININGS}</strong>,
            children: <TrainingFields trainingsList={trainingsList} setTrainingsList={setTrainingsList} />
          }
        ]}
      />
    </Fragment>
  );
};

export default { EventForm };
