import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Typography, Button, Row, Col, List, Card, Tabs, Table } from 'antd';
import { LessonTopic as StLessonTopic } from 'Admin/Education/styles';
import { InfoForm } from 'Global/modules/Materials/LessonTopic/InfoForm';
import { columns } from 'Global/modules/Materials/LessonTopic/columns';
import { History } from 'history';
import { useLastLocation } from 'react-router-last-location';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';

const { Title } = Typography;
const { TabPane } = Tabs;
const { Column } = Table;

const data = [
  {
    title: 'Group',
    content: 'AGer3'
  },
  {
    title: 'Subject',
    content: 'German'
  },
  {
    title: 'Teacher',
    content: 'Gkourovanidis Ntemis'
  },
  {
    title: 'Tutors'
  }
];

interface lessonTopicProps {
  history: History;
}

export const LessonTopic = (props: lessonTopicProps) => {
  const {
    history: { goBack }
  } = props;
  const lastLocation = useLastLocation();
  const handleBack = () => goBack();

  return (
    <ErrorBoundary>
      <StLessonTopic>
        <Row>
          <Col lg={12}>
            <Title level={3}>{lastLocation && <ArrowLeftOutlined onClick={handleBack} />} 1 Umwelt</Title>
          </Col>
          <Col lg={12} style={{ textAlign: 'right' }}>
            <Button>Disciplines</Button>
            <Button>See the whole programme</Button>
            <Button>Previous Lesson</Button>
            <Button>Next Lesson</Button>
          </Col>
        </Row>
        <Row style={{ marginTop: '30px' }}>
          <Col lg={24}>
            <List
              grid={{ gutter: 16, column: 4 }}
              dataSource={data}
              renderItem={item => (
                <List.Item>
                  <Card title={item.title}>Card content</Card>
                </List.Item>
              )}
            />
            ,
          </Col>
        </Row>
        <Row>
          <Col lg={24}>
            <Title level={4}>Dec 9, 2011 19:15 - 20:45</Title>
          </Col>
        </Row>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Info" key="1">
            <Col lg={12}>
              <InfoForm />
            </Col>
          </TabPane>
          <TabPane tab="Index" key="2">
            <Table dataSource={[]} pagination={false} bordered>
              {columns.map(col => (
                <Column key={col.key} {...col} />
              ))}
            </Table>
          </TabPane>
        </Tabs>
      </StLessonTopic>
    </ErrorBoundary>
  );
};

export default { LessonTopic };
