import React, { useMemo } from 'react';
import { httpService } from 'api/core';
import { ExpertDeleteParams, ExpertPostParams, ExpertsFilterParams } from 'types/education';
import { IRepositoryProvider } from './RepositoryProvider';
import queryString from 'query-string';
import { AdditionalAccountPostParams, AdditionalAccountsFilterParams } from 'types/CRM/additionalAccounts';
import { AdminGetParams } from '../types/user';
import { ChangeTutor } from '../Admin/Website/CRM/ChangeTutor';
import { IChangeTutorParams } from '../types/global';

const AdminRepositoryContext = React.createContext<IAdminRepository>(null);
interface IAdminRepository {
  getOriginalCoursesAdminAccess: (params) => Promise<any>; //TODO описать params
  addOriginalCoursesAdminAccess: (params) => Promise<any>; //don't use
  getAllAdmins: (params: AdminGetParams) => Promise<any>;
  createAdmin: (params) => Promise<any>; //TODO описать params
  editAdminById: (id: number | string, params) => Promise<any>; //TODO описать params
  getAdminById: (id: number | string) => Promise<any>;
  setOriginalCoursesAdminAccess: (id: number, level: string) => Promise<any>;
  getAdminsByName: (name: string, allBranchActive?: number) => Promise<any>;
  getExperts: (params: ExpertsFilterParams) => Promise<any>;
  editExpert: (params: ExpertPostParams) => Promise<any>;
  deleteExpert: (params: ExpertDeleteParams) => Promise<any>;
  getAdditionalAccounts: (params?: AdditionalAccountsFilterParams) => Promise<any>;
  deleteAdditionalAccounts: (id: number | string) => Promise<any>;
  createAdditionalAccounts: (params: AdditionalAccountPostParams) => Promise<any>;
  changeFamiliesTutor: (params: IChangeTutorParams) => Promise<any>;
  changeTeachersTutor: (params: IChangeTutorParams) => Promise<any>;
}

class AdminRepository {
  getOriginalCoursesAdminAccess(params) {
    return httpService.get('/api/originalcourses/access/admin', params);
  }
  addOriginalCoursesAdminAccess(params) {
    return httpService.post('/api/originalcourses/access/admin', params);
  }
  setOriginalCoursesAdminAccess(id: number, level: string) {
    return httpService.put(`/api/originalcourses/access/${id}`, { level });
  }
  getAdminsByName(name: string, allBranchActive?: number) {
    return httpService.get(
      `/api/v1/admins?query=${name}&isActive=1&allBranchActive=${allBranchActive}`,
      null,
      null,
      true
    );
  }
  getAllAdmins(params: AdminGetParams) {
    return httpService.get(`/api/v1/admins`, params, null, true);
  }

  createAdmin(data) {
    return httpService.post('/api/v1/user/admin', data, null, null, true);
  }

  editAdminById(id: number | string, data) {
    return httpService.put(`/api/v1/user/admin/${id}`, data, null, null, true);
  }

  getAdminById(id: number | string) {
    return httpService.get(`/api/v1/admins/${id}`, null, null, true);
  }

  getExperts(params: ExpertsFilterParams) {
    return httpService.get(`/api/v1/assessment/expert/list`, params, null, true);
  }

  editExpert(params: ExpertPostParams) {
    return httpService.post('/api/v1/assessment/expert', params, null, null, true);
  }

  deleteExpert(params: ExpertDeleteParams) {
    return httpService.delete(`/api/v1/assessment/expert?${queryString.stringify(params)}`, null, null, true);
  }

  getAdditionalAccounts(params?: AdditionalAccountsFilterParams) {
    return httpService.get(`/api/v1/additional-accounts`, params, null, true);
  }

  deleteAdditionalAccounts(id: number | string) {
    return httpService.delete(`/api/v1/additional-accounts/${id}`, null, null, true);
  }

  createAdditionalAccounts(params: AdditionalAccountPostParams) {
    return httpService.post('/api/v1/additional-accounts', params, null, null, true);
  }
  changeFamiliesTutor(params: IChangeTutorParams) {
    return httpService.post('/api/v1/tutors/families-change', params, null, null, true);
  }
  changeTeachersTutor(params: IChangeTutorParams) {
    return httpService.post('/api/v1/tutors/teachers-change', params, null, null, true);
  }
}

export const useAdminRepository = () => {
  const service = React.useContext(AdminRepositoryContext);

  if (!service) {
    throw new Error('Admin repository is unavailable');
  }

  return service;
};

export const AdminRepositoryProvider: React.FC<IRepositoryProvider> = ({ children }) => {
  const service = useMemo(() => new AdminRepository(), []);

  return <AdminRepositoryContext.Provider value={service}>{children}</AdminRepositoryContext.Provider>;
};
