import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Flex, Form, Input, InputNumber, Row, Spin } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { match } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useStatusesRepository } from 'repos/StatusesRepository';
import { BASE_STATUSES } from '../../routes';
import { IStatusPostParams } from 'types/CRM/statuses';
import { Container, StPageHeader } from 'Global/GlobalStyle';

export const FamilyStatusForm: React.FC = () => {
  const [strings] = useLanguageContext();
  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();

  const [form] = Form.useForm();
  const { push, goBack } = useHistory();
  const statusesRepository = useStatusesRepository();
  const [hasLoading, setHasLoading] = useState<boolean>(false);

  const handleSubmit = async (values: IStatusPostParams): Promise<any> => {
    setHasLoading(true);
    try {
      if (id) {
        await statusesRepository.editFamilyStatus(id, values);
      } else {
        await statusesRepository.addFamilyStatus(values);
      }
      push(`${BASE_STATUSES}?tab=family`);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  const getFamilyStatusInfo = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await statusesRepository.getFamilyStatusById(id);
      form.setFieldsValue(data);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getFamilyStatusInfo();
    }
  }, [id]);

  return (
    <Container>
      <StPageHeader
        onBack={goBack}
        title={id ? `${strings.EDIT} ${strings.FAMILY_STATUS_LOW}` : `${strings.CREATE} ${strings.FAMILY_STATUS_LOW}`}
      />
      <Spin spinning={hasLoading}>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Row gutter={[10, 10]}>
            <Col lg={8} xs={24}>
              <Form.Item name="name" label={strings.NAME} rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col lg={4} xs={24}>
              <Form.Item name="weight" label={strings.WEIGHT}>
                <InputNumber />
              </Form.Item>
            </Col>
            <Col lg={8} xs={12} style={{ marginTop: 35 }}>
              <Form.Item name="isActive" valuePropName="checked">
                <Checkbox>{strings.ACTIVE}</Checkbox>
              </Form.Item>
            </Col>
            <Col lg={20} xs={24}>
              <Form.Item name="comment" label={strings.COMMENT}>
                <Input.TextArea placeholder={strings.COMMENT} />
              </Form.Item>
            </Col>
          </Row>
          <Flex justify="start" gap={10}>
            <Button type="primary" htmlType="submit">
              {id ? strings.EDIT : strings.ADD}
            </Button>
          </Flex>
        </Form>
      </Spin>
    </Container>
  );
};
export default FamilyStatusForm;
