import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Table, Typography, Button, Modal, message, Flex } from 'antd';
import { Loader } from 'Global/components/Loader/Loader';
import { Families } from 'api/Families';
import { columns } from 'Admin/People/Families/Family/ExistingContracts/columns';
import { ModalTags } from 'Admin/People/Families/Family/ExistingContracts/Modal';
import { CREATE_FAMILY_CONTRACT } from 'Global/routes';
import { downloadSomeFile, getFormData } from 'helpers';
import { TagFamily, Contract } from 'types/Family';
import { Branch, FamilyContractStatus } from 'types';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Title } = Typography;

interface ExistingContractsProps {
  id: number;
  branch: Branch;
  hasStudent?: boolean;
}

/**
 * @description Modal tags contracts
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const ExistingContracts = (props: ExistingContractsProps) => {
  const [strings] = useLanguageContext();
  const history = useHistory();
  const { push } = useHistory();
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [contractIdModal, setContractIdModal] = useState<number>();
  const [contractsTags, setContractsTags] = useState<TagFamily[]>([]);
  const [showContracts, setShowContracts] = useState<boolean>(false);
  const [modalVision, setModalVision] = useState<boolean>(false);
  const [contractStatuses, setContractStatuses] = useState<FamilyContractStatus[]>([]);
  const [isStatusLoading, setIsStatusLoading] = useState<boolean>(false);
  const { id, hasStudent } = props;
  const [{ confirm }, contextHolder] = Modal.useModal();

  const [user] = useUserContext();
  const hasTopManagerOrBranchDirector = user?.hasRoles?.topManager || user?.hasRoles?.branchDirector;

  /**
   * @description Получение контрактов по id семьи
   * @return {Promise<any>}
   * */
  const getContractsById = async (): Promise<any> => {
    setShowContracts(false);
    await Families.getFamilyContracts(id)
      .then(({ data }) => {
        setShowContracts(true);
        setContracts(data);
      })
      .catch(() => {});
  };

  /**
   * @description Получение списка контрактов семьи
   * @return {Promise<any>}
   * */
  const getContractsTags = async (): Promise<any> => {
    await Families.getFamilyContractsTags()
      .then(({ data }) => {
        setContractsTags(data);
      })
      .catch(() => {});
  };

  /**
   * @description Получить статусы по контрактам
   * @returns {Promise<any>}
   */
  const getStatuses = async (): Promise<any> => {
    const { data: statuses } = await Families.getFamilyContractStatuses();
    setContractStatuses(statuses);
  };

  /**
   * @description Удаление тега
   * @param {Event} e
   * @param {number} tagId
   * @param {number} contractId
   * @return {Promise<any>}
   */
  const deleteTag = (e: React.MouseEvent, tagId: number, contractId: number): void => {
    e.preventDefault();
    confirm({
      title: 'Do you want to delete this tag?',
      okText: 'Delete',
      okType: 'danger',
      maskClosable: true,
      async onOk() {
        await Families.deleteTagFromFamilyContract(id, contractId, tagId)
          .then(async ({ status }) => {
            if (status === 200) {
              message.success('Contract tag deleted successfully');
              await getContractsById();
            }
          })
          .catch(() => {});
      }
    });
  };

  /**
   * @description Удаление контракта
   * @param {number} contractId
   * @return {void}
   */
  const deleteContract = (contractId: number): void => {
    confirm({
      title: 'Do you want to delete this contract?',
      okText: 'Delete',
      okType: 'danger',
      maskClosable: true,
      async onOk() {
        await Families.deleteContractById(contractId)
          .then(() => {
            message.success('Contract delete successfully');
            getContractsById();
          })
          .catch(() => {});
      }
    });
  };

  /**
   * @description Добавление тега в контракт
   * @param {number} familyId
   * @param {number} contractId
   * @param {number} tagId
   * @return {Promise<any>}
   * */
  const addNewTagToFamilyContract = async (familyId: number, contractId: number, tagId: number): Promise<any> => {
    await Families.addTagToFamilyContract(familyId, contractId, {
      tag: tagId
    })
      .then(async ({ status }) => {
        if (status === 200) {
          message.success('Tag added successfully');
          await getContractsById();
        }
      })
      .catch(() => {});
  };

  /**
   * @description Скачать контракт по ИД
   * @param {Contract} contract
   * @returns {Promise<any>}
   */
  const downloadContract = async (contract: Contract): Promise<any> => {
    const {
      id,
      holded: { name, surname }
    } = contract;
    await downloadSomeFile(`/api/families/contract/${id}/download`, `${surname}_${name[0]}_contract.pdf`);
  };

  const modalClose = (): void => setModalVision(false);

  const onStatusChange = async (id: number, contractId: number): Promise<any> => {
    setIsStatusLoading(true);
    try {
      const { status } = await Families.editFamilyContract(contractId, { status: id });
      if (status === 200) {
        message.success('Contract status was successfully saved!');
      }
    } catch (e) {
      message.error('Sorry, something went wrong when changing status! Please, try again.');
    } finally {
      setIsStatusLoading(false);
    }
  };

  useEffect(() => {
    getContractsTags();
    getContractsById();
    getStatuses();
  }, []); // eslint-disable-line

  return (
    <Loader spinning={!showContracts}>
      <Flex justify="space-between" style={{ marginBottom: 20 }}>
        <Title level={4} style={{ marginTop: 0 }}>
          {strings.CONTRACTS}
        </Title>
        <Button
          type="primary"
          onClick={() => {
            hasStudent ? push(`${CREATE_FAMILY_CONTRACT}/${id}`) : message.error('No students in the family', 2);
          }}
        >
          {strings.CREATE}
        </Button>
      </Flex>
      {contextHolder}
      <Loader spinning={isStatusLoading}>
        <Table
          dataSource={contracts}
          columns={columns({
            deleteTag,
            setModalVision,
            setContractIdModal,
            downloadContract,
            history,
            deleteContract,
            hasTopManagerOrBranchDirector,
            statuses: contractStatuses,
            onStatusChange
          })}
          pagination={false}
          bordered
          rowKey="id"
        />
      </Loader>
      <ModalTags
        visible={modalVision}
        tags={contractsTags}
        modalClose={modalClose}
        addNewTagToFamilyContract={addNewTagToFamilyContract}
        contractIdModal={contractIdModal}
        familyId={id}
      />
    </Loader>
  );
};

export default { ExistingContracts };
