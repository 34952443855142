import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { Spin, Typography, Col, Row, Tabs, Table } from 'antd';
import { WorkProgress as StWorkProgress } from './styles';
import { columns } from './columns';
import { WorkProgress as IWorkProgress } from 'types/TeacherAccount/types';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { useTeacherRepository } from 'repos/TeacherRepository';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Title } = Typography;
const { TabPane } = Tabs;

export const WorkProgress = () => {
  const [strings] = useLanguageContext();
  const teacherRepository = useTeacherRepository();
  const [hasLoad, setHasLoad] = useState<boolean>(false);
  const [lessons, setLessons] = useState<IWorkProgress[]>([]);
  const [lessonsPrevMonth, setLessonsPrevMonth] = useState<IWorkProgress[]>([]);

  const fetchWorkProgress = async (): Promise<any> => {
    try {
      setHasLoad(true);
      const [{ data: notFilled }, { data: archive }] = await Promise.all([
        teacherRepository.getNotFilledWorkProgress(),
        teacherRepository.getArchiveWorkProgress()
      ]);
      setLessons(notFilled);
      setLessonsPrevMonth(archive);
      setHasLoad(false);
    } catch {
      setLessons([]);
      setLessonsPrevMonth([]);
      setHasLoad(false);
    }
  };

  useEffect(() => {
    fetchWorkProgress();
  }, []);

  return (
    <ErrorBoundary>
      <StWorkProgress>
        <Row>
          <Col lg={12}>
            <Title level={3}>{strings.WORK_PROGRESS}</Title>
          </Col>
        </Row>
        <Row>
          <Col lg={24}>
            <Spin spinning={hasLoad} tip={strings.LOADING_WORK_PROGRESS}>
              <Tabs defaultActiveKey="1">
                <TabPane tab={strings.REGISTERS_TO_FILL_IN} key="1">
                  <Table
                    dataSource={lessons}
                    columns={columns(strings, true)}
                    rowKey="lessonId"
                    bordered={false}
                    pagination={lessons.length > 20 ? { pageSize: 20 } : false}
                  />
                </TabPane>
                <TabPane tab={strings.ARCHIVE} key="2">
                  <Table
                    dataSource={lessonsPrevMonth}
                    columns={columns(strings)}
                    rowKey="lessonId"
                    bordered={false}
                    pagination={lessonsPrevMonth.length > 20 ? { pageSize: 20 } : false}
                  />
                </TabPane>
              </Tabs>
            </Spin>
          </Col>
        </Row>
      </StWorkProgress>
    </ErrorBoundary>
  );
};

export default { WorkProgress };
