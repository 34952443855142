import React, { useState, useEffect } from 'react';
import { EditOutlined, ReloadOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import {Row, Col, Select, Input, Button, Avatar, Upload, Checkbox, message, DatePicker} from 'antd';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { Role, Tag as ITag } from 'types';
import { useUserContext } from 'contexts/UserContext';
import {beforeImageUpload, dateFormat, getAllFormData, getAvatarUrl, getBase64} from 'helpers';
import { useAdminRepository } from 'repos/AdminRepository';
import { AdminCard } from './AdminCard';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { match } from 'react-router';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useHistory } from 'react-router-dom';
import { BASE_ADMINS } from 'Admin/People/Administrators/routes';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import { booleanFormatValues } from 'helpers/booleanFomatValues';
import { formatDateValues } from 'helpers/dates';
import Breadcrumbs from 'Global/components/Breadcrumbs';
import { StyledForm, StyledSeparator, StyledRow } from 'Global/styles';
import { FEMALE, MALE } from 'Global/constants';
import { PhoneInput } from 'Global/components/PhoneInput';
import dayjs from "dayjs";

const { Option } = Select;

interface IAdminFormProps {
  form: WrappedFormUtils;
}

export const AdminForm = Form.create<IAdminFormProps>({})((props: IAdminFormProps) => {
  const [strings] = useLanguageContext();
  const location = useLocation();
  const [defaultAdmin, setDefaultAdmin] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [previewAvatar, setPreviewAvatar] = useState<string>('');
  const {
    form,
    form: { getFieldDecorator, getFieldsValue, validateFieldsAndScroll }
  } = props;

  const [user] = useUserContext();
  const hasTopManager = user?.hasRoles?.topManager;
  const adminRepository = useAdminRepository();
  const globalRequestsRepository = useGlobalRequestsRepository();
  const adminRole = defaultAdmin?.user?.roles && defaultAdmin?.user?.roles?.find(item => item !== 'ROLE_ADMIN');

  const {
    params: { id: adminId }
  }: match<{ id: string }> = useRouteMatch();

  const { push } = useHistory();
  const { roles, branches, ageTags, adminTags } = useGlobalCollectionsContext();
  const adminRoleId = roles.find(item => adminRole === item.sysName)?.id;

  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        setHasLoading(true);
        try {
          const params = booleanFormatValues(formatDateValues(values));
          if (!user?.hasRoles?.topManager) {
            params['branch'] = user?.branch?.id;
          }
          if (adminId) {
            const { status } = await adminRepository.editAdminById(Number(adminId), params);
            if (status === 200) {
              //если изменили аватар
              if (avatar) {
                uploadAvatar(adminId);
              }
              push(`${BASE_ADMINS}/${adminId}/`);
            }
            message.success(strings.ADMIN_SUCCESSFULLY_EDITED, 2);
          } else {
            const {
              status,
              data: { id: createdAdminId }
            } = await adminRepository.createAdmin(params);
            if (status === 200) {
              //если изменили аватар
              if (avatar) {
                uploadAvatar(createdAdminId);
              }
              message.success(strings.ADMIN_SUCCESSFULLY_CREATED, 2);
              push(`${BASE_ADMINS}/${createdAdminId}/`);
            }
          }
        } catch (err) {
          message.error(strings.SORRY_ERROR, 2);
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  /**
   * @description Запрос на добавление аватарки
   * @return {Promise<any>}
   */
  const uploadAvatar = async (id): Promise<any> => {
    try {
      await globalRequestsRepository.uploadAvatar(getAllFormData({ id: id, type: 'admin', file: avatar }));
    } catch {}
  };

  const handleChangeAvatar = async (upload): Promise<any> => {
    const imageHash = await getBase64(upload.file);
    setPreviewAvatar(imageHash);
    setAvatar(upload.file);
  };

  useEffect(() => {
    if (adminId) {
      (async () => {
        try {
          const { data } = await adminRepository.getAdminById(adminId);
          setPreviewAvatar(getAvatarUrl(data?.mainPhoto));
          setDefaultAdmin(data);
        } catch {}
      })();
    }
  }, [adminId]);

  function getActionString() {
    if (adminId) {
      return `${strings.EDIT} ${defaultAdmin?.user?.name} ${defaultAdmin?.user?.surname}`;
    }

    return strings.ADD_NEW_ADMIN;
  }

  return (
    <Row style={{ margin: 20 }}>
      <Row>
        <Breadcrumbs location={location} />
      </Row>
      <Row>
        <Col lg={14} style={{ paddingInlineEnd: 20 }}>
          <StyledForm layout="vertical" onSubmit={handleSubmit}>
            <StyledRow>
              <Col lg={hasTopManager ? 8 : 16} style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ position: 'relative', marginInlineStart: '-10px' }}>
                  <Avatar
                    src={previewAvatar}
                    style={{ backgroundColor: '#e6e6e6', opacity: 0.8 }}
                    size={100}
                    icon={<UserOutlined />}
                  />
                  <Upload
                    className="uploadAvatar"
                    accept={'image/*'}
                    multiple={false}
                    showUploadList={false}
                    onChange={handleChangeAvatar}
                    beforeUpload={beforeImageUpload}
                  >
                    <Button shape="circle" icon={<EditOutlined />} style={{ boxShadow: '0 0 6px rgb(0 0 0 / 30%)' }} />
                  </Upload>
                </div>
              </Col>
              {hasTopManager && (
                <Col lg={8}>
                  <MyFormItem label={strings.BRANCH}>
                    {getFieldDecorator('branch', {
                      initialValue: defaultAdmin?.branch?.id,
                      rules: [{ required: true, message: strings.BRANCH_IS_REQUIRED }]
                    })(
                      <Select placeholder={strings.BRANCH}>
                        {branches?.map(branch => (
                          <Option value={branch.id} key={branch.id}>
                            {branch.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </MyFormItem>
                </Col>
              )}
              <Col lg={8}>
                <MyFormItem label={strings.SEX}>
                  {getFieldDecorator('gender', {
                    initialValue: defaultAdmin && String(defaultAdmin?.sex),
                    rules: [{ required: false }]
                  })(
                    <Select placeholder={strings.SELECT_GENDER}>
                      <Option value="1" key={FEMALE}>
                        {strings.FEMALE}
                      </Option>
                      <Option value="0" key={MALE}>
                        {strings.MALE}
                      </Option>
                    </Select>
                  )}
                </MyFormItem>
              </Col>
            </StyledRow>
            <StyledRow>
              <Col lg={8}>
                <MyFormItem label={strings.FIRST_NAME}>
                  {getFieldDecorator('name', {
                    initialValue: defaultAdmin?.user?.name,
                    rules: [{ required: true, message: strings.NAME_IS_REQUIRED }]
                  })(<Input />)}
                </MyFormItem>
              </Col>
              <Col lg={8}>
                <MyFormItem label={strings.LAST_NAME}>
                  {getFieldDecorator('surname', {
                    initialValue: defaultAdmin?.user?.surname,
                    rules: [{ required: false }]
                  })(<Input />)}
                </MyFormItem>
              </Col>
              <Col lg={8}>
                <MyFormItem label={strings.FATHERS_NAME}>
                  {getFieldDecorator('patronymic', {
                    initialValue: defaultAdmin?.user?.patronymic
                  })(<Input />)}
                </MyFormItem>
              </Col>
              <Col lg={8}>
                <PhoneInput form={form} name="phone" label={strings.MOBILE} initValue={defaultAdmin?.user?.phone} />
              </Col>
              <Col lg={8}>
                <MyFormItem label={strings.EMAIL}>
                  {getFieldDecorator('email', {
                    initialValue: defaultAdmin?.user?.email,
                    rules: [{ required: false }]
                  })(<Input />)}
                </MyFormItem>
              </Col>
              <Col lg={8}>
                <MyFormItem label={strings.BIRTHDAY}>
                  {getFieldDecorator(`birthday`, {
                    initialValue: defaultAdmin?.user?.birthDate && dayjs(defaultAdmin?.user?.birthDate)
                  })(<DatePicker format={dateFormat} placeholder={strings.BIRTHDAY} />)}
                </MyFormItem>
              </Col>
              <Col lg={24}>
                <MyFormItem label={strings.ROLE}>
                  {getFieldDecorator('role', {
                    initialValue: adminRoleId && adminRoleId,
                    rules: [{ required: true }]
                  })(
                    <Select placeholder={strings.SELECT_POSITION}>
                      {roles?.map((role: Role) => (
                        <Option value={role.id} key={role.id}>
                          {role.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </MyFormItem>
              </Col>
            </StyledRow>
            <StyledRow>
              <Col lg={8}>
                <MyFormItem label={strings.ORG_1_ID}>
                  {getFieldDecorator('org1Id', {
                    initialValue: defaultAdmin?.user?.org1Id,
                    rules: [{ required: false }]
                  })(<Input />)}
                </MyFormItem>
              </Col>
              <Col lg={8}>
                <MyFormItem label={strings.INTERNAL_NUMBER}>
                  {getFieldDecorator('sipuniNum', {
                    initialValue: defaultAdmin?.sipuniNum,
                    rules: [{ required: false }]
                  })(<Input />)}
                </MyFormItem>
              </Col>
              <Col lg={8}>
                <MyFormItem>
                  {getFieldDecorator('isActive', {
                    initialValue: defaultAdmin ? defaultAdmin?.isActive : true,
                    valuePropName: 'checked',
                    rules: [{ required: false }]
                  })(<Checkbox style={{ marginTop: 35 }}>{strings.ACTIVE}</Checkbox>)}
                </MyFormItem>
              </Col>
            </StyledRow>
            <StyledSeparator />
            <StyledRow>
              <Col lg={24}>
                <MyFormItem label={strings.TAGS_AGE}>
                  {getFieldDecorator('tagsAge', {
                    initialValue: defaultAdmin ? defaultAdmin?.tagsAge?.map(item => item.id) : undefined,
                    rules: [{ required: false }]
                  })(
                    <Select
                      size="large"
                      mode="multiple"
                      style={{ width: '100%' }}
                      tokenSeparators={[',']}
                      placeholder={strings.SELECT_TAGS_AGE}
                    >
                      {ageTags.map(tag => (
                        <Option value={tag.id} key={tag.id}>
                          {tag.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </MyFormItem>
              </Col>
              <Col lg={24}>
                <MyFormItem label={strings.TAGS}>
                  {getFieldDecorator('tags', {
                    initialValue: defaultAdmin ? defaultAdmin?.tags.map(item => item.id) : undefined,
                    rules: [{ required: false }]
                  })(
                    <Select
                      size="large"
                      mode="multiple"
                      style={{ width: '100%' }}
                      tokenSeparators={[',']}
                      placeholder={strings.SELECT_TAGS}
                    >
                      {adminTags.map((tag: ITag) => (
                        <Option value={tag.id} key={tag.id}>
                          {tag.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </MyFormItem>
              </Col>
            </StyledRow>
            <StyledRow>
              <Col lg={24}>
                <MyFormItem label={strings.COMMENTS}>
                  {getFieldDecorator('comment', {
                    initialValue: defaultAdmin?.comment,
                    rules: [{ required: false }]
                  })(<Input.TextArea style={{ height: 90 }} />)}
                </MyFormItem>
              </Col>
            </StyledRow>
            <StyledRow style={{ textAlign: 'right' }}>
              <Button type="primary" icon={<ReloadOutlined />} loading={hasLoading} htmlType="submit" size="large">
                {getActionString()}
              </Button>
            </StyledRow>
          </StyledForm>
        </Col>
        <Col lg={10}>
          <AdminCard previewAvatar={previewAvatar} {...getFieldsValue()} />
        </Col>
      </Row>
    </Row>
  );
});
