import React from 'react';
import { StyledEvent, StyledEventParagraph } from 'Global/modules/Schedule/styles';
import { ZoomIcon } from 'Global/modules/Schedule/ScheduleLessonItem/ZoomIcon';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { getSubjectNameByLang } from 'Student&Parent/helpers';
import { useLanguageContext } from 'contexts/LanguageContext';

/**
 * @description Плашка с уроком у студента и родителя
 * @param startTime
 * @param endTime
 * @param event
 */
export const StudentScheduleLessonItem = ({ startTime, endTime, event, hasZoom }) => {
  const [, lang] = useLanguageContext();
  const {
    room: { name: roomName }
  } = event;
  const { subjectsTranslation } = useGlobalCollectionsContext();
  const subject = event?.lesson?.course?.subject;

  return (
    <StyledEvent>
      <StyledEventParagraph>
        {startTime[0] === '0' ? startTime.replace('0', '') : startTime}-{endTime}{' '}
      </StyledEventParagraph>
      <StyledEventParagraph>
        {hasZoom && <ZoomIcon />}
        <span>{roomName}</span>
      </StyledEventParagraph>
      <StyledEventParagraph>
        {subject && getSubjectNameByLang(subjectsTranslation, lang, subject.id)}
      </StyledEventParagraph>
      {event.hasOwnProperty('event') && <StyledEventParagraph>{event?.event?.name}</StyledEventParagraph>}
    </StyledEvent>
  );
};
