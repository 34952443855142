import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Row, Col, Button, Input, InputNumber, Checkbox, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { Container } from 'Global/styles';
import { Title } from 'Global/components/Title';
import { Families } from 'api/Families';
import { BASE_SOURCE_OF_REFERENCE } from '../routes';
import { useLanguageContext } from 'contexts/LanguageContext';

import { FormComponentProps } from '@ant-design/compatible/es/form';

interface SourceReferenceNewProps extends FormComponentProps {}

/**
 * @description Добавление нового источника ссылки
 * @param {SourceReferenceNewProps} props
 * @return {React.ReactNode}
 */
const SourceReferenceNewTemplate = (props: SourceReferenceNewProps) => {
  const [strings] = useLanguageContext();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    form: { getFieldDecorator, validateFields }
  } = props;
  // Hooks
  const { goBack, push } = useHistory();

  /**
   * @description Отправка формы
   * @param {React.FormEvent<HTMLFormElement>} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        await Families.addLearnedFrom(values)
          .then(() => {
            message.success(strings.SOURCE_OF_REFERENCE_SUCCESSFULLY_ADDED);

            // После добавления источника через секунду отправить на главную страницу
            setTimeout(() => {
              push(BASE_SOURCE_OF_REFERENCE);
            }, 1000);
          })
          .catch(() => {})
          .finally(() => setLoading(false));
      }
    });
  };
  return (
    <Container>
      <Title goBack={goBack}>{strings.SOURCE_OF_REFERENCE_NEW}</Title>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <MyFormItem label={strings.TITLE}>
          {getFieldDecorator('title', {
            rules: [{ required: true, message: `${strings.TITLE} ${strings.IS_REQUIRED}` }]
          })(<Input />)}
        </MyFormItem>
        <MyFormItem label={strings.POSITION}>{getFieldDecorator('position')(<InputNumber />)}</MyFormItem>
        <Row>
          <Col lg={6}>
            <MyFormItem label={strings.IS_ACTIVE}>{getFieldDecorator('isActive')(<Checkbox />)}</MyFormItem>
          </Col>
          <Col lg={6}>
            <MyFormItem label={strings.SHOW_IN_FORM}>{getFieldDecorator('showInForm')(<Checkbox />)}</MyFormItem>
          </Col>
        </Row>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          disabled={loading}
          loading={loading}
          style={{ marginTop: 20 }}
        >
          {strings.ADD}
        </Button>
      </Form>
    </Container>
  );
};

export const SourceReferenceNew = Form.create<SourceReferenceNewProps>({})(SourceReferenceNewTemplate);

export default { SourceReferenceNew };
