import { reversedDateFormatForBackend, dateFormatForBackend, dateTimeFormatForBackend } from './dates';
import dayjs, {isDayjs} from "dayjs";

/**
 * @description Форматирует данные из формы, если в них присутствует дата (moment), то меняет формат для бекэнда
 * @param {any} formValues
 */
export const momentFormatValues = (formValues: any, formatDate?: string) => {
  const backendFormat = formatDate === 'reversed' ? reversedDateFormatForBackend : dateFormatForBackend;
  for (let i in formValues) {
    const value = formValues[i];
    if (isDayjs(value)) {
      formValues[i] = dayjs(value).format(backendFormat);
    }
  }

  return formValues;
};
/**
 * @description Форматирует данные из формы для schedule, если в них присутствует дата (moment), то меняет формат для бекэнда
 * @param {any} formValues
 */
export const scheduleDayjsFormatValues = formValues => {
  for (let i in formValues) {
    const value = formValues[i];
    if (isDayjs(value)) {
      formValues[i] = dayjs(value).format(dateTimeFormatForBackend);
    }
  }

  return formValues;
};

export default { momentFormatValues, scheduleDayjsFormatValues };
