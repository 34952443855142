import React, { useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table, Row, Modal } from 'antd';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { Link, useHistory } from 'react-router-dom';
import { Filter } from './Filter';
import { ITeacherWage, ITeacherWagesFilterParams } from 'types/finance';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import BaseFilter from 'helpers/BaseFilter';
import { getColumns } from './columns';
import { BASE_TEACHERS_WAGES, BASE_TEACHERS_WAGES_NEW } from '../../routes';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { first, isEmpty } from 'lodash';
import { convertBooleanToNumber } from 'helpers';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';

export const TeachersWages = () => {
  const [strings] = useLanguageContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [wages, setWages] = useState<ITeacherWage[]>([]);
  const defFilterValues = { active: 1 };
  const [{ confirm }, contextHolder] = Modal.useModal();

  const [user] = useUserContext();
  const { goBack, push } = useHistory();
  const { locationSearchParams } = useLocationSearchParams();
  const globalRequestsRepository = useGlobalRequestsRepository();
  const { branches } = useGlobalCollectionsContext();
  const FilterService = new BaseFilter();

  /**
   * @description Фильтр
   * @param {object} params
   * @return {Promise<any>}
   */
  const onFilter = async (params: ITeacherWagesFilterParams): Promise<any> => {
    setHasLoading(true);
    try {
      const values = convertBooleanToNumber(params);
      if (branches.length === 1) {
        // @ts-ignore
        values['branch'] = first(branches).id;
      }
      const { data } = await globalRequestsRepository.getTeacherWages(values);
      setWages(data);
      push({ search: FilterService.getQueryStringParams(values) });
    } finally {
      setHasLoading(false);
    }
  };
  /**
   * @description Фильтр
   * @param {object} params
   * @return {Promise<any>}
   */
  const onDeleteTeacherWage = (id: number): void => {
    confirm({
      title: strings.ARE_YOU_SURE,
      okType: 'danger',
      async onOk() {
        setHasLoading(true);
        try {
          await globalRequestsRepository.deleteTeacherWage(id);
          onFilter(locationSearchParams);
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  useEffect(() => {
    if (branches.isNotEmpty()) {
      if (isEmpty(locationSearchParams)) {
        onFilter(defFilterValues);
      } else {
        onFilter(locationSearchParams);
      }
    }
  }, [branches]);

  const getActionColumns = () => [
    {
      title: '',
      dataIndex: 'id',
      key: 'action',
      render: (id: number) => (
        <Row align="middle" style={{ gap: 5 }}>
          <Link to={`${BASE_TEACHERS_WAGES}/${id}/edit`}>
            <Button icon={<EditOutlined />} />
          </Link>
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
              onDeleteTeacherWage(id);
            }}
          />
        </Row>
      )
    }
  ];

  return (
    <Container>
      {contextHolder}
      <StPageHeader
        onBack={goBack}
        title={strings.TEACHER_S_WAGES}
        extra={[
          <Button key="1" type="primary" onClick={() => push(BASE_TEACHERS_WAGES_NEW)}>
            {strings.ADD}
          </Button>
        ]}
      >
        <Filter defFilterValues={defFilterValues} onFilter={onFilter} />
      </StPageHeader>
      <Row gutter={[10, 10]}>
        <Table
          dataSource={wages}
          columns={[...getColumns(strings), ...getActionColumns()]}
          pagination={false}
          loading={{ spinning: hasLoading }}
        />
      </Row>
    </Container>
  );
};

export default { TeachersWages };
