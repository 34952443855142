import React, { useEffect, useState } from 'react';
import CoursesService from 'helpers/services/CoursesService';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Row, Col, Select, Input, Checkbox } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { OriginalCoursesParams } from 'types/courses';
import { Branch, Subject } from 'types';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { OC_LIMIT } from 'Admin/Courses/OriginalCourses/index';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { StageSelect } from 'Global/components/FormComponentsCompatible/Selects/StageSelect';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { Global } from 'api';
import { FilterButtons } from 'Global/components/FilterButtons';

const { Option } = Select;

interface FilterProps extends FormComponentProps {
  fetchOriginalCourses: (params: OriginalCoursesParams) => Promise<any>;
}

/**
 * @description Фильр Original courses
 * @return {React.ReactNode}
 */
export const Filter = Form.create<FilterProps>({})((props: FilterProps) => {
  const {
    form,
    form: { getFieldDecorator, validateFields, resetFields },
    fetchOriginalCourses
  } = props;

  const [strings] = useLanguageContext();
  const { subjects: subjectsList, branches: branchesList } = useGlobalCollectionsContext();
  const [branches, setBranches] = useState<Branch[]>(branchesList);

  const services = new CoursesService();
  const { locationSearchParams } = useLocationSearchParams();
  const { stage, subjects, title, branch, lessonName } = locationSearchParams || {};
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);

  /**
   * @description Отправка формы
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (!err) {
        const params = services.getFilterParams(values, 1, null, 50);
        try {
          setHasSubmitLoading(true);
          await fetchOriginalCourses(params);
        } catch {
        } finally {
          setHasSubmitLoading(false);
        }
      }
    });
  };

  const handleClear = async (): Promise<any> => {
    resetFields();
    await fetchOriginalCourses({ isActive: 1, limit: OC_LIMIT, page: 1 });
  };

  /**
   * @description Получить все бранчи
   * @returns {Promise<void>}
   */
  const fetchAllBranches = async (): Promise<void> => {
    const { data } = await Global.getBranches({ all: true });
    setBranches(data);
  };
  useEffect(() => {
    fetchAllBranches();
  }, []);

  return (
    <ErrorBoundary>
      <Form onSubmit={handleSubmit}>
        <Row gutter={[10, 10]}>
          <Col xs={24} lg={6}>
            {getFieldDecorator('branch', {
              initialValue: branch,
              rules: [{ required: false }]
            })(
              <Select placeholder={strings.BRANCH}>
                {branches &&
                  branches.map((branch: Branch) => (
                    <Option value={branch.id} key={branch.id}>
                      {branch.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Col>
          <Col xs={24} lg={6}>
            <StageSelect initialValue={stage} form={form} />
          </Col>
          <Col xs={24} lg={6}>
            {getFieldDecorator('title', {
              initialValue: title
            })(<Input placeholder={strings.TITLE} />)}
          </Col>
          <Col xs={24} lg={6}>
            {getFieldDecorator('subjects', {
              initialValue: subjects && subjects?.map((s: string) => Number(s)),
              rules: [{ required: false }]
            })(
              <Select placeholder={strings.SUBJECT} mode="multiple" maxTagCount="responsive">
                <Option value="">&nbsp;</Option>
                {subjectsList.map((subject: Subject) => (
                  <Option value={subject.id} key={subject.id}>
                    {subject.name}
                  </Option>
                ))}
              </Select>
            )}
          </Col>
          <Col xs={24} lg={6}>
            {getFieldDecorator('lessonName', {
              initialValue: lessonName
            })(<Input placeholder={strings.LESSON_TITLE} />)}
          </Col>
          <Col xs={24} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
            {getFieldDecorator('isActive', {
              initialValue: true,
              valuePropName: 'checked',
              rules: [{ required: false }]
            })(<Checkbox>{strings.ACTIVE}</Checkbox>)}
          </Col>
        </Row>
        <FilterButtons handleClear={handleClear} hasSubmitLoading={hasSubmitLoading} />
      </Form>
    </ErrorBoundary>
  );
});

export default { Filter };
