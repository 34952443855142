import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Forbidden } from 'Global/modules/Errors/403';
import { Link } from 'react-router-dom';
import { useLanguageContext } from 'contexts/LanguageContext';

/**
 * @description Страница отсутствия доступа в журнале
 * @return {React.ReactNode}
 */
export const LessonForbidden = () => {
  const [strings] = useLanguageContext();
  return (
    <Forbidden subTitle={strings.NO_ACCESS_LESSON}>
      <Button size="large" type="primary" icon={<ArrowLeftOutlined />}>
        <Link to="/" style={{ color: '#fff', paddingInlineStart: '10px' }}>
          {strings.BACK_HOME}
        </Link>
      </Button>
    </Forbidden>
  );
};
