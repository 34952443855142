import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { PhoneOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import styled from 'styled-components';
import { FeedbackModal } from 'Global/modules/Support/Modal';
import { menu } from 'Global/components/LeftMenu/menu';
import { menuListForAdmin } from 'Admin/menuList';
import { menuListForAcademicExpert } from 'Admin/AcademicExpert/menuList';
import { menuListForTeacher } from 'Teacher/menuList';
import { menuListForPeople } from 'Student&Parent/menuList';
import { Link } from 'react-router-dom';
import { useUserContext } from 'contexts/UserContext';
import { SUPPORT } from 'Global/modules/Support/routes';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Chameleon } from 'Global/components/Chameleon';
import { Tutors } from '../../../Student&Parent/Main/Tutors';
import { cleanPath } from './helper';
import { BASE_SUPPORT } from '../../routes';
import { debounce } from 'lodash';

const { Sider } = Layout;

const StSider = styled(Sider)`
  position: fixed;
  z-index: 10;
  top: 90px;
  bottom: 0;
  border-top-right-radius: 6px;
  box-shadow: 0px 0px 46px -15px rgba(0, 0, 0, 0.12);

  .ant-layout-sider-zero-width-trigger .anticon.anticon-bars {
    font-size: large;
    padding: 5px;
  }

  .ant-menu {
    border: 0;
    padding-top: 15px;
    border-top-right-radius: 5px;
  }
  .extra-menu-item {
    height: auto;
    margin-bottom: 40px;
  }
  :not(.ant-layout-sider-collapsed) .ant-menu-item-only-child {
    margin: 0;
  }
  .ant-menu-item-selected.extra-menu-item,
  .ant-menu-item-active.extra-menu-item {
    color: inherit;
  }
  .ant-menu-submenu-title {
    padding-inline-start: 10px;
  }

  .ant-layout-sider-children {
    overflow: auto;
  }

  .ant-layout-sider-zero-width-trigger {
    position: fixed;
    top: 90px;
    border-radius: 0 5px 5px 0;
  }
`;

const MOBILE_WIDTH = 576;
const DESKTOP_COLLAPSED_MENU_WIDTH = 80;
const MOBILE_COLLAPSED_MENU_WIDTH = 0;

interface LeftMenuProps {
  setHasMenuCollapse: (hasCollaplse: boolean) => void;
}

const { SubMenu } = Menu;

/**
 * @description Компонент левого меню
 * @param {object} user
 * @return {React.ReactNode}
 */
export const LeftMenu = React.memo(({ setHasMenuCollapse }: LeftMenuProps) => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const location = useLocation();
  const hasParentOrStudentRole = user?.hasRoles?.parent || user?.hasRoles?.student;

  const [showFeedBack, setFeedBackShow] = useState<boolean>(false);
  const showFeedBackModal = () => setFeedBackShow(true);
  const hideShowModal = () => setFeedBackShow(false);
  const [width, setWidth] = useState<number>(0);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const onCollapse = (collapse: boolean): void => {
    setCollapseValue(collapse);
  };

  const handleClick = () => {
    if (width <= MOBILE_WIDTH) {
      setCollapseValue(true);
    }
  };

  const setCollapseValue = (collapse: boolean): void => {
    setIsCollapsed(collapse);

    // Значение записывается только в десктопе
    if (width > MOBILE_WIDTH) {
      localStorage.setItem('isCollapsed', collapse ? '1' : '0');
    }
  };

  const getMenuList = useMemo(() => {
    if (user?.hasRoles?.teacher) return menuListForTeacher(strings, user);
    if (user?.hasRoles?.admin) return menuListForAdmin(user);
    if (user?.hasRoles?.academicExpert) return menuListForAcademicExpert(strings);
    if (hasParentOrStudentRole) return menuListForPeople(strings, user);
    return [];
  }, [user]);

  const activeKeyMenuItem = React.useMemo(() => {
    const path = location.pathname.substring(1);
    return cleanPath(path);
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = debounce(() => setWidth(window.innerWidth), 100);
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const initialCollapse = width <= MOBILE_WIDTH ? true : Boolean(Number(localStorage.getItem('isCollapsed')));
    setIsCollapsed(initialCollapse);
  }, [width]);

  useEffect(() => {
    setHasMenuCollapse(isCollapsed);
  }, [isCollapsed]);

  return (
    <StSider
      theme="light"
      onCollapse={onCollapse}
      width={220}
      collapsible
      collapsed={isCollapsed}
      collapsedWidth={width <= MOBILE_WIDTH ? MOBILE_COLLAPSED_MENU_WIDTH : DESKTOP_COLLAPSED_MENU_WIDTH}
      breakpoint="xs"
      className="left-menu"
    >
      <Menu theme="light" mode="vertical" selectedKeys={[activeKeyMenuItem || '']} onClick={handleClick}>
        {menu(getMenuList)}
        <hr style={{ opacity: '0.3' }} />
        <SubMenu
          key={cleanPath(BASE_SUPPORT.substring(1))}
          title={
            <>
              <span className="anticon" style={{ position: 'relative', top: '5px' }}>
                <Chameleon />
              </span>
              <span>{strings.CONTACT_AMI_TEAM}</span>
            </>
          }
        >
          <Menu.Item>
            <a href="#" onClick={showFeedBackModal}>
              {strings.CREATE_NEW_REQUEST}
            </a>
          </Menu.Item>
          <Menu.Item key={cleanPath(BASE_SUPPORT.substring(1))}>
            <Link to={SUPPORT}>
              <span>{strings.MY_REQUESTS}</span>
            </Link>
          </Menu.Item>
        </SubMenu>
        {hasParentOrStudentRole && <hr style={{ opacity: '0.3' }} />}
        {hasParentOrStudentRole && (
          <Menu.Item className="extra-menu-item">
            <PhoneOutlined />
            <span>
              {strings.TUTORS}:
              <Tutors />
            </span>
          </Menu.Item>
        )}
        {showFeedBack && <FeedbackModal onHide={hideShowModal} hasShow={showFeedBack} />}
      </Menu>
    </StSider>
  );
});

export default { LeftMenu };
