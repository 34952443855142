import { httpService } from 'api/core';
import * as lesson from './lesson';
import * as event from './event';
import * as slot from './slot';
import queryString from 'query-string';
import { BulkUpdateParams, GetParamsRooms, ScheduleLogParams } from 'types/Schedule';

/**
 * @description получение комнат
 * @return {Promise<any>}
 */
const getRooms = (params?: GetParamsRooms): Promise<any> => httpService.get('/api/rooms', params);

/**
 * @description получение домашнего задания
 * @return {Promise<any>}
 */
const getDownloadHomework = (): Promise<any> => httpService.get('/api/schedule/download-homework');

/**
 * @description Получить файлы урока
 * @param {number} lessonId
 * @return {Promise<any>}
 */
const getLessonFiles = (lessonId: number): Promise<any> => httpService.get(`/api/lessons/${lessonId}/materials`);

/**
 * @description Bulk update
 * @param {object} params
 * @return {Promise<any>}
 */
const setBulkUpdate = (params: BulkUpdateParams): Promise<any> => httpService.put('/api/slots/lesson/bulk', params);

/**
 * @description Bulk delete
 * @param {object} params
 * @return {Promise<any>}
 */
const deleteBulkSlots = (params): Promise<any> => {
  const queryParams = queryString.stringify(params, { arrayFormat: 'bracket' });
  return httpService.delete(`/api/slots/lesson/bulk?${queryParams}`);
};
/**
 * @description Логи расписания
 * @param {ScheduleLogParams} params
 * @return {Promise<any>}
 */
const getLogs = (params: ScheduleLogParams) => httpService.get('/api/v1/log/schedule', params, null, true);

/**
 * @description Список экшин типов
 * @return {Promise<any>}
 */
const getActionList = (): Promise<any> => httpService.get('/api/v1/log/schedule/action-types/list', null, null, true);

export const Schedule = {
  getRooms,
  getDownloadHomework,
  getLessonFiles,
  setBulkUpdate,
  deleteBulkSlots,
  getLogs,
  getActionList,
  ...lesson.default,
  ...event.default,
  ...slot.default
};

export default { Schedule };
