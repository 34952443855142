import React, { useState } from 'react';
import { Button, Row, Col, Input, Spin, message, Form } from 'antd';
import queryString from 'query-string';
import { Courses } from 'api/Courses';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import { useLanguageContext } from 'contexts/LanguageContext';
import { match } from 'react-router';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { SubjectGoalsForAddLesson } from './SubjectGoalsForAddLesson';
import { useCoursesRepository } from 'repos/CoursesRepository';
import MyJoditEditor from 'Global/components/MyJoditEditor';

/**
 * @description Добавление урока в курс
 * @param {OriginalLessonAddProps} props
 * @return {React.ReactNode}
 */
export const OriginalLessonAdd = () => {
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();

  const [form] = Form.useForm();
  const { goBack } = useHistory();
  const location = useLocation();
  const coursesRepository = useCoursesRepository();

  const unit = queryString.parse(location?.search).unitId;
  const [strings] = useLanguageContext();

  const handleSubmit = values => {
    const formValues = pickBy({ ...values, unit }, identity);
    createOriginalLesson(id, formValues);
  };

  /**
   * @description Получение урока
   * @param {number} originalCourseId
   * @param {any} params
   * @return {Promise<any>}
   */
  const createOriginalLesson = async (originalCourseId: number | string, params: any): Promise<any> => {
    const { goals } = params;
    const filteredGoals = goals.filter(item => item !== '');
    setHasLoading(true);
    try {
      const {
        data: { id }
      } = await Courses.createOriginalLesson(originalCourseId, params);
      if (filteredGoals) {
        handleAddGoalstoLesson(id, filteredGoals);
      } else {
        message.success(strings.LESSON_SUCCESSFULLY_CREATED);
        setTimeout(() => {
          goBack();
        }, 1500);
      }
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  const handleAddGoalstoLesson = async (originalLessonId: number | string, goals: string[]): Promise<any> => {
    setHasLoading(true);
    try {
      Promise.all([
        goals.forEach(async (goal): Promise<any> => {
          await coursesRepository.addOutcomesByOriginalLesson(originalLessonId, { description: goal });
        })
      ]);
      message.success(strings.LESSON_SUCCESSFULLY_CREATED);
      setTimeout(() => {
        goBack();
      }, 1500);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  return (
    <Container>
      <Spin tip={strings.SAVE_LESSON} spinning={hasLoading}>
        <StPageHeader onBack={goBack} title={strings.ADDING_A_LESSON} />
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Row>
            <Col lg={24}>
              <Form.Item
                name="topic"
                label={strings.TOPIC}
                rules={[{ required: true, message: strings.LESSON_TOPIC_REQUIRED }]}
              >
                <Input />
              </Form.Item>
              <SubjectGoalsForAddLesson />
              <Form.Item name="outcomes" label={strings.OLD_OUTCOMES}>
                <MyJoditEditor key="outcomes-editor" onChange={value => form.setFieldsValue({ outcomes: value })} />
              </Form.Item>
              <Form.Item name="keyVocabulary" label={strings.VOCABULARY}>
                <MyJoditEditor
                  key="keyVocabulary-editor"
                  onChange={value => form.setFieldsValue({ keyVocabulary: value })}
                />
              </Form.Item>
              <Form.Item name="suggestedActivities" label={strings.SUGGESTED_ACTIVITIES}>
                <MyJoditEditor
                  key="suggestedActivities-editor"
                  onChange={value => form.setFieldsValue({ suggestedActivities: value })}
                />
              </Form.Item>
              <Form.Item name="suggestedHomework" label={strings.SUGGESTED_HOMEWORK}>
                <MyJoditEditor
                  key="suggestedHomework-editor"
                  onChange={value => form.setFieldsValue({ suggestedHomework: value })}
                />
              </Form.Item>
              <Form.Item name="comment" label={strings.COMMENT}>
                <MyJoditEditor key="comment-editor" onChange={value => form.setFieldsValue({ comment: value })} />
              </Form.Item>
              <Form.Item name="resources" label={strings.RESOURCES}>
                <MyJoditEditor key="resources-editor" onChange={value => form.setFieldsValue({ resources: value })} />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Button type="primary" htmlType="submit" size="large" loading={hasLoading} disabled={hasLoading}>
                {strings.ADD_LESSON}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Container>
  );
};

export default { OriginalLessonAdd };
