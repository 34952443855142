import React, { useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Table, Button, message, Spin, Tooltip, Flex, Tag, Popconfirm } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { Link, useHistory } from 'react-router-dom';
import { Branch } from 'types';
import { IPerformerShortInfo } from 'types/performers';
import { BASE_INVOICE_LETTERS_TEMPLATES, CREATE_INVOICE_LETTERS_TEMPLATES } from '../routes';
import { useInvoicesRepository } from 'repos/InvoicesRepository';
import { ILetterTemplate } from 'types/invoice';

const { Column } = Table;

export const InvoiceLettersTemplates = () => {
  const [strings] = useLanguageContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [letterTemplates, setLetterTemplates] = useState<ILetterTemplate[]>([]);

  const { goBack } = useHistory();
  const invoicesRepository = useInvoicesRepository();

  const getInvoiceLettersTemplates = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await invoicesRepository.getLetterTemplates();
      setLetterTemplates(data);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };
  const handleDelete = async (id): Promise<any> => {
    setHasLoading(true);
    try {
      await invoicesRepository.deleteLetterTemplate(id);
      getInvoiceLettersTemplates();
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  const getColumns = [
    {
      title: strings.TITLE,
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: strings.BRANCH,
      dataIndex: 'branch',
      key: 'branch',
      render: (branch: Branch) => branch?.name
    },
    {
      title: strings.PERFORMER,
      key: 'performer',
      dataIndex: 'performer',
      render: (performer: IPerformerShortInfo) => <span>{performer?.name}</span>
    },
    {
      key: 'action',
      dataIndex: 'id',
      render: (id: number) => (
        <Row align="middle" style={{ gap: 5, flexWrap: 'nowrap' }}>
          <Link to={`${BASE_INVOICE_LETTERS_TEMPLATES}/${id}/edit`}>
            <Button size="small" icon={<EditOutlined />} />
          </Link>
          <Popconfirm
            title={strings.ARE_YOU_SURE}
            placement="left"
            okText={strings.YES}
            cancelText={strings.NO}
            onConfirm={() => handleDelete(id)}
          >
            <Button icon={<DeleteOutlined />} size="small" danger />
          </Popconfirm>
        </Row>
      )
    }
  ];

  useEffect(() => {
    getInvoiceLettersTemplates();
  }, []);

  return (
    <Container>
      <StPageHeader
        onBack={goBack}
        title={strings.INVOICE_LETTERS_TEMPLATES}
        extra={[
          <Link to={CREATE_INVOICE_LETTERS_TEMPLATES}>
            <Button type="primary">{strings.ADD}</Button>
          </Link>
        ]}
      />
      <Spin spinning={hasLoading}>
        <Row gutter={[10, 10]}>
          <Table dataSource={letterTemplates} pagination={false}>
            {getColumns.map(col => (
              <Column key={col.key} {...col} />
            ))}
          </Table>
        </Row>
      </Spin>
    </Container>
  );
};

export default { InvoiceLettersTemplates };
