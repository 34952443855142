import React from 'react';
import { Link } from 'react-router-dom';
import { IParent, IStudent, Status, Supergroup } from 'types';
import { first } from 'lodash';
import dayjs from 'dayjs';
import { dateFormat } from '../../../helpers';
import { IChildrenFilter } from '../../../types/Family';

export const getColumns = (strings, filterParams: IChildrenFilter) => [
  {
    title: strings.STUDENT,
    dataIndex: 'user',
    key: 'user',
    render: (user, record) => {
      return (
        <Link to={`/family/${record?.familyId}/student/${record?.id}`}>
          {user?.name} {user?.surname}
        </Link>
      );
    }
  },
  {
    title: strings.FAMILY,
    dataIndex: 'familyId',
    key: 'familyId',
    render: familyId => {
      return <Link to={`/family/${familyId}`}>{familyId}</Link>;
    }
  },
  {
    title: strings.SUPERGROUPS,
    dataIndex: 'supergroups',
    key: 'supergroups',
    render: (supergroups: Supergroup[]) => {
      return (
        supergroups &&
        supergroups.map(item => (
          <>
            <Link to={`/supergroup/${item?.id}`}>{item?.name}</Link>
            <br />
          </>
        ))
      );
    }
  },
  {
    title: strings.TUTOR,
    dataIndex: 'tutor',
    key: 'tutor',
    render: tutor => (
      <p>
        <Link to={`/admin/${tutor?.id}`}>
          {tutor?.user?.name} {tutor?.user?.surname}
        </Link>
      </p>
    )
  },
  {
    title: strings.STATUS,
    dataIndex: 'status',
    key: 'status',
    hidden: filterParams?.statuses?.length < 2,
    render: (status: Status) => <p>{status?.name}</p>
  },
  {
    title: <p style={{ width: 100 }}>{strings.DATE_OF_BIRTH}</p>,
    dataIndex: 'user',
    key: 'birthDate',
    hidden: !('birthDateFrom' in filterParams) || !('birthDateTo' in filterParams),
    render: user => user?.birthDate && dayjs(user.birthDate).format(dateFormat)
  },
  {
    title: strings.GENDER,
    dataIndex: 'user',
    key: 'gender',
    hidden: !('gender' in filterParams),
    render: user => (user?.gender === 1 ? strings.FEMALE : strings.MALE)
  },
  {
    title: strings.ALLERGIES,
    dataIndex: 'allergies',
    key: 'allergies',
    hidden: !('hasAllergies' in filterParams)
  },
  {
    title: strings.HEALTH_CONDITIONS,
    dataIndex: 'medicalConditions',
    key: 'medicalConditions',
    hidden: !('hasMedicalConditions' in filterParams)
  },
  {
    title: strings.PSYCHOLOGISTS_EVALUATION,
    dataIndex: 'psychologistEvaluation',
    key: 'psychologistEvaluation',
    hidden: !('hasPsychologistEvaluation' in filterParams)
  },
  {
    title: strings.TAKING_PHOTOS,
    dataIndex: 'takingPhotos',
    key: 'takingPhotos',
    hidden: !('hasTakingPhotos' in filterParams),
    render: takingPhotos => <span>{takingPhotos ? strings.YES : strings.NO}</span>
  },
  {
    title: strings.SCHOOL_TRIPS,
    dataIndex: 'schoolTrips',
    key: 'schoolTrips',
    hidden: !('hasSchoolTrips' in filterParams),
    render: schoolTrips => <span>{schoolTrips ? strings.YES : strings.NO}</span>
  },
  {
    title: strings.STUDENTS_CONTACTS,
    dataIndex: 'user',
    key: 'contacts',
    render: user => {
      const { email, phone } = user || {};
      return (
        <>
          {email && (
            <p>
              <a href={`mailto:${email}`}>{email}</a>
            </p>
          )}
          {phone && (
            <p>
              <a href={`tel:${phone}`}>{phone}</a>
            </p>
          )}
        </>
      );
    }
  },
  {
    title: strings.PARENTS,
    dataIndex: 'parents',
    key: 'parents',
    render: (parents: IParent[], record) => {
      return parents.map(item => (
        <span key={item.id}>
          <Link to={`/family/${record?.familyId}/parent/${item?.id}`}>
            {item?.user?.name} {item?.user?.surname}
          </Link>
          <br />
        </span>
      ));
    }
  },
  {
    title: strings.PARENTS_CONTACTS,
    dataIndex: 'parents',
    key: 'parents',
    render: (parents: IParent[]) => {
      const { email, phone } = first(parents)?.user || {};
      return (
        <>
          {email && (
            <p>
              <a href={`mailto:${email}`}>{email}</a>
            </p>
          )}
          {phone && (
            <p>
              <a href={`tel:${phone}`}>{phone}</a>
            </p>
          )}
        </>
      );
    }
  },
  {
    title: strings.SIBLINGS,
    dataIndex: 'siblings',
    key: 'siblings',
    render: (siblings: IStudent[], record) => {
      return siblings.map(item => (
        <span key={item.id}>
          <Link to={`/family/${record?.familyId}/student/${item?.id}`}>
            {item?.user?.name} {item?.user?.surname}
          </Link>
          <br />
        </span>
      ));
    }
  }
];
