export const baseMonth = {
  unitOfTime: 'month',
  format: 'MMM'
};

export const month = {
  unitOfTime: 'month',
  format: 'MMMM YYYY'
};

export const mockData = {
  group: 'Msk_C21_Tue_Art',
  subject: 'Art and design',
  date: 'Sep 4, 2018 10:25 - 10:50',
  teacher: {
    name: 'Stadnik Nina',
    id: 10460
  },
  tutors: [
    {
      id: 328,
      name: 'Elzhanov Olzhas'
    },
    {
      id: 361,
      name: 'Kovardakova Marina'
    },
    {
      id: 260,
      name: 'Bashakina Maria'
    }
  ]
};

export const mockEvents = [
  {
    start: new Date(),
    end: new Date(),
    title: 'D1 Science',
    studentsCount: 5,
    id: Math.random()
  }
];

export const mockCustomMaterials = [
  {
    studentName: 'Lancmane Lāsma',
    materials: [
      {
        name: 'B9172EEA-5CCA-43CF-A264-43DC84D9919C.jpeg',
        date: '13.09.2019'
      },
      {
        name: 'B9172EEA-5CCA-43CF-A264-43DC84D9919C.jpeg',
        date: '13.09.2019'
      },
      {
        name: 'B9172EEA-5CCA-43CF-A264-43DC84D9919C.jpeg',
        date: '13.09.2019'
      }
    ]
  },
  {
    studentName: 'Lancmane Lāsma',
    materials: [
      {
        name: 'B9172EEA-5CCA-43CF-A264-43DC84D9919C.jpeg',
        date: '13.09.2019'
      },
      {
        name: 'B9172EEA-5CCA-43CF-A264-43DC84D9919C.jpeg',
        date: '13.09.2019'
      },
      {
        name: 'B9172EEA-5CCA-43CF-A264-43DC84D9919C.jpeg',
        date: '13.09.2019'
      }
    ]
  }
];

export const mockApprovedMaterialItems = [
  {
    id: 1231,
    title: 'Lesson_1_Holidays.pdf',
    date: '09.09.2018 13:50'
  },
  {
    id: 4313,
    title: 'Lesson_1_Holidays.pdf',
    date: '09.09.2018 13:50'
  }
];

export const mockDataTable = [
  {
    student: 'Ксения Жулябина',
    lastFeedback: 'test 001'
  },
  {
    student: 'Ксения Жулябина',
    lastFeedback: 'test 002'
  },
  {
    student: 'Ксения Жулябина',
    lastFeedback: 'test 002'
  }
];

export const USER_TYPES = {
  parent: 'parents',
  student: 'students',
  admin: 'admins',
  teacher: 'teachers'
};

export default {
  mockData,
  mockEvents,
  baseMonth,
  month,
  mockCustomMaterials,
  mockApprovedMaterialItems,
  mockDataTable
};
