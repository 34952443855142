import React from 'react';
import { Pagination } from 'antd';

interface paginationProps {
  count: number;
  pageChange: (page: number) => void;
  current: number;
  pageSize?: number;
}

export default (props: paginationProps) => {
  const { count, pageChange, current, pageSize } = props;

  return (
    <Pagination
      total={count}
      current={current}
      onChange={pageChange}
      pageSize={pageSize || 1}
      align="end"
      style={{ width: '100%' }}
      showSizeChanger={false}
    />
  );
};
