import React from 'react';
import { Tag } from 'antd';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { StyledCard, StyledCardDetails, StyledCardDetailsItem } from 'Global/styles';
import { CardTitle } from 'Global/components/CardTitle';
import { dateFormat } from 'helpers';
import dayjs from 'dayjs';

export function TeacherCard(props) {
  const [strings] = useLanguageContext();
  const { branches, teacherTags: teacherTagsList, subjects, teacherStatuses } = useGlobalCollectionsContext();
  const {
    birthday,
    email,
    branch,
    org1Id,
    prefix1c,
    mainSubjects,
    additionalSubjects,
    teacherTags,
    selectedTutor,
    selectedMentor,
    selectedExperts
  } = props;
  

  const getAdditionalSubjects = items =>
    items.map(subjectId => {
      return <Tag color="green">{subjects.find(({ id }) => id === Number(subjectId))?.name}</Tag>;
    });

  const getSubjects = items =>
    items.map(subjectId => {
      return <Tag color="volcano">{subjects.find(({ id }) => id === Number(subjectId))?.name}</Tag>;
    });

  const getTeacherTags = () =>
    teacherTags.map(tagId => {
      return <Tag color="blue">{teacherTagsList.find(({ id }) => id === Number(tagId))?.name}</Tag>;
    });

  return (
    <StyledCard style={{ borderRadius: 5 }}>
      <CardTitle {...props} />
      <StyledCardDetails>
        {props?.status && (
          <StyledCardDetailsItem>
            <b>{strings.STATUS}:</b>
            <span>{teacherStatuses.find(status => Number(props?.status) === status.id)?.name}</span>
          </StyledCardDetailsItem>
        )}
        {branch && (
          <StyledCardDetailsItem>
            <b>{strings.BRANCH}:</b>
            <span>{branches.find(branch => Number(props?.branch) === branch.id)?.name}</span>
          </StyledCardDetailsItem>
        )}
        {mainSubjects && mainSubjects?.isNotEmpty() && (
          <StyledCardDetailsItem>
            <b>{strings.MAIN_SUBJECTS}: </b>
            <div style={{ textAlign: 'left' }}>{getSubjects(mainSubjects)}</div>
          </StyledCardDetailsItem>
        )}
        {additionalSubjects && additionalSubjects?.isNotEmpty() && (
          <StyledCardDetailsItem>
            <b>{strings.ADDITIONAL_SUBJECTS}: </b>
            <div style={{ textAlign: 'left' }}>{getAdditionalSubjects(additionalSubjects)}</div>
          </StyledCardDetailsItem>
        )}
        {teacherTags && teacherTags?.isNotEmpty() && (
          <StyledCardDetailsItem>
            <b>{strings.TAGS}: </b>
            <div style={{ textAlign: 'left' }}>{getTeacherTags()}</div>
          </StyledCardDetailsItem>
        )}
        {birthday && (
          <StyledCardDetailsItem>
            <b>{strings.DATE_OF_BIRTH}:</b>
            <span>{birthday ? dayjs(birthday).format(dateFormat) : ''}</span>
          </StyledCardDetailsItem>
        )}
        {email && (
          <StyledCardDetailsItem>
            <b>{strings.E_MAIL}:</b>
            {email}
          </StyledCardDetailsItem>
        )}
        {selectedTutor && (
          <StyledCardDetailsItem>
            <b>{strings.ADM}:</b>
            {selectedTutor}
          </StyledCardDetailsItem>
        )}
        {selectedMentor && (
          <StyledCardDetailsItem>
            <b>{strings.MENTOR}:</b>
            {selectedMentor}
          </StyledCardDetailsItem>
        )}
        {selectedExperts && selectedExperts?.isNotEmpty() && (
          <StyledCardDetailsItem>
            <b>{strings.EXPERTS}:</b>
            {selectedExperts.map((expert: string, index) => (
              <span key={`expert${index}`}>
                {expert}
                {index + 1 < selectedExperts.length ? ', ' : ''}
              </span>
            ))}
          </StyledCardDetailsItem>
        )}
        {prefix1c && (
          <StyledCardDetailsItem>
            <b>{strings.PREFIX_1_C}:</b>
            {prefix1c}
          </StyledCardDetailsItem>
        )}
        {org1Id && (
          <StyledCardDetailsItem>
            <b>{strings.ORG_1_ID}:</b>
            {org1Id}
          </StyledCardDetailsItem>
        )}
      </StyledCardDetails>
    </StyledCard>
  );
}
