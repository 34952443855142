import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Tag, Tooltip } from 'antd';
import { Product as IProduct } from 'types';
import { Parent } from 'types/Family';

const ParentColumn = React.memo(({ parents = [] }: { parents: Parent[] }) => {
  return (
    <>
      {parents.map((parent, idx) => (
        <React.Fragment key={parent.id}>
          <Link
            to={`/family/${parent.familyId}/parent/${parent.id}`}
            style={{ display: 'inline-block', marginBottom: 5 }}
          >
            <Tag
              color={idx === 0 ? 'orange' : 'blue'}
              style={{ maxWidth: 180, textOverflow: 'ellipsis', overflow: 'hidden' }}
            >
              <Tooltip
                placement="leftTop"
                overlayStyle={{ maxWidth: 400 }}
                title={`${parent?.name} ${parent?.surname}`}
              >
                {parent?.name} {parent?.surname}
              </Tooltip>
            </Tag>
          </Link>
          <br />
        </React.Fragment>
      ))}
    </>
  );
});

export const getColumns = (
  products: IProduct[] = [],
  extraClmns: {
    hasShowChargeOff: boolean;
    hasShowCompensation: boolean;
    hasShowEndBalance: boolean;
    hasShowDoubtsCount: boolean;
    hasShowDepositReceived: boolean;
    hasShowTotalAmount: boolean;
  },
  strings
) => {
  const productsClmns = useMemo(
    () =>
      products.map((product: IProduct) => ({
        title: product.name,
        dataIndex: ['byProducts', `${product.id}`],
        key: product.id,
        width: 100,
        className: 'clmn-center',
        render: value => <span>{value > 0 ? value : '—'}</span>
      })),
    [products]
  );

  const columns = [
    {
      title: strings.FAMILY,
      dataIndex: 'familyId',
      key: 'familyId',
      width: 80,
      className: 'clmn-center',
      render: (familyId: number) => (
        <Link to={`/family/${familyId}`} style={{ width: 80, display: 'inline-block' }}>
          {familyId}
        </Link>
      )
    },
    {
      title: strings.PARENT,
      dataIndex: 'parents',
      key: 'parents',
      width: 180,
      render: (parents: Parent[]) => <ParentColumn parents={parents} />
    },
    {
      title: strings.BALANCE_BY_THE_START_OF_PERIOD,
      dataIndex: 'startBalance',
      width: 140,
      className: 'clmn-center',
      render: (balance: number) => <div style={{ width: 100 }}>{balance}</div>
    },
    extraClmns.hasShowChargeOff && {
      title: strings.CHARGE_OFF_SUM,
      dataIndex: 'chargeOff',
      className: 'clmn-center',
      width: 100
    },
    extraClmns.hasShowCompensation && {
      title: strings.COMPENSATION_SUM,
      dataIndex: 'compensation',
      className: 'clmn-center'
    },
    extraClmns.hasShowEndBalance && {
      title: strings.BALANCE_BY_THE_END_OF_PERIOD,
      dataIndex: 'endBalance',
      className: 'clmn-center',
      width: 140
    },
    extraClmns.hasShowDoubtsCount && {
      title: strings.ERROR_REPORTS,
      dataIndex: 'doubtsCount',
      className: 'clmn-center',
      width: 100
    },
    extraClmns.hasShowDepositReceived && {
      title: strings.DEPOSIT_RECEIVED,
      dataIndex: 'depositReceived',
      className: 'clmn-center',
      width: 140
    },
    extraClmns.hasShowTotalAmount && {
      title: strings.CHARGE_OFF_SUM_C_1_C_2_COMPENSATION_SUM,
      dataIndex: 'chargeOffCompensationSum'
    },
    {
      title: strings.PAYMENT_SUM,
      children: [
        { title: 'A', dataIndex: ['payments', 'A'], className: 'clmn-center', key: 'A' },
        { title: 'B', dataIndex: ['payments', 'B'], className: 'clmn-center', key: 'B' },
        { title: 'C1', dataIndex: ['payments', 'C1'], className: 'clmn-center', key: 'C1' },
        { title: 'C2', dataIndex: ['payments', 'C2'], className: 'clmn-center', key: 'C2' },
        { title: 'D', dataIndex: ['payments', 'D'], className: 'clmn-center', key: 'D' },
        {
          title: strings.WITHOUT_CODE,
          dataIndex: ['payments', 'withoutCode'],
          className: 'clmn-center',
          key: 'withoutCode'
        }
      ]
    },
    {
      title: strings.PRODUCTS,
      children: productsClmns
    }
  ];

  return columns;
};
