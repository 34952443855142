import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import { Select, DatePicker, Col, Row, Badge } from 'antd';
import { TutorSelect } from 'Global/components/FormComponentsCompatible/Selects/TutorSelect';
import { dateFormat } from 'helpers/dates';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { Branch, Status, Subject } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { FilterButtons } from 'Global/components/FilterButtons';
import { GroupSelect } from 'Global/components/FormComponentsCompatible/Selects/GroupSelect';
import { TeacherSelect } from 'Global/components/FormComponentsCompatible/Selects/TeacherSelect';
import { AssessmentStatus, IAssessmentCalendarParams } from 'types/accessment';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { momentFormatValues } from 'helpers/momentFormatValues';

const { Option } = Select;

interface FilterProps {
  form: WrappedFormUtils;
  defFilterValues?: IAssessmentCalendarParams;
  onFilter: (params: IAssessmentCalendarParams) => Promise<any>;
  assessmentsStatuses: AssessmentStatus[];
}

export const Filter = Form.create<FilterProps>({})((props: FilterProps) => {
  const [strings] = useLanguageContext();
  const {
    form: { getFieldDecorator, resetFields, validateFields },
    defFilterValues,
    onFilter,
    assessmentsStatuses
  } = props;

  const { subjects, branches, teacherStatuses } = useGlobalCollectionsContext();
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);
  const { locationSearchParams } = useLocationSearchParams();
  const { dateTo, subject, branch, assessmentStatus, teacherStatus, group, tutor, teacher, experts } =
    locationSearchParams || {};

  /**
   * @param {React.FormEvent} e
   * @return {null}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFields(async (errors, values): Promise<any> => {
      if (isEmpty(errors)) {
        setHasSubmitLoading(true);
        try {
          const params = momentFormatValues(values);
          params['page'] = 1;
          await onFilter(params);
        } finally {
          setHasSubmitLoading(false);
        }
      }
    });
  };

  /**
   * @description Очистка фильтра
   */
  const handleClear = async (): Promise<any> => {
    await onFilter(defFilterValues);
    resetFields();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={[10, 10]}>
        <Col lg={4}>
          {getFieldDecorator('dateTo', { initialValue: dateTo && dayjs(dateTo) })(
            <DatePicker format={dateFormat} placeholder={strings.TO_WEEK} />
          )}
        </Col>
        <Col lg={8}>
          {getFieldDecorator('teacherStatus', {
            initialValue: teacherStatus
          })(
            <Select maxTagCount={1} mode="multiple" placeholder={strings.TEACHER_STATUSES}>
              {teacherStatuses.map((status: Status) => (
                <Option key={status.id} value={Number(status.id)}>
                  {status.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>{' '}
        {branches.length > 1 && (
          <Col lg={6}>
            {getFieldDecorator('branch', {
              initialValue: branch
            })(
              <Select placeholder={strings.BRANCH} mode="multiple" maxTagCount={1}>
                <Option value={undefined}>&nbsp;</Option>
                {branches.map((branch: Branch) => (
                  <Option value={branch.id} key={branch.id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            )}
          </Col>
        )}
        <Col lg={6}>
          {getFieldDecorator('subject', { initialValue: subject })(
            <Select placeholder={strings.SUBJECTS} mode="multiple" maxTagCount={1}>
              <Option value={undefined}>&nbsp;</Option>
              {subjects.map((subject: Subject) => (
                <Option value={subject.id} key={subject.id}>
                  {subject.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col lg={6}>
          <TutorSelect name="tutor" initTutor={tutor} getFieldDecorator={getFieldDecorator} placeholder={strings.ADM} />
        </Col>
        <Col lg={6}>
          <TeacherSelect
            placeholder={strings.TEACHER}
            initTeacherId={teacher}
            getFieldDecorator={getFieldDecorator}
            name="teacher"
          />
        </Col>
        <Col lg={6}>
          <TutorSelect
            name="experts"
            initTutors={experts}
            getFieldDecorator={getFieldDecorator}
            placeholder={strings.EXPERTS}
            mode="multiple"
          />
        </Col>
        <Col lg={6}>
          <GroupSelect name="group" initGroup={group} getFieldDecorator={getFieldDecorator} />
        </Col>
        <Col lg={12}>
          {getFieldDecorator('assessmentStatus', {
            initialValue: assessmentStatus
          })(
            <Select placeholder={strings.ASSESSMENT_STATUSES} mode="multiple" maxTagCount={2}>
              {assessmentsStatuses.map((status: AssessmentStatus) => {
                const statusColor = status.color.match('#') ? status.color : '#' + status.color;
                return (
                  <Option value={status.id} key={status.id}>
                    {status.name} <Badge color={statusColor} />
                  </Option>
                );
              })}
            </Select>
          )}
        </Col>
      </Row>
      <FilterButtons handleClear={handleClear} hasSubmitLoading={hasSubmitLoading} />
    </Form>
  );
});

export default Filter;
