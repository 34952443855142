import React from 'react';
import dayjs from 'dayjs';
import { IStudent, Performer } from 'types';
import { dateFormat } from 'helpers/dates';
import { InvoiceListItem } from 'types/invoice';
import { Flex } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';

/**
 * @description Функция для генерации колонок в таблице инвойсов ЛК родителея
 * @param {boolean} hasParent
 * @return {array}
 */
export const getInvoicesColumns = (hasParent: boolean, openModalPartiallyPay, strings) => [
  {
    title: strings.ID,
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: strings.STUDENT,
    dataIndex: 'child',
    key: 'child',
    render: (child: IStudent) => (
      <p>
        {child?.name || child?.user?.name} {child?.surname || child?.user?.surname}
      </p>
    )
  },
  {
    title: strings.CREATED,
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: string) => date && dayjs(date).format(dateFormat)
  },
  {
    title: strings.SUM,
    dataIndex: 'sum',
    key: 'sum',
    className: 'clmn-center'
  },
  !hasParent && {
    // TODO: Сделать более кратко, с одной проверкой
    title: strings.SUM_PAID,
    key: 'allPaidSum',
    width: 150,
    render: (invoice: InvoiceListItem) => (
      <Flex align="center" gap={10}>
        {invoice?.allPaidSum}
        {invoice?.status === 1 && (
          <PlusCircleFilled
            onClick={() => openModalPartiallyPay(invoice?.id)}
            style={{ color: '#87d068', fontSize: 26 }}
          />
        )}
      </Flex>
    )
  },
  !hasParent && {
    title: 'Hold',
    dataIndex: 'hold',
    key: 'hold',
    className: 'clmn-center',
    render: (hold: number) => hold
  },
  !hasParent && {
    title: 'To balance',
    dataIndex: 'balance',
    key: 'balance',
    width: 90,
    className: 'clmn-center',
    render: (balance: number) => balance
  },
  !hasParent && {
    title: 'To deposit',
    dataIndex: 'deposit',
    key: 'toDeposit',
    width: 90,
    className: 'clmn-center',
    render: (deposit: number) => deposit
  },
  {
    title: strings.PERIOD,
    key: 'period',
    className: 'clmn-center',
    render: (invoice: InvoiceListItem) => {
      const { startAt, endAt, periodName } = invoice;

      // Функция для отображение периода в таблице
      const renderPeriod = () => {
        if (startAt && endAt) {
          return <span>{`${dayjs(startAt).format(dateFormat)} - ${dayjs(endAt).format(dateFormat)}`}</span>;
        } else {
          return <span>—</span>;
        }
      };

      return renderPeriod();
    }
  },
  !hasParent && {
    title: 'Performer',
    dataIndex: 'performer',
    key: 'performer',
    width: 150,
    render: (performer: Performer) => <p>{performer && performer.name}</p>
  }
];

export default { getInvoicesColumns };
