import React from 'react';
import { InfoCircleTwoTone } from '@ant-design/icons';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Button, Col, Row, Input, Radio, Typography, Tooltip } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { LessonAssessmentPreshool } from 'types/accessment';
import { useLanguageContext } from 'contexts/LanguageContext';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { VotingItem } from './VotingItem';
import { checkTypeForInput } from 'helpers/booleanFomatValues';

const { Text, Title } = Typography;

interface LessonPreschoolFieldsProps extends FormComponentProps {
  lessonAssessment: LessonAssessmentPreshool;
  form: WrappedFormUtils;
}

/**
 * @description LessonPreschoolFields component
 * @param {object} props
 * @return {React.ReactNode}
 */
export const LessonPreschoolFields = (props: LessonPreschoolFieldsProps) => {
  const [strings] = useLanguageContext();
  const {
    form,
    form: { getFieldDecorator, setFieldsValue },
    lessonAssessment: {
      assistantsPerformanceComment,
      lessonRecommendation,
      lessonFinalMark,
      safety,
      taskWrappedGrade,
      taskConnectedGrade,
      taskChallengingGrade,
      lessonLessonAreMirrored,
      tasksComment,
      communicationCommentsGrade,
      communicationAppropriateGrade,
      communicationAndskillsGrade,
      useChildrenNames,
      startEndIndication,
      nonEngagingChildren,
      communicationStrategies,
      feedbackActionsGrade,
      feedbackAdviceGrade,
      feedbackSymbolsGrade,
      evidenceGrade,
      feedbackAndAssessmentComment
    }
  } = props;

  /**
   * @description Очистить поле
   * @param {string} fieldName
   * @return {void}
   */
  const clearFieldByName = (fieldName: string): void => setFieldsValue({ [fieldName]: '' });

  return (
    <>
      <Title level={4}>{strings.TASKS}</Title>
      <VotingItem
        form={form}
        label={strings.WRAPPED_COMMUNICATIVE}
        option="taskWrappedGrade"
        initialValue={taskWrappedGrade}
      />
      <VotingItem
        form={form}
        label={strings.LINKED_TOGETHER}
        option="taskConnectedGrade"
        initialValue={taskConnectedGrade}
        alertText={strings.TASKS_ARE_CONNECTED_THROUGH_A_COMMON_THEME_CHARACTER_ISSUE_ETC}
      />
      <VotingItem
        form={form}
        label={strings.AGE_AND_LEVEL_APPROPRIATE}
        option="taskChallengingGrade"
        initialValue={taskChallengingGrade}
        alertText={strings.TASKS_ARE_CHALLENGING}
      />
      <Row gutter={[10, 10]}>
        <Col lg={24}>
          <MyFormItem
            label={
              <>
                {strings.LESSONS_ARE_MIRRORED}{' '}
                <Tooltip title={strings.LESSONS_ARE_MIRRORED_VISUAL_CONTEXT_WRAPPING}>
                  <InfoCircleTwoTone twoToneColor="#1890ff" style={{ fontSize: 22, margin: 10 }} />
                </Tooltip>
              </>
            }
          >
            {getFieldDecorator('lessonLessonAreMirrored', {
              initialValue: lessonLessonAreMirrored,
              rules: [{ required: false }]
            })(
              <Radio.Group>
                <Radio.Button value="ni">Ni</Radio.Button>
                <Radio.Button value="s">S</Radio.Button>
                <Radio.Button value="g">G</Radio.Button>
              </Radio.Group>
            )}
            <Button
              size="middle"
              style={{ marginInlineStart: 10 }}
              onClick={() => clearFieldByName('lessonLessonAreMirrored')}
            >
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem label={strings.СOMMENT}>
            {getFieldDecorator('tasksComment', {
              initialValue: checkTypeForInput(tasksComment),
              rules: [{ required: false }]
            })(<Input.TextArea placeholder={strings.СOMMENT} />)}
          </MyFormItem>
        </Col>
      </Row>
      <Title level={4}>{strings.COMMUNICATION_STRATEGIES}</Title>
      <VotingItem
        form={form}
        label={strings.TEACHER_STUDENT_INTERACTION}
        option="communicationCommentsGrade"
        initialValue={communicationCommentsGrade}
        alertText={strings.THE_TEACHER_LISTENS_AND_UTILIZES}
      />
      <VotingItem
        form={form}
        label={strings.SPEECH_ADEQUACY}
        option="communicationAppropriateGrade"
        initialValue={communicationAppropriateGrade}
        alertText={
          strings.USE_OF_DIFFERENT_COMMUNICATION_STRATEGIES_THE_TEACHER_USES_VERBAL_NON_VERBAL_AND_WRITTEN_COMMUNICATION_AND_SKILLS_USES_GESTURES_PICTURES_ETC_TO_CLARIFY_THE_MATERIAL
        }
      />
      <VotingItem
        form={form}
        label={strings.СOMMUNICATION_STRATEGIES}
        option="communicationAndskillsGrade"
        initialValue={communicationAndskillsGrade}
        alertText={strings.THE_TEACHER_USES_APPROPRIATE_VOLUME_PACE_AND_PITCH_POSITIVE_WORDS}
      />
      <VotingItem
        form={form}
        label={strings.USE_OF_CLILDRENS_NAMES}
        option="useChildrenNames"
        initialValue={useChildrenNames}
      />
      <VotingItem
        form={form}
        label={strings.START_AND_END_INDICATION}
        option="startEndIndication"
        initialValue={startEndIndication}
        alertText={strings.THE_TEACHER_USES_GREETINGS_AND_RITUALS}
      />
      <Row gutter={[10, 10]}>
        <Col lg={24}>
          <MyFormItem
            label={
              <>
                {strings.NON_ENGAGING_CHILDREN}
                <Tooltip title={strings.COMMUNICATION_WITH_NON_ENGAGING_CHILDREN}>
                  <InfoCircleTwoTone twoToneColor="#1890ff" style={{ fontSize: 22, margin: 10 }} />
                </Tooltip>
              </>
            }
          >
            {getFieldDecorator('nonEngagingChildren', {
              initialValue: nonEngagingChildren,
              rules: [{ required: false }]
            })(
              <Radio.Group>
                <Radio.Button value="ni">Ni</Radio.Button>
                <Radio.Button value="s">S</Radio.Button>
                <Radio.Button value="g">G</Radio.Button>
              </Radio.Group>
            )}
            <Button
              size="middle"
              style={{ marginInlineStart: 10 }}
              onClick={() => clearFieldByName('nonEngagingChildren')}
            >
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem label={strings.СOMMENT}>
            {getFieldDecorator('communicationStrategies', {
              initialValue: communicationStrategies,
              rules: [{ required: false }]
            })(<Input.TextArea placeholder={strings.СOMMENT} />)}
          </MyFormItem>
        </Col>
      </Row>
      <Title level={4}>Feedback and assessment</Title>
      <VotingItem
        form={form}
        label={strings.RESPONDING_TO_STUDENTS_ACTIONS}
        option="feedbackActionsGrade"
        initialValue={feedbackActionsGrade}
        alertText={strings.THE_TEACHER_PROVIDES_FEEDBACK}
      />
      <VotingItem
        form={form}
        label={strings.ADVICE_FOR_IMPROVEMENT}
        option="feedbackAdviceGrade"
        initialValue={feedbackAdviceGrade}
        alertText={strings.THE_TEACHER_GIVES_SPECIFIC_ADVICE_TO_HELP_THE_STUDENT_IMPROVE}
      />
      <VotingItem
        form={form}
        label={strings.AGE_APPROPRIATE_SYMBOLS}
        option="feedbackSymbolsGrade"
        initialValue={feedbackSymbolsGrade}
        alertText={strings.THE_TEACHER_USES_AGE_APPROPRIATE_SYMBOL}
      />
      <Row gutter={[10, 10]}>
        <Col lg={24}>
          <MyFormItem
            label={
              <>
                {strings.EVIDENCE_FOR_EACH_CHILD}
                <Tooltip title={strings.THE_TEACHER_GIVES_AT_LEAST_ONE_EVIDENCE_FOR_EACH_CHILD}>
                  <InfoCircleTwoTone twoToneColor="#1890ff" style={{ fontSize: 22, margin: 10 }} />
                </Tooltip>
              </>
            }
          >
            {getFieldDecorator('evidenceGrade', {
              initialValue: evidenceGrade,
              rules: [{ required: false }]
            })(
              <Radio.Group>
                <Radio.Button value="ni">Ni</Radio.Button>
                <Radio.Button value="s">S</Radio.Button>
                <Radio.Button value="g">G</Radio.Button>
              </Radio.Group>
            )}
            <Button size="middle" style={{ marginInlineStart: 10 }} onClick={() => clearFieldByName('evidenceGrade')}>
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem label={strings.СOMMENT}>
            {getFieldDecorator('feedbackAndAssessmentComment', {
              initialValue: feedbackAndAssessmentComment,
              rules: [{ required: false }]
            })(<Input.TextArea placeholder={strings.СOMMENT} />)}
          </MyFormItem>
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col lg={12}>
          <MyFormItem label={strings.SAFETY}>
            {getFieldDecorator('safety', {
              initialValue: safety
            })(
              <Radio.Group>
                <Tooltip title={strings.THREAT_IS_PRESENT}>
                  <Radio.Button value="0">0</Radio.Button>
                </Tooltip>
                <Tooltip title={strings.MEDIUM_POSITION}>
                  <Radio.Button value="1">1</Radio.Button>
                </Tooltip>
                <Tooltip title={strings.NO_THREATS_AT_ALL}>
                  <Radio.Button value="2">2</Radio.Button>
                </Tooltip>
              </Radio.Group>
            )}
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem label={strings.ASSISTANTS_PERFORMANCE_COMMENT}>
            {getFieldDecorator('assistantsPerformanceComment', {
              initialValue: checkTypeForInput(assistantsPerformanceComment),
              rules: [{ required: false }]
            })(<Input.TextArea placeholder={strings.СOMMENT} />)}
          </MyFormItem>
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col lg={24} style={{ background: '#e6f7ff', padding: '10px 20px 30px 20px', borderRadius: '5px' }}>
          <MyFormItem
            label={
              <>
                {strings.SUMMARY_COMMENTS}
                <Text type="secondary" style={{ float: 'right', fontSize: '12px' }}>
                  {strings.ONLY_THIS_PART_IS_VISIBLE_TO_THE_TEACHER}
                </Text>
              </>
            }
          >
            {getFieldDecorator('lessonRecommendation', {
              initialValue: checkTypeForInput(lessonRecommendation),
              rules: [
                {
                  required: false
                }
              ]
            })(<Input.TextArea style={{ height: 300 }} placeholder={strings.СOMMENT} />)}
          </MyFormItem>
          <MyFormItem label={strings.FINAL_MARK}>
            {getFieldDecorator('lessonFinalMark', {
              initialValue: lessonFinalMark,
              rules: [{ required: false }]
            })(
              <Radio.Group>
                <Radio.Button value="ni">Ni</Radio.Button>
                <Radio.Button value="s">S</Radio.Button>
                <Radio.Button value="g">G</Radio.Button>
              </Radio.Group>
            )}
            <Button size="middle" style={{ marginInlineStart: 10 }} onClick={() => clearFieldByName('lessonFinalMark')}>
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
      </Row>
    </>
  );
};

export default { LessonPreschoolFields };
