import React, { useState, useEffect } from 'react';
import { Table, Modal, message, Select, Button } from 'antd';
import { Courses } from 'api/Courses';
import { Loader } from 'Global/components/Loader/Loader';
import { columns } from './columns';
import { Supergroup } from 'types/education';
import { useUserContext } from 'contexts/UserContext';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Option } = Select;

interface SuperGroupsProps {
  groupId: number;
}

/**
 * @description Страница просмотра Group course
 * @param {object} props - component props
 * @return {React.ReactNode}
 */

export const SuperGroups = (props: SuperGroupsProps) => {
  const [supergroups, setSupergroups] = useState<Supergroup[]>([]);
  const [listSupergroups, setListSupergroups] = useState<Supergroup[]>([]);
  const [selectedSupergroup, setSelectedSupergroup] = useState<number | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [addGroupLoading, setAddGroupLoading] = useState<boolean>(false);
  const { groupId } = props;

  const [user] = useUserContext();
  const coursesRepository = useCoursesRepository();
  const [strings] = useLanguageContext();
  const [{ confirm }, contextHolder] = Modal.useModal();

  /**
   * @description Получения списка супергрупп по ид группе
   * @param {number} groupId
   * @return {Promise<any>}
   */
  const getSuperGroupsById = async (groupId: number) => {
    setLoading(true);
    try {
      const { data } = await coursesRepository.getSuperGroupListByGroup(groupId);
      setSupergroups(data);
      setLoading(false);
    } catch {}
  };

  /**
   * @description Получения списка супергрупп
   * @return {Promise<any>}
   */
  const getSupergroups = async () => {
    try {
      const { data } = await coursesRepository.getSuperGroups({ byStages: 0, branch: user?.branch?.id });
      setListSupergroups(data);
    } catch {}
  };

  const addSupergroup = async () => {
    if (selectedSupergroup) {
      setAddGroupLoading(true);
      try {
        const { status } = await Courses.addSupergroupToGroup(groupId, { supergroup: selectedSupergroup });
        if (status === 200) {
          message.success(strings.SUPERGROUP_SUCCESSFULLY_ADDED);
          getSuperGroupsById(groupId);
          setSelectedSupergroup(undefined);
        }
      } finally {
        setAddGroupLoading(false);
      }
    } else {
      message.error(strings.CHOOSE_SUPERGROUP_PLEASE);
    }
  };

  const showDeleteSuperGroup = (supergroupId: number) => {
    confirm({
      title: strings.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_GROUP_FROM_THIS_SUPERGROUP,
      okText: 'Delete',
      okType: 'danger',
      async onOk() {
        try {
          const { status } = await Courses.removeSupergroupFromGroup(groupId, supergroupId);
          if (status === 200) {
            message.success(strings.THE_GROUP_HAS_BEEN_SUCCESSFULLY_DELETED_FROM_THE_SUPERGROUP);
            getSuperGroupsById(groupId);
          }
        } catch {}
      }
    });
  };

  useEffect(() => {
    if (user?.hasRoles?.admin) {
      getSupergroups();
    }
  }, [user]);

  useEffect(() => {
    getSuperGroupsById(groupId);
  }, [groupId]);

  return (
    <Loader spinning={loading}>
      {contextHolder}
      {user?.hasRoles?.admin && (
        <>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder={strings.SELECT_A_SUPERGROUP}
            optionFilterProp="children"
            onChange={setSelectedSupergroup}
            filterOption={(input: string, option: any): boolean =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={selectedSupergroup}
          >
            {listSupergroups.map((supergroup: Supergroup) => (
              <Option key={supergroup.id} value={supergroup.id}>
                {supergroup.name}
              </Option>
            ))}
          </Select>
          <Button
            type="primary"
            onClick={addSupergroup}
            disabled={addGroupLoading}
            loading={addGroupLoading}
            style={{ marginInlineStart: 8 }}
          >
            {strings.INCLUDE_IN_THE_SUPERGROUP}
          </Button>
        </>
      )}

      <Table
        dataSource={supergroups}
        columns={columns(showDeleteSuperGroup)}
        bordered
        pagination={false}
        style={{ marginTop: 20 }}
      />
    </Loader>
  );
};

export default { SuperGroups };
