import React, { useState } from 'react';
import { RouteProps } from 'react-router-dom';
import { Layout } from 'antd';
import { StyledContent } from './styles';
import { adminRoutes } from './routes/admin';
import { teacherRoutes } from './routes/teacher';
import { parentRoutes } from './routes/parent';
import * as courses from 'Admin/Courses/routes';
import * as global from 'Global/routes';
import * as parent from 'Student&Parent/routes';
import * as monitoring from 'Admin/Monitoring/routes';
import { Route, Switch } from 'react-router-dom';
import { Lesson } from 'Global/modules/Lesson';
import { Child } from 'Admin/People/Families/Student';
import { Header } from 'Global/components/Header';
import { LeftMenu } from 'Global/components/LeftMenu';
import { NotFound } from 'Global/modules/Errors/404';
import { Forbidden } from 'Global/modules/Errors/403';
import { ParentFinance, WorkProgress as ParentWorkProgress, ParentInvoices, GoogleCalendar } from 'Student&Parent';
import { InvoicesLogs } from 'Admin/Monitoring/InvoicesLogs';
import { RegistersLogs } from 'Admin/Monitoring/RegistersLogs';
import { FULL_NEWS } from 'Admin/News/routes';
import { NewsFull } from 'Global/modules/Home/News/NewsFull';
import { useUserContext } from 'contexts/UserContext';
import ExternalCodeService from 'helpers/services/ExternalCodeService';
import CacheService from 'helpers/services/CacheService';
import { YMInitializer } from 'react-yandex-metrika';
import { Home } from 'Global/modules/Home';
import { SupportChat } from 'Global/modules/Support/Chat';
import { AssistantFeedback } from 'Global/modules/AssistantsFeedback';
import { EditAssistantFeedback } from 'Global/modules/AssistantsFeedback/EditAssistantFeedback';
import { EditParentForm } from '../Student&Parent/EditParentForm';
import { OriginalCourseLesson } from 'Admin/Courses/OriginalCourses/OriginalCourseLesson';
import { hasInvoiceMonthFinanceSystem } from '../helpers';
import { BASE_SUPPORT } from 'Global/routes';
import { BASE_ASSISTANTS_FEEDBACK } from 'Admin/Monitoring/routes';

const cacheService = new CacheService();
const externalCodeService = new ExternalCodeService();

const { Content } = Layout;

/**
 * @description Корневой компонент для формирования роутов, меню, контента.
 * @param {props} props - component props
 * @return {React.ReactNode}
 */
export const Root = () => {
  const [user, , hasLoadUser] = useUserContext();
  let routes: RouteProps[] = [];
  const userFromCache = cacheService.getFromCache('user');
  const { branch } = user || userFromCache;
  const [hasMenuCollapse, setHasMenuCollapse] = useState<boolean>(false);

  // Если в массиве ролей текущего пользователя содержится учитель
  if (user?.hasRoles?.teacher) {
    routes = teacherRoutes;
  }

  //Если в массиве ролей текущего пользователя содержатся админ или топ менеджер
  if (user?.hasRoles?.admin) {
    routes = adminRoutes;
  }

  //Если в массиве ролей текущего пользователя содержатся родитель или студент
  if (user?.hasRoles?.parent || user?.hasRoles?.student) {
    routes = parentRoutes;
  }

  /**
   * @description Функция для распределения маршрутов, которые могут быть использованны в разных ЛК
   * @return {array}
   */
  const getGlobalRoutesByRoles = (): React.ReactElement[] | [] => {
    if (hasLoadUser === false) {
      const list: React.ReactElement[] = [
        <Route component={SupportChat} path={BASE_SUPPORT} key="support" exact />,
        <Route path="/" component={Home} key="home" exact />,
        <Route component={Forbidden} path="/forbidden" key="forbidden" exact />,
        <Route component={NotFound} key="not-found" />
      ];

      // Страница урока и профиль студента могут быть доступы И учителю И админу
      if (user?.hasRoles?.admin || user?.hasRoles?.teacher) {
        list.unshift(
          ...[
            <Route
              path={courses.ORIGINAL_COURSE_LESSON}
              component={OriginalCourseLesson}
              key="original-course-lesson"
              exact
            />
          ]
        );
        list.unshift(...[<Route path={FULL_NEWS} component={NewsFull} key="news-list" exact />]);
        list.unshift(...[<Route path={global.BASE_STUDENT} component={Child} key="student" exact />]);
        list.unshift(...[<Route path={courses.LESSON} component={Lesson} key="lesson" exact />]);
        list.unshift(
          ...[<Route path={BASE_ASSISTANTS_FEEDBACK} component={AssistantFeedback} key="assistants-feedback" exact />]
        );
        list.unshift(
          ...[
            <Route
              path="/assistants-feedback/:id"
              component={EditAssistantFeedback}
              key="assistants-feedback-id"
              exact
            />
          ]
        );
      }

      // Раздел Invoices Logs видит ТОЛЬКО админы, роли Топ менеджер ИЛИ branch директор
      // ИЛИ Branch director of operational department
      if (
        user?.hasRoles?.admin &&
        (user?.hasRoles?.topManager || user?.hasRoles?.branchDirector || user?.hasRoles?.branchOperationDepartament)
      ) {
        list.unshift(
          ...[<Route path={monitoring.BASE_INVOICES_LOGS} component={InvoicesLogs} key="invoice-logs" exact />]
        );
      }

      // Раздел Registers Logs видят ТОЛЬКО админы, роли Топ менеджер ИЛИ branch директор ИЛИ Branch director of operational department
      if (
        user?.hasRoles?.admin &&
        (user?.hasRoles?.topManager || user?.hasRoles?.branchDirector || user?.hasRoles?.branchOperationDepartament)
      ) {
        list.unshift(
          ...[<Route path={monitoring.BASE_REGISTERS_LOGS} component={RegistersLogs} key="registers-logs" exact />]
        );
      }
      // ЕСЛИ роль учитель и филиал МОСКВА
      if (user?.hasRoles?.teacher && branch?.id === 1) {
        list.unshift(...[<Route path={parent.CALENDAR} component={GoogleCalendar} key="google-calendar" exact />]);
      }

      /**
       * В ЛК РОДИТЕЛЯ видно вкладки Финансов, Прогресс репорта, Редактирование профиля(подписок)
       */
      if (user?.hasRoles?.parent) {
        list.unshift(
          ...[
            <Route path={parent.BASE_FINANCE} component={ParentFinance} key="finance" exact />,
            <Route path={parent.BASE_REPORT_ON_PROGRESS} component={ParentWorkProgress} key="progress" exact />,
            <Route path={parent.EDIT_PARENT_PROFILE} component={EditParentForm} key="edit-profile" exact />
          ]
        );
      }

      // В ЛК РОДИТЕЛЯ видно раздел инвойсов, если есть флаг invoiceMonthFinanceSystem. Скрываем инвойсы для Риги
      if (user?.hasRoles?.parent && hasInvoiceMonthFinanceSystem(branch) && branch?.id !== 5) {
        list.unshift(...[<Route path={parent.INVOICES} component={ParentInvoices} key="invoices" exact />]);
      }
      return list;
    }

    return [];
  };

  if (hasLoadUser === false) {
    return (
      <Content className="fullheight">
        {!externalCodeService.isLocalEnvironment() && (
          <YMInitializer
            accounts={[98637728]}
            options={{
              clickmap: true,
              trackLinks: true,
              accurateTrackBounce: true,
              webvisor: true
            }}
          />
        )}
        <Header />
        <Layout className="fullheight">
          <LeftMenu setHasMenuCollapse={setHasMenuCollapse} />
          <StyledContent hasCollapse={hasMenuCollapse} className="fullheight">
            <Switch>
              {routes.map((route, index) => (
                <Route {...route} key={index} />
              ))}
              {getGlobalRoutesByRoles()}
            </Switch>
          </StyledContent>
        </Layout>
      </Content>
    );
  }

  return null;
};

export default { Root };
