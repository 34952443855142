import React, { useEffect, useState } from 'react';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, message, Modal, Row, Table } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { getColumns } from './columns';
import { Filter } from './Filter';
import queryString from 'query-string';
import { isEmpty } from 'lodash';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { BASE_CLASSROOMS, CLASSROOMS_NEW } from '../routes';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { ClassroomItem, ClassroomsFilterParams } from 'types/education';
import { useClassroomsRepository } from 'repos/ClassroomsRepository';

export const Classrooms = () => {
  const [user] = useUserContext();
  const [strings] = useLanguageContext();
  const { goBack, push } = useHistory();
  const { locationSearchParams } = useLocationSearchParams();
  const { branches } = useGlobalCollectionsContext();
  const classroomsRepository = useClassroomsRepository();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [classrooms, setClassrooms] = useState<ClassroomItem[]>([]);
  const hasBranchOperationDepartament = user?.hasRoles?.branchOperationDepartament;
  const [{ confirm }, contextHolder] = Modal.useModal();

  const fetchClassrooms = async (params: ClassroomsFilterParams = {}): Promise<any> => {
    setHasLoading(true);
    try {
      if (branches.length === 1) {
        params['branch'] = user.branch.id;
      }
      const { data } = await classroomsRepository.getClassrooms(params);
      setClassrooms(data);
      push({ search: queryString.stringify(params) });
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  /**
   * @description Удалить classroom
   * @param {object} params
   * @return {Promise<any>}
   */
  const handleDeleteClassroom = (id: number): void => {
    confirm({
      title: strings.ARE_YOU_SURE,
      okType: 'danger',
      async onOk() {
        setHasLoading(true);
        try {
          await classroomsRepository.deleteClassroom(id);
          fetchClassrooms();
        } catch (err) {
          message.error(err.response.data.message);
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  const getActionColumns = () => [
    {
      title: '',
      dataIndex: 'id',
      key: 'action',
      render: (id: number) => (
        <Row align="middle" style={{ gap: 5, flexWrap: 'nowrap' }}>
          <Link to={`${BASE_CLASSROOMS}/${id}/edit`}>
            <Button icon={<EditOutlined />} />
          </Link>
          {!hasBranchOperationDepartament && (
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                handleDeleteClassroom(id);
              }}
            />
          )}
        </Row>
      )
    }
  ];

  useEffect(() => {
    if (branches.isNotEmpty()) {
      if (isEmpty(locationSearchParams)) {
        fetchClassrooms();
      } else {
        fetchClassrooms(locationSearchParams);
      }
    }
  }, [branches]);

  return (
    <Container>
      {contextHolder}
      <StPageHeader
        onBack={goBack}
        title={strings.CLASSROOMS}
        extra={[
          <Link to={CLASSROOMS_NEW}>
            <Button type="primary">{strings.ADD}</Button>
          </Link>
        ]}
      >
        <Filter onFilter={fetchClassrooms} />
      </StPageHeader>
      <Row gutter={[10, 10]}>
        <Table
          dataSource={classrooms}
          columns={[...getColumns(strings), ...getActionColumns()]}
          pagination={false}
          loading={{ spinning: hasLoading }}
        />
      </Row>
    </Container>
  );
};
export default Classrooms;
