import React, { useState } from 'react';
import { DeleteOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Spin, Button, message, Upload, Popconfirm } from 'antd';
import {
  createTextLinks,
  dateFormatForBackend,
  downloadFileByUrl,
  downloadSomeFile,
  getAttachmentLink,
  getFormData
} from 'helpers';
import { useLanguageContext } from 'contexts/LanguageContext';
import { IFiles, IHomeworkItem } from 'types/homework';
import styled from 'styled-components';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { Html } from 'Global/components/Html';
import { useAttachment } from 'hooks/useAttachment';
import { useMediaQuery } from 'react-responsive';
import { getLessonDate } from '../helpers';
import { Global } from '../../api';
import { StDivider } from '../styles';
import { RcFile, UploadChangeParam } from 'antd/es/upload';

interface HomeworkItemProps {
  homework: IHomeworkItem;
  isWidget?: boolean;
  refetchHW: () => Promise<any>;
}

const StBtnGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const StHwHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const HomeworkListItem = ({ homework, refetchHW, isWidget = false }: HomeworkItemProps) => {
  const [strings, lang] = useLanguageContext();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [hasDownloading, setHasDownloading] = useState<boolean>(false);
  const [hasUploading, setHasUploading] = useState<boolean>(false);
  const { lessonMaterial, lessonHomework, studentFiles, teacherFiles, lesson, register, nextLesson } = homework || {};
  const { uploadAttachment } = useAttachment(`/api/v2/lessonregister/${register.id}/homework`);

  /**
   * @description Скачивание файла
   * @param {number} id
   * @param {string} name
   * @return {Promise<any>}
   */
  const onDownloadFile = async (id: number, name: string): Promise<any> => {
    setHasLoading(true);
    const attachLink: string = getAttachmentLink(id);
    try {
      await downloadFileByUrl(attachLink, name);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  const handleDownloadAllMaterials = async (): Promise<any> => {
    setHasDownloading(true);
    const { startAt, id } = lesson;
    const params = { date: dayjs(startAt).format(dateFormatForBackend), lesson: id };
    try {
      await downloadSomeFile(`/api/v1/schedule/homework/zip?${queryString.stringify(params)}`, null, null, null, true);
    } catch {
      message.error(strings.HW_DOWNLOAD_FAILED, 2);
    } finally {
      setHasDownloading(false);
    }
  };

  const handleUploadFiles = async (files: RcFile[]) => {
    setHasUploading(true);
    try {
      const { status } = await uploadAttachment(getFormData({ files: files }));
      if (status === 200) {
        message.success(strings.SUCCESSFULLY_UPLOADED, 2);
        refetchHW();
      }
    } catch {
      message.error(strings.HOMEWORK_UPLOAD_FAILED, 2);
    } finally {
      setHasUploading(false);
    }
    return false;
  };

  const handleChange = ({ file, fileList }: UploadChangeParam) => {
    if (file.uid === fileList[fileList.length - 1].uid) {
      const newFiles = fileList.map(f => f.originFileObj);
      handleUploadFiles(newFiles);
    }
  };

  const handleDelete = async hwId => {
    setHasUploading(true);
    try {
      await Global.deleteAttachment(hwId);
      message.success(strings.HOMEWORK_HAS_BEEN_DELETED, 2);
      refetchHW();
    } catch (error) {
      message.error(strings.COULD_NOT_DELETE_THE_FILE);
    } finally {
      setHasUploading(false);
    }
  };

  const getLessonFiles = (attachments: IFiles[], hasDeleteAccess?: boolean) => {
    return attachments.map((attach: IFiles) => (
      <p style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
        {hasDeleteAccess && (
          <Popconfirm
            title={strings.SURE_DELETE}
            onConfirm={() => handleDelete(attach.id)}
            okText={strings.YES}
            cancelText={strings.NO}
          >
            <Button size="small" icon={<DeleteOutlined />} danger />
          </Popconfirm>
        )}
        <Button
          type="link"
          style={{ padding: 0, height: 'auto', whiteSpace: 'normal', textAlign: 'left' }}
          onClick={() => onDownloadFile(attach.id, attach.name)}
        >
          {attach.name}
        </Button>
        {attach.uploadedWhen && (
          <span style={{ fontSize: 12, color: '#bbb' }}>
            {strings.UPLOADED_AT} {attach.uploadedWhen}
          </span>
        )}
      </p>
    ));
  };

  return (
    <Spin spinning={hasLoading} tip={strings.LOADING}>
      <div style={{ lineHeight: 1 }}>
        <StHwHeader>
          <span style={{ fontSize: 14 }}>
            {strings.NEXT_LESSON} {nextLesson ? getLessonDate(nextLesson?.startAt, lang) : '-'}
          </span>
          <StBtnGroup>
            {!isMobile && (
              <Button
                icon={<DownloadOutlined />}
                size={isWidget ? 'small' : 'middle'}
                type="primary"
                loading={hasDownloading}
                onClick={handleDownloadAllMaterials}
              >
                {strings.DOWNLOAD_ALL_MATERIALS}
              </Button>
            )}
            <Upload
              multiple={true}
              beforeUpload={() => false}
              fileList={[]}
              disabled={hasUploading}
              onChange={handleChange}
              showUploadList={false}
            >
              {isMobile ? (
                <Button icon={<UploadOutlined />} loading={hasUploading} />
              ) : (
                <Button icon={<UploadOutlined />} loading={hasUploading} size={isWidget ? 'small' : 'middle'}>
                  {isWidget ? strings.SUBMIT_HW : strings.SUBMIT_HOMEWORK}
                </Button>
              )}
            </Upload>
          </StBtnGroup>
        </StHwHeader>
        <StDivider orientation="left">{strings.CLASSROOM_MATERIALS}</StDivider>
        {lesson?.material && <Html html={createTextLinks(lesson?.material)} />}
        {getLessonFiles(lessonMaterial)}
        <StDivider orientation="left">{strings.HOMEWORK}</StDivider>
        {lesson?.homework && <Html html={createTextLinks(lesson?.homework)} />}
        {getLessonFiles(lessonHomework)}
        {studentFiles?.isNotEmpty() && (
          <>
            <StDivider orientation="left">{strings.SUBMITED_HOMEWORK}</StDivider>
            {getLessonFiles(studentFiles, true)}
          </>
        )}
        {teacherFiles?.isNotEmpty() && (
          <>
            <StDivider orientation="left">{strings.CHECKED_HOMEWORK}</StDivider>
            {getLessonFiles(teacherFiles)}
          </>
        )}
      </div>
    </Spin>
  );
};

export default { HomeworkListItem };
