import { message } from 'antd';

export const autosave = (submit, ms) =>
  setInterval(() => {
    try {
      message.info('Auto-saving progress...');
      submit();
    } catch {
      message.error(`Sorry, unable to auto-save progress. Retry in ${ms / 1000} seconds...`);
    }
  }, ms);
