import React, { useEffect, useState } from 'react';
import { Form, Col, Row, DatePicker, Flex, Input, FormListFieldData, Checkbox, Select } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { dateFormat } from 'helpers/dates';
import { IParent, Tag } from 'types';
import { useMediaQuery } from 'react-responsive';
import { InputForMobile } from 'Global/components/FormComponents';
import { AvatarUploader } from './AvatarUploader';
import { StRow } from './EditParent';
import { BranchMoscow, BranchRiga } from 'types/branches';
import type { NamePath } from 'antd/lib/form/interface';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
const { Option } = Select;

type ParentFormProps = {
  parent?: IParent;
  familyBranch?: number;
  field?: FormListFieldData;
};

export const ParentForm: React.FC<ParentFormProps> = ({ parent, familyBranch, field }) => {
  const [strings] = useLanguageContext();
  const { parentTags } = useGlobalCollectionsContext();
  const form = Form.useFormInstance();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { mainPhoto, legalEntity, user } = parent || {};
  const hasMoscow = familyBranch === BranchMoscow.id;
  const hasRiga = familyBranch === BranchRiga.id;
  const [hasLegalEntity, setHasLegalEntity] = useState<boolean>(legalEntity);
  const [email, setEmail] = useState<string>(user?.email);

  const getName = (name): NamePath => (field ? [field.name, name] : name);

  useEffect(() => {
    form.validateFields([getName('phone')]);
  }, [email, hasLegalEntity, form]);

  return (
    <Flex justify="space-between" align="start" gap={20} vertical={isMobile}>
      <AvatarUploader name={getName('avatar')} mainPhoto={mainPhoto ? mainPhoto : ''} />
      <StRow>
        <Row gutter={[10, 10]}>
          <Col lg={6} xs={24}>
            <Form.Item
              name={getName('name')}
              label={strings.NAME}
              rules={[{ required: true, message: `${strings.NAME} ${strings.IS_REQUIRED}` }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item name={getName('surname')} label={strings.SURNAME}>
              <Input />
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item name={getName('patronymic')} label={strings.PATRONYMIC}>
              <Input />
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item name={getName('birthday')} label={strings.DATE_OF_BIRTH}>
              <DatePicker format={dateFormat} />
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item
              name={getName('phone')}
              label={strings.MOBILE}
              rules={[
                {
                  required: !email && !hasLegalEntity,
                  message: strings.FILL_IN_PHONE_OR_EMAIL
                }
              ]}
            >
              <InputForMobile />
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item
              name={getName('email')}
              label={strings.EMAIL}
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!'
                }
              ]}
            >
              <Input onChange={({ target: { value } }) => setEmail(value)} />
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item name={getName('profession')} label={strings.OCCUPATION}>
              <Input />
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item name={getName('passport')} label={strings.PASSPORT}>
              <Input />
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item name={getName('address')} label={strings.ADDRESS}>
              <Input />
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item name={getName('tags')} label={strings.TAGS}>
              <Select mode="multiple" maxTagCount="responsive" placeholder={strings.SELECT_TAGS}>
                {parentTags.map((tag: Tag) => (
                  <Option value={tag.id} key={tag.id}>
                    {tag.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item name={getName('comment')} label={strings.COMMENT}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col lg={6} xs={24}>
            <Form.Item name={getName('isPayer')} valuePropName="checked">
              <Checkbox>{strings.PAYER}</Checkbox>
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item name={getName('subscribed')} valuePropName="checked">
              <Checkbox>{strings.EMAIL_SUBSCRIPTION}</Checkbox>
            </Form.Item>
          </Col>
          <Col lg={6} xs={24}>
            <Form.Item name={getName('smsSubscribed')} valuePropName="checked">
              <Checkbox>{strings.SMS_NOTIFICATIONS}</Checkbox>
            </Form.Item>
          </Col>
          {hasMoscow && (
            <Col lg={6} xs={24}>
              <Form.Item name={getName('subscribeToHomework')} valuePropName="checked">
                <Checkbox>{strings.LGEG_WEEKLY_NEWSLETTER}</Checkbox>
              </Form.Item>
            </Col>
          )}
          {hasRiga && (
            <Col lg={6} xs={24}>
              <Form.Item name={getName('legalEntity')} valuePropName="checked">
                <Checkbox onChange={e => setHasLegalEntity(e.target.checked)}>{strings.PAY_AS_LEGAL_ENTITY}</Checkbox>
              </Form.Item>
            </Col>
          )}
        </Row>
        {hasRiga && hasLegalEntity && (
          <Row gutter={[10, 10]}>
            <Col lg={12} xs={24}>
              <Form.Item
                name={getName('bankAccountNumber')}
                label={strings.ACCOUNT_NUMBER}
                rules={[{ required: true, message: `${strings.ACCOUNT_NUMBER} ${strings.IS_REQUIRED}` }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={6} xs={24}>
              <Form.Item
                name={getName('bankCode')}
                label={strings.BANK_CODE}
                rules={[{ required: true, message: `${strings.BANK_CODE} ${strings.IS_REQUIRED}` }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={6} xs={24}>
              <Form.Item
                name={getName('bankName')}
                label={strings.BANK}
                rules={[{ required: true, message: `${strings.BANK} ${strings.IS_REQUIRED}` }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        )}
      </StRow>
    </Flex>
  );
};
export default { ParentForm };
