import React from 'react';
import { Link } from 'react-router-dom';
import { TeacherBrief } from 'types/Teachers';
import { BASE_TEACHERS } from 'Admin/People/Teachers/routes';
import { TABLE_TYPES } from './constants';

export const columns = strings => [
  {
    title: strings.NAME,
    dataIndex: 'teacher',
    key: 'teacher',
    width: 170,
    render: (teacher: TeacherBrief) => (
      <div style={{ minWidth: 100 }}>
        <Link to={`${BASE_TEACHERS}/${teacher.id}`}>{teacher.name}</Link>
      </div>
    )
  },
  {
    title: strings.DISCIPLINE,
    dataIndex: 'discipline',
    key: 'discipline',
    children: [
      {
        title: strings.REGISTERS,
        dataIndex: 'discipline',
        key: 'registers',
        render: (discipline, row) => {
          return (
            <a onClick={() => row.handleOpenModal(row.teacher.id, TABLE_TYPES.registers.name)}>
              {discipline?.registers}
            </a>
          );
        }
      },
      {
        title: strings.ON_TIME,
        dataIndex: 'discipline',
        key: 'onTime',
        render: discipline => <p>{discipline.onTime}</p>
      },
      {
        title: strings.TOTAL_PERSENT,
        dataIndex: 'discipline',
        key: 'total',
        render: discipline => <p>{discipline.total}</p>
      }
    ]
  },
  {
    title: strings.FEEDBACK,
    dataIndex: 'feedback',
    key: 'feedback',
    children: [
      {
        title: strings.COMMENTS_GRADED,
        dataIndex: 'feedback',
        key: 'commentsGraded',
        render: discipline => <p>{discipline.commentsGraded}</p>
      },
      {
        title: strings.LIKED,
        dataIndex: 'feedback',
        key: 'liked',
        render: (feedback, row) => {
          return <a onClick={() => row.handleOpenModal(row.teacher.id, TABLE_TYPES.liked.name)}>{feedback.liked}</a>;
        }
      },
      {
        title: strings.DISLIKED,
        dataIndex: 'feedback',
        key: 'disliked',
        render: (feedback, row) => {
          return <a onClick={() => row.handleOpenModal(row.teacher.id, TABLE_TYPES.disliked.name)}>{feedback.disliked}</a>;
        }
      },
      {
        title: strings.PARENT_LIKED,
        dataIndex: 'feedback',
        key: 'parentLiked',
        render: (feedback, row) => {
          return (
            <a onClick={() => row.handleOpenModal(row.teacher.id, TABLE_TYPES.parentDisliked.name)}>{feedback.parentLiked}</a>
          );
        }
      }
    ]
  },
  {
    title: strings.KARMA,
    dataIndex: 'karma',
    key: 'karma'
  },
  {
    title: strings.STUDENT,
    dataIndex: 'students',
    key: 'students',
    children: [
      {
        title: strings.USEFUL,
        dataIndex: 'students',
        key: 'useful',
        render: students => <p>{students.useful}</p>
      },
      {
        title: strings.INTERESTING,
        dataIndex: 'students',
        key: 'interesting',
        render: students => <p>{students.interesting}</p>
      },
      {
        title: strings.GRADES_TOTAL,
        dataIndex: 'students',
        key: 'gradesTotal',
        render: students => <p>{students.gradesTotal}</p>
      },
      {
        title: strings.UNIQUE_VOTERS,
        dataIndex: 'students',
        key: 'uniqueVoters',
        render: students => <p>{students.uniqueVoters}</p>
      },
      {
        title: strings.VOTERS_SHARE,
        dataIndex: 'students',
        key: 'votersShare',
        render: students => <p>{students.votersShare}</p>
      }
    ]
  }
];

export default { columns };
