import React, { useEffect, useState } from 'react';
import { Button, Flex, message, Popconfirm, Row, Spin, Table } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Branch } from 'types';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useTagsRepository } from 'repos/TagsRepository';
import { TagsFormModal, TagType } from './TagsFormModal';

export const TeacherTagsTab = () => {
  const [strings] = useLanguageContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const [selectedTag, setSelectedTag] = useState<number>(null);
  const [dataList, setDataList] = useState<Branch[]>([]);
  const tagsRepository = useTagsRepository();

  const fetchTags = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await tagsRepository.getTeacherTags();
      setDataList(data);
    } catch (e) {
      message.error(e.message);
    } finally {
      setHasLoading(false);
    }
  };

  const handleDelete = async (id: number): Promise<any> => {
    setHasLoading(true);
    try {
      await tagsRepository.deleteTeacherTag(id);
      fetchTags();
    } finally {
      setHasLoading(false);
    }
  };

  const getColumns = [
    {
      title: strings.NAME,
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: strings.COMMENT,
      key: 'comment',
      dataIndex: 'comment'
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'action',
      render: (id: number) => (
        <Flex gap={10} justify="end">
          <Button
            icon={<EditOutlined />}
            size="small"
            onClick={() => {
              setSelectedTag(id);
              setHasShowModal(true);
            }}
          />

          <Popconfirm
            title={strings.ARE_YOU_SURE}
            placement="left"
            okText={strings.YES}
            cancelText={strings.NO}
            onConfirm={() => handleDelete(id)}
          >
            <Button icon={<DeleteOutlined />} size="small" danger />
          </Popconfirm>
        </Flex>
      )
    }
  ];

  useEffect(() => {
    fetchTags();
  }, []);

  return (
    <>
      <TagsFormModal
        id={selectedTag}
        handler={fetchTags}
        type={TagType.teacher}
        hasShowModal={hasShowModal}
        setHasShowModal={setHasShowModal}
      />
      <Flex justify="end">
        <Button
          type="primary"
          onClick={() => {
            setSelectedTag(null);
            setHasShowModal(true);
          }}
        >
          {strings.ADD}
        </Button>
      </Flex>
      <Spin spinning={hasLoading}>
        <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
          <Table dataSource={dataList} columns={getColumns} pagination={false} />
        </Row>
      </Spin>
    </>
  );
};

export default { TeacherTagsTab };
