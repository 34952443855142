import React, { useEffect, useState } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { Button, message, Spin, Row, Form } from 'antd';
import { Courses } from 'api';
import { CHOOSE_ORIGINAL_COURSE, default as courses } from '../routes';
import { CourseGroupParams } from 'types/education';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useHistory } from 'react-router-dom';
import { Container } from 'Global/GlobalStyle';
import { useGroupContext } from 'contexts/GroupContext';
import { GroupForm } from './GroupForm';

/**
 * @description Create Group course component
 * @return {React.ReactNode}
 */
export const AddCourseCroup = () => {
  const [form] = Form.useForm();
  const [strings] = useLanguageContext();
  const [loading, setLoading] = useState<boolean>(false);
  const { goBack, push } = useHistory();
  const [
    {
      selectedOriginalCourse,
      setSelectedOriginalCourse,
      prefixGenerateCourses,
      setPrefixGenerateCourses,
      newSuperGroup,
      setNewSuperGroup
    }
  ] = useGroupContext();

  const handleSubmit = async (values): Promise<any> => {
    message.loading(strings.CREATE_GROUP_COURSE, 2);
    const { items } = values;
    try {
      setLoading(true);
      await Promise.all([items.forEach(item => createCourseGroupById(item.originalCourse, item))]);
      if (selectedOriginalCourse.length > 1) {
        handleSuccess();
      }
    } catch {
    } finally {
      setLoading(false);
    }
  };

  /**
   * @param {number} id
   * @param {object} params
   */
  const createCourseGroupById = async (id: number, params: CourseGroupParams): Promise<any> => {
    setLoading(true);
    if (newSuperGroup) {
      params['supergroup'] = newSuperGroup?.id;
    }
    try {
      const { data } = await Courses.createCourseGroup(id, params);
      if (selectedOriginalCourse.length === 1) {
        handleSuccess(data.id);
      }
    } catch {
    } finally {
      setLoading(false);
    }
  };
  const handleSuccess = (id?: number) => {
    if (id) {
      push(`${courses.BASE_COURSE_GROUP}/${id}`);
    } else {
      push(`${courses.BASE_COURSE_GROUP}`);
    }
    message.success(strings.GROUP_COURSE_CREATE, 2);
    //обнуляем OriginalCourse, на основе которых создавались группы
    setSelectedOriginalCourse([]);
    setPrefixGenerateCourses('');
    setNewSuperGroup(null);
  };

  useEffect(() => {
    if (selectedOriginalCourse.isEmpty()) {
      push(CHOOSE_ORIGINAL_COURSE);
    }
  }, [selectedOriginalCourse]);

  return (
    <Spin spinning={loading}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <PageHeader style={{ padding: 0, margin: 20 }} onBack={goBack} title={strings.ADD_GROUP_COURSE} />
        <Form.List name="items" initialValue={selectedOriginalCourse.map(id => ({ originalCourse: id }))}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Container key={field.key}>
                  <GroupForm
                    originalCourseId={selectedOriginalCourse[index]} // передаем ID курса
                    prefix={prefixGenerateCourses}
                    field={field}
                    index={index}
                    nameList="items"
                  />
                </Container>
              ))}
            </>
          )}
        </Form.List>
        <Row style={{ margin: 20, textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" size="large">
            {strings.ADD}
          </Button>
        </Row>
      </Form>
    </Spin>
  );
};

export default { AddCourseCroup };
