import React, { Fragment } from 'react';
import { Table, Row, Button } from 'antd';
import { ActualGroup } from 'types/courses';
import { Branch } from 'types';
import { Link, useHistory } from 'react-router-dom';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useGroupContext } from 'contexts/GroupContext';
import { BASE_COURSE_GROUP_ADD } from '../../routes';

interface GroupsProps {
  actualGroups: ActualGroup[];
  originCourseId: number | string;
}

/**
 * @description Groups
 * @param {object} props - component props
 * @return {React.ReactNode}
 */

export const Groups = ({ actualGroups, originCourseId }: GroupsProps) => {
  const [strings] = useLanguageContext();
  const { push } = useHistory();
  const [{ setSelectedOriginalCourse }] = useGroupContext();

  const handleAddGroup = (): void => {
    setSelectedOriginalCourse([originCourseId]);
    push(BASE_COURSE_GROUP_ADD);
  };

  const columns = [
    {
      title: 'Branch',
      dataIndex: 'branch',
      key: 'branch',
      render: (branch: Branch) => <p>{branch && branch.name}</p>
    },
    {
      title: 'Teachers',
      dataIndex: 'teachers',
      key: 'teachers',
      render: teachers => {
        return (
          <div>
            {teachers?.main.map((item, i) => (
              <span>
                {i !== 0 ? ', ' : ''}
                {item.name} {item.surname}
              </span>
            ))}
            {teachers?.additional.map(item => (
              <span>
                , {item.name} {item.surname}
              </span>
            ))}
          </div>
        );
      }
    },
    {
      title: 'Group',
      dataIndex: 'groupName',
      key: 'groupName',
      render: (groupName, record) => <Link to={`/group/${record.id}`}>{groupName}</Link>
    }
  ];

  return (
    <Fragment>
      <Row>
        <Button type="primary" onClick={handleAddGroup}>
          {strings.ADD}
        </Button>
      </Row>
      <Table dataSource={actualGroups} columns={columns} bordered pagination={false} style={{ marginTop: 20 }} />
    </Fragment>
  );
};

export default { Groups };
