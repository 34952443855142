import React from 'react';
import { PlusCircleTwoTone } from '@ant-design/icons';
import { Course } from 'types/TeacherAccount/Courses';
import { WorkProgressColumn, WorkProgress } from 'types/TeacherAccount/types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { dateFormat, dateTimeWithoutSecondFormat } from 'helpers/dates';

function renderProgress(progress: WorkProgressColumn, showFilledDate?: boolean) {
  return !progress.filled ? (
    showFilledDate && progress.expired && (
      <span style={{ marginInlineStart: 6, color: '#777', fontSize: 12 }}>{dayjs(progress.expired).format(dateFormat)}</span>
    )
  ) : (
    <PlusCircleTwoTone twoToneColor="#52c41a" />
  );
}

export const columns = (strings, showFilledDate?: boolean) => [
  {
    title: strings.DATE,
    dataIndex: 'startAt',
    key: 'startAt',
    render: (date: string) => date && dayjs(date).format(dateTimeWithoutSecondFormat)
  },
  {
    title: strings.GROUP,
    dataIndex: 'group',
    key: 'group',
    render: (group: Course, record: WorkProgress) =>
      group?.name && <Link to={`/le/${record.lessonId}`}>{group.name}</Link>
  },
  {
    title: strings.STUDENTS, // check
    dataIndex: 'students',
    key: 'students',
    render: (students: WorkProgressColumn) => renderProgress(students, showFilledDate)
  },
  {
    title: strings.LESSON_MATERIALS,
    dataIndex: 'lessonMaterials',
    key: 'lessonMaterials',
    render: (lessonMaterials: WorkProgressColumn) => renderProgress(lessonMaterials, showFilledDate)
  },
  {
    title: strings.HOMEWORK,
    dataIndex: 'homeworkGrade',
    key: 'homeworkGrade',
    render: (homeworkGrade: WorkProgressColumn) => renderProgress(homeworkGrade, showFilledDate)
  },
  {
    title: strings.HOME_ASSIGNMENT,
    dataIndex: 'assignedHW',
    key: 'assignedHW',
    render: assignedHW => <div dangerouslySetInnerHTML={{ __html: assignedHW }} />
  },
  {
    title: strings.COMMENTS,
    dataIndex: 'commentaries',
    key: 'commentaries',
    render: (commentaries: WorkProgressColumn) => renderProgress(commentaries, showFilledDate)
  }
];

export default { columns };
