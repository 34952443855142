import styled from 'styled-components';

export const Card = styled.div`
  background-color: white;
  border: 1px solid #e8e8e8;
  padding: 8px;

  button {
    opacity: 0;
    transition: 0.4s;
    color: #ff4d4f;
  }

  &:hover {
    button {
      opacity: 1;
    }
  }
`;

export const CardsList = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .ant-spin-container {
    .column-card-draggable {
      margin-bottom: 5px;
    }
  }
`;
