import React, { useEffect, useState } from 'react';
import { Button, Col, Input, message, Row, Select, Spin, Checkbox, Form } from 'antd';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useLanguageContext } from 'contexts/LanguageContext';
import { convertBooleanToNumber } from 'helpers';
import { BASE_PERFORMERS } from '../../routes';
import { IPerformer, IPerformerPostParams } from 'types/performers';
import { Branch } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { usePerformersRepository } from 'repos/PerformersRepository';
import { contractTemplates } from 'Global/constants';
import { match } from 'react-router';

const { Option } = Select;

const PerformerForm: React.FC = () => {
  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();
  const { goBack, push } = useHistory();
  const [strings] = useLanguageContext();
  const { branches } = useGlobalCollectionsContext();
  const performersRepository = usePerformersRepository();
  const [form] = Form.useForm();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [performer, setPerformer] = useState<IPerformer | null>(null);

  const fetchPerformer = async (): Promise<void> => {
    setHasLoading(true);
    try {
      const { data } = await performersRepository.getPerformerById(id);
      setPerformer(data);
      const {
        name,
        phone,
        email,
        branch,
        receiverBank,
        inn,
        acc,
        bik,
        corAcc,
        nameFull,
        directorFull,
        directorShort,
        directorPosition,
        kpp,
        bankAddress,
        address,
        city,
        individualInfo,
        contractTemplate,
        actingOn,
        ogrn,
        isActive
      } = data;
      form.setFieldsValue({
        name,
        phone,
        email,
        branch: branch?.id,
        receiverBank,
        inn,
        acc,
        bik,
        corAcc,
        nameFull,
        directorFull,
        directorShort,
        directorPosition,
        kpp,
        bankAddress,
        address,
        city,
        individualInfo,
        contractTemplate,
        actingOn,
        ogrn,
        isActive
      });
    } catch {
      message.error(strings.SORRY_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN);
    } finally {
      setHasLoading(false);
    }
  };

  const handleSubmit = async (values: IPerformerPostParams): Promise<void> => {
    setHasLoading(true);
    try {
      const params = convertBooleanToNumber(values) as IPerformerPostParams;
      if (id) {
        await performersRepository.editPerformer(id, params);
      } else {
        await performersRepository.createPerformer(params);
      }
      push(BASE_PERFORMERS);
      message.success(`${strings.PERFORMER} ${id ? strings.SUCCESSFULLY_EDITED : strings.SUCCESSFULLY_CREATED}`, 2);
    } catch (err) {
      message.error(err.message);
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchPerformer();
    }
  }, [id]);

  return (
    <Container>
      <StPageHeader onBack={goBack} title={id ? strings.EDIT : strings.ADD} />
      <Spin spinning={hasLoading} tip={strings.LOADING}>
        <Form layout="vertical" form={form} onFinish={handleSubmit} initialValues={performer || {}}>
          <Row gutter={[10, 10]}>
            <Col lg={8}>
              <Form.Item
                label={strings.NAME_OF_THE_BENEFICIARY}
                name="name"
                rules={[{ required: true, message: `${strings.NAME_OF_THE_BENEFICIARY} ${strings.IS_REQUIRED}` }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item
                label={strings.NAME_OF_THE_BENEFICIARY_BANK}
                name="receiverBank"
                rules={[{ required: true, message: `${strings.NAME_OF_THE_BENEFICIARY_BANK} ${strings.IS_REQUIRED}` }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item label={strings.BRANCH} name="branch" rules={[{ required: true }]}>
                <Select>
                  {branches.map((branch: Branch) => (
                    <Option value={branch.id} key={branch.id}>
                      {branch.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item
                label={strings.INN}
                name="inn"
                rules={[{ required: true, message: `${strings.INN} ${strings.IS_REQUIRED}` }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item
                label={strings.ACC}
                name="acc"
                rules={[{ required: true, message: `${strings.ACC} ${strings.IS_REQUIRED}` }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item
                label={strings.BIK}
                name="bik"
                rules={[{ required: true, message: `${strings.BIK} ${strings.IS_REQUIRED}` }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item
                name="phone"
                label={strings.PHONE}
                rules={[
                  { required: true, message: `${strings.PHONE} ${strings.IS_REQUIRED}` },
                  {
                    pattern: /^\+?[0-9\b]+$/,
                    message: strings.INVALID_FORMAT
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item
                label={strings.EMAIL}
                name="email"
                rules={[{ required: true, type: 'email', message: `${strings.EMAIL} ${strings.IS_REQUIRED}` }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item
                label={strings.ADDRESS}
                name="address"
                rules={[{ required: true, message: `${strings.ADDRESS} ${strings.IS_REQUIRED}` }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={6}>
              <Form.Item
                label={strings.COR_ACC_SHORT}
                name="corAcc"
                rules={[{ required: true, message: `${strings.COR_ACC_SHORT} ${strings.IS_REQUIRED}` }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={6}>
              <Form.Item label={strings.OGRN} name="ogrn">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={6}>
              <Form.Item label={strings.KPP} name="kpp">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={6}>
              <Form.Item label={strings.BANK_ADDRESS} name="bankAddress">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item label={strings.NAME_FULL} name="nameFull">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item label={strings.DIRECTOR_FULL} name="directorFull">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item label={strings.DIRECTOR_SHORT} name="directorShort">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item label={strings.DIRECTOR_POSITION} name="directorPosition">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item label={strings.CITY} name="city">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item label={strings.CONTRACT_TEMPLATE} name="contractTemplate">
                <Select allowClear>
                  {contractTemplates.map(item => (
                    <Option value={item.value} key={item.value}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item label={strings.INDIVIDUAL_INFO} name="individualInfo">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item label={strings.ACTING_ON} name="actingOn">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={4}>
              <Form.Item name="isActive" valuePropName="checked">
                <Checkbox>{strings.ACTIVE}</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Button type="primary" size="large" htmlType="submit">
                {id ? strings.EDIT : strings.ADD}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Container>
  );
};

export default PerformerForm;
