import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Select, Input, message, Checkbox, Form } from 'antd';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import { COURSE_GROUPS_LIMIT } from 'Global/constants';
import { Branch, Subject } from 'types';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { StageSelect } from 'Global/components/FormComponents';
import { FilterButtons } from 'Global/components/FilterButtons';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { useUserContext } from 'contexts/UserContext';
import { GetCourseGroupsParams } from 'types/education';
import { FormItemWithoutMargin } from 'Global/styles';

const { Option } = Select;
const defValues = { limit: COURSE_GROUPS_LIMIT, active: 1 };

interface FilterCourseGroupsProps {
  hasTopManagerRole: boolean;
  selectedRowKeys: number[];
  setSelectedRowKeys: (rowKeys: number[]) => void;
  getAllCourseGroups: (params: GetCourseGroupsParams) => Promise<any>;
}

/**
 * @description Filter group course component
 * @return {React.ReactNode}
 */
export const Filter = (props: FilterCourseGroupsProps): any => {
  const { selectedRowKeys, setSelectedRowKeys, getAllCourseGroups } = props;
  const [strings] = useLanguageContext();
  const [form] = Form.useForm();
  const [user] = useUserContext();
  const hasTopManager = user?.hasRoles?.topManager;
  const { branches, subjects } = useGlobalCollectionsContext();
  const coursesRepository = useCoursesRepository();

  const { locationSearchParams } = useLocationSearchParams();
  const { branch, stage, name, active } = locationSearchParams || {};
  const [selectedBranch, setSelectedBranch] = useState<number>(branch);

  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);

  const handleSubmit = async (values: GetCourseGroupsParams): Promise<any> => {
    const formValues = pickBy({ ...values, page: 1, limit: COURSE_GROUPS_LIMIT }, identity);
    try {
      setHasSubmitLoading(true);
      await getAllCourseGroups(formValues);
    } catch {
    } finally {
      setHasSubmitLoading(false);
    }
  };

  /**
   * @description Очистка формы
   */
  const handleClear = async (): Promise<any> => {
    await getAllCourseGroups(defValues);
    form.resetFields();
  };

  /**
   * Bulk деактивация групп
   */
  const onDeactivateGroups = async () => {
    try {
      await coursesRepository.courseGroupsBulkDeactivate(selectedRowKeys);
      await getAllCourseGroups(locationSearchParams);
      setSelectedRowKeys([]);
      message.success(strings.SUCCESSFULLY_DEACTIVATED, 2);
    } catch {
      message.error(strings.DEACTIVATED_ERROR_TRY_AGAIN_LATER, 2);
    }
  };

  const extraBtn = selectedRowKeys.length !== 0 && (
    <Button danger size="large" onClick={onDeactivateGroups}>
      {strings.DEACTIVATE}
    </Button>
  );
  useEffect(() => {
    form.setFieldsValue(locationSearchParams);
  }, [locationSearchParams]);

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Row gutter={[10, 10]}>
        <Col xs={hasTopManager ? 24 : 0} lg={hasTopManager ? 5 : 0}>
          <FormItemWithoutMargin name="branch" hidden={!hasTopManager}>
            <Select placeholder={strings.BRANCH} allowClear onChange={val => setSelectedBranch(val)}>
              {branches.map(branch => (
                <Option value={branch.id} key={branch.id}>
                  {branch.name}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col xs={24} lg={5}>
          <FormItemWithoutMargin name="stage">
            <StageSelect branch={selectedBranch} />
          </FormItemWithoutMargin>
        </Col>
        <Col xs={24} lg={5}>
          <FormItemWithoutMargin name="name">
            <Input placeholder={strings.SEARCH_GROUP} />
          </FormItemWithoutMargin>
        </Col>
        <Col xs={24} lg={5}>
          <FormItemWithoutMargin name="subject">
            <Select placeholder={strings.SUBJECT} allowClear>
              {subjects.map((subject: Subject) => (
                <Option key={subject.id} value={subject.id}>
                  {subject.name}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col xs={24} lg={4}>
          <FormItemWithoutMargin name="active" valuePropName="checked">
            <Checkbox>{strings.ACTIVE}</Checkbox>
          </FormItemWithoutMargin>
        </Col>
      </Row>
      <FilterButtons handleClear={handleClear} extra={extraBtn} hasSubmitLoading={hasSubmitLoading} />
    </Form>
  );
};

export default { Filter };
