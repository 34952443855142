import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Row, Col, Input, Select, Upload, Button } from 'antd';
import { InfoForm as StInfoForm } from 'Admin/Education/styles';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { useLanguageContext } from 'contexts/LanguageContext';

interface infoFormProps {
  form: WrappedFormUtils;
}

export const InfoForm = Form.create({})((props: infoFormProps) => {
  const [strings] = useLanguageContext();
  const {
    form: { getFieldDecorator }
  } = props;

  /**
   * @param {React.FormEvent} e
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
  };
  return (
    <StInfoForm>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Row>
          <Col lg={12}>
            {getFieldDecorator('number', {
              rules: [{ required: false }]
            })(<Input placeholder={strings.NUMBER} />)}
          </Col>
          <Col lg={12}>
            {getFieldDecorator('unit', {
              rules: [{ required: false }]
            })(<Select placeholder={strings.UNIT} />)}
          </Col>
          <Col lg={24}>
            {getFieldDecorator('lessonTopicFromSyllabus', {
              rules: [{ required: true }]
            })(<Select placeholder={strings.LESSON_TOPIC_FROM_SYLLABUS} />)}
          </Col>
          <Col lg={24}>
            {getFieldDecorator('lessonTopic', {
              rules: [{ required: true }]
            })(<Select placeholder={strings.LESSON_TOPIC_FROM_SYLLABUS} />)}
          </Col>
          <Col lg={24}>
            {getFieldDecorator('classroomMaterials', {
              rules: [{ required: true }]
            })(<Input.TextArea placeholder={strings.CLASSROOM_MATERIALS} />)}
          </Col>
          <Col lg={24}>
            {getFieldDecorator('homeAssignment', {
              rules: [{ required: true }]
            })(<Input.TextArea placeholder={strings.HOME_ASSIGNMENT} />)}
          </Col>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Col lg={24} style={{ margin: '15px 0' }}>
            {getFieldDecorator('tests', {
              rules: [{ required: true }]
            })(
              <Upload>
                <Button>
                  <UploadOutlined /> Tests
                </Button>
              </Upload>
            )}
          </Col>
          <Col lg={24} style={{ marginInlineStart: '15px 0' }}>
            {getFieldDecorator('additionalMaterials', {
              rules: [{ required: true }]
            })(
              <Upload>
                <Button>
                  <UploadOutlined /> {strings.ADDITIONAL_MATERIALS}
                </Button>
              </Upload>
            )}
          </Col>
          <Col lg={24} style={{ margin: '15px 0' }}>
            {getFieldDecorator('hiddenMaterials', {
              rules: [{ required: true }]
            })(
              <Upload>
                <Button>
                  <UploadOutlined /> {strings.HIDDEN_MATERIALS}
                </Button>
              </Upload>
            )}
          </Col>
        </Row>
      </Form>
    </StInfoForm>
  );
});

export default { InfoForm };
