import React, { useMemo, useContext } from 'react';
import { httpService } from 'api/core';
import { ITagPostParams } from 'types';

const TagsContext = React.createContext<ITagsRepository>(null);

interface ITagsRepository {
  getTeacherTags: () => Promise<any>;
  getTeacherTagById: (id: number) => Promise<any>;
  addTeacherTag: (params: ITagPostParams) => Promise<any>;
  editTeacherTag: (id: number, params: ITagPostParams) => Promise<any>;
  deleteTeacherTag: (id: number) => Promise<any>;
  getFamilyTags: () => Promise<any>;
  getFamilyTagById: (id: number) => Promise<any>;
  addFamilyTag: (params: ITagPostParams) => Promise<any>;
  editFamilyTag: (id: number, params: ITagPostParams) => Promise<any>;
  deleteFamilyTag: (id: number) => Promise<any>;
}

class TagsRepository implements ITagsRepository {
  getTeacherTags() {
    return httpService.get('/api/v1/tags/teachers', null, null, true);
  }
  getTeacherTagById(id: number) {
    return httpService.get(`/api/v1/tags/teachers/${id}`, null, null, true);
  }
  addTeacherTag(params: ITagPostParams) {
    return httpService.post(`/api/v1/tags/teachers`, params, null, null, true);
  }
  editTeacherTag(id: number, params: ITagPostParams) {
    return httpService.put(`/api/v1/tags/teachers/${id}`, params, null, null, true);
  }
  deleteTeacherTag(id: number) {
    return httpService.delete(`/api/v1/tags/teachers/${id}`, null, null, true);
  }
  getFamilyTags() {
    return httpService.get('/api/v1/tags/family', null, null, true);
  }
  getFamilyTagById(id: number) {
    return httpService.get(`/api/v1/tags/family/${id}`, null, null, true);
  }
  addFamilyTag(params: ITagPostParams) {
    return httpService.post(`/api/v1/tags/family`, params, null, null, true);
  }
  editFamilyTag(id: number, params: ITagPostParams) {
    return httpService.put(`/api/v1/tags/family/${id}`, params, null, null, true);
  }
  deleteFamilyTag(id: number) {
    return httpService.delete(`/api/v1/tags/family/${id}`, null, null, true);
  }
}

export const useTagsRepository = () => {
  const service = useContext(TagsContext);

  if (!service) {
    throw new Error('Tags repository is unavailable');
  }

  return service;
};

export const TagsRepositoryProvider = props => {
  const service = useMemo(() => new TagsRepository(), []);

  return <TagsContext.Provider value={service}>{props.children}</TagsContext.Provider>;
};
