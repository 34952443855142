import React from 'react';
import ru from 'Global/components/Header/img/ru.png';
import en from 'Global/components/Header/img/en.png';
import lv from 'Global/components/Header/img/lv.png';
import ge from 'Global/components/Header/img/ge.png';
import iw from 'Global/components/Header/img/iw.png';
import { AVAILABLE_LANGS, useLanguageContext } from 'contexts/LanguageContext';
import { useUserContext } from 'contexts/UserContext';
import { Select } from 'antd';
import styled from 'styled-components';
import { BranchBruner } from 'types/branches';
const { Option } = Select;

enum Lang {
  ru = 'ru-RU',
  en = 'en-US',
  lv = 'lv-LV',
  ka = 'ka-GE',
  iw = 'he-IL'
}

export const StLangSelect = styled.div`
  max-width: 80px;
  display: flex;
  justify-items: center;

  .lang-select .ant-select-selector {
    border: none;
  }
  .lang-select .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
`;

export const Languages = () => {
  const [, lang, setLang] = useLanguageContext();
  const [user] = useUserContext();
  const hasParentOrStudentRole = user?.hasRoles?.student || user?.hasRoles?.parent;
  const hasTeacher = user?.hasRoles?.teacher;
  const hasBruner = user?.branch?.id === BranchBruner.id;

  if (hasParentOrStudentRole || (hasTeacher && !hasBruner)) {
    return (
      <StLangSelect>
        <Select
          className="lang-select"
          defaultValue={AVAILABLE_LANGS[lang] || Lang[lang]}
          onSelect={val => setLang(val)}
        >
          <Option value={Lang.en}>
            <img src={en} alt="en" style={{ width: 30 }} />
          </Option>
          <Option value={Lang.ru}>
            <img src={ru} alt="ru" style={{ width: 30 }} />
          </Option>
          {hasParentOrStudentRole && (
            <Option value={Lang.lv}>
              <img src={lv} alt="lv" style={{ width: 30 }} />
            </Option>
          )}
          {hasParentOrStudentRole && (
            <Option value={Lang.ka}>
              <img src={ge} alt="ge" style={{ width: 30 }} />
            </Option>
          )}
          {hasParentOrStudentRole && (
            <Option value={Lang.iw}>
              <img src={iw} alt="he" style={{ width: 30 }} />
            </Option>
          )}
        </Select>
      </StLangSelect>
    );
  }
  return null;
};

export default { Languages };
