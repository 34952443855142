import { httpService } from 'api/core';
import * as child from './child';
import * as parent from './parent';
import * as invoices from './invoices';
import * as discounts from './discounts';
import * as finances from './finances';
import * as deposit from './deposit';
import * as contracts from './contracts';
import * as learnedFrom from './learnedFrom';
import * as family from './family';
import { RatesParams } from 'types/API/global';
import { IChildrenFilter, IParentsFilter } from 'types/Family';
import { FamiliesFilterParams } from 'types/Family/';
import { CreateContactOrStatusFamilyParams, ManualAccountBlockApi } from 'types/API/families';

/**
 * @description Получение списка семей
 * @param {object} params
 * @return {Promise<any>}
 */
const getAllFamilies = (params: any): Promise<any> => httpService.get(`/api/families`, params);

/**
 * @description Добавление тега в контракт
 * @param {number} familyId
 * @param {number} contractId
 * @param {any} params
 * @return {Promise<any>}
 */
const addTagToFamilyContract = (familyId: number, contractId: number, params: any): Promise<any> =>
  httpService.post(`/api/families/${familyId}/contracts/${contractId}/tags`, params);

/**
 * @description Удаление тега у контрактов
 * @param {number} familyId
 * @param {number} contractId
 * @param {number} tagId
 * @return {Promise<any>}
 */
const deleteTagFromFamilyContract = (familyId: number, contractId: number, tagId: number): Promise<any> =>
  httpService.delete(`/api/families/${familyId}/contracts/${contractId}/tags/${tagId}`);

/**
 * @description Список тегов в семейных контрактах
 * @return {Promise<any>}
 */
const getFamilyContractsTags = (): Promise<any> => httpService.get('/api/tags/family-contracts');

/**
 * @description Получение телефонов  родителей для фильтра
 * @param {object} params
 * @return {Promise<any>}
 */
const getParentsByFilter = (params: IParentsFilter): Promise<any> => httpService.get('/api/parents', params);

/**
 * @description Получение списка родителей в семье
 * @param {number} id
 * @return {Promise<any>}
 */
const getParentsOfFamily = (id: number): Promise<any> => httpService.get(`/api/family/${id}/parents_list`);

/**
 * @description Получение телефонов детей для фильтра
 * @param {object} params
 * @return {Promise<any>}
 */
const getChildrenByFilter = (params: IChildrenFilter): Promise<any> => httpService.get('/api/children', params);

/**
 * @description Получение acceptance acts
 * @return {Promise<any>}
 */
export const getAcceptance = (): Promise<any> => httpService.get('/api/family/my/acceptance-acts');

/**
 * @description download file acceptance acts
 * @param {number} id
 * @return {Promise<any>}
 */
export const getDownloadAcceptance = (id: number): Promise<any> =>
  httpService.get(`/api/acceptance-acts/${id}/download`);

/**
 * @description Получить статусы контрактов
 * @returns {Promise<any>}
 */
export const getFamilyContractStatuses = (): Promise<any> => httpService.get('/api/family/contracts/statuses');

/**
 * @description Получить драфты для контракта
 * @param {number} id
 * @returns {Promise<any>}
 */
export const getFamilyContractTemplates = (id: number | string): Promise<any> =>
  httpService.get(`/api/family/${id}/contracts/templates`);

/**
 * @description Создать контракт семьи
 * @param {number} familyId
 * @param {CreateContract} params
 * @return {Promise<any>}
 */
export const createFamilyContract = (familyId: number, params: object): Promise<any> =>
  httpService.post(`/api/families/${familyId}/contracts/`, params);

/**
 * @description Get list of families LearnedFrom
 * @return {Promise<any>}
 */
export const familyLearnedFrom = (): Promise<any> => httpService.get('/api/family/learned-from');

/**
 * @description Создать контакт или статус для семьи
 * @param {number} familyId
 * @param {CreateContactOrStatusFamilyParams} params
 */
export const createFamilyContact = (familyId: number, params: CreateContactOrStatusFamilyParams): Promise<any> =>
  httpService.post(`/api/families/${familyId}/contacts`, params);

/**
 * @description Блокировка финансов семьи
 * @param {number} familyId
 * @param {ManualAccountBlockApi} params
 * @return {Promise<any>}
 */
export const manualAccountBlock = (familyId: number, params: ManualAccountBlockApi): Promise<any> =>
  httpService.post(`/api/families/${familyId}/manual-block`, params);

/**
 * @description Добавление нового бесплатного дня
 * @param {number} familyId
 * @param {any} params
 * @return {Promise<any>}
 */
export const addNewFreeDay = (familyId: number, params: any): Promise<any> =>
  httpService.post(`/api/families/${familyId}/free-days`, params);

/**
 * @description Удаление бесплатной семьи
 * @param {number} familyId
 * @return {Promise<any>}
 */
export const deleteFreeDay = (familyId: number): Promise<any> =>
  httpService.delete(`/api/families/free-days/${familyId}`);

/**
 * @description Список бесплатных дней семьи
 * @param {number} familyId
 * @return {Promise<any>}
 */
export const getFreeDays = (familyId: number): Promise<any> => httpService.get(`/api/families/${familyId}/free-days`);

export const Families = {
  ...child.default,
  ...parent.default,
  ...invoices.default,
  ...discounts.default,
  ...finances.default,
  ...deposit.default,
  ...contracts.default,
  ...learnedFrom.default,
  ...family.default,
  getAllFamilies,
  getDownloadAcceptance,
  getAcceptance,
  createFamilyContract,
  getParentsByFilter,
  getParentsOfFamily,
  addTagToFamilyContract,
  getFamilyContractsTags,
  deleteTagFromFamilyContract,
  getFamilyContractStatuses,
  getFamilyContractTemplates,
  familyLearnedFrom,
  createFamilyContact,
  manualAccountBlock,
  addNewFreeDay,
  deleteFreeDay,
  getFreeDays
};

export default { Families };
