import React, { useState, useEffect } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Typography, Row, Col, Tabs, Spin } from 'antd';
import { History, Location } from 'history';
import queryString from 'query-string';
import { Group as StGroup, GroupHeading } from '../styles';
import Breadcrumbs from 'Global/components/Breadcrumbs';
import { useLastLocation } from 'react-router-last-location';
import { LessonPlan } from './LessonPlan';
import { SuperGroups } from './SuperGroups';
import { ClassRoster } from './ClassRoster';
import { Teachers } from './Teachers';
import { Information } from './Information';
import { CourseGroup } from 'types/education';
import { ChangeOriginalCourse } from 'Admin/Courses/CourseGroup/ChangeOC';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Title } = Typography;
const { TabPane } = Tabs;

interface GroupProps {
  history: History;
  location: Location;
  match: any;
}

/**
 * @description Страница просмотра group course
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const Group = (props: GroupProps) => {
  const [strings] = useLanguageContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [group, setGroup] = useState<CourseGroup>(null);
  const [originalCourses, setOriginalCourses] = useState([]);
  const [groupOriginalCourse, setGroupOriginalCourse] = useState<number>(null);
  const coursesRepository = useCoursesRepository();

  const {
    history: { goBack },
    location,
    match: {
      params: { id }
    }
  } = props;

  const activeTab = queryString.parse(location?.search).tab;
  const lastLocation = useLastLocation();
  const handleBack = (): void => goBack();

  const fetchGroupById = async (groupId: number): Promise<any> => {
    try {
      setLoading(true);
      const { data: group } = await coursesRepository.getCourseGroupById(groupId);
      if (group?.originalCourse) {
        setOriginalCourses([group?.originalCourse]);
        setGroupOriginalCourse(group?.originalCourse.id);
      }
      setGroup(group);
      setLoading(false);
    } catch {
      setGroup(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGroupById(id);
  }, [id]);

  return (
    <Spin spinning={loading} tip={strings.LOADING}>
      <StGroup>
        <Breadcrumbs location={location} />
        <Row gutter={[6, 8]}>
          <Col lg={12}>
            <GroupHeading>
              <Title level={3}>
                {lastLocation && <ArrowLeftOutlined style={{ marginInlineEnd: '10px' }} onClick={handleBack} />}
                {group?.groupName}
              </Title>
            </GroupHeading>
          </Col>
          <ChangeOriginalCourse
            groupId={group?.id}
            originalCourses={originalCourses}
            groupOriginalCourse={groupOriginalCourse}
            setGroupOriginalCourse={setGroupOriginalCourse}
            setOriginalCourses={setOriginalCourses}
          />
        </Row>
        <Row>
          <Tabs defaultActiveKey={activeTab ? String(activeTab) : 'plan'}>
            <TabPane tab={strings.OBJECTIVES} key="objectives">
              <Title level={3}>{strings.OBJECTIVES}</Title>
              <div dangerouslySetInnerHTML={{ __html: group?.originalCourse?.objectives }} />
            </TabPane>
            <TabPane tab={strings.METHODOLOGY} key="methodology">
              <Title level={3}>{strings.METHODOLOGY}</Title>
              <div dangerouslySetInnerHTML={{ __html: group?.originalCourse?.methodology }} />
            </TabPane>
            <TabPane tab={strings.LESSON_PLAN} key="plan">
              <LessonPlan groupId={id} />
            </TabPane>
            <TabPane tab={strings.INFORMATION} key="info">
              <Information group={group} />
            </TabPane>
            <TabPane tab={strings.CLASS_ROSTER} key="roster">
              <ClassRoster groupId={id} />
            </TabPane>
            <TabPane tab={strings.TEACHERS} key="teachers">
              <Teachers group={group} groupId={id} />
            </TabPane>
            <TabPane tab={strings.SUPERGROUPS} key="supergroups">
              <SuperGroups groupId={id} />
            </TabPane>
          </Tabs>
        </Row>
      </StGroup>
    </Spin>
  );
};

export default { Group };
