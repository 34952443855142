import React, { useState, useEffect } from 'react';
import { head, identity, pickBy } from 'lodash';
import { Form } from '@ant-design/compatible';
import { Button, Select, Row, Col, message, Tooltip, Input } from 'antd';
import { useUserContext } from 'contexts/UserContext';
import { useInvoicesRepository } from 'repos/InvoicesRepository';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import { initialRate, pricesNames, priceTypesById } from 'Admin/People/Families/Family/Invoices/Create/constants';
import { setPayer } from 'api/Families/invoices';
import { ModalSetPayerConfirm } from 'Admin/People/Families/Family/Invoices/Create/ModalSetPayerConfirm';
import { Rates } from 'Admin/People/Families/Family/Invoices/Create/Rates';
import { RateItemType } from 'Admin/People/Families/Family/Invoices/types';
import { InvoiceCreateParams, InvoiceFormProps, PriceType } from 'Admin/People/Families/Family/Invoices/Create/types';
import { IParent, IStudent, Performer } from 'types';
import { useLanguageContext } from 'contexts/LanguageContext';
import { momentFormatValues } from 'helpers/momentFormatValues';
import { discountReasonListForComment } from 'Global/constants';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { IPerformerParams } from 'types/finance';
import { usePerformersRepository } from 'repos/PerformersRepository';
import { StageSelect } from 'Global/components/FormComponents';

const { Option } = Select;

const InvoiceFormTemplate = (props: InvoiceFormProps) => {
  const {
    form,
    form: { getFieldDecorator, validateFields, setFieldsValue, getFieldValue },
    parents,
    payer,
    students,
    branch,
    prices,
    setLoading,
    createInvoice,
    fetchPrices
  } = props;
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const invoicesRepository = useInvoicesRepository();
  const globalRequestsRepository = useGlobalRequestsRepository();
  const performersRepository = usePerformersRepository();
  const hasTopManager = user?.hasRoles?.topManager;
  const [performers, setPerformers] = useState<Performer[]>([]);
  const [ratesList, setRatesList] = useState<RateItemType[]>([]);
  const [priceTypes, setPriceTypes] = useState<PriceType[]>([]);
  const [isConfirmModalVisible, setConfirmModalVisible] = useState<boolean>(false);
  const [isPayerSetLoading, setPayerSetLoading] = useState<boolean>(false);
  const [selectedPayer, setSelectedPayer] = useState<IParent | undefined>();
  const [selectedStudentId, setSelectedStudentId] = useState<number>();

  const onPayerChange = (id: number): void => {
    setSelectedPayer(parents.find((parent: IParent) => parent.id === id));
    setConfirmModalVisible(true);
  };

  const onPayerChangeCancel = (): void => {
    setSelectedPayer(undefined);
    setConfirmModalVisible(false);
  };

  const handlePayerChange = async (): Promise<void> => {
    setPayerSetLoading(true);
    try {
      await setPayer(selectedPayer.familyId, selectedPayer.id);
    } catch (e) {
      message.error(strings.UNEXPECTED_ERROR_WHEN_TRYING_TO_SET_PAYER_PLEASE_TRY_AGAIN);
    } finally {
      setPayerSetLoading(false);
      setConfirmModalVisible(false);
    }
  };

  const handleSubmit = async (): Promise<void> => {
    validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        const { rates } = values;
        if (!rates) {
          message.error(strings.SELECT_RATES, 2);
          setLoading(false);
          return false;
        }
        const ratesParams = rates.map(item => momentFormatValues(item));
        const params: InvoiceCreateParams = { ...values, rates: ratesParams };
        if (payer) {
          await setPayer(payer.familyId, payer.id);
        }

        await createInvoice(params);
      }
    });
  };

  const handleRateDelete = id => {
    const newRatesList = ratesList
      .filter((rate: RateItemType) => rate.id !== id)
      .map((rateItem: RateItemType, index: number) => ({ ...rateItem, id: index }));
    setFieldsValue({ rates: newRatesList });
    setRatesList(newRatesList);
  };

  const onPriceTypeClick = (typeId: number): void => {
    const priceType = priceTypes.find((priceType: PriceType) => priceType.id === typeId);
    const ratesValues = getFieldValue('rates') || [];
    const newRatesList: RateItemType[] = Object.values(pickBy(ratesValues, identity));
    const newRate = { ...initialRate, rateType: priceType.id, id: newRatesList.length };
    newRatesList.push(newRate);
    setRatesList(newRatesList);
    setFieldsValue({ rates: newRatesList });
  };

  const fetchPerformers = async (): Promise<any> => {
    try {
      const params: IPerformerParams = { branch: branch.id, isActive: 1 };
      const { data } = await globalRequestsRepository.getPerformers(params);
      setPerformers(data);
      return data;
    } catch {}
  };

  const fetchPriceTypes = async () => {
    const { data } = await invoicesRepository.getRatesTypes();
    setPriceTypes(data);
  };

  const getLastPerformerForStudent = async (student: number): Promise<any> => {
    try {
      const { data } = await performersRepository.getLastPerformersByStudentId(student);
      setFieldsValue({ performer: data.id });
    } catch {}
  };

  const handleChangeStudent = (value: number) => {
    getLastPerformerForStudent(value);
    const stageId = students.find(child => child.id === value)?.studyProgram?.stage?.id;
    setFieldsValue({ stage: stageId });
    fetchPrices(value, stageId && { stage: Number(stageId) });
    setRatesList([]);
  };

  const onChangeStage = (val: unknown) => {
    const stageId = Number(val);
    fetchPrices(selectedStudentId, stageId && { stage: Number(stageId) });
    setRatesList([]);
  };

  const hasDisabled = (id: number): boolean => {
    const selectedRateTypes = ratesList.map(item => item.rateType);
    switch (id) {
      case priceTypesById.trialType:
        return (
          selectedRateTypes.includes(priceTypesById.flexibleType) ||
          selectedRateTypes.includes(priceTypesById.periodType)
        );
      case priceTypesById.depositType:
        return selectedRateTypes.includes(priceTypesById.depositType);
      case priceTypesById.periodType:
        return (
          selectedRateTypes.includes(priceTypesById.flexibleType) ||
          selectedRateTypes.includes(priceTypesById.trialType)
        );
      case priceTypesById.flexibleType:
        return (
          selectedRateTypes.includes(priceTypesById.periodType) || selectedRateTypes.includes(priceTypesById.trialType)
        );
      default:
        return false;
    }
  };

  useEffect(() => {
    const childrenId = head(students).id;
    setSelectedStudentId(childrenId);
    fetchPerformers();
    fetchPriceTypes();
  }, []);

  useEffect(() => {
    if (selectedStudentId) {
      handleChangeStudent(selectedStudentId);
    }
  }, [selectedStudentId]);

  const validateComment = () => {
    const discountReasonArr =
      getFieldValue('rates')
        ?.filter(item => item?.discountReason)
        .map(item => item.discountReason) || [];

    return discountReasonListForComment.some(elem => discountReasonArr.includes(elem));
  };

  return (
    <Form layout="vertical">
      <ModalSetPayerConfirm
        isVisible={isConfirmModalVisible}
        loading={isPayerSetLoading}
        onConfirm={handlePayerChange}
        onHide={onPayerChangeCancel}
        selectedPayer={selectedPayer}
      />

      <Row gutter={[10, 10]}>
        <Col lg={4}>
          {payer ? (
            <MyFormItem label={strings.PAYER}>{`${payer.user.name} ${payer.user.surname}`}</MyFormItem>
          ) : (
            <MyFormItem label={strings.PAYER}>
              {getFieldDecorator('payer', {
                rules: [{ required: true, message: strings.PAYER_IS_REQUIRED }],
                initialValue: selectedPayer?.id
              })(
                <Select placeholder={strings.PAYER} onChange={onPayerChange}>
                  {parents.map((parent: IParent) => (
                    <Option value={parent.id} key={parent.id}>
                      {parent.user.name} {parent.user.surname}
                    </Option>
                  ))}
                </Select>
              )}
            </MyFormItem>
          )}
        </Col>
        <Col lg={6}>
          <MyFormItem label={strings.STUDENT}>
            {getFieldDecorator('child', {
              initialValue: selectedStudentId,
              rules: [{ required: true, message: strings.STUDENT_IS_REQUIRED }]
            })(
              <Select placeholder={strings.STUDENT} onChange={(value: number) => setSelectedStudentId(value)}>
                {students.map((child: IStudent) => {
                  return (
                    <Option value={child.id} key={child.id}>
                      {child.user.name} {child.user.surname}
                    </Option>
                  );
                })}
              </Select>
            )}
          </MyFormItem>
        </Col>
        <Col lg={6}>
          <MyFormItem label={strings.STAGE}>
            {getFieldDecorator('stage')(<StageSelect branch={branch?.id} onChange={onChangeStage} />)}
          </MyFormItem>
        </Col>
        <Col lg={8}>
          <MyFormItem label={strings.PERFORMER}>
            {getFieldDecorator('performer', {
              rules: [{ required: true }]
            })(
              <Select placeholder={strings.PERFORMER}>
                {performers.map((performer: Performer) => (
                  <Option key={performer.id} value={performer.id}>
                    <Tooltip title={performer.name} placement="top" overlayStyle={{ zIndex: 9999 }}>
                      {performer.name}
                    </Tooltip>
                  </Option>
                ))}
              </Select>
            )}
          </MyFormItem>
        </Col>
      </Row>
      <div style={{ display: 'flex', alignItems: 'center', gap: 10, marginBottom: 20 }}>
        {priceTypes.map((priceType: PriceType) =>
          priceType.id === priceTypesById.flexibleType && !hasTopManager ? null : (
            <Button
              onClick={() => onPriceTypeClick(priceType.id)}
              disabled={hasDisabled(priceType.id)}
              key={priceType.id}
            >
              + {priceType.name === pricesNames.DEPOSIT_TYPE ? strings.ENTRANCE_FEE : priceType.name}
            </Button>
          )
        )}
      </div>
      <Rates
        prices={prices}
        priceTypes={priceTypes}
        ratesList={ratesList}
        setRatesList={setRatesList}
        handleRateDelete={handleRateDelete}
        form={form}
        familyBranch={branch}
      />
      <Row
        gutter={[10, 10]}
        align="middle"
        style={{ display: 'flex', alignItems: 'center', marginBottom: 20, marginTop: 20 }}
      >
        <Col lg={18}>
          <MyFormItem label={strings.COMMENT}>
            {getFieldDecorator('comment', {
              rules: [
                {
                  required: validateComment(),
                  message: strings.DISCOUNT_REASON_NEEDS_TO_BE_SPECIFIED_PLEASE_FILL_IN_AN_INVOICE_COMMENT
                }
              ]
            })(<Input.TextArea style={{ margin: 0 }} />)}
          </MyFormItem>
        </Col>
      </Row>
      <Button type="primary" onClick={handleSubmit}>
        {strings.CREATE_INVOICE}
      </Button>
    </Form>
  );
};

export const InvoiceForm = Form.create<InvoiceFormProps>({})(InvoiceFormTemplate);
