import React, { useEffect, useMemo, useState } from 'react';
import { Tabs } from 'antd';
import { SearchSlotsParams } from 'types/Schedule';
import { IFamily, IStudent } from 'types';
import { useFamilyContext } from 'contexts/FamilyContext';
import { first } from 'lodash';
import { useConstantsContext } from 'contexts/ConstantsContext';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import ScheduleService from 'helpers/services/ScheduleService';

const { TabPane } = Tabs;

interface StudentFilterProps {
  getEvents: (params: Partial<SearchSlotsParams>) => Promise<any>;
}

const service = new ScheduleService();

/**
 * @description Компонент фильтра по детям, доступен только родителю
 * @param {object} props
 * @return {React.ReactNode}
 */
export const StudentFilter = (props: StudentFilterProps) => {
  const { getEvents } = props;
  const [family]: [IFamily] = useFamilyContext();
  const { ACTIVE_STUDENT_STATUS_ID } = useConstantsContext();
  const { locationSearchParams } = useLocationSearchParams();

  const myChildrenList: IStudent[] = useMemo(
    () => family?.children?.filter((child: IStudent) => child?.status?.id === ACTIVE_STUDENT_STATUS_ID) || [],
    [family?.children]
  );
  const [activeTab, setActiveTab] = useState<string>();
  const getInitDates = (): any => service.getWeekDates();
  const defFilterValues = {
    view: 'week',
    ...getInitDates(),
    students: [first(myChildrenList)?.id]
  };

  /**
   * @description Функция выбора студента
   * @return {Promise<any>}
   */
  const handleTabChange = async (studentId: string | number): Promise<any> => {
    setActiveTab(String(studentId));
    await getEvents({ ...locationSearchParams, students: [Number(studentId)] });
  };

  useEffect(() => {
    const { dateStart } = locationSearchParams;
    if (myChildrenList?.isNotEmpty()) {
      if (!dateStart) {
        getEvents(defFilterValues);
      } else {
        const { students } = locationSearchParams;
        setActiveTab(first(students).toString());
        getEvents(locationSearchParams);
      }
    }
  }, [myChildrenList]);

  return (
    <>
      {myChildrenList.length > 1 && (
        <Tabs activeKey={activeTab || String(first(myChildrenList)?.id)} onChange={handleTabChange}>
          {myChildrenList?.map(student => (
            <TabPane tab={student?.user?.name} key={String(student.id)} />
          ))}
        </Tabs>
      )}
    </>
  );
};

export default { StudentFilter };
