import { LessonsFilterParams, CourseGroupParams } from 'types/education';
import { OriginalCourseAccessParams, OriginalLessonTopicsParams, DisenrollStudentFromGroupParams } from 'types/courses';
import * as unit from './unit';
import { httpService } from 'api/core';
import { ILessonTask } from 'types/lesson';

/**
 * @description Получить список активных original courses
 * @param {object} params
 * @return {Promise<any>}
 */
const getActiveOriginalCourses = (params: any = {}): Promise<any> =>
  httpService.get(`/api/originalcourses?isActive=1&limit=1000`, params); // TODO: объектом передать параметры

/**
 * @description ...
 * @param {number} id
 * @return {Promise<any>}
 */
const getCourseGroupInitById = (id: unknown): Promise<any> =>
  httpService.get(`/api/originalcourse/${id}/coursegroup/init`);

/**
 * @description
 * @param {number} id
 * @param {object} params
 * @return {Promise<any>}
 */
const createCourseGroup = (id: number | string, params: CourseGroupParams): Promise<any> =>
  httpService.post(`/api/originalcourse/${id}/coursegroup`, params);

/**
 * @description ...
 * @param {number} id
 * @param {object} params
 * @return {Promise<any>}
 */
const editCourseGroup = (id: number | string, params: CourseGroupParams): Promise<any> =>
  httpService.put(`/api/coursegroup/${id}`, params);

/**
 * @description Редактирование группы студента
 * @param {number} group
 * @param {number} student
 * @param {object} params
 * @return {Promise<any>}
 */

const editGroupStudent = (group: number, student: number, params: any): Promise<any> =>
  httpService.put(`/api/coursegroup/${group}/student/${student}`, params);

/**
 * @description Удаление группы студента
 * @param {number} group
 * @param {number} student
 * @return {Promise<any>}
 */

const deleteGroupStudent = (group: number, student: number): Promise<any> =>
  httpService.delete(`/api/coursegroup/${group}/student/${student}`);

/**
 * @description Отчисление студента из группы
 * @param {number} groupId
 * @param {number} studentId
 * @param {object} params
 * @return {Promise<any>}
 */
const disenrollStudentFromGroup = (
  groupId: number,
  studentId: number,
  params: DisenrollStudentFromGroupParams
): Promise<any> => httpService.put(`/api/coursegroup/${groupId}/student/${studentId}`, params);

/**
 * @description Список студентов по группе
 * @param {number} groupId
 * @param {boolean} active
 * @return {Promise<any>}
 */
const getStudentsByGroup = (groupId: number | string, active?: boolean): Promise<any> =>
  httpService.get(`/api/coursegroup/${groupId}/students`, { active: active ? 1 : undefined });

/**
 * @description Удаление супергруппы из группы
 * @param {number} groupId
 * @param {number} supergroupId
 * @return {Promise<any>}
 */
const removeSupergroupFromGroup = (groupId: number, supergroupId: number): Promise<any> =>
  httpService.delete(`/api/coursegroup/${groupId}/supergroups/${supergroupId}`);

/**
 * @description Добавление супергруппы в группу
 * @param {number} groupId
 * @param {any} params
 * @return {Promise<any>}
 */
const addSupergroupToGroup = (groupId: number, params: any): Promise<any> =>
  httpService.post(`/api/coursegroup/${groupId}/supergroups`, params);

/**
 * @description Добавление студента в супергруппу
 * @param {number} studentId
 * @param {number} superGroupId
 * @param {object} params
 * @return {Promise<any>}
 */
const addStudentToSuperGroup = (studentId: number, superGroupId: number, params: any): Promise<any> =>
  httpService.post(`/api/supergroups/${superGroupId}/student/${studentId}`, params);

/**
 * @description Удаление студента из супергруппы
 * @param {number} studentId
 * @param {number} superGroupId
 * @param {string} date (2019-09-26)
 * @return {Promise<any>}
 */
const removeStudentFromSuperGroup = (studentId: number, superGroupId: number, date: string): Promise<any> =>
  httpService.delete(`/api/supergroups/${superGroupId}/student/${studentId}?date=${date}`);

/**
 * @description Получение списка уроков
 * @param {object} params
 * @return {Promise<any>}
 */
const getLessonsList = (params: LessonsFilterParams): Promise<any> => httpService.get('/api/lessons', params);

/**
 * @description Get original lesson topic list
 * @param {OriginalLessonTopicsParams} params
 * @return {Promise<any>}
 */
const getOriginalLessonTopics = (params: OriginalLessonTopicsParams): Promise<any> =>
  httpService.get(`/api/originallessons/topics`, params);

/**
 * @description Список материалов по id
 * @param {number} lessonId
 * @return {Promise<any>}
 */
const getOriginalLessonMaterials = (lessonId: number | string): Promise<any> =>
  httpService.get(`/api/originallesson/${lessonId}/materials`);

/**
 * @description Удаление задачи урока
 * @param {number} taskId
 * @return {Promise<any>}
 */
const deleteLessonTask = (taskId: number): Promise<any> => httpService.delete(`/api/lessons/task/${taskId}`);

/**
 * @description Обновление задачи урока
 * @param {number} taskId
 * @param {any} data
 * @return {Promise<any>}
 */
const updateLessonTask = (taskId: number, data: ILessonTask): Promise<any> =>
  httpService.put(`/api/lessons/task/${taskId}`, data);

/**
 * @description Добавление задачи урока
 * @param {number} lessonId
 * @param {any} data
 * @return {Promise<any>}
 */
const addLessonTask = (lessonId: number | string, data: any): Promise<any> =>
  httpService.post(`/api/lessons/${lessonId}/task`, data);

/**
 * @description Обновление урока у курса
 * @param {number} originalCourseId
 * @param {number} originalLessonId
 * @param {any} params
 * @return {Promise<any>}
 */
const updateOriginalLesson = (
  originalCourseId: number | string,
  originalLessonId: number | string,
  params: any //TODO описать params
): Promise<any> =>
  httpService.put(`/api/originalcourses/${originalCourseId}/originallesson/${originalLessonId}`, params);

/**
 * @description Созданиие нового урока у курса
 * @param {number} originalCourseId
 * @param {any} params
 * @return {Promise<any>}
 */
const createOriginalLesson = (originalCourseId: number | string, params: any): Promise<any> =>
  httpService.post(`/api/originalcourses/${originalCourseId}/originallesson/`, params);

/**
 * @description Удаление оригинального урока
 * @param {number} originalCourseId
 * @param {number} originalLessonId
 * @return {Promise<any>}
 */
const deleteOriginalLesson = (originalCourseId: number, originalLessonId: number): Promise<any> =>
  httpService.delete(`/api/originalcourses/${originalCourseId}/originallesson/${originalLessonId}`);

/**
 * @description Добавление юзера в access список
 * @param {number} originalCourseId
 * @param {OriginalCourseAccessParams} params
 * @return {Promise<any>}
 */
const addOriginalCoursesAccess = (
  originalCourseId: number | string,
  params: OriginalCourseAccessParams
): Promise<any> => httpService.post(`/api/originalcourses/${originalCourseId}/access`, params);

/**
 * @description Удаление юзера из access списка
 * @param {number} originalCourseId
 * @param {number} userId
 * @return {Promise<any>}
 */
const deleteOriginalCoursesAccess = (originalCourseId: number | string, userId: number): Promise<any> =>
  httpService.delete(`/api/originalcourses/${originalCourseId}/access/${userId}`);

/**
 * @description Изменение доступа для юзера из access списка
 * @param {number} userId
 * @param {object} params
 * @return {Promise<any>}
 */
const changeOriginalCoursesAccessLevel = (userId: number, params: { level: string }): Promise<any> =>
  httpService.put(`/api/originalcourses/access/${userId}`, params);

/**
 * @description Список инвентаря
 * @param {object} params
 * @return {Promise<any>}
 */
const getListInventories = (params: object = {}): Promise<any> => httpService.get('/api/inventories', params);

/**
 * @description Сортирует уроки
 * @param {number} originalCourseId
 * @param {string} lessons
 * @return {Promise<any>}
 */
const originalLessonSort = (originalCourseId: number | string, lessons: string): Promise<any> =>
  httpService.post(`/api/originalcourses/${originalCourseId}/originallesson/sort?${lessons}`);

/**
 * @description Сортировка юнитов в оригинальных курсах
 * @param {number} originalCourseId
 * @param {string} units
 * @return {Promise<any>}
 */
const originalCourseSortUnit = (originalCourseId: number | string, units: string): Promise<any> =>
  httpService.post(`/api/originalcourses/${originalCourseId}/units/sort?${units}`);

/**
 * @description Получение списка комментариев к материалам классных работ
 * @param {number} lessonId
 * @return {Promise<any>}
 */
const getClassWorkComments = (lessonId): Promise<any> =>
  httpService.get(`/api/originallesson/${lessonId}/comments/material`);

/**
 * @description Получение списка комментариев к материалам домашек
 * @param {number} lessonId
 * @return {Promise<any>}
 */
const getHWComments = (lessonId): Promise<any> => httpService.get(`/api/originallesson/${lessonId}/comments/homework`);

export const Courses = {
  ...unit.default,
  getActiveOriginalCourses,
  getCourseGroupInitById,
  createCourseGroup,
  editCourseGroup,
  editGroupStudent,
  addStudentToSuperGroup,
  removeStudentFromSuperGroup,
  getStudentsByGroup,
  removeSupergroupFromGroup,
  addSupergroupToGroup,
  getLessonsList,
  getOriginalLessonTopics,
  deleteLessonTask,
  addLessonTask,
  updateLessonTask,
  getOriginalLessonMaterials,
  updateOriginalLesson,
  createOriginalLesson,
  deleteOriginalLesson,
  getListInventories,
  addOriginalCoursesAccess,
  deleteOriginalCoursesAccess,
  changeOriginalCoursesAccessLevel,
  originalLessonSort,
  originalCourseSortUnit,
  disenrollStudentFromGroup, // Отчисление студента из группы
  deleteGroupStudent, // Удаление студента из группы
  getClassWorkComments,
  getHWComments
};

export default { Courses };
