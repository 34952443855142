import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin, Table as AntTable, Table } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { LessonRegister } from 'types';
import { useParentJournalRepository } from 'repos/ParentJournalRepository';
import { Task as ITask } from 'types/Family/collaborationHistory';
import { getRegisterColumnsByParent, getTestsColumns } from './columns';
import ModalCreateThread from 'Global/components/Chat/ModalCreateThread';
import { useUserContext } from 'contexts/UserContext';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';
import { ICreateThread } from 'types/ParentJournal';
import { ReportingType } from 'types/global';

const { Column } = Table;

export const SubjectTabsContent = ({
  studentId,
  subjectId,
  reportType
}: {
  studentId: number;
  subjectId: number | string;
  reportType?: ReportingType;
}) => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const parentJournalRepository = useParentJournalRepository();
  const reportsRepository = useProgressReportsRepository();
  const hasStudent = user?.hasRoles?.student;
  const hasParent = user?.hasRoles?.parent;
  const hasPrimary = reportType && reportType === ReportingType.primary;

  const [needFeedbackNum, setNeedFeedbackNum] = useState<number>(0);
  const [lessonsRegister, setLessonsRegisters] = useState<LessonRegister[]>([]);
  const [tests, setTests] = useState<ITask[]>([]);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [hasTaskLoading, setHasTaskLoading] = useState<boolean>(false);
  const [threadInfo, setThreadInfo] = useState<ICreateThread>(null);

  const [hasShowThreadModal, setHasShowThreadModal] = useState<boolean>(false);
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);

  /**
   * @description Функция открытия модалку учителя и создания нового треда
   * @param {number} lessonRegister объект register
   * @return {Promise<any>}
   */
  const openModalCreateThread = (lessonRegister: LessonRegister): void => {
    const {
      lessonId,
      teacher: { id: teacherId }
    } = lessonRegister;

    setThreadInfo({
      teacher: teacherId,
      child: studentId,
      lesson: lessonId
    });
    setHasShowThreadModal(true);
  };

  /**
   * @description Родитель прочитал комментарий
   * @param {number} lessonRegister id
   * @return {Promise<any>}
   */
  const handleReadParentComment = (lessonRegisterId: number): void => {
    parentJournalRepository.setReadParentComment(lessonRegisterId);
  };

  /**
   * @description Запрос на тесты
   * @param {number} id
   * @param {number} subjectId
   * @return {Promise<any>}
   */
  const getTasksRegister = async (): Promise<any> => {
    setHasTaskLoading(true);
    try {
      const { data } = await parentJournalRepository.getTasksRegistersByChild(studentId, subjectId);
      setTests(data);
    } catch {
    } finally {
      setHasTaskLoading(false);
    }
  };

  /**
   * @description Get Lessons Register
   * @return {React.ReactNode}
   */
  const getLessonsRegister = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const {
        data: { registers, buttonNeedFeedbackForParents }
      } = await parentJournalRepository.getLessonRegister(studentId, subjectId);
      setLessonsRegisters(registers);
      setNeedFeedbackNum(buttonNeedFeedbackForParents);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  const getNeedFeedbackNum = async (): Promise<any> => {
    try {
      const {
        data: { buttonNeedFeedbackForParents }
      } = await parentJournalRepository.getLessonRegister(studentId, subjectId);
      setNeedFeedbackNum(buttonNeedFeedbackForParents);
    } catch {}
  };
  /**
   * @description Запрос фидбэка по клику
   * @return {Promise<any>}
   */
  const handleAskForFeedback = async (): Promise<any> => {
    try {
      const { status } = await reportsRepository.askFeedbackForParents(studentId, subjectId);
      if (status === 200) {
        setHasShowModal(true);
        getNeedFeedbackNum();
      }
    } catch {}
  };

  const getFeedbackButton = () => {
    if (needFeedbackNum === 0) {
      return null;
    } else {
      return (
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          {needFeedbackNum === 1 ? (
            <Button disabled>{strings.WAITING_FEEDBACK}</Button>
          ) : (
            <Button onClick={handleAskForFeedback} type="primary">
              {strings.NEEDS_FEEDBACK}
            </Button>
          )}
        </div>
      );
    }
  };

  useEffect(() => {
    if (studentId) {
      getLessonsRegister();
      getTasksRegister();
    }
  }, [studentId]);

  return (
    <>
      {getFeedbackButton()}
      {hasPrimary && tests.isNotEmpty() && (
        <AntTable
          dataSource={tests}
          columns={getTestsColumns(strings)}
          loading={hasTaskLoading}
          pagination={false}
          locale={{ emptyText: strings.NO_DATA }}
          style={{ marginBottom: 20 }}
        />
      )}
      <Spin spinning={hasLoading}>
        <Table dataSource={lessonsRegister} locale={{ emptyText: strings.NO_DATA }}>
          {getRegisterColumnsByParent({
            strings,
            subjectId,
            studentId,
            openModalCreateThread,
            hasStudent,
            hasParent,
            handleReadParentComment
          })
            .filter(col => col)
            .map(col => (
              <Column key={col.key} {...col} />
            ))}
        </Table>
      </Spin>
      <ModalCreateThread
        visible={hasShowThreadModal}
        thread={threadInfo}
        hideModal={() => setHasShowThreadModal(false)}
      />
      <Modal
        footer={
          <Button type="primary" onClick={() => setHasShowModal(false)}>
            {strings.OK}
          </Button>
        }
        open={hasShowModal}
        onCancel={() => setHasShowModal(false)}
      >
        <p style={{ paddingInlineEnd: 20, margin: 0 }}>{strings.FEEDBACK_DATE}</p>
      </Modal>
    </>
  );
};
