import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, Select, Button, message } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { TrainingsFilterParams, TrainingsItem } from 'types/education';
import { useTrainingsRepository } from 'repos/TrainingsRepository';
import { includes } from 'lodash';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Option } = Select;

interface AddTrainingModalProps extends FormComponentProps {
  visible: boolean;
  teacherId: number | string;
  addedTrainingList: number[];
  hideModal: () => void;
  fetchTeacherTraining: () => Promise<any>;
}

/**
 * @description Компонент модальног окна для добавления материалов
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const AddTrainingModalTemplate = (props: AddTrainingModalProps) => {
  const {
    form: { getFieldDecorator, validateFields, resetFields },
    visible,
    hideModal,
    teacherId,
    fetchTeacherTraining,
    addedTrainingList
  } = props;

  const [trainings, setTrainings] = useState<TrainingsItem[]>([]);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const trainingsRepository = useTrainingsRepository();
  const [strings] = useLanguageContext();

  const getFooter = () => {
    const buttons = [
      <Button key="back" onClick={hideModal}>
        {strings.CANCEL}
      </Button>,
      <Button type="primary" htmlType="submit" onClick={handleSubmit}>
        {strings.ADD_TRAINING}
      </Button>
    ];

    return buttons;
  };

  /**
   * @description Добавить тренинги учителю
   * @param {React.FormEvent} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();

    validateFields(async (err, values) => {
      if (!err) {
        try {
          await trainingsRepository.addTrainingToTeacher(teacherId, values);
          message.success(strings.TRAINING_ADDED_SUCCESSFULLY);
          resetFields();
          hideModal();
          fetchTeacherTraining();
        } catch {}
      }
    });
  };

  /**
   * @description Получение списка тренингов
   * @return {Promise<any>}
   */
  const fetchTrainings = async (params?: TrainingsFilterParams): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await trainingsRepository.getTrainings(params);
      setTrainings(data);
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    fetchTrainings();
  }, []);

  //Уберем из списка уже добавленные учителю тренинги
  const filteredTrainingList = trainings.filter(item => {
    return !includes(addedTrainingList, item.id);
  });

  return (
    (<Modal title={strings.ADD_TRAINING} open={visible} onCancel={hideModal} footer={getFooter()}>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <MyFormItem label={strings.TRAINING}>
          {getFieldDecorator('trainings', {
            rules: [{ required: true, message: strings.PLEASE_SELECT_TRAINING }]
          })(
            <Select mode="multiple" placeholder={strings.PLEASE_SELECT_TRAINING} loading={hasLoading}>
              {filteredTrainingList.map((training: TrainingsItem) => (
                <Option value={training.id} key={training.id}>
                  {training.name}
                </Option>
              ))}
            </Select>
          )}
        </MyFormItem>
      </Form>
    </Modal>)
  );
};

export const AddTrainingModal = Form.create<AddTrainingModalProps>({})(AddTrainingModalTemplate);

export default { AddTrainingModal };
