import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Spin, Typography, Empty } from 'antd';
import { DraggbleCard } from './DraggbleCard';

const { Title } = Typography;

interface DroppableListProps {
  id: string | number;
  index: number;
  title?: string;
  cards: Array<any>;
  CardComponent: typeof React.Component;
  isLoading?: boolean;
  stylesForColumns?: object;
  stylesForColumn?: object;
}

/**
 * @description список карточек(колонка)
 * @param id
 * @param index
 * @param cards список данных
 * @param CardComponent компонент карточки
 * @constructor
 */
export const DroppableList = ({
  id,
  index,
  title,
  cards,
  CardComponent,
  isLoading,
  stylesForColumns,
  stylesForColumn
}: DroppableListProps) => {
  const styleColumn = stylesForColumn ? { ...stylesForColumn } : {};

  return (
    <div style={stylesForColumns}>
      <Title level={4}>{title}</Title>
      <Droppable type="CARDS" droppableId={String(id || cards[index].id)} index={index}>
        {(provider, snapshot) => (
          <div
            style={{
              minHeight: 100,
              height: 'calc(100% - 30px)',
              backgroundColor: snapshot.isDraggingOver ? '#EAEEF6' : 'white',
              padding: 5,
              ...styleColumn
            }}
            ref={provider.innerRef}
            {...provider.droppableProps}
          >
            <Spin key={id} spinning={isLoading} tip={'Loading...'}>
              {!!cards.length ? (
                cards.map((card, i) => (
                  <DraggbleCard
                    key={card?.id || i}
                    id={card?.id || i}
                    index={i}
                    data={card}
                    CardComponent={CardComponent}
                  />
                ))
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </Spin>
            {provider.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default DroppableList;
