import styled from 'styled-components';

export const TableContainer = styled.div<{ opacity?: boolean }>`
  margin-bottom: 30px;
  transition: opacioty 0.3s ease-in-out;
  ${props => (props.opacity ? `opacity: 0.65` : null)};

  &:hover {
    ${props => (props.opacity ? `opacity: 0.85` : null)};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const TableHeading = styled.div<{ color?: string }>`
  margin-bottom: 20px;
  font-size: 24px;
  border-bottom: 4px solid ${props => props.color};
`;

export const TableWrapper = styled.div`
  margin-bottom: 50px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export default { TableContainer, TableHeading, TableWrapper };
