import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import { Table, Typography, Button } from 'antd';
import { Loader } from 'Global/components/Loader/Loader';
import { Families } from 'api/Families';
import { getColumns } from 'Admin/People/Families/Family/Finance/Discounts/columns';
import DiscountModal from 'Admin/People/Families/Family/Finance/Discounts/DiscountModal';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { ModalMode, IStudent } from 'types';
import { FinanceDiscount } from 'types/finance';
import { useUserContext } from 'contexts/UserContext';

const { Column } = Table;
const StDiscounts = styled.div`
  padding: 30px 0;
  border-bottom: 1px solid #888;
  margin-bottom: 30px;
`;
const { Title } = Typography;

interface DiscountProps {
  id: number;
  students: IStudent[];
}

/**
 * @description Family discount
 * @param {DiscountProps} props
 */
export const Discounts = (props: DiscountProps) => {
  const [discounts, setDiscounts] = useState<FinanceDiscount[]>([]);
  const [showDiscounts, setShowDiscounts] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Hidden);
  const [currentDiscountId, setCurrentDiscountId] = useState<number>(0);

  const [user] = useUserContext();
  const hasBranchOperationDepartament = user?.hasRoles?.branchOperationDepartament;

  const { id, students } = props;

  const getDiscountsById = async () => {
    const { data } = await Families.getFamilyDiscounts(id);
    setShowDiscounts(true);
    setDiscounts(data);
  };

  useEffect(() => {
    getDiscountsById();
  }, [id]); // eslint-disable-line

  /**
   * @description Показывает форму для редактирование скидки
   * @param {number} discountId
   */
  const showEditForm = (discountId: number) => {
    setCurrentDiscountId(discountId);
    setModalMode(ModalMode.Edit);
  };

  /**
   * @description Показывает форму для удаления скидки
   * @param {number} discountId
   */
  const showDeleteForm = (discountId: number) => {
    setCurrentDiscountId(discountId);
    setModalMode(ModalMode.Delete);
  };

  const values = discounts.find(d => d.id === currentDiscountId);

  return (
    <ErrorBoundary>
      <Loader spinning={!showDiscounts}>
        <StDiscounts>
          <Title level={4}>Discounts</Title>
          <Table dataSource={discounts} className="discounts-table" pagination={false} rowKey="id">
            {getColumns(showEditForm, showDeleteForm).map(col => (
              <Column key={col.key} {...col} />
            ))}
          </Table>
          {!hasBranchOperationDepartament && (
            <Button
              onClick={() => setModalMode(ModalMode.Add)}
              style={{ marginTop: '20px', marginInlineStart: '0', fontSize: '14px' }}
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              id="create-discount"
            >
              Create
            </Button>
          )}
          <DiscountModal
            id={id}
            mode={modalMode}
            hideModal={() => setModalMode(ModalMode.Hidden)}
            reloadDiscounts={getDiscountsById}
            students={students}
            currentDiscountId={currentDiscountId}
            values={values}
          />
        </StDiscounts>
      </Loader>
    </ErrorBoundary>
  );
};
export default { Discounts };
