import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { useTrainingsRepository } from 'repos/TrainingsRepository';
import { TrainingsItem } from 'types/education';

const { Option } = Select;

type AsyncSelectProps = {
  value?: number;
  placeholder?: string;
  onChange?: (value: number) => void;
  mode?: 'multiple' | 'tags';
};

export const TrainingSelect: React.FC<AsyncSelectProps> = ({ value, placeholder, onChange, mode }) => {
  const trainingsRepository = useTrainingsRepository();
  const [options, setOptions] = useState<TrainingsItem[]>([]);
  const [hasLoading, setHasLoading] = useState(false);

  const fetchTrainings = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await trainingsRepository.getTrainings();
      setOptions(data);
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    fetchTrainings();
  }, []);

  return (
    <Select
      showSearch
      value={value}
      onChange={val => onChange(val)}
      allowClear
      placeholder={placeholder}
      loading={hasLoading}
      mode={mode}
      maxTagCount="responsive"
      filterOption={false}
    >
      {options.map(option => (
        <Option key={option.id} value={option.id}>
          {option.name}
        </Option>
      ))}
    </Select>
  );
};

export default TrainingSelect;
