import React, { useState, useEffect } from 'react';
import { ChatContainer } from './styles';
import ChatContext from './chatContext';
import { Dialog } from './Dialog';
import { Thread } from 'types/ParentJournal';
import { useUserContext } from 'contexts/UserContext';
import { useThreadsContext } from 'contexts/ThreadsContext';
import { TransparentContainer } from 'Global/GlobalStyle';
import { ListUsers } from './ListUsers';
import { useThreadsRepository } from 'repos/ThreadsRepository';

const THREADS_LIMIT = 500;

export const Chat = () => {
  const [threads, setThreads] = useState<Thread[]>([]);
  const [user] = useUserContext();
  const [, setUnreadThreads] = useThreadsContext();
  const threadsRepository = useThreadsRepository();
  const [hasLoadThreads, setHasLoadThreads] = useState<boolean>(false);
  const [activeThreadId, setActiveThreadId] = useState<number>(null);
  const hasParent = user?.hasRoles?.parent;
  const hasStudent = user?.hasRoles?.student;
  const hasTeacher = user?.hasRoles?.teacher;

  const readThread = (threadId: number): void => {
    const updateThreads = threads.map((thread: Thread) => {
      if (thread.id === threadId) {
        return {
          ...thread,
          isRead: true
        };
      }

      return thread;
    });
    setThreads(updateThreads);
    setUnreadThreads(updateThreads.filter(t => !t.isRead).length || 0);
  };

  const getThreads = async (withLoading = true) => {
    try {
      if (withLoading) {
        setHasLoadThreads(true);
      }
      const {
        data: { items }
      } = await threadsRepository.getMyThreads({ limit: THREADS_LIMIT });
      setThreads(items);
    } catch {
    } finally {
      setHasLoadThreads(false);
    }
  };

  const chatState = {
    threads,
    hasLoadThreads,
    activeThreadId,
    setActiveThreadId,
    getThreads,
    hasParent,
    hasStudent,
    hasTeacher,
    readThread
  };

  useEffect(() => {
    getThreads();
  }, []);

  return (
    <ChatContext.Provider value={chatState}>
      <TransparentContainer>
        <ChatContainer threadActive={Boolean(activeThreadId)}>
          <ListUsers />
          <Dialog />
        </ChatContainer>
      </TransparentContainer>
    </ChatContext.Provider>
  );
};

export default { Chat };
