import { httpService } from 'api/core';

/**
 * @description Контракт
 * @param {number} contractId
 * @return {Promise<any>}
 */
export const getContractById = (contractId: number): Promise<any> =>
  httpService.get(`/api/families/contracts/${contractId}`);

/**
 * @description Редактирование контракта
 * @param {number} contractId
 * @param {any} params
 * @return {Promise<any>}
 */
export const editContractById = (contractId: number, params: any): Promise<any> =>
  httpService.post(`/api/families/contracts/${contractId}`, params);

/**
 * @description Удаление контракта
 * @param {number} contractId
 * @return {Promise<any>}
 */
export const deleteContractById = (contractId: number): Promise<any> =>
  httpService.delete(`/api/families/contracts/${contractId}`);

export default {
  getContractById,
  editContractById,
  deleteContractById
};
