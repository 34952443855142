import React, { useState, useEffect } from 'react';
import { match } from 'react-router';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Row, Col, Typography, Input, Select, Checkbox, Button, Spin, message } from 'antd';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { EditTeachingMaterials as StEditTeachingMaterials } from '../styles';
import { History } from 'history';
import { useLastLocation } from 'react-router-last-location';
import { Branch } from 'types';
import { EditInventory } from 'types/CRM/materials';
import { CRM } from 'api/CRM';
import { editInventoryMock } from 'Global/mocks';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Title } = Typography;
const { Option } = Select;

interface editTeachingMaterialsProps {
  history: History;
  match: match<{ id: string }>;
  form: WrappedFormUtils;
}

export const EditTeachingMaterials = Form.create({})((props: editTeachingMaterialsProps) => {
  const {
    form: { getFieldDecorator, validateFields },
    history: { goBack, push },
    match: {
      params: { id }
    }
  } = props;

  const handleBack = () => goBack();
  const lastLocation = useLastLocation();
  const [strings] = useLanguageContext();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [inventory, setInventory] = useState<EditInventory>(editInventoryMock);
  const [inventoryTypes, setInventoryTypes] = useState<{ id: number; name: string }[]>([]);

  const { branches } = useGlobalCollectionsContext();

  /**
   * @description Обновить инвентарь
   * @param {React.FormEvent<EventTarget>} e
   * @returns {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        try {
          await CRM.updateInventory(id, values);
          message.success(strings.INVENTORY_WAS_UPDATED_SUCCESSFULLY);
          push('/teaching_materials');
        } catch {
          message.error(strings.SORRY_SOMETHING_WENT_WRONG_WHEN_EDITING_AN_INVENTORY_PLEASE_TRY_AGAIN);
        } finally {
          setLoading(false);
        }
      } else {
        message.error(strings.FILL_ALL_FIELDS_TO_PROCEED);
      }
    });
  };

  /**
   * @description Получить инвентарь по ID
   * @returns {Promise<void>}
   */
  const fetchInventory = async (): Promise<void> => {
    setLoading(true);

    try {
      const { data } = await CRM.getInventoryById(id);
      setInventory(data);
    } catch {
      message.error(strings.SORRY_SOMETHING_WENT_WRONG_WHEN_FETCHING_INVENTORY_PLEASE_TRY_AGAIN);
    } finally {
      setLoading(false);
    }
  };

  /**
   * @description Получить type
   * @returns {Promise<any>}
   */
  const fetchInventoryTypes = async (): Promise<void> => {
    try {
      const { data } = await CRM.getInventoryTypes();
      setInventoryTypes(data);
    } catch {
      message.error(strings.SORRY_SOMETHING_WENT_WRONG_WHEN_FETCHING_INVENTORY_TYPES);
    }
  };

  useEffect(() => {
    fetchInventoryTypes();
    fetchInventory();
  }, []);

  return (
    <StEditTeachingMaterials>
      <Title level={3}>
        {lastLocation && <ArrowLeftOutlined onClick={handleBack} />} {strings.EDITING_STUDY_MATERIAL}
      </Title>

      <Spin spinning={isLoading} tip={strings.LOADING}>
        <Form layout="vertical" onSubmit={handleSubmit}>
          <Row>
            <Col lg={7}>
              <MyFormItem label={strings.ISBN}>
                {getFieldDecorator('isbn', {
                  rules: [{ required: false }],
                  initialValue: inventory.isbn
                })(<Input placeholder={strings.ISBN} />)}
              </MyFormItem>
              <MyFormItem label={strings.NAME}>
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: `${strings.NAME} ${strings.IS_REQUIRED}` }],
                  initialValue: inventory.name
                })(<Input placeholder={strings.NAME} />)}
              </MyFormItem>
              <MyFormItem label={strings.TYPE}>
                {getFieldDecorator('type', {
                  rules: [{ required: false }],
                  initialValue: inventory?.type?.id
                })(
                  <Select placeholder={strings.TYPE}>
                    {inventoryTypes.map((type: Branch) => (
                      <Option value={type.id} key={type.id}>
                        {type.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </MyFormItem>
              <MyFormItem label={strings.RATE}>
                {getFieldDecorator('rate', {
                  rules: [{ required: true, message: `${strings.RATE} ${strings.IS_REQUIRED}` }],
                  initialValue: inventory.rate
                })(<Input placeholder={strings.RATE} />)}
              </MyFormItem>
              <MyFormItem label={strings.COMMENT}>
                {getFieldDecorator('comment', {
                  rules: [{ required: false }],
                  initialValue: inventory.comment
                })(<Input.TextArea placeholder={strings.COMMENT} />)}
              </MyFormItem>
            </Col>
            <Col lg={7}>
              <MyFormItem label={strings.LEFT}>
                {getFieldDecorator('left', {
                  rules: [{ required: false }],
                  initialValue: inventory.left
                })(<Input placeholder={strings.LEFT} />)}
              </MyFormItem>
              <MyFormItem label={strings.OLD_RATE}>
                {getFieldDecorator('oldRate', {
                  rules: [{ required: true, message: `${strings.OLD_RATE} ${strings.IS_REQUIRED}` }],
                  initialValue: inventory.rate
                })(<Input placeholder={strings.OLD_RATE} />)}
              </MyFormItem>

              <MyFormItem label={strings.BRANCH}>
                {getFieldDecorator('branch', {
                  rules: [{ required: false }],
                  initialValue: inventory.branch
                })(
                  <Select placeholder={strings.BRANCH}>
                    {branches &&
                      branches.map((br: Branch) => (
                        <Option value={br.id} key={br.id}>
                          {br.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </MyFormItem>
              <MyFormItem label={strings.ACTUAL}>
                {getFieldDecorator('actual', {
                  rules: [{ required: false }],
                  initialValue: inventory.actual,
                  valuePropName: 'checked'
                })(<Checkbox style={{ padding: 0 }}>{strings.ACTUAL}</Checkbox>)}
              </MyFormItem>
            </Col>
          </Row>
          <Row style={{ marginTop: '30px' }}>
            <Col lg={14}>
              <Button type="primary" size="large" htmlType="submit">
                {strings.EDIT}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </StEditTeachingMaterials>
  );
});

export default { EditTeachingMaterials };
