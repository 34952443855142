import styled, { css } from 'styled-components';
import { Form as LegalForm } from '@ant-design/compatible';
import { Col, Row, Form } from 'antd';

interface IContainer {
  tableScrollRemove?: boolean;
  tableWithScroll?: boolean;
}

export const FlexBox = styled.div<{ direction: string; justifyContent: string; alignItems: string }>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ alignItems }) => alignItems};
`;

export const Container = styled.div<IContainer>`
  margin: 20px;
  padding: 30px 20px;
  border-radius: 5px;
  background: #fff;
  // box-shadow: 0px 0px 46px -15px rgb(0 0 0 / 12%);

  & td.big-number {
    font-size: 16px;
  }

  ${props =>
    props.tableWithScroll &&
    css`
      .ant-table-wrapper {
        overflow-x: auto;
      }
    `}
  .ant-table-wrapper::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  .ant-table-wrapper::-webkit-scrollbar-thumb {
    background: #aaa;
  }

  //.ant-col {
  //  padding: 0 6px;
  //}
  //
  //.ant-row {
  //  margin-inline-start: -6px;
  //  margin-inline-end: -6px;
  //}

  ${props =>
    props.tableScrollRemove &&
    css`
      .ant-table-wrapper {
        overflow: unset;
      }
    `}//& .ant-col {
  //  padding: 0 6px;
  //}
  //
  //& .ant-form .ant-row {
  //  margin: 0 -3px;
  //}
`;

export const TableScroll = styled.div`
  overflow-x: auto;
`;

export const StyledCard = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 0px 46px -15px rgb(0 0 0 / 12%);
  opacity: 0.8;
`;

export const StyledCardTitle = styled.div`
  display: flex;
  .name {
    margin-inline-start: 20px;
    font-size: 20px;
    font-weight: 500;
    .phone {
      font-size: 16px;
      display: block;
      opacity: 0.7;
    }
  }
`;

export const StyledCardDetails = styled.div`
  column-count: 1;
  margin-top: 15px;
  h3 {
    font-size: 18px;
    color: #8b8a8a;
    margin: 25px 0 15px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #d8d8d8;
  }
`;

export const StyledCardDetailsItem = styled.li`
  font-size: 16px;
  margin: 5px 0;
  padding: 12px 15px;
  background: #fff;
  border-radius: 10px;
  text-align: right;
  word-break: break-all;
  list-style-type: none;
  &:nth-child(even) {
    background: #f1f1f1;
  }
  & b {
    text-align: left;
    float: left;
    padding-inline-end: 10px;
  }
  page-break-inside: avoid;
  break-inside: avoid;
  page-break-inside: avoid-column;
  break-inside: avoid-column;
  width: 100%;
  float: left;
`;

export const StyledCollapseBtn = styled.div`
  padding: 12px 0;
  flex: 1;
  text-align: right;
`;

export const StyledCollapsePanel = styled.div`
  color: #cc1e44;
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-radius: 5px 5px 0 0;
  background: #f3f3f3;
  gap: 10px;
  span {
    color: #595959;
    padding: 4px;
  }
  svg {
    fill: #595959;
  }
`;

export const StyledCollapseAvatar = {
  top: 40,
  left: 40,
  opacity: 1,
  zIndex: 100,
  fontSize: 12,
  color: '#cc1e44',
  border: 'none',
  boxShadow: '0 0 4px rgb(0 0 0 / 20%)'
};

export const StyledForm = styled(LegalForm)`
  padding: 20px;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 5px;
  max-width: 100%;
  box-shadow: 0 0 46px -15px rgb(0 0 0 / 12%);
  .ant-select,
  .ant-input {
    margin-bottom: 0;
  }
  .ant-col {
    padding: 0 5px;
  }

  h1 {
    margin-bottom: 30px;
  }
  .uploadAudio {
    position: absolute;
    right: -10px;
    opacity: 1;
    z-index: 100;
  }
`;
export const StForm = styled(Form)`
  padding: 20px;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 5px;
  max-width: 100%;
  box-shadow: 0 0 46px -15px rgb(0 0 0 / 12%);
  h1 {
    margin-bottom: 30px;
  }
  .uploadAudio {
    position: absolute;
    right: -10px;
    opacity: 1;
    z-index: 100;
  }
`;

export const StyledSeparator = styled.div`
  border-bottom: 1px solid #d8d8d8;
  margin: 20px 0;

  h2 {
    font-size: 18px;
    color: #5c5c5c;
  }
`;

export const StyledRow = styled(Row)`
  margin: 15px 0;
`;

export const StUploadedFieldsRow = styled(Row)`
  margin: 15px 0;
  padding: 5px;
`;

export const StCol = styled(Col)`
  margin-bottom: 30px;
`;

export const StLabel = styled.div`
  margin-bottom: 10px;
  font-size: 16px;
  color: #262626;
`;

export const blockStyles = {
  backgroundColor: '#fafafa',
  maxHeight: 100,
  padding: 10,
  overflow: 'scroll',
  borderRadius: 5
};

export const FormItemWithoutMargin = styled(Form.Item)`
  margin-bottom: 0;
`;

export default { Container, TableScroll, FormItemWithoutMargin };
