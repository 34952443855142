import React from 'react';
import { Branch, Supergroup } from 'types';
import { CheckCircleTwoTone, EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

export const columns = (hasTopManager: boolean, strings) => [
  {
    title: strings.NAME,
    dataIndex: 'name',
    key: 'name',
    render: (name: string, supergroup: Supergroup) => <Link to={`/supergroup/${supergroup.id}`}>{name}</Link>
  },
  hasTopManager
    ? {
        title: strings.BRANCH,
        dataIndex: 'branch',
        key: 'branch',
        width: 200,
        render: (branch: Branch) => branch?.name
      }
    : {},
  {
    title: strings.CURRENT,
    dataIndex: 'isActive',
    key: 'current',
    width: 100,
    render: (isActive: boolean) =>
      isActive ? <CheckCircleTwoTone style={{ fontSize: 24 }} twoToneColor="#52c41a" /> : null
  },
  {
    dataIndex: 'id',
    key: 'editAction',
    width: 60,
    render: (id: number) => (
      <Link to={`/supergroup/${id}/edit`}>
        <Button icon={<EditOutlined />} style={{ marginBottom: 0 }} />
      </Link>
    )
  }
];

export default { columns };
