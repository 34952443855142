import { Button, Row } from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons';
import React from 'react';
import dayjs from 'dayjs';
import { dateFormat } from 'helpers';

export const getFinanceColumn = strings => [
  {
    title: strings.DATE,
    dataIndex: 'opDate',
    key: 'date',
    render(opDate: string) {
      return <p>{dayjs(opDate).format(dateFormat)}</p>;
    }
  },
  {
    title: strings.SUM,
    dataIndex: 'amount',
    key: 'amount'
  },
  {
    title: strings.OPERATION,
    dataIndex: 'operationType',
    key: 'operationType',
    render: operationType => (operationType === 'minus' ? strings.MINUS : strings.PLUS)
  },
  {
    title: strings.COMMENT,
    dataIndex: 'comment',
    key: 'comment'
  }
];

export const getExtraBalanceColumns = strings => [
  {
    title: strings.STUDENTS,
    dataIndex: 'students',
    key: 'students',
    render: (students: { name: string; surname: string }[]) =>
        students.map((child, idx) => (
            <p key={idx}>
              {child.name} {child?.surname}
            </p>
        ))
  },
  {
    title: strings.BALANCE,
    dataIndex: 'balance',
    key: 'balance'
  },
  {
    title: strings.DEPOSIT,
    dataIndex: 'deposit',
    key: 'deposit'
  }
];
