import styled from 'styled-components';

export const Email = styled.div`
  margin: 20px;
  padding: 30px 20px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 46px -15px rgb(0 0 0 / 12%);
  .ant-col {
    padding: 0 6px;
  }
  .ant-select {
    width: 100%;
  }

  .ant-form-legacy-item-control-wrapper,
  .ant-form-legacy-item-label {
    padding-inline-start: 0;
  }
`;

export const EmailHistory = styled(Email)``;
export const ShowEmail = styled(Email)``;

export default { Email, EmailHistory, ShowEmail };
