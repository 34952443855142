import React, { useEffect, useState } from 'react';
import { Modal, Button, Input, message, Typography, Collapse, Spin, Form, Flex } from 'antd';
import { CommentForParentsPostParams, MetaGrade } from 'types/TeacherAccount/Courses';
import { StudentInfoFeedback } from './StudentInfoFeedback';
import { MetaGoalItem } from 'types/courses';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useLessonRepository } from 'repos/LessonRepository';
import { hasMetaGoalsBranch } from 'helpers/branches';
import { QuickTips } from 'Global/components/QuickTips';
import { QUICK_TIPS_META_GOALS } from 'Global/constants';
import { useUserContext } from 'contexts/UserContext';
import MetaGoalsMemo from 'Global/components/FormComponents/MetaGoals';
import { CommentForParent } from './CommentForParent';
import { ILessonRegister } from 'types/lesson';
import { useLessonRegisterStore } from 'stores/useLessonRegisterStore';
import { useLessonInfoStore } from 'stores/useLessonInfoStore';

const { Text } = Typography;
const { Panel } = Collapse;

interface FeedbackForParentProps {
  hasShowFeedbackForParentModal: boolean;
  setHasShowFeedbackForParentModal: (hasShow: boolean) => void;
  register: ILessonRegister;
  hasNeedFeedback: boolean;
  lessonsMetaGoals: MetaGoalItem[];
}

/**
 * @description Модальное окно сохранения фибэка для родителя или ученика
 */
export const FeedbackForParentModal = ({
  hasShowFeedbackForParentModal,
  setHasShowFeedbackForParentModal,
  register,
  hasNeedFeedback,
  lessonsMetaGoals
}: FeedbackForParentProps) => {
  const lessonInfo = useLessonInfoStore(state => state.lessonInfo);
  const registers = useLessonRegisterStore(state => state.registers);
  const registerColumns = useLessonRegisterStore(state => state.registerColumns);
  const setRegisters = useLessonRegisterStore(state => state.setRegisters);

  const { student, id: registerId } = register;
  const [goals, setGoals] = useState<MetaGrade[]>([]);
  const [goalsPrev, setGoalsPrev] = useState<MetaGrade[]>([]);
  const [goalsUpdatedAt, setGoalsUpdatedAt] = useState<string>('');
  const [parentsComments, setParentsComments] = useState<CommentForParentsPostParams>(null);
  const [commentForParentUpdatedAt, setCommentForParentUpdatedAt] = useState<string>('');
  const [commentForStudentUpdatedAt, setCommentForStudentUpdatedAt] = useState<string>('');
  const [hasCommentsLoading, setCommentsLoading] = useState<boolean>(false);
  const [hasMetaGoalsLoading, setHasMetaGoalsLoading] = useState<boolean>(false);
  const [hasSaveLoading, setHasSaveLoading] = useState<boolean>(false);
  const [hasOutcomesChanged, setHasOutcomesChanged] = useState<boolean>(false);
  const [hasMetaGoalsChanged, setHasMetaGoalsChanged] = useState<boolean>(false);
  const [hasParentCommentChanged, setHasParentCommentChanged] = useState<boolean>(false);
  const [hasStudentCommentChanged, setHasStudentCommentChanged] = useState<boolean>(false);
  const [strings] = useLanguageContext();
  const lessonRepository = useLessonRepository();
  const [user] = useUserContext();
  const hasRiga = user?.branch?.id === 5;
  const [{ confirm, info }, contextHolder] = Modal.useModal();

  const [form] = Form.useForm();

  // Если пришло время заполнять фидбэк бейдж становится красного цвета. И при этом, ученик был на уроке
  const hasRedBagde = hasNeedFeedback && register?.presence;

  const updateComments = async (values, ignoreUpdated = false): Promise<any> => {
    const { commentForParent, commentForParent2, commentForStudent } = values;
    const commentParams = {
      commentForParent,
      commentForParent2,
      commentForStudent,
      commentForParentUpdatedAt,
      commentForStudentUpdatedAt,
      ignoreUpdated
    };
    try {
      const response = await lessonRepository.editCommentsByRegisterId(registerId, commentParams);
      const {
        data: { commentForParent, commentForStudent, lastFeedback, needFeedbackForParentsStatus }
      } = response;
      const registersValues = registers.map(register => {
        if (register.student.id === student.id) {
          return {
            ...register,
            commentForParent,
            commentForStudent,
            lastFeedback,
            needFeedbackForParentsStatus
          };
        }
        return register;
      });
      setRegisters(registersValues);
      return response;
    } catch (error) {
      return { ...error, call: updateComments };
    }
  };

  const updateGoals = async (values, ignoreUpdated = false): Promise<any> => {
    const { goals } = values;
    const params = { goals, ignoreUpdated, updatedAt: goalsUpdatedAt };
    try {
      const response = await lessonRepository.editMetagoalsByRegisterId(registerId, params);
      return response;
    } catch (error) {
      return { ...error, call: updateGoals };
    }
  };

  const updateOutcomes = async (values): Promise<any> => {
    const { outcomes } = values;
    const params = { outcomes };
    try {
      const response = await lessonRepository.editOutcomesByRegisterId(registerId, params);
      return response;
    } catch {}
  };

  /**
   * @description Отдельное сохранение фидбэка
   * @return {void}
   */
  const handleSaveFeedback = async (values): Promise<any> => {
    try {
      const hasCommentsChanged = hasParentCommentChanged || hasStudentCommentChanged;
      if (!hasCommentsChanged && !hasMetaGoalsChanged && !hasOutcomesChanged) {
        return info({ title: strings.THERE_WERE_NO_CHANGES });
      }
      setHasSaveLoading(true);
      const saveComment = hasCommentsChanged && updateComments(values);
      const saveGoals = hasMetaGoalsChanged && updateGoals(values);
      const saveOutcomes = hasOutcomesChanged && updateOutcomes(values);
      const response = await Promise.all([saveComment, saveGoals, saveOutcomes]);

      if (response.filter(item => item).filter(item => item?.status !== 200).length === 0) {
        setHasShowFeedbackForParentModal(false);
        message.success(strings.SUCCESS_SAVE, 2);
      } else {
        const conflictSaveRequests = response.filter(item => item?.status === 422);
        if (conflictSaveRequests.length > 0) {
          confirm({
            title: strings.DO_YOU_WANT_TO_SAVE_THE_CURRENT_CHANGES,
            content:
              strings.ATTENTION_THIS_FEEDBACK_HAS_ALREADY_BEEN_SAVED_YOU_MAY_LOSE_THE_INFORMATION_YOU_FILLED_IN_EARLIER,
            okText: strings.SAVE,
            okType: 'danger',
            async onOk() {
              try {
                await Promise.all(
                  conflictSaveRequests.map(({ call }: { call: (values, ignoreUpdated: boolean) => Promise<any> }) =>
                    call(values, true)
                  )
                );
                setHasShowFeedbackForParentModal(false);
                message.success(strings.SUCCESS_SAVE, 2);
              } catch {}
            }
          });
        }
      }
    } catch (error) {
      message.error(strings.SAVE_FAILED_TRY_AGAIN_LATER, 2);
    } finally {
      setHasSaveLoading(false);
    }
  };

  const onCancel = () => {
    const hasCommentsChanged = hasParentCommentChanged || hasStudentCommentChanged;
    if (hasMetaGoalsChanged || hasCommentsChanged || hasOutcomesChanged) {
      confirm({
        title: strings.ARE_YOU_SURE_YOU_WANT_LEAVE_WITHOUT_SAVING,
        okType: 'danger',
        onOk() {
          setHasShowFeedbackForParentModal(false);
        }
      });
    } else {
      setHasShowFeedbackForParentModal(false);
    }
  };

  const getComments = async (): Promise<any> => {
    setCommentsLoading(true);
    try {
      const { data } = await lessonRepository.getCommentsByRegisterId(registerId);
      setCommentForStudentUpdatedAt(data.commentForStudentUpdatedAt);
      setCommentForParentUpdatedAt(data.commentForParentUpdatedAt);
      setParentsComments(data);
    } finally {
      setCommentsLoading(false);
    }
  };

  const getRegisterMetaGoals = async (): Promise<any> => {
    setHasMetaGoalsLoading(true);
    try {
      const {
        data: { goals, goalsPrev, updatedAt }
      } = await lessonRepository.getMetagoalsByRegisterId(registerId);
      setGoals(goals);
      setGoalsPrev(goalsPrev);
      setGoalsUpdatedAt(updatedAt);
    } finally {
      setHasMetaGoalsLoading(false);
    }
  };

  useEffect(() => {
    if (hasShowFeedbackForParentModal) {
      getComments();
      getRegisterMetaGoals();
    }
  }, [hasShowFeedbackForParentModal]);

  return (
    <Modal
      title={strings.FEEDBACK_FOR_PARENT}
      width={800}
      open={hasShowFeedbackForParentModal}
      onCancel={onCancel}
      destroyOnClose={true}
      footer={false}
    >
      {contextHolder}
      <Form form={form} layout="vertical" onFinish={handleSaveFeedback}>
        <StudentInfoFeedback student={student} />
        <Spin spinning={hasCommentsLoading}>
          {parentsComments && (
            <>
              <CommentForParent
                parentsComments={parentsComments}
                setHasCommentForParentChanged={setHasParentCommentChanged}
                hasNeedFeedback={hasNeedFeedback}
              />
              {registerColumns.includes('commentForStudent') && (
                <Form.Item
                  label={strings.FEEDBACK_FOR_THE_STUDENT}
                  name="commentForStudent"
                  initialValue={parentsComments?.commentForStudent}
                >
                  <Input.TextArea style={{ height: 75 }} onChange={() => setHasStudentCommentChanged(true)} />
                </Form.Item>
              )}
            </>
          )}
        </Spin>
        {lessonsMetaGoals?.length > 0 && hasMetaGoalsBranch(lessonInfo?.lesson?.courseGroup?.branch?.id) && (
          <Form.Item>
            <Collapse defaultActiveKey={hasRedBagde ? 1 : 0}>
              <Panel
                key={1}
                header={strings.META_GOALS}
                extra={
                  <div style={{ display: 'flex', gap: 10 }}>
                    <Text strong style={{ color: '#cc4454' }}>
                      {strings.PARENTS_WILL_NOT_SEE_THE_META_GOALS}
                    </Text>
                    {!hasRiga && <QuickTips url={QUICK_TIPS_META_GOALS} />}
                  </div>
                }
              >
                <Spin spinning={hasMetaGoalsLoading}>
                  <MetaGoalsMemo
                    metaGoals={lessonsMetaGoals}
                    metaGoalsGrades={goals}
                    prevMetaGoalsGrades={goalsPrev}
                    setHasMetaGoalsChanged={setHasMetaGoalsChanged}
                  />
                </Spin>
              </Panel>
            </Collapse>
          </Form.Item>
        )}
        <Flex gap={10} justify="end">
          <Button key="cancel" onClick={onCancel}>
            {strings.CANCEL}
          </Button>
          <Button type="primary" key="save" loading={hasSaveLoading} htmlType="submit">
            {strings.SAVE_FEEDBACK}
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};
