import React from 'react';
import { Select } from 'antd';
import { Admin } from 'types';

const { Option } = Select;

export const columns = strings => [
  {
    title: strings.ADMIN,
    dataIndex: 'admin',
    key: 'admin',
    render: (admin: Admin) => `${admin.name} ${admin.surname}`
  },
  {
    title: strings.LEVEL,
    dataIndex: 'level',
    key: 'level',
    render: (level: string, record: any) => {
      return (
        <Select value={level} onChange={(level: string) => record.onLevelEdit(record.id, level)}>
          <Option value="e">{strings.CAN_VIEW_AND_EDIT}</Option>
          <Option value="v">{strings.ONLY_VIEW}</Option>
        </Select>
      );
    }
  }
];

export default columns;
