import React from 'react';
import Helmet from 'react-helmet';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { useLastLocation } from 'react-router-last-location';
import { useLocation, useHistory } from 'react-router-dom';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Title } = Typography;

/**
 * @description How to LG
 * @param props
 * @returns {React.ReactNode}
 */
export const HowToLg = () => {
  const lastLocation = useLastLocation();
  const location = useLocation();
  const [strings] = useLanguageContext();
  const { goBack } = useHistory();

  return (
    <>
      <Helmet>
        <title>How to lg</title>
      </Helmet>
      <div style={{ padding: '40px 20px' }}>
        <Title level={3}>
          {lastLocation && <ArrowLeftOutlined onClick={goBack} style={{ marginInlineEnd: 8 }} />}
          {strings.HOW_TO_LG}
        </Title>
        <iframe
          className="airtable-embed"
          src="https://airtable.com/embed/shrzWXut3Oom5vrkY?backgroundColor=red&viewControls=on"
          frameBorder="0"
          width="100%"
          title="How to LG"
          style={{
            height: '100vh',
            background: 'transparent',
            border: '1px solid #ccc'
          }}
        />
      </div>
    </>
  );
};

export default { HowToLg };
