import React from 'react';
import { IStudent } from 'types';
import { BranchBruner } from 'types/branches';
import { BrunerMainInfo } from './BrunerMainInfo';
import Avatar from 'Global/components/Avatar';
import { Descriptions, Flex, Typography } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { BaseMainInfo } from './BaseMainInfo';
import styled from 'styled-components';
import { useLanguageContext } from 'contexts/LanguageContext';
import dayjs from 'dayjs';
import { dateFormat } from 'helpers/dates';

interface MainInformationProps {
  child: IStudent;
}
export const StDescription = styled(Descriptions)`
  .ant-descriptions-item-container {
    flex-wrap: wrap;
  }
  .ant-descriptions-item-content {
    min-width: fit-content;
  }
`;

export const MainInformation = (props: MainInformationProps) => {
  const [strings] = useLanguageContext();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { child } = props;
  const { user, mainPhoto, created, updated } = child || {};
  const { name, surname, branch } = user || {};
  const hasBruner = branch?.id === BranchBruner.id;

  return (
    <Flex vertical>
      <Flex wrap={isMobile ? 'wrap' : 'nowrap'} gap={isMobile ? 20 : 80}>
        <Avatar photo={mainPhoto} scale={3} size={80} userName={name} userSurname={surname} />
        {hasBruner ? <BrunerMainInfo child={child} /> : <BaseMainInfo child={child} />}
      </Flex>
      <Flex justify="end" gap={10}>
        <Typography.Text disabled>
          {strings.CREATED}: {created && dayjs(created).format(dateFormat)}
        </Typography.Text>
        <Typography.Text disabled>
          {strings.UPDATED}: {updated && dayjs(updated).format(dateFormat)}
        </Typography.Text>
      </Flex>
    </Flex>
  );
};

export default { MainInformation };
