export const COLOR_DEFAULT = '#007cd0';
export const COLOR_TEACHER_FILLED_ALL = '#00FF00';
export const COLOR_TEACHER_DISCIPLINES_WARNING = '#FFFF00';
export const COLOR_TEACHER_DISCIPLINES_ALERT = '#FF0000';
export const LESSON_TEST_MATERIAL_TYPE = 'lessonTest';
export const LESSON_BONUS_MATERIAL_TYPE = 'lessonBonus';
export const LESSON_HIDDEN_MATERIAL_TYPE = 'lessonHidden';
export const LESSON_MATERIAL_TYPE = 'lessonMaterial';
export const LESSON_HOMEWORK_MATERIAL_TYPE = 'lessonHomework';
export const REGISTER_TAB = 'register';
export const INFO_TAB = 'info';

export const mapTooltips = new Map();
mapTooltips.set(COLOR_DEFAULT, 'Upcoming lesson');
mapTooltips.set(COLOR_TEACHER_FILLED_ALL, 'Register is filled in');
mapTooltips.set(COLOR_TEACHER_DISCIPLINES_WARNING, 'Fill in the register');
mapTooltips.set(COLOR_TEACHER_DISCIPLINES_ALERT, 'Fill in the register');

export const initialAttachment = {
  id: null,
  name: ''
};

export type AttachmentType = {
  id: number;
  name: string;
};

export const gradeList = [
  { value: 1, title: 'Yes' },
  { value: 2, title: 'No' },
  { value: 3, title: "Can't assess" }
];
export const primaryGradeList = [
  { value: 3, title: 'I still need to learn this' },
  { value: 2, title: 'I can do this' },
  { value: 1, title: 'I can do this very well' }
];
