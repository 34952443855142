// User
export const ROLE_USER = 'ROLE_USER';

// Teacher
export const ROLE_TEACHER = 'ROLE_TEACHER';

// Parent
export const ROLE_PARENT = 'ROLE_PARENT';

// Admin
export const ROLE_ADMIN = 'ROLE_ADMIN';

// Top manager
export const ROLE_TOP_MANAGER = 'ROLE_TOP_MANAGER';

// Branch Director
export const ROLE_BRANCH_DIRECTOR = 'ROLE_BRANCH_MANAGER';

// Student
export const ROLE_STUDENT = 'ROLE_STUDENT';

// Branch Director of Operational Department
export const ROLE_BRANCH_DIRECTOR_OPERATION_DEPARTMENT = 'ROLE_BRANCH_DIRECTOR_OPERATION_DEPARTMENT';

// Academic Expert
export const ROLE_ACADEMIC_EXPERT = 'ROLE_ACADEMIC_EXPERT';

// ROLE_USER;
// ROLE_TEACHER;
// ROLE_PARENT;
// ROLE_STUDENT;
// ROLE_TOP_MANAGER;
// ROLE_BRANCH_DIRECTOR;
// ROLE_BRANCH_OPERATION_DEPARTAMENT_MANAGER;
// ROLE_ACADEMIC_EXPERT;

export const LIST_ROLES = [
  {
    role: ROLE_USER,
    prefix: 'user'
  },
  {
    role: ROLE_TEACHER,
    prefix: 'teacher'
  },
  {
    role: ROLE_PARENT,
    prefix: 'parent'
  },
  {
    role: ROLE_ADMIN,
    prefix: 'admin'
  },
  {
    role: ROLE_TOP_MANAGER,
    prefix: 'topManager'
  },
  {
    role: ROLE_BRANCH_DIRECTOR,
    prefix: 'branchDirector'
  },
  {
    role: ROLE_STUDENT,
    prefix: 'student'
  },
  {
    role: ROLE_BRANCH_DIRECTOR_OPERATION_DEPARTMENT,
    prefix: 'branchOperationDepartament'
  },
  {
    role: ROLE_ACADEMIC_EXPERT,
    prefix: 'academicExpert'
  }
];

export default {
  ROLE_TEACHER,
  ROLE_USER,
  ROLE_STUDENT,
  ROLE_PARENT,
  ROLE_ADMIN,
  ROLE_TOP_MANAGER,
  ROLE_BRANCH_DIRECTOR,
  ROLE_BRANCH_DIRECTOR_OPERATION_DEPARTMENT,
  ROLE_ACADEMIC_EXPERT,
  LIST_ROLES
};
