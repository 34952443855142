import React from 'react';
import { DeleteOutlined, EditOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import dayjs from 'dayjs';
import { dateFormat } from 'helpers/dates';
import { BASE_FAMILIES } from 'Global/routes';
import { Link } from 'react-router-dom';
import { Child } from 'types';
import { FreeDay } from 'types/Family';
import { FamilyFinancePerformer, FamilyFinanceProduct, FamilyFinanceItem } from 'types/Family';

export const getColumns = (hasAdmin?: boolean, hasOperationTypeColumn?: boolean) => [
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (amount: number) => (amount > 0 ? <b>+{amount}</b> : amount)
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    render: (code: string) => (code ? code : '-')
  },
  {
    title: 'Op Date',
    dataIndex: 'opDate',
    key: 'opDate',
    render: (date: string) => date && <p>{dayjs(date).format(dateFormat)}</p>
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment'
  },
  {
    title: 'Operation type comment',
    dataIndex: 'financeTypeComment',
    key: 'financeTypeComment'
  },
  hasAdmin && {
    title: 'Remaining balance',
    dataIndex: 'remainingBalance',
    key: 'remainingBalance'
  },
  hasOperationTypeColumn && {
    title: 'Operation Type',
    dataIndex: 'performerOperationType',
    key: 'performerOperationType',
    render: (performerOperationType: number) => (performerOperationType === 1 ? 'Balance' : 'Deposit')
  },
  {
    title: 'Performer',
    dataIndex: 'performer',
    key: 'performer',
    sorter: (a, b) => a?.performer?.name.localeCompare(b?.performer?.name),
    render: (performer: FamilyFinancePerformer) => performer?.name ?? '-'
  },
  {
    title: 'Student',
    dataIndex: 'child',
    key: 'child',
    render: (child: Child, financeItem) => {
      if (child) {
        return (
          <Link to={`${BASE_FAMILIES}/${financeItem?.parent?.familyId}/student/${child.id}`}>
            {child.name} {child.surname}
          </Link>
        );
      }

      return null;
    }
  },
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
    render: (product: FamilyFinanceProduct) => product?.name ?? '-'
  }
];

export const getActionsColumn = (
  showEditForm: (finance: FamilyFinanceItem) => void,
  showDeleteForm: (finance: FamilyFinanceItem) => void,
  addCompensation: (finance: FamilyFinanceItem) => void
) => [
  {
    title: 'Actions',
    dataIndex: 'id',
    key: 'id',
    width: 150,
    render: (id: number, finance: FamilyFinanceItem) => {
      const { opDate } = finance;
      const date = new Date().getMonth();
      const financeDate = new Date(opDate).getMonth();
      return (
        <div style={{ display: 'flex', gap: 8 }}>
          <Button icon={<EditOutlined />} style={{ marginBottom: 0 }} onClick={() => showEditForm(finance)} />
          <Button
            type="default"
            style={{ marginBottom: 0 }}
            icon={<ReloadOutlined />}
            disabled={!(date === financeDate)}
            onClick={() => addCompensation(finance)}
          />
          <Button
            danger
            style={{ marginBottom: 0 }}
            icon={<DeleteOutlined />}
            onClick={() => showDeleteForm(finance)}
          />
        </div>
      );
    }
  }
];

export const performersColumns = strings => [
  {
    title: strings.PERFORMER_NAME,
    dataIndex: 'performer',
    key: 'performer',
    render: (performer: FamilyFinancePerformer) => performer && performer.name
  },
  {
    title: strings.BALANCE,
    dataIndex: 'balance',
    key: 'balance',
    render: balance => <span style={{ fontWeight: 600 }}>{balance}</span>
  },
  {
    title: strings.DEPOSIT,
    dataIndex: 'deposit',
    key: 'deposit',
    render: deposit => <span style={{ fontWeight: 600 }}>{deposit}</span>
  }
];

/**
 * @description Колонки для таблицы с бесплатными днями
 * @param {Function} deleteFreeDay
 */
export const freeDaysColumns = (deleteFreeDay: (familyId: number) => Promise<any>) => [
  {
    dataIndex: 'date',
    key: 'date',
    render: (date: string) => date && dayjs(date).format(dateFormat)
  },
  {
    dataIndex: 'child',
    key: 'child',
    render: (child: Child, freeDay: FreeDay) => {
      if (freeDay.forAllChildren) {
        return <p>All student</p>;
      } else {
        return (
          <p>
            {child.name} {child.surname ?? ''}
          </p>
        );
      }
    }
  },
  {
    dataIndex: 'id',
    key: 'id',
    width: 32,
    render: (freeDayId: number) => (
      <Popconfirm
        title={<div style={{ width: 200 }}>Are you sure you want delete this free day?</div>}
        okText="Yes, delete!"
        okType="danger"
        cancelText="No"
        onConfirm={() => deleteFreeDay(freeDayId)}
        style={{ width: 220 }}
      >
        <Button
          danger
          icon={<DeleteOutlined />}
          size="small"
          style={{ margin: 0, padding: '2px 6px', width: 'auto', height: 'auto' }}
        />
      </Popconfirm>
    )
  }
];

export default { getColumns, performersColumns };
