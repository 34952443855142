import React from 'react';
import { Result } from 'antd';

interface ForbiddenProps {
  subTitle?: string;
  children: React.ReactElement<any>;
}

/**
 * @description 403 компонент ошибки
 * @param {string} title
 * @param {React.Children} children
 * @return {React.ReactNode}
 */
export const Forbidden = ({ subTitle, children }: ForbiddenProps) => (
  <Result
    status="403"
    title="403"
    subTitle={subTitle || 'Sorry, you do not have access to this page.'}
    extra={children}
  />
);

export default { Forbidden };
