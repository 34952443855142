import React, { useState } from 'react';
import dayjs from 'dayjs';
import {
  DeleteOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  StarOutlined,
  StarTwoTone,
  ZoomInOutlined
} from '@ant-design/icons';
import { Button, Typography, message, Flex, Popconfirm, Spin } from 'antd';
import { Global } from 'api';
import { AxiosResponse } from 'axios';
import { createTextLinks, downloadSomeFile } from 'helpers';
import { MaterialFile } from 'types/materials';
import { useUserContext } from 'contexts/UserContext';
import { IMAGE_TYPES_LIST } from 'Global/components/FilePreview/constants';
import { MaterialTitle } from './styles';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Paragraph } = Typography;

interface MaterialItemProps {
  material: MaterialFile;
  getFiles: () => Promise<any>;
  handleShowModal: (attachmentId: number, attachmentName: string) => Promise<any>;
}

/**
 * @description Компонент для вывода файлов (материалов) в уроке оригинале
 */
export const MaterialItem = ({ material, getFiles, handleShowModal }: MaterialItemProps) => {
  const [user] = useUserContext();
  const [strings] = useLanguageContext();

  const { attach, hasComment, comment, createdAt } = material;
  const formatDate = dayjs(createdAt).format('DD.MM.YYYY HH:mm');
  const [hasHideForTeacher, setHasHideForTeacher] = useState<boolean>(attach?.hideInOriginal);
  const [hasLoading, setHasLoading] = useState<boolean>(false);

  /**
   * @description Удаление материала
   */
  const deleteMaterial = async (materialId: number): Promise<any> => {
    setHasLoading(true);
    try {
      await Global.deleteAttachment(materialId);
      message.success(strings.SUCCESSFULLY_DELETED);
      await getFiles();
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  /**
   * @description Скачка прикрепленного материала
   */
  const downloadAttachment = async (fileId: number, fileName: string): Promise<any> => {
    setHasLoading(true);
    try {
      await downloadSomeFile(`/api/v1/attachment/download/${fileId}`, fileName, null, null, true);
    } finally {
      setHasLoading(false);
    }
  };

  /**
   * @description Скрытие материала от учителя
   */
  const hideMaterialForTeacher = async (materialId: number): Promise<any> => {
    setHasLoading(true);
    try {
      await Global.editAttachment({ hideInOriginal: !hasHideForTeacher }, materialId);
      message.success(strings.SUCCESSFULLY_CHANGED, 2);
      setHasHideForTeacher(prevState => !prevState);
    } catch {
      message.error(`${strings.ERROR_TRY_AGAIN_LATER} :(`, 2);
    } finally {
      setHasLoading(false);
    }
  };
  const moveMaterial = async (materialId: number, isMark: boolean): Promise<any> => {
    setHasLoading(true);
    try {
      await Global.editAttachment({ mark: Number(!isMark) }, materialId);
      message.success(strings.SUCCESSFULLY_MOVE, 2);
      await getFiles();
    } catch {
      message.error(`${strings.ERROR_TRY_AGAIN_LATER} :(`, 2);
    } finally {
      setHasLoading(false);
    }
  };

  return (
    <Spin spinning={hasLoading}>
      <Flex justify="space-between" key={attach.id}>
        <Flex gap={10} align="center">
          {(user?.hasRoles?.admin || user?.hasRoles?.topManager) && (
            <Popconfirm
              key={attach.id}
              trigger="click"
              title={strings.DO_YOU_WANT_TO_DELETE_THIS_FILE}
              onConfirm={() => deleteMaterial(attach.id)}
            >
              <Button size="small" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
          <MaterialTitle onClick={() => downloadAttachment(attach.id, attach?.downloadName)}>
            {attach?.displayName}
          </MaterialTitle>
        </Flex>
        <Flex justify="end" align="center" gap={10}>
          {user?.hasRoles?.admin && (
            <>
              {attach?.isMark ? (
                <StarTwoTone onClick={() => moveMaterial(attach.id, attach.isMark)} />
              ) : (
                <StarOutlined onClick={() => moveMaterial(attach.id, attach.isMark)} />
              )}
              {hasHideForTeacher ? (
                <EyeInvisibleOutlined onClick={() => hideMaterialForTeacher(attach.id)} key="eye-invisible" />
              ) : (
                <EyeOutlined onClick={() => hideMaterialForTeacher(attach.id)} key="eye" />
              )}
              {IMAGE_TYPES_LIST.some((attachmentType: string) => attach.originalName.includes(attachmentType)) && (
                <ZoomInOutlined onClick={() => handleShowModal(attach.id, attach.originalName)} key="zoom-in" />
              )}
            </>
          )}
          <span>{formatDate}</span>
        </Flex>
      </Flex>
      {hasComment && (
        <Paragraph style={{ margin: '10px 0' }}>
          <div dangerouslySetInnerHTML={{ __html: createTextLinks(comment) }} style={{ whiteSpace: 'pre-wrap' }} />
        </Paragraph>
      )}
    </Spin>
  );
};

export default { MaterialItem };
