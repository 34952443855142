import React from 'react';
import { CheckCircleTwoTone, CloseCircleTwoTone, MailOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
import { Row, Tooltip, Button } from 'antd';
import { dateFormat } from 'helpers/dates';
import dayjs from 'dayjs';
import { isNull } from 'lodash';
import { LessonRegister } from 'types';
import { ShowMoreText } from 'Global/components/ShowMoreText';
import { Link } from 'react-router-dom';
import emptyIcon from 'Global/img/empty.png';
import AvatarWithUserName from 'Global/components/AvatarWithUserName';
import { Teacher } from 'types/Teachers/teacher';

export const getRegisterColumnsByParent = (props: {
  strings: any;
  subjectId: number | string;
  studentId: number | string;
  openModalCreateThread: (register: LessonRegister) => void;
  handleReadParentComment: (lessonRegisterId: number) => void;
  hasStudent: boolean;
  hasParent: boolean;
}) => {
  //не показываем колонку для предмета Драма
  const { strings, openModalCreateThread, hasStudent, hasParent, studentId, subjectId, handleReadParentComment } =
    props;
  const hasShowClmns = subjectId !== '9';

  const columns = [
    {
      title: strings.DATE,
      dataIndex: 'startAt',
      key: 'date',
      width: 130,
      className: 'clmn',
      render(date: Date, register: LessonRegister) {
        const { startAt } = register;
        if (register?.homeworkRequired) {
          //если домашка обязательна,добавляем ссылку на домашку
          return (
            <Link to={`/homework?student=${studentId}&subject=${subjectId}&&startAt=${startAt}&endAt=${startAt}`}>
              {dayjs(date).format(dateFormat)}
            </Link>
          );
        } else {
          return <p>{dayjs(date).format(dateFormat)}</p>;
        }
      }
    },
    hasShowClmns && {
      title: strings.ATTENDANCE,
      key: 'attendance',
      width: 120,
      className: 'clmn-center',
      render: (lesson: LessonRegister) => {
        if (isNull(lesson?.attendance)) {
          return (
            <Row justify="center" align="middle">
              <QuestionCircleTwoTone twoToneColor="#f0ec38" style={{ fontSize: 22 }} />
            </Row>
          );
        }
        return (
          <Row justify="center" align="middle">
            {lesson?.attendance ? (
              <CheckCircleTwoTone style={{ fontSize: 22 }} twoToneColor="#52c41a" />
            ) : (
              <CloseCircleTwoTone style={{ fontSize: 22 }} twoToneColor="red" />
            )}
          </Row>
        );
      }
    },
    hasShowClmns && {
      title: strings.HOMEWORK,
      dataIndex: '',
      key: 'homework',
      width: 120,
      className: 'clmn-center',
      render: (register: LessonRegister) => {
        if (register?.homeworkRequired) {
          //еще не зафиксировано, сдана ли домашка
          if (isNull(register?.homeworkDone)) {
            return (
              <Tooltip title={strings.HW_NOT_FIXED}>
                <QuestionCircleTwoTone twoToneColor="#f0ec38" style={{ fontSize: 22 }} />
              </Tooltip>
            );
          }
          if (register?.homeworkDone) {
            //Домашка сдана
            return (
              <Tooltip title={strings.HOMEWORK_DONE}>
                <CheckCircleTwoTone style={{ fontSize: 22 }} twoToneColor="#52c41a" />
              </Tooltip>
            );
          } else {
            //Домашка не сдана
            return (
              <Tooltip title={strings.THE_TEACHER_MARKED_THIS_HOMEWORK_AS_NOT_DONE}>
                <CloseCircleTwoTone style={{ fontSize: 22 }} twoToneColor="red" />
              </Tooltip>
            );
          }
        } else {
          //дз не задано
          return (
            <Tooltip title={strings.NO_HOMEWORK_WAS_ASSIGNED}>
              <img src={emptyIcon} style={{ width: 22, opacity: 0.4 }} />
            </Tooltip>
          );
        }
      }
    },
    {
      title: strings.HW_PERCENT,
      key: 'homeworkGrade',
      dataIndex: 'homeworkGrade',
      width: 80,
      className: 'clmn-center'
    },
    {
      title: strings.TOPIC,
      dataIndex: 'lessonTitle',
      key: 'no-theme',
      width: 180,
      className: 'clmn',
      render(title: string | boolean) {
        return title || strings.NO_THEME;
      }
    },
    {
      title: strings.COMMENTS,
      key: 'comments',
      className: 'clmn',
      render: (register: LessonRegister) => {
        if (hasStudent) {
          return register.commentForStudent;
        }

        const { commentForParent, commentForParent2, commentForParent3 } = register;
        const comments = [commentForParent, commentForParent2, commentForParent3]
          .filter(comment => comment != null)
          .join(' ');
        return commentForParent && <ShowMoreText text={comments} action={handleReadParentComment} id={register.id} />;
      }
    },
    {
      title: strings.TEACHER,
      dataIndex: 'teacher',
      key: 'teacher',
      width: 150,
      className: 'clmn',
      render: (teacher: Teacher) => (
        <AvatarWithUserName
          name={teacher?.user?.name}
          nameHebrew={teacher?.user?.nameHebrew}
          surname={teacher?.user?.surname}
          surnameHebrew={teacher?.user?.surnameHebrew}
          photo={teacher?.mainPhoto}
        />
      )
    },
    hasParent && {
      title: '',
      key: 'register',
      render: (register: LessonRegister) => (
        <Tooltip title={strings.WRITE_TO_TEACHER}>
          <Button
            onClick={() => openModalCreateThread(register)}
            icon={<MailOutlined />}
            type="link"
            style={{ marginBottom: 0 }}
          ></Button>
        </Tooltip>
      )
    }
  ];

  return columns;
};

export const getTestsColumns = strings => [
  {
    title: strings.TEST_DATE,
    dataIndex: 'startAt',
    key: 'startAt',
    render: (date: string) => dayjs(date).format(dateFormat)
  },
  {
    title: strings.TOPIC,
    dataIndex: 'topic',
    key: 'topic'
  },
  {
    title: strings.TEST_PERCENT,
    dataIndex: 'percent',
    key: 'percent'
  },
  {
    title: strings.COMMENT,
    dataIndex: 'commentForStudent',
    key: 'commentary'
  }
];

export default { getRegisterColumnsByParent, getTestsColumns };
