import React from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select } from 'antd';
import { GetFieldDecoratorOptions } from '@ant-design/compatible/lib/form/Form';
import { CUSTOM_FIELD_TICKET_TYPE_ID } from 'Global/modules/Support/Modal/constants';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Option } = Select;

interface TicketTypeSelectProps {
  getFieldDecorator<T extends Object = {}>(
    id: keyof T,
    options?: GetFieldDecoratorOptions
  ): (node: React.ReactNode) => React.ReactNode;
}

/**
 * @description Селект типа тикета
 * @param {object} param
 * @return {React.ReactNode}
 */
export const TicketTypeSelect = ({ getFieldDecorator }: TicketTypeSelectProps) => {
  const [user] = useUserContext();
  const [strings] = useLanguageContext();

  // ТОЛЬКО ДЛЯ РОДИТЕЛЯ из Message Type убираем опцию I am annoyed by something
  return (
    <MyFormItem label={strings.MESSAGE_TYPE} colon={false}>
      {getFieldDecorator(CUSTOM_FIELD_TICKET_TYPE_ID, {
        rules: [{ required: false }]
      })(
        <Select placeholder={strings.SELECT_TYPE}>
          <Option value="">&nbsp;</Option>
          <Option value="problem" key="0">
            {strings.I_HAVE_A_PROBLEM}
          </Option>
          <Option value="suggestion" key="1">
            {strings.I_HAVE_A_SUGGESTION}
          </Option>
          {!user?.hasRoles?.parent && !user?.hasRoles?.student && (
            <Option value="annoyance" key="2">
              {strings.I_AM_ANNOYED_BY_SOMETHING}
            </Option>
          )}
          <Option value="kudos" key="3">
            {strings.I_WOULD_LIKE_TO_THANK_YOU}
          </Option>
          <Option value="type-other" key="4">
            {strings.OTHER}
          </Option>
        </Select>
      )}
    </MyFormItem>
  );
};

export default { TicketTypeSelect };
