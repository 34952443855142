import React from 'react';
import { Popover } from 'antd';
import { StyledEvent, StyledEventParagraph } from 'Global/modules/Schedule/styles';
import { LessonItemPopover } from 'Global/modules/Schedule/ScheduleLessonItem/LessonItemPopover';
import { ZoomIcon } from 'Global/modules/Schedule/ScheduleLessonItem/ZoomIcon';

/**
 * @description Плашка с уроком с учителя
 * @param popoverTitle
 * @param roomMetadata
 * @param hasZoom
 * @param hasEvent
 * @param event
 * @param startTime
 * @param endTime
 */
export const TeacherScheduleLessonItem = ({
  popoverTitle,
  roomMetadata,
  hasZoom,
  hasEvent,
  event,
  startTime,
  endTime
}) => {
  const {
    room: { name: roomName },
    studentsCount,
    participantsCount,
    type
  } = event;

  const lessonItemContent = (
    <Popover
      title={popoverTitle}
      content={
        <LessonItemPopover
          endTime={endTime}
          hasZoom={hasZoom}
          hasEvent={hasEvent}
          startTime={startTime}
          event={event}
          roomMetadata={roomMetadata}
        />
      }
    >
      <StyledEvent>
        <StyledEventParagraph>
          {startTime[0] === '0' ? startTime.replace('0', '') : startTime}-{endTime}{' '}
        </StyledEventParagraph>
        <StyledEventParagraph>
          {hasZoom && <ZoomIcon />}
          {roomName} ({type === 'event' ? participantsCount : studentsCount})
        </StyledEventParagraph>
        <StyledEventParagraph>{event?.lesson?.course?.groupName}</StyledEventParagraph>
        <StyledEventParagraph>{event?.lesson?.title}</StyledEventParagraph>
      </StyledEvent>
    </Popover>
  );

  if (hasEvent) {
    return lessonItemContent;
  }

  return (
    <a href={`le/${event?.lesson?.id}`} target="_blank" rel="noopener noreferrer">
      {lessonItemContent}
    </a>
  );
};
