import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select, Col, Row, Button, DatePicker } from 'antd';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { isEmpty, pickBy } from 'lodash';
import dayjs from 'dayjs';
import { useLanguageContext } from 'contexts/LanguageContext';
import { dateFormat, dateFormatForBackend } from 'helpers/dates';
import { invoicesLogsEvents } from 'Global/constants';
import { InvoicesLogsFilterParams } from 'types/logs';
import { TutorSelect } from 'Global/components/FormComponentsCompatible/Selects/TutorSelect';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { Branch } from 'types';
import { FilterButtons } from '../../../Global/components/FilterButtons';
import { useLocationSearchParams } from '../../../hooks/useLocationSearchParams';
import { momentFormatValues } from '../../../helpers/momentFormatValues';

const { Option } = Select;

interface FilterProps {
  form: WrappedFormUtils;
  getInvoicesLogsList: (params?: InvoicesLogsFilterParams) => Promise<any>;
}

/**
 * @description Filter InvoicesLogs table
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const FilterTemplate = (props: FilterProps) => {
  const {
    form: { getFieldDecorator, validateFieldsAndScroll, resetFields },
    getInvoicesLogsList
  } = props;
  const [strings] = useLanguageContext();
  const { branches } = useGlobalCollectionsContext();
  const { locationSearchParams } = useLocationSearchParams();
  const { dateStart, dateEnd, admin, event, branch } = locationSearchParams || {};
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);

  /**
   * @description Form Submit
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values) => {
      if (isEmpty(errors)) {
        const filterParams = pickBy(momentFormatValues(values));
        try {
          setHasSubmitLoading(true);
          await getInvoicesLogsList(filterParams);
        } finally {
          setHasSubmitLoading(false);
        }
      }
    });
  };

  /**
   * @description Очистка фильтра
   * @return {void}
   */
  const handleClear = async (): Promise<any> => {
    resetFields();
    await getInvoicesLogsList();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={[10, 10]}>
        {branches.length > 1 && (
          <Col lg={5}>
            {getFieldDecorator('branch', {
              initialValue: branch && Number(branch),
              rules: [{ required: false }]
            })(
              <Select placeholder={strings.BRANCH}>
                {branches.map((branch: Branch) => (
                  <Option value={branch.id} key={branch.id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            )}
          </Col>
        )}
        <Col lg={4}>
          {getFieldDecorator('dateStart', {
            rules: [{ required: false }],
            initialValue: dateStart ? dayjs(dateStart) : null
          })(<DatePicker format={dateFormat} placeholder={strings.FROM} />)}
        </Col>
        <Col lg={4}>
          {getFieldDecorator('dateEnd', {
            rules: [{ required: false }],
            initialValue: dateEnd ? dayjs(dateEnd) : null
          })(<DatePicker format={dateFormat} placeholder={strings.TILL} />)}
        </Col>
        <Col lg={5}>
          <TutorSelect
            initTutor={admin}
            name="admin"
            placeholder={strings.SELECT_ADMIN}
            getFieldDecorator={getFieldDecorator}
            style={{ margin: 0 }}
          />
        </Col>
        <Col lg={5}>
          {getFieldDecorator('event', {
            rules: [{ required: false }],
            initialValue: event && Number(event)
          })(
            <Select placeholder={strings.ACTION}>
              <Option value="">&nbsp;</Option>
              {invoicesLogsEvents.map(type => (
                <Option key={type.value} value={type.value}>
                  {type.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
      </Row>
      <FilterButtons handleClear={handleClear} hasSubmitLoading={hasSubmitLoading} />
    </Form>
  );
};

export const Filter = Form.create<FilterProps>({})(FilterTemplate);
export default { Filter };
