import React from 'react';
import { BASE_FAMILIES } from 'Global/routes';
import { DollarOutlined, EditOutlined, LoginOutlined } from '@ant-design/icons';
import { Button, Tooltip, Row, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { switchUserModal } from 'helpers/switchUser';
import { FamilyStatus } from 'types/Family';
import AvatarWithUserName from 'Global/components/AvatarWithUserName';
import { BtnCreateAccount } from 'Global/components/BtnCreateAccount';
import { getCallPhoneButton } from 'helpers';
import { IParent } from 'types';
import { Teacher } from '../../../../types/Teachers/teacher';

export interface ParentWithAction extends IParent {
  handleUserRefetch: () => Promise<any>;
}

export const columns = strings => [
  {
    title: strings.NAME,
    dataIndex: 'user',
    key: 'name',
    width: 200,
    render: (user, parent: ParentWithAction) => {
      return (
        <AvatarWithUserName
          name={user?.name}
          surname={user?.surname}
          link={`${BASE_FAMILIES}/${parent.familyId}/parent/${parent?.id}`}
          photo={parent?.mainPhoto}
        />
      );
    }
  },
  {
    title: strings.FAMILY,
    dataIndex: 'familyId',
    key: 'familyId',
    width: 85,
    render: (familyId: number) => <Link to={`${BASE_FAMILIES}/${familyId}`}>{familyId}</Link>
  },
  {
    title: strings.CONTACTS,
    dataIndex: '',
    key: 'email',
    render: (parent: ParentWithAction) => {
      const {
        user: { phone, email }
      } = parent;

      return (
        <>
          {email && <p>{email}</p>}
          {phone && <p>{phone}</p>}
        </>
      );
    }
  },
  {
    title: strings.STATUS,
    dataIndex: 'familyStatus',
    key: 'familyStatus',
    width: 120,
    render: (familyStatus: FamilyStatus) => <p>{familyStatus?.name}</p>
  }
];
