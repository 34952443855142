import styled from 'styled-components';
import { Layout } from 'antd';
import * as React from 'react';

const { Header } = Layout;

export const HeaderContainer = styled(Header)`
  padding: 0 20px 0 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  height: 70px;
  position: fixed;
  width: 100%;
  z-index: 1000;
  box-shadow: 3px 0 3px 1px rgb(0 0 0 / 10%);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const HeaderLogoTitle = styled.h2`
  color: #333;
  margin: 0;
  img {
    max-width: 70px;
    object-fit: cover;
  }
`;

export const HeaderUser = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: 1.5;
  .ant-dropdown-link {
    color: #fff;
  }

  @media (min-width: 768px) {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
  }
`;

export const HeaderUserResponsive = styled.div<{ isAdmin?: boolean }>`
  margin-inline-end: ${(props: any) => (props.isAdmin ? '0' : '0.5rem')};

  display: flex;
  align-items: center;
  gap: 10px;

  @media (min-width: 768px) {
    display: none;
  }
  .ant-dropdown-link {
    color: #fff;
  }
`;

export const HeaderSearch = styled.div`
  .ant-input-suffix {
    top: 43%;
    font-size: 16px;
  }

  @media only screen and (max-width: 480px) {
    width: 40vw;

    .ant-input-suffix {
      top: 38%;
    }

    .ant-input {
      height: auto;
    }
  }
`;

export const ColLeftStyles = {
  display: 'flex',
  justifyContent: 'space-between'
};

export const AvatarStyles = {
  marginInlineEnd: '10px',
  minWidth: '50px'
};

export const MobileHeaderGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const headerMobileIconSt: React.CSSProperties = {
  fontSize: 22,
  cursor: 'pointer',
  position: 'relative',
  top: 2
};
export const headerDesktopIconSt: React.CSSProperties = {
  fontSize: 28,
  cursor: 'pointer',
  position: 'relative',
  top: 2
};

export default {
  HeaderLogoTitle,
  HeaderUser,
  HeaderUserResponsive,
  HeaderSearch,
  HeaderContainer,
  ColLeftStyles,
  AvatarStyles,
  MobileHeaderGroup
};
