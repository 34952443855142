/**
 * @description Конвертирую объект в массив, для отображение в Select
 * @param {object} statuses
 * @return {ReportStatus[]}
 */
export const convertStatusesToArray = (statuses: object) => {
  let res = [];
  for (let value in statuses) {
    let note = {
      value,
      text: statuses[value]
    };
    res = [...res, note];
  }

  return res;
};
