import React from 'react';
import dayjs from 'dayjs';
import { Tag } from 'antd';
import { HistoryItem } from 'Admin/People/Teachers/styles';
import { dateFormat } from 'helpers';
import { Admin } from '../../../../../types';
import { useLanguageContext } from '../../../../../contexts/LanguageContext';

export interface NoteProps {
  description: string;
  date: string;
  admin: Admin;
}

/**
 * @description Компонент для типа text в collaboration history
 * @param {NoteProps} props - component props
 * @return {React.ReactNode}
 */
export const Note = (props: NoteProps) => {
  const [strings] = useLanguageContext();
  const { date, description, admin } = props;

  return (
    <HistoryItem>
      <h4>
        <Tag color="blue">{strings.NOTE}</Tag>
        <span style={{ marginInlineStart: 10 }}>{dayjs(date).format(dateFormat)}</span>
      </h4>
      <div dangerouslySetInnerHTML={{ __html: description }} />
      <span style={{ opacity: '.7' }}>
        {strings.ADMIN}: {admin?.user?.name} {admin?.user?.surname}
      </span>
    </HistoryItem>
  );
};

export default { Note };
