import React, { useEffect, useState } from 'react';
import { Input, notification, AutoComplete } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useSearchContext } from 'contexts/SearchContext';
import { BASE_SEARCH } from '../../routes';
import { useHistory } from 'react-router-dom';

const SEARCH_HISTORY_KEY = 'search_history';
const getSearchHistory = () => {
  const history = localStorage.getItem(SEARCH_HISTORY_KEY);
  return history ? JSON.parse(history) : [];
};

const saveSearchHistory = value => {
  let history = getSearchHistory();
  history = history.filter(item => item !== value);
  history.unshift(value);
  if (history.length > 5) {
    history = history.slice(0, 5);
  }
  localStorage.setItem(SEARCH_HISTORY_KEY, JSON.stringify(history));
};

/**
 * @description Компонент поиска
 * @param {object} props
 * @return {React.ReactNode}
 */
export const Search = () => {
  const [isWait, setIsWait] = useState<boolean>(false);
  const { push } = useHistory();
  const [strings] = useLanguageContext();
  const [options, setOptions] = useState([]);
  const { setQueryTerm, queryTerm, fetchSearchResults } = useSearchContext();

  const handleChange = (value: string): void => {
    setQueryTerm(value);
    const filteredOptions = getSearchHistory()
      .filter(item => item.toLowerCase().includes(value.toLowerCase()))
      .map(val => ({ value: val }));

    setOptions(filteredOptions);
  };

  const onSearch = (value: string): void => {
    // Проверка для тех кто захочет нажать интер более одного раза
    // Показывать подскадку один раз
    if (value.length < 3 && !isWait) {
      setIsWait(true);
      notification.error({
        message: strings.NOT_ENOUGH_CHARACTERS,
        description: strings.USE_3_OR_MORE_CHARACTERS_TO_SEARCH,
        duration: 2.5
      });

      setTimeout(() => {
        setIsWait(false);
      }, 2500);
      return;
    }

    if (value.length && value.length >= 3) {
      if (!window.location.href.includes(BASE_SEARCH)) {
        push(BASE_SEARCH);
      }

      fetchSearchResults(value, true);
      saveSearchHistory(value);
      setOptions(getSearchHistory().map(val => ({ value: val })));
    }
  };

  useEffect(() => {
    setOptions(getSearchHistory().map(value => ({ value })));
  }, []);

  return (
    <AutoComplete options={options} onSelect={onSearch} onSearch={setQueryTerm} value={queryTerm}>
      <Input.Search
        value={queryTerm}
        onChange={e => handleChange(e.target.value)}
        onSearch={onSearch}
        placeholder={strings.SEARCH}
      />
    </AutoComplete>
  );
};

export default { Search };
