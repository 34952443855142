import React, { useState, useEffect, useMemo } from 'react';
import { Select, Spin } from 'antd';
import { Admin } from 'types';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useAdminRepository } from 'repos/AdminRepository';
import debounce from 'lodash/debounce';

const { Option } = Select;

// Обобщенный тип, который создает подтип из User с заданными ключами
type Subset<T> = {
  [K in keyof T]?: T[K];
};

type AdminSubset = Subset<Admin>;

type AsyncSelectProps = {
  initOptions?: AdminSubset[];
  value?: number;
  placeholder?: string;
  onChange?: (value: number) => void;
  mode?: 'multiple' | 'tags';
  isAllBranchActive?: boolean;
};

const TutorSelect: React.FC<AsyncSelectProps> = ({
  value,
  initOptions,
  placeholder,
  onChange,
  mode,
  isAllBranchActive
}) => {
  const [strings] = useLanguageContext();
  const adminRepository = useAdminRepository();
  const [options, setOptions] = useState<AdminSubset[]>([]);
  const [hasLoading, setHasLoading] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [typing, setTyping] = useState<Boolean>(false);

  const fetchTutors = async (query: string): Promise<any> => {
    setSearchValue(query);
    if (query) {
      setHasLoading(true);
      const allBranchActive = isAllBranchActive ? 1 : 0;
      try {
        const {
          data: { admins },
          status
        } = await adminRepository.getAdminsByName(query, allBranchActive);
        if (status === 200) {
          setOptions(admins);
        }
      } catch {
      } finally {
        setHasLoading(false);
      }
    }
  };
  const getTutorById = async (value: string | number): Promise<any> => {
    try {
      const { data } = await adminRepository.getAdminById(value);
      setOptions([data]);
    } catch {}
  };

  const getTutorsByIds = async (value): Promise<any> => {
    const tutors = [];
    for (const tutorId of value) {
      try {
        const { data } = await adminRepository.getAdminById(tutorId);
        tutors.push(data);
      } catch {}
    }
    try {
      const results = await Promise.all(tutors);
      setOptions(results);
    } catch {}
  };

  useEffect(() => {
    if (initOptions) {
      setOptions(initOptions);
    }
  }, [initOptions]);

  useEffect(() => {
    if (value) {
      if (mode === 'multiple') {
        getTutorsByIds(value);
      } else {
        getTutorById(value);
      }
    }
  }, [JSON.stringify(value)]);

  useEffect(() => {
    if (searchValue) {
      setTyping(true);
    } else {
      setTyping(false);
    }
  }, [searchValue]);

  return (
    <Select
      showSearch
      value={value}
      onChange={val => onChange(val)}
      allowClear
      placeholder={placeholder}
      onSearch={debounce(fetchTutors, 800)}
      loading={hasLoading}
      mode={mode}
      maxTagCount="responsive"
      filterOption={false}
      notFoundContent={hasLoading ? <Spin size="small" /> : <p>{!typing ? strings.START_TYPING : strings.NOT_FOUND}</p>}
    >
      {options?.map(option => (
        <Option key={option.id} value={option.id}>
          {option.user.surname} {option.user.name}
        </Option>
      ))}
    </Select>
  );
};

export default TutorSelect;
