import React, { useMemo } from 'react';
import { httpService } from '../api/core';
import { AxiosPromise } from 'axios';
import { IGetAccessParams, ICreateAccessParams } from '../types/AdminAcess';

interface IUserAccessRepository {
  getObjectAccess: () => AxiosPromise;
  getAccess: (params?: IGetAccessParams) => AxiosPromise;
  getUserAccess: (userId: number) => AxiosPromise;
  createUserAccess: (params: ICreateAccessParams) => AxiosPromise;
  deleteUserAccess: (accessId: number) => AxiosPromise;
}

const UserAccessRepositoryContext = React.createContext<IUserAccessRepository | null>(null);

class UserAccessRepository implements IUserAccessRepository{
  getObjectAccess() {
    return httpService.get('/api/v1/access/objects', null, null, true);
  }

  getAccess(params?: IGetAccessParams) {
    return httpService.get('/api/v1/access/search', params, null, true);
  }

  getUserAccess(userId: number) {
    return httpService.get(`/api/v1/access/user/${userId}`, null, null, true);
  }

  createUserAccess(params: ICreateAccessParams) {
    return httpService.post('/api/v1/access/create', params, null, false, true);
  }

  deleteUserAccess(accessId: number) {
    return httpService.delete(`/api/v1/access/delete/${accessId}`, null, false, true);
  }
}

export const useUserAccessRepository = () => {
  const service = React.useContext(UserAccessRepositoryContext) as IUserAccessRepository;

  if (!service) {
    throw new Error('UserAccess repository is unavailable');
  }

  return service;
};

export const UserAccessRepositoryProvider = props => {
  const service = useMemo(() => new UserAccessRepository(), []);

  return <UserAccessRepositoryContext.Provider value={service}>{props.children}</UserAccessRepositoryContext.Provider>;
};
