import React, { useState } from 'react';
import { Modal, Select, Checkbox, Input, message, Row, Col, ColorPicker, Form } from 'antd';
import { Branch } from 'types';
import { ISupergroupPostParams, NewSuperGroupParams } from 'types/API/education';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useSupergroupRepository } from 'repos/SupergroupRepository';

const { Option } = Select;

interface AddSuperGroupModalProps {
  visible: boolean;
  hideModal: () => void;
  getSupergroups: () => Promise<any>;
}

/**
 * @description Добавление супергруппы
 * @param {AddSuperGroupModalProps} props
 * @return {React.ReactNode}
 */
export const AddSupergroupModal = (props: AddSuperGroupModalProps) => {
  const { visible, hideModal, getSupergroups } = props;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [colour, setColour] = useState<string>(null);
  const { branches } = useGlobalCollectionsContext();
  const [strings] = useLanguageContext();
  const supergroupRepository = useSupergroupRepository();

  /**
   * @description Добавление новой супергруппы
   * @param {NewSuperGroupParams} params
   * @return {Promise<any>}
   */
  const onSubmit = async (params: ISupergroupPostParams): Promise<any> => {
    setLoading(true);
    try {
      const { data } = await supergroupRepository.createSupergroup({ ...params, colour });
      message.success(`${strings.SUPERGROUP} ${data.name} ${strings.SUCESSFULLY_ADDED}`);
      hideModal();
      getSupergroups();
    } catch {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={strings.ADD_SUPERGROUP}
      open={visible}
      onCancel={hideModal}
      okText={strings.ADD_SUPERGROUP}
      onOk={() => form.submit()}
      okButtonProps={{ disabled: loading, loading }}
      width={400}
    >
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Row gutter={[10, 10]} align="middle">
          <Col xs={18} lg={18}>
            <Form.Item
              name="name"
              label={strings.NAME}
              rules={[{ required: true, message: `${strings.NAME} ${strings.IS_REQUIRED}` }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={6} lg={6}>
            <Form.Item label={strings.COLOR}>
              <ColorPicker
                allowClear
                value={colour}
                size="large"
                onClear={() => setColour(null)}
                onChange={(val, hex) => setColour(hex)}
              />
            </Form.Item>
          </Col>
          <Col xs={18} lg={18}>
            <Form.Item
              name="branch"
              label={strings.BRANCH}
              rules={[
                {
                  required: true,
                  message: `${strings.BRANCH} ${strings.IS_REQUIRED}`
                }
              ]}
            >
              <Select>
                {branches.map((branch: Branch) => (
                  <Option key={branch.id} value={branch.id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item name="isActive" valuePropName="checked" style={{ marginTop: 30 }}>
              <Checkbox>{strings.CURRENT}</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default { AddSupergroupModal };
