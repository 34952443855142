import React from 'react';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import { PreviousIcon, NextIcon } from './icons';
import { StCalendarWidget, WidgetHeader, WidgetDay, WidgetMonth, WidgetTime } from './styles';
import { useHistory } from 'react-router-dom';

interface CalendarWidgetProps {
  startAt: string;
  endAt: string;
  prevLessonId: number;
  nextLessonId: number;
}

/**
 * @description Виджет времени в календаре
 */
export const CalendarWidget = ({ startAt, endAt, prevLessonId, nextLessonId }: CalendarWidgetProps) => {
  if (!startAt || !endAt) {
    return null;
  }
  const { push } = useHistory();
  const year = dayjs(startAt).format('YYYY');
  const day = dayjs(startAt).format('D');
  const month = dayjs(startAt).format('MMM');
  const time = `${dayjs(startAt).format('HH:mm')} - ${dayjs(endAt).format('HH:mm')}`;

  const handleNavigate = (lessonId: number) => {
    push(`/le/${lessonId}`);
  };

  return (
    <StCalendarWidget>
      <WidgetHeader>{year}</WidgetHeader>
      <WidgetDay>{day}</WidgetDay>
      <WidgetMonth>{capitalize(month)}</WidgetMonth>
      <WidgetTime>{time}</WidgetTime>
      {prevLessonId && <PreviousIcon lessonId={prevLessonId} handleNavigate={handleNavigate} />}
      {nextLessonId && <NextIcon lessonId={nextLessonId} handleNavigate={handleNavigate} />}
    </StCalendarWidget>
  );
};

const CalendarWidgetMemo = React.memo(CalendarWidget);
export default CalendarWidgetMemo;