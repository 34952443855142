import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { PageHeader } from '@ant-design/pro-layout';
import { Button, Select, Input, Spin, Row, Col, Form, ColorPicker } from 'antd';
import { OriginalCourse } from 'types/courses';
import { useUserContext } from 'contexts/UserContext';
import { Group } from 'types/education';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container } from 'Global/GlobalStyle';
import { useHistory } from 'react-router-dom';
import { useGroupContext } from 'contexts/GroupContext';
import { BASE_COURSE_GROUP_ADD } from '../routes';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { useSupergroupRepository } from 'repos/SupergroupRepository';

const { Option } = Select;

/**
 * @description Генератор групп
 * @param {SupergroupGeneratorProps} props
 * @return {React.ReactNode}
 */
export const SupergroupsGenerator = () => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const { goBack, push } = useHistory();
  const [form] = Form.useForm();
  const [{ setSelectedOriginalCourse, setPrefixGenerateCourses, setNewSuperGroup }] = useGroupContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingOriginalCourses, setLoadingOriginalCourses] = useState<boolean>(false);
  const [originalCourses, setOriginalCourses] = useState<OriginalCourse[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [typing, setTyping] = useState<Boolean>(false);

  const [colour, setColour] = useState<string>(null);
  const coursesRepository = useCoursesRepository();
  const supergroupRepository = useSupergroupRepository();

  const fetchOriginalCourses = async (course: string) => {
    setSearchValue(course);
    if (course) {
      try {
        setLoadingOriginalCourses(true);
        const params = { title: course, isActive: 1 };
        const {
          data: { items }
        } = await coursesRepository.getOriginalCourses(params);
        setOriginalCourses(items);
      } catch {
        setOriginalCourses([]);
      } finally {
        setLoadingOriginalCourses(false);
      }
    }
  };

  const handleSubmit = async (fields): Promise<any> => {
    const { originalCourses, prefix } = fields;
    // Курсы которые нужно будет создать
    setSelectedOriginalCourse(originalCourses);
    setPrefixGenerateCourses(prefix);
    setLoading(true);

    // Создание новой супергруппы для дальнейшего присвоение этой группы новым группам
    try {
      const { data } = await supergroupRepository.createSupergroup({
        name: prefix,
        isActive: true,
        branch: user?.branch?.id,
        colour
      });
      setNewSuperGroup(data);
      push(BASE_COURSE_GROUP_ADD);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchValue) {
      setTyping(true);
    } else {
      setTyping(false);
    }
  }, [searchValue]);

  return (
    <Container>
      <PageHeader onBack={goBack} style={{ padding: 0 }} title={strings.ENTER_SUPERGROUP_PREFIX_BRANCH_LEVEL} />
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={[10, 10]}>
          <Col lg={8} xs={24}>
            <Form.Item
              name="prefix"
              label={strings.PREFIX}
              rules={[{ required: true, message: `${strings.PREFIX} ${strings.IS_REQUIRED}` }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={8} xs={24}>
            <Form.Item
              name="originalCourses"
              label={strings.ORIGINAL_COURSE}
              rules={[{ required: true, message: `${strings.ORIGINAL_COURSE} ${strings.IS_REQUIRED}` }]}
            >
              <Select
                showSearch
                allowClear
                mode="multiple"
                maxTagCount="responsive"
                placeholder={strings.ORIGINAL_COURSES}
                defaultActiveFirstOption={false}
                filterOption={false}
                onSearch={debounce(fetchOriginalCourses, 800)}
                style={{ width: '100%' }}
                notFoundContent={
                  loadingOriginalCourses ? (
                    <Spin size="small" />
                  ) : (
                    <p>{!typing ? strings.START_TYPING : strings.NOT_FOUND}</p>
                  )
                }
              >
                {originalCourses.map((course: Group) => (
                  <Option value={String(course.id)} key={course.id}>
                    {course.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={4}>
            <Form.Item label={strings.COLOR}>
              <ColorPicker
                allowClear
                value={colour}
                size="large"
                onClear={() => setColour(null)}
                onChange={(val, hex) => setColour(hex)}
              />
            </Form.Item>
          </Col>
          <Col lg={24} xs={24}>
            <Button size="large" htmlType="submit" loading={loading} type="primary">
              {strings.GENERATE}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default { SupergroupsGenerator };
