import React, { useCallback } from 'react';
import { Button, Modal, Row, Table, Tooltip } from 'antd';
import { getColumns } from './columns';
import { ITeacherGetParams, Teacher } from 'types/Teachers/teacher';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Link } from 'react-router-dom';
import { DollarOutlined, EditOutlined, LoginOutlined, MailOutlined } from '@ant-design/icons';
import { BASE_TEACHERS } from 'Admin/People/Teachers/routes';
import { BtnCreateAccount } from 'Global/components/BtnCreateAccount';
import { switchUserModal } from 'helpers/switchUser';
import { getCallPhoneButton, scrollTop } from 'helpers';
import styled from 'styled-components';
import { PaginationInfo } from 'types/global';
import Pagination from '../../Pagination';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';

const { Column } = Table;

const ButtonAction = styled(Button)`
  margin: 0px;
`;

interface TeacherWithAction extends Teacher {
  handleUserRefetch: () => Promise<any>;
}

interface TeachersTableProps {
  teachers: Teacher[];
  paginationInfo?: PaginationInfo;
  getTeachers?: (params?: ITeacherGetParams) => Promise<any>;
  handleUserRefetch: () => void;
}

/**
 * @description Таблица учителей
 * @param {object} props
 * @return {React.ReactNode}
 */
export const TeachersTable = React.memo(
  ({ teachers, paginationInfo, getTeachers, handleUserRefetch }: TeachersTableProps) => {
    const [user] = useUserContext();
    const [strings] = useLanguageContext();
    const [{ confirm }, contextHolder] = Modal.useModal();
    const { locationSearchParams } = useLocationSearchParams();

    const handlePage = useCallback((page: number) => {
      scrollTop();
      getTeachers({ ...locationSearchParams, page });
    }, []);

    const optionsCol = {
      title: '',
      dataIndex: 'teacher',
      key: 'actions',
      width: 280,
      render: (action: any, teacher: TeacherWithAction) => {
        const {
          id,
          user: { email, phone, name, surname, login, id: userId },
          loginAs,
          accessCreateAccount,
          handleUserRefetch
        } = teacher;

        const hasAdmin = user?.hasRoles?.admin;
        const hasAcademicExpert = user?.hasRoles?.academicExpert;

        return (
          <Row align="middle" style={{ gap: 8, flexWrap: 'nowrap' }}>
            {contextHolder}
            <Tooltip title={strings.EDIT}>
              <Link to={`${BASE_TEACHERS}/${teacher?.id}/edit`}>
                <ButtonAction icon={<EditOutlined />} />
              </Link>
            </Tooltip>
            {hasAdmin && loginAs && (
              <Tooltip placement="top" title={`${strings.LOGIN_AS} ${name} ${surname || ''}`}>
                <Button icon={<LoginOutlined />} onClick={() => switchUserModal(loginAs, confirm)} />
              </Tooltip>
            )}
            {hasAdmin && accessCreateAccount && (
              <BtnCreateAccount
                id={id}
                userId={userId}
                email={email}
                loginAs={loginAs}
                type="teacher"
                login={login}
                handleUserRefetch={handleUserRefetch}
              />
            )}
            {getCallPhoneButton(phone)}
            {email && (
              <Tooltip title={strings.SEND_E_MAIL}>
                <a href={`mailto:${email}`}>
                  <ButtonAction icon={<MailOutlined />} />
                </a>
              </Tooltip>
            )}
            {hasAdmin && !hasAcademicExpert && (
              <Link to={`/teachers/${id}?tab=finance`}>
                <Tooltip title={strings.FINANCE}>
                  <ButtonAction icon={<DollarOutlined />} />
                </Tooltip>
              </Link>
            )}
          </Row>
        );
      }
    };

    // Массив родителей с методом создания юзера внутри (для кнопки Create account)
    const teachersWithActions = teachers?.map((teacher: Teacher) => ({
      ...teacher,
      handleUserRefetch
    }));

    return (
      <>
        <Table dataSource={teachersWithActions} bordered={false} className="teachers-table" pagination={false}>
          {[...getColumns(user, strings), optionsCol]
            .filter(col => col)
            .map(col => (
              <Column {...col} />
            ))}
        </Table>
        {paginationInfo && (
          <Pagination count={paginationInfo?.pageCount} current={paginationInfo?.current} pageChange={handlePage} />
        )}
      </>
    );
  }
);

export default { TeachersTable };
