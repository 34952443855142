import React, { useMemo, useContext } from 'react';
import { httpService } from 'api/core';
import { IChildrenFilter, StudentParams } from 'types/Family';
import { IRepositoryProvider } from './RepositoryProvider';
import { ILessonOutcomesFilterParams } from 'types/Student';

const StudentRepositoryContext = React.createContext<IStudentRepository>(null);
interface IStudentRepository {
  getStudentById: (id: number | string) => Promise<any>;
  getStudents: (params: IChildrenFilter) => Promise<any>;
  getExamineTypes: () => Promise<any>;
  createStudent: (params: StudentParams) => Promise<any>;
  editStudent: (childId: number | string, params: StudentParams) => Promise<any>;
  getChildGroups: (childId: number | string) => Promise<any>;
  getLessonOutcomesByStudentId: (childId: number | string, params?: ILessonOutcomesFilterParams) => Promise<any>;
  getCollaborationHistory: (childId: number | string, params?: { start?: string; end?: string }) => Promise<any>;
  createCollaborationHistory: (
    childId: number | string,
    params: { description: string; visibleToTeacher: number }
  ) => Promise<any>;
  getAcademicYearsByStudent: (id: number | string) => Promise<any>;
}

class StudentRepository implements IStudentRepository {
  getStudentById(id: number | string) {
    return httpService.get(`/api/v1/children/${id}`, null, null, true);
  }

  getStudents(params: IChildrenFilter) {
    return httpService.get('/api/v1/children', params, null, true);
  }

  getExamineTypes() {
    return httpService.get('/api/v1/children/examine_types', null, null, true);
  }

  createStudent(params: StudentParams) {
    return httpService.post(`/api/v1/user/child`, params, null, null, true);
  }

  editStudent(childId: number | string, params: StudentParams) {
    return httpService.put(`/api/v1/user/child/${childId}`, params, null, null, true);
  }

  getChildGroups(childId: number | string) {
    return httpService.get(`/api/v1/children/${childId}/groups`, null, null, true);
  }

  getLessonOutcomesByStudentId(childId: number | string, params?: ILessonOutcomesFilterParams) {
    return httpService.get(`/api/v1/children/${childId}/lesson-outcomes`, params, null, true);
  }

  getCollaborationHistory(childId: number | string, params?: { start?: string; end?: string }) {
    return httpService.get(`/api/v1/children/${childId}/history`, params, null, true);
  }

  createCollaborationHistory(childId: number | string, params: { description: string; visibleToTeacher: number }) {
    return httpService.post(`/api/v1/children/${childId}/contacts`, params, null, null, true);
  }

  getAcademicYearsByStudent(id: string | number) {
    return httpService.get(`/api/v1/children/${id}/academic-years`, null, null, true);
  }
}

export const useStudentRepository = () => {
  const service = useContext(StudentRepositoryContext);

  if (!service) {
    throw new Error('Student repository is unavailable');
  }

  return service;
};

export const StudentRepositoryProvider: React.FC<IRepositoryProvider> = ({ children }) => {
  const service = useMemo(() => new StudentRepository(), []);

  return <StudentRepositoryContext.Provider value={service}>{children}</StudentRepositoryContext.Provider>;
};
