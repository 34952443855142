import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { pickBy } from 'lodash';
import { Select, Button, Table, Row, Col, Checkbox, message, Form, Flex, Switch } from 'antd';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';
import { getColumnsForTutors } from './columns';
import { READY_FOR_SENDING_STATUS } from 'Admin/Monitoring/ProgressReport/constants';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { StageSelect } from 'Global/components/FormComponents';
import { FilterButtons } from 'Global/components/FilterButtons';
import { useUserContext } from 'contexts/UserContext';
import { FormItemWithoutMargin } from 'Global/styles';
import { ProgressReportSendParams } from 'types/Reports';

const { Option } = Select;

const StyledSpan = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
`;

export const ProgressReportsWithSuperGroup = () => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const [form] = Form.useForm();
  const hasTopManager = user?.hasRoles?.topManager;
  const reportsRepository = useProgressReportsRepository();
  const [selectedBranch, setSelectedBranch] = useState<number>();
  const [progressReportsWithSuperGroups, setProgressReportsWithSuperGroups] = useState([]);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasShowReadyForSending, setHasShowReadyForSending] = useState<boolean>(false);
  const { branches } = useGlobalCollectionsContext();
  const initVal = {
    branch: user?.branch?.id
  };
  const onSelectChange = selectedRowKeys => setSelectedRowKeys(selectedRowKeys);

  /**
   * Получение списка репортов с супергруппами
   * @param params
   */
  const getProgressReportsWithSuperGroups = async (params?: ProgressReportSendParams) => {
    try {
      setHasLoading(true);
      const { data } = await reportsRepository.getProgressReportsWithSuperGroups(params);
      setProgressReportsWithSuperGroups(data);
      setHasLoading(false);
    } catch {
      setHasLoading(false);
      setProgressReportsWithSuperGroups([]);
      message.error(strings.GET_REPORTS_ERROR_TRY_AGAIN_LATER);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  /**
   * Отправка email
   * @param selectedRowKeys
   */
  const onSendEmails = async selectedRowKeys => {
    try {
      await reportsRepository.sendProgressReportsWithSuperGroups({ supergroups: selectedRowKeys });
      message.success(strings.SUCCESSFULLY_SENT, 2);
      setSelectedRowKeys([]);

      const params = pickBy(form.getFieldsValue());
      await getProgressReportsWithSuperGroups(params);
    } catch {
      message.error(strings.SEND_ERROR_TRY_AGAIN_LATER, 2);
      setSelectedRowKeys([]);
    }
  };

  const handleSubmit = async (values): Promise<any> => {
    const params = pickBy(values);
    try {
      await getProgressReportsWithSuperGroups(params);
    } catch {}
  };

  const clearFilter = async (): Promise<any> => {
    form.resetFields();
    await getProgressReportsWithSuperGroups(form.getFieldsValue());
  };

  useEffect(() => {
    setSelectedBranch(user?.branch?.id);
    getProgressReportsWithSuperGroups(initVal);
    form.setFieldsValue(initVal);
  }, [user]);

  const selectedSuperGroupsCount = selectedRowKeys.length;
  const filteredReport = useMemo(() => {
    if (hasShowReadyForSending) {
      return progressReportsWithSuperGroups.filter(report => report.status === READY_FOR_SENDING_STATUS);
    }
    return progressReportsWithSuperGroups;
  }, [hasShowReadyForSending, progressReportsWithSuperGroups]);

  return (
    <>
      <Form form={form} onFinish={handleSubmit} initialValues={initVal}>
        <Row align="middle" gutter={[10, 10]} style={{ marginBottom: 20 }}>
          <Col xs={hasTopManager ? 24 : 0} lg={hasTopManager ? 6 : 0}>
            <FormItemWithoutMargin name="branch" label={strings.BRANCH} hidden={!hasTopManager}>
              <Select placeholder={strings.BRANCH} allowClear>
                {branches.map(branch => (
                  <Option value={branch.id} key={branch.id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            </FormItemWithoutMargin>
          </Col>
          <Col xs={24} lg={6}>
            <FormItemWithoutMargin name="stage">
              <StageSelect branch={selectedBranch} />
            </FormItemWithoutMargin>
          </Col>
          <FilterButtons handleClear={clearFilter} isSameRow={true} />
        </Row>
      </Form>
      <Flex justify="space-between" align="center" style={{ marginBottom: 10 }}>
        <Flex align="center" gap={10}>
          <Button
            danger
            size="large"
            disabled={selectedRowKeys.length === 0}
            onClick={() => onSendEmails(selectedRowKeys)}
          >
            {strings.SEND_EMAILS_TO_SELECTED_SG}
          </Button>
          <StyledSpan>
            {strings.SELECTED_SUPERGROUPS} {selectedSuperGroupsCount}
          </StyledSpan>
        </Flex>
        <Flex gap={10} align="baseline">
          <span>{strings.SHOW_READY_FOR_SENDING}</span>
          <Switch
            checkedChildren={strings.YES}
            unCheckedChildren={strings.NO}
            onChange={() => {
              setHasShowReadyForSending(prevState => !prevState);
            }}
          />
        </Flex>
      </Flex>
      <Table
        loading={hasLoading}
        rowSelection={rowSelection}
        dataSource={filteredReport}
        columns={getColumnsForTutors(onSendEmails)}
        rowKey={record => record?.supergroup?.id}
        bordered={false}
        pagination={false}
      />
    </>
  );
};
