import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, Button, Row, message } from 'antd';
import { OriginalCoursesTable } from './Table';
import { Filter } from './Filter';
import { OriginalCourse, OriginalCoursesParams } from 'types/courses';
import { PaginationInfo } from 'types/global';
import { mockPagination } from 'Global/constants';
import { ORIGINAL_COURSE_NEW } from 'Admin/Courses/routes';
import { CreateFromExcelButton } from 'Admin/Courses/OriginalCourses/CreateFromExcelButton';
import queryString from 'query-string';
import { isEmpty } from 'lodash';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { convertBooleanToNumber, downloadSomeFile } from 'helpers';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { DownloadOutlined } from '@ant-design/icons';

const { Content } = Layout;

interface OriginalCoursesProps {}

export const OC_LIMIT: number = 50;
const queryStringOptions = { arrayFormat: 'bracket' as 'bracket' };

export const OriginalCourses = (props: OriginalCoursesProps) => {
  const [strings] = useLanguageContext();
  const { branches } = useGlobalCollectionsContext();
  const { locationSearchParams } = useLocationSearchParams();
  const [originalCourses, setOriginalCourses] = useState<OriginalCourse[]>([]);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>(mockPagination);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasTemplateLoading, setHasTemplateLoading] = useState<boolean>(false);
  const coursesRepository = useCoursesRepository();

  const { push, goBack } = useHistory();

  const defParams = { isActive: 1, limit: OC_LIMIT };

  /**
   * @description Получается список оригинальных курсов
   * @param {OriginalCoursesParams} params
   * @return {Promise<any>}
   */
  const fetchOriginalCourses = async (params: OriginalCoursesParams): Promise<any> => {
    setLoading(true);
    try {
      const values = convertBooleanToNumber(params);
      values['limit'] = OC_LIMIT;
      const { data } = await coursesRepository.getOriginalCourses(values);
      setOriginalCourses(data.items);
      setPaginationInfo(data.pagination);
      setLoading(false);
      push({ search: queryString.stringify(values, queryStringOptions) });
    } catch {}
  };

  const handleDownloadTemplate = async (): Promise<any> => {
    try {
      setHasTemplateLoading(true);
      await downloadSomeFile(`/api/v1/originalcourses/template`, null, null, null, true);
      message.success(strings.EXCEL_FILE_HAS_BEEN_DOWNLOADED, 2);
    } catch {
      message.error(strings.SORRY_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN);
    } finally {
      setHasTemplateLoading(false);
    }
  };

  useEffect(() => {
    if (branches.isNotEmpty()) {
      if (isEmpty(locationSearchParams)) {
        fetchOriginalCourses(defParams);
      } else {
        fetchOriginalCourses(locationSearchParams);
      }
    }
  }, [branches]);

  return (
    <Content>
      <Container>
        <StPageHeader
          onBack={goBack}
          title={strings.ORIGINAL_COURSES}
          extra={[
            <Button key="1" type="primary" onClick={() => push(ORIGINAL_COURSE_NEW)}>
              {strings.ADD}
            </Button>,
            <CreateFromExcelButton key="2" originalCourseId={null} getOriginalCourseById={null} />,
            <Button key="3" loading={hasTemplateLoading} icon={<DownloadOutlined />} onClick={handleDownloadTemplate}>
              {strings.EXCEL_TEMPLATE}
            </Button>
          ]}
        >
          <Filter fetchOriginalCourses={fetchOriginalCourses} />
        </StPageHeader>
        <OriginalCoursesTable
          originalCourses={originalCourses}
          paginationInfo={paginationInfo}
          loading={loading}
          fetchOriginalCourses={fetchOriginalCourses}
          hasActionColumn={true}
        />
      </Container>
    </Content>
  );
};

export default { OriginalCourses };
