import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, DatePicker, Button, message } from 'antd';
import dayjs from 'dayjs';
import { Courses } from 'api/Courses';
import { dateFormat, dateFormatForBackend } from 'helpers/dates';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { CourseGroup } from 'types/education';
import { useLanguageContext } from 'contexts/LanguageContext';

interface GroupModalProps extends FormComponentProps {
  id: number;
  visible: boolean;
  student: number;
  group: CourseGroup;
  setShowGroups: (bool: boolean) => void;
  hideModal: () => void;
  fetchGroupsById: () => void;
}

/**
 * @description Редактирование группы
 * @param {GroupModalProps} props
 * @return {React.ReactNode}
 */
const GroupModalTemplate = (props: GroupModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    form: { getFieldDecorator, resetFields, validateFields },
    hideModal,
    id,
    student,
    visible,
    setShowGroups,
    fetchGroupsById,
    group
  } = props;

  const groupId = group.id ?? '';
  const [strings] = useLanguageContext();

  /**
   * @description Отправка формы
   * @param {React.FormEvent<EventTarget>} e
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();

    validateFields(async (err, fields) => {
      if (!err) {
        const { startDate, endDate } = fields;

        const startAt = startDate ? dayjs(startDate).format(dateFormatForBackend) : '';
        const endAt = endDate ? dayjs(endDate).format(dateFormatForBackend) : '';

        setShowGroups(false);
        setLoading(true);

        await Courses.editGroupStudent(id, student, {
          startAt,
          endAt
        })
          .then(({ status }) => {
            if (status === 200) {
              fetchGroupsById();
              setShowGroups(true);
              hideModal();
              resetFields();
              message.config({ top: 120 });
              message.success(strings.DATE_SUCCESSFULLY_CHANGED);
            }
          })
          .finally(() => setLoading(false));
      }
    });
  };

  // Закрытие модалки
  const handleCloseModal = () => {
    hideModal();
    resetFields();
  };

  const getFooter = () => {
    const buttons = [
      <Button key="backBtn" onClick={handleCloseModal}>
        {strings.CANCEL}
      </Button>,
      <Button
        type="primary"
        htmlType="submit"
        disabled={loading}
        loading={loading}
        onClick={handleSubmit}
        key="updateBtn"
      >
        {strings.UPDATE}
      </Button>
    ];

    return buttons;
  };

  return (
    (<Modal
      title={`${strings.EDIT_GROUP} ${groupId}`}
      open={visible}
      footer={getFooter()}
      onCancel={() => handleCloseModal()}
      key={groupId}
    >
      <Form layout="vertical">
        <MyFormItem label={strings.START_DATE}>
          {getFieldDecorator('startDate', {
            initialValue: group?.startDate && dayjs(group?.startDate)
          })(<DatePicker format={dateFormat} placeholder={strings.START_DATE} />)}
        </MyFormItem>
        <MyFormItem label={strings.END_DATE}>
          {getFieldDecorator('endDate', {
            initialValue: group?.endDate && dayjs(group?.endDate)
          })(<DatePicker format={dateFormat} placeholder={strings.END_DATE} />)}
        </MyFormItem>
      </Form>
    </Modal>)
  );
};

export const GroupModal = Form.create<GroupModalProps>({})(GroupModalTemplate);

export default { GroupModal };
