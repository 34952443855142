import * as family from 'Global/routes';
import * as admins from 'Admin/People/Administrators/routes';
import * as teachers from 'Admin/People/Teachers/routes';
import * as courses from 'Admin/Courses/routes';
import * as education from 'Admin/Education/routes';
import * as sms from 'Admin/Website/SMS/routes';
import * as email from 'Admin/Website/Mailing/routes';
import * as monitoring from 'Admin/Monitoring/routes';
import * as schedule from 'Global/modules/Schedule/routes';
import * as parentRoutes from 'Student&Parent/routes';
import * as website from 'Admin/Website/routes';
import { NEW_PROFILE_FAMILY } from 'Global/routes';

export const enBreadcrumbNameMap = {
  [admins.BASE_ADMINS]: 'Admins',
  [admins.BASE_ADMIN]: 'Admin',
  [admins.BASE_ADMIN_EDIT]: 'Edit',
  [admins.ADMIN_ADD]: 'Add',
  [schedule.SCHEDULE]: 'Schedule',
  [family.BASE_FAMILIES]: 'Families',
  [family.BASE_FAMILY]: 'Family',
  [family.BASE_PEOPLE]: 'Family',
  [family.EDIT_FAMILY_CONTRACT]: 'Edit contract',
  [family.BASE_STUDENT]: 'Student',
  [family.BASE_PAYER]: 'Payer',
  [family.EDIT_FAMILY]: 'Edit family',
  [teachers.BASE_TEACHERS]: 'Teachers',
  [teachers.BASE_TEACHER]: 'Teacher',
  [teachers.BASE_TEACHER_ADD]: 'Add',
  [teachers.BASE_TEACHER_EDIT]: 'Edit',
  [teachers.ADD_INDIVIDUAL_WAGE]: 'Create individual wage',
  [courses.BASE_COURSE_GROUP_ADD]: 'Add',
  [courses.BASE_COURSE_GROUP]: 'Group course',
  [courses.BASE_ORIGINAL_COURSES]: 'Original Courses',
  [courses.ORIGINAL_COURSE]: 'Original Course',
  [courses.ORIGINAL_COURSE_EDIT]: 'Edit',
  [courses.ORIGINAL_COURSE_NEW]: 'New',
  [courses.CHOOSE_ORIGINAL_COURSE]: 'Choose',
  [courses.BASE_SUPER_GROUPS]: 'Supergroups',
  [courses.BASE_PLACEMENT]: 'Placement',
  [courses.BASE_ORIGINAL_COURSES_UNITS]: 'Original courses units',
  [courses.BASE_ORIGINAL_COURSES_UNITS_NEW]: 'New',
  [courses.LESSON]: 'Lesson',
  [education.BASE_TEACHING_MATERIALS]: 'Teaching Materials',
  [education.BASE_MATERIALS]: 'Materials',
  [education.BASE_SOURCE_OF_REFERENCE]: 'Source of reference',
  [education.BASE_TRAININGS]: 'Training',
  [sms.BASE_SMS]: 'Sms',
  [sms.SHOW_HISTORY]: 'History',
  [email.BASE_EMAIL]: 'Email',
  [email.SHOW_HISTORY]: 'History',
  [monitoring.BASE_REPORT_TEACHERS]: 'Report Teachers',
  [monitoring.BASE_LESSONS]: 'Lessons',
  [monitoring.BASE_FEEDBACK_MANAGEMENT]: 'Feedback management',
  [monitoring.BASE_GRADING]: 'Grading',
  [monitoring.BASE_PROGRESS_REPORT]: 'Progress Report',
  [monitoring.EDIT_PROGRESS_REPORT]: 'Progress Report Edit',
  [monitoring.BASE_MARKETING_EVENTS]: 'Marketing Events',
  [monitoring.ADD_MARKETING_EVENTS]: 'Add',
  [monitoring.BASE_MESSAGES_FROM_PARENTS]: 'Messages from parents',
  [monitoring.BASE_SUMMARY_TABLE]: 'Summary Table',
  [monitoring.BASE_SUMMARY_CALENDAR]: 'Summary Calendar',
  [monitoring.BASE_REPORT_MANUAL_INPUT]: 'Manual input',
  [monitoring.BASE_INVOICE_MONTH_SYSTEM]: 'Invoice month system',
  [monitoring.BASE_INVOICES_LOGS]: 'Invoice logs',
  [monitoring.BASE_REGISTERS_LOGS]: 'Register logs',
  [monitoring.BASE_FINANCE_FAMILIES]: 'Finance Families',
  [monitoring.BASE_FINANCE_TEACHERS]: 'Finance Teachers',
  [monitoring.BASE_REPORT_STUDENTS_PAYING]: 'Student paying',
  [`${monitoring.BASE_MESSAGES_FROM_PARENTS}/:id`]: 'Thread',
  [parentRoutes.BASE_FINANCE]: 'Finance',
  [parentRoutes.JOURNAL]: 'Register',
  [parentRoutes.NEWS]: 'News',
  [parentRoutes.CALENDAR]: 'Event&Holidays',
  [parentRoutes.UPLOAD_HOMEWORK]: 'Homework',
  [parentRoutes.HOW_TO_LG_LINK]: 'How to LGEG',
  [parentRoutes.BASE_REPORT_ON_PROGRESS]: 'Report on progress',
  [website.SCHEDULE_CHAGES]: 'Timetable updates',
  [website.SERVICE_RATES]: 'Service rates',
  [NEW_PROFILE_FAMILY]: 'New family profile',
  [monitoring.ASSISTANTS_COMMENTS]: "Assistant's comments",
  [monitoring.BASE_ASSISTANTS_FEEDBACK]: 'Assistants` feedback',
  [monitoring.PROGRESS_REPORT_GENERATOR]: 'Progress report generator'
};

export const ruBreadcrumbNameMap = {
  [admins.BASE_ADMINS]: 'Администраторы',
  [admins.BASE_ADMIN]: 'Администратор',
  [admins.BASE_ADMIN_EDIT]: 'Редактирование',
  [admins.ADMIN_ADD]: 'Добавить',
  [schedule.SCHEDULE]: 'Расписание',
  [family.BASE_FAMILIES]: 'Семьи',
  [family.BASE_FAMILY]: 'Семья',
  [family.BASE_PEOPLE]: 'Семья',
  [family.EDIT_FAMILY_CONTRACT]: 'Редактирование контракта',
  [family.BASE_STUDENT]: 'Студент',
  [family.BASE_PAYER]: 'Payer',
  [family.EDIT_FAMILY]: 'Редактировать семью',
  [teachers.BASE_TEACHERS]: 'Учителя',
  [teachers.BASE_TEACHER]: 'Учитель',
  [teachers.BASE_TEACHER_ADD]: 'Добавить',
  [teachers.BASE_TEACHER_EDIT]: 'Редактирование',
  [teachers.ADD_INDIVIDUAL_WAGE]: 'Create individual wage',
  [courses.BASE_COURSE_GROUP_ADD]: 'Добавить',
  [courses.BASE_COURSE_GROUP]: 'Курс группы',
  [courses.BASE_ORIGINAL_COURSES]: 'Курсы оригиналы',
  [courses.CHOOSE_ORIGINAL_COURSE]: 'Выбрать',
  [courses.BASE_SUPER_GROUPS]: 'Supergroups',
  [courses.BASE_PLACEMENT]: 'Placement',
  [courses.BASE_ORIGINAL_COURSES_UNITS]: 'Уроки курсов оригиналов',
  [courses.BASE_ORIGINAL_COURSES_UNITS_NEW]: 'Новый',
  [courses.ORIGINAL_COURSE]: 'Курс',
  [courses.ORIGINAL_COURSE_EDIT]: 'Редактирование',
  [courses.ORIGINAL_COURSE_NEW]: 'Добавить',
  [courses.LESSON]: 'Урок',
  [education.BASE_TEACHING_MATERIALS]: 'Обучающие материалы',
  [education.BASE_MATERIALS]: 'Материалы',
  [education.BASE_SOURCE_OF_REFERENCE]: 'Источник ссылки',
  [education.BASE_TRAININGS]: 'Тренинги',
  [sms.BASE_SMS]: 'СМС',
  [sms.SHOW_HISTORY]: 'История',
  [email.BASE_EMAIL]: 'Email',
  [email.SHOW_HISTORY]: 'История',
  [monitoring.BASE_REPORT_TEACHERS]: 'Report Teachers',
  [monitoring.BASE_LESSONS]: 'Уроки',
  [monitoring.BASE_FEEDBACK_MANAGEMENT]: 'Управление обратной связью',
  [monitoring.BASE_GRADING]: 'Grading',
  [monitoring.BASE_PROGRESS_REPORT]: 'Отчеты',
  [monitoring.EDIT_PROGRESS_REPORT]: 'Редактирование отчетов',
  [monitoring.BASE_MARKETING_EVENTS]: 'Marketing Events',
  [monitoring.ADD_MARKETING_EVENTS]: 'Добавить',
  [monitoring.BASE_MESSAGES_FROM_PARENTS]: 'Сообщения от родителей',
  [monitoring.BASE_SUMMARY_TABLE]: 'Summary Table',
  [monitoring.BASE_SUMMARY_CALENDAR]: 'Summary Calendar',
  [monitoring.BASE_REPORT_MANUAL_INPUT]: 'Manual input',
  [monitoring.BASE_INVOICE_MONTH_SYSTEM]: 'Invoice month system',
  [monitoring.BASE_INVOICES_LOGS]: 'Invoice logs',
  [monitoring.BASE_REGISTERS_LOGS]: 'Register logs',
  [monitoring.BASE_FINANCE_TEACHERS]: 'Финансы учителя',
  [monitoring.BASE_REPORT_STUDENTS_PAYING]: 'Student paying',
  [`${monitoring.BASE_MESSAGES_FROM_PARENTS}/:id`]: 'Тред',
  [parentRoutes.BASE_FINANCE]: 'Финансы',
  [parentRoutes.JOURNAL]: 'Журнал',
  [parentRoutes.CALENDAR]: 'Мероприятия LG',
  [parentRoutes.NEWS]: 'Новости',
  [parentRoutes.UPLOAD_HOMEWORK]: 'Домашнее задание',
  [parentRoutes.HOW_TO_LG_LINK]: 'How to LGEG',
  [parentRoutes.BASE_REPORT_ON_PROGRESS]: 'Отчет об успеваемости',
  [website.SCHEDULE_CHAGES]: 'Обновления в расписании',
  [website.SERVICE_RATES]: 'Service rates',
  [monitoring.ASSISTANTS_COMMENTS]: "Assistant's comments",
  [monitoring.BASE_ASSISTANTS_FEEDBACK]: 'Assistants` feedback',
  [monitoring.PROGRESS_REPORT_GENERATOR]: 'Progress report generator'
};

export default { enBreadcrumbNameMap, ruBreadcrumbNameMap };
