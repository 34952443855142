import React, { useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  DownloadOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  PlusOutlined,
  LeftOutlined,
  RightOutlined,
  LoginOutlined
} from '@ant-design/icons';
import { Tag, Button, message, Modal, Select, Tooltip, Col, Avatar, Flex } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import {
  StyledCardDetailsItem,
  StyledCardDetails,
  StyledCollapsePanel,
  StyledCollapseBtn,
  StyledCollapseAvatar,
  StyledCard
} from 'Global/styles';
import { useUserContext } from 'contexts/UserContext';
import { Admin, Subject } from 'types';
import dayjs from 'dayjs';
import {
  AMI_5_LOCATION,
  BACKEND_LOCATION,
  dateFormat,
  downloadFileByUrl,
  getAttachmentLink,
  getAvatarUrl
} from 'helpers';
import { CardTitle } from 'Global/components/CardTitle';
import { Teacher as ITeacher, TeacherAttachment, TeacherTag } from 'types/Teachers/teacher';
import { Teachers } from 'api';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useTeacherRepository } from 'repos/TeacherRepository';
import { useHistory } from 'react-router-dom';
import * as family from '../routes';
import { scrollTop } from 'helpers/scroll';
import { QuickTips } from 'Global/components/QuickTips';
import { QUICK_TIPS_NEW_ACCOUNT } from 'Global/constants';
import { switchUserModal } from '../../../../helpers/switchUser';

const { Option } = Select;

enum CurrentSubjects {
  Main,
  Additional
}

export function TeacherProfile(props: { teacher: ITeacher; updateTeacher: () => Promise<any> }) {
  const { teacher, updateTeacher } = props;
  const [strings] = useLanguageContext();
  const teacherRepository = useTeacherRepository();
  const { push } = useHistory();
  const [user, , , userAccess] = useUserContext();
  const hasAccessIsInvoiceUnlimitedAccess = user?.isInvoiceUnlimitedAccess;
  const { teacherTags: teacherTagsList, subjects } = useGlobalCollectionsContext();
  const hasTeacher = user?.hasRoles?.teacher;
  const hasAcademicExpert = user?.hasRoles?.academicExpert;
  const hasTeacherAndAcademic = hasTeacher || hasAcademicExpert;
  const hasRiga = user?.branch?.id === 5;
  const [{ confirm }, contextHolder] = Modal.useModal();

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const {
    id,
    status,
    cv,
    nda,
    teacherTag,
    prefix1c,
    additionalSubjects,
    mainSubjects,
    approvedByJuliaAudio,
    audio,
    showAudio,
    hideJuliaComment,
    tutor,
    experts,
    mentor,
    mainPhoto,
    contract,
    loginAs
  } = teacher || {};
  const { branch, email, birthDate, org1Id } = teacher?.user || {};
  const [hasHideJuliaComment, setHasHideJuliaComment] = useState<boolean>(hideJuliaComment);

  const [hasCollapsed, setHasCollapsed] = useState<boolean>(false);
  const [hasShowTagsModal, setHasShowTagsModal] = useState<boolean>(false);
  const [hasShowSubjectsModal, setHasShowSubjectsModal] = useState<boolean>(false);
  const [subject, setSubject] = useState<number>(0);
  const [selectedTeacherTag, setSelectedTeacherTag] = useState<number>(0);
  const [currentSubjects, setCurrentSubjects] = useState<CurrentSubjects>(CurrentSubjects.Main);

  /**
   * @description Добавление тега к учителю
   * @return {Promise<any>}
   */
  const addTeacherTag = async (): Promise<any> => {
    message.loading(strings.TAG_ADDED, 2);
    try {
      const { status } = await Teachers.addTeacherTag(id, { tag: selectedTeacherTag });
      if (status === 200) {
        message.success(strings.TAG_ADDED_SUCCESSFULLY, 2);
        setHasShowTagsModal(false);
        await updateTeacher();
      }
    } catch {
      message.error(strings.TAG_ADDED_ERROR, 2);
    }
  };

  const onDeleteTeacher = () => {
    confirm({
      title: strings.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE_TEACHER_YOU_WON_T_BE_ABLE_TO_RECOVER_THIS_INFORMATION,
      okType: 'danger',
      onOk: async () => {
        try {
          await teacherRepository.deleteTeacher(id);
          message.success(strings.TEACHER_SUCCESSFULLY_DELETED, 2);
          push('/teachers');
        } catch {
          message.error(strings.TEACHER_DELETION_ERROR_TRY_AGAIN_LATER, 2);
        }
      }
    });
  };

  /**
   * @description Удаление Main Subject
   * @param {number} subjectId
   * @return {Promise<any>}
   */
  const deleteMainSubject = async (subjectId: number): Promise<any> => {
    message.loading(strings.SUBJECT_DELETED, 2);
    try {
      const { status } = await Teachers.deleteMainSubjectFromTeacher(id, subjectId);
      if (status === 200) {
        message.success(strings.SUBJECT_DELETED_SUCCESSFULLY, 2);
        updateTeacher();
      }
    } catch {}
  };

  /**
   * @description Удаление Main Subject
   * @param {number} subjectId
   * @return {Promise<any>}
   */
  const deleteAdditionalSubject = async (subjectId: number): Promise<any> => {
    message.loading(strings.SUBJECT_DELETED, 2);
    try {
      const { status } = await Teachers.deleteAdditionalSubjectFromTeacher(id, subjectId);
      if (status === 200) {
        message.success(strings.SUBJECT_DELETED_SUCCESSFULLY, 2);
        updateTeacher();
      }
    } catch {}
  };

  /**
   * @description Удаление тега у учителя
   * @param {number} tagId
   * @return {Promise<any>}
   */
  const deleteTeacherTag = async (tagId: number): Promise<any> => {
    message.loading(strings.TAG_DELETED, 2);
    try {
      const { status } = await Teachers.deleteTeacherTag(id, tagId);
      if (status === 200) {
        message.success(strings.TAG_DELETED_SUCCESSFULLY, 2);
        updateTeacher();
      }
    } catch {
      message.error(strings.TAG_DELETED_ERROR, 2);
    }
  };

  /**
   * @description Добавление Subject к учителю
   * @return {Promise<any>}
   */
  const handleAddSubject = async (): Promise<any> => {
    let request;
    message.loading(strings.SUBJECT_ADDED, 2);
    if (currentSubjects === CurrentSubjects.Main) {
      request = Teachers.addMainSubjectToTeacher(id, { subject });
    } else if (currentSubjects === CurrentSubjects.Additional) {
      request = Teachers.addAdditionalSubjectToTeacher(id, { subject });
    }
    try {
      const { status } = await request;
      if (status === 200) {
        message.success(strings.SUBJECT_ADDED_SUCCESSFULLY, 2);
        setHasShowSubjectsModal(false);
        updateTeacher();
      }
    } catch {
      message.error(strings.SUBJECT_ADDED_ERROR, 2);
    }
  };

  /**
   * @description Установление тегов
   * @param {number} tag
   * @return {void}
   */
  const handleTagsTeacher = (tag: number): void => {
    setSelectedTeacherTag(tag);
  };

  /**
   * @description Удаление тегов
   * @param {number} tag
   * @return {Promise<any>}
   */
  const handleDeleteTag = async (tag: number): Promise<any> => {
    await deleteTeacherTag(tag);
  };

  /**
   * @description Удаление main subject
   * @param {number} subject
   * @return {Promise<any>}
   */
  const handleDeleteMainSubject = async (subject: number): Promise<any> => {
    await deleteMainSubject(subject);
  };

  /**
   * @description Удаление additional subject
   * @param {number} subject
   * @return {Promise<any>}
   */
  const handleDeleteAdditionalSubject = async (subject: number): Promise<any> => {
    await deleteAdditionalSubject(subject);
  };

  /**
   * @description Установление subjects
   * @param {number} subject
   * @return {void}
   */
  const handleSubjectsTeacher = (subject: number): void => {
    setSubject(subject);
  };

  /**
   * @description Установление additional subject
   * @return {void}
   */
  const handleAddAdditionalSubject = (): void => {
    setHasShowSubjectsModal(true);
    setCurrentSubjects(CurrentSubjects.Additional);
  };

  /**
   * @description Добавление main subject
   * @return {void}
   */
  const handleAddMainSubject = (): void => {
    setHasShowSubjectsModal(true);
    setCurrentSubjects(CurrentSubjects.Main);
  };

  /**
   * @description Скрыть/показать аудиокомментарий
   * @return {void}
   */
  const handleChangeJuliaComment = async (): Promise<any> => {
    await Teachers.hideJuliaComment(id)
      .then(({ data: { hideComment } }) => {
        setHasHideJuliaComment(hideComment);
      })
      .catch(() => {});
  };

  /**
   * @description Список прикреплённых файлов
   * @param {TeacherAttachment[]} items
   * @return {void}
   */
  const getAttachments = items => {
    return items.map((item: TeacherAttachment) => {
      const link: string = getAttachmentLink(item.id);
      return (
        <Button
          style={{ margin: 5, maxWidth: '100%' }}
          onClick={() => downloadFileByUrl(link, item.name)}
          icon={<DownloadOutlined />}
          key={item.id}
        >
          <span
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              verticalAlign: 'bottom',
              maxWidth: '90%'
            }}
          >
            {item.name}
          </span>
        </Button>
      );
    });
  };
  const extraBtns = useMemo(
    () => (
      <Flex gap={5} align="center">
        {loginAs && <Button icon={<LoginOutlined />} onClick={() => switchUserModal(loginAs, confirm)} />}
        <Button icon={<EditOutlined />} onClick={() => push(`${family.BASE_TEACHERS}/${id}/edit`)} />
      </Flex>
    ),
    [teacher]
  );

  return (
    <>
      {contextHolder}
      {!isMobile && hasCollapsed && (
        <Col style={{ position: 'relative', opacity: 0.8 }}>
          <div style={{ position: 'sticky', top: 20 }}>
            <Avatar src={getAvatarUrl(mainPhoto)} size={60}>
              {teacher?.user?.name?.charAt(0)}
              {teacher?.user?.surname?.charAt(0)}
            </Avatar>
            <Button
              shape="circle"
              icon={<RightOutlined />}
              size="small"
              style={{
                position: 'absolute',
                ...StyledCollapseAvatar
              }}
              onClick={() => {
                setHasCollapsed(!hasCollapsed);
                scrollTop();
              }}
            />
          </div>
        </Col>
      )}
      {!hasCollapsed && (
        <Col flex="400px">
          <Modal
            title={strings.ADD_TEACHER_TAG}
            open={hasShowTagsModal}
            onCancel={() => setHasShowTagsModal(false)}
            footer={null}
          >
            <Select placeholder={strings.SELECT_TAG} onChange={handleTagsTeacher}>
              {teacherTagsList.map((tag: TeacherTag) => (
                <Option value={tag.id} key={tag.id}>
                  {tag.name}
                </Option>
              ))}
            </Select>
            <Button type="primary" icon={<PlusOutlined />} onClick={addTeacherTag}>
              {strings.ADD_TAG}
            </Button>
          </Modal>
          <Modal
            title={strings.ADD_SUBJECT_TO_TEACHER}
            open={hasShowSubjectsModal}
            onCancel={() => setHasShowSubjectsModal(false)}
            footer={
              <Button type="primary" icon={<PlusOutlined />} onClick={handleAddSubject}>
                {strings.ADD_SUBJECT}
              </Button>
            }
          >
            <Select placeholder={strings.SELECT_SUBJECT} onChange={handleSubjectsTeacher}>
              {subjects.map((subjects: Subject) => (
                <Option value={subjects.id} key={subjects.id}>
                  {subjects.name}
                </Option>
              ))}
            </Select>
          </Modal>
          <div>
            <StyledCollapsePanel style={{ ...(isMobile && { flexDirection: 'row-reverse' }) }}>
              {!hasTeacherAndAcademic && extraBtns}
              {!hasRiga && <QuickTips url={QUICK_TIPS_NEW_ACCOUNT} />}
              <StyledCollapseBtn>
                <span>{strings.MAIN_INFORMATION}</span>
                {!isMobile && (
                  <>
                    {!hasCollapsed ? (
                      <LeftOutlined onClick={() => setHasCollapsed(!hasCollapsed)} />
                    ) : (
                      <RightOutlined onClick={() => setHasCollapsed(!hasCollapsed)} />
                    )}
                  </>
                )}
              </StyledCollapseBtn>
            </StyledCollapsePanel>
            <StyledCard>
              <CardTitle {...teacher?.user} previewAvatar={getAvatarUrl(mainPhoto)} />
              <StyledCardDetails>
                {status && (
                  <StyledCardDetailsItem>
                    <b>{strings.STATUSES}: </b>
                    <span>{status.name}</span>
                  </StyledCardDetailsItem>
                )}
                {!hasTeacher && branch && (
                  <StyledCardDetailsItem>
                    <b>{strings.BRANCH}:</b>
                    <span>{branch.name}</span>
                  </StyledCardDetailsItem>
                )}
                <StyledCardDetailsItem>
                  <Flex justify="space-between">
                    <b>{strings.MAIN_SUBJECTS}: </b>
                    {!hasTeacher && (
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        size="small"
                        onClick={handleAddMainSubject}
                        style={{ fontSize: 14 }}
                      />
                    )}
                  </Flex>
                  {mainSubjects && (
                    <div style={{ textAlign: 'left' }}>
                      {mainSubjects.map((subject: Subject) => (
                        <Tag
                          color="volcano"
                          style={{ margin: '5px 5px 5px 0' }}
                          closable={!hasTeacher}
                          key={subject.id}
                          onClose={() => handleDeleteMainSubject(subject.id)}
                        >
                          {subject.name}
                        </Tag>
                      ))}
                    </div>
                  )}
                </StyledCardDetailsItem>
                <StyledCardDetailsItem>
                  <Flex justify="space-between">
                    <b>{strings.ADDITIONAL_SUBJECTS}: </b>
                    {!hasTeacher && (
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        size="small"
                        style={{ fontSize: 14 }}
                        onClick={handleAddAdditionalSubject}
                      />
                    )}
                  </Flex>
                  {additionalSubjects && (
                    <div style={{ textAlign: 'left' }}>
                      {additionalSubjects.map((subject: Subject) => (
                        <Tag
                          color="green"
                          style={{ margin: '5px 5px 5px 0' }}
                          closable={!hasTeacher}
                          key={subject.id}
                          onClose={() => handleDeleteAdditionalSubject(subject.id)}
                        >
                          {subject.name}
                        </Tag>
                      ))}
                    </div>
                  )}
                </StyledCardDetailsItem>
                <StyledCardDetailsItem>
                  <Flex justify="space-between">
                    <b>{strings.TAGS}:</b>
                    {!hasTeacher && (
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        size="small"
                        onClick={() => setHasShowTagsModal(true)}
                        style={{ fontSize: 14 }}
                      />
                    )}
                  </Flex>
                  <div style={{ textAlign: 'left' }}>
                    {teacherTag.map((tag: TeacherTag) => (
                      <Tag
                        color="blue"
                        style={{ margin: '5px 5px 5px 0' }}
                        closable={!hasTeacher}
                        key={tag?.id}
                        onClose={() => handleDeleteTag(tag?.id)}
                      >
                        {tag?.name}
                      </Tag>
                    ))}
                  </div>
                </StyledCardDetailsItem>
                {birthDate && (
                  <StyledCardDetailsItem>
                    <b>{strings.DATE_OF_BIRTH}:</b>
                    <span>{birthDate ? dayjs(birthDate).format(dateFormat) : ''}</span>
                  </StyledCardDetailsItem>
                )}
                {email && (
                  <StyledCardDetailsItem>
                    <b>{strings.E_MAIL}:</b>
                    {email}
                  </StyledCardDetailsItem>
                )}
                {prefix1c && (
                  <StyledCardDetailsItem>
                    <b>{strings.PREFIX_1_C}:</b>
                    {prefix1c}
                  </StyledCardDetailsItem>
                )}
                {org1Id && (
                  <StyledCardDetailsItem>
                    <b>{strings.ORG_1_ID}:</b>
                    {org1Id}
                  </StyledCardDetailsItem>
                )}
                {tutor && (
                  <StyledCardDetailsItem>
                    <b>{strings.ADM}:</b>
                    {tutor?.user?.name} {tutor?.user?.surname}
                  </StyledCardDetailsItem>
                )}
                {mentor && (
                  <StyledCardDetailsItem>
                    <b>{strings.MENTOR}:</b>
                    {mentor?.user?.name} {mentor?.user?.surname}
                  </StyledCardDetailsItem>
                )}
                {experts?.isNotEmpty() && (
                  <StyledCardDetailsItem>
                    <b>{strings.EXPERTS}:</b>
                    {experts.map((expert: Admin, index) => (
                      <span key={expert.id}>
                        {expert?.user?.name} {expert?.user?.surname}
                        {index + 1 < experts.length ? ', ' : ''}
                      </span>
                    ))}
                  </StyledCardDetailsItem>
                )}
                {hasAccessIsInvoiceUnlimitedAccess && contract && (
                  <StyledCardDetailsItem>
                    <b>{strings.CONTRACT}:</b>
                    {contract && contract?.name}
                  </StyledCardDetailsItem>
                )}
                {cv && cv?.isNotEmpty() && userAccess?.teacherAccessInfo && (
                  <StyledCardDetailsItem>
                    <b>{strings.DOWNLOAD_CV_S}</b>
                    <div style={{ textAlign: 'left' }}>{getAttachments(cv)}</div>
                  </StyledCardDetailsItem>
                )}
                {nda && nda?.isNotEmpty() && userAccess?.teacherAccessInfo && (
                  <StyledCardDetailsItem>
                    <b>{strings.DOWNLOAD_NDA_S}</b>
                    <div style={{ textAlign: 'left' }}>{getAttachments(nda)}</div>
                  </StyledCardDetailsItem>
                )}
                {(approvedByJuliaAudio || audio) && (
                  <StyledCardDetailsItem>
                    <div style={{ marginBottom: 10 }}>
                      <b>{strings.COMMENT_BY_JULIA}:</b>
                      {user?.canHideJuliaComment && (
                        <Tooltip title={hasHideJuliaComment ? strings.SHOW_COMMENT : strings.HIDE_FROM_EVERYONE}>
                          <Button
                            style={{ fontSize: 14 }}
                            icon={hasHideJuliaComment ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                            size="small"
                            onClick={handleChangeJuliaComment}
                          />
                        </Tooltip>
                      )}
                    </div>
                    {approvedByJuliaAudio && (
                      <audio
                        style={{ width: '100%' }}
                        controls={true}
                        src={`${BACKEND_LOCATION}/upload/peoplesContacts/${approvedByJuliaAudio}`}
                      />
                    )}
                    {audio && showAudio && (
                      <audio style={{ width: '100%' }} src={`${AMI_5_LOCATION}${audio}`} controls={true} />
                    )}
                  </StyledCardDetailsItem>
                )}
                <div style={{ textAlign: 'right', marginTop: 20 }}>
                  {teacher?.isCanDeleted ? (
                    <Button onClick={onDeleteTeacher} danger>
                      {strings.DELETE_TEACHER}
                    </Button>
                  ) : (
                    <Tooltip
                      title={
                        strings.YOU_CANT_DELETE_THIS_TEACHER_THERE_ARE_SOME_LESSONS_IN_THE_SCHEDULE_AND_FINANCE_OPERATIONS
                      }
                    >
                      <Button disabled danger>
                        {strings.DELETE_TEACHER}
                      </Button>
                    </Tooltip>
                  )}
                </div>
              </StyledCardDetails>
            </StyledCard>
          </div>
        </Col>
      )}
    </>
  );
}
