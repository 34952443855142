import { useCallback } from 'react';
import { message, Modal } from 'antd';
import { pickBy, identity } from 'lodash';
import { Courses } from 'api/Courses';
import { ILessonPostRarams, ILessonRegister, ILessonTask } from 'types/lesson';
import { FormInstance } from 'antd/lib/form';
import { useLessonRepository } from 'repos/LessonRepository';
import { registersFormat } from 'helpers/registerFormat';
import { INFO_TAB, REGISTER_TAB } from '../constants';

interface UseSaveLessonProps {
  lessonInfoForm: FormInstance;
  outcomesForm: FormInstance;
  id: string | number;
  task?: ILessonTask;
  registers: ILessonRegister[];
  registerUpdatedAt?: string;
  maxTaskGrade: number;
  maxHwGrade: number;
  hasLessonOutcomesChanged: boolean;
  getLessonById: (id: string | number) => Promise<void>;
  activeTab: string;
  onChangeActiveTab: (tab: string) => void;
  setHasLessonOutcomesChanged: (val: boolean) => void;
  setHasRegistersFetch: (val: boolean) => void;
  setHasSaveLoading: (val: boolean) => void;
  setHasLessonsInfoLoading: (val: boolean) => void;
  setHasRegistersLoading: (val: boolean) => void;
  strings: any;
}

export const useSaveLesson = ({
  lessonInfoForm,
  outcomesForm,
  id: lessonId,
  task,
  registers,
  registerUpdatedAt,
  maxTaskGrade,
  maxHwGrade,
  hasLessonOutcomesChanged,
  getLessonById,
  activeTab,
  onChangeActiveTab,
  setHasLessonOutcomesChanged,
  setHasRegistersFetch,
  setHasSaveLoading,
  setHasLessonsInfoLoading,
  setHasRegistersLoading,
  strings
}: UseSaveLessonProps) => {
  const [{ confirm }, contextHolder] = Modal.useModal();
  const lessonRepository = useLessonRepository();

  const onSaveLesson = useCallback(
    async (ignoreUpdated: boolean): Promise<void> => {
      try {
        const lessonInfoValues = await lessonInfoForm.validateFields();
        setHasLessonsInfoLoading(true);
        setHasSaveLoading(true);
        setHasRegistersLoading(true);

        try {
          //если не открывали вкладку Registers и ничего там не меняли, то ignoreUpdated, тк он относится только к Registers
          const params = pickBy(
            {
              ...lessonInfoValues,
              status: 'done',
              ignoreUpdated: true
            },
            identity
          ) as ILessonPostRarams;

          if (registers?.length > 0) {
            params['registers'] = registersFormat(registers);
            params['updatedAt'] = registerUpdatedAt;
            params['homeworkMaxGrade'] = maxHwGrade;
            params['ignoreUpdated'] = ignoreUpdated;
          }

          const {
            data: { success },
            status
          } = await lessonRepository.editLessonById(lessonId, params);

          if (hasLessonOutcomesChanged) {
            const outcomesValues = outcomesForm.getFieldsValue();
            try {
              await lessonRepository.editOutcomesByLesson(lessonId, outcomesValues);
            } catch {
            } finally {
              setHasLessonOutcomesChanged(false);
            }
          }

          if (task) {
            try {
              await Courses.updateLessonTask(task.id, { maxGrade: maxTaskGrade, theme: task?.topic });
            } catch {}
          }

          if (status === 200 && success) {
            message.success(strings.THE_LESSON_WAS_SUCCESSFULLY_EDITED);
            if (registers?.length > 0) {
              setHasRegistersFetch(true);
            }
            await getLessonById(lessonId);
          }
        } catch (error) {
          const { status, data } = error;
          if (status === 400) {
            confirm({
              title: strings.DO_YOU_WANT_TO_SAVE_THE_CURRENT_CHANGES,
              content: data.message,
              okText: strings.SAVE,
              okType: 'danger',
              async onOk() {
                await onSaveLesson(true);
              }
            });
          } else {
            message.error(data?.message, 2);
          }
        }
      } catch (errorInfo) {
        if (activeTab === REGISTER_TAB) {
          onChangeActiveTab(INFO_TAB);
        }
      } finally {
        setHasSaveLoading(false);
        setHasLessonsInfoLoading(false);
        setHasRegistersLoading(false);
      }
    },
    [
      lessonInfoForm,
      task,
      maxTaskGrade,
      hasLessonOutcomesChanged,
      outcomesForm,
      lessonId,
      registers,
      registerUpdatedAt,
      maxHwGrade
    ]
  );

  return {
    onSaveLesson,
    contextHolder
  };
};
