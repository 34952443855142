import { httpService } from 'api/core';

/**
 * @description ...
 * @param {object} params
 * @return {Promise<any>}
 */
export const sendFormDistribution = (params: object): Promise<any> => httpService.post('/api/sms', params);

/**
 * @description ...
 * @param {number} id
 * @return {Promise<any>}
 */
export const getSmsSessionById = (id: number): Promise<any> => httpService.get(`/api/sms/${id}/session`);

/**
 * @description ...
 * @params {number} page
 * @return {Promise<any>}
 */
export const getSmsSessionsList = (page?: number): Promise<any> => httpService.get(`/api/sms/sessions?page=${page}`);

export const Sms = {
  sendFormDistribution,
  getSmsSessionById,
  getSmsSessionsList
};

export default { Sms };
