import { message } from 'antd';
import { AMI_5_LOCATION, BACKEND_LOCATION } from './index';
import queryString from 'query-string';
import { dispositionFilename } from './dispositionFilename';
import { storageService } from 'contexts/StorageContext';
import { isEmpty } from 'lodash';

/**
 * @description Функция для скачки файла
 * @param {string} dataUrl
 * @param {string} fileName
 * @return {boolean}
 */
export const downloadFile = (dataUrl: string, fileName: string, openNewWindow?: boolean) => {
  const a = document.createElement('a');
  a.href = dataUrl;
  if (openNewWindow) {
    a.setAttribute('target', '_blank');
  }

  a.setAttribute('download', fileName);
  a.click();
  return false;
};

/**
 * @description Скачка файла по урл
 * @param {string} url
 * @param {object} params
 * @param {string} fileName
 */
export const downloadFileByUrl = async (url: string, fileName: string, params?: any): Promise<any> => {
  let token = storageService.getAccessToken();
  await fetch(`${url}?${queryString.stringify(params)}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(res => res.blob())
    .then(blob => {
      if (blob) {
        const fileURL = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
        downloadFile(fileURL, fileName);
      } else {
        message.error('Could not download file. :(', 2);
      }
    })
    .catch(() => {});
};

export const downloadSomeFile = async (
  url: string,
  customFilename?: string | null,
  params?: any,
  setLoadingPercentage?: (loadingPercentage: number) => any,
  isAmiFive: boolean = false
): Promise<any> => {
  let token = storageService.getAccessToken();

  try {
    const requestParams = {
      method: isEmpty(params) || !params ? 'GET' : 'POST',
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    };

    if (params && !isEmpty(params)) {
      requestParams['body'] = JSON.stringify(params);
    }

    const backendLocation = isAmiFive ? AMI_5_LOCATION : BACKEND_LOCATION;
    const response = await fetch(`${backendLocation}${url}`, requestParams);

    const disposition = response.headers.get('content-disposition');
    const contentType = response.headers.get('content-type').split(';')[0];
    // Возьмем кастомное название файла, если оно определенно, иначе берем из заголовка ответа content-disposition
    const filename = customFilename ? customFilename : dispositionFilename(disposition);
    const { status } = response;

    if (response.status === 422) {
      const responseJson = await response.json();
      message.error(responseJson.message);

      return;
    }

    if (status === 200) {
      let receivedLength = 0;
      let chunks = [];
      const reader = await response.body.getReader();
      const contentLength = +response.headers.get('Content-Length');

      // Получаем отрывками файл, считаем проценты скачки
      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          break;
        }
        chunks.push(value);
        receivedLength += value.length;

        // Проверку необходимо убрать, после того как во всех функциях будет прокинут этот аргумент
        // + сделать setLoadingPercentage обязательным аргументом
        if (setLoadingPercentage) {
          setLoadingPercentage(Math.floor((receivedLength / contentLength) * 100));
        }
      }

      // Из скаченных чанков, формируем блоб, скачиваем файл
      const blob = new Blob(chunks, { type: contentType });

      if (blob) {
        const fileURL = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
        downloadFile(fileURL, filename);
      } else {
        message.error('Could not download file. :(', 2);
      }
    } else if (status === 404) {
      message.error('File not found', 3);
    }
  } catch (error) {
    if (error.hasOwnProperty('response')) {
      message.error(error.response.data?.message, 3);
    }
  }
};

/**
 * @description Формирование ссылки на скачку ресурса
 * @param {number} attachmentId
 * @return {Promise<any>}
 */
export const getAttachmentLink = (attachmentId: number): string =>
  `${AMI_5_LOCATION}/api/v1/attachment/download/${attachmentId}`;

/**
 * @description Получить ID материала по URL (material.path)
 * @param {string} url
 * @returns {number}
 */
export const getAttachmentIdByUrl = (url: string): number => parseInt(url.match(/\d+/)[0], 10);
