import { useState, useEffect, useCallback } from 'react';
import { InvoiceListItem } from 'types/invoice';
import { message } from 'antd';
import { useUserContext } from 'contexts/UserContext';
import { hasMoscowBranch } from 'helpers';
import { useInvoicesRepository } from 'repos/InvoicesRepository';
import { invoiceStatus } from '../Global/constants';

export const useFamilyInvoices = (id: number) => {
  const [user] = useUserContext();
  const [isLoadInvoices, setIsLoadInvoices] = useState<boolean>(false);
  const [invoicesList, setInvoicesList] = useState<InvoiceListItem[]>();
  const invoicesRepository = useInvoicesRepository();

  /**
   * @description Получение списка инвойсов по id семьи
   * @param {number} id
   * @return {Promise<any>}
   */
  const fetchInvoicesByFamilyId = async (): Promise<any> => {
    try {
      setIsLoadInvoices(true);
      const { data, status } = await invoicesRepository.getInvoicesByFamilyId(id);
      if (status === 200) {
        // Для Мск: Если роль родитель, то ему показываем только инвойсы в статусе pending или paid
        if (user?.hasRoles?.parent && hasMoscowBranch(user?.branch)) {
          setInvoicesList(
            data.filter(invoice => invoice.status === invoiceStatus.pending || invoice.status === invoiceStatus.paid)
          );
        } else {
          setInvoicesList(data);
        }
        setIsLoadInvoices(false);
      }
    } catch {
      // TODO Локализовать
      message.error('Get invoices error :(, try again later');
      setIsLoadInvoices(false);
    }
  };

  const refetchInvoices = useCallback(() => {
    fetchInvoicesByFamilyId();
  }, []);

  useEffect(() => {
    fetchInvoicesByFamilyId();
  }, [id]);

  return { invoicesList, isLoadInvoices, refetchInvoices };
};
