import React from 'react';
import dayjs from 'dayjs';
import { dateFormat } from 'helpers/dates';
import { Supergroup } from 'types';
import { CollapseSupergroups } from './CollapseSupergroups';
import { IWeeklyEmailItem } from '../../../types/Mailing';

export const getColumns = strings => [
  {
    title: strings.DATE,
    dataIndex: 'sendAt',
    key: 'sendAt',
    defaultSortOrder: 'descend' as 'descend',
    sorter: (a, b) => dayjs(a?.sendAt).diff(dayjs(b?.sendAt)),
    render: (date: string) => date && dayjs(date).format(dateFormat)
  },
  {
    title: strings.TITLE,
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: strings.SUPERGROUPS,
    dataIndex: 'supergroups',
    key: 'supergroups',
    className: 'clmn-no-padding',
    render: (supergroups: Supergroup[], row: IWeeklyEmailItem) => {
      return <CollapseSupergroups supergroups={supergroups} mailingId={row.id} />;
    }
  },
  {
    title: 'Invoices',
    dataIndex: 'invoices',
    key: 'invoices',
    render: (invoices: boolean) => (invoices ? 'Yes' : 'No')
  }
];

export default { getColumns };
