import React, { useState } from 'react';
import styled from 'styled-components';
import { Progress } from 'antd';
import { downloadSomeFile } from 'helpers';
import { getLessonAttachmentPreview } from 'Global/components/FilePreview/helpers';
import { useLanguageContext } from 'contexts/LanguageContext';
import { EyeOutlined, LoadingOutlined } from '@ant-design/icons';

const spanAttachStyle = {
  color: '#1890ff',
  fontSize: 14,
  fontWeight: 500,
  cursor: 'pointer'
};

const StyledProgress = styled(Progress)`
  margin-top: 10px;
`;

/**
 * @description Вывод имени аттачмента + прогресса скачки + сеттинг данных для модалки preview
 */
export const AttachmentName = ({
  attachment,
  setPreviewUrl,
  currentAttachment,
  setCurrentAttachment,
  setHasShowPreviewModal,
  setHasLoadPreviewAttachment
}) => {
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
  const [hasDownloadingFile, setHasDownloadingFile] = useState<boolean>(false);
  const [loadingPercentage, setLoadingPercentage] = useState<number>(0);
  const [strings] = useLanguageContext();

  const iconProps = {
    onClick: () => handleShowModal(attachment.id, attachment.name),
    style: { fontSize: 18, margin: '5px 10px 0 0', cursor: 'pointer' }
  };

  /**
   * @description Скачка прикрепленного материала
   * @param {number} fileId
   * @param {string} fileName
   * @return {React.ReactNode}
   */
  const downloadAttachment = async (fileId: number, fileName: string): Promise<any> => {
    setHasDownloadingFile(true);
    try {
      await downloadSomeFile(`/api/v1/attachment/download/${fileId}`, fileName, undefined, setLoadingPercentage, true);
    } finally {
      setHasDownloadingFile(false);
    }
  };

  /**
   * @description Превью в модалке
   * @param {number} attachmentId
   * @param {string} attachmentName
   * @return {Promise<any>}
   */
  const handleShowModal = async (attachmentId: number, attachmentName: string): Promise<any> => {
    setCurrentAttachment({
      id: attachmentId,
      name: attachmentName
    });
    setHasLoadPreviewAttachment(true);
    setIsLoadingFile(true);
    await getLessonAttachmentPreview(attachmentId, setPreviewUrl, setHasShowPreviewModal, strings);
    setHasLoadPreviewAttachment(false);
    setIsLoadingFile(false);
  };

  const equalAttachment = currentAttachment.id === attachment.id;
  const hasShowProgress = loadingPercentage !== 0 && loadingPercentage !== 100;

  const getAttachmentName = () => (attachment.hasOwnProperty('displayName') ? attachment.displayName : attachment.name);

  return (
    <>
      <span
        key={attachment.id}
        style={spanAttachStyle}
        onClick={() => {
          !hasDownloadingFile && downloadAttachment(attachment.id, attachment.name);
        }}
      >
        {getAttachmentName()}
        {hasShowProgress && <StyledProgress percent={loadingPercentage} status="active" />}
      </span>
      {attachment.isPreview && equalAttachment && (
        <>{isLoadingFile ? <LoadingOutlined {...iconProps} /> : <EyeOutlined {...iconProps} />}</>
      )}
    </>
  );
};
