import React from 'react';
import dayjs from 'dayjs';
import { DownloadOutlined } from '@ant-design/icons';
import { Tag, Button, Flex } from 'antd';
import { Link } from 'react-router-dom';
import { Branch, LessonTopic, Material, Stage, Subject } from 'types';
import { downloadSomeFile } from 'helpers';
import { dateTimeFormat } from 'helpers/dates';

export const getColumns = (hasTopManager: boolean, strings) => [
  {
    title: strings.LESSON_TOPIC_ID,
    dataIndex: 'lessonTopic',
    key: 'id',
    width: 100,
    render: (lessonTopic: LessonTopic) => lessonTopic?.id
  },
  {
    title: strings.LESSON_TOPIC,
    dataIndex: 'lessonTopic',
    key: 'lessonTopic',
    render: (lessonTopic: LessonTopic) =>
      hasTopManager ? <Link to={`/le/${lessonTopic.id}`}>{lessonTopic.name}</Link> : lessonTopic.name
  },
  {
    title: strings.MATERIALS,
    dataIndex: 'materialsName',
    key: 'materialsName',
    width: 300,
    render: (materialsName: string, material: Material) => {
      const { materialAttachmentId } = material;
      const donwloadMaterial = async () => {
        await downloadSomeFile(`/api/v1/attachment/download/${materialAttachmentId}`, materialsName, null, null, true);
      };

      return (
        <Flex align="center" gap={10}>
          <Button onClick={donwloadMaterial} icon={<DownloadOutlined />} size="small" type="primary" />
          <span>{materialsName}</span>
        </Flex>
      );
    }
  },
  {
    title: strings.TYPE,
    dataIndex: 'type',
    key: 'type',
    width: 100,
    render: (type: string) => <Tag>{type}</Tag>
  },
  {
    title: strings.UPLOADED_WHEN,
    dataIndex: 'uploadedWhen',
    key: 'uploadedWhen',
    sorter: true,
    render: (uploadedWhen: string) => uploadedWhen && dayjs(uploadedWhen).format(dateTimeFormat)
  },
  {
    title: strings.BRANCH,
    dataIndex: 'branch',
    key: 'branch',
    render: (branch: Branch) => branch?.name
  },
  {
    title: strings.SUBJECT,
    dataIndex: 'subject',
    key: 'subject',
    render: (subject: Subject) => subject?.name
  },
  {
    title: strings.STAGE,
    dataIndex: 'stage',
    key: 'stage',
    render: (stage: Stage) => stage?.name
  },
  {
    title: strings.GROUP_COURSE,
    dataIndex: 'groupCourse',
    key: 'groupCourse'
  }
];

export default { getColumns };
