import React, { useMemo } from 'react';
import { GetFieldDecoratorOptions } from '@ant-design/compatible/lib/form/Form';
import { ReportBranches } from 'types/Reports';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Col, DatePicker, Row, Select } from 'antd';
import { Branch } from 'types';
import { dateFormat } from 'helpers';
import dayjs, {Dayjs} from 'dayjs';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
const { Option } = Select;

interface BranchDateFieldsProps {
  getFieldDecorator<T extends Object = {}>(
    id: keyof T,
    options?: GetFieldDecoratorOptions
  ): (node: React.ReactNode) => React.ReactNode;
  getFieldValue(fieldName: string): any;
  reportBranches: ReportBranches[];
}

export const BranchDateFields = ({ getFieldDecorator, reportBranches, getFieldValue }: BranchDateFieldsProps) => {
  const [strings] = useLanguageContext();
  const { branches } = useGlobalCollectionsContext();
  const getFinishDate: Dayjs = useMemo(() => {
    const branch = getFieldValue('branch');
    const reportBranch = reportBranches.find(item => item?.branch.id === branch);
    return reportBranch ? dayjs(reportBranch.finishDate) : undefined;
  }, [getFieldValue('branch')]);

  return (
    <Row gutter={[6, 6]}>
      <Col lg={12}>
        <MyFormItem label={strings.BRANCH}>
          {getFieldDecorator('branch')(
            <Select placeholder={strings.BRANCH}>
              <Option value={undefined}>&nbsp;</Option>
              {branches.map((branch: Branch) => (
                <Option value={branch.id} key={branch.id}>
                  {branch.name}
                </Option>
              ))}
            </Select>
          )}
        </MyFormItem>
      </Col>
      <Col lg={12}>
        <MyFormItem label={strings.DATE}>
          {<DatePicker disabled value={getFinishDate} placeholder="-" format={dateFormat} />}
        </MyFormItem>
      </Col>
    </Row>
  );
};
export default BranchDateFields;
