import React, { useEffect, useState } from 'react';
import { Row, Button, Col, Select, Form, Tag } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { ICommentaryCalendarParams } from 'types/commentary';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { Subject } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import StageSelect from 'Global/components/FormComponents/StageSelect';
import { FormItemWithoutMargin } from 'Global/styles';
import { StudentSelect, SupergroupSelect, TutorSelect } from 'Global/components/FormComponents';
import { NumberOfComments, ReportTrimester, StatusNewReport } from 'types/Reports';
import { FilterDate } from '../styles';
import dayjs from 'dayjs';
import { dateFormat } from 'helpers';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';
import { FilterButtons } from 'Global/components/FilterButtons';
import { useUserContext } from 'contexts/UserContext';

const { Option } = Select;

const numberOfCommentsOptions: NumberOfComments[] = ['0', '1', '2+'];

export const statusOptions = [
  { value: StatusNewReport.new, label: StatusNewReport[StatusNewReport.new] },
  { value: StatusNewReport.checked, label: StatusNewReport[StatusNewReport.checked] },
  { value: StatusNewReport.sent, label: StatusNewReport[StatusNewReport.sent] }
];

interface FilterProps {
  defValues: ICommentaryCalendarParams;
  onFilter: (params: ICommentaryCalendarParams) => Promise<any>;
  selectedRowKeys: number[];
  handleBulkSelect: () => void;
}

export const Filter: React.FC<FilterProps> = ({ defValues, onFilter, selectedRowKeys, handleBulkSelect }) => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const hasTopManager = user?.hasRoles?.topManager;
  const [form] = Form.useForm();
  const reportsRepository = useProgressReportsRepository();
  const { locationSearchParams } = useLocationSearchParams();
  const { branch } = locationSearchParams || {};

  const { branches, subjects } = useGlobalCollectionsContext();

  const [selectedBranch, setSelectedBranch] = useState<number>(branch);
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);
  const [trimesters, setTrimesters] = useState<ReportTrimester[]>([]);
  const [hasTrimesterLoading, setHasTrimesterLoading] = useState<boolean>(false);

  const extraBtn = selectedRowKeys.length !== 0 && (
    <Button danger size="large" onClick={handleBulkSelect}>
      {strings.SEND}
    </Button>
  );

  const handleClear = async (): Promise<any> => {
    onFilter(defValues);
  };

  const handleSubmit = async (values: ICommentaryCalendarParams): Promise<any> => {
    try {
      setHasSubmitLoading(true);
      await onFilter(values);
    } finally {
      setHasSubmitLoading(false);
    }
  };
  const fetchTrimesters = async (): Promise<any> => {
    setHasTrimesterLoading(true);
    try {
      const { data } = await reportsRepository.getTrimesters();
      setTrimesters(data);
    } catch {
    } finally {
      setHasTrimesterLoading(false);
    }
  };
  useEffect(() => {
    fetchTrimesters();
  }, []);

  useEffect(() => {
    form.setFieldsValue(locationSearchParams);
    const { branch } = locationSearchParams;
    setSelectedBranch(branch);
  }, [locationSearchParams]);

  return (
    <Form form={form} onFinish={handleSubmit} layout="vertical">
      <Row gutter={[10, 10]}>
        <Col xs={hasTopManager ? 24 : 0} lg={hasTopManager ? 6 : 0}>
          <FormItemWithoutMargin name="branch" hidden={!hasTopManager}>
            <Select placeholder={strings.BRANCH} allowClear onChange={val => setSelectedBranch(val)}>
              {branches.map(branch => (
                <Option value={branch.id} key={branch.id}>
                  {branch.name}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="stage">
            <StageSelect branch={selectedBranch} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="familyTutor">
            <TutorSelect placeholder={strings.FAMILY_TUTOR} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="student">
            <StudentSelect placeholder={strings.STUDENT} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="supergroup">
            <SupergroupSelect branch={selectedBranch} placeholder={strings.SUPERGROUP} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="subject">
            <Select placeholder={strings.SUBJECT} allowClear>
              {subjects.map((sub: Subject) => (
                <Option key={sub.id} value={sub.id}>
                  {sub.name}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={12} xs={24}>
          <FormItemWithoutMargin name="trimester">
            <Select placeholder={strings.SEMESTER} loading={hasTrimesterLoading} allowClear>
              {trimesters.map((trimester: ReportTrimester) => (
                <Option value={trimester.id} key={trimester.id}>
                  {trimester.active && <Tag color="green">{strings.CURRENT}</Tag>}
                  {trimester.name}
                  <FilterDate m>{dayjs(trimester.startAt).format(dateFormat)}</FilterDate>
                  <FilterDate>{dayjs(trimester.endAt).format(dateFormat)}</FilterDate>
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="numberOfComments">
            <Select placeholder={strings.NUMBER_OF_COMMENTS} loading={hasTrimesterLoading} allowClear>
              {numberOfCommentsOptions.map((item: string) => (
                <Option value={item} key={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="status">
            <Select placeholder={strings.STATUS} allowClear>
              {statusOptions.map(item => (
                <Option value={item.value} key={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
      </Row>
      <FilterButtons handleClear={handleClear} extra={extraBtn} hasSubmitLoading={hasSubmitLoading} />
    </Form>
  );
};

export default { Filter };
