import React, { useState } from 'react';
import { Row, Col, Select, Form, Button } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { Branch } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { FormItemWithoutMargin } from 'Global/styles';
import { IStagesGetParams } from 'types/global';

const { Option } = Select;

interface FilterProps {
  onFilter: (params: IStagesGetParams) => Promise<any>;
}

export const Filter: React.FC<FilterProps> = ({ onFilter }) => {
  const [strings] = useLanguageContext();
  const [form] = Form.useForm();
  const { locationSearchParams } = useLocationSearchParams();
  const { branch } = locationSearchParams || {};

  const { branches } = useGlobalCollectionsContext();
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);

  const handleSubmit = async (values: IStagesGetParams): Promise<any> => {
    try {
      setHasSubmitLoading(true);
      await onFilter(values);
    } finally {
      setHasSubmitLoading(false);
    }
  };

  return (
    <Form form={form} onFinish={handleSubmit} initialValues={{ branch }} layout="vertical">
      <Row gutter={[10, 10]}>
        <Col lg={6} xs={24}>
          <FormItemWithoutMargin name="branch">
            <Select placeholder={strings.BRANCH} mode="multiple" maxTagCount="responsive" allowClear>
              {branches.map((branch: Branch) => (
                <Option key={branch.id} value={branch.id}>
                  {branch.name}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6} xs={24}>
          <Button type="primary" htmlType="submit" size="large" loading={hasSubmitLoading}>
            {strings.FILTER}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default { Filter };
