import React, { useState } from 'react';
import { MaterialFile, Material } from 'types/materials';
import { getPreviewUrl } from 'Global/components/FilePreview/helpers';
import { PreviewFileModal } from 'Global/components/FilePreview';
import { MaterialItem } from './MaterialItem';
import { Collapse, Flex } from 'antd';

const { Panel } = Collapse;

interface MaterialsListProps {
  materialsList: Material[];
  getFiles: () => Promise<any>;
  lessonId: number;
}

/**
 * @description Компонент для вывода файлов (материалов) в уроке оригинале
 * @param {object} materialsList
 * @param {string} title
 * @return {React.ReactNode}
 */
export const MaterialsList = (props: MaterialsListProps) => {
  const { getFiles, materialsList } = props;
  const [currentAttachment, setCurrentAttachment] = useState<{ id: number; name: string }>(null);
  const [hasLoadPreviewAttachment, setHasLoadPreviewAttachment] = useState<boolean>(false);
  const [hasShowPreviewModal, setHasShowPreviewModal] = useState<boolean>(false);
  const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer>('');

  if (!materialsList) return null;
  const handleShowModal = async (attachmentId: number, attachmentName: string): Promise<any> => {
    setHasShowPreviewModal(true);
    setHasLoadPreviewAttachment(true);
    setCurrentAttachment({
      id: attachmentId,
      name: attachmentName
    });
    try {
      await getPreviewUrl(attachmentId, setPreviewUrl, setHasShowPreviewModal);
    } finally {
      setHasLoadPreviewAttachment(false);
    }
  };
  const handleHideModal = () => {
    setHasShowPreviewModal(false);
    setCurrentAttachment(null);
    setPreviewUrl('');
  };

  return (
    <>
      <PreviewFileModal
        hasLoadPreviewAttachment={hasLoadPreviewAttachment}
        fileName={currentAttachment?.name}
        previewUrl={previewUrl}
        handleHideModal={handleHideModal}
        hasShowModal={hasShowPreviewModal}
      />
      <Collapse ghost>
        {materialsList?.map((materialItem: Material, index) => {
          const { materials, createdBy } = materialItem;
          const title = `${createdBy?.surname} ${createdBy?.name}`;
          return (
            <Panel header={title} key={index}>
              <Flex vertical gap={5} style={{ marginInlineStart: 25 }}>
                {materials?.map((material: MaterialFile) => (
                  <MaterialItem material={material} getFiles={getFiles} handleShowModal={handleShowModal} />
                ))}
              </Flex>
            </Panel>
          );
        })}
      </Collapse>
    </>
  );
};

export default { MaterialsList };
