import React, { useState, useEffect } from 'react';
import { ConfigProvider, theme } from 'antd';
import type { DirectionType } from 'antd/es/config-provider';
import { useLanguageContext } from './LanguageContext';
import { useUserContext } from './UserContext';
import { getKeyOfThemeByUrl } from 'helpers/url';
import { BranchBruner } from '../types/branches';
import { hasGymnasiumBranch } from 'helpers/branches';
import { brunerTheme, gymnasiumTheme, lgegTheme, themeObj } from 'Global/colors';

export const ThemeContext = React.createContext<IThemeContext>(null);
interface IThemeContext {
  direction: string;
  setDirection: (val: DirectionType) => void;
  activeTheme: themeObj;
  activeThemeKey: keyForTheme;
}

export enum keyForTheme {
  bruner = 'bruner',
  lgeg = 'lgeg',
  gymnasium = 'gymnasium'
}

const themes: Record<keyForTheme, themeObj> = {
  [keyForTheme.bruner]: brunerTheme,
  [keyForTheme.lgeg]: lgegTheme,
  [keyForTheme.gymnasium]: gymnasiumTheme
};

export const ThemeContextProvider = props => {
  const [, lang, , , , , , antdLocale] = useLanguageContext();
  const [user] = useUserContext();
  const keyTheme = getKeyOfThemeByUrl();
  const [direction, setDirection] = useState<DirectionType>('rtl');
  const [activeThemeKey, setActiveThemeKey] = useState<keyForTheme>(keyTheme);
  const [activeTheme, setActiveTheme] = useState<themeObj>(themes[keyTheme]);

  const setTheme = (value: keyForTheme): void => {
    if (activeThemeKey !== value) {
      setActiveThemeKey(value);
      setActiveTheme(themes[value]);
    }
  };

  useEffect(() => {
    if (lang === 'iw') {
      setDirection('rtl');
    } else {
      setDirection('ltr');
    }
  }, [lang]);

  useEffect(() => {
    if (user && user?.isLogin) {
      if (user.branch.id === BranchBruner.id) {
        setTheme(keyForTheme.bruner);
      } else if (hasGymnasiumBranch(user.branch)) {
        setTheme(keyForTheme.gymnasium);
      } else {
        setTheme(keyForTheme.lgeg);
      }
    }
  }, [user]);

  const provider: IThemeContext = {
    direction,
    setDirection,
    activeTheme,
    activeThemeKey
  };

  return (
    <ThemeContext.Provider value={provider}>
      {user && (
        <ConfigProvider
          locale={antdLocale}
          direction={direction}
          theme={{
            token: {
              fontFamily: 'Open Sans',
              colorPrimary: activeTheme.primaryColor,
              colorPrimaryText: activeTheme.primaryColor,
              colorLink: activeTheme.primaryColor,
              colorPrimaryBorder: activeTheme.primaryColor,
              borderRadius: 5,
              fontSize: 16
            },
            components: {
              Layout: {
                lightTriggerBg: activeTheme.primaryColor,
                lightTriggerColor: '#fff',
                borderRadius: 5,
                siderBg: '#fff'
              },
              Tabs: {
                horizontalItemGutter: 20
              },
              Typography: {
                titleMarginTop: 0
              },
              Avatar: { containerSizeSM: 16 },
              Button: {
                primaryShadow: 'none',
                contentFontSizeSM: 14
              },
              Descriptions: {
                titleColor: activeTheme.primaryColor
              }
            }
          }}
        >
          {props.children}
        </ConfigProvider>
      )}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const service = React.useContext(ThemeContext);

  if (!service) {
    throw new Error('Theme context is not available');
  }

  return service;
};
