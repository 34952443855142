import React from 'react';

import { BarsOutlined, CloudOutlined, LineChartOutlined, ScheduleOutlined, UserOutlined } from '@ant-design/icons';

import { Link } from 'react-router-dom';

// Routes
import { SCHEDULE } from 'Global/modules/Schedule/routes';
import { BASE_TEACHERS } from 'Admin/People/Teachers/routes';
import { BASE_MATERIALS, BASE_TRAININGS } from '../Education/routes';
import * as courses from '../Courses/routes';
import * as monitoring from '../Monitoring/routes';
import { cleanPath } from '../../Global/components/LeftMenu/helper';

export const menuListForAcademicExpert = strings => {
  return [
    {
      key: 1,
      title: (
        <span>
          <UserOutlined />
          <span>{strings.PEOPLE}</span>
        </span>
      ),
      subMenu: [
        {
          key: cleanPath(BASE_TEACHERS.substring(1)),
          title: strings.TEACHERS,
          link: BASE_TEACHERS
        }
      ]
    },
    {
      key: 2,
      title: (
        <span>
          <CloudOutlined />
          <span>{strings.WEBSITE}</span>
        </span>
      ),
      subMenu: [
        {
          key: 2.1,
          title: strings.EDUCATION,
          subMenu: [
            {
              key: cleanPath(BASE_MATERIALS.substring(1)),
              title: strings.MATERIALS,
              link: BASE_MATERIALS
            },
            {
              key: cleanPath(BASE_TRAININGS.substring(1)),
              title: strings.TRAININGS,
              link: BASE_TRAININGS
            }
          ]
        }
      ]
    },
    {
      key: 3,
      title: (
        <span>
          <BarsOutlined />
          <span>{strings.COURSES}</span>
        </span>
      ),
      subMenu: [
        {
          key: cleanPath(courses.BASE_COURSE_GROUP.substring(1)),
          title: strings.GROUP_COURSES,
          link: courses.BASE_COURSE_GROUP
        },
        {
          key: cleanPath(courses.BASE_ORIGINAL_COURSES.substring(1)),
          title: strings.ORIGINAL_COURSES,
          link: courses.BASE_ORIGINAL_COURSES
        }
      ]
    },
    {
      key: cleanPath(SCHEDULE.substring(1)),
      title: (
        <Link to={SCHEDULE}>
          <ScheduleOutlined />
          <span>{strings.SCHEDULE}</span>
        </Link>
      )
    },
    {
      key: 5,
      title: (
        <span>
          <LineChartOutlined />
          <span>{strings.MONITORING}</span>
        </span>
      ),
      subMenu: [
        {
          key: 5.1,
          title: strings.ACADEMIC,
          subMenu: [
            {
              key: cleanPath(monitoring.BASE_LESSONS.substring(1)),
              title: strings.LESSONS,
              link: monitoring.BASE_LESSONS
            },
            {
              key: cleanPath(monitoring.BASE_FEEDBACK_MANAGEMENT.substring(1)),
              title: strings.FEEDBACK_MANAGEMENT,
              link: monitoring.BASE_FEEDBACK_MANAGEMENT
            },
            {
              key: cleanPath(monitoring.BASE_REPORT_TEACHERS.substring(1)),
              title: strings.TEACHERS,
              link: monitoring.BASE_REPORT_TEACHERS
            },
            {
              key: cleanPath(monitoring.BASE_PROGRESS_REPORT.substring(1)),
              title: strings.PROGRESS_REPORTS,
              link: monitoring.BASE_PROGRESS_REPORT
            }
          ]
        },
        {
          key: 5.2,
          title: strings.ASSESSMENT,
          subMenu: [
            {
              key: cleanPath(monitoring.BASE_SUMMARY_TABLE.substring(1)),
              title: strings.SUMMARY_TABLE,
              link: monitoring.BASE_SUMMARY_TABLE
            },
            {
              key: cleanPath(monitoring.BASE_SUMMARY_CALENDAR.substring(1)),
              title: strings.SUMMARY_CALENDAR,
              link: monitoring.BASE_SUMMARY_CALENDAR
            }
          ]
        }
      ]
    }
  ];
};

export default { menuListForAcademicExpert };
