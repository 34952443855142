import { httpService } from 'api/core';

/**
 * @description Удаление родителя по id
 * @param {number} parentId
 * @return {Promise<any>}
 */
export const deleteParent = (parentId: number | string): Promise<any> => httpService.delete(`/api/parent/${parentId}`);

export default { deleteParent };
