import React, { useState, useEffect } from 'react';
import { StopOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select, Spin } from 'antd';
import { debounce } from 'lodash';
import { GetFieldDecoratorOptions } from '@ant-design/compatible/lib/form/Form';

import styled from 'styled-components';
import { useStudentRepository } from 'repos/StudentRepository';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';

const StSelect = styled.div`
  .ant-select-selection--multiple .ant-select-selection__clear,
  .ant-select-selection--multiple .ant-select-arrow {
    top: 20px;
  }
`;

const { Option } = Select;

interface IBaseStudent {
  id: string | number;
  name: string;
  surname: string;
  isBusy?: boolean;
}

interface StudentSelectProps {
  getFieldDecorator?<T extends Object = {}>(
    id: keyof T,
    options?: GetFieldDecoratorOptions
  ): (node: React.ReactNode) => React.ReactNode;
  mode?: 'multiple' | 'tags';
  placeholder?: string;
  name: string;
  searchParams?: object;
  required?: boolean;
  disabled?: boolean;
  maxTagCount?: number;
  initStudentsIds?: string[] | number[];
  initStudentId?: string | number;
  initStudentValue?: IBaseStudent;
}

/**
 * @description Компонент поиска по учителям
 * @param {object} props
 * @return {React.ReactNode}
 */
export const StudentSelect = (props: StudentSelectProps) => {
  const {
    placeholder,
    getFieldDecorator,
    mode,
    name,
    required,
    disabled,
    maxTagCount,
    initStudentId,
    initStudentsIds
  } = props;

  const [strings] = useLanguageContext();
  const studentRepository = useStudentRepository();
  const globalRequestsRepository = useGlobalRequestsRepository();
  const [hasLoadStudents, setHasLoadStudents] = useState<boolean>(false);
  const [studentList, setStudentsList] = useState<IBaseStudent[]>([]);
  const [baseStudent, setBaseStudent] = useState<IBaseStudent>(undefined);
  const [baseStudents, setBaseStudents] = useState<IBaseStudent[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [typing, setTyping] = useState<Boolean>(false);

  /**
   * @description Поиск по студентам
   * @param {string} student
   * @return {Promise<void>}
   */
  const fetchStudents = async (query: string): Promise<any> => {
    setSearchValue(query);
    if (query) {
      setHasLoadStudents(true);
      try {
        const {
          data: { children }
        } = await globalRequestsRepository.search(query);
        const students = children.map(item => {
          return {
            id: item.id,
            name: item?.user?.name,
            surname: item?.user?.surname,
            isBusy: item?.isBusy
          };
        });
        setStudentsList(students);
      } catch {
      } finally {
        setHasLoadStudents(false);
      }
    }
  };

  /**
   * @description Получение студента по id
   * @param {number} studentId
   * @return {Promise<any>}
   */
  const getStudentById = async (studentId: string | number): Promise<any> => {
    if (!studentId || mode === 'multiple') return;
    try {
      const {
        data: {
          user: { name, surname }
        }
      } = await studentRepository.getStudentById(studentId);
      setBaseStudent({ id: studentId, name: name, surname: surname });
    } catch {}
  };

  /**
   * @description Получение списка студентов, ЕСЛИ select multiple
   * @return {Promise<any>}
   */
  const getStudentsByIds = async (): Promise<any> => {
    if (mode !== 'multiple') return;
    const students = [];
    for (const studentId of initStudentsIds) {
      try {
        const { data } = await studentRepository.getStudentById(studentId);
        students.push(data);
      } catch {}
    }
    try {
      const results = await Promise.all(students);
      const data = results.map(item => {
        return {
          id: item?.id,
          name: item?.user?.name,
          surname: item?.user?.surname
        };
      });
      setBaseStudents(data);
    } catch {}
  };

  /**
   * @description Формирование Node для Select
   * @return {React.ReactNode | React.ReactNode[]}
   */
  const getInitStudentNode = (): React.ReactNode | React.ReactNode[] => {
    if (baseStudents && mode === 'multiple') {
      return baseStudents.map((item: IBaseStudent) => (
        <Option value={Number(item.id)} key={item.id}>
          {item.name} {item?.surname}
        </Option>
      ));
    }

    if (baseStudent) {
      return (
        <Option value={Number(baseStudent.id)} key={baseStudent.id}>
          {baseStudent.name} {baseStudent.surname}
        </Option>
      );
    }
  };

  const options = studentList.map((item: IBaseStudent) => {
    return (
      <Option value={Number(item.id)} key={item.id}>
        {item?.name} {item?.surname}{' '}
        {item?.isBusy && (
          <>
            <StopOutlined style={{ color: '#f5222d' }} /> (busy)
          </>
        )}
      </Option>
    );
  });
  useEffect(() => {
    if (searchValue) {
      setTyping(true);
    } else {
      setTyping(false);
    }
  }, [searchValue]);

  const select = (
    <Select
      allowClear={true}
      showSearch
      mode={mode}
      placeholder={placeholder || strings.STUDENT}
      defaultActiveFirstOption={false}
      filterOption={false}
      onSearch={debounce(fetchStudents, 800)}
      style={{ width: '100%' }}
      notFoundContent={
        hasLoadStudents ? <Spin size="small" /> : <p>{!typing ? strings.START_TYPING : strings.NOT_FOUND}</p>
      }
      maxTagCount={maxTagCount || 1}
      disabled={disabled}
      loading={hasLoadStudents}
    >
      {[getInitStudentNode(), ...options]}
    </Select>
  );

  useEffect(() => {
    if (initStudentId) {
      getStudentById(initStudentId);
    }
    if (initStudentsIds && initStudentsIds.join()) {
      getStudentsByIds();
    }
    //  initStudentsIds массив, поэтому преобразуем в строку, чтобы зафиксировать изменение и выполнить getStudentsByIds
  }, [initStudentId, initStudentsIds && initStudentsIds.join()]); // eslint-disable-line

  if (required) {
    return (
      <StSelect>
        <MyFormItem label={strings.STUDENT}>
          {getFieldDecorator(name, {
            initialValue:
              mode === 'multiple' ? initStudentsIds && initStudentsIds : initStudentId && Number(initStudentId),
            rules: [{ required: required, message: 'Select student' }]
          })(select)}
        </MyFormItem>
      </StSelect>
    );
  }

  return (
    <StSelect>
      {getFieldDecorator(name, {
        initialValue: mode === 'multiple' ? initStudentsIds && initStudentsIds : initStudentId && Number(initStudentId),
        rules: [{ required: false }]
      })(select)}
    </StSelect>
  );
};

export default { StudentSelect };
