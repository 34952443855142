import React from 'react';
import { Flex, Tag } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { BASE_GRAY_TEXT, BASE_GREEN } from 'Global/colors';

export const Legend: React.FC = React.memo(() => {
  const [strings] = useLanguageContext();
  return (
    <Flex justify="end" style={{ marginBottom: 15 }}>
      <Flex gap={5}>
        <Tag style={{ backgroundColor: BASE_GRAY_TEXT }}>{strings.I_STILL_NEED_TO_LEARN_THIS}</Tag>
      </Flex>
      <Flex gap={5}>
        <Tag style={{ backgroundColor: 'orange' }}>{strings.I_CAN_DO_THIS}</Tag>
      </Flex>
      <Flex gap={5}>
        <Tag style={{ backgroundColor: BASE_GREEN }}>{strings.I_CAN_DO_THIS_VERY_WELL}</Tag>
      </Flex>
    </Flex>
  );
});
