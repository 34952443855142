export const PNG_MIME_TYPE = 'image/png';
export const JPG_MIME_TYPE = 'image/jpg';
export const JPEG_MIME_TYPE = 'image/jpeg';
export const GIF_MIME_TYPE = 'image/gif';
export const PDF_MIME_TYPE = 'application/pdf';
export const MS_POWER_POINT_MIME_TYPE = 'application/vnd.ms-powerpoint';
export const MS_XML_POWER_POINT_MIME_TYPE = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
export const MS_WORD_MIME_TYPE = 'application/msword';
export const MS_XML_WORD_MIME_TYPE = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const MS_EXCEL_MIME_TYPE = 'application/vnd.ms-excel';
export const MS_XML_EXCEL_MIME_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const IMAGE_TYPES_LIST = ['pdf', 'png', 'jpg', 'jpeg', 'gif'];

export default {
  PNG_MIME_TYPE,
  JPG_MIME_TYPE,
  JPEG_MIME_TYPE,
  GIF_MIME_TYPE,
  PDF_MIME_TYPE,
  MS_POWER_POINT_MIME_TYPE,
  MS_XML_POWER_POINT_MIME_TYPE,
  MS_WORD_MIME_TYPE,
  MS_XML_WORD_MIME_TYPE,
  MS_EXCEL_MIME_TYPE,
  MS_XML_EXCEL_MIME_TYPE,
  IMAGE_TYPES_LIST
};
