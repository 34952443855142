import React, { useContext, useMemo } from 'react';
import { httpService } from 'api/core';
import { GetAttachmentParams, UploadAttachmentParams } from 'types/global';

const AttachmentRepositoryContext = React.createContext(null);

class AttachmentRepository {
  getAttachmentList(params: GetAttachmentParams) {
    return httpService.get('/api/v1/attachment/list', params, null, true);
  }

  // Необходимо строго передавать форма это или нет
  /**
   * @description Загрузка attachment
   * @param {object} params
   * @return {Promise<any>}
   */
  uploadAttachment(params: Partial<UploadAttachmentParams & FormData>, isForm, config?) {
    return httpService.post('/api/v1/attachment/upload', params, config, isForm, true);
  }

  /**
   * @description Метод удаления универсальный, и для уроков и для ивентов.
   * @param {number} attachmentId
   * @return {Promise<any>}
   */
  deleteAttachment(attachmentId: number) {
    return httpService.delete(`/api/v1/attachment/${attachmentId}`, null, null, true);
  }
}

export const AttachmentRepositoryProvider = props => {
  const service = useMemo(() => new AttachmentRepository(), []);

  return <AttachmentRepositoryContext.Provider value={service}>{props.children}</AttachmentRepositoryContext.Provider>;
};

export const useAttachmentRepository = () => {
  const service = useContext(AttachmentRepositoryContext);

  if (!service) {
    throw new Error('Attachment repository is unavailable');
  }

  return service;
};
