import { Branch } from 'types';

/**
 * @description Проверка на Российские филиалы
 * @param {object} branch
 * @return {Promise<any>}
 */
export const hasRussia = (branch: Branch) => branch.id === 1 || branch.id === 2;

/**
 * @description Функция на проверку соответствия текущего филиала Москве
 * @param branch
 * @return {boolean}
 */
export const hasMoscowBranch = (branch: Branch) => branch && branch.id === 1;

/**
 * @description Функция на проверку флага invoiceMonthFinanceSystem
 * @param branch
 * @return {boolean}
 */
export const hasInvoiceMonthFinanceSystem = (branch: Branch) => branch && branch?.invoiceMonthFinanceSystem;

/**
 * @description Проверка на бранч
 * @param {number} branchId
 * @param {number[]} branches
 * @return {boolean}
 */
export const hasBranch = (branchId: number, branches: number[]): boolean => {
  return branches.includes(branchId);
};

/**
 * @description Проверка доступа к метацелям в журналах и прогресс репортах, разрешены только для Мск и Питера
 * @param {number} branchId
 * @return {boolean}
 */
export const hasMetaGoalsBranch = (branchId: number): boolean => {
  return branchId === 1 || branchId === 2;
};

export const hasGymnasiumBranch = (branch: Branch) => branch && branch.type === 'rg';
