import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { message, Row, Col, Typography, Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import { Teachers } from 'api/Teachers';
import { WorkProgressItem } from './WorkProgressItem';
import { BASE_WORK_PROGRESS } from '../../routes';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Title } = Typography;
const StyledWorkProgress = styled.div`
  margin-bottom: 16px;
`;

export interface IWorkProgressItem {
  endAt: string;
  groupName: string;
  lessonId: number;
  startAt: string;
  studentsCount: number;
  hasOverdue: boolean;
  tag: string;
  disciplines: {
    expired: string;
    filled: false;
  }[];
}

export const WorkProgressWidget = () => {
  const [strings] = useLanguageContext();
  const [hasLoadWorkProgressList, setHasLoadWorkProgressList] = useState<boolean>(false);
  const [workProgress, setWorkProgress] = useState<IWorkProgressItem[]>([]);
  const [weekWorkProgress, setWeekWorkProgress] = useState<IWorkProgressItem[]>([]);

  const fetchWorkProgress = async () => {
    try {
      setHasLoadWorkProgressList(true);
      const { data } = await Teachers.getTeacherLastNotFilledJournal();
      const { lessThanOneDay, overdue, lessThanOneWeek } = data;
      setWeekWorkProgress(
        lessThanOneWeek.reverse().map(i => ({
          ...i,
          tag: strings.LESS_THAN_A_WEEK
        }))
      );
      setWorkProgress([
        ...overdue
          .reverse()
          .slice(0, 2)
          .map(i => ({ ...i, hasOverdue: true, tag: strings.OVERDUE })),
        ...lessThanOneDay
          .reverse()
          .slice(0, 2)
          .map(i => ({ ...i, hasOverdue: false, tag: strings.LESS_THAN_A_DAY }))
      ]);
      setHasLoadWorkProgressList(false);
    } catch {
      message.error(strings.ERROR_FETCHING_WORK_PROGRESS);
    }
  };

  useEffect(() => {
    fetchWorkProgress();
  }, []);

  if (hasLoadWorkProgressList) {
    return <Skeleton active={true} />;
  }

  const getLessonThanAWeekTitle = () => {
    // Если нету overdue и lesson than a day, то вычитаем less than a week - 2
    if (workProgress.length === 0) {
      const weekLength = weekWorkProgress.length;
      // Если больше 2 уроков то вычитаем 2, иначе просто выводим число
      const countLessons = weekLength > 2 ? weekLength - 2 : weekLength;

      return `+ ${countLessons} ${strings.LESS_THAN_A_WEEK}`;
    }

    return `+ ${weekWorkProgress.length} ${strings.LESS_THAN_A_WEEK}`;
  };

  /**
   * Отображены карточки work progress если остался один день или дедлайн просрочен
   * Если карточек выше нет, то отобразить 2 карточки (со статусом less than a week)
   **/
  const getWorkProgressList = () => {
    if (workProgress.length === 0) {
      return weekWorkProgress.slice(0, 2).map(({ lessonId, hasOverdue, startAt, groupName, tag, disciplines }) => (
        <Col key={lessonId} lg={12}>
          <WorkProgressItem
            lessonId={lessonId}
            tag={tag}
            disciplines={disciplines}
            hasOverdue={hasOverdue}
            startAt={startAt}
            groupName={groupName}
          />
        </Col>
      ));
    }

    return workProgress.map(({ lessonId, hasOverdue, startAt, groupName, tag, disciplines }) => (
      <Col key={lessonId} lg={12}>
        <WorkProgressItem
          lessonId={lessonId}
          tag={tag}
          disciplines={disciplines}
          hasOverdue={hasOverdue}
          startAt={startAt}
          groupName={groupName}
        />
      </Col>
    ));
  };

  if (weekWorkProgress.length === 0 && workProgress.length === 0) return null;

  return (
    <ErrorBoundary>
      <Row gutter={[10, 10]}>
        <Col lg={12}>
          <Title level={4}>{strings.WORK_PROGRESS}</Title>
        </Col>
        <Col lg={12} style={{ textAlign: 'right' }}>
          <Link to={BASE_WORK_PROGRESS}>{getLessonThanAWeekTitle()}</Link>
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 20 }}>
        {getWorkProgressList()}
      </Row>
    </ErrorBoundary>
  );
};
