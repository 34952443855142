import React, { useMemo, useState } from 'react';
import { Tabs } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useHistory, useLocation } from 'react-router-dom';
import { BranchesTab } from './BranchesTab';
import { BranchesClickupTab } from './BranchesClickupTab';
const { TabPane } = Tabs;

export const Branches = () => {
  const [strings] = useLanguageContext();
  const { goBack, push } = useHistory();

  const location = useLocation();
  const initialTab = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('tab') || 'branches';
  }, []);

  const [activeTab, setActiveTab] = useState(initialTab);

  const handleTabChange = key => {
    setActiveTab(key);
    push({ search: `?tab=${key}` });
  };

  return (
    <Container>
      <StPageHeader onBack={goBack} title={strings.BRANCHES} />
      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab={strings.BRANCHES} key="branches">
          <BranchesTab />
        </TabPane>
        <TabPane tab={strings.BRANCHES_CLICKUP} key="clickup">
          <BranchesClickupTab />
        </TabPane>
      </Tabs>
    </Container>
  );
};

export default { Branches };
