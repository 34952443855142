import React, { useState, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Spin, Input, Select, Checkbox, Row, Col, Button, message } from 'antd';
import { omitBy, isUndefined } from 'lodash';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Courses } from 'api/Courses';
import BreadCrumbs from 'Global/components/Breadcrumbs';
import { BASE_ORIGINAL_COURSES } from '../routes';
import { Branch, Subject, Inventory } from 'types';
import { OriginalCourse } from 'types/courses';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { StageSelect } from 'Global/components/FormComponentsCompatible/Selects/StageSelect';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useMetaGoalsRepository } from 'repos/MetaGoalsRepository';
import { match } from 'react-router';
import { StPageHeader, Container } from 'Global/GlobalStyle';
import MyJoditEditor from 'Global/components/MyJoditEditor';

const { Option } = Select;

interface OriginalCourseFormProps extends FormComponentProps {}

export const OriginalCourseFormTemplate = (props: OriginalCourseFormProps) => {
  const {
    form,
    form: { getFieldDecorator, validateFieldsAndScroll, setFieldsValue }
  } = props;

  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();

  const [strings] = useLanguageContext();
  const [course, setCourse] = useState<OriginalCourse>();
  const [inventoriesList, setInventoriesList] = useState<Inventory[]>([]);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasLoadingInventories, setHasLoadingInventories] = useState<boolean>(false);
  const [metaGoalsLevels, setMetaGoalsLevels] = useState([]);
  const metaGoalsRepo = useMetaGoalsRepository();
  const { goBack, push } = useHistory();

  const { branches, subjects: subjectsList } = useGlobalCollectionsContext();
  const coursesRepository = useCoursesRepository();
  const location = useLocation();

  const {
    name,
    branch,
    subjects,
    stage,
    duration,
    durationMeasure,
    weekLessonsCount,
    description,
    objectives,
    methodology,
    isActive,
    januaryUnits,
    juneUnits,
    metalevel,
    inventories
  } = course || {};

  /**
   * @description Оригинальный курс
   * @param {number} id
   * @return {Promise<any>}
   */
  const getOriginalCourseById = async (id: number | string): Promise<any> => {
    setLoading(true);
    try {
      const { data } = await coursesRepository.getOriginalCourseById(id);
      setCourse(data);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = () => {
    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const params = omitBy(values, isUndefined);
        try {
          setDisabledButton(true);
          await coursesRepository.editOriginalCourse(id, params);
          setDisabledButton(false);
          message.success('Original course save!', 2);
          setTimeout(() => {
            push(`${BASE_ORIGINAL_COURSES}/${id}`);
          }, 1500);
        } catch {
          setDisabledButton(false);
        }
      }
    });
  };

  const handleCreate = () => {
    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const params = omitBy(values, isUndefined);
        setDisabledButton(true);
        try {
          const { data } = await coursesRepository.createOriginalCourse(params);
          message.success(`${strings.ORIGINAL_COURSE} ${data.name} ${strings.SUCCESSFULLY_CREATED}`, 2);
          push(`/original_courses/${data.id}`);
          setDisabledButton(false);
        } catch {
          message.success(strings.ERROR_CREATING_ORIGINAL_COURSE_TRY_AGAIN_LATER, 2);
          setDisabledButton(false);
        }
      }
    });
  };

  /**
   * @description Список инвентаря
   * @return {Promise<any>}
   */
  const fetchInventories = async (): Promise<any> => {
    setHasLoadingInventories(true);
    try {
      const { data } = await Courses.getListInventories();
      setInventoriesList(data);
    } catch {
    } finally {
      setHasLoadingInventories(false);
    }
  };
  const fetchMetaGoals = async () => {
    try {
      const { data } = await metaGoalsRepo.getMetaGoalsList();
      setMetaGoalsLevels(data);
    } catch {
      setMetaGoalsLevels([]);
    }
  };

  useEffect(() => {
    fetchInventories();
    fetchMetaGoals();
  }, []);

  useEffect(() => {
    if (id) {
      getOriginalCourseById(id);
    }
  }, [id]);

  return (
    <Spin spinning={loading}>
      <Container>
        <BreadCrumbs location={location} />
        <StPageHeader onBack={goBack} title={id ? name : `${strings.CREATING_AN_ORIGINAL_COURSE}`} />
        <Form layout="vertical">
          <Row gutter={[10, 10]}>
            <Col lg={6}>
              <MyFormItem label={strings.TITLE}>
                {getFieldDecorator('name', {
                  initialValue: name,
                  rules: [{ required: true }]
                })(<Input type="text" />)}
              </MyFormItem>
            </Col>
            <Col lg={6}>
              <MyFormItem label={strings.BRANCH}>
                {getFieldDecorator('branch', { initialValue: branch?.id, rules: [{ required: true }] })(
                  <Select placeholder={strings.SELECT_BRANCH}>
                    {branches.map((branch: Branch) => (
                      <Option value={branch.id} key={branch.id}>
                        {branch.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </MyFormItem>
            </Col>
            <Col lg={6}>
              <StageSelect label={strings.STAGE} form={form} isRequired={true} initialValue={stage?.id} />
            </Col>
            <Col lg={6}>
              <MyFormItem label={strings.SUBJECT}>
                {getFieldDecorator('subjects', {
                  initialValue: subjects && subjects.map(item => item.id),
                  rules: [{ required: true }]
                })(
                  <Select mode="multiple" placeholder={strings.SELECT_SUBJECT}>
                    {subjectsList.map((subject: Subject) => (
                      <Option value={subject.id} key={subject.id}>
                        {subject.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </MyFormItem>
            </Col>
            <Col lg={6}>
              <MyFormItem label={strings.BOOKS_FOR_STUDENTS}>
                {getFieldDecorator('inventories', { initialValue: inventories && inventories.map(item => item.id) })(
                  <Select
                    mode="multiple"
                    maxTagCount={1}
                    placeholder={strings.SELECT_BOOKS}
                    loading={hasLoadingInventories}
                  >
                    {inventoriesList.map((inventory: Inventory) => (
                      <Option value={inventory.id} key={inventory.id}>
                        {inventory.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </MyFormItem>
            </Col>
            <Col lg={6}>
              <MyFormItem label={strings.DURATION}>
                {getFieldDecorator('duration', { initialValue: duration })(<Input type="text" />)}
              </MyFormItem>
            </Col>
            <Col lg={6}>
              <MyFormItem label={strings.HOUR_DURATION}>
                {getFieldDecorator('durationMeasure', { initialValue: durationMeasure })(<Input type="text" />)}
              </MyFormItem>
            </Col>
            <Col lg={6}>
              <MyFormItem label={strings.NUMBER_OF_CLASSES_PER_WEEK}>
                {getFieldDecorator('weekLessonsCount', { initialValue: weekLessonsCount })(<Input type="text" />)}
              </MyFormItem>
            </Col>
            <Col lg={12}>
              <MyFormItem label={strings.DESCRIPTION}>
                {getFieldDecorator('description', { initialValue: description })(
                  <Input.TextArea placeholder={strings.DESCRIPTION} />
                )}
              </MyFormItem>
            </Col>
            <Col lg={6}>
              <MyFormItem label={strings.META_LEVEL}>
                {getFieldDecorator('metaLevel', { initialValue: metalevel && metalevel?.id })(
                  <Select placeholder={strings.SELECT_LEVEL}>
                    <Option value="">&nbsp;</Option>
                    {metaGoalsLevels.map(goal => (
                      <Option value={goal.id} key={goal.id}>
                        {goal.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </MyFormItem>
            </Col>
            <Col lg={6}>
              <MyFormItem label={strings.UP_TO_DATE}>
                {getFieldDecorator('isActive', { initialValue: isActive, valuePropName: 'checked' })(<Checkbox />)}
              </MyFormItem>
            </Col>
            <Col lg={24}>
              <MyFormItem label={strings.OBJECTIVES}>
                {getFieldDecorator('objectives', {
                  initialValue: objectives
                })(
                  <MyJoditEditor
                    value={objectives}
                    key="objectives-editor"
                    onChange={value => setFieldsValue({ objectives: value })}
                    height={200}
                  />
                )}
              </MyFormItem>
            </Col>
            <Col lg={24}>
              <MyFormItem label={strings.METHODOLOGY}>
                {getFieldDecorator('methodology', {
                  initialValue: methodology
                })(
                  <MyJoditEditor
                    value={methodology}
                    key="methodology-editor"
                    onChange={value => setFieldsValue({ methodology: value })}
                    height={200}
                  />
                )}
              </MyFormItem>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col lg={24}>
              <h2>{strings.EDEXCEL_INFO}</h2>
            </Col>
            <Col lg={8}>
              <MyFormItem label={`${strings.JANUARY} ${strings.UNITS}`}>
                {getFieldDecorator('januaryUnits', { initialValue: januaryUnits })(<Input type="text" />)}
              </MyFormItem>
            </Col>
            <Col lg={8}>
              <MyFormItem label={`${strings.JUNE} ${strings.UNITS}`}>
                {getFieldDecorator('juneUnits', { initialValue: juneUnits })(<Input type="text" />)}
              </MyFormItem>
            </Col>
          </Row>
          <Row>
            <Col lg={24} style={{ marginInlineStart: 5 }}>
              {id ? (
                <Button type="primary" onClick={handleEdit} size="large" disabled={disabledButton}>
                  {strings.EDIT_COURSE}
                </Button>
              ) : (
                <Button type="primary" onClick={handleCreate} size="large" disabled={disabledButton}>
                  {strings.CREATE}
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Container>
    </Spin>
  );
};

export const OriginalCourseForm = Form.create<OriginalCourseFormProps>({})(OriginalCourseFormTemplate);

export default { OriginalCourseForm };
