import React, { useCallback, useMemo, useState } from 'react';
import ScheduleService from 'helpers/services/ScheduleService';
import { Col, Row, Table, Form, Input, Select } from 'antd';
import { columns } from 'Global/modules/Schedule/columns';
import { Branch, Child, Subject } from 'types';
import { useLanguageContext } from 'contexts/LanguageContext';
import dayjs from 'dayjs';
import { CourseGroupSelect, StageSelect, TeacherSelect } from 'Global/components/FormComponents';
import { StSlotCollapse } from '../../styles';
import { ProlongUntilSlotFields } from '../SlotFields/ProlongUntilSlotFields';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { Teacher } from 'types/Teachers/teacher';

const { Option } = Select;
const service = new ScheduleService();

interface LessonFormProps {
  userBranch: number;
}

/**
 * @description Форма создания урока
 * @param {object} props
 * @return {React.ReactNode}
 */
export const LessonForm = React.memo(({ userBranch }: LessonFormProps) => {
  const [strings] = useLanguageContext();
  const form = Form.useFormInstance();
  const [students, setStudents] = useState<Child[]>([]);
  const [initTeacher, setInitTeacher] = useState<Teacher>();

  /**
   * @description Обработка select с группами и добавление студентов из выбранной группы в таблицу
   * @return {Promise<any>}
   */
  const handleGroupChange = useCallback(async (group: number): Promise<any> => {
    try {
      const data = await service.getGroupInfo(group);
      const [students, teacher, duration] = data;
      setInitTeacher(teacher);
      setStudents(students);
      const startTime = form.getFieldValue('startTime');
      //выставляем время окончания урока dateEnd в зависимости от длительности durationMeasure, полученной в getCourseGroupById
      const newEndTime = dayjs(startTime).add(duration, 'm');
      form.setFieldsValue({
        endTime: newEndTime,
        teacher: teacher?.teacher?.id
      });
    } catch {}
  }, []);

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col lg={8} xs={24}>
          <Form.Item name="teacher" label={strings.TEACHER} rules={[{ required: true }]}>
            <TeacherSelect initOptions={initTeacher && [initTeacher]} />
          </Form.Item>
        </Col>
        <Col lg={8} xs={24}>
          <Form.Item name="group" label={strings.GROUP} rules={[{ required: true }]}>
            <CourseGroupSelect branch={userBranch} onChange={handleGroupChange} />
          </Form.Item>
        </Col>
      </Row>
      <StSlotCollapse
        ghost
        size="small"
        defaultActiveKey={['1', '2']}
        items={[
          {
            key: '1',
            label: <strong>{strings.PROLONG_UNTIL}</strong>,
            children: <ProlongUntilSlotFields hasApplyToAll={true} />
          },
          {
            key: '2',
            label: <strong>{strings.CLASS_ROSTER}</strong>,
            children: <Table dataSource={students} columns={columns} size="small" pagination={false} bordered={false} />
          }
        ]}
      />
    </>
  );
});

export default { LessonForm };
