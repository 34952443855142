import React from 'react';
import { Row, Checkbox, Button } from 'antd';

export const columnsRegisters = [
  {
    title: '',
    dataIndex: 'done',
    key: 'done',
    render: (checked: boolean) => <Checkbox disabled checked={checked} />
  },
  {
    title: 'Date',
    dataIndex: 'endAt',
    key: 'endAt'
  },
  {
    title: 'Group',
    dataIndex: 'group',
    key: 'group'
  },
  {
    title: '',
    dataIndex: 'notCountComm',
    key: 'actions',
    render: (notCountComm: boolean, row: any) => (
      <Row>
        <Button
          onClick={() => row.excludeDisciplines(row.teacherId, row.lessonId, !row.notCountMain, 'main')}
          size="small"
          type={row.notCountMain ? 'default' : 'primary'}
        >
          Main
        </Button>
        <Button
          onClick={() => row.excludeDisciplines(row.teacherId, row.lessonId, !notCountComm, 'comment')}
          size="small"
          type={notCountComm ? 'default' : 'primary'}
        >
          Comm
        </Button>
        <Button
          onClick={() => row.excludeDisciplines(row.teacherId, row.lessonId, !row.notCountHW, 'hw')}
          size="small"
          type={row.notCountHW ? 'default' : 'primary'}
        >
          HW
        </Button>
      </Row>
    )
  },
  {
    title: 'Valid %',
    dataIndex: 'disciplinePercent',
    key: 'disciplinePercent'
  },
  {
    title: 'Total %',
    dataIndex: 'disciplinePercentAll',
    key: 'disciplinePercentAll'
  }
];

export const columnsComments = [
  {
    title: 'Date',
    dataIndex: 'lesson',
    key: 'date',
    render: lesson => <p>{lesson.startAt}</p>
  },
  {
    title: 'Subject',
    dataIndex: 'course',
    key: 'subject',
    render: course => <p>{course.subject.name}</p>
  },
  {
    title: 'Group',
    dataIndex: 'course.groupName',
    key: 'course',
    render: course => <p>{course.groupName}</p>
  },
  {
    title: 'Feedback for students',
    dataIndex: 'studentComment',
    key: 'studentComment'
  },
  {
    title: 'Feedback for parents',
    dataIndex: 'parentComment',
    key: 'parentComment'
  }
];

export const parentsDislikedComments = [
  {
    title: 'Date',
    dataIndex: 'lesson',
    key: 'date',
    render: lesson => <p>{lesson.startAt}</p>
  },
  {
    title: 'Subject',
    dataIndex: 'course',
    key: 'subject',
    render: course => <p>{course.subject.name}</p>
  },
  {
    title: 'Group',
    dataIndex: 'course',
    key: 'course',
    render: course => <p>{course.groupName}</p>
  },
  {
    title: 'Feedback for parents',
    dataIndex: 'parentComment',
    key: 'parentComment'
  }
];

export const TABLE_TYPES = {
  registers: {
    columns: columnsRegisters,
    name: 'registers',
    apiMethod: 'getTeacherDisciplines',
    tableTitle: 'Registers',
    responseField: 'lessons'
  },
  liked: {
    columns: columnsComments,
    name: 'liked',
    apiMethod: 'getLikedComments',
    tableTitle: 'Liked comments'
  },
  disliked: {
    columns: columnsComments,
    name: 'disliked',
    apiMethod: 'getDislikedCommentsByTeacherId',
    tableTitle: 'Disliked comments'
  },
  parentDisliked: {
    columns: parentsDislikedComments,
    name: 'parentDisliked',
    apiMethod: 'getParentDislikedComments',
    tableTitle: 'Parents liked comments'
  }
};
