import React, { useState, useEffect } from 'react';
import { match } from 'react-router';
import { Col, Spin, Button, message, Input, Row, Checkbox, Form, Select } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useInvoicesRepository } from 'repos/InvoicesRepository';
import { BASE_INVOICE_LETTERS_TEMPLATES } from '../routes';
import { ILetterTemplatePostParams } from 'Admin/People/Families/Family/Invoices/types';
import { Branch, Performer } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useUserContext } from 'contexts/UserContext';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
const { Option } = Select;

export const LetterTamplateForm = () => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [hasPerformerLoading, setHasPerformerLoading] = useState<boolean>(false);
  const [selectedBranch, setSelectedBranch] = useState<number>();
  const [performers, setPerformers] = useState<Performer[]>([]);
  const { goBack, push } = useHistory();
  const [form] = Form.useForm();
  const { branches } = useGlobalCollectionsContext();

  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();

  const invoicesRepository = useInvoicesRepository();
  const globalRequestsRepository = useGlobalRequestsRepository();

  const getLetterTemplateById = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await invoicesRepository.getLetterTemplateById(id);
      const { title, branch, performer, body } = data;
      const initVal = { title, body, branch: branch?.id, performer: performer?.id };
      form.setFieldsValue(initVal);
      setSelectedBranch(branch?.id);
    } finally {
      setHasLoading(false);
    }
  };

  const onSubmit = async (params: ILetterTemplatePostParams): Promise<any> => {
    setHasLoading(true);
    try {
      if (id) {
        await invoicesRepository.editLetterTemplate(id, params);
        message.success(strings.SUCCESSFULLY_UPDATED, 2);
      } else {
        await invoicesRepository.createLetterTemplate(params);
        message.success(strings.SUCCESSFULLY_CREATED, 2);
      }
      push(BASE_INVOICE_LETTERS_TEMPLATES);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  const fetchPerformers = async (): Promise<any> => {
    setHasPerformerLoading(true);
    try {
      const { data } = await globalRequestsRepository.getPerformers({
        ...(selectedBranch && { branch: selectedBranch }),
        isActive: 1
      });
      setPerformers(data);
    } catch {
    } finally {
      setHasPerformerLoading(false);
    }
  };
  const onChangeBranch = val => {
    setSelectedBranch(val);
    form.setFieldsValue({ performer: undefined });
  };

  useEffect(() => {
    if (id) {
      getLetterTemplateById();
    }
  }, [id]);

  useEffect(() => {
    fetchPerformers();
    // form.setFieldsValue({ performer: undefined });
  }, [selectedBranch]);

  return (
    <Container>
      <StPageHeader onBack={goBack} title={id ? `${strings.EDIT}` : `${strings.ADD}`} />
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Spin spinning={hasLoading}>
          <Row gutter={[10, 10]} align="middle">
            <Col xs={24} lg={8}>
              <Form.Item
                name="title"
                label={strings.TITLE}
                rules={[{ required: true, message: `${strings.TITLE} ${strings.IS_REQUIRED}` }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item name="branch" label={strings.BRANCH}>
                <Select placeholder={strings.BRANCH} allowClear onChange={onChangeBranch}>
                  {branches.map((branch: Branch) => (
                    <Option key={branch.id} value={branch.id}>
                      {branch.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item name="performer" label={strings.PERFORMER}>
                <Select placeholder={strings.PERFORMER} allowClear loading={hasPerformerLoading}>
                  {performers.map((performer: Performer) => (
                    <Option value={performer.id} key={performer.id}>
                      {performer.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={24}>
              <Form.Item
                name="body"
                label={strings.BODY}
                rules={[{ required: true, message: `${strings.BODY} ${strings.IS_REQUIRED}` }]}
              >
                <Input.TextArea placeholder={strings.BODY} rows={6} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={6}>
              <Button type="primary" size="large" htmlType="submit" loading={hasLoading}>
                {id ? strings.EDIT : strings.ADD}
              </Button>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Container>
  );
};
