import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, Row, Col, Typography, Checkbox, Spin, Button, message } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useAssessmentsRepository } from 'repos/AssessmentsRepository';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { IAssessmentCalendarLessonsByTeacher, IAssessmentCalendarParams } from 'types/accessment';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { dateFormat, dateMonthFormat } from 'helpers/dates';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';

interface LessonsModalProps {
  form: WrappedFormUtils;
  teacher: number;
  dateFrom: string;
  dateTo: string;
  hideModal: () => void;
  hasShowModal: boolean;
  refetchTable: (params: IAssessmentCalendarParams) => Promise<any>;
}

/**
 * @return {React.ReactNode}
 */

export const LessonModal = Form.create<LessonsModalProps>({})((props: LessonsModalProps) => {
  const [strings] = useLanguageContext();
  const {
    teacher,
    dateFrom,
    dateTo,
    hasShowModal,
    hideModal,
    refetchTable,
    form: { getFieldDecorator, validateFields }
  } = props;
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [lessonsInfo, setLessonsInfo] = useState<IAssessmentCalendarLessonsByTeacher[]>([]);
  const assessmentsRepository = useAssessmentsRepository();
  const { locationSearchParams } = useLocationSearchParams();

  const getCalendarLessons = async (): Promise<any> => {
    const params = { dateTo, dateFrom };
    setHasLoading(true);
    try {
      const { data } = await assessmentsRepository.getCalendarLessonsByTeacher(teacher, params);
      setLessonsInfo(data);
    } finally {
      setHasLoading(false);
    }
  };
  const getTitle = () => {
    const weekDates = `${dayjs(dateFrom).format(dateMonthFormat)}-${dayjs(dateTo).format(dateMonthFormat)}`;
    return `${strings.LESSON_FOR_ASSESSMENT} (${weekDates})`;
  };

  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFields(async (err, values): Promise<any> => {
      if (!err) {
        const allLessonIds = lessonsInfo.map(item => item.lesson.id);
        const { lessonsChecked } = values;
        const lessonsUnchecked = allLessonIds.filter(item => !lessonsChecked.includes(item));
        const params = { lessonsUnchecked, lessonsChecked };
        setHasLoading(true);
        try {
          await assessmentsRepository.scheduleAssessments(params);
          await refetchTable(locationSearchParams);
          message.success(`${strings.SUCCESSFULLY_CHANGED} :)`, 2);
          hideModal();
        } catch {
          message.error(`${strings.ERROR_TRY_AGAIN_LATER} :(`, 2);
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  const modalFooter = [
    <Button danger onClick={hideModal}>
      {strings.CANCEL}
    </Button>,
    <Button type="primary" htmlType="submit" onClick={handleSubmit} loading={hasLoading}>
      {strings.SAVE}
    </Button>
  ];

  const initValues = lessonsInfo.filter(item => item.scheduled).map(item => item.lesson.id);

  useEffect(() => {
    getCalendarLessons();
  }, []);

  return (
    (<Modal
      width={600}
      title={getTitle()}
      open={hasShowModal}
      footer={lessonsInfo.isNotEmpty() && modalFooter}
      onCancel={hideModal}
      destroyOnClose={true}
    >
      <Spin spinning={hasLoading}>
        {lessonsInfo.isEmpty() ? (
          <p>{strings.NO_DATA}</p>
        ) : (
          <Form layout="vertical">
            {getFieldDecorator('lessonsChecked', { initialValue: initValues })(
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  {lessonsInfo.map(item => (
                    <Col lg={24} style={{ paddingBottom: 10 }}>
                      <Checkbox value={item.lesson.id}>
                        <Link to={`/le/${item.lesson.id}`}>{item.lesson.title}</Link>{' '}
                        <span style={{ fontSize: 14, opacity: 0.8 }}>
                          {dayjs(item.lesson.startAt).format(dateFormat)}{' '}
                          <Link to={`/group/${item.lesson.courseGroup.id}`}>{item.lesson.courseGroup.groupName}</Link>
                        </span>
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            )}
          </Form>
        )}
      </Spin>
    </Modal>)
  );
});

export default { LessonModal };
