import React, { useState, useEffect } from 'react';
import { match } from 'react-router';
import { Col, Spin, Button, message, Input, Row, Checkbox, Form } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useSubjectsRepository } from 'repos/SubjectsRepository';
import { ISubjectPostParams } from 'types/education';
import { BASE_SUBJECTS } from '../routes';
import { TrainingSelect } from 'Global/components/FormComponents';

export const SubjectForm = () => {
  const [strings] = useLanguageContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const { goBack, push } = useHistory();
  const [form] = Form.useForm();

  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();

  const subjectRepository = useSubjectsRepository();

  const getSubjectById = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await subjectRepository.getSubjectById(id);
      const { name, nameRG, isActive, comment, trainings } = data;
      const initVal = { name, nameRG, comment, isActive, trainings: trainings.map(item => item.id) };
      form.setFieldsValue(initVal);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  const onSubmit = async (params: ISubjectPostParams): Promise<any> => {
    setHasLoading(true);
    try {
      if (id) {
        await subjectRepository.editSubject(id, params);
        message.success(strings.SUCCESSFULLY_UPDATED, 2);
      } else {
        await subjectRepository.createSubject(params);
        message.success(strings.SUCCESSFULLY_CREATED, 2);
      }
      push(BASE_SUBJECTS);
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getSubjectById();
    }
  }, [id]);

  return (
    <Container>
      <StPageHeader
        onBack={goBack}
        title={id ? `${strings.EDIT} ${strings.SUBJECT_LOW}` : `${strings.CREATE} ${strings.SUBJECT_LOW}`}
      />
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Spin spinning={hasLoading}>
          <Row gutter={[10, 10]} align="middle">
            <Col xs={24} lg={6}>
              <Form.Item
                name="name"
                label={strings.NAME}
                rules={[{ required: true, message: `${strings.NAME} ${strings.IS_REQUIRED}` }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item name="nameRG" label={strings.NAME_FOR_RG}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item name="trainings" label={strings.TRAININGS}>
                <TrainingSelect mode="multiple" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={20}>
              <Form.Item name="comment" label={strings.COMMENT}>
                <Input.TextArea placeholder={strings.COMMENT} />
              </Form.Item>
            </Col>
            <Col xs={12} lg={8}>
              <Form.Item name="isActive" valuePropName="checked">
                <Checkbox>{strings.ACTIVE}</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={6}>
              <Button type="primary" size="large" htmlType="submit" loading={hasLoading}>
                {id ? strings.EDIT : strings.ADD}
              </Button>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Container>
  );
};
