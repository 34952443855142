import React, { useState, useEffect } from 'react';
import { DeleteFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import { Row, Button, Modal, message } from 'antd';
import { Loader } from 'Global/components/Loader/Loader';
import { Draggble } from 'Global/components/DraggbleComponent';
import { DraggbleApi } from 'api/Teachers/draggble';
import { Card, CardsList } from './styles';
import { Teacher } from 'types/Teachers/teacher';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { useTeacherRepository } from 'repos/TeacherRepository';
import { useLanguageContext } from 'contexts/LanguageContext';
import { FIRST_YEAR_ACTIVE, LONG_TERM_ACTIVE, SECOND_YEAR_ACTIVE } from '../../../People/Teachers/constants';

interface TeachersProps {
  groupId: number;
  group: any;
}

/**
 * @description Вкладка Teachers
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const Teachers = (props: TeachersProps) => {
  const [strings] = useLanguageContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [allMainSubjectsTeachers, setAllMainSubjectsTeachers] = useState<Teacher[]>([]);
  const [allAdditionalSubjectsTeachers, setAllAdditionalSubjectsTeachers] = useState<Teacher[]>([]);
  const [facultyTeachers, setFacultyTeachers] = useState<Teacher[]>([]);
  const [additionalSubstrituteTeachers, setAdditionalTeachers] = useState<Teacher[]>([]);
  const { groupId, group } = props;
  const coursesRepository = useCoursesRepository();
  const teacherRepository = useTeacherRepository();
  const activeStatuses = [FIRST_YEAR_ACTIVE, SECOND_YEAR_ACTIVE, LONG_TERM_ACTIVE];
  const [{ confirm }, contextHolder] = Modal.useModal();

  const fetchTeachersByGroup = async (): Promise<any> => {
    setLoading(true);
    try {
      const { data } = await coursesRepository.getTeachersByGroup(groupId);
      setFacultyTeachers(data?.filter(item => item?.isMain).map(item => item?.teacher));
      setAdditionalTeachers(data?.filter(item => !item?.isMain).map(item => item?.teacher));
    } finally {
      setLoading(false);
    }
  };

  const fetchMainSubjectsTeachers = async (): Promise<any> => {
    try {
      const {
        data: { items }
      } = await teacherRepository.getTeachers({
        mainSubject: [group?.subject?.id],
        branch: group?.branch.id,
        limit: 10000,
        hasActiveStatus: true,
        status: activeStatuses
      });
      setAllMainSubjectsTeachers(items);
    } catch {
      setAllMainSubjectsTeachers([]);
    }
  };
  const fetchAdditionalSubjectsTeachers = async (): Promise<any> => {
    try {
      const {
        data: { items }
      } = await teacherRepository.getTeachers({
        subject: [group?.subject?.id],
        branch: group?.branch.id,
        limit: 10000
      });
      setAllAdditionalSubjectsTeachers(items);
    } catch {
      setAllAdditionalSubjectsTeachers([]);
    }
  };

  const filteredMainSubjectsTeachers = allMainSubjectsTeachers.filter(
    ({ id }) => !facultyTeachers.map(teacher => teacher?.id).includes(id)
  );
  const filteredAdditionalSubjectsTeachers = allAdditionalSubjectsTeachers.filter(
    ({ id }) => !additionalSubstrituteTeachers.map(teacher => teacher?.id).includes(id)
  );

  const moveTeacher = async (idCourse, idTeacher, isMain): Promise<any> => {
    setLoading(true);
    try {
      await DraggbleApi.moveTeacher(idCourse, idTeacher, { isMain });
      fetchTeachersByGroup();
    } catch {
      message.error(strings.SORRY_ERROR, 2);
    } finally {
      setLoading(false);
    }
  };

  const moveDeleteTeacher = async (idTeacher: number, isMain: boolean): Promise<any> => {
    setLoading(true);
    try {
      await DraggbleApi.moveDeleteTeacher(groupId, idTeacher, { isMain });
      fetchTeachersByGroup();
    } catch {
      message.error(strings.SORRY_ERROR, 2);
    } finally {
      setLoading(false);
    }
  };

  const showDeleteConfirm = (idCard: number, isMain: boolean) => {
    confirm({
      title: strings.ARE_YOU_SURE,
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        await moveDeleteTeacher(idCard, isMain);
      }
    });
  };

  useEffect(() => {
    if (groupId) {
      fetchTeachersByGroup();
      fetchMainSubjectsTeachers();
      fetchAdditionalSubjectsTeachers();
    }
  }, [groupId]);

  const CardComponent = (props: any) => {
    const columnId = props?.column?.id;
    const isMain = columnId === 'faculty' ? true : false;

    return (
      <Card className="card">
        {contextHolder}
        <Row justify="space-between" align="middle">
          <span>
            {props?.user?.surname} {props?.user?.name}
          </span>
          {(columnId === 'faculty' || columnId === 'additional_substritute') && (
            <Button
              onClick={() => showDeleteConfirm(props.idCard, isMain)}
              type="link"
              style={{ color: '#ff4d4f', fontSize: 18, height: 'auto', padding: 0 }}
            >
              <DeleteFilled />
            </Button>
          )}
        </Row>
      </Card>
    );
  };

  const draggbleHandler = async (result): Promise<any> => {
    const columnId = result?.destination?.droppableId;
    if (columnId === 'faculty' || columnId === 'additional_substritute') {
      moveTeacher(groupId, result.draggableId.replace(/_.+/, ''), columnId === 'faculty' ? true : false);
    } else {
      message.warning(strings.YOU_CANT_DO_IT);
    }
  };

  const checkArray = (array: Teacher[], columnId: string): any[] => {
    const arr = array || [];
    return arr.map((item, i) => {
      return { ...item, idCard: item.id, id: `${item.id}_${i}_${columnId}`, column: { id: columnId } };
    });
  };

  return (
    <Loader spinning={loading}>
      <Row style={{ marginBottom: 24 }}>
        <Draggble
          onDraggble={draggbleHandler}
          columnsData={[
            { id: 'faculty', title: strings.FACULTY_TEACHING_THE_GROUP },
            { id: 'main', title: strings.MAIN_SUBJECTS },
            { id: 'additional_substritute', title: strings.ADDITIONAL_SUBSTITUTE_TEACHERS },
            { id: 'additional', title: strings.ADDITIONAL_SUBJECTS }
          ]}
          CardComponent={CardComponent}
          cards={[
            ...checkArray(facultyTeachers, 'faculty'),
            ...checkArray(filteredMainSubjectsTeachers, 'main'),
            ...checkArray(additionalSubstrituteTeachers, 'additional_substritute'),
            ...checkArray(filteredAdditionalSubjectsTeachers, 'additional')
          ]}
          Container={CardsList}
          stylesForColumns={{ width: '50%', padding: 5, minHeight: 100, maxHeight: 450, marginBottom: 20 }}
          stylesForColumn={{ overflowY: 'auto' }}
        />
      </Row>
    </Loader>
  );
};

export default { Teachers };
