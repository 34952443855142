import React, { useState } from 'react';
import { Button, Col, Row } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';

interface FilterButtonsProps {
  handleClear: () => Promise<any>;
  handleSubmit?: () => Promise<any>;
  isSameRow?: boolean;
  extra?: React.ReactNode;
  hasSubmitLoading?: boolean;
}

export const FilterButtons = ({
  handleClear,
  isSameRow,
  extra,
  hasSubmitLoading,
  handleSubmit
}: FilterButtonsProps) => {
  const [strings] = useLanguageContext();
  const [hasClearLoading, setHasClearLoading] = useState<boolean>(false);

  const handleClearFilter = async (): Promise<any> => {
    setHasClearLoading(true);
    try {
      await handleClear();
    } catch {
    } finally {
      setHasClearLoading(false);
    }
  };

  return isSameRow ? (
    <Col xs={24} style={{ display: 'flex', gap: 10, flex: 1 }}>
      <Button onClick={handleClearFilter} size="large" loading={hasClearLoading}>
        {strings.CLEAR_FILTER}
      </Button>
      <Button type="primary" htmlType="submit" size="large" loading={hasSubmitLoading} onClick={handleSubmit}>
        {strings.FILTER}
      </Button>
      {extra}
    </Col>
  ) : (
    <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
      <Col xs={24} lg={24} style={{ display: 'flex', gap: 10 }}>
        {extra}
        <Button onClick={handleClear} size="large" loading={hasClearLoading}>
          {strings.CLEAR_FILTER}
        </Button>
        <Button type="primary" htmlType="submit" size="large" loading={hasSubmitLoading} onClick={handleSubmit}>
          {strings.FILTER}
        </Button>
      </Col>
    </Row>
  );
};

export default { FilterButtons };
