import React, { useEffect, useState } from 'react';
import { Table, message, Button, Row } from 'antd';
import { columns } from './columns';
import { Filter } from './Filter';
import { AddSupergroupModal } from './Add';
import { Supergroup } from 'types';
import { SupergroupListParams } from 'types/courses';
import { useUserContext } from 'contexts/UserContext';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Link, useHistory } from 'react-router-dom';
import { SUPERGROUPS_GENERATOR } from '../routes';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import queryString from 'query-string';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { isEmpty } from 'lodash';

/**
 * @return {React.ReactNode}
 */
export const SuperGroups = () => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const hasTopManager = user?.hasRoles?.topManager;
  const [supergroups, setSupergroups] = useState<Supergroup[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [visibleAddSupergroup, setVisibleAddSupergroup] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = selectedRowKeys => setSelectedRowKeys(selectedRowKeys);
  const coursesRepository = useCoursesRepository();
  const { goBack, push } = useHistory();
  const { locationSearchParams } = useLocationSearchParams();

  const hideModalAddSupergroup = () => setVisibleAddSupergroup(false);
  const openModalAddSupergroup = () => setVisibleAddSupergroup(true);

  const getSupergroups = async (superGroupsParams?: SupergroupListParams): Promise<any> => {
    let params = {};
    setLoading(true);

    if (hasTopManager) {
      params = { byStages: 0, ...superGroupsParams };
    } else {
      if (user?.branch?.id) {
        params = { byStages: 0, branch: user?.branch?.id, ...superGroupsParams };
      }
    }

    try {
      const { data } = await coursesRepository.getSuperGroups(params);
      setSupergroups(data);
      push({ search: queryString.stringify(params) });
      setLoading(false);
    } catch {
      setLoading(false);
      setSupergroups([]);
    }
  };

  /**
   * Bulk деактивация супергрупп
   */
  const onDeactivateSuperGroups = async () => {
    try {
      await coursesRepository.superGroupsBulkDeactivate(selectedRowKeys);
      await getSupergroups();
      setSelectedRowKeys([]);
      message.success(strings.SUCCESSFULLY_DEACTIVATED, 2);
    } catch {
      message.error(strings.DEACTIVATED_ERROR_TRY_AGAIN_LATER, 2);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  const getSubtitle = () => {
    if (supergroups?.length) {
      return `${strings.TOTAL_NUMBER_OF_SUPERGROUPS} ${supergroups?.length}`;
    }

    return null;
  };

  useEffect(() => {
    if (isEmpty(locationSearchParams)) {
      getSupergroups();
    } else {
      getSupergroups(locationSearchParams);
    }
  }, [user]);

  return (
    <Container>
      <StPageHeader
        onBack={goBack}
        title={strings.SUPERGROUPS}
        subTitle={getSubtitle()}
        extra={[
          <Button onClick={openModalAddSupergroup}>{strings.NEW_EMPTY}</Button>,
          <Button>
            <Link to={SUPERGROUPS_GENERATOR}>{strings.NEW_GROUPS_GENERATOR}</Link>
          </Button>
        ]}
      >
        <Filter
          selectedRowKeys={selectedRowKeys}
          onDeactivateSuperGroups={onDeactivateSuperGroups}
          getSupergroups={getSupergroups}
        />
      </StPageHeader>
      <Row gutter={[10, 10]}>
        <Table
          rowSelection={rowSelection}
          dataSource={supergroups}
          columns={columns(hasTopManager, strings)}
          pagination={false}
          rowKey="id"
          loading={{ tip: strings.WAIT_WE_ARE_LOADING_SUPERGROUPS, spinning: loading }}
        />
      </Row>
      <AddSupergroupModal
        visible={visibleAddSupergroup}
        getSupergroups={getSupergroups}
        hideModal={hideModalAddSupergroup}
      />
    </Container>
  );
};

export default { SuperGroups };
