import React, { useState, useEffect, useCallback } from 'react';
import { Select, SelectProps, Spin } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import debounce from 'lodash/debounce';
import { useTeacherRepository } from 'repos/TeacherRepository';
import { ITeacherGetParams, Teacher } from 'types/Teachers/teacher';

const { Option } = Select;

type TeacherSubset = Partial<Teacher>;

interface AsyncSelectProps extends SelectProps {
  initOptions?: TeacherSubset[];
  value?: number;
  placeholder?: string;
  onChange?: (value: number) => void;
  mode?: 'multiple' | 'tags';
  filterValues?: ITeacherGetParams;
  disabled?: boolean;
}

const TeacherSelect: React.FC<AsyncSelectProps> = ({
  value,
  initOptions,
  placeholder,
  onChange,
  mode,
  filterValues = {},
  disabled = false
}) => {
  const [strings] = useLanguageContext();
  const teacherRepository = useTeacherRepository();
  const [options, setOptions] = useState<TeacherSubset[]>([]);
  const [hasLoading, setHasLoading] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [typing, setTyping] = useState<Boolean>(false);

  const fetchTeachers = async (query: string): Promise<any> => {
    setSearchValue(query);
    if (query) {
      setHasLoading(true);
      try {
        const {
          data: { items }
        } = await teacherRepository.getTeachers({ query, ...filterValues });
        setOptions(items);
      } catch {
      } finally {
        setHasLoading(false);
      }
    }
  };

  const getTeacherById = async (teacherId: string | number): Promise<any> => {
    try {
      const { data } = await teacherRepository.getTeacherById(teacherId);
      setOptions([data]);
    } catch {}
  };

  const getTeachersByIds = async (value): Promise<any> => {
    const teachers = [];
    for (const teacherId of value) {
      try {
        const { data } = await teacherRepository.getTeacherById(teacherId);
        teachers.push(data);
      } catch {}
    }
    try {
      const results = await Promise.all(teachers);
      setOptions(results);
    } catch {}
  };

  const getUserName = useCallback(item => {
    return `${item?.user?.surname || item?.surname} ${item?.user?.name || item?.name}`;
  }, []);

  useEffect(() => {
    if (value) {
      if (mode === 'multiple') {
        getTeachersByIds(value);
      } else {
        getTeacherById(value);
      }
    }
  }, [JSON.stringify(value)]);

  useEffect(() => {
    if (initOptions) {
      setOptions(initOptions);
    }
  }, [initOptions]);

  useEffect(() => {
    if (searchValue) {
      setTyping(true);
    } else {
      setTyping(false);
    }
  }, [searchValue]);

  return (
    <Select
      showSearch
      value={value}
      onChange={val => onChange(val)}
      allowClear
      placeholder={placeholder}
      onSearch={debounce(fetchTeachers, 800)}
      loading={hasLoading}
      mode={mode}
      disabled={disabled}
      maxTagCount="responsive"
      filterOption={false}
      notFoundContent={hasLoading ? <Spin size="small" /> : <p>{!typing ? strings.START_TYPING : strings.NOT_FOUND}</p>}
    >
      {options.map(option => (
        <Option key={option?.id} value={option?.id}>
          {getUserName(option)}
        </Option>
      ))}
    </Select>
  );
};

export default TeacherSelect;
