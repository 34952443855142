import React, { useEffect, useState } from 'react';
import { useScheduleRepository } from 'repos/ScheduleRepostirory';
import { IHomeworkItem } from 'types/homework';
import { PaginationInfo } from 'types/global';
import { Spin, Collapse, Row, Col, Steps, Tag } from 'antd';
import { StCard } from 'Global/GlobalStyle';
import Pagination from 'Global/components/Pagination';
import { scrollTop } from 'helpers';
import { useLanguageContext } from 'contexts/LanguageContext';
import { HomeworkListItem } from './HomeworkListItem';
import dayjs from 'dayjs';
import { Filter } from './Filter';
import { homeworkStatuses } from 'Global/constants';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { IHomeworkFilterParams } from './index';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { getSubjectNameByLang } from '../helpers';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';

const { Panel } = Collapse;
const { Step } = Steps;

export const StSteps = styled(Steps)`
  .ant-steps-item-finish,
  .ant-steps-item-wait {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
`;

export const HomeworkList = ({ studentId }: { studentId: number }) => {
  const [strings, lang] = useLanguageContext();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const scheduleRepository = useScheduleRepository();
  const defFilterValues: IHomeworkFilterParams = {
    withHomeworkAssigned: true,
    subject: undefined,
    startAt: undefined,
    endAt: undefined
  };
  const [hwList, setHwList] = useState<IHomeworkItem[]>([]);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const { locationSearchParams } = useLocationSearchParams();
  const { student } = locationSearchParams;
  const hasStudentByUrl = student === studentId;
  const [filterParams, setFilterParams] = useState<IHomeworkFilterParams>(
    hasStudentByUrl ? locationSearchParams : defFilterValues
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>(null);
  const { pageCount, current } = paginationInfo || {};
  const { subjectsTranslation } = useGlobalCollectionsContext();

  const getStatusData = status => homeworkStatuses.find(item => item.name === status);

  const handleChangePage = async (page: number) => {
    setCurrentPage(page);
    await getHomework(page);
    scrollTop();
  };

  const getHomework = async (page?: number): Promise<any> => {
    const params = { child: studentId, limit: 20, page: page ? page : currentPage, ...filterParams };
    setHasLoading(true);
    try {
      const {
        data: { items, paginationInfo }
      } = await scheduleRepository.getHomeworkList(params);
      setHwList(items);
      setPaginationInfo(paginationInfo);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  const getLessonDate = date => {
    const nextLessonDate = date && dayjs(date);
    const day = nextLessonDate.locale(lang).format('D');
    const month = nextLessonDate.locale(lang).format('MMM');
    return `${day} ${month}`;
  };

  const getHeaderPanel = (item: IHomeworkItem) => {
    const { lesson, status, register } = item;
    const statusData = getStatusData(status);
    const subject = lesson?.courseGroup?.subject;
    return (
      <Row align="middle" gutter={6} style={{ flexWrap: 'nowrap' }}>
        <Col lg={2}>{getLessonDate(lesson?.startAt)}</Col>
        <Col lg={12} sm={18} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>
            <strong>{getSubjectNameByLang(subjectsTranslation, lang, subject?.id)}: </strong>
            {lesson?.title}
          </span>
          {register?.homeworkGrade && <span>{register?.homeworkGrade}%</span>}
        </Col>
        {!isMobile ? (
          <Col lg={10}>
            <StSteps current={statusData?.step} size="small">
              {homeworkStatuses.map(item => (
                <Step
                  title={
                    <span style={{ color: item.color, fontSize: 12 }}>{item.titleByLang[lang] || item.title}</span>
                  }
                />
              ))}
            </StSteps>
          </Col>
        ) : (
          <Col lg={4}>
            <Tag color={statusData?.color}>{statusData?.title}</Tag>
          </Col>
        )}
      </Row>
    );
  };

  useEffect(() => {
    if (studentId) {
      const page = 1;
      setCurrentPage(page);
      getHomework(page);
    }
  }, [filterParams]);

  return (
    <StCard>
      <Filter
        studentId={studentId}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        defFilterValues={defFilterValues}
      />
      <Spin spinning={hasLoading}>
        <Collapse expandIconPosition="right" style={{ marginTop: 20 }}>
          {hwList.map((item: IHomeworkItem, index) => (
            <Panel header={getHeaderPanel(item)} key={index}>
              <HomeworkListItem homework={item} refetchHW={getHomework} />
            </Panel>
          ))}
        </Collapse>
        <Pagination count={pageCount} current={current} pageChange={handleChangePage} />
      </Spin>
    </StCard>
  );
};
