import React, { useState, useEffect } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Modal, Spin, List, message, Descriptions, Flex } from 'antd';
import { MaterialList, ScheduleLesson, LessonFile } from 'types/Schedule';
import { LessonInfo } from 'Global/modules/Schedule/styles';
import { Schedule } from 'api/Schedule';
import { createTextLinks, getAttachmentLink, downloadFileByUrl } from 'helpers';
import { dateFormat } from 'helpers/dates';
import dayjs from 'dayjs';
import ZoomIcon from 'img/zoom.png';
import styled from 'styled-components';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { getSubjectNameByLang } from 'Student&Parent/helpers';
import { StDivider } from 'Student&Parent/styles';
import { Html } from 'Global/components/Html';

const mockMaterial = {
  id: 0,
  type: 'lesson_bonus' as 'lesson_bonus',
  list: [],
  create: false,
  delete: false,
  print: false
};

const mockMaterials: MaterialList = {
  lessonMaterial: mockMaterial,
  lessonHomework: mockMaterial,
  lessonBonus: mockMaterial,
  lessonTest: mockMaterial
};

interface LessonModalProps {
  hideModal: () => void;
  loading?: boolean;
  values: ScheduleLesson;
  hasShow: boolean;
}

const StyledList = styled(List)`
  .ant-list-item {
    border: none;
  }
`;

/**
 * @description Модальное окно урока у родителя
 * @param {object} props объект props
 * @return {React.ReactNode}
 */
export const LessonModalParent = (props: LessonModalProps) => {
  const { hideModal, values, loading, hasShow } = props;
  const {
    title,
    id,
    subject,
    teacher,
    slot,
    homework,
    material,
    slot: { room }
  } = values;

  const [strings, lang] = useLanguageContext();
  const { subjectsTranslation } = useGlobalCollectionsContext();
  const [materials, setMaterials] = useState<MaterialList>(mockMaterials);
  const [hasLoadMaterials, setHasLoadMaterials] = useState<boolean>(true);

  /**
   * @description Получение вложений к уроку
   * @return {Promise<any>}
   */
  const getLessonAttachments = async (id: number): Promise<any> => {
    try {
      const { data } = await Schedule.getLessonFiles(id);
      setMaterials(data);
      setHasLoadMaterials(false);
    } catch {
      message.error('Get lesson materials error :(');
      setHasLoadMaterials(false);
    }
  };

  /**
   * @description Скачивание файла
   * @param {number} id
   * @param {string} name
   * @return {Promise<any>}
   */
  const onDownloadFile = async (id: number, name: string): Promise<any> => {
    setHasLoadMaterials(true);
    const attachLink: string = getAttachmentLink(id);
    try {
      await downloadFileByUrl(attachLink, name);
    } finally {
      setHasLoadMaterials(false);
    }
  };

  const getLessonFiles = (attachments: LessonFile[]) => {
    return attachments.map((attach: LessonFile) => (
      <p style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
        <Button
          size="small"
          onClick={() => onDownloadFile(attach.id, attach.name)}
          icon={<DownloadOutlined />}
          style={{ marginInlineEnd: 8 }}
          type="primary"
        />
        {attach.name}
      </p>
    ));
  };

  useEffect(() => {
    if (id !== 0) getLessonAttachments(id);
  }, [id]);

  return (
    <Modal
      title={title || strings.LESSON}
      style={{ top: 50, overflowY: 'auto' }}
      footer={[
        <Button key="back" onClick={hideModal}>
          {strings.CANCEL}
        </Button>
      ]}
      open={hasShow}
      onCancel={hideModal}
      width={900}
    >
      <LessonInfo>
        <Spin tip={strings.LOADING} spinning={loading}>
          <Descriptions>
            {teacher?.name && (
              <Descriptions.Item label={strings.TEACHER}>
                {teacher?.name} {teacher?.surname}
              </Descriptions.Item>
            )}
            {subject?.id && (
              <Descriptions.Item label={strings.SUBJECT}>
                {getSubjectNameByLang(subjectsTranslation, lang, subject.id)}
              </Descriptions.Item>
            )}
            {slot?.startAt && (
              <Descriptions.Item label={strings.LESSON_DATE}>
                {dayjs(slot.startAt).format(`${dateFormat} HH:mm`)}
              </Descriptions.Item>
            )}
            {room?.webLink && (
              <Descriptions.Item label={strings.LINK}>
                <a href={room?.webLink} rel="noopener noreferrer" target="_blank">
                  <Flex align="center">
                    <img src={ZoomIcon} alt="zoom link" width={28} height={28} />
                    <span>{strings.GO_TO_ZOOM}</span>
                  </Flex>
                </a>
              </Descriptions.Item>
            )}
          </Descriptions>
        </Spin>
        <Spin spinning={hasLoadMaterials}>
          <StDivider orientation="left">{strings.CLASSROOM_MATERIALS}</StDivider>
          {material && <Html html={createTextLinks(material)} />}
          {materials?.lessonMaterial.list.isNotEmpty() ? (
            getLessonFiles(materials?.lessonMaterial?.list)
          ) : (
            <p>{strings.NO_MATERIALS_ATTACHED}</p>
          )}
          <StDivider orientation="left">{strings.HOMEWORK}</StDivider>
          {homework && <Html html={createTextLinks(homework)} />}
          {materials?.lessonHomework.list.isNotEmpty() ? (
            getLessonFiles(materials?.lessonHomework.list)
          ) : (
            <p>{strings.NO_MATERIALS_ATTACHED}</p>
          )}
          <StDivider orientation="left">{strings.ADDITIONAL_MATERIALS}</StDivider>
          {materials?.lessonBonus.list.isNotEmpty() ? (
            getLessonFiles(materials?.lessonBonus.list)
          ) : (
            <p>{strings.NO_ADDITIONAL_MATERIALS_ATTACHED}</p>
          )}
          <StDivider orientation="left">{strings.TESTS}</StDivider>
          {materials?.lessonTest.list.isNotEmpty() ? (
            getLessonFiles(materials?.lessonTest.list)
          ) : (
            <p>{strings.NO_TESTS_ATTACHED}</p>
          )}
        </Spin>
      </LessonInfo>
    </Modal>
  );
};

export default { ParentModal: LessonModalParent };
