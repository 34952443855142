import React from 'react';
import styled from 'styled-components';

const StHtml = styled.div`
  a,
  p {
    word-break: break-word;
    line-height: normal;
  }
`;

interface HtmlProps {
  html: string;
}

/**
 * @description Рендеринг html
 * @param {string} Html
 * @return {React.ReactNode}
 */
export const Html = ({ html }: HtmlProps) => {
  return <StHtml dangerouslySetInnerHTML={{ __html: html }} />;
};

export default { Html };
