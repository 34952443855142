import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Input, Modal, Spin, Form, Flex } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Tag } from 'types';
import { useTagsRepository } from 'repos/TagsRepository';

export enum TagType {
  family = 'family',
  teacher = 'teacher'
}

interface TagsFormProps {
  handler: () => Promise<any>;
  id?: number;
  type: TagType;
  hasShowModal: boolean;
  setHasShowModal: (val: boolean) => void;
}

export const TagsFormModal = ({ handler, id, hasShowModal, setHasShowModal, type }: TagsFormProps) => {
  const [form] = Form.useForm();
  const [strings] = useLanguageContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [tag, setTag] = useState<Tag>(null);
  const tagsRepository = useTagsRepository();

  const handleSubmit = async (params): Promise<any> => {
    setHasLoading(true);
    try {
      if (id) {
        if (type === TagType.teacher) {
          await tagsRepository.editTeacherTag(id, params);
        } else {
          await tagsRepository.editFamilyTag(id, params);
        }
      } else {
        if (type === TagType.teacher) {
          await tagsRepository.addTeacherTag(params);
        } else {
          await tagsRepository.addFamilyTag(params);
        }
      }
      handler();
      closeModal();
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  const closeModal = (): void => {
    setHasShowModal(false);
    form.resetFields();
  };

  const getTagInfo = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const fetchTag = type === TagType.teacher ? tagsRepository.getTeacherTagById : tagsRepository.getFamilyTagById;

      const { data } = await fetchTag(id);
      setTag(data);
      form.setFieldsValue(data);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    if (hasShowModal) {
      if (id) {
        getTagInfo();
      } else {
        setTag(null);
      }
    }
  }, [hasShowModal]);

  const titleCreation = type === TagType.teacher ? strings.ADD_A_NEW_TEACHER_TAG : strings.ADD_A_NEW_FAMILY_TAG;

  return (
    <Modal
      title={id ? `${strings.EDIT} ${tag?.name}` : titleCreation}
      footer={false}
      open={hasShowModal}
      onCancel={closeModal}
      style={{ top: 20 }}
      width={450}
      destroyOnClose={true}
    >
      <Spin spinning={hasLoading}>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Row gutter={[10, 10]}>
            <Col lg={24}>
              <Form.Item name="name" label={strings.NAME} rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item name="comment" label={strings.COMMENT}>
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Flex justify="end" gap={10}>
            <Button key="back" onClick={closeModal}>
              {strings.CANCEL}
            </Button>
            <Button type="primary" htmlType="submit">
              {id ? strings.EDIT : strings.ADD}
            </Button>
          </Flex>
        </Form>
      </Spin>
    </Modal>
  );
};

export default { TagsFormModal };
