import React, { useEffect, useState } from 'react';
import { Table, Button, message, Modal, Row } from 'antd';
import { columns } from './columns';
import { Filter } from './Filter';
import { CRM } from 'api/CRM';
import Pagination from 'Global/components/Pagination';
import { scrollTop } from 'helpers/scroll';
import { BASE_MESSAGES_FROM_PARENTS } from '../routes';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { PaginationInfo } from 'types/global';
import { ThreadsParams, Thread, IThread } from 'types/CRM/messages';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useHistory } from 'react-router-dom';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import queryString from 'query-string';
import { useThreadsRepository } from 'repos/ThreadsRepository';

const { Column } = Table;

interface MessagesFromParentsProps {
  form: WrappedFormUtils;
}
const LIMIT = 10;

/**
 * @description Messages from parents component
 * @param {MessagesFromParentsProps} props
 * @return {React.ReactNode}
 */
export const MessagesFromParents = () => {
  const [strings] = useLanguageContext();
  const [threads, setThreads] = useState<IThread[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>();

  const messagesFromParentRepository = useThreadsRepository();

  const { push, goBack } = useHistory();
  const { locationSearchParams } = useLocationSearchParams();
  const [user] = useUserContext();
  const [{ confirm }, contextHolder] = Modal.useModal();

  /**
   * @description Загрузка сообщений
   * @param {ThreadsParams} params
   * @return {Promise<any>}
   */
  const getThreads = async (params?: ThreadsParams): Promise<any> => {
    setLoading(true);
    params['limit'] = LIMIT;
    try {
      const {
        data: { items, paginationInfo }
      } = await messagesFromParentRepository.getThreads(params);
      setThreads(items);
      setPaginationInfo(paginationInfo);
      push({ search: queryString.stringify(params) });
    } finally {
      setLoading(false);
    }
  };

  // Топ-менеджер - все филиалы, остальные только свой.
  const hasTopManagerRole = user?.hasRoles?.topManager;
  const branch = hasTopManagerRole ? undefined : user?.branch?.id;
  const { pageCount, current } = paginationInfo || {};

  /**
   * @description Смена страницы в таблице
   * @param {number} page
   * @return {void}
   */
  const handlePage = (page: number): void => {
    getThreads({ ...locationSearchParams, page });
    scrollTop();
  };

  /**
   * @description Модалка для удаления сообщения
   * @param {number} threadId
   * @param {React.MouseEvent<HTMLElement, MouseEvent>} e
   * @return {void}
   */
  const confirmDeleteHistory = (threadId: number, e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    e.stopPropagation();
    confirm({
      title: strings.DO_YOU_WANT_TO_DELETE_MESSAGE_HISTORY,
      async onOk() {
        try {
          await CRM.deleteThread(threadId);
          message.success(strings.HISTORY_SUCCESSFULLY_DELETED, 2);
          getThreads(locationSearchParams);
        } catch {
          message.error(strings.HISTORY_DELETED_ERROR, 2);
        }
      },
      maskClosable: true
    });
  };

  // Колонка с кнопками для таблиц Not answered, Archive
  const actionColumns = {
    title: strings.ACTIONS,
    key: 'actions',
    className: 'clmn',
    width: 120,
    render: ({ id }: IThread) => (
      <Button danger onClick={e => confirmDeleteHistory(id, e)}>
        {strings.CLEAR_HISTORY}
      </Button>
    )
  };

  useEffect(() => {
    // Дождаться пока появятся роли, чтобы проверить это топ менеджер или обычный админ
    if (user?.roles) {
      getThreads({ branch, ...locationSearchParams, page: 1 });
    }
  }, [branch, user]);

  return (
    <Container>
      {contextHolder}
      <StPageHeader onBack={goBack} title={strings.MESSAGES_FROM_PARENTS}>
        <Filter onFilter={getThreads} />
      </StPageHeader>
      <Row gutter={[10, 10]}>
        <Table dataSource={threads} className="table-vertical-top" loading={loading} pagination={false}>
          {[...columns(getThreads, current, strings), actionColumns].map(col => (
            <Column key={col.key} {...col} />
          ))}
        </Table>
        {pageCount > 1 && <Pagination count={pageCount} current={current} pageChange={handlePage} />}
      </Row>
    </Container>
  );
};

export default { MessagesFromParents };
