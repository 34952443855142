import React, { useEffect, useState } from 'react';
import { Card, Select, Input, Button, message } from 'antd';
import { AssessmentItem } from 'types/accessment';
import { Status } from 'types';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useAssessmentsRepository } from 'repos/AssessmentsRepository';

const { Option } = Select;
const { TextArea } = Input;

interface LessonRecordingProps {
  id: number;
  onSuccessUpdate?: () => void;
  assessmentItem: AssessmentItem;
}

/**
 * @description Отправка статуса и ссылок на видео
 */
export const LessonRecording = ({ id, onSuccessUpdate, assessmentItem: { lessonRecording } }: LessonRecordingProps) => {
  const [statuses, setStatuses] = useState<Status[]>([]);
  const [selectStatus, setSelectStatus] = useState<number>(null);
  const [links, setLinks] = useState<string>('');
  const [strings] = useLanguageContext();
  const assessmentsRepository = useAssessmentsRepository();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await assessmentsRepository.getAssessmentStatuses();
        setStatuses(data);
      } catch {
        setStatuses([]);
      }
    })();
  }, []);

  useEffect(() => {
    setLinks(lessonRecording?.video);
  }, [lessonRecording?.video]);

  const handleSubmit = async () => {
    const params = {
      type: 'lessonRecording',
      assessmentStatus: selectStatus,
      assessmentVideo: links
    };
    try {
      await assessmentsRepository.editAssessmentByLessonId(id, params);

      message.success(strings.SUCCESS_UPDATE_ASSESSMENT, 3);
      onSuccessUpdate();
    } catch {
      message.error(strings.ERROR_UPDATE_ASSESSMENT, 3);
    }
  };

  return (
    <div>
      <Card title={strings.LESSON_RECORDING}>
        <Select
          value={selectStatus || lessonRecording?.status?.id}
          onChange={id => setSelectStatus(Number(id))}
          placeholder={strings.ASSESSMENT_STATUS}
        >
          {statuses.map((status: Status) => (
            <Option key={status.id} value={status.id}>
              {status.name}
            </Option>
          ))}
        </Select>
        <TextArea
          value={links}
          rows={3}
          style={{ marginTop: 10 }}
          placeholder={strings.ASSESMENT_VIDEO_LINKS}
          onChange={e => setLinks(e.target.value)}
        />
        <div style={{ marginTop: 10 }}>
          <Button onClick={handleSubmit} type="primary">
            {strings.SAVE}
          </Button>
        </div>
      </Card>
    </div>
  );
};
