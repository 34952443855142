import React, { useCallback } from 'react';
import { Flex, InputNumber } from 'antd';
import { generateColumn } from '../../helpers';
import { THandleSetFieldByChildId } from 'types/TeacherAccount/Courses';
import { useLanguageContext } from 'contexts/LanguageContext';
import { StInputPercent } from '../../styles';
import { Child } from 'types';
import { ILessonRegister } from 'types/lesson';
import { useLessonRegisterStore } from 'stores/useLessonRegisterStore';
import { StTitle } from '../styles';

/**
 * @description Рендеринг заголовка колонки max grade
 * @param {number} maxHwGrade
 * @param {function} setMaxHwGrade
 * @return {React.ReactNode}
 */
const TitleHomeworkGrade = () => {
  const [strings] = useLanguageContext();
  const maxHwGrade = useLessonRegisterStore(state => state.maxHwGrade);
  const setMaxHwGrade = useLessonRegisterStore(state => state.setMaxHwGrade);

  const handleChangeMaxGrade = useCallback((val: number) => {
    setMaxHwGrade(val);
  }, []);

  return (
    <Flex vertical gap={5} align="center">
      <StTitle>{strings.HW_GRADE}</StTitle>
      <StInputPercent>
        <span>{strings.MAX_PERCENT}</span>
        <InputNumber
          name="maxhwgrade"
          size="small"
          id="maxhwgrade"
          onChange={handleChangeMaxGrade}
          value={maxHwGrade}
        />
      </StInputPercent>
    </Flex>
  );
};

/**
 * @description Рендеринг колонки homework grade
 * @param {number} homeworkGrade
 * @param {object} student
 * @param {function} handleSetFieldByChildId
 * @param {number} homeworkMarkGrade
 * @return {React.ReactNode}
 */
export const RegisterHomeworkGrade = React.memo(
  ({
    homeworkGrade,
    student,
    handleSetFieldByChildId,
    homeworkMarkGrade
  }: {
    homeworkMarkGrade: number;
    student: Child;
    handleSetFieldByChildId: THandleSetFieldByChildId;
    homeworkGrade: number;
  }) => {
    const maxHwGrade = useLessonRegisterStore(state => state.maxHwGrade);
    const handleChangeHomeworkGrade = useCallback((val: number) => {
      handleSetFieldByChildId('homeworkMarkGrade', student.id, val);
    }, []);

    const calculatePercentage = useCallback(
      (value: number): string => {
        if (!maxHwGrade || maxHwGrade === 0) {
          return `%`;
        }
        const percentage = String(Math.floor((value / maxHwGrade) * 100)).substring(0, 3);
        return `${percentage}%`;
      },
      [maxHwGrade]
    );

    return (
      <StInputPercent>
        <InputNumber value={homeworkMarkGrade} defaultValue={homeworkMarkGrade} onChange={handleChangeHomeworkGrade} />
        <span>{calculatePercentage(homeworkMarkGrade)}</span>
      </StInputPercent>
    );
  }
);

const COLUMN_WIDTH = 110;

/**
 * @description Генерация колонки homework grade
 * @param {string[]} registerColumns
 * @param {number} maxHwGrade
 * @param {function} setMaxHwGrade
 * @param {function} handleSetFieldByChildId
 * @return {object}
 */
export const getHomeworkGradeColumn = (handleSetFieldByChildId: THandleSetFieldByChildId) => {
  return {
    ...generateColumn(() => <TitleHomeworkGrade />, 'homeworkMarkGrade', 'grade', null, null, COLUMN_WIDTH),
    render: (homeworkMarkGrade: number, { homeworkGrade, student, homeworkRequired }: ILessonRegister) => {
      if (!homeworkRequired) {
        return null;
      }

      return (
        <RegisterHomeworkGrade
          student={student}
          homeworkMarkGrade={homeworkMarkGrade}
          homeworkGrade={homeworkGrade}
          handleSetFieldByChildId={handleSetFieldByChildId}
        />
      );
    }
  };
};
