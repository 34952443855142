import React from 'react';
import { Button, Modal, Row, Table, Tooltip } from 'antd';
import { columns, ParentWithAction } from './columns';
import { Parent } from 'types/Family';
import { TablePaginationConfig } from 'antd/lib/table';
import { useLanguageContext } from 'contexts/LanguageContext';
import { IParent } from 'types';
import { Link } from 'react-router-dom';
import { DollarOutlined, EditOutlined, LoginOutlined } from '@ant-design/icons';
import { switchUserModal } from 'helpers/switchUser';
import { BtnCreateAccount } from '../../BtnCreateAccount';
import { getCallPhoneButton } from 'helpers';
import { User } from 'types/user';
const { Column } = Table;

interface ParensTableProps {
  parents: IParent[];
  size?: 'default' | 'middle' | 'small';
  pagination?: TablePaginationConfig | false;
  handleUserRefetch: () => void;
}

/**
 * @description Таблица родителей
 * @param {object} props
 * @return {React.ReactNode}
 */
export const ParentsTable = (props: ParensTableProps) => {
  const { parents, pagination, handleUserRefetch } = props;
  const [strings] = useLanguageContext();
  const [{ confirm }, contextHolder] = Modal.useModal();

  const optionsCol = {
    title: '',
    dataIndex: 'user',
    key: 'action',
    width: 240,
    render: (user: User, parent: ParentWithAction) => {
      const { id, loginAs, handleUserRefetch } = parent;
      const { surname, email, phone, id: userId, name } = user || {};

      return (
        <Row align="middle" style={{ gap: 8, flexWrap: 'nowrap' }}>
          {contextHolder}
          <Link to={`/family/${parent?.familyId}/parent/${id}/edit/`}>
            <Button icon={<EditOutlined />} />
          </Link>
          {loginAs && (
            <Tooltip placement="top" title={`${strings.LOGIN_AS} ${name} ${surname || ''}`}>
              <Button icon={<LoginOutlined />} onClick={() => switchUserModal(loginAs, confirm)} />
            </Tooltip>
          )}
          <BtnCreateAccount
            id={id}
            userId={userId}
            email={email}
            loginAs={loginAs}
            type="parent"
            handleUserRefetch={handleUserRefetch}
            hasLoginEqualsEmail={true}
          />
          {getCallPhoneButton(phone)}
          <Link to={`/family/${parent?.familyId}/finance`}>
            <Button icon={<DollarOutlined />} />
          </Link>
        </Row>
      );
    }
  };

  // Массив родителей с методом создания юзера внутри (для кнопки Create account)
  const parentsWithActions = parents?.map((parent: Parent) => ({ ...parent, handleUserRefetch }));

  return (
    <Table dataSource={parentsWithActions} pagination={pagination} rowKey="id" bordered={false}>
      {[...columns(strings), optionsCol]
        .filter(col => col)
        .map(col => (
          <Column {...col} />
        ))}
    </Table>
  );
};

export default { ParentsTable };
