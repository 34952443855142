import React from 'react';
import { Link } from 'react-router-dom';
import { BASE_TEACHER_ORIGINAL_COURSES } from '../routes';
import { OriginalCourse } from 'types/courses';
import { Stage, Subject } from 'types';

export const columns = strings => [
  {
    title: strings.TITLE,
    key: 'title',
    render: (course: OriginalCourse) => {
      const { id, name } = course;
      return (
        <Link to={`${BASE_TEACHER_ORIGINAL_COURSES}/${id}`}>
          <span style={{ fontSize: 16 }}>{name}</span>
        </Link>
      );
    }
  },
  {
    title: strings.STAGE,
    dataIndex: 'stage',
    key: 'stage',
    render: (stage: Stage) => stage.name
  },
  {
    title: strings.SUBJECTS,
    dataIndex: 'subjects',
    key: 'subjects',
    render: (subjects: Subject[]) =>
      subjects &&
      subjects.map((subject: Subject) => {
        return (
          <span>
            {subject.name}
            <br />
          </span>
        );
      })
  }
];

export default { columns };
