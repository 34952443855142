import { Avatar as AntAvatar, Modal } from 'antd';
import styled from 'styled-components';
import React, { useState } from 'react';
import { getAvatarUrl } from 'helpers';
import { UserOutlined } from '@ant-design/icons';
import { useThemeContext } from 'contexts/ThemeContext';

const avatarStyle = (size: number) => ({
  width: `${size}px`,
  height: `${size}px`,
  borderRadius: `${size / 2}px`,
  position: 'relative',
  objectFit: 'cover'
});

interface IProps {
  photo?: string;
  size?: number;
  icon?: string;
  scale?: number;
  style?: any;
  isHeaderAvatar?: boolean;
  isClickDisabled?: boolean;
  userName?: string;
  userSurname?: string;
}

interface IScale {
  scale?: number;
  isHeaderAvatar?: boolean;
}

const Image = styled.img<IScale>`
  transition: 0.4s;
  position: relative;
  z-index: 100;

  ${props => !props.isHeaderAvatar && `box-shadow: rgb(216, 224, 230) 0px 8px 10px;`}

  &:hover {
    transform: scale(${props => (!props.isHeaderAvatar ? (props.scale ? props.scale : '1.3') : '1')}) !important;
  }
`;

const StyledModalImage = styled.img`
  max-height: 450px;
  object-fit: cover;
`;

/**
 * @description Компонент аватарки
 * @param {string} photo
 * @param {number} size
 * @param {string} icon
 * @return {React.React.Node}
 */
export const Avatar = ({
  photo,
  size = 40,
  icon,
  scale = null,
  style = {},
  isHeaderAvatar = false,
  isClickDisabled = false,
  userName,
  userSurname
}: IProps) => {
  const [errorLoad, onErrorLoad] = useState<boolean>(false);
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const { activeTheme } = useThemeContext();

  const handleOpenPhoto = () => {
    if (photo && !isClickDisabled) setHasShowModal(true);
  };

  return (
    <>
      <Modal
        open={hasShowModal}
        onCancel={() => setHasShowModal(false)}
        footer={false}
        className="modal-photo"
        bodyStyle={{ display: 'flex', justifyContent: 'center' }}
      >
        <StyledModalImage src={getAvatarUrl(photo)} style={{ maxWidth: '100%' }} />
      </Modal>
      {photo && !errorLoad ? (
        <Image
          onClick={handleOpenPhoto}
          src={getAvatarUrl(photo)}
          onError={() => onErrorLoad(true)}
          style={{ ...avatarStyle(size), ...style, minWidth: size, opacity: 0.8 }}
          alt="Avatar"
          isHeaderAvatar={isHeaderAvatar}
          scale={scale}
        />
      ) : (
        <AntAvatar
          icon={!userName && !userSurname ? <UserOutlined /> : null}
          src={getAvatarUrl(photo)}
          style={{ minWidth: size, opacity: 0.8, backgroundColor: activeTheme?.lightPrimaryColor }}
          size={size}
        >
          {userName?.charAt(0)}
          {userSurname?.charAt(0)}
        </AntAvatar>
      )}
    </>
  );
};

export default Avatar;
