import React, { useState } from 'react';
import { DownloadOutlined, StopOutlined } from '@ant-design/icons';
import { Tooltip, Button, message, Flex } from 'antd';
import { generateColumn } from '../../helpers';
import { Child } from 'types';
import { downloadSomeFile } from 'helpers';
import { StTitle, StRegisterButton } from 'Global/modules/Lesson/LessonRegister/styles';
import { Register } from 'types/TeacherAccount/Courses';
import { useLanguageContext } from 'contexts/LanguageContext';

/**
 * @description Рендеринг заголовка домашнего задания студента
 * @param {number} lessonId
 * @return {React.ReactNode}
 */
const TitleHomeworkStudent = React.memo(({ lessonId }: { lessonId: number | string }) => {
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [strings] = useLanguageContext();

  const handleDownloadAllHomeworks = async (): Promise<any> => {
    try {
      setHasLoading(true);
      await downloadSomeFile(`/api/v1/lessons/${lessonId}/homeworks-done`, null, null, null, true);
      setHasLoading(false);
    } catch {
      message.error(strings.HW_DOWNLOAD_FAILED, 2);
      setHasLoading(false);
    }
  };

  return (
    <Flex vertical gap={5} align="center" justify="center">
      <Tooltip title={strings.DOWNLOAD_ALL_HOMEWORKS}>
        <Button shape="circle" loading={hasLoading} icon={<DownloadOutlined />} onClick={handleDownloadAllHomeworks} />
      </Tooltip>
      <StTitle>{strings.BY_STUDENT}</StTitle>
    </Flex>
  );
});

/**
 * @description Ренедринг колонки студента
 * @param {number} homeworkStatus
 * @return {React.ReactNode}
 */
const RegisterHomeworkStudent = React.memo(
  ({ homeworkStatus, student, lessonId }: { homeworkStatus: number; student: Child; lessonId: number | string }) => {
    const [hasLoading, setHasLoading] = useState<boolean>(false);
    const [strings] = useLanguageContext();

    /**
     * @description Загрузка ДЗ согласно ученику
     * @return {Promise<any>}
     */
    const handleDownloadHomeworkByStudent = async (): Promise<any> => {
      try {
        setHasLoading(true);
        await downloadSomeFile(
          `/api/v1/lessons/${lessonId}/homeworks-done?student=${student.id}`,
          null,
          null,
          null,
          true
        );
        setHasLoading(false);
      } catch {
        message.error(strings.HW_DOWNLOAD_FAILED, 2);
        setHasLoading(false);
      }
    };

    return (
      <Flex justify="center" align="center">
        {homeworkStatus === 1 || homeworkStatus === 2 ? (
          <Tooltip title={strings.DOWNLOAD_STUDENT_HW}>
            <StRegisterButton
              type="primary"
              loading={hasLoading}
              icon={<DownloadOutlined />}
              onClick={handleDownloadHomeworkByStudent}
            />
          </Tooltip>
        ) : (
          <StopOutlined style={{ fontSize: 20 }} />
        )}
      </Flex>
    );
  }
);

const COLUMN_WIDTH = 55;

/**
 * @description Генерация колонки student
 * @return {object}
 */
export const getHomeworkStudentColumn = lessonId => ({
  ...generateColumn(
    () => <TitleHomeworkStudent lessonId={lessonId} />,
    'student',
    'bystudents',
    null,
    null,
    COLUMN_WIDTH
  ),
  render: (student: Child, { homeworkStatus }: Register) => {
    return <RegisterHomeworkStudent student={student} homeworkStatus={homeworkStatus} lessonId={lessonId} />;
  }
});
