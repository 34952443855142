import React, { useState } from 'react';
import { Button, message } from 'antd';
import { AxiosResponse } from 'axios';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';
import { useLanguageContext } from 'contexts/LanguageContext';

interface AskTeacherFeedbackProps {
  buttonNeedFeedbackForParents: number;
  subjectId: number;
  childId: number;
}

/**
 * @description Кнопка для запроса фидбека от учителя
 * @param {AskTeacherFeedbackProps} props
 * @return {React.ReactNode}
 */
export const AskTeacherFeedback = (props: AskTeacherFeedbackProps) => {
  const [strings] = useLanguageContext();
  const reportsRepository = useProgressReportsRepository();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRequestFeedback, setIsRequestFeedback] = useState<boolean>(false);
  const { buttonNeedFeedbackForParents, subjectId, childId } = props;
  const isWaitingFeedbackFromTeacher = buttonNeedFeedbackForParents === 1;
  const isBtnDisabledForFeedback = isWaitingFeedbackFromTeacher || isRequestFeedback || isLoading;
  const btnText = isBtnDisabledForFeedback ? strings.WAITING_FEEDBACK : strings.ASK_TEACHER_FOR_FEEDBACK;

  /**
   * @description Запрос фидбэка по клику
   * @param {number} subjectId
   * @return {Promise<any>}
   */
  const askForFeedback = async (subjectId: number): Promise<any> => {
    setIsLoading(true);
    await reportsRepository
      .askFeedbackForParents(childId, subjectId)
      .then(async ({ data }: AxiosResponse): Promise<any> => {
        if (data.status) {
          message.success(strings.FEEDBACK_HAS_BEEN_SUCCESSFULLY_REQUESTED, 2);
          setIsRequestFeedback(true);
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Button
      style={{ margin: '20px 0' }}
      disabled={isBtnDisabledForFeedback}
      loading={isLoading}
      type="primary"
      onClick={() => askForFeedback(subjectId)}
    >
      {btnText}
    </Button>
  );
};

export default { AskTeacherFeedback };
