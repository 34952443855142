import React, { useState } from 'react';
import { Button, Form, message, Flex, Tabs, Spin } from 'antd';
import { useStudentRepository } from 'repos/StudentRepository';
import { getAllFormData } from 'helpers';
import { booleanFormatValues } from 'helpers/booleanFomatValues';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import { formatDateValues } from 'helpers/dates';
import { useFamilyRepository } from 'repos/FamilyRepository';
import { useHistory } from 'react-router-dom';
import { StPageHeader } from 'Global/GlobalStyle';
import { FamilyProfileForm, AddStudents, AddParents } from 'Admin/People/Families/Family/EditFamily/Forms';
import { useUserContext } from 'contexts/UserContext';

const { TabPane } = Tabs;

/**
 * @description Корневой компонент редактирования семьи
 * @param {object} props
 * @return {React.ReactNode}
 */
export const NewFamily: React.FC = () => {
  const [strings] = useLanguageContext();
  const [form] = Form.useForm();
  const [user] = useUserContext();

  const studentRepository = useStudentRepository();
  const familyRepository = useFamilyRepository();
  const globalRequestsRepository = useGlobalRequestsRepository();
  const { goBack, push } = useHistory();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [familyBranch, setFamilyBranch] = useState<number>(user?.branch?.id);

  /**
   * @description Запрос на добавление аватарки студенту
   * @return {Promise<any>}
   */
  const uploadAvatar = async (params: { id: number; file: File; type: string }): Promise<any> => {
    try {
      await globalRequestsRepository.uploadAvatar(getAllFormData(params));
    } catch {}
  };

  /**
   * @description Запрос на создание студента
   * @param {number} familyId
   * @param {object} children
   * @return {Promise<any>}
   */
  const createChildren = async (familyId, students): Promise<any> => {
    setHasLoading(true);
    return await Promise.all([
      ...students.map(async (student): Promise<any> => {
        const { avatar } = student;
        try {
          const params = booleanFormatValues(formatDateValues(student));
          const {
            data: { id }
          } = await studentRepository.createStudent({
            ...params,
            family: Number(familyId),
            isActive: 1
          });
          // Если аватарка есть и студент успешно создан, то загрузим ее
          if (avatar) {
            await uploadAvatar({ id, file: avatar.file, type: 'student' });
          }
        } catch (err) {
          message.error(strings.STUDENT_CREATE_ERROR, 2);
        } finally {
          setHasLoading(false);
        }
      })
    ]);
  };

  /**
   * @description Запрос на создание родителя
   * @param {number} familyId
   * @param {object} children
   * @return {Promise<any>}
   */
  const createParents = async (familyId, parents): Promise<any> => {
    return await Promise.all([
      ...parents.map(async (parent): Promise<any> => {
        const { avatar } = parent;
        try {
          const params = booleanFormatValues(formatDateValues(parent));
          const {
            data: { id }
          } = await familyRepository.createParent({
            ...params,
            family: Number(familyId),
            isActive: 1
          });
          // Если аватарка есть и родитель успешно создан, то загрузим ее
          if (avatar) {
            await uploadAvatar({ id, file: avatar.file, type: 'parent' });
          }
        } catch (err) {
          message.error(strings.PARENT_CREATE_ERROR, 2);
        }
      })
    ]);
  };

  /**
   * @description Запрос на создание семьи
   * @param {object} values
   * @return {Promise<any>}
   */
  const handleSubmit = async (values): Promise<any> => {
    const { family, students, parents } = values;
    setHasLoading(true);
    try {
      const { data, status } = await familyRepository.createFamily(family);
      const { id: familyId } = data;
      // ЕСЛИ семья успешно создана, создадим детей и родителей
      // Так же создадим их аватарки
      if (status === 200) {
        await Promise.all([createChildren(familyId, students), createParents(familyId, parents)]).then(() => {
          message.success(strings.FAMILY_SUCCESSFULLY_CREATED, 2);
          push(`/family/${familyId}`);
        });
      }
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    message.warning(strings.REQUIRED_FIELDS_ARE_NOT_FILLED_IN);
  };

  const onValuesChange = (changedValues, allValues) => {
    // Проверяем, изменилось ли поле ['family', 'branch']
    if (changedValues.family?.branch !== undefined) {
      setFamilyBranch(changedValues.family.branch);
    }
  };

  return (
    <Flex vertical style={{ margin: 20 }}>
      <StPageHeader title={strings.NEW_FAMILY_PROFILE} onBack={goBack} />
      <Spin spinning={hasLoading}>
        <Flex>
          <Form
            form={form}
            layout="vertical"
            style={{ width: '100%' }}
            onValuesChange={onValuesChange}
            onFinish={handleSubmit}
            onFinishFailed={onFinishFailed}
            initialValues={{ students: [{}], parents: [{}], family: { branch: familyBranch } }}
          >
            <Tabs>
              <TabPane tab={strings.FAMILY_PROFILE} key="family">
                <FamilyProfileForm />
              </TabPane>
              <TabPane tab={strings.PARENTS} key="parents" forceRender>
                <AddParents familyBranch={familyBranch} />
              </TabPane>
              <TabPane tab={strings.STUDENTS} key="students" forceRender>
                <AddStudents familyBranch={familyBranch} />
              </TabPane>
            </Tabs>
            <Button
              loading={hasLoading}
              disabled={hasLoading}
              type="primary"
              htmlType="submit"
              style={{ float: 'right', marginTop: 20 }}
            >
              {strings.CREATE_FAMILY}
            </Button>
          </Form>
        </Flex>
      </Spin>
    </Flex>
  );
};

export default { NewFamily };
