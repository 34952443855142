import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Spin, Table, Row } from 'antd';
import { TeacherFinanceFilter } from './Filter';
import { columns } from './columns';
import { dateFormatForBackend } from 'helpers/dates';
import { today } from 'helpers/dates';
import { initalStatuses } from '../helpers';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { useLanguageContext } from 'contexts/LanguageContext';
import { ApiFinanceReportTeachers } from 'types/Teachers/finance';
import { Product } from 'types';
import { FinanceReportTeacher } from 'types/Reports';
import { useUserContext } from 'contexts/UserContext';
import { useTeacherRepository } from 'repos/TeacherRepository';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useHistory } from 'react-router-dom';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import queryString from 'query-string';

interface TeacherFinanceProps {}

interface FinanceReportContextProps {
  getFinanceReportTeachers: (params: ApiFinanceReportTeachers) => Promise<any>;
}

export const FinanceReportContext = React.createContext<FinanceReportContextProps | null>(null);

/**
 * @description Мониторинг финансов учителя
 * @param {TeacherFinanceProps} props
 * @return {React.ReactNode}
 */
export const TeacherFinance = (props: TeacherFinanceProps) => {
  const [strings] = useLanguageContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [teachers, setTeachers] = useState<FinanceReportTeacher[]>([]);

  const teacherRepository = useTeacherRepository();
  const [user] = useUserContext();

  const { push, goBack } = useHistory();
  const { locationSearchParams } = useLocationSearchParams();

  /**
   * @description Финансовый отчет учителей
   * @param {ApiFinanceReportTeachers} params
   * @return {Promise<any>}
   */
  const getFinanceReportTeachers = async (params: ApiFinanceReportTeachers): Promise<any> => {
    setLoading(true);
    try {
      const { data } = await teacherRepository.getFinanceReportTeachers(params);
      push({ search: queryString.stringify(params) });
      setProducts(data.products);
      setTeachers(data.data);
    } finally {
      setLoading(false);
    }
  };

  const contextValues = {
    getFinanceReportTeachers
  };

  useEffect(() => {
    // Филиал обязателен при запросе данных, поэтому берем у пользователя
    if (user?.branch?.id) {
      getFinanceReportTeachers({
        branch: user.branch.id,
        end: dayjs(today).format(dateFormatForBackend),
        ...locationSearchParams,
        status: initalStatuses(locationSearchParams?.status)
      });
    }
  }, [user]); // eslint-disable-line

  return (
    <ErrorBoundary>
      <Container>
        <FinanceReportContext.Provider value={contextValues}>
          <StPageHeader onBack={goBack} title={strings.FINANCE_TEACHERS}>
            <TeacherFinanceFilter />
          </StPageHeader>
          <Spin tip={strings.LOADING_FINANCE_REPORT_TEACHERS} spinning={loading}>
            <Row gutter={[10, 10]}>
              <Table
                dataSource={teachers}
                columns={columns(products, strings)}
                rowKey="teacherId"
                pagination={{ pageSize: 25 }}
              />
            </Row>
          </Spin>
        </FinanceReportContext.Provider>
      </Container>
    </ErrorBoundary>
  );
};

export default { TeacherFinance };
