import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form, Spin, Table } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { MetaGrade, OriginalLessonOutcomeItem } from 'types/TeacherAccount/Courses';
import { useLessonRepository } from 'repos/LessonRepository';
import { IStudent } from 'types';
import { isEqual } from 'lodash';
import { ReportingType } from 'types/global';
import OutcomeCell from './OutcomeCell';
import { getOutcomeColumns, getStudentColumn } from './columns';
import { useLessonInfoStore } from 'stores/useLessonInfoStore';
import { useLessonOutcomesStore } from 'stores/useLessonOutcomesStore';

interface LessonOutcomesProps {
  lessonId: number | string;
  hasSaveLoading: boolean;
}

export interface IOutcomesForStudent {
  student: IStudent;
  outcomes: MetaGrade[];
  outcomesFilled?: boolean;
  outcomesRequired: boolean;
  recordIndex: number;
}
interface OutcomeCellProps {
  record: IOutcomesForStudent;
  recordIndex: number;
  item: OriginalLessonOutcomeItem;
  outcomeIndex: number;
  setHasLessonOutcomesChanged: (val: boolean) => void;
  hasPrimaryOrSchool: boolean;
}
const LessonOutcomes: React.FC<LessonOutcomesProps> = ({ lessonId, hasSaveLoading }) => {
  const [strings] = useLanguageContext();
  const form = Form.useFormInstance();
  const lessonRepository = useLessonRepository();
  const lessonInfo = useLessonInfoStore(state => state.lessonInfo);
  const { lesson } = lessonInfo;

  const originalLessonOutcomes = useLessonOutcomesStore(state => state.originalLessonOutcomes);
  const setHasLessonOutcomesChanged = useLessonOutcomesStore(state => state.setHasLessonOutcomesChanged);

  const reportType = lesson?.courseGroup?.stage?.reportType;
  const hasPrimaryOrSchool = reportType === ReportingType.primary || reportType === ReportingType.school;

  const [outcomesList, setOutcomesList] = useState<OriginalLessonOutcomeItem[]>([]);
  const [outcomesForStudents, setOutcomesForStudents] = useState<IOutcomesForStudent[]>([]);
  const [intialOutcomes, setIntialOutcomes] = useState<{
    data: IOutcomesForStudent[];
    outcomes: OriginalLessonOutcomeItem[];
  }>(null);

  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const selectedRowKeysRef = useRef([]);

  const onSelectChange = useCallback(newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys);
    setIsRowSelected(newSelectedRowKeys.length > 0);
    selectedRowKeysRef.current = newSelectedRowKeys;
  }, []);

  const rowSelection = useMemo(
    () => ({ selectedRowKeys, onChange: onSelectChange }),
    [selectedRowKeys, onSelectChange]
  );

  const getOutcomes = useCallback(async () => {
    setHasLoading(true);
    try {
      const {
        data: { data, outcomes }
      } = await lessonRepository.getOutcomesByLesson(lessonId);
      const initOutcomes = outcomes.isNotEmpty() ? outcomes : originalLessonOutcomes;
      const newData = data?.map((item, recordIndex) => {
        const combinedArray = initOutcomes.map(outcome => {
          const gradeOutcome = item.outcomes.find(gradeOutcomeItem => gradeOutcomeItem.id === outcome.id);
          return { ...outcome, grade: gradeOutcome?.grade };
        });
        return { ...item, outcomes: combinedArray, recordIndex };
      });
      setIntialOutcomes({ data: newData, outcomes: initOutcomes });
      setFormFieldsValue(newData);
    } finally {
      setHasLoading(false);
    }
  }, [lessonId, originalLessonOutcomes]);

  const setFormFieldsValue = useCallback(
    data => {
      form.setFieldsValue({
        data: data.map(item => ({ ...item, student: item.student.id }))
      });
    },
    [form]
  );

  const setValueForSelection = useCallback(
    (grade: number, outcomeId: number) => {
      const outcomesValues = form.getFieldsValue();
      outcomesValues.data.forEach(studentData => {
        if (selectedRowKeysRef.current.includes(studentData.student)) {
          studentData.outcomes.forEach(outcome => {
            if (outcome.id === outcomeId) {
              outcome.grade = grade;
            }
          });
        }
      });

      form.setFieldsValue(outcomesValues);
      setHasLessonOutcomesChanged(true);
    },
    [form, setHasLessonOutcomesChanged]
  );

  const renderOutcomeCell = useCallback(
    (item, outcomeIndex) => (record: IOutcomesForStudent) =>
      (
        <OutcomeCell
          record={record}
          recordIndex={record?.recordIndex}
          item={item}
          outcomeIndex={outcomeIndex}
          setHasLessonOutcomesChanged={setHasLessonOutcomesChanged}
          hasPrimaryOrSchool={hasPrimaryOrSchool}
        />
      ),
    [setHasLessonOutcomesChanged, hasPrimaryOrSchool]
  );

  useEffect(() => {
    setIntialOutcomes(null);
    getOutcomes();
  }, []);

  useEffect(() => {
    if (intialOutcomes) {
      const { data, outcomes } = intialOutcomes;
      if (outcomes.isNotEmpty()) {
        setOutcomesList(outcomes);
        setOutcomesForStudents(data);
      }
    }
  }, [intialOutcomes]);

  useEffect(() => {
    if (intialOutcomes) {
      const { outcomes, data } = intialOutcomes;
      if (!isEqual(originalLessonOutcomes, outcomes)) {
        setOutcomesList(originalLessonOutcomes);
        const newData = data.map(dataItem => {
          return {
            ...dataItem,
            outcomes: originalLessonOutcomes.map(item => {
              return {
                id: item.id,
                grade: null
              };
            })
          };
        });
        setOutcomesForStudents(newData);
        setFormFieldsValue(newData);
      }
    }
  }, [originalLessonOutcomes]);

  return (
    <Spin spinning={hasLoading || hasSaveLoading}>
      <Table
        columns={[
          ...getStudentColumn({ strings, outcomesList }),
          ...getOutcomeColumns({
            outcomesList,
            isRowSelected,
            setValueForSelection,
            hasPrimaryOrSchool,
            renderOutcomeCell
          })
        ]}
        dataSource={outcomesForStudents}
        rowSelection={rowSelection}
        rowKey={(record: IOutcomesForStudent) => record.student.id}
        bordered
        pagination={false}
        loading={hasLoading}
      />
    </Spin>
  );
};

const LessonOutcomesMemo = React.memo(LessonOutcomes);
export default LessonOutcomesMemo;
