import { Tabs } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useHistory, useLocation } from 'react-router-dom';
import { StPageHeader, Container } from 'Global/GlobalStyle';
import React, { useMemo, useState } from 'react';
import { FamilyStatuses } from './FamilyStatuses';
import { TeacherStatuses } from './TeacherStatuses';
import { StudentStatuses } from './StudentStatuses';

const { TabPane } = Tabs;

export const Statuses = () => {
  const [strings] = useLanguageContext();
  const { push, goBack, replace } = useHistory();
  const location = useLocation();
  const initialTab = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('tab') || 'teacher';
  }, []);

  const [activeTab, setActiveTab] = useState(initialTab);

  const handleTabChange = key => {
    setActiveTab(key);
    push({ search: `?tab=${key}` });
  };

  return (
    <Container>
      <StPageHeader onBack={goBack} title={strings.STATUSES} />
      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab={strings.TEACHER_STATUS} key="teacher">
          <TeacherStatuses />
        </TabPane>
        <TabPane tab={strings.FAMILY_STATUS} key="family">
          <FamilyStatuses />
        </TabPane>
        <TabPane tab={strings.STUDENT_STATUS} key="student">
          <StudentStatuses />
        </TabPane>
      </Tabs>
    </Container>
  );
};
