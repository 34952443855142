import React, { useMemo } from 'react';
import { truncate, capitalize } from 'lodash';
import dayjs from 'dayjs';
import {
  StyledShortTicketItem,
  StyledTicketSubject,
  StyledTicketBottom,
  StyledStatusColor
} from 'Global/modules/Support/Chat/styles';
import { Flex } from 'antd';

export function ShortTicketItem({ subject, date, status, onClick, id: currentId, ticket }) {
  const hasActive = useMemo(() => ticket?.id === currentId, [ticket, currentId]);

  return (
    <StyledShortTicketItem hasActive={hasActive} onClick={onClick}>
      <StyledTicketSubject>{truncate(subject, { length: 45 })}</StyledTicketSubject>
      <StyledTicketBottom>
        <Flex align="center" gap={5}>
          <StyledStatusColor />
          {capitalize(status)}
        </Flex>
        <p>{dayjs(date).format('DD.MM.YY')}</p>
      </StyledTicketBottom>
    </StyledShortTicketItem>
  );
}
