import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import styled from 'styled-components';
import { getImageUrl } from 'helpers/getImageUrl';
import { Link } from 'react-router-dom';
import { truncate } from 'lodash';
dayjs.extend(relativeTime);

const StyledImage = styled.div<{ backgroundUrl: string }>`
  background-image: url(${({ backgroundUrl }) => backgroundUrl});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;
  border-radius: 5px;
`;

const StyledCardContent = styled.div`
  background: #fff;
  position: relative;
  padding: 20px 20px;

  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 105px;

  h3 {
    color: #333;
    font-weight: bolder;
    font-size: 14px !important;
    z-index: 2;
  }
  .date {
    position: absolute;
    bottom: 3px;
    font-size: 14px;
    z-index: 2;
    font-weight: 600;
    opacity: 0.8;
    color: #333;
    right: 20px;
  }
`;

const StyledNewsCard = styled(Link)`
  width: 100%;
  display: block;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 46px -15px rgba(0, 0, 0, 0.12);
  &:hover {
    .news-image {
      transform: scale(1.1);
      transition: all 0.5s ease-in-out;
    }
  }
`;

function getNewsDate(date: string) {
  const diff = dayjs().diff(date, 'days');
  if (diff < 31) {
    return <p className="date">{dayjs(date).fromNow()}</p>;
  } else if (diff > 31 && diff <= 365) {
    return <p className="date">{dayjs(date).format('MMMM D')}</p>;
  }

  return <p className="date">{dayjs(date).format('MMMM DD, YYYY')}</p>;
}

export function NewsCard({ header, date, image, link, height = 400 }) {
  return (
    <StyledNewsCard to={link}>
      <StyledImage className="news-image" backgroundUrl={getImageUrl(image)} />
      <StyledCardContent>
        <h3>{truncate(header, { length: 45 })}</h3>
        {getNewsDate(date)}
      </StyledCardContent>
    </StyledNewsCard>
  );
}
