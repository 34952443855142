import { User } from 'types/user';
import { ROLE_ADMIN, ROLE_PARENT, ROLE_STUDENT, ROLE_TEACHER } from 'Global/roles';

/**
 * @description Проверка на роль
 * @param {User} user
 * @param {string[]} roles
 * @return {boolean}
 */
export const hasRole = (user: User, roles: string[]) => {
  return roles.some((role: any) => user.roles && user.roles.includes(role));
};

/**
 * @description Проверяет есть ли роль админа, учителя или родителя
 * @param {string[]} roles
 * @return {array}
 */
export const getRole = (roles: string[]) => {
  return roles && roles.filter((role: string) => role === ROLE_ADMIN || role === ROLE_TEACHER || role === ROLE_PARENT);
};

/**
 * @description Проверка на админа
 * @param {User} user
 * @return {boolean}
 */
export const hasAdmin = (user: User) => hasRole(user, [ROLE_ADMIN]);

/**
 * @description Проверка на teacher
 * @param {User} user
 * @return {boolean}
 */
export const hasTeacher = (user: User) => hasRole(user, [ROLE_TEACHER]);

/**
 * @description Проверка на parent
 * @param {User} user
 * @return {boolean}
 */
export const hasParent = (user: User) => hasRole(user, [ROLE_PARENT]);

/**
 * @description Проверка на parent
 * @param {User} user
 * @return {boolean}
 */
export const hasStudent = (user: User) => hasRole(user, [ROLE_STUDENT]);

export const getTypeByRole = (roles: string[]) => {
  if (roles.includes(ROLE_ADMIN)) {
    return 'admin';
  } else if (roles.includes(ROLE_TEACHER)) {
    return 'teacher';
  } else if (roles.includes(ROLE_PARENT)) {
    return 'parent';
  } else if (roles.includes(ROLE_STUDENT)) {
    return 'student';
  } else {
    return '';
  }
};
