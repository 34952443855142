import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import { Col, Row, Select, Input } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useHistory } from 'react-router-dom';
import { Branch, Subject } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { TutorSelect } from 'Global/components/FormComponentsCompatible/Selects/TutorSelect';
import { ExpertsFilterParams } from 'types/education';
import isEmpty from 'lodash/isEmpty';
import { useUserContext } from 'contexts/UserContext';
import { FilterButtons } from 'Global/components/FilterButtons';
import { StageSelect } from 'Global/components/FormComponentsCompatible/Selects/StageSelect';

const { Option } = Select;

interface ExpertsFilterProps extends FormComponentProps {
  onFilter: (params?: ExpertsFilterParams) => void;
}

export const Filter = Form.create<ExpertsFilterProps>({})((props: ExpertsFilterProps) => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const hasBranchOperationDepartament = user?.hasRoles?.branchOperationDepartament;
  const {
    form,
    form: { getFieldDecorator, resetFields, validateFieldsAndScroll },
    onFilter
  } = props;
  const { locationSearchParams } = useLocationSearchParams();
  const { branches, subjects } = useGlobalCollectionsContext();

  const { push } = useHistory();
  const { stage, subject, branch, expert } = locationSearchParams || {};
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);

  /**
   * @description Очистка фильтра
   * @return {void}
   */
  const handleClear = async (): Promise<any> => {
    resetFields();
    push({ search: '' });
    await onFilter();
  };

  /**
   * @description Отправка формы
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (err, values): Promise<any> => {
      if (isEmpty(err)) {
        try {
          setHasSubmitLoading(true);
          await onFilter(values);
        } catch {
        } finally {
          setHasSubmitLoading(false);
        }
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={[10, 10]}>
        {branches.length > 1 ? (
          <Col lg={6}>
            {getFieldDecorator('branch', {
              initialValue: branch
            })(
              <Select placeholder={strings.BRANCH}>
                <Option value={undefined}>&nbsp;</Option>
                {branches &&
                  branches.map((branch: Branch) => (
                    <Option value={branch.id} key={branch.id}>
                      {branch.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Col>
        ) : (
          <>
            {getFieldDecorator('branch', {
              initialValue: user?.branch.id
            })(<Input style={{ display: 'none' }} />)}
          </>
        )}
        <Col lg={6}>
          <StageSelect form={form} initialValue={stage} />
        </Col>
        {!hasBranchOperationDepartament && (
          <Col lg={6}>
            <TutorSelect
              initTutor={expert}
              placeholder={strings.EXPERT}
              getFieldDecorator={getFieldDecorator}
              name="expert"
              style={{ margin: 0 }}
            />
          </Col>
        )}
        <Col lg={6}>
          {getFieldDecorator('subject', {
            initialValue: subject
          })(
            <Select placeholder={strings.SUBJECT}>
              <Option value={undefined}>&nbsp;</Option>
              {subjects.map((subject: Subject) => (
                <Option value={subject.id} key={subject.id}>
                  {subject.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
      </Row>
      <FilterButtons handleClear={handleClear} hasSubmitLoading={hasSubmitLoading} />
    </Form>
  );
});
export default { Filter };
