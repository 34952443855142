import { httpService } from 'api/core';
import { GetHomeWorkParams } from 'types/API/homework';

/**
 * @description Получить (скачать) домашнее задание
 * @date {string} date
 * @return {Promise<any>}
 */
export const getHomeworkZip = (date?: string): Promise<any> =>
  httpService.get(`/api/v1/schedule/homework/zip?date=${date}`, null, null, true);

/**
 * @description Получить данные об уроках и домашках для каждого студента в семье
 * @date {string} param
 * @return {Promise<any>}
 */
export const getHomeworkForEachStudent = (params?: { date: string }): Promise<any> =>
  httpService.get(`/api/schedule/homework`, params);

/**
 * @description Получить список файлов домашки
 * @param {number} lessonId
 * @return {Promise<any>}
 */
export const getHomeWorkFilesByRegister = (lessonId: string | number): Promise<any> =>
  httpService.get(`/api/lessonregister/${lessonId}/homework`);

/**
 * @description Загрузка домашки к уроку
 * @param {number} lessonId
 * @param {object} params
 * @return {Promise<any>}
 */
export const addHomeWorkFiles = (lessonId: string | number, params: any): Promise<any> =>
  httpService.post(`/api/v2/lessonregister/${lessonId}/homework`, params, true);

/**
 * @description Загрузка домашки к уроку
 * @param {number} lessonId
 * @param {object} params
 * @return {Promise<any>}
 */
export const addHomeWorkFilesV1 = (lessonId: string | number, params: any): Promise<any> =>
  httpService.post(`/api/lessonregister/${lessonId}/homework`, params, true);

/**
 * @description Получить комментарии к домашке согласно уроку
 * @param {number} lessonId
 * @return {Promise<any>}
 */
export const getHomeWorkComments = (lessonId: string): Promise<any> =>
  httpService.get(`/api/lessonregister/${lessonId}/homework/comments`);

/**
 * @description Добавить комментарий к домашке
 * @param {number} lessonId
 * @param {object} params
 * @return {Promise<any>}
 */
export const addCommentToLessonHomeWork = (lessonId: string, params: { comment: string }): Promise<any> =>
  httpService.post(`/api/lessonregister/${lessonId}/homework/comments`, params);

/**
 * @description Удаление домашнего задания
 * @param {number} fileId
 * @return {Promise<any>}
 */
export const deleteHomeWork = (fileId: number): Promise<any> =>
  httpService.delete(`/api/lessonregister/homework/${fileId}`);

/**
 * @description Переключатель выполнения домашки (сделано/не сделано)
 * @param {number} studentId
 * @return {Promise<any>}
 */
export const setIsStudentSendHomework = (registerId: number): Promise<any> =>
  httpService.put(`/api/lessonregister/${registerId}/student-send-homework`, {});

/**
 * @description Получение списка домашнего задания (родитель, студент)
 * @param {object} params
 * @return {Promise<any>}
 */
export const getHomework = (params: GetHomeWorkParams): Promise<any> =>
  httpService.get('/api/v2/schedule/homework', params);

export const Homework = {
  getHomeworkZip,
  getHomeworkForEachStudent,
  getHomeWorkFilesByRegister,
  addHomeWorkFiles,
  addHomeWorkFilesV1,
  getHomeWorkComments,
  addCommentToLessonHomeWork,
  deleteHomeWork,
  setIsStudentSendHomework,
  getHomework
};
