import React, { useContext, useMemo } from 'react';
import { httpService } from 'api/core';
import queryString from 'query-string';
import {
  FeedbackType,
  IChangeStatusNewReportParams,
  IFeedbackParams,
  IGenerateProgressReporPDF,
  IGetNewReportByStudentParams,
  INewReportGetParams,
  IRegisterUseInNewProgressReportParams,
  ISendProgressReportParams,
  ProgressReporByBranchesParams,
  ProgressReporByGroupsParams,
  ProgressReportParams,
  ProgressReportSendParams,
  Semester
} from '../types/Reports';

const ProgressReportsRepositoryContext = React.createContext<IProgressReportsRepository>(null);

interface IProgressReportsRepository {
  getStatusesList: () => Promise<any>;
  getProgressReports: (params: ProgressReportParams) => Promise<any>;
  getTrimesters: () => Promise<any>;
  getProgressReportForParent: () => Promise<any>;
  getProgressReportById: (parentId: number | string) => Promise<any>;
  updateProgressReportById: (reportId: number | string, params) => Promise<any>; //TODO описать params
  getReports: (params) => Promise<any>; //TODO описать params
  getRegistersCount: (params) => Promise<any>; //TODO описать params
  deleteReportRow: (rowId: number) => Promise<any>;
  getProgressReportRowsChild: (studentId: number, trimester?: number) => Promise<any>;
  deleteProgressReport: (reportId: number) => Promise<any>;
  getStudentsPayingReport: (params: { startAt: string; endAt: string; branch?: number }) => Promise<any>;
  registerCommentApprove: (id: number, type: FeedbackType) => Promise<any>;
  registerCommentReject: (id: number, params: IFeedbackParams) => Promise<any>;
  editRegisterComment: (id: number, params: IFeedbackParams) => Promise<any>;
  askFeedbackForParents: (childId: number, subjectId: number | string) => Promise<any>;
  deleteProgressReportsBulk: (reportsIds: number[]) => Promise<any>;
  makeAvailableToParentByBulkSelect: (params: { headers: number[] }) => Promise<any>;
  getProgressReportsWithSuperGroups: (params: ProgressReportSendParams) => Promise<any>;
  sendProgressReportsWithSuperGroups: (params: ProgressReportSendParams) => Promise<any>;
  getProgressReportsGenerator: () => Promise<any>;
  getTrimesterById: (id: number) => Promise<any>;
  editTrimester: (id: number, params: Semester) => Promise<any>;
  createTrimester: (params: Semester) => Promise<any>;
  generateProgressReporByGroups: (trimesterId: number, params: ProgressReporByGroupsParams) => Promise<any>;
  generateProgressReporByBranches: (trimesterId: number, params: ProgressReporByBranchesParams) => Promise<any>;
  getStudentsForNewReport: (params: INewReportGetParams) => Promise<any>;
  getNewReportByStudent: (params: IGetNewReportByStudentParams) => Promise<any>;
  changeStatusNewProgressReport: (params: IChangeStatusNewReportParams) => Promise<any>;
  generateProgressReportPDF: (params: IGenerateProgressReporPDF) => Promise<any>;
  sendProgressReportPDF: (params: ISendProgressReportParams) => Promise<any>;
  toggleRegisterUseInNewProgressReport: (id: number, params: IRegisterUseInNewProgressReportParams) => Promise<any>;
}

class ProgressReportsRepository implements IProgressReportsRepository {
  getStatusesList() {
    return httpService.get('/api/v1/statuses/progress-reports', null, null, true);
  }
  getProgressReports(params: ProgressReportParams) {
    return httpService.get('/api/v1/progress-reports', params, null, true);
  }
  getTrimesters() {
    return httpService.get('/api/v1/trimesters', null, null, true);
  }
  getProgressReportForParent() {
    return httpService.get(`/api/v1/progress-reports/for-parent`, null, null, true);
  }
  getProgressReportById(reportId: number | string) {
    return httpService.get(`/api/v1/progress-reports/${reportId}`, null, null, true);
  }
  updateProgressReportById(reportId: number | string, params) {
    return httpService.put(`/api/v1/progress-reports/${reportId}`, params, null, null, true);
  }
  deleteReportRow(rowId: number) {
    return httpService.delete(`/api/progress-reports/rows/${rowId}`);
  }
  getProgressReportRowsChild(studentId: number, trimester?: number) {
    return httpService.get(`/api/progress-reports/rows/child/${studentId}`, { trimester });
  }
  deleteProgressReport(reportId: number) {
    return httpService.delete(`/api/v1/progress-reports/${reportId}`, null, null, true);
  }
  getStudentsPayingReport(params: { startAt: string; endAt: string; branch?: number }) {
    return httpService.get(`/api/report/students/paying`, params);
  }
  getReports(params) {
    return httpService.get('/api/v1/report/registers', params, null, true);
  }
  getRegistersCount(params) {
    return httpService.get('/api/report/registers/count', params);
  }
  registerCommentApprove(id: number, type: FeedbackType) {
    return httpService.put(`/api/v1/report/registers/${id}/approve`, { type }, null, null, true);
  }
  registerCommentReject(id: number, params: IFeedbackParams) {
    return httpService.put(`/api/v1/report/registers/${id}/reject`, params, null, null, true);
  }
  editRegisterComment(id: number, params: IFeedbackParams) {
    return httpService.put(`/api/v1/report/registers/${id}/comment`, params, null, null, true);
  }
  askFeedbackForParents(childId: number, subjectId: number | string) {
    return httpService.post(`/api/children/${childId}/subject/${subjectId}/ask-feedback-for-parents`);
  }
  deleteProgressReportsBulk(reportsIds: number[]) {
    const queryParams = queryString.stringify({ headers: reportsIds }, { arrayFormat: 'bracket' });
    return httpService.delete(`/api/v1/progress-reports/bulk?${queryParams}`, null, null, true);
  }
  makeAvailableToParentByBulkSelect(params: { headers: number[] }) {
    return httpService.post(`/api/v1/progress-reports/bulk/status-available`, params, null, null, true);
  }
  getProgressReportsWithSuperGroups(params: ProgressReportSendParams) {
    return httpService.get('/api/progress-reports/supergroups', params);
  }
  sendProgressReportsWithSuperGroups(params: ProgressReportSendParams) {
    return httpService.post('/api/v1/progress-reports/supergroups/send', params, null, null, true);
  }
  getProgressReportsGenerator() {
    return httpService.get('/api/v1/progress-reports/generator', null, null, true);
  }
  getTrimesterById(id: number) {
    return httpService.get(`/api/v1/trimesters/${id}`, null, null, true);
  }
  editTrimester(id: number, params: Semester) {
    return httpService.put(`/api/v1/trimesters/${id}`, params, null, null, true);
  }
  createTrimester(params: Semester) {
    return httpService.post(`/api/v1/trimesters`, params, null, null, true);
  }
  generateProgressReporByGroups(trimesterId: number, params: ProgressReporByGroupsParams) {
    return httpService.post(`/api/v1/progress-reports/generator/${trimesterId}/groups`, params, null, null, true);
  }
  generateProgressReporByBranches(trimesterId: number, params: ProgressReporByBranchesParams) {
    return httpService.post(`/api/v1/progress-reports/generator/${trimesterId}/branches`, params, null, null, true);
  }
  getStudentsForNewReport(params: INewReportGetParams) {
    return httpService.get(`/api/v1/progress-reports-new`, params, null, true);
  }
  getNewReportByStudent(params: IGetNewReportByStudentParams) {
    return httpService.get(`/api/v1/progress-reports-new/student`, params, null, true);
  }
  changeStatusNewProgressReport(params: IChangeStatusNewReportParams) {
    return httpService.post('/api/v1/progress-reports-new/status', params, null, null, true);
  }
  generateProgressReportPDF(params: IGenerateProgressReporPDF) {
    return httpService.post('/api/v1/progress-reports-new', params, null, null, true);
  }
  sendProgressReportPDF(params: ISendProgressReportParams) {
    return httpService.post('/api/v1/progress-reports-new/send', params, null, null, true);
  }
  toggleRegisterUseInNewProgressReport(id: number, params: IRegisterUseInNewProgressReportParams) {
    return httpService.post(`/api/v1/lessonregister/${id}/use-in-pr`, params, null, null, true);
  }
}

export const useProgressReportsRepository = () => {
  const service = useContext(ProgressReportsRepositoryContext);

  if (!service) {
    throw new Error('Progress reports repository is unavailable');
  }

  return service;
};

export const ProgressReportsRepositoryProvider = props => {
  const service = useMemo(() => new ProgressReportsRepository(), []);

  return (
    <ProgressReportsRepositoryContext.Provider value={service}>
      {props.children}
    </ProgressReportsRepositoryContext.Provider>
  );
};
