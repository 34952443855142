/**
 * @description функция копирования текста
 * @param value строка для копирования
 * @param getResult функция получения результата
 */
export const copyingText = async (value: string, getResult: (boolean) => void): Promise<any> => {
  return await navigator.clipboard
    .writeText(value)
    .then(() => {
      getResult(true);
    })
    .catch(() => {
      getResult(false);
    });
};
