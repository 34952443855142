import { httpService } from 'api/core';
import { ApiGetTeacherFinance } from 'types/Teachers/finance';

/**
 * @description Создает новую финансовую операцию учителя по id
 * @param {number} teacherId
 * @param {object} params
 * @return {Promise<any>}
 */
export const createTeacherOperation = (teacherId: number | string, params: any): Promise<any> =>
  httpService.post(`/api/teacher/${teacherId}/finance`, params);

/**
 * @description Редактировать финансовую операцию учителя по id
 * @param {number} teacherId
 * @param {object} params
 * @return {Promise<any>}
 */
export const editTeacherOperation = (teacherId: number | string, params: any): Promise<any> =>
  httpService.put(`/api/teacher/operation/${teacherId}`, params);

/**
 * @description Удаление финансовой операции у учителя
 * @param {number} operationId
 * @return {Promise<any>}
 */
export const deleteTeacherOperation = (operationId: number): Promise<any> =>
  httpService.delete(`/api/teacher/operation/${operationId}`);

/**
 * @description Получить финансы учителя по месяцам
 * @param {number} teacherId
 * @param {string[]} dates
 * @returns {Promise<any>}
 */
export const getTeacherFinanceByMonths = (teacherId: number, dates: string[]): Promise<any> =>
  // TODO: вынести в хелпер маппер дат
  httpService.post(`/api/teacher/${teacherId}/finance/by-months?${dates.map(date => 'date[]=' + date).join('&')}`, {});

/**
 * @description Установка коэффицента
 * @param {number} teacherId
 * @param {object} params
 * @returns {Promise<any>}
 */
export const setIndividualCoefficient = (
  teacherId: number | string,
  params: { coefficient: number; enabled: number }
): Promise<any> => httpService.post(`/api/teachers/${teacherId}/wage/coefficient`, params);

export default {
  createTeacherOperation,
  getTeacherFinanceByMonths,
  editTeacherOperation,
  setIndividualCoefficient,
  deleteTeacherOperation
};
