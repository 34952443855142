import React, { useEffect, useState } from 'react';
import { Button, Avatar, Upload, Form } from 'antd';
import { EditOutlined, UserOutlined } from '@ant-design/icons';
import { beforeImageUpload, getAvatarUrl, getBase64 } from 'helpers';
import { useThemeContext } from 'contexts/ThemeContext';
import type { NamePath } from 'antd/lib/form/interface';

type AvatarUploaderProps = {
  mainPhoto?: string;
  name: NamePath;
};

export const AvatarUploader: React.FC<AvatarUploaderProps> = ({ mainPhoto = '', name }) => {
  const { activeTheme } = useThemeContext();

  const [previewAvatar, setPreviewAvatar] = useState<string>('');

  const handleChangeAvatar = async (upload): Promise<any> => {
    const imageHash = await getBase64(upload.file);
    setPreviewAvatar(imageHash);
  };

  useEffect(() => {
    if (mainPhoto) {
      setPreviewAvatar(getAvatarUrl(mainPhoto));
    }
  }, [mainPhoto]);

  return (
    <div style={{ position: 'relative', marginInlineEnd: 10 }}>
      <Avatar
        src={previewAvatar}
        style={{ backgroundColor: activeTheme?.lightPrimaryColor, opacity: 0.8 }}
        size={80}
        icon={<UserOutlined />}
      />
      <Form.Item name={name} style={{ position: 'absolute', top: 40, left: 60 }}>
        <Upload
          accept={'image/*'}
          multiple={false}
          showUploadList={false}
          onChange={handleChangeAvatar}
          beforeUpload={beforeImageUpload}
        >
          <Button shape="circle" icon={<EditOutlined />} style={{ boxShadow: '0 0 6px rgb(0 0 0 / 30%)' }} />
        </Upload>
      </Form.Item>
    </div>
  );
};
export default { AvatarUploader };
