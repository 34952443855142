import React, { useEffect, useState } from 'react';
import { Form, Row, Button, Flex, Spin, message } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { match } from 'react-router';
import { IParent } from 'types';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { booleanFormatValues } from 'helpers/booleanFomatValues';
import { formatDateValues } from 'helpers/dates';
import { ParentParams } from 'types/Family';
import { getAllFormData } from 'helpers';
import { useFamilyRepository } from 'repos/FamilyRepository';
import { ParentForm } from './ParentForm';

export const StRow = styled(Row)`
  flex: 1;
  width: 100%;
  .ant-row {
    width: 100%;
  }
`;

export const EditParent = () => {
  const [strings] = useLanguageContext();
  const { goBack, push } = useHistory();
  const familyRepository = useFamilyRepository();
  const globalRequestsRepository = useGlobalRequestsRepository();

  const {
    params: { userId: id }
  }: match<{ userId: string }> = useRouteMatch();

  const [form] = Form.useForm();
  const [parent, setParent] = useState<IParent>();
  const [hasLoading, setHasLoading] = useState<boolean>(false);

  const {
    familyId,
    isPayer,
    comment,
    profession,
    address,
    passport,
    subscribed,
    smsSubscribed,
    subscribeToHomework,
    legalEntity,
    bankAccountNumber,
    bankName,
    bankCode,
    tags
  } = parent || {};
  const { name, surname, patronymic, phone, email, birthDate, branch } = parent?.user || {};

  const defValues = {
    name,
    surname,
    patronymic,
    phone,
    email,
    birthday: birthDate && dayjs(birthDate),
    branch: branch?.id,
    profession,
    passport,
    address,
    isPayer,
    subscribed,
    smsSubscribed,
    subscribeToHomework,
    legalEntity,
    bankAccountNumber,
    bankName,
    bankCode,
    comment,
    tags: tags?.map(item => item?.id)
  };

  /**
   * @description Получение родителя по id
   * @return {Promise<any>}
   */
  const fetchParentById = async () => {
    setHasLoading(true);
    try {
      const { data } = await familyRepository.getParentById(id);
      setParent(data);
    } finally {
      setHasLoading(false);
    }
  };

  /**
   * @description Отправка формы
   * @return {void}
   */
  const handleSubmit = async (values: ParentParams): Promise<any> => {
    try {
      setHasLoading(true);
      const params = booleanFormatValues(formatDateValues(values));
      const { status } = await familyRepository.editParent(id, {
        ...params,
        family: Number(familyId)
      });
      if (status === 200) {
        const { avatar } = values;
        //если изменили аватар
        if (avatar) {
          uploadAvatar(avatar.file);
        }
        message.success(strings.EDIT_PARENTS_SUCCESSFULLY, 2);
        push(`/family/${familyId}/parent/${id}`);
      }
    } catch (err) {
      message.error(strings.EDIT_PARENTS_ERROR_TRY_AGAIN_LATER, 2);
    } finally {
      setHasLoading(false);
    }
  };

  /**
   * @description Запрос на добавление аватарки студенту
   * @return {Promise<any>}
   */
  const uploadAvatar = async (avatar: File): Promise<any> => {
    try {
      await globalRequestsRepository.uploadAvatar(getAllFormData({ id: id, type: 'parent', file: avatar }));
    } catch {}
  };

  useEffect(() => {
    fetchParentById();
  }, []);

  return (
    <Container>
      <StPageHeader title={strings.EDIT_PARENT} onBack={goBack} />
      <Spin spinning={hasLoading}>
        {parent && (
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={defValues}
            style={{ marginBottom: 20 }}
          >
            <ParentForm parent={parent} familyBranch={branch?.id} />
            <Flex justify="end">
              <Button htmlType="submit" type="primary">
                {strings.EDIT_PARENT}
              </Button>
            </Flex>
          </Form>
        )}
      </Spin>
    </Container>
  );
};
export default { EditParent };
