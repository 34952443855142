import React from 'react';
import { Link } from 'react-router-dom';

/**
 * @description Компонент для ссылки в меню
 * @param {object} props
 * @return {React.ReactNode}
 */
export const MenuItem: React.FC<{
  title: string;
  isNativeLink?: boolean;
  link: string;
  icon?: React.ReactElement;
}> = props => {
  const { title, link, icon, isNativeLink } = props;
  const child = (
    <span>
      {icon}
      <span>{title}</span>
    </span>
  );

  if (isNativeLink) {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer" style={{ width: '100%' }}>
        {child}
      </a>
    );
  }

  return (
    <Link to={link} style={{ width: '100%' }}>
      {child}
    </Link>
  );
};

export default { MenuItem };
