import styled from 'styled-components';
import { Col, Row, DatePicker } from 'antd';

export const SmallPicker = styled(DatePicker)`
  &,
  input {
    margin-bottom: 0;
    height: 24px;
  }
`;

export const StyledListTutors = styled.div`
  height: max-content;
  overflow-y: auto;
  background: #fff;
  border-radius: 5px;
  padding: 20px 20px;
  margin-bottom: 15px;
  box-shadow: 0px 0px 46px -15px rgba(0, 0, 0, 0.12);
`;

export const StyledStudentTutor = styled.div`
  max-height: 230px;
  overflow-y: auto;
  background: #fff;
  border-radius: 5px;
  padding: 20px 20px;
  margin: 15px 0;
`;

export const UserBlockContent = styled(Row)`
  margin-inline-start: 10px;
`;

export const Username = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const ColRightPosition = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

export const HeaderRow = styled(Row)`
  padding-inline-start: 10px;
  padding-inline-end: 10px;
  margin-bottom: 20px;
`;

export const StyledMain = styled.div`
  margin: 25px 20px;

  h3 {
    font-size: 20px;
    font-weight: 700;
  }

  h4 {
    font-size: 16px;
  }

  .ant-col {
    padding: 0 15px;
  }
`;

export const TrLessons = styled.tr`
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-left: none;
  border-right: none;
`;

export const CardContent = styled.div`
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-left: none;
  border-right: none;
`;

export const PaddingBlock = styled.div`
  padding: 20px;
`;

export const Lessons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

export const StyledLessonItem = styled.div`
  background: #f1f5fe;
  padding: 10px;
  margin: 3px;
  width: 135px;
  height: 90px;
  span {
    font-size: 14px;
    font-weight: 700;
    color: #333;
  }
  p {
    font-size: 14px;
  }
`;

export const StyledMediaBlock = styled.div`
  iframe,
  img {
    width: 100% !important;
    margin: 10px 0;
  }
`;

export const SurveyContainer = styled.div`
  font-size: 16px;

  .has-error .ant-form-explain,
  .has-error .ant-form-split {
    font-size: 12px;
    margin-top: 2px;
  }
`;

export default {
  StyledMain,
  ColRightPosition,
  HeaderRow,
  PaddingBlock,
  TrLessons,
  CardContent,
  StyledMediaBlock,
  StyledLessonItem,
  Username,
  UserBlockContent,
  SmallPicker,
  SurveyContainer
};
