import React from 'react';

export const getColumns = strings => [
  {
    title: strings.NAME,
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: strings.ACTUAL,
    dataIndex: 'isActive',
    key: 'isActive',
    render: (actual: boolean) => (actual ? 'Yes' : 'No')
  },
  {
    title: strings.PRICE,
    dataIndex: 'cost',
    key: 'cost'
  }
];

export default { getColumns };
