import React, { useState, useContext } from 'react';
import { Button, Input, message as messageAnt, Upload } from 'antd';
import { FormMessage } from './styles';
import ChatContext from './chatContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { PaperClipOutlined, SendOutlined } from '@ant-design/icons';
import { ModalChatAttachments } from 'Global/modules/Support/Chat/ModalChatAttachments';
import { useThreadsRepository } from 'repos/ThreadsRepository';

interface SendFormProps {
  getThreadById: (threadId: number) => Promise<any>;
}

/**
 * @description Форма отправки сообщения
 * @param {SendFormProps} props
 * @return {React.ReactNode}
 */
export const SendMessageForm = (props: SendFormProps) => {
  const { activeThreadId } = useContext(ChatContext);
  const { getThreadById } = props;
  const [message, setMessage] = useState<string>('');
  const [fileList, setFileList] = useState([]);
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [strings] = useLanguageContext();
  const threadsRepository = useThreadsRepository();

  const handleChange = (e): void => {
    const value = e.target.value;
    setMessage(value);
  };
  const onChangeUploaded = upload => {
    const { fileList: newFileList } = upload;
    setFileList(newFileList);
  };

  const uploadProps = {
    multiple: true,
    showUploadList: false,
    fileList: fileList,
    beforeUpload: () => false,
    onChange: upload => {
      if (upload.file) {
        onChangeUploaded(upload);
        setHasShowModal(true);
      }
      return false;
    }
  };

  /**
   * @description Функция отправки сообщения
   */
  const handleSendMessage = async (text?: string) => {
    try {
      setHasLoading(true);
      const params = { body: text ? text : message };
      if (fileList.isNotEmpty()) {
        params['files'] = fileList.map(file => file.originFileObj);
      }
      await threadsRepository.replyThread(activeThreadId, params);
      getThreadById(activeThreadId);
      setMessage('');
      messageAnt.success(strings.MESSAGE_SENT_SUCCESSFULLY);
    } catch {
      messageAnt.error(strings.MESSAGE_NOT_SENT_AN_ERROR_HAS_OCCURRED);
    } finally {
      setHasLoading(false);
    }
  };

  return (
    <FormMessage>
      <ModalChatAttachments
        hasShowModal={hasShowModal}
        setHasShowModal={setHasShowModal}
        hasLoadingSentMessage={hasLoading}
        handleSendMessage={handleSendMessage}
        message={message}
        fileList={fileList}
        setFileList={setFileList}
      />
      <Input.TextArea
        style={{ resize: 'none' }}
        placeholder={strings.ENTER_MESSAGE}
        onChange={handleChange}
        value={message}
      />
      <Upload {...uploadProps}>
        <PaperClipOutlined style={{ cursor: 'pointer', fontSize: 20 }} />
      </Upload>
      <Button
        type="primary"
        size="large"
        shape="circle"
        icon={<SendOutlined />}
        onClick={() => handleSendMessage()}
        disabled={message.trimStart().length <= 1 || hasLoading}
        loading={hasLoading}
        style={{ width: 24 }}
      />
    </FormMessage>
  );
};

export default { SendMessageForm };
