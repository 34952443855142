import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { Modal, Table, message, Button, Input, Flex, Typography } from 'antd';
import { dateTimeWithoutSecondFormat } from 'helpers/dates';
import { useUserContext } from 'contexts/UserContext';
import { Auth } from 'api';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';
import { useTeacherRepository } from 'repos/TeacherRepository';
import { Lesson } from 'types/education';
import { IStudent } from 'types';
import { FeedbackType, IFeedbackParams } from '../../../types/Reports';

const StInitialComment = styled.p`
  p {
    color: #9e9e9e;
  }
`;

interface RejectedCommentsModalProps {
  hasShow: boolean;
  onClose: () => void;
}
interface IRejectedComment {
  id: number;
  lesson: Lesson;
  student: IStudent;
  commentForParent: string | null;
  commentForParent2: string | null;
  commentForParent3: string | null;
  commentForParentDeclineComment: string;
  commentForStudent: string | null;
  commentForStudentDeclineComment: string | null;
}

const ExpandedComments = ({ record, getRejectedComments }) => {
  const [strings] = useLanguageContext();
  const TEXTAREA_HEIGHT = 150;
  const reportsRepository = useProgressReportsRepository();
  const [user, setUser] = useUserContext();
  const [studentComment, setStudentComment] = useState<string>(record?.commentForStudent);
  const [parentComment, setParentComment] = useState<string>(record?.commentForParent);
  const [parentComment2, setParentComment2] = useState<string>(record?.commentForParent2);
  const [hasParentCommentChanged, setHasParentCommentChanged] = useState<boolean>(false);
  const [hasStudentCommentChanged, setHasStudentCommentChanged] = useState<boolean>(false);
  const [hasLoadUpdate, setHasLoadUpdate] = useState<boolean>(false);

  const recountUserRejectedComments = async () => {
    try {
      const {
        data: { rejectedCommentsCount }
      } = await Auth.getUserInfo();

      setUser({ ...user, rejectedCommentsCount });
      await getRejectedComments();
    } catch {}
  };

  const handleChangeStudentComment = ({ target: { value } }) => {
    setStudentComment(value);
    setHasStudentCommentChanged(true);
  };

  const handleChangeParentComment = ({ target: { value } }) => {
    setParentComment(value);
    setHasParentCommentChanged(true);
  };

  const handleChangeParentComment2 = ({ target: { value } }) => {
    setParentComment2(value);
    setHasParentCommentChanged(true);
  };

  const onUpdate = async (type: FeedbackType) => {
    try {
      setHasLoadUpdate(true);
      let commentParams = {};
      if (type === 'student') {
        commentParams = { comment: studentComment };
      } else {
        commentParams = {
          comment: parentComment,
          comment2: parentComment2
        };
      }
      const params = { type: type, ...commentParams } as IFeedbackParams;
      await reportsRepository.editRegisterComment(record.id, params);
      await recountUserRejectedComments();
      message.success(`${strings.SUCCESSFULLY_CHANGED} :)`, 2);
      setHasLoadUpdate(false);
    } catch {
      message.error(strings.CHANGE_ERROR_TRY_AGAIN_LATER, 2);
      setHasLoadUpdate(false);
    }
  };

  return (
    <Flex gap={20} vertical={true}>
      {record?.commentForStudent && (
        <div>
          <Typography.Title level={5}>{strings.FEEDBACK_FOR_THE_STUDENT}</Typography.Title>
          <StInitialComment>
            <p>{record?.commentForStudentDeclineComment}</p>
            {record?.commentForStudent && (
              <Input.TextArea
                onChange={handleChangeStudentComment}
                style={{ minHeight: TEXTAREA_HEIGHT, margin: '10px 0' }}
                value={studentComment}
              />
            )}
            <Button
              style={{ marginTop: 10 }}
              disabled={!hasStudentCommentChanged}
              loading={hasLoadUpdate}
              type="primary"
              onClick={() => onUpdate('student')}
            >
              {strings.UPDATE}
            </Button>
          </StInitialComment>
        </div>
      )}
      {(record?.commentForParent || record?.commentForParent2) && (
        <div>
          <Typography.Title level={5}>{strings.FEEDBACK_FOR_THE_PARENTS}</Typography.Title>
          <StInitialComment>
            <p>{record?.commentForParentDeclineComment}</p>
            <Flex gap={20}>
              <div style={{ flex: 1 }}>
                <span>{strings.OVERALL_PROGRESS_SPECIFIC_DETAILS_1_2}</span>
                <Input.TextArea
                  onChange={handleChangeParentComment}
                  style={{ minHeight: TEXTAREA_HEIGHT, margin: '10px 0' }}
                  value={parentComment}
                />
              </div>
              <div style={{ flex: 1 }}>
                <span>{strings.GOAL_WAY_TO_ACHIEVE_IT}</span>
                <Input.TextArea
                  onChange={handleChangeParentComment2}
                  style={{ minHeight: TEXTAREA_HEIGHT, margin: '10px 0' }}
                  value={parentComment2}
                />
              </div>
            </Flex>
            <Button
              style={{ marginTop: 10 }}
              disabled={!hasParentCommentChanged}
              loading={hasLoadUpdate}
              type="primary"
              onClick={() => onUpdate('parent')}
            >
              {strings.UPDATE}
            </Button>
          </StInitialComment>
        </div>
      )}
    </Flex>
  );
};

export const RejectedCommentsModal = ({ hasShow, onClose }: RejectedCommentsModalProps) => {
  const [strings] = useLanguageContext();
  const teacherRepository = useTeacherRepository();
  const [rejectedComments, setRejectedComments] = useState<IRejectedComment[]>([]);
  const [hasLoading, setHasLoading] = useState<boolean>(false);

  const columns = [
    {
      title: 'ID',
      key: 'ID',
      dataIndex: 'lesson',
      render: ({ id }: Lesson) => {
        return (
          <Link to={`/le/${id}`} onClick={() => onClose()}>
            {id}
          </Link>
        );
      }
    },
    {
      title: strings.STUDENT,
      dataIndex: 'student',
      key: 'student',
      render: ({ user }: IStudent) => {
        return `${user?.name} ${user?.surname}`;
      }
    },
    {
      title: strings.DATE,
      key: 'date',
      width: 200,
      dataIndex: 'lesson',
      render: ({ startAt }: Lesson) => {
        return startAt && dayjs(startAt).format(dateTimeWithoutSecondFormat);
      }
    },
    {
      title: strings.SUBJECT,
      key: 'subject',
      dataIndex: 'lesson',
      render: ({ courseGroup }: Lesson) => {
        return courseGroup?.subject?.name;
      }
    },
    {
      title: strings.GROUP,
      key: 'course',
      dataIndex: 'lesson',
      render: ({ courseGroup }: Lesson) => {
        return courseGroup?.groupName;
      }
    }
  ];

  const getRejectedComments = async (): Promise<any> => {
    try {
      setHasLoading(true);
      const { data } = await teacherRepository.getTeacherRejectedComments();
      setRejectedComments(data);
      setHasLoading(false);
    } catch {
      message.error(strings.ERROR_GETTING_COMMENTS_PLEASE_TRY_AGAIN_LATER);
      setRejectedComments([]);
      setHasLoading(false);
    }
  };

  const footerButtons = [
    <Button onClick={onClose} key="btnOk" type="primary">
      {strings.OK}
    </Button>
  ];

  useEffect(() => {
    if (hasShow) {
      getRejectedComments();
    }
  }, [hasShow]);

  return (
    <Modal width={1000} open={hasShow} onCancel={onClose} footer={footerButtons} destroyOnClose={true}>
      <InfoCircleTwoTone />
      <span style={{ marginInlineStart: 5, fontWeight: 500 }}>
        {strings.EXPAND_TO_SHOW_STUDENT_AND_PARENTS_COMMENTS}
      </span>
      <Table
        expandedRowRender={record => <ExpandedComments record={record} getRejectedComments={getRejectedComments} />}
        columns={columns}
        pagination={false}
        dataSource={rejectedComments}
        loading={hasLoading}
        rowKey="id"
        style={{ overflow: 'auto', marginTop: 20 }}
      />
    </Modal>
  );
};
