import React from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Row, Col, Select } from 'antd';
import { GetFieldDecoratorOptions } from '@ant-design/compatible/lib/form/Form';
import { TicketTypeSelect } from 'Global/modules/Support/Modal/TicketTypeSelect';
import { SeveritySelect } from 'Global/modules/Support/Modal/SeveritySelect';
import { BranchSelect } from 'Global/modules/Support/Modal/BranchSelect';
import { RoleSelect } from 'Global/modules/Support/Modal/RoleSelect';
import { CUSTOM_FIELD_USERS_AFFECTED_ID } from 'Global/modules/Support/Modal/constants';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';

interface FeedbackAdminProps {
  getFieldDecorator<T extends Object = {}>(
    id: keyof T,
    options?: GetFieldDecoratorOptions
  ): (node: React.ReactNode) => React.ReactNode;
}

const { Option } = Select;

/**
 * @description Форма отправки фидбэка для админа
 * @param {object} props
 * @return {React.ReactNode}
 */
export const FeedbackAdmin = (props: FeedbackAdminProps) => {
  const { getFieldDecorator } = props;
  const [user] = useUserContext();
  const [strings] = useLanguageContext();

  return (
    <Row gutter={[10, 10]}>
      <Col xs={24} lg={12}>
        <RoleSelect initialValue="Admin" getFieldDecorator={getFieldDecorator} />
      </Col>
      <Col xs={24} lg={12}>
        <BranchSelect initialValue={user?.branch?.name} getFieldDecorator={getFieldDecorator} />
      </Col>
      <Col xs={24} lg={24}>
        <TicketTypeSelect getFieldDecorator={getFieldDecorator} />
      </Col>
      <Col xs={24} lg={12}>
        <SeveritySelect getFieldDecorator={getFieldDecorator} />
      </Col>
      <Col xs={24} lg={12}>
        <MyFormItem label={strings.ESTIMATED_NUMBER_OF_USERS_AFFECTED} colon={false}>
          {getFieldDecorator(CUSTOM_FIELD_USERS_AFFECTED_ID, {
            rules: [{ required: false }]
          })(
            <Select placeholder={strings.SELECT_USERS}>
              <Option value="">&nbsp;</Option>
              <Option value="1-20" key="0">
                1-20
              </Option>
              <Option value="20-100" key="1">
                20-100
              </Option>
              <Option value="100_" key="2">
                100+
              </Option>
            </Select>
          )}
        </MyFormItem>
      </Col>
    </Row>
  );
};

export default { FeedbackAdmin };
