import React, { useState, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import { storageService } from 'contexts/StorageContext';
import { AMI_5_LOCATION, getUrlForStaticFiles } from 'helpers';
import { InsertMode } from 'jodit/types';

interface MyJoditEditorProps {
  value?: string;
  toolbar?: string[];
  onChange?: (value: string) => void;
  height?: number;
  size?: 'small' | 'middle' | 'large';
  getFormData?: (file: File) => FormData;
  pasteOnlyText?: boolean;
}
const MyJoditEditor: React.FC<MyJoditEditorProps> = ({
  value,
  toolbar,
  onChange,
  height = 250,
  getFormData,
  size = 'middle',
  pasteOnlyText
}) => {
  const editor = useRef(null);
  const [content, setContent] = useState<string>('');

  const uploader = {
    url: `${AMI_5_LOCATION}/api/v1/upload`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${storageService.getAccessToken()}`
    },
    buildData: function (data) {
      if (!data) {
        return null;
      }
      const file = data.get('files[0]');
      if (!file) {
        return null;
      }
      return getFormData(file);
    },
    isSuccess: resp => {
      return resp[0];
    },
    process: resp => {
      return getUrlForStaticFiles() + resp;
    },
    defaultHandlerSuccess: url => {
      handleImageInsert(url);
    }
  };

  const handleImageInsert = url => {
    if (url) {
      const textarea = editor.current as HTMLElement;
      const wysiwyg = textarea.previousElementSibling.querySelector('.jodit-wysiwyg');
      const imgElement = document.createElement('img');
      imgElement.src = url;
      let selection = window.getSelection();
      let range = selection.getRangeAt(0);
      if (selection.rangeCount > 0 && wysiwyg.contains(range.startContainer)) {
        range.insertNode(imgElement);
      } else {
        wysiwyg.appendChild(imgElement);
      }
      wysiwyg.dispatchEvent(new Event('input'));
    }
  };

  const config = useMemo(() => {
    return {
      readonly: false,
      placeholder: '',
      buttons: toolbar || [
        'source',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'ul',
        'ol',
        'font',
        'fontsize',
        'brush',
        'paragraph',
        'image',
        'table',
        'link',
        'align',
        'eraser',
        'undo',
        'redo',
        'fullsize'
      ],
      toolbarAdaptive: false,
      toolbarButtonSize: size,
      uploader: getFormData && uploader,
      height: height,
      minHeight: 100,
      askBeforePasteHTML: false,
      defaultActionOnPaste: (pasteOnlyText ? 'insert_only_text' : 'insert_as_html') as InsertMode,
      events: {
        afterInit: (editorInstance: any) => {
          editorInstance.events.on('afterPaste', (event: ClipboardEvent) => {
            setTimeout(() => {
              const editorContent = editorInstance.value;
              const parser = new DOMParser();
              const doc = parser.parseFromString(editorContent, 'text/html');
              const iframes = doc.querySelectorAll<HTMLIFrameElement>(
                'iframe[src*="youtube.com"], iframe[src*="youtu.be"]'
              );

              if (iframes.length > 0) {
                let lastConvertedLink = null;
                iframes.forEach(iframe => {
                  const src = iframe.src;
                  const urlMatch = src.match(
                    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/embed\/|youtu\.be\/)([a-zA-Z0-9_-]{11})/
                  );
                  if (urlMatch && urlMatch[1]) {
                    const videoId = urlMatch[1];
                    const url = `https://www.youtube.com/watch?v=${videoId}`;
                    const newLink = doc.createElement('a');
                    newLink.href = url;
                    newLink.target = '_blank';
                    newLink.textContent = url;
                    iframe.parentNode?.replaceChild(newLink, iframe);
                    lastConvertedLink = newLink.href;
                  }
                });

                editorInstance.value = doc.body.innerHTML;

                if (lastConvertedLink) {
                  const link = editorInstance.editorWindow.document.querySelector(`a[href="${lastConvertedLink}"]`);
                  if (link) {
                    editorInstance.selection.setCursorAfter(link);
                  }
                }
              }
            }, 100);
          });
        }
      }
    };
  }, [height]);

  const handleChange = (newContent: string) => {
    setContent(newContent);
    onChange && onChange(newContent);
  };

  useEffect(() => {
    setContent(value);
  }, [value]);

  return <JoditEditor ref={editor} value={content} config={config} onChange={handleChange} />;
};

export const MyJoditEditorMemo = React.memo(MyJoditEditor);
export default MyJoditEditor;
