import React from 'react';
import styled from 'styled-components';
import { Button, Flex } from 'antd';
import { downloadFile } from 'helpers';

const StyledAttachments = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
`;
const StyledAttachment = styled.div``;
const StyledAttachmentImage = styled.img`
  width: 50px;
  cursor: pointer;
  border: 1px solid #dedede;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
  margin-inline-end: 5px;
  &:hover {
    opacity: 0.7;
    transition: all 0.3s ease;
  }
`;

export const MessageAttachments = ({ attachments, setHasShowModal, setPreviewAttachment }) => {
  const { images, files } = attachments.reduce(
    (acc, item) => {
      if (item?.content_type?.includes('image')) {
        acc.images.push(item);
      } else {
        acc.files.push(item);
      }
      return acc;
    },
    { images: [], files: [] }
  );
  const handleClick = (url: string) => {
    setHasShowModal(true);
    setPreviewAttachment(url);
  };

  return (
    <Flex vertical gap={5}>
      {images?.isNotEmpty() && (
        <StyledAttachments>
          {images.map(({ id, mapped_content_url }) => (
            <StyledAttachment key={id.toString()}>
              <StyledAttachmentImage src={mapped_content_url} onClick={() => handleClick(mapped_content_url)} />
            </StyledAttachment>
          ))}
        </StyledAttachments>
      )}
      {files?.map(({ file_name, mapped_content_url }) => (
        <Button
          type="link"
          style={{ padding: 0, textAlign: 'left', fontSize: 14 }}
          onClick={() => {
            downloadFile(mapped_content_url, file_name);
          }}
        >
          {file_name}
        </Button>
      ))}
    </Flex>
  );
};
