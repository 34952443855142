import React, { useState, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import { Select, Col, Row, DatePicker, Badge } from 'antd';
import { TutorSelect } from 'Global/components/FormComponentsCompatible/Selects/TutorSelect';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { Branch, Subject } from 'types';
import { isEmpty, pickBy } from 'lodash';
import { TeacherSelect } from 'Global/components/FormComponentsCompatible/Selects/TeacherSelect';
import { ExpertSelect } from 'Global/components/FormComponentsCompatible/Selects/ExpertSelect';
import { AssessmentsFilterParams, AssessmentStatus } from 'types/accessment';
import { GroupSelect } from 'Global/components/FormComponentsCompatible/Selects/GroupSelect';
import { dateFormat, dateFormatForBackend } from 'helpers/dates';
import dayjs, {Dayjs} from 'dayjs';
import { Global } from 'api/Global';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { StageSelect } from 'Global/components/FormComponentsCompatible/Selects/StageSelect';
import { useAssessmentsRepository } from 'repos/AssessmentsRepository';
import { FilterButtons } from 'Global/components/FilterButtons';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';

const { Option } = Select;
const { RangePicker } = DatePicker;

interface FilterProps {
  form: WrappedFormUtils;
  getAssessmentsList: (params: AssessmentsFilterParams) => Promise<any>;
  defValues: AssessmentsFilterParams;
}

/**
 * @description Filter Assessment table
 * @return {React.ReactNode}
 */
export const FilterTemplate = (props: FilterProps) => {
  const [strings] = useLanguageContext();
  const [assessmentsStatuses, setAssessmentsStatuses] = useState<AssessmentStatus[]>([]);
  const [branchesWithAssessment, setBranchesWithAssessment] = useState<Branch[]>([]);
  const { subjects } = useGlobalCollectionsContext();
  const { locationSearchParams } = useLocationSearchParams();
  const { branch, stage, filterDaysVideo, group, subject, assessmentStatus, experts, tutor, teacher } =
    locationSearchParams || {};

  const assessmentsRepository = useAssessmentsRepository();
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);

  const {
    form,
    form: { getFieldDecorator, validateFieldsAndScroll, resetFields },
    getAssessmentsList,
    defValues
  } = props;

  /**
   * @description Получение списка бранчей
   */
  const getBranchesWithAssessment = async (): Promise<any> => {
    try {
      const response = await Global.getBranchesForAssessment();
      if (response.status === 200) {
        setBranchesWithAssessment(response.data);
      }
    } catch {}
  };

  /**
   * @description Form Submit
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values) => {
      if (isEmpty(errors)) {
        if (values?.date && values.date instanceof Array && values.date.length > 0) {
          const [dateFrom, dateTo]: [Dayjs, Dayjs] = values.date;
          values['dateFrom'] = dateFrom.format(dateFormatForBackend);
          values['dateTo'] = dateTo.format(dateFormatForBackend);
          delete values['date'];
        }
        const filterParams = pickBy(values);
        try {
          setHasSubmitLoading(true);
          await getAssessmentsList(filterParams);
        } finally {
          setHasSubmitLoading(false);
        }
      }
    });
  };

  /**
   * @description Получение AssessmentsStatuses
   * @return {Promise<any>}
   */
  const getAssessmentsStatuses = async (): Promise<any> => {
    try {
      const { data } = await assessmentsRepository.getAssessmentStatuses();
      setAssessmentsStatuses(data);
    } catch {
      setAssessmentsStatuses([]);
    }
  };

  const setInitialRangePickerValue = (keyStart: string, keyEnd: string): any => {
    const valueDateStart = locationSearchParams[keyStart];
    const valueDateEnd = locationSearchParams[keyEnd];
    if (valueDateStart && valueDateEnd) {
      return [dayjs(valueDateStart), dayjs(valueDateEnd)];
    }
  };

  /**
   * @description Очистка фильтра
   * @return {void}
   */
  const handleClear = async (): Promise<any> => {
    await getAssessmentsList(defValues);
    resetFields();
  };

  useEffect(() => {
    getAssessmentsStatuses();
    getBranchesWithAssessment();
  }, []);

  const activeAssessmentStatuses = assessmentsStatuses.filter((status: AssessmentStatus) => status.active);

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={[10, 10]}>
        <Col lg={6}>
          {getFieldDecorator('branch', {
            initialValue: branch
          })(
            <Select placeholder={strings.BRANCH} mode="multiple" maxTagCount={1}>
              {branchesWithAssessment.map((branch: Branch) => (
                <Option value={branch.id} key={branch.id}>
                  {branch.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col lg={6}>
          <StageSelect form={form} initialValue={stage} mode="multiple" maxTagCount={1} />
        </Col>
        <Col lg={6}>
          {getFieldDecorator('filterDaysVideo', {
            initialValue: filterDaysVideo
          })(
            <Select placeholder={strings.DAYS_ON_VIDEO} maxTagCount={1}>
              <Option value={undefined}>&nbsp;</Option>
              {['0-7', '8-14', '15-21', '> 22'].map((status: string) => (
                <Option value={status} key={status}>
                  {status}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col lg={6}>
          <GroupSelect initGroup={group} name="group" getFieldDecorator={getFieldDecorator} />
        </Col>
        <Col lg={6}>
          {getFieldDecorator('subject', {
            initialValue: subject
          })(
            <Select placeholder={strings.SUBJECT} mode="multiple" maxTagCount={1}>
              {subjects.map((subject: Subject) => (
                <Option key={subject.id} value={subject.id}>
                  {subject.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col lg={6}>
          <TutorSelect
            placeholder={strings.ADM}
            getFieldDecorator={getFieldDecorator}
            name="tutor"
            initTutor={tutor}
            style={{ margin: 0 }}
          />
        </Col>
        <Col lg={6}>
          <TeacherSelect
            initTeacherId={teacher}
            placeholder={strings.TEACHERS}
            getFieldDecorator={getFieldDecorator}
            name="teacher"
          />
        </Col>
        <Col lg={6}>
          <ExpertSelect
            initExperts={experts}
            getFieldDecorator={getFieldDecorator}
            name="experts"
            placeholder={strings.TEACHERS_EXPERT}
            style={{ margin: 0 }}
          />
        </Col>
        <Col lg={6}>
          {getFieldDecorator('date', {
            initialValue: setInitialRangePickerValue('dateFrom', 'dateTo')
          })(<RangePicker format={dateFormat} />)}
        </Col>
        <Col lg={12}>
          {getFieldDecorator('assessmentStatus', {
            initialValue: assessmentStatus
          })(
            <Select placeholder={strings.ASSESSMENT_STATUSES} mode="multiple" maxTagCount={1}>
              {activeAssessmentStatuses.map((status: AssessmentStatus) => {
                const statusColor = status.color.match('#') ? status.color : '#' + status.color;
                return (
                  <Option value={status.id} key={status.id}>
                    {status.name} <Badge color={statusColor} />
                  </Option>
                );
              })}
            </Select>
          )}
        </Col>
      </Row>
      <FilterButtons handleClear={handleClear} hasSubmitLoading={hasSubmitLoading} />
    </Form>
  );
};

export const Filter = Form.create<FilterProps>({})(FilterTemplate);

export default { Filter };
