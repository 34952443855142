import React from 'react';
import { NewsRepositoryProvider } from 'repos/NewsRepository';
import { UserRepositoryProvider } from 'repos/UserRepository';
import { ProgressReportsRepositoryProvider } from 'repos/ProgressReportsRepository';
import { CourseRepositoryProvider } from 'repos/CoursesRepository';
import { TeacherRepositoryProvider } from 'repos/TeacherRepository';
import { StudentRepositoryProvider } from 'repos/StudentRepository';
import { MetaGoalsRepositoryProvider } from 'repos/MetaGoalsRepository';
import { SupportRepositoryProvider } from 'repos/SupportRepository';
import { ParentJournalRepositoryProvider } from 'repos/ParentJournalRepository';
import { AssistantFeedbackRepositoryProvider } from 'repos/AssistantsFeedbackRepository';
import { LessonRepositoryProvider } from 'repos/LessonRepository';
import { LangRepositoryProvider } from 'repos/LangRepository';
import { GlobalRequestsRepositoryProvider } from 'repos/GlobalRequestsRepository';
import { InvoicesRepositoryProvider } from 'repos/InvoicesRepository';
import { TrainingsRepositoryProvider } from 'repos/TrainingsRepository';
import { AdminRepositoryProvider } from 'repos/AdminRepository';
import { FamilyRepositoryProvider } from 'repos/FamilyRepository';
import { UserAccessRepositoryProvider } from './UserAccessRepostirory';
import { ScheduleRepositoryProvider } from './ScheduleRepostirory';
import { MailingRepositoryProvider } from 'repos/MailingRepository';
import { AttachmentRepositoryProvider } from 'repos/AttachmentRepository';
import { AssessmentsRepositoryProvider } from './AssessmentsRepository';
import { ClassroomsRepositoryProvider } from './ClassroomsRepository';
import { ThreadsRepositoryProvider } from './ThreadsRepository';
import { PerformersRepositoryProvider } from './PerformersRepository';
import { SupergroupRepositoryProvider } from './SupergroupRepository';
import { CommentaryCalendarRepositoryProvider } from './CommentaryCalendarRepository';
import { SubjectsRepositoryProvider } from './SubjectsRepository';
import { StagesRepositoryProvider } from './StagesRepository';
import { TemplatesRepositoryProvider } from './TemplatesRepository';
import { StatusesRepositoryProvider } from './StatusesRepository';
import { BranchesRepositoryProvider } from './BranchesRepository';
import { TagsRepositoryProvider } from './TagsRepository';

export interface IRepositoryProvider {
  children: React.ReactNode;
}

export const RepositoryProvider: React.FC<IRepositoryProvider> = ({ children }) => (
  <UserRepositoryProvider>
    <GlobalRequestsRepositoryProvider>
      <TeacherRepositoryProvider>
        <LessonRepositoryProvider>
          <NewsRepositoryProvider>
            <ProgressReportsRepositoryProvider>
              <CourseRepositoryProvider>
                <StudentRepositoryProvider>
                  <MetaGoalsRepositoryProvider>
                    <SupportRepositoryProvider>
                      <ParentJournalRepositoryProvider>
                        <InvoicesRepositoryProvider>
                          <AssistantFeedbackRepositoryProvider>
                            <LangRepositoryProvider>
                              <TrainingsRepositoryProvider>
                                <AdminRepositoryProvider>
                                  <UserAccessRepositoryProvider>
                                    <ScheduleRepositoryProvider>
                                      <FamilyRepositoryProvider>
                                        <MailingRepositoryProvider>
                                          <AttachmentRepositoryProvider>
                                            <AssessmentsRepositoryProvider>
                                              <ClassroomsRepositoryProvider>
                                                <ThreadsRepositoryProvider>
                                                  <PerformersRepositoryProvider>
                                                    <SupergroupRepositoryProvider>
                                                      <CommentaryCalendarRepositoryProvider>
                                                        <SubjectsRepositoryProvider>
                                                          <TemplatesRepositoryProvider>
                                                            <StagesRepositoryProvider>
                                                              <StatusesRepositoryProvider>
                                                                <BranchesRepositoryProvider>
                                                                  <TagsRepositoryProvider>
                                                                    {children}
                                                                  </TagsRepositoryProvider>
                                                                </BranchesRepositoryProvider>
                                                              </StatusesRepositoryProvider>
                                                            </StagesRepositoryProvider>
                                                          </TemplatesRepositoryProvider>
                                                        </SubjectsRepositoryProvider>
                                                      </CommentaryCalendarRepositoryProvider>
                                                    </SupergroupRepositoryProvider>
                                                  </PerformersRepositoryProvider>
                                                </ThreadsRepositoryProvider>
                                              </ClassroomsRepositoryProvider>
                                            </AssessmentsRepositoryProvider>
                                          </AttachmentRepositoryProvider>
                                        </MailingRepositoryProvider>
                                      </FamilyRepositoryProvider>
                                    </ScheduleRepositoryProvider>
                                  </UserAccessRepositoryProvider>
                                </AdminRepositoryProvider>
                              </TrainingsRepositoryProvider>
                            </LangRepositoryProvider>
                          </AssistantFeedbackRepositoryProvider>
                        </InvoicesRepositoryProvider>
                      </ParentJournalRepositoryProvider>
                    </SupportRepositoryProvider>
                  </MetaGoalsRepositoryProvider>
                </StudentRepositoryProvider>
              </CourseRepositoryProvider>
            </ProgressReportsRepositoryProvider>
          </NewsRepositoryProvider>
        </LessonRepositoryProvider>
      </TeacherRepositoryProvider>
    </GlobalRequestsRepositoryProvider>
  </UserRepositoryProvider>
);
