export const supergroupsStatusesMap = new Map();
export const supergroupsStatusesColorMap = new Map();

export const PENDING_STATUS = 1;
export const READY_FOR_SENDING_STATUS = 2;
export const SENT_STATUS = 3;

supergroupsStatusesColorMap.set(PENDING_STATUS, '#1890FF');
supergroupsStatusesColorMap.set(READY_FOR_SENDING_STATUS, '#FAFF18');
supergroupsStatusesColorMap.set(SENT_STATUS, '#0BC34A');

supergroupsStatusesMap.set(PENDING_STATUS, 'Pending');
supergroupsStatusesMap.set(READY_FOR_SENDING_STATUS, 'Ready for sending');
supergroupsStatusesMap.set(SENT_STATUS, 'Sent');
