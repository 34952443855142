import React from 'react';
import { last } from 'lodash';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { BASE_SUMMARY_TABLE } from '../routes';
import { IAssessment } from 'types/accessment';
import { ClockCircleOutlined, EyeOutlined } from '@ant-design/icons';
import { Tooltip, Popover } from 'antd';
import { booleanRender } from 'helpers';
import { dateFormat } from 'helpers/dates';

/**
 * @description Получение title для tooltip
 * @param {IAssessment} assessment
 * @return {React.ReactNode}
 */
const getAssessmentTitle = (assessment: IAssessment): React.ReactNode => {
  const { mark, logMark, adminFullName, markLastSave } = assessment;

  if (mark) {
    if (logMark.length > 0) {
      const { author, date } = last(logMark);

      return (
        <Tooltip title={`Last save: ${date} by ${author}`}>
          <span style={{ cursor: 'pointer' }}>{mark}</span>
        </Tooltip>
      );
    }

    return (
      <Tooltip title={`Last save: ${markLastSave.replace(/-/g, '.')} by ${adminFullName}`}>
        <span style={{ cursor: 'pointer' }}>{mark}</span>
      </Tooltip>
    );
  }

  return null;
};

/**
 * @description feedback render
 * @return {Promise<any>}
 */
const feedback = (_: undefined, assessment: IAssessment): React.ReactNode => {
  const { gotFeedback, repliedFeedback, reportedToCollabhistory, visibleTeacher } = assessment;
  const countTrue = [gotFeedback, repliedFeedback, reportedToCollabhistory, visibleTeacher].filter(a => a);

  const content = (
    <div>
      <p>Teacher got feedback: {booleanRender(gotFeedback)}</p>
      <p>Teacher replied to feedback: {booleanRender(repliedFeedback)}</p>
      <p>Reported to Collabhistory: {booleanRender(reportedToCollabhistory)}</p>
      <p>Visible to teacher: {booleanRender(visibleTeacher)}</p>
    </div>
  );

  return (
    <Popover content={content} title="Feedback">
      {`${countTrue.length}/4`}
    </Popover>
  );
};

const setDefaultSortOrder = (sort): any => {
  if (sort) {
    return sort === 'desc' ? ('descend' as 'descend') : ('ascend' as 'ascend');
  }
};

export const getColumns = (strings, sortParams) => {
  const { sortByVideoDate, direction } = sortParams;
  return [
    {
      title: strings.ID,
      dataIndex: 'lesson',
      key: 'id',
      render: lesson => {
        return (
          <Link to={`${BASE_SUMMARY_TABLE}/${lesson?.id}`}>
            <EyeOutlined />
          </Link>
        );
      },
      width: 50
    },
    {
      title: strings.DATE,
      dataIndex: 'lesson',
      key: 'startAt',
      sorter: true,
      width: 120,
      ellipsis: true,
      defaultSortOrder: setDefaultSortOrder(direction),
      render: lesson => lesson.startAt && dayjs(lesson.startAt).format(dateFormat)
    },
    {
      title: strings.VIDEO_AVAILABLE,
      dataIndex: 'lesson',
      key: 'videoAvailable',
      sorter: true,
      width: 170,
      align: 'center' as 'center',
      defaultSortOrder: setDefaultSortOrder(sortByVideoDate),
      render: lesson => (lesson?.videoAvailable !== '-' ? dayjs(lesson?.videoAvailable).format(dateFormat) : '-')
    },
    {
      title: () => (
        <Tooltip title={strings.DAYS_SINCE_VIDEO_LESSON}>
          <ClockCircleOutlined /> (L)
        </Tooltip>
      ),
      dataIndex: 'daysSinceVideoLesson',
      key: 'daysSinceVideoLesson',
      width: 80,
      align: 'center' as 'center'
    },
    {
      title: () => (
        <Tooltip title={strings.DAYS_SINCE_VIDEO_HW}>
          <ClockCircleOutlined /> (H)
        </Tooltip>
      ),
      dataIndex: 'daysSinceVideoHW',
      key: 'daysSinceVideoHW',
      width: 80,
      align: 'center' as 'center'
    },
    {
      title: () => (
        <Tooltip title={strings.DAYS_SINCE_VIDEO_ASSESSED}>
          <ClockCircleOutlined /> (E)
        </Tooltip>
      ),
      dataIndex: 'daysSinceVideoExpert',
      key: 'daysSinceVideoExpert',
      width: 80,
      align: 'center' as 'center'
    },
    {
      title: strings.BRANCH,
      dataIndex: 'lesson',
      key: 'branch',
      width: 120,
      render: lesson => lesson?.courseGroup.branch?.name
    },
    {
      title: strings.TEACHER,
      dataIndex: 'lesson',
      key: 'teacher',
      width: 150,
      render: lesson => {
        const { teacher } = lesson;
        return teacher ? (
          <Link to={`/teachers/${teacher.id}`}>
            {teacher?.user?.name} {teacher?.user?.surname}
          </Link>
        ) : null;
      }
    },
    {
      title: strings.SUBJECT,
      dataIndex: 'lesson',
      key: 'subject',
      width: 150,
      render: lesson => lesson?.courseGroup?.subject?.name ?? '-'
    },
    {
      title: strings.GROUP,
      dataIndex: 'lesson',
      key: 'courseGroup',
      width: 180,
      render: lesson =>
        lesson?.courseGroup ? <Link to={`/group/${lesson.courseGroup.id}`}>{lesson.courseGroup.name}</Link> : null
    },
    {
      title: strings.FEEDBACK,
      dataIndex: 'feedback',
      width: 120,
      align: 'center' as 'center',
      render: feedback
    },
    {
      title: strings.LESSON_ASSESSMENT,
      dataIndex: 'lessonAssessment',
      key: 'lessonAssessment',
      width: 140,
      render: (assessment: IAssessment) => getAssessmentTitle(assessment)
    },
    {
      title: strings.HW_DESIGN,
      dataIndex: 'hwAssessment',
      key: 'hwAssessment',
      width: 120,
      render: (assessment: IAssessment) => getAssessmentTitle(assessment)
    },
    {
      title: strings.EXPERT_ASSESSMENT,
      dataIndex: 'expertAssessment',
      key: 'expertAssessment',
      width: 140,
      render: (assessment: IAssessment) => getAssessmentTitle(assessment)
    }
  ];
};

export default { getColumns };
