import { useState } from 'react';
import { UploadAttachmentParams } from 'types/global';
import { httpService } from 'api/core';

export const useAttachment = url => {
  const [uploadPercent, setUploadPercent] = useState<number>(0);

  const uploadAttachment = async (params: Partial<UploadAttachmentParams & FormData>): Promise<any> => {
    return await httpService.post(
      url,
      params,
      {
        onUploadProgress: function (progressEvent) {
          setUploadPercent(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }
      },
      false
    );
  };

  return { uploadAttachment, uploadPercent };
};
