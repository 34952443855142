import React, { useState } from 'react';
import styled from 'styled-components';
import { LV_KEY, EN_KEY, RU_KEY, LANGS, GE_KEY, IW_KEY } from './index';
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Button, Input, Row, Col, message } from 'antd';
import { useLangRepository } from 'repos/LangRepository';
import { useLanguageContext } from 'contexts/LanguageContext';
import { copyingText } from 'helpers';
import { words, toUpper } from 'lodash';

const StyledAddTransition = styled(Form)`
  padding: 5px;
  background: #f0f0f0;
  border-radius: 5px;
  margin: 10px 0;
  border: 1px solid #d1d1d1;
`;

const StFormItem = styled(MyFormItem)`
  margin: 0;
`;

export function AddTranslation({ getTranslations }) {
  const langRepo = useLangRepository();
  const [strings] = useLanguageContext();
  const [key, setKey] = useState<string>(null);
  const [hasCreated, setHasCreated] = useState<boolean>(false);
  const [ruValue, setRuValue] = useState<string>(null);
  const [enValue, setEnValue] = useState<string>(null);
  const [lvValue, setLvValue] = useState<string>(null);
  const [geValue, setGeValue] = useState<string>(null);
  const [iwValue, setIwValue] = useState<string>(null);

  const setValue = (value, lang) => {
    switch (lang) {
      case EN_KEY:
        setEnValue(value);
        break;
      case LV_KEY:
        setLvValue(value);
        break;
      case RU_KEY:
        setRuValue(value);
        break;
      case GE_KEY:
        setGeValue(value);
        break;
      case IW_KEY:
        setIwValue(value);
        break;
    }
  };

  const createTranslation = async () => {
    try {
      if (strings.hasOwnProperty(key)) {
        message.success('The key already exists', 2);
        await copyingText(key, () => message.success('Key copied', 2));
        return;
      }

      if (!ruValue || !enValue || !lvValue || !geValue || !iwValue || !key) {
        message.error('Fill all languages please', 2);
        return;
      }
      setHasCreated(true);
      await copyingText(key, () => null);
      await langRepo.createTranslation({ key, translation: ruValue, lang: RU_KEY });
      await langRepo.createTranslation({ key, translation: enValue, lang: EN_KEY });
      await langRepo.createTranslation({ key, translation: lvValue, lang: LV_KEY });
      await langRepo.createTranslation({ key, translation: geValue, lang: GE_KEY });
      await langRepo.createTranslation({ key, translation: iwValue, lang: IW_KEY });
      await getTranslations();
      message.success('Successfully created', 2);
    } catch {
      message.error('Created error :(', 2);
    } finally {
      setHasCreated(false);
    }
  };

  const onChangeKey = ({ target: { value } }) => {
    setKey(
      toUpper(
        words(value)
          .join('_')
          .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
          .trim()
      )
    );
    // window.open(`https://translate.google.com/?sl=en&tl=lv&text=${enValue}group&op=translate`);
  };

  return (
    <StyledAddTransition layout="vertical">
      <Row gutter={[4, 4]}>
        <Col lg={12}>
          <StFormItem label={strings.ENTER_KEY}>
            <Input placeholder={strings.ENTER_KEY} onChange={onChangeKey} value={key} />
          </StFormItem>
        </Col>
        {LANGS.map(lang => (
          <Col lg={6}>
            <StFormItem label={`Value ${lang}:`}>
              <Input placeholder={strings.ENTER_VALUE} onChange={({ target: { value } }) => setValue(value, lang)} />
            </StFormItem>
          </Col>
        ))}
        <Col lg={6} style={{ marginTop: 40 }}>
          <StFormItem>
            <Button
              icon={<PlusOutlined />}
              block
              size="large"
              loading={hasCreated}
              type="primary"
              onClick={createTranslation}
            >
              Create translation
            </Button>
          </StFormItem>
        </Col>
      </Row>
    </StyledAddTransition>
  );
}
