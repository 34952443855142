import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

interface DraggbleCardProps {
  id: string | number;
  index: number;
  data: object;
  CardComponent: typeof React.Component;
}

/**
 * @description карточка для перетаскивания
 * @param id id draggble
 * @param index
 * @param data
 * @param CardComponent компонент для карточки
 * @constructor
 */
export const DraggbleCard = ({ id, index, data, CardComponent }: DraggbleCardProps) => {
  return (
    <Draggable draggableId={String(id)} index={index}>
      {provider => (
        <div
          {...provider.dragHandleProps}
          {...provider.draggableProps}
          ref={provider.innerRef}
          className={'column-card-draggable'}
        >
          <CardComponent {...data} />
        </div>
      )}
    </Draggable>
  );
};

export default DraggbleCard;
