import * as teacher from 'Teacher/routes';
import * as monitoringRoutes from 'Admin/Monitoring/routes';
import * as adminCourseRoutes from 'Admin/Courses/routes';
import { BASE_ADMIN } from 'Admin/People/Administrators/routes';
import * as teachers from 'Admin/People/Teachers/routes';
import * as courses from 'Admin/Courses/routes';
import * as educationRoutes from 'Admin/Education/routes';
import {
  BASE_ONLINE_ACTIVITIES,
  BASE_ADD_ONLINE_ACTIVITY,
  BASE_HOMEWORKS_BASIC,
  BASE_HOMEWORKS_ADVANCED,
  BASE_CHAT
} from 'Global/routes';
import { schedule } from '../schedule';
import { ProgressReport, ViewProgressReport } from 'Admin/Monitoring';
import { Main, OriginalCourses, WorkProgress, Finance, Training, VideoNews, Grading, Chat } from 'Teacher';
import { Group, OriginalCourseFull } from 'Admin/Courses';
import { AdminPage } from 'Admin/People/Administrators/Admin';
import { Teacher } from 'Admin/People/Teachers/Teacher';
import { RouteProps } from 'react-router';
import { OnlineActivities, AddOnlineActivities, Homeworks } from 'Teacher/OnlineActivities';
import { GroupCourses } from 'Teacher/GroupCourses';
import { Materials } from 'Global/modules/Materials';
import { TrainingItem } from 'Admin/Education';
import { ProgressReportEditForm } from 'Admin/Monitoring';

export const teacherRoutes: RouteProps[] = [
  ...schedule,
  {
    path: '/',
    component: Main,
    exact: true
  },
  {
    path: BASE_ONLINE_ACTIVITIES,
    component: OnlineActivities,
    exact: true
  },
  {
    path: BASE_ADD_ONLINE_ACTIVITY,
    component: AddOnlineActivities
  },
  {
    path: [BASE_HOMEWORKS_BASIC, BASE_HOMEWORKS_ADVANCED],
    component: Homeworks
  },
  {
    path: teacher.BASE_TEACHER_ORIGINAL_COURSES,
    component: OriginalCourses,
    exact: true
  },
  {
    path: teacher.SELECTED_ORIGINAL_COURSE,
    component: OriginalCourseFull,
    exact: true
  },
  {
    path: teacher.BASE_GROUP_COURSES,
    component: GroupCourses,
    exact: true
  },
  {
    path: educationRoutes.BASE_MATERIALS,
    component: Materials,
    exact: true
  },
  {
    path: teacher.BASE_WORK_PROGRESS,
    component: WorkProgress,
    exact: true
  },
  {
    path: teacher.BASE_FINANCE,
    component: Finance,
    exact: true
  },
  {
    path: teacher.BASE_TRAININGS,
    component: Training,
    exact: true
  },
  {
    path: `${educationRoutes.BASE_TRAININGS}/:id`,
    component: TrainingItem,
    exact: true
  },
  {
    path: teacher.BASE_VIDEO_NEWS,
    component: VideoNews,
    exact: true
  },
  {
    path: teacher.BASE_GRADING,
    component: Grading,
    exact: true
  },
  {
    path: `${courses.BASE_COURSE_GROUP}/:id`,
    component: Group,
    exact: true
  },
  {
    path: monitoringRoutes.BASE_PROGRESS_REPORT,
    component: ProgressReport,
    exact: true
  },
  {
    path: `${monitoringRoutes.BASE_PROGRESS_REPORT}/edit/:id`,
    component: ProgressReportEditForm,
    exact: true
  },
  {
    path: `${monitoringRoutes.BASE_PROGRESS_REPORT}/:id`,
    component: ViewProgressReport,
    exact: true
  },
  {
    path: `${adminCourseRoutes.BASE_COURSE_GROUP}/:id`,
    component: Group,
    exact: true
  },
  {
    path: BASE_ADMIN,
    component: AdminPage,
    exact: true
  },
  {
    path: `${teachers.BASE_TEACHERS}/:id`,
    component: Teacher,
    exact: true
  },
  {
    path: adminCourseRoutes.ORIGINAL_COURSE,
    component: OriginalCourseFull,
    exact: true
  },
  {
    path: BASE_CHAT,
    component: Chat,
    exact: true
  }
];

export default { teacherRoutes };
