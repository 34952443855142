import React, { useMemo, useContext } from 'react';
import { httpService } from 'api/core';
import { ClassroomPostParams, ClassroomReserve, ClassroomsFilterParams } from 'types/education';

const ClassroomsRepositoryContext = React.createContext<IClassroomsRepository>(null);
interface IClassroomsRepository {
  getClassrooms: (params: ClassroomsFilterParams) => Promise<any>;
  getClassroomById: (id: number | string) => Promise<any>;
  editClassroom: (id: number | string, data: ClassroomPostParams) => Promise<any>;
  createClassroom: (data: ClassroomPostParams) => Promise<any>;
  deleteClassroom: (id: number | string) => Promise<any>;
  createClassroomReserve: (roomId: number | string, data: ClassroomReserve) => Promise<any>;
  deleteClassroomReserve: (id: number | string) => Promise<any>;
  editClassroomReserve: (id: number | string, data: ClassroomReserve) => Promise<any>;
}

class ClassroomsRepository implements IClassroomsRepository{
  getClassrooms(params: ClassroomsFilterParams) {
    return httpService.get('/api/v1/rooms', params, null, true);
  }

  getClassroomById(id: number | string) {
    return httpService.get(`/api/v1/rooms/${id}`, null, null, true);
  }

  editClassroom(id: number | string, data: ClassroomPostParams) {
    return httpService.put(`/api/v1/rooms/${id}`, data, null, null, true);
  }

  createClassroom(data: ClassroomPostParams) {
    return httpService.post(`/api/v1/rooms`, data, null, null, true);
  }

  deleteClassroom(id: number | string) {
    return httpService.delete(`/api/v1/rooms/${id}`, null, null, true);
  }

  createClassroomReserve(roomId: number | string, data: ClassroomReserve) {
    return httpService.post(`/api/v1/rooms/${roomId}/reserve`, data, null, null, true);
  }

  deleteClassroomReserve(id: number | string) {
    return httpService.delete(`/api/v1/rooms/reserve/${id}`, null, null, true);
  }

  editClassroomReserve(id: number | string, data: ClassroomReserve) {
    return httpService.put(`/api/v1/rooms/reserve/${id}`, data, null, null, true);
  }
}

export const useClassroomsRepository = () => {
  const service = useContext(ClassroomsRepositoryContext);

  if (!service) {
    throw new Error('Classrooms repository is unavailable');
  }

  return service;
};

export const ClassroomsRepositoryProvider = props => {
  const service = useMemo(() => new ClassroomsRepository(), []);

  return <ClassroomsRepositoryContext.Provider value={service}>{props.children}</ClassroomsRepositoryContext.Provider>;
};
