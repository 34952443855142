import React, { useEffect, useState } from 'react';
import { Button, Form, FormInstance, Row, Flex, Card } from 'antd';
import { useRouteMatch } from 'react-router-dom';
import { match } from 'react-router';
import styled from 'styled-components';
import { BrunerStudentForm } from './BrunerStudentForm';
import { Performer } from 'types';
import { BranchBelgrade, BranchBruner, BranchSPB } from 'types/branches';
import { BaseStudentForm } from './BaseStudentForm';
import { Global } from 'api';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

export const StRow = styled(Row)`
  flex: 1;
  width: 100%;
`;

interface IAddStudentsProps {
  familyBranch?: number;
  form?: FormInstance;
}

export const AddStudents: React.FC<IAddStudentsProps> = ({ familyBranch }) => {
  const {
    params: { id: familyId }
  }: match<{ id: string }> = useRouteMatch();

  const hasBruner = familyBranch === BranchBruner.id;

  return (
    <>
      <Form.List name="students">
        {(fields, { add, remove }) => (
          <Flex vertical gap={20}>
            {fields.map((field, index) => (
              <Flex gap={10}>
                {fields.length > 1 && (
                  <Button type="primary" shape="circle" icon={<MinusOutlined />} onClick={() => remove(field.name)} />
                )}
                <Card key={field.key}>
                  {hasBruner ? (
                    <BrunerStudentForm field={field} />
                  ) : (
                    <BaseStudentForm familyId={familyId} familyBranch={familyBranch} field={field} />
                  )}
                </Card>
              </Flex>
            ))}
            <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={() => add()} />
          </Flex>
        )}
      </Form.List>
    </>
  );
};
export default { AddStudents };
