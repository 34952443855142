import React from 'react';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Button, Col, Row, Input, Radio, Tooltip } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';

interface VotingProps {
  label: string;
  option: string;
  commentOption?: string;
  labelComment?: string;
  alertText?: string;
  initialValue?: string | boolean;
  initialValueComment?: string;
  form: WrappedFormUtils;
}

/**
 * @description Компонент с голосованием
 * @param {object} props
 * @return {React.ReactElement}
 */
export const VotingItem = (props: VotingProps) => {
  const [strings] = useLanguageContext();
  const {
    form: { getFieldDecorator, setFieldsValue },
    label,
    option,
    commentOption,
    labelComment,
    alertText,
    initialValue,
    initialValueComment
  } = props;

  /**
   * @description Очистить поле
   * @param {string} fieldName
   * @return {void}
   */
  const clearFieldByName = (fieldName: string): void => setFieldsValue({ [fieldName]: '' });

  return (
    <Row gutter={[10, 10]}>
      <Col lg={24}>
        <MyFormItem
          label={
            <>
              {label}{' '}
              {alertText && (
                <Tooltip title={alertText}>
                  <InfoCircleTwoTone style={{ fontSize: 22, margin: 10 }} twoToneColor="#1890ff" />
                </Tooltip>
              )}
            </>
          }
        >
          {getFieldDecorator(option, {
            initialValue: initialValue,
            rules: [{ required: false }]
          })(
            <Radio.Group>
              <Radio.Button value="ni">Ni</Radio.Button>
              <Radio.Button value="s">S</Radio.Button>
              <Radio.Button value="g">G</Radio.Button>
            </Radio.Group>
          )}
          <Button size="middle" style={{ marginInlineStart: 10 }} onClick={() => clearFieldByName(option)}>
            {strings.CLEAR}
          </Button>
        </MyFormItem>
      </Col>
      {commentOption && (
        <Col lg={24}>
          <MyFormItem label={labelComment || strings.СOMMENT}>
            {getFieldDecorator(commentOption, {
              initialValue: initialValueComment,
              rules: [{ required: false }]
            })(<Input.TextArea placeholder={strings.СOMMENT} />)}
          </MyFormItem>
        </Col>
      )}
    </Row>
  );
};

export default { VotingItem };
