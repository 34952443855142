import * as smsRoutes from '../../../Admin/Website/SMS/routes';
import { NewSms, SmsHistory, ShowSms } from '../../../Admin/Website/SMS';

export const sms = [
  {
    path: smsRoutes.BASE_SMS,
    component: NewSms,
    exact: true
  },
  {
    path: smsRoutes.SHOW_HISTORY,
    component: SmsHistory,
    exact: true
  },
  {
    path: smsRoutes.SHOW_SMS,
    component: ShowSms,
    exact: true
  }
];

export default { sms };
