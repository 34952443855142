import React, { useEffect, useState } from 'react';
import { Table, Row } from 'antd';
import { Filter } from './Filter';
import { columns } from './columns';
import { LessonsFilterParams, LessonsListItem } from 'types/education';
import { Courses } from 'api/Courses';
import { PaginationInfo } from 'types/global';
import { LESSONS_LIMIT, mockPagination } from 'Global/constants';
import { scrollTop } from 'helpers/scroll';
import Pagination from 'Global/components/Pagination';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

const { Column } = Table;

/**
 * @description Monitoring Lessons component
 * @param {object} location
 * @param {function} goBack
 * @param {function} push
 * @return {React.ReactNode}
 */
export const Lessons = () => {
  const [strings] = useLanguageContext();
  const [LessonsList, setLessonsList] = useState<LessonsListItem[]>([]);
  const [hasLoadLessonsList, setLoadLessonsList] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationInfo>(mockPagination);
  const limit = LESSONS_LIMIT;
  const { locationSearchParams } = useLocationSearchParams();
  const { goBack, push } = useHistory();
  const { pageCount, current } = pagination;
  const [user] = useUserContext();

  /**
   * @description Получение списка уроков
   * @param {LessonsFilterParams} params
   * @return {Promise<any>}
   */
  const getLessonsList = async (params: LessonsFilterParams): Promise<any> => {
    setLoadLessonsList(true);
    try {
      const {
        data: { lessons, paginationInfo }
      } = await Courses.getLessonsList({ ...params, limit });
      setLessonsList(lessons);
      setPagination(paginationInfo);
      push({ search: queryString.stringify(params) });
    } finally {
      setLoadLessonsList(false);
    }
  };

  /**
   * @description Пагинация
   * @param {number} page
   * @return {void}
   */
  const handlePage = async (page: number): Promise<any> => {
    const params = { ...locationSearchParams, page, limit };
    await getLessonsList(params);
    push({ search: queryString.stringify(params) });
    scrollTop();
  };

  useEffect(() => {
    getLessonsList({ ...locationSearchParams, branch: user?.branch?.id, page: 1 });
  }, []); // eslint-disable-line

  return (
    <Container>
      <StPageHeader onBack={goBack} title={strings.LESSONS}>
        <Filter getLessonsList={getLessonsList} />
      </StPageHeader>
      <Row gutter={[10, 10]}>
        <Table dataSource={LessonsList} pagination={false} bordered={false} loading={hasLoadLessonsList}>
          {columns(strings).map(col => (
            <Column key={col.key} {...col} />
          ))}
        </Table>
        <Pagination count={pageCount} current={current} pageChange={handlePage} />
      </Row>
    </Container>
  );
};

export default { Lessons };
