export const BASE_TEACHING_MATERIALS = '/teaching_materials';
export const BASE_MATERIALS = '/materials';
export const BASE_LESSON_TOPIC = '/lesson-topic';
export const BASE_TRAININGS = '/training';
export const TRAINING_ITEM = `${BASE_TRAININGS}/:id(\\d+)`;
export const TRAINING_NEW = `${BASE_TRAININGS}/new`;
export const TRAINING_EDIT = `${BASE_TRAININGS}/:id(\\d+)/edit`;
export const BASE_SOURCE_OF_REFERENCE = '/familylearnedfrom';
export const SOURCE_OF_REFERENCE_FULL = `${BASE_SOURCE_OF_REFERENCE}/:id(\\d+)`;
export const SOURCE_OF_REFERENCE_EDIT = `${BASE_SOURCE_OF_REFERENCE}/:id(\\d+)/edit`;
export const SOURCE_OF_REFERENCE_NEW = `${BASE_SOURCE_OF_REFERENCE}/new`;
export const BASE_ASSESSMENT_STATUSES = '/assessment-statuses';
export const ASSESSMENT_STATUSES_EDIT = `${BASE_ASSESSMENT_STATUSES}/:id(\\d+)/edit`;
export const ASSESSMENT_STATUSES_NEW = `${BASE_ASSESSMENT_STATUSES}/new`;
export const BASE_EXPERTS = '/experts';
export const BASE_CLASSROOMS = '/classrooms';
export const CLASSROOMS_EDIT = `${BASE_CLASSROOMS}/:id(\\d+)/edit`;
export const CLASSROOMS_VIEW = `${BASE_CLASSROOMS}/:id(\\d+)`;
export const CLASSROOMS_NEW = `${BASE_CLASSROOMS}/new`;
export const BASE_SUBJECTS = '/subjects';
export const SUBJECT_NEW = `${BASE_SUBJECTS}/new`;
export const SUBJECT_EDIT = `${BASE_SUBJECTS}/:id(\\d+)/edit`;
export const BASE_STAGES = '/stages';
export const STAGES_EDIT = `${BASE_STAGES}/:id(\\d+)/edit`;
export const STAGES_NEW = `${BASE_STAGES}/new`;

export default {
  BASE_TEACHING_MATERIALS,
  BASE_MATERIALS,
  BASE_LESSON_TOPIC,
  BASE_TRAININGS,
  TRAINING_NEW,
  BASE_SOURCE_OF_REFERENCE,
  SOURCE_OF_REFERENCE_FULL,
  SOURCE_OF_REFERENCE_EDIT,
  SOURCE_OF_REFERENCE_NEW,
  BASE_ASSESSMENT_STATUSES,
  ASSESSMENT_STATUSES_EDIT,
  ASSESSMENT_STATUSES_NEW,
  BASE_EXPERTS,
  BASE_CLASSROOMS,
  CLASSROOMS_EDIT,
  CLASSROOMS_NEW,
  CLASSROOMS_VIEW,
  BASE_SUBJECTS,
  SUBJECT_NEW,
  SUBJECT_EDIT,
  BASE_STAGES,
  STAGES_NEW,
  STAGES_EDIT
};
