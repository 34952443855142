import React from 'react';
import { Tag } from 'antd';
import { BASE_TEACHERS } from 'Admin/People/Teachers/routes';
import { TeacherTag } from 'types/Teachers/teacher';
import { Status } from 'types';
import { User } from 'types/user';
import { Teacher } from 'types/Teachers/teacher';
import { Subject } from 'types';
import AvatarWithUserName from 'Global/components/AvatarWithUserName';

export const getColumns = (user: User, strings) => [
  {
    title: strings.NAME,
    dataIndex: '',
    key: 'name',
    width: 200,
    render: (teacher: Teacher) => (
      <AvatarWithUserName
        name={teacher?.user?.name}
        surname={teacher?.user?.surname}
        link={`${BASE_TEACHERS}/${teacher?.id}`}
        photo={teacher?.mainPhoto}
      />
    )
  },
  {
    title: strings.STATUS,
    dataIndex: 'status',
    key: 'status',
    render: (status: Status) => status && <p style={{ whiteSpace: 'nowrap' }}>{status.name}</p>
  },
  {
    title: strings.BRANCH,
    dataIndex: 'user',
    key: 'branch',
    render: (user: User) => user?.branch && <p>{user.branch.name}</p>
  },
  {
    title: strings.TAGS,
    dataIndex: 'teacherTags',
    key: 'tags',
    render: (tags: TeacherTag[]) =>
      tags &&
      tags
        .map((tag: any) => tag.tag)
        .map((tag: TeacherTag) => (
          <Tag color="blue" key={tag.id} style={{ margin: '5px' }}>
            {tag.name}
          </Tag>
        ))
  },
  {
    title: strings.MAIN_SUBJECT,
    dataIndex: 'mainSubjects',
    key: 'mainSubjects',
    width: 140,
    render: (mainSubjects: Subject[]) => {
      return mainSubjects && mainSubjects.map(subject => <Tag style={{ margin: '5px' }}>{subject.name}</Tag>);
    }
  },
  {
    title: strings.CONTACTS,
    dataIndex: '',
    key: 'email',
    render: (teacher: Teacher) => {
      const {
        user: { phone, email }
      } = teacher;

      return (
        <>
          {email && <p>{email}</p>}
          {phone && <p>{phone}</p>}
        </>
      );
    }
  }
];

export default { getColumns };
