import { httpService } from 'api/core';

/**
 * @description получение урока
 * @param {number} lessonId урока
 * @return {Promise<any>}
 */
export const getLessonById = (lessonId: number): Promise<any> => httpService.get(`/api/slots/lesson/${lessonId}`);

/**
 * @description Редактирование урока
 * @param {number} id
 * @param {object} params
 * @return {Promise<any>}
 */
export const editLessonById = (id: number, params: any): Promise<any> =>
  httpService.put(`/api/slot/${id}/lesson`, params);

/**
 * @description Удаление урока по id
 * @param {number} lessonId
 * @return {Promise<any>}
 */
export const deleteLessonById = (lessonId: number): Promise<any> => httpService.delete(`/api/slot/${lessonId}/lesson`);

export default {
  getLessonById,
  editLessonById,
  deleteLessonById
};
