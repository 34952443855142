import React, { useMemo, useContext } from 'react';
import { httpService } from 'api/core';
import {
  CommentForOfficePostParams,
  CommentForParentsPostParams,
  LessonOutcomesParams,
  RegisterMetagoalsParams,
  RegisterOutcomesParams
} from 'types/TeacherAccount/Courses';
import { getFormData } from '../helpers';
import {ILessonPostRarams, ILessonRegisterFullPostRarams, ILessonRegisterPostRarams} from 'types/lesson';

const LessonRepositoryContext = React.createContext<ILessonRepository>(null);

interface ILessonRepository {
  editLessonById: (lessonId: number | string, params: ILessonPostRarams) => Promise<any>;
  updateLessonRegisters: (lessonId: number | string, params: ILessonRegisterFullPostRarams) => Promise<any>;
  deleteRegisterAudioComment: (registerId: number | string) => Promise<any>;
  uploadRegisterAudioComment: (registerId: number | string, params) => Promise<any>; //TODO описать params
  getLessonRegisters: (lessonId: number | string) => Promise<any>;
  getCommentForOfficeByRegisterId: (registerId: number) => Promise<any>;
  editCommentForOfficeByRegisterId: (registerId: number, params: CommentForOfficePostParams) => Promise<any>;
  getMetagoalsByRegisterId: (registerId: number) => Promise<any>;
  editMetagoalsByRegisterId: (registerId: number, params: RegisterMetagoalsParams) => Promise<any>;
  getCommentsByRegisterId: (registerId: number) => Promise<any>;
  editCommentsByRegisterId: (registerId: number, params: CommentForParentsPostParams) => Promise<any>;
  getLessonsInfoById: (lessonId: number | string) => Promise<any>;
  getOutcomesByRegister: (id: number | string) => Promise<any>;
  editOutcomesByRegisterId: (id: number, params: RegisterOutcomesParams) => Promise<any>;
  getOutcomesByLesson: (id: number | string) => Promise<any>;
  editOutcomesByLesson: (id: number | string, params: LessonOutcomesParams) => Promise<any>;
  getLessonDisciplines: (id: number | string) => Promise<any>;
}

class LessonRepository implements ILessonRepository {
  uploadRegisterAudioComment(registerId: number, params) {
    return httpService.post(`api/v1/lessonregister/${registerId}/audio-for-supervisor`, params, null, true, true);
  }

  deleteRegisterAudioComment(registerId: number) {
    return httpService.delete(`api/v1/lessonregister/${registerId}/audio-for-supervisor`, null, true, true);
  }
  /**
   * @description Обновляем регистры
   * @param {number} lessonId
   * @param {object} params
   * @return {Promise<any>}
   */
  updateLessonRegisters(lessonId: number, params: ILessonRegisterFullPostRarams) {
    return httpService.put(`/api/v1/lessons/${lessonId}/registers`, params, null, null, true, false);
  }

  getLessonRegisters(lessonId: number) {
    return httpService.get(`/api/v1/lessons/${lessonId}/registers`, null, null, true);
  }

  editLessonById(lessonId: number | string, params: ILessonPostRarams) {
    return httpService.put(`/api/v1/lessons/${lessonId}`, params, null, null, true, false);
  }

  getMetagoalsByRegisterId(registerId: number) {
    return httpService.get(`/api/v1/lessonregister/${registerId}/metagoals`, null, null, true);
  }

  editMetagoalsByRegisterId(registerId: number, params: RegisterMetagoalsParams) {
    return httpService.put(`/api/v1/lessonregister/${registerId}/metagoals`, params, null, null, true);
  }

  getCommentForOfficeByRegisterId(registerId: number) {
    return httpService.get(`/api/v1/lessonregister/${registerId}/comment-supervisor`, null, null, true);
  }

  editCommentForOfficeByRegisterId(registerId: number, params: CommentForOfficePostParams) {
    const formDataParams = getFormData(params);
    return httpService.post(
      `/api/v1/lessonregister/${registerId}/comment-supervisor`,
      formDataParams,
      null,
      null,
      true
    );
  }

  getCommentsByRegisterId(registerId: number) {
    return httpService.get(`/api/v1/lessonregister/${registerId}/comments`, null, null, true);
  }

  editCommentsByRegisterId(registerId: number, params: CommentForParentsPostParams) {
    return httpService.put(`/api/v1/lessonregister/${registerId}/comments`, params, null, null, true, false);
  }

  getLessonsInfoById(lessonId: number | string) {
    return httpService.get(`/api/v1/lessons/${lessonId}/info`, null, null, true);
  }

  getOutcomesByRegister(id: number | string) {
    return httpService.get(`/api/v1/lessonregister/${id}/outcomes`, null, null, true);
  }

  editOutcomesByRegisterId(id: number, params: RegisterOutcomesParams) {
    return httpService.put(`/api/v1/lessonregister/${id}/outcomes`, params, null, null, true);
  }

  getOutcomesByLesson(id: number | string) {
    return httpService.get(`/api/v1/lessons/${id}/outcomes`, null, null, true);
  }

  editOutcomesByLesson(id: number, params: LessonOutcomesParams) {
    return httpService.put(`/api/v1/lessons/${id}/outcomes`, params, null, null, true);
  }
  getLessonDisciplines(id: number | string) {
    return httpService.get(`/api/v1/lessons/${id}/disciplines`, null, null, true);
  }
}

export const LessonRepositoryProvider = (props: { children: React.ReactNode }) => {
  const service = useMemo(() => new LessonRepository(), []);

  return <LessonRepositoryContext.Provider value={service}>{props.children}</LessonRepositoryContext.Provider>;
};

export const useLessonRepository = () => {
  const service = useContext(LessonRepositoryContext);

  if (!service) {
    throw new Error('Lesson repository is unavailable');
  }

  return service;
};
