import React, { useMemo } from 'react';
import Cookie from 'js-cookie';

const StorageContext = React.createContext(null);

export class StorageService {
  getAccessToken() {
    return localStorage.getItem('token') || Cookie.get('token');
  }

  setAccessToken(token) {
    localStorage.setItem('token', token);
  }

  removeAccessToken() {
    Cookie.remove('token');
    localStorage.removeItem('token');
  }

  getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }

  setRefreshToken(refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
  }

  removeRefreshToken() {
    localStorage.removeItem('refreshToken');
  }
}

export const storageService = new StorageService();

export const StorageContextProvider = props => {
  const service = useMemo(() => new StorageService(), []);

  return <StorageContext.Provider value={service}>{props.children}</StorageContext.Provider>;
};

export const useStorageService = () => {
  const service = React.useContext(StorageContext);

  if (!service) {
    throw new Error('Storage context is unavailable');
  }

  return service;
};
