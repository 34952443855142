import { httpService } from 'api/core';
import { InvoicesLogsFilterParams, LessonsRegistersLogsFilterParams } from 'types/logs';

/**
 * @description Получение InvoicesLogs logs report
 * @param {InvocesLogsFilterParams} params
 * @return {Promise<any>}
 */
const getInvoicesLogsList = (params: InvoicesLogsFilterParams): Promise<any> =>
  httpService.get('/api/v1/log/invoices', params, null, true);

/**
 * @description Получение InvoicesLogs logs report
 * @param {LessonsRegistersLogsFilterParams} params
 * @return {Promise<any>}
 */
const getLessonsRegistersLogsList = (params: LessonsRegistersLogsFilterParams): Promise<any> =>
  httpService.get('/api/log/lessonregisters', params);

export const Logs = {
  getInvoicesLogsList,
  getLessonsRegistersLogsList
};

export default {
  Logs
};
