import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useLanguageContext } from 'contexts/LanguageContext';
import { UploadFile } from 'antd/lib/upload/interface';

interface ModalAttachmentsProps {
  hasShowModal: boolean;
  setHasShowModal: (val: boolean) => void;
  handleSendMessage: (val?: string) => Promise<any>;
  message: string;
  fileList: UploadFile[];
  setFileList: (val: UploadFile[]) => void;
  hasLoadingSentMessage: boolean;
}
export const ModalChatAttachments = ({
  hasShowModal,
  setHasShowModal,
  handleSendMessage,
  message,
  fileList,
  setFileList,
  hasLoadingSentMessage
}: ModalAttachmentsProps) => {
  const [strings] = useLanguageContext();
  const [text, setText] = useState<string>('');

  const onSubmit = async () => {
    handleSendMessage(text);
  };
  const handleTextChange = ({ target: { value } }) => {
    setText(value);
  };

  const onChangeUploaded = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const handleCancel = () => {
    setFileList([]);
    setHasShowModal(false);
  };

  useEffect(() => {
    setText(message);
  }, [message]);

  const modalFooter = [
    <Button onClick={handleCancel}>{strings.CANCEL}</Button>,
    <Button onClick={onSubmit} type="primary" loading={hasLoadingSentMessage}>
      {strings.SEND}
    </Button>
  ];

  return (
    <Modal visible={hasShowModal} title={strings.MESSAGE} footer={modalFooter} width={600} onCancel={handleCancel}>
      <Upload
        fileList={fileList}
        multiple={true}
        listType="picture"
        beforeUpload={() => false}
        onChange={onChangeUploaded}
      >
        <Button icon={<UploadOutlined />}>{strings.ADD_FILES}</Button>
      </Upload>
      <Input.TextArea
        style={{ resize: 'none', marginTop: 10 }}
        placeholder={strings.ENTER_MESSAGE}
        onChange={handleTextChange}
        value={text}
      />
    </Modal>
  );
};
