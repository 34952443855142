import React, { useEffect, useRef, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Spin, Skeleton } from 'antd';
import { INews } from 'types';
import Helmet from 'react-helmet';
import { useNewsRepository } from 'repos/NewsRepository';
import { NewsCard } from 'Global/components/NewsCard';
import { PaginationInfo } from 'types/global';
import { mockPagination } from 'Global/constants';
import { useUserContext } from 'contexts/UserContext';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { CREATE_NEWS } from 'Admin/News/routes';
import { Link } from 'react-router-dom';
import { IGetNewsParams } from '../../../../types/news';

const StyledNews = styled.div<{ isPage: boolean }>`
  margin: ${({ isPage }) => isPage && `30px`};
`;

const StyledNewsList = styled.div<{ columnsCount: number }>`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(${({ columnsCount }) => columnsCount}, 1fr);
  grid-auto-rows: 315px;
  grid-column-gap: 15px;
  grid-row-gap: 15px;

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`;

const StyledSpin = styled.div`
  margin: 15px 0;
  display: flex;
  justify-content: center;
`;

const StyledAddNewsItem = styled(Link)`
  border-radius: 5px;
  box-shadow: 0 0 46px -15px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  &:hover {
    opacity: 0.7;
    transition: all 0.3s ease;
  }
  svg {
    opacity: 0.7;
  }
  p {
    font-size: 18px;
    margin-top: 10px;
    color: #333;
  }
`;

const AddNewsItem = () => (
  <StyledAddNewsItem to={CREATE_NEWS}>
    <>
      <svg fill="#CC1E44" xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="0 0 24 24">
        <path d="m12 0a12 12 0 1 0 12 12 12.013 12.013 0 0 0 -12-12zm0 22a10 10 0 1 1 10-10 10.011 10.011 0 0 1 -10 10zm5-10a1 1 0 0 1 -1 1h-3v3a1 1 0 0 1 -2 0v-3h-3a1 1 0 0 1 0-2h3v-3a1 1 0 0 1 2 0v3h3a1 1 0 0 1 1 1z" />
      </svg>
      <p>Add News</p>
    </>
  </StyledAddNewsItem>
);

const LIMIT_NEWS = 23;
const LIMIT_NEWS_HOME_PAGE = 24;
const LIMIT_NEWS_FOR_PARENT = 4;

export const News = ({ isPage }: { isPage?: boolean }) => {
  const [user] = useUserContext();
  const targetRef = useRef(null);
  const newsRepository = useNewsRepository();
  const [newsList, setNewsList] = useState([]);
  const [hasLoadNewsList, setHasLoadNewsList] = useState<boolean>(false);

  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>(mockPagination);
  const [isFetchNextPage, setIsFetchNextPage] = useState<boolean>(false);
  const { current, last, next } = paginationInfo;

  const getLimitNews = () => {
    if (user?.hasRoles?.parent || user?.hasRoles?.student) {
      return LIMIT_NEWS_FOR_PARENT;
    }
    return isPage ? LIMIT_NEWS : LIMIT_NEWS_HOME_PAGE;
  };

  const fetchNews = async (page?: number, isFirstLoad?: boolean): Promise<any> => {
    try {
      if (isFirstLoad) {
        setHasLoadNewsList(true);
      }
      //isIndex - новости, отфильтрванные по юзеру, показываем на главной
      const params = { limit: getLimitNews(), page, isIndex: !isPage } as IGetNewsParams;
      const { data } = await newsRepository.getNews(params);

      if (page) {
        setNewsList([...newsList, ...data.items]);
      } else {
        setNewsList(data.items);
      }
      setPaginationInfo(data.paginationInfo);
      setHasLoadNewsList(false);
    } catch {}
  };

  const callBackRef = async entries => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      if (current !== last) {
        setIsFetchNextPage(true);
        try {
          await fetchNews(next, false);
        } finally {
          setIsFetchNextPage(false);
        }
      }
    }
  };

  const options = useMemo(() => {
    return {
      root: null,
      rootMargin: '0px',
      threshold: 0.5
    };
  }, []);

  useEffect(() => {
    if (newsList.length > 0 && next !== 0 && !hasLoadNewsList) {
      const observer = new IntersectionObserver(callBackRef, options);
      const currentTarget = targetRef.current;
      if (currentTarget) {
        observer.observe(currentTarget);
        return () => {
          observer.unobserve(currentTarget);
        };
      }
    }
  }, [targetRef.current, newsList, next, hasLoadNewsList]);

  useEffect(() => {
    if (user?.id) {
      fetchNews(1, true);
    }
  }, [user]);

  const COLUMNS_COUNT_TEACHER = 1;
  const COLUMNS_COUNT_PARENT = 4;
  const COLUMNS_COUNT_ADMIN = 4;

  const getColumnsCount = () => {
    if (user?.hasRoles?.teacher) {
      return COLUMNS_COUNT_TEACHER;
    } else if (user?.hasRoles?.parent || user?.hasRoles?.student) {
      return COLUMNS_COUNT_PARENT;
    }

    return COLUMNS_COUNT_ADMIN;
  };

  if (hasLoadNewsList) {
    return <Skeleton active={true} />;
  }

  return (
    <ErrorBoundary>
      <StyledNews isPage={isPage}>
        <Helmet>
          <title>AMI: News</title>
        </Helmet>
        <StyledNewsList columnsCount={getColumnsCount()}>
          {user?.hasRoles?.admin && isPage && <AddNewsItem />}
          {newsList.map((newsItem: INews, key: number) => {
            const { header, date, image } = newsItem;
            if (key === 0) {
              return (
                <div className="news-item" ref={targetRef}>
                  <NewsCard height={400} image={image} link={`/news/${newsItem.id}`} header={header} date={date} />
                </div>
              );
            }
            return (
              <div ref={targetRef}>
                <NewsCard image={image} link={`/news/${newsItem.id}`} header={header} date={date} />
              </div>
            );
          })}
        </StyledNewsList>
        <StyledSpin>{isFetchNextPage && <Spin />}</StyledSpin>
      </StyledNews>
    </ErrorBoundary>
  );
};

export default { News };
