/* eslint-disable */

/**
 * @description Плавно проскроллить в начало странцы
 * @returns {void}
 */
export const scrollTop = () => {
  // FIXME:
  const layout = document.getElementsByClassName('ant-layout-content');
  for (let i = 0; i < layout.length; i++) {
    layout[i].scrollIntoView();
  }
  try {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  } catch (error) {
    window.scrollTo(0, 0);
  }
};

/**
 * @description Плавный scrollTo c колбеком
 * @param {number} x
 * @param {number} y
 * @param {function} callback
 * @param {object} options
 * @returns {void}
 */
export const scrollTo = (x: number, y: number, callback?: () => any, options = { gap: 0 }): void => {
  const { gap } = options;

  if (y > 0 && Math.abs(window.scrollY + gap - y) > 1) {
    window.onscroll = e => {
      if (Math.abs(window.scrollY + gap - y) <= 1) {
        window.onscroll = null;

        if (callback) {
          callback();
        }
      }
    };

    window.scrollTo(x, y);
  } else if (callback) {
    callback();
  }
};
