import React from 'react';
import { Card, Col } from 'antd';
import { dateTimeWithoutSecondFormat } from 'helpers/dates';
import dayjs from 'dayjs';
import { Sms as ISms } from 'types/Family/collaborationHistory';

interface SmsProps {
  data: ISms;
}

/**
 * @description Chronicle type sms
 * @param props - component props
 * @return {React.ReactNode}
 */
export const Sms = (props: SmsProps) => {
  const {
    data: { title, text, date, phone }
  } = props;

  const formatDate = dayjs(date).format(dateTimeWithoutSecondFormat);
  return (
    <Col lg={24}>
      <Card title={title || 'Sms'} extra={formatDate}>
        <p>{text}</p>
        <p>
          <a href={`tel:+${phone}`}>{phone}</a>
        </p>
      </Card>
    </Col>
  );
};

export default { Sms };
