import React, { useEffect, useState } from 'react';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, message, Modal, Table, Tag, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { isEmpty } from 'lodash';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { useAdminRepository } from 'repos/AdminRepository';
import {
  IAdditionalAccountItem,
  AdditionalAccountsFilterParams,
  IAdditionalAccountUser
} from 'types/CRM/additionalAccounts';
import { PaginationInfo } from 'types/global';
import Pagination from 'Global/components/Pagination';
import { scrollTop } from 'helpers';
import { Filter } from './Filter';
import { ModalAdditionalAccountForm } from './ModalAdditionalAccountForm';
import { getTypeByRole } from 'helpers/roles';

export const AdditionalAccounts = () => {
  const [user] = useUserContext();
  const [strings] = useLanguageContext();
  const { goBack, push } = useHistory();
  const { locationSearchParams } = useLocationSearchParams();
  const adminRepository = useAdminRepository();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const [additionalAccounts, setAdditionalAccounts] = useState<IAdditionalAccountItem[]>([]);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>();
  const { pageCount, current } = paginationInfo || {};
  const [{ confirm }, contextHolder] = Modal.useModal();

  const fetchAdditionalAccounts = async (params?: AdditionalAccountsFilterParams): Promise<any> => {
    setHasLoading(true);
    try {
      const {
        data: { items, paginationInfo }
      } = await adminRepository.getAdditionalAccounts(params);
      setAdditionalAccounts(items);
      setPaginationInfo(paginationInfo);
      push({ search: queryString.stringify(params) });
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  const handlePage = async (page: number): Promise<any> => {
    scrollTop();
    fetchAdditionalAccounts({ ...locationSearchParams, page });
  };

  const handleDeleteLink = (id: number): void => {
    confirm({
      title: strings.ARE_YOU_SURE,
      okType: 'danger',
      async onOk() {
        setHasLoading(true);
        try {
          await adminRepository.deleteAdditionalAccounts(id);
          fetchAdditionalAccounts({ ...locationSearchParams, page: 1 });
        } catch (err) {
          message.error(err.response.data.message);
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  const getUserInfo = (user: IAdditionalAccountUser) => {
    const { name, surname, email, username, roles } = user;
    return (
      <p>
        <strong>
          {surname} {name}
        </strong>
        {roles?.isNotEmpty() && (
          <Tag color="blue" style={{ marginInlineStart: 8 }}>
            {getTypeByRole(roles)}
          </Tag>
        )}
        <br />
        <span>{email || username}</span>
      </p>
    );
  };

  const getColumns = [
    {
      title: strings.USER,
      dataIndex: 'user',
      key: 'user',
      render: (user: IAdditionalAccountUser) => getUserInfo(user)
    },
    {
      title: strings.ADDITIONAL_ACCOUNTS,
      dataIndex: 'additionalUser',
      key: 'additionalUser',
      render: (user: IAdditionalAccountUser) => getUserInfo(user)
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      className: 'clmn-center',
      render: (id: number) => {
        return (
          <Button
            style={{ margin: 0 }}
            icon={<DeleteOutlined />}
            danger
            onClick={() => handleDeleteLink(id)}
            size="small"
          />
        );
      }
    }
  ];

  useEffect(() => {
    if (isEmpty(locationSearchParams)) {
      fetchAdditionalAccounts();
    } else {
      fetchAdditionalAccounts(locationSearchParams);
    }
  }, [user]);

  return (
    <Container>
      {contextHolder}
      <ModalAdditionalAccountForm
        fetchAdditionalAccounts={fetchAdditionalAccounts}
        hasShowModal={hasShowModal}
        setHasShowModal={setHasShowModal}
      />
      <StPageHeader
        onBack={goBack}
        title={strings.ADDITIONAL_ACCOUNTS}
        extra={
          <Button type="primary" onClick={() => setHasShowModal(true)}>
            {strings.CREATE_NEW_ADDITIONAL_ACCOUNTS}
          </Button>
        }
      >
        <Filter onFilter={fetchAdditionalAccounts} />
      </StPageHeader>
      <Row gutter={[10, 10]}>
        <Table
          bordered
          dataSource={additionalAccounts}
          columns={getColumns}
          pagination={false}
          loading={{ spinning: hasLoading }}
        />
        <Pagination count={pageCount} current={current} pageChange={handlePage} />
      </Row>
    </Container>
  );
};
export default AdditionalAccounts;
