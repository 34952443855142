import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'antd';
import { Branch, NewsRole } from 'types';
import { useNewsRepository } from 'repos/NewsRepository';
import { useUserContext } from 'contexts/UserContext';
import { storageService } from 'contexts/StorageContext';
import { isEmpty } from 'lodash';
import { getImageUrl } from '../helpers/getImageUrl';
import { NewsImage } from '../Global/modules/Home/News/styled';

const NewsContext = React.createContext(null);

export const NewsContextProvider = props => {
  const [user] = useUserContext();
  const [hasOpenMessage, setHasOpenMessage] = useState<boolean>(false);
  const [importantNews, setImportantNews] = useState(null);
  const [newsList, setNewsList] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [hasLoadNews, setHasLoadNews] = useState<boolean>(false);
  const [hasEmpty, setHasEmpty] = useState<boolean>(false);
  const newsRepository = useNewsRepository();
  const provider = [newsList, setNewsList, pagination, setPagination, hasLoadNews, setHasLoadNews, hasEmpty];

  const hasBranch = (newsBranches: Branch[], userBranchId: number): boolean =>
    newsBranches.some((branch: Branch) => branch.id === userBranchId);

  const hasRoles = (newsRoles: NewsRole[], userRoles: string[]): boolean =>
    newsRoles?.some(newsRole => userRoles?.includes(newsRole.sysName));

  const handleClose = () => {
    setHasOpenMessage(false);
    newsRepository.setWatchNews(importantNews.id);
  };

  /**
   * @description Получение списка новостей
   * @return {Promise<any>}
   */
  const getNews = async (): Promise<any> => {
    setHasLoadNews(true);
    try {
      const {
        data: { items }
      } = await newsRepository.getNews();
      if (items) {
        if (!isEmpty(items)) setHasEmpty(false);
        setNewsList(items);
      }
    } catch {
    } finally {
      setHasLoadNews(false);
    }
  };
  const modal = (
    <Modal title={importantNews?.header} open={hasOpenMessage} onCancel={handleClose} footer={null}>
      <NewsImage>
        <img src={getImageUrl(importantNews?.image)} alt={importantNews?.header} />
      </NewsImage>
      <div dangerouslySetInnerHTML={{ __html: importantNews?.body }} />
    </Modal>
  );

  useEffect(() => {
    if (storageService.getAccessToken() && user?.id) {
      getNews();
    }
  }, [storageService.getAccessToken(), user]);

  useEffect(() => {
    if (newsList.isNotEmpty()) {
      const importantNews = newsList.find(
        item =>
          item.important &&
          !item.watched &&
          hasBranch(item.branch, user?.branch?.id) &&
          hasRoles(item.roles, user?.roles)
      );

      if (importantNews) {
        setImportantNews(importantNews);
        setHasOpenMessage(true);
      }
    }
  }, [newsList]);

  return (
    <NewsContext.Provider value={provider}>
      {modal}
      {props.children}
    </NewsContext.Provider>
  );
};

export const useNewsContext = () => {
  const service = useContext(NewsContext);

  if (!service) {
    throw new Error('News context is not available');
  }

  return service;
};
