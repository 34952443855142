import React, { useMemo, useContext } from 'react';
import { httpService } from 'api/core';
import { IStatusesGetParams, IStatusPostParams, IStudentStatusPostParams } from 'types/CRM/statuses';

const StatusesContext = React.createContext<IStatusesRepository>(null);

interface IStatusesRepository {
  getTeacherStatuses: (params: IStatusesGetParams) => Promise<any>;
  getFamilyStatuses: (params: IStatusesGetParams) => Promise<any>;
  getStudentStatuses: (params: IStatusesGetParams) => Promise<any>;
  getTeacherStatusById: (id: number | string) => Promise<any>;
  getFamilyStatusById: (id: number | string) => Promise<any>;
  getStudentStatusById: (id: number | string) => Promise<any>;
  addTeacherStatus: (params: IStatusPostParams) => Promise<any>;
  addFamilyStatus: (params: IStatusPostParams) => Promise<any>;
  addStudentStatus: (params: IStudentStatusPostParams) => Promise<any>;
  editTeacherStatus: (id: number | string, params: IStatusPostParams) => Promise<any>;
  editFamilyStatus: (id: number | string, params: IStatusPostParams) => Promise<any>;
  editStudentStatus: (id: number | string, params: IStudentStatusPostParams) => Promise<any>;
  deleteTeacherStatus: (id: number | string) => Promise<any>;
  deleteFamilyStatus: (id: number | string) => Promise<any>;
  deleteStudentStatus: (id: number | string) => Promise<any>;
}

class TemplatesRepository implements IStatusesRepository {
  getTeacherStatuses(params: IStatusesGetParams) {
    return httpService.get('/api/v1/statuses/teachers', params, null, true);
  }
  getFamilyStatuses(params: IStatusesGetParams) {
    return httpService.get('/api/v1/statuses/family', params, null, true);
  }
  getStudentStatuses(params: IStatusesGetParams) {
    return httpService.get('/api/v1/statuses/child', params, null, true);
  }
  getTeacherStatusById(id: number | string) {
    return httpService.get(`/api/v1/statuses/teachers/${id}`, null, null, true);
  }
  getFamilyStatusById(id: number | string) {
    return httpService.get(`/api/v1/statuses/family/${id}`, null, null, true);
  }
  getStudentStatusById(id: number | string) {
    return httpService.get(`/api/v1/statuses/child/${id}`, null, null, true);
  }
  addTeacherStatus(params: IStatusPostParams) {
    return httpService.post(`/api/v1/statuses/teachers`, params, null, null, true);
  }
  addFamilyStatus(params: IStatusPostParams) {
    return httpService.post(`/api/v1/statuses/family`, params, null, null, true);
  }
  addStudentStatus(params: IStudentStatusPostParams) {
    return httpService.post(`/api/v1/statuses/child`, params, null, null, true);
  }
  editTeacherStatus(id: number | string, params: IStatusPostParams) {
    return httpService.put(`/api/v1/statuses/teachers/${id}`, params, null, null, true);
  }
  editFamilyStatus(id: number | string, params: IStatusPostParams) {
    return httpService.put(`/api/v1/statuses/family/${id}`, params, null, null, true);
  }
  editStudentStatus(id: number | string, params: IStudentStatusPostParams) {
    return httpService.put(`/api/v1/statuses/child/${id}`, params, null, null, true);
  }
  deleteTeacherStatus(id: number | string) {
    return httpService.delete(`/api/v1/statuses/teachers/${id}`, null, null, true);
  }
  deleteFamilyStatus(id: number | string) {
    return httpService.delete(`/api/v1/statuses/family/${id}`, null, null, true);
  }
  deleteStudentStatus(id: number | string) {
    return httpService.delete(`/api/v1/statuses/student/${id}`, null, null, true);
  }
}

export const useStatusesRepository = () => {
  const service = useContext(StatusesContext);

  if (!service) {
    throw new Error('Statuses repository is unavailable');
  }

  return service;
};

export const StatusesRepositoryProvider = props => {
  const service = useMemo(() => new TemplatesRepository(), []);

  return <StatusesContext.Provider value={service}>{props.children}</StatusesContext.Provider>;
};
