import { httpService } from 'api/core';
import { ReportFinanceFamilyApi } from 'types/Family/index';

/**
 * @description Получение финансов семьи
 * @param {number} id
 * @param {object} params
 * @return {Promise<any>}
 */
export const getFamilyFinances = (id: number, params?: any): Promise<any> =>
  httpService.get(`/api/families/${id}/finance`, params);

/**
 * @description Редактирование финансов семьи
 * @param {number} id
 * @param {object} params
 * @return {Promise<any>}
 */
export const editFamilyFinanceById = (id: number, params: any): Promise<any> => {
  return httpService.put(`/api/finances/operation/${id}`, params);
};

/**
 * @description Редактирование финансов семьи
 * @param {number} id
 * @param {object} params
 * @return {Promise<any>}
 */

export const createFamilyFinance = (id: number, params: any): Promise<any> => {
  return httpService.post(`/api/finances/operation/families/${id}`, params);
};

/**
 * @description Удаление финансов семьи
 * @param {number} id
 * @return {Promise<any>}
 */
export const removeFamilyFinanceById = (id: number): Promise<any> => {
  return httpService.delete(`/api/finances/operation/${id}`);
};


/**
 * @description Получение финансов Москвы
 * @param {object} params
 * @return {Promise<any>}
 */
export const getFinanceMoscow = (params?: any): Promise<any> => httpService.get(`/api/invoices/my`, params);

/**
 * @description получение Balance for extra service
 * @return {Promise<any>}
 */
export const getExtraBalance = (): Promise<any> => httpService.get('/api/account/family/my');

/**
 * @description Report finance family
 * @param {ReportFinanceFamilyApi} params
 * @return {Promise<any>}
 */
export const getReportFinanceFamily = (params: ReportFinanceFamilyApi): Promise<any> =>
  httpService.get(`/api/report/finance/family`, params);

export default {
  getFamilyFinances,
  editFamilyFinanceById,
  removeFamilyFinanceById,
  createFamilyFinance,
  getFinanceMoscow,
  getExtraBalance,
  getReportFinanceFamily
};
