export const columns = [
  {
    title: 'Student',
    dataIndex: 'student',
    key: 'student'
  },
  {
    title: 'Attendance',
    dataIndex: 'attendance',
    key: 'attendance'
  },
  {
    title: 'Classroom participation grade',
    dataIndex: 'participation',
    key: 'participation'
  },
  {
    title: 'Feedback for the office',
    dataIndex: 'feedback',
    key: 'feedback'
  },
  {
    title: 'Feedback for the parents',
    dataIndex: 'feedbackForParents',
    key: 'feedbackForParents'
  },
  {
    title: 'Last feedback',
    dataIndex: 'lastFeedBack',
    key: 'lastFeedBack'
  }
];

export default { columns };
