import React from 'react';
import { Card, Col } from 'antd';
import { dateTimeWithoutSecondFormat } from 'helpers/dates';
import dayjs from 'dayjs';
import { List, ListItem } from 'Admin/People/Families/Family/CollaborationHistory/Types/styles';
import { Register as IRegister } from 'types/Family/collaborationHistory';

interface RegisterProps {
  data: IRegister;
}

/**
 * @description Chronicle type register
 * @param props - component props
 * @return {React.ReactNode}
 */
export const Register = (props: RegisterProps) => {
  const {
    data: { title, date, text, teacher, lesson }
  } = props;
  const formatDate = dayjs(date).format(dateTimeWithoutSecondFormat);

  return (
    <Col lg={24}>
      <Card title={title || 'Register'} extra={formatDate}>
        <p>{text}</p>
        {teacher && (
          <List>
            <ListItem>
              <span>Teacher:</span>
              <p>
                {teacher.name} {teacher.surname}
              </p>
            </ListItem>
            <ListItem>
              <span>Subject:</span>
              <p>{lesson?.course?.subject.name}</p>
            </ListItem>
          </List>
        )}
      </Card>
    </Col>
  );
};

export default { Register };
