import React, { useMemo, useState } from 'react';
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Input, Button, Col, Row, Tag, Empty, Spin, message as antMessage, Alert, Upload } from 'antd';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { Mail } from 'api';
import { IRecipient } from './index';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { isEmpty } from 'lodash';
import { useLanguageContext } from 'contexts/LanguageContext';
import { UploadFile } from 'antd/lib/upload/interface';
import { getAllFormData } from 'helpers';
import { useUserContext } from 'contexts/UserContext';
import MyJoditEditor from 'Global/components/MyJoditEditor';

interface EmailFormProps extends FormComponentProps {
  form: WrappedFormUtils;
  hasRecipientsLoading: boolean;
  recipientsList: IRecipient[];
  handleDeleteParticipant: (id: number) => void;
  clearRecipients: () => void;
  currentPeople: string;
}

/**
 * @description Форма отправки email
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const EmailFormTemplate = (props: EmailFormProps) => {
  const [strings] = useLanguageContext();
  const [testEmail, setTestEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadingFileList, setUploadingFileList] = useState<UploadFile[]>([]);
  const [user] = useUserContext();

  const {
    form: { getFieldDecorator, validateFieldsAndScroll, resetFields, setFieldsValue },
    recipientsList,
    hasRecipientsLoading,
    handleDeleteParticipant,
    clearRecipients,
    currentPeople
  } = props;

  const hasStudent = currentPeople === 'students';

  const uploadProps = {
    name: 'files',
    multiple: true,
    beforeUpload: () => false,
    onChange: ({ file, fileList }) => {
      setUploadingFileList(fileList.map(file => file.originFileObj));
    }
  };

  const sendMailDistribution = async (params: object, hasTest?: boolean): Promise<any> => {
    setLoading(true);
    try {
      await Mail.sendMailDistribution(getAllFormData(params));
      antMessage.success(strings.MAIL_SENT_SUCCESSFULLY);
      if (!hasTest) {
        resetFields();
        clearRecipients();
      }
    } catch (e) {
      antMessage.error(strings.MAIL_SENT_ERROR);
    } finally {
      setLoading(false);
    }
  };

  /**
   * @description Отправить письма выбранным юзерам
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values): Promise<any> => {
      if (isEmpty(errors)) {
        const { message, subject, from, name, emails, replyTo } = values;
        const params = {
          userType: currentPeople,
          ids: recipientsList.map(recipient => recipient.id),
          emails: emails ? emails.split('\n') : null,
          message,
          subject,
          from,
          name,
          replyTo
        };
        if (uploadingFileList.isNotEmpty()) {
          params['attachments'] = uploadingFileList;
        }
        sendMailDistribution(params);
      }
    });
  };

  /**
   * @description Тестовая отправка письма на email
   * @return {void}
   */
  const handleSendTestEmail = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values): Promise<any> => {
      if (isEmpty(errors)) {
        const { message, subject, from, name, replyTo } = values;
        const params = {
          emails: [testEmail],
          message,
          subject,
          from,
          name,
          replyTo
        };
        if (uploadingFileList.isNotEmpty()) {
          params['attachments'] = uploadingFileList;
        }
        sendMailDistribution(params, true);
      }
    });
  };

  /**
   * @description Отправить письма родителям выбранных студентов
   * @return {void}
   */
  const handleSendParentsToStudents = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    const parentIds = recipientsList
      .map(recipient => recipient.parents.map(parent => parent.id))
      .reduce((array, val) => array.concat(val), []);

    validateFieldsAndScroll(async (errors, values): Promise<any> => {
      if (isEmpty(errors)) {
        const { message, subject, from, name, emails, replyTo } = values;
        const params = {
          userType: 'parents',
          ids: parentIds,
          emails: emails ? emails.split('\n') : null,
          message,
          subject,
          from,
          name,
          replyTo
        };
        sendMailDistribution(params);
      }
    });
  };

  const placeholder = `${strings.PASTE_EMAIL}:
    email1@example.com
    email2@example.com
    email3@example.com
    etc`;

  const recipients = useMemo(() => {
    return (
      <MyFormItem label={strings.RECIPIENTS}>
        <Row
          style={{
            height: 170,
            overflow: 'auto',
            background: '#f5f5f5',
            borderRadius: 5
          }}
        >
          {recipientsList.isEmpty() ? (
            <Empty style={{ margin: 'auto' }} />
          ) : (
            recipientsList.map(recipient => {
              // TODO: Вынести в отдельный компонент
              if (recipient.name) {
                return (
                  <Tag key={recipient.id} color="blue" style={{ margin: '10px 5px', fontSize: 14 }}>
                    <CloseOutlined onClick={() => handleDeleteParticipant(recipient.id)} />
                    {recipient.name} {recipient.surname}
                  </Tag>
                );
              }
            })
          )}
        </Row>
      </MyFormItem>
    );
  }, [recipientsList]);

  return (
    <Spin spinning={loading}>
      <Form layout="vertical">
        <Row gutter={[10, 10]} style={{ margin: '20px 0' }}>
          <Col lg={12}>
            <Row gutter={[10, 10]}>
              <Col lg={12}>
                <MyFormItem label={strings.SUBJECT}>
                  {getFieldDecorator('subject', {
                    rules: [{ required: true, message: `${strings.SUBJECT} ${strings.IS_REQUIRED}` }]
                  })(<Input placeholder={strings.SUBJECT} />)}
                </MyFormItem>
              </Col>
              <Col lg={12}>
                <MyFormItem label={strings.REPLY_TO}>
                  {getFieldDecorator('replyTo', {
                    rules: [{ required: false }],
                    initialValue: user?.email
                  })(<Input placeholder={strings.REPLY_TO} />)}
                </MyFormItem>
              </Col>
              <Col lg={12}>
                <MyFormItem label={strings.SEND_FROM}>
                  {getFieldDecorator('from', {
                    rules: [{ required: true, message: `${strings.SEND_FROM} ${strings.IS_REQUIRED}` }]
                  })(<Input placeholder={strings.SEND_FROM} />)}
                </MyFormItem>
              </Col>
              <Col lg={12}>
                <MyFormItem label={strings.DISPLAY_NAME}>
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: `${strings.DISPLAY_NAME} ${strings.IS_REQUIRED}` }]
                  })(<Input placeholder={strings.DISPLAY_NAME} />)}
                </MyFormItem>
              </Col>
              <Col lg={24}>
                <MyFormItem label={strings.ENTER_MESSAGE}>
                  <Alert
                    style={{ fontSize: 14, marginBottom: 10 }}
                    type="info"
                    showIcon
                    message={
                      strings.HTML_CODE_SHOULD_BE_ADDED_EXCLUSIVELY_THROUGH_THE_BUTTON_SOURCE_CODE_OTHER_INPUT_OPTIONS_WILL_NOT_WORK_CORRECTLY
                    }
                  />
                  {getFieldDecorator('message', {
                    rules: [{ required: true, message: `${strings.ENTER_MESSAGE} ${strings.IS_REQUIRED}` }]
                  })(<MyJoditEditor key="msg-editor" onChange={value => setFieldsValue({ message: value })} />)}
                </MyFormItem>
              </Col>
              <Col lg={24}>
                <Upload {...uploadProps}>
                  <Button>
                    <UploadOutlined /> {strings.UPLOAD_FILES}
                  </Button>
                </Upload>
              </Col>
            </Row>
          </Col>
          <Col lg={12}>
            <Spin spinning={hasRecipientsLoading}>
              <Row gutter={[10, 10]}>
                <Col lg={24}>{recipients}</Col>
                <Col lg={24}>
                  <MyFormItem label={strings.EMAILS}>
                    {getFieldDecorator('emails', {
                      rules: [
                        {
                          required: recipientsList.isEmpty() ? true : false,
                          message: strings.EMAILS_OR_RECIPIENTS_IS_REQUIRED
                        }
                      ]
                    })(<Input.TextArea placeholder={placeholder} style={{ height: 320 }} />)}
                  </MyFormItem>
                </Col>
              </Row>
            </Spin>
          </Col>
        </Row>
        <Row
          gutter={[10, 10]}
          style={{
            paddingTop: 20,
            marginTop: 20,
            borderTop: '1px solid #dedede'
          }}
        >
          <Col xs={24} lg={14}>
            <Row align="middle" style={{ flexWrap: 'nowrap', gap: '10px' }}>
              <Button type="primary" htmlType="submit" size="large" onClick={handleSubmit}>
                {strings.SEND}
              </Button>
              {hasStudent && (
                <Button type="primary" htmlType="submit" size="large" onClick={handleSendParentsToStudents}>
                  {strings.SEND_PARENTS_TO_STUDENTS}
                </Button>
              )}
            </Row>
          </Col>
          <Col xs={24} lg={10}>
            <Row justify="end" style={{ flexWrap: 'nowrap', gap: '10px' }}>
              <MyFormItem>
                {getFieldDecorator('email', {
                  rules: [{ required: false, type: 'email', message: strings.EMAIL_IS_NOT_VALID }]
                })(<Input placeholder={strings.EMAIL} onChange={({ target: { value } }) => setTestEmail(value)} />)}
              </MyFormItem>
              <Button type="primary" htmlType="submit" size="large" onClick={handleSendTestEmail}>
                {strings.TEST_EMAIL}
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export const EmailForm = Form.create<EmailFormProps>({})(EmailFormTemplate);

export default { EmailForm };
