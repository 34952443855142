import React, { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import { Button, Modal, Tabs, message, Form, Flex } from 'antd';
import { Participant, SearchSlotsParams } from 'types/Schedule';
import { Schedule } from 'api/Schedule';
import { EventForm } from 'Global/modules/Schedule/SlotModals/Create/EventForm';
import { LessonForm } from 'Global/modules/Schedule/SlotModals/Create/LessonForm';
import { dateFormatForBackend } from 'helpers/dates';
import { Training } from 'types';
import { sendSms } from 'Global/modules/Schedule/SlotModals/SlotFields/helpers';
import { useUserContext } from 'contexts/UserContext';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { getDateForSchedule } from 'helpers/getDateForSchedule';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { useScheduleRepository } from 'repos/ScheduleRepostirory';
import TimeRoomSlotFields from '../SlotFields/TimeRoomSlotFields';

interface CreateModalProps {
  hideModal: () => void;
  show: boolean;
  dateStart: string;
  dateEnd: string;
  getEventsByDates: (params: Partial<SearchSlotsParams>) => Promise<any>;
  service: any;
}

const { TabPane } = Tabs;

/**
 * @description Модальное окно создания события
 * @param {object} props component props
 * @return {React.ReactNode}
 */
export const CreateSlot = React.memo(
  ({ hideModal, show, dateStart, dateEnd, getEventsByDates, service }: CreateModalProps) => {
    const [strings] = useLanguageContext();
    const [user] = useUserContext();
    const [form] = Form.useForm();
    const scheduleRepository = useScheduleRepository();
// Участники события
    const [eventParticipants, setEventParticipants] = useState<Participant[]>([]);
    const [trainingsList, setTrainingsList] = useState<Training[]>([]);
    const [activeTabKey, setActiveTabKey] = useState<string>('1');

    // Отправка смс сообщений
    const [smsText, setSmsText] = useState<string>('');
    const [hasSendToParent, setHasSendToParent] = useState<boolean>(false);
    const [hasShouldClearSms, setHasShouldClearSms] = useState<boolean>(false);
    const [hasLoadingSubmit, setHasLoadingSubmit] = useState<boolean>(false);

    const { locationSearchParams } = useLocationSearchParams();
    const { branch } = locationSearchParams;

    const userBranch = user?.branch?.id;

    const resetFormFields = useCallback(() => {
      form.resetFields();
      setEventParticipants([]);
    }, []);

    /**
     * @description Функция для сбора, форматирования данных и создания события
     * @return {void}
     */
    const handleCreate = async (values): Promise<any> => {
      const { day, startTime, endTime } = values;
      const newDateStart = getDateForSchedule(day, startTime);
      const newDateEnd = getDateForSchedule(day, endTime);
      delete values['day'];
      delete values['startTime'];
      delete values['endTime'];
      const params = { ...values, dateStart: newDateStart, dateEnd: newDateEnd };

      // Если присутствует - отформатируем
      if (values.dateSequence) {
        params['dateSequence'] = dayjs(values.dateSequence).format(dateFormatForBackend);
      }
      setHasLoadingSubmit(true);

      // Урок
      if (activeTabKey === '1') {
        try {
          const { status } = await scheduleRepository.createSlotLesson(params);
          if (status === 200) {
            message.success(strings.LESSON_CREATED_SUCCESSFULLY, 2);
            closeModal();
            // Запросим заново слоты
            await getEventsByDates(locationSearchParams);
          }
        } catch {
          message.error(strings.LESSON_CREATION_ERROR, 2);
        } finally {
          setHasLoadingSubmit(false);
        }

        // Событие
      } else if (activeTabKey === '2') {
        params['participants'] = service.getFormatParticipants(eventParticipants);
        params['trainings'] = trainingsList.map((training: Training) => training.id);

        await sendSms(eventParticipants, smsText, hasSendToParent);

        try {
          const { status } = await Schedule.createEvent(params);

          if (status === 200) {
            message.success(strings.EVENT_HAS_BEEN_CREATED_SUCCESSFULLY, 2);
            closeModal();
            // Запросим заново слоты
            await getEventsByDates(locationSearchParams);
          }
        } catch {
          message.error(strings.EVENT_CREATION_ERROR, 2);
        } finally {
          setHasLoadingSubmit(false);
        }
      }
    };

    const closeModal = useCallback(() => {
      resetFormFields();
      hideModal();
      setHasShouldClearSms(true);
    }, []);

    /**
     * @description Обработчик на переключение табов
     * @param {string} activeKey
     * @return {void}
     */
    const handleTabsChange = useCallback((activeKey: string): void => setActiveTabKey(activeKey), []);

    // Проверки нужны для рендера, чтобы не нужная форма не попала в submit
    const hasLessonTab = activeTabKey === '1';
    const hasEventTab = activeTabKey === '2';

    return (
      <Modal centered width={900} title={strings.CREATE} footer={null} open={show} destroyOnClose onCancel={closeModal}>
        <Form form={form} layout="vertical" onFinish={handleCreate}>
          <TimeRoomSlotFields
            form={form}
            dateStart={dateStart}
            dateEnd={dateEnd}
            branch={branch || userBranch}
          />
          <Tabs defaultActiveKey="1" activeKey={activeTabKey} onChange={handleTabsChange}>
            <TabPane tab="Lesson" key="1">
              {hasLessonTab && <LessonForm userBranch={branch || userBranch} />}
            </TabPane>
            <TabPane tab="Event" key="2">
              {hasEventTab && (
                <EventForm
                  userBranch={branch || userBranch}
                  setSmsText={setSmsText}
                  setHasSendToParent={setHasSendToParent}
                  setEventParticipants={setEventParticipants}
                  hasShouldClearSms={hasShouldClearSms}
                  setHasShouldClearSms={setHasShouldClearSms}
                  trainingsList={trainingsList}
                  setTrainingsList={setTrainingsList}
                  eventParticipants={eventParticipants}
                />
              )}
            </TabPane>
          </Tabs>
          <Flex align="center" justify="right" gap={5}>
            <Button key="backBtn" onClick={closeModal}>
              {strings.CANCEL}
            </Button>
            <Button key="createBtn" type="primary" htmlType="submit" loading={hasLoadingSubmit}>
              {strings.CREATE}
            </Button>
          </Flex>
        </Form>
      </Modal>
    );
  }
);

export default { CreateSlot };
