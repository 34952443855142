import { ModalMode } from 'types';
import { PaginationInfo } from 'types/global';

export const PROGRESS_REPORT_TIPS =
  'https://app.weeek.net/s/project-document-NjQ2MDA5fDlkNjBhYjliLWMzODQtNGZhMy05MWUwLWQ3MGY3YmIwYTNiMQ==';
export const QUICK_TIPS_NEW_ACCOUNT =
  'https://app.weeek.net/s/project-document-NjQ2MDA5fDlkNTAzZWI4LTM2ZmMtNGZiMC1hOTBiLTNmOGJmMTFhMjlkOQ==';
export const QUICK_TIPS_META_GOALS =
  'https://docs.google.com/spreadsheets/d/1jvd_cb-y6fLhzq46LQRaOhtTxzIsTX9Tg7SGUVQWmYk/edit#gid=2015502672';
export const QUICK_TIPS_PRIMARY_LESSON_ASSESSMENT =
  'https://app.weeek.net/s/project-document-NjQ2MDA5fDlkNjBlMjUzLTAxZTctNDVmZC05NTMwLTQ2MmJhNmU5YmJjZA==';
export const QUICK_TIPS_FAMILY_INVOICE =
  'https://app.weeek.net/s/project-document-NjQ2MDA5fDlkNTAzYjQxLTIxYWYtNDFhMC05YzliLTdjYjZkZGVkMDU3YQ';
export const QUICK_TIPS_EXPERT_ASSESSMENT_LESSON =
  'https://app.weeek.net/s/project-document-NjQ2MDA5fDlkNjBlM2MzLTlhNDItNDVjZi1iZTVjLTkxMTIzMDZmNDhkNg==';
export const QUICK_TIPS_EXPERT_ASSESSMENT_CLASSROOM_MATERIALS_AND_HW =
  'https://app.weeek.net/s/project-document-NjQ2MDA5fDlkNjBlNDNjLTQ2ZjYtNDdhNi05NTRiLTY0OTQxY2Y0NmU4MA==';
export const QUICK_TIPS_WEEKLY_EMAIL =
  'https://app.weeek.net/s/project-document-NjQ2MDA5fDlkNTA0MjE5LTZiN2UtNDJkZi04ODAzLTk4OTA1M2UyMTI1OQ==';

export const ENTRANCE_TESTS_RIGA = 'https://drive.google.com/drive/u/2/folders/1jTET3MdLa1VG-oHR0-jvuPSLmTjJ2yqo';
export const ENTRANCE_TESTS = 'https://drive.google.com/drive/u/0/folders/1jTET3MdLa1VG-oHR0-jvuPSLmTjJ2yqo';
export const METAGOALS_FOR_TEACHERS =
  'https://docs.google.com/spreadsheets/d/1jvd_cb-y6fLhzq46LQRaOhtTxzIsTX9Tg7SGUVQWmYk/edit?pli=1#gid=2015502672';

// Время и дата
export const DATE_FORMAT_STRING_MONTH = 'MMM D, YYYY';
export const TIME_FORMAT = 'HH:mm';

// Лимиты
export const FAMILIES_LIMIT = 30;
export const COURSE_GROUPS_LIMIT = 30;
export const MANUAL_INPUT_LIMIT = 40;
export const LESSONS_LIMIT = 30;
export const PROGRESS_REPORTS_LIMIT = 50;

export const FEMALE = 1; // Женский
export const MALE = 0; // Мужской

export const defLang = 'en';

export const editorModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, false] }, 'bold', 'italic', 'underline', { align: [] }],
    [{ color: [] }, { background: [] }, { list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image', 'code-block', 'clean']
  ]
};


export const emptyChildrenObj = {
  id: 0,
  name: null,
  surname: null,
  patronymic: null,
  sex: '0',
  birthDate: null,
  email: null,
  additionalEmail: null,
  mobile: null,
  mobileSecond: null,
  skype: null,
  purpose: null,
  comment: null,
  special: null,
  hobby: null,
  status: null,
  school: null,
  grade: null,
  englishLevel: null,
  languageExperience: null,
  studyProgram: null,
  tutor: null,
  photo: null
};

export const emptyPayerObj = {
  id: 0,
  name: null,
  surname: null,
  patronymic: null,
  email: null,
  birthDate: null,
  address: null,
  passportParams: {
    passportSeries: null,
    passportIssuedBy: null,
    passportDate: null
  },
  inn: null,
  profession: null,
  comment: null,
  isPayer: false,
  subscribed: false,
  phone: null
};

export const paymentTypes = [
  {
    name: 'A',
    value: 'A'
  },
  {
    name: 'B',
    value: 'B'
  },
  {
    name: 'C1 — корректируем текущий месяц',
    value: 'C1'
  },
  {
    name: 'C2 — корректируем предыдущие месяцы',
    value: 'C2'
  },
  {
    name: 'D — взаимозачеты с филилалами',
    value: 'D'
  }
];

export const manualInputPaymentTypes = [
  {
    name: 'A',
    value: 'A'
  },
  {
    name: 'B',
    value: 'B'
  },
  {
    name: 'C',
    value: 'C'
  },
  {
    name: 'D',
    value: 'D'
  }
];

export const operationPaymentTypes = [
  { value: -1, name: 'Outgoing payment' },
  { value: 1, name: 'Incoming payment' },
  { value: 2, name: 'Cancel' }
];

export const invoicesLogsEvents = [
  { value: 0, name: 'All' },
  { value: 1, name: 'Create' },
  { value: 2, name: 'Mailing' },
  { value: 3, name: 'Delete' },
  { value: 4, name: 'Comment' },
  { value: 5, name: 'Status' }
];

export const lessonsRegisterLogsEvents = [
  { value: 'view', name: 'view' },
  { value: 'request', name: 'request' },
  { value: 'accessed', name: 'accessed' },
  { value: 'updated', name: 'updated' }
];

export const englishLevels = [
  { value: 1, name: '0' },
  { value: 2, name: '0-1' },
  { value: 3, name: '1-2' },
  { value: 4, name: '2-3' },
  { value: 5, name: '3-5' },
  { value: 6, name: '>5' }
];

export const ACTIVE_CLIENT_FAMILY_STATUS = 18;
export const RIGA_LOCATION = 'Riga';
export const ALL_TIME_ENTRIES_DATE = '2000-01-01';

export const ModeActionMap = {
  [ModalMode.Delete]: 'delet',
  [ModalMode.Edit]: 'updat',
  [ModalMode.Add]: 'add'
};

export const contractStatuses = {
  pending: 1,
  active: 2,
  terminated: 3,
  suspended: 4
};

export const mockPagination: PaginationInfo = {
  last: 0,
  current: 1,
  numItemsPerPage: 0,
  first: 0,
  pageCount: 0,
  totalCount: 0,
  pageRange: 0,
  startPage: 0,
  endPage: 0,
  next: 0,
  previous: 0,
  pagesInRange: [0],
  firstPageInRange: 0,
  lastPageInRange: 0,
  currentItemCount: 0,
  firstItemNumber: 0,
  lastItemNumber: 0
};

export enum AccessLevelsValue {
  e = 'e',
  v = 'v'
}
export const accessLevels = [
  { value: AccessLevelsValue.e, name: 'Can view and edit' },
  { value: AccessLevelsValue.v, name: 'View only' }
];

export enum invoiceStatus {
  pending = 1,
  paid = 2,
  cancelled = 3,
  returned = 4,
  draft = 5
}
export const invoiceStatuses = [
  { name: 'Pending', id: invoiceStatus.pending },
  { name: 'Paid', id: invoiceStatus.paid },
  { name: 'Cancelled', id: invoiceStatus.cancelled },
  { name: 'Returned', id: invoiceStatus.returned },
  { name: 'Draft', id: invoiceStatus.draft }
];

export const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const homeworkStatuses = [
  { name: 'assigned', title: 'Assigned', color: '#108ee9', step: 0, titleByLang: { ru: 'Задано', en: 'Assigned' } },
  { name: 'submitted', title: 'Submitted', color: '#eed063', step: 1, titleByLang: { ru: 'Сдано', en: 'Submitted' } },
  { name: 'checked', title: 'Checked', color: '#87d068', step: 2, titleByLang: { ru: 'Проверено', en: 'Checked' } }
];
export const contractTemplates = [
  { value: 0, name: 'Default' },
  { value: 1, name: 'Individual' },
  { value: 2, name: 'Licensee' },
  { value: 3, name: 'Individual licensed' },
  { value: 4, name: 'Online' }
];

export const contractTypes = [
  { value: 0, name: 'General' },
  { value: 1, name: 'GSCE' }
];

export const discountReasonList = [
  { value: 1, name: 'Personal' },
  { value: 2, name: 'Employee' },
  { value: 3, name: 'Not a discount (technical)' },
  { value: 4, name: 'Compensation' },
  { value: 6, name: 'Referral program' },
  { value: 5, name: 'Other' }
];
//коммент становится обязательным, если в поле discountReason выбрано:
// 'Not a discount' или 'Other' или 'Compensation'
export const discountReasonListForComment = [3, 4, 5];

export default {
  emptyChildrenObj,
  emptyPayerObj,
  FAMILIES_LIMIT,
  COURSE_GROUPS_LIMIT,
  MANUAL_INPUT_LIMIT,
  LESSONS_LIMIT,
  paymentTypes,
  manualInputPaymentTypes,
  operationPaymentTypes,
  ACTIVE_CLIENT_FAMILY_STATUS,
  RIGA_LOCATION,
  ModeActionMap,
  ALL_TIME_ENTRIES_DATE,
  mockPagination,
  contractStatuses,
  DATE_FORMAT_STRING_MONTH,
  TIME_FORMAT,
  PROGRESS_REPORTS_LIMIT,
  accessLevels,
  editorModules,
  invoiceStatuses,
  PROGRESS_REPORT_TIPS,
  homeworkStatuses
};
