import React from 'react';
import { Avatar, Flex, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { getAvatarUrl } from 'helpers';
import { useThemeContext } from 'contexts/ThemeContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { IW_KEY } from 'Admin/Translations';
const { Text } = Typography;

interface IProps {
  link?: string;
  name: string;
  surname: string;
  photo: string;
  size?: number;
  nameHebrew?: string;
  surnameHebrew?: string;
  extraInfo?: string;
}

/**
 * @description Avatar With UserName
 * @return {React.React.Node}
 */
export const AvatarWithUserName = ({
  link,
  name,
  surname,
  photo,
  size = 42,
  nameHebrew,
  surnameHebrew,
  extraInfo
}: IProps) => {
  const { activeTheme } = useThemeContext();
  const [, lang] = useLanguageContext();
  const userInfo = (
    <>
      <Avatar
        src={photo ? getAvatarUrl(photo) : null}
        style={{ minWidth: size, opacity: 0.8, backgroundColor: activeTheme?.lightPrimaryColor }}
        size={size}
      >
        {name?.charAt(0)}
        {surname?.charAt(0)}
      </Avatar>
      <Flex vertical style={{ width: `calc(100% - ${size + 10}px)` }}>
        <span>
          {lang === IW_KEY && (nameHebrew || surnameHebrew) ? `${nameHebrew} ${surnameHebrew}` : `${name} ${surname}`}
        </span>
        {extraInfo && (
          <Text type="secondary" ellipsis style={{ fontSize: 'small' }}>
            {extraInfo}
          </Text>
        )}
      </Flex>
    </>
  );
  if (link) {
    return (
      <Link to={link} style={{ display: 'flex', gap: 10, alignItems: 'center', flexWrap: 'nowrap' }}>
        {userInfo}
      </Link>
    );
  } else {
    return (
      <Row align="middle" style={{ gap: 10, flexWrap: 'nowrap' }}>
        {userInfo}
      </Row>
    );
  }
};

export const AvatarWithUserNameMemo = React.memo(AvatarWithUserName);
export default AvatarWithUserName;
