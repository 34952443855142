import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, message, Button, DatePicker, InputNumber, Select, Input, Col, Row } from 'antd';
import { isEmpty } from 'lodash';
import { Branch, ISmallChild, IStudent, ModalMode, Product } from 'types';
import { FamilyFinanceItem, IAccountState } from 'types/Family';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { ModeActionMap } from 'Global/constants';
import { Families } from 'api/Families';
import { hasInvoiceMonthFinanceSystem } from 'helpers';
import { paymentTypes } from 'Global/constants';
import { Dayjs } from 'dayjs';
import { dateFormatForBackend } from 'helpers/dates';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Option } = Select;

export interface IFinance {
  op_date: string;
  amount: number;
  performer: any;
  performer_operation_type: number;
  product: Product;
  code: any;
  child: ISmallChild;
  comment: string;
  paymentType: any;
}

interface AddFinanceProps extends FormComponentProps {
  id: number;
  currentFinance?: FamilyFinanceItem;
  students: IStudent[];
  hideModal: () => void;
  values?: IFinance;
  mode: ModalMode;
  products: Product[];
  accountStates: IAccountState[];
  branch: Branch;
  fetchFinanceById: () => void;
  fetchFinancePreviousMonth: (start?: Dayjs, end?: Dayjs) => void;
  valuesByPreviousFinance: any;
}

/**
 * @description - Компонент модального окна добавления транзакции
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const FinanceModalTemplate = (props: AddFinanceProps) => {
  const {
    form: { getFieldDecorator, validateFields, setFieldsValue },
    mode,
    id,
    currentFinance,
    students,
    hideModal,
    values,
    products,
    accountStates,
    branch,
    fetchFinanceById,
    fetchFinancePreviousMonth,
    valuesByPreviousFinance
  } = props;

  const [isLoadActionWithFinance, setIsLoadActionWithFinance] = useState<boolean>(false);
  const [strings] = useLanguageContext();
  const reloadFinances = valuesByPreviousFinance ? fetchFinancePreviousMonth : fetchFinanceById;

  const closeModal = () => {
    reloadFinances();
    setTimeout(() => {
      message.success(
        mode === ModalMode.AddCompensation
          ? strings.FINANCIAL_OPERATION_HAS_ADDED_SUCCESSFULLY
          : `${strings.FINANCIAL_OPERATION_HAS} ${ModeActionMap[mode]}ed ${strings.SUCCESSFULLY}`,
        2
      );
    }, 2000);
    setIsLoadActionWithFinance(false);
    hideModal();
  };

  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFields(async (errors, values) => {
      if (isEmpty(errors)) {
        setIsLoadActionWithFinance(true);
        values.op_date = values.op_date.format(dateFormatForBackend);
        if (mode === ModalMode.Add || mode === ModalMode.AddCompensation) {
          await Families.createFamilyFinance(id, values);
        } else if (mode === ModalMode.Edit) {
          await Families.editFamilyFinanceById(currentFinance?.id, values);
        }
        closeModal();
      }
    });
  };

  const handleDelete = async () => {
    setIsLoadActionWithFinance(true);
    await Families.removeFamilyFinanceById(currentFinance?.id);
    closeModal();
  };

  const getFooter = () => {
    const buttons = [
      <Button key="backBtn" onClick={hideModal}>
        {strings.CANCEL}
      </Button>
    ];

    if (mode === ModalMode.Add || mode === ModalMode.AddCompensation) {
      buttons.unshift(
        <Button type="primary" key="create" loading={isLoadActionWithFinance} htmlType="submit" onClick={handleSubmit}>
          {strings.CREATE}
        </Button>
      );
    } else if (mode === ModalMode.Edit) {
      buttons.unshift(
        <Button type="primary" key="update" loading={isLoadActionWithFinance} htmlType="submit" onClick={handleSubmit}>
          {strings.UPDATE}
        </Button>
      );
    } else if (mode === ModalMode.Delete) {
      buttons.unshift(
        <Button type="primary" key="delete" loading={isLoadActionWithFinance} htmlType="submit" onClick={handleDelete}>
          {strings.DELETE}
        </Button>
      );
    }
    return buttons;
  };

  const defaultValues =
    mode === ModalMode.Edit && values
      ? {
          date: dayjs(values.op_date),
          amount: values.amount,
          product: values.product ? values.product.id : null,
          performer: values.performer ? values.performer.id : null,
          child: values.child ? values.child.id : null,
          comment: values.comment,
          code: values.code,
          performer_operation_type: values.performer_operation_type
        }
      : {
          date: dayjs(),
          amount: 0,
          product: 10,
          performer: undefined,
          child: undefined,
          comment: '',
          code: undefined
        };

  useEffect(() => {
    if (mode === ModalMode.AddCompensation) {
      const { amount } = values;
      setFieldsValue({
        amount: amount * -1,
        code: 'C1',
        comment: 'Compensation'
      });
    }
  }, [currentFinance, mode]);

  // Связана ли финансовая операция с уроком или ивентом
  const isFinanceScheduleRelated = currentFinance?.type === 2;

  const form = (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Row gutter={24}>
        <Col span={12}>
          <MyFormItem label={strings.DATE}>
            {getFieldDecorator('op_date', {
              rules: [{ required: true, message: strings.DATE_IS_REQUIRED_FIELD }],
              initialValue: defaultValues.date
            })(<DatePicker placeholder={strings.DATE} />)}
          </MyFormItem>
        </Col>
        <Col span={12}>
          <MyFormItem label={strings.AMOUNT}>
            {getFieldDecorator('amount', {
              rules: [{ required: true, message: strings.AMOUNT_IS_REQUIRED_FIELD }],
              initialValue: defaultValues.amount
            })(<InputNumber placeholder={strings.AMOUNT} />)}
          </MyFormItem>
        </Col>
        <Col span={12}>
          <MyFormItem label={strings.PRODUCT}>
            {getFieldDecorator('product', {
              rules: [{ required: !isFinanceScheduleRelated, message: strings.PRODUCT_IS_REQUIRED_FIELD }],
              initialValue: defaultValues.product
            })(
              <Select placeholder={strings.PRODUCT} disabled={isFinanceScheduleRelated}>
                {products.map((product: Product) => (
                  <Option value={product.id} key={product.id}>
                    {product.name}
                  </Option>
                ))}
              </Select>
            )}
          </MyFormItem>
        </Col>
        <Col span={12}>
          <MyFormItem label={strings.PERFORMER}>
            {getFieldDecorator('performer', {
              rules: [{ required: !(!hasInvoiceMonthFinanceSystem(branch) || isFinanceScheduleRelated) }],
              initialValue: defaultValues.performer
            })(
              <Select
                placeholder={strings.PERFORMER}
                disabled={!hasInvoiceMonthFinanceSystem(branch) || isFinanceScheduleRelated}
              >
                {accountStates &&
                  accountStates.map((state: any) => {
                    const { performer } = state;
                    return (
                      <Option value={performer.id} key={performer.id}>
                        {performer.name}
                      </Option>
                    );
                  })}
              </Select>
            )}
          </MyFormItem>
        </Col>
        <Col span={12}>
          <MyFormItem label={strings.PAYMENT_TYPE}>
            {getFieldDecorator('code', {
              rules: [{ required: !isFinanceScheduleRelated, message: strings.PAYMENT_TYPE_IS_REQUIRED_FIELD }],
              initialValue: defaultValues.code
            })(
              <Select placeholder={strings.PAYMENT_TYPE} disabled={isFinanceScheduleRelated}>
                {paymentTypes.map(type => (
                  <Option key={type.value} value={type.value}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            )}
          </MyFormItem>
        </Col>
        <Col span={12}>
          <MyFormItem label={strings.CHILD}>
            {getFieldDecorator('child', {
              rules: [{ required: false }],
              initialValue: defaultValues.child
            })(
              <Select placeholder={strings.CHILD}>
                {students?.map((child: IStudent) => (
                  <Option value={child.id} key={child.id}>
                    {child.user.surname} {child.user.name}
                  </Option>
                ))}
              </Select>
            )}
          </MyFormItem>
        </Col>
        <Col span={12}>
          <MyFormItem label={strings.OPERATION_TYPE}>
            {getFieldDecorator('performer_operation_type', {
              rules: [{ required: false }],
              initialValue: defaultValues.performer_operation_type
            })(
              <Select placeholder={strings.OPERATION_TYPE} disabled={isFinanceScheduleRelated}>
                <Option value="1" key="balance">
                  {strings.BALANCE}
                </Option>
                <Option value="2" key="deposit">
                  {strings.DEPOSIT}
                </Option>
              </Select>
            )}
          </MyFormItem>
        </Col>
        <Col span={24}>
          <MyFormItem label={strings.COMMENT}>
            {getFieldDecorator('comment', {
              rules: [{ required: false }],
              initialValue: defaultValues.comment
            })(<Input.TextArea placeholder={strings.COMMENT} />)}
          </MyFormItem>
        </Col>
      </Row>
    </Form>
  );

  return (
    (<Modal
      title={strings.CREATE_NEW_TRANSACTION}
      footer={getFooter()}
      open={mode !== ModalMode.Hidden}
      onCancel={hideModal}
      style={{ top: 20 }}
      width={620}
    >
      {mode === ModalMode.Add || mode === ModalMode.AddCompensation || mode === ModalMode.Edit
        ? form
        : strings.ARE_YOU_SURE_WANT_TO_DELETE_TRANSACTION}
    </Modal>)
  );
};

export const FinanceModal = Form.create<AddFinanceProps>({})(FinanceModalTemplate);

export default { FinanceModal };
