import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, Select, message, Col, TimePicker, Spin, Row, DatePicker } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { useLanguageContext } from 'contexts/LanguageContext';
import { ClassroomReserve } from 'types/education';
import dayjs, { Dayjs } from 'dayjs';
import { weekdays } from 'Global/constants';
import { useClassroomsRepository } from 'repos/ClassroomsRepository';
import { match } from 'react-router';
import { useRouteMatch } from 'react-router-dom';

const { Option } = Select;

interface ModalReservedFormProps extends FormComponentProps {
  hasShowModal: boolean;
  setHasShowModal: (hasShow: boolean) => void;
  reservedItem: ClassroomReserve | null;
  fetchClassroom: () => Promise<any>;
}

/**
 * @description Добавление/редактирование reserve для classroom
 * @param {ModalReservedFormProps} props
 * @return {React.ReactNode}
 */
const ModalReservedFormTemplate = (props: ModalReservedFormProps) => {
  const {
    params: { id: roomId }
  }: match<{ id: string }> = useRouteMatch();
  const [strings] = useLanguageContext();
  const classroomsRepository = useClassroomsRepository();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const {
    form: { getFieldDecorator, validateFields, getFieldValue, resetFields },
    hasShowModal,
    setHasShowModal,
    reservedItem,
    fetchClassroom
  } = props;

  const { id, end, start, weekday } = reservedItem || {};

  const getTime = (strTime: string): Dayjs => {
    const hour = strTime.split(':')[0];
    const minutes = strTime.split(':')[1];
    return dayjs().set('hour', Number(hour)).set('minute', Number(minutes));
  };

  const getStrTime = (time: Dayjs): string => {
    const hour = time.hour();
    const minutes = time.minute();
    return `${hour}:${minutes < 10 ? '0' + minutes : minutes}`;
  };

  /**
   * @description Отправка формы
   * @param {React.FormEvent} e
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (!err) {
        setHasLoading(true);
        const { startTime, endTime, weekday } = values;
        const params: ClassroomReserve = { weekday, start: getStrTime(startTime), end: getStrTime(endTime) };
        try {
          if (id) {
            await classroomsRepository.editClassroomReserve(id, params);
            message.success(strings.SUCCESSFULLY_UPDATED, 2);
          } else {
            await classroomsRepository.createClassroomReserve(roomId, params);
            message.success(strings.SUCCESSFULLY_CREATED, 2);
          }
          await fetchClassroom();
          resetFields();
          setHasShowModal(false);
        } catch {
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  const closeModal = () => {
    setHasShowModal(false);
  };

  const validationEndTime = (rule, value, callback) => {
    if (value && value.isBefore(getFieldValue('dateStart'))) {
      callback(strings.THE_END_TIME_SHOULD_NOT_BE_BEFORE_THE_START_TIME);
    } else {
      callback();
    }
  };

  return (
    <Modal
      title={id ? strings.EDIT_RESERVE : strings.NEW_RESERVE}
      open={hasShowModal}
      onCancel={closeModal}
      okText={id ? strings.EDIT : strings.CREATE}
      onOk={handleSubmit}
      destroyOnClose={true}
    >
      <Spin spinning={hasLoading}>
        <Form layout="vertical">
          <Row gutter={[6, 6]}>
            <Col lg={12}>
              <MyFormItem label={strings.WEEKDAY}>
                {getFieldDecorator('weekday', {
                  initialValue: weekday,
                  rules: [{ required: true, message: `${strings.WEEKDAY} ${strings.IS_REQUIRED}` }]
                })(
                  <Select>
                    {weekdays.map((item, index) => (
                      <Option value={index} key={index}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                )}
              </MyFormItem>
            </Col>
            <Col lg={6}>
              <MyFormItem label={strings.START_TIME}>
                {getFieldDecorator('startTime', {
                  initialValue: start && getTime(start),
                  rules: [{ required: true, message: `${strings.START_TIME} ${strings.IS_REQUIRED}` }]
                })(<TimePicker format={'HH:mm'} allowClear={false} needConfirm={false} />)}
              </MyFormItem>
            </Col>
            <Col lg={6}>
              <MyFormItem label={strings.END_TIME}>
                {getFieldDecorator('endTime', {
                  initialValue: end && getTime(end),
                  rules: [
                    { required: true, message: `${strings.END_TIME} ${strings.IS_REQUIRED}` },
                    { validator: validationEndTime }
                  ]
                })(<TimePicker format={'HH:mm'} allowClear={false} needConfirm={false} />)}
              </MyFormItem>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export const ModalReservedForm = Form.create<ModalReservedFormProps>({})(ModalReservedFormTemplate);

export default { ModalReservedForm };
