import React, { useEffect, useState } from 'react';
import { EyeOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { AttachmentItem } from 'types/global';
import { downloadSomeFile } from 'helpers';
import { PreviewFileModal } from 'Global/components/FilePreview';
import { getPreviewUrl } from 'Global/components/FilePreview/helpers';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useAttachmentRepository } from 'repos/AttachmentRepository';

interface AttachmentsListProps {
  lessonId: number;
  type: string;
}

export const initialAttachment = {
  id: null,
  name: ''
};

export type AttachmentType = {
  id: number;
  name: string;
};

export const AttachmentsList = (props: AttachmentsListProps) => {
  const [strings] = useLanguageContext();
  const { lessonId, type } = props;
  const [attachments, setAttachments] = useState<AttachmentItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasShowPreviewModal, setHasShowPreviewModal] = useState<boolean>(false);
  const [hasLoadPreviewAttachment, setHasLoadPreviewAttachment] = useState<boolean>(false);
  const [currentAttachment, setCurrentAttachment] = useState<AttachmentType>(initialAttachment);
  const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer>('');

  const attachmentRepository = useAttachmentRepository();

  const hideModal = () => setHasShowPreviewModal(false);

  /**
   * @description Скачивание отдельного файла
   * @param {number} id
   * @param {string} filename
   * @return {Promise<any>}
   */
  const downloadAttachment = async (id: number, filename: string): Promise<any> => {
    setLoading(true);
    try {
      await downloadSomeFile(`/api/v1/attachment/download/${id}`, filename, null, null, true);
    } finally {
      setLoading(false);
    }
  };

  /**
   * @description Получить список файлов
   * @return {Promise<any>}
   */
  const getAttachmentList = async (): Promise<any> => {
    setLoading(true);
    try {
      const { data } = await attachmentRepository.getAttachmentList({ type: type, id: lessonId });
      setAttachments(data.list);
    } finally {
      setLoading(false);
    }
  };

  /**
   * @description Превью в модалке
   * @param {number} attachmentId
   * @param {string} attachmentName
   * @return {Promise<any>}
   */
  const handleShowModal = async (attachmentId: number, attachmentName: string): Promise<any> => {
    setCurrentAttachment({
      id: attachmentId,
      name: attachmentName
    });
    setHasLoadPreviewAttachment(true);
    setLoading(true);
    try {
      await getPreviewUrl(attachmentId, setPreviewUrl, setHasShowPreviewModal);
    } finally {
      setHasLoadPreviewAttachment(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAttachmentList();
  }, [lessonId]);

  return (
    <Spin spinning={loading}>
      <PreviewFileModal
        hasLoadPreviewAttachment={hasLoadPreviewAttachment}
        fileName={currentAttachment.name}
        previewUrl={previewUrl}
        handleHideModal={hideModal}
        hasShowModal={hasShowPreviewModal}
      />
      {attachments.isEmpty() ? (
        <p>{strings.NO_HIDDEN_MATERIALS}</p>
      ) : (
        attachments.map((attachment: AttachmentItem) => {
          const createdBy = `${attachment?.user?.surname} ${attachment?.user?.name}`;
          return (
            <div key={attachment.id} style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={() => {
                  downloadAttachment(attachment.id, attachment.name);
                }}
              >
                {attachment.name}
              </Button>
              <EyeOutlined
                onClick={() => {
                  handleShowModal(attachment.id, attachment.name);
                }}
                style={{ color: '#999', cursor: 'pointer' }}
              />
              <span style={{ fontSize: 12, color: '#bbb' }}>
                {` ${strings.UPLOADED_AT} ${attachment.updatedAt} ${strings.BY} ${createdBy}`}
              </span>
            </div>
          );
        })
      )}
    </Spin>
  );
};

export default { AttachmentsList };
