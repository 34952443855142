import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LoginOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Row, Col, Button, Tooltip, Modal, Flex, Tag } from 'antd';
import { BASE_FAMILIES } from 'Global/routes';
import { switchUserModal } from 'helpers/switchUser';
import { IParent, IStudent } from 'types';
import { TitleWithButtons } from 'Admin/People/Families/styles';
import { useLanguageContext } from 'contexts/LanguageContext';
import { StudentsTable } from 'Global/components/Tables/Children';
import AvatarWithUserName from 'Global/components/AvatarWithUserName';
import { BtnCreateAccount } from 'Global/components/BtnCreateAccount';

interface PeopleProps {
  parents: IParent[];
  students: IStudent[];
  familyId: number;
  handleUserRefetch?: () => Promise<any>;
}

/**
 * @description Вывод родителей и детей в профиле семьи
 * @param {object} props
 * @return {React.ReactNode}
 */
export const People = (props: PeopleProps) => {
  const [strings] = useLanguageContext();
  const { parents, students, familyId, handleUserRefetch } = props;
  const [{ confirm }, contextHolder] = Modal.useModal();

  return (
    <>
      {contextHolder}
      <TitleWithButtons>
        <h3>Parents</h3>
        <Link to={`/family/${familyId}/add-parents`} style={{ marginInlineStart: 8 }}>
          <Button type="primary" size="small">
            {strings.ADD_NEW_PAYER}
          </Button>
        </Link>
      </TitleWithButtons>
      <div className="parents-list">
        {parents?.map((parent: IParent) => {
          const { id, familyId, loginAs, mainPhoto, tags } = parent;
          const { name, surname, email, id: userId, phone } = parent?.user || {};

          return (
            <Flex align="center" gap={10} style={{ marginTop: 20, marginBottom: 20 }} key={id}>
              <AvatarWithUserName
                name={name}
                surname={surname}
                link={`${BASE_FAMILIES}/${familyId}/parent/${id}`}
                photo={mainPhoto}
                size={55}
              />
              {loginAs && (
                <Tooltip title={strings.LOGIN_AS_PARENT}>
                  <Button icon={<LoginOutlined />} onClick={() => switchUserModal(loginAs, confirm)} />
                </Tooltip>
              )}
              <BtnCreateAccount
                id={id}
                userId={userId}
                email={email}
                loginAs={loginAs}
                type="parent"
                handleUserRefetch={handleUserRefetch}
                hasLoginEqualsEmail={true}
              />
              {email && (
                <span>
                  <MailOutlined /> <a href={`mailto:${email}`}>{email}</a>
                </span>
              )}
              {phone && (
                <span>
                  <PhoneOutlined /> <a href={`tel:${phone}`}>{phone}</a>
                </span>
              )}
              <Flex wrap="wrap" gap={5}>
                {tags?.map(({ id, name }) => (
                  <Tag color="blue" key={id} style={{ margin: 0 }}>
                    {name}
                  </Tag>
                ))}
              </Flex>
            </Flex>
          );
        })}
      </div>

      <TitleWithButtons>
        <h3>{strings.STUDENTS}</h3>
        <Link to={`/family/${familyId}/add-students`} style={{ marginInlineStart: 8 }}>
          <Button type="primary" size="small">
            {strings.ADD_NEW_STUDENT}
          </Button>
        </Link>
      </TitleWithButtons>
      <div className="children-list">
        <StudentsTable students={students} handleUserRefetch={handleUserRefetch} />
      </div>
    </>
  );
};

export default { People };
