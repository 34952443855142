import React, { useEffect, useMemo, useState } from 'react';
import { Row, Table, Button, message, Spin, Flex, Switch, Tooltip } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useBranchesRepository } from 'repos/BranchesRepository';
import { Branch } from 'types';
import { CheckCircleTwoTone, EditOutlined, MailOutlined, MinusCircleTwoTone } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { BASE_BRANCHES, BRANCHES_NEW } from '../../routes';
import { WelcomeLetterModal } from './WelcomeLetterModal';

export const BranchesTab = () => {
  const [strings] = useLanguageContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [hasHideInactiveBranch, setHasHideInactiveBranch] = useState<boolean>(true);
  const [dataList, setDataList] = useState<Branch[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<number>(null);
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const branchRepository = useBranchesRepository();

  const filteredBranches = useMemo(() => {
    if (hasHideInactiveBranch) {
      return dataList.filter(report => report.active);
    }
    return dataList;
  }, [dataList, hasHideInactiveBranch]);

  const fetchBranches = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await branchRepository.getBranches();
      setDataList(data);
    } catch (e) {
      message.error(e.message);
    } finally {
      setHasLoading(false);
    }
  };

  const getColumns = [
    {
      title: strings.NAME,
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: strings.TYPE,
      key: 'type',
      dataIndex: 'type'
    },
    {
      title: strings.ACTIVE,
      key: 'active',
      dataIndex: 'active',
      className: 'clmn-center',
      render: (active: boolean) =>
        active ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <MinusCircleTwoTone twoToneColor="#f5222d" />
    }
  ];

  const getActionColumns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'action',
      render: (id: number) => (
        <Flex gap={10} justify="end">
          <Link to={`${BASE_BRANCHES}/${id}/edit`}>
            <Button icon={<EditOutlined />} size="small" />
          </Link>
          <Tooltip title={strings.WELCOME_EMAIL}>
            <Button
              icon={<MailOutlined />}
              size="small"
              onClick={() => {
                setSelectedBranch(id);
                setHasShowModal(true);
              }}
            />
          </Tooltip>
        </Flex>
      )
    }
  ];

  useEffect(() => {
    fetchBranches();
  }, []);

  return (
    <>
      <WelcomeLetterModal id={selectedBranch} hasShowModal={hasShowModal} setHasShowModal={setHasShowModal} />
      <Flex justify="space-between">
        <Flex gap={10} align="baseline">
          <span>{strings.SHOW_ACTIVE}</span>
          <Switch
            defaultChecked
            checkedChildren={strings.YES}
            unCheckedChildren={strings.NO}
            onChange={() => setHasHideInactiveBranch(prevState => !prevState)}
          />
        </Flex>
        <Link to={BRANCHES_NEW}>
          <Button type="primary">{strings.ADD}</Button>
        </Link>
      </Flex>
      <Spin spinning={hasLoading}>
        <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
          <Table dataSource={filteredBranches} columns={[...getColumns, ...getActionColumns]} pagination={false} />
        </Row>
      </Spin>
    </>
  );
};

export default { BranchesTab };
