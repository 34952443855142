import React from 'react';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';

/**
 * @description Boolean render
 * @param {boolean} val
 * @return {React.ReactNode}
 */
export const booleanRender = (val: boolean): React.ReactNode =>
  val ? (
    <CheckCircleTwoTone style={{ fontSize: 20 }} twoToneColor="#52c41a" />
  ) : (
    <CloseCircleTwoTone style={{ fontSize: 20 }} twoToneColor="#f5222d" />
  );
