import React from 'react';
import { enBreadcrumbNameMap, ruBreadcrumbNameMap } from './Breadcrumbs/breadcrumbNameMap';
import Helmet from 'react-helmet';
import { AVAILABLE_LANGS, useLanguageContext } from 'contexts/LanguageContext';
import { useLocation } from 'react-router-dom';
import { EN_KEY, RU_KEY } from '../../Admin/Translations';

/**
 * @description Формирование Helmet
 * @param {object} location
 * @return {React.ReactNode | null}
 */
export default (): React.ReactElement | null => {
  const [, lang] = useLanguageContext();
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter((i: any) => i);
  const breadcrumbNames = pathSnippets
    .map((_: string, index: number) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
      const breadcrumbUrl = url.replace(/\/\d+/g, '/:id');
      if (lang === EN_KEY && enBreadcrumbNameMap[breadcrumbUrl]) {
        return enBreadcrumbNameMap[breadcrumbUrl];
      }
      if (lang === RU_KEY && ruBreadcrumbNameMap[breadcrumbUrl]) {
        return ruBreadcrumbNameMap[breadcrumbUrl];
      }
      return null;
    })
    .filter(breadcrumb => breadcrumb !== null);

  const title = breadcrumbNames.isNotEmpty() ? `AMI: ${breadcrumbNames[breadcrumbNames.length - 1]}` : 'AMI';

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};
