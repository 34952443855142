import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, Button, DatePicker, InputNumber, Select, Input, Col, Row, Tooltip, Spin, message } from 'antd';
import { isEmpty } from 'lodash';
import { Product } from 'types';
import { paymentTypes } from 'Global/constants';
import { Teachers } from 'api/Teachers';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { dateFormat } from 'helpers/dates';
import { TeacherFinanceItem } from 'types/Teachers/finance';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Option } = Select;

interface CreateTransactionProps {
  form: WrappedFormUtils;
  teacherId: number | string;
  products: Product[];
  isModalVisibility: boolean;
  fetchFinanceById: () => void;
  hideModal: () => void;
  edit?: boolean;
  defaultVal?: TeacherFinanceItem;
}

/**
 * @description - Компонент модального окна добавления транзакции
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const CreateTransactionModalTemplate = (props: CreateTransactionProps) => {
  const [hasLoading, setLoading] = useState<boolean>(false);
  const {
    form: { getFieldDecorator, validateFields, resetFields },
    teacherId,
    products,
    isModalVisibility,
    fetchFinanceById,
    defaultVal,
    edit,
    hideModal
  } = props;
  const [strings] = useLanguageContext();

  const closeModal = () => {
    fetchFinanceById();
    hideModal();
    resetFields();
  };

  /**
   * @param {Event} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    setLoading(true);
    validateFields(async (errors, values) => {
      if (isEmpty(errors)) {
        // TODO унифицировать даты. Бэк просит пока что в ISO.
        values.opDate = values.opDate.toISOString();
        if (edit) {
          await Teachers.editTeacherOperation(teacherId, values)
            .then(() => closeModal())
            .catch(() => {
              message.error(strings.ERROR_EDIT_OPERATION, 3);
              setLoading(false);
            });
        } else {
          await Teachers.createTeacherOperation(teacherId, { ...values, verified_by_ecp: 0, teacher_id: teacherId })
            .then(() => {
              closeModal();
              setTimeout(() => {
                message.success(strings.FINANCIAL_OPERATION_HAS_ADDED_SUCCESSFULLY, 2);
              }, 1000);
            })
            .catch(() => setLoading(false));
        }
      }
      setLoading(false);
    });
  };

  const getFooter = () => {
    const buttons = [
      <Button key="submitBtn" type="primary" htmlType="submit" onClick={handleSubmit}>
        {edit ? strings.EDIT : strings.CREATE}
      </Button>,
      <Button key="backBtn" onClick={hideModal}>
        {strings.CANCEL}
      </Button>
    ];
    return buttons;
  };

  const form = (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Row gutter={24}>
        <Col span={12}>
          <MyFormItem label={strings.DATE}>
            {getFieldDecorator('opDate', {
              rules: [
                {
                  required: true,
                  message: strings.DATE_IS_REQUIRED_FIELD
                }
              ],
              initialValue: defaultVal ? dayjs(defaultVal.date) : dayjs()
            })(<DatePicker placeholder={strings.DATE} format={dateFormat} />)}
          </MyFormItem>
        </Col>
        <Col span={12}>
          <MyFormItem label={strings.SUM}>
            {getFieldDecorator('amount', {
              rules: [
                {
                  required: true,
                  message: strings.SUM_IS_REQUIRED_FIELD
                }
              ],
              initialValue: defaultVal ? defaultVal.sum : 0
            })(<InputNumber placeholder={strings.SUM} />)}
          </MyFormItem>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <MyFormItem label={strings.PRODUCT}>
            {getFieldDecorator('product', {
              rules: [{ required: true, message: strings.PRODUCT_IS_REQUIRED_FIELD }],
              initialValue: defaultVal ? defaultVal.product.id : 10
            })(
              <Select placeholder={strings.PRODUCT}>
                {products.map((product: Product) => (
                  <Option value={product.id} key={product.id}>
                    {product.name}
                  </Option>
                ))}
              </Select>
            )}
          </MyFormItem>
        </Col>
        <Tooltip
          overlayStyle={{ maxWidth: '550px', zIndex: 1011 }}
          title="
              Если начисление было завышено, сумму корректировки указываем с минусом и выбираем Payment
              type: С1 - корректировка текущего периода, С2 - корректировка прошлого периода.
              Если начисление было занижено и надо доначислить зарплату, сумму корректировки указываем
              с плюсом и выбираем Payment type: С1 - доначисление текущего периода, С2 - доначисление прошлого периода
              "
        >
          <Col span={12}>
            <MyFormItem label={strings.PAYMENT_TYPE}>
              {getFieldDecorator('code', {
                rules: [
                  {
                    required: true,
                    message: strings.PAYMENT_TYPE_IS_REQUIRED_FIELD
                  }
                ],
                initialValue: defaultVal && defaultVal.paymentType
              })(
                <Select placeholder={strings.PAYMENT_TYPE}>
                  {paymentTypes.map(type => (
                    <Option key={type.value} value={type.value}>
                      {type.name}
                    </Option>
                  ))}
                </Select>
              )}
            </MyFormItem>
          </Col>
        </Tooltip>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <MyFormItem label={strings.COMMENT}>
            {getFieldDecorator('comment', {
              rules: [{ required: false }],
              initialValue: defaultVal ? defaultVal.comment : ''
            })(<Input.TextArea placeholder={strings.COMMENT} />)}
          </MyFormItem>
        </Col>
      </Row>
    </Form>
  );

  return (
    (<Modal
      title={edit ? strings.EDIT_TRANSACTION : strings.CREATE_NEW_TRANSACTION}
      footer={getFooter()}
      open={isModalVisibility}
      onCancel={hideModal}
      style={{ top: 20 }}
      width={620}
    >
      <Spin spinning={hasLoading}>{form}</Spin>
    </Modal>)
  );
};

export const CreateTransactionModal = Form.create<CreateTransactionProps>({})(CreateTransactionModalTemplate);

export default { CreateTransactionModal };
