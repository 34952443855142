export const columns = strings => [
  {
    title: 'Lesson topic',
    dataIndex: 'lessonTopic',
    key: 'lessonTopic'
  },
  {
    title: 'Lesson outcomes',
    dataIndex: 'lessonOutcomes',
    key: 'lessonOutcomes'
  },
  {
    title: 'Key Terms',
    dataIndex: 'keyTerms',
    key: 'keyTerms'
  },
  {
    title: 'Suggested Activities',
    dataIndex: 'suggestedActivities',
    key: 'suggestedActivities'
  },
  {
    title: 'Suggested homework',
    dataIndex: 'suggestedHomework',
    key: 'suggestedHomework'
  },
  {
    title: 'Comments',
    dataIndex: 'comments',
    key: 'comments'
  },
  {
    title: 'Resources',
    dataIndex: 'resources',
    key: 'resources'
  }
];

export default { columns };
