import React, { useEffect, useState } from 'react';
import { Modal, Button, message, Spin } from 'antd';
import { StudentInfoFeedback } from './StudentInfoFeedback';
import { useLanguageContext } from 'contexts/LanguageContext';
import { AudioCommentary } from './AudioCommentary';
import { TextCommentary } from './TextCommentary';
import { BACKEND_LOCATION } from 'helpers';
import { useLessonRepository } from 'repos/LessonRepository';
import { isEqual, isNull } from 'lodash';
import { ILessonRegister } from 'types/lesson';
import { useLessonRegisterStore } from 'stores/useLessonRegisterStore';

interface FeedbackForSupervisorModalProps {
  hasShowFeedbackForSupervisorModal: boolean;
  setHasShowFeedbackForSupervisorModal: (hasShow: boolean) => void;
  register: ILessonRegister;
}

/**
 * @description Модальное окно фидбэка для офиса
 * @param {boolean} hasShowFeedbackForSupervisorModal
 * @param {function} setHasShowFeedbackForSupervisorModal
 * @param {object} register
 * @param {number} lessonId
 * @return {React.ReactNode}
 */
export const FeedbackForSupervisorModal = ({
  hasShowFeedbackForSupervisorModal,
  setHasShowFeedbackForSupervisorModal,
  register
}: FeedbackForSupervisorModalProps) => {
  const { student, id: registerId } = register;
  const registers = useLessonRegisterStore(state => state.registers);
  const setRegisters = useLessonRegisterStore(state => state.setRegisters);

  const [hasSaveLoading, setHasSaveLoading] = useState<boolean>(false);
  const [mockCommentForSupervisor, setMockCommentForSupervisor] = useState<string>('');
  const [commentForSupervisor, setCommentForSupervisor] = useState<string>('');
  const [commentForSupervisorAudio, setCommentForSupervisorAudio] = useState<string>('');
  const [commentForSupervisorUpdatedAt, setCommentForSupervisorUpdatedAt] = useState<string>('');
  const [hasCommentsLoading, setCommentsLoading] = useState<boolean>(false);
  const [hasRemoveAudio, setHasRemoveAudio] = useState<boolean>(false);
  const [strings] = useLanguageContext();
  const defAudioValue = !isNull(commentForSupervisorAudio) ? `${BACKEND_LOCATION}${commentForSupervisorAudio}` : null;

  const [audio, setAudio] = useState<File>(null);
  const lessonRepository = useLessonRepository();
  const [{ confirm, info }, contextHolder] = Modal.useModal();

  /**
   * @description Удалить аудиокоммент
   * @return {Promise<any>}
   */
  const deleteAudio = async (): Promise<any> => {
    const hasCommentChanged = !isEqual(commentForSupervisor, mockCommentForSupervisor);
    try {
      const { data } = await lessonRepository.deleteRegisterAudioComment(register.id);
      setCommentForSupervisorUpdatedAt(data.commentForSupervisorUpdatedAt);
      if (hasCommentChanged) {
        updateComments(data.commentForSupervisorUpdatedAt);
      } else {
        //Чтобы не делать запрос всех реджистеров, берем значения обновляенных комментриев из ответа и обновляем у студента столбец Parents/students feedback
        const registersValues = registers.map(register => {
          if (register.student.id === student.id) {
            return {
              ...register,
              commentForSupervisorAudio: null
            };
          }
          return register;
        });
        setRegisters(registersValues);
        handleSaveSuccess();
      }
    } catch {}
  };

  const handleSaveSuccess = () => {
    setHasSaveLoading(false);
    setHasShowFeedbackForSupervisorModal(false);
    message.success(strings.SUCCESS_SAVE, 2);
  };

  const updateComments = async (commentForSupervisorUpdatedAt: string, ignoreUpdated = false): Promise<any> => {
    const commentParams = {
      _method: 'PUT',
      commentForSupervisor,
      commentForSupervisorUpdatedAt,
      ignoreUpdated
    };
    if (audio) {
      commentParams['file'] = audio;
    }

    try {
      const {
        data: { needFeedbackForSupervisorStatus, commentForSupervisor, commentForSupervisorAudio }
      } = await lessonRepository.editCommentForOfficeByRegisterId(registerId, commentParams);

      //Чтобы не делать запрос всех реджистеров, берем значения обновляенных комментриев из ответа и обновляем у студента столбец Parents/students feedback
      const registersValues = registers.map(register => {
        if (register.student.id === student.id) {
          return {
            ...register,
            needFeedbackForSupervisorStatus,
            commentForSupervisor,
            commentForSupervisorAudio
          };
        }
        return register;
      });
      setRegisters(registersValues);
      handleSaveSuccess();
    } catch (error) {
      const { status, data } = error;
      if (status === 422) {
        confirm({
          title: strings.DO_YOU_WANT_TO_SAVE_THE_CURRENT_CHANGES,
          content: data.message,
          okText: strings.SAVE,
          okType: 'danger',
          async onOk() {
            await updateComments(commentForSupervisorUpdatedAt, true);
          }
        });
      } else {
        setHasShowFeedbackForSupervisorModal(false);
        message.error(strings.SAVE_FAILED_TRY_AGAIN_LATER, 2);
      }
    }
  };

  const handleSaveFeedback = async () => {
    const hasCommentChanged = !isEqual(commentForSupervisor, mockCommentForSupervisor);
    const hasAudioDeleted = isNull(audio) && hasRemoveAudio;
    const hasAudioChanged = !isNull(audio) || hasAudioDeleted;
    if (!hasCommentChanged && !hasAudioChanged && !hasAudioDeleted) {
      return info({ title: strings.THERE_WERE_NO_CHANGES });
    }
    setHasSaveLoading(true);
    if (hasAudioDeleted) {
      await deleteAudio();
    } else {
      await updateComments(commentForSupervisorUpdatedAt);
    }
  };

  const onCancel = () => {
    const hasCommentChanged = !isEqual(commentForSupervisor, mockCommentForSupervisor);
    const hasAudioDeleted = isNull(audio) && hasRemoveAudio;
    const hasAudioChanged = !isNull(audio) || hasAudioDeleted;
    if (hasCommentChanged || hasAudioDeleted || hasAudioChanged) {
      confirm({
        title: strings.ARE_YOU_SURE_YOU_WANT_LEAVE_WITHOUT_SAVING,
        okType: 'danger',
        onOk() {
          setHasShowFeedbackForSupervisorModal(false);
        }
      });
    } else {
      setHasShowFeedbackForSupervisorModal(false);
    }
  };

  const getComments = async (): Promise<any> => {
    setCommentsLoading(true);
    try {
      const {
        data: { commentForSupervisor, commentForSupervisorUpdatedAt, commentForSupervisorAudio }
      } = await lessonRepository.getCommentForOfficeByRegisterId(registerId);
      setCommentForSupervisorUpdatedAt(commentForSupervisorUpdatedAt);
      setCommentForSupervisor(commentForSupervisor);
      setCommentForSupervisorAudio(commentForSupervisorAudio);
      // Запишем первоначальную информацию для дальнейшего сравнивания.
      setMockCommentForSupervisor(commentForSupervisor);
    } finally {
      setCommentsLoading(false);
    }
  };

  useEffect(() => {
    if (hasShowFeedbackForSupervisorModal) {
      getComments();
      setHasRemoveAudio(false);
    }
  }, [hasShowFeedbackForSupervisorModal]);

  return (
    <Modal
      width={800}
      title={strings.FEEDBACK_FOR_OFFICE}
      open={hasShowFeedbackForSupervisorModal}
      onCancel={onCancel}
      destroyOnClose={true}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {strings.CANCEL}
        </Button>,
        <Button type="primary" key="save" loading={hasSaveLoading} onClick={handleSaveFeedback}>
          {strings.SAVE_FEEDBACK}
        </Button>
      ]}
    >
      {contextHolder}
      <Spin spinning={hasCommentsLoading}>
        <StudentInfoFeedback student={student} />
        <TextCommentary
          feedbackForSupervisor={commentForSupervisor}
          setFeedbackForSupervisor={setCommentForSupervisor}
        />
        <AudioCommentary defAudioValue={defAudioValue} setAudio={setAudio} setHasRemoveAudio={setHasRemoveAudio} />
      </Spin>
    </Modal>
  );
};
