import { Lesson, Price } from './education';
import { Teacher } from './Teachers/teacher';
import { accountTypes } from 'types/user';
import { Event } from './Schedule';
import { Dayjs } from 'dayjs';
import { RegisterType, ReportingType } from './global';

export type Branch = {
  id: number;
  name: string;
  academicEmail?: string; //нигде не юзаем
  adminLanguage?: string; //нигде не юзаем
  currency?: string;
  disableCabinetLock?: boolean; //нигде не юзаем
  hideTeacher?: boolean; //нигде не юзаем
  hideFinances?: boolean; //нигде не юзаем
  hideParentFinance?: boolean;
  hideTeacherFinance?: boolean;
  hideParent?: boolean; //нигде не юзаем
  operationEmail?: string; //нигде не юзаем
  parentLanguage?: string; //нигде не юзаем
  type?: string;
  invoiceMonthFinanceSystem?: boolean; // Новая фин система
  active?: boolean;
};

export type AdminRole = {
  name: string;
  value: string;
  id?: number;
  sysName?: string;
};

export type TeacherWageType = {
  id: number;
  cost: number;
  name: string;
};

export type TeacherStatus = {
  id: number;
  name: string;
  weight: number;
};

export type ITag = {
  id: number;
  name: string;
};

export type AdminTag = {
  id: number;
  name: string;
};

export type AgeTag = {
  id: number;
  name: string;
};

export type ChildStatus = {
  id: number;
  name: string;
};

export type AcceptanceActs = {
  id: number;
  startedAt: Date;
  endAt: Date;
  file: any;
};

export type Student = {
  id: string | number;
  name: string;
  surname: string;
  patronymic: string;
  photoTiny: string;
  photoBig: string;
  needToPassSurvey?: boolean;
  meStudentId?: number;
};

export type TutorFinance = {
  id: string | number;
  name: string;
  surname: string;
  phone: string;
  email: string;
  photoTiny: string;
  photoBig: string;
  students: Student[];
};

export type IPrice = {
  id: number;
  name: string;
  price: number;
  type: {
    id: number;
    name: string;
  };
};

export type Rate = {
  id: number;
  name?: string;
  price?: number;
  discount?: number;
  discountReason?: number;
  comment?: string;
  endAt?: string;
  startAt?: string;
  afterTrial?: boolean;
  rate?: number;
  rateType?: number;
  type?: { id: number; name: string };
};

export type Performer = {
  id: number;
  name: string;
  isActive?: boolean;
  branch?: Branch;
};

export type AdditionalPerformer = {
  id: number;
  name: string;
  // Тут бренч не обычный, а с типом
  branch: {
    id: number;
    name: string;
    type: string;
  };
};

export type LastPerformer = {
  id: number;
  lastPerformer: Performer;
  name: string;
};

export interface ISmallChild {
  id: number;
  name: string;
  surname: string;
}

export interface Tag {
  id: number;
  name: string;
  comment?: string;
}

export interface ITagPostParams {
  name: string;
  comment?: string;
}

export interface TagAge {
  id: number;
  name: string;
}

export interface Tutor {
  id: number;
  name: string;
  surname: string;
  sex: 0 | 1;
  comment: string;
  branch: Branch;
  megaplanId: null | number;
  mobile: string;
  sipuniNum: string;
  email: string;
  skype: string;
  tags: Tag[];
  tagsAge: TagAge[];
  position: string;
  tasksCounter: number;
  isAccessClickUp: boolean;
  hasClickUpToken: boolean;
}

export interface Supergroup {
  id: number;
  name: string;
  branch: Branch;
  isActive: boolean;
  activeForStudent?: boolean;
  colour?: string;
}

export type Child = {
  id: number;
  family?: Family;
  familyId?: number;
  status?: Status;
  name: string;
  school?: any;
  patronymic?: string;
  surname: string;
  sex?: number;
  birthDate?: string;
  purpose?: any;
  entryTestResults?: any;
  studyProgram?: StudyProgram;
  comment?: null | string;
  meStudentId?: number;
  extraInfo?: null | string;
  loginAs?: string;
  hobby?: any;
  mobile?: string;
  mobileSecond?: string;
  mobileCanonical?: string;
  email?: string;
  emailSecond?: string;
  additionalEmail?: null | string;
  skype?: null | string;
  created?: string;
  updated?: string;
  tutor?: Tutor;
  supergroups?: Supergroup[];
  age?: string;
  photo?: string | string[];
  parents?: [{ parent: Parent }];
  mainPhoto?: string;
  mainPhotoBig?: string;
  mainPhotoMedium?: string;
  mainPhotoSmall?: string;
  grade?: string;
  englishLevel?: { id: number; name: string; comment: string }; // TODO разобраться как правильно
  englishLevelComment?: string;
  languageExperience?: number | string;
  classN?: string;
  target?: string;
  accessCreateAccount?: boolean;
  UCINumber?: string;
  candidateNumber?: string;
  firstNameEnglish?: string;
  lastNameEnglish?: string;
  examineType?: number;
  isSubsсribe?: boolean;
  performer?: Performer;
};

export interface ChildDiscount {
  id: number;
  familyId: number;
  name: string;
  surname: string;
  age: string;
  accessCreateAccount: boolean;
}

export type StudyProgram = {
  id: number;
  name: string;
  stage?: Stage;
};

export type Subject = {
  id: number;
  cost?: number;
  name: string;
};

export type Wage = {
  id: number;
  name: string;
  cost: number;
};

export enum ModalMode {
  Hidden,
  Add,
  Edit,
  Delete,
  AddCompensation
}

export type Product = {
  id: number;
  name: string;
};

export type Inventory = {
  id: number;
  name: string;
};

export interface IAcademicProgress extends Subject {
  lessonRegisters?: any; // TODO:
  taskRegisters?: any; // TODO:
  start_at?: string;
}

/**
 * ----------------------------------------------------------------------------------
 Почему я не использовал тип выше ^?
 - columns в AcademicProgress у учителя на данный момент отличаются от columns в ЛК админа
 - и поля внутри интерфейсов могут конфликтовать. Поэтому я создал временно новый тип для ЛК учителя
 - (до тех пор, пока мы не скоординируем представление данных фронта с представлением данных дизайна).
 - Потому что сейчас в дизайне есть штуки, которых я не вижу на бэке или для которых я не могу получить
 - объяснения на данный момент, но нужно залить то, что сделал.
 */

/**
  Представления колонок для таблиц в Student/AcademicProgress/columns.tsx
  */
export interface TestRegister {
  id: number;
  grade: number;
  title: string;
  start_at?: string; // FIXME: snake_case
}

export interface LessonRegister {
  id: number;
  lessonId?: number;
  lessonTitle?: string;
  student: Child;
  lesson: Lesson;
  attendance: boolean;
  homeworkApproved: boolean;
  createdAt: Date;
  title: string;
  startAt: Date;
  presence?: boolean;
  homeworkDone?: boolean;
  isFirstLesson: boolean;
  isFirstLessonOfStudent: boolean;
  noHomework: boolean;
  grade?: string;
  teacher?: Teacher;
  commentForStudent: string;
  commentForParent: string;
  commentForParent2: string;
  commentForParent3: string;
  commentForParentRewritten?: boolean;
  commentForParentOriginal: string;
  commentForParentApproved?: boolean;
  homeworkGradeSmiles?: boolean;
  commentForParentLikedByParent?: boolean;
  homeworkGrade?: string;
  homeworkStatus?: 0 | 1 | 2;
  homeworkRequired?: boolean;
}

export interface ProgressSubject {
  id: number;
  name: string;
  testRegister: TestRegister[];
  lessonRegister: {
    registers: LessonRegister[];
    registerColumns: string[];
  };
}

// ----------------------------------------------------------------------------------

export type Stage = {
  id: number;
  name: string;
  registerType: RegisterType;
  reportType: ReportingType;
  position?: number;
  performer?: Performer;
  branches?: Branch[];
  studyProgram?: StudyProgram;
  product?: Product;
};

export type Cost = {
  cost: number;
  id: number;
  name: string;
};

export type Phone = {
  mobile: string;
  mobileCannonical: string;
};

export type Parent = {
  id: number;
  name: string;
  patronymic: string;
  surname: string;
  userType?: string;
  email: string;
  isPayer: boolean;
  familyId: number;
  balancePerformer: number;
  familyStatus?: FamilyStatus;
  accessCreateAccount?: boolean;
  comment?: string;
  phones?: Phone[];
  profession?: string;
  birthDate?: Date;
  address?: string;
  teacher?: Teacher;
  mainPhoto?: string;
  loginAs?: string;
  passport?: string;
  passportParams?: {
    passportSeries: string;
    passportIssuedBy: string;
    passportDate: string;
  };
  phone: string;
  photo?: string[];
  inn?: string;
  invoiceSubscriber?: boolean;
  subscribed?: boolean;
  smsSubscribed?: number;
};

export interface StudentGroup {
  id: number;
  student: Child;
  startDate: string;
}
export interface Group {
  studentToGroups: StudentGroup[];
  id: number;
  name: string;
  groupName: string;
  version: string;
  duration: number;
  isActive: boolean;
  branch: Branch;
  subject: Subject;
}

export type IndividualCost = {
  cost: {
    id: number;
    cost: number;
    name: string;
  };
  daily_salary: boolean;
  individual: number;
};

export type Status = {
  id: number;
  name: string;
  weight?: number | null;
};

export interface GeoLocation {
  as: string;
  city: string;
  country: string;
  countryCode: string;
  isp: string;
  lat: number;
  lon: number;
  org: string;
  query: string;
  region: string;
  regionName: string;
  status: string;
  timezone: string;
  zip: string;
}
export type Role = {
  id: number;
  name: string;
  value: string;
  sysName?: string;
};

export type RoleWithPrefix = {
  role: string;
  prefix: string;
};

export type Summary = {
  start_balance: string;
  end_balance: string;
  endBalance: number;
};

export type ItemFinance = {
  id: number;
  date: string;
  sum: number;
  comment: string;
  product: Product;
};

export type Finance = {
  summary: Summary;
  currentMonth: ItemFinance[];
  previousMonths: ItemFinance[];
};

export interface Admin {
  id: number;
  sipuni?: number;
  branch: Branch;
  email: string;
  name: string;
  surname: string;
  mobile: string;
  position: string;
  patronymic?: string;
  skype?: string;
  megaplan_id?: number;
  comment?: string;
  sipuniNum?: string | number;
  sex: number;
  tags: Tag[];
  tagsAge: TagAge[];
  tasksCounter: number;
  value?: string;
  photo?: string | { full: string; medium: string; small: string };
  type?: 'Admin';
  loginAs?: string;
  isActive?: boolean;
  isAccessClickUp: boolean;
  hasClickUpToken: boolean;
  originalCoursesAccess?: string[];
  isInvoiceUnlimitedAccess?: boolean;
  clickupToken?: string;
  mainPhoto?: string;
  user?: {
    // TODO: Вынести в один интерфейс и везде его использовать
    id?: number;
    branch?: Branch;
    email?: string;
    name?: string;
    surname?: string;
    phone?: string;
    firstName?: string;
    lastName?: string;
    org1Id?: string;
    login?: string;
    birthDate?: string;
  };
}

export interface IAccount {
  id: number;
  type: accountTypes;
  email: string;
  manually: boolean;
  password: string;
  passwordAgain: string;
}

export interface LearnedFrom {
  id: number;
  title: string;
  isActive?: boolean;
  position?: null | number;
}

export interface FamilyTag {
  id: number;
  name: string;
}
export interface Family {
  id: number;
  status: Status;
  learnedFrom: LearnedFrom;
  commentLearnedFrom: string;
  oldPrice: boolean;
  balance: number;
  prefix1c: string;
  parents: { parent: Parent }[];
  created: string;
  updated: string;
  familyIndex: any;
  branch: Branch;
  personalCode: any;
  tax: any;
  familyTags: FamilyTag[];
  children?: Child[];
  summary?: Summary;
  clickupLink: string | undefined;
  interviewBy?: Admin;
  callAdmin?: Admin;
  product?: Product;
  manualBlock?: boolean;
  megaplanCard?: string;
  isDeletable?: boolean;
  amoLeadId?: number; // AmoCRM
}
export interface FamilyContractStatus {
  id: number;
  status: string;
  created?: string;
  is_active?: string;
  sort?: string;
  updated?: string;
}
export interface FamilyContractTemplateRow {
  id: number;
  subject: Subject;
  type: string;
  countWeek: number;
  countPeriod: number;
  price: Price;
}
export interface FamilyContractTemplate {
  id: number;
  name: string;
  branch: Branch;
  rows: FamilyContractTemplateRow[];
}
export type FamilyStatus = {
  id: number;
  name: string;
  weight?: number | null;
};
export interface LessonStatistics {
  lessonId: number;
  teacherId: number;
  notCountMain: boolean;
  notCountComm: boolean;
  notCountHW: boolean;
  disciplinePercent: number;
  disciplinePercentAll: number;
  startAt: string;
  endAt: string;
  group: string;
  done: boolean;
  url: string;
  firstLevelInvalid: boolean;
  secondLevelInvalid: boolean;
  noHw: boolean;
}

export interface LessonStatisticsFilledIn {
  count: string;
  done: number;
  percent: number;
}

export interface LessonsStatistics {
  [key: string]: {
    lessons: LessonStatistics[];
    start: string;
    end: string;
    valid: number;
    total: number;
    filledIn: LessonStatisticsFilledIn;
    money?: number;
  };
}

export interface Children {}

export interface NewsRole {
  id: number;
  name: string;
  sysName: string;
}

export interface INews {
  body: string;
  id: number;
  header: string;
  branch: Branch[];
  date: string;
  important: boolean;
  hasVideo: boolean;
  image?: string;
  studyPrograms?: StudyProgram[];
  roles?: NewsRole[];
  watched: boolean;
  fixed: boolean;
}
export interface NotFilledJournal {
  startAt: string;
  endAt: string;
  studentsCount: number;
  groupName: string;
  lessonId: number;
}

export interface NotFilledJournalResponse {
  lessThanOneDay: NotFilledJournal[];
  lessThanOneWeek: NotFilledJournal[];
  overdue: NotFilledJournal[];
}

export interface SlotRoom {
  id: number;
  name: string;
  color: string;
}

export interface SlotLessonTeacher {
  id: number;
  name: string;
  surname: string;
}

export interface SlotLessonCourse {
  id: number;
  name: string;
  groupName: string;
  duration: number;
  subject: Subject;
}

export interface SlotLessonDiscipline {
  id: number;
  teacherId: number;
  done: boolean;
  type: string;
  expired: string;
  created: string;
  updated: string;
  filled: boolean;
}
export interface SlotLesson {
  id: number;
  teacher: SlotLessonTeacher;
  course: SlotLessonCourse;
  disciplines: SlotLessonDiscipline[];
  date: string;
  filledIn: FilledIn;
}

// TODO: remove this type
export interface Slot {
  id: number;
  startAt: string;
  endAt: string;
  room: SlotRoom;
  type: string;
  lesson: SlotLesson;
  color: string;
  event: Event;
  editable: boolean;
  studentsCount: number;
  isAssistant: boolean;
  isAssistantAccess: boolean;
  isLessonTeacher: boolean;
}

export interface SlotRights {
  slotWrite: boolean;
  eventWrite: boolean;
  eventRead: boolean;
  lessonRead: boolean;
}

export interface Slots {
  slots: Slot[];
  rights: SlotRights;
  imGranted: boolean;
}

export interface FilledIn {
  red: boolean;
  yellow: boolean;
}

export interface Training {
  id: number;
  name: string;
}

export interface LessonTopic {
  patch: string;
  id: number;
  name: string;
}

export interface Material {
  lessonTopic: LessonTopic;
  materialsName: string;
  materialAttachmentId: number;
  type: string;
  uploadedBy: string;
  uploadedWhen: string;
  branch: Branch;
  subject: Subject;
  stage: Stage;
  groupCourse: string;
}

export interface CreateAccountNewUser {
  id: number;
  loginAs?: string;
  email: string;
  type?: accountTypes;
}

export interface CustomProduct {
  title: string;
  value: number;
  key: number;
}

export interface SearchContract {
  id: number;
  contract_number: string;
  contract_date: string;
  contract_type: number;
  created: string;
  updated: string;
  signed: boolean;
}

export interface AcademicProgressParams {
  limit?: number;
  page?: number;
  startAt?: string | Dayjs;
  endAt?: string;
  emptyTitle?: boolean;
  like?: boolean;
  dislike?: boolean;
  stage?: number;
  teacherTutor?: number;
  studentTutor?: number;
  branch?: number;
  subject?: number;
  teacher?: number;
  student?: number;
  commentType?: number /*1 - all, 2 - for parents, 3 - for students, 4 - for office*/;
  commentApproveType?: number /*0 - all, 1 - approved, 2 - unapproved*/;
  isCommentForParentRead?: boolean;
}

//AMI5
export type IStudent = {
  id: number;
  user: IUserData;
  familyId?: number;
  mainPhoto?: string;
  status?: Status;
  studyProgram?: StudyProgram;
  tutor?: Admin;
  supergroups?: Supergroup[];
  age?: string;
  loginAs?: string;
  created?: string;
  updated?: string;
  school?: string;
  classN?: string;
  target?: string;
  entryTestResults?: string;
  comment?: string;
  extraInfo?: string;
  hobby?: string;
  mobileSecond?: string;
  additionalEmail?: string;
  UCINumber?: string;
  candidateNumber?: string;
  firstNameEnglish?: string;
  lastNameEnglish?: string;
  examineType?: number;
  englishLevel?: { id: number; name: string };
  englishLevelComment?: string;
  performer?: Performer;
  name?: string;
  surname?: string;
  parents?: IParent[];
  siblings: IStudent[];
  autogeneratedInvoices?: boolean;
  allergies?: string;
  medicalConditions?: string;
  psychologistEvaluation?: string;
  takingPhotos?: boolean;
  schoolTrips?: boolean;
};

//AMI5
export type IUserData = {
  id: number;
  name: string;
  surname: string;
  patronymic: string;
  email: string;
  phone: string;
  gender: 0 | 1;
  birthDate: string;
  phoneSecond?: string;
  additionalEmail?: string;
  branch?: Branch;
  age?: string;
  login?: string;
  surnameHebrew?: string;
  nameHebrew?: string;
};

//AMI5
export interface IFamily {
  id: number;
  parents?: IParent[];
  children?: IStudent[];
  callAdmin?: { id: number; user: IUserData };
  interviewBy?: { id: number; user: IUserData };
  status?: Status;
  branch?: Branch;
  balance?: number;
  learnedFrom?: LearnedFrom;
  commentLearnedFrom?: string;
  created?: string;
  clickupLink?: string;
  prefix1c: string;
  isDeletable?: boolean;
  personalCode?: any;
  product?: Product;
  familyTags: FamilyTag[];
  amoLeadId?: number; // AmoCRM
  manualBlock?: boolean;
}

//AMI5
export type IParent = {
  id: number;
  user?: {
    id: number;
    name: string;
    surname: string;
    patronymic: string;
    age: string;
    email: string;
    additionalEmail?: string;
    phone: string;
    phoneSecond?: string;
    gender: 0 | 1;
    birthDate: string;
    isActive: boolean;
    branch: Branch;
    login?: string;
  };
  isPayer?: boolean;
  familyId?: number;
  balancePerformer?: number;
  accessCreateAccount?: boolean;
  comment?: string;
  profession?: string;
  address?: string;
  mainPhoto?: string;
  loginAs?: string;
  passport?: string;
  passportParams?: {
    passportSeries: string;
    passportNumber: string;
    passportIssuedBy: string;
    passportDate: string;
  };
  invoiceSubscriber?: boolean;
  subscribed?: boolean;
  smsSubscribed?: number;
  subscribeToHomework?: boolean;
  legalEntity?: boolean;
  familyStatus?: FamilyStatus;
  bankAccountNumber?: string;
  bankName?: string;
  bankCode?: string;
  tags?: FamilyTag[];
};

//AMI5
export type ITutor = {
  id: number;
  user: IUserData;
  mainPhoto: string;
};
