import styled from 'styled-components';
import { Teacher, MainBlock } from '../styles';

export const WorkProgress = styled(Teacher)``;
export const WorkProgressInfo = styled(MainBlock)`
  padding: 15px;
`;
export const ShowGroup = styled(Teacher)``;
export const ShowGroupInfo = styled(MainBlock)`
  padding: 15px;
`;

export default {
  WorkProgress,
  WorkProgressInfo,
  ShowGroup,
  ShowGroupInfo
};
