import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ScheduleService from 'helpers/services/ScheduleService';
import { Schedule as StSchedule } from 'Global/modules/Schedule/styles';
import { AxiosResponse } from 'axios';
import { PageHeader } from '@ant-design/pro-layout';
import { Row, Col, Switch, Button, Skeleton, Flex } from 'antd';
import { LessonsCalendar } from 'Global/modules/Schedule/Calendar';
import { Schedule as ScheduleApi } from 'api/Schedule';
import { EventSlot, LessonSlot, Room, SearchSlotsParams } from 'types/Schedule';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { TimeTable } from 'Global/modules/Schedule/TimeTable';
import { Filter } from 'Global/modules/Schedule/Filter';
import { StudentFilter } from 'Global/modules/Schedule/StudentFilter';
import dayjs from 'dayjs';
import { dateFormatForBackend } from 'helpers/dates';
import { useUserContext } from 'contexts/UserContext';
import { hasMoscowBranch } from 'helpers';
import { InformalAgreementModal } from 'Teacher/Main/InformalAgreement/InformalAgreement';
import { useFamilyContext } from 'contexts/FamilyContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { BulkSelectSection } from './BulkSelect';
import { useHistory } from 'react-router-dom';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import queryString from 'query-string';
import { useMediaQuery } from 'react-responsive';

export type ViewType = 'day' | 'week';

const service = new ScheduleService();

const StyledFilterContainer = styled.div`
  background: #fff;
  padding: 20px 15px;
  box-shadow: 0px 0px 46px -15px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  margin-bottom: 10px;
  @media (max-width: 960px) {
    .ant-btn {
      margin: 10px 0;
    }
  }
`;

/**
 * @description Расписание (Админ, Учитель, Родитель, Студент)
 * @return {React.ReactNode}
 */
export const Schedule = () => {
  const { goBack, push } = useHistory();
  const [user] = useUserContext();
  const [strings] = useLanguageContext();
  const [, hasLoadFamily] = useFamilyContext();
  const { locationSearchParams } = useLocationSearchParams();
  const hasMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const [rooms, setRooms] = useState<Room[]>([]);
  const hasAdmin = user?.hasRoles?.admin;
  const hasTeacher = user?.hasRoles?.teacher;
  const hasStudent = user?.hasRoles?.student;
  const hasParent = user?.hasRoles?.parent;

  const [hasLoadEvents, setHasLoadEvents] = useState<boolean | undefined>(undefined);
  const [events, setEvents] = useState<LessonSlot[] & EventSlot[]>([]);
  const [dates, setDates] = useState({ dateStart: '', dateEnd: '' });

  const [hasShowFilter, setHasShowFilter] = useState<boolean>(false);
  const [hasBulkMode, setHasBulkMode] = useState<boolean>(false);
  const [bulkLessons, setBulkLessons] = useState<number[]>([]);
  const initialView: ViewType = hasAdmin || hasMobile ? 'day' : 'week';
  const [view, setView] = useState<ViewType>(initialView);
  const hasDayView = view === 'day';
  const getInitDates = (): any =>
    hasDayView
      ? { dateStart: dayjs().format(dateFormatForBackend), dateEnd: dayjs().format(dateFormatForBackend) }
      : { ...service.getWeekDates() };
  const defFilterValues = {
    branch: user?.branch?.id,
    view: initialView,
    ...getInitDates()
  };
  const { lessonsOfOtherTeachers } = locationSearchParams;
  const [hasShowOtherLessons, setHasShowOtherLessons] = useState<boolean>(lessonsOfOtherTeachers);
  const userBranch = user?.branch?.id;
  /**
   * @description Показать/скрыть уроки других учителей
   * @returns {Promise<any>}
   */
  const setOtherTeachersLessons = (): void => {
    setHasShowOtherLessons(prevState => {
      getEventsByDates({ ...locationSearchParams, lessonsOfOtherTeachers: !prevState });
      return !prevState;
    });
  };

  /**
   * @description Функция на локальное получение комнат согласно бранчу (для фильтра)
   * @return {Promise<any>}
   */
  const getRoomsByBranch = async (branchId?: number): Promise<any> => {
    const branch = branchId || userBranch;
    await ScheduleApi.getRooms({ branch })
      .then(({ data, status }: AxiosResponse) => {
        if (status === 200) {
          setRooms(data);
        }
      })
      .catch(() => {});
  };

  /**
   * @description Выделение текущих дней
   * @param {boolean} hasShow
   * @return {void}
   */
  const setOnlyOneLessons = (hasShow: boolean): void => {
    if (hasShow) {
      setBulkLessons(service.getLessonsIdsByCurrentDay(events));
    } else {
      setBulkLessons([]);
    }
  };

  /**
   * @description Функция получения эвентов
   * @param {object} params
   * @return {Promise<any>}
   */
  const getEventsByDates = async (params?: Partial<SearchSlotsParams>): Promise<any> => {
    const { dateStart, dateEnd, view: scheduleView } = params || {};
    setHasLoadEvents(true);
    // Если даты прокинуты, то установим их
    if (dateStart && dateEnd) {
      setDates({ dateStart, dateEnd });
    }
    if (user.branch) {
      const searchParams = {
        ...dates,
        ...params,
        branch: params.branch || user?.branch?.id
      };

      // Если запрос делают родитель или студент, то мы не привязываемся к их филиалу
      if (hasParent || hasStudent) {
        delete searchParams.branch;
      }
      // Студент получает только свои уроки
      if (hasStudent) {
        searchParams['student'] = user.meStudentId;
      }

      try {
        const { data, status } = await ScheduleApi.getSlots(searchParams);

        if (status === 200) {
          setEvents(service.getScheduleSlots(user, data.slots, scheduleView));
          push({ search: queryString.stringify(params, { arrayFormat: 'bracket' }) });
          setView(scheduleView);
        }
      } finally {
        setHasLoadEvents(false);
      }
    }
  };

  useEffect(() => {
    //для родителей запрос слотов выполняется в компоненте StudentFilter`
    if (user.branch && !hasParent) {
      const { dateStart } = locationSearchParams;
      if (!dateStart) {
        getEventsByDates(defFilterValues);
      } else {
        const { view: scheduleView } = locationSearchParams;
        getEventsByDates({ view: scheduleView ? scheduleView : view, ...locationSearchParams });
        if (scheduleView) {
          setView(scheduleView);
        }
      }
    }
  }, [user]);

  // Получить доступные комнаты для ивентов по ветке юзера
  useEffect(() => {
    getRoomsByBranch();
  }, [user]);

  // На ресайз окна вызываем функцию для получения слотов, в которой идёт пересчёт ширины экрана
  useEffect(() => {
    const listener = () => {
      setEvents(service.getScheduleSlots(user, events, view, true));
    };

    if (hasLoadEvents) {
      window.addEventListener('resize', listener);
    }

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, [events]); // eslint-disable-line

  /**
   * @description Submit filter
   * @param {string} fields`
   * @return {void}
   */
  const handleSubmitFilter = async fields => {
    await getEventsByDates(fields);
  };

  const handleLastFilter = async () => {
    setHasShowFilter(true);
    await getEventsByDates(service.getLastFilter());
  };

  const getParentFilter = () => {
    if (hasLoadFamily) {
      return <Skeleton active={true} />;
    }

    return <StudentFilter getEvents={getEventsByDates} />;
  };

  function OtherTeachersLessons() {
    if (!hasTeacher) return null;

    return (
      <Col xs={24} lg={12}>
        <Switch onChange={setOtherTeachersLessons} checked={hasShowOtherLessons} style={{ marginInlineEnd: 10 }} />
        <span style={{ whiteSpace: 'nowrap' }}>{strings.SHOW_OTHER_TEACHERS_LESSONS}</span>
      </Col>
    );
  }

  function AdminActions() {
    if (!hasAdmin) return null;

    return (
      <>
        <Flex gap={10}>
          <Flex gap={10} align="center">
            <span>{hasShowFilter ? strings.HIDE_FILTER : strings.SHOW_FILTER}</span>
            <Switch checked={hasShowFilter} onChange={() => setHasShowFilter(!hasShowFilter)} />
          </Flex>
          {service.getLastFilter() && <Button onClick={handleLastFilter}>{strings.LAST}</Button>}
        </Flex>
        {hasDayView && <Button onClick={() => setHasBulkMode(!hasBulkMode)}>Bulk select</Button>}
        <TimeTable />
      </>
    );
  }

  return (
    <ErrorBoundary>
      {hasTeacher && hasMoscowBranch(user?.branch) && !user?.readAgreement && (
        <InformalAgreementModal hasOpenModal={true} />
      )}
      <StSchedule>
        <PageHeader onBack={goBack} title={strings.SCHEDULE} style={{ padding: 0, marginBottom: 10 }} />
        {hasParent && getParentFilter()}
        {!hasStudent && !hasParent && (
          <StyledFilterContainer>
            <Row justify="space-between" align="middle">
              <OtherTeachersLessons />
              <AdminActions />
            </Row>
            {hasAdmin && hasShowFilter && (
              <Filter
                rooms={rooms}
                getRoomsByBranch={getRoomsByBranch}
                onSubmit={handleSubmitFilter}
                getEventsByDates={getEventsByDates}
              />
            )}
          </StyledFilterContainer>
        )}
        {user?.hasRoles?.admin && hasBulkMode && (
          <BulkSelectSection
            rooms={rooms}
            setHasBulkMode={setHasBulkMode}
            bulkLessons={bulkLessons}
            hasBulkMode={hasBulkMode}
            getEventsByDates={getEventsByDates}
            setBulkLessons={setBulkLessons}
            setOnlyOneLessons={setOnlyOneLessons}
          />
        )}
        <LessonsCalendar
          events={events}
          rooms={rooms}
          getEventsByDates={getEventsByDates}
          hasBulkMode={hasBulkMode}
          setBulkLessons={setBulkLessons}
          bulkLessons={bulkLessons}
          setView={setView}
          hasLoadEvents={hasLoadEvents}
          view={view}
        />
      </StSchedule>
    </ErrorBoundary>
  );
};

export default { Schedule };
