import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledUpcomingLesson = styled.div`
  margin-top: 5px;
`;

export const StyledUpcomingSlot = styled.div`
  padding: 12px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  margin-bottom: 16px;
  min-height: 115px;
  background: #fff;
  &:hover {
    background: #f9f9f9;
  }

  .slot-sm {
    display: none;
    @media (max-width: 960px) {
      display: block;
    }
  }

  .slot-md {
    display: none;
    @media (min-width: 960px) {
      display: block;
    }
  }

  @media (max-width: 960px) {
    .ant-col {
      margin: 5px 0;
    }
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    margin: 0;
  }
`;

export const StyledLessonTime = styled.span`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 18px;
`;

export const StyledRoom = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0;
`;

export const StyledRoomColor = styled.div`
  width: 6px;
  height: 6px;
  background: ${({ color }) => color};
  border-radius: 50%;
  margin-inline-end: 6px;
`;

export const StyledGroupName = styled(Link)`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #1890ff;
`;
