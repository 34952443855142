import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, message, Button, DatePicker, InputNumber, Select, Input, Col, Row } from 'antd';
import { isEmpty } from 'lodash';
import { ModalMode, Product } from 'types';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { ModeActionMap } from 'Global/constants';
import { createTeacherOperation } from 'api/Teachers/finance';
import { paymentTypes } from 'Global/constants';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Option } = Select;

interface AddFinanceProps extends FormComponentProps {
  id: number | string;
  financeId: number;
  hideModal: () => void;
  values?: any;
  mode: ModalMode;
  fetchFinanceById: () => void;
  products: Product[];
}

/**
 * @description - Компонент модального окна добавления транзакции
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const FinanceModalTemplate = (props: AddFinanceProps) => {
  const {
    form: { getFieldDecorator, validateFields, setFieldsValue },
    mode,
    id,
    financeId,
    hideModal,
    values,
    fetchFinanceById,
    products
  } = props;
  const [strings] = useLanguageContext();

  const closeModal = () => {
    fetchFinanceById();
    setTimeout(() => {
      message.success(
        // TODO: Доработать перевод
        mode === ModalMode.AddCompensation
          ? strings.FINANCIAL_OPERATION_HAS_ADDED_SUCCESSFULLY
          : `${strings.FINANCIAL_OPERATION_HAS} ${ModeActionMap[mode]}ed ${strings.SUCCESSFULLY}`,
        2
      );
    }, 1000);
    hideModal();
  };

  /**
   * @param {Event} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFields(async (errors, values) => {
      if (isEmpty(errors)) {
        values.opDate = new Date(values.opDate).toISOString();
        await createTeacherOperation(id, values)
          .then(() => closeModal())
          .catch(() => {});
      }
    });
  };

  const getFooter = () => {
    const buttons = [
      <Button key="addBtn" type="primary" htmlType="submit" onClick={handleSubmit}>
        {strings.ADD}
      </Button>
    ];

    return buttons;
  };

  const defaultValues = {
    date: dayjs(values.date),
    sum: values.sum,
    comment: values.comment,
    code: values.code,
    product: values.product
  };

  useEffect(() => {
    if (mode === ModalMode.AddCompensation) {
      const { sum } = values;
      setFieldsValue({
        sum: sum * -1,
        code: 'C1',
        comment: 'Compensation'
      });
    }
  }, [financeId, mode]);

  const form = (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Row gutter={24}>
        <Col span={12}>
          <MyFormItem label={strings.DATE}>
            {getFieldDecorator('opDate', {
              rules: [
                {
                  required: true,
                  message: strings.DATE_IS_REQUIRED_FIELD
                }
              ],
              initialValue: defaultValues.date
            })(<DatePicker placeholder={strings.DATE} />)}
          </MyFormItem>
        </Col>
        <Col span={12}>
          <MyFormItem label={strings.SUM}>
            {getFieldDecorator('amount', {
              rules: [
                {
                  required: true,
                  message: strings.SUM_IS_REQUIRED_FIELD
                }
              ],
              initialValue: defaultValues.sum
            })(<InputNumber placeholder={strings.SUM} />)}
          </MyFormItem>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <MyFormItem label={strings.PRODUCT}>
            {getFieldDecorator('product', {
              rules: [{ required: true, message: strings.PRODUCT_IS_REQUIRED_FIELD }],
              initialValue: defaultValues.product?.id || defaultValues.product
            })(
              <Select placeholder={strings.PRODUCT}>
                {products.map((product: Product) => (
                  <Option value={product.id} key={product.id}>
                    {product.name}
                  </Option>
                ))}
              </Select>
            )}
          </MyFormItem>
        </Col>
        <Col span={12}>
          <MyFormItem label={strings.PAYMENT_TYPE}>
            {getFieldDecorator('code', {
              rules: [
                {
                  required: true,
                  message: strings.PAYMENT_TYPE_IS_REQUIRED_FIELD
                }
              ],
              initialValue: defaultValues.code
            })(
              <Select placeholder={strings.PAYMENT_TYPE}>
                {paymentTypes.map(type => (
                  <Option key={type.value} value={type.value}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            )}
          </MyFormItem>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <MyFormItem label={strings.COMMENT}>
            {getFieldDecorator('comment', {
              rules: [
                {
                  required: false
                }
              ],
              initialValue: defaultValues.comment
            })(<Input.TextArea placeholder={strings.COMMENT} />)}
          </MyFormItem>
        </Col>
      </Row>
    </Form>
  );

  return (
    <Modal
      title={strings.CREATE_NEW_TRANSACTION}
      footer={getFooter()}
      open={mode !== ModalMode.Hidden}
      onCancel={hideModal}
      style={{ top: 20 }}
      width={620}
    >
      {mode === ModalMode.Add || mode === ModalMode.AddCompensation || mode === ModalMode.Edit
        ? form
        : strings.ARE_YOU_SURE_WANT_TO_DELETE_TRANSACTION}
    </Modal>
  );
};

export const FinanceModal = Form.create<AddFinanceProps>({})(FinanceModalTemplate);

export default { FinanceModal };
