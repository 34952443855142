/**
 * @description Получение ссылки youtube для вставки в iframe
 * @param {string} url
 * @return {string}
 */
export const getYoutubeVideoUrl = (url: string): string => {
  if (!url.length) return;
  const regexp = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/; //eslint-disable-line
  const videoId = url.match(regexp) ? url.match(regexp)[1] : null;
  if (videoId) {
    return `https://www.youtube.com/embed/${videoId}`;
  } else {
    return null;
  }
};

/**
 * @description Разбивает строку на массив строк со ссылками на ютуб
 * @param {string} url
 * @return {Array<string>}
 */
export const splitYoutubeUrl = (url: string): Array<string> => {
  return url?.split('\n');
};

/**
 * @description Проверяет это ютуб ссылка или обычная
 * @param {string} url
 * @return {boolean}
 */
export const checkYoutubeUrl = (url: string): boolean => {
  const regexp = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/; // eslint-disable-line
  return regexp.test(url);
};
