import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Form } from '@ant-design/compatible';
import { Select, Input, Row, Col, DatePicker, Spin, Checkbox } from 'antd';
import { TutorSelect } from 'Global/components/FormComponentsCompatible/Selects/TutorSelect';
import { TeacherSelect } from 'Global/components/FormComponentsCompatible/Selects/TeacherSelect';
import { GroupSelect } from 'Global/components/FormComponentsCompatible/Selects/GroupSelect';
import { reversedDateFormatForBackend } from 'helpers/dates';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { LessonsFilterParams } from 'types/education';
import { debounce, isEmpty, pickBy } from 'lodash';
import { Global } from 'api';
import { Branch, IStudent, Subject } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { StageSelect } from 'Global/components/FormComponentsCompatible/Selects/StageSelect';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import { momentFormatValues } from 'helpers/momentFormatValues';
import { FilterButtons } from 'Global/components/FilterButtons';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { useHistory } from 'react-router-dom';
import { useUserContext } from 'contexts/UserContext';
import {StudentSelect} from "../../../Global/components/FormComponentsCompatible/Selects/StudentSelect";

const { Option } = Select;

interface FilterProps {
  form: WrappedFormUtils;
  getLessonsList: (params?: LessonsFilterParams) => Promise<any>;
}

/**
 * @description Filter lessons table
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const FilterTemplate = (props: FilterProps) => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const {
    form,
    form: { getFieldDecorator, validateFieldsAndScroll, resetFields },
    getLessonsList
  } = props;

  const globalRequestsRepository = useGlobalRequestsRepository();

  const { subjects, branches: branchesList } = useGlobalCollectionsContext();
  const [branches, setBranches] = useState<Branch[]>(branchesList);
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);
  const { locationSearchParams } = useLocationSearchParams();
  const { push } = useHistory();
  const hasRoleAcademicExpert = user?.hasRoles?.academicExpert;

  const { start, end, notFilledIn, branch, subject, tutor, teacher, student, stage, group } =
    locationSearchParams || {};

  /**
   * @description Form Submit
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values) => {
      const { notFilledIn } = values;
      if (isEmpty(errors)) {
        const filterParams = pickBy(momentFormatValues(values));
        if (notFilledIn) {
          filterParams['notFilledIn'] = 1;
        } else {
          filterParams['notFilledIn'] = 0;
        }
        try {
          setHasSubmitLoading(true);
          await getLessonsList(filterParams);
        } finally {
          setHasSubmitLoading(false);
        }
      }
    });
  };

  /**
   * @description Очистка фильтра
   * @return {void}
   */
  const handleClear = async (): Promise<any> => {
    push({ search: '' });
    resetFields();
    await getLessonsList();
  };

  /**
   * @description Получить все бранчи
   * @returns {Promise<void>}
   */
  const fetchAllBranches = async (): Promise<void> => {
    const { data } = await Global.getBranches({ all: true });
    setBranches(data);
  };

  // для админа academic Expert должны быть доступны все филиалы
  useEffect(() => {
    if (hasRoleAcademicExpert) {
      fetchAllBranches();
    }
  }, []);

  useEffect(() => {
    if (branchesList && !hasRoleAcademicExpert) {
      setBranches(branchesList);
    }
  }, [branchesList]);

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={[10, 10]}>
        <Col lg={6} xs={24}>
          {getFieldDecorator('start', {
            initialValue: start && dayjs(start)
          })(<DatePicker format={reversedDateFormatForBackend} placeholder={strings.PICK_START_DATE} />)}
        </Col>
        <Col lg={6} xs={24}>
          {getFieldDecorator('end', {
            initialValue: end && dayjs(end)
          })(<DatePicker format={reversedDateFormatForBackend} placeholder={strings.PICK_END_DATE} />)}
        </Col>
        <Col lg={6} xs={24}>
          <TutorSelect
            initTutor={tutor}
            getFieldDecorator={getFieldDecorator}
            name="tutor"
            placeholder={strings.TUTOR}
            style={{ margin: 0 }}
          />
        </Col>
        <Col lg={6} xs={24}>
          <TeacherSelect
            initTeacherId={teacher}
            getFieldDecorator={getFieldDecorator}
            name="teacher"
            placeholder={strings.TEACHER}
          />
        </Col>
        {branches.length !== 1 ? (
          <Col lg={6} xs={24}>
            {getFieldDecorator('branch', {
              initialValue: branch
            })(
              <Select placeholder={strings.BRANCH}>
                <Option value={undefined}>&nbsp;</Option>
                {branches.map((branch: Branch) => (
                  <Option key={branch.id} value={branch.id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            )}
          </Col>
        ) : (
          <>
            {getFieldDecorator('branch', {
              initialValue: user?.branch.id
            })(<Input style={{ display: 'none' }} />)}
          </>
        )}
        <Col lg={6} xs={24}>
          <StageSelect initialValue={stage} form={form} />
        </Col>
        <Col lg={6} xs={24}>
          {getFieldDecorator('subject', {
            initialValue: subject
          })(
            <Select placeholder={strings.SUBJECT}>
              <Option value={undefined}>&nbsp;</Option>
              {subjects.map((subject: Subject) => (
                <Option key={subject.id} value={subject.id}>
                  {subject.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col lg={6} xs={24}>
          <GroupSelect
            initGroup={group}
            getFieldDecorator={getFieldDecorator}
            name="group"
            placeholder={strings.GROUP}
          />
        </Col>
        <Col lg={6} xs={24}>
          <StudentSelect getFieldDecorator={getFieldDecorator} name="student" />
        </Col>
        <Col lg={6} xs={24}>
          {getFieldDecorator('notFilledIn', {
            initialValue: notFilledIn,
            valuePropName: 'checked'
          })(<Checkbox>{strings.NOT_FILLED_IN}</Checkbox>)}
        </Col>
      </Row>
      <FilterButtons handleClear={handleClear} hasSubmitLoading={hasSubmitLoading} />
    </Form>
  );
};

export const Filter = Form.create<FilterProps>({})(FilterTemplate);

export default { Filter };
