import React, { useState, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { Select, Modal, Input, Typography, Row, Col, Button, Spin, message, Tooltip, Badge, Form, Flex } from 'antd';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import Breadcrumbs from 'Global/components/Breadcrumbs';
import { convertStatusesToArray, autosave } from 'helpers';
import { useLastLocation } from 'react-router-last-location';
import { ReportStatus, ReportRow, ProgressReport } from 'types/Reports';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';
import { IStudent } from 'types';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { useConstantsContext } from 'contexts/ConstantsContext';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { BASE_PROGRESS_REPORT } from '../routes';
import { Container } from 'Global/GlobalStyle';
import { MetaGoalsModal } from './MetaGoalsModal';
import MyJoditEditor from 'Global/components/MyJoditEditor';
import { match } from 'react-router';
import { TeacherSelect } from 'Global/components/FormComponents';
import { BranchBruner } from 'types/branches';

const { Title } = Typography;
const { Option } = Select;

const StyledUpdatedBy = styled.div``;

// Autosave interval
let interval;

/**
 * @description Редактирование репорта
 */
export const ProgressReportEditForm = () => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();
  const { push, goBack } = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();

  const {
    ALLOW_TEACHER_PROGRESS_REPORT_STATUSES,
    PROGRESS_REPORT_APPROVED_STATUS,
    PROGRESS_REPORT_AVAILABLE_TO_PARENT,
    ACTIVE_STUDENT_STATUS_ID
  } = useConstantsContext();
  const [report, setReport] = useState<ProgressReport>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRowId, setSelectedRowId] = useState<number>(null);
  const [deletedRowId, setDeletedRowId] = useState<number>();
  const [loadingText, setLoadingText] = useState<string>('');
  const [statuses, setStatuses] = useState<ReportStatus[]>([]);
  const [rows, setRows] = useState<ReportRow[]>([]);
  const [updatedAt, setUpdatedAt] = useState<string>();
  const [hasStopAutosave, setHasStopAutosave] = useState<boolean>(false);
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);

  const hasBruner = useMemo(() => report?.group?.branch?.id === BranchBruner.id, [report]);

  const reportsRepository = useProgressReportsRepository();
  const handleBack = () => goBack();
  const hasTeacherRole = user?.hasRoles?.teacher;
  const lastLocation = useLastLocation();
  const [{ confirm }, contextHolder] = Modal.useModal();

  const initValues = useMemo(
    () => ({
      status: report?.status,
      name: report?.name,
      teacher: report?.teacher?.id,
      whatWasLearned: report?.whatWasLearned || '<p style="text-align: right;"></p>'
    }),
    [report]
  );

  const rowsRef = useRef(rows);

  const getUpdatedByFullName = (): string | null => {
    if (report?.updatedBy) {
      const { name, surname, id: userId } = report.updatedBy;
      return user.id === userId ? strings.ME : `${name} ${surname}`;
    }

    return;
  };

  const getUpdatedBy = (): React.ReactElement | null => {
    if (report?.updatedBy) {
      const { name, surname, id: userId } = report.updatedBy;
      return (
        <StyledUpdatedBy>
          <span>
            {strings.LAST_UPDATE_BY} <b style={{ margin: '0 3px' }}>{getUpdatedByFullName()}</b>{' '}
            <span style={{ opacity: '.5' }}>{updatedAt}</span>
          </span>
        </StyledUpdatedBy>
      );
    }

    return null;
  };

  const getSaveConfirmModal = (error, isAutosave?: boolean) => {
    setHasStopAutosave(true);
    const { status } = error;
    if (status === 400) {
      confirm({
        title: `${strings.ATTENTION_THIS_REPORT_HAS_ALREADY_BEEN_SAVED_BY} ${getUpdatedByFullName()}. ${
          strings.YOU_MAY_LOSE_THE_INFORMATION_YOU_FILLED_IN_EARLIER
        }.`,
        okText: strings.SAVE,
        okType: 'danger',
        async onOk() {
          await reportsRepository.updateProgressReportById(id, {
            ...form.getFieldsValue(),
            rows: sanitizedRows(),
            updatedAt: updatedAt,
            ignoreUpdated: true
          });
          setHasStopAutosave(false);
          message.success(strings.PROGRESS_REPORT_SUCCESSFULLY_SAVED);
          if (!isAutosave) {
            push(`${BASE_PROGRESS_REPORT}/${id}`);
          } else {
            await fetchProgressReport(id);
          }
        },
        onCancel() {
          setHasStopAutosave(false);
        }
      });
    }
  };
  const sanitizedRows = () => {
    if (!hasBruner) {
      return rowsRef.current;
    }
    return rowsRef.current.map(item => ({ ...item, progress: item.progress.replace(/&nbsp;/g, ' ') }));
  };

  const handleSubmit = async (values): Promise<any> => {
    setLoading(true);
    setLoadingText(strings.PLEASE_WAIT_WE_SAVE_THE_DATA);
    try {
      await reportsRepository.updateProgressReportById(id, {
        ...values,
        rows: sanitizedRows(),
        updatedAt: updatedAt
      });
      message.success(strings.PROGRESS_REPORT_SUCCESSFULLY_SAVED);
      push(`${BASE_PROGRESS_REPORT}/${id}`);
    } catch (error) {
      getSaveConfirmModal(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAutoSubmit = async (): Promise<any> => {
    // при автосейве не делаем перезагрузку страницы
    try {
      await reportsRepository.updateProgressReportById(id, {
        ...form.getFieldsValue(),
        rows: sanitizedRows(),
        updatedAt: updatedAt
      });
      await fetchUpdatedAtProgressReport();
    } catch (error) {
      getSaveConfirmModal(error, true);
    }
  };

  const fetchProgressReport = async (reportId: number | string): Promise<any> => {
    setLoading(true);
    setLoadingText(strings.LOADING_PROGRESS_REPORT);
    try {
      const { data } = await reportsRepository.getProgressReportById(reportId);
      setReport(data);
      setRows(data.rows);
      setUpdatedAt(data.updatedAt);
    } finally {
      setLoading(false);
      setDeletedRowId(null);
    }
  };

  const fetchUpdatedAtProgressReport = async (): Promise<any> => {
    try {
      const { data } = await reportsRepository.getProgressReportById(id);
      setUpdatedAt(data.updatedAt);
    } catch {}
  };

  const fetchStatuses = async (): Promise<any> => {
    try {
      const { data } = await reportsRepository.getStatusesList();
      setStatuses(convertStatusesToArray(data));
    } catch {
      setStatuses([]);
    }
  };

  const deleteRowReport = async (rowId: number): Promise<any> => {
    setDeletedRowId(rowId);
    confirm({
      title: strings.ARE_YOU_SURE_WANT_DELETE_STUDENT,
      okType: 'danger',
      async onOk() {
        await reportsRepository
          .deleteReportRow(rowId)
          .then(() => {
            fetchProgressReport(id);
          })
          .catch(() => {});
      }
    });
  };

  const handleSetRow = (field: string, studentId: number, text: string) => {
    setRows(
      rows.map((row: ReportRow) => {
        if (studentId === row.student.id) {
          return {
            ...row,
            [field]: text
          };
        }

        return row;
      })
    );
  };

  const handleChangeField = (field: string, studentId: number, text: string): void => {
    handleSetRow(field, studentId, text);
  };

  const handleOpenMetaGoals = (rowId: number): void => {
    setSelectedRowId(rowId);
    setHasShowModal(true);
  };

  const getStatusStudent = (child: IStudent) => {
    // 21 - Not student
    if (child?.status?.id === 21) {
      return <span style={{ color: '#f5222d', fontSize: 14 }}> {strings.NOT_STUDENT}</span>;
    }
    return null;
  };

  const getChangeStatusSelect = () => {
    if (
      user?.hasRoles?.teacher &&
      (report?.status === PROGRESS_REPORT_AVAILABLE_TO_PARENT || report?.status === PROGRESS_REPORT_APPROVED_STATUS)
    ) {
      return null;
    }

    const options = statuses.map(({ value, text }) => {
      if (value === 'filled' && report?.goals?.isNotEmpty()) {
        const filteredRows = rows
          ?.filter(item => item?.student?.status?.id !== 21)
          ?.filter(item => item?.goals?.filter(goal => goal?.grade === null)?.isNotEmpty());

        if (filteredRows?.isNotEmpty()) {
          return (
            <Option value={value} key={value} disabled>
              <Tooltip title={strings.PLEASE_RATE_THE_META_GOALS} placement="top" overlayStyle={{ zIndex: 9999 }}>
                {text}
              </Tooltip>
            </Option>
          );
        }
      }
      if (user.hasRoles.teacher && !ALLOW_TEACHER_PROGRESS_REPORT_STATUSES.includes(text)) {
        return (
          <Option value={value} key={value} disabled>
            {text}
          </Option>
        );
      }
      return (
        <Option value={value} key={value}>
          {text}
        </Option>
      );
    });

    return (
      <Col lg={6}>
        <Form.Item name="status" label={strings.STATUS}>
          <Select placeholder={strings.STATUS}>{options}</Select>
        </Form.Item>
      </Col>
    );
  };

  useEffect(() => {
    clearInterval(interval);
    interval = autosave(handleAutoSubmit, 60000);

    //когда открыто мод окно с конфликтом сохранения, отключаем автосохранение
    if (hasStopAutosave) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [hasStopAutosave, updatedAt]);

  useEffect(() => {
    rowsRef.current = rows;
  }, [rows]);

  useEffect(() => {
    fetchProgressReport(id);
    fetchStatuses();
  }, [id, hasTeacherRole]);

  useEffect(() => {
    form.setFieldsValue(initValues);
  }, [initValues]);

  return (
    <ErrorBoundary>
      {contextHolder}
      {selectedRowId && hasShowModal && (
        <MetaGoalsModal
          rowId={selectedRowId}
          rows={rows}
          setRows={setRows}
          metaGoals={report?.goals}
          hasShowModal={hasShowModal}
          setHasShowModal={setHasShowModal}
        />
      )}
      <Spin tip={loadingText} spinning={loading}>
        <Container>
          <Breadcrumbs location={location} />
          <Row gutter={[10, 10]}>
            <Col lg={24} xs={24}>
              <Title level={3}>
                {lastLocation && <ArrowLeftOutlined onClick={handleBack} />} {strings.EDIT_PROGRESS_REPORTS}
                <span style={{ paddingInlineStart: 10, fontWeight: 600 }}>{report?.group?.groupName}</span>
              </Title>
            </Col>
            <Col lg={12}>{getUpdatedBy()}</Col>
          </Row>
          <Form form={form} layout="vertical" onFinish={handleSubmit} style={{ marginTop: 20 }}>
            <Row gutter={[10, 10]}>
              {getChangeStatusSelect()}
              {!hasTeacherRole && (
                <>
                  <Col lg={8} xs={24}>
                    <Form.Item name="name" label={strings.TITLE}>
                      <Input placeholder={strings.TITLE} />
                    </Form.Item>
                  </Col>
                  <Col lg={8} xs={24}>
                    <Form.Item name="teacher" label={strings.THE_TEACHER_WHO_HAS_FILLED_IN_THE_JOURNAL}>
                      <TeacherSelect disabled={report?.status === 'approved'} />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
            <Row>
              <Col lg={24} xs={24}>
                <Form.Item name="whatWasLearned" label={strings.THEMES}>
                  {hasBruner ? (
                    <MyJoditEditor
                      toolbar={['left', 'right']}
                      size="small"
                      key="whatWasLearned-editor"
                      height={150}
                      onChange={value => form.setFieldsValue({ whatWasLearned: value })}
                    />
                  ) : (
                    <Input.TextArea style={{ height: 150 }} placeholder={strings.THEMES} />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 10]} style={{ fontSize: '16px', fontWeight: 'bold' }}>
              {!hasTeacherRole && <Col lg={1}></Col>}
              <Col lg={4}>{strings.STUDENT}</Col>
              <Col lg={12}>{strings.GENERAL_FEEDBACK_CONCERNING_THE_STUDENT_S_PROGRESS}</Col>
              <Col lg={!hasTeacherRole ? 7 : 8}>{strings.COMMENT_FOR_THE_OFFICE}</Col>
            </Row>
            {rows.map((row: ReportRow, index) => {
              const { student, progress, commentForOffice, id: rowId } = row;
              const hasActiveStudent = student?.status?.id === ACTIVE_STUDENT_STATUS_ID;
              return (
                <>
                  <Row gutter={[10, 10]} align="top" style={{ marginTop: '20px' }} key={rowId}>
                    {!hasTeacherRole && (
                      <Col lg={1}>
                        <Tooltip
                          title={
                            !hasActiveStudent
                              ? strings.DELETE_STUDENT
                              : strings.YOU_CANT_DELETE_AN_ENROLLED_STUDENT_OR_ANY_STUDENT_WHEN_THE_STATUS_OF_THE_REPORT_IS_APPROVED_OR_AVAILABLE_TO_PARENT
                          }
                        >
                          <Button
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => deleteRowReport(rowId)}
                            loading={deletedRowId === rowId}
                            disabled={!hasActiveStudent ? false : true}
                          />
                        </Tooltip>
                      </Col>
                    )}
                    <Col lg={4}>
                      <Form.Item>
                        <Flex vertical gap={5}>
                          <Link to={`/family/${student.familyId}/student/${student.id}`}>
                            {student?.user?.name} {student?.user?.surname}
                          </Link>
                          {getStatusStudent(student)}
                          {report?.goals?.isNotEmpty() && (
                            <Badge
                              color={row?.goals.filter(item => item.grade === null).isEmpty() ? 'green' : 'red'}
                              dot
                            >
                              <Button onClick={() => handleOpenMetaGoals(rowId)}>{strings.META_GOALS}</Button>
                            </Badge>
                          )}
                        </Flex>
                      </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                      <Form.Item>
                        {hasBruner ? (
                          <MyJoditEditor
                            toolbar={['left', 'right']}
                            size="small"
                            value={progress || '<p style="text-align: right;"></p>'}
                            key={`keyVocabulary-editor-${student.id}`}
                            height={120}
                            onChange={value => handleChangeField('progress', student.id, value)}
                          />
                        ) : (
                          <Input.TextArea
                            defaultValue={progress}
                            style={{ height: 120 }}
                            placeholder={strings.PROGRESS_ACQUIRED_KNOWLEDGE_AND_SKILL}
                            onChange={e => handleChangeField('progress', student.id, e.target.value)}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col lg={!hasTeacherRole ? 7 : 8} xs={24}>
                      <Form.Item>
                        <Input.TextArea
                          defaultValue={commentForOffice}
                          placeholder={strings.TYPE_COMMENT}
                          style={{ height: 120 }}
                          onChange={e => handleChangeField('commentForOffice', student.id, e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              );
            })}
            <Row style={{ marginTop: 24 }}>
              <Col lg={24}>
                <Button type="primary" size="large" htmlType="submit" loading={loading} disabled={loading}>
                  {strings.SAVE}
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </Spin>
    </ErrorBoundary>
  );
};

export default { ProgressReportEditForm };
