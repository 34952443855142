import React from 'react';
import { InfoCircleTwoTone } from '@ant-design/icons';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Button, Col, Row, Input, Radio, Tooltip, Typography } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { LessonAssessmentPrimary } from 'types/accessment';
import { useLanguageContext } from 'contexts/LanguageContext';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { QuickTips } from 'Global/components/QuickTips';
import { QUICK_TIPS_PRIMARY_LESSON_ASSESSMENT } from 'Global/constants';
import { checkTypeForInput } from 'helpers/booleanFomatValues';

const { Text } = Typography;

interface LessonAssessmentProps extends FormComponentProps {
    lessonAssessment: LessonAssessmentPrimary;
    form: WrappedFormUtils;
}

/**
 * @description LessonPrimaryFields component
 * @param {object} props
 * @return {React.ReactNode}
 */
export const LessonPrimaryFields = (props: LessonAssessmentProps) => {
    const [strings] = useLanguageContext();
    const {
        form: { getFieldDecorator, setFieldsValue },
        lessonAssessment: {
            lessonFinalMark,
            lessonEmotions,
            lessonEmotionsComment,
            lessonSafety,
            lessonSafetyComment,
            lessonControl,
            lessonControlComment,
            lessonPositiveReinforcement,
            lessonPositiveReinforcementComment,
            lessonDisruptiveBehaviourFeedback,
            lessonDisruptiveBehaviourFeedbackComment,
            lessonNewKnowledge,
            lessonNewKnowledgeComment,
            lessonReadyBeforeBell,
            lessonReadyBeforeBellComment,
            lessonClearBeginningEnd,
            lessonClearBeginningEndComment,
            lessonIndividualWork,
            lessonIndividualWorkComment,
            lessonGroupWork,
            lessonGroupWorkComment,
            lessonMovingActivities,
            lessonMovingActivitiesComment,
            lessonToldPlan,
            lessonToldPlanComment,
            lessonReflectionTime,
            lessonReflectionTimeComment,
            lessonRecommendation
        }
    } = props;

    /**
     * @description Очистка формы
     * @param {string} fieldName
     * @return {void}
     */
    const clearFieldByName = (fieldName: string): void => setFieldsValue({ [fieldName]: '' });

    const getTitleDisruptiveBehaviourFeedback = () => {
        return (
            <div>
                {
                    strings.SOMETIMES_STUDENTS_DEMONSTRATE_DISRUPTIVE_BEHAVIOUR_DURING_THE_LESSON_IT_SHOULD_NOT_AFFECT_LESSON_ASSESSMENT_WHAT_IS_IMPORTANT_FOR_THE_TEACHER_HERE_IS_TO_NOTICE_IT
                }
                {strings.TEACHERS_CORRECT_REACTION_INCLUDES}
                <ul>
                    <li>{strings.NOTICING_DISRUPTIVE_BEHAVIOUR}</li>
                    <li>{strings.TELLING_THE_STUDENT_WHAT_TO_DO}</li>
                    <li>{strings.SEEING_TO_IT_THAT_THE_STUDENT_STOPS_THIS_BEHAVIOUR}</li>
                    <li>
                        {
                            strings.IF_NOTHING_CHANGES_THE_TEACHER_CALLS_THE_TUTOR_OR_OFFERS_A_TIMEOUT_IMPORTANT_THE_TEACHER_IS_CALM_WHILE_GIVING_SPECIFIC_FEEDBACK
                        }
                    </li>
                </ul>
            </div>
        );
    };

    const getTitleClearBeginningEnd = () => {
        return (
            <div>
                {strings.COMPULSORY_LESSON_ELEMENTS}
                <ul>
                    <li>{strings.RINGING_THE_BELL_AT_THE_BEGINNING_OF_THE_LESSON}</li>
                    <li>{strings.OPENING_RITUAL_VERBAL_OR_GAMIFIED_THERE_MUST_BE_SOME_SIGNAL_TO_MAKE_STUDENTS_CONCENTRATE}</li>
                    <li>{strings.CLOSING_RITUAL_THE_TEACHER_SHOULD_LET_STUDENTS_KNOW_THAT_THE_LESSON_IS_OVER}</li>
                    <li>{strings.RINGING_THE_BELL_AT_THE_END_OF_THE_LESSON}</li>
                </ul>
            </div>
        );
    };

    const getTitleMovingActivities = () => {
        return (
            <>
                <p>{strings.NO_MOVING_ACTIVITIES_NO_DYNAMIC_BREAK_THOUGH_THEY_WERE_NEEDED}</p>
                <p>
                    {strings.KIDS_WERE_MOVING_BUT_NOT_ENOUGH_NO_ACTIVE_MOVEMENT_NO_DYNAMIC_BREAK_BUT_IT_DIDNT_CHANGE_THE_LESSON}
                </p>
                <p>{strings.MOVING_ACTIVITIES_PRESENT}</p>
            </>
        );
    };

    const getTitleToldPlan = () => {
        return (
            <>
                <div>
                    {
                        strings.IT_IS_IMPORTANT_FOR_THE_STUDENTS_TO_GET_AN_IDEA_OF_WHAT_THEY_ARE_GOING_TO_DO_IN_THE_LESSON_THE_TEACHER_CAN_SHOW_A_LESSON_MAP_A_CHECKLIST_A_LESSON_PLAN_ANY_FORM_WILL_DO
                    }
                </div>
                <div>
                    {
                        strings.THIS_ENHANCES_THE_STUDENTS_REFLECTION_AND_PLANNING_SKILLS_AS_WELL_AS_KEEPS_THE_LESSON_WELL_STRUCTURED
                    }
                </div>
            </>
        );
    };

    const getTitleReflectionTime = () => {
        return (
            <>
                <div>
                    {
                        strings.AT_THE_END_OF_THE_LESSON_OR_THE_KEY_ACTIVITY_WE_SHOULD_DISCUSS_WHETHER_WE_COMPLETED_IT_SUCCESSFULLY_ACHIEVED_THE_GOALS_OF_THE_LESSON
                    }
                </div>
                <div>
                    {
                        strings.EVEN_IF_YOU_DIDNT_IT_IS_A_GREAT_OPPORTUNITY_TO_REFLECT_ON_WHAT_AND_WHY_SOMETHING_WENT_WRONG_AND_WHAT_CAN_BE_DONE_NEXT_TIME_TO_COPE_WITH_THE_TASK_BETTER
                    }
                </div>
            </>
        );
    };

    return (
        <>
            <Row justify="end">
                <QuickTips label={strings.QUICK_TIPS} url={QUICK_TIPS_PRIMARY_LESSON_ASSESSMENT} />
            </Row>
            <Row gutter={[10, 10]}>
                <Col lg={12}>
                    <MyFormItem
                        label={
                            <>
                                {strings.CONTROL}
                                <Tooltip
                                    overlayStyle={{ minWidth: 400 }}
                                    title={
                                        <>
                                            <div>{strings.INFO_CLASS_CONTROL_1}</div>
                                            <div>{strings.INFO_CLASS_CONTROL_2}</div>
                                        </>
                                    }
                                >
                                    <InfoCircleTwoTone twoToneColor="#1890ff" />
                                </Tooltip>
                            </>
                        }
                    >
                        {getFieldDecorator('lessonControl', {
                            initialValue: lessonControl
                        })(
                            <Radio.Group>
                                <Tooltip title={strings.NOT_MORE_THAN_25_IF_0_THEN_THE_LESSON_SHOULD_BE_MARKED_NI}>
                                    <Radio.Button value="0">0</Radio.Button>
                                </Tooltip>
                                <Tooltip title={strings.NOT_MORE_THAN_50}>
                                    <Radio.Button value="1">1</Radio.Button>
                                </Tooltip>
                                <Tooltip title={strings.FROM_50_TO_75}>
                                    <Radio.Button value="2">2</Radio.Button>
                                </Tooltip>
                                <Tooltip title={strings.FROM_75_TO_95}>
                                    <Radio.Button value="3">3</Radio.Button>
                                </Tooltip>
                                <Tooltip title={strings.THE_TEACHER_IS_CONTROLLING_MORE_THAN_95_OF_THE_STUDENTS}>
                                    <Radio.Button value="4">4</Radio.Button>
                                </Tooltip>
                            </Radio.Group>
                        )}
                        <Button size="middle" style={{ marginInlineStart: 10 }} onClick={() => clearFieldByName('lessonControl')}>
                            {strings.CLEAR}
                        </Button>
                    </MyFormItem>
                </Col>
                <Col lg={12}>
                    <MyFormItem label={strings.COMMENT}>
                        {getFieldDecorator('lessonControlComment', {
                            initialValue: checkTypeForInput(lessonControlComment),
                            rules: [{ required: false }]
                        })(<Input.TextArea placeholder={strings.СOMMENT} />)}
                    </MyFormItem>
                </Col>
            </Row>
            <Row gutter={[10, 10]}>
                <Col lg={12}>
                    <MyFormItem label={strings.STUDENTS_EMOTIONAL_INVOLVEMENT}>
                        {getFieldDecorator('lessonEmotions', {
                            initialValue: lessonEmotions,
                            rules: [{ required: false }]
                        })(
                            <Radio.Group>
                                <Tooltip title={strings.STUDENTS_SHOW_SIGNS_OF_BOREDOM_AND_FATIGUE_YAWNING_LYING_ON_THE_DESKS}>
                                    <Radio.Button value="0">0</Radio.Button>
                                </Tooltip>
                                <Tooltip title={strings.SUSTAINABLE_INTEREST_ALTERNATE_WITH_SIGNS_OF_FATIGUE}>
                                    <Radio.Button value="1">1</Radio.Button>
                                </Tooltip>
                                <Tooltip title={strings.STUDENTS_DEMONSTRATE_SUSTAINABLE_INTEREST}>
                                    <Radio.Button value="2">2</Radio.Button>
                                </Tooltip>
                                <Tooltip title={strings.STUDENTS_ARE_EMOTIONALLY_INVOLVED_AT_LEAST_ONE_HIGH_MOMENT}>
                                    <Radio.Button value="3">3</Radio.Button>
                                </Tooltip>
                                <Tooltip
                                    title={
                                        strings.STUDENTS_ARE_ENJOYING_THE_LESSON_THEY_ARE_IN_A_GOOD_MOOD_AND_DEMONSTRATE_HIGH_EMOTIONAL_INVOLVEMENT_THERE_ARE_SEVERAL_EMOTIONAL_HIGH_MOMENTS_THROUGHOUT_THE_LESSON
                                    }
                                >
                                    <Radio.Button value="4">4</Radio.Button>
                                </Tooltip>
                            </Radio.Group>
                        )}
                        <Button size="middle" style={{ marginInlineStart: 10 }} onClick={() => clearFieldByName('lessonEmotions')}>
                            {strings.CLEAR}
                        </Button>
                    </MyFormItem>
                </Col>
                <Col lg={12}>
                    <MyFormItem label={strings.EMOTIONS_COMMENT}>
                        {getFieldDecorator('lessonEmotionsComment', {
                            initialValue: lessonEmotionsComment
                        })(<Input.TextArea placeholder={strings.COMMENT} />)}
                    </MyFormItem>
                </Col>
            </Row>
            <Row gutter={[10, 10]}>
                <Col lg={12}>
                    <MyFormItem label={strings.SAFETY}>
                        {getFieldDecorator('lessonSafety', {
                            initialValue: lessonSafety
                        })(
                            <Radio.Group>
                                <Tooltip title={strings.A_DANGEROUS_SITUATION_IN_THE_LESSON}>
                                    <Radio.Button value="0">0</Radio.Button>
                                </Tooltip>
                                <Tooltip title={strings.POTENTIALLY_DANGEROUS_TASK_OR_THINGS_IN_THE_CLASSROOM}>
                                    <Radio.Button value="1">1</Radio.Button>
                                </Tooltip>
                                <Tooltip title={strings.NO_DANGEROUS_SITUATIONS}>
                                    <Radio.Button value="2">2</Radio.Button>
                                </Tooltip>
                            </Radio.Group>
                        )}
                        <Button size="middle" style={{ marginInlineStart: 10 }} onClick={() => clearFieldByName('lessonSafety')}>
                            {strings.CLEAR}
                        </Button>
                    </MyFormItem>
                </Col>
                <Col lg={12}>
                    <MyFormItem label={strings.COMMENT}>
                        {getFieldDecorator('lessonSafetyComment', {
                            initialValue: checkTypeForInput(lessonSafetyComment),
                            rules: [{ required: false }]
                        })(<Input.TextArea placeholder={strings.СOMMENT} />)}
                    </MyFormItem>
                </Col>
            </Row>
            <Row gutter={[10, 10]}>
                <Col lg={12}>
                    <MyFormItem label={strings.ACQUISITION_OF_THE_NEW_KNOWLEDGE}>
                        {getFieldDecorator('lessonNewKnowledge', {
                            initialValue: lessonNewKnowledge
                        })(
                            <Radio.Group>
                                <Tooltip title={strings.NOBODY_DEMONSTRATES_NEW_KNOWLEDGE}>
                                    <Radio.Button value="0">0</Radio.Button>
                                </Tooltip>
                                <Tooltip
                                    title={
                                        strings.LESS_THAN_2_3_OF_THE_GROUP_DEMOSTRATE_THEIR_KNOWLEDGE_OF_THE_TOPIC_OR_MORE_THAN_2_3_DO_THAT_BUT_THE_KNOWLEDGE_INFORMATION_IS_NOT_NEW_OR_THE_TASKS_ARE_NOT_CHALLENGING_ENOUGH
                                    }
                                >
                                    <Radio.Button value="1">1</Radio.Button>
                                </Tooltip>
                                <Tooltip
                                    title={
                                        strings.OF_STUDENTS_DEMONSTRATE_NEW_KNOWLEDGE_BY_ASKING_AND_ANSWERING_QUESTIONS_COMPLETING_TASKS
                                    }
                                >
                                    <Radio.Button value="2">2</Radio.Button>
                                </Tooltip>
                            </Radio.Group>
                        )}
                        <Button
                            size="middle"
                            style={{ marginInlineStart: 10 }}
                            onClick={() => clearFieldByName('lessonNewKnowledge')}
                        >
                            {strings.CLEAR}
                        </Button>
                    </MyFormItem>
                </Col>
                <Col lg={12}>
                    <MyFormItem label={strings.COMMENT}>
                        {getFieldDecorator('lessonNewKnowledgeComment', {
                            initialValue: checkTypeForInput(lessonNewKnowledgeComment),
                            rules: [{ required: false }]
                        })(<Input.TextArea placeholder={strings.СOMMENT} />)}
                    </MyFormItem>
                </Col>
            </Row>
            <Row gutter={[10, 10]}>
                <Col lg={12}>
                    <MyFormItem
                        label={
                            <>
                                {strings.POSITIVE_REINFORCEMENT}
                                <Tooltip
                                    overlayStyle={{ minWidth: 400 }}
                                    title={
                                        <>
                                            <div>{strings.POSITIVE_REINFORCEMENT_INFO_1}</div>
                                            <div>{strings.POSITIVE_REINFORCEMENT_INFO_2}</div>
                                        </>
                                    }
                                >
                                    <InfoCircleTwoTone twoToneColor="#1890ff" />
                                </Tooltip>
                            </>
                        }
                    >
                        {getFieldDecorator('lessonPositiveReinforcement', {
                            initialValue: lessonPositiveReinforcement
                        })(
                            <Radio.Group>
                                <Tooltip
                                    title={strings.THE_TEACHER_DIDNT_USE_POSITIVE_REINFORCEMENT_AT_ALL_IF_1_OR_0_THE_LESSON_IS_MARKED_NI}
                                >
                                    <Radio.Button value="0">0</Radio.Button>
                                </Tooltip>
                                <Tooltip title={strings.LESS_THAN_49}>
                                    <Radio.Button value="1">1</Radio.Button>
                                </Tooltip>
                                <Tooltip title={strings.GOT_POSITIVE_REINFORCEMENT_50_75}>
                                    <Radio.Button value="2">2</Radio.Button>
                                </Tooltip>
                                <Tooltip title={strings.GOT_POSITIVE_REINFORCEMEN_75_95}>
                                    <Radio.Button value="3">3</Radio.Button>
                                </Tooltip>
                                <Tooltip title={strings.EACH_STUDENT_WAS_PRAISED_FOR_SOMETHING_SPECIFIC_AT_LEAST_ONCE}>
                                    <Radio.Button value="4">4</Radio.Button>
                                </Tooltip>
                            </Radio.Group>
                        )}
                        <Button
                            size="middle"
                            style={{ marginInlineStart: 10 }}
                            onClick={() => clearFieldByName('lessonPositiveReinforcement')}
                        >
                            {strings.CLEAR}
                        </Button>
                    </MyFormItem>
                </Col>
                <Col lg={12}>
                    <MyFormItem label={strings.COMMENT}>
                        {getFieldDecorator('lessonPositiveReinforcementComment', {
                            initialValue: checkTypeForInput(lessonPositiveReinforcementComment),
                            rules: [{ required: false }]
                        })(<Input.TextArea placeholder={strings.СOMMENT} />)}
                    </MyFormItem>
                </Col>
            </Row>
            <Row gutter={[10, 10]}>
                <Col lg={12}>
                    <MyFormItem
                        label={
                            <>
                                {strings.FEEDBACK_ON_DISRUPTIVE_BEHAVIOUR_SPECIFIC_RECOMMENDATIONS}
                                <Tooltip overlayStyle={{ minWidth: 400 }} title={getTitleDisruptiveBehaviourFeedback}>
                                    <InfoCircleTwoTone twoToneColor="#1890ff" />
                                </Tooltip>
                            </>
                        }
                    >
                        {getFieldDecorator('lessonDisruptiveBehaviourFeedback', {
                            initialValue: lessonDisruptiveBehaviourFeedback
                        })(
                            <Radio.Group>
                                <Tooltip title={strings.IF_0_THE_LESSON_IS_MARKED_NI}>
                                    <Radio.Button value="0">0</Radio.Button>
                                </Tooltip>
                                <Tooltip title={strings.NOT_LESS_THAN_49_OF_CASES_WERE_NOTICED_ALMOST_NO_SPECIFIC_FEEDBACK}>
                                    <Radio.Button value="1">1</Radio.Button>
                                </Tooltip>
                                <Tooltip
                                    title={
                                        strings.CASES_WERE_NOTICED_BY_THE_TEACHER_STUDENTS_GOT_CONSTRUCTIVE_AND_SPECIFIC_FEEDBACK_50_74
                                    }
                                >
                                    <Radio.Button value="2">2</Radio.Button>
                                </Tooltip>
                                <Tooltip
                                    title={
                                        strings.CASES_WERE_NOTICED_BY_THE_TEACHER_STUDENTS_GOT_CONSTRUCTIVE_AND_SPECIFIC_FEEDBACK_75_95
                                    }
                                >
                                    <Radio.Button value="3">3</Radio.Button>
                                </Tooltip>
                                <Tooltip
                                    title={
                                        strings.THE_TEACHERS_REACTION_WAS_CORRECT_TO_ALL_INSTANCES_OF_DISRUPTIVE_BEHAVIOUR_THERE_WAS_NO_DISRUPTIVE_BEHAVOIUR
                                    }
                                >
                                    <Radio.Button value="4">4</Radio.Button>
                                </Tooltip>
                            </Radio.Group>
                        )}
                        <Button
                            size="middle"
                            style={{ marginInlineStart: 10 }}
                            onClick={() => clearFieldByName('lessonDisruptiveBehaviourFeedback')}
                        >
                            {strings.CLEAR}
                        </Button>
                    </MyFormItem>
                </Col>
                <Col lg={12}>
                    <MyFormItem label={strings.COMMENT}>
                        {getFieldDecorator('lessonDisruptiveBehaviourFeedbackComment', {
                            initialValue: checkTypeForInput(lessonDisruptiveBehaviourFeedbackComment),
                            rules: [{ required: false }]
                        })(<Input.TextArea placeholder={strings.СOMMENT} />)}
                    </MyFormItem>
                </Col>
            </Row>
            <Row gutter={[10, 10]}>
                <Col lg={12}>
                    <MyFormItem label={strings.STUDENTS_ARE_READY_FOR_THE_LESSON_BEFORE_THE_BELL_RINGS}>
                        {getFieldDecorator('lessonReadyBeforeBell', {
                            initialValue: lessonReadyBeforeBell
                        })(
                            <Radio.Group>
                                <Tooltip
                                    title={
                                        strings.THE_TEACHER_DIDNT_TELL_THE_STUDENTS_WHAT_TO_PREPARE_BEFORE_THE_BEGINNING_OF_THE_LESSON_THE_STUDENTS_ARE_NOT_READY_FOR_THE_LESSON
                                    }
                                >
                                    <Radio.Button value="0">0</Radio.Button>
                                </Tooltip>
                                <Tooltip
                                    title={
                                        strings.THE_TEACHER_TOLD_THE_STUDENTS_WHAT_MATERIALS_THEY_SHOULD_PREPARE_BEFORE_THE_BEGINNING_OF_THE_LESSON_THE_STUDENTS_SIT_AT_THEIR_DESKS_ALL_THE_NECESSARY_MATERIALS_ARE_ON_THE_DESKS_IN_FRONT_OF_THEM
                                    }
                                >
                                    <Radio.Button value="1">1</Radio.Button>
                                </Tooltip>
                            </Radio.Group>
                        )}
                        <Button
                            size="middle"
                            style={{ marginInlineStart: 10 }}
                            onClick={() => clearFieldByName('lessonReadyBeforeBell')}
                        >
                            {strings.CLEAR}
                        </Button>
                    </MyFormItem>
                </Col>
                <Col lg={12}>
                    <MyFormItem label={strings.COMMENT}>
                        {getFieldDecorator('lessonReadyBeforeBellComment', {
                            initialValue: checkTypeForInput(lessonReadyBeforeBellComment),
                            rules: [{ required: false }]
                        })(<Input.TextArea placeholder={strings.СOMMENT} />)}
                    </MyFormItem>
                </Col>
            </Row>
            <Row gutter={[10, 10]}>
                <Col lg={12}>
                    <MyFormItem
                        label={
                            <>
                                {strings.CLEAR_BEGINNING_AND_END_OF_THE_LESSON}
                                <Tooltip title={getTitleClearBeginningEnd} overlayStyle={{ minWidth: 400 }}>
                                    <InfoCircleTwoTone twoToneColor="#1890ff" />
                                </Tooltip>
                            </>
                        }
                    >
                        {getFieldDecorator('lessonClearBeginningEnd', {
                            initialValue: lessonClearBeginningEnd
                        })(
                            <Radio.Group>
                                <Radio.Button value="0">0</Radio.Button>
                                <Radio.Button value="1">1</Radio.Button>
                            </Radio.Group>
                        )}
                        <Button
                            size="middle"
                            style={{ marginInlineStart: 10 }}
                            onClick={() => clearFieldByName('lessonClearBeginningEnd')}
                        >
                            {strings.CLEAR}
                        </Button>
                    </MyFormItem>
                </Col>
                <Col lg={12}>
                    <MyFormItem label={strings.COMMENT}>
                        {getFieldDecorator('lessonClearBeginningEndComment', {
                            initialValue: checkTypeForInput(lessonClearBeginningEndComment),
                            rules: [{ required: false }]
                        })(<Input.TextArea placeholder={strings.СOMMENT} />)}
                    </MyFormItem>
                </Col>
            </Row>
            <Row gutter={[10, 10]}>
                <Col lg={12}>
                    <MyFormItem
                        label={
                            <>
                                {strings.INDIVIDUAL_WORK}
                                <Tooltip title={strings.IF_0_THE_LESSON_IS_MARKED_NI}>
                                    <InfoCircleTwoTone twoToneColor="#1890ff" />
                                </Tooltip>
                            </>
                        }
                    >
                        {getFieldDecorator('lessonIndividualWork', {
                            initialValue: lessonIndividualWork
                        })(
                            <Radio.Group>
                                <Radio.Button value="0">0</Radio.Button>
                                <Radio.Button value="1">1</Radio.Button>
                            </Radio.Group>
                        )}
                        <Button
                            size="middle"
                            style={{ marginInlineStart: 10 }}
                            onClick={() => clearFieldByName('lessonIndividualWork')}
                        >
                            {strings.CLEAR}
                        </Button>
                    </MyFormItem>
                </Col>
                <Col lg={12}>
                    <MyFormItem label={strings.COMMENT}>
                        {getFieldDecorator('lessonIndividualWorkComment', {
                            initialValue: checkTypeForInput(lessonIndividualWorkComment),
                            rules: [{ required: false }]
                        })(<Input.TextArea placeholder={strings.СOMMENT} />)}
                    </MyFormItem>
                </Col>
            </Row>
            <Row gutter={[10, 10]}>
                <Col lg={12}>
                    <MyFormItem
                        label={
                            <>
                                {strings.GROUP_OR_PAIRWORK}
                                <Tooltip
                                    title={
                                        <>
                                            <p>{strings.NO_GROUP_PAIRWORK}</p>
                                            <p>{strings.LITTLE_GROUP_PAIRWORK_OR_THIS_LESSON_DOES_NOT_REQUIRE_THESE_INTERACTION_PATTERNS}</p>
                                            <p>{strings.GROUP_PAIRWORK_IS_PRESENT}</p>
                                        </>
                                    }
                                >
                                    <InfoCircleTwoTone twoToneColor="#1890ff" />
                                </Tooltip>
                            </>
                        }
                    >
                        {getFieldDecorator('lessonGroupWork', {
                            initialValue: lessonGroupWork
                        })(
                            <Radio.Group>
                                <Radio.Button value="0">0</Radio.Button>
                                <Radio.Button value="1">1</Radio.Button>
                                <Radio.Button value="2">2</Radio.Button>
                            </Radio.Group>
                        )}
                        <Button size="middle" style={{ marginInlineStart: 10 }} onClick={() => clearFieldByName('lessonGroupWork')}>
                            {strings.CLEAR}
                        </Button>
                    </MyFormItem>
                </Col>
                <Col lg={12}>
                    <MyFormItem label={strings.COMMENT}>
                        {getFieldDecorator('lessonGroupWorkComment', {
                            initialValue: checkTypeForInput(lessonGroupWorkComment),
                            rules: [{ required: false }]
                        })(<Input.TextArea placeholder={strings.СOMMENT} />)}
                    </MyFormItem>
                </Col>
            </Row>
            <Row gutter={[10, 10]}>
                <Col lg={12}>
                    <MyFormItem
                        label={
                            <>
                                {strings.MOVING_ACTIVITIES_A_DYNAMIC_BREAK}
                                <Tooltip title={getTitleMovingActivities} overlayStyle={{ minWidth: 400 }}>
                                    <InfoCircleTwoTone twoToneColor="#1890ff" />
                                </Tooltip>
                            </>
                        }
                    >
                        {getFieldDecorator('lessonMovingActivities', {
                            initialValue: lessonMovingActivities
                        })(
                            <Radio.Group>
                                <Radio.Button value="0">0</Radio.Button>
                                <Radio.Button value="1">1</Radio.Button>
                                <Radio.Button value="2">2</Radio.Button>
                            </Radio.Group>
                        )}
                        <Button
                            size="middle"
                            style={{ marginInlineStart: 10 }}
                            onClick={() => clearFieldByName('lessonMovingActivities')}
                        >
                            {strings.CLEAR}
                        </Button>
                    </MyFormItem>
                </Col>
                <Col lg={12}>
                    <MyFormItem label={strings.COMMENT}>
                        {getFieldDecorator('lessonMovingActivitiesComment', {
                            initialValue: checkTypeForInput(lessonMovingActivitiesComment),
                            rules: [{ required: false }]
                        })(<Input.TextArea placeholder={strings.СOMMENT} />)}
                    </MyFormItem>
                </Col>
            </Row>
            <Row gutter={[10, 10]}>
                <Col lg={12}>
                    <MyFormItem
                        label={
                            <>
                                {strings.TEACHER_TOLD_ABOUT_OR_VISUALIZED_THE_LESSON_PLAN_AT_THE_BEGINNING_OF_THE_LESSON}
                                <Tooltip title={getTitleToldPlan} overlayStyle={{ minWidth: 400 }}>
                                    <InfoCircleTwoTone twoToneColor="#1890ff" />
                                </Tooltip>
                            </>
                        }
                    >
                        {getFieldDecorator('lessonToldPlan', {
                            initialValue: lessonToldPlan
                        })(
                            <Radio.Group>
                                <Radio.Button value="0">0</Radio.Button>
                                <Radio.Button value="1">1</Radio.Button>
                            </Radio.Group>
                        )}
                        <Button size="middle" style={{ marginInlineStart: 10 }} onClick={() => clearFieldByName('lessonToldPlan')}>
                            {strings.CLEAR}
                        </Button>
                    </MyFormItem>
                </Col>
                <Col lg={12}>
                    <MyFormItem label={strings.COMMENT}>
                        {getFieldDecorator('lessonToldPlanComment', {
                            initialValue: checkTypeForInput(lessonToldPlanComment),
                            rules: [{ required: false }]
                        })(<Input.TextArea placeholder={strings.СOMMENT} />)}
                    </MyFormItem>
                </Col>
            </Row>
            <Row gutter={[10, 10]}>
                <Col lg={12}>
                    <MyFormItem
                        label={
                            <>
                                {strings.TEACHER_ALLOCATED_TIME_FOR_REFLECTION}
                                <Tooltip title={getTitleReflectionTime} overlayStyle={{ minWidth: 400 }}>
                                    <InfoCircleTwoTone twoToneColor="#1890ff" />
                                </Tooltip>
                            </>
                        }
                    >
                        {getFieldDecorator('lessonReflectionTime', {
                            initialValue: lessonReflectionTime
                        })(
                            <Radio.Group>
                                <Radio.Button value="0">0</Radio.Button>
                                <Radio.Button value="1">1</Radio.Button>
                            </Radio.Group>
                        )}
                        <Button
                            size="middle"
                            style={{ marginInlineStart: 10 }}
                            onClick={() => clearFieldByName('lessonReflectionTime')}
                        >
                            {strings.CLEAR}
                        </Button>
                    </MyFormItem>
                </Col>
                <Col lg={12}>
                    <MyFormItem label={strings.COMMENT}>
                        {getFieldDecorator('lessonReflectionTimeComment', {
                            initialValue: checkTypeForInput(lessonReflectionTimeComment),
                            rules: [{ required: false }]
                        })(<Input.TextArea placeholder={strings.СOMMENT} />)}
                    </MyFormItem>
                </Col>
            </Row>
            <Row gutter={[10, 10]}>
                <Col lg={24} style={{ background: '#e6f7ff', padding: '10px 20px 30px 20px', borderRadius: '5px' }}>
                    <MyFormItem
                        label={
                            <>
                                {strings.SUMMARY_COMMENTS}
                                <Text type="secondary" style={{ float: 'right', fontSize: '12px' }}>
                                    {strings.ONLY_THIS_PART_IS_VISIBLE_TO_THE_TEACHER}
                                </Text>
                            </>
                        }
                    >
                        {getFieldDecorator('lessonRecommendation', {
                            initialValue: checkTypeForInput(lessonRecommendation),
                            rules: [
                                {
                                    required: false
                                }
                            ]
                        })(<Input.TextArea style={{ height: 300, width: '100%' }} placeholder={strings.СOMMENT} />)}
                    </MyFormItem>
                    <MyFormItem label={strings.FINAL_MARK}>
                        {getFieldDecorator('lessonFinalMark', {
                            initialValue: lessonFinalMark,
                            rules: [{ required: false }]
                        })(
                            <Radio.Group>
                                <Radio.Button value="ni">Ni</Radio.Button>
                                <Radio.Button value="s">S</Radio.Button>
                                <Radio.Button value="g">G</Radio.Button>
                            </Radio.Group>
                        )}
                        <Button size="middle" style={{ marginInlineStart: 10 }} onClick={() => clearFieldByName('lessonFinalMark')}>
                            {strings.CLEAR}
                        </Button>
                    </MyFormItem>
                </Col>
            </Row>
        </>
    );
};

export default { LessonPrimaryFields };
