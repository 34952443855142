import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select, Col, Row, DatePicker, Spin, Tag } from 'antd';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { isEmpty, pickBy, debounce } from 'lodash';
import { Branch } from 'types';
import { ManualInputFilterParams } from 'types/finance';
import { manualInputPaymentTypes, operationPaymentTypes } from 'Global/constants';
import { dateFormat } from 'helpers/dates';
import dayjs from 'dayjs';
import { TutorSelect } from 'Global/components/FormComponentsCompatible/Selects/TutorSelect';
import { useUserContext } from 'contexts/UserContext';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import { Participant } from 'types/Schedule';
import { FilterButtons } from 'Global/components/FilterButtons';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { momentFormatValues } from 'helpers/momentFormatValues';

const { Option } = Select;

interface FilterProps {
  form: WrappedFormUtils;
  getManualInputList: (params?: ManualInputFilterParams) => Promise<any>;
}

/**
 * @description Filter Manual input table
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const FilterTemplate = (props: FilterProps) => {
  const [strings] = useLanguageContext();
  const [hasLoadUsers, setHasLoadUsers] = useState<boolean>(false);
  const [users, setUsers] = useState<Participant[]>([]);
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);
  const { locationSearchParams } = useLocationSearchParams();
  const {
    form: { getFieldDecorator, validateFieldsAndScroll, resetFields },
    getManualInputList
  } = props;

  const [user] = useUserContext();
  const { branches: branchesValues } = useGlobalCollectionsContext();
  const globalRequestsRepository = useGlobalRequestsRepository();

  const hasBranchDirector = user?.hasRoles?.branchDirector;
  const { dateFrom, dateTo, userType, type, code, branches } = locationSearchParams || '';

  const [searchValue, setSearchValue] = useState<string>('');
  const [typing, setTyping] = useState<Boolean>(false);

  /**
   * @description Form Submit
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values) => {
      if (isEmpty(errors)) {
        const filterParams = pickBy(momentFormatValues(values));
        try {
          setHasSubmitLoading(true);
          await getManualInputList(filterParams);
        } finally {
          setHasSubmitLoading(false);
        }
      }
    });
  };

  /**
   * @description Очистка фильтра
   * @return {void}
   */
  const handleClear = async (): Promise<any> => {
    resetFields();
    await getManualInputList();
  };

  /**
   * @description Поиск по родителям или учителям
   * @param {string} userName
   * @return {Promise<any>}
   */
  const fetchUsers = async (userName: string): Promise<any> => {
    setSearchValue(userName);
    if (userName) {
      setHasLoadUsers(true);
      try {
        const {
          data: { teachers, parents }
        } = await globalRequestsRepository.search(userName);
        const parentUsers = parents
          ?.filter(item => item?.user?.name || item?.user?.surname)
          ?.map(item => ({ ...item, type: 'parent' }));
        const teacherUsers = teachers
          ?.filter(item => item?.user?.name || item?.user?.surname)
          ?.map(item => ({ ...item, type: 'teacher' }));
        const users = [...parentUsers, ...teacherUsers];
        setUsers(users);
      } catch {
      } finally {
        setHasLoadUsers(true);
      }
    }
  };

  useEffect(() => {
    if (searchValue) {
      setTyping(true);
    } else {
      setTyping(false);
    }
  }, [searchValue]);

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={[10, 10]}>
        <Col lg={6} xs={24}>
          <TutorSelect
            name="admin"
            placeholder={strings.SELECT_ADMIN}
            getFieldDecorator={getFieldDecorator}
            style={{ margin: 0 }}
          />
        </Col>
        <Col lg={6} xs={24}>
          {getFieldDecorator('dateFrom', {
            initialValue: dateFrom && dayjs(dateFrom)
          })(<DatePicker format={dateFormat} placeholder={strings.FROM} />)}
        </Col>
        <Col lg={6} xs={24}>
          {getFieldDecorator('dateTo', {
            initialValue: dateTo && dayjs(dateTo)
          })(<DatePicker format={dateFormat} placeholder={strings.TILL} />)}
        </Col>
        <Col lg={6} xs={24}>
          {getFieldDecorator('userType', {
            initialValue: userType && userType
          })(
            <Select placeholder={strings.USER_TYPE} allowClear>
              <Option value="family">Family</Option>
              <Option value="teacher">Teacher</Option>
            </Select>
          )}
        </Col>
        <Col lg={6} xs={24}>
          {getFieldDecorator('type', {
            initialValue: type && Number(type)
          })(
            <Select placeholder={strings.OPERATION_TYPE} allowClear>
              {operationPaymentTypes.map(type => (
                <Option key={type.value} value={type.value}>
                  {type.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col lg={6} xs={24}>
          {getFieldDecorator('userId')(
            <Select
              showSearch
              allowClear
              placeholder={strings.USER}
              defaultActiveFirstOption={false}
              filterOption={false}
              onSearch={debounce(fetchUsers, 800)}
              style={{ width: '100%' }}
              notFoundContent={
                hasLoadUsers ? <Spin size="small" /> : <p>{!typing ? strings.START_TYPING : strings.NOT_FOUND}</p>
              }
            >
              {users.map((item: Participant) => (
                <Option value={String(item.id)} key={item.id}>
                  {item?.user?.name} {item?.user?.surname}{' '}
                  <Tag color={item.type === 'parent' ? 'green' : 'blue'}>{item.type}</Tag>
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col lg={6} xs={24}>
          {getFieldDecorator('code', {
            initialValue: code
          })(
            <Select placeholder={strings.PAYMENT_TYPE} allowClear>
              {manualInputPaymentTypes.map(type => (
                <Option key={type.value} value={type.value}>
                  {type.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col lg={6} xs={24}>
          {getFieldDecorator(
            'branches',
            hasBranchDirector
              ? {
                  rules: [{ required: false, type: 'array' }],
                  initialValue: [user?.branch?.id]
                }
              : {
                  initialValue: branches?.map(branch => Number(branch))
                }
          )(
            <Select
              allowClear
              size="large"
              placeholder={strings.BRANCH}
              mode="multiple"
              maxTagCount={1}
              disabled={hasBranchDirector}
            >
              {branchesValues.map((branch: Branch) => (
                <Option value={branch.id} key={branch.id}>
                  {branch.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
      </Row>
      <FilterButtons handleClear={handleClear} hasSubmitLoading={hasSubmitLoading} />
    </Form>
  );
};

export const Filter = Form.create<FilterProps>({})(FilterTemplate);
export default { Filter };
