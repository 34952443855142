import React, { useState } from 'react';
import { message, Modal, Input, Button, Row, Col, Form, Flex } from 'antd';
import { Courses } from 'api/Courses';
import { useLanguageContext } from 'contexts/LanguageContext';
import { ILessonTask } from 'types/lesson';
import { useLessonRegisterStore } from 'stores/useLessonRegisterStore';

interface AddTestModalProps {
  hasShowTestModal: boolean;
  setHasShowTestModal: (hasShowTestModal: boolean) => void;
  lessonId: number | string;
}

export const AddTestModal = ({ hasShowTestModal, setHasShowTestModal, lessonId }: AddTestModalProps) => {
  const [strings] = useLanguageContext();
  const [form] = Form.useForm();

  const setHasRegistersFetch = useLessonRegisterStore(state => state.setHasRegistersFetch);
  const task = useLessonRegisterStore(state => state.extraRegisterData?.task);

  const [hasLoadCreationTest, setHasLoadCreationTest] = useState<boolean>(false);

  /**
   * @description Добавление задачи
   */
  const addLessonTask = async (lessonId: number | string, data: ILessonTask): Promise<any> => {
    setHasLoadCreationTest(true);
    try {
      await Courses.addLessonTask(lessonId, data);
      message.success(strings.ADDED_TEST_SUCCESS);
      setHasRegistersFetch(true);
      setHasShowTestModal(false);
    } catch {
      message.error(strings.ADDING_TEST_ERROR);
    } finally {
      setHasLoadCreationTest(false);
    }
  };

  const handleSubmit = async (values: ILessonTask): Promise<any> => {
    addLessonTask(lessonId, values);
  };

  return (
    <Modal
      width={500}
      title={strings.ADD_TEST}
      open={hasShowTestModal}
      footer={false}
      onCancel={() => setHasShowTestModal(false)}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={10}>
          <Col lg={14} xs={24}>
            <Form.Item
              name="theme"
              initialValue={task?.theme}
              label={strings.THEME}
              rules={[{ required: true, message: strings.INPUT_THEME }]}
            >
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col lg={10} xs={24}>
            <Form.Item
              name="maxGrade"
              label={strings.MAX_TEST_GRADE}
              initialValue={task?.maxGrade}
              rules={[{ required: true, message: strings.INPUT_GRADE }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Flex gap={5} justify="end">
          <Button htmlType="submit" onClick={() => setHasShowTestModal(false)} key="cancel">
            {strings.CANCEL}
          </Button>
          <Button type="primary" htmlType="submit" loading={hasLoadCreationTest} key="saveBtn">
            {strings.ADD_TEST}
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};
