import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Row, Col } from 'antd';
import styled from 'styled-components';
import { enBreadcrumbNameMap, ruBreadcrumbNameMap } from './breadcrumbNameMap';
import { AVAILABLE_LANGS, useLanguageContext } from 'contexts/LanguageContext';
import { EN_KEY, RU_KEY } from '../../../Admin/Translations';

const StBreadCrumbs = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  padding-inline-start: 0;
  background: none;
  height: 45px;
  border-radius: 5px;
`;

/**
 * @description Формирование breadcrumbs
 * @param {object} location
 * @return {React.ReactNode | null}
 */
export default ({ location }: any): React.ReactElement | null => {
  const [strings, lang] = useLanguageContext();
  const pathSnippets = location.pathname.split('/').filter((i: any) => i);
  const extraBreadcrumbItems = [];
  pathSnippets
    .map((_: string, index: number) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
      const breadcrumbUrl = url.replace(/\/\d+/g, '/:id');
      if (enBreadcrumbNameMap[breadcrumbUrl]) {
        extraBreadcrumbItems.push(
          <Breadcrumb.Item key={url}>
            <Link to={url}>
              {lang === EN_KEY
                ? enBreadcrumbNameMap[breadcrumbUrl]
                : lang === RU_KEY
                ? ruBreadcrumbNameMap[breadcrumbUrl]
                : null}
            </Link>
          </Breadcrumb.Item>
        );
        return enBreadcrumbNameMap[breadcrumbUrl];
      }
      return null;
    })
    .filter(breadcrumb => breadcrumb !== null);

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">{strings.HOME}</Link>
    </Breadcrumb.Item>
  ].concat(extraBreadcrumbItems);

  return (
    <Row>
      <Col lg={24}>
        <StBreadCrumbs>
          <Breadcrumb>{breadcrumbItems}</Breadcrumb>
        </StBreadCrumbs>
      </Col>
    </Row>
  );
};
