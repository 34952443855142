import { httpService } from 'api/core';

/**
 * @description Получение discounts
 * @param {number} id
 * @return {Promise<any>}
 */
const getFamilyDiscounts = (id: number): Promise<any> => httpService.get(`/api/family/${id}/discounts`);

/**
 * @description Создание discount
 * @param {number} id
 * @param {object} params
 * @return {Promise<any>}
 */
const createFamilyDiscount = (id: number, params: any): Promise<any> =>
  httpService.post(`/api/family/${id}/discounts`, params);

/**
 * @description Редактирование discount
 * @param {number} id
 * @param {object} params
 * @return {Promise<any>}
 */
const editFamilyDiscountById = (id: number, params: any): Promise<any> =>
  httpService.put(`/api/discounts/${id}`, params);

/**
 * @description Удаление discount
 * @param {number} id
 * @return {Promise<any>}
 */
const removeFamilyDiscountById = (id: number): Promise<any> => httpService.delete(`/api/discounts/${id}`);

export default { getFamilyDiscounts, createFamilyDiscount, editFamilyDiscountById, removeFamilyDiscountById };
