import React, { useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { Button, Descriptions, message, Modal, Row, Spin, Table, Tag } from 'antd';
import { Container } from 'Global/GlobalStyle';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { match } from 'react-router';
import { useLanguageContext } from 'contexts/LanguageContext';
import { BASE_CLASSROOMS } from '../routes';
import { ClassroomItem, ClassroomReserve } from 'types/education';
import { useClassroomsRepository } from 'repos/ClassroomsRepository';
import { getReservedColumns } from './columns';
import { ModalReservedForm } from './ModalReservedForm';

export const ClassroomView = () => {
  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();
  const { goBack, push } = useHistory();
  const [strings] = useLanguageContext();
  const classroomsRepository = useClassroomsRepository();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [classroom, setClassroom] = useState<ClassroomItem>({});
  const [selectedReserve, setSelectedReserve] = useState<ClassroomReserve>(null);
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const { name, color, comment, placesCount, webLink, webLogin, webPasscode, webPassword, branch, reserve } = classroom;

  const [{ confirm }, contextHolder] = Modal.useModal();

  const fetchClassroom = async (): Promise<void> => {
    setHasLoading(true);
    try {
      const { data } = await classroomsRepository.getClassroomById(id);
      setClassroom(data);
    } catch {
      message.error(strings.SORRY_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN);
    } finally {
      setHasLoading(false);
    }
  };

  const handleDeleteReserve = async (id): Promise<any> => {
    confirm({
      title: strings.ARE_YOU_SURE,
      okText: 'Delete',
      okType: 'danger',
      async onOk() {
        setHasLoading(true);
        try {
          await classroomsRepository.deleteClassroomReserve(id);
          message.success(strings.SUCCESSFULLY_DELETED);
          fetchClassroom();
        } catch (error) {
          message.error(error?.data?.message || strings.UNEXPECTED_ERROR);
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  const getActionColumns = () => [
    {
      title: '',
      dataIndex: 'id',
      key: 'action',
      render: (id: number) => (
        <Row align="middle" style={{ gap: 5, flexWrap: 'nowrap' }}>
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              const reserveItem = reserve.find(item => item.id === id);
              setSelectedReserve(reserveItem);
              setHasShowModal(true);
            }}
          />
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
              handleDeleteReserve(id);
            }}
          />
        </Row>
      )
    }
  ];

  useEffect(() => {
    if (id) {
      fetchClassroom();
    }
  }, [id]);

  return (
    <Container>
      {contextHolder}
      <ModalReservedForm
        setHasShowModal={setHasShowModal}
        hasShowModal={hasShowModal}
        reservedItem={selectedReserve}
        fetchClassroom={fetchClassroom}
      />
      <Spin spinning={hasLoading} tip={strings.LOADING}>
        <PageHeader
          style={{ padding: 0, marginBottom: 20 }}
          onBack={goBack}
          title={`${strings.CLASSROOM} ${name}`}
          extra={
            <Button onClick={() => push(`${BASE_CLASSROOMS}/${id}/edit`)}>
              {`${strings.EDIT} ${strings.CLASSROOM}`}
            </Button>
          }
        >
          <Descriptions>
            <Descriptions.Item label={strings.BRANCH}>{branch?.name}</Descriptions.Item>
            <Descriptions.Item label={strings.NUMBER_OF_SEATS}>{placesCount}</Descriptions.Item>
            <Descriptions.Item label={strings.COLOR}>
              <Tag color={color}>{color}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label={strings.ZOOM_LOGIN}>{webLogin ? webLogin : '-'}</Descriptions.Item>
            <Descriptions.Item label={strings.ZOOM_WEB_PASSCODE}>{webPasscode ? webPasscode : '-'}</Descriptions.Item>
            <Descriptions.Item label={strings.ZOOM_PASSWORD}>{webPassword ? webPassword : '-'}</Descriptions.Item>
            <Descriptions.Item label={strings.ZOOM_LINK}>{webLink ? webLink : '-'}</Descriptions.Item>
            <Descriptions.Item label={strings.COMMENT}>{comment ? comment : '-'}</Descriptions.Item>
          </Descriptions>
        </PageHeader>
        <PageHeader
          style={{ padding: 0, marginBottom: 20 }}
          title={strings.RESERVED}
          extra={
            <Button
              type="primary"
              onClick={() => {
                setSelectedReserve(null);
                setHasShowModal(true);
              }}
            >
              {strings.NEW_RESERVE}
            </Button>
          }
        >
          <Table
            dataSource={reserve}
            columns={[...getReservedColumns(strings), ...getActionColumns()]}
            pagination={false}
            loading={{ spinning: hasLoading }}
          />
        </PageHeader>
      </Spin>
    </Container>
  );
};

export default { ClassroomView };
