import React, { useEffect, useState } from 'react';
import { Modal, message } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { IOutcomesByOriginalLesson } from 'types/courses';
import { useCoursesRepository } from 'repos/CoursesRepository';
import MyJoditEditor from 'Global/components/MyJoditEditor';

interface ModalEditSubjectGoalProps {
  hasShowModal: boolean;
  setHasShowModal: (bool: boolean) => void;
  subjectGoal: IOutcomesByOriginalLesson;
  refetchGoals: () => Promise<any>;
}

export const ModalEditSubjectGoal = ({
  hasShowModal,
  setHasShowModal,
  subjectGoal,
  refetchGoals
}: ModalEditSubjectGoalProps) => {
  const [description, setDescription] = useState<string>(subjectGoal?.description);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [strings] = useLanguageContext();
  const coursesRepository = useCoursesRepository();

  const handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    try {
      setHasLoading(true);
      await coursesRepository.editOutcomesById(subjectGoal.id, { description });
      refetchGoals();
      message.success(`${strings.SUCCESSFULLY_CHANGED} :)`, 2);
      setHasShowModal(false);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    hasShowModal && setDescription(subjectGoal?.description);
  }, [hasShowModal]);

  return (
    <Modal
      title={strings.EDIT}
      open={hasShowModal}
      onCancel={() => setHasShowModal(false)}
      okText={strings.SAVE}
      okButtonProps={{ disabled: description.length === 0, loading: hasLoading }}
      onOk={handleSubmit}
      destroyOnClose={true}
    >
      <MyJoditEditor key="description-editor" value={description} onChange={value => setDescription(value)} />
    </Modal>
  );
};

export default { ModalEditSubjectGoal };
