import React from 'react';
// import summerChameleon from 'img/summerChameleon.svg';
//весенний фон у хамелеона
// import { SPRING_LOADER } from 'img/bg-bee';
//сентябрьский фон у хамелеона
import loading from 'img/bg-loading.svg';
//новогодний фон у хамелеона
import bgNg from 'img/bg-NG.svg';
import { CHAMELEON_NG } from 'img/chameleonNg';
import chameleon from 'img/chameleon.svg';
import styled from 'styled-components';

export function Chameleon() {
  return (
    <svg
      className="chameleon"
      width="20"
      height="20"
      viewBox="0 0 187 189"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M81.3452 3.36548V106.346H183.527C183.661 49.5386 137.846 3.36548 81.3452 3.36548Z"
        fill="#E6556F"
        stroke="#C4C4C4"
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <path
        d="M119.68 96.1156C135.836 96.1156 148.932 82.9166 148.932 66.6348C148.932 50.353 135.836 37.1541 119.68 37.1541C103.525 37.1541 90.428 50.353 90.428 66.6348C90.428 82.9166 103.525 96.1156 119.68 96.1156Z"
        fill="white"
      />
      <path
        d="M81.2116 177.019C97.3671 177.019 110.464 163.82 110.464 147.539C110.464 131.257 97.3671 118.058 81.2116 118.058C65.0561 118.058 51.9595 131.257 51.9595 147.539C51.9595 163.82 65.0561 177.019 81.2116 177.019Z"
        fill="#E6556F"
        stroke="#C4C4C4"
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <path
        d="M130.766 79.0192C135.045 79.0192 138.514 75.5236 138.514 71.2115C138.514 66.8994 135.045 63.4038 130.766 63.4038C126.488 63.4038 123.019 66.8994 123.019 71.2115C123.019 75.5236 126.488 79.0192 130.766 79.0192Z"
        fill="#E6556F"
      />
      <path
        d="M3.33936 106.346C3.33936 62.7309 38.2015 27.4617 81.3451 27.0579V106.481L3.33936 106.346Z"
        fill="#E6556F"
        stroke="#C4C4C4"
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <path
        d="M42.0751 146.192C42.0751 124.385 59.5729 106.75 81.2115 106.75H81.3451V106.346H3.33936C3.33936 147.942 35.2629 182 75.8686 185.231C56.7679 182.673 42.0751 166.115 42.0751 146.192Z"
        fill="#E6556F"
        stroke="#C4C4C4"
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <path
        d="M81.3452 108.096H81.2116C59.5731 108.096 42.0752 125.731 42.0752 147.538C42.0752 167.462 56.7681 184.019 75.8688 186.577C77.6052 186.846 79.3416 186.981 81.2116 186.981C102.85 186.981 120.348 169.346 120.348 147.538C120.348 125.865 102.984 108.231 81.3452 108.096ZM81.2116 177.019C65.0495 177.019 51.9595 163.827 51.9595 147.538C51.9595 131.25 65.0495 118.058 81.2116 118.058C97.3738 118.058 110.464 131.25 110.464 147.538C110.464 163.827 97.3738 177.019 81.2116 177.019Z"
        fill="white"
        stroke="#C4C4C4"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M81.2114 163.692C90.0638 163.692 97.24 156.46 97.24 147.539C97.24 138.617 90.0638 131.385 81.2114 131.385C72.3591 131.385 65.1829 138.617 65.1829 147.539C65.1829 156.46 72.3591 163.692 81.2114 163.692Z"
        fill="white"
      />
    </svg>
  );
}

const StImgBackground = styled.img`
  max-width: 140px;
  animation: rotation 7s linear infinite;
  position: absolute;
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes pulsing {
    0% {
      -webkit-transform: scale(0.7, 0.7);
      transform: scale(0.7, 0.7);
    }
    50% {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
    100% {
      -webkit-transform: scale(0.7, 0.7);
      transform: scale(0.7, 0.7);
    }
  }
  @keyframes pulse {
    from {
      box-shadow: 0 0 0 0 #e5556f;
    }
    to {
      box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
  }
`;

// export function SummerChameleon() {
//   return <img style={{ maxWidth: 70 }} src={summerChameleon} />;
// }

export function AnimateChameleon() {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <StImgBackground src={loading} />
      <img style={{ width: 120 }} src={chameleon} />
      {/*<img style={{ width: 80 }} src={CHAMELEON_NG} />*/}
    </div>
  );
}
