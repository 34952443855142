import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { DeleteOutlined, DeleteTwoTone } from '@ant-design/icons';
import { Tag, Modal, message, Flex } from 'antd';

import { TeacherHistoryStatusChanged } from 'types/Teachers/History';
import { Teachers } from 'api/Teachers';
import { dateFormat } from 'helpers/dates';
import { Admin } from 'types';
import { useLanguageContext } from 'contexts/LanguageContext';

const SmsItem = styled.div`
  box-shadow: 0 5px 15px rgba(143, 168, 191, 0.2);
  padding: 18px;
  border-radius: 5px;
  margin: 15px 15px 15px 0px;
  min-height: 180px;

  h5 {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    font-weight: 500;
    align-items: center;
  }

  p {
    font-size: 16px;
  }
`;

export interface StatusChangedProps extends TeacherHistoryStatusChanged {
  teacherId: number;
  description: string;
  reloadHistory: (dateStart?: string, dateEnd?: string) => Promise<any>;
  admin: Admin;
}

/**
 * @description Компонент StatusChanged
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const StatusChanged = (props: StatusChangedProps) => {
  const [strings] = useLanguageContext();
  const { date, title, id, description, teacherId, reloadHistory, admin } = props;
  const [{ confirm }, contextHolder] = Modal.useModal();

  const confirmDeleteContact = (): void => {
    confirm({
      title: strings.DO_YOU_WANT_DELETE_CONTACT,
      async onOk() {
        await Teachers.deleteTeacherContact(teacherId, id).then(() => {
          setTimeout(() => {
            message.success('contact deleted successfully :)');
            reloadHistory();
          }, 2000);
        });
      },
      onCancel() {},
      okText: strings.DELETE_CONTACT
    });
  };

  return (
    <SmsItem>
      {contextHolder}
      <Flex justify="space-between" style={{ marginBottom: 10 }}>
        <p>
          <Tag color="blue">{strings.STATUS_CHANGED}</Tag>
          <span style={{ marginInlineStart: 10 }}>{dayjs(date).format(dateFormat)}</span>
        </p>
        <DeleteTwoTone twoToneColor="red" onClick={confirmDeleteContact} />
      </Flex>
      <p style={{ fontWeight: 300 }}>{title}</p>
      <span>{strings.COMMENT}: </span>
      <div dangerouslySetInnerHTML={{ __html: description }} />
      <span style={{ opacity: '.7' }}>
        {strings.ADMIN}: {admin?.user?.name} {admin?.user?.surname}
      </span>
    </SmsItem>
  );
};

export default { StatusChanged };
