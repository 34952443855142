import styled from 'styled-components';
import { Teacher, MainBlock } from '../styles';

export const VideoNews = styled(Teacher)``;
export const VideoNewsInfo = styled(MainBlock)`
  padding: 15px;
`;
export const NewsItemWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 1rem;
    border-bottom: 1px solid #ddd;
  }
`;

export default {
  VideoNews,
  VideoNewsInfo,
  NewsItemWrapper
};
