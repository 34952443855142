import { Tag } from 'antd';
import dayjs from 'dayjs';
import { dateFormat } from 'helpers/dates';
import React from 'react';

export const columnsPending = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: email => <a href={`mailto:${email}`}>{email}</a>
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment'
  },
  {
    title: 'Text',
    dataIndex: 'text',
    key: 'text',
    width: 650
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: status => <Tag color="green">{status}</Tag>
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'date',
    render: date => date && dayjs(date).format(dateFormat)
  }
];

export const columnsSent = [
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date'
  }
];

export default { columnsPending, columnsSent };
