import React, { useEffect, useState } from 'react';
import { Row, Col, Select, DatePicker, Form, Checkbox } from 'antd';
import { Subject } from 'types';
import { pickBy, identity } from 'lodash';
import { useParentJournalRepository } from 'repos/ParentJournalRepository';
import { IHomeworkFilterParams } from './index';
import { dateFormat } from 'helpers';
import { momentFormatValues } from 'helpers/momentFormatValues';
import { useLanguageContext } from 'contexts/LanguageContext';
import { FilterButtons } from 'Global/components/FilterButtons';
import { homeworkStatuses } from 'Global/constants';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { getSubjectNameByLang } from '../helpers';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { FormItemWithoutMargin } from '../../Global/styles';
const { Option } = Select;

interface HwFilterProps {
  studentId: number;
  defFilterValues: IHomeworkFilterParams;
  filterParams: IHomeworkFilterParams;
  setFilterParams: (params: IHomeworkFilterParams) => void;
}

/**
 * @description Фильтр для hw
 * @param {FilterProps} props
 * @returns {React.ReactNode}
 */
export const Filter = ({ studentId, filterParams, setFilterParams, defFilterValues }: HwFilterProps) => {
  const [form] = Form.useForm();

  const { push } = useHistory();
  const { startAt, endAt } = filterParams;
  const parentJournalRepository = useParentJournalRepository();
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [strings, lang] = useLanguageContext();
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);
  const { subjectsTranslation } = useGlobalCollectionsContext();

  /**
   * @description Submit формы
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = async (values): Promise<any> => {
    const params = momentFormatValues(values);
    try {
      setHasSubmitLoading(true);
      setFilterParams(params);
      push({ search: '' });
    } catch {
    } finally {
      setHasSubmitLoading(false);
    }
  };

  /**
   * @description Очистка фильтра
   * @return {Promise<any>}
   */
  const handleClear = async (): Promise<any> => {
    push({ search: '' });
    setFilterParams(defFilterValues);
    form.setFieldsValue(defFilterValues);
  };

  /**
   * @description Получение предметов студента
   * @return {Promise<any>}
   */
  const getSubjectsByStudentId = async (): Promise<any> => {
    try {
      const { data } = await parentJournalRepository.getSubjects(studentId);
      setSubjects(data);
    } catch {
      setSubjects([]);
    }
  };

  useEffect(() => {
    if (studentId) {
      getSubjectsByStudentId();
    }
  }, [studentId]);

  const initValues = { ...filterParams, startAt: startAt && dayjs(startAt), endAt: endAt && dayjs(endAt) };

  return (
    <Form layout="vertical" form={form} onFinish={handleSubmit} initialValues={initValues}>
      <Row gutter={[10, 10]} align="middle">
        <Col lg={4} xs={12}>
          <FormItemWithoutMargin name="subject">
            <Select placeholder={strings.SUBJECT} allowClear>
              {subjects.map((subject: Subject) => (
                <Option key={subject.id} value={subject.id}>
                  {getSubjectNameByLang(subjectsTranslation, lang, subject.id)}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={3} xs={12}>
          <FormItemWithoutMargin name="startAt">
            <DatePicker format={dateFormat} placeholder={strings.START} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={3} xs={12}>
          <FormItemWithoutMargin name="endAt">
            <DatePicker format={dateFormat} placeholder={strings.END} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={4} xs={12}>
          <FormItemWithoutMargin name="status">
            <Select placeholder={strings.STATUS} allowClear>
              {homeworkStatuses.map(item => (
                <Option key={item.name} value={item.name}>
                  {item.titleByLang[lang] || item.title}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col>
          <FormItemWithoutMargin name="withHomeworkAssigned" valuePropName="checked">
            <Checkbox>{strings.HOMEWORK_ASSIGNED}</Checkbox>
          </FormItemWithoutMargin>
        </Col>
        <FilterButtons handleClear={handleClear} hasSubmitLoading={hasSubmitLoading} isSameRow />
      </Row>
    </Form>
  );
};
export default { Filter };
