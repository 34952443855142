import { Col, Collapse, Select } from 'antd';
import styled, { css } from 'styled-components';
import { slideInLeft } from 'Global/animations';

export const ColPeriod = styled(Col)`
  @media screen and (max-width: 1100px) {
    order: 2;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const ColControl = styled(Col)`
  display: flex;
  justify-content: space-between;
  .nav-container {
    display: flex;
    @media (min-width: 320px) {
      width: 100%;
      justify-content: space-between;
      margin-top: 20px;
    }

    @media (min-width: 768px) {
      width: 250px;
      margin-bottom: 10px;
      margin-top: 0;
      justify-content: flex-end;
    }
  }
  .ant-btn {
    margin-inline-end: 10px;
  }
  @media screen and (max-width: 860px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

export const StyledEventParagraph = styled.p`
  margin: 0;
  color: #000;
  font-size: 12px;
  font-weight: 500;
`;

export const StyledEvent = styled.div<{ hasBorder?: boolean }>`
  height: 100%;
  border: ${props => (props.hasBorder ? '2px solid #1890ff' : 'none')};
  position: relative;
  font-size: 13px;
  padding: 3px 3px 0;

  .ant-typography {
    color: black;
    margin-bottom: 0;
  }

  @media only screen and (min-width: 1024px) {
    white-space: nowrap;
  }
`;

export const Schedule = styled.div`
  animation: ${slideInLeft} 0.6s ease-in;

  @media (min-width: 320px) {
    padding: 0 10px;
  }

  @media (min-width: 768px) {
    padding: 30px;
  }

  .ant-legacy-form-item {
    margin: 0;
  }

  .rbc-event {
    padding: 0;
    //z-index: 99999;
  }

  .rbc-timeslot-group {
    min-height: 90px;
  }

  .rbc-event-label {
    color: #000;
    display: none;
  }

  .rbc-events-container {
    display: flex;
    align-items: center;
    z-index: 1;
    margin-inline-end: 5px;
  }

  .rbc-day-slot .rbc-event {
    position: absolute;
  }

  .rbc-current-time-indicator {
    width: 0;
    height: auto;
    border: 10px solid transparent;
    border-left-color: rgba(255, 0, 0, 0.95);
    background-color: transparent;
    opacity: 0.75;
    transform: translateY(-50%);
  }

  .fc-button-primary {
    background: #1890ff;
    border: 1px solid #1890ff;
  }

  .fc-day-header {
    padding: 10px 0;
    border: 1px solid #e8e8e8;
    background: #fafafa;
  }

  .ant-select {
    width: 100%;
  }
`;

export const LessonInfo = styled.div`
  .ant-calendar {
    max-width: 460px;
    width: auto;
  }
  .ant-time-picker-icon,
  .ant-time-picker-clear {
    top: 40%;
  }
  .ant-time-picker {
    width: 100%;
  }
  .ant-col {
    p: 0 5px;
  }

  @media only screen and (max-width: 768px) {
  }
`;

export const CreateLesson = styled(LessonInfo)``;
export const ShowLesson = styled(Schedule)``;

export const LessonDescription = styled.div`
  @media (min-width: 320px) {
    border-left: 0;
    padding: 0;
    margin: 40px 0;
  }

  @media (min-width: 960px) {
    border-left: 1px solid #dadada;
    padding: 0 35px;
    margin: 0 15px;
  }
`;

export const ModalDesc = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ModalDescItem = styled.div<{ nopad?: boolean }>`
  flex: 0 0 100%;
  box-sizing: border-box;
  font-size: 15px;
  ${props =>
    !props.nopad &&
    css`
      margin-bottom: 20px;
      border-bottom: 1px solid #ddd;
      padding-bottom: 10px;
    `}
`;

export const ModalDescTitle = styled.div`
  color: #a0aec0;
  text-transform: uppercase;
  font-size: 13px;
`;

export const ModalDescValue = styled.div`
  color: #1a202c;
`;

export const ZoomContainer = styled.div<{ nopad?: boolean }>`
  display: flex;
  margin: ${props => (props.nopad ? '0 -20px' : '0')};
`;

export const ZoomItem = styled.div`
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  padding: 0 20px;
`;

export const ZoomLabel = styled.div`
  margin-bottom: 6px;
  color: #000;
`;

export const CalendarLoaded = styled.div<{ hasLoadEvents: boolean }>`
  width: 100%;
  height: 100vh;
  .rbc-time-view {
    opacity: ${props => (props.hasLoadEvents ? '0.2' : '1')};
  }
  .rbc-allday-cell {
    display: none;
  }
  .rbc-header {
    border-bottom: none;
  }
`;

export const combinedFieldsSt = {
  backgroundColor: '#fafafa',
  padding: 10,
  borderRadius: 5,
  marginBottom: 20,
  gap: 10
};

export const StSlotCollapse = styled(Collapse)`
  .ant-collapse-header {
    padding-inline-start: 0 !important;
  }
`;

export default {
  CalendarLoaded,
  Schedule,
  LessonInfo,
  CreateLesson,
  ShowLesson,
  LessonDescription,
  Event,
  ModalDesc,
  ModalDescItem,
  ModalDescTitle,
  ModalDescValue,
  ZoomContainer,
  ZoomItem,
  ZoomLabel,
  StSlotCollapse
};
