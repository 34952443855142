import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { message, Row } from 'antd';
import { useAssistantFeedbackRepository } from 'repos/AssistantsFeedbackRepository';
import { AssistantFeedbackFilter } from './AssistantFeedbackFilter';
import { useHistory } from 'react-router-dom';
import { AssistantCommentItem, GetAssistantsCommentsParams } from './types';
import { useLanguageContext } from 'contexts/LanguageContext';
import { dateFormatForBackend, scrollTop } from 'helpers';
import { isEmpty } from 'lodash';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { StyledTable } from './styles';
import { columns } from './columns';
import Pagination from 'Global/components/Pagination';
import { useUserContext } from 'contexts/UserContext';
import { PaginationInfo } from 'types/global';
import dayjs from 'dayjs';
import { momentFormatValues } from '../../../helpers/momentFormatValues';

export function AssistantFeedback() {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const assistantFeedbackRepo = useAssistantFeedbackRepository();
  const [assistantComments, setAssistantComments] = useState<AssistantCommentItem[]>([]);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>(null);
  const { goBack, push } = useHistory();
  const { locationSearchParams } = useLocationSearchParams();
  const defFilterValues = {
    branch: user?.branch.id,
    dateStart: dayjs().subtract(1, 'month'),
    dateEnd: dayjs(),
    ...(user?.isAssistant && { showAllAssistants: 0 })
  };

  const getAssistantComments = async (values?: GetAssistantsCommentsParams): Promise<any> => {
    const params = momentFormatValues(values);
    setHasLoading(true);
    try {
      const {
        data: { items, paginationInfo }
      } = await assistantFeedbackRepo.getAssistantComments(params || null);
      setAssistantComments(items);
      setPaginationInfo(paginationInfo);
      push({ search: queryString.stringify(params) });
    } catch (e) {
      message.error(e.message);
    } finally {
      setHasLoading(false);
    }
  };

  const handleChangePage = async (page: number) => {
    await getAssistantComments({ ...locationSearchParams, page });
    scrollTop();
  };

  useEffect(() => {
    if (isEmpty(locationSearchParams)) {
      getAssistantComments(defFilterValues);
    } else {
      getAssistantComments(locationSearchParams);
    }
  }, []);

  return (
    <Container>
      <StPageHeader onBack={goBack} title={strings.ASSISTANTS_FEEDBACK}>
        <AssistantFeedbackFilter getAssistantComments={getAssistantComments} defFilterValues={defFilterValues} />
      </StPageHeader>
      <Row gutter={[10, 10]}>
        <StyledTable
          loading={hasLoading}
          bordered={false}
          columns={columns(strings).filter(col => col)}
          pagination={false}
          dataSource={assistantComments}
          rowKey="id"
        />
        {paginationInfo && (
          <Pagination
            count={paginationInfo?.pageCount}
            current={paginationInfo?.current}
            pageChange={handleChangePage}
          />
        )}
      </Row>
    </Container>
  );
}
