import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import { Button, Table, Spin, message, Row } from 'antd';
import { getColumns } from './columns';
import { BASE_TEACHING_MATERIALS } from '../routes';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { Filter } from './Filter';
import { CRM } from 'api/CRM';
import { GetInventoriesParams, Inventory } from 'types/CRM/materials';
import BaseFilter from 'helpers/BaseFilter';
import { first } from 'lodash';
import { useUserContext } from 'contexts/UserContext';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';

interface teachingMaterialsProps {
  form: WrappedFormUtils;
}

export const TeachingMaterials = Form.create({})((props: teachingMaterialsProps) => {
  const [strings] = useLanguageContext();
  const { push, goBack } = useHistory();
  const [currentFilter, setCurrentFilter] = useState<GetInventoriesParams>({});
  const [inventories, setInventories] = useState<Inventory[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { branches } = useGlobalCollectionsContext();
  const defFilterValues: GetInventoriesParams = { active: 0, deficit: false };
  const FilterService = new BaseFilter();
  const [user] = useUserContext();
  const hasTopManager = user?.hasRoles?.topManager;
  const hasBranchDirector = user?.hasRoles?.branchDirector;
  const hasActionsAccess = hasTopManager || hasBranchDirector || user?.access?.inventory?.edit;

  /**
   * @description Получить инвентари
   * @param {GetInventoriesParams} filter
   * @returns {Promise<void>}
   */
  const fetchInventories = async (params: GetInventoriesParams): Promise<void> => {
    //Выставляем бранч по умолчанию, если недоступен выбор филиалов
    if (branches.length === 1) {
      // @ts-ignore
      params['branch'] = first(branches)?.id;
    }
    setLoading(true);
    try {
      const { data } = await CRM.getInventories(params);
      const { deficit } = params || {};
      //фильтруем по дефициту не на бэке
      setInventories(data.filter(item => (deficit ? item.deficit === 'yes' : item.deficit === 'no')));
      push({ search: FilterService.getQueryStringParams(params) });
      setCurrentFilter(params);
    } catch {
      message.error(strings.SORRY_SOMETHING_WENT_WRONG_WHEN_FETCHING_INVENTORIES_PLEASE_TRY_AGAIN);
    } finally {
      setLoading(false);
    }
  };

  /**
   * @description Удалить инвентарь
   * @param {number} id
   * @returns {Promise<void>}
   */
  const deleteInventory = async (id: number): Promise<void> => {
    setLoading(true);
    try {
      await CRM.deleteInventory(id);
      await fetchInventories(currentFilter);
    } catch {
      message.error(strings.SORRY_SOMETHING_WENT_WRONG_WHEN_DELETING_AN_INVENTORY_PLEASE_TRY_AGAIN);
    } finally {
      setLoading(false);
    }
  };

  const columns = getColumns({ deleteInventory, hasActionsAccess, strings });

  return (
    <Container>
      <StPageHeader
        onBack={goBack}
        title={strings.TEACHING_MATERIALS}
        extra={
          hasActionsAccess && (
            <Button type="primary" onClick={() => push(`${BASE_TEACHING_MATERIALS}/new`)}>
              {strings.ADD}
            </Button>
          )
        }
      >
        <Filter defFilterValues={defFilterValues} onFilter={fetchInventories} />
      </StPageHeader>
      <Spin spinning={isLoading} tip={strings.LOADING}>
        <Row gutter={[10, 10]}>
          <Table dataSource={inventories} columns={columns.filter(col => col)} pagination={false}></Table>
        </Row>
      </Spin>
    </Container>
  );
});

export default { TeachingMaterials };
