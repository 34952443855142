import React, { useState } from 'react';
import { Spin } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useHistory } from 'react-router-dom';
import { SmsFilter } from './SmsFilter';
import { SmsForm } from './SmsForm';

export interface IRecipient {
  id: number;
  name: string;
  surname: string;
  smsSubscribed?: boolean;
  parents?: any[];
}

/**
 * @description Компонент отправки смс
 * @param {object} props - component props
 */
export const NewSms = () => {
  const [recipientsList, setRecipientsList] = useState<IRecipient[]>([]);
  const [currentPeople, setCurrentPeople] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [hasRecipientsLoading, setHasRecipientsLoading] = useState<boolean>(false);
  const [strings] = useLanguageContext();
  const { goBack } = useHistory();

  return (
    <Container>
      <StPageHeader title={strings.NEW_SMS} onBack={goBack} />
      <Spin spinning={loading}>
        <SmsFilter
          setRecipientsList={setRecipientsList}
          currentPeople={currentPeople}
          setCurrentPeople={setCurrentPeople}
          hasRecipientsLoading={hasRecipientsLoading}
          setHasRecipientsLoading={setHasRecipientsLoading}
        />
        <SmsForm
          currentPeople={currentPeople}
          recipientsList={recipientsList}
          setRecipientsList={setRecipientsList}
          setLoading={setLoading}
          hasRecipientsLoading={hasRecipientsLoading}
        />
      </Spin>
    </Container>
  );
};
export default { NewSms };
