import React from 'react';

import {
  BarsOutlined,
  CloudOutlined,
  LineChartOutlined,
  ScheduleOutlined,
  UserAddOutlined,
  UserOutlined
} from '@ant-design/icons';

import { Link } from 'react-router-dom';
import { User } from 'types/user';
import { BASE_TEACHERS } from 'Admin/People/Teachers/routes';
import { BASE_ADMINS } from 'Admin/People/Administrators/routes';
import { BASE_FAMILIES } from 'Global/routes';
import { BASE_NEWS } from './News/routes';
import {
  BASE_ASSESSMENT_STATUSES,
  BASE_EXPERTS,
  BASE_CLASSROOMS,
  BASE_MATERIALS,
  BASE_SOURCE_OF_REFERENCE,
  BASE_TEACHING_MATERIALS,
  BASE_TRAININGS,
  BASE_SUBJECTS,
  BASE_STAGES
} from './Education/routes';
import { SCHEDULE } from 'Global/modules/Schedule/routes';
import * as smsRoutes from './Website/SMS/routes';
import * as emailRoutes from './Website/Mailing/routes';
import * as courses from './Courses/routes';
import * as monitoring from './Monitoring/routes';
import * as website from './Website/routes';
import { NEW_PROFILE_FAMILY } from 'Global/routes';
import { hasInvoiceMonthFinanceSystem } from 'helpers';
import {
  ADMIN_ACCESS,
  META_GOALS,
  CHANGE_TUTOR,
  CONTRACT_TEMPLATES,
  BASE_STATUSES,
  BASE_INVOICE_LETTERS_TEMPLATES,
  BASE_BRANCHES, BASE_TAGS
} from 'Admin/Website/CRM/routes';
import { ENTRANCE_TESTS, ENTRANCE_TESTS_RIGA } from '../Global/constants';
import { BranchRiga } from 'types/branches';
import { cleanPath } from 'Global/components/LeftMenu/helper';
import { BASE_STUDENTS } from 'Admin/People/Students/routes';

/**
 * @description Функция формирования меню для админских ролей
 * @param {object} user
 * @return {React.ReactNode}
 */
export const menuListForAdmin = (user: User) => {
  const hasAdminRole = user?.hasRoles?.admin;
  const hasAcademicExpertRole = user?.hasRoles?.academicExpert;
  const hasTopManagerRole = user?.hasRoles?.topManager;
  const hasBranchDirectorRole = user?.hasRoles?.branchDirector;
  const hasBranchOperationDepartament = user?.hasRoles?.branchOperationDepartament;
  const hasTopManagerOrBranchDirector = hasTopManagerRole || hasBranchDirectorRole;
  const branch = user?.branch;
  const hasRiga = user?.branch?.id === BranchRiga.id;

  const getItemsForTopManagerOrBranchDirector = () => {
    if (hasTopManagerOrBranchDirector) {
      return [
        {
          key: cleanPath(monitoring.BASE_REPORT_MANUAL_INPUT.substring(1)),
          title: 'Manual input',
          link: monitoring.BASE_REPORT_MANUAL_INPUT
        },
        {
          key: cleanPath(monitoring.BASE_FINANCE_FAMILIES.substring(1)),
          title: 'Finance Families',
          link: monitoring.BASE_FINANCE_FAMILIES
        },
        {
          key: cleanPath(monitoring.BASE_FINANCE_TEACHERS.substring(1)),
          title: 'Finance Teachers',
          link: monitoring.BASE_FINANCE_TEACHERS
        },
        hasRiga && {
          key: cleanPath(monitoring.INVOICES_GENERATOR.substring(1)),
          title: 'Riga invoices generator',
          link: monitoring.INVOICES_GENERATOR
        }
      ];
    }

    return [];
  };

  const getDashboard = () => {
    if (hasTopManagerRole) {
      return [
        {
          key: 'dashboard',
          title: 'Dashboard',
          subMenu: [
            {
              key: cleanPath(monitoring.BASE_REPORT_STUDENTS_PAYING.substring(1)),
              title: 'Student paying',
              link: monitoring.BASE_REPORT_STUDENTS_PAYING
            }
          ]
        }
      ];
    }

    return [];
  };

  return [
    {
      key: 1,
      title: (
        <span>
          <UserAddOutlined />
          <span>New Client</span>
        </span>
      ),
      subMenu: [
        {
          key: cleanPath(NEW_PROFILE_FAMILY.substring(1)),
          title: 'New family profile',
          link: NEW_PROFILE_FAMILY
        },
        {
          key: 3,
          title: 'Entrance tests',
          link: hasRiga ? ENTRANCE_TESTS_RIGA : ENTRANCE_TESTS
        },
        {
          key: 4,
          title: 'Placement tests instructions',
          link: 'https://drive.google.com/drive/u/0/folders/14vFT6iPbJzJenvGkcWEWZi4QeL06fX0l'
        }
      ]
    },
    {
      key: 6,
      title: (
        <span>
          <UserOutlined />
          <span>People</span>
        </span>
      ),
      subMenu: [
        !hasAcademicExpertRole && {
          key: cleanPath(BASE_ADMINS.substring(1)),
          title: 'Administrators',
          link: BASE_ADMINS
        },
        {
          key: cleanPath(BASE_TEACHERS.substring(1)),
          title: 'Teachers',
          link: BASE_TEACHERS
        },
        {
          key: cleanPath(BASE_FAMILIES.substring(1)),
          title: 'Families',
          link: BASE_FAMILIES
        },
        {
          key: cleanPath(BASE_STUDENTS.substring(1)),
          title: 'Students',
          link: BASE_STUDENTS
        }
      ]
    },
    {
      key: 10,
      title: (
        <span>
          <CloudOutlined />
          <span>Website</span>
        </span>
      ),
      subMenu: [
        {
          key: 10.1,
          title: 'CRM',
          subMenu: [
            {
              key: cleanPath(META_GOALS.substring(1)),
              title: 'Meta-goals',
              link: META_GOALS
            },
            hasTopManagerRole && {
              key: cleanPath(ADMIN_ACCESS.substring(1)),
              title: 'Admin Access',
              link: ADMIN_ACCESS
            },
            hasTopManagerRole && {
              key: cleanPath(website.PRICE_GROUP.substring(1)),
              title: 'Price group',
              link: website.PRICE_GROUP
            },
            hasTopManagerRole && {
              key: cleanPath(website.ADDITIONAL_ACCOUNTS.substring(1)),
              title: 'Additional accounts',
              link: website.ADDITIONAL_ACCOUNTS
            },
            hasTopManagerOrBranchDirector && {
              key: cleanPath(CONTRACT_TEMPLATES.substring(1)),
              title: 'Contract templates',
              link: CONTRACT_TEMPLATES
            },
            {
              key: cleanPath(CHANGE_TUTOR.substring(1)),
              title: 'Change tutor',
              link: CHANGE_TUTOR
            },
            {
              key: cleanPath(BASE_INVOICE_LETTERS_TEMPLATES.substring(1)),
              title: 'Invoice letters templates',
              link: BASE_INVOICE_LETTERS_TEMPLATES
            },
            {
              key: cleanPath(BASE_STATUSES.substring(1)),
              title: 'Statuses',
              link: BASE_STATUSES
            },
            {
              key: cleanPath(BASE_BRANCHES.substring(1)),
              title: 'Branches',
              link: BASE_BRANCHES
            },
            {
              key: cleanPath(BASE_TAGS.substring(1)),
              title: 'Tags',
              link: BASE_TAGS
            }
          ]
        },
        {
          key: cleanPath(BASE_NEWS.substring(1)),
          title: 'News',
          link: BASE_NEWS
        },
        {
          key: 12,
          title: 'SMS & mailing',
          subMenu: [
            {
              key: cleanPath(website.WEEKLY_EMAIL.substring(1)),
              title: 'Weekly email',
              link: website.WEEKLY_EMAIL
            },
            {
              key: cleanPath(smsRoutes.BASE_SMS.substring(1)),
              title: 'New sms',
              link: smsRoutes.BASE_SMS
            },

            {
              key: cleanPath(emailRoutes.BASE_EMAIL.substring(1)),
              title: 'New email',
              link: emailRoutes.BASE_EMAIL
            },
            {
              key: cleanPath(smsRoutes.SHOW_HISTORY.substring(1)),
              title: 'Sms history',
              link: smsRoutes.SHOW_HISTORY
            },
            {
              key: cleanPath(emailRoutes.SHOW_HISTORY.substring(1)),
              title: 'Email history',
              link: emailRoutes.SHOW_HISTORY
            }
          ]
        },
        {
          key: 18,
          title: 'Education',
          subMenu: [
            !hasAcademicExpertRole && {
              key: cleanPath(BASE_CLASSROOMS.substring(1)),
              title: 'Classrooms',
              link: BASE_CLASSROOMS
            },
            {
              key: cleanPath(BASE_SUBJECTS.substring(1)),
              title: 'Subjects',
              link: BASE_SUBJECTS
            },
            {
              key: cleanPath(BASE_STAGES.substring(1)),
              title: 'Stages',
              link: BASE_STAGES
            },
            !hasAcademicExpertRole && {
              key: cleanPath(BASE_TEACHING_MATERIALS.substring(1)),
              title: 'Teaching materials',
              link: BASE_TEACHING_MATERIALS
            },
            {
              key: cleanPath(BASE_SOURCE_OF_REFERENCE.substring(1)),
              title: 'Source of reference',
              link: BASE_SOURCE_OF_REFERENCE
            },
            {
              key: cleanPath(BASE_MATERIALS.substring(1)),
              title: 'Materials',
              link: BASE_MATERIALS
            },
            {
              key: cleanPath(BASE_TRAININGS.substring(1)),
              title: 'Teachers training',
              link: BASE_TRAININGS
            },
            {
              key: cleanPath(BASE_ASSESSMENT_STATUSES.substring(1)),
              title: 'Assessment statuses',
              link: BASE_ASSESSMENT_STATUSES
            },
            !hasAcademicExpertRole && {
              key: cleanPath(BASE_EXPERTS.substring(1)),
              title: 'Experts',
              link: BASE_EXPERTS
            }
          ]
        },
        {
          key: cleanPath(website.SCHEDULE_CHAGES.substring(1)),
          title: 'Changes in the timetable',
          link: website.SCHEDULE_CHAGES
        },
        hasTopManagerOrBranchDirector && {
          key: 25,
          title: 'Finance',
          subMenu: [
            {
              key: cleanPath(website.BASE_TEACHERS_WAGES.substring(1)),
              title: 'Teacher`s wages',
              link: website.BASE_TEACHERS_WAGES
            },
            {
              key: cleanPath(website.SERVICE_RATES.substring(1)),
              title: 'Service rates',
              link: website.SERVICE_RATES
            },
            hasTopManagerRole && {
              key: cleanPath(website.BASE_PERFORMERS.substring(1)),
              title: 'Performers',
              link: website.BASE_PERFORMERS
            }
          ]
        }
      ]
    },
    {
      key: 36,
      title: (
        <span>
          <BarsOutlined />
          <span>Courses</span>
        </span>
      ),
      subMenu: [
        {
          key: cleanPath(courses.BASE_COURSE_GROUP.substring(1)),
          title: 'Group course',
          link: courses.BASE_COURSE_GROUP
        },
        {
          key: cleanPath(courses.BASE_ORIGINAL_COURSES.substring(1)),
          title: 'Original courses',
          link: courses.BASE_ORIGINAL_COURSES
        },
        {
          key: cleanPath(courses.BASE_SUPER_GROUPS.substring(1)),
          title: 'Supergroups',
          link: courses.BASE_SUPER_GROUPS
        },
        {
          key: cleanPath(courses.BASE_PLACEMENT.substring(1)),
          title: 'Placement',
          link: courses.BASE_PLACEMENT
        }
      ]
    },
    {
      key: cleanPath(SCHEDULE.substring(1)),
      title: (
        <Link to={SCHEDULE}>
          <ScheduleOutlined />
          <span>Schedule</span>
        </Link>
      )
    },
    {
      key: 44,
      title: (
        <span>
          <LineChartOutlined />
          <span>Monitoring</span>
        </span>
      ),
      subMenu: [
        {
          key: 45.1,
          title: 'Finance',
          subMenu: [
            hasAdminRole &&
              hasInvoiceMonthFinanceSystem(branch) && {
                key: cleanPath(monitoring.BASE_INVOICE_MONTH_SYSTEM.substring(1)),
                title: 'Invoice month system',
                link: monitoring.BASE_INVOICE_MONTH_SYSTEM
              },
            (hasTopManagerOrBranchDirector || hasBranchOperationDepartament) && {
              key: cleanPath(monitoring.BASE_INVOICES_LOGS.substring(1)),
              title: 'Invoice logs',
              link: monitoring.BASE_INVOICES_LOGS
            },
            ...getItemsForTopManagerOrBranchDirector()
          ]
        },
        {
          key: 45,
          title: 'Academic',
          subMenu: [
            {
              key: cleanPath(monitoring.BASE_LESSONS.substring(1)),
              title: 'Lessons',
              link: monitoring.BASE_LESSONS
            },
            {
              key: cleanPath(monitoring.BASE_FEEDBACK_MANAGEMENT.substring(1)),
              title: 'Feedback management',
              link: monitoring.BASE_FEEDBACK_MANAGEMENT
            },
            {
              key: cleanPath(monitoring.BASE_REPORT_TEACHERS.substring(1)),
              title: 'Teachers',
              link: monitoring.BASE_REPORT_TEACHERS
            },
            {
              key: cleanPath(monitoring.BASE_PROGRESS_REPORT.substring(1)),
              title: 'Progress Report',
              link: monitoring.BASE_PROGRESS_REPORT
            },
            {
              key: cleanPath(monitoring.NEW_PROGRESS_REPORTS.substring(1)),
              title: 'New progress report',
              link: monitoring.NEW_PROGRESS_REPORTS
            },
            (hasTopManagerOrBranchDirector || hasBranchOperationDepartament) && {
              key: cleanPath(monitoring.BASE_REGISTERS_LOGS.substring(1)),
              title: 'Register logs',
              link: monitoring.BASE_REGISTERS_LOGS
            },
            {
              key: cleanPath(monitoring.BASE_ASSISTANTS_FEEDBACK.substring(1)),
              title: 'Assistants` feedback',
              link: monitoring.BASE_ASSISTANTS_FEEDBACK
            },
            {
              key: cleanPath(monitoring.BASE_COMMENTARY_CALENDAR.substring(1)),
              title: 'Commentary calendar',
              link: monitoring.BASE_COMMENTARY_CALENDAR
            }
          ]
        },
        {
          key: 55,
          title: 'CRM',
          subMenu: [
            {
              key: cleanPath(monitoring.BASE_MESSAGES_FROM_PARENTS.substring(1)),
              title: 'Messages from parents',
              link: monitoring.BASE_MESSAGES_FROM_PARENTS
            },
            {
              key: cleanPath(monitoring.BASE_ORIGINAL_COURSES_ACCESS.substring(1)),
              title: 'Original courses access',
              link: monitoring.BASE_ORIGINAL_COURSES_ACCESS
            },
            hasTopManagerOrBranchDirector && {
              key: cleanPath(monitoring.PROGRESS_REPORT_GENERATOR.substring(1)),
              title: 'Progress reports generator',
              link: monitoring.PROGRESS_REPORT_GENERATOR
            }
          ]
        },
        /* 9 пункт */

        {
          key: 63,
          title: 'Marketing',
          subMenu: [...getDashboard()]
        },

        {
          key: 66,
          title: 'Assessment',
          subMenu: [
            {
              key: cleanPath(monitoring.BASE_SUMMARY_TABLE.substring(1)),
              title: 'Summary table',
              link: monitoring.BASE_SUMMARY_TABLE
            },
            {
              key: cleanPath(monitoring.BASE_SUMMARY_CALENDAR.substring(1)),
              title: 'Summary calendar',
              link: monitoring.BASE_SUMMARY_CALENDAR
            }
          ]
        }
      ]
    }
  ];
};

export default { menuListForAdmin };
