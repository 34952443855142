import React, { useState, useEffect } from 'react';
import { MessageOutlined } from '@ant-design/icons';
import { Row, Col, message, Button, Divider, Spin, Form } from 'antd';
import { scrollTop } from 'helpers/scroll';
import MaterialsMemo from './Materials';
import { LessonInfoContainer } from '../styles';
import { ORIGINAL_LESSON_CREATE_ID } from 'Admin/Courses/CourseGroup/constants';
import { ModalFeedback } from '../ModalFeedback';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { Courses } from 'api/Courses';
import { OriginalLessonItem, Materials as IMaterials, OriginalLessonTopicsParams } from 'types/courses';
import { OriginalLesson } from 'types/TeacherAccount/Courses';
import { LessonMainInfo } from './LessonMainInfo';
import { LESSON_HOMEWORK_MATERIAL_TYPE, LESSON_MATERIAL_TYPE } from '../constants';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { useLessonInfoStore } from 'stores/useLessonInfoStore';
import { useLessonOutcomesStore } from 'stores/useLessonOutcomesStore';

interface LessonInfoProps {
  lessonId: number | string;
}

/**
 * @description Lesson info
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const LessonInfo = ({ lessonId }: LessonInfoProps) => {
  const form = Form.useFormInstance();

  const setOriginalLessonOutcomes = useLessonOutcomesStore(state => state.setOriginalLessonOutcomes);
  const originalLessonOutcomes = useLessonOutcomesStore(state => state.originalLessonOutcomes);
  const setHasLessonOutcomesChanged = useLessonOutcomesStore(state => state.setHasLessonOutcomesChanged);

  const lessonInfo = useLessonInfoStore(state => state.lessonInfo);
  const { lesson } = lessonInfo;
  const { title, id, originalLesson, unit } = lesson || {};
  const [strings] = useLanguageContext();
  const coursesRepository = useCoursesRepository();
  const [originalLessonInfo, setOriginalLessonInfo] = useState<OriginalLesson | undefined>();
  const [originalLessons, setOriginalLessons] = useState<OriginalLessonItem[]>([]);
  const [materials, setMaterials] = useState<IMaterials | undefined>();
  const [hasShowModalFeedback, setHasShowModalFeedback] = useState<boolean>(false);
  const [hasOriginalLessonLoading, setHasOriginalLessonLoading] = useState<boolean>(false);
  const [hasClassroomMaterialsUpdated, setHasClassroomMaterialsUpdated] = useState<boolean>(false);
  const [hasHwMaterialsUpdated, setHasHwMaterialsUpdated] = useState<boolean>(false);

  /**
   * @description Список юнитов
   * @param {number} unitId
   * @return {Promise<any>}
   */
  const handleChangeUnitList = async (unitId: number): Promise<any> => {
    await getLessonTopic({ unit: unitId });
  };

  /**
   * @description Get original lesson topic list
   * @param {OriginalLessonTopicsParams} params
   * @return {Promise<any>}
   */
  const getLessonTopic = async (params: OriginalLessonTopicsParams): Promise<any> => {
    try {
      const { data } = await Courses.getOriginalLessonTopics(params);
      setOriginalLessons(data);
    } catch {
      setOriginalLessons([]);
    }
  };

  /**
   * @description Get original lesson by id
   * @param {number} originalLessonId
   * @return {Promise<any>}
   */
  const fetchOriginalLessonById = async (
    originalLessonId: number,
    hasChangeOriginalLesson: boolean = false
  ): Promise<any> => {
    if (originalLessonId === ORIGINAL_LESSON_CREATE_ID) {
      handleResetOriginalLesson();
    } else if (originalLessonId) {
      const originalLesson = coursesRepository.getOriginalLessonById(originalLessonId);
      const lessonMaterials = Courses.getOriginalLessonMaterials(originalLessonId);
      setHasOriginalLessonLoading(true);
      try {
        const data = await Promise.all([originalLesson, lessonMaterials]);
        const [lesson, materials] = data;
        setOriginalLessonInfo(lesson.data);
        if (lesson.data?.outcomesList) {
          setOriginalLessonOutcomes(lesson.data.outcomesList);
        }
        setMaterials(materials.data);
        if (hasChangeOriginalLesson) {
          form.setFieldsValue({
            title: lesson.data.topic
          });
          setHasLessonOutcomesChanged(true);
        }
      } catch {
        message.error(strings.ERROR_GETTING_ORIGINAL_LESSON, 2);
      } finally {
        setHasOriginalLessonLoading(false);
      }
    }
  };

  const handleUpdateMaterials = (type: string) => {
    if (type === LESSON_MATERIAL_TYPE) {
      setHasClassroomMaterialsUpdated(true);
    }
    if (type === LESSON_HOMEWORK_MATERIAL_TYPE) {
      setHasHwMaterialsUpdated(true);
    }
  };

  /**
   * @description Syllabus handler change
   * @param {number} originalLessonId
   * @return {void}
   */
  const handleChangeOriginalLesson = async (originalLessonId: number): Promise<any> => {
    fetchOriginalLessonById(originalLessonId, true);

    const findUnit = originalLessons.find(lesson => lesson.id === originalLessonId);
    //Устанавливает юнит при выборе Lesson topic from syllabus
    //TODO проверить актуальность кода
    if (findUnit?.unitId) {
      form.setFieldsValue({ unit: findUnit?.unitId });
    }
  };

  const handleResetOriginalLesson = () => {
    setOriginalLessonInfo(undefined);
    setOriginalLessonOutcomes([]);
    setHasLessonOutcomesChanged(false);
    setMaterials(undefined);
  };

  useEffect(() => {
    // стираем предыдущие данные originalLesson
    handleResetOriginalLesson();
    if (originalLesson) {
      fetchOriginalLessonById(originalLesson?.id);
    }
  }, [lesson]);

  useEffect(() => {
    if (unit?.id) {
      getLessonTopic({ unit: unit.id });
    } else {
      getLessonTopic({ lesson: lessonId });
    }

    scrollTop();
  }, [unit, lessonId]);

  return (
    <ErrorBoundary>
      <Row>
        <Col lg={12} sm={24}>
          <LessonMainInfo
            handleChangeUnitList={handleChangeUnitList}
            handleChangeOriginalLesson={handleChangeOriginalLesson}
            originalLessons={originalLessons}
            hasClassroomMaterialsUpdated={hasClassroomMaterialsUpdated}
            setHasClassroomMaterialsUpdated={setHasClassroomMaterialsUpdated}
            hasHwMaterialsUpdated={hasHwMaterialsUpdated}
            setHasHwMaterialsUpdated={setHasHwMaterialsUpdated}
          />
        </Col>
        <Col lg={12} sm={24}>
          <Spin spinning={hasOriginalLessonLoading}>
            <LessonInfoContainer>
              <p>
                <Divider orientation="left">{strings.LESSON_OUTCOMES}</Divider>
              </p>
              {originalLessonOutcomes.isNotEmpty() &&
                originalLessonOutcomes.map(goal => (
                  <div style={{ lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: goal?.description }} />
                ))}
              <p>
                <Divider orientation="left">{strings.OLD_OUTCOMES}</Divider>
              </p>
              <div style={{ marginBottom: 24 }} dangerouslySetInnerHTML={{ __html: originalLessonInfo?.outcomes }} />

              <p>
                <Divider orientation="left">{strings.KEY_VOCABULARY}</Divider>
              </p>
              <div
                style={{ marginBottom: 24 }}
                dangerouslySetInnerHTML={{ __html: originalLessonInfo?.keyVocabulary }}
              />
              <p>
                <Divider orientation="left">{strings.SUGGESTED_ACTIVITIES}</Divider>
              </p>
              <div
                style={{ marginBottom: 24 }}
                dangerouslySetInnerHTML={{ __html: originalLessonInfo?.suggestedActivities }}
              />
              {materials?.suggestedActivities && (
                <MaterialsMemo
                  data={materials.suggestedActivities}
                  type="lessonMaterial"
                  lessonId={id}
                  originalLessonId={originalLesson?.id}
                  stateUpdate={() => handleUpdateMaterials(LESSON_MATERIAL_TYPE)}
                />
              )}
              <p>
                <Divider orientation="left">{strings.SUGGESTED_HOMEWORK}</Divider>
              </p>
              <div
                style={{ marginBottom: 24 }}
                dangerouslySetInnerHTML={{ __html: originalLessonInfo?.suggestedHomework }}
              />
              {materials?.suggestedHomework && (
                <MaterialsMemo
                  data={materials.suggestedHomework}
                  type="lessonHomework"
                  lessonId={id}
                  originalLessonId={originalLesson?.id}
                  stateUpdate={() => handleUpdateMaterials(LESSON_HOMEWORK_MATERIAL_TYPE)}
                />
              )}
              <p>
                <Divider orientation="left">{strings.COMMENTS}</Divider>
              </p>
              <div style={{ marginBottom: 24 }} dangerouslySetInnerHTML={{ __html: originalLessonInfo?.comment }} />
              <p>
                <strong>{strings.RESOURCES}</strong>
              </p>
              <div style={{ marginBottom: 24 }} dangerouslySetInnerHTML={{ __html: originalLessonInfo?.resources }} />
              <Button
                icon={<MessageOutlined />}
                size="large"
                type="primary"
                onClick={() => setHasShowModalFeedback(true)}
              >
                {strings.FEEDBACK_FROM_ORIGINAL_COURSE}
              </Button>
              {hasShowModalFeedback && (
                <ModalFeedback
                  visible={hasShowModalFeedback}
                  setHasShowModalFeedback={setHasShowModalFeedback}
                  title={title}
                />
              )}
            </LessonInfoContainer>
          </Spin>
        </Col>
      </Row>
    </ErrorBoundary>
  );
};

const LessonInfoMemo = React.memo(LessonInfo);
export default LessonInfoMemo;
