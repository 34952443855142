import React, { useState, useEffect } from 'react';
import { Button, Table, Popconfirm, Flex } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { Link, useHistory } from 'react-router-dom';
import { useTemplatesRepository } from 'repos/TemplatesRepository';
import { Branch } from 'types';
import { ArrowLeftOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ModalContractTemplateForm } from './ModalForm';
import { Forbidden } from 'Global/modules/Errors/403';
import { useUserContext } from 'contexts/UserContext';

export const ContractTemplates = () => {
  const [strings] = useLanguageContext();
  const templatesRepository = useTemplatesRepository();
  const { goBack } = useHistory();
  const [user] = useUserContext();
  const hasTopManager = user?.hasRoles?.topManager;
  const hasBranchDirector = user?.hasRoles?.branchDirector;
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [contractTemplates, setContractTemplates] = useState<{ id: number; name: string }[]>([]);
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<number>(null);

  const fetchContractTemplates = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await templatesRepository.getContractTemplates();
      if (!hasTopManager) {
        const filteredData = data?.filter(item => item?.branch?.id === user?.branch?.id);
        setContractTemplates(filteredData);
      } else {
        setContractTemplates(data);
      }
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  const handleDelete = async (id): Promise<any> => {
    setHasLoading(true);
    try {
      await templatesRepository.deleteContractTemplate(id);
      fetchContractTemplates();
    } finally {
      setHasLoading(false);
    }
  };

  const columns = [
    {
      title: strings.NAME,
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: strings.BRANCH,
      dataIndex: 'branch',
      key: 'branch',
      render: (branch: Branch) => branch?.name
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'action',
      render: id => (
        <Flex gap={5} justify="end">
          <Button
            size="small"
            icon={<EditOutlined />}
            onClick={() => {
              setSelectedTemplateId(id);
              setHasShowModal(true);
            }}
          />
          <Popconfirm
            title={strings.ARE_YOU_SURE}
            placement="left"
            okText={strings.YES}
            cancelText={strings.NO}
            onConfirm={() => handleDelete(id)}
          >
            <Button icon={<DeleteOutlined />} size="small" danger />
          </Popconfirm>
        </Flex>
      )
    }
  ];

  if (!hasTopManager && !hasBranchDirector) {
    return (
      <Forbidden subTitle={strings.SORRY_YOU_DO_NOT_HAVE_ACCESS_TO_THIS_PAGE}>
        <Button size="large" type="primary" icon={<ArrowLeftOutlined />}>
          <Link to="/" style={{ color: '#fff', paddingInlineStart: '10px' }}>
            {strings.BACK_TO_HOME}
          </Link>
        </Button>
      </Forbidden>
    );
  }

  useEffect(() => {
    fetchContractTemplates();
  }, []);

  return (
    <Container>
      {hasShowModal && (
        <ModalContractTemplateForm
          hasShowModal={hasShowModal}
          setHasShowModal={setHasShowModal}
          handler={fetchContractTemplates}
          id={selectedTemplateId}
        />
      )}
      <StPageHeader
        onBack={goBack}
        title={strings.CONTRACT_TEMPLATES}
        extra={
          <Button
            type="primary"
            onClick={() => {
              setSelectedTemplateId(null);
              setHasShowModal(true);
            }}
          >
            {strings.ADD}
          </Button>
        }
      />
      <Table loading={hasLoading} dataSource={contractTemplates} columns={columns} pagination={false} />
    </Container>
  );
};
