import React, { useState, useEffect, useMemo } from 'react';
import { Select, Spin, Tag } from 'antd';
import { Admin } from 'types';
import { useLanguageContext } from 'contexts/LanguageContext';
import debounce from 'lodash/debounce';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';

const { Option } = Select;

type Subset<T> = Partial<T>;

type AdminSubset = Subset<Admin>;

type AsyncSelectProps = {
  value?: AdminSubset[];
  placeholder?: string;
  onChange?: (value: AdminSubset[]) => void;
  mode?: 'multiple' | 'tags';
};

const UserDataSelect: React.FC<AsyncSelectProps> = ({ value = [], placeholder, onChange, mode = 'multiple' }) => {
  const [strings] = useLanguageContext();
  const globalRequestsRepository = useGlobalRequestsRepository();
  const [typing, setTyping] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState<AdminSubset[]>([]);
  const [hasLoading, setHasLoading] = useState(false);

  const fetchUsers = async (userName: string) => {
    setSearchValue(userName);
    if (!userName) return setOptions([]);

    setHasLoading(true);
    try {
      const { data: { children = [], parents = [], teachers = [], admins = [] } = {} } =
        await globalRequestsRepository.search(userName, { modeSchedule: true });

      const mapUsers = (users: any[], type: string) =>
        users.filter(item => item?.user?.name || item?.user?.surname).map(item => ({ ...item, type }));

      setOptions([
        ...mapUsers(children, 'student'),
        ...mapUsers(parents, 'parent'),
        ...mapUsers(teachers, 'teacher'),
        ...mapUsers(admins, 'admin')
      ]);
    } catch {
      setOptions([]);
    } finally {
      setHasLoading(false);
    }
  };

  const debouncedFetchUsers = useMemo(() => debounce(fetchUsers, 800), []);

  useEffect(() => {
    setTyping(Boolean(searchValue));
  }, [searchValue]);

  return (
    <Select
      mode={mode}
      showSearch
      value={value.map(item => ({ key: item.id }))}
      onChange={selectedOptions => {
        const selectedValues = selectedOptions.map(
          option =>
            options.find(item => item.id === Number(option.key)) || {
              id: Number(option.key),
              user: { surname: '', name: '' }
            }
        );
        onChange?.(selectedValues);
      }}
      allowClear
      placeholder={placeholder}
      onSearch={debouncedFetchUsers}
      loading={hasLoading}
      labelInValue
      maxTagCount="responsive"
      filterOption={false}
      notFoundContent={hasLoading ? <Spin size="small" /> : <p>{!typing ? strings.START_TYPING : strings.NOT_FOUND}</p>}
    >
      {options.map(option => (
        <Option key={option.id} value={option.id}>
          {option.user?.surname} {option.user?.name} <Tag color="blue">{option?.type}</Tag>
        </Option>
      ))}
    </Select>
  );
};

export default UserDataSelect;
