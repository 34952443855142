import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import dayjs from 'dayjs';
import ScheduleService from 'helpers/services/ScheduleService';
import { UploadOutlined } from '@ant-design/icons';
import { Modal, Button, Select, Input, Checkbox, Upload, Row, Col, DatePicker, Spin, message, Flex, Form } from 'antd';
import { pickBy } from 'lodash';
import { ModalMode, Product, Training } from 'types';
import { Event, EventFile, Participant, SearchSlotsParams } from 'types/Schedule';
import { UploadFile } from 'antd/lib/upload/interface';
import { Schedule } from 'api/Schedule';
import { dateFormatForBackend, getFormData } from 'helpers';
import { dateFormat } from 'helpers/dates';
import ZoomIcon from 'img/zoom.png';
import { SmsForm } from 'Global/modules/Schedule/SlotModals/SlotFields/Sms';
import { sendSms } from 'Global/modules/Schedule/SlotModals/SlotFields/helpers';
import { useUserContext } from 'contexts/UserContext';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { RateSelect, StageSelect, UserSelect, WageSelect } from 'Global/components/FormComponents';
import { useLanguageContext } from 'contexts/LanguageContext';
import { FormItemWithoutMargin } from 'Global/styles';
import { AttachmentsList } from 'Global/components/AttachmentsList';
import { ParticipantsFields } from './SlotFields/ParticipantsFields';
import { TrainingFields } from './SlotFields/TrainingFields';
import { useAttachmentRepository } from 'repos/AttachmentRepository';
import { getDateForSchedule } from 'helpers/getDateForSchedule';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import TimeRoomSlotFields from './SlotFields/TimeRoomSlotFields';
import { StSlotCollapse } from '../styles';
import { useMediaQuery } from 'react-responsive';

const { Option } = Select;
const service = new ScheduleService();

interface EventModalProps {
  values: Event;
  mode: ModalMode;
  hideModal: () => void;
  hasLoadEvent: boolean;
  getEventById: (eventId: number) => Promise<any>;
  getEvents: (params: Partial<SearchSlotsParams>) => Promise<any>;
}

/**
 * @description Модальное окно события
 * @param {object} props
 * @return {React.ReactNode}
 */
const EventModal: React.FC<EventModalProps> = React.memo(
  ({ mode, hideModal, values, hasLoadEvent, getEvents }: EventModalProps) => {
    const [strings] = useLanguageContext();
    const [user] = useUserContext();
    const [form] = Form.useForm();
    const { products } = useGlobalCollectionsContext();
    const attachmentRepository = useAttachmentRepository();
    const { locationSearchParams } = useLocationSearchParams();
    const { branch } = locationSearchParams;
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const [eventParticipants, setEventParticipants] = useState<Participant[]>([]);
    const [trainingsList, setTrainingsList] = useState<Training[]>([]);
    const [attachmentList, setAttachmentList] = useState<EventFile[]>([]);
    const [hasUploadFile, setHasUploadFile] = useState<boolean>(false);

    // Отправка смс сообщений
    const [smsText, setSmsText] = useState<string>('');
    const [hasSendToParent, setHasSendToParent] = useState<boolean>(false);
    const [hasShouldClearSms, setHasShouldClearSms] = useState<boolean>(false);

    const [activeKeys, setActiveKeys] = useState<string[]>(['1']);
    const attachmentListRef = useRef(attachmentList);
    const trainingsListRef = useRef(trainingsList);

    const [{ confirm }, contextHolder] = Modal.useModal();

    const {
      slot: { startAt, endAt, room, id: slotId },
      editable,
      name,
      product,
      stage,
      master,
      id: eventId,
      participants,
      trainings: eventTrainings,
      price,
      cost,
      activateDiscount
    } = values;

    const [hasLoadingSubmit, setHasLoadingSubmit] = useState<boolean>(false);

    const resetAllFields = useCallback(() => {
      form.resetFields();
      setEventParticipants([]);
      setTrainingsList([]);
      setAttachmentList([]);
      setSmsText('');
      setHasSendToParent(false);
      setHasShouldClearSms(true);
      setActiveKeys(['1']);
    }, []);

    const closeModal = useCallback(() => {
      resetAllFields();
      hideModal();
    }, [hideModal]);

    const handleSubmit = async (values): Promise<any> => {
      // Если фин. период окончен, выведем сообщение, прервем функцию
      if (!editable) {
        message.error(strings.YOU_CAN_NО_LONGER_APPLY_CHANGES_TO_THIS_DATE_THE_FINANCIAL_PERIOD_IS_CLOSED, 3);
        return;
      }
      const { day, startTime, endTime, dateSequence } = values;
      const newDateStart = getDateForSchedule(day, startTime);
      const newDateEnd = getDateForSchedule(day, endTime);
      delete values['day'];
      delete values['startTime'];
      delete values['endTime'];
      const params = { ...values, dateStart: newDateStart, dateEnd: newDateEnd };

      // Если присутствует - отформатируем Prolong until
      if (dateSequence) {
        params['dateSequence'] = dayjs(dateSequence).format(dateFormatForBackend);
      }

      params['participants'] = service.getFormatParticipants(eventParticipants);
      params['trainings'] = trainingsList.map((training: Training) => training.id);

      setHasLoadingSubmit(true);
      try {
        const { status } = await Schedule.editEventById(slotId, params);
        if (status === 200) {
          await getEvents(locationSearchParams);
          form.resetFields();
          message.success(strings.EVENT_EDITED, 2);
          closeModal();
        }
      } catch {
        message.error(strings.EVENT_EDITING_ERROR, 2);
      } finally {
        setHasLoadingSubmit(false);
      }

      // Отправим смс участникам
      await sendSms(eventParticipants, smsText, hasSendToParent);
    };

    const getEventAttachments = useCallback(async (): Promise<any> => {
      try {
        const {
          data: { list }
        } = await attachmentRepository.getAttachmentList({
          id: eventId,
          type: 'eventFiles'
        });
        setAttachmentList(list);
      } catch {}
    }, [eventId]);

    const deleteEvent = useCallback(
      async (slotId: number): Promise<any> => {
        // Если фин. период окончен, выведем сообщение, прервем функцию
        if (!editable) {
          message.error(strings.YOU_CAN_NО_LONGER_APPLY_CHANGES_TO_THIS_DATE_THE_FINANCIAL_PERIOD_IS_CLOSED, 3);
          return;
        }

        // Если отметить чекбокс apply to all и нажать Remove должен удаляться
        // не только текущий урок, но и все последующие.
        const hasApplyToAll = form.getFieldValue('applyToAll');
        try {
          await Schedule.deleteEvent(slotId, pickBy({ applyToAll: Number(hasApplyToAll) }));
          message.success(strings.SUCCESSFULLY_DELETED);
          closeModal();
          getEvents(locationSearchParams);
        } catch {}
      },
      [editable, locationSearchParams]
    );

    const handleDelete = useCallback(() => {
      confirm({
        title: strings.DO_YOU_WANT_TO_DELETE_THIS_EVENT,
        content: <b style={{ color: 'rgb(246 70 70)' }}>{dayjs(values.slot.startAt).format('dddd, MMMM D, YYYY')}</b>,
        onOk: async () => deleteEvent(slotId)
      });
    }, [values]);

    const handleUploadFiles = useCallback(
      async (e: UploadFile & { file: UploadFile; fileList: UploadFile[] }) => {
        const { file } = e;
        if (!file) return;
        setHasUploadFile(true);
        try {
          const { status } = await attachmentRepository.uploadAttachment(
            getFormData({ type: 'eventFiles', id: eventId, file }),
            true
          );
          if (status === 200) {
            message.success(strings.FILE_UPLOAD, 2);
            getEventAttachments();
          }
        } catch {
          message.error(strings.UPLOAD_FILE_ERROR, 2);
        } finally {
          setHasUploadFile(false);
        }
      },
      [eventId]
    );
    useEffect(() => {
      attachmentListRef.current = attachmentList;
      trainingsListRef.current = trainingsList;
    }, [attachmentList, trainingsList]);

    useEffect(() => {
      setActiveKeys(prevKeys => {
        const newKeys = [...prevKeys];

        if (trainingsListRef.current.isNotEmpty() && !newKeys.includes('2')) {
          newKeys.push('2');
        }
        if (trainingsListRef.current.isNotEmpty() && !newKeys.includes('3')) {
          newKeys.push('3');
        }

        return newKeys;
      });
    }, [attachmentList, trainingsList]);

    useEffect(() => {
      setEventParticipants(participants);
      setTrainingsList(eventTrainings);
    }, [master]);

    useEffect(() => {
      if (mode === ModalMode.Edit && eventId !== 0) {
        getEventAttachments();
      }
    }, [mode, eventId]);

    const defaultValues = useMemo(
      () =>
        mode === ModalMode.Edit && values
          ? {
              branch: branch || user?.branch?.id,
              stage: stage?.id,
              product: product?.id,
              conductorId: master?.id,
              conductorType: master?.type,
              room: room?.id,
              price: price?.id,
              cost: cost?.id,
              discount: activateDiscount,
              name
            }
          : {},
      [mode, values]
    );

    useEffect(() => {
      form.setFieldsValue(defaultValues);
    }, [defaultValues]);

    return (
      <Modal
        title={name}
        width={950}
        style={{ top: 50, overflowY: 'auto' }}
        footer={null}
        open={mode !== ModalMode.Hidden}
        onCancel={closeModal}
        destroyOnClose={true}
      >
        {contextHolder}
        <Spin spinning={hasLoadEvent}>
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <TimeRoomSlotFields
              form={form}
              dateStart={startAt}
              dateEnd={endAt}
              room={room}
              branch={branch || user?.branch?.id}
            />
            <Row gutter={[10, 10]} align="middle">
              <Col lg={10} xs={24}>
                <Form.Item name="name" label={strings.NAME} rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={7} xs={24}>
                <Form.Item name="branch" hidden={true}>
                  <Input />
                </Form.Item>
                <Form.Item name="stage" label={strings.STAGE} rules={[{ required: true }]}>
                  <StageSelect branch={defaultValues?.branch} />
                </Form.Item>
              </Col>
              <Col lg={7} xs={24}>
                <Form.Item name="product" label={strings.PRODUCT} rules={[{ required: true }]}>
                  <Select placeholder={strings.PRODUCT}>
                    {products.map((product: Product) => (
                      <Option value={product.id} key={product.id}>
                        {product.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 10]} align="middle">
              <Col lg={10} xs={24}>
                <Form.Item name="price" label={strings.RATE}>
                  <RateSelect branch={defaultValues?.branch} />
                </Form.Item>
              </Col>
              <Col lg={7} xs={24}>
                <Form.Item name="cost" label={strings.CONDUCTOR_WAGE}>
                  <WageSelect branch={defaultValues?.branch} />
                </Form.Item>
              </Col>
              <Col lg={7} xs={24}>
                <Form.Item name="discount" style={{ display: 'contents' }} valuePropName="checked">
                  <Checkbox style={{ ...(!isMobile && { paddingTop: 6 }) }}>{strings.ACTIVATE_DISCOUNT}</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 10]} align="middle">
              <Col lg={10} xs={24}>
                <Form.Item name="conductorId" label={strings.CONDUCTOR} rules={[{ required: true }]}>
                  <UserSelect initOption={master} nameExtraTypeField="conductorType" />
                </Form.Item>
                <Form.Item name="conductorType" hidden>
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={7} xs={24}>
                <Form.Item name="dateSequence" label={strings.PROLONG_UNTIL}>
                  <DatePicker format={dateFormat} placeholder={strings.PROLONG_UNTIL} />
                </Form.Item>
              </Col>
              <Col lg={7} xs={24}>
                <Form.Item name="applyToAll" style={{ display: 'contents' }} valuePropName="checked">
                  <Checkbox style={{ ...(!isMobile && { paddingTop: 6 }) }}>{strings.APPLY_TO_ALL}</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            {(room?.webLogin || room?.webPassword || room?.webLink) && (
              <Row gutter={[10, 10]}>
                <Col lg={10} xs={24}>
                  <FormItemWithoutMargin label={strings.ZOOM_LOGIN}>
                    <p>{room?.webLogin}</p>
                  </FormItemWithoutMargin>
                </Col>
                <Col lg={7} xs={24}>
                  <FormItemWithoutMargin label={strings.ZOOM_PASSWORD}>
                    <p>{room?.webPassword}</p>
                  </FormItemWithoutMargin>
                </Col>
                <Col lg={7} xs={24}>
                  <FormItemWithoutMargin label={strings.LINK}>
                    <a href={room?.webLink} rel="noopener noreferrer" target="_blank">
                      <Flex align="center">
                        <img src={ZoomIcon} alt="zoom link" width={28} height={28} />
                        <span>{strings.GO_TO_ZOOM}</span>
                      </Flex>
                    </a>
                  </FormItemWithoutMargin>
                </Col>
              </Row>
            )}
            <StSlotCollapse
              ghost
              size="small"
              activeKey={activeKeys}
              onChange={keys => setActiveKeys(Array.isArray(keys) ? keys : [keys])}
              items={[
                {
                  key: '1',
                  label: <strong>{strings.PARTICIPANTS}</strong>,
                  children: (
                    <Flex vertical gap={10}>
                      <ParticipantsFields
                        eventParticipants={eventParticipants}
                        setEventParticipants={setEventParticipants}
                        selectedBranch={branch || user?.branch?.id}
                      />
                      <SmsForm
                        smsTextChanged={setSmsText}
                        sendToParentChanged={setHasSendToParent}
                        hasShouldClearSms={hasShouldClearSms}
                        setHasShouldClearSms={setHasShouldClearSms}
                      />
                    </Flex>
                  )
                },
                {
                  key: '2',
                  label: <strong>{strings.TRAININGS}</strong>,
                  children: <TrainingFields trainingsList={trainingsList} setTrainingsList={setTrainingsList} />
                },
                {
                  key: '3',
                  label: <strong>{strings.FILES}</strong>,
                  children: (
                    <>
                      <Upload beforeUpload={() => false} onChange={handleUploadFiles} showUploadList={false}>
                        <Button icon={<UploadOutlined />} loading={hasUploadFile}>
                          {strings.UPLOAD}
                        </Button>
                      </Upload>
                      {attachmentList.isNotEmpty() && (
                        <AttachmentsList attachments={attachmentList} updateAttachments={getEventAttachments} />
                      )}
                    </>
                  )
                }
              ]}
            />
            <Flex justify="end" gap={5}>
              <Button key="back" onClick={closeModal}>
                {strings.CANCEL}
              </Button>
              <Button danger onClick={handleDelete}>
                {strings.REMOVE}
              </Button>
              <Button type="primary" htmlType="submit" loading={hasLoadingSubmit}>
                {strings.UPDATE}
              </Button>
            </Flex>
          </Form>
        </Spin>
      </Modal>
    );
  }
);

export default EventModal;
