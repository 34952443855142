import React, { useEffect, useState } from 'react';
import { useUserContext } from 'contexts/UserContext';
import { convertBooleanToNumber, dateFormat } from 'helpers';
import { Row, Col, Select, DatePicker, Checkbox, Form } from 'antd';
import { GetAssistantsCommentsParams } from './types';
import { momentFormatValues } from 'helpers/momentFormatValues';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import {
  StageSelect,
  StudentSelect,
  TutorSelect,
  TeacherSelect,
  SupergroupSelect
} from 'Global/components/FormComponents';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { FilterButtons } from 'Global/components/FilterButtons';
import { useAssistantFeedbackRepository } from 'repos/AssistantsFeedbackRepository';
import { FormItemWithoutMargin } from '../../styles';
import dayjs from 'dayjs';

interface AssistantFeedbackFilterProps {
  getAssistantComments: (params: GetAssistantsCommentsParams) => Promise<any>;
  defFilterValues: GetAssistantsCommentsParams;
}

const { Option } = Select;

export const AssistantFeedbackFilter = ({ getAssistantComments, defFilterValues }: AssistantFeedbackFilterProps) => {
  const [user] = useUserContext();
  const [strings] = useLanguageContext();
  const [form] = Form.useForm();
  const { branches } = useGlobalCollectionsContext();
  const assistantFeedbackRepo = useAssistantFeedbackRepository();
  const [selectedBranch, setSelectedBranch] = useState<number>();
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);
  const [tags, setTags] = useState<{ id: number; name: string }[]>([]);
  const { locationSearchParams } = useLocationSearchParams();
  const hasTopManager = user?.hasRoles?.topManager;

  const onClearFilter = async (): Promise<any> => {
    await getAssistantComments(defFilterValues);
    form.resetFields();
  };

  const handleSubmit = async (values): Promise<any> => {
    const params = momentFormatValues(convertBooleanToNumber(values));
    try {
      setHasSubmitLoading(true);
      await getAssistantComments(params);
    } finally {
      setHasSubmitLoading(false);
    }
  };

  const getAssistantTags = async (): Promise<any> => {
    try {
      const { data } = await assistantFeedbackRepo.getAssistantCommentTags();
      setTags(data);
    } catch {}
  };

  useEffect(() => {
    getAssistantTags();
  }, []);

  useEffect(() => {
    const { branch, dateStart, dateEnd } = locationSearchParams;
    form.setFieldsValue({
      ...locationSearchParams,
      dateStart: dateStart && dayjs(dateStart),
      dateEnd: dateEnd && dayjs(dateEnd)
    });
    branch && setSelectedBranch(branch);
  }, [locationSearchParams]);

  return (
    <Form form={form} onFinish={handleSubmit} initialValues={defFilterValues}>
      <Row gutter={[10, 10]}>
        <Col lg={4}>
          <FormItemWithoutMargin name="dateStart">
            <DatePicker format={dateFormat} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={4}>
          <FormItemWithoutMargin name="dateEnd">
            <DatePicker format={dateFormat} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={4}>
          <FormItemWithoutMargin name="status">
            <Select placeholder={strings.STATUS}>
              <Option key={2} value={undefined}>
                &nbsp;
              </Option>
              <Option key={1} value={1}>
                {strings.FILLED_IN}
              </Option>
              <Option key={0} value={0}>
                {strings.NOT_FILLED_IN}
              </Option>
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col xs={hasTopManager ? 24 : 0} lg={hasTopManager ? 6 : 0}>
          <FormItemWithoutMargin name="branch" hidden={!hasTopManager}>
            <Select placeholder={strings.BRANCH} allowClear onChange={val => setSelectedBranch(val)}>
              {branches.map(branch => (
                <Option value={branch.id} key={branch.id}>
                  {branch.name}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6}>
          <FormItemWithoutMargin name="stage">
            <StageSelect branch={selectedBranch} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6}>
          <FormItemWithoutMargin name="student">
            <StudentSelect placeholder={strings.STUDENT} />
          </FormItemWithoutMargin>
        </Col>
        {(user?.hasRoles?.teacher || user?.hasRoles?.admin) && (
          <Col lg={6}>
            <FormItemWithoutMargin name="assistant">
              <TeacherSelect placeholder={strings.ASSISTANT} />
            </FormItemWithoutMargin>
          </Col>
        )}
        <Col lg={6}>
          <FormItemWithoutMargin name="supergroup">
            <SupergroupSelect branch={selectedBranch} placeholder={strings.SUPERGROUP} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6}>
          <FormItemWithoutMargin name="tutor">
            <TutorSelect placeholder={strings.FAMILY_TUTOR} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={6}>
          <FormItemWithoutMargin name="tag">
            <Select placeholder={strings.TAG}>
              <Option value={undefined}>&nbsp;</Option>
              {tags.map(item => (
                <Option value={item.id}>{item.name}</Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        {user?.isAssistant && (
          <Col lg={6}>
            <FormItemWithoutMargin name="showAllAssistants" valuePropName="checked">
              <Checkbox>{strings.SHOW_ALL_ASSISTANTS}</Checkbox>
            </FormItemWithoutMargin>
          </Col>
        )}
      </Row>
      <FilterButtons handleClear={onClearFilter} hasSubmitLoading={hasSubmitLoading} />
    </Form>
  );
};
