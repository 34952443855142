import React, { useState, useEffect } from 'react';
import { match } from 'react-router';
import { Col, Spin, message, Input, Row, Select, Form, Checkbox, Button } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { BASE_BRANCHES } from '../../routes';
import { useBranchesRepository } from 'repos/BranchesRepository';
import { BranchClickupType, IBranchClickupItem, IBranchPostParams } from 'types/branches';
import { Branch } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useMediaQuery } from 'react-responsive';
const { Option } = Select;

export const BranchClickupForm = () => {
  const [strings] = useLanguageContext();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { goBack, push } = useHistory();
  const { branches } = useGlobalCollectionsContext();
  const [form] = Form.useForm();
  const branchRepository = useBranchesRepository();
  const [hasLoading, setHasLoading] = useState<boolean>(false);

  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();

  const getBranchById = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data }: { data: IBranchClickupItem } = await branchRepository.getBranchClickupById(id);
      form.setFieldsValue({ ...data, branch: data?.branch?.id });
    } finally {
      setHasLoading(false);
    }
  };

  const onSubmit = async (params: IBranchPostParams): Promise<any> => {
    setHasLoading(true);
    try {
      if (id) {
        await branchRepository.editBranchClickup(id, params);
        message.success(strings.SUCCESSFULLY_UPDATED, 2);
      } else {
        await branchRepository.addBranchClickup(params);
        message.success(strings.SUCCESSFULLY_CREATED, 2);
      }
      push(`${BASE_BRANCHES}?tab=clickup`);
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getBranchById();
    }
  }, [id]);

  return (
    <Container>
      <StPageHeader onBack={goBack} title={id ? strings.EDIT : strings.CREATE} />
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Spin spinning={hasLoading}>
          <Row gutter={[10, 10]} align="middle">
            <Col xs={24} lg={6}>
              <Form.Item
                name="branch"
                label={strings.BRANCH}
                rules={[{ required: true, message: `${strings.BRANCH} ${strings.IS_REQUIRED}` }]}
              >
                <Select>
                  {branches.map((branch: Branch) => (
                    <Option key={branch.id} value={branch.id}>
                      {branch.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                name="type"
                label={strings.TYPE}
                rules={[{ required: true, message: `${strings.TYPE} ${strings.IS_REQUIRED}` }]}
              >
                <Select placeholder={strings.TYPE}>
                  {Object.values(BranchClickupType).map((item: string) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                name="token"
                label={strings.TOKEN}
                rules={[{ required: true, message: `${strings.TOKEN} ${strings.IS_REQUIRED}` }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item name="sourceUrl" label={strings.SOURCE_URL}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                name="taskList"
                label={strings.TASK_LIST}
                rules={[{ required: true, message: `${strings.TASK_LIST} ${strings.IS_REQUIRED}` }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item
                name="taskStatus"
                label={strings.TASK_STATUS}
                rules={[{ required: true, message: `${strings.TASK_STATUS} ${strings.IS_REQUIRED}` }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item name="taskAssignee" label={strings.TASK_ASSIGNEE}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item name="isActive" valuePropName="checked" initialValue={true}>
                <Checkbox style={{ ...(!isMobile && { paddingTop: 30 }) }}>{strings.ACTIVE}</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item name="sourceUrl" label={strings.CHECKLIST_NAME_CHECKLIST_WILL_BE_CREATED_IF_NAME_IS_NOT_EMPTY}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[10, 10]} align="middle">
            <Col xs={24} lg={12}>
              <Form.Item name="checklistItems" label={strings.CHECKLIST_ITEMS_EVERY_LINE_IS_ONE_ITEM}>
                <Input.TextArea style={{ height: 300 }} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item name="subtasks" label={strings.SUBTASKS_EVERY_LINE_IS_ONE_SUBTASK}>
                <Input.TextArea style={{ height: 300 }} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={6}>
              <Button type="primary" size="large" htmlType="submit" loading={hasLoading}>
                {id ? strings.EDIT : strings.ADD}
              </Button>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Container>
  );
};
