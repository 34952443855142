import React, { Fragment } from 'react';
import { Alert, Row, Col } from 'antd';
import { ChroniclesItem } from 'Admin/People/Families/Family/CollaborationHistory/ChroniclesItem';
import { Register } from 'Admin/People/Families/Family/CollaborationHistory/Types/Register';
import { Sms } from 'Admin/People/Families/Family/CollaborationHistory/Types/Sms';
import { Call } from 'Admin/People/Families/Family/CollaborationHistory/Types/Call';
import { Task } from 'Admin/People/Families/Family/CollaborationHistory/Types/Task';
import { Text } from 'Admin/People/Families/Family/CollaborationHistory/Types/Text';
import {
  StatusChanged,
  InvoiceCreated,
  InvoiceStatusChanged,
  TypeChroniclesItem,
  Register as IRegister,
  Call as ICall,
  Sms as ISms,
  Task as ITask,
  Text as IText
} from 'types/Family/collaborationHistory';
import { FamilyCollabHystoryTypes } from './index';

interface ChroniclesProps {
  chroniclesItems: TypeChroniclesItem[];
}

/**
 * @description Лента Collaboration history
 * @param {array} chroniclesItems - component props
 * @return {React.ReactNode}
 */
export const Chronicles = ({ chroniclesItems }: ChroniclesProps) => {
  return (
    <Row gutter={[10, 10]}>
      {chroniclesItems.map((item: TypeChroniclesItem): React.ReactNode => {
        const { type } = item;

        if (type === FamilyCollabHystoryTypes.register) {
          return <Register key={item.id} data={item as IRegister} />;
        } else if (type === FamilyCollabHystoryTypes.sms) {
          return <Sms key={item.id} data={item as ISms} />;
        } else if (type === FamilyCollabHystoryTypes.call) {
          return <Call key={item.id} data={item as ICall} />;
        } else if (type === FamilyCollabHystoryTypes.task) {
          return <Task key={item.id} data={item as ITask} />;
        } else if (type === FamilyCollabHystoryTypes.text) {
          return <Text key={item.id} data={item as IText} />;
        }

        return <ChroniclesItem key={item.id} data={item as StatusChanged | InvoiceCreated | InvoiceStatusChanged} />;
      })}

      {chroniclesItems.length === 0 && (
        <Col lg={8}>
          <Alert message="Collaboration history is empty" type="info" showIcon />
        </Col>
      )}
    </Row>
  );
};

export default { Chronicles };
