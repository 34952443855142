import React from 'react';
import { Text as IText } from 'types/Family/collaborationHistory';
import { Card, Col } from 'antd';
import { dateTimeWithoutSecondFormat } from 'helpers/dates';
import dayjs from 'dayjs';

interface TextProps {
  data: IText;
}

/**
 * @description Chronicle type text
 * @param {TextProps} props
 * @return {React.ReactNode}
 */
export const Text = (props: TextProps) => {
  const { data } = props;
  const { date, description, admin } = data || {};
  const formatDate = dayjs(date).format(dateTimeWithoutSecondFormat);
  return (
    <Col lg={24}>
      <Card title={`Note by ${admin?.surname ?? ''} ${admin?.name}`} extra={formatDate}>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </Card>
    </Col>
  );
};

export default { Text };
