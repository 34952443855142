import React, { useState, useEffect, useCallback } from 'react';
import { Col, Input, Radio, Checkbox, Row } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { useLanguageContext } from 'contexts/LanguageContext';

interface SmsFormProps {
  smsTextChanged: (smsText: string) => void;
  sendToParentChanged: (selected: boolean) => void;
  readonly hasShouldClearSms: boolean;
  setHasShouldClearSms?: (value: boolean) => void;
}

/**
 * @description Форма отправки смс-сообщений
 * @param {object} props
 * @return {React.ReactNode}
 */
export const SmsForm = React.memo(
  ({ smsTextChanged, sendToParentChanged, hasShouldClearSms, setHasShouldClearSms }: SmsFormProps) => {
    const [strings] = useLanguageContext();
    const [smsText, setSmsText] = useState<string>('');
    const [hasSendToParent, setHasSendToParent] = useState<boolean>(false);
    const [hasSmsBlockVisible, setHasSmsBlockVisible] = useState<boolean>(false);

    const handleSmsTextChange = useCallback(
      (smsValue: string): void => {
        setSmsText(smsValue);
        smsTextChanged(smsValue);
      },
      [smsTextChanged]
    );

    const handleRadioChange = useCallback(
      (selected: boolean): void => {
        setHasSendToParent(selected);
        sendToParentChanged(selected);
      },
      [sendToParentChanged]
    );

    const setSmsBlockVisibility = useCallback(
      (checked: boolean): void => {
        setHasSmsBlockVisible(checked);
        if (!checked) handleSmsTextChange('');
      },
      [handleSmsTextChange]
    );

    useEffect(() => {
      if (hasShouldClearSms) {
        handleSmsTextChange('');
        handleRadioChange(false);
        setHasSmsBlockVisible(false);
        setHasShouldClearSms(false);
      }
    }, [hasShouldClearSms]);

    return (
      <Row gutter={[10,10]}>
        <Col lg={24} xs={24}>
          <Checkbox checked={hasSmsBlockVisible} onChange={e => setSmsBlockVisibility(e.target.checked)}>
            {strings.SEND_SMS_TO_PARTICIPANTS_WILL_BE_SENT_AUTOMATICALLY_AFTER_EVENT_CREATION}
          </Checkbox>
        </Col>
        {hasSmsBlockVisible ? (
          <>
            <Col lg={24} xs={24}>
              <Input.TextArea
                value={smsText}
                onChange={e => handleSmsTextChange(e.target.value)}
                placeholder={strings.SMS_TEXT}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Radio.Group
                value={hasSendToParent}
                onChange={(e: RadioChangeEvent) => handleRadioChange(e.target.value)}
              >
                <Radio value={false}>{strings.SEND_TO_PARTICIPANTS}</Radio>
                <Radio value={true}>{strings.SEND_TO_PARTICIPANTS_PARENTS}</Radio>
              </Radio.Group>
            </Col>
          </>
        ) : null}
      </Row>
    );
  }
);

export default { SmsForm };
