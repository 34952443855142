import React from 'react';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { ProductsContainer, Product, ProductTitle, ProductValue } from '../../styles';

// Types
import { Product as IProduct, CustomProduct } from 'types';
import { FinanceReportTeacher } from 'types/Reports';
import { ByProducts } from 'types/Family';

export const columns = (productTitles: IProduct[], strings) => [
  {
    title: strings.NAME,
    dataIndex: 'teacherName',
    render: (name: string, teacher: FinanceReportTeacher) => <Link to={`/teachers/${teacher.teacherId}`}>{name}</Link>
  },
  {
    title: strings.BALANCE_BY_THE_START_OF_PERIOD,
    dataIndex: 'startBalance',
    className: 'big-number'
  },
  {
    title: strings.PAYMENT_SUM,
    dataIndex: 'payments',
    className: 'big-number'
  },
  {
    title: strings.PAYMENT_SUM_C_1_C_2,
    dataIndex: 'paymentsWithC1C2',
    className: 'big-number'
  },
  {
    title: strings.СHARGE_FOR_EXTRAS,
    dataIndex: 'chargeForExtras',
    className: 'big-number'
  },
  {
    title: () => (
      <Tooltip title={strings.TOTAL_PAYMENT_SUM_PAYMENT_SUM_C_1_C_2} overlayClassName="fullwide">
        {strings.TOTAL_PAYMENT_SUM}
      </Tooltip>
    ),
    dataIndex: 'totalPayments',
    className: 'big-number'
  },
  {
    title: strings.PRODUCTS,
    dataIndex: 'byProducts',
    width: 500,
    render: (byProducts: ByProducts) => {
      const products = productTitles.map((product: IProduct, idx: number) => {
        return {
          title: product.name,
          value: byProducts[product.id],
          key: idx
        };
      });

      return (
        <ProductsContainer>
          {products.map((product: CustomProduct) => (
            <Product key={product.key}>
              <ProductTitle>{product.title}</ProductTitle>
              <ProductValue>{product.value > 0 ? product.value : '—'}</ProductValue>
            </Product>
          ))}
        </ProductsContainer>
      );
    }
  },
  {
    title: strings.CORRECTIONS,
    dataIndex: 'correctionsC1',
    width: 100,
    render: (correctionsC1: number, teacher: FinanceReportTeacher) => {
      return (
        <ProductsContainer width={100}>
          <Product width={50}>
            <ProductTitle>C1</ProductTitle>
            <ProductValue>{correctionsC1}</ProductValue>
          </Product>
          <Product width={50}>
            <ProductTitle>C2</ProductTitle>
            <ProductValue>{teacher?.correctionsC2}</ProductValue>
          </Product>
        </ProductsContainer>
      );
    }
  },
  {
    title: strings.WORKING_HOURS,
    key: 'workingHours',
    width: 200,
    render: (teacher: FinanceReportTeacher) => {
      const { academicHours, clockHours } = teacher;
      return (
        <ProductsContainer width={200}>
          <Product width={50}>
            <ProductTitle>Academic</ProductTitle>
            <ProductValue>{academicHours}</ProductValue>
          </Product>
          <Product width={50}>
            <ProductTitle>Clock</ProductTitle>
            <ProductValue>{clockHours}</ProductValue>
          </Product>
        </ProductsContainer>
      );
    }
  },
  {
    title: (
      <Tooltip placement="left" title={strings.BALANCE_CHECK_EQUATION}>
        {strings.BALANCE_CHECK}
      </Tooltip>
    ),
    dataIndex: 'balanceCheck',
    className: 'big-number'
  }
];
