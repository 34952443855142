import {
  dateFormat,
  reversedDateFormatForBackend,
  dateFormatForLesson,
  dateFormatForBackend,
  dateTimeFormat
} from './dates';
import { responsive } from './responsive';
import { copyingText } from './copyingText';
import { hasRussia, hasMoscowBranch, hasBranch, hasInvoiceMonthFinanceSystem } from './branches';
import { downloadFile, downloadFileByUrl, downloadSomeFile, getAttachmentLink } from './downloadFiles';
import { getFormData, getAllFormData } from './getFormData';
import { beforeImageUpload, beforeAudioUpload } from './beforeUpload';
import { maxTagPlaceholder } from './maxTagPlaceholder';
import { hasRole, getRole, hasAdmin } from './roles';
import { checkChildrenErrors, checkPayersErrors, hasErrorsFields } from './validation';
import { getIsLocalRef, isLocalLaunch } from './isLocal';
import { defineObjectProp } from './defineObjectProp';
import { replaceElementForIndex } from './replaceElementForIndex';
import { getRandomColor } from './getRandomColor';
import { getBase64 } from './getBase64';
import { checkEmptyFilter } from './checkEmptyFilter';
import { getDayName } from './getDayName';
import { languageExperience } from './languageExperience';
import { booleanRender } from './booleanRender';
import { getYoutubeVideoUrl, splitYoutubeUrl, checkYoutubeUrl } from './youtube';
import { createTextLinks } from './createTextLinks';
import { convertStatusesToArray } from './convertStatusesToArray';
import { setCurrency } from './setCurrency';
import { errorFormat } from './errorFormat';
import { scrollTo, scrollTop } from './scroll';
import { createElementMock } from './createElementMock';
import { multiplyWageByCoefficient } from './multiplyWageByCoefficient';
import { inputNumberParser, inputNumberFormatter } from './inputNumberProps';
import { generateColumnsForTable } from './generateColumnsForTable';
import { autosave } from './autosave';
import { PhoneOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

if (!process.env.REACT_APP_BACKEND_LOCATION) {
  throw new Error('No .env configuration for backend found!');
}

export const BACKEND_LOCATION = process.env.REACT_APP_BACKEND_LOCATION;
export const AMI_5_LOCATION = process.env.REACT_APP_AMI_5_BACKEND_LOCATION;

export function getUrlForStaticFiles() {
  if (isLocalLaunch) {
    return 'https://wombat.londongates.org/';
  }

  return 'https://ami.londongates.org/';
}

// if (isLocalLaunch && (BACKEND_LOCATION === AMI_URL || BACKEND_LOCATION === AMIGO_URL)) {
//   throw new Error('You use production API!');
// }

Object.defineProperty(Array.prototype, 'isNotEmpty', {
  value: function () {
    return this.length !== 0;
  }
});

Object.defineProperty(Array.prototype, 'isEmpty', {
  value: function () {
    return this.length === 0;
  }
});

export const convertBooleanToNumber = (fields: any): object => {
  let result = {};
  for (const [key, value] of Object.entries(fields)) {
    const object = typeof value === 'boolean' ? { [key]: Number(value) } : { [key]: value };
    result = Object.assign({}, result, object);
  }

  return result;
};

export const getAvatarUrl = (url: string) => {
  return `${AMI_5_LOCATION}${url}`;
};

export const getCallPhoneButton = phone => {
  if (phone) {
    return (
      <a href={`tel:${phone}`}>
        <Button icon={<PhoneOutlined />} style={{ margin: 0 }} />
      </a>
    );
  }
  return null;
};

export {
  isLocalLaunch,
  getIsLocalRef,
  defineObjectProp,
  replaceElementForIndex,
  getRandomColor,
  checkChildrenErrors,
  checkPayersErrors,
  checkEmptyFilter,
  getDayName,
  hasRole,
  getRole,
  hasAdmin,
  hasMoscowBranch,
  maxTagPlaceholder,
  languageExperience,
  hasErrorsFields,
  beforeImageUpload,
  beforeAudioUpload,
  getFormData,
  getAllFormData,
  getAttachmentLink,
  downloadFile,
  downloadSomeFile,
  downloadFileByUrl,
  hasRussia,
  booleanRender,
  getYoutubeVideoUrl,
  responsive,
  convertStatusesToArray,
  splitYoutubeUrl,
  checkYoutubeUrl,
  setCurrency,
  hasBranch,
  createTextLinks,
  errorFormat,
  scrollTo,
  scrollTop,
  createElementMock,
  dateFormat,
  dateFormatForLesson,
  dateFormatForBackend,
  reversedDateFormatForBackend,
  dateTimeFormat,
  copyingText,
  getBase64,
  multiplyWageByCoefficient,
  inputNumberParser,
  inputNumberFormatter,
  generateColumnsForTable,
  autosave,
  hasInvoiceMonthFinanceSystem
};
