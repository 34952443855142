import { Col, Form, FormListFieldData, InputNumber, Row, Select, Input } from 'antd';
import { Rate } from 'types';
import React from 'react';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { NamePath } from 'antd/lib/form/interface';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Option } = Select;

type ServicesFormProps = {
  field?: FormListFieldData;
  rates: Rate[];
};

export const ServicesForm: React.FC<ServicesFormProps> = ({ field, rates }) => {
  const [strings] = useLanguageContext();
  const { subjects } = useGlobalCollectionsContext();
  const getName = (name): NamePath => (field ? [field.name, name] : name);

  return (
    <Row gutter={10} style={{ flex: 1 }}>
      <Col lg={6} xs={12}>
        <Form.Item name={getName('serviceName')} label={strings.SERVICE_NAME}>
          <Input />
        </Form.Item>
      </Col>
      <Col lg={4} xs={12}>
        <Form.Item name={getName('amount')} label={strings.AMOUNT}>
          <InputNumber />
        </Form.Item>
      </Col>
      <Col lg={14} xs={24}>
        <Form.Item name={getName('price')} label={strings.PRICE}>
          <Select>
            {rates.map((rate: Rate) => (
              <Option key={rate.id} value={rate.id}>
                {rate.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default { ServicesForm };
