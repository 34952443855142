import React from 'react';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Button, Input, Row, Col, Radio, message, Tooltip, Typography } from 'antd';
import { IExpertAssessment } from 'types/accessment';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { scrollTop } from 'helpers/scroll';
import { useLanguageContext } from 'contexts/LanguageContext';
import { isEmpty } from 'lodash';
import { useAssessmentsRepository } from 'repos/AssessmentsRepository';
import { checkTypeForInput } from 'helpers/booleanFomatValues';

const { Text } = Typography;

interface ExpertAssessmentOldProps extends FormComponentProps {
  expertAssessment: IExpertAssessment;
}

/**
 * @description ExpertAssessmentOld component
 * @return {React.ReactNode}
 */
const ExpertAssessmentOldTemplate = (props: ExpertAssessmentOldProps) => {
  const [strings] = useLanguageContext();
  const assessmentsRepository = useAssessmentsRepository();
  const tableLink = 'https://docs.google.com/spreadsheets/d/1VhUpl65zZYw8Lh8pCO8N5OWkpvpfJqF4r3eP9EarJ4M/edit#gid=0 ';
  const {
    form: { getFieldDecorator, setFieldsValue, validateFieldsAndScroll },
    expertAssessment: {
      type,
      expertCurriculum,
      expertCurriculumComment,
      expertMaterials,
      expertMaterialsComment,
      expertHomework,
      expertHomeworkComment,
      expertFinalMark,
      expertRecommendation,
      id
    }
  } = props;

  /**
   * @description Очистка формы
   * @param {string} fieldName
   * @return {void}
   */
  const clearFieldByName = (fieldName: string): void => setFieldsValue({ [fieldName]: '' });

  /**
   * @description Обработчик на submit формы
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values) => {
      message.loading(strings.EXPERT_ASSESSMENT_UPDATING, 1);
      if (isEmpty(errors)) {
        try {
          await assessmentsRepository.editAssessmentByLessonId(id, { type, ...values });
          message.success(strings.EXPERT_ASSESSMENT_UPDATING);
          scrollTop();
        } catch {}
      }
    });
  };

  const getTitle = () => {
    return (
      <div>
        {strings.WATCH_SEVERAL_EXCERPTS}
        <ul>
          <li>{strings.THE_CONTENT_OF_THE_LESSON_CORRESPONDS_TO_THE_CURRICULUM}</li>
          <li>{strings.THE_LESSON_OUTCOMES_ARE_ACHIEVED}</li>
          <li>{strings.THE_LESSON_IS_CONNECTED_WITH_THE_PREVIOUS_LESSONS_OF_THE_CYCLE}</li>
          <li>{strings.THE_TEACHERS_CHOICE_OF_METHODS_IS_EFFECTIVE_FOR_THE_AGE_GROUP}</li>
          <li>{strings.THE_TEACHERS_CHOICE_OF_METHODS_IS_EFFECTIVE_FOR_THE_SUBJECT}</li>
          <li>{strings.THE_TEACHER_PRONOUNCES_THE_TERMS_AND_KEY_WORDS_CORRECTLY}</li>
          <li>
            {
              strings.THE_TEACHER_DEMONSTRATES_A_GOOD_LEVEL_OF_COMPETENCE_E_G_DOESNT_MAKE_ANY_FACTUAL_ERRORS_OR_PRESENT_INFORMATION_THAT_IS_NOT_TRUE
            }
          </li>
        </ul>
      </div>
    );
  };

  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Row>
        <Col lg={24}>
          <MyFormItem
            label={
              <>
                {strings.LESSON_VIDEO}
                <Tooltip overlayStyle={{ minWidth: 400 }} title={getTitle}>
                  <InfoCircleTwoTone style={{ fontSize: 22, margin: 10 }} twoToneColor="#1890ff" />
                </Tooltip>
                <a href={tableLink} target="_blank">
                  {strings.TABLE}
                </a>
              </>
            }
          >
            {getFieldDecorator('expertCurriculum', {
              initialValue: expertCurriculum,
              rules: [{ required: false }]
            })(
              <Radio.Group>
                <Radio.Button value="ni">Ni</Radio.Button>
                <Radio.Button value="s">S</Radio.Button>
                <Radio.Button value="g">G</Radio.Button>
              </Radio.Group>
            )}
            <Button
              size="middle"
              style={{ marginInlineStart: 10 }}
              onClick={() => clearFieldByName('expertCurriculum')}
            >
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem label={strings.COMMENT}>
            {getFieldDecorator('expertCurriculumComment', {
              initialValue: checkTypeForInput(expertCurriculumComment),
              rules: [{ required: false }]
            })(<Input.TextArea placeholder={strings.COMMENT} />)}
          </MyFormItem>
        </Col>
      </Row>
      <Row>
        <Col lg={24}>
          <MyFormItem
            label={
              <>
                {strings.MATERIALS_ATTACHED_TO_THIS_AND_2_PREVIOUS_CLASSES}
                <Tooltip
                  title={`${strings.MATERIALS_ATTACHED_TO_THIS_AND_2_PREVIOUS_CLASSES_1}${strings.MATERIALS_ATTACHED_TO_THIS_AND_2_PREVIOUS_CLASSES_2}`}
                >
                  <InfoCircleTwoTone style={{ fontSize: 22, margin: 10 }} twoToneColor="#1890ff" />
                </Tooltip>
              </>
            }
          >
            {getFieldDecorator('expertMaterials', {
              initialValue: expertMaterials,
              rules: [{ required: false }]
            })(
              <Radio.Group>
                <Radio.Button value="ni">Ni</Radio.Button>
                <Radio.Button value="s">S</Radio.Button>
                <Radio.Button value="g">G</Radio.Button>
              </Radio.Group>
            )}
            <Button size="middle" style={{ marginInlineStart: 10 }} onClick={() => clearFieldByName('expertMaterials')}>
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem label={strings.COMMENT}>
            {getFieldDecorator('expertMaterialsComment', {
              initialValue: checkTypeForInput(expertMaterialsComment),
              rules: [{ required: false }]
            })(<Input.TextArea placeholder={strings.COMMENT} />)}
          </MyFormItem>
        </Col>
      </Row>
      <Row>
        <Col lg={24}>
          <div style={{ color: '#262626' }}>
            {strings.ASSESSMENT_OF_USEFULNESS_OF_THE_HOMEWORK_ATTACHED_TO_THIS_AND_2_PREVIOUS_CLASSES}
            <Tooltip title={`${strings.ASSESSMENT_TEXT_1}${strings.ASSESSMENT_TEXT_2}${strings.ASSESSMENT_TEXT_3}`}>
              <InfoCircleTwoTone style={{ fontSize: 22, margin: 10 }} twoToneColor="#1890ff" />
            </Tooltip>
            <a
              target="_blank"
              href="https://docs.google.com/a/londongates.org/document/d/1B9s6tkmg6FWRlzgJX74QP8Sf1URyafLWH7HuDr-ZxdQ/edit?usp=sharing"
            >
              {strings.DOCUMENT}
            </a>
            :
          </div>
          <MyFormItem>
            {getFieldDecorator('expertHomework', {
              initialValue: expertHomework,
              rules: [{ required: false }]
            })(
              <Radio.Group>
                <Radio.Button value="ni">Ni</Radio.Button>
                <Radio.Button value="s">S</Radio.Button>
                <Radio.Button value="g">G</Radio.Button>
              </Radio.Group>
            )}
            <Button size="middle" style={{ marginInlineStart: 10 }} onClick={() => clearFieldByName('expertHomework')}>
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem label={strings.COMMENT}>
            {getFieldDecorator('expertHomeworkComment', {
              initialValue: checkTypeForInput(expertHomeworkComment),
              rules: [{ required: false }]
            })(<Input.TextArea placeholder={strings.COMMENT} />)}
          </MyFormItem>
        </Col>
      </Row>
      <Row>
        <Col lg={24} style={{ background: '#e6f7ff', padding: '10px 20px 30px 20px', borderRadius: '5px' }}>
          <MyFormItem
            label={
              <>
                {strings.SUMMARY_COMMENTS}
                <Text type="secondary" style={{ float: 'right', fontSize: '12px' }}>
                  {strings.ONLY_THIS_PART_IS_VISIBLE_TO_THE_TEACHER}
                </Text>
              </>
            }
          >
            {getFieldDecorator('expertRecommendation', {
              initialValue: checkTypeForInput(expertRecommendation),
              rules: [{ required: false }]
            })(<Input.TextArea style={{ height: 300, width: '100%' }} placeholder={strings.COMMENT} />)}
          </MyFormItem>
          <MyFormItem label={strings.FINAL_MARK}>
            {getFieldDecorator('expertFinalMark', {
              initialValue: expertFinalMark,
              rules: [{ required: false }]
            })(
              <Radio.Group>
                <Radio.Button value="ni">Ni</Radio.Button>
                <Radio.Button value="s">S</Radio.Button>
                <Radio.Button value="g">G</Radio.Button>
              </Radio.Group>
            )}
            <Button size="middle" style={{ marginInlineStart: 10 }} onClick={() => clearFieldByName('expertFinalMark')}>
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Col lg={24}>
          <Button type="primary" size="large" htmlType="submit">
            {strings.SAVE}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export const ExpertAssessmentOld = Form.create<ExpertAssessmentOldProps>({})(ExpertAssessmentOldTemplate);

export default { ExpertAssessmentOld };
