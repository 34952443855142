import React from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, Select, Button } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { TagFamily } from 'types/Family';
import { isEmpty } from 'lodash';
import { useLanguageContext } from 'contexts/LanguageContext';

interface ModalProps extends FormComponentProps {
  visible: boolean;
  tags: TagFamily[];
  modalClose: () => void;
  addNewTagToFamilyContract: (familyId: number, contractId: number, tagId: number) => Promise<any>;
  contractIdModal: number;
  familyId: number;
}

const { Option } = Select;

/**
 * @description Modal tags contracts
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const ModalTagsTemplate = (props: ModalProps) => {
  const {
    visible,
    tags,
    modalClose,
    addNewTagToFamilyContract,
    contractIdModal,
    familyId,
    form: { validateFields, getFieldDecorator, resetFields }
  } = props;
  const [strings] = useLanguageContext();

  /**
   * @description Submit формы
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();

    validateFields(async (errors, values) => {
      if (isEmpty(errors)) {
        const { tag } = values;
        await addNewTagToFamilyContract(familyId, contractIdModal, tag);
        modalClose();
        resetFields();
      }
    });
  };

  const getFooter = (): React.ReactElement[] => {
    const buttons = [
      <Button key="cancelBtn" onClick={modalClose}>
        {strings.CANCEL}
      </Button>,
      <Button key="addBtn" type="primary" htmlType="submit" onClick={handleSubmit}>
        {strings.ADD_TAG}
      </Button>
    ];

    return buttons;
  };

  return (
    (<Modal title={strings.ADD_NEW_TAG_CONTRACTS} open={visible} onCancel={modalClose} footer={getFooter()}>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <MyFormItem>
          {getFieldDecorator('tag', {
            rules: [{ required: true, message: strings.PLEASE_SELECT_TAG }]
          })(
            <Select placeholder={strings.SELECT_TAG_CONTRACT}>
              {tags.map((tag: TagFamily) => (
                <Option key={tag.id} value={tag.id}>
                  {tag.name}
                </Option>
              ))}
            </Select>
          )}
        </MyFormItem>
      </Form>
    </Modal>)
  );
};

export const ModalTags = Form.create<ModalProps>({})(ModalTagsTemplate);

export default { ModalTags };
