import React, { useEffect, useState } from 'react';
import { FilePdfOutlined } from '@ant-design/icons';
import { Button, Typography, Checkbox, Row } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container } from 'Global/GlobalStyle';
import { useUserContext } from 'contexts/UserContext';
import { useFamilyRepository } from 'repos/FamilyRepository';

const { Title, Text } = Typography;

export const Contract = () => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const familyRepository = useFamilyRepository();
  const [activeContract, setActiveContract] = useState(null);

  const fetchFamilyContract = async (): Promise<any> => {
    const {
      data: { contract }
    } = await familyRepository.getActiveFamilyContract();
    setActiveContract(contract);
  };

  const downloadContract = async (): Promise<any> => {
    familyRepository.downloadActiveFamilyContract(activeContract?.id, activeContract?.contractNumber);
  };

  useEffect(() => {
    if (user) {
      fetchFamilyContract();
    }
  }, [user]);

  return (
    <Container>
      <Title level={4}>{strings.CONTRACT}</Title>
      <Row>
        <Row>
          <Button type="link" style={{ padding: 0 }} onClick={downloadContract}>
            {strings.HERE}
            <FilePdfOutlined />
          </Button>{' '}
          {strings.YOU_CAN_ACCESS}
        </Row>
        <Row style={{ float: 'right' }}>
          <Checkbox defaultChecked disabled />
          <Text type="secondary">
            {` ${strings.I_AGREE} ${activeContract?.acceptanceDate} ${activeContract?.acceptedBy?.surname} ${activeContract?.acceptedBy?.name}`}
          </Text>
        </Row>
      </Row>
    </Container>
  );
};

export default { Contract };
