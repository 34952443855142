import React from 'react';
import { CheckCircleTwoTone, EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { BASE_SOURCE_OF_REFERENCE } from '../routes';

export const columns = strings => [
  {
    title: strings.ID,
    dataIndex: 'id',
    key: 'id',
    width: 50,
    render: (id: number) => <Link to={`${BASE_SOURCE_OF_REFERENCE}/${id}`}>{id}</Link>
  },
  {
    title: strings.TITLE,
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: strings.IS_ACTIVE,
    dataIndex: 'isActive',
    key: 'isActive',
    width: 120,
    render: (isActive: boolean) =>
      isActive ? (
        <CheckCircleTwoTone style={{ fontSize: 24 }} twoToneColor="#52c41a" />
      ) : (
        <span style={{ color: '#999' }}>—</span>
      )
  },
  {
    dataIndex: 'id',
    key: 'actions',
    width: 100,
    render: (id: number) => (
      <Link to={`${BASE_SOURCE_OF_REFERENCE}/${id}/edit`}>
        <Button icon={<EditOutlined />} style={{ margin: 0 }} />
      </Link>
    )
  }
];

export default { columns };
