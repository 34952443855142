import React, { Fragment, useState, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select, Spin, Tooltip } from 'antd';
import { GetFieldDecoratorOptions } from '@ant-design/compatible/lib/form/Form';

import { debounce } from 'lodash';
import { Group, GetCourseGroupsParams } from 'types/education';
import { AxiosResponse } from 'axios';
import { useUserContext } from 'contexts/UserContext';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Option } = Select;

interface GroupSelectProps {
  initGroup?: string | number;
  getFieldDecorator?<T extends Object = {}>(
    id: keyof T,
    options?: GetFieldDecoratorOptions
  ): (node: React.ReactNode) => React.ReactNode;
  mode?: 'multiple' | 'tags';
  placeholder?: string;
  name: string;
  disabled?: boolean;
  required?: boolean;
  params?: GetCourseGroupsParams;
  onChange?: (group: number) => void;
}

/**
 * @description Компонент поиска по группам
 * @param {object} props
 * @return {React.ReactNode}
 */
export const GroupSelect = (props: GroupSelectProps) => {
  const { placeholder, onChange, getFieldDecorator, mode, name, initGroup, disabled, required, params } = props;
  const [hasLoadGroups, setHasLoadGroups] = useState<boolean>(false);
  const [groups, setGroups] = useState<Group[]>([]);
  const [baseGroup, setBaseGroup] = useState<Group>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [typing, setTyping] = useState<Boolean>(false);

  const coursesRepository = useCoursesRepository();
  const [strings] = useLanguageContext();
  const [user] = useUserContext();

  /**
   * @description Поиск по группам
   * @param {string} group
   * @return {Promise<void>}
   */
  const fetchGroups = async (group: string): Promise<any> => {
    setSearchValue(group);
    if (group) {
      setHasLoadGroups(true);
      const getCourseGroupsParams = { name: group, limit: 500, branch: user?.branch?.id, active: 1, ...params };
      try {
        const {
          data: { items }
        } = await coursesRepository.getCourseGroups(getCourseGroupsParams);
        setGroups(items.filter((group: Group) => group.groupName));
      } finally {
        setHasLoadGroups(false);
      }
    }
  };

  /**
   * @description Получение группы по id
   * @param {string} groupId
   * @return {Promise<any>}
   */
  const getGroupById = async (groupId: string | number): Promise<any> => {
    if (!groupId) return;
    coursesRepository
      .getCourseGroupById(groupId)
      .then(({ data }: AxiosResponse) => {
        setBaseGroup(data);
      })
      .catch(() => {});
  };

  /**
   * @description Формирование Node для Select
   * @return {React.ReactNode | React.ReactNode[]}
   */
  const getInitGroupNode = (): React.ReactNode | React.ReactNode[] => {
    if (!baseGroup) return;
    return (
      <Option value={String(baseGroup.id)} key={baseGroup.id}>
        <Tooltip title={baseGroup.groupName} overlayStyle={{ zIndex: 9999 }}>
          {baseGroup.groupName}
        </Tooltip>
      </Option>
    );
  };

  const options = groups.map((group: Group) => (
    <Option value={String(group.id)} key={group.id}>
      <Tooltip title={group.groupName} overlayStyle={{ zIndex: 9999 }}>
        {group.groupName}
      </Tooltip>
    </Option>
  ));

  const select = (
    <Select
      showSearch
      allowClear
      mode={mode}
      placeholder={placeholder || strings.GROUPS}
      defaultActiveFirstOption={false}
      filterOption={false}
      onSearch={debounce(fetchGroups, 800)}
      style={{ width: '100%' }}
      notFoundContent={
        hasLoadGroups ? <Spin size="small" /> : <p>{!typing ? strings.START_TYPING : strings.NOT_FOUND}</p>
      }
      maxTagCount="responsive"
      disabled={disabled}
      onChange={onChange}
    >
      {[getInitGroupNode(), ...options]}
    </Select>
  );

  useEffect(() => {
    getGroupById(initGroup);
  }, [initGroup]);

  useEffect(() => {
    if (searchValue) {
      setTyping(true);
    } else {
      setTyping(false);
    }
  }, [searchValue]);

  if (required) {
    return (
      <MyFormItem label={strings.GROUP}>
        {getFieldDecorator(name, {
          initialValue: initGroup && String(initGroup),
          rules: [{ required: required, message: strings.SELECT_GROUP }]
        })(select)}
      </MyFormItem>
    );
  }

  return (
    <Fragment>
      {getFieldDecorator(name, {
        initialValue: initGroup && String(initGroup),
        rules: [{ required: false }]
      })(select)}
    </Fragment>
  );
};

export default { GroupSelect };
