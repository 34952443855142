import React from 'react';
import { Col, Input, Row, Form } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useLanguageContext } from 'contexts/LanguageContext';
import { CommentForParentsPostParams } from 'types/TeacherAccount/Courses';
import dayjs from 'dayjs';
import { FormItemWithoutMargin } from 'Global/styles';
import { useLessonInfoStore } from 'stores/useLessonInfoStore';

const legendStyle = {
  fontSize: '14px',
  lineHeight: 1.5,
  opacity: 0.6,
  color: '#262626'
};

interface CommentForParentProps {
  parentsComments: CommentForParentsPostParams;
  setHasCommentForParentChanged: (val: boolean) => void;
  hasNeedFeedback: boolean;
}

export const CommentForParent = ({
  setHasCommentForParentChanged,
  hasNeedFeedback,
  parentsComments
}: CommentForParentProps) => {
  const [strings] = useLanguageContext();
  const lessonInfo = useLessonInfoStore(state => state.lessonInfo);

  const { commentForParent, commentForParent2 } = parentsComments;
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  // Модульный комментарий появляется в журналах, дата которых 1 сентября 2023 или позже.
  // В более ранних журналах все по-старому (одно поле для Feedback for parent)
  const isNotModularComment = dayjs(lessonInfo.lesson.startAt).isBefore('2023-09-01');

  const hasBranchAccess =
    lessonInfo?.lesson?.courseGroup?.branch?.id === 1 || lessonInfo?.lesson?.courseGroup?.branch?.id === 2;

  if (isNotModularComment) {
    return (
      <>
        <FormItemWithoutMargin
          name="commentForParent"
          label={strings.FEEDBACK_FOR_PARENT}
          initialValue={commentForParent}
        >
          <Input.TextArea
            style={{ height: 75, border: hasNeedFeedback ? '1px solid red' : '' }}
            onChange={() => setHasCommentForParentChanged(true)}
          />
        </FormItemWithoutMargin>
        {hasBranchAccess && (
          <Row style={{ ...legendStyle, ...(isMobile && { flexDirection: 'column' }) }}>
            <Col>{strings.SUGGESTED_STRUCTURE}</Col>
            <Col>1. {strings.OVERALL_PROGRESS}</Col>
            <Col>2. {strings.SPECIFIC_DETAILS_1_2}</Col>
            <Col>3. {strings.GOAL_WAY_TO_ACHIEVE_IT}</Col>
          </Row>
        )}
      </>
    );
  }

  return (
    <>
      <Form.Item
        label={strings.OVERALL_PROGRESS_SPECIFIC_DETAILS_1_2}
        name="commentForParent"
        initialValue={commentForParent}
      >
        <Input.TextArea
          style={{ border: hasNeedFeedback ? '1px solid red' : '' }}
          onChange={() => setHasCommentForParentChanged(true)}
        />
      </Form.Item>
      <Form.Item label={strings.GOAL_WAY_TO_ACHIEVE_IT} name="commentForParent2" initialValue={commentForParent2}>
        <Input.TextArea onChange={() => setHasCommentForParentChanged(true)} />
      </Form.Item>
    </>
  );
};
