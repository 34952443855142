import { formatRegistersField } from 'helpers/formatRegistersField';
import { ILessonRegisterPostRarams, ILessonRegister } from 'types/lesson';

/**
 * @description Форматирует данные перед отправкой на сервер
 * @param {IRegister[]} registers
 */
export const registersFormat = (registers: ILessonRegister[]): ILessonRegisterPostRarams[] => {
  return registers.map((register: ILessonRegister) => {
    return {
      ...register,
      presence: formatRegistersField(register.presence),
      homeworkDone: formatRegistersField(register?.homeworkDone),
      student: register.student.id
    };
  });
};
