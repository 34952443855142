import React from 'react';
import { CheckCircleTwoTone, MinusCircleTwoTone } from '@ant-design/icons';
import { Tag } from 'antd';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { StyledCard, StyledCardDetails, StyledCardDetailsItem } from 'Global/styles';
import { CardTitle } from 'Global/components/CardTitle';
import dayjs from 'dayjs';
import { dateFormat } from 'helpers';

export function AdminCard(props) {
  const [strings] = useLanguageContext();
  const { branches, roles, ageTags, adminTags } = useGlobalCollectionsContext();
  const { email, branch, tagsAge, sipuni, sipuniNum, org1Id, birthday } = props;

  const TagsAgeList = () =>
    tagsAge?.map(tagId => {
      return <Tag>{ageTags.find(({ id }) => id === Number(tagId))?.name}</Tag>;
    });

  const TagsList = () =>
    props?.tags?.map(tagId => {
      return <Tag>{adminTags.find(({ id }) => id === Number(tagId))?.name}</Tag>;
    });

  const getRoleString = () => {
    return roles.find(role => props?.role === role.id)?.name;
  };

  return (
    <StyledCard style={{ borderRadius: 5 }}>
      <CardTitle {...props} />
      <StyledCardDetails>
        {email && (
          <StyledCardDetailsItem>
            <b>{strings.EMAIL}:</b>
            <span>{email}</span>
          </StyledCardDetailsItem>
        )}
        {branch && (
          <StyledCardDetailsItem>
            <b>{strings.BRANCH}:</b>
            <span>{branches.find(branch => Number(props?.branch) === branch.id)?.name}</span>
          </StyledCardDetailsItem>
        )}
        {props?.role && (
          <StyledCardDetailsItem>
            <b>{strings.ROLE}:</b>
            <span style={{ textAlign: 'right' }}>{getRoleString()}</span>
          </StyledCardDetailsItem>
        )}
        {birthday && (
          <StyledCardDetailsItem>
            <b>{strings.DATE_OF_BIRTH}:</b>
            <span>{birthday ? dayjs(birthday).format(dateFormat) : ''}</span>
          </StyledCardDetailsItem>
        )}
        {org1Id && (
          <StyledCardDetailsItem>
            <b>{strings.ORG_1_ID}:</b>
            <span>{org1Id}</span>
          </StyledCardDetailsItem>
        )}
        {sipuniNum && (
          <StyledCardDetailsItem>
            <b>{strings.INTERNAL_NUMBER}:</b>
            <span>{sipuniNum}</span>
          </StyledCardDetailsItem>
        )}
        <StyledCardDetailsItem>
          <b>{strings.ACTIVE}:</b>
          <span>
            {props?.isActive ? (
              <CheckCircleTwoTone twoToneColor="#52c41a" />
            ) : (
              <MinusCircleTwoTone twoToneColor="#f5222d" />
            )}
          </span>
        </StyledCardDetailsItem>
        {tagsAge && tagsAge.isNotEmpty() && (
          <StyledCardDetailsItem>
            <b>{strings.TAGS_AGE}:</b>
            <span style={{ marginInlineStart: 10 }}>
              <TagsAgeList />
            </span>
          </StyledCardDetailsItem>
        )}
        {props?.tags && props?.tags.isNotEmpty() && (
          <StyledCardDetailsItem>
            <b>{strings.TAGS}:</b>
            <span style={{ marginInlineStart: 10 }}>
              <TagsList />
            </span>
          </StyledCardDetailsItem>
        )}
        {props.comment && (
          <div style={{ marginTop: 10 }}>
            <b>{strings.COMMENT}:</b>
            <p>{props.comment}</p>
          </div>
        )}
      </StyledCardDetails>
    </StyledCard>
  );
}
