import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Flex, Table, Tooltip } from 'antd';
import { downloadSomeFile } from 'helpers';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { IProgressReportForParent } from 'types/Reports';
import { useUserContext } from 'contexts/UserContext';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { upperFirst } from 'lodash';
import { DownloadOutlined } from '@ant-design/icons';

export const WorkProgress = () => {
  // Hooks
  const [strings] = useLanguageContext();
  const { goBack } = useHistory();
  const reportsRepository = useProgressReportsRepository();

  const [progressReport, setProgressReport] = useState<IProgressReportForParent[]>([]);
  const [currentTrimester, setCurrentTrimester] = useState<number | null>(null);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [user] = useUserContext();

  const fetchProgressReport = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await reportsRepository.getProgressReportForParent();
      const updatedData = data.map(item => ({
        ...item,
        students: item.children,
        children: undefined
      }));
      setProgressReport(updatedData);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  const downloadReport = async (params: { trimester: number; student: number }, fileName: string): Promise<any> => {
    // const filename = `${name}.pdf`;
    setHasLoading(true);
    try {
      await downloadSomeFile(`/api/v1/progress-reports/for-parent`, fileName, params, null, true);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  const columns = [
    {
      title: strings.SEMESTER,
      dataIndex: 'trimester',
      key: 'trimester',
      render: trimester => <p>{upperFirst(trimester?.name)}</p>
    },
    {
      title: strings.STUDENT,
      key: 'students',
      render: (record: IProgressReportForParent) => {
        const { trimester, students } = record;
        return (
          <>
            {students.map(student => {
              const studentName = `${student?.user?.name} ${student?.user?.surname}`;
              const fileName = `report ${studentName}`;
              return (
                <Flex gap={5} align="center" style={{ marginBottom: 5 }}>
                  <Tooltip title={strings.DOWNLOAD}>
                    <Button
                      icon={<DownloadOutlined />}
                      onClick={() => downloadReport({ trimester: trimester?.id, student: student?.id }, fileName)}
                    />
                  </Tooltip>
                  <span>{studentName}</span>
                </Flex>
              );
            })}
          </>
        );
      }
    }
  ];

  useEffect(() => {
    fetchProgressReport();
  }, [user]);

  return (
    <ErrorBoundary>
      <Container>
        <StPageHeader onBack={goBack} title={strings.REPORT_ON_PROGRESS} />
        <Table
          dataSource={progressReport}
          rowKey="id"
          columns={columns}
          bordered={false}
          pagination={false}
          loading={hasLoading}
          locale={{ emptyText: strings.NO_DATA }}
        />
      </Container>
    </ErrorBoundary>
  );
};

export default { WorkProgress };
