import styled, { css } from 'styled-components';
import { slideInLeft } from 'Global/animations';

export const HistoryItem = styled.div`
  box-shadow: 0 5px 15px rgba(143, 168, 191, 0.2);
  padding: 18px;
  border-radius: 5px;
  margin: 15px 15px 15px 0px;
  min-height: 180px;

  h4 {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    font-weight: 500;
    align-items: center;
  }
`;

export const HistoryItemFeedback = styled.p`
  margin: 20px 0;
`;

export const Teachers = styled.div`
  margin: 20px;
  padding: 30px 20px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 46px -15px rgb(0 0 0 / 12%);
  .ant-col {
    padding: 0 5px;
  }
`;

export const Teacher = styled.div`
  margin: 10px;
  padding: 30px;
  animation: ${slideInLeft} 0.6s ease-in;

  .ant-checkbox-wrapper {
    margin-top: 8px !important;
  }
  .ant-upload.ant-upload-drag {
    height: 102px;
  }

  .ant-upload-drag-icon {
    margin-bottom: 0 !important;
  }
  .ant-upload-text {
    font-size: 14px;
  }
`;

export const StFilter = styled.div`
  margin: 30px 0;
  .ant-checkbox-wrapper {
    margin: 10px 0;
  }
`;

export const TableWithoutScrollbar = styled.div`
  margin-bottom: 20px;

  & .ant-table-wrapper {
    overflow-x: unset;
  }
`;

export const Edit = styled(Teacher)`
  .ant-upload {
    width: 100%;
  }
  .ant-empty-image {
    height: 63px;
  }
  .ant-empty-image svg {
    width: 70px;
  }
`;
export const StTags = styled.div`
  margin-bottom: 31px;
`;
export const Add = styled(Teacher)``;
export const CreateTransaction = styled(Teacher)``;
export const AddIndividualWage = styled(Teacher)``;

export const AssessmentHistoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;

  & table {
    min-width: auto !important;
  }

  & .ant-table-body {
    overflow-x: auto;
  }
  & .ant-table {
    margin-bottom: 20px;
  }
`;

interface IAssessmentHistoryCol {
  fullwide?: boolean;
}

export const AssessmentHistoryCol = styled.div<IAssessmentHistoryCol>`
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 10px;
  box-sizing: border-box;

  ${props =>
    props.fullwide &&
    css`
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 15px;
    `}
`;

export const AssessmentHistoryLabel = styled.div`
  margin-bottom: 6px;
  color: #999;
`;

export const AssessmentHistoryText = styled.div`
  font-weight: 700;
  font-size: 16px;
`;

export const FinanceHeading = styled.div`
  display: flex;
  margin-bottom: 20px;

  & h3 {
    margin-bottom: 0;
    flex: 1;
  }
`;

export const FinanceHeadingSelect = styled.div`
  flex: 0 0 180px;
  display: flex;
  align-items: center;

  & > span {
    margin-inline-end: 12px;
  }

  & .ant-select {
    margin: 0;
  }
`;

export default {
  Teachers,
  Teacher,
  StFilter,
  Edit,
  Add,
  CreateTransaction,
  AddIndividualWage,
  HistoryItem,
  HistoryItemFeedback,
  TableWithoutScrollbar,
  AssessmentHistoryContainer,
  AssessmentHistoryCol,
  AssessmentHistoryLabel,
  AssessmentHistoryText,
  FinanceHeading,
  FinanceHeadingSelect,
  StTags
};
