import React, { useState, useEffect, useMemo } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { InputNumber, Select, Checkbox, Row, Col, DatePicker, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { TutorSelect } from 'Global/components/FormComponentsCompatible/Selects/TutorSelect';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { convertBooleanToNumber, dateFormat, downloadSomeFile, maxTagPlaceholder } from 'helpers';
import { firstDayOfPrevMonth, lastDayOfPrevMonth } from 'helpers/dates';
import { useFamilyRepository } from 'repos/FamilyRepository';
import { IFamily } from 'types';
import { PaginationInfo } from 'types/global';
import { Dayjs } from 'dayjs';
import { FamilyTable } from '../../People/Families/Table';
import { useConstantsContext } from 'contexts/ConstantsContext';
import { momentFormatValues } from 'helpers/momentFormatValues';
import queryString from 'query-string';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { FilterButtons } from 'Global/components/FilterButtons';

const { Option } = Select;

interface IInvoicesGeneratorProps extends FormComponentProps {
  getFamilies: (params) => Promise<any>;
  defaultFilterDates: { from: Dayjs; to: Dayjs };
}

const InvoicesGeneratorFilter = Form.create<IInvoicesGeneratorProps>({})((props: IInvoicesGeneratorProps) => {
  const {
    form: { getFieldDecorator, getFieldsValue, resetFields },
    getFamilies,
    defaultFilterDates
  } = props;
  const { FAMILY_ACTIVE_CLIENT_STATUS } = useConstantsContext();
  const [strings] = useLanguageContext();
  const { familyStatuses, familyTags: familyTagsList } = useGlobalCollectionsContext();
  const { from, to } = defaultFilterDates;

  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);
  const [hasDowloadLoading, setHasDowloadLoading] = useState<boolean>(false);

  const handleSubmit = async (event): Promise<any> => {
    event.preventDefault();
    const formValues = getFieldsValue();
    await getFamilies(momentFormatValues(formValues));
  };

  const clearFilter = async (): Promise<any> => {
    resetFields();
    await getFamilies(
      momentFormatValues({
        status: [FAMILY_ACTIVE_CLIENT_STATUS],
        ...defaultFilterDates
      })
    );
  };

  const downloadInvoices = async () => {
    const currentFilter = momentFormatValues(convertBooleanToNumber(getFieldsValue()));
    const queryParams = queryString.stringify(currentFilter, { arrayFormat: 'bracket' });
    setHasDowloadLoading(true);
    try {
      await downloadSomeFile(`/api/v1/invoices/riga_invoice_bunch_generator?${queryParams}`, null, null, null, true);
    } finally {
      setHasDowloadLoading(false);
    }
  };

  const extraBtn = (
    <Button icon={<DownloadOutlined />} size="large" danger onClick={downloadInvoices} loading={hasDowloadLoading}>
      {strings.DOWNLOAD}
    </Button>
  );

  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Row>
        <Col lg={8}>
          <TutorSelect name="tutor" getFieldDecorator={getFieldDecorator} style={{ margin: 0 }} />
        </Col>
        <Col lg={8}>
          <Row gutter={15}>
            <Col lg={12}>
              {getFieldDecorator('from', {
                initialValue: from
              })(<DatePicker format={dateFormat} placeholder={strings.FROM} />)}
            </Col>
            <Col lg={12}>
              {getFieldDecorator('to', {
                initialValue: to
              })(<DatePicker format={dateFormat} placeholder={strings.TO} />)}
            </Col>
          </Row>
        </Col>
        <Col lg={8}>
          <Row gutter={15}>
            <Col lg={12}>
              {getFieldDecorator('balanceFrom', {
                rules: [{ required: false }]
              })(<InputNumber placeholder={strings.BALANCE_FROM} />)}
            </Col>
            <Col lg={12}>
              {getFieldDecorator('balanceTo', {
                rules: [{ required: false }]
              })(<InputNumber placeholder={strings.BALANCE_TO} />)}
            </Col>
          </Row>
        </Col>
        <Col lg={8}>
          {getFieldDecorator('status', {
            initialValue: [FAMILY_ACTIVE_CLIENT_STATUS]
          })(
            <Select mode="multiple" placeholder={strings.STATUS} maxTagCount={1}>
              {familyStatuses?.map(status => (
                <Option value={status.id} key={status.id}>
                  {status.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col lg={8}>
          {getFieldDecorator('familyTags')(
            <Select mode="multiple" tokenSeparators={[',']} placeholder={strings.TAGS} maxTagCount={1}>
              {familyTagsList?.map(tag => (
                <Option value={String(tag.id)} key={tag.id}>
                  {tag.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
      </Row>
      <Row style={{ marginBottom: 20 }}>
        <Col lg={8}>
          {getFieldDecorator('includeEvents', {
            valuePropName: 'checked',
            initialValue: true
          })(<Checkbox>{strings.INCLUDE_EVENTS_FOR_GENERATION_ONLY}</Checkbox>)}
        </Col>
        <Col lg={12}>
          {getFieldDecorator('includeCompensations', {
            valuePropName: 'checked',
            initialValue: true
          })(<Checkbox>{strings.INCLUDE_COMPENSATIONS_FOR_GENERATION_ONLY}</Checkbox>)}
        </Col>
      </Row>
      <FilterButtons handleClear={clearFilter} hasSubmitLoading={hasSubmitLoading} extra={extraBtn} />
    </Form>
  );
});

export function InvoicesGenerator() {
  const { goBack } = useHistory();
  const [strings] = useLanguageContext();
  const [families, setFamilies] = useState<IFamily[]>([]);
  const [pagination, setPagination] = useState<PaginationInfo>();
  const [loadingFamilies, setLoadingFamilies] = useState<boolean>(false);
  const familyRepository = useFamilyRepository();
  const { FAMILY_ACTIVE_CLIENT_STATUS, BRANCH_RIGA } = useConstantsContext();

  const defaultFilterDates = useMemo(
    () => ({
      from: firstDayOfPrevMonth,
      to: lastDayOfPrevMonth
    }),
    []
  );

  useEffect(() => {
    getFamilies({
      status: [FAMILY_ACTIVE_CLIENT_STATUS],
      branch: BRANCH_RIGA,
      ...defaultFilterDates
    });
  }, []);

  const getFamilies = async (params): Promise<any> => {
    try {
      setLoadingFamilies(true);
      const {
        data: { families: familiesData, paginationInfo }
      } = await familyRepository.getFamilyList(params);
      //antd ui воспринимает children как expandedRow, поэтому переименовываем children в students
      const familyList = familiesData.map((item: IFamily) => {
        return { ...item, students: item.children, children: null };
      });
      setFamilies(familyList);
      setPagination(paginationInfo);
      setLoadingFamilies(false);
    } catch (error) {}
  };

  return (
    <Container>
      <StPageHeader onBack={goBack} title={strings.INVOICES_GENERATOR}>
        <InvoicesGeneratorFilter defaultFilterDates={defaultFilterDates} getFamilies={getFamilies} />
      </StPageHeader>
      <Row gutter={[10, 10]}>
        <FamilyTable
          families={families}
          pagination={pagination}
          loadingFamilies={loadingFamilies}
          getFamilies={getFamilies}
        />
      </Row>
    </Container>
  );
}
