import React from 'react';
import { Spin } from 'antd';

import { randomTextTip } from './dataTip';

interface LoaderProps {
  spinning?: boolean;
  tip?: string;
  children: React.ReactNode;
  [key: string]: any;
}

export const Loader = ({ children, spinning, tip, ...rest }: LoaderProps) => (
  <Spin spinning={spinning} tip={tip || randomTextTip()} {...rest}>
    {children}
  </Spin>
);

export default Loader;
