import React from 'react';
import { Link } from 'react-router-dom';
import { Teacher } from 'types/Teachers/teacher';

export const columns = strings => [
  {
    title: strings.TEACHER,
    dataIndex: 'teacher',
    render: (teacher: Teacher) => (
      <Link to={`/teachers/${teacher?.id}`}>
        {teacher?.surname ?? ''} {teacher?.name ?? ''}
      </Link>
    )
  },
  {
    title: strings.GENERAL_FEEDBACK_CONCERNING_THE_STUDENTS_PROGRESS,
    dataIndex: 'progress',
    render: (progress: string) => {
      return <div dangerouslySetInnerHTML={{ __html: progress }} />;
    }
  }
];

export default { columns };
