import { httpService } from 'api/core';

/**
 * @description Получает список материалов
 * @return {Promise<any>}
 */
export const getListMaterials = (): Promise<any> => httpService.get('/api/inventories');

/**
 * @description Получает материалы учителя по id
 * @param {number} teacherId
 * @return {Promise<any>}
 */
export const getMaterialsTeacher = (teacherId: number): Promise<any> =>
  httpService.get(`/api/inventory/teacher/${teacherId}`);

/**
 * @description Добавляет материал учителю по id
 * @param {number} materialId
 * @param {number} teacherId
 * @return {Promise<any>}
 */
export const addMaterialTeacher = (materialId: number, teacherId: number): Promise<any> =>
  httpService.post(`/api/inventory/${materialId}/teacher/${teacherId}`);

/**
 * @description Удаляет материал учителя по id
 * @param {number} materialId
 * @param {number} teacherId
 * @return {Promise<any>}
 */
export const deleteMaterialTeacher = (materialId: number, teacherId: number): Promise<any> =>
  httpService.delete(`/api/inventory/${materialId}/teacher/${teacherId}`);

export default {
  getMaterialsTeacher,
  getListMaterials,
  addMaterialTeacher,
  deleteMaterialTeacher
};
