import { keyForTheme } from 'contexts/ThemeContext';

/**
 * @description На основе урла проверяет окружение для формирование урла API
 * @param {string} url
 * @return {string}
 */
export const getUrlEnvironment = (url: string): string => {
  const prefixUrl = url.split('.')[0];

  if (prefixUrl === 'localhost' || prefixUrl === 'bat') {
    return 'https://wombat.londongates.org';
  } else if (prefixUrl === 'amigo') {
    return 'https://ami.londongates.org';
  }
};

/**
 * @description На основе урла проверяет окружение для формирование урла API
 * @param {string} url
 * @return {string}
 */
export const getKeyOfThemeByUrl = (): keyForTheme => {
  const hostname = window.location.hostname;
  switch (hostname) {
    case 'amigo.londongates.org':
      return keyForTheme.lgeg;
    case 'amigo.brunerschool.org':
      return keyForTheme.bruner;
    default:
      return keyForTheme.lgeg;
  }
};

export default { getUrlEnvironment };
