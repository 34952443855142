import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import { List } from 'antd';
import { Loader } from 'Global/components/Loader/Loader';
import { Group } from 'types';
import { Groups } from 'types/Teachers/teacher';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { useLanguageContext } from 'contexts/LanguageContext';
import { StCard } from 'Global/GlobalStyle';

/**
 * @description Teacher current groups
 * @param { teacherId: number } props
 * @returns {React.ReactNode}
 */
export const CurrentGroups = ({ teacherId }: { teacherId: number | string }) => {
  const [strings] = useLanguageContext();
  const [hasLoadGroups, setHasLoadGroups] = useState<boolean>(false);
  const [groups, setGroups] = useState<Groups>({
    mainGroups: [],
    secondaryGroups: []
  });
  const coursesRepository = useCoursesRepository();

  useEffect(() => {
    (async () => {
      setHasLoadGroups(true);
      const { data } = await coursesRepository.getGroupsByTeacher(teacherId);
      setGroups(data);
      setHasLoadGroups(false);
    })();
  }, []);

  return (
    <Loader spinning={hasLoadGroups}>
      <StCard>
        <PageHeader style={{ padding: 0 }} title={strings.MAIN_GROUPS}>
          <List
            itemLayout="horizontal"
            dataSource={groups.mainGroups}
            renderItem={(item: Group) => (
              <List.Item>
                <Link to={`/group/${item.id}`}>{item.groupName}</Link>
              </List.Item>
            )}
          />
        </PageHeader>
      </StCard>
      <StCard>
        <PageHeader style={{ padding: 0 }} title={strings.SUBSTITUTION}>
          <List
            itemLayout="horizontal"
            dataSource={groups.secondaryGroups}
            renderItem={(item: Group) => (
              <List.Item>
                <Link to={`/group/${item.id}`}>{item.groupName}</Link>
              </List.Item>
            )}
          />
        </PageHeader>
      </StCard>
    </Loader>
  );
};

export default { CurrentGroups };
