import { httpService } from 'api/core';
import { ManualInputFilterParams, SolvedDoubtParams } from 'types/finance';

/**
 * @description Получение Manual input report
 * @param {object} params
 * @return {Promise<any>}
 */
const getManualInputList = (params: ManualInputFilterParams): Promise<any> =>
  httpService.get('/api/report/manual', params);

/**
 * @description Отправить репорт об ошибке в финансах
 * @param {object} params
 * @returns {Promise<any>}
 */
export const postOperationDoubt = (params: { comment: string; operationId: number }): Promise<any> =>
  httpService.post('/api/finance/my/operation/doubt', params);

/**
 * @description Mark doubt as solved
 * @param {SolvedDoubtParams} params
 * @returns {Promise<any>}
 */
export const solvedDoubt = (params: SolvedDoubtParams): Promise<any> =>
  httpService.post('/api/finance/doubts/solve', params);

export const Finance = {
  getManualInputList,
  postOperationDoubt,
  solvedDoubt
};

export default {
  Finance
};
