import React, { useMemo, useContext } from 'react';
import { httpService } from 'api/core';
import { IRepositoryProvider } from './RepositoryProvider';

export const LangRepositoryContext = React.createContext<ILangRepository>(null);

interface ILangRepository {
  getTranslations: (params?: any) => Promise<any>; //TODO описать params
  createTranslation: (params) => Promise<any>; //TODO описать params
  updateTranslation: (translationId: number, params) => Promise<any>; //TODO описать params
  deleteTranslation: (translationId: number) => Promise<any>;
  getSubjectsTranslation: () => Promise<any>;
}

class LangRepository implements ILangRepository{
  getTranslations(params) {
    return httpService.get('/api/v1/translation', params, null, true);
  }

  createTranslation(params) {
    return httpService.post('/api/v1/translation', params, false, false, true);
  }

  updateTranslation(translationId, params) {
    return httpService.put(`/api/v1/translation/${translationId}`, params, false, false, true);
  }

  deleteTranslation(translationId: number) {
    return httpService.delete(`/api/v1/translation/${translationId}`, null, null, true);
  }

  getSubjectsTranslation() {
    return httpService.get('/api/v1/translation/subjects', null, null, true);
  }
}

export const LangRepositoryProvider: React.FC<IRepositoryProvider> = ({ children }) => {
  const service = useMemo(() => new LangRepository(), []);

  return <LangRepositoryContext.Provider value={service}>{children}</LangRepositoryContext.Provider>;
};

export const useLangRepository = () => {
  const service = useContext(LangRepositoryContext);

  if (!service) {
    throw new Error('Language repository is not available');
  }

  return service;
};
