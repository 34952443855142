import React, { useState } from 'react';
import styled from 'styled-components';
import { DownloadOutlined, EnterOutlined, EuroOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Input, Checkbox, Row, Col, Button, Spin, Popconfirm } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useMediaQuery } from 'react-responsive';
import { StyledCard } from '../../styles';

const TranferContainer = styled.div`
  .ant-popover-message-title {
    max-width: 230px;
  }
`;

interface DepositFormProps extends FormComponentProps {
  addDepositFamily: (familyId: number, sum: number, addToHistory: boolean, downloadFilePdf?: boolean) => Promise<any>;
  transferDepositToFinance: (familyId: number, sum: number) => Promise<any>;
  familyId: number;
}

/**
 * @description Deposit form
 * @param {object} props
 * @return {React.ReactNode}
 */
const DepositFormTemplate = (props: DepositFormProps) => {
  const [strings] = useLanguageContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [disabledTransferBtn, setDisabledTransferButton] = useState<boolean>(false);
  const [download, setDownload] = useState<boolean>(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const {
    form: { getFieldDecorator, validateFields, resetFields, getFieldValue },
    familyId,
    addDepositFamily,
    transferDepositToFinance
  } = props;

  /**
   * @description Подтверждение трансфера
   */
  const confirmTransfer = async () => {
    setDisabledTransferButton(true);
    const sum = getFieldValue('depositSum');
    await transferDepositToFinance(familyId, sum).finally(() => setDisabledTransferButton(false));
  };

  /**
   * @description Отправка формы
   * @param {React.FormEvent<HTMLFormElement>} e
   */
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        const { sum, history } = values;

        await addDepositFamily(familyId, sum, history, download).finally(() => {
          setDownload(false);
          setLoading(false);
        });
        resetFields(['sum']);
      }
    });
  };

  return (
    <Spin tip={strings.WAITING_FOR_THE_DEPOSIT_TO_BE_ADDED} spinning={loading}>
      <Row>
        <Col lg={14}>
          <StyledCard size="small">
            <Form layout="vertical" onSubmit={handleSubmit}>
              <Row>
                <Col lg={7}>
                  <MyFormItem>
                    <span>{strings.DEPOSIT_INVOICE}</span>
                    {getFieldDecorator('sum', {
                      rules: [{ required: true, message: strings.PLEASE_INPUT_SUM }]
                    })(
                      <Input
                        prefix={<EuroOutlined style={{ color: 'rgba(0,0,0,.5)', fontSize: 14 }} />}
                        placeholder={strings.SUM}
                        className="without-margin"
                      />
                    )}
                  </MyFormItem>
                </Col>
                <Col lg={7}>
                  <MyFormItem>
                    {getFieldDecorator('history', {
                      valuePropName: 'checked',
                      initialValue: true
                    })(<Checkbox style={{ ...(!isMobile && { paddingTop: 40 }) }}>{strings.ADD_TO_HISTORY}</Checkbox>)}
                  </MyFormItem>
                </Col>
                <Col lg={10} style={{ display: 'flex', gap: 8, ...(!isMobile && { paddingTop: 40 }) }}>
                  <Button icon={<DownloadOutlined />} size="large" htmlType="submit" onClick={() => setDownload(true)}>
                    {strings.DOWNLOAD}
                  </Button>
                  <Button type="primary" size="large" htmlType="submit">
                    {strings.SEND}
                  </Button>
                </Col>
              </Row>
            </Form>
          </StyledCard>
        </Col>
        <Col lg={10}>
          <StyledCard size="small">
            <Row>
              <Col lg={10}>
                <MyFormItem>
                  <span>{strings.DEPOSIT_SUM}</span>
                  {getFieldDecorator('depositSum')(
                    <Input
                      size="small"
                      prefix={<EuroOutlined style={{ color: 'rgba(0,0,0,.5)', fontSize: 14 }} />}
                      placeholder={strings.ENTER_SUM}
                      className="without-margin"
                    />
                  )}
                </MyFormItem>
              </Col>
              <Col lg={12} style={{ ...(!isMobile && { paddingTop: 40 }) }}>
                <TranferContainer>
                  <Popconfirm
                    title={strings.ARE_YOU_SURE_YOU_WANT_TO_TRANSFER_THE_DEPOSIT_TO_FINANCE}
                    placement="left"
                    onConfirm={confirmTransfer}
                    okText={strings.YES_TRANSFER}
                    cancelText={strings.CANCEL}
                  >
                    <Button
                      icon={<EnterOutlined />}
                      size="large"
                      disabled={disabledTransferBtn}
                      loading={disabledTransferBtn}
                    >
                      {strings.TRANSFER_TO_FINANCE}
                    </Button>
                  </Popconfirm>
                </TranferContainer>
              </Col>
            </Row>
          </StyledCard>
        </Col>
      </Row>
    </Spin>
  );
};

export const DepositForm = Form.create<DepositFormProps>({})(DepositFormTemplate);

export default { DepositForm };
