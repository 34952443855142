import React, { useState } from 'react';
import styled from 'styled-components';
import { InfoCircleTwoTone, PaperClipOutlined, SendOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Modal, Button, Upload, message as antMessage, Spin, Input, Empty } from 'antd';
import { ChatMessages } from 'Global/modules/Support/Chat/ChatMessages';
import { first, truncate, pickBy } from 'lodash';
import { useSupportRepository } from 'repos/SupportRepository';
import { StyledInfo } from 'Global/modules/Support/Chat/styles';
import { getFormData } from 'helpers';
import { useLanguageContext } from 'contexts/LanguageContext';
import { ChatContent, ChatHeader, FormMessage } from 'Global/components/Chat/styles';
import { LoadingItems } from 'Global/components/LoadingItems';
import { ModalChatAttachments } from './ModalChatAttachments';

const StyledSpin = styled(Spin)`
  padding-inline-start: 10px;
  padding-top: 5px;
`;

export const ChatDialog = ({ setTicket, ticket, hasLoadDialog, refetchTicket, refetchTickets, hasDialog }) => {
  const [strings] = useLanguageContext();
  const supportRepo = useSupportRepository();
  const [message, setMessage] = useState<string>('');
  const [fileList, setFileList] = useState([]);
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const [hasLoadingSentMessage, setHasLoadingSentMessage] = useState<boolean>(false);

  // @ts-ignore
  const authorId = first(ticket?.comments)?.author_id;

  const handleChange = ({ target: { value } }) => {
    setMessage(value);
  };

  const handleSendMessage = async (text?: string) => {
    try {
      setHasLoadingSentMessage(true);
      const msg = text ? text : message;
      const data = { message: msg, subject: truncate(msg, { length: 50 }) };
      if (fileList.isNotEmpty()) {
        data['attachments'] = fileList.map(file => file.originFileObj);
      }
      const formData = getFormData(pickBy(data));
      if (ticket) {
        await supportRepo.createTicketComment(ticket?.id, formData);
        await refetchTicket();
        setHasLoadingSentMessage(false);
        return;
      }

      await supportRepo.sendZendeskFeedback(formData);
      Modal.info({
        title: strings.THE_MESSAGE_WAS_SUCCESSFULLY_CREATED_THE_CORRESPONDENCE_WILL_APPEAR_IN_A_FEW_MINUTES
      });
      await refetchTickets();
    } catch {
      antMessage.error(strings.SOME_ERROR_TRY_AGAIN_LATER, 2);
    } finally {
      setHasLoadingSentMessage(false);
      setHasShowModal(false);
      setMessage('');
      setFileList([]);
    }
  };

  const onChangeUploaded = upload => {
    const { fileList: newFileList } = upload;
    setFileList(newFileList);
  };

  const uploadProps = {
    multiple: true,
    showUploadList: false,
    fileList: fileList,
    beforeUpload: () => false,
    onChange: upload => {
      if (upload.file) {
        onChangeUploaded(upload);
        setHasShowModal(true);
      }
      return false;
    }
  };

  if (!ticket) {
    return (
      <ChatContent>
        <Empty style={{ margin: 'auto' }} description={strings.YOU_DONT_HAVE_ACTIVE_CONVERSATIONS} />
      </ChatContent>
    );
  }
  if (hasLoadDialog) {
    return (
      <ChatContent>
        <LoadingItems rows={2} count={2} />
      </ChatContent>
    );
  }

  return (
    <ChatContent>
      <ModalChatAttachments
        hasShowModal={hasShowModal}
        setHasShowModal={setHasShowModal}
        hasLoadingSentMessage={hasLoadingSentMessage}
        handleSendMessage={handleSendMessage}
        message={message}
        fileList={fileList}
        setFileList={setFileList}
      />
      <ChatHeader>
        <ArrowLeftOutlined onClick={() => setTicket(null)} />
        <>{ticket?.subject}</>
      </ChatHeader>
      <ChatMessages messages={ticket?.comments} authorId={authorId} />
      <FormMessage>
        {hasLoadingSentMessage && <StyledSpin spinning size="small" />}
        {ticket?.status === 'closed' ? (
          <StyledInfo>
            <InfoCircleTwoTone twoToneColor="#f5222d" style={{ paddingInlineEnd: 5 }} />
            {strings.THIS_TRED_IS_CLOSED_PLEASE_CREATE_A_NEW_REQUEST}
          </StyledInfo>
        ) : (
          <>
            <Input.TextArea
              style={{ resize: 'none' }}
              placeholder={strings.ENTER_MESSAGE}
              onChange={handleChange}
              value={message}
            />
            <Upload {...uploadProps}>
              <PaperClipOutlined style={{ cursor: 'pointer', fontSize: 20 }} />
            </Upload>
            <Button
              type="primary"
              size="large"
              shape="circle"
              icon={<SendOutlined />}
              onClick={() => handleSendMessage()}
              disabled={message.trimStart().length <= 1 || hasLoadingSentMessage}
              loading={hasLoadingSentMessage}
              style={{ width: 24 }}
            />
          </>
        )}
      </FormMessage>
    </ChatContent>
  );
};
