import React, { useContext, useMemo, useState } from 'react';
import { User } from './User';
import { NoUsers } from './NoUsers';
import ChatContext from './chatContext';
import { ChatHeader, SideBar } from './styles';
import { Thread } from 'types/ParentJournal';
import { Parent } from 'types';
import { Teacher } from 'types/Teachers/teacher';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useUserContext } from 'contexts/UserContext';
import { Flex, Input } from 'antd';
import { LoadingItems } from '../LoadingItems';
import { SearchOutlined } from '@ant-design/icons';

interface ThreadUser {
  user: Parent | Teacher;
  threadId: number;
  isRead: boolean;
  subject: string;
}

/**
 * @description Список пользователей
 * @return {React.ReactNode}
 */
export const ListUsers = () => {
  const [user] = useUserContext();
  const { threads, setActiveThreadId, activeThreadId, hasLoadThreads } = useContext(ChatContext);
  const [strings] = useLanguageContext();
  const [search, setSearch] = useState<string>('');
  const hasTeacher = user?.hasRoles?.teacher;

  const usersList: ThreadUser[] = useMemo(() => {
    const users: ThreadUser[] = threads.map((item: Thread) => ({
      user: hasTeacher ? item?.parent?.user : item?.teacher?.user,
      threadId: item.id,
      isRead: item.isRead,
      subject: item.subject
    }));
    return users;
  }, [threads]);

  const filteredUsersBySearch = useMemo(() => {
    const searchRegexp = new RegExp(search, 'ig');
    return usersList.filter(item => searchRegexp.test(`${item?.user?.name} ${item?.user?.surname}`));
  }, [usersList, search]);

  return (
    <SideBar vertical>
      <ChatHeader>
        <Input
          prefix={<SearchOutlined />}
          placeholder={strings.SEARCH}
          onChange={({ target: { value } }) => setSearch(value)}
        />
      </ChatHeader>
      {hasLoadThreads ? (
        <LoadingItems count={4} />
      ) : (
        <Flex vertical style={{ overflow: 'auto' }}>
          {filteredUsersBySearch.isNotEmpty() ? (
            filteredUsersBySearch.map(item => (
              <User
                key={item.threadId}
                user={item.user}
                threadId={item.threadId}
                isRead={item.isRead}
                subject={item.subject}
                showDialogClick={() => setActiveThreadId(item.threadId)}
                isThreadActive={activeThreadId === item.threadId}
              />
            ))
          ) : (
            <NoUsers />
          )}
        </Flex>
      )}
    </SideBar>
  );
};

export default { ListUsers };
