import React, { ReactNode } from 'react';
import { Form } from '@ant-design/compatible';

interface MyFormItemProps {
  children: ReactNode;
  [key: string]: any; // или конкретные пропсы, которые вы ожидаете
}

const MyFormItem: React.FC<MyFormItemProps> = ({ children, ...props }) => {
  return <Form.Item {...props}>{children}</Form.Item>;
};

export default MyFormItem;
