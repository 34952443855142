import React, { useState, useEffect } from 'react';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select, Spin, Tag } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';

import { GetFieldDecoratorOptions } from '@ant-design/compatible/lib/form/Form';
import { debounce } from 'lodash';
import { Participant } from 'types/Schedule';

const { Option } = Select;

interface UserSelectProps {
  getFieldDecorator<T extends Object = {}>(
    id: keyof T,
    options?: GetFieldDecoratorOptions
  ): (node: React.ReactNode) => React.ReactNode;
  isRequired?: boolean;
  label?: string;
  name: string;
}

export const UserSelect: React.FC<UserSelectProps> = ({ getFieldDecorator, isRequired, label, name }) => {
  const [strings] = useLanguageContext();
  const [usersList, setUsersList] = useState<Participant[]>([]);
  const [hasLoadingUsers, setHasLoadingUsers] = useState<boolean>(false);
  const globalRequestsRepository = useGlobalRequestsRepository();
  const [searchValue, setSearchValue] = useState<string>('');
  const [typing, setTyping] = useState<Boolean>(false);

  const decoratorFields = {};

  if (isRequired) {
    decoratorFields['rules'] = [{ required: true, message: `${label} ${strings.IS_REQUIRED}` }];
  }

  /**
   * @description Поиск по пользователям
   * @param {string} userName
   * @return {Promise<void>}
   */
  const fetchUsers = async (userName: string): Promise<any> => {
    setSearchValue(userName);
    if (userName) {
      setHasLoadingUsers(true);
      try {
        const {
          data: { children, parents, teachers, admins }
        } = await globalRequestsRepository.search(userName, { modeSchedule: true });
        const studentUsers = children
          ?.filter(item => item?.user?.name || item?.user?.surname)
          .map(item => ({ ...item, type: 'student' }));
        const parentUsers = parents
          ?.filter(item => item?.user?.name || item?.user?.surname)
          ?.map(item => ({ ...item, type: 'parent' }));
        const teacherUsers = teachers
          ?.filter(item => item?.user?.name || item?.user?.surname)
          ?.map(item => ({ ...item, type: 'teacher' }));
        const adminUsers = admins
          ?.filter(item => item?.user?.name || item?.user?.surname)
          ?.map(item => ({ ...item, type: 'admin' }));
        const users = [...studentUsers, ...parentUsers, ...teacherUsers, ...adminUsers];
        setUsersList(users);
      } catch {
        setUsersList([]);
      } finally {
        setHasLoadingUsers(false);
      }
    }
  };

  useEffect(() => {
    if (searchValue) {
      setTyping(true);
    } else {
      setTyping(false);
    }
  }, [searchValue]);

  const select = (
    <>
      {getFieldDecorator(
        name,
        decoratorFields
      )(
        <Select
          showSearch
          allowClear
          defaultActiveFirstOption={false}
          filterOption={false}
          placeholder={label}
          onSearch={debounce(fetchUsers, 800)}
          style={{ width: '100%' }}
          notFoundContent={
            hasLoadingUsers ? <Spin size="small" /> : <p>{!typing ? strings.START_TYPING : strings.NOT_FOUND}</p>
          }
        >
          {usersList
            .filter(item => item?.user?.name !== undefined && item?.user?.surname !== undefined)
            .map(item => (
              <Option key={item?.user?.id} value={item?.user?.id}>
                {item?.user?.name} {item?.user?.surname} <Tag color="blue">{item.type}</Tag>
              </Option>
            ))}
        </Select>
      )}
    </>
  );

  if (label) {
    return <MyFormItem label={label}>{select}</MyFormItem>;
  }
  return select;
};
