import React, { useState, useEffect } from 'react';
import { Input } from 'antd';

export const InputForMobile: React.FC = ({
  value,
  onChange
}: {
  value?: string;
  onChange?: (value: string) => void;
}) => {
  const [phoneValue, setPhoneValue] = useState(value || '');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const cleanValue = value.replace(/[^0-9+]/g, '').replace(/(^\+)|\D/g, '$1');
    setPhoneValue(cleanValue);
    onChange(cleanValue);
  };

  return <Input value={phoneValue} onChange={handleChange} />;
};

export default InputForMobile;
