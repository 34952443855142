import { httpService } from 'api/core';

// Teaching materials

/**
 * @description
 * @param {number} id
 * @return {Promise<any>}
 */
export const getChildInventory = (id: number): Promise<any> => httpService.get(`/api/inventory/child/${id}`);

/**
 * @description ...
 * @param {number} id
 * @return {Promise<any>}
 */
export const getAuthorizedChildInventory = (id: number): Promise<any> =>
  httpService.get(`/api/inventory/child/${id}/authorized`);

/**
 * @description ...
 * @param {number} id
 * @return {Promise<any>}
 */
export const getNeededChildInventory = (id: number): Promise<any> =>
  httpService.get(`/api/inventory/child/${id}/needed`);

/**
 * @description ...
 * @param {number} childId
 * @param {number} inventoryId
 */
export const createInventoryToTheChild = (childId: number, inventoryId: any): Promise<any> =>
  httpService.post(`/api/inventory/${inventoryId}/child/${childId}`);

/**
 * @description ...
 * @param {number} childId
 * @param {number} inventoryId
 */
export const deleteInventoryFromChild = (childId: number, inventoryId: any): Promise<any> =>
  httpService.delete(`/api/inventory/${inventoryId}/child/${childId}`);

/**
 * @description Удаление студента по id
 * @param {number} studentId
 * @return {Promise<any>}
 */
export const deleteStudent = (studentId: number): Promise<any> => httpService.delete(`/api/children/${studentId}`);

/**
 * @description Collaboration history
 * @param {number} familyId
 * @param {any} params
 * @return {Promise<any>}
 */
export const getCollaborationHistory = (familyId: number, params?: any): Promise<any> =>
  httpService.get(`/api/families/${familyId}/history`, params);

/**
 * @description добавить студента в супергруппу
 * @param {number} groupId
 * @param {number} studentId
 * @param {object} params
 */
export const addStudentToSupergroup = (groupId: number, studentId: number, params?: any): Promise<any> =>
  httpService.post(`/api/supergroups/${groupId}/student/${studentId}`, params);

/**
 * @description добавить студента в группу
 * @param {number} groupId
 * @param {number} studentId
 * @param {object} params
 */
export const addStudentToGroup = (groupId: number, studentId: number, params?: any): Promise<any> =>
  httpService.post(`/api/coursegroup/${groupId}/student/${studentId}`, params);

/**
 * @description Удаление студента из группы
 * @param {number} groupId
 * @param {number} studentId
 * @return {Promise<any>}
 */
export const deleteStudentFromGroup = (groupId: number, studentId: number): Promise<any> =>
  httpService.delete(`/api/coursegroup/${groupId}/student/${studentId}`);

export default {
  addStudentToSupergroup,
  getChildInventory,
  getAuthorizedChildInventory,
  getNeededChildInventory,
  createInventoryToTheChild,
  deleteInventoryFromChild,
  deleteStudent,
  getCollaborationHistory,
  addStudentToGroup,
  deleteStudentFromGroup
};
