import React, { useEffect, useState } from 'react';
import { Collapse, Spin, Table, Typography } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { AskTeacherFeedback } from './AskTeacherFeedback';
import { LessonRegister } from 'types';
import { useUserContext } from 'contexts/UserContext';
import { columnsTasks, columnsLesson } from './columns';
import { groupBy } from 'lodash';
import dayjs from 'dayjs';
import { StTable } from './index';
import { useParentJournalRepository } from 'repos/ParentJournalRepository';

const { Column } = Table;
const { Title } = Typography;
const { Panel } = Collapse;

interface SubjectTabProps {
  studentId: number;
  subjectId: number;
  academicYear?: number;
}

interface ITaskRegistersByStudentAndSubject {
  id: number;
  grade: number;
  title: string;
  startAt: string;
  percent: number;
}

/**
 * @description Вкладка предмета в академик прогресс
 * @param {SubjectTabProps} props
 * @return {React.ReactNode}
 */
export const SubjectTab = ({ studentId, subjectId, academicYear }: SubjectTabProps) => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const parentJournalRepository = useParentJournalRepository();
  const hasAdmin = user?.hasRoles?.admin;
  const [lessonsRegisters, setLessonsRegisters] = useState<LessonRegister[]>([]);
  const [hasLoadLessonsRegisters, setHasLoadLessonsRegisters] = useState<boolean>(false);
  const [needFeedbackNum, setNeedFeedbackNum] = useState<number>(0);
  const [taskRegisters, setTaskRegisters] = useState<ITaskRegistersByStudentAndSubject[]>([]);
  const [hasLoadTaskRegisters, setHasLoadTaskRegisters] = useState<boolean>(false);
  const groupedByYearTests = groupBy(taskRegisters, ({ startAt }) => dayjs(startAt).year());
  const currentYear = dayjs().year();
  const currentTests = groupedByYearTests[currentYear];
  const otherYearsTests = Object.keys(groupedByYearTests).filter((year: string) => year !== String(currentYear));

  /**
   * @description Запрос на тесты
   * @param {number} id
   * @param {number} subjectId
   * @return {Promise<any>}
   */
  const getTasksRegister = async (): Promise<any> => {
    try {
      setHasLoadTaskRegisters(true);
      const { data } = await parentJournalRepository.getTasksRegistersByChild(studentId, subjectId);
      setTaskRegisters(data);
    } finally {
      setHasLoadTaskRegisters(false);
    }
  };
  /**
   * @description Запрос на тесты
   * @param {number} id
   * @param {number} subjectId
   * @return {Promise<any>}
   */
  const getLessonRegister = async (academicYear: number): Promise<any> => {
    const params = { academicYear };
    try {
      setHasLoadLessonsRegisters(true);
      const {
        data: { registers, buttonNeedFeedbackForParents }
      } = await parentJournalRepository.getLessonRegister(studentId, subjectId, params);
      setLessonsRegisters(registers);
      setNeedFeedbackNum(buttonNeedFeedbackForParents);
    } finally {
      setHasLoadLessonsRegisters(false);
    }
  };

  useEffect(() => {
    getTasksRegister();
  }, []);

  useEffect(() => {
    getLessonRegister(academicYear);
  }, [academicYear]);

  return (
    <>
      {hasAdmin && needFeedbackNum !== 0 && (
        <AskTeacherFeedback buttonNeedFeedbackForParents={needFeedbackNum} subjectId={subjectId} childId={studentId} />
      )}
      <Spin spinning={hasLoadTaskRegisters}>
        {taskRegisters.isNotEmpty() && (
          <StTable>
            {currentTests?.isNotEmpty() && (
              <>
                <Title level={4}>{strings.TESTS}</Title>
                <Table
                  dataSource={currentTests}
                  {...(currentTests.length <= 10 && { pagination: false })}
                  style={{ marginBottom: 24 }}
                >
                  {columnsTasks(strings).map(col => (
                    <Column {...col} />
                  ))}
                </Table>
              </>
            )}
            {otherYearsTests?.isNotEmpty() && (
              <>
                <Title level={4}>{strings.TESTS_OTHER_YEARS}</Title>
                <Collapse style={{ margin: '15px 0' }}>
                  {otherYearsTests.map((year: string) => (
                    <Panel header={year} key={year}>
                      <Table
                        dataSource={groupedByYearTests[year]}
                        {...(groupedByYearTests[year].length <= 10 && { pagination: false })}
                        style={{ marginBottom: 24 }}
                      >
                        {columnsTasks(strings).map(col => (
                          <Column {...col} />
                        ))}
                      </Table>
                    </Panel>
                  ))}
                </Collapse>
              </>
            )}
          </StTable>
        )}
      </Spin>
      <Title level={4}>{strings.TASKS}</Title>
      <Spin spinning={hasLoadLessonsRegisters}>
        <StTable>
          <Table dataSource={lessonsRegisters} {...(lessonsRegisters.length <= 10 && { pagination: false })}>
            {columnsLesson(strings).map(col => (
              <Column {...col} />
            ))}
          </Table>
        </StTable>
      </Spin>
    </>
  );
};

export default { SubjectTab };
