import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';
import { InvoiceFilterParams, InvoiceListItem } from 'types/invoice';
import { mockPagination } from 'Global/constants';
import { PaginationInfo } from 'types/global';
import { Filter } from './Filter';
import { InvoicesTable } from 'Global/components/Tables/Invoices';
import { Forbidden } from 'Global/modules/Errors/403';
import { Link, useHistory } from 'react-router-dom';
import { ErrorHandler } from 'helpers/services/ErrorHandler';
import { isEmpty, pickBy } from 'lodash';
import { scrollTop, hasInvoiceMonthFinanceSystem, downloadSomeFile } from 'helpers';
import Pagination from 'Global/components/Pagination';
import { getInitialFilterParams } from './helpers';
import { useUserContext } from 'contexts/UserContext';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useInvoicesRepository } from 'repos/InvoicesRepository';
import queryString from 'query-string';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { momentFormatValues } from 'helpers/momentFormatValues';

/**
 * @description Invoice moscow page
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const InvoiceMoscow = () => {
  const [strings] = useLanguageContext();
  const { push, goBack } = useHistory();
  const [hasForbidden, setHasForbidden] = useState<boolean>(false);
  const [isLoadInvoices, setIsLoadInvoices] = useState<boolean>(false);
  const [invoicesList, setInvoicesList] = useState<InvoiceListItem[]>();
  const [currentFilterParams, setCurrentFilterParams] = useState<InvoiceFilterParams>({});
  const [pagination, setPagination] = useState<PaginationInfo>(mockPagination);
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
  const [isRatesExcelLoading, setRatesExcelLoading] = useState<boolean>(false);
  const [user] = useUserContext();
  const invoicesRepository = useInvoicesRepository();
  const { locationSearchParams } = useLocationSearchParams();
  const hasAdminRole = user?.hasRoles?.admin;
  const hasAcademicExpertRole = user?.hasRoles?.academicExpert;
  const branch = user.branch;
  const { branches } = useGlobalCollectionsContext();
  const defFilterParams = useMemo(() => getInitialFilterParams({ branch: branch?.id }), [branch?.id]);

  const getParams = useCallback(() => {
    if (isEmpty(locationSearchParams)) {
      return defFilterParams;
    } else {
      if (branches.length === 1) {
        locationSearchParams['branch'] = branch.id;
      }
      return locationSearchParams;
    }
  }, [locationSearchParams, defFilterParams, branches, branch]);

  /**
   * @description Получение инвойсов
   * @param {object} params - параметры фильтра
   * @return {Promise<any>}
   */

  const getInvoiceMoscow = useCallback(
    async (values: InvoiceFilterParams): Promise<any> => {
      const params = pickBy(momentFormatValues(values));
      setCurrentFilterParams(params);
      setIsLoadInvoices(true);

      try {
        const { status, data } = await invoicesRepository.getInvoiceMonthSystemList(params);
        if (status === 200) {
          const { items, paginationInfo } = data;
          setInvoicesList(items);
          setPagination(paginationInfo);
          push({ search: queryString.stringify(params) });
        }
      } catch (err) {
        if (ErrorHandler.checkStatusCode(err, 403)) {
          setHasForbidden(true);
        }
        setInvoicesList([]);
      } finally {
        setIsLoadInvoices(false);
      }
    },
    [invoicesRepository]
  );

  const handlePage = async (page: number): Promise<any> => {
    await getInvoiceMoscow({ ...currentFilterParams, page });
    scrollTop();
  };

  /**
   * @description Функция для скачивания отчетов
   * @return {void}
   */
  const exportInvoiceReportToExcel = async () => {
    setIsFileLoading(true);
    try {
      const params = queryString.stringify(locationSearchParams, { skipNull: true, arrayFormat: 'bracket' });
      await downloadSomeFile(`/api/v1/report/invoice/services-rendered?${params}`, null, null, null, true);
    } catch {
    } finally {
      setIsFileLoading(false);
    }
  };

  const exportBetaRatesExcel = async () => {
    setRatesExcelLoading(true);
    try {
      const params = queryString.stringify(locationSearchParams, { skipNull: true, arrayFormat: 'bracket' });
      await downloadSomeFile(`/api/v1/report/invoice/services-rendered-new?${params}`, null, null, null, true);
    } catch {
    } finally {
      setRatesExcelLoading(false);
    }
  };

  useEffect(() => {
    if (branches.isNotEmpty()) {
      getInvoiceMoscow(getParams());
    }
  }, [branches]);

  const isAccessDenied = useMemo(() => {
    return (
      (hasForbidden || !(hasAdminRole && hasInvoiceMonthFinanceSystem(branch)) || hasAcademicExpertRole) &&
      !isLoadInvoices
    );
  }, [hasForbidden, hasAdminRole, hasAcademicExpertRole, isLoadInvoices, branch]);

  if (isAccessDenied) {
    return (
      <Forbidden subTitle={strings.SORRY_YOU_DO_NOT_HAVE_ACCESS_THIS_PAGE}>
        <Button size="large" type="primary" icon={<ArrowLeftOutlined />}>
          <Link to="/" style={{ color: '#fff' }}>
            {strings.BACK_TO_HOME}
          </Link>
        </Button>
      </Forbidden>
    );
  }

  return (
    <Container>
      <StPageHeader
        onBack={goBack}
        title={strings.INVOICE_MONTH_SYSTEM}
        extra={[
          <Button loading={isRatesExcelLoading} onClick={() => exportBetaRatesExcel()}>
            {strings.BETA_RATES_EXCEL}
          </Button>,
          <Button loading={isFileLoading} onClick={() => exportInvoiceReportToExcel()}>
            {strings.EXPORT_TO_EXCEL}
          </Button>
        ]}
      >
        <Filter getInvoiceMoscow={getInvoiceMoscow} defFilterParams={defFilterParams} />
      </StPageHeader>
      <Row gutter={[10, 10]}>
        <InvoicesTable
          currentFilterParams={currentFilterParams}
          isPagination={false}
          getInvoiceMoscow={getInvoiceMoscow}
          isFamily={false}
          invoicesList={invoicesList}
          isLoadInvoices={isLoadInvoices}
          isRowSelection={true}
        />
        <Pagination count={pagination.pageCount} current={pagination.current} pageChange={handlePage} />
      </Row>
    </Container>
  );
};

export default InvoiceMoscow;
