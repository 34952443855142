import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Tabs, Typography } from 'antd';
import { TeachingMaterial as StTeachingMaterial } from '../styles';
import { History } from 'history';
import { useLastLocation } from 'react-router-last-location';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

interface teachingMaterialProps {
  history: History;
}

export const TeachingMaterial = (props: teachingMaterialProps) => {
  const {
    history: { goBack }
  } = props;
  const [strings] = useLanguageContext();
  const lastLocation = useLastLocation();
  const handleBack = () => goBack();
  return (
    <StTeachingMaterial>
      <Title level={3}>
        {lastLocation && <ArrowLeftOutlined onClick={handleBack} />} 21st Century Reading 3 CD+DVD
      </Title>
      <Tabs defaultActiveKey="1">
        <TabPane tab={strings.MAIN_INFORMATION} key="1" style={{ margin: '25px 0' }}>
          <Paragraph>
            {strings.ISBN} <b>9781305495494</b>
          </Paragraph>
          <Paragraph>
            {strings.ACTUAL}: <b>{strings.YES}</b>
          </Paragraph>
          <Paragraph>
            {strings.NAME}: <b>{strings.CENTURY_READING_3_CD_DVD}</b>
          </Paragraph>
          <Paragraph>
            {strings.TYPE} <b>{strings.STUDYBOOK}</b>
          </Paragraph>
          <Paragraph>
            {strings.RATE}: <b>1200</b>
          </Paragraph>
          <Paragraph>
            {strings.OLD_RATE}: <b>1200</b>
          </Paragraph>
          <Paragraph>
            {strings.LEFT} <b>1</b>
          </Paragraph>
          <Paragraph>
            {strings.COMMENT}: <b />
          </Paragraph>
        </TabPane>
        <TabPane tab={strings.OWNERS} key="2" />
        <TabPane tab={strings.NEED_TO_GIVE} key="3" />
      </Tabs>
    </StTeachingMaterial>
  );
};

export default { TeachingMaterial };
