import React, { useMemo } from 'react';
import { httpService } from 'api/core';
import { IRepositoryProvider } from './RepositoryProvider';
import { IHomeworkParams } from 'types/homework';
import { ScheduleLessonPutParams, ScheduleLessonPutParamsForPreview, SlotLessonPostParams } from 'types/Schedule';

const ScheduleRepositoryContext = React.createContext<IScheduleRepository>(null);

interface IScheduleRepository {
  getSlotsBulkUpdateInfo: (params) => Promise<any>; //TODO описать params
  getHomeworkList: (params: IHomeworkParams) => Promise<any>;
  editSlotByLessonId: (id: number, params: ScheduleLessonPutParams) => Promise<any>;
  getPreviewSlotByLessonId: (id: number, params: ScheduleLessonPutParamsForPreview) => Promise<any>;
  getPreviewSlotForNewLesson: (params: ScheduleLessonPutParamsForPreview) => Promise<any>;
  createSlotLesson: (params: SlotLessonPostParams) => Promise<any>;
}

class ScheduleRepository implements IScheduleRepository {
  getSlotsBulkUpdateInfo(params) {
    return httpService.post('/api/v1/slots/lesson/bulk/info', params, null, false, true);
  }

  getHomeworkList(params: IHomeworkParams) {
    return httpService.get('/api/v1/schedule/homework', params, null, true);
  }

  editSlotByLessonId(id: number, params: ScheduleLessonPutParams) {
    return httpService.put(`/api/v1/slots/${id}/lesson`, params, null, null, true);
  }

  getPreviewSlotByLessonId(id: number, params: ScheduleLessonPutParamsForPreview) {
    return httpService.put(`/api/v1/slots/${id}/lesson`, params, null, null, true);
  }
  getPreviewSlotForNewLesson(params: ScheduleLessonPutParamsForPreview) {
    return httpService.post(`/api/v1/slots/lesson`, params, null, null, true);
  }
  createSlotLesson(params: SlotLessonPostParams) {
    return httpService.post(`/api/v1/slots/lesson`, params, null, null, true);
  }
}

export const useScheduleRepository = () => {
  const service = React.useContext(ScheduleRepositoryContext);

  if (!service) {
    throw new Error('Schedule repository is unavailable');
  }

  return service;
};

export const ScheduleRepositoryProvider: React.FC<IRepositoryProvider> = ({ children }) => {
  const service = useMemo(() => new ScheduleRepository(), []);

  return <ScheduleRepositoryContext.Provider value={service}>{children}</ScheduleRepositoryContext.Provider>;
};
