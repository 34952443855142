import React from 'react';
import dayjs from 'dayjs';
import { Tag, Descriptions, Flex } from 'antd';
import { Link } from 'react-router-dom';
import { dateFormat } from 'helpers/dates';
import { IStudent } from 'types';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useMediaQuery } from 'react-responsive';
import { useChildContext } from 'contexts/ChildContext';
import { StDescription } from './index';
import { useUserContext } from 'contexts/UserContext';

type BaseMainInfoProps = {
  child: IStudent;
};

export const BaseMainInfo: React.FC<BaseMainInfoProps> = ({ child }) => {
  const {
    user: studentUser,
    target,
    school,
    status,
    studyProgram,
    supergroups,
    tutor,
    entryTestResults, //examsResults
    comment,
    hobby,
    classN, // Class
    englishLevel,
    englishLevelComment,
    UCINumber,
    candidateNumber,
    firstNameEnglish,
    lastNameEnglish,
    examineType,
    performer,
    autogeneratedInvoices
  } = child || {};

  const { name, surname, gender, birthDate, age, email, phone, phoneSecond } = studentUser || {};

  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const hasTeacher = user?.hasRoles?.teacher;
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [examineTypes] = useChildContext();

  return (
    <Flex wrap={isMobile ? 'wrap' : 'nowrap'} gap={20}>
      <StDescription title={strings.GENERAL_INFO} column={1}>
        <Descriptions.Item label={strings.NAME}>
          {name} {surname ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label={strings.DATE_OF_BIRTH}>
          {birthDate && dayjs(birthDate).format(dateFormat)}
        </Descriptions.Item>
        <Descriptions.Item label={strings.AGE}>{age ?? '-'}</Descriptions.Item>
        <Descriptions.Item label={strings.SEX}>{gender === 1 ? strings.FEMALE : strings.MALE}</Descriptions.Item>
        {!hasTeacher && (
          <>
            <Descriptions.Item label={strings.MOBILE}>
              {phone ? <a href={`tel:${phone}`}>{phone}</a> : '-'}
            </Descriptions.Item>
            <Descriptions.Item label={strings.MOBILE_SECOND}>
              {phoneSecond ? <a href={`tel:${phoneSecond}`}>{phoneSecond}</a> : '-'}
            </Descriptions.Item>
            <Descriptions.Item label={strings.EMAIL}>
              {email ? <a href={`mailto:${email}`}>{email}</a> : '-'}
            </Descriptions.Item>
            {performer && <Descriptions.Item label={strings.PERFORMER}>{performer?.name ?? '-'}</Descriptions.Item>}
            <Descriptions.Item label={strings.SIMPLE_INVOICE}>
              {autogeneratedInvoices ? strings.YES : strings.NO}
            </Descriptions.Item>
            <Descriptions.Item label={strings.COMMENT}>{comment ?? '-'}</Descriptions.Item>
          </>
        )}
      </StDescription>
      <StDescription title={strings.STUDYING_INFO} column={1}>
        <Descriptions.Item label={strings.STATUS}>{status?.name ?? '-'}</Descriptions.Item>
        <Descriptions.Item label={strings.TUTOR}>
          {tutor?.user?.name} {tutor?.user?.surname ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label={strings.STUDY_PROGRAMME}>{studyProgram?.name ?? '-'}</Descriptions.Item>
        <Descriptions.Item label={strings.SUPERGROUPS}>
          <Flex wrap="wrap" gap={5}>
            {supergroups?.map(({ id, name, activeForStudent }) => (
              <Tag color={activeForStudent ? 'green' : 'blue'} key={id} style={{ margin: 0 }}>
                <Link to={`/supergroup/${id}`}>{name}</Link>
              </Tag>
            ))}
          </Flex>
          {supergroups?.length === 0 && '-'}
        </Descriptions.Item>
        <Descriptions.Item label={strings.LEVEL_OF_LANGUAGE_PROFICIENCY}>{englishLevel?.name ?? '-'}</Descriptions.Item>
        <Descriptions.Item label={strings.LANGUAGE_EXPERIENCE}>{englishLevelComment ?? '-'}</Descriptions.Item>
        <Descriptions.Item label={strings.ENTRY_TEST_RESULTS}>{entryTestResults ?? '-'}</Descriptions.Item>
        <Descriptions.Item label={strings.SCHOOL}>{school ?? '-'}</Descriptions.Item>
        <Descriptions.Item label={strings.CLASS}>{classN ?? '-'}</Descriptions.Item>
        <Descriptions.Item label={strings.HOBBY}>{hobby ?? '-'}</Descriptions.Item>
        <Descriptions.Item label={strings.WHY_STUDY_WITH_US}>{target ?? '-'}</Descriptions.Item>
      </StDescription>
      {!hasTeacher && (
        <StDescription title={strings.PERSON_EDEXCEL_INFO} column={1}>
          <Descriptions.Item label={strings.UCI_NUMBER}>{UCINumber ?? '-'}</Descriptions.Item>
          <Descriptions.Item label={strings.CANDIDATE_NUMBER}>{candidateNumber ?? '-'}</Descriptions.Item>
          <Descriptions.Item label={strings.FIRST_NAME_ENGLISH}>{firstNameEnglish ?? '-'}</Descriptions.Item>
          <Descriptions.Item label={strings.LAST_NAME_ENGLISH}>{lastNameEnglish ?? '-'}</Descriptions.Item>
          <Descriptions.Item label={strings.EXAMINE_TYPE}>
            {examineTypes?.get(examineType?.toString()) ?? '-'}
          </Descriptions.Item>
        </StDescription>
      )}
    </Flex>
  );
};

export default { BaseMainInfo };
