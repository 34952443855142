import React from 'react';
import { Table, Tag } from 'antd';
import dayjs from 'dayjs';
import { dateFormat } from 'helpers/dates';
import { TeacherHistoryLessonAssessment } from 'types/Teachers/History';
import {
  HistoryItem,
  AssessmentHistoryContainer,
  AssessmentHistoryCol,
  AssessmentHistoryLabel,
  AssessmentHistoryText
} from 'Admin/People/Teachers/styles';
import { columnsAssessment } from 'Admin/People/Teachers/Teacher/CollaborationHistory/AssessmentColumns';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Admin } from '../../../../../types';

interface LessonAssessmentProps {
  lessonAssessment: TeacherHistoryLessonAssessment;
  admin: Admin;
}

/**
 * @description Компонент Index
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const LessonAssessment = (props: LessonAssessmentProps) => {
  const [strings] = useLanguageContext();
  const { lessonAssessment, admin } = props;

  return (
    <HistoryItem>
      <h4>
        <Tag color="blue">{strings.LESSON_ASSESSMENT}</Tag>
        <span style={{ marginInlineStart: 10 }}>{dayjs(lessonAssessment.date).format(dateFormat)}</span>
      </h4>
      <AssessmentHistoryContainer>
        <AssessmentHistoryCol>
          <AssessmentHistoryLabel>{strings.DATE}</AssessmentHistoryLabel>
          <AssessmentHistoryText>
            {lessonAssessment?.date && dayjs(lessonAssessment.date).format(dateFormat)}
          </AssessmentHistoryText>
        </AssessmentHistoryCol>
        <AssessmentHistoryCol>
          <AssessmentHistoryLabel>{strings.CONTACT_PERSON}</AssessmentHistoryLabel>
          <AssessmentHistoryText>
            {lessonAssessment?.admin && `${lessonAssessment.admin.user.name} ${lessonAssessment.admin.user.surname}`}
          </AssessmentHistoryText>
        </AssessmentHistoryCol>
        <AssessmentHistoryCol fullwide>
          <AssessmentHistoryLabel>{strings.DESCRIPTION}</AssessmentHistoryLabel>
          <Table
            dataSource={[lessonAssessment?.assessment]}
            bordered={false}
            columns={columnsAssessment(strings)}
            rowKey="id"
            pagination={false}
          />
          <p>{lessonAssessment?.assessment?.teacherRepliedToFeedbackComment}</p>
        </AssessmentHistoryCol>
      </AssessmentHistoryContainer>
      <span style={{ opacity: '.7' }}>
        {strings.ADMIN}: {admin?.user?.name} {admin?.user?.surname}
      </span>
    </HistoryItem>
  );
};

export default { LessonAssessment };
