import React from 'react';
import dayjs from 'dayjs';
import { Modal, Table } from 'antd';
import { CourseGroup, CourseGroupLog } from 'types/education';
import { dateFormat } from 'helpers/dates';

interface LogsModalProps {
  group: CourseGroup;
  visible: boolean;
  hideModal: () => void;
}

const columns = [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt: string) => dayjs(createdAt).format(dateFormat)
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action'
  },
  {
    title: 'Enrollment date',
    dataIndex: 'startDate',
    key: 'startAt',
    render: (startAt: string) => dayjs(startAt).format(dateFormat)
  },
  {
    title: 'Disenrollment date',
    dataIndex: 'endDate',
    key: 'endAt',
    render: (endAt: string) => (endAt ? dayjs(endAt).format(dateFormat) : '')
  }
];

/**
 * @description Редактирование группы
 * @param {GroupModalProps} props
 * @return {React.ReactNode}
 */
export const LogsModal = (props: LogsModalProps) => {
  const { group, hideModal, visible } = props;

  const groupId = group.id ?? '';
  const logs: CourseGroupLog[] = group.logs;

  return (
    (<Modal
      title={`Logs for the group ${groupId}`}
      open={visible}
      onCancel={hideModal}
      key={groupId}
      footer={null}
      width={690}
    >
      <Table rowKey="createdAt" dataSource={logs} columns={columns} pagination={false} bordered={false} />
    </Modal>)
  );
};

export default { LogsModal };
