import React from 'react';
import { Modal, Button, Typography, Form, Flex } from 'antd';
import { MetaGoalItem } from 'types/courses';
import { useLanguageContext } from 'contexts/LanguageContext';
import { ReportRow } from 'types/Reports';
import { QuickTips } from 'Global/components/QuickTips';
import { QUICK_TIPS_META_GOALS } from 'Global/constants';
import { useUserContext } from 'contexts/UserContext';
import MetaGoalsMemo from 'Global/components/FormComponents/MetaGoals';

const { Text } = Typography;

interface MetaGoalsModalProps {
  rowId: number;
  rows: ReportRow[];
  setRows: (rows: ReportRow[]) => void;
  metaGoals: MetaGoalItem[];
  hasShowModal: boolean;
  setHasShowModal: (hasShow: boolean) => void;
}

/**
 * @description Модальное окно сохранения метацелей
 * @param {boolean} hasShowMetaGoalsModalModal
 * @param {function} setHasShowMetaGoalsModalModal
 * @return {React.ReactNode}
 */
export const MetaGoalsModal = ({
  rowId,
  rows,
  setRows,
  metaGoals,
  hasShowModal,
  setHasShowModal
}: MetaGoalsModalProps) => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const [form] = Form.useForm();
  const hasRiga = user?.branch?.id === 5;
  const selectedRow = rows.find(item => item.id === rowId);

  /**
   * @description Отдельное сохранение фидбэка
   * @return {void}
   */
  const handleSubmit = (values): void => {
    const updatedRows = rows.map((row: ReportRow) => {
      if (rowId === row.id) {
        return {
          ...row,
          goals: values?.goals
        };
      }

      return row;
    });
    setRows(updatedRows);
    setHasShowModal(false);
  };

  return (
    <Modal
      title={
        <div style={{ display: 'flex', gap: 10, alignItems: 'baseline' }}>
          <Text strong>{strings.META_GOALS}</Text>
          <Text strong style={{ color: '#cc4454' }}>
            {strings.PARENTS_WILL_NOT_SEE_THE_META_GOALS}
          </Text>
          {!hasRiga && <QuickTips url={QUICK_TIPS_META_GOALS} />}
        </div>
      }
      width={800}
      open={hasShowModal}
      onCancel={() => setHasShowModal(false)}
      destroyOnClose={true}
      footer={[]}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <MetaGoalsMemo
          metaGoals={metaGoals}
          metaGoalsGrades={selectedRow?.goals}
          prevMetaGoalsGrades={selectedRow?.goalsPrev}
        />
        <Flex justify="end" gap={10}>
          <Button key="cancel" onClick={() => setHasShowModal(false)}>
            {strings.CANCEL}
          </Button>
          <Button type="primary" key="save" htmlType="submit">
            {strings.OK}
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

export default MetaGoalsModal;
