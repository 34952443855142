import { TimetableChanges } from 'Admin/Website';
import * as websiteRoutes from 'Admin/Website/routes';
import {
  ADMIN_ACCESS,
  META_GOALS,
  CHANGE_TUTOR,
  BASE_INVOICE_LETTERS_TEMPLATES,
  EDIT_INVOICE_LETTERS_TEMPLATES,
  CREATE_INVOICE_LETTERS_TEMPLATES,
  CONTRACT_TEMPLATES,
  BASE_STATUSES,
  FAMILY_STATUS_NEW,
  FAMILY_STATUS_EDIT,
  TEACHER_STATUS_NEW,
  TEACHER_STATUS_EDIT,
  STUDENT_STATUS_NEW,
  STUDENT_STATUS_EDIT,
  BASE_BRANCHES,
  BRANCHES_NEW,
  BRANCHES_EDIT,
  BRANCHES_CLICKUP_NEW,
  BRANCHES_CLICKUP_EDIT,
  BASE_TAGS
} from 'Admin/Website/CRM/routes';
import { MetaGoals } from 'Admin/Website/CRM/MetaGoals';
import { ServiceRates } from 'Admin/Website/Finance/ServiceRates';
import { RateForm } from 'Admin/Website/Finance/ServiceRates/RateForm';
import { AdminAccess } from 'Admin/Website/CRM/AdminAccess';
import { TeachersWages } from 'Admin/Website/Finance/TeachersWages';
import { TeacherWageForm } from 'Admin/Website/Finance/TeachersWages/TeacherWageForm';
import { WeeklyEmail } from 'Admin/Website/WeeklyEmail';
import { WeeklyEmailForm } from 'Admin/Website/WeeklyEmail/WeeklyEmailForm';
import { PriceGroup } from 'Admin/Website/CRM/PriceGroup';
import AdditionalAccounts from 'Admin/Website/CRM/AdditionalAccounts';
import { Performers } from 'Admin/Website/Finance/Performers';
import PerformerForm from 'Admin/Website/Finance/Performers/PerformerForm';
import { ChangeTutor } from 'Admin/Website/CRM/ChangeTutor';
import { InvoiceLettersTemplates } from 'Admin/Website/CRM/InvoiceLettersTemplates';
import { LetterTamplateForm } from 'Admin/Website/CRM/InvoiceLettersTemplates/LetterTamplateForm';
import { ContractTemplates } from 'Admin/Website/CRM/ContractTemplates';
import { Statuses } from 'Admin/Website/CRM/Statuses';
import FamilyStatusForm from 'Admin/Website/CRM/Statuses/FamilyStatuses/AddEditForm';
import TeacherStatusForm from 'Admin/Website/CRM/Statuses/TeacherStatuses/AddEditForm';
import StudentStatusForm from 'Admin/Website/CRM/Statuses/StudentStatuses/AddEditForm';
import { Branches } from 'Admin/Website/CRM/Branches';
import { BranchForm } from 'Admin/Website/CRM/Branches/BranchesTab/BranchForm';
import { BranchClickupForm } from 'Admin/Website/CRM/Branches/BranchesClickupTab/BranchClickupForm';
import { Tags } from 'Admin/Website/CRM/Tags';

export const website = [
  {
    path: websiteRoutes.SCHEDULE_CHAGES,
    component: TimetableChanges,
    exact: true
  },
  {
    path: META_GOALS,
    component: MetaGoals,
    exact: true
  },
  {
    path: ADMIN_ACCESS,
    component: AdminAccess,
    exact: true
  },
  {
    path: websiteRoutes.SERVICE_RATES,
    component: ServiceRates,
    exact: true
  },
  {
    path: websiteRoutes.SERVICE_RATE_NEW,
    component: RateForm,
    exact: true
  },
  {
    path: `${websiteRoutes.SERVICE_RATES}/:id/edit`,
    component: RateForm,
    exact: true
  },
  {
    path: `${websiteRoutes.BASE_TEACHERS_WAGES}`,
    component: TeachersWages,
    exact: true
  },
  {
    path: websiteRoutes.BASE_TEACHERS_WAGES_EDIT,
    component: TeacherWageForm,
    exact: true
  },
  {
    path: websiteRoutes.BASE_TEACHERS_WAGES_NEW,
    component: TeacherWageForm,
    exact: true
  },
  {
    path: websiteRoutes.WEEKLY_EMAIL,
    component: WeeklyEmail,
    exact: true
  },
  {
    path: websiteRoutes.WEEKLY_EMAIL_EDIT,
    component: WeeklyEmailForm,
    exact: true
  },
  {
    path: websiteRoutes.WEEKLY_EMAIL_NEW,
    component: WeeklyEmailForm,
    exact: true
  },
  {
    path: websiteRoutes.PRICE_GROUP,
    component: PriceGroup,
    exact: true
  },
  {
    path: websiteRoutes.ADDITIONAL_ACCOUNTS,
    component: AdditionalAccounts,
    exact: true
  },
  {
    path: websiteRoutes.BASE_PERFORMERS,
    component: Performers,
    exact: true
  },
  {
    path: websiteRoutes.PERFORMERS_NEW,
    component: PerformerForm,
    exact: true
  },
  {
    path: websiteRoutes.PERFORMERS_EDIT,
    component: PerformerForm,
    exact: true
  },
  {
    path: CHANGE_TUTOR,
    component: ChangeTutor,
    exact: true
  },
  {
    path: BASE_INVOICE_LETTERS_TEMPLATES,
    component: InvoiceLettersTemplates,
    exact: true
  },
  {
    path: EDIT_INVOICE_LETTERS_TEMPLATES,
    component: LetterTamplateForm,
    exact: true
  },
  {
    path: CREATE_INVOICE_LETTERS_TEMPLATES,
    component: LetterTamplateForm,
    exact: true
  },
  {
    path: CONTRACT_TEMPLATES,
    component: ContractTemplates,
    exact: true
  },
  {
    path: BASE_STATUSES,
    component: Statuses,
    exact: true
  },
  {
    path: FAMILY_STATUS_NEW,
    component: FamilyStatusForm,
    exact: true
  },
  {
    path: FAMILY_STATUS_EDIT,
    component: FamilyStatusForm,
    exact: true
  },
  {
    path: TEACHER_STATUS_NEW,
    component: TeacherStatusForm,
    exact: true
  },
  {
    path: TEACHER_STATUS_EDIT,
    component: TeacherStatusForm,
    exact: true
  },
  {
    path: STUDENT_STATUS_NEW,
    component: StudentStatusForm,
    exact: true
  },
  {
    path: STUDENT_STATUS_EDIT,
    component: StudentStatusForm,
    exact: true
  },
  {
    path: BASE_BRANCHES,
    component: Branches,
    exact: true
  },
  {
    path: BRANCHES_NEW,
    component: BranchForm,
    exact: true
  },
  {
    path: BRANCHES_EDIT,
    component: BranchForm,
    exact: true
  },
  {
    path: BRANCHES_CLICKUP_NEW,
    component: BranchClickupForm,
    exact: true
  },
  {
    path: BRANCHES_CLICKUP_EDIT,
    component: BranchClickupForm,
    exact: true
  },
  {
    path: BASE_TAGS,
    component: Tags,
    exact: true
  }
];

export default { website };
