import React, { useState, useEffect } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Table, Typography, Button, message, Popconfirm, Row, Col } from 'antd';
import { Families } from 'api/Families';
import { Loader } from 'Global/components/Loader/Loader';
import { getColumns } from './columns';
import { AddStudent } from './AddStudent';
import { CopyStudentsFromGroup } from './CopyStudentsFromGroup';
import { CourseGroupStudent } from 'types/education';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useCoursesRepository } from 'repos/CoursesRepository';

const { Title } = Typography;

interface ClassRosterProps {
  groupId: number;
}

/**
 * @description Вкладка Class Roster
 * @param {object} props - component props
 * @return {React.ReactNode}
 */

export const ClassRoster = (props: ClassRosterProps) => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [disableDeleteBtn, setDisableDeleteBtn] = useState<number | null>(null);
  const { groupId } = props;
  const [user] = useUserContext();
  const [strings] = useLanguageContext();
  const hasTopManager = user?.hasRoles?.topManager;
  const coursesRepository = useCoursesRepository();

  /**
   * @description Получения студентов
   * @param {number} groupId
   * @return {Promise<any>}
   */
  const fetchClassRoster = async (groupId: number): Promise<any> => {
    try {
      setLoading(true);
      const { data } = await coursesRepository.getStudentsByGroup(groupId); // TODO Ждем правок в ответ
      setStudents(data);
      setLoading(false);
    } catch {}
  };

  /**
   * @description Удаление студента
   * @param {number} studentId
   * @return {Promise<any>}
   */
  const deleteStudent = async (studentId: number): Promise<any> => {
    setDisableDeleteBtn(studentId);
    try {
      await Families.deleteStudentFromGroup(groupId, studentId);
      message.success(strings.STUDENT_SUCCESSFULLY_DELETED, 2);
      fetchClassRoster(groupId);
    } finally {
      setDisableDeleteBtn(null);
    }
  };

  const controls = {
    title: '',
    dataIndex: 'student',
    key: 'controls',
    width: 80,
    render: (student: CourseGroupStudent) => {
      const confirmDelete = () => {
        deleteStudent(student.id);
      };

      return (
        <Popconfirm
          placement="left"
          onConfirm={confirmDelete}
          title={strings.ARE_YOU_SURE_WANT_DELETE_STUDENT}
          okText={strings.YES_DELETE}
          cancelText={strings.CANCEL}
        >
          <Button
            icon={<DeleteOutlined />}
            danger
            style={{ width: 36, height: 36 }}
            disabled={disableDeleteBtn === student.id}
            loading={disableDeleteBtn === student.id}
          />
        </Popconfirm>
      );
    }
  };

  const genColumns = hasTopManager ? [...getColumns(strings), controls] : getColumns(strings);

  useEffect(() => {
    fetchClassRoster(groupId);
  }, [groupId]);

  return (
    <Loader spinning={loading}>
      <Row align="middle" className="no-padding">
        <Col lg={12} xs={24}>
          <Title level={4}>{strings.CLASS_ROSTER}</Title>
        </Col>
        <Col lg={12} xs={24}>
          <Row justify="end" style={{ gap: 10, paddingBottom: 16 }}>
            <AddStudent groupId={groupId} handler={() => fetchClassRoster(groupId)} />
            <CopyStudentsFromGroup groupId={groupId} fetchClassRoster={fetchClassRoster} />
          </Row>
        </Col>
      </Row>
      <Table dataSource={students} columns={genColumns} bordered pagination={false} rowKey="id" />
    </Loader>
  );
};

export default { ClassRoster };
