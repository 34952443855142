import React, { Fragment } from 'react';
import { uniqWith } from 'lodash';
import { RateItemType, RatesProps } from 'Admin/People/Families/Family/Invoices/types';
import RateItem from 'Admin/People/Families/Family/Invoices/Create/RateItem';
import { Rate } from 'types';

/**
 * @description Rates
 * @param {object} props
 * @return {React.ReactNode}
 */
export const Rates = (props: RatesProps) => {
  const { prices, ratesList, priceTypes, handleRateDelete, form, familyBranch } = props;

  return (
    <Fragment>
      <div>
        {ratesList.map((rate: RateItemType, index) => {
          const pricesList: Rate[] = uniqWith(
            prices.filter((priceRate: Rate) => priceRate.type.id === rate.rateType),
            (r1, r2) => r1.id === r2.id && r1.price === r2.price && r1.name === r2.name
          );

          return (
            <RateItem
              {...rate}
              index={index}
              pricesList={pricesList}
              priceTypes={priceTypes}
              key={`${rate.id}-${index}`}
              handleDelete={handleRateDelete}
              form={form}
              familyBranch={familyBranch}
            />
          );
        })}
      </div>
    </Fragment>
  );
};

export default { Rates };
