import React, { useState, useEffect } from 'react';
import { useUserContext } from './UserContext';
import { useHistory, useLocation } from 'react-router-dom';
import { Admin, IParent, IStudent, SearchContract } from 'types';
import { Teacher } from 'types/Teachers/teacher';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import { useLanguageContext } from './LanguageContext';
import { message } from 'antd';
import queryString from 'query-string';
import { BASE_SEARCH } from '../Global/routes';
import { IRepositoryProvider } from '../repos/RepositoryProvider';

type SearchData = {
  admins: Admin[];
  children: IStudent[];
  parents: IParent[];
  teachers: Teacher[];
  contracts: SearchContract[];
};

interface ISearchContext {
  setQueryTerm: (queryTerm: string) => void;
  queryTerm: string;
  searchData: SearchData;
  fetchSearchResults: (queryTerm: string, isShowAll: boolean) => Promise<any>;
  isLoadSearchData: boolean;
}

const SearchContext = React.createContext<ISearchContext | null>(null);

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const initialSearchData = {
  admins: [],
  children: [],
  students: [],
  parents: [],
  teachers: [],
  contracts: []
};

export const SearchContextProvider: React.FC<IRepositoryProvider> = ({ children }) => {
  const query = useQuery();
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const [queryTerm, setQueryTerm] = useState<string>('');
  const [searchData, setSearchData] = useState<SearchData>(initialSearchData);
  const [isLoadSearchData, setIsLoadSearchData] = useState<boolean>(false);
  const globalRequestsRepository = useGlobalRequestsRepository();
  const { push } = useHistory();

  const hasAdmin = user?.hasRoles?.admin;

  const fetchSearchResults = async (queryTerm, isShowAll: boolean): Promise<any> => {
    try {
      setIsLoadSearchData(true);
      setQueryTerm(queryTerm);
      const { data } = await globalRequestsRepository.search(queryTerm, { showAll: isShowAll });
      let { admins, ...restData } = data;
      setSearchData(hasAdmin ? data : restData);
      setIsLoadSearchData(false);
      push({ search: queryString.stringify({ query: queryTerm, showAll: isShowAll }) });
    } catch {
      setIsLoadSearchData(false);
      message.error(strings.SORRY_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN);
      setSearchData(initialSearchData);
    }
  };

  const provider = { setQueryTerm, queryTerm, searchData, fetchSearchResults, isLoadSearchData };

  useEffect(() => {
    const initialQuery = query.get('query');
    const isShowAll = query.get('showAll');
    if (user && initialQuery && window.location.href.includes(BASE_SEARCH)) {
      fetchSearchResults(initialQuery, Boolean(isShowAll));
    }
  }, []);

  useEffect(() => {
    if (!window.location.href.includes(BASE_SEARCH)) {
      setQueryTerm('');
    }
  }, [query]);

  return <SearchContext.Provider value={provider}>{children}</SearchContext.Provider>;
};

export const useSearchContext = () => {
  const service = React.useContext(SearchContext) as ISearchContext;

  if (!service) {
    throw new Error('Search context is unavailable');
  }

  return service;
};
