import React, { useEffect, useMemo, useState } from 'react';
import { Table, Spin, Switch, Flex, Select } from 'antd';
import { getColumns } from './columns';
import { ChildCollaborationHistoryItem as CollabItem } from 'types/Family';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useStudentRepository } from 'repos/StudentRepository';
import { ALL_TIME_ENTRIES_DATE } from 'Global/constants';
import { uniqBy } from 'lodash';
import { FamilyCollabHystoryTitleForType, FamilyCollabHystoryTypes } from '../../Family/CollaborationHistory';
import { AddCollaborationHistory } from './AddCollaborationHistory';
import { useUserContext } from 'contexts/UserContext';
const { Option } = Select;

interface CollaborationHistoryProps {
  studentId: number;
}

/**
 * @description Collaboration History
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const CollaborationHistory = (props: CollaborationHistoryProps) => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const hasAdmin = user?.hasRoles?.admin;
  const studentRepository = useStudentRepository();
  const [collaborationHistory, setCollaborationHistory] = useState<CollabItem[]>([]);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [hasHideEmptyDescription, setHasHideEmptyDescription] = useState<boolean>(true);
  const [selectedType, setSelectedType] = useState('all');
  const [types, setTypes] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const { studentId } = props;

  /**
   * @description Обработчик на переключение Switch
   * @param {boolean} hasChecked
   * @return {void}
   */
  const onChangeSwitch = (hasChecked: boolean): void => {
    setHasHideEmptyDescription(hasChecked);
  };

  /**
   * @description Список история сотрудничества
   * @param {number} familyId
   * @param {any} params
   * @return {Promise<any>}
   */
  const fetchCollaborationHistory = async (): Promise<any> => {
    setHasLoading(true);
    const params = { start: ALL_TIME_ENTRIES_DATE };
    try {
      const { data } = await studentRepository.getCollaborationHistory(studentId, params);
      setCollaborationHistory(data);
    } finally {
      setHasLoading(false);
    }
  };

  const filteredCollabHistoryByType = useMemo(() => {
    if (selectedType === 'all') {
      return collaborationHistory;
    }
    return collaborationHistory.filter(item => item.type === selectedType);
  }, [selectedType, collaborationHistory]);

  const filteredCollabHistoryByDescription = useMemo(() => {
    if (hasHideEmptyDescription) {
      return filteredCollabHistoryByType.filter((item: CollabItem) => item?.description);
    }
    return filteredCollabHistoryByType;
  }, [hasHideEmptyDescription, filteredCollabHistoryByType]);

  useEffect(() => {
    fetchCollaborationHistory();
  }, [studentId]);

  useEffect(() => {
    const types = uniqBy(collaborationHistory, 'type').map(({ type }) => {
      return {
        label: FamilyCollabHystoryTitleForType[type] || type,
        value: type
      };
    });
    if (types.find(item => item.value === FamilyCollabHystoryTypes.text)) {
      setSelectedType(FamilyCollabHystoryTypes.text);
    }
    setTypes(types);
  }, [collaborationHistory]);

  return (
    <Spin spinning={hasLoading} tip="Loading collaboration history...">
      {hasAdmin && (
        <AddCollaborationHistory studentId={studentId} fetchCollaborationHistory={fetchCollaborationHistory} />
      )}
      <Flex gap={10} align="baseline" justify="space-between" wrap="wrap" style={{ marginBottom: 20 }}>
        <Flex gap={10} align="baseline">
          <span>{strings.FILTER_BY_TYPE}</span>
          <Select
            value={selectedType}
            onChange={val => {
              setSelectedType(val);
            }}
            style={{ width: 240 }}
          >
            <Option key="all" value="all">
              {strings.ALL}
            </Option>
            {types.map(({ label, value }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Flex>
        <Flex gap={10} align="baseline">
          <span>{strings.HIDE_EMPTY_LINES}</span>
          <Switch
            defaultChecked
            checkedChildren={strings.YES}
            unCheckedChildren={strings.NO}
            onChange={onChangeSwitch}
          />
        </Flex>
      </Flex>
      <Table
        dataSource={filteredCollabHistoryByDescription}
        columns={getColumns(strings)}
        pagination={false}
        bordered
      />
    </Spin>
  );
};

export default { CollaborationHistory };
