import React from 'react';
import { Modal, Spin } from 'antd';

import { IParent } from 'types';

interface ModalSetPayerConfirmProps {
  selectedPayer?: IParent;
  loading: boolean;
  onConfirm: () => void;
  onHide: () => void;
  isVisible: boolean;
}

/**
 * @description Модальное окно для подтверждения выбора пэйера
 * @param {ModalSetPayerConfirmProps} props
 * @returns {React.ReactNode}
 */
export const ModalSetPayerConfirm = (props: ModalSetPayerConfirmProps) => {
  const { selectedPayer, onConfirm, onHide, isVisible, loading } = props;

  return (
    (<Modal title="Confirm payer" open={isVisible} onCancel={onHide} onOk={onConfirm} okText="Confirm">
      <Spin spinning={loading}>
        Please confirm you want set {selectedPayer?.user?.name} {selectedPayer?.user?.surname} as a payer
      </Spin>
    </Modal>)
  );
};

export default { ModalSetPayerConfirm };
