import React, { useState } from 'react';
import styled from 'styled-components';
import { DownloadOutlined, UpOutlined } from '@ant-design/icons';

const StAlertDescription = styled.p`
  opacity: 0.8;
  font-size: 14px;
  margin: 0;
`;

interface AlertCollapseProps {
  title: string;
  description: string | React.ReactNode;
}

export const AlertCollapse = ({ title, description }: AlertCollapseProps) => {
  const [hasShowDescription, setHasShowDescription] = useState<boolean>(false);
  const iconProps = {
    style: { marginInlineStart: 10, cursor: 'pointer' },
    onClick: () => setHasShowDescription(!hasShowDescription)
  };

  return (
    <>
      {title}
      {hasShowDescription ? (
        <UpOutlined {...iconProps} />
      ) : (
        <>
          <DownloadOutlined {...iconProps} />
          <StAlertDescription>{description}</StAlertDescription>
        </>
      )}
    </>
  );
};
export default { AlertCollapse };
