import React, { useEffect, useState } from 'react';
import { useLanguageContext } from 'contexts/LanguageContext';
import { IStudent } from 'types';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useHistory } from 'react-router-dom';
import { Filter } from './Filter';
import { IChildrenFilter } from 'types/Family';
import { useStudentRepository } from 'repos/StudentRepository';
import { message, Row, Spin, Table } from 'antd';
import { getColumns } from './columns';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { formatDateValues } from 'helpers/dates';
import { isEmpty, omitBy } from 'lodash';
import { useUserContext } from 'contexts/UserContext';
import queryString from 'query-string';
import { PaginationInfo } from 'types/global';
import Pagination from 'Global/components/Pagination';
const { Column } = Table;

export const Students = () => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const { push, goBack } = useHistory();
  const { locationSearchParams } = useLocationSearchParams();
  const studentRepository = useStudentRepository();

  const defFilterValues = { branch: user?.branch?.id, statuses: [15] };
  const [students, setStudents] = useState<IStudent[]>([]);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>(null);

  const [hasLoading, setHasLoading] = useState<boolean>(false);

  const getStudents = async (values?: IChildrenFilter): Promise<any> => {
    setHasLoading(true);
    try {
      const params = {
        ...formatDateValues(omitBy(values, value => value === false)),
        withParents: 1,
        withSiblings: 1,
        withDetails: 1,
        activeGroupsOnly: 1
      };
      push({ search: queryString.stringify(params, { arrayFormat: 'bracket' }) });
      const {
        data: { items, paginationInfo }
      } = await studentRepository.getStudents(params);
      setStudents(items);
      setPaginationInfo(paginationInfo);
    } catch (err) {
      message.error(err.data.message);
    } finally {
      setHasLoading(false);
    }
  };

  const handleChangePage = async (page: number) => {
    await getStudents({ ...locationSearchParams, page });
  };

  useEffect(() => {
    if (isEmpty(locationSearchParams)) {
      getStudents(defFilterValues);
    } else {
      getStudents(locationSearchParams);
    }
  }, []);

  return (
    <Container>
      <StPageHeader onBack={goBack} title={strings.STUDENTS}>
        <Filter defValues={defFilterValues} onFilter={getStudents} />
      </StPageHeader>
      <Spin spinning={hasLoading}>
        <Row gutter={[10, 10]}>
          <Table dataSource={students} pagination={false}>
            {getColumns(strings, locationSearchParams).map(col => (
              <Column key={col.key} {...col} />
            ))}
          </Table>
          {paginationInfo && (
            <Pagination
              count={paginationInfo?.pageCount}
              current={paginationInfo?.current}
              pageChange={handleChangePage}
            />
          )}
        </Row>
      </Spin>
    </Container>
  );
};
