import React, { useState, useEffect, useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Button, Row } from 'antd';
import { Loader } from 'Global/components/Loader/Loader';
import { Families } from 'api';
import { Chronicles } from 'Admin/People/Families/Family/CollaborationHistory/Chronicles';
import { FilterHistory } from 'Admin/People/Families/Family/CollaborationHistory/Filter';
import { uniqBy } from 'lodash';
import { AddContact } from './AddContactModal';
import { IStudent } from 'types';
import { TypeChroniclesItem } from 'types/Family/collaborationHistory';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { useLanguageContext } from 'contexts/LanguageContext';
import { StPageHeader } from 'Global/GlobalStyle';

interface CollaborationHistoryProps {
  id: number;
  students: IStudent[];
  fetchFamilyById: (familyId: number) => Promise<any>;
}

interface Type {
  label: string;
  value: string;
}
export enum FamilyCollabHystoryTypes {
  register = 'register',
  text = 'text',
  task = 'task',
  call = 'call',
  statusChanged = 'statusChanged',
  invoiceStatusChanged = 'invoiceStatusChanged',
  invoiceCreated = 'invoiceCreated',
  invoiceCommented = 'invoiceCommented',
  sms = 'sms'
}

export const FamilyCollabHystoryTitleForType = {
  register: 'Feedback for parents',
  text: 'Note',
  task: 'Task',
  call: 'Call',
  invoiceCreated: 'Invoice created',
  invoiceStatusChanged: 'Invoice status shanged',
  invoiceCommented: 'Invoice commented',
  statusChanged: 'Status changed',
  sms: 'Sms'
};

/**
 * @description Family Collaboration History
 * @param {object} props
 * @return {React.ReactNode}
 */
export const CollaborationHistory = (props: CollaborationHistoryProps) => {
  const defaultValues = {
    type: 'all',
    start: dayjs().subtract(1, 'months'),
    end: dayjs()
  };
  const [strings] = useLanguageContext();
  const { id, students, fetchFamilyById } = props;
  const [chronicles, setChronicles] = useState<TypeChroniclesItem[]>([]);
  const [types, setTypes] = useState<Type[]>([]);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<string>(defaultValues.type);
  const [selectedDateStart, setSelectedDateStart] = useState<Dayjs>(defaultValues.start);
  const [selectedDateEnd, setSelectedDateEnd] = useState<Dayjs>(defaultValues.end);
  const hideAddModal = () => setShowAddModal(false);
  const handleClick = () => setShowAddModal(true);

  /**
   * @description Получение Collaboration History
   * @return {Promise<any>}
   */
  const fetchCollabHistoryByDates = async (): Promise<any> => {
    setHasLoading(false);
    const params = {
      start: selectedDateStart.format('DD.MM.YYYY'),
      end: selectedDateEnd.format('DD.MM.YYYY')
    };
    try {
      const { data: chronicles } = await Families.getCollaborationHistory(id, params);
      setChronicles(chronicles);
      //Возвращает список типов
      const types = uniqBy([...chronicles], 'type').map((chronicle: TypeChroniclesItem) => {
        const { type } = chronicle;
        return {
          label: FamilyCollabHystoryTitleForType[type] || type,
          value: type
        };
      });
      setTypes(types);
    } finally {
      setHasLoading(true);
    }
  };
  const finteredChronicles = useMemo(() => {
    if (selectedType === 'all') {
      return chronicles;
    }
    return chronicles.filter(item => item.type === selectedType);
  }, [selectedType, chronicles]);

  useEffect(() => {
    fetchCollabHistoryByDates();
  }, [selectedDateStart, selectedDateEnd]);

  return (
    <ErrorBoundary>
      <Loader spinning={!hasLoading}>
        <AddContact
          students={students}
          isShow={showAddModal}
          hide={hideAddModal}
          id={id}
          fetchCollabHistoryByDates={fetchCollabHistoryByDates}
          fetchFamilyById={fetchFamilyById}
        />
        <StPageHeader
          title={strings.COLLABORATION_HISTORY}
          extra={
            <Button type="primary" onClick={handleClick}>
              {strings.ADD_CONTACT}
            </Button>
          }
        />
        <FilterHistory
          types={types}
          setSelectedType={setSelectedType}
          setSelectedDateStart={setSelectedDateStart}
          setSelectedDateEnd={setSelectedDateEnd}
        />
        <Chronicles chroniclesItems={finteredChronicles} />
      </Loader>
    </ErrorBoundary>
  );
};

export default { CollaborationHistory };
