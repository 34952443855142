import React, { useState, useEffect } from 'react';
import { ArrowLeftOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { Typography, Row, Col, Button, Spin, Tooltip, Table, Flex } from 'antd';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { first } from 'lodash';
import { EditProgressReport as StViewProgressReport } from '../styles';
import Breadcrumbs from 'Global/components/Breadcrumbs';
import { convertStatusesToArray } from 'helpers';
import { useLastLocation } from 'react-router-last-location';
import { ReportStatus, ReportRow, ProgressReport } from 'types/Reports';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { BASE_PROGRESS_REPORT } from '../routes';
import { match } from 'react-router';

const { Title, Paragraph } = Typography;
const { Column } = Table;

/**
 * @description Редактирование репорта
 * @param {object} props
 * @return {React.ReactNode}
 */
export const ViewProgressReport = () => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();

  const { push, goBack } = useHistory();
  const location = useLocation();
  const [report, setReport] = useState<ProgressReport>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState<string>('');
  const [statuses, setStatuses] = useState<ReportStatus[]>([]);
  const [rows, setRows] = useState<ReportRow[]>([]);
  const gradeList = [
    { value: 2, title: strings.CAN_CONFIDENTLY_DO_IT_WITHOUT_ASSISTANCE_WITHOUT_MISTAKES_CAN_HELP_OTHERS },
    { value: 1, title: strings.CAN_DO_WITH_LIMITED_ASSISTANCE_LITTLE_MISTAKES },
    { value: 0, title: strings.CAN_ONLY_DO_WITH_PROMPTING_AND_EXTENSIVE_ASSISTANCE_LOTS_OF_MISTAKES },
    { value: -1, title: strings.CANT_DO_IT_YET },
    { value: -2, title: strings.NOT_AVAILABLE }
  ];

  const reportsRepository = useProgressReportsRepository();
  const handleBack = () => goBack();
  const hasTeacherRole = user?.hasRoles?.teacher;
  const lastLocation = useLastLocation();

  const getUpdatedByFullName = (): string | null => {
    if (report?.updatedBy) {
      const { name, surname, id: userId } = report.updatedBy;
      return user.id === userId ? strings.ME : `${name} ${surname}`;
    }
    return;
  };

  const getStatus = () => {
    const filteredStatuses = statuses
      ?.filter(({ value, text }) => {
        return value === report?.status;
      })
      .map(item => item.text);
    return first(filteredStatuses);
  };

  const fetchProgressReport = async (reportId: number | string): Promise<any> => {
    setLoading(true);
    setLoadingText(strings.LOADING_PROGRESS_REPORT);
    try {
      const { data } = await reportsRepository.getProgressReportById(reportId);
      setReport(data);
      setRows(data.rows);
    } finally {
      setLoading(false);
    }
  };

  const fetchStatuses = async (): Promise<any> => {
    try {
      const { data } = await reportsRepository.getStatusesList();
      setStatuses(convertStatusesToArray(data));
    } catch {
      setStatuses([]);
    }
  };

  const columns = [
    {
      title: strings.STUDENT,
      dataIndex: 'student',
      key: 'student',
      render: student => {
        return (
          <Flex vertical gap={5}>
            <Link to={`/family/${student.familyId}/student/${student.id}`}>
              {`${student?.user?.name} ${student?.user?.surname}`}
              {student?.status?.id === 21 && (
                <span style={{ color: '#f5222d', fontSize: 14 }}> {strings.NOT_STUDENT}</span>
              )}
            </Link>
          </Flex>
        );
      }
    },
    {
      title: strings.GENERAL_FEEDBACK_CONCERNING_THE_STUDENT_S_PROGRESS,
      dataIndex: 'progress',
      key: 'progress',
      render: progress => <div dangerouslySetInnerHTML={{ __html: progress }} />
    },
    {
      title: strings.COMMENT_FOR_THE_OFFICE,
      dataIndex: 'commentForOffice',
      key: 'commentForOffice'
    }
  ];

  const expandedRowRender = record => {
    const columns = [
      {
        title: strings.META_GOALS,
        dataIndex: 'id',
        key: 'id',
        render: id => {
          return report.goals.find(item => item.id === id)?.description;
        }
      },
      {
        title: strings.GRADE,
        dataIndex: 'grade',
        key: 'grade',
        render: grade =>
          grade !== null ? (
            <>
              <span style={{ color: '#CC1E44' }}>{grade === -2 ? strings.N_A : grade}</span>
              <span style={{ paddingInlineStart: 5, paddingInlineEnd: 5 }}>-</span>
              {gradeList.find(item => item.value === grade)?.title}
            </>
          ) : (
            '-'
          )
      }
    ];
    return <Table columns={columns} dataSource={record.goals} pagination={false} style={{ fontSize: 14 }} />;
  };

  useEffect(() => {
    fetchProgressReport(id);
    fetchStatuses();
  }, [id, hasTeacherRole]);

  return (
    <ErrorBoundary>
      <Spin tip={loadingText} spinning={loading}>
        <StViewProgressReport>
          <Breadcrumbs location={location} />
          <Row align="middle" justify="space-between" style={{ margin: '0 6px' }}>
            <Title level={3}>
              {lastLocation && <ArrowLeftOutlined onClick={handleBack} />} {report?.group?.groupName}
            </Title>
            <div style={{ flex: 1 }}>
              {hasTeacherRole && (report?.status === 'available' || report?.status === 'available') ? (
                <Tooltip title={strings.YOU_CANT_EDIT_THE_PROGRESS_REPORT}>
                  <Button type="primary" disabled style={{ float: 'right' }}>
                    {strings.EDIT}
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  type="primary"
                  style={{ float: 'right' }}
                  onClick={() => push(`${BASE_PROGRESS_REPORT}/edit/${report?.id}`)}
                >
                  {strings.EDIT}
                </Button>
              )}
            </div>
          </Row>
          <Row>
            <Col lg={24}>
              <Paragraph>
                <b>{strings.LAST_UPDATE_BY} </b>
                {getUpdatedByFullName()} <span style={{ opacity: '.5' }}>{report?.updatedAt}</span>
              </Paragraph>
              <Paragraph>
                <b> {strings.STATUS}: </b>
                {getStatus()}
              </Paragraph>
              {!hasTeacherRole && (
                <>
                  <Paragraph>
                    <b> {strings.TITLE}: </b>
                    {report?.name}
                  </Paragraph>
                  <Paragraph>
                    <b> {strings.THE_TEACHER_WHO_HAS_FILLED_IN_THE_JOURNAL}: </b>
                    {report?.teacher?.user?.surname} {report?.teacher?.user?.name}
                  </Paragraph>
                </>
              )}
              <Paragraph>
                <b> {strings.THEMES}: </b>
                <div dangerouslySetInnerHTML={{ __html: report?.whatWasLearned }} />
              </Paragraph>
            </Col>
          </Row>
          <Table
            dataSource={rows}
            pagination={false}
            rowKey="id"
            expandedRowRender={report?.goals?.isNotEmpty() ? expandedRowRender : null}
          >
            {columns.map(col => (
              <Column key={col.key} {...(col as any)} />
            ))}
          </Table>
        </StViewProgressReport>
      </Spin>
    </ErrorBoundary>
  );
};

export default { ViewProgressReport };
