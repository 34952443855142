import React, { useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  CheckCircleTwoTone,
  EditOutlined,
  LeftOutlined,
  LoginOutlined,
  MinusCircleTwoTone,
  RightOutlined
} from '@ant-design/icons';
import { Tag, Button, Col, Avatar, Row, Modal, Flex } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import {
  StyledCardDetailsItem,
  StyledCardDetails,
  StyledCollapsePanel,
  StyledCollapseAvatar,
  StyledCard,
  StyledCollapseBtn
} from 'Global/styles';
import { Admin } from 'types';
import { dateFormat, getAvatarUrl } from 'helpers';
import { CardTitle } from 'Global/components/CardTitle';
import { useHistory } from 'react-router-dom';
import { scrollTop } from 'helpers/scroll';
import { switchUserModal } from 'helpers/switchUser';
import dayjs from 'dayjs';
import { useUserContext } from 'contexts/UserContext';

/**
 * @description Компонент профиля учителя
 * @param {object} props
 * hasEmptyTabs - если нет доступа к табам, разтягиваем профиль во весь экран и разбиваем поля на 2 колнки
 * @return {React.ReactNode}
 */
export function AdminProfile(props: { admin: Admin; updateAdmin: () => Promise<any>; hasEmptyTabs?: boolean }) {
  const { admin, hasEmptyTabs } = props;
  const [strings] = useLanguageContext();
  const { push } = useHistory();
  const [{ confirm }, contextHolder] = Modal.useModal();
  const [user] = useUserContext();
  const hasTopManager = user?.hasRoles?.topManager;
  const hasBranchDirector = user?.hasRoles?.branchDirector;
  const hasExtraAccess = hasTopManager || hasBranchDirector;

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  function getBooleanPic(isActive) {
    return isActive ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <MinusCircleTwoTone twoToneColor="#f5222d" />;
  }

  const {
    id,
    loginAs,
    mainPhoto,
    tagsAge,
    sipuniNum,
    position,
    branch,
    tags,
    clickupToken,
    isActive,
    comment
  } = admin || {};
  const { email, org1Id, name, surname, birthDate } = admin?.user || {};

  const [hasCollapsed, setHasCollapsed] = useState<boolean>(false);

  const extraBtns = useMemo(
    () => (
      <Flex gap={5} align="center">
        {loginAs && <Button icon={<LoginOutlined />} onClick={() => switchUserModal(loginAs, confirm)} />}
        <Button icon={<EditOutlined />} onClick={() => push(`/admin/${id}/edit`)} />
      </Flex>
    ),
    [admin]
  );

  return (
    <>
      {contextHolder}
      {hasCollapsed && (
        <Col style={{ position: 'relative', opacity: 0.8 }}>
          <div style={{ position: 'sticky', top: 20 }}>
            <Avatar src={getAvatarUrl(mainPhoto)} size={60}>
              {name?.charAt(0)}
              {surname?.charAt(0)}
            </Avatar>
            <Button
              shape="circle"
              icon={<RightOutlined />}
              size="small"
              style={{
                position: 'absolute',
                ...StyledCollapseAvatar
              }}
              onClick={() => {
                setHasCollapsed(!hasCollapsed);
                scrollTop();
              }}
            />
          </div>
        </Col>
      )}
      {!hasCollapsed && (
        <Col flex="400px">
          {(!hasEmptyTabs || isMobile) && (
            <StyledCollapsePanel style={{ ...(isMobile && { flexDirection: 'row-reverse' }) }}>
              {hasExtraAccess && extraBtns}
              <StyledCollapseBtn>
                <span>{strings.MAIN_INFORMATION}</span>
                {!isMobile &&
                  (!hasCollapsed ? (
                    <LeftOutlined onClick={() => setHasCollapsed(!hasCollapsed)} />
                  ) : (
                    <RightOutlined onClick={() => setHasCollapsed(!hasCollapsed)} />
                  ))}
              </StyledCollapseBtn>
            </StyledCollapsePanel>
          )}
          <StyledCard style={{ ...(hasEmptyTabs && !isMobile && { borderRadius: 5 }) }}>
            {hasEmptyTabs && !isMobile ? (
              <Row justify="space-between" style={{ gap: 10 }}>
                <CardTitle {...admin?.user} previewAvatar={getAvatarUrl(mainPhoto)} />
                {hasExtraAccess && extraBtns}
              </Row>
            ) : (
              <CardTitle {...admin?.user} previewAvatar={getAvatarUrl(mainPhoto)} />
            )}
            <StyledCardDetails style={{ columnCount: hasEmptyTabs && !isMobile ? 2 : 1 }}>
              {branch && (
                <StyledCardDetailsItem>
                  <b>{strings.BRANCH}:</b>
                  <span>{branch.name}</span>
                </StyledCardDetailsItem>
              )}
              {email && (
                <StyledCardDetailsItem>
                  <b>{strings.E_MAIL}:</b>
                  {email}
                </StyledCardDetailsItem>
              )}
              {position && (
                <StyledCardDetailsItem>
                  <b>{strings.ROLE}:</b>
                  {position}
                </StyledCardDetailsItem>
              )}
              {birthDate && (
                <StyledCardDetailsItem>
                  <b>{strings.DATE_OF_BIRTH}:</b>
                  <span>{birthDate ? dayjs(birthDate).format(dateFormat) : ''}</span>
                </StyledCardDetailsItem>
              )}
              {sipuniNum && (
                <StyledCardDetailsItem>
                  <b>{strings.INTERNAL_NUMBER}:</b>
                  {sipuniNum}
                </StyledCardDetailsItem>
              )}
              {org1Id && (
                <StyledCardDetailsItem>
                  <b>{strings.ORG_1_ID}:</b>
                  {org1Id}
                </StyledCardDetailsItem>
              )}
              {clickupToken && (
                <StyledCardDetailsItem>
                  <b>{strings.CLICK_UP_TOKEN}:</b>
                  {clickupToken}
                </StyledCardDetailsItem>
              )}
              <StyledCardDetailsItem>
                <b>{strings.ACTIVE}:</b>
                {getBooleanPic(isActive)}
              </StyledCardDetailsItem>
              {tags && tags?.isNotEmpty() && (
                <StyledCardDetailsItem>
                  <div>
                    <b>{strings.TAGS}:</b>
                  </div>
                  <div style={{ textAlign: 'left' }}>
                    {tags.map(tag => (
                      <Tag color="blue" style={{ margin: '5px 5px 5px 0' }} key={tag?.id}>
                        {tag?.name}
                      </Tag>
                    ))}
                  </div>
                </StyledCardDetailsItem>
              )}
              {tagsAge && tagsAge?.isNotEmpty() && (
                <StyledCardDetailsItem>
                  <div>
                    <b>{strings.TAGS_AGE}:</b>
                  </div>
                  <div style={{ textAlign: 'left' }}>
                    {tagsAge.map(tag => (
                      <Tag color="blue" style={{ margin: '5px 5px 5px 0' }} key={tag?.id}>
                        {tag?.name}
                      </Tag>
                    ))}
                  </div>
                </StyledCardDetailsItem>
              )}
              {comment && (
                <StyledCardDetailsItem>
                  <b>{strings.COMMENT}:</b>
                  {comment}
                </StyledCardDetailsItem>
              )}
            </StyledCardDetails>
          </StyledCard>
        </Col>
      )}
    </>
  );
}
