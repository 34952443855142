import styled from 'styled-components';
import {Button} from "antd";

export const RegistersContainer = styled.div`
  .ant-table-wrapper {
    overflow-x: auto;
    overflow-y: hidden !important;
    .ant-table-header-column {
      text-align: center;
    }
  }
  .ant-table-column-title {
    font-size: 13px;
  }

  .ant-table-tbody .ant-btn {
    width: 48px;
  }

  & .ant-table-thead > tr > th {
    padding: 11px;
    text-align: center;
  }
  & .ant-table-tbody > tr > td {
    padding: 11px;
  }

  .ant-table-tbody {
    .ant-table-row {
      &:nth-child(even) {
        background: #f7f7f7;
      }
    }

    .table-border-right {
      border-right: 1px solid #f0f0f0;
    }

    .table-border-left {
      border-left: 1px solid #f0f0f0;
    }
  }

  & .ant-table-thead {
    & .ant-table-header-column {
      width: 100%;
    }

    & th {
      text-align: left;
      background: #fafafa;

      &.table-border-right {
        border-right: 1px solid #f0f0f0;
      }

      &.table-border-left {
        border-left: 1px solid #f0f0f0;
      }
    }

    & tr:first-child {
      & th {
        border: 1px solid #f0f0f0;

        &:not(:first-child) {
          border-left-color: transparent;
        }
      }
    }
  }
`;
export const StGeneralTitle = styled.div`
  opacity: 0.6;
`;
export const StTitle = styled.div`
  white-space: nowrap;
`;
export const StRegisterButton = styled(Button)`
  width: 48px;
`;


export default {
  RegistersContainer,
  StGeneralTitle,
  StTitle, StRegisterButton
};
