import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Tabs } from 'antd';
import { Loader } from 'Global/components/Loader/Loader';
import Breadcrumbs from 'Global/components/Breadcrumbs';
import { Finance } from 'Admin/People/Teachers/Teacher/Finance';
import { IndividualWage } from 'Admin/People/Teachers/Teacher/IndividualWage';
import { CurrentGroups } from 'Admin/People/Teachers/Teacher/CurrentGroups';
import { Teacher as ITeacher } from 'types/Teachers/teacher';
import { CollaborationHistory } from 'Admin/People/Teachers/Teacher/CollaborationHistory';
import { useUserContext } from 'contexts/UserContext';
import { useTeacherRepository } from 'repos/TeacherRepository';
import { TeacherTraining } from './Training';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { TeacherProfile } from './TeacherProfile';
import styled from 'styled-components';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { match } from 'react-router';
import { useMediaQuery } from 'react-responsive';

const { Content } = Layout;
const { TabPane } = Tabs;

const StyledTabsBar = styled(Tabs)`
  & .ant-tabs-bar {
    background-color: white;
    border-radius: 5px;
    margin: 0 20px;
    box-shadow: 0px 0px 46px -15px rgb(0 0 0 / 12%);
  }
`;

/**
 * @description Компонент просмотра учителя
 * @return {Promise<any>}
 */
export const Teacher = () => {
  const teacherRepository = useTeacherRepository();
  const [teacher, setTeacher] = useState<ITeacher>(null);
  const [showTeacher, setShowTeacher] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('finance');
  const [strings] = useLanguageContext();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();

  const location = useLocation();

  const { products } = useGlobalCollectionsContext();
  const [user] = useUserContext();
  const hasTeacher = user?.hasRoles?.teacher;
  const hasAcademicExpert = user?.hasRoles?.academicExpert;
  const hasAdminRole = user?.hasRoles?.admin;
  const hasAccessToFinanceTab = hasAdminRole && !hasAcademicExpert && teacher?.isCanShowFinance;

  /**
   * @description Получение учителя по id
   * @param {number} id
   * @return {Promise<any>}
   */
  const fetchTeacherById = async (): Promise<any> => {
    setShowTeacher(false);
    try {
      const { data } = await teacherRepository.getTeacherById(id);
      setTeacher(data);
      setShowTeacher(true);
    } catch {}
  };

  useEffect(() => {
    if (id) {
      fetchTeacherById();
    }
  }, [id]);

  useEffect(() => {
    if (hasTeacher) {
      setActiveTab('groups');
    } else {
      if (hasAccessToFinanceTab) {
        setActiveTab('finance');
      } else {
        setActiveTab('history');
      }
    }
  }, [hasAccessToFinanceTab]);

  return (
    <Loader spinning={!showTeacher}>
      <Content style={{ margin: 20 }}>
        <Row>
          <Breadcrumbs location={location} />
        </Row>
        <Row align="top" wrap={isMobile} style={{ gap: 20 }}>
          {teacher && <TeacherProfile teacher={teacher} updateTeacher={fetchTeacherById} />}
          <Col flex={1}>
            <Tabs activeKey={activeTab} onChange={(activeKey: string) => setActiveTab(activeKey)}>
              {hasAccessToFinanceTab && [
                <TabPane tab={strings.FINANCE} key="finance">
                  <Finance teacherId={id} products={products} teacher={teacher} />
                </TabPane>,
                <TabPane tab={strings.INDIVIDUAL_WAGE} key="wage">
                  <IndividualWage teacherId={id} />
                </TabPane>
              ]}
              {!hasTeacher && (
                <TabPane tab={strings.COLLABORATION_HISTORY} key="history">
                  <CollaborationHistory id={id} status={teacher?.status} />
                </TabPane>
              )}
              {!hasTeacher && (
                <TabPane tab={strings.TRAINING} key="training">
                  <TeacherTraining teacherId={id} />
                </TabPane>
              )}
              <TabPane tab={strings.CURRENT_GROUPS} key="groups">
                <CurrentGroups teacherId={id} />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Content>
    </Loader>
  );
};
