import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table, message, Flex, Popconfirm } from 'antd';
import { Loader } from 'Global/components/Loader/Loader';
import { TeacherWageListItem } from 'types/Teachers/wages';
import { Cost, ModalMode } from 'types';
import { AddWageModal } from 'Admin/People/Teachers/Teacher/IndividualWage/WageModal';
import { Link } from 'react-router-dom';
import { BASE_TEACHERS } from 'Admin/People/Teachers/routes';
import { Forbidden } from 'Global/modules/Errors/403';
import { AddCoefficient } from 'Admin/People/Teachers/Teacher/IndividualWage/AddCoefficient';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { multiplyWageByCoefficient } from 'helpers';
import { ErrorHandler } from 'helpers/services/ErrorHandler';
import { useLanguageContext } from 'contexts/LanguageContext';
import { StCard, StPageHeader } from 'Global/GlobalStyle';
import { useTeacherRepository } from 'repos/TeacherRepository';

interface IndividualWageProps {
  teacherId: number | string;
}

/**
 * @description Компонент с зарплатой учителя (табом)
 * @param {IndividualWageProps} props
 * @return {React.ReactNode}
 */
export const IndividualWage = (props: IndividualWageProps) => {
  const { teacherId } = props;
  const [strings] = useLanguageContext();
  const teacherRepository = useTeacherRepository();

  const [wagesList, setWagesList] = useState<TeacherWageListItem[]>([]);
  const [hasLoadIndividualWage, setHasLoadIndividualWage] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Hidden);
  const [currentWageId, setCurrentWageId] = useState<number>(null);
  const [hasAccess, setHasAccess] = useState<boolean>(false);

  const [currentCoefficient, setCurrentCoefficient] = useState<number>(1);

  /**
   * @description Получение списка wages
   * @return {Promise<any>}
   */
  const getIndividualWagesList = async (): Promise<any> => {
    setHasLoadIndividualWage(true);
    try {
      const { data } = await teacherRepository.getTeacherIndividualWageList(teacherId);
      setWagesList(data?.wageList);
      setCurrentCoefficient(data?.coefficient);
    } catch (err) {
      if (ErrorHandler.checkStatusCode(err, 403)) {
        setHasAccess(true);
      }
      message.error("Sorry, error getting teacher's finance :(");
    } finally {
      setHasLoadIndividualWage(false);
    }
  };

  /**
   * @description Установка формы редактирования
   * @param {number} wageId
   * @return {void}
   */
  const showEditForm = (wageId: number): void => {
    setCurrentWageId(wageId);
    setModalMode(ModalMode.Edit);
  };

  const handleDeleteWage = async (wageId: number) => {
    setHasLoadIndividualWage(true);
    try {
      await teacherRepository.deleteTeacherIndividualWage(teacherId, wageId);
      getIndividualWagesList();
    } catch {
    } finally {
      setHasLoadIndividualWage(false);
    }
  };
  const handleCloseModal = () => {
    setModalMode(ModalMode.Hidden);
    setCurrentWageId(null);
  };

  const columns = [
    {
      title: strings.WAGE,
      dataIndex: 'cost',
      key: 'wage',
      render: (cost: Cost) => (
        <p>
          {cost?.cost} - {cost?.name}
        </p>
      )
    },
    {
      title: strings.CHANGE_TO,
      dataIndex: 'individual',
      key: 'changeTo'
    },
    {
      title: '',
      dataIndex: '',
      key: 'actions',
      render: wage => (
        <Flex gap={10} justify="end">
          <Button icon={<EditOutlined />} size="small" onClick={() => showEditForm(wage.cost.id)} />
          <Popconfirm trigger="click" title={strings.ARE_YOU_SURE} onConfirm={() => handleDeleteWage(wage.cost.id)}>
            <Button size="small" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Flex>
      )
    }
  ];

  useEffect(() => {
    getIndividualWagesList();
  }, []);

  if (hasAccess)
    return (
      <Forbidden subTitle="Sorry, you do not have access to the wages this teacher :(">
        <Button size="large" type="primary" icon={<ArrowLeftOutlined />}>
          <Link to={BASE_TEACHERS} style={{ color: '#fff' }}>
            Back to teacher list
          </Link>
        </Button>
      </Forbidden>
    );

  return (
    <ErrorBoundary>
      <Loader spinning={hasLoadIndividualWage}>
        <StCard>
          <StPageHeader
            title={strings.INDIVIDUAL_WAGE}
            extra={[
              <Button type="primary" size="middle" onClick={() => setModalMode(ModalMode.Add)}>
                {strings.ADD_INDIVIDUAL_WAGE}
              </Button>
            ]}
          >
            {!hasLoadIndividualWage && (
              <Table
                dataSource={multiplyWageByCoefficient(wagesList, currentCoefficient)}
                columns={columns}
                pagination={false}
              />
            )}
          </StPageHeader>
          <StPageHeader title={`${strings.COEFFICIENT}: ${currentCoefficient}`}>
            <AddCoefficient
              getIndividualWagesList={getIndividualWagesList}
              teacherId={teacherId}
              changeCoefficient={setCurrentCoefficient}
              coefficient={currentCoefficient}
            />
          </StPageHeader>
        </StCard>
        <AddWageModal
          hideModal={handleCloseModal}
          teacherId={teacherId}
          getWages={getIndividualWagesList}
          wageId={currentWageId}
          wagesList={wagesList}
          mode={modalMode}
        />
      </Loader>
    </ErrorBoundary>
  );
};

export default { IndividualWage };
