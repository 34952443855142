import React, { useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Redirect } from 'react-router';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { Input, Modal, Col, Row, Table, Typography, Tabs, Button, message, DatePicker, Descriptions } from 'antd';
import { Loader } from 'Global/components/Loader/Loader';
import { postOperationDoubt } from 'api/Finance';
import { getGlobalDoubtsColumns, getColumns } from './columns';
import { AxiosResponse } from 'axios';
import { scrollTop } from 'helpers/scroll';
import { branchesWithNoFinances } from 'Teacher/global/constants';
import { isEmpty, first } from 'lodash';
import { dateFormatForBackend, hasMoscowBranch } from 'helpers';
import { inclineMonth } from 'helpers/dates';
import { TeacherFinance } from 'types/Teachers/finance';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { ApiGetTeacherFinance } from 'types/Teachers/finance';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useUserContext } from 'contexts/UserContext';
import { useTeacherRepository } from 'repos/TeacherRepository';
import { Container } from 'Global/GlobalStyle';
import { useHistory } from 'react-router-dom';

const { TabPane } = Tabs;
const { Title } = Typography;
const { TextArea } = Input;
const { Column } = Table;
const { MonthPicker } = DatePicker;

const initialState = {
  summary: {},
  currentMonth: [],
  previousMonths: [],
  globalDoubts: []
};
const defaultPrevMonth = dayjs().subtract(1, 'month');

/**
 * @description Teacher finance component
 */
export const Finance = () => {
  const [strings, lang] = useLanguageContext();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isReportPending, setReportPending] = useState<boolean>(false);
  const [reportMessage, setReportMessage] = useState<string>('');
  const [previousMonth, setPreviousMonth] = useState<Dayjs>(defaultPrevMonth);
  const [operationId, setOperationId] = useState<number>(null);
  const [finance, setFinance] = useState<TeacherFinance>(initialState);
  const [hasLoadTeacherFinance, setHasLoadTeacherFinance] = useState<boolean>(false);
  const [lessonsCount, setLessonsCount] = useState<number>(0);

  const teacherRepository = useTeacherRepository();
  const [{ meTeacherId }] = useUserContext();
  const [user] = useUserContext();
  const { goBack } = useHistory();
  const userBranch = user?.branch?.name;
  const hideTeacherFinance = user?.branch?.hideTeacherFinance;

  const getTeacherFinanceByUserId = async (params?: ApiGetTeacherFinance): Promise<any> => {
    try {
      setHasLoadTeacherFinance(true);
      const { data }: AxiosResponse = await teacherRepository.getTeacherFinance(meTeacherId, params);
      setFinance(data);
      setHasLoadTeacherFinance(false);
    } catch {
      setHasLoadTeacherFinance(false);
    }
  };

  const handleErrorReport = async (): Promise<any> => {
    setReportPending(true);

    try {
      await postOperationDoubt({
        comment: reportMessage,
        operationId: operationId
      });
      message.success(strings.REPORT_HAS_BEEN_SENT_SUCCESSFULLY, 2);
      await getTeacherFinanceByUserId();
    } catch {
      message.error(`${strings.ERROR_TRY_AGAIN_LATER} :(`, 2);
    } finally {
      setReportPending(false);
      setIsModalVisible(false);
      setReportMessage('');
    }
  };

  const handleChangePage = () => {
    scrollTop();
  };

  const goToBack = (): void => {
    setPreviousMonth(dayjs(previousMonth).subtract(1, 'month'));
  };

  const goToNext = (): void => {
    setPreviousMonth(dayjs(previousMonth).add(1, 'month'));
  };

  /**
   * @description Получение количества уроков в предыдущем месяце
   * @return {Promise<any>}
   * */
  const fetchScheduleStatistics = async (): Promise<any> => {
    try {
      const {
        data: { lessonsCount, eventsWithPayment }
      } = await teacherRepository.getTeacherScheduleStatistics(meTeacherId);
      const totalCount = lessonsCount + eventsWithPayment;
      setLessonsCount(totalCount);
    } catch {}
  };

  useEffect(() => {
    const params = {
      prevDateStart: defaultPrevMonth.startOf('month').format(dateFormatForBackend),
      prevDateEnd: defaultPrevMonth.endOf('month').format(dateFormatForBackend)
    };
    getTeacherFinanceByUserId(params);
    fetchScheduleStatistics();
  }, [meTeacherId]);

  useEffect(() => {
    const params = {
      prevDateStart: previousMonth.startOf('month').format(dateFormatForBackend),
      prevDateEnd: previousMonth.endOf('month').format(dateFormatForBackend)
    };
    getTeacherFinanceByUserId(params);
  }, [previousMonth]);

  if (branchesWithNoFinances.includes(userBranch) || hideTeacherFinance) {
    return <Redirect to="/" />;
  }

  return (
    <ErrorBoundary>
      <Modal title={strings.REPORT_BAG} open={isModalVisible} onCancel={() => setIsModalVisible(false)} footer={null}>
        <TextArea
          placeholder={strings.ENTER_REPORT_MESSAGE}
          onChange={e => setReportMessage(e.target.value)}
          value={reportMessage}
          style={{
            resize: 'none',
            marginTop: 20
          }}
        />
        <Button
          disabled={isReportPending}
          type="primary"
          onClick={handleErrorReport}
          style={{
            marginInlineStart: '100%',
            transform: 'translateX(-100%)'
          }}
        >
          {strings.REPORT}
        </Button>
      </Modal>
      <Container>
        <PageHeader
          onBack={goBack}
          style={{ padding: 0 }}
          title={strings.FINANCE}
          extra={[
            <Button
              type="primary"
              onClick={() => {
                setOperationId(null);
                setIsModalVisible(true);
              }}
            >
              {strings.ERROR_REPORT}
            </Button>
          ]}
        >
          <Descriptions size="small" column={1}>
            <Descriptions.Item label={strings.BALANCE}>{finance.summary.endBalance}</Descriptions.Item>
            <Descriptions.Item label={strings.NUMBER_OF_LESSONS_IN_THE_PREVOIUS_MONTH}>
              {lessonsCount}
            </Descriptions.Item>
            {hasMoscowBranch(user?.branch) && (
              <Descriptions.Item label={strings.INDIVIDUAL_COEFFICIENT}>{finance.coefficient}</Descriptions.Item>
            )}
          </Descriptions>
        </PageHeader>
        {!isEmpty(finance.globalDoubts) && (
          <>
            <PageHeader style={{ padding: 0 }} title={strings.ERROR_REPORT} />
            <Table
              dataSource={finance.globalDoubts}
              columns={getGlobalDoubtsColumns(strings)}
              pagination={false}
              style={{ marginTop: 20, marginBottom: 20 }}
            />
          </>
        )}
        <Row>
          <Col lg={24}>
            <Loader spinning={hasLoadTeacherFinance}>
              <Tabs defaultActiveKey="currentMonth">
                <TabPane tab={strings.THIS_MONTH} key="currentMonth">
                  <Table dataSource={finance.currentMonth} style={{ marginBottom: 20 }} pagination={false} rowKey="id">
                    {getColumns(strings, true, lang, setOperationId, setIsModalVisible)
                      .filter(col => col)
                      .map(col => (
                        <Column key={col.key} {...col} />
                      ))}
                  </Table>
                </TabPane>
                <TabPane tab={strings.PREVIOUS_MONTH} key="previousMonths">
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Row align="middle" style={{ gap: 5 }}>
                        <Button icon={<LeftOutlined />} size="large" onClick={goToBack} />
                        <MonthPicker
                          value={previousMonth}
                          disabledDate={current => current > defaultPrevMonth}
                          onChange={val => {
                            val ? setPreviousMonth(val) : setPreviousMonth(defaultPrevMonth);
                          }}
                          format={'MMM YYYY'}
                          style={{ width: '150px' }}
                        />
                        <Button
                          icon={<RightOutlined />}
                          size="large"
                          onClick={goToNext}
                          disabled={previousMonth.isSame(defaultPrevMonth) ? true : false}
                        />
                      </Row>
                    </Col>
                    <Col>
                      <Title level={4}>
                        {Math.round(first(finance.previousMonths)?.remainingBalance)} {strings.REMANING_BALANCE}{' '}
                        {inclineMonth(previousMonth.format(dateFormatForBackend), lang)}
                      </Title>
                    </Col>
                  </Row>
                  <Table
                    dataSource={finance.previousMonths}
                    style={{ marginBottom: 20 }}
                    pagination={{ pageSize: 50, onChange: handleChangePage }}
                    rowKey="id"
                    columns={getColumns(strings, false, lang).filter(col => col)}
                  />
                </TabPane>
              </Tabs>
            </Loader>
          </Col>
        </Row>
      </Container>
    </ErrorBoundary>
  );
};

export default { Finance };
