import React, { useMemo } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { Tabs } from 'antd';
import { IFamily, IStudent } from 'types';
import { useHistory } from 'react-router-dom';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useFamilyContext } from 'contexts/FamilyContext';
import { useConstantsContext } from 'contexts/ConstantsContext';
import { TransparentContainer } from 'Global/GlobalStyle';
import { SubjectTabs } from './SubjectTabs';
import { first } from 'lodash';

const { TabPane } = Tabs;

/**
 * @description Cтраница журнала ЛК родителя
 * @param {object} props
 * @return {React.ReactNode}
 */
export const Journal = () => {
  const [strings] = useLanguageContext();
  const { goBack } = useHistory();
  const [user] = useUserContext();
  const [family]: [IFamily] = useFamilyContext();

  const { ACTIVE_STUDENT_STATUS_ID } = useConstantsContext();
  const hasParentRole = user?.hasRoles?.parent;
  const hasStudentRole = user?.hasRoles?.student;

  const studentList: IStudent[] = useMemo(
    () => family?.children?.filter((child: IStudent) => child?.status?.id === ACTIVE_STUDENT_STATUS_ID) || [],
    [family?.children]
  );

  return (
    <ErrorBoundary>
      <TransparentContainer>
        <PageHeader
          onBack={goBack}
          title={strings.REGISTER}
          style={{ padding: 0, marginBottom: hasStudentRole || studentList.length === 1 ? 10 : 0 }}
        ></PageHeader>
        {hasStudentRole && <SubjectTabs studentId={user?.meStudentId} />}
        {hasParentRole && studentList.length === 1 && (
          <SubjectTabs
            studentId={first(studentList).id}
            reportType={first(studentList)?.studyProgram?.stage?.reportType}
          />
        )}
        {hasParentRole && studentList.length > 1 && (
          <Tabs defaultActiveKey="1">
            {studentList?.map((student: IStudent) => (
              <TabPane tab={student?.user?.name} key={String(student.id)}>
                <SubjectTabs studentId={student.id} reportType={student?.studyProgram?.stage?.reportType} />
              </TabPane>
            ))}
          </Tabs>
        )}
      </TransparentContainer>
    </ErrorBoundary>
  );
};

export default { Journal };
