import React, { useState, useEffect } from 'react';
import { Row, Button, Collapse } from 'antd';
import { LessonItem, LessonText, LessonTitle } from 'Admin/Courses/styles';
import { UploadMaterialButton } from '../Full/UploadMaterialButton';
import { MaterialsList } from '../Full/Materials';
import { ModalLessonsComments } from 'Global/components/ModalLessonsComments';
import { createTextLinks } from 'helpers';
import { useLanguageContext } from 'contexts/LanguageContext';
import { IOutcomesByOriginalLesson } from 'types/courses';
import { useCoursesRepository } from 'repos/CoursesRepository';

const { Panel } = Collapse;

export function ShowOriginalCourseLesson({ lesson, lessonMaterials, moveMaterial, getOriginalCourseFiles }) {
  const [strings] = useLanguageContext();
  const [visibleModalComments, setVisibleModalComments] = useState<boolean>(false);
  const [selectedLessonId, setSelectedLessonId] = useState<number>();
  const [selectedTypeComments, setSelectedTypeComments] = useState<'hw' | 'classwork'>('hw');
  const { id: lessonId, outcomes, keyVocabulary, resources, suggestedActivities, suggestedHomework, comment } = lesson;

  const coursesRepository = useCoursesRepository();
  const [goals, setGoals] = useState<IOutcomesByOriginalLesson[]>([]);

  const getSubjectGoals = async (): Promise<any> => {
    try {
      const { data } = await coursesRepository.getOutcomesByOriginalLesson(lessonId);
      setGoals(data);
    } catch {}
  };
  useEffect(() => {
    getOriginalCourseFiles();
    getSubjectGoals();
  }, []);

  return (
    <>
      <ModalLessonsComments
        lessonId={selectedLessonId}
        typeComments={selectedTypeComments}
        visibleModalComments={visibleModalComments}
        setVisibleModalComments={setVisibleModalComments}
      />
      {goals.isNotEmpty() && (
        <LessonItem>
          <LessonTitle>{strings.LESSON_OUTCOMES}</LessonTitle>
          <LessonText>
            {goals.map(goal => (
              <div style={{ lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: goal?.description }} />
            ))}
          </LessonText>
        </LessonItem>
      )}
      {outcomes && (
        <LessonItem>
          <LessonTitle>{strings.OLD_OUTCOMES}</LessonTitle>
          <LessonText>
            <div dangerouslySetInnerHTML={{ __html: createTextLinks(outcomes) }} style={{ marginBottom: 16 }} />
          </LessonText>
        </LessonItem>
      )}

      {keyVocabulary && (
        <LessonItem>
          <LessonTitle>{strings.KEY_VOCABULARY}</LessonTitle>
          <LessonText>
            <div dangerouslySetInnerHTML={{ __html: createTextLinks(keyVocabulary) }} style={{ marginBottom: 16 }} />
          </LessonText>
        </LessonItem>
      )}

      <LessonItem>
        <LessonTitle>{strings.SUGGESTED_ACTIVITIES}</LessonTitle>
        <LessonText>
          <div
            dangerouslySetInnerHTML={{ __html: createTextLinks(suggestedActivities) }}
            style={{ marginBottom: 16, whiteSpace: 'pre-wrap' }}
          />
          <Collapse style={{ marginBottom: 24 }}>
            <Panel
              disabled={lessonMaterials.suggestedActivities.approvedMaterials.isEmpty()}
              header={strings.APPROVED_MATERIALS}
              key="1"
              extra={
                <UploadMaterialButton
                  lessonId={lessonId}
                  getOriginalCourseFiles={getOriginalCourseFiles}
                  type="approved"
                  materialType="originalLessonMaterial"
                />
              }
            >
              <MaterialsList
                materialsList={lessonMaterials.suggestedActivities.approvedMaterials}
                getFiles={getOriginalCourseFiles}
                lessonId={lessonId}
              />
            </Panel>

            <Panel
              disabled={lessonMaterials.suggestedActivities.customMaterials.isEmpty()}
              header={strings.CUSTOM_MATERIALS}
              key="2"
              extra={
                <Row align="middle" style={{ gap: 10 }}>
                  <Button
                    type="link"
                    style={{ padding: '0' }}
                    onClick={e => {
                      e.stopPropagation();
                      setSelectedLessonId(lessonId);
                      setSelectedTypeComments('classwork');
                      setVisibleModalComments(true);
                    }}
                  >
                    {strings.SHOW_ALL_COMMENTS}
                  </Button>
                  <UploadMaterialButton
                    lessonId={lessonId}
                    getOriginalCourseFiles={getOriginalCourseFiles}
                    type="custom"
                    materialType="originalLessonMaterial"
                  />
                </Row>
              }
            >
              <MaterialsList
                materialsList={lessonMaterials.suggestedActivities.customMaterials}
                getFiles={getOriginalCourseFiles}
                lessonId={lessonId}
              />
            </Panel>
          </Collapse>
        </LessonText>
      </LessonItem>
      <LessonItem>
        <LessonTitle>{strings.SUGGESTED_HOMEWORK}</LessonTitle>
        <LessonText>
          <div
            dangerouslySetInnerHTML={{ __html: createTextLinks(suggestedHomework) }}
            style={{ marginBottom: 16, whiteSpace: 'pre-wrap' }}
          />
          <Collapse style={{ marginBottom: 24 }}>
            <Panel
              disabled={lessonMaterials.suggestedHomework.approvedMaterials.isEmpty()}
              header={strings.APPROVED_MATERIALS}
              key="1"
              extra={
                <UploadMaterialButton
                  type="approved"
                  lessonId={lessonId}
                  getOriginalCourseFiles={getOriginalCourseFiles}
                  materialType="originalLessonHomework"
                />
              }
            >
              <MaterialsList
                materialsList={lessonMaterials.suggestedHomework.approvedMaterials}
                getFiles={getOriginalCourseFiles}
                lessonId={lessonId}
              />
            </Panel>
            <Panel
              disabled={lessonMaterials.suggestedHomework.customMaterials.isEmpty()}
              header={strings.CUSTOM_MATERIALS}
              key="2"
              extra={
                <Row align="middle" style={{ gap: 10 }}>
                  <Button
                    type="link"
                    style={{ padding: '0' }}
                    onClick={e => {
                      e.stopPropagation();
                      setSelectedLessonId(lessonId);
                      setSelectedTypeComments('hw');
                      setVisibleModalComments(true);
                    }}
                  >
                    {strings.SHOW_ALL_COMMENTS}
                  </Button>
                  <UploadMaterialButton
                    type="custom"
                    lessonId={lessonId}
                    getOriginalCourseFiles={getOriginalCourseFiles}
                    materialType="originalLessonHomework"
                  />
                </Row>
              }
            >
              <MaterialsList
                materialsList={lessonMaterials.suggestedHomework.customMaterials}
                getFiles={getOriginalCourseFiles}
                lessonId={lessonId}
              />
            </Panel>
          </Collapse>
        </LessonText>
      </LessonItem>

      {comment && (
        <LessonItem>
          <LessonTitle>{strings.COMMENT}</LessonTitle>
          <LessonText>
            <div dangerouslySetInnerHTML={{ __html: createTextLinks(comment) }} style={{ marginBottom: 16 }} />
          </LessonText>
        </LessonItem>
      )}

      {resources && (
        <LessonItem>
          <LessonTitle>{strings.RESOURCES}</LessonTitle>
          <LessonText>
            <div dangerouslySetInnerHTML={{ __html: createTextLinks(resources) }} style={{ marginBottom: 16 }} />
          </LessonText>
        </LessonItem>
      )}
    </>
  );
}
