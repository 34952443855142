import React from 'react';
import styled, { css } from 'styled-components';

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const initialStateProgress = {
  items: [],
  paginationInfo: {
    totalCount: 0,
    current: 0
  }
};

const ApprovedContainer = styled.div<{ color: string }>`
  padding: 0 10px;

  ${props =>
    props.color === 'rejected' &&
    css`
      box-shadow: 3px 0 0 0 #f5222d;
    `}

  ${props =>
    props.color === 'approved' &&
    css`
      box-shadow: 3px 0 0 0 #52c41a;
    `}

    ${props =>
    props.color === 'N/A' &&
    css`
      box-shadow: 3px 0 0 0 transparent;
    `}
`;

/**
 * @description Компонент обертка для отображения линии
 * @param {object} props
 * @link https://londongates.zendesk.com/agent/tickets/809
 * @link https://3x.ant.design/docs/spec/colors#header
 * @return {React.ReactNode}
 */
export const Approved: React.FC<{ status: 'rejected' | 'approved' | 'N/A'; children: React.ReactElement }> = ({
  status,
  children
}) => {
  let color;

  if (status === 'rejected') {
    color = '#f5222d';
  } else if (status === 'approved') {
    color = '#52c41a';
  } else if (status === 'N/A') {
    color = 'transparent';
  }

  return <ApprovedContainer color={color}>{children}</ApprovedContainer>;
};

/**
 * @description Функция для возврата статуса
 * @param {boolean} hasApproved
 * @param {boolean} hasRejected
 * @return {string}
 */
export const getAcademicProgressStatus = (hasApproved: boolean, hasRejected: boolean) => {
  if (hasRejected) {
    return 'rejected';
  } else if (hasApproved) {
    return 'approved';
  } else {
    return 'N/A';
  }
};

export default { months, getAcademicProgressStatus, Approved };
