import React, { useCallback, useState } from 'react';
import { message, Button, Flex } from 'antd';
import { Courses } from 'api/Courses';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useLessonRegisterStore } from 'stores/useLessonRegisterStore';
import {StGeneralTitle} from "../styles";

/**
 * @description Рендеринг колонки in class
 * @param {function} setHasShowTestModal
 * @param {number} lessonId
 * @return {React.ReactNode}
 */
export const InClassTitle = React.memo(({ setHasShowTestModal }: { setHasShowTestModal: (val: boolean) => void }) => {
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [strings] = useLanguageContext();
  const setHasRegistersFetch = useLessonRegisterStore(state => state.setHasRegistersFetch);
  const setHasRegistersUpdate = useLessonRegisterStore(state => state.setHasRegistersUpdate);
  const task = useLessonRegisterStore(state => state.extraRegisterData?.task);
  const setMaxTaskGrade = useLessonRegisterStore(state => state.setMaxTaskGrade);

  const handleDeleteTask = useCallback(
    async (taskId: number): Promise<any> => {
      setHasLoading(true);
      setHasRegistersUpdate(true);
      try {
        await Courses.deleteLessonTask(taskId);
        message.success(strings.DELETED_TEST);
        setHasRegistersFetch(true);
        setMaxTaskGrade(null);
      } catch {
        message.error(strings.TEST_DELETION_ERROR);
      } finally {
        setHasLoading(false);
      }
    },
    [setHasRegistersUpdate, setHasRegistersFetch, setMaxTaskGrade]
  );

  const handleAddTask = useCallback(async (): Promise<any> => {
    setHasRegistersUpdate(true);
    setHasShowTestModal(true);
  }, [setHasRegistersUpdate, setHasShowTestModal]);

  return (
    <Flex align="center" justify="center">
      <StGeneralTitle style={{ flex: 1 }}>{strings.IN_THE_CLASS}</StGeneralTitle>
      {task?.id ? (
        <Button size="small" loading={hasLoading} onClick={() => handleDeleteTask(task.id)}>
          {strings.REMOVE_TEST}
        </Button>
      ) : (
        <Button size="small" onClick={handleAddTask}>
          {strings.ADD_TEST}
        </Button>
      )}
    </Flex>
  );
});
