import React, { useState, useEffect } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { DroppableList } from './DroppableList';
import { ContainerDraggbles } from './styles';
import { StyledComponent } from 'styled-components';

interface DraggbleContainerProps {
  Container?: StyledComponent<any, any>;
  columnsData: Array<{
    id: string;
    taskIds?: any[];
    title?: string;
    index?: number;
  }>; // массив колонок
  cards: any[]; // массив карточек(данные)
  CardComponent: any; // компонент карточка
  stylesForColumns?: object;
  onDraggble?: (object) => void;
  stylesForColumn?: object;
}

interface HocRenderContainerProps {
  Container?: StyledComponent<any, any>;
  ContainerDraggbles: StyledComponent<any, any>;
  children?: React.ReactNode;
}

/**
 * @description компонент определения дал ли другой программист свой блок или нет
 * @param Container блок со своими стилями
 * @param ContainerDraggbles блок по умолчанию
 * @param children все остальное
 * @constructor
 */
export const HocRenderContainer = ({ Container, ContainerDraggbles, children }: HocRenderContainerProps) => {
  if (Container) {
    return <Container>{children}</Container>;
  }

  return <ContainerDraggbles>{children}</ContainerDraggbles>;
};

/**
 * @description компонент для перетаскивания элементов
 * @param Container блок со своими стилями
 * @param {object} columns объекты колонок
 * @constructor
 * @return {React.Component}
 */
export const DraggbleContainer = ({
  Container,
  columnsData,
  cards,
  CardComponent,
  stylesForColumns,
  onDraggble,
  stylesForColumn
}: DraggbleContainerProps) => {
  const [columns, setColumns] = useState<object>(null);
  const [columnOrder, setColumnOrder] = useState<string[]>(null);
  //const [_, setTasks] = useState<object>(null);
  const [isLoadRenderColumns, toggleLoadRenderColumns] = useState<boolean>(false);

  /**
   * @description функция распределения данных
   * @return {Promise<any>}
   */
  const startData = async (): Promise<any> => {
    return new Promise(res => {
      const columnsState = {};
      const columnsOrderData = [];
      const objCrm = {};

      for (let i = 0; i < columnsData.length; i += 1) {
        columnsData[i].taskIds = [];
        columnsData[i].index = i;
        columnsState[columnsData[i].id] = columnsData[i];
        columnsOrderData.push(columnsData[i].id);
      }

      cards.forEach((item, task) => {
        objCrm[cards[task].id] = cards[task];
        if (columnsState[cards[task]?.column?.id]) {
          columnsState[cards[task]?.column?.id].taskIds.push(cards[task].id);
        }
      });

      setColumns(columnsState);
      setColumnOrder(columnsOrderData);
      //setTasks(objCrm);
      res(true);
    });
  };

  useEffect(() => {
    toggleLoadRenderColumns(true);
    startData().finally(() => toggleLoadRenderColumns(false));
  }, [cards]); // eslint-disable-line

  /**
   * @description вызывается если мы не перенесли в другую колонку
   * @param start начало колонки
   * @param source информация об положении исходного
   * @param destination информация о положении нового
   * @param draggableId id карточки
   * @param columns колонки
   */
  const notReorderCards = (start, source, destination, draggableId, columns): void => {
    const newTaskIds = Array.from(start.taskIds);
    newTaskIds.splice(source.index, 1);
    newTaskIds.splice(destination.index, 0, draggableId);

    const newColumn = {
      ...start,
      taskIds: newTaskIds
    };

    const newState = {
      columns: {
        ...columns,
        [newColumn.id]: newColumn
      }
    };

    setColumns(newState.columns);
  };

  /**
   * @description вызывается если сменини колонку
   * @param newStart начало
   * @param newFinish конец
   * @param columns колонки
   * @param draggableId id колонки
   * @param destination информация о перетаскивании
   */
  const reorderCards = (newStart, newFinish, columns, draggableId, destination): void => {
    const newState = {
      columns: {
        ...columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      }
    };
    setColumns(newState.columns);
  };

  /**
   * @description функция на реордер карточек
   * @param result
   */
  const handleReorder = result => {
    const { destination, source, draggableId } = result;

    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      return;
    }

    const start = columns[source.droppableId];
    const finish = columns[destination.droppableId];

    if (start === finish) {
      notReorderCards(start, source, destination, draggableId, columns);
      return;
    }

    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);

    const newStart = {
      ...start,
      taskIds: startTaskIds
    };
    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);

    const newFinish = {
      ...finish,
      taskIds: finishTaskIds
    };

    if (onDraggble) {
      onDraggble(result);
    }

    if (!onDraggble) reorderCards(newStart, newFinish, columns, draggableId, destination);
  };

  return (
    <DragDropContext onDragEnd={handleReorder}>
      <HocRenderContainer Container={Container} ContainerDraggbles={ContainerDraggbles}>
        {Array.isArray(columnOrder) &&
          columnOrder.map((columnId, i) => {
            const column = columns[columnId];
            const tasks = column.taskIds.map(taskId => cards.find(card => String(card?.id) === String(taskId)));
            return (
              <DroppableList
                {...column}
                isLoading={isLoadRenderColumns}
                index={i}
                key={columnId}
                id={columnId}
                cards={tasks}
                CardComponent={CardComponent}
                stylesForColumns={stylesForColumns}
                stylesForColumn={stylesForColumn}
              />
            );
          })}
      </HocRenderContainer>
    </DragDropContext>
  );
};

export default DraggbleContainer;
