import styled from 'styled-components';
import { Table } from 'antd';

export const StyledAssistantsFeedback = styled.div`
  margin: 20px;
  padding: 30px 20px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 46px -15px rgb(0 0 0 / 12%);
  .ant-col {
    margin: 0 5px;
  }
`;

export const StyledTable = styled(Table)`
  margin: 20px 0;
`;

export const StyledEditAssistantFeedback = styled.div`
  margin: 40px;
`;

export const StyledFeedbackInfo = styled.p`
  font-size: 18px;
  font-weight: bolder;
`;

export const StyledStudentList = styled.div`
  margin: 20px 0;
`;
