import { message } from 'antd';
import { RcFile } from 'antd/lib/upload';

/**
 * @description Проверка изображений
 * @param {object} file
 * @return {Promise<any>}
 */
export const beforeImageUpload = (file: RcFile): boolean | Promise<File> => {
  const isImage = file.type.includes('image');
  if (!isImage) {
    message.error('You can only upload image !');
    return false;
  }
  return false;
};

/**
 * @description Проверка audio
 * @param {object} file
 * @return {Promise<any>}
 */
export const beforeAudioUpload = (file: RcFile): boolean | PromiseLike<void> => {
  const isAudio = file.type.includes('audio');
  if (!isAudio) {
    message.error('You can only upload audio !');
    return false;
  }
  return false;
};
