import React, { Fragment } from 'react';
import { Table } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import Pagination from 'Global/components/Pagination';
import { columns } from 'Admin/People/Families/columns';
import FamilyService from 'helpers/services/FamilyService';
import { SearchFilter } from 'Global/components/SearchFilter';
import { IFamily } from 'types';
import { PaginationInfo } from 'types/global';
import { FamiliesFilterParams } from 'types/Family';

interface FamilyTableProps {
  pagination: PaginationInfo;
  families: IFamily[];
  loadingFamilies: boolean;
  getFamilies: (params?: FamiliesFilterParams) => Promise<any>;
}

export const FamilyTable = (props: FamilyTableProps) => {
  const { pagination, families, loadingFamilies, getFamilies } = props;

  const location = useLocation();
  const { push } = useHistory();
  const family = new FamilyService();
  const limit = FamilyService.limit;
  const locationSearchParams = family.getLocationSearchParams(location, { arrayFormat: 'bracket' });
  const { pageCount, current } = pagination || {};

  /**
   * @description Смена страницы
   * @param {number} page
   * @return {void}
   */
  const handlePage = (page: number): void => {
    getFamilies({ ...locationSearchParams, page });
  };

  /**
   * @description Сортировка таблицы
   * @param sortParams
   */
  const sortFamilies = sortParams => {
    const sort = family.getFilterParams(locationSearchParams, current, sortParams, limit);
    push({ search: family.getQueryStringParams(sort) });
    getFamilies(sort);
  };

  /**
   * @description Срабатывает при изменении пагинации, фильтров или сортировки
   * @param {any} pagination
   * @param {any} filters
   * @param {any} sorter
   */
  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    const { order, field } = sorter;
    const isAscending = order === 'ascend';
    const isDescending = order === 'descend';

    switch (field) {
      case 'balance':
        if (isAscending) {
          sortFamilies({ orderby: 'family.balance', direction: 'DESC' });
        }

        if (isDescending) {
          sortFamilies({ orderby: 'family.balance', direction: 'ASC' });
        }

        break;
      case 'id':
        if (isAscending) {
          sortFamilies({ orderby: 'family.id', direction: 'ASC' });
        }

        if (isDescending) {
          sortFamilies({ orderby: 'family.id', direction: 'DESC' });
        }
        break;
      default:
    }
  };

  return (
    <>
      <SearchFilter isLoading={loadingFamilies} getUsers={getFamilies} hasFamily={true} />
      <Table
        dataSource={families}
        onChange={onTableChange}
        columns={columns}
        rowKey="id"
        pagination={false}
        loading={{ spinning: loadingFamilies, tip: 'Loading families...' }}
      />
      {pagination && <Pagination count={pageCount} current={current} pageChange={handlePage} />}
    </>
  );
};

export default { FamilyTable };
