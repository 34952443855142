import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Col, Row, Button } from 'antd';
import { useStudent } from 'hooks/useStudent';
import { FlexBox } from 'Global/styles';
import Avatar from 'Global/components/Avatar';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Child } from 'types';
import { useLessonInfoStore } from 'stores/useLessonInfoStore';

const StudentName = styled.h2`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  padding: 0;
  margin-inline-start: 26px;
  p {
    margin: 0;
    padding: 0;
    color: #595959;
  }
`;

/**
 * @description Информация о студенте в модальном окне фидбэка
 * @param {object} student
 * @return {React.ReactNode}
 */
export const StudentInfoFeedback = ({ student }: { student: Child }) => {
  const { fullName, yearsOld, birthday, studentLink } = useStudent(student);
  const [strings] = useLanguageContext();
  const lessonInfo = useLessonInfoStore(state => state.lessonInfo);

  const subjectId = lessonInfo?.lesson?.courseGroup?.subject?.id;

  return (
    <Row style={{ marginBottom: 20 }}>
      <Col lg={13}>
        <FlexBox direction="row" justifyContent="start" alignItems="center">
          <Avatar size={40} photo={student.mainPhotoBig} scale={1.5} />
          <StudentName>
            {fullName}
            {birthday && (
              <p>
                {birthday.format('DD.MM.YYYY')} {yearsOld} {strings.YEARS_OLD}
              </p>
            )}
          </StudentName>
        </FlexBox>
      </Col>
      <Col lg={11} style={{ textAlign: 'right' }}>
        <Button type="primary">
          <Link to={`${studentLink}?tab=progress&subject=${subjectId}`} target="_blank">
            {strings.CHECK_THE_ACADEMIC_PROGRESS}
          </Link>
        </Button>
      </Col>
    </Row>
  );
};
