import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Typography, Button, Row, Col, Alert, Flex } from 'antd';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import { StForm } from './styles';
import { CodeForm } from './CodeForm';
import { useConstantsContext } from 'contexts/ConstantsContext';

const { Title } = Typography;

export function LoginByPhone({ setIsPhoneLogin }) {
  const [isShowCode, setIsShowCode] = useState<boolean>(false);
  const globalRequestsRepository = useGlobalRequestsRepository();
  const phoneRef = useRef(null);
  const { ENTER_KEY_CODE } = useConstantsContext();
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoadSend, setIsLoadSend] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const StyledInput = styled(InputMask)`
    width: 100%;
    border: 1px solid #c9c9c9;
    border-radius: 5px;
    padding: 0 10px;
    color: #606060;
    outline: none;
    font-size: 18px;
    height: 50px !important;
  `;

  const StyledPhoneForm = styled.div`
    input {
      font-size: 18px;
    }
  `;

  const onSendSms = async (): Promise<void> => {
    try {
      setIsLoadSend(true);
      setPhoneNumber(phoneRef?.current?.value);
      await globalRequestsRepository.sendAuthSms({ phone: phoneRef?.current?.value });
      setIsShowCode(true);
      setIsLoadSend(false);
    } catch (error) {
      setIsLoadSend(false);
      setIsShowCode(false);
      if (error.hasOwnProperty('data')) {
        setIsError(true);
        setErrorMessage(error.data.message);
      }
    }
  };

  const onKeyDown = async (event): Promise<void> => {
    if (event.keyCode === ENTER_KEY_CODE) {
      await onSendSms();
    }
  };

  const onPaste = () => {
    phoneRef.current.setSelection({
      start: 0
    });
  };

  return (
    <StForm style={{ width: 450 }}>
      {!isShowCode ? (
        <>
          <Title level={3}>Enter your phone</Title>
          <Flex vertical gap={20}>
            <StyledInput
              onPaste={onPaste}
              alwaysShowMask={false}
              onKeyDown={onKeyDown}
              ref={phoneRef}
              placeholder="Your phone..."
              mask="+7(999)9999999"
            />
            <Flex gap={10} justify="space-between">
              <Button onClick={() => setIsPhoneLogin(false)} size="large" icon={<ArrowLeftOutlined />}>
                Cancel
              </Button>
              <Button loading={isLoadSend} onClick={onSendSms} size="large" type="primary">
                Send SMS
              </Button>
            </Flex>
          </Flex>
          {isError && <Alert style={{ marginTop: 10 }} message={errorMessage} type="error" showIcon />}
        </>
      ) : (
        <>
          <Title level={3}>
            Enter sms code
          </Title>
          <CodeForm setIsShowCode={setIsShowCode} phone={phoneNumber} />
        </>
      )}
    </StForm>
  );
}
