import styled from 'styled-components';

export const List = styled.div`
  display: flex;
  margin-top: 25px;

  @media only screen and (max-width: 480px) {
    flex-wrap: wrap;
  }
`;

export const ListItem = styled.div`
  display: flex;
  margin-inline-end: 16px;

  & span {
    display: block;
    padding-inline-end: 8px;
    color: #999999;
  }

  & p {
    margin: 0;
    color: #222222;
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;
