import React from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Button, Col, Row, message } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { LessonAssessmentDefault, LessonAssessmentPreshool, LessonAssessmentPrimary } from 'types/accessment';
import { scrollTop } from 'helpers/scroll';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useAssessmentsRepository } from 'repos/AssessmentsRepository';
import { isEmpty } from 'lodash';
import { LessonPrimaryFields } from './LessonPrimaryFields';
import { LessonDefaultFields } from './LessonDefaultFields';
import { LessonPreschoolFields } from './LessonPreschoolFields';
import { StyledForm } from '../index';

interface LessonAssessmentProps extends FormComponentProps {
  lessonAssessment: Partial<LessonAssessmentDefault | LessonAssessmentPreshool | LessonAssessmentPrimary>;
}

/**
 * @description Index component
 * @param {object} props
 * @return {React.ReactNode}
 */
const LessonAssessmentTemplate = (props: LessonAssessmentProps) => {
  const [strings] = useLanguageContext();
  const assessmentsRepository = useAssessmentsRepository();
  const {
    form,
    form: { validateFieldsAndScroll },
    lessonAssessment,
    lessonAssessment: { type, id }
  } = props;

  /**
   * @description Обработчик на submit формы
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values) => {
      message.loading(strings.LESSON_ASSESSMENT_UPDATING, 1);
      if (isEmpty(errors)) {
        try {
          await assessmentsRepository.editAssessmentByLessonId(id, { type, ...values });
          message.success(strings.LESSON_ASSESSMENT_UPDATE);
          scrollTop();
        } catch {}
      }
    });
  };

  return (
    <StyledForm layout="vertical" onSubmit={handleSubmit}>
      {type === 'lessonDefault' && <LessonDefaultFields lessonAssessment={lessonAssessment} form={form} />}
      {(type === 'lessonNew' || type === 'lessonComment') && (
        <LessonPreschoolFields lessonAssessment={lessonAssessment} form={form} />
      )}
      {type === 'lessonPrimary' && <LessonPrimaryFields lessonAssessment={lessonAssessment} form={form} />}
      <Row style={{ marginTop: '20px' }}>
        <Col lg={24}>
          <Button type="primary" size="large" htmlType="submit">
            {strings.SAVE}
          </Button>
        </Col>
      </Row>
    </StyledForm>
  );
};

export const LessonAssessment = Form.create<LessonAssessmentProps>({})(LessonAssessmentTemplate);

export default { LessonAssessment };
