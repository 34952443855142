import React, { useState, useEffect, useMemo } from 'react';
import { Table, Tag, Row } from 'antd';
import { useUserAccessRepository } from 'repos/UserAccessRepostirory';
import { User } from 'types/user';
import { Link, useHistory } from 'react-router-dom';
import Pagination from 'Global/components/Pagination';
import { PaginationInfo } from 'types/global';
import { scrollTop } from 'helpers';
import { AdminAccessFilter } from './AdminAccessFilter';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useLanguageContext } from 'contexts/LanguageContext';

export function AdminAccess() {
  const [strings] = useLanguageContext();
  const userAccessRepo = useUserAccessRepository();
  const [accessObjects, setAccessObjects] = useState([]);
  const [isLoadAccessObjects, setIsLoadAccessObjects] = useState<boolean>(false);
  const [accessPagination, setAccessPagination] = useState<PaginationInfo>(null);
  const [filterState, setFilterState] = useState({});
  const { goBack } = useHistory();

  const fetchAccessObjects = async (params): Promise<any> => {
    try {
      setFilterState(state => ({ ...params, ...state }));
      setIsLoadAccessObjects(true);
      const { data } = await userAccessRepo.getAccess(params);
      setAccessObjects(data.items);
      setAccessPagination(data.paginationInfo);
      setIsLoadAccessObjects(false);
    } catch {
      setIsLoadAccessObjects(false);
    }
  };

  const accessColumns = useMemo(
    () => [
      {
        title: 'User',
        dataIndex: 'name',
        key: 'name',
        render: (name: string, user: User) => {
          if (user.roles.includes('ROLE_ADMIN')) {
            return (
              <Link target="_blank" to={`/admin/${user?.relation?.id}`}>
                {name} {user?.surname} <Tag color="orange">Admin</Tag>
              </Link>
            );
          }

          return (
            <Link target="_blank" to={`/teachers/${user.relation?.id}`}>
              {name} {user?.surname} <Tag color="lime">Teacher</Tag>
            </Link>
          );
        }
      }
    ],
    []
  );

  const handlePage = async (page: number): Promise<any> => {
    await fetchAccessObjects({ ...filterState, page: page });
    scrollTop();
  };

  useEffect(() => {
    fetchAccessObjects({ page: 1 });
  }, []);

  return (
    <Container>
      <StPageHeader onBack={goBack} title={strings.ADMIN_ACCESS}>
        <AdminAccessFilter fetchAccess={fetchAccessObjects} />
      </StPageHeader>
      <Row gutter={[10, 10]}>
        <Table
          loading={isLoadAccessObjects}
          dataSource={accessObjects}
          rowKey="id"
          columns={accessColumns}
          pagination={false}
        />
        {accessPagination?.pageCount !== 1 && (
          <Pagination
            pageSize={accessPagination?.numItemsPerPage}
            count={accessPagination?.totalCount}
            current={accessPagination?.current}
            pageChange={handlePage}
          />
        )}
      </Row>
    </Container>
  );
}
