import React, { useMemo, useContext } from 'react';
import { httpService } from 'api/core';
import { GetAssistantsCommentsParams } from 'Global/modules/AssistantsFeedback/types';

const AssistantsFeedbackContext = React.createContext<IAssistantsFeedbackRepository>(null);

interface IAssistantsFeedbackRepository {
  getAssistantComments: (params: GetAssistantsCommentsParams) => Promise<any>;
  getAssistantCommentsByStudent: (studentId: number | string) => Promise<any>;
  getAssistantCommentsById: (studentId: number | string) => Promise<any>;
  editAssistantCommentReport: (commentId: number | string, data) => Promise<any>;
  getAssistantCommentTags: () => Promise<any>;
}

class AssistantFeedbackRepository implements IAssistantsFeedbackRepository{
  getAssistantComments(params: GetAssistantsCommentsParams) {
    return httpService.get('/api/v1/assistant-comments', params, null, true);
  }

  getAssistantCommentsByStudent(studentId: number | string) {
    return httpService.get(`/api/v1/assistant-comments/child/${studentId}`, null, null, true);
  }

  getAssistantCommentsById(commentId: number | string) {
    return httpService.get(`/api/v1/assistant-comments/${commentId}`, null, null, true);
  }

  editAssistantCommentReport(commentId: number | string, data) {
    return httpService.put(`/api/v1/assistant-comments/${commentId}`, data, null, null, true);
  }

  getAssistantCommentTags() {
    return httpService.get(`/api/v1/tags/assistant-comments`, null, null, true);
  }
}

export const useAssistantFeedbackRepository = () => {
  const service = useContext(AssistantsFeedbackContext);

  if (!service) {
    throw new Error('Assistant feedback  repository is unavailable');
  }

  return service;
};

export const AssistantFeedbackRepositoryProvider = props => {
  const service = useMemo(() => new AssistantFeedbackRepository(), []);

  return <AssistantsFeedbackContext.Provider value={service}>{props.children}</AssistantsFeedbackContext.Provider>;
};
