import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Form, Select, Spin, Tag } from 'antd';
import { Admin } from 'types';
import { useLanguageContext } from 'contexts/LanguageContext';
import debounce from 'lodash/debounce';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import { Master } from 'types/Schedule';

const { Option } = Select;

type UserSubset = Partial<Admin | Master>;

type AsyncSelectProps = {
  value?: number;
  placeholder?: string;
  onChange?: (value: number) => void;
  initOption?: UserSubset;
  nameExtraTypeField?: string;
};

const UserSelect: React.FC<AsyncSelectProps> = React.memo(
  ({ value = [], placeholder, onChange, initOption, nameExtraTypeField }) => {
    const [strings] = useLanguageContext();
    const form = Form.useFormInstance();
    const globalRequestsRepository = useGlobalRequestsRepository();
    const [typing, setTyping] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [options, setOptions] = useState<UserSubset[]>([]);
    const [hasLoading, setHasLoading] = useState(false);

    const handleChange = (userId: number): void => {
      onChange(userId);
      if (nameExtraTypeField && options?.isNotEmpty()) {
        const selectedUser = options.find((user: UserSubset) => user.id === userId);
        form.setFieldsValue({ [nameExtraTypeField]: selectedUser?.type });
      }
    };

    const fetchUsers = async (userName: string) => {
      setSearchValue(userName);
      if (!userName) return setOptions([]);

      setHasLoading(true);
      try {
        const { data: { children = [], parents = [], teachers = [], admins = [] } = {} } =
          await globalRequestsRepository.search(userName, { modeSchedule: true });

        const mapUsers = (users: any[], type: string) =>
          users.filter(item => item?.user?.name || item?.user?.surname).map(item => ({ ...item, type }));

        setOptions([
          ...mapUsers(children, 'student'),
          ...mapUsers(parents, 'parent'),
          ...mapUsers(teachers, 'teacher'),
          ...mapUsers(admins, 'admin')
        ]);
      } catch {
        setOptions([]);
      } finally {
        setHasLoading(false);
      }
    };

    const debouncedFetchUsers = useMemo(() => debounce(fetchUsers, 800), []);
    const getUserName = useCallback(item => {
      return `${item?.user?.surname || item?.surname} ${item?.user?.name || item?.name}`;
    }, []);

    useEffect(() => {
      setTyping(Boolean(searchValue));
    }, [searchValue]);

    return (
      <Select
        showSearch
        value={value}
        onChange={handleChange}
        allowClear
        placeholder={placeholder}
        onSearch={debouncedFetchUsers}
        loading={hasLoading}
        maxTagCount="responsive"
        filterOption={false}
        notFoundContent={
          hasLoading ? <Spin size="small" /> : <p>{!typing ? strings.START_TYPING : strings.NOT_FOUND}</p>
        }
      >
        {options.map(option => (
          <Option key={option.id} value={option.id}>
            {getUserName(option)} <Tag color="blue">{option?.type}</Tag>
          </Option>
        ))}

        {initOption && (
          <Option key={initOption?.id} value={initOption?.id}>
            {getUserName(initOption)} <Tag color="blue">{initOption?.type}</Tag>
          </Option>
        )}
      </Select>
    );
  }
);

export default UserSelect;
