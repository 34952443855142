import React, { useCallback, useMemo } from 'react';
import { Button, Col, Flex, Row, Select, Tag, Form } from 'antd';
import { Training } from 'types';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { blockStyles, FormItemWithoutMargin } from 'Global/styles';

const { Option } = Select;

interface TrainingFieldsProps {
  setTrainingsList: (items: Training[]) => void;
  trainingsList: Training[];
}

export const TrainingFields = React.memo(({ trainingsList, setTrainingsList }: TrainingFieldsProps) => {
  const [strings] = useLanguageContext();
  const [form] = Form.useForm();
  const { trainings } = useGlobalCollectionsContext();
  const memoizedTrainings = useMemo(() => trainings, [trainings]);

  const deleteTraining = useCallback(
    (trainingId: number): void => {
      setTrainingsList(trainingsList.filter(training => training.id !== trainingId));
    },
    [trainingsList]
  );

  const handleAddTraining = useCallback((): void => {
    const trainingId = form.getFieldValue('training');
    const current = memoizedTrainings.find(training => training.id === trainingId);

    if (current && !trainingsList.some(training => training.id === trainingId)) {
      setTrainingsList([...trainingsList, current]);
    }

    form.resetFields(['training']);
  }, [form, trainingsList, memoizedTrainings]);

  return (
    <Form form={form} layout="vertical">
      <Row gutter={[10, 10]}>
        <Col lg={24} xs={24}>
          <Flex gap={5}>
            <FormItemWithoutMargin name="training" style={{ flex: 1 }}>
              <Select placeholder={strings.TRAININGS}>
                {trainings.map((training: Training) => (
                  <Option value={training.id} key={training.id}>
                    {training?.name}
                  </Option>
                ))}
              </Select>
            </FormItemWithoutMargin>
            <Button type="primary" size="large" onClick={handleAddTraining}>
              {strings.ADD}
            </Button>
          </Flex>
        </Col>
        <Col lg={24} xs={24}>
          <Flex style={blockStyles} gap={5} wrap="wrap">
            {trainingsList.map((training: Training) => (
              <Tag color="green" key={training.id} closable onClose={() => deleteTraining(training.id)}>
                {training?.name}
              </Tag>
            ))}
          </Flex>
        </Col>
      </Row>
    </Form>
  );
});

export default TrainingFields;
