import React, { useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Flex, message, Popconfirm, Spin } from 'antd';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { useLanguageContext } from 'contexts/LanguageContext';
import { IOutcomesByOriginalLesson } from 'types/courses';
import { ModalEditSubjectGoal } from './ModalEditSubjectGoal';
import MyJoditEditor from 'Global/components/MyJoditEditor';

interface SubjectGoalsForEditLessonProps {
  originalLessonId: number | string;
}

export const SubjectGoalsForEditLesson = ({ originalLessonId }: SubjectGoalsForEditLessonProps) => {
  const [strings] = useLanguageContext();
  const coursesRepository = useCoursesRepository();
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [newGoalDescription, setNewGoalDescription] = useState<string>('');
  const [hasCreateLoading, setHasCreateLoading] = useState<boolean>(false);
  const [selectedGoal, setSelectedGoal] = useState<IOutcomesByOriginalLesson>(null);
  const [goals, setGoals] = useState<IOutcomesByOriginalLesson[]>([]);

  const handleCreate = async (): Promise<any> => {
    try {
      setHasCreateLoading(true);
      await coursesRepository.addOutcomesByOriginalLesson(originalLessonId, { description: newGoalDescription });
      setNewGoalDescription('');
      getSubjectGoalsForEditLesson();
      message.success(strings.SUCCESSFULLY_CREATED, 2);
    } catch {
    } finally {
      setHasCreateLoading(false);
    }
  };

  const handleDelete = async (id: number): Promise<any> => {
    try {
      setHasLoading(true);
      await coursesRepository.deleteOutcomesById(id);
      getSubjectGoalsForEditLesson();
      message.success(strings.SUCCESSFULLY_DELETED, 2);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  const editSubjectGoal = (goal: IOutcomesByOriginalLesson) => {
    setSelectedGoal(goal);
    setHasShowModal(true);
  };

  const getSubjectGoalsForEditLesson = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await coursesRepository.getOutcomesByOriginalLesson(originalLessonId);
      setGoals(data);
      setSelectedGoal(null);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    getSubjectGoalsForEditLesson();
  }, []);

  return (
    <>
      <>
        <MyJoditEditor
          key="description-editor"
          value={newGoalDescription}
          height={150}
          onChange={value => setNewGoalDescription(value)}
          pasteOnlyText
        />
        <Button
          loading={hasCreateLoading}
          disabled={newGoalDescription.length === 0}
          style={{ marginBottom: 10, marginTop: 10 }}
          onClick={handleCreate}
        >
          {strings.ADD_A_NEW_GOAL}
        </Button>
      </>
      <Spin spinning={hasLoading}>
        {goals.map(goal => (
          <Flex gap={8} align="baseline" style={{ marginBottom: 8 }}>
            <Button
              type="primary"
              icon={<EditOutlined />}
              size="small"
              style={{ minWidth: 24 }}
              onClick={() => editSubjectGoal(goal)}
            />
            <Popconfirm
              title={strings.ARE_YOU_SURE}
              onConfirm={() => handleDelete(goal?.id)}
              okText={strings.YES}
              cancelText={strings.NO}
            >
              <Button size="small" icon={<DeleteOutlined />} danger style={{ minWidth: 24 }} />
            </Popconfirm>
            <div style={{ lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: goal?.description }} />
          </Flex>
        ))}
      </Spin>
      {selectedGoal && (
        <ModalEditSubjectGoal
          hasShowModal={hasShowModal}
          setHasShowModal={setHasShowModal}
          subjectGoal={selectedGoal}
          refetchGoals={getSubjectGoalsForEditLesson}
        />
      )}
    </>
  );
};

export default { SubjectGoalsForEditLesson };
