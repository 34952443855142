import React, { useMemo, useContext } from 'react';
import { httpService } from 'api/core';

const SupportRepositoryContext = React.createContext(null);

class SupportRepository {
  sendFeedBack(params) {
    return httpService.post('/api/feedback', params, true);
  }

  sendZendeskFeedback(params) {
    return httpService.post('/api/zendesk/feedback', params, true);
  }

  getZendeskTicketsByEmail(email) {
    return httpService.get(`/api/zendesk/tickets?email=${email}`);
  }

  getTicketComments(ticketId) {
    return httpService.get(`/api/zendesk/tickets/${ticketId}/comments`);
  }

  createTicketComment(ticketId, params) {
    return httpService.post(`/api/zendesk/tickets/${ticketId}/comments`, params, true);
  }

  createTicketFromAuthPage(params) {
    return httpService.post(`/api/v1/zendesk/support?key=MnJ4fqeOdZZd`, params, null, null, true);
  }
}

export const useSupportRepository = () => {
  const service = useContext(SupportRepositoryContext);
  if (!service) {
    throw new Error('Support repository is unavailable');
  }

  return service;
};

export const SupportRepositoryProvider = props => {
  const service = useMemo(() => new SupportRepository(), []);

  return <SupportRepositoryContext.Provider value={service}>{props.children}</SupportRepositoryContext.Provider>;
};
