import React from 'react';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Button, Col, Row, Input, Radio, Typography, Tooltip } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { LessonAssessmentDefault } from 'types/accessment';
import { useLanguageContext } from 'contexts/LanguageContext';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { checkTypeForInput } from 'helpers/booleanFomatValues';

const { Text } = Typography;

interface LessonDefaultFieldsProps extends FormComponentProps {
  lessonAssessment: LessonAssessmentDefault;
  form: WrappedFormUtils;
}

/**
 * @description LessonDefaultFields component
 * @param {object} props
 * @return {React.ReactNode}
 */
export const LessonDefaultFields = (props: LessonDefaultFieldsProps) => {
  const [strings] = useLanguageContext();
  const {
    form,
    form: { getFieldDecorator, setFieldsValue },
    lessonAssessment: {
      lessonFinalMark,
      lessonControl,
      lessonControlComment,
      lessonEmotions,
      lessonEmotionsComment,
      lessonViolationsComment,
      lessonGrowup,
      lessonGrowupComment,
      lessonSafety,
      assistantsPerformanceComment,
      lessonRecommendation
    }
  } = props;

  /**
   * @description Очистить поле
   * @param {string} fieldName
   * @return {void}
   */
  const clearFieldByName = (fieldName: string): void => setFieldsValue({ [fieldName]: '' });

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col lg={12}>
          <MyFormItem label={strings.CONTROL}>
            {getFieldDecorator('lessonControl', {
              initialValue: lessonControl,
              rules: [{ required: false }]
            })(
              <Radio.Group>
                <Tooltip title={strings.NOT_MORE_THAN_25_OF_GOOD_KIDS}>
                  <Radio.Button value="0">0</Radio.Button>
                </Tooltip>
                <Tooltip title={strings.NOT_MORE_THAN_50}>
                  <Radio.Button value="1">1</Radio.Button>
                </Tooltip>
                <Tooltip title={strings.FROM_50_TO_75}>
                  <Radio.Button value="2">2</Radio.Button>
                </Tooltip>
                <Tooltip title={strings.FROM_75_TO_95}>
                  <Radio.Button value="3">3</Radio.Button>
                </Tooltip>
                <Tooltip title={strings.ALL_OF_THE_KIDS_ARE_GOOD}>
                  <Radio.Button value="4">4</Radio.Button>
                </Tooltip>
              </Radio.Group>
            )}
            <Button size="middle" style={{ marginInlineStart: 10 }} onClick={() => clearFieldByName('lessonControl')}>
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={12}>
          <MyFormItem label={strings.CONTROL_COMMENT}>
            {getFieldDecorator('lessonControlComment', {
              initialValue: checkTypeForInput(lessonControlComment),
              rules: [{ required: false }]
            })(<Input.TextArea placeholder={strings.COMMENT} />)}
          </MyFormItem>
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col lg={12}>
          <MyFormItem label={strings.EMOTIONS}>
            {getFieldDecorator('lessonEmotions', {
              initialValue: lessonEmotions,
              rules: [{ required: false }]
            })(
              <Radio.Group>
                <Tooltip title={strings.MOST_OF_THE_KIDS_DEMONSTRATE}>
                  <Radio.Button value="0">0</Radio.Button>
                </Tooltip>
                <Tooltip title={strings.NO_EXTREME_BOREDOM}>
                  <Radio.Button value="1">1</Radio.Button>
                </Tooltip>
                <Tooltip title={strings.SUSTAINABLE_INTEREST}>
                  <Radio.Button value="2">2</Radio.Button>
                </Tooltip>
                <Tooltip title={strings.EMOTIONAL_PEAK_A_HIGH_MOMENT}>
                  <Radio.Button value="3">3</Radio.Button>
                </Tooltip>
              </Radio.Group>
            )}
            <Button size="middle" style={{ marginInlineStart: 10 }} onClick={() => clearFieldByName('lessonEmotions')}>
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={12}>
          <MyFormItem label={strings.EMOTIONS_COMMENT}>
            {getFieldDecorator('lessonEmotionsComment', {
              initialValue: checkTypeForInput(lessonEmotionsComment),
              rules: [{ required: false }]
            })(<Input.TextArea placeholder={strings.СOMMENT} />)}
          </MyFormItem>
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col lg={12}>
          <MyFormItem label={strings.SAFETY}>
            {getFieldDecorator('lessonSafety', {
              initialValue: lessonSafety,
              rules: [{ required: false }]
            })(
              <Radio.Group>
                <Tooltip title={strings.THREAT_IS_PRESENT}>
                  <Radio.Button value="0">0</Radio.Button>
                </Tooltip>
                <Tooltip title={strings.MEDIUM_POSITION}>
                  <Radio.Button value="1">1</Radio.Button>
                </Tooltip>
                <Tooltip title={strings.NO_THREATS_AT_ALL}>
                  <Radio.Button value="2">2</Radio.Button>
                </Tooltip>
              </Radio.Group>
            )}
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem label={strings.ASSISTANTS_PERFORMANCE_COMMENT}>
            {getFieldDecorator('assistantsPerformanceComment', {
              initialValue: checkTypeForInput(assistantsPerformanceComment),
              rules: [{ required: false }]
            })(<Input.TextArea placeholder={strings.СOMMENT} />)}
          </MyFormItem>
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col lg={24}>
          <MyFormItem label={strings.BREAKING_THE_GENERAL_RULES_COMMENT}>
            {getFieldDecorator('lessonViolationsComment', {
              initialValue: checkTypeForInput(lessonViolationsComment),
              rules: [{ required: false }]
            })(<Input.TextArea placeholder={strings.СOMMENT} />)}
          </MyFormItem>
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col lg={12}>
          <MyFormItem label={strings.AREA_FOR_IMPROVEMENT}>
            {getFieldDecorator('lessonGrowup', {
              initialValue: lessonGrowup,
              rules: [{ required: false }]
            })(
              <Radio.Group>
                <Tooltip title={strings.NO_EVIDENCE}>
                  <Radio.Button value="0">0</Radio.Button>
                </Tooltip>
                <Tooltip title={strings.MORE_THAN_2_3_OF_KIDS_PRODUCE_INFORMATION}>
                  <Radio.Button value="1">1</Radio.Button>
                </Tooltip>
                <Tooltip title={strings.THERE_IS_EVIDENCE}>
                  <Radio.Button value="2">2</Radio.Button>
                </Tooltip>
              </Radio.Group>
            )}
            <Button size="middle" style={{ marginInlineStart: 10 }} onClick={() => clearFieldByName('lessonGrowup')}>
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={12}>
          <MyFormItem label={strings.AREA_FOR_IMPROVEMENT_COMMENT}>
            {getFieldDecorator('lessonGrowupComment', {
              initialValue: checkTypeForInput(lessonGrowupComment),
              rules: [{ required: false }]
            })(<Input.TextArea placeholder={strings.СOMMENT} />)}
          </MyFormItem>
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col lg={24} style={{ background: '#e6f7ff', padding: '10px 20px 30px 20px', borderRadius: '5px' }}>
          <MyFormItem
            label={
              <>
                {strings.SUMMARY_COMMENTS}
                <Text type="secondary" style={{ float: 'right', fontSize: '12px' }}>
                  {strings.ONLY_THIS_PART_IS_VISIBLE_TO_THE_TEACHER}
                </Text>
              </>
            }
          >
            {getFieldDecorator('lessonRecommendation', {
              initialValue: checkTypeForInput(lessonRecommendation),
              rules: [
                {
                  required: false
                }
              ]
            })(<Input.TextArea style={{ height: 300, width: '100%' }} placeholder={strings.СOMMENT} />)}
          </MyFormItem>
          <MyFormItem label={strings.FINAL_MARK}>
            {getFieldDecorator('lessonFinalMark', {
              initialValue: lessonFinalMark,
              rules: [{ required: false }]
            })(
              <Radio.Group>
                <Radio.Button value="ni">Ni</Radio.Button>
                <Radio.Button value="s">S</Radio.Button>
                <Radio.Button value="g">G</Radio.Button>
              </Radio.Group>
            )}
            <Button size="middle" style={{ marginInlineStart: 10 }} onClick={() => clearFieldByName('lessonFinalMark')}>
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
      </Row>
    </>
  );
};

export default { LessonDefaultFields };
