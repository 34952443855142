import React, { useContext } from 'react';
import { IRepositoryProvider } from 'repos/RepositoryProvider';

const ConstantsContext = React.createContext(null);

export const ConstantsContextProvider: React.FC<IRepositoryProvider> = ({ children }) => {
  const provider = {
    NOT_STUDENT_STATUS_ID: 21,
    ACTIVE_STUDENT_STATUS_ID: 15,
    ALLOW_TEACHER_PROGRESS_REPORT_STATUSES: ['Filled in', 'Empty', 'In progress'],
    PROGRESS_REPORT_APPROVED_STATUS: 'approved',
    PROGRESS_REPORT_AVAILABLE_TO_PARENT: 'available to parent',
    RETURN_KEY_CODE: 8,
    ENTER_KEY_CODE: 13,
    FAMILY_ACTIVE_CLIENT_STATUS: 18,
    BRANCH_RIGA: 5
  };

  return <ConstantsContext.Provider value={provider}>{children}</ConstantsContext.Provider>;
};

export const useConstantsContext = () => {
  const service = useContext(ConstantsContext);

  if (!service) {
    throw new Error('Constants context is unavailable');
  }

  return service;
};
