import { Subject } from 'types';
import React from 'react';
import { Link } from 'react-router-dom';
import { TrainingsItem } from 'types/education';
import * as educationRoutes from '../routes';

export const columns = strings => [
  {
    title: strings.TITLE,
    key: 'name',
    render: (training: TrainingsItem) => (
      <Link to={`${educationRoutes.BASE_TRAININGS}/${training.id}`}>{training.name}</Link>
    )
  },
  {
    title: strings.DESCRIPTION,
    key: 'description',
    description: 'description'
  },
  {
    title: strings.SUBJECTS,
    dataIndex: 'subjects',
    key: 'subjects',
    render: (subjects: Subject[]) =>
      subjects.isNotEmpty() &&
      subjects.map((subject: Subject) => {
        return (
          <span>
            {subject.name}
            <br />
          </span>
        );
      })
  },
  {
    title: strings.STAGE,
    dataIndex: 'stages',
    key: 'stages',
    render: (stages: { name: string; id: number }[]) =>
      stages.isNotEmpty() &&
      stages.map(stage => {
        return (
          <span>
            {stage.name}
            <br />
          </span>
        );
      })
  }
];
