import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, message, Col, Spin, Row } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useAdminRepository } from 'repos/AdminRepository';
import { AdditionalAccountPostParams } from 'types/CRM/additionalAccounts';
import { UserSelect } from 'Global/components/FormComponentsCompatible/Selects/UserSelect';

interface ModalExpertFormProps extends FormComponentProps {
  hasShowModal: boolean;
  setHasShowModal: (hasShow: boolean) => void;
  fetchAdditionalAccounts: () => Promise<any>;
}

/**
 * @description Добавление Additional Accounts
 * @param {ModalExpertFormProps} props
 * @return {React.ReactNode}
 */
const ModalExpertFormTemplate = (props: ModalExpertFormProps) => {
  const [strings] = useLanguageContext();
  const adminRepository = useAdminRepository();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const {
    form: { getFieldDecorator, getFieldValue, resetFields, validateFieldsAndScroll },
    hasShowModal,
    setHasShowModal,
    fetchAdditionalAccounts
  } = props;

  /**
   * @description Отправка формы
   * @param {React.FormEvent} e
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (err, values: AdditionalAccountPostParams) => {
      if (!err) {
        setHasLoading(true);
        try {
          await adminRepository.createAdditionalAccounts(values);
          message.success(strings.SUCCESSFULLY_CREATED, 2);
          await fetchAdditionalAccounts();
          resetFields();
          setHasShowModal(false);
        } catch {
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  const closeModal = () => {
    setHasShowModal(false);
  };

  return (
    <Modal
      title={strings.CREATE_NEW_ADDITIONAL_ACCOUNTS}
      open={hasShowModal}
      onCancel={closeModal}
      width={500}
      okText={strings.CREATE}
      onOk={handleSubmit}
      destroyOnClose={true}
    >
      <Spin spinning={hasLoading}>
        <Form layout="vertical">
          <Row>
            <Col lg={24}>
              <UserSelect getFieldDecorator={getFieldDecorator} name="user1" label={strings.USER} />
            </Col>
            <Col lg={24}>
              <UserSelect getFieldDecorator={getFieldDecorator} name="user2" label={strings.ADDITIONAL_ACCOUNT} />
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export const ModalAdditionalAccountForm = Form.create<ModalExpertFormProps>({})(ModalExpertFormTemplate);

export default { ModalExpertForm: ModalAdditionalAccountForm };
