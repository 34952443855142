export const BASE_COURSE_GROUP = '/group';
export const BASE_COURSE_GROUP_ADD = '/group/new';
export const BASE_ORIGINAL_COURSES = '/original_courses';
export const CHOOSE_ORIGINAL_COURSE = '/original_courses/choose';
export const BASE_SUPER_GROUPS = '/supergroup';
export const SUPERGROUPS_GENERATOR = `${BASE_SUPER_GROUPS}/generator`;
export const NEW_SUPER_GROUP = `${BASE_SUPER_GROUPS}/new`;
export const EDIT_SUPER_GROUP = `${BASE_SUPER_GROUPS}/:id(\\d+)/edit`;
export const FULL_SUPER_GROUP = `${BASE_SUPER_GROUPS}/:id(\\d+)`;
export const NEW_GROUP_GENERATOR = 'supergroup/group-generator';
export const BASE_PLACEMENT = '/placement';
export const BASE_ORIGINAL_COURSES_UNITS = '/original-course-units';
export const BASE_ORIGINAL_COURSES_UNITS_NEW = '/original-course-units/new';
export const EDIT_COURSE_GROUP = `${BASE_COURSE_GROUP}/:id/edit`;
export const LESSON = `/le/:id`;
export const LESSON_OLD = `/old-le/:id`;
export const ORIGINAL_COURSE = `${BASE_ORIGINAL_COURSES}/:id(\\d+)`;
export const ORIGINAL_COURSE_LESSON = `/original_lesson/:id`;
export const ORIGINAL_COURSE_EDIT = `${BASE_ORIGINAL_COURSES}/:id/edit`;
export const ORIGINAL_COURSE_NEW = `${BASE_ORIGINAL_COURSES}/new`;
export const ORIGINAL_LESSON_EDIT = `/original-lesson/:id(\\d+)/edit/course/:courseId(\\d+)`;
export const ORIGINAL_LESSON_NEW = `/original-lesson/new/:id(\\d+)`;

export default {
  BASE_COURSE_GROUP,
  BASE_COURSE_GROUP_ADD,
  BASE_ORIGINAL_COURSES,
  CHOOSE_ORIGINAL_COURSE,
  BASE_SUPER_GROUPS,
  NEW_SUPER_GROUP,
  NEW_GROUP_GENERATOR,
  BASE_ORIGINAL_COURSES_UNITS,
  BASE_ORIGINAL_COURSES_UNITS_NEW,
  EDIT_COURSE_GROUP,
  LESSON,
  ORIGINAL_COURSE,
  ORIGINAL_COURSE_EDIT,
  ORIGINAL_COURSE_NEW,
  ORIGINAL_LESSON_EDIT,
  SUPERGROUPS_GENERATOR,
  FULL_SUPER_GROUP,
  LESSON_OLD
};
