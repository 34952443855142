export const META_GOALS = '/metagoals';
export const ADMIN_ACCESS = '/admin-access';
export const CHANGE_TUTOR = '/change-tutor';
export const BASE_INVOICE_LETTERS_TEMPLATES = '/invoice-letters-templates';
export const EDIT_INVOICE_LETTERS_TEMPLATES = `${BASE_INVOICE_LETTERS_TEMPLATES}/:id(\\d+)/edit`;
export const CREATE_INVOICE_LETTERS_TEMPLATES = `${BASE_INVOICE_LETTERS_TEMPLATES}/new`;
export const CONTRACT_TEMPLATES = '/contract-templates';
export const BASE_STATUSES = '/statuses';
export const BASE_TEACHER_STATUS = `${BASE_STATUSES}/teacher-form`;
export const BASE_FAMILY_STATUS = `${BASE_STATUSES}/family-form`;
export const BASE_STUDENT_STATUS = `${BASE_STATUSES}/student-form`;
export const TEACHER_STATUS_NEW = `${BASE_TEACHER_STATUS}/new`;
export const FAMILY_STATUS_NEW = `${BASE_FAMILY_STATUS}/new`;
export const STUDENT_STATUS_NEW = `${BASE_STUDENT_STATUS}/new`;
export const TEACHER_STATUS_EDIT = `${BASE_TEACHER_STATUS}/:id(\\d+)/edit`;
export const FAMILY_STATUS_EDIT = `${BASE_FAMILY_STATUS}/:id(\\d+)/edit`;
export const STUDENT_STATUS_EDIT = `${BASE_STUDENT_STATUS}/:id(\\d+)/edit`;
export const BASE_BRANCHES = '/branches';
export const BRANCHES_EDIT = `${BASE_BRANCHES}/:id/edit`;
export const BRANCHES_NEW = `${BASE_BRANCHES}/new`;
export const BASE_BRANCHES_CLICKUP = '/branches-clickup';
export const BRANCHES_CLICKUP_EDIT = `${BASE_BRANCHES_CLICKUP}/:id/edit`;
export const BRANCHES_CLICKUP_NEW = `${BASE_BRANCHES_CLICKUP}/new`;
export const BASE_TAGS = '/tags';
