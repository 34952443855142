import { httpService } from 'api/core';
import {
  CommentInvoiceType,
  generateParisInvoiceParams
} from 'Admin/People/Families/Family/Invoices/types';

/**
 * @description Добавление комментария
 * @param {number} id
 * @param {CommentInvoiceType} params
 * @return {Promise<any>}
 */
export const addCommentToInvoice = (id: number, params: CommentInvoiceType): Promise<any> => {
  return httpService.post(`/api/invoices/${id}/comment`, params);
};

/**
 * @description Обозначить плательщика инвойсов у семьи
 * @param {number} familyId
 * @param {number} payerId
 * @returns {Promise<any>}
 */
export const setPayer = (familyId: number, payerId: number): Promise<any> =>
  httpService.post(`/api/families/${familyId}/payer/${payerId}`);

/**
 * @description функция генерации ссылки для перехода на оплату
 * @param {number} id
 * @return {Promise<any>}
 */
export const generateLink = (id: number): Promise<any> => {
  return httpService.get(`/api/invoice/${id}/hash`);
};

/**
 * @description download/send invoice for the period of…
 * @param {number} id семьи
 * @param {object} params
 * @return {Promise<any>}
 */
export const generateParisInvoice = (id: number, params: generateParisInvoiceParams): Promise<any> => {
  return httpService.post(`/api/invoices/family/${id}/generate-for-paris`, params);
};

/**
 * @description Получить шаблон письма инвойса
 * @param {number} id
 * @returns {Promise<any>}
 */
export const getLetterTemplate = (id: number): Promise<any> => {
  return httpService.get(`/api/invoices/${id}/letter-template`);
};

export default {
  generateLink,
  setPayer,
  getLetterTemplate,
  generateParisInvoice,
  addCommentToInvoice
};
