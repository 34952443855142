import { TeacherWageListItem } from 'types/Teachers/wages';

/**
 * @description Умножает значение wage учителя на его индивидуальный коэффициент для окончательного отображения в таблице
 * @param {TeacherWageListItem[]} wages
 * @param {number} coefficient
 * @returns {TeacherWageListItem[]}
 */
export const multiplyWageByCoefficient = (wages: TeacherWageListItem[], coefficient: number) =>
  wages.map((wage: TeacherWageListItem) => ({
    ...wage,
    individual:
      // Пропускаем ненужное умножение на 1
      coefficient && coefficient !== 1
        ? `${parseFloat((wage.individual * coefficient).toFixed(1))} (${coefficient})`
        : wage.individual
  }));

export default { multiplyWageByCoefficient };
