import React, { useState, useEffect } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { Tabs } from 'antd';
import { ProgressReportsWithSuperGroup } from 'Admin/Monitoring/ProgressReport/ProgressReportWithSuperGroup';
import { ReportTrimester } from 'types/Reports';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container } from 'Global/GlobalStyle';
import { useHistory } from 'react-router-dom';
import { QuickTips } from 'Global/components/QuickTips';
import ProgressReportForParents from './ProgressReportForParents';
import ProgressReportForTeacher from './ProgressReportForTeacher';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';

const { TabPane } = Tabs;

/**
 * @description Progress Report
 * @param {object} props
 * @return {React.ReactNode}
 */
export const ProgressReport = () => {
  const [strings] = useLanguageContext();
  const { goBack } = useHistory();
  const [user] = useUserContext();
  const hasTeacherRole = user?.hasRoles?.teacher;
  const hasRiga = user?.branch?.id === 5;
  const [trimesters, setTrimesters] = useState<ReportTrimester[]>([]);
  const [loadingTrimesters, setLoadingTrimesters] = useState<boolean>(false);

  const reportsRepository = useProgressReportsRepository();

  /**
   * @description Список семестров
   * @return {Promise<any>}
   */
  const fetchTrimesters = async (): Promise<any> => {
    setLoadingTrimesters(true);
    try {
      const { data } = await reportsRepository.getTrimesters();
      setTrimesters(data);
    } finally {
      setLoadingTrimesters(false);
    }
  };

  useEffect(() => {
    fetchTrimesters();
  }, []);

  return (
    <Container>
      <PageHeader
        onBack={goBack}
        style={{ padding: 0 }}
        title={strings.PROGRESS_REPORTS}
        extra={[!hasRiga && <QuickTips label={strings.QUICK_TIPS} />]}
      />
      {!hasTeacherRole ? (
        <Tabs defaultActiveKey="1">
          <TabPane tab={strings.FOR_TEACHERS} key="1">
            <ProgressReportForTeacher trimesters={trimesters} loadingTrimesters={loadingTrimesters} />
          </TabPane>
          <TabPane tab={strings.FOR_PARENTS} disabled={loadingTrimesters} key="2">
            <ProgressReportForParents trimesters={trimesters} loadingTrimesters={loadingTrimesters} />
          </TabPane>
          <TabPane tab={strings.FOR_TUTORS} key="3">
            <ProgressReportsWithSuperGroup />
          </TabPane>
        </Tabs>
      ) : (
        <ProgressReportForTeacher trimesters={trimesters} loadingTrimesters={loadingTrimesters} />
      )}
    </Container>
  );
};
