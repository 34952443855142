import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Select, Input, Tag, Form } from 'antd';
import dayjs from 'dayjs';
import { dateFormat } from 'helpers/dates';
import { TutorSelect, StageSelect, TeacherSelect, StudentSelect } from 'Global/components/FormComponents';
import ProgressReportsServices from 'helpers/services/ProgressReports';
import { FilterDate } from '../styles';
import { Branch, Subject } from 'types';
import { ProgressReportParams, ReportStatus, ReportTrimester } from 'types/Reports';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { FilterButtons } from 'Global/components/FilterButtons';
import { FormItemWithoutMargin } from 'Global/styles';

const { Option } = Select;

interface FilterTeachersProps {
  fetchProgressReports?: (params: ProgressReportParams) => Promise<any>;
  trimesters?: ReportTrimester[];
  statuses: ReportStatus[];
  loadingTrimesters: boolean;
  selectedRowKeys?: number[];
}

/**
 * @description Filter progress report
 * @param {object} props
 */
export const FilterTeachers = ({
  fetchProgressReports,
  trimesters,
  statuses,
  loadingTrimesters
}: FilterTeachersProps) => {
  const [strings] = useLanguageContext();
  const [form] = Form.useForm();
  const { branches, subjects } = useGlobalCollectionsContext();
  const limitReports = ProgressReportsServices.limit;
  const { locationSearchParams } = useLocationSearchParams();
  const { branch } = locationSearchParams || {};

  const [selectedBranch, setSelectedBranch] = useState<number>(branch);
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);

  const handleClear = async (): Promise<any> => {
    const params = { page: 1, limit: limitReports };
    await fetchProgressReports(params);
    form.resetFields();
  };

  /**
   * @description Отправка формы
   * @param {React.FormEvent<EventTarget>} e
   */
  const handleSubmit = async (values: ProgressReportParams): Promise<any> => {
    try {
      setHasSubmitLoading(true);
      await fetchProgressReports(values);
    } catch {
    } finally {
      setHasSubmitLoading(false);
    }
  };
  const initValues: ProgressReportParams = useMemo(() => locationSearchParams, [locationSearchParams]);

  useEffect(() => {
    branch && setSelectedBranch(branch);
  }, [branch]);

  useEffect(() => {
    form.setFieldsValue(initValues);
  }, [initValues]);

  return (
    <Form form={form} initialValues={initValues} onFinish={handleSubmit}>
      <Row gutter={[10, 10]}>
        <Col lg={12}>
          <FormItemWithoutMargin name="trimester">
            <Select placeholder={strings.SEMESTER} loading={loadingTrimesters} allowClear>
              {trimesters.map((trimester: ReportTrimester) => (
                <Option value={trimester.id} key={trimester.id}>
                  {trimester.active && <Tag color="green">{strings.CURRENT}</Tag>}
                  {trimester.name}
                  <FilterDate m>{dayjs(trimester.startAt).format(dateFormat)}</FilterDate>
                  <FilterDate>{dayjs(trimester.endAt).format(dateFormat)}</FilterDate>
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={4}>
          <FormItemWithoutMargin name="branch">
            <Select placeholder={strings.BRANCH} onChange={setSelectedBranch} allowClear>
              {branches.map((branch: Branch) => (
                <Option value={branch.id} key={branch.id}>
                  {branch.name}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={4}>
          <FormItemWithoutMargin name="stage">
            <StageSelect branch={selectedBranch} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={4}>
          <FormItemWithoutMargin name="subject">
            <Select placeholder={strings.SUBJECT} allowClear>
              {subjects.map((subject: Subject) => (
                <Option value={subject.id} key={subject.id}>
                  {subject.name}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={4}>
          <FormItemWithoutMargin name="teacherTutor">
            <TutorSelect placeholder={strings.ADM} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={4}>
          <FormItemWithoutMargin name="studentName">
            <Input placeholder={strings.STUDENT} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={4}>
          <FormItemWithoutMargin name="teacher">
            <TeacherSelect placeholder={strings.TEACHER} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={4}>
          <FormItemWithoutMargin name="title">
            <Input placeholder={strings.TITLE} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={4}>
          <FormItemWithoutMargin name="status">
            <Select placeholder={strings.STATUS} allowClear>
              {statuses.map((status: ReportStatus) => (
                <Option value={status.value} key={status.value}>
                  {status.text}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={4}>
          <FormItemWithoutMargin name="familyTutor">
            <TutorSelect placeholder={strings.FAMILY_TUTOR} />
          </FormItemWithoutMargin>
        </Col>
      </Row>
      <FilterButtons handleClear={handleClear} hasSubmitLoading={hasSubmitLoading} />
    </Form>
  );
};

export default { FilterTeachers };
