import React, { useState } from 'react';
import dayjs from 'dayjs';
import { MessageContaner, MessageTitle, MessageText, MessageDateTime, MessageItem } from './styles';
import { dateTimeWithoutSecondFormat } from 'helpers/dates';
import { Message as IMessage } from 'types/ParentJournal';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Avatar } from 'Global/components/Avatar';
import { useThemeContext } from 'contexts/ThemeContext';
import { Button, Flex, Spin } from 'antd';
import { downloadSomeFile } from 'helpers';

interface MessageProps {
  reverse?: boolean;
  message: IMessage;
  photo?: string;
  name?: string;
  surname?: string;
}

/**
 * @description Сообщение в чате
 * @param {MessageProps} props
 * @return {React.ReactNode}
 */
export const Message = ({ reverse, message, photo, name, surname }: MessageProps) => {
  const { activeTheme } = useThemeContext();

  const { body, createdAt, files } = message;
  const [strings, lang] = useLanguageContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const date = dayjs(createdAt).locale(lang).format(dateTimeWithoutSecondFormat);

  const onDownloadFile = async (fileId: number, fileName: string): Promise<any> => {
    setHasLoading(true);
    try {
      await downloadSomeFile(`/api/v1/attachment/download/${fileId}`, fileName, null, null, true);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  return (
    <MessageContaner reverse={reverse}>
      <Avatar photo={photo} size={40} isClickDisabled userName={name} userSurname={surname} />
      <MessageItem>
        <MessageTitle>
          <strong style={{ color: activeTheme.primaryColor }}>{reverse ? strings.YOU : `${name} ${surname}`}</strong>
          <MessageDateTime>{date}</MessageDateTime>
        </MessageTitle>
        <MessageText>
          {body}
          <Spin spinning={hasLoading}>
            <Flex vertical align="start">
              {files?.map(attach => (
                <Button type="link" onClick={() => onDownloadFile(attach.id, attach.name)}>
                  {attach.name}
                </Button>
              ))}
            </Flex>
          </Spin>
        </MessageText>
      </MessageItem>
    </MessageContaner>
  );
};

export default { Message };
