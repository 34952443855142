import { Branch, Product, StudyProgram } from 'types';
import { Flex, Tag, Tooltip } from 'antd';
import React from 'react';
import { InfoCircleTwoTone } from '@ant-design/icons';

export const getColumns = strings => [
  {
    title: strings.NAME,
    key: 'name',
    dataIndex: 'name'
  },
  {
    title: strings.REGISTER_TYPE,
    key: 'registerType',
    dataIndex: 'registerType'
  },
  {
    title: (
      <Flex gap={5}>
        {strings.REPORTING_TYPE}
        <Tooltip title={strings.LESSON_OUTCOMES_GRADES_IN_REGISTER_AND_STUDENTS_PROFILE}>
          <InfoCircleTwoTone twoToneColor="#1890ff" />
        </Tooltip>
      </Flex>
    ),
    key: 'reportType',
    dataIndex: 'reportType'
  },
  {
    title: strings.PRODUCT,
    key: 'product',
    dataIndex: 'product',
    render: (product: Product) => <p>{product?.name}</p>
  },
  {
    title: strings.STUDY_PROGRAM,
    key: 'studyProgram',
    dataIndex: 'studyProgram',
    render: (studyProgram: StudyProgram) => <p>{studyProgram?.name}</p>
  },
  {
    title: strings.BRANCHES,
    key: 'branches',
    dataIndex: 'branches',
    render: (branches: Branch[]) => (
      <>
        {branches?.map(item => (
          <Tag style={{ margin: 5 }}>{item?.name}</Tag>
        ))}
      </>
    )
  }
];
