import React, { useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, Row, Typography, Input, Button, Col, Upload, message, Spin, Alert, Segmented } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { Mail } from 'api';
import { IFamily, IStudent, ITutor } from 'types';
import { first } from 'lodash';
import { useUserContext } from 'contexts/UserContext';
import { useFamilyContext } from 'contexts/FamilyContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useFamilyRepository } from 'repos/FamilyRepository';
import { TutorItem } from 'Student&Parent/Main/Tutors';
import { useConstantsContext } from 'contexts/ConstantsContext';

const { Text } = Typography;
const { TextArea } = Input;

interface ModalTutorProps extends FormComponentProps {
  onClose?: () => void;
  visible: boolean;
}

/**
 * @description Модальное окно тьютора (Zendesk)
 * @param {function} onClose функция для закрытия модального окна
 * @param {boolean} visible флаг для скрытия/открытия окна
 * @param {object} form форма
 * @return {React.ReactNode}
 */
const ModalTutorTemplate = ({ onClose, visible, form }: ModalTutorProps) => {
  const [strings] = useLanguageContext();
  const familyRepository = useFamilyRepository();
  const { getFieldDecorator, getFieldsValue, resetFields, validateFieldsAndScroll } = form;
  const [activeChild, setActiveChild] = useState<IStudent>(null);
  const [children, setChildren] = useState<IStudent[]>(null);
  const [hasLoadTutor, setHasLoadTutor] = useState<boolean>(false);
  const [user] = useUserContext();
  const [family, hasLoadFamily]: [IFamily, boolean] = useFamilyContext();
  const { ACTIVE_STUDENT_STATUS_ID } = useConstantsContext();
  const hasParent = user?.hasRoles?.parent;
  const hasStudent = user?.hasRoles?.student;
  const [studentTutor, setStudentTutor] = useState<ITutor>(null);
  const hasTutor = studentTutor?.user?.email;

  const getStudentTutor = async (): Promise<any> => {
    setHasLoadTutor(false);
    try {
      const { data } = await familyRepository.getTutors();
      setStudentTutor(first(data));
    } finally {
      setHasLoadTutor(true);
    }
  };

  useEffect(() => {
    if (hasStudent || hasParent) {
      getStudentTutor();
    }
  }, [hasStudent, hasParent]);

  useEffect(() => {
    if ((hasParent || hasStudent) && family?.children) {
      const students = family?.children.filter(child => child?.status?.id === ACTIVE_STUDENT_STATUS_ID);
      setChildren(students);
      setActiveChild(first(students));
    }
  }, [hasStudent, hasParent, family?.children]);

  /**
   * @description Отправка сообщения
   * @param params
   * @return {Promise<any>}
   */
  const sendMessage = async (params): Promise<any> => {
    try {
      await Mail.sendMailDistribution(params);
      message.success(strings.THE_MESSAGE_HAS_BEEN_SENT_SUCCESSFULLY);
    } catch {
      message.error(strings.THE_EMAIL_IS_UNAVAILABLE_PLEASE_CALL_YOUR_TUTOR);
    }
  };

  /**
   * функция изменения ребенка
   * @param child
   */
  const onToggleChild = id => {
    const toggleChild = children.find(item => item.id === id);
    setActiveChild(toggleChild);
  };

  const resetFieldsAndClose = () => {
    resetFields();
    onClose();
  };

  /**
   * @description Form Submit
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = async (e: React.FormEvent<EventTarget>): Promise<any> => {
    e.preventDefault();
    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const messageParams = {
          userType: 'admins',
          message: values.message,
          subject: `Message from: ${user.name} ${user.surname}`,
          from: user?.myConnectedAccounts[0]?.user?.email || user.name, //берем !первый! email отправителя если есть
          name: `${user.name} ${user.surname}`,
          emails: hasParent ? [activeChild?.tutor?.user?.email] : [studentTutor?.user?.email],
          attachments: values.attachments
        };
        try {
          await sendMessage(messageParams);
          resetFieldsAndClose();
        } catch {}
      }
    });
  };

  const options = children?.map(child => ({
    label: <span>{`${child?.user?.name} ${child?.user?.surname}`}</span>,
    value: child.id
  }));

  const uploadProps = {
    name: 'files',
    multiple: true,
    beforeUpload: () => false
  };

  return (
    <Modal
      onCancel={onClose}
      width={700}
      open={visible}
      title={strings.MESSAGE_TO_YOUR_TUTOR}
      footer={
        hasTutor
          ? [
              <Button type="primary" size="large" key="send" onClick={handleSubmit}>
                {strings.SEND}
              </Button>
            ]
          : null
      }
    >
      <Spin spinning={hasLoadFamily} tip={strings.LOADING}>
        {hasTutor ? (
          <>
            {hasStudent && <TutorItem tutor={studentTutor} />}
            {hasParent && (
              <Row gutter={[10, 10]}>
                {children?.length > 1 && (
                  <>
                    <Col lg={24}>
                      <Text>{strings.WHICH_STUDENT_TUTOR_DO_YOU_WANT_TO_SEND_A_MESSAGE_TO}</Text>
                    </Col>
                    <Col lg={24}>
                      <Segmented options={options} value={activeChild?.id} onChange={onToggleChild} />
                    </Col>
                  </>
                )}
                <Col lg={24}>
                  {strings.TUTOR}:{' '}
                  <Text strong>
                    {activeChild?.tutor?.user?.name} {activeChild?.tutor?.user?.surname}
                  </Text>
                </Col>
              </Row>
            )}
            <Form layout="vertical" onSubmit={handleSubmit}>
              <MyFormItem label={strings.MESSAGE}>
                {getFieldDecorator('message', {
                  rules: [{ required: false }]
                })(<TextArea rows={10} placeholder={strings.TELL_US_ABOUT_YOUR_PROBLEM} />)}
              </MyFormItem>

              <MyFormItem label={strings.ATTACHMENTS}>
                {getFieldDecorator('attachments', {
                  rules: [{ required: false }]
                })(
                  <Upload {...uploadProps}>
                    <Button>
                      <UploadOutlined /> {strings.CLICK_TO_UPLOAD}
                    </Button>
                  </Upload>
                )}
              </MyFormItem>
            </Form>
          </>
        ) : (
          hasLoadTutor && <Alert message={strings.YOU_DONT_HAVE_ANY_TUTOR} type="warning" showIcon />
        )}
      </Spin>
    </Modal>
  );
};

export const ModalTutor = Form.create<ModalTutorProps>({})(ModalTutorTemplate);

export default { ModalTutor };
