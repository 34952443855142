import React, { useMemo, useContext } from 'react';
import { httpService } from 'api/core';
import { ISubjectPostParams, SubjectsFilterParams } from 'types/education';
import { getAllFormData } from '../helpers';

const SubjectsRepositoryContext = React.createContext<ISubjectsRepository>(null);
interface ISubjectsRepository {
  getSubjects: (params: SubjectsFilterParams) => Promise<any>;
  getSubjectById: (id: number | string) => Promise<any>;
  editSubject: (id: number | string, data: ISubjectPostParams) => Promise<any>;
  createSubject: (data: ISubjectPostParams) => Promise<any>;
  deleteSubject: (id: number | string) => Promise<any>;
}

class SubjectsRepository implements ISubjectsRepository {
  getSubjects(params: SubjectsFilterParams) {
    return httpService.get('/api/v1/subjects', params, null, true);
  }

  getSubjectById(id: number | string) {
    return httpService.get(`/api/v1/subjects/${id}`, null, null, true);
  }

  editSubject(id: number | string, data: ISubjectPostParams) {
    const params = getAllFormData(data);
    return httpService.put(`/api/v1/subjects/${id}`, data, null, null, true);
  }

  createSubject(data: ISubjectPostParams) {
    return httpService.post(`/api/v1/subjects`, data, null, null, true);
  }

  deleteSubject(id: number | string) {
    return httpService.delete(`/api/v1/subjects/${id}`, null, null, true);
  }
}

export const useSubjectsRepository = () => {
  const service = useContext(SubjectsRepositoryContext);

  if (!service) {
    throw new Error('Subjects repository is unavailable');
  }

  return service;
};

export const SubjectsRepositoryProvider = props => {
  const service = useMemo(() => new SubjectsRepository(), []);

  return <SubjectsRepositoryContext.Provider value={service}>{props.children}</SubjectsRepositoryContext.Provider>;
};
