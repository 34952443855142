import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Table, Typography, Row } from 'antd';
import { columnsSent } from './columns';
import { ShowSms as StShowSms } from '../styles';
import Breadcrumbs from 'Global/components/Breadcrumbs';
import { Location, History } from 'history';
import { Sms } from 'api/Sms';
import { useLastLocation } from 'react-router-last-location';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Title } = Typography;
const { Column } = Table;

interface ShowSmsProps {
  location: Location;
  history: History;
  match: any;
}

/**
 * @description Компонент просмотра смс
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const ShowSms = (props: ShowSmsProps) => {
  const [strings] = useLanguageContext();
  const [sms, setSms] = useState([]);
  const [loadSms, setLoadSms] = useState<boolean>(false);
  const handleBack = () => goBack();
  const lastLocation = useLastLocation();
  const {
    location,
    history: { goBack },
    match: {
      params: { id }
    }
  } = props;

  /**
   * @description Получение сообщений по id
   * @return {Promise<any>}
   */
  const getSmsById = async (): Promise<any> => {
    setLoadSms(true);
    await Sms.getSmsSessionById(id)
      .then(({ data: { Send } }) => {
        setSms(Send);
        setLoadSms(false);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getSmsById();
  }, []);

  return (
    <StShowSms>
      <Breadcrumbs location={location} />
      <Title level={4}>
        {lastLocation && <ArrowLeftOutlined onClick={handleBack} />}
        {strings.VIEW_SMS_MAILING}
      </Title>
      <Row>
        <Table dataSource={sms} loading={loadSms}>
          {columnsSent(strings).map(col => (
            <Column key={col.key} {...col} />
          ))}
        </Table>
      </Row>
    </StShowSms>
  );
};

export default { ShowSms };
