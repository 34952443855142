import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Input, Button, message, Spin, Alert, Form } from 'antd';
import { Families } from 'api/Families';
import { IParent } from 'types';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useInvoicesRepository } from 'repos/InvoicesRepository';
import { MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import MyJoditEditor from 'Global/components/MyJoditEditor';

export interface SendInvoiceType {
  theme: string;
  text: string;
  emails: string[];
}

export interface LetterProps {
  cancel: (id?: number) => void;
  show: boolean;
  id: number;
  parents: IParent[];
}

export const Letter = (props: LetterProps) => {
  const [form] = Form.useForm();
  const [strings] = useLanguageContext();
  const invoicesRepository = useInvoicesRepository();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFormLoading, setFormLoading] = useState<boolean>(false);
  const [template, setTemplate] = useState<{ title: string; body: string }>(null);
  const [text, setText] = useState<string>(null);
  const { show, cancel, id, parents } = props;

  const defEmails = parents.filter(parent => parent?.user?.email).map((parent, index) => parent.user.email);

  const sendInvoiceAction = useCallback(
    async (values: SendInvoiceType) => {
      setIsLoading(true);
      try {
        await invoicesRepository.sendInvoice(id, values);
        message.success(strings.SUCCESS_SEND, 2);
        cancel(id);
        form.resetFields();
      } finally {
        setIsLoading(false);
      }
    },
    [cancel, form]
  );

  const onFinish = (values: any) => {
    sendInvoiceAction(values);
  };

  /**
   * @description Получить шаблон письма
   * @returns {Promise<any>}
   */
  const fetchLetterTemplate = useCallback(async () => {
    if (!id) return;
    setFormLoading(true);
    try {
      const { data } = await Families.getLetterTemplate(id);
      setTemplate(data);
    } finally {
      setFormLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchLetterTemplate();
  }, [fetchLetterTemplate]);

  useEffect(() => {
    form.setFieldsValue({ theme: template?.title, text: template?.body });
    setText(template?.body);
  }, [template]);

  useEffect(() => {
    form.setFieldsValue({ emails: defEmails });
  }, [defEmails]);

  return (
    <Modal
      destroyOnClose={true}
      title={strings.LETTER}
      style={{ top: 100 }}
      open={show}
      onCancel={() => cancel()}
      width={820}
      footer={[]}
    >
      <Spin spinning={isFormLoading}>
        <Form form={form} layout="vertical" name="dynamic_form_item" onFinish={onFinish}>
          <Form.Item label={strings.THEME} name="theme" rules={[{ required: true, message: 'Please input theme!' }]}>
            <Input />
          </Form.Item>
          <Form.List
            name="emails"
            rules={[
              {
                validator: async (_, names) => {
                  if (!names || names.length < 1) {
                    return Promise.reject(new Error('At least 1 email'));
                  }
                }
              }
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item label={index === 0 ? 'Emails' : ''} required={true} key={field.key}>
                    <Form.Item
                      {...field}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Please input email or delete this field.'
                        }
                      ]}
                      noStyle
                    >
                      <Input style={{ width: '50%' }} />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleTwoTone
                        twoToneColor="#f5222d"
                        onClick={() => remove(field.name)}
                        style={{ marginInlineStart: 10 }}
                      />
                    ) : null}
                    {index === fields.length - 1 ? (
                      <PlusCircleTwoTone onClick={() => add()} style={{ marginInlineStart: 10 }} />
                    ) : null}
                  </Form.Item>
                ))}
              </>
            )}
          </Form.List>
          <Form.Item name="text" label={strings.TEXT}>
            <Alert
              style={{ fontSize: 14, marginBottom: 10 }}
              type="info"
              showIcon
              message={
                strings.HTML_CODE_SHOULD_BE_ADDED_EXCLUSIVELY_THROUGH_THE_BUTTON_SOURCE_CODE_OTHER_INPUT_OPTIONS_WILL_NOT_WORK_CORRECTLY
              }
            />
            <MyJoditEditor key="editor" value={text} onChange={value => form.setFieldsValue({ text: value })} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={isLoading} loading={isLoading}>
              {strings.SEND}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default { Letter };
