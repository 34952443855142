import React from 'react';
import { Collapse, Form, Row, Col, Checkbox, Button } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import MyJoditEditor from 'Global/components/MyJoditEditor';
import { useStudentRepository } from 'repos/StudentRepository';
import { convertBooleanToNumber } from 'helpers';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const { Panel } = Collapse;

interface AddCollaborationHistoryProps {
  studentId: number | string;
  fetchCollaborationHistory: () => Promise<any>;
}

/**
 * @return {React.ReactNode}
 */
export const AddCollaborationHistory: React.FC<AddCollaborationHistoryProps> = ({
  studentId,
  fetchCollaborationHistory
}: AddCollaborationHistoryProps) => {
  const [strings] = useLanguageContext();
  const [form] = Form.useForm();
  const studentRepository = useStudentRepository();

  const onFinish = async (values: { description: string; visibleToTeacher: boolean }) => {
    const params = convertBooleanToNumber(values) as { description: string; visibleToTeacher: number };
    try {
      await studentRepository.createCollaborationHistory(studentId, params);
      fetchCollaborationHistory();
      form.resetFields();
    } catch {}
  };

  const handleNotifyTeacher = (e: CheckboxChangeEvent) => {
    const value = e.target.checked;
    if (value) {
      form.setFieldsValue({ visibleToTeacher: value });
    }
  };

  return (
    <Collapse
      expandIconPosition="right"
      bordered={false}
      defaultActiveKey="1"
      style={{ marginBottom: 20, border: '1px solid #f0f0f0' }}
    >
      <Panel header={strings.ADD_COLLABORATION_HISTORY} key="1">
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          initialValues={{ description: '', visibleToTeacher: 0 }}
        >
          <Row gutter={10}>
            <Col lg={24}>
              <Form.Item name="description">
                <MyJoditEditor
                  key="description-editor"
                  height={150}
                  onChange={value => form.setFieldsValue({ description: value })}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item name="visibleToTeacher" valuePropName="checked" style={{ margin: 0 }}>
                <Checkbox>{strings.AVAILABLE_TO_TEACHER}</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item name="notifyTeacher" valuePropName="checked" style={{ margin: 0 }}>
                <Checkbox onChange={handleNotifyTeacher}>{strings.NOTIFY_TEACHER}</Checkbox>
              </Form.Item>
            </Col>
            <Col lg={24} style={{ display: 'flex', justifyContent: 'end' }}>
              <Button type="primary" htmlType="submit">
                {strings.CREATE}
              </Button>
            </Col>
          </Row>
        </Form>
      </Panel>
    </Collapse>
  );
};

export default { AddCollaborationHistory };
