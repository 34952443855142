import React, { useState } from 'react';
import { BASE_FAMILIES } from 'Global/routes';
import { InvoicesTable } from 'Global/components/Tables/Invoices';
import { Button, Switch as SwitchBtn, Row, Typography, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { useUserContext } from 'contexts/UserContext';
import { InvoiceListItem } from 'types/invoice';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Branch } from 'types';
import { ExtraCreateInvoice } from './Create/ExtraCreateInvoice';
import { QuickTips } from 'Global/components/QuickTips';
import { QUICK_TIPS_FAMILY_INVOICE } from 'Global/constants';

const { Text } = Typography;

interface InvoicesListProps {
  familyId: number;
  isLoadInvoices: boolean;
  hasAccessCreating: boolean;
  invoicesList: InvoiceListItem[];
  refetchInvoices: () => void;
  branch: Branch;
}

/**
 * @description Инвойсы Москвы
 * @param {Object} InvoicesMoscowProps
 * @return {React.ReactNode}
 */
export const InvoicesList = (props: InvoicesListProps) => {
  const [strings] = useLanguageContext();
  const { familyId, isLoadInvoices, invoicesList, refetchInvoices, branch, hasAccessCreating } = props;
  const [user] = useUserContext();
  const [hasFilterByCancelledStatus, setHasFilterByCancelledStatus] = useState<boolean>(true);
  const createInvoiceRoute: string = `${BASE_FAMILIES}/${familyId}/create-invoice`;
  const hasRigaFamily = branch?.id === 5;
  const hasRiga = user?.branch?.id === 5;

  const filterInvoicesByStatus = () => {
    if (invoicesList && hasFilterByCancelledStatus) {
      return invoicesList.filter(invoice => invoice?.status !== 3);
    } else {
      return invoicesList;
    }
  };

  return (
    <>
      <Row align="middle" style={{ marginBottom: 20 }} justify="space-between" className="no-padding">
        {user?.hasRoles?.admin && (
          <div style={{ display: 'flex', gap: 10 }}>
            <Tooltip title={hasAccessCreating ? null : strings.CHANGE_STUDENTS_STATUS_TO_STUDENT_OR_SEASONAL}>
              <Button type="primary" disabled={!hasAccessCreating}>
                <Link to={createInvoiceRoute}>{strings.CREATE_INVOICE}</Link>
              </Button>
            </Tooltip>
            {hasRigaFamily && <ExtraCreateInvoice familyId={familyId} />}
            {!hasRiga && <QuickTips url={QUICK_TIPS_FAMILY_INVOICE} />}
          </div>
        )}
        <div>
          <SwitchBtn
            checked={hasFilterByCancelledStatus}
            onChange={checked => setHasFilterByCancelledStatus(checked)}
          />
          <Text style={{ paddingInlineStart: '5px' }}>{strings.HIDE_CANCELLED_STATUSES}</Text>
        </div>
      </Row>
      <InvoicesTable
        isPagination={false}
        isFamily={true}
        refetchInvoices={refetchInvoices && refetchInvoices}
        invoicesList={filterInvoicesByStatus()}
        isLoadInvoices={isLoadInvoices && isLoadInvoices}
      />
    </>
  );
};
export default { InvoicesList };
