import React from 'react';
import {CheckCircleTwoTone, EditOutlined, MinusCircleTwoTone} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { SERVICE_RATES } from '../../routes';

export const getColumns = strings => [
  {
    title: strings.NAME,
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: strings.RATE,
    dataIndex: 'price',
    key: 'price',
    className: 'clmn-center'
  },
  {
    title: strings.PRICE_GROUP,
    dataIndex: 'priceGroup',
    key: 'priceGroup',
    width: 140,
    render: priceGroup => priceGroup && priceGroup.name
  },
  {
    title: strings.CURRENT,
    dataIndex: 'isActive',
    key: 'isActive',
    className: 'clmn-center',
    render: (isActive: boolean) =>
      isActive ? <CheckCircleTwoTone style={{ fontSize: 24 }} twoToneColor="#52c41a" /> : <MinusCircleTwoTone style={{ fontSize: 24 }} twoToneColor="#f5222d" />
  },
  {
    title: strings.COMMENT,
    dataIndex: 'sequence',
    key: 'sequence'
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'actions',
    render: id => {
      return (
        <Link to={`${SERVICE_RATES}/${id}/edit`}>
          <EditOutlined />
        </Link>
      );
    }
  }
];
export default { getColumns };
