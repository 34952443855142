import React, { useEffect, useState } from 'react';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message, Modal, Row, Table } from 'antd';
import { useHistory } from 'react-router-dom';
import { Filter } from './Filter';
import queryString from 'query-string';
import { isEmpty } from 'lodash';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { ExpertDeleteParams, ExpertItem, ExpertReverseItem, ExpertsFilterParams } from 'types/education';
import { useAdminRepository } from 'repos/AdminRepository';
import { ExpertFormMode, ModalExpertForm } from './ModalExpertForm';
import { Stage } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';

export const Experts = () => {
  const [user] = useUserContext();
  const [strings] = useLanguageContext();
  const { goBack, push } = useHistory();
  const { locationSearchParams } = useLocationSearchParams();
  const adminRepository = useAdminRepository();
  const { branches } = useGlobalCollectionsContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [experts, setExperts] = useState<ExpertItem[]>([]);
  const [selectedExpert, setSelectedExpert] = useState<ExpertReverseItem>(null);
  const [expertFormMode, setExpertFormMode] = useState<ExpertFormMode>(ExpertFormMode.create);
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const [{ confirm }, contextHolder] = Modal.useModal();

  const fetchExperts = async (params?: ExpertsFilterParams): Promise<any> => {
    setHasLoading(true);
    if (branches.length === 1) {
      params['branch'] = user.branch.id;
    }
    try {
      const { data } = await adminRepository.getExperts(params);
      setExperts(data);
      push({ search: queryString.stringify(params) });
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  function createReverseArray(obj: ExpertItem): ExpertReverseItem[] {
    let reverseArray = [];
    let itemIndex = 0;
    obj.subjects.forEach(subject => {
      const branchCount = subject.branch.length;
      subject.branch.forEach((branch, branchIndex) => {
        itemIndex = ++itemIndex;
        let newObj = {
          id: obj.id,
          name: obj.name,
          surname: obj.surname,
          subjectId: subject?.id,
          subjectName: subject?.name,
          branchId: branch.id,
          branchName: branch.name,
          stages: branch.stage,
          branchCount,
          branchIndex,
          itemIndex
        };
        reverseArray.push(newObj);
      });
    });
    const itemCount = itemIndex;
    return reverseArray.map(item => {
      return { ...item, itemCount };
    });
  }

  const handlerAddExpert = () => {
    setSelectedExpert(null);
    setExpertFormMode(ExpertFormMode.create);
    setHasShowModal(true);
  };

  const handleDelete = (row: ExpertReverseItem): void => {
    const { id, subjectId, branchId, subjectName, branchName, surname, name } = row;
    const title = (
      <p>
        {strings.ARE_YOU_SURE_YOU_WANT_TO_DELETE} the <strong>{branchName}</strong> branch inside the
        <strong> {subjectName}</strong> item for
        <strong>
          {' '}
          {surname} {name}
        </strong>
        ?
      </p>
    );
    confirm({
      content: title,
      okText: strings.DELETE,
      okType: 'danger',
      async onOk() {
        const params: ExpertDeleteParams = { user: id, subject: subjectId, branch: branchId };
        try {
          await adminRepository.deleteExpert(params);
          message.success(strings.SUCCESSFULLY_DELETED);
          fetchExperts();
        } catch (err) {
          message.error(err.response.data.message);
        }
      }
    });
  };

  const getActionClmn = [
    {
      title: strings.NAME,
      dataIndex: '',
      key: 'expertName',
      render: (row: ExpertReverseItem) => {
        const { itemIndex, itemCount, id, name, surname } = row;
        const value = (
          <>
            {`${surname} ${name}`}
            <br />
            <Button
              style={{ margin: 0 }}
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => {
                setSelectedExpert(row);
                setExpertFormMode(ExpertFormMode.addSubject);
                setHasShowModal(true);
              }}
              size="small"
            >
              {strings.SUBJECT_SM}
            </Button>
          </>
        );
        const obj = {
          children: value,
          props: { rowSpan: itemIndex === 1 ? itemCount : 0 }
        };
        return obj;
      }
    },
    {
      title: strings.SUBJECT,
      dataIndex: 'subjectName',
      key: 'subjectName',
      render: (subjectName, row: ExpertReverseItem) => {
        const { branchCount, branchIndex } = row;
        const obj = {
          children: subjectName,
          props: { rowSpan: branchIndex === 0 ? branchCount : 0 }
        };
        return obj;
      }
    },
    {
      title: strings.BRANCH,
      dataIndex: 'branchName',
      key: 'branchName',
      render: (branchName: string, row: ExpertReverseItem) => {
        return (
          <Row
            align="middle"
            justify="space-between"
            style={{
              flexWrap: 'nowrap',
              gap: 8,
              whiteSpace: 'nowrap'
            }}
          >
            {branchName}
            <Button
              style={{ margin: 0 }}
              icon={<DeleteOutlined />}
              danger
              onClick={() => handleDelete(row)}
              size="small"
            />
          </Row>
        );
      }
    },
    {
      title: strings.STAGE,
      dataIndex: 'stages',
      key: 'stages',
      render: (stages: Stage[], row: ExpertReverseItem) => {
        const stageNames = stages?.map(stage => stage.name);
        return (
          <Row align="middle" justify="space-between" style={{ flexWrap: 'nowrap' }}>
            {stageNames.join(', ').toString()}
            <Button
              style={{ margin: 0, minWidth: 24 }}
              icon={<EditOutlined />}
              type="primary"
              size="small"
              onClick={() => {
                setSelectedExpert(row);
                setExpertFormMode(ExpertFormMode.edit);
                setHasShowModal(true);
              }}
            />
          </Row>
        );
      }
    }
  ];

  const reverseExperts = React.useMemo(() => {
    if (experts.isNotEmpty()) {
      const newArrays = experts.map(item => createReverseArray(item)).flat();
      return newArrays;
    }
  }, [experts]);

  useEffect(() => {
    if (isEmpty(locationSearchParams)) {
      fetchExperts();
    } else {
      fetchExperts(locationSearchParams);
    }
  }, [user]);

  return (
    <Container>
      {contextHolder}
      <ModalExpertForm
        mode={expertFormMode}
        expert={selectedExpert}
        fetchExperts={fetchExperts}
        hasShowModal={hasShowModal}
        setHasShowModal={setHasShowModal}
      />
      <StPageHeader
        onBack={goBack}
        title={strings.EXPERTS}
        extra={
          <Button type="primary" onClick={handlerAddExpert}>
            {strings.ADD_AN_EXPERT}
          </Button>
        }
      >
        <Filter onFilter={fetchExperts} />
      </StPageHeader>
      <Row gutter={[10, 10]}>
        <Table
          bordered
          dataSource={reverseExperts}
          columns={getActionClmn}
          pagination={false}
          loading={{ spinning: hasLoading }}
        />
      </Row>
    </Container>
  );
};
export default Experts;
