import React from 'react';

export const InformalAgreementContent = () => (
  <div>
    <meta charSet="utf-8" />
    <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
    <style
      type="text/css"
      dangerouslySetInnerHTML={{
        __html:
          '\n      .ExternalClass {\n        width: 100%;\n      }\n\n      img {\n        border: 0 none;\n        height: auto;\n        line-height: 100%;\n        outline: none;\n        text-decoration: none;\n        -ms-interpolation-mode: bicubic;\n      }\n\n      a img {\n        border: 0 none;\n      }\n\n      #outlook a {\n        padding: 0;\n      }\n\n      #allrecords {\n        height: 100% !important;\n        margin: 0;\n        padding: 0;\n        width: 100% !important;\n        -webkit-font-smoothing: antialiased;\n        line-height: 1.45;\n      }\n\n      #allrecords td {\n        margin: 0;\n        padding: 0;\n      }\n\n      #allrecords ul {\n        -webkit-padding-start: 30px;\n      }\n\n      .t-records ol,\n      .t-records ul {\n        padding-left: 20px;\n        margin-top: 0px;\n        margin-bottom: 10px;\n      }\n\n      @media only screen and (max-width: 600px) {\n        .r {\n          width: 100% !important;\n          min-width: 300px !important;\n          margin-top: -1px !important;\n        }\n      }\n\n      @media only screen and (max-width: 480px) {\n        .t-emailBlock {\n          display: block !important;\n          padding-left: 0 !important;\n          padding-right: 0 !important;\n          width: 100% !important;\n        }\n\n        .t-emailBlockPadding {\n          padding-top: 15px !important;\n        }\n\n        .t-emailBlockPadding30 {\n          padding-top: 30px !important;\n        }\n\n        .t-emailAlignLeft {\n          text-align: left !important;\n          margin-left: 0 !important;\n        }\n\n        .t-emailAlignCenter {\n          text-align: center !important;\n          margin-left: auto !important;\n          margin-right: auto !important;\n        }\n      }\n    '
      }}
    />
    <table
      id="allrecords"
      className="t-records"
      data-tilda-email="yes"
      data-tilda-project-id={5934141}
      data-tilda-page-id={38769307}
      data-tilda-page-alias=""
      cellPadding={0}
      cellSpacing={0}
      style={{
        width: '100%',
        borderCollapse: 'collapse',
        borderSpacing: 0,
        padding: 0,
        margin: 0,
        border: 0
      }}
    >
      <tbody>
        <tr>
          <td style={{ backgroundColor: '#f2f2f2' }}>
            {/*record_mail*/}
            <table
              id="rec626693259"
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                borderSpacing: 0,
                margin: 0,
                border: 0
              }}
              cellPadding={0}
              cellSpacing={0}
              data-record-type={619}
            >
              <tbody>
                <tr>
                  <td style={{ paddingLeft: 15, paddingRight: 15 }}>
                    <table
                      id="recin626693259"
                      className="r"
                      style={{ margin: '0 auto', borderSpacing: 0, width: 600 }}
                      align="center"
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              paddingRight: 0
                            }}
                          >
                            <table
                              border={0}
                              cellPadding={0}
                              cellSpacing={0}
                              width="100%"
                              style={{ tableLayout: 'fixed' }}
                            >
                              <tbody>
                                <tr>
                                  <td style={{ height: 30 }} height="30px" />
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            {/*/record*/}
            {/*record_mail*/}
            <table
              id="rec626693260"
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                borderSpacing: 0,
                margin: 0,
                border: 0
              }}
              cellPadding={0}
              cellSpacing={0}
              data-record-type={624}
            >
              <tbody>
                <tr>
                  <td style={{ paddingLeft: 15, paddingRight: 15 }}>
                    <table
                      id="recin626693260"
                      className="r"
                      style={{
                        margin: '0 auto',
                        backgroundColor: '#ffffff',
                        borderSpacing: 0,
                        width: 600
                      }}
                      align="center"
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              paddingTop: 15,
                              paddingBottom: 0,
                              paddingLeft: 30,
                              paddingRight: 30
                            }}
                          >
                            <table border={0} cellPadding={0} cellSpacing={0} width="100%">
                              <tbody>
                                <tr>
                                  <td
                                    className="t-emailBlock"
                                    valign="middle"
                                    style={{
                                      textAlign: 'left',
                                      paddingRight: 10,
                                      width: '50%'
                                    }}
                                  >
                                    <table border={0} cellPadding={0} cellSpacing={0} width="100%">
                                      <tbody>
                                        <tr>
                                          <td width="80px">
                                            <a style={{ textDecoration: 'none' }} href="https://tilda.cc/">
                                              <img
                                                width={80}
                                                style={{
                                                  display: 'block',
                                                  width: 80
                                                }}
                                                src="https://static.tildacdn.com/tild6236-6162-4231-a562-386532613236/_-8.png"
                                              />
                                            </a>
                                          </td>
                                          <td
                                            style={{
                                              textAlign: 'left',
                                              paddingLeft: 18
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontWeight: 'normal',
                                                fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
                                                color: '#222222',
                                                fontSize: 16
                                              }}
                                            >
                                              <a
                                                style={{
                                                  color: 'initial',
                                                  textDecoration: 'none'
                                                }}
                                                href="https://tilda.cc/"
                                              >
                                                LGEG
                                                <br />
                                                Образовательный центр
                                              </a>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td
                                    className="t-emailBlock t-emailBlockPadding t-emailAlignLeft"
                                    valign="middle"
                                    style={{
                                      textAlign: 'right',
                                      paddingLeft: 10,
                                      width: '50%'
                                    }}
                                  ></td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            {/*/record*/}
            {/*record_mail*/}
            <table
              id="rec626693261"
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                borderSpacing: 0,
                margin: 0,
                border: 0
              }}
              cellPadding={0}
              cellSpacing={0}
              data-record-type={637}
            >
              <tbody>
                <tr>
                  <td style={{ paddingLeft: 15, paddingRight: 15 }}>
                    <table
                      id="recin626693261"
                      className="r"
                      style={{
                        margin: '0 auto',
                        backgroundColor: '#ffffff',
                        borderSpacing: 0,
                        width: 600
                      }}
                      align="center"
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              paddingRight: 0
                            }}
                          >
                            <table border={0} cellPadding={0} cellSpacing={0} width="100%">
                              <tbody>
                                <tr>
                                  <td style={{ height: 15 }} height="15px" />
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      height: 3,
                                      backgroundColor: '#cc1e44'
                                    }}
                                    height="3px"
                                  />
                                </tr>
                                <tr>
                                  <td style={{ height: 15 }} height="15px" />
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            {/*/record*/}
            {/*record_mail*/}
            <table
              id="rec626693262"
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                borderSpacing: 0,
                margin: 0,
                border: 0
              }}
              cellPadding={0}
              cellSpacing={0}
              data-record-type={323}
            >
              <tbody>
                <tr>
                  <td style={{ paddingLeft: 15, paddingRight: 15 }}>
                    <table
                      id="recin626693262"
                      className="r"
                      style={{
                        margin: '0 auto',
                        backgroundColor: '#ffffff',
                        borderSpacing: 0,
                        width: 600
                      }}
                      align="center"
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              paddingTop: 30,
                              paddingBottom: 15,
                              paddingLeft: 30,
                              paddingRight: 30
                            }}
                          >
                            <table border={0} cellPadding={0} cellSpacing={0} width="100%" style={{ width: '100%' }}>
                              <tbody>
                                <tr>
                                  <td style={{ textAlign: 'left', padding: '0 0 0' }}>
                                    <div
                                      style={{
                                        marginRight: 'auto',
                                        fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
                                        color: '#444444',
                                        fontSize: 26,
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold'
                                      }}
                                    >
                                      <span style={{ color: 'rgb(206, 41, 72)' }}>приветственное письмо LGEG.</span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            {/*/record*/}
            {/*record_mail*/}
            <table
              id="rec626693263"
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                borderSpacing: 0,
                margin: 0,
                border: 0
              }}
              cellPadding={0}
              cellSpacing={0}
              data-record-type={329}
            >
              <tbody>
                <tr>
                  <td style={{ paddingLeft: 15, paddingRight: 15 }}>
                    <table
                      id="recin626693263"
                      className="r"
                      style={{
                        margin: '0 auto',
                        backgroundColor: '#ffffff',
                        borderSpacing: 0,
                        width: 600
                      }}
                      align="center"
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              paddingTop: 15,
                              paddingBottom: 0,
                              paddingLeft: 30,
                              paddingRight: 30
                            }}
                          >
                            <table border={0} cellPadding={0} cellSpacing={0} width="100%" style={{ width: '100%' }}>
                              <tbody>
                                <tr>
                                  <td style={{ textAlign: 'left', padding: '0 0 0' }}>
                                    <div
                                      style={{
                                        marginRight: 'auto',
                                        fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
                                        color: '#444444',
                                        fontSize: 18,
                                        lineHeight: '1.45'
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            {/*/record*/}
            {/*record_mail*/}
            <table
              id="rec626693264"
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                borderSpacing: 0,
                margin: 0,
                border: 0
              }}
              cellPadding={0}
              cellSpacing={0}
              data-record-type={329}
            >
              <tbody>
                <tr>
                  <td style={{ paddingLeft: 15, paddingRight: 15 }}>
                    <table
                      id="recin626693264"
                      className="r"
                      style={{
                        margin: '0 auto',
                        backgroundColor: '#ffffff',
                        borderSpacing: 0,
                        width: 600
                      }}
                      align="center"
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              paddingTop: 15,
                              paddingBottom: 15,
                              paddingLeft: 30,
                              paddingRight: 30
                            }}
                          >
                            <table border={0} cellPadding={0} cellSpacing={0} width="100%" style={{ width: '100%' }}>
                              <tbody>
                                <tr>
                                  <td style={{ textAlign: 'left', padding: '0 0 0' }}>
                                    <div
                                      style={{
                                        marginRight: 'auto',
                                        fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
                                        color: '#444444',
                                        fontSize: 18,
                                        lineHeight: '1.4'
                                      }}
                                    >
                                      Приветствуем, друзья!
                                      <br />
                                      Добро пожаловать в<span style={{ color: 'rgb(206, 41, 72)' }}> LGEG</span>! Если
                                      вы читаете этот договор, значит вы недавно присоединились к нашей команде. Нам
                                      очень важно сохранить и приумножить то, что мы создали. Для этого необходимо
                                      свериться и исключить разногласия в нескольких моментах, которые описаны ниже.
                                      Первая часть посвящена непосредственно
                                      <span style={{ color: 'rgb(206, 41, 72)' }}>работе преподавателя</span>, а вторая
                                      – <span style={{ color: 'rgb(206, 41, 72)' }}>общим правилам LGEG</span>
                                      .<br />
                                      <br />
                                      <strong style={{ color: 'rgb(206, 41, 72)' }}>
                                        I. Обязанности преподавателя LGEG:
                                      </strong>
                                      <br />
                                      <br />
                                      <span style={{ color: 'rgb(206, 41, 72)' }}>
                                        0. Подготовка к уроку и его проведение (в том числе необходимо дать домашнее
                                        задание):
                                      </span>
                                      <br />
                                      <br />
                                      1. Заполнение журналов (в том числе написание комментариев для родителей);
                                      <br />
                                      2. Проверка домашнего задания и передача обратной связи ученику;
                                      <br />
                                      3. Написание модульных комментариев (2 и более раз в год);
                                      <br />
                                      4. Встречи с родителями ( 2 раза в год, в конце семестра).
                                      <br />
                                      <br />С пунктом “0” на начальных этапах вам обязательно помогут АДМ и старший
                                      преподаватель. А пока что чуть подробнее рассмотрим остальные.
                                      <br />
                                      <br />
                                      <span style={{ color: 'rgb(206, 41, 72)' }}>1. Заполнение журналов:</span>
                                      <br />
                                      <br />
                                      Заполнение журналов и написание комментариев – это форма взаимодействия с
                                      клиентами, во многом на базе этого родители принимают решение о продолжении или
                                      прекращении сотрудничества, поэтому так важно предоставлять эту информацию вовремя
                                      и в полном объеме. Коммуникация об учебном процессе – важная часть, без которой
                                      образовательный процесс не будет максимально эффективным.
                                      <br />
                                      <br />
                                      <strong>
                                        <em>Статьи про работу с журналами:</em>
                                      </strong>
                                      <br />
                                      <br />
                                      <em />
                                      <em style={{ color: 'rgb(206, 41, 72)' }}>
                                        •
                                        <u>
                                          <a
                                            href="https://doc.clickup.com/2466699/d/h/2b8wb-18600/d4abc6951e83546"
                                            style={{ color: 'rgb(206, 41, 72)' }}
                                          >
                                            AMI: работа с журналами
                                          </a>
                                        </u>
                                      </em>
                                      <span style={{ color: 'rgb(206, 41, 72)' }}>;</span>
                                      <br />
                                      <em style={{ color: 'rgb(206, 41, 72)' }}>
                                        •
                                        <u>
                                          <a
                                            href="https://doc.clickup.com/2466699/d/h/2b8wb-20791/2b7a8ca9b4096f5"
                                            style={{ color: 'rgb(206, 41, 72)' }}
                                          >
                                            AMI: работа с проверенными домашними заданиями
                                          </a>
                                        </u>
                                        ;
                                      </em>
                                      <br />
                                      <em style={{ color: 'rgb(206, 41, 72)' }}>
                                        •
                                        <u>
                                          <a
                                            href="https://doc.clickup.com/2466699/d/h/2b8wb-18250/087c2cd9749f06a"
                                            style={{ color: 'rgb(206, 41, 72)' }}
                                          >
                                            AMI: color-coding уроков
                                          </a>
                                        </u>
                                      </em>
                                      <span style={{ color: 'rgb(206, 41, 72)' }}>.</span>
                                      <br />
                                      <br />
                                      <span style={{ color: 'rgb(206, 41, 72)' }}>2. Домашние задания детям:</span>
                                      <br />
                                      <br />В LGEG мы задаем и проверяем домашние задания (за исключением начальной
                                      школы, там другой принцип) и считаем, что это полезно и важно. Задавать,
                                      проверять, давать обратную связь ученикам о выполненных заданиях – такая же часть
                                      работы учителя, как подготовка и проведение урока.
                                      <br />
                                      Качество домашних заданий – это то, как мы говорим о себе и ребенку, и родителю. И
                                      так как родитель не может присутствовать на уроках, домашние задания – это одна из
                                      немногих его возможностей убедиться в качестве образовательного процесса.
                                      <br />
                                      <br />
                                      <strong>
                                        <em>Статьи про Домашние задания (кроме билингвальной школы):</em>
                                      </strong>
                                      <br />
                                      <br />
                                      <em />
                                      <em style={{ color: 'rgb(206, 41, 72)' }}>
                                        •
                                        <u>
                                          <a
                                            href="https://doc.clickup.com/2466699/d/h/2b8wb-20518/d437a98384a165e"
                                            style={{ color: 'rgb(206, 41, 72)' }}
                                          >
                                            Домашние задания - наш подход
                                          </a>
                                        </u>
                                        ;
                                      </em>
                                      <br />
                                      <em style={{ color: 'rgb(206, 41, 72)' }}>
                                        •
                                        <u>
                                          <a
                                            href="https://doc.clickup.com/2466699/d/h/2b8wb-18352/b243909c5d39bd5"
                                            style={{ color: 'rgb(206, 41, 72)' }}
                                          >
                                            Оформление домашнего задания
                                          </a>
                                        </u>
                                      </em>
                                      <em>.</em>
                                      <br />
                                      <br />
                                      <em style={{ color: 'rgb(206, 41, 72)' }}>3.</em>
                                      <span style={{ color: 'rgb(206, 41, 72)' }}> Модульные комментарии:</span>
                                      <br />
                                      <br />
                                      Для совместной работы с родителями нам важно сообщать им информацию о прогрессе
                                      ребенка. Модульные комментарии – это отчеты о прогрессе студентов, которые
                                      преподаватели пишут в конце тематического модуля. Качественная и своевременная
                                      обратная связь помогает родителю принять решение о продолжении или прекращении
                                      обучения. Это позволяет нам бороться с оттоком, поэтому нам очень важно, чтобы
                                      написание отчетов происходило вовремя.
                                      <br />
                                      <br />
                                      <strong>
                                        <em>Статьи про отчеты:</em>
                                      </strong>
                                      <br />
                                      <br />
                                      <em />
                                      <em style={{ color: 'rgb(206, 41, 72)' }}>
                                        •
                                        <u>
                                          <a
                                            href="https://doc.clickup.com/2466699/d/h/2b8wb-470952/c2a1c3ac090e6a9"
                                            style={{ color: 'rgb(206, 41, 72)' }}
                                          >
                                            Модульные комментарии
                                          </a>
                                        </u>
                                        ;
                                      </em>
                                      <br />
                                      <em style={{ color: 'rgb(206, 41, 72)' }}>
                                        •
                                        <u>
                                          <a
                                            href="https://doc.clickup.com/2466699/d/h/2b8wb-471432/d2cc6687d7bc6f9"
                                            style={{ color: 'rgb(206, 41, 72)' }}
                                          >
                                            Структура и содержание модульного комментария
                                          </a>
                                        </u>
                                        ;
                                      </em>
                                      <br />
                                      <br />
                                      <span style={{ color: 'rgb(206, 41, 72)' }}>
                                        4. Индивидуальные встречи родителей с преподавателями:
                                      </span>
                                      <br />
                                      <br />
                                      Такие встречи – единственная возможность для родителей лично пообщаться с
                                      преподавателем (в остальное время общение происходит только через тьютора или с
                                      помощью мессенджера в AMI). Они проводятся в LGEG 2 раза в год. Участие в них –
                                      это часть работы учителя, качество которой не может повышаться без обратной связи.
                                      Даты индивидуальных встреч становятся известны заранее, не позднее, чем за 2
                                      недели, чтобы у преподавателей была возможность выделить для них время в своем
                                      расписании.
                                      <br />
                                      <br />
                                      <em>
                                        *Если прочитал(-а) до этого момента, то напиши своему АДМу, какой у тебя любимый
                                        фильм*
                                      </em>
                                      <br />
                                      <br />
                                      Ну и последний, но немаловажный пункт -
                                      <span style={{ color: 'rgb(206, 41, 72)' }}>пунктуальность</span>
                                      !<br />
                                      Мы просим преподавателей приезжать на работу в LGEG заранее, лучше как минимум за
                                      10-15 минут до начала урока, оставляя время на то, чтобы «отдышаться», снять
                                      верхнюю одежду, подготовить все необходимые материалы и вовремя начать урок.
                                      Опоздания и неявки на работу без предупреждения создают дополнительные сложности
                                      для сотрудников и напрямую влияют на формирование индивидуального грейда учителя.
                                      <br />
                                      <br />
                                      <strong style={{ color: 'rgb(206, 41, 72)' }}>
                                        II. Общие правила для всех сотрудников ЛГЕГ:
                                      </strong>
                                      <br />
                                      <br />
                                      <span style={{ color: 'rgb(206, 41, 72)' }}>1.Внешний вид.</span>
                                      <br />У нас нет дресс-кода, и иногда наши преподаватели и ассистенты (и студенты)
                                      имеют нестандартные прически или носят веселую одежду. Это нормально, и мы хотим,
                                      чтобы наши дети знали, что люди бывают разными и имеют на это право. Но мы просим
                                      помнить, что любой выбранный стиль и образ должны соответствовать ситуации. Такие
                                      решения, как например, глубокий вырез в одежде, короткие шорты или кожаные
                                      леггинсы лучше оставить для неформальных мероприятий.
                                      <br />
                                      <br />
                                      <span style={{ color: 'rgb(206, 41, 72)' }}>
                                        2.Интеллектуальная собственность.
                                      </span>
                                      <br />
                                      LGEG – это компания, которая работает в рамках международного интеллектуального
                                      права и дорожит своей интеллектуальной собственностью. Все материалы, которые
                                      создаются в рамках LGEG (образовательные методики, учебные программы, учебные
                                      пособия, прочие материалы, используемые в обучении, а также любые прочие знания,
                                      опыт, информация, представляющие коммерческую ценность для LGEG) – это
                                      собственность компании, и их нельзя использовать для какой-то деятельности не в
                                      рамках сотрудничества с LGEG и/или передавать третьим лицам без письменного
                                      разрешения компании. Также сотрудничество с LGEG предполагает отсутствие
                                      сотрудничества с конкурентами, а именно с образовательными организациями,
                                      занимающимися аналогичными видами деятельности и с организациями, являющимися
                                      прямыми конкурентами. Не всегда можно сразу понять, является ли компания прямым
                                      конкурентом LGEG, поэтому если Вам поступило предложение о сотрудничестве в
                                      подобной организации или у Вас возникает идея создания такого центра, открытия
                                      курсов и т.д., просим рассказать об этом вашему линейному менеджеру. Нам очень
                                      важно, чтобы отношения в этом вопросе были прозрачными. Если сейчас Вы уже
                                      работаете в организации, которая выглядит как потенциальный конкурент LGEG, мы
                                      просим Вас дать нам об этом знать.
                                      <br />
                                      <br />
                                      <span style={{ color: 'rgb(206, 41, 72)' }}>
                                        3.Правила использования социальных сетей.
                                      </span>
                                      <br />
                                      Мы делаем все, чтобы создавать в LGEG во всех смыслах безопасную для детей и
                                      взрослых среду. Социальные сети стали неотъемлемой частью нашей жизни, поэтому нам
                                      очень важно, чтобы все одинаково понимали, как «вести себя» в медиа пространстве с
                                      учетом того, что это пространство, которое не всегда возможно отделить от работы и
                                      взаимодействия со своими студентами. По правилам LGEG, мы не размещаем на своих
                                      страницах в социальных сетях фото и видео с нашими учениками и не добавляем в
                                      друзья их или их родителей. Важно понимать, что публичное пространство
                                      предполагает некую форму сдержанного нейтралитета во всех размещаемых в открытых
                                      аккаунтах постах.
                                      <br />
                                      Здесь снова хотим напомнить, что преподаватели – это ролевые модели для студентов,
                                      и нам важна ваша готовность быть этой моделью.
                                      <br />
                                      <em>
                                        В статье ниже Вы можете детальнее ознакомиться с принципами LGEG, касающимися
                                        этого очень важного вопроса:
                                      </em>
                                      <br />
                                      <ul>
                                        <li data-list="bullet" style={{ color: 'rgb(206, 41, 72)' }}>
                                          <em style={{ color: 'rgb(206, 41, 72)' }}>
                                            <u>
                                              <a href="https://doc.clickup.com/2466699/d/h/2b8wb-19321/0e910d029070f98">
                                                Политика LGEG в отношении социальных сетей и онлайн общения со
                                                студентами
                                              </a>
                                            </u>
                                            .
                                          </em>
                                        </li>
                                      </ul>
                                      <span style={{ color: 'rgb(206, 41, 72)' }}>
                                        4.LGEG – это место свободное от обсуждения политики, религии, сексуальной
                                        ориентации и национальной принадлежности.
                                      </span>
                                      <br />
                                      Если студент пытается начать обсуждение подобной темы, Вам как преподавателю
                                      необходимо спокойно, доброжелательно и нейтрально изложить позицию центра.
                                      <br />
                                      <br />
                                      <span style={{ color: 'rgb(206, 41, 72)' }}>
                                        5.Использование английского языка на уроках и в общественных пространствах на
                                        территории центра.
                                      </span>
                                      <br />В LGEG мы вместе создаем для детей уникальную языковую среду, поэтому
                                      преподаватель на уроках использует только английский язык. Мы никогда не переходим
                                      на русский и не прибегаем к переводу. Лучше всего, чтобы дети были уверены в том,
                                      что учитель вообще не говорит и не понимает по-русски. В исключительных случаях,
                                      когда без перехода на русский не обойтись, необходимо напрямую попросить о помощи
                                      ассистента или тьютора, позвав его в класс. <br />
                                      Также мы не пользуемся русским языком в Common Room, в коридорах и в других общих
                                      пространствах, где могут находиться дети.
                                      <br />
                                      <em>Подробнее - в статье про…</em>
                                      <br />
                                      <ul>
                                        <li data-list="bullet" style={{ color: 'rgb(206, 41, 72)' }}>
                                          <em style={{ color: 'rgb(206, 41, 72)' }}>
                                            <u>
                                              <a href="https://doc.clickup.com/2466699/d/h/2b8wb-18306/7ed2ec3ab92461e">
                                                English Speaking Area
                                              </a>
                                            </u>
                                            !
                                          </em>
                                        </li>
                                      </ul>
                                      <span style={{ color: 'rgb(206, 41, 72)' }}>Будьте внимательны!</span> На 3 этаже
                                      у нас учатся студенты нашей билингвальной школы. В понедельник и четверг, а иногда
                                      и в среду можно слышать русскую речь от наших детей. Для этого в начале коридора
                                      всегда стоит табличка, которая обозначает, какой день (русский или английский) у
                                      них сегодня. <br />
                                      <br />
                                      <br />
                                      <span style={{ color: 'rgb(206, 41, 72)' }}>LGEG</span> – это уникальное место,
                                      где студенты окружены талантливыми и увлеченными людьми, которые делают их детство
                                      и взросление незабываемыми, а будущее – блестящим. Эти люди – мы с вами. Вы – уже
                                      часть этой команды или вот-вот ей станете, поэтому нам очень важно, чтобы Вы
                                      разделяли все наши принципы. Надеемся, вместе мы сделаем множество действительно
                                      крутых вещей – мы это умеем!
                                      <br />
                                      <br />
                                      <br />
                                      Искренне Ваши,
                                      <br />
                                      Юля Десятникова и Команда LGEG
                                      <br />
                                      <br />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            {/*/record*/}
            {/*record_mail*/}
            <table
              id="rec626693265"
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                borderSpacing: 0,
                margin: 0,
                border: 0
              }}
              cellPadding={0}
              cellSpacing={0}
              data-record-type={322}
            >
              <tbody>
                <tr>
                  <td style={{ paddingLeft: 15, paddingRight: 15 }}>
                    <table
                      id="recin626693265"
                      className="r"
                      style={{
                        margin: '0 auto',
                        backgroundColor: '#ffffff',
                        borderSpacing: 0,
                        width: 600
                      }}
                      align="center"
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              paddingTop: 15,
                              paddingBottom: 15,
                              paddingLeft: 30,
                              paddingRight: 30
                            }}
                          >
                            <table border={0} cellPadding={0} cellSpacing={0} width="100%" style={{ width: '100%' }}>
                              <tbody>
                                <tr>
                                  <td style={{ width: '100%' }}>
                                    <a href="https://tilda.cc" target="_blank">
                                      <img
                                        width={540}
                                        style={{
                                          display: 'block',
                                          width: '100%',
                                          height: 'auto'
                                        }}
                                        src="https://static.tildacdn.com/tild3966-6437-4330-a435-363734343336/Fotoramio-2.jpg"
                                      />
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            {/*/record*/}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);
