import React, { useState } from 'react';
import { EmailForm } from './EmailForm';
import { EmailFilter } from './EmailFilter';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useHistory } from 'react-router-dom';
import { useLanguageContext } from 'contexts/LanguageContext';


export interface IRecipient {
  id: number;
  name: string;
  surname: string;
  parents?: any[];
}

/**
 * @description Компонент отправки email
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const NewEmail = () => {
  const [strings] = useLanguageContext();
  const [currentPeople, setCurrentPeople] = useState<string>('');
  const [recipientsList, setRecipientsList] = useState<IRecipient[]>([]);
  const [hasRecipientsLoading, setHasRecipientsLoading] = useState<boolean>(false);

  const { goBack } = useHistory();

  const clearRecipients = () => setRecipientsList([]);

  /**
   * @description Удаление юзера из списка
   * @param {number} id
   * @return {void}
   */
  const handleDeleteParticipant = (id: number): void => {
    const filteredList = recipientsList.filter(item => item.id !== id);
    setRecipientsList(filteredList);
  };

  return (
    <Container>
      <StPageHeader title={strings.NEW_EMAIL} onBack={goBack} />
      <EmailFilter
        setRecipientsList={setRecipientsList}
        hasRecipientsLoading={hasRecipientsLoading}
        setHasRecipientsLoading={setHasRecipientsLoading}
        setCurrentPeople={setCurrentPeople}
        currentPeople={currentPeople}
      />
      <EmailForm
        hasRecipientsLoading={hasRecipientsLoading}
        handleDeleteParticipant={handleDeleteParticipant}
        clearRecipients={clearRecipients}
        currentPeople={currentPeople}
        recipientsList={recipientsList}
      />
    </Container>
  );
};

export default { NewEmail };
