import React from 'react';
import { InfoCircleTwoTone, LoadingOutlined } from '@ant-design/icons';
import { Modal, Spin } from 'antd';
import { InfoContainer, LogoContainer } from './styles';
import payKeeperLogo from './img/logo.png';
import { useLanguageContext } from 'contexts/LanguageContext';

interface InvoicePayModalProps {
  hasVisible: boolean;
  onCancel: (hasShow: boolean) => void;
  hasInvoicesPayLinkLoaded: boolean;
  invoicePayLink: string;
}

/**
 * @description Модальное окно оплаты инвойса PayKeeper
 * @param {object} props
 * @return {React.ReactNode}
 */
export const InvoicePayModal = (props: InvoicePayModalProps) => {
  const { hasVisible, onCancel, hasInvoicesPayLinkLoaded, invoicePayLink } = props;
  const [strings] = useLanguageContext();

  const iframeProps = {
    style: { border: 'none' },
    src: invoicePayLink,
    width: 820,
    height: 500
  };

  return (
    (<Modal
      title={strings.PAY_INVOICE}
      style={{ top: 100, textAlign: 'center' }}
      open={hasVisible}
      onCancel={() => onCancel(false)}
      width={820}
      footer={[]}
    >
      {hasInvoicesPayLinkLoaded ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <iframe {...iframeProps}></iframe>
      )}
      <InfoContainer>
        <InfoCircleTwoTone style={{ marginInlineEnd: 10, fontSize: 18 }} />
        <span>{strings.SERVICE_FEE_WILL_BE_ADDED_TO_THE_PAYMENT_SUM}</span>
      </InfoContainer>
      <LogoContainer>
        <img src={payKeeperLogo} alt="paykeeper" />
      </LogoContainer>
    </Modal>)
  );
};

export default { InvoicePayModal };
