import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { Supergroup } from 'types';

export const columns = (showDeleteSuperGroup: any) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (name: string, supergroup: Supergroup) => <Link to={`/supergroup/${supergroup.id}`}>{name}</Link>
  },
  {
    dataIndex: 'id',
    key: 'action',
    width: 70,
    render: (id: number) => <Button icon={<DeleteOutlined />} danger onClick={() => showDeleteSuperGroup(id)} />
  }
];

export default { columns };
