import React, { useEffect, useState } from 'react';
import { Input, Row, Col, Button } from 'antd';
import BreadCrumbs from 'Global/components/Breadcrumbs';
import { OriginalCoursesTable } from './OriginalCourses/Table';
import * as courses from '../Courses/routes';
import { OriginalCourse, OriginalCoursesParams } from 'types/courses';
import { PaginationInfo } from 'types/global';
import { mockPagination } from 'Global/constants';
import { scrollTop } from 'helpers';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useGroupContext } from 'contexts/GroupContext';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { useHistory, useLocation } from 'react-router-dom';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import queryString from 'query-string';

export const ChooseOriginal = () => {
  const [originalCourses, setOriginalCourses] = useState<OriginalCourse[]>([]);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>(mockPagination);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [{ setSelectedOriginalCourse }] = useGroupContext();
  const { push, goBack } = useHistory();
  const location = useLocation();

  const [strings] = useLanguageContext();
  const { locationSearchParams } = useLocationSearchParams();
  const defaultFilter = { isActive: 1, limit: 30, page: 1 };
  const coursesRepository = useCoursesRepository();

  const handleRowClick = (course): void => {
    setSelectedOriginalCourse([course.id]);
    push(courses.BASE_COURSE_GROUP_ADD);
  };

  const fetchOriginalCourses = async (params: OriginalCoursesParams = defaultFilter): Promise<any> => {
    setLoading(true);
    try {
      const {
        data: { items, paginationInfo }
      } = await coursesRepository.getOriginalCourses(params);
      push({ search: queryString.stringify(params) });
      setOriginalCourses(items);
      setPaginationInfo(paginationInfo);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const handleSearchByTitle = async () => {
    await fetchOriginalCourses({ ...locationSearchParams, page: 1, limit: 30, title: inputValue });
    scrollTop();
  };

  const clearSearch = async (): Promise<void> => {
    setInputValue('');
    await fetchOriginalCourses();
  };

  useEffect(() => {
    fetchOriginalCourses();
  }, []);

  return (
    <Container>
      <BreadCrumbs location={location} />
      <StPageHeader onBack={goBack} title={strings.CHOOSE_ORIGINAL_COURSE} />
      <Row gutter={[10, 10]} align="middle" style={{ marginBottom: 20 }}>
        <Col lg={15} xs={24}>
          <Input
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            onPressEnter={() => handleSearchByTitle()}
            placeholder={strings.SEARCH_BY_TITLE}
          />
        </Col>
        <Col lg={6} xs={12}>
          <Button block size="large" type="primary" onClick={() => handleSearchByTitle()}>
            {strings.SEARCH_ORIGINAL_COURSE}
          </Button>
        </Col>
        <Col lg={3} xs={12}>
          <Button block size="large" onClick={clearSearch}>
            {strings.CLEAR}
          </Button>
        </Col>
      </Row>
      <OriginalCoursesTable
        originalCourses={originalCourses}
        paginationInfo={paginationInfo}
        loading={loading}
        fetchOriginalCourses={fetchOriginalCourses}
        handleRowClick={handleRowClick}
      />
    </Container>
  );
};

export default { ChooseOriginal };
