import React, { useState } from 'react';
import { Modal, Input, Button, message, Form, Flex } from 'antd';
import { Global } from 'api/Global';
import { useLanguageContext } from 'contexts/LanguageContext';

interface PrintFileProps {
  hasShowPrintModal: boolean;
  hideModal: () => void;
  attachmentIdToPrint: number | undefined;
}

/**
 * @description Модалка для печати
 * @param {PrintFileProps} props
 * @return {React.ReactNode}
 */
export const PrintFile: React.FC<PrintFileProps> = ({ hideModal, hasShowPrintModal, attachmentIdToPrint }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [strings] = useLanguageContext();

  /**
   * @description Отправка файла на печать
   * @param {React.MouseEvent<HTMLElement, MouseEvent>} e
   * @return {void}
   */
  const handleSubmit = async (values): Promise<void> => {
    try {
      setIsLoading(true);
      const { status } = await Global.printAttachment(values.message, attachmentIdToPrint);
      if (status === 200) {
        message.success(strings.FILE_WAS_SUCCESSFULLY_SENT_FOR_PRINTING);
        form.resetFields();
        hideModal();
      }
    } catch (error) {
      message.error(strings.FILE_WAS_NOT_SENT_TO_PRINT_AN_ERROR_OCCURRED);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal title={strings.FILE_PRINT} open={hasShowPrintModal} onCancel={hideModal} footer={null}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="message">
          <Input.TextArea style={{ height: 200 }} placeholder={strings.YOUR_MESSAGE} />
        </Form.Item>
        <Flex align="end">
          <Button htmlType="submit" key="send" size="large" type="primary" loading={isLoading} disabled={isLoading}>
            {strings.SEND}
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

export default { PrintFile };
