export const TEACHER_MAIN = '/teacher/main';
export const BASE_TEACHER_ORIGINAL_COURSES = '/teacher-original-courses';
export const BASE_GROUP_COURSES = '/teacher-group-courses';
export const SELECTED_ORIGINAL_COURSE = `${BASE_TEACHER_ORIGINAL_COURSES}/:id`;
export const BASE_WORK_PROGRESS = '/teacher-work-progress';
export const SELECTED_WORK_PROGRESS_GROUP = `${BASE_WORK_PROGRESS}/:id`;
export const BASE_FINANCE = '/teacher-finance';
export const BASE_TRAININGS = '/teacher-education';
export const BASE_VIDEO_NEWS = '/teacher-video-news';
export const BASE_GRADING = '/teacher-grading';
export const BASE_PROGRESS_REPORT = '/student-progress';

export default {
  TEACHER_MAIN,
  BASE_TEACHER_ORIGINAL_COURSES,
  SELECTED_ORIGINAL_COURSE,
  BASE_WORK_PROGRESS,
  BASE_FINANCE,
  BASE_TRAININGS,
  BASE_VIDEO_NEWS,
  BASE_GRADING,
  SELECTED_WORK_PROGRESS_GROUP,
  BASE_GROUP_COURSES,
  BASE_PROGRESS_REPORT
};
