import React, { Fragment, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table, DatePicker, Switch as SwitchBtn, Typography } from 'antd';
import { getColumns, getActionsColumn } from 'Admin/People/Families/Family/Finance/Finance/columns';
import { FamilyFinanceItem, FamilyFinanceSummary } from 'types/Family';

const { RangePicker } = DatePicker;
const { Column } = Table;
const { Text } = Typography;

interface PreviousFinancesTableProps {
  id: number; // Family id
  created: string;
  showEditForm: (finance: FamilyFinanceItem) => void;
  showDeleteForm: (finance: FamilyFinanceItem) => void;
  addCompensation: (finance: FamilyFinanceItem) => void;
  fetchFinancePreviousMonth: (start?: Dayjs, end?: Dayjs) => void;
  previousLoaded: boolean;
  first: Dayjs; // Последний день первого месяца
  last: Dayjs; // Последний день предыдущего месяца
  previousFinance: any | FamilyFinanceItem[]; // TODO
  previousFinanceSummary: FamilyFinanceSummary;
  hasAdmin: boolean;
  hasOperationTypeColumn: boolean;
}

/**
 * @description Таблица операций за предыдущие месяца (периоды)
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const PreviousFinancesTable = (props: PreviousFinancesTableProps) => {
  const {
    created,
    showEditForm,
    showDeleteForm,
    addCompensation,
    fetchFinancePreviousMonth,
    previousLoaded,
    first,
    last,
    previousFinance,
    hasAdmin,
    hasOperationTypeColumn
  } = props;

  const [filterDates, setFilterDates] = useState<Dayjs[]>([dayjs(first), dayjs(last)]);
  const [hasHideNullAmont, setHasHideNullAmont] = useState<boolean>(false);

  // Дата создания семьи
  const familyCreatedDate = dayjs(created);

  /**
   * @description Функция для запрета на выбор всех дат, которые были до создания семьи
   * @param {Date} current
   * @return {boolean}
   */
  const disabledDate = (current): boolean => current < familyCreatedDate;

  /**
   * @param {array} dates
   * @return {void}
   */
  const handleChange = (dates): void => setFilterDates(dates);

  const handleFilterPrevious = () => {
    const [start, end] = filterDates;
    fetchFinancePreviousMonth(start, end);
  };

  /**
   * @description После 5 числа каждого месяца у финансовых операций за предыдущий месяц недоступно редактирование
   * @return {boolean}
   */
  const hasAccessActionsColumn = new Date().getDate() < 5;

  /**
   * @description Фильтруем финансы по amount
   * @return {FamilyFinanceItem[]}
   */
  const filteredFinancePreviousMonth = () => {
    if (previousFinance && hasHideNullAmont) {
      return previousFinance.filter((item: FamilyFinanceItem) => item?.amount !== 0);
    } else {
      return previousFinance;
    }
  };

  useEffect(() => {
    fetchFinancePreviousMonth();
  }, []); // eslint-disable-line

  return (
    <Fragment>
      <Row gutter={8} className="no-padding">
        <Col lg={8}>
          <RangePicker defaultValue={[first, last]} onChange={handleChange} disabledDate={disabledDate} />
        </Col>
        <Col lg={4}>
          <Button
            type="primary"
            size="large"
            onClick={handleFilterPrevious}
            icon={<SearchOutlined />}
            disabled={!(filterDates.length !== 0)}
          >
            Filter
          </Button>
        </Col>
        <Col lg={24} xs={24} style={{ margin: '20px 0' }}>
          <SwitchBtn checked={hasHideNullAmont} onChange={checked => setHasHideNullAmont(checked)} />
          <Text style={{ paddingInlineStart: '5px' }}>Hide Null amount</Text>
        </Col>
      </Row>
      <Table dataSource={filteredFinancePreviousMonth()} pagination={false} loading={previousLoaded} rowKey="id">
        {getColumns(hasAdmin, hasOperationTypeColumn)
          .filter(col => col)
          .map(col => (
            <Column key={col.key} {...col} />
          ))}
        {hasAccessActionsColumn &&
          getActionsColumn(showEditForm, showDeleteForm, addCompensation).map(col => <Column {...col} />)}
      </Table>
    </Fragment>
  );
};

export default { PreviousFinancesTable };
