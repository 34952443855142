import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Table, Button, message, Spin, Select, Badge, Flex, Tooltip } from 'antd';
import { Filter, statusOptions } from './Filter';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { Link, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { isEmpty } from 'lodash';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { PaginationInfo } from 'types/global';
import Pagination from 'Global/components/Pagination';
import { BASE_FAMILIES } from 'Global/routes';
import { IStudent, Supergroup } from 'types';
import AvatarWithUserName from 'Global/components/AvatarWithUserName';
import { BASE_SUPER_GROUPS } from 'Admin/Courses/routes';
import {
  IChangeStatusNewReportParams,
  INewReportGetParams,
  INewReportItem,
  ISendProgressReportParams,
  StatusNewReport
} from 'types/Reports';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';
import { DownloadOutlined, EditOutlined, SendOutlined } from '@ant-design/icons';
import { useUserContext } from 'contexts/UserContext';
import { NEW_PROGRESS_REPORTS } from '../routes';
import { downloadSomeFile } from 'helpers';
import dayjs from 'dayjs';
import { dateTimeWithoutSecondFormat } from 'helpers/dates';

const { Column } = Table;
const { Option } = Select;

const getStatusBadge = item => {
  switch (item.value) {
    case StatusNewReport.new:
      return <Badge status="default" text={item.label} style={{ paddingInlineStart: 5 }} />;
    case StatusNewReport.checked:
      return <Badge status="processing" text={item.label} style={{ paddingInlineStart: 5 }} />;
    case StatusNewReport.sent:
      return <Badge status="success" text={item.label} style={{ paddingInlineStart: 5 }} />;
    default:
      return null;
  }
};

export const NewProgressReports = () => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [dataList, setDataList] = useState<INewReportItem[]>([]);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>(null);
  const { goBack, push } = useHistory();
  const defFilterValues = { limit: 50, page: 1, branch: user?.branch?.id };
  const reportsRepository = useProgressReportsRepository();
  const { locationSearchParams } = useLocationSearchParams();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = selectedRowKeys => setSelectedRowKeys(selectedRowKeys);

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onChange: onSelectChange,
      getCheckboxProps: record => ({
        disabled: record.status !== StatusNewReport.checked
      })
    }),
    [selectedRowKeys, onSelectChange]
  );

  const handlerChangeStatus = useCallback(
    async (newStatus: StatusNewReport, record: INewReportItem) => {
      setHasLoading(true);
      try {
        const params: IChangeStatusNewReportParams = {
          status: newStatus,
          student: record?.student?.id,
          trimester: record?.trimester?.id
        };
        await reportsRepository.changeStatusNewProgressReport(params);
        await getDataForNewProgressReports(locationSearchParams);
        message.success(strings.STATUS_SUCCESSFULLY_EDITED, 2);
      } catch {
        message.error(strings.SORRY_ERROR, 2);
      } finally {
        setHasLoading(false);
      }
    },
    [locationSearchParams]
  );

  const downloadReportPdf = useCallback(async (reportId: number): Promise<any> => {
    setHasLoading(true);
    try {
      await downloadSomeFile(`/api/v1/progress-reports-new/${reportId}/pdf`, null, null, null, true);
    } catch {
    } finally {
      setHasLoading(false);
    }
  }, []);

  const handleBulkSelect = () => {
    handleSendReports({ reports: selectedRowKeys });
  };

  const handleSendReports = async (params: ISendProgressReportParams): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await reportsRepository.sendProgressReportPDF(params);
      message.success(strings.SUCCESSFULLY_SENT, 2);
      if (data?.errors?.length) {
        const errorsContent = data?.errors.map((error, index) => <p key={index}>{error}</p>);
        message.error(errorsContent, 3);
      }
      setSelectedRowKeys([]);
      await getDataForNewProgressReports(locationSearchParams);
    } catch {
      message.error(strings.SEND_ERROR_TRY_AGAIN_LATER, 2);
    } finally {
      setHasLoading(false);
    }
  };

  const getDataForNewProgressReports = async (values: INewReportGetParams): Promise<any> => {
    const { page } = values;
    const params = { ...values, limit: 50, page: page || 1 };
    setHasLoading(true);
    push({ search: queryString.stringify(params) });
    try {
      const {
        data: { items, paginationInfo }
      } = await reportsRepository.getStudentsForNewReport(params);
      setDataList(items);
      setPaginationInfo(paginationInfo);
    } catch (e) {
      message.error(e.message);
    } finally {
      setHasLoading(false);
    }
  };

  const handleChangePage = useCallback(
    async (page: number) => {
      await getDataForNewProgressReports({ ...locationSearchParams, page });
    },
    [locationSearchParams]
  );

  const getColumns = useMemo(
    () => [
      {
        title: strings.STUDENTS,
        dataIndex: 'student',
        key: 'student',
        render: (student: IStudent) => {
          return (
            <AvatarWithUserName
              name={student?.user?.name}
              surname={student?.user?.surname}
              link={`${BASE_FAMILIES}/${student?.familyId}/student/${student?.id}`}
              photo={student?.mainPhoto}
            />
          );
        }
      },
      {
        title: strings.SUPERGROUPS,
        dataIndex: 'supergroups',
        key: 'supergroups',
        render: (supergroups: Supergroup[]) => {
          return supergroups?.map(item => (
            <>
              <Link to={`${BASE_SUPER_GROUPS}/${item?.id}`}>{item?.name}</Link>
              <br />
            </>
          ));
        }
      },
      {
        title: strings.NUMBER_OF_COMMENTS,
        key: 'countComments',
        dataIndex: 'countComments'
      },
      {
        title: strings.STATUS,
        key: 'status',
        dataIndex: 'status',
        render: (status: StatusNewReport, record) => (
          <Select
            disabled={status === StatusNewReport.sent}
            value={status}
            onChange={val => handlerChangeStatus(val, record)}
          >
            {statusOptions.map(item => (
              <Option value={item.value} key={item.value} disabled={item.value === StatusNewReport.sent}>
                {getStatusBadge(item)}
              </Option>
            ))}
          </Select>
        )
      },
      {
        key: 'action',
        render: (record: INewReportItem) => {
          const { student, pdf, trimester, status, sentAt, sentByUser } = record;
          const hasPdf = pdf !== null;
          const sendDate = sentAt ? `${dayjs(sentAt).format(dateTimeWithoutSecondFormat)}` : '';
          const sendUser = sentByUser ? `by ${sentByUser?.surname} ${sentByUser?.name}` : '';
          const sendTitle = status === StatusNewReport.sent ? `Sent ${sendDate} ${sendUser}` : strings.SEND;
          return (
            <Flex gap={5}>
              <Tooltip title={hasPdf ? strings.EDITING_IS_NOT_AVAILABLE_PDF_REPORT_IS_READY : strings.EDIT}>
                <Link to={`${NEW_PROGRESS_REPORTS}/student/${student?.id}/semester/${trimester?.id}`}>
                  <Button icon={<EditOutlined />} disabled={hasPdf} />
                </Link>
              </Tooltip>
              <Tooltip title={!hasPdf ? strings.PDF_REPORTS_ARE_NOT_GENERATED_YET : strings.DOWNLOAD}>
                <Button icon={<DownloadOutlined />} disabled={!hasPdf} onClick={() => downloadReportPdf(pdf?.id)} />
              </Tooltip>
              <Tooltip title={!hasPdf ? strings.PDF_REPORTS_ARE_NOT_GENERATED_YET : sendTitle}>
                <Button
                  icon={<SendOutlined />}
                  disabled={!hasPdf}
                  onClick={() => handleSendReports({ reports: [pdf?.id] })}
                />
              </Tooltip>
            </Flex>
          );
        }
      }
    ],
    []
  );

  useEffect(() => {
    if (isEmpty(locationSearchParams)) {
      getDataForNewProgressReports(defFilterValues);
    } else {
      getDataForNewProgressReports({ ...locationSearchParams, branch: user?.branch?.id });
    }
  }, [user]);

  return (
    <Container>
      <StPageHeader onBack={goBack} title={strings.NEW_PROGRESS_REPORT}>
        <Filter
          onFilter={getDataForNewProgressReports}
          defValues={defFilterValues}
          selectedRowKeys={selectedRowKeys}
          handleBulkSelect={handleBulkSelect}
        />
      </StPageHeader>
      <Spin spinning={hasLoading}>
        <Row gutter={[10, 10]}>
          <Table
            dataSource={dataList}
            pagination={false}
            rowSelection={rowSelection}
            rowKey={record => record.student?.id}
          >
            {getColumns.map(col => (
              <Column key={col.key} {...col} />
            ))}
          </Table>
          {paginationInfo && (
            <Pagination
              count={paginationInfo?.pageCount}
              current={paginationInfo?.current}
              pageChange={handleChangePage}
            />
          )}
        </Row>
      </Spin>
    </Container>
  );
};

export default { NewProgressReports };
