import React, { useEffect, useMemo, useState } from 'react';
import { Tabs, Button, Modal, message, Descriptions, Flex, Tag, Tooltip } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { Loader } from 'Global/components/Loader/Loader';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import Breadcrumbs from 'Global/components/Breadcrumbs';
import { Families } from 'api';
import dayjs from 'dayjs';
import { dateFormat } from 'helpers/dates';
import { BASE_FAMILIES } from 'Global/routes';
import Avatar from 'Global/components/Avatar';
import { IParent } from 'types';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useFamilyRepository } from 'repos/FamilyRepository';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { match } from 'react-router';
import { StDescription } from '../Student/Main';
import { LoginOutlined } from '@ant-design/icons';
import { switchUserModal } from '../../../../helpers/switchUser';
import { useUserContext } from '../../../../contexts/UserContext';
const { TabPane } = Tabs;

/**
 * @description Страница родителя
 * @param {PayerProps} props
 * @return {React.ReactNode}
 */
export const Payer = () => {
  const [{ confirm }, contextHolder] = Modal.useModal();
  const [parent, setParent] = useState<IParent>();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const {
    params: { userId }
  }: match<{ userId: string }> = useRouteMatch();

  const { goBack } = useHistory();
  const location = useLocation();

  const [strings] = useLanguageContext();
  const familyRepository = useFamilyRepository();

  const [user] = useUserContext();
  const hasTopManager = user?.hasRoles?.topManager;
  const hasBranchDirector = user?.hasRoles?.branchDirector;
  const hasExtraAccess = hasTopManager || hasBranchDirector;

  /**
   * @description Получение родителя по id
   * @return {Promise<any>}
   */
  const fetchParentById = async () => {
    setHasLoading(true);
    try {
      const { data } = await familyRepository.getParentById(userId);
      setParent(data);
    } finally {
      setHasLoading(false);
    }
  };

  /**
   * @description Удаление родителя
   */
  const deleteParent = () => {
    confirm({
      title: strings.DO_YOU_WANT_TO_DELETE_THIS_PARENT,
      okText: 'Delete',
      okType: 'danger',
      async onOk() {
        try {
          await Families.deleteParent(userId);
          message.success(strings.PARENT_DELETED_SUCCESSFULLY);
          goBack();
        } catch {}
      }
    });
  };

  const {
    mainPhoto,
    subscribed,
    profession,
    address,
    comment,
    familyId,
    passport,
    smsSubscribed,
    isPayer,
    subscribeToHomework,
    legalEntity,
    bankAccountNumber,
    bankName,
    bankCode,
    tags,
    loginAs
  } = parent || {};
  const { name, surname, patronymic, phone, email, birthDate, branch } = parent?.user || {};

  useEffect(() => {
    fetchParentById();
  }, [userId]);

  const getTitle = useMemo(() => {
    const fullName = `${name || ''} ${surname || ''}`;
    return (
      <Flex gap={10} align="center">
        {fullName}
        {loginAs && hasExtraAccess && (
          <Tooltip title={`${strings.LOGIN_AS} ${fullName}`}>
            <Button size="small" icon={<LoginOutlined />} onClick={() => switchUserModal(loginAs, confirm)} />
          </Tooltip>
        )}
      </Flex>
    );
  }, [parent]);

  return (
    <Loader spinning={hasLoading}>
      <Container>
        {contextHolder}
        <Breadcrumbs location={location} />
        <StPageHeader
          onBack={goBack}
          title={getTitle}
          extra={[
            <Button onClick={deleteParent} danger style={{ marginInlineEnd: 8 }}>
              {strings.DELETE}
            </Button>,
            <Button type="primary">
              <Link to={`${BASE_FAMILIES}/${familyId}/parent/${userId}/edit`}>{strings.EDIT}</Link>
            </Button>
          ]}
        />
        <Tabs defaultActiveKey="1">
          <TabPane tab={strings.MAIN_INFORMATION} key="1">
            <Flex wrap={isMobile ? 'wrap' : 'nowrap'} gap={isMobile ? 20 : 80}>
              <Avatar photo={mainPhoto} scale={3} size={80} userName={name} userSurname={surname} />
              <StDescription>
                <Descriptions.Item label={strings.ID}>{userId}</Descriptions.Item>
                <Descriptions.Item label={strings.SURNAME}>{surname}</Descriptions.Item>
                <Descriptions.Item label={strings.NAME}>{name}</Descriptions.Item>
                {Boolean(patronymic && patronymic !== '') && (
                  <Descriptions.Item label={strings.FATHERS_NAME}>{patronymic}</Descriptions.Item>
                )}
                {phone && (
                  <Descriptions.Item label={strings.MOBILE}>
                    <span key={phone}>
                      <a href={`tel:${phone}`} style={{ margin: '0 5px' }}>
                        {phone?.replace(/ /g, '')}
                      </a>
                    </span>
                  </Descriptions.Item>
                )}
                {email && (
                  <Descriptions.Item label={strings.EMAIL}>
                    <span key={phone}>
                      <a href={`mailto:${email}`}> {email}</a>
                    </span>
                  </Descriptions.Item>
                )}
                {Boolean(profession && profession !== '') && (
                  <Descriptions.Item label={strings.OCCUPATION}>{profession}</Descriptions.Item>
                )}
                {Boolean(passport && passport !== '') && (
                  <Descriptions.Item label={strings.PASSPORT}>{passport}</Descriptions.Item>
                )}
                {birthDate && (
                  <Descriptions.Item label={strings.DATE_OF_BIRTH}>
                    {birthDate && dayjs(birthDate).format(dateFormat)}
                  </Descriptions.Item>
                )}
                {Boolean(address && address !== '') && (
                  <Descriptions.Item label={strings.ADDRESS}>{address}</Descriptions.Item>
                )}
                {Boolean(bankAccountNumber && bankAccountNumber !== '') && (
                  <Descriptions.Item label={strings.ACCOUNT_NUMBER}>{bankAccountNumber}</Descriptions.Item>
                )}
                {Boolean(bankCode && bankCode !== '') && (
                  <Descriptions.Item label={strings.BANK_CODE}>{bankCode}</Descriptions.Item>
                )}
                {Boolean(bankName && bankName !== '') && (
                  <Descriptions.Item label={strings.BANK}>{bankName}</Descriptions.Item>
                )}
                {tags?.isNotEmpty() && (
                  <Descriptions.Item label={strings.TAGS}>
                    <Flex wrap="wrap" gap={5}>
                      {tags?.map(({ id, name }) => (
                        <Tag color="blue" key={id} style={{ margin: 0 }}>
                          {name}
                        </Tag>
                      ))}
                    </Flex>
                  </Descriptions.Item>
                )}
                <Descriptions.Item label={strings.EMAIL_SUBSCRIPTION}>{subscribed ? 'yes' : 'no'}</Descriptions.Item>
                <Descriptions.Item label={strings.SMS_NOTIFICATIONS}>{smsSubscribed ? 'yes' : 'no'}</Descriptions.Item>
                {branch?.id === 1 && (
                  <Descriptions.Item label={strings.LGEG_WEEKLY_NEWSLETTER}>
                    {subscribeToHomework ? 'yes' : 'no'}
                  </Descriptions.Item>
                )}
                {branch?.id === 5 && (
                  <Descriptions.Item label={strings.PAY_AS_LEGAL_ENTITY}>
                    {legalEntity ? 'yes' : 'no'}
                  </Descriptions.Item>
                )}
                <Descriptions.Item label={strings.PAYER}>{isPayer ? 'yes' : 'no'}</Descriptions.Item>
                {comment && comment !== '' && <Descriptions.Item label={strings.COMMENT}>{comment}</Descriptions.Item>}
              </StDescription>
            </Flex>
          </TabPane>
          <TabPane tab={strings.COLLABORATION_HISTORY} key="2" disabled>
            {strings.COLLABORATION_HISTORY}
          </TabPane>
        </Tabs>
      </Container>
    </Loader>
  );
};
