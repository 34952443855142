import React, { useEffect, useState } from 'react';
import { ScheduleOutlined } from '@ant-design/icons';
import { Empty, Alert, Skeleton } from 'antd';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { Link } from 'react-router-dom';
import { Lessons } from 'Student&Parent/Main/styles';
import { UpcomingLessonItem } from './UpcomingLessonItem';
import { useUserContext } from 'contexts/UserContext';
import { useFamilyContext } from 'contexts/FamilyContext';
import { Schedule } from 'api/Schedule';
import ScheduleService from 'helpers/services/ScheduleService';
import dayjs from 'dayjs';
import { useLanguageContext } from 'contexts/LanguageContext';
import { StTitleHomework } from '../index';
import { StCard, StPageHeader } from 'Global/GlobalStyle';
import { SCHEDULE } from 'Global/modules/Schedule/routes';

const scheduleService = new ScheduleService();

const SLOTS_LIMIT = 9;

export function UpcomingLessons({ studentId }: { studentId: number }) {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const [hasLoadSlots, setHasLoadSlots] = useState<boolean>(false);
  const [slots, setSlots] = useState([]);
  const [, hasLoadFamily] = useFamilyContext();

  const getUpcomingLessonsList = () => {
    if (user?.hasRoles?.parent && slots.length === 0) {
      return (
        <Alert
          style={{ margin: '15px 0' }}
          message={strings.THE_CHILD_HAS_NOT_YET_ENROLLED_IN_EVENTS}
          type="info"
          showIcon
        />
      );
    }
    return (
      <>
        <Lessons>
          {slots.length !== 0 && slots.map((sl, i) => <UpcomingLessonItem key={sl.id || i} slot={sl} />)}
        </Lessons>
      </>
    );
  };

  useEffect(() => {
    setHasLoadSlots(true);
    if (user) {
      (async () => {
        try {
          if (user?.hasRoles?.student) {
            const {
              data: { slots }
            } = await Schedule.getSlots({
              ...scheduleService.getWeekDates()
            });
            setSlots(slots.sort((a, b) => dayjs(a?.startAt).diff(dayjs(b?.startAt))).slice(0, SLOTS_LIMIT));
          }
          if (user?.hasRoles?.parent) {
            const params = {
              ...scheduleService.getWeekDates(),
              students: [studentId],
              branch: user?.branch?.id
            };
            const {
              data: { slots }
            } = await Schedule.getSlots(params);

            setSlots(slots.sort((a, b) => dayjs(a?.startAt).diff(dayjs(b?.startAt))).slice(0, SLOTS_LIMIT));
          }
          setHasLoadSlots(false);
        } catch (error) {
          setHasLoadSlots(false);
        }
      })();
    }
  }, [user]);

  if (hasLoadSlots) {
    return <Skeleton active={true} />;
  }

  if (slots.isEmpty() && !hasLoadSlots && !hasLoadFamily) {
    return (
      <ErrorBoundary>
        <StCard>
          <Empty description={strings.NO_LESSONS} style={{ minHeight: 210 }} />
        </StCard>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <StCard>
        <StPageHeader
          title={<StTitleHomework>{strings.UPCOMING_LESSONS}</StTitleHomework>}
          extra={
            <Link to={SCHEDULE}>
              <ScheduleOutlined style={{ marginInlineEnd: 5 }} />
              {strings.SCHEDULE}
            </Link>
          }
        />
        {getUpcomingLessonsList()}
      </StCard>
    </ErrorBoundary>
  );
}
