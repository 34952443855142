import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Table, Button, message, Spin, Tag, Tooltip, Flex } from 'antd';
import { Filter } from './Filter';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { Link, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { first, isEmpty } from 'lodash';
import {
  AssessmentStatus,
  IAssessmentCalendarData,
  IAssessmentCalendarHeader,
  IAssessmentCalendarParams,
  IAssessmentDataItem
} from 'types/accessment';
import { useAssessmentsRepository } from 'repos/AssessmentsRepository';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import dayjs from 'dayjs';
import { dateFormat, dateFormatForLesson, dateMonthFormat } from 'helpers/dates';
import { Teacher } from 'types/Teachers/teacher';
import { BASE_TEACHERS } from 'Admin/People/Teachers/routes';
import { LessonModal } from './LessonModal';
import { BASE_SUMMARY_TABLE } from '../routes';
import { PaginationInfo } from 'types/global';
import Pagination from 'Global/components/Pagination';
import styled from 'styled-components';

const { Column } = Table;

const StTag = styled(Tag)`
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50px;
`;

//1st year active, 2nd year active, Long-term active
const INITIAL_STATUS_IDS = [13, 14, 19];
interface IAssesmentCalendarByTeacherParams {
  dateFrom: string;
  dateTo: string;
  teacher: number;
}

export const SummaryCalendar = () => {
  const [strings] = useLanguageContext();
  const [hasLessonsModal, setHasLessonsModal] = useState(false);
  const [modalProps, setModalProps] = useState<IAssesmentCalendarByTeacherParams>(null);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [assessmentsStatuses, setAssessmentsStatuses] = useState<AssessmentStatus[]>([]);
  const [assessmentCalendarData, setAssessmentCalendarData] = useState<IAssessmentCalendarData[]>([]);
  const [assessmentCalendarHeader, setAssessmentCalendarHeader] = useState<IAssessmentCalendarHeader[]>([]);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>(null);
  const { goBack, push } = useHistory();
  const defFilterValues = { teacherStatus: INITIAL_STATUS_IDS, limit: 50, page: 1 };
  const assessmentsRepository = useAssessmentsRepository();
  const { locationSearchParams } = useLocationSearchParams();

  const hideLessonsModal = () => {
    setModalProps(null);
    setHasLessonsModal(false);
  };

  const openLessonsModal = (props: IAssesmentCalendarByTeacherParams) => {
    setModalProps(props);
    setHasLessonsModal(true);
  };

  const getSammaryCalendar = async (params: IAssessmentCalendarParams): Promise<any> => {
    setHasLoading(true);
    try {
      const {
        data: { data, header, paginationInfo }
      } = await assessmentsRepository.getAssessmentCalendar(params);
      setAssessmentCalendarData(data);
      setAssessmentCalendarHeader(header);
      setPaginationInfo(paginationInfo);
      push({ search: queryString.stringify(params, { arrayFormat: 'bracket' }) });
    } catch (e) {
      message.error(e.message);
    } finally {
      setHasLoading(false);
    }
  };

  const handleChangePage = async (page: number) => {
    await getSammaryCalendar({ ...locationSearchParams, page });
  };

  const handlePrev = async () => {
    if (assessmentCalendarHeader.isNotEmpty()) {
      const dateTo = first(assessmentCalendarHeader).start;
      await getSammaryCalendar({ ...locationSearchParams, dateTo });
    }
  };

  const getColumns = [
    {
      title: strings.TEACHER,
      key: 'teacher',
      dataIndex: 'teacher',
      render: (teacher: Teacher) => (
        <Link to={`${BASE_TEACHERS}/${teacher.id}`}>{`${teacher.user.surname} ${teacher.user.name}`}</Link>
      ),
      width: 200,
      fixed: true
    },
    {
      title: strings.CLOSED_ALL_ASSESSMENT_LESSONS,
      key: 'countClosed',
      dataIndex: 'countClosed',
      width: 140,
      fixed: true,
      align: 'center' as 'center',
      render: (countClosed, record: IAssessmentCalendarData) => `${countClosed}/${record.countAll}`
    }
  ];

  const getMutableColumns = () => {
    const clms = assessmentCalendarHeader.map((item: IAssessmentCalendarHeader, index) => {
      const weekDates = `${dayjs(item.start).format(dateMonthFormat)}-${dayjs(item.end).format(dateMonthFormat)}`;
      return {
        title: (
          <>
            <p>{`week ${item.weekNumber}`}</p>
            <p style={{ fontSize: 12, opacity: 0.8 }}>
              {weekDates}
              <br />
              {item.count} {item.count === 1 ? strings.LESSON_SMALL : strings.LESSONS_SMALL}
            </p>
          </>
        ),
        key: 'data',
        width: 120,
        dataIndex: 'data',
        align: 'center' as 'center',
        render: (data: IAssessmentDataItem[][], record: IAssessmentCalendarData) => {
          const assessmentData = data[index];
          const modalParams = { dateFrom: item.start, dateTo: item.end, teacher: record.teacher.id };
          if (assessmentData === null) {
            return (
              <Flex justify="center">
                <Tooltip title={weekDates}>
                  <Button
                    size="small"
                    shape="circle"
                    icon={<PlusOutlined />}
                    onClick={() => openLessonsModal(modalParams)}
                  />
                </Tooltip>
              </Flex>
            );
          }
          const tags = assessmentData?.map((item: IAssessmentDataItem) => {
            const color = item?.assessment?.status?.color;
            const statusColor = color?.match('#') ? color : '#' + color;
            const subjectTooltip = (
              <>
                <p>
                  {strings.SUBJECT}: {item?.subject.name}
                  <br />
                  {strings.ROOM}: {item.room.name}
                  <br />
                  {strings.GROUP}: {item.group.groupName}
                  <br />
                  {strings.DATE}: {dayjs(item?.slot.start).format(dateFormat)}{' '}
                  {dayjs(item?.slot.start).format(dateFormatForLesson)}-
                  {dayjs(item?.slot.end).format(dateFormatForLesson)}
                </p>
              </>
            );
            return (
              <Tooltip title={subjectTooltip}>
                <Link to={`${BASE_SUMMARY_TABLE}/${item.id}`} style={{ lineHeight: 0 }}>
                  <StTag color={statusColor}>{item?.subject.name}</StTag>
                </Link>
              </Tooltip>
            );
          });
          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>{tags}</div>
              <Button
                size="small"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => openLessonsModal(modalParams)}
              />
            </div>
          );
        }
      };
    });
    return clms;
  };
  /**
   * @description Получение AssessmentsStatuses
   * @return {Promise<any>}
   */
  const getAssessmentsStatuses = async (): Promise<any> => {
    try {
      const { data } = await assessmentsRepository.getAssessmentStatuses();
      const activeAssessmentStatuses = data.filter((status: AssessmentStatus) => status.active);
      setAssessmentsStatuses(activeAssessmentStatuses);
    } catch {
      setAssessmentsStatuses([]);
    }
  };

  useEffect(() => {
    getAssessmentsStatuses();
    if (isEmpty(locationSearchParams)) {
      getSammaryCalendar(defFilterValues);
    } else {
      getSammaryCalendar({ ...locationSearchParams, limit: 50 });
    }
  }, []);

  return (
    <Container>
      {modalProps && (
        <LessonModal
          {...modalProps}
          hideModal={hideLessonsModal}
          hasShowModal={hasLessonsModal}
          refetchTable={getSammaryCalendar}
        />
      )}
      <StPageHeader onBack={goBack} title={strings.SUMMARY_CALENDAR}>
        <Filter
          onFilter={getSammaryCalendar}
          defFilterValues={defFilterValues}
          assessmentsStatuses={assessmentsStatuses}
        />
      </StPageHeader>
      <Row gutter={[10, 10]} justify="space-between" style={{ marginBottom: 10 }}>
        <Button icon={<ArrowLeftOutlined />} onClick={handlePrev}>
          {strings.PREVIOUS_ASSESSMENTS}
        </Button>
        <div>
          {assessmentsStatuses.map((status: AssessmentStatus) => {
            const statusColor = status.color.match('#') ? status.color : '#' + status.color;
            return <Tag color={statusColor}>{status.name}</Tag>;
          })}
        </div>
      </Row>
      <Spin spinning={hasLoading}>
        <Row gutter={[10, 10]}>
          <Table
            scroll={{ x: '100%' }}
            dataSource={assessmentCalendarData}
            tableLayout="fixed"
            pagination={false}
            bordered
          >
            {getColumns.map(col => (
              <Column key={col.key} {...col} />
            ))}
            {getMutableColumns().map(col => (
              <Column key={col.key} {...col} />
            ))}
          </Table>
          {paginationInfo && (
            <Pagination
              count={paginationInfo?.pageCount}
              current={paginationInfo?.current}
              pageChange={handleChangePage}
            />
          )}
        </Row>
      </Spin>
    </Container>
  );
};

export default { SummaryCalendar };
