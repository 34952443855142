import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select, Button, Col, Row } from 'antd';
import { TutorSelect } from 'Global/components/FormComponentsCompatible/Selects/TutorSelect';
import { Branch, IParent, Status } from 'types';
import { Supergroup } from 'types/education';
import { filterSelect } from 'helpers/filterSelect';
import { ActualGroup } from 'types/courses';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { useMediaQuery } from 'react-responsive';
import { useTeacherRepository } from 'repos/TeacherRepository';
import { useAdminRepository } from 'repos/AdminRepository';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useStudentRepository } from 'repos/StudentRepository';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useFamilyRepository } from 'repos/FamilyRepository';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { IRecipient } from './index';

const { Option } = Select;

interface SmsFilterProps extends FormComponentProps {
  setRecipientsList: (value: IRecipient[]) => void;
  setCurrentPeople: (value: string) => void;
  currentPeople: string;
  hasRecipientsLoading: boolean;
  setHasRecipientsLoading: (val: boolean) => void;
}

/**
 * @description Компонент отправки смс
 * @param {object} props - component props
 */
const SmsFilterTemplate: React.FC<SmsFilterProps> = ({
  setRecipientsList,
  currentPeople,
  setCurrentPeople,
  hasRecipientsLoading,
  setHasRecipientsLoading,
  form: { getFieldDecorator, validateFields, resetFields }
}) => {
  const [strings] = useLanguageContext();
  const [superGroups, setSuperGroups] = useState<Supergroup[]>([]);
  const [statuses, setStatuses] = useState<Status[]>([]);
  const [groups, setGroups] = useState<ActualGroup[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<number>(null);
  const [hasSuperGroupsLoading, setHasSuperGroupsLoading] = useState<boolean>(false);
  const [hasCourseGroupsLoading, setHasCourseGroupsLoading] = useState<boolean>(false);
  const coursesRepository = useCoursesRepository();
  const teacherRepository = useTeacherRepository();
  const adminRepository = useAdminRepository();
  const { branches, familyStatuses, childStatuses, teacherStatuses } = useGlobalCollectionsContext();
  const studentRepository = useStudentRepository();
  const familyRepository = useFamilyRepository();

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const clearRecipients = () => setRecipientsList([]);

  const hasParent = currentPeople === 'parents';
  const hasStudent = currentPeople === 'students';
  const hasTeacher = currentPeople === 'teachers';
  const CLEAR_FIELDS = ['statuses', 'supergroup', 'tutor', 'groups'];

  const handleGetRecipients = (): void => {
    validateFields(async (errors, values) => {
      if (isEmpty(errors)) {
        setHasRecipientsLoading(true);
        if (currentPeople === 'students') {
          await getStudentRecipients(values);
        } else if (currentPeople === 'parents') {
          await getParentRecipients(values);
        } else if (currentPeople === 'teachers') {
          await getTeacherRecipients(values);
        } else {
          await getAdminRecipients(values);
        }
      }
    });
  };

  const handlePeopleChange = (people: string): void => {
    setCurrentPeople(people);
    resetFields([...CLEAR_FIELDS]);
    clearRecipients();
    if (people === 'students') {
      setStatuses(childStatuses);
    } else if (people === 'parents') {
      setStatuses(familyStatuses);
    } else if (people === 'teachers') {
      setStatuses(teacherStatuses);
    }
  };

  /**hasRecipientsLoading
   * @description Список админов
   * @param {object} params
   * @return {Promise<any>}
   */
  const getAdminRecipients = async (params): Promise<any> => {
    try {
      const {
        data: { admins }
      } = await adminRepository.getAllAdmins({ ...params, isActive: 1 });
      const recipients = admins
        .filter(recipient => recipient?.user?.phone)
        .map(item => {
          return { id: item.id, name: item?.user?.name, surname: item?.user?.surname };
        });
      setRecipientsList(recipients);
    } catch {
      setRecipientsList([]);
    } finally {
      setHasRecipientsLoading(false);
    }
  };

  /**
   * @description Список учителей
   * @param {object} params
   * @return {Promise<any>}
   */
  const getTeacherRecipients = async (params): Promise<any> => {
    const { groups } = params;
    const mainTeacherParam = !groups || (groups && groups.isEmpty()) ? { mainTeacher: 1 } : {};
    try {
      const {
        data: { items }
      } = await teacherRepository.getTeachers({ ...params, ...mainTeacherParam, limit: 1000 });
      const recipients = items
        .filter(recipient => recipient.user.phone)
        .map(item => {
          return { id: item.id, name: item.user.name, surname: item.user.surname };
        });
      setRecipientsList(recipients);
    } catch {
      setRecipientsList([]);
    } finally {
      setHasRecipientsLoading(false);
    }
  };

  /**
   * @description Список родителей
   * @param {object} params
   * @return {Promise<any>}
   */
  const getParentRecipients = async (params): Promise<any> => {
    try {
      const { data } = await familyRepository.getParentsByFilter(params);
      const recipients = data
        .filter((recipient: IParent) => recipient?.user?.phone)
        .map(item => {
          return {
            id: item.id,
            name: item?.user?.name,
            surname: item?.user?.surname,
            smsSubscribed: item?.smsSubscribed
          };
        });
      setRecipientsList(recipients);
    } catch {
      setRecipientsList([]);
    } finally {
      setHasRecipientsLoading(false);
    }
  };

  /**
   * @description Список студентов
   * @param {object} params
   * @return {Promise<any>}
   */
  const getStudentRecipients = async (params): Promise<any> => {
    try {
      const { data } = await studentRepository.getStudents({ ...params, withParents: true });
      const recipients = data
        .filter(recipient => recipient.user.phone)
        .map(item => {
          return { id: item.id, name: item.user.name, surname: item.user.surname, parents: item.parents };
        });
      setRecipientsList(recipients);
    } catch {
      setRecipientsList([]);
    } finally {
      setHasRecipientsLoading(false);
    }
  };

  /**
   * @description Список SuperGroups
   * @return {Promise<any>}
   */
  const getSuperGroupsByBranch = async (): Promise<any> => {
    setHasSuperGroupsLoading(true);
    try {
      const { data } = await coursesRepository.getSuperGroups({ branch: selectedBranch, byStages: 0 });
      setSuperGroups(data);
    } catch {
    } finally {
      setHasSuperGroupsLoading(false);
    }
  };

  /**
   * @description Список CourseGroups
   * @return {Promise<any>}
   */
  const getCourseGroupsByBranch = async (): Promise<any> => {
    //задаем большой лимит, чтобы получить все страницы
    const params = { branch: selectedBranch, active: 1, limit: 2000 };
    setHasCourseGroupsLoading(true);
    try {
      const {
        data: { items }
      } = await coursesRepository.getCourseGroups(params);
      const sortingGroups = items.sort((a, b) => a.groupName.localeCompare(b.groupName));
      setGroups(sortingGroups);
    } finally {
      setHasCourseGroupsLoading(false);
    }
  };

  const handleChangeBranch = (branchId: number): void => {
    resetFields(['groups', 'supergroup']);
    setSelectedBranch(branchId);
  };

  useEffect(() => {
    if (selectedBranch) {
      if (hasStudent || hasParent) {
        getSuperGroupsByBranch();
      }
      if (hasTeacher) {
        getCourseGroupsByBranch();
      }
    }
  }, [selectedBranch, currentPeople]);

  return (
    <Form layout="vertical">
      <Row gutter={[10, 10]}>
        <Col lg={4}>
          <MyFormItem label={strings.BRANCH}>
            {getFieldDecorator('branch', {
              rules: [{ required: true }]
            })(
              <Select placeholder={strings.BRANCH} onChange={handleChangeBranch}>
                {branches.map((branch: Branch) => (
                  <Option value={branch.id} key={branch.id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            )}
          </MyFormItem>
        </Col>
        <Col lg={4}>
          <MyFormItem label={strings.PEOPLE}>
            {getFieldDecorator('people', {
              rules: [{ required: true }]
            })(
              <Select placeholder={strings.PEOPLE} onChange={handlePeopleChange}>
                <Option value="students">{strings.STUDENT}</Option>
                <Option value="parents">{strings.PARENT}</Option>
                <Option value="teachers">{strings.TEACHER}</Option>
                <Option value="admins">{strings.ADMINISTRATOR}</Option>
              </Select>
            )}
          </MyFormItem>
        </Col>
        {selectedBranch && (hasStudent || hasParent || hasTeacher) && (
          <Col lg={4}>
            <MyFormItem label={strings.STATUSES}>
              {getFieldDecorator('statuses', {
                rules: [{ required: false }]
              })(
                <Select
                  size="large"
                  style={{ width: '100%' }}
                  placeholder={strings.STATUS}
                  mode="multiple"
                  maxTagCount={2}
                >
                  {statuses.map((status: Status) => (
                    <Option value={String(status.id)} key={status.id}>
                      {status.name}
                    </Option>
                  ))}
                </Select>
              )}
            </MyFormItem>
          </Col>
        )}
        {selectedBranch && (hasStudent || hasParent) && (
          <Col lg={4}>
            <TutorSelect label={strings.TUTORS} mode="multiple" name="tutor" getFieldDecorator={getFieldDecorator} />
          </Col>
        )}
        {selectedBranch && (hasStudent || hasParent) && (
          <Col lg={4}>
            <MyFormItem label={strings.SUPERGROUPS}>
              {getFieldDecorator('supergroup', {
                rules: [{ required: false }]
              })(
                <Select
                  loading={hasSuperGroupsLoading}
                  placeholder={strings.SUPERGROUPS}
                  maxTagCount={2}
                  mode="multiple"
                  filterOption={filterSelect}
                >
                  {superGroups.map((superGroup: Supergroup) => (
                    <Option key={superGroup.id} value={superGroup.id}>
                      {superGroup.name}
                    </Option>
                  ))}
                </Select>
              )}
            </MyFormItem>
          </Col>
        )}
        {selectedBranch && hasTeacher && (
          <Col lg={8}>
            <MyFormItem label={strings.GROUPS}>
              {getFieldDecorator('groups', {
                rules: [{ required: false }]
              })(
                <Select
                  filterOption={filterSelect}
                  loading={hasCourseGroupsLoading}
                  maxTagCount={2}
                  mode="multiple"
                  placeholder={strings.GROUPS}
                >
                  {groups.map((group: ActualGroup) => (
                    <Option key={group.id} value={group.id}>
                      {group.groupName}
                    </Option>
                  ))}
                </Select>
              )}
            </MyFormItem>
          </Col>
        )}
        <Col lg={4}>
          <Button
            style={{ marginTop: isMobile ? 0 : 48 }}
            loading={hasRecipientsLoading}
            type="primary"
            size="large"
            onClick={handleGetRecipients}
            block
          >
            {strings.GET} {currentPeople ? `${currentPeople}` : strings.USERS}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export const SmsFilter = Form.create<SmsFilterProps>({})(SmsFilterTemplate);
