import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Select, Collapse, Input, Button, Flex } from 'antd';
import { FamilyContractTemplate, Inventory, Rate } from 'types';
import { useLanguageContext } from 'contexts/LanguageContext';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { NamePath } from 'antd/lib/form/interface';
import { OrganizingLessonForm } from './OrganizingLessonForm';
import { ServicesForm } from './ServicesForm';
import { InventoryForm } from './InventoryForm';

const { Option } = Select;
const { Panel } = Collapse;

interface StudentContractFormProps {
  studentId: number;
  hasGSCEContractType: boolean;
  contractTemplates: FamilyContractTemplate[];
  rates: Rate[];
  inventories: Inventory[];
  index: number;
}

export const StudentContractForm: React.FC<StudentContractFormProps> = ({
  contractTemplates,
  rates,
  inventories,
  studentId,
  hasGSCEContractType,
  index
}) => {
  const [strings] = useLanguageContext();
  const form = Form.useFormInstance();
  const [template, setTemplate] = useState<number | string>('default');
  const handleTemplateChange = (template: number) => {
    setTemplate(template);
  };

  const getName = (name: string): NamePath => ['child', index, name];

  const handleReset = () => {
    form.setFieldsValue({
      child: {
        [index]: { subject: [], inventory: [], service: [] }
      }
    });
  };

  useEffect(() => {
    handleReset();
    const newFieldValues = {
      child: {
        [index]: {
          id: studentId,
          template: 'default'
        }
      }
    };
    form.setFieldsValue(newFieldValues);
  }, [studentId]);

  useEffect(() => {
    handleReset();
    if (template && template !== 'default') {
      const templateValues = contractTemplates
        .find(item => item.id === template)
        .rows.map(item => {
          return {
            subject: item.subject.id,
            type: item.type,
            countWeek: item.countWeek,
            countPeriod: item.countPeriod,
            price: item.price.id
          };
        });
      const newFieldValues = {
        child: {
          [index]: {
            id: studentId,
            template: template,
            subject: templateValues
          }
        }
      };
      form.setFieldsValue(newFieldValues);
    }
  }, [template]);

  return (
    <Row gutter={[10, 10]}>
      <Col lg={6} xs={24}>
        <Form.Item name={getName('id')} style={{ display: 'none' }}>
          <Input type="hidden" value={studentId} />
        </Form.Item>
        {!hasGSCEContractType && (
          <Form.Item name={getName('template')} label={strings.DRAFT} style={{ margin: 0 }}>
            <Select onChange={handleTemplateChange}>
              <Option key="default" value="default">
                {strings.NO_DRAFT}
              </Option>
              {contractTemplates.map((draft: FamilyContractTemplate) => (
                <Option key={draft.id} value={draft.id}>
                  {draft.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Col>
      <Col lg={24} xs={24}>
        <Collapse defaultActiveKey="1">
          <Panel header={strings.ORGANIZING_LESSONS} key="1">
            <Form.List name={getName('subject')}>
              {(fields, { add, remove }) => (
                <Flex vertical gap={10}>
                  {fields.map((field, index) => (
                    <>
                      {
                        <Flex gap={10}>
                          <Button
                            type="primary"
                            shape="circle"
                            size="small"
                            icon={<MinusOutlined />}
                            onClick={() => remove(field.name)}
                            style={{ marginTop: 40 }}
                          />
                          <OrganizingLessonForm field={field} rates={rates} />
                        </Flex>
                      }
                    </>
                  ))}
                  <Button type="primary" shape="circle" size="small" icon={<PlusOutlined />} onClick={() => add()} />
                </Flex>
              )}
            </Form.List>
          </Panel>
        </Collapse>
      </Col>
      <Col lg={24} xs={24}>
        <Collapse defaultActiveKey="2">
          <Panel header={strings.EXTRA_SERVICES} key="2">
            <Form.List name={getName('service')}>
              {(fields, { add, remove }) => (
                <Flex vertical gap={10}>
                  {fields.map((field, index) => (
                    <>
                      {
                        <Flex gap={10}>
                          <Button
                            type="primary"
                            shape="circle"
                            size="small"
                            icon={<MinusOutlined />}
                            onClick={() => remove(field.name)}
                            style={{ marginTop: 40 }}
                          />
                          <ServicesForm field={field} rates={rates} />
                        </Flex>
                      }
                    </>
                  ))}
                  <Button type="primary" shape="circle" size="small" icon={<PlusOutlined />} onClick={() => add()} />
                </Flex>
              )}
            </Form.List>
          </Panel>
        </Collapse>
      </Col>
      {!hasGSCEContractType && (
        <Col lg={24} xs={24}>
          <Collapse defaultActiveKey="3">
            <Panel header={strings.TEACHING_MATERIALS} key="3">
              <Form.List name={getName('inventory')}>
                {(fields, { add, remove }) => (
                  <Flex vertical gap={10}>
                    {fields.map((field, index) => (
                      <>
                        {
                          <Flex gap={10}>
                            <Button
                              type="primary"
                              shape="circle"
                              size="small"
                              icon={<MinusOutlined />}
                              onClick={() => remove(field.name)}
                              style={{ marginTop: 40 }}
                            />
                            <InventoryForm field={field} inventories={inventories} />
                          </Flex>
                        }
                      </>
                    ))}
                    <Button type="primary" shape="circle" size="small" icon={<PlusOutlined />} onClick={() => add()} />
                  </Flex>
                )}
              </Form.List>
            </Panel>
          </Collapse>
        </Col>
      )}
    </Row>
  );
};
