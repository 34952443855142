import React, { useEffect, useState } from 'react';
import { Table, Spin } from 'antd';
import { IMetaGoalsBySubject, IMetaGoalsSummary } from 'types/Student';
import { useMetaGoalsRepository } from 'repos/MetaGoalsRepository';
import { ColumnProps } from 'antd/lib/table/Column';
import { useLanguageContext } from 'contexts/LanguageContext';
import { SubjectTable } from './SubjectTable';

interface MetaGoalsProps {
  studentId: number;
}

/**
 * @description Meta Goals
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const MetaGoals = ({ studentId }: MetaGoalsProps) => {
  const [strings] = useLanguageContext();
  const [metaGoalsBySubjects, setMetaGoalsBySubjects] = useState<IMetaGoalsBySubject[]>([]);
  const [summaryMetaGoals, setSummaryMetaGoals] = useState<IMetaGoalsSummary>(null);
  const [hasLoading, setHasLoading] = useState<boolean>(false);

  const metaGoalsRepository = useMetaGoalsRepository();

  const fetchMetaGoals = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const {
        data: { summary, bySubjects }
      } = await metaGoalsRepository.getMetaGoalsSummaryByStudentId(studentId);
      setSummaryMetaGoals(summary);
      setMetaGoalsBySubjects(bySubjects);
    } finally {
      setHasLoading(false);
    }
  };

  const getSummaryClmn = (): ColumnProps<any>[] => {
    if (summaryMetaGoals) {
      const { header } = summaryMetaGoals;
      const headerClmns = header?.map((header, index) => {
        return {
          title: header,
          className: 'clmn-center',
          dataIndex: `data`,
          key: `data[${index}]`,
          render: data => data[index]
        };
      });
      return [
        {
          title: strings.META_GOALS,
          width: 500,
          dataIndex: 'metaGoal',
          key: 'description',
          render: ({ description }) => description
        },
        ...headerClmns
      ];
    }
    return null;
  };

  useEffect(() => {
    if (studentId) {
      fetchMetaGoals();
    }
  }, [studentId]);

  return (
    <Spin spinning={hasLoading}>
      <Table dataSource={summaryMetaGoals?.data} columns={getSummaryClmn()} pagination={false} bordered />
      {metaGoalsBySubjects?.isNotEmpty() && metaGoalsBySubjects.map(item => <SubjectTable metaGoalsBySubject={item} />)}
    </Spin>
  );
};

export default { MetaGoals };
