import React, { useMemo, useContext } from 'react';
import { httpService } from 'api/core';
import { IBranchPostParams, IBranchWelcomeLetterParams } from '../types/branches';

const BranchesContext = React.createContext<IBranchesRepository>(null);

interface IBranchesRepository {
  getBranches: () => Promise<any>;
  getBranchById: (id: string | number) => Promise<any>;
  addBranch: (params: IBranchPostParams) => Promise<any>;
  editBranch: (id: number | string, params: IBranchPostParams) => Promise<any>;
  getBranchesClickup: () => Promise<any>;
  getBranchClickupById: (id: string | number) => Promise<any>;
  addBranchClickup: (params: IBranchPostParams) => Promise<any>;
  editBranchClickup: (id: number | string, params: IBranchPostParams) => Promise<any>;
  getWelcomeLetter: (id: number | string) => Promise<any>;
  editWelcomeLetter: (id: number | string, params: IBranchWelcomeLetterParams) => Promise<any>;
}

class BranchesRepository implements IBranchesRepository {
  getBranches() {
    return httpService.get('/api/v1/branch', null, null, true);
  }
  getBranchById(id: string | number) {
    return httpService.get(`/api/v1/branch/${id}`, null, null, true);
  }
  addBranch(params: IBranchPostParams) {
    return httpService.post(`/api/v1/branch`, params, null, null, true);
  }
  editBranch(id: number | string, params: IBranchPostParams) {
    return httpService.put(`/api/v1/branch/${id}`, params, null, null, true);
  }
  getBranchesClickup() {
    return httpService.get('/api/v1/branch-settings', null, null, true);
  }
  getBranchClickupById(id: string | number) {
    return httpService.get(`/api/v1/branch-settings/${id}`, null, null, true);
  }
  addBranchClickup(params: IBranchPostParams) {
    return httpService.post(`/api/v1/branch-settings`, params, null, null, true);
  }
  editBranchClickup(id: number | string, params: IBranchPostParams) {
    return httpService.put(`/api/v1/branch-settings/${id}`, params, null, null, true);
  }
  getWelcomeLetter(id: string | number) {
    return httpService.get(`/api/v1/branch/${id}/welcome-letter`, null, null, true);
  }
  editWelcomeLetter(id: number | string, params: IBranchWelcomeLetterParams) {
    return httpService.put(`/api/v1/branch/${id}/welcome-letter`, params, null, null, true);
  }
}

export const useBranchesRepository = () => {
  const service = useContext(BranchesContext);

  if (!service) {
    throw new Error('Branch repository is unavailable');
  }

  return service;
};

export const BranchesRepositoryProvider = props => {
  const service = useMemo(() => new BranchesRepository(), []);

  return <BranchesContext.Provider value={service}>{props.children}</BranchesContext.Provider>;
};
