import React, { useEffect, useMemo, useState } from 'react';
import { Row, DatePicker, Col, Select, Form, Checkbox } from 'antd';
import dayjs from 'dayjs';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { Branch, Status } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { FormItemWithoutMargin } from 'Global/styles';
import { dateFormat } from 'helpers';
import { IChildrenFilter } from 'types/Family';
import { FEMALE, MALE } from 'Global/constants';
import { TutorSelect, StageSelect, SupergroupSelect, CourseGroupSelect } from 'Global/components/FormComponents';
import { FilterButtons } from 'Global/components/FilterButtons';
import { BranchBruner } from 'types/branches';

const { Option } = Select;

interface FilterProps {
  defValues: IChildrenFilter;
  onFilter: (params: IChildrenFilter) => Promise<any>;
}

export const Filter: React.FC<FilterProps> = ({ defValues, onFilter }) => {
  const [strings] = useLanguageContext();
  const [form] = Form.useForm();
  const { locationSearchParams } = useLocationSearchParams();
  const {
    branch,
    tutor,
    teacher,
    birthDateFrom,
    birthDateTo,
    stages,
    supergroup,
    courseGroups,
    gender,
    statuses,
    hasAllergies,
    hasMedicalConditions,
    hasTakingPhotos,
    hasSchoolTrips,
    hasPsychologistEvaluation
  } = locationSearchParams || {};

  const initValues = useMemo(
    () => ({
      branch,
      tutor,
      teacher,
      birthDateFrom: birthDateFrom && dayjs(birthDateFrom),
      birthDateTo: birthDateTo && dayjs(birthDateTo),
      stages,
      supergroup,
      courseGroups,
      gender,
      statuses,
      hasAllergies,
      hasMedicalConditions,
      hasTakingPhotos,
      hasSchoolTrips,
      hasPsychologistEvaluation
    }),
    [locationSearchParams]
  );

  const { branches, childStatuses } = useGlobalCollectionsContext();

  const [selectedBranch, setSelectedBranch] = useState<number>();
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);

  const handleClear = async () => {
    form.resetFields();
    await onFilter(defValues);
  };

  const handleSubmit = async (values: IChildrenFilter): Promise<any> => {
    try {
      setHasSubmitLoading(true);
      await onFilter(values);
    } finally {
      setHasSubmitLoading(false);
    }
  };
  useEffect(() => {
    form.setFieldsValue(initValues);
  }, [initValues]);

  useEffect(() => {
    branch && setSelectedBranch(branch);
  }, [branch]);

  return (
    <Form form={form} onFinish={handleSubmit} initialValues={initValues} layout="vertical">
      <Row align="middle" gutter={[10, 10]}>
        <Col lg={4} xs={24}>
          <FormItemWithoutMargin name="branch">
            <Select placeholder={strings.BRANCH} onChange={val => setSelectedBranch(val)}>
              {branches.map((branch: Branch) => (
                <Option key={branch.id} value={branch.id}>
                  {branch.name}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={4} xs={24}>
          <FormItemWithoutMargin name="stages">
            <StageSelect branch={selectedBranch} mode="multiple" />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={4} xs={24}>
          <FormItemWithoutMargin name="supergroup">
            <SupergroupSelect branch={selectedBranch} mode="multiple" placeholder={strings.SUPERGROUPS} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={4} xs={24}>
          <FormItemWithoutMargin name="courseGroups">
            <CourseGroupSelect branch={selectedBranch} mode="multiple" placeholder={strings.GROUP} />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={4} xs={24}>
          <FormItemWithoutMargin name="gender">
            <Select placeholder={strings.SELECT_GENDER} allowClear>
              <Option value={1} key={FEMALE}>
                {strings.FEMALE}
              </Option>
              <Option value={0} key={MALE}>
                {strings.MALE}
              </Option>
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={4} xs={24}>
          <FormItemWithoutMargin name="statuses">
            <Select placeholder={strings.SELECT_STATUS} mode="multiple" maxTagCount="responsive">
              {childStatuses?.map((status: Status) => (
                <Option value={status.id} key={status.id}>
                  {status.name}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={4} xs={24}>
          <FormItemWithoutMargin name="birthDateFrom">
            <DatePicker placeholder={`${strings.BIRTHDAY_FROM}`} format={dateFormat} allowClear />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={4} xs={24}>
          <FormItemWithoutMargin name="birthDateTo">
            <DatePicker placeholder={`${strings.BIRTHDAY_TILL}`} format={dateFormat} allowClear />
          </FormItemWithoutMargin>
        </Col>
        <Col lg={4} xs={24}>
          <FormItemWithoutMargin name="tutor">
            <TutorSelect placeholder={strings.TUTOR} mode="multiple" />
          </FormItemWithoutMargin>
        </Col>
        {selectedBranch === BranchBruner.id && (
          <>
            <Col lg={4} xs={24}>
              <FormItemWithoutMargin name="hasAllergies" valuePropName="checked">
                <Checkbox>{strings.ALLERGIES}</Checkbox>
              </FormItemWithoutMargin>
            </Col>
            <Col lg={4} xs={24}>
              <FormItemWithoutMargin name="hasMedicalConditions" valuePropName="checked">
                <Checkbox>{strings.HEALTH_CONDITIONS}</Checkbox>
              </FormItemWithoutMargin>
            </Col>
            <Col lg={4} xs={24}>
              <FormItemWithoutMargin name="hasTakingPhotos" valuePropName="checked">
                <Checkbox>{strings.TAKING_PHOTOS}</Checkbox>
              </FormItemWithoutMargin>
            </Col>
            <Col lg={4} xs={24}>
              <FormItemWithoutMargin name="hasSchoolTrips" valuePropName="checked">
                <Checkbox>{strings.SCHOOL_TRIPS}</Checkbox>
              </FormItemWithoutMargin>
            </Col>
            <Col lg={6} xs={24}>
              <FormItemWithoutMargin name="hasPsychologistEvaluation" valuePropName="checked">
                <Checkbox>{strings.PSYCHOLOGISTS_EVALUATION}</Checkbox>
              </FormItemWithoutMargin>
            </Col>
          </>
        )}
      </Row>
      <FilterButtons handleClear={handleClear} hasSubmitLoading={hasSubmitLoading} />
    </Form>
  );
};

export default { Filter };
