import React, { useState, useEffect } from 'react';
import { StopOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Row, Col, Select, Button, Badge, Form } from 'antd';
import ScheduleService from 'helpers/services/ScheduleService';
import { Global } from 'api';
import { Branch, Subject } from 'types';
import { Room, SearchSlotsParams } from 'types/Schedule';
import { pickBy } from 'lodash';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { FormItemWithoutMargin } from '../../styles';
import dayjs from 'dayjs';
import {
  SupergroupSelect,
  TutorSelect,
  StudentSelect,
  StageSelect,
  TeacherSelect
} from 'Global/components/FormComponents';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';

const { Option } = Select;
const service = new ScheduleService();

interface FilterScheduleProps {
  onSubmit: (fields: Partial<SearchSlotsParams>) => void;
  getEventsByDates: (params?: object) => Promise<any>;
  getRoomsByBranch: (branchId: number) => Promise<any>;
  rooms: Room[]; // Комнаты согласно филиалу
}

/**
 * @description Фильтр расписания
 * @param {object} props объект props
 * @return {React.ReactNode}
 */
export const Filter = ({ onSubmit, getEventsByDates, rooms, getRoomsByBranch }: FilterScheduleProps) => {
  const [form] = Form.useForm();
  const [user] = useUserContext();
  const [strings] = useLanguageContext();
  const { subjects } = useGlobalCollectionsContext();

  const [branches, setBranches] = useState<Branch[]>([]);

  const [selectedBranch, setSelectedBranch] = useState<number>();
  const { locationSearchParams } = useLocationSearchParams();
  const { dateStart, dateEnd, view, branch } = locationSearchParams || {};

  const [hasLoadDataByBranch, setHasLoadDataByBranch] = useState<boolean>(false);
  const { isTutorActiveFamilies } = user;
  const hasRolesToShowBranches = user?.hasRoles?.topManager || user?.hasRoles?.academicExpert;

  const handleSubmit = values => {
    const params = { ...pickBy(values), dateStart, dateEnd, view };
    service.setLastFilter(params);
    onSubmit(params);
  };

  const resetFilter = async (): Promise<any> => {
    form.resetFields();
    const { dateStart, dateEnd, view } = locationSearchParams;
    await getEventsByDates({ branch: user?.branch?.id, dateStart, dateEnd, view });
  };

  const filterMyGroups = async (): Promise<any> => {
    const tutorId = user?.meAdminId;
    await getEventsByDates({ ...locationSearchParams, familyTutors: [tutorId] });
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await Global.getBranchesForSchedule();
        setBranches(data);
      } catch {
        setBranches([]);
      }
    })();
  }, []);

  useEffect(() => {
    const { branch, dateStart, dateEnd } = locationSearchParams;
    form.setFieldsValue({
      ...locationSearchParams,
      dateStart: dateStart && dayjs(dateStart),
      dateEnd: dateEnd && dayjs(dateEnd)
    });
    branch && setSelectedBranch(branch);
  }, [locationSearchParams]);

  return (
    <Form form={form} onFinish={handleSubmit} initialValues={{}} style={{ marginTop: 20 }}>
      <Row gutter={[10, 10]}>
        <Col xs={24} lg={6}>
          <FormItemWithoutMargin name="stages">
            <StageSelect branch={selectedBranch} mode="multiple" placeholder={strings.STAGES} />
          </FormItemWithoutMargin>
        </Col>
        <Col xs={hasRolesToShowBranches ? 24 : 0} lg={hasRolesToShowBranches ? 6 : 0}>
          <FormItemWithoutMargin name="branch" hidden={!hasRolesToShowBranches}>
            <Select placeholder={strings.BRANCH} allowClear onChange={val => setSelectedBranch(val)}>
              {branches.map(branch => (
                <Option value={branch.id} key={branch.id}>
                  {branch.name}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col xs={24} lg={6}>
          <FormItemWithoutMargin name="supergroups">
            <SupergroupSelect branch={selectedBranch} mode="multiple" placeholder={strings.SUPERGROUP} />
          </FormItemWithoutMargin>
        </Col>
        <Col xs={24} lg={6}>
          <FormItemWithoutMargin name="teacherTutors">
            <TutorSelect placeholder={strings.ADM} mode="multiple" />
          </FormItemWithoutMargin>
        </Col>
        <Col xs={24} lg={6}>
          <FormItemWithoutMargin name="familyTutors">
            <TutorSelect placeholder={strings.FAMILY_TUTOR} mode="multiple" />
          </FormItemWithoutMargin>
        </Col>
        <Col xs={24} lg={6}>
          <FormItemWithoutMargin name="subject">
            <Select mode="multiple" placeholder={strings.SUBJECTS} maxTagCount="responsive" allowClear>
              {subjects.map((subject: Subject) => (
                <Option key={subject.id} value={subject.id}>
                  {subject.name}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col xs={24} lg={6}>
          <FormItemWithoutMargin name="students">
            <StudentSelect placeholder={strings.STUDENT} mode="multiple" />
          </FormItemWithoutMargin>
        </Col>
        <Col xs={24} lg={6}>
          <FormItemWithoutMargin name="eventType">
            <Select allowClear={true} placeholder={strings.EVENT_TYPE}>
              <Option key="lesson" value="lesson">
                {strings.LESSON}
              </Option>
              <Option key="event" value="event">
                {strings.EVENT}
              </Option>
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col xs={24} lg={6}>
          <FormItemWithoutMargin name="rooms">
            <Select allowClear placeholder={strings.ROOM} loading={hasLoadDataByBranch} mode="multiple" maxTagCount={1}>
              {rooms.map((room: Room) => (
                <Option value={room.id} key={room.id}>
                  {room.name} <Badge color={room.color} />
                  {room?.isBusy && (
                    <>
                      <StopOutlined style={{ color: '#f5222d' }} /> (busy)
                    </>
                  )}
                </Option>
              ))}
            </Select>
          </FormItemWithoutMargin>
        </Col>
        <Col xs={24} lg={6}>
          <FormItemWithoutMargin name="assistant">
            <TeacherSelect placeholder={strings.ASSISTANT} mode="multiple" />
          </FormItemWithoutMargin>
        </Col>
        <Col xs={24} lg={6}>
          <FormItemWithoutMargin name="teacher">
            <TeacherSelect placeholder={strings.TEACHER} mode="multiple" />
          </FormItemWithoutMargin>
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
        <Col xs={24} lg={24} style={{ display: 'flex', gap: 10 }}>
          <Button type="primary" htmlType="submit" size="middle">
            {strings.FILTER}
          </Button>
          {isTutorActiveFamilies && (
            <Button onClick={filterMyGroups} icon={<UsergroupAddOutlined />} size="middle">
              {strings.MY_GROUPS}
            </Button>
          )}
          <Button htmlType="button" onClick={resetFilter} size="middle">
            {strings.CLEAR_FILTER}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
