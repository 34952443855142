import React, { useState, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select, Spin, Tooltip } from 'antd';
import { debounce, first } from 'lodash';
import { GetFieldDecoratorOptions } from '@ant-design/compatible/lib/form/Form';

import styled from 'styled-components';
import { Admin } from 'types';
import { useAdminRepository } from 'repos/AdminRepository';
import { useLanguageContext } from 'contexts/LanguageContext';

const StSelect = styled.div`
  .ant-select-selection--multiple .ant-select-selection__clear,
  .ant-select-selection--multiple .ant-select-arrow {
    top: 20px;
  }
`;

const { Option } = Select;

interface IBaseTutor {
  id: string | number;
  name: string;
  surname: string;
  userId?: number;
}

interface TutorSelectProps {
  getFieldDecorator?<T extends Object = {}>(
    id: keyof T,
    options?: GetFieldDecoratorOptions
  ): (node: React.ReactNode) => React.ReactNode;
  mode?: 'multiple' | 'tags';
  placeholder?: string;
  name: string;
  searchParams?: object;
  required?: boolean;
  disabled?: boolean;
  maxTagCount?: number;
  initTutor?: Admin | IBaseTutor | string | number;
  initTutors?: Admin[] | string[] | number[];
  isAllBranchActive?: boolean;
  label?: string;
  message?: string;
  style?: object;
  className?: string;
  hasUseUserId?: boolean;
  handleChange?: (tutor: any, option?) => any;
}

/**
 * @description Компонент поиска по админам
 * @param {object} props
 * @return {React.ReactNode}
 */
export const TutorSelect = (props: TutorSelectProps) => {
  const {
    placeholder,
    getFieldDecorator,
    mode,
    name,
    required,
    disabled,
    maxTagCount,
    initTutor,
    initTutors,
    isAllBranchActive,
    style,
    className,
    label,
    message,
    handleChange,
    hasUseUserId
  } = props;

  const [hasLoadTutors, setHasLoadTutors] = useState<boolean>(false);
  const [tutorList, setTutorsList] = useState<IBaseTutor[]>([]);
  const [tutorListBySearch, setTutorsListBySearch] = useState<IBaseTutor[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [typing, setTyping] = useState<Boolean>(false);
  const adminRepository = useAdminRepository();
  const [strings] = useLanguageContext();

  /**
   * @description Форматирование пришедших админов для Select Option
   * @param {Admin[]} admins
   * @return {object[]}
   */
  const formatTutorList = (admins: Admin[]) =>
    admins.map(item => {
      if (typeof item === 'string' || typeof item === 'number') {
      } else {
        const { id, name, surname, user } = item;
        if (user) {
          return {
            id,
            name: user?.name || user?.firstName,
            surname: user?.surname || user?.lastName,
            userId: user?.id
          };
        } else {
          return { id, name, surname };
        }
      }
    });

  /**
   * @description Поиск по студентам
   * @param {string} tutor
   * @return {Promise<void>}
   */
  const fetchTutors = async (query: string): Promise<any> => {
    setSearchValue(query);
    if (query) {
      const allBranchActive = isAllBranchActive ? Number(isAllBranchActive) : 0;
      setHasLoadTutors(true);
      try {
        const {
          data: { admins },
          status
        } = await adminRepository.getAdminsByName(query, allBranchActive);
        if (status === 200) {
          setTutorsListBySearch(formatTutorList(admins));
        }
      } catch {
      } finally {
        setHasLoadTutors(false);
      }
    }
  };

  /**
   * @description Получение тьютора по id
   * @param {number} tutorId
   * @return {Promise<any>}
   */
  const getTutorById = async (tutorId: string | number): Promise<any> => {
    setHasLoadTutors(true);
    try {
      const { data, status } = await adminRepository.getAdminById(tutorId);
      if (status === 200) {
        const {
          id,
          user: { name, surname }
        } = data;
        setTutorsList([{ id, name, surname }]);
      }
    } finally {
      setHasLoadTutors(false);
    }
  };

  /**
   * @description Получение списка тьюторов, ЕСЛИ select multiple
   * @return {Promise<any>}
   */
  const getTutorsByIds = async (initTutorsIds): Promise<any> => {
    if (mode !== 'multiple') return;
    setHasLoadTutors(true);
    const tutors = [];
    for (const tutorId of initTutorsIds) {
      try {
        const { data } = await adminRepository.getAdminById(tutorId);
        tutors.push(data);
      } catch {}
    }
    try {
      const results = await Promise.all(tutors);
      const data = results.map(item => {
        return {
          id: item?.id,
          name: item?.name,
          surname: item?.surname
        };
      });
      setTutorsList(data);
    } finally {
      setHasLoadTutors(false);
    }
  };

  const optionsByInitialValue = tutorList.map((item: IBaseTutor) => {
    return (
      <Option value={Number(item.id)} key={item.id}>
        <Tooltip title={`${item.name} ${item.surname}`} overlayStyle={{ zIndex: 9999 }}>
          {item?.name} {item?.surname}{' '}
        </Tooltip>
      </Option>
    );
  });

  const optionsBySearch = tutorListBySearch.map((item: IBaseTutor) => {
    return (
      <Option value={hasUseUserId ? Number(item.userId) : Number(item.id)} key={item.id}>
        <Tooltip title={`${item.name} ${item.surname}`} overlayStyle={{ zIndex: 9999 }}>
          {item?.name} {item?.surname}{' '}
        </Tooltip>
      </Option>
    );
  });

  /**
   * @description Получение базового значения
   * @return {number}
   */
  const getDefaultTutorValue = (item): number => {
    if (!item) return;
    if (typeof item === 'string' || typeof item === 'number') {
      return Number(item);
    } else {
      //@ts-ignore
      return Number(item.id);
    }
  };
  /**
   * @description Получение базового Multiple значения
   * @return {number[]}
   */
  const getDefaultMultipleTutorValue = (array): number[] => {
    if (!array) return;
    //@ts-ignore
    return array.map(item => getDefaultTutorValue(item));
  };

  useEffect(() => {
    if (searchValue) {
      setTyping(true);
    } else {
      setTyping(false);
    }
  }, [searchValue]);

  const select = (
    <Select
      allowClear={true}
      showSearch
      mode={mode}
      placeholder={placeholder || strings.TUTOR}
      defaultActiveFirstOption={false}
      filterOption={false}
      onChange={handleChange}
      onSearch={debounce(fetchTutors, 800)}
      style={{ width: '100%' }}
      notFoundContent={
        hasLoadTutors ? <Spin size="small" /> : <p>{!typing ? strings.START_TYPING : strings.NOT_FOUND}</p>
      }
      maxTagCount={maxTagCount || 1}
      disabled={disabled}
      loading={hasLoadTutors}
    >
      {[...optionsByInitialValue, ...optionsBySearch]}
    </Select>
  );

  useEffect(() => {
    if (initTutor) {
      //если initTutor - это id
      if (typeof initTutor === 'string' || typeof initTutor === 'number') {
        // @ts-ignore
        getTutorById(initTutor);
      } else {
        //если initTutor - это object
        // @ts-ignore
        setTutorsList(formatTutorList([initTutor]));
      }
    }
    if (initTutors && initTutors.join()) {
      //если initTutors - это number[]
      // @ts-ignore
      if (typeof first(initTutors) === 'string' || typeof first(initTutors) === 'number') {
        // @ts-ignore
        getTutorsByIds(initTutors);
      } else {
        //если initTutors - это Admin[]
        // @ts-ignore
        setTutorsList(formatTutorList(initTutors));
      }
    }
    //  initTutorsIds массив, поэтому преобразуем в строку, чтобы зафиксировать изменение и выполнить getTutorsByIds
  }, [initTutor, initTutors && initTutors.join()]); // eslint-disable-line

  if (required) {
    return (
      <StSelect>
        <MyFormItem label={label} style={style} className={className}>
          {getFieldDecorator(name, {
            initialValue:
              mode === 'multiple'
                ? initTutors && getDefaultMultipleTutorValue(initTutors)
                : getDefaultTutorValue(initTutor),
            rules: [{ required: required, message: message ? message : 'Select tutor' }]
          })(select)}
        </MyFormItem>
      </StSelect>
    );
  }

  return (
    <StSelect>
      <MyFormItem label={label} style={style} className={className}>
        {getFieldDecorator(name, {
          initialValue:
            mode === 'multiple'
              ? initTutors && getDefaultMultipleTutorValue(initTutors)
              : getDefaultTutorValue(initTutor),
          rules: [{ required: false }]
        })(select)}
      </MyFormItem>
    </StSelect>
  );
};

export default { TutorSelect };
