import React from 'react';
import { first, flatten } from 'lodash';
import dayjs from 'dayjs';
import { dateFormatForBackend, dateTimeWithoutSecondFormatForBackend } from '../dates';
import { AxiosResponse } from 'axios';
import { IStudent, IParent } from 'types';
import { Teacher } from 'types/Teachers/teacher';
import { EventSlot, LessonSlot, Participant, peopleType, Meta, BaseSlot, MetaWithSubLevel } from 'types/Schedule';
import { User } from 'types/user';
import { Courses } from 'api';
import { coursesRepository } from 'repos/CoursesRepository';

export default class ScheduleService {
  static HEIGHT_ONE_HOUR = 90;
  static HEIGHT_ONE_MINUTE = this.HEIGHT_ONE_HOUR / 60;
  static SCHEDULE_TIME_SLOTS = 12;
  static SCHEDULE_STEPS = 5;
  private lastFilterLocalStorageKey = 'lastFilterSchedule';
  // Форматы для props календаря
  static selectRangeFormat = ({ start, end }) => `${dayjs(start).format('HH:mm')} — ${dayjs(end).format('HH:mm')}`;

  /**
   * @description Обработка слотов с уроками (эвентами)
   * @param {object} user
   * @param {array} data
   * @param {string} view
   * @param {boolean} resize
   * @return {array}
   */
  public getScheduleSlots(user: User, data: LessonSlot[] & EventSlot[], view: 'day' | 'week', resize?: boolean) {
    const hasParent = user?.hasRoles?.parent;
    const slots = this.setOptionsToSlots(data, view, resize).map((slot: BaseSlot) => {
      let title = '';

      if (slot.lesson) {
        // Если роль родитель, то указываем название предмета, не группы
        if (hasParent) {
          title = slot.lesson.course?.subject?.name;
        } else {
          title = slot.lesson?.course?.groupName;
        }
      }

      return {
        ...slot,
        id: slot.id,
        title: title,
        color: slot.color,
        endAt: dayjs(slot.endAt).format(dateTimeWithoutSecondFormatForBackend),
        startAt: dayjs(slot.startAt).format(dateTimeWithoutSecondFormatForBackend),
        start: dayjs(slot.startAt).toDate(),
        end: dayjs(slot.endAt).toDate()
      };
    });

    return slots;
  }

  /**
   * @description Форматирование списка участников
   * @param {array} participants
   * @return {array}
   */
  public getFormatParticipants(participants: Participant[]) {
    // checkbox всегда false, потому что на уровне фронта удаляются участники.
    return participants.map(participant => ({
      id: participant.id,
      type: participant.type,
      checkbox: false
    }));
  }

  /**
   * @description Метод для возвращения дат на текущей недели
   * @return {object}
   */
  public getWeekDates(): { dateStart: string; dateEnd: string } {
    return {
      dateStart: dayjs().startOf('week').format(dateFormatForBackend),
      dateEnd: dayjs().endOf('week').format(dateFormatForBackend)
    };
  }

  /**
   * @description Обработка массива пользователей для селекта
   * @param {array} users
   * @param {string} type
   * @return {array}
   */
  public mapUsersByType(users: (IStudent & IParent & Teacher)[], type: peopleType) {
    return users.map(item => ({
      ...item,
      type: type
    }));
  }

  /**
   * @description Получение вспомогательной информации исходя из группы
   * @param {number} groupId
   * @return {Promise<any>}
   */
  public async getGroupInfo(groupId: number): Promise<any> {
    const duration = coursesRepository
      .getCourseGroupById(groupId)
      .then(({ data }: AxiosResponse) => {
        const { durationMeasure } = data;
        return durationMeasure;
      })
      .catch(() => {});

    // TODO Ждем правок в ответ
    const groupStudents = Courses.getStudentsByGroup(groupId)
      .then(({ data }: AxiosResponse) => {
        return data.map(group => ({ ...group, ...group.student }));
      })
      .catch(() => {});

    const groupTeacher = coursesRepository
      .getTeachersByGroup(groupId)
      .then(({ data: teachers }: AxiosResponse) => {
        return first(teachers.filter(teacher => teacher.isMain));
      })
      .catch(() => {});

    return await Promise.all([groupStudents, groupTeacher, duration]);
  }

  /**
   * @description Получение массива уроков согласно текущему дню для bulk select
   * @param {array} slots
   * @return {number[]}
   */
  public getLessonsIdsByCurrentDay(slots: LessonSlot[] & EventSlot[]): number[] {
    const today = dayjs(new Date());
    /**
     * 1. Берем общий массив слотов. 2. Фильтруем эвенты.
     * 3. Выбираем, которые проходят в текущий день. 4. Делаем map с id
     * 5. Фильтруем пустые значения
     **/
    return slots
      .filter((slot: LessonSlot & EventSlot) => slot.type !== 'event')
      .map(slot => {
        const startSlotDate = dayjs(slot.startAt);
        const isToday = today.isSame(startSlotDate, 'day');
        if (isToday) return slot.id;
      })
      .filter(i => i);
  }

  /**
   * @description Функция для вычета стилей для выравнивания блоков
   * @param {object} dateStart
   * @param {object} dateEnd
   * @return {object}
   */
  public getRowStyleByScheduleEvent(dateStart: string) {
    // 8 утра текущего дня
    const startDayTime = dayjs(dateStart).set('hours', 8).set('minutes', 0);

    // Разница в минутах
    const diff = dayjs(dateStart).diff(startDayTime, 'minutes');

    return {
      top: `${diff * ScheduleService.HEIGHT_ONE_MINUTE}px`
    };
  }

  /**
   * @description Установка параметров слота для позиционирования и размеров
   * @param {array} slots
   * @param {string} view - текущий вид календаря
   * @param {boolean} resize
   * @return {array}
   */
  public setOptionsToSlots(slots, view: 'day' | 'week', resize: boolean) {
    // Максимальная ширина плашки
    const MAX_EVENT_WIDTH = 280;

    const eventContainer: HTMLElement = document.querySelector('.rbc-events-container');

    /**
     * @description Получение ширины текущего контейнера
     * @return {number}
     */
    const getContainerWidth = (): number => {
      if (view === 'day' && eventContainer instanceof HTMLElement) {
        return eventContainer.offsetWidth;
      } else if (view === 'week' && eventContainer instanceof HTMLElement) {
        return eventContainer.clientWidth;
      } else {
        return MAX_EVENT_WIDTH;
      }
    };

    const slotWithOptions = slot => {
      const { length, lengthSubLevel, key, keySubLevel, zIndex } = slot;
      // Ширина плашки для размещения впритык
      const columnWidth = getContainerWidth() / length;

      // Ширина плашки - MAX_EVENT_WIDTH или меньше (делим ширину контейнера на кол-во слотов в группе)
      const width = columnWidth > MAX_EVENT_WIDTH ? MAX_EVENT_WIDTH : columnWidth;
      const slotWidth = width / lengthSubLevel;
      // Смещение будем получать умножив порядковый номер в группе на ширину
      const columnLeft = Math.floor(key * width);
      const slotLeft = columnLeft + Math.floor(keySubLevel * slotWidth);

      const height = this.getEventHeight(slot);
      return { ...slot, options: { height, width: slotWidth, left: slotLeft, zIndex: 10 + zIndex } };
    };

    if (resize) {
      return slots.map(slot => slotWithOptions(slot));
    }

    function groupSlotsByTimeInterval(data, endOfDay) {
      data.sort((a, b) => {
        const diff = dayjs(a.startAt).diff(dayjs(b.startAt));
        if (diff !== 0) return diff;
        return a?.supergroup?.name.localeCompare(b?.supergroup?.name);
      });

      const groupsByTimeInterval = data.reduce((result, lesson) => {
        let added = false;

        // Перебираем существующие группы
        for (const group of result) {
          const lastLesson = group[group.length - 1];
          const lessonStart = dayjs(lesson.startAt);
          const lessonEnd = dayjs(lesson?.endTimeForShowing ? lesson?.endTimeForShowing : lesson.endAt);
          const groupEnd = dayjs(lastLesson?.endTimeForShowing ? lastLesson?.endTimeForShowing : lastLesson.endAt);

          // Проверка, помещается ли урок в текущую группу
          if ((lessonStart.isSame(groupEnd) || lessonStart.isAfter(groupEnd)) && lessonEnd.isBefore(endOfDay)) {
            group.push(lesson);
            added = true;
            break;
          }
        }

        // Если урок не был добавлен в существующие группы, создаем новую группу
        if (!added) {
          result.push([lesson]);
        }
        return result;
      }, []);

      return groupsByTimeInterval;
    }

    function groupLessons(originData: BaseSlot[]): MetaWithSubLevel[][] {
      if (!originData.length) return [];

      const endOfDay = dayjs(originData[0].startAt).hour(23).minute(0);
      const data: MetaWithSubLevel[] = originData.map(item => ({
        ...item,
        supergroup: item?.lesson?.course?.supergroups[0],
        lengthSubLevel: 1,
        keySubLevel: 0
      }));

      let eventsList: MetaWithSubLevel[] = [];
      const groupedBySupergroup = data.reduce((acc, item) => {
        const supergroup = item?.supergroup?.id;
        if (supergroup) {
          acc[supergroup] = acc[supergroup] || [];
          acc[supergroup].push(item);
        } else eventsList.push(item);
        return acc;
      }, {});

      const mappedEventListByDuration = eventsList.map(event => {
        const startTime = dayjs(event.startAt);
        const endTime = dayjs(event.endAt);
        const duration = endTime.diff(startTime, 'hour', true);

        //Для ивентов длительностью более 2-х часов добавляем endTimeForShowing,
        //чтобы после этого времени могло быть перерытие следующим слотом в расписании
        if (duration < 2) {
          return event;
        } else {
          return { ...event, endTimeForShowing: startTime.add(30, 'minute') };
        }
      });

      const groupOverlappingLessons: (lessons: MetaWithSubLevel[]) => MetaWithSubLevel[] = (
        lessons: MetaWithSubLevel[]
      ) => {
        // Сначала сортируем уроки по времени начала
        const sortedLessons = lessons.sort((a, b) => (dayjs(a.startAt).isAfter(dayjs(b.startAt)) ? 1 : -1));

        const groups: MetaWithSubLevel[][] = [];
        sortedLessons.forEach(lesson => {
          let added = false;

          // Проверяем, есть ли уже группа, в которую этот урок пересекается по времени
          for (const group of groups) {
            const overlaps = group.some(
              gLesson =>
                dayjs(lesson.startAt).isBefore(dayjs(gLesson.endAt)) &&
                dayjs(lesson.endAt).isAfter(dayjs(gLesson.startAt))
            );

            if (overlaps) {
              group.push(lesson);
              added = true;
              break;
            }
          }

          // Если урок не пересекается ни с одной из существующих групп, создаем новую группу
          if (!added) {
            groups.push([lesson]);
          }
        });

        const mappedGroups: MetaWithSubLevel[][] = groups.map((group: MetaWithSubLevel[]) =>
          group.map((item, index) => ({ ...item, lengthSubLevel: group.length, keySubLevel: index }))
        );
        return flatten(mappedGroups);
      };

      const groupedBySupergroupAndOverlappingLessons: MetaWithSubLevel[][] = Object.values(groupedBySupergroup).map(
        (item: MetaWithSubLevel[]) => groupOverlappingLessons(item)
      );

      //представляем группу слотов для одной супергруппы в виде единого слота,
      //чтобы в расписании они не разбивались и оказались в одном столбце
      const lessonList = groupedBySupergroupAndOverlappingLessons.map((group: MetaWithSubLevel[]) => {
        if (group.length > 1) {
          const sortedGroup = group.sort((a, b) => a.startAt.localeCompare(b.startAt));
          return {
            supergroup: sortedGroup[0].supergroup,
            startAt: sortedGroup[0].startAt,
            endAt: sortedGroup[sortedGroup.length - 1].endAt,
            children: sortedGroup
          };
        }
        return group[0];
      });

      const groupedSlotsByTimeInterval = groupSlotsByTimeInterval(
        [...lessonList, ...mappedEventListByDuration],
        endOfDay
      );

      const extendedGroupedLessonByTimeInterval = groupedSlotsByTimeInterval.map(items =>
        flatten(items.map(item => (item?.children ? item?.children : item)))
      );

      return extendedGroupedLessonByTimeInterval;
    }

    function sortAndGroupByDay(data: BaseSlot[]): BaseSlot[][] {
      data.sort((a, b) => dayjs(a.startAt).unix() - dayjs(b.startAt).unix());
      const groupedByDay = {};
      data.forEach(item => {
        const day = dayjs(item.startAt).format('YYYY-MM-DD');
        if (!groupedByDay[day]) {
          groupedByDay[day] = [];
        }
        groupedByDay[day].push(item);
      });
      return Object.values(groupedByDay);
    }

    const slotsSortByDay = sortAndGroupByDay(slots);

    const groupedSlots = slotsSortByDay.map(slotByDay => {
      const groupedLessons: Meta[][] = groupLessons(slotByDay).map((items, index, array) =>
        items.map((item, itemIndex) => ({
          ...item,
          key: index,
          length: array.length,
          lengthSubLevel: item.lengthSubLevel,
          keySubLevel: item.keySubLevel,
          zIndex: itemIndex
        }))
      );
      return flatten(groupedLessons);
    });

    // Пройдемся по развёрнутому массиву пересечений (одноуровневый массив)
    const result = flatten(groupedSlots).map((slot: Meta) => {
      return slotWithOptions(slot);
    });

    return result;
  }

  public getEventHeight(slot): number {
    // Высоту получим путем вычета разницы МИНУТ между датой начала и датой конца
    // и поделим на ONE_QUARTER_LENGTH(5 минут) и умножим на высоту занимаемую 5 минутами.
    const diffMinutes = Math.abs(dayjs(slot.startAt).diff(dayjs(slot.endAt), 'minutes'));

    const height = diffMinutes * ScheduleService.HEIGHT_ONE_MINUTE;
    return height;
  }

  /**
   * @description Получение последнего фильтра
   * @return {object}
   */
  public getLastFilter(): object {
    return JSON.parse(localStorage.getItem(this.lastFilterLocalStorageKey));
  }

  /**
   * @description Установка последнего фильтра
   * @param {object} fields
   * @return {object}
   */
  public setLastFilter(fields) {
    const key = this.lastFilterLocalStorageKey;
    if (localStorage.getItem(key)) {
      localStorage.setItem(key, '');
    }
    localStorage.setItem(key, JSON.stringify(fields));
  }
}
