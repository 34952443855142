import React, { useState, useEffect } from 'react';
import { Table, Spin, Typography, Pagination, message } from 'antd';
import { Filter } from './Filter';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { columns } from './columns';
import { OriginalCourse, OriginalCourseAccess } from 'types/courses';
import { useAdminRepository } from 'repos/AdminRepository';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { useHistory } from 'react-router-dom';

const { Column } = Table;

const { Title } = Typography;

// Лимит курсов оригиналов на одной странице
const ORIGINAL_COURSES_PAGE_LIMIT = 10;

/**
 * @description Управление доступами к курсам оригиналам
 * @returns {React.ReactNode}
 */
export const OriginalCoursesAccess = () => {
  const [strings] = useLanguageContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [accesses, setAccesses] = useState<OriginalCourseAccess[]>([]);
  const [courses, setCourses] = useState<{
    paginationInfo: any;
    items: OriginalCourse[];
  }>({
    paginationInfo: undefined,
    items: []
  });
  const [currentFilter, setCurrentFilter] = useState<{ branch: number } | null>(null);
  const adminRepository = useAdminRepository();
  const coursesRepository = useCoursesRepository();
  const { goBack } = useHistory();

  /**
   * @description Обработчик на изменение доступа к курсу
   * @param {number} id
   * @param {string} level
   * @returns {Promise<void>}
   */
  const onLevelEdit = async (id: number, level: string): Promise<void> => {
    await adminRepository.setOriginalCoursesAdminAccess(id, level);
    await fetchAccesses();
  };

  /**
   * @description Получить доступы админов к курсам оригиналам
   * @param {object} filter
   * @returns {Promise<any>}
   */
  const fetchAccesses = async (filter: object = {}): Promise<any> => {
    setLoading(true);
    try {
      const {
        data: { items }
      } = await adminRepository.getOriginalCoursesAdminAccess(filter);
      setAccesses(
        items.map((item: OriginalCourseAccess) => ({
          ...item,
          onLevelEdit
        }))
      );
    } catch (err) {
      message.error(strings.SORRY_SOMETHING_WENT_WRONG_WHEN_UPLOADING_ACCESSES_PLEASE_TRY_AGAIN);
    } finally {
      setLoading(false);
    }
  };

  /**
   * @description Получить курсы оригиналы
   * @returns {Promise<any>}
   */
  const fetchCourses = async (page?: number): Promise<any> => {
    setLoading(true);
    try {
      const {
        data: { paginationInfo, items }
      } = await coursesRepository.getOriginalCourses({
        isActive: 1,
        limit: ORIGINAL_COURSES_PAGE_LIMIT,
        ...(page && { page })
      });

      setCourses({
        paginationInfo,
        items: items.filter((course: OriginalCourse) => course.branch)
      });
    } catch (e) {
      message.error(strings.SORRY_SOMETHING_WENT_WRONG_WHEN_UPLOADING_ACCESSES_PLEASE_TRY_AGAIN);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCourses();
    fetchAccesses();
  }, []);

  const pagination = courses.paginationInfo;
  const coursesItems = currentFilter
    ? courses.items.filter((course: OriginalCourse) => course.branch.id === currentFilter.branch)
    : courses.items;

  return (
    <Container>
      <StPageHeader onBack={goBack} title={strings.ORIGINAL_COURSES_ACCESS} />
      <Filter onFilter={setCurrentFilter} />
      <Spin spinning={loading}>
        {coursesItems.map((course: OriginalCourse) => {
          const { id, name, branch } = course;

          const filteredAccesses = accesses.filter(
            (access: OriginalCourseAccess) => access.branch_type === branch.type
          );
          return (
            <div key={id + name} style={{ marginBottom: '1rem' }}>
              <Title level={4}>{name}</Title>
              <Table dataSource={filteredAccesses} pagination={false}>
                {columns(strings).map((col: any) => (
                  <Column key={col.key} {...col} />
                ))}
              </Table>
            </div>
          );
        })}

        {pagination && (
          <Pagination
            total={pagination.totalCount}
            current={pagination.current}
            pageSize={pagination.numItemsPerPage}
            onChange={fetchCourses}
          />
        )}
      </Spin>
    </Container>
  );
};
