import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Button, Select, InputNumber, Row, Col, Checkbox, Flex, Form } from 'antd';
import { ModalMode, Wage } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { TeacherWageListItem } from 'types/Teachers/wages';
import { ITeacherIndividualWagePostParams } from 'types/Teachers/teacher';
import { useTeacherRepository } from 'repos/TeacherRepository';

const { Option } = Select;

interface AddWageProps {
  teacherId: number | string;
  hideModal: () => void;
  getWages: () => Promise<any>;
  wageId?: number;
  wagesList: TeacherWageListItem[];
  mode: ModalMode;
}

export const AddWageModal = ({ mode, teacherId, hideModal, wagesList, wageId, getWages }: AddWageProps) => {
  const { teacherWages } = useGlobalCollectionsContext();
  const [strings] = useLanguageContext();
  const teacherRepository = useTeacherRepository();
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState<boolean>(false);

  const title = mode === ModalMode.Edit ? 'Edit wage' : strings.ADDING_NEW_WAGE;

  const handleSubmit = async (values: ITeacherIndividualWagePostParams): Promise<any> => {
    setLoading(true);
    values['dailySalary'] = Number(values.dailySalary);
    values['cost'] = Number(values.cost);

    if (mode === ModalMode.Add) {
      try {
        await teacherRepository.addTeacherIndividualWage(teacherId, values);
        getWages();
        hideModal();
      } catch {
      } finally {
        setLoading(false);
      }
    } else if (mode === ModalMode.Edit) {
      try {
        await teacherRepository.editTeacherIndividualWage(teacherId, wageId, values);
        getWages();
        hideModal();
      } catch {
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (wageId) {
      const currentWage = wagesList.find(wage => wage.cost.id === wageId);
      form.setFieldsValue({
        cost: currentWage.cost.id,
        individual: currentWage.individual,
        dailySalary: currentWage.daily_salary
      });
    } else {
      form.resetFields();
    }
  }, [wageId]);

  return (
    <Modal title={title} footer={false} destroyOnClose={true} open={mode !== ModalMode.Hidden} onCancel={hideModal}>
      <Form form={form} layout="vertical" onFinish={handleSubmit} initialValues={{ dailySalary: false }}>
        <Row>
          <Col lg={24}>
            <Form.Item name="cost" label={strings.WAGE} rules={[{ required: true, message: strings.WAGE_IS_REQUIRED }]}>
              <Select placeholder={strings.WAGE} disabled={mode === ModalMode.Edit}>
                {teacherWages.map((wage: Wage) => (
                  <Option key={wage.id} value={wage.id}>
                    {wage.cost} - {wage.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item
              name="individual"
              label={strings.INDIVIDUAL_WAGE}
              rules={[{ required: true, message: strings.INDIVIDUAL_WAGE_IS_REQUIRED }]}
            >
              <InputNumber placeholder={strings.INDIVIDUAL_WAGE} />
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item name="dailySalary" valuePropName="checked">
              <Checkbox>{strings.DAILY_SALARY}</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Flex justify="end" gap={10}>
          <Button key="back" onClick={hideModal}>
            {strings.CANCEL}
          </Button>
          <Button type="primary" htmlType="submit" disabled={isLoading} loading={isLoading}>
            {mode === ModalMode.Edit ? strings.EDIT : strings.ADD}
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

export default { AddWageModal };
