import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Col, Row, TimePicker, DatePicker, Select, Badge, Form, Flex } from 'antd';
import { dateFormat } from 'helpers/dates';
import { Room } from 'types/Schedule';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Schedule } from 'api/Schedule';
import { getDateForSchedule } from 'helpers/getDateForSchedule';
import { FormInstance } from 'antd/es/form';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { StopOutlined } from '@ant-design/icons';
dayjs.extend(isSameOrBefore);

interface TimeFormProps {
  form: FormInstance;
  dateStart: string;
  dateEnd: string;
  room?: Room;
  branch: number;
  setDateStartValue?: (val: string) => void;
}

const { Option } = Select;

const TimeRoomSlotFields: React.FC<TimeFormProps> = ({ form, dateStart, dateEnd, branch, setDateStartValue }) => {
  const [strings] = useLanguageContext();
  const [rooms, setRooms] = useState<Room[]>([]);
  const [hasLoadingRooms, setHasLoadingRooms] = useState<boolean>(false);

  const getRoomsByDatesAndBranch = useCallback(async () => {
    const day = form.getFieldValue('day');
    const startTime = form.getFieldValue('startTime');
    const endTime = form.getFieldValue('endTime');

    if (!day || !startTime || !endTime) return;

    const dateStartTime = getDateForSchedule(day, startTime);
    const dateEndTime = getDateForSchedule(day, endTime);

    setHasLoadingRooms(true);
    try {
      const { data } = await Schedule.getRooms({
        dateStart: dateStartTime,
        dateEnd: dateEndTime,
        branch
      });
      setRooms(data);
    } finally {
      setHasLoadingRooms(false);
    }
  }, [branch, form]);

  useEffect(() => {
    if (dateStart && dateEnd) {
      form.setFieldsValue({
        day: dayjs(dateStart),
        startTime: dayjs(dateStart),
        endTime: dayjs(dateEnd)
      });
      getRoomsByDatesAndBranch();
    }
  }, [dateStart, dateEnd, form]);

  const handleDateFieldsChange = () => {
    const day = form.getFieldValue('day');
    const startTime = form.getFieldValue('startTime');
    const endTime = form.getFieldValue('endTime');

    if (day && startTime && endTime) {
      const dateStartTime = getDateForSchedule(day, startTime);
      setDateStartValue && setDateStartValue(dateStartTime);
      getRoomsByDatesAndBranch();
    }
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col lg={6} xs={24}>
          <Form.Item
            name="day"
            label={strings.DATE}
            rules={[{ required: true, message: `${strings.DATE} ${strings.IS_REQUIRED}` }]}
          >
            <DatePicker format={dateFormat} allowClear={false} onChange={handleDateFieldsChange} />
          </Form.Item>
        </Col>
        <Col lg={4} xs={24}>
          <Form.Item
            name="startTime"
            label={strings.START_TIME}
            rules={[{ required: true, message: `${strings.START_TIME} ${strings.IS_REQUIRED}` }]}
          >
            <TimePicker format="HH:mm" allowClear={false} needConfirm={false} onChange={handleDateFieldsChange} />
          </Form.Item>
        </Col>
        <Col lg={4} xs={24}>
          <Form.Item
            name="endTime"
            label={strings.END_TIME}
            rules={[
              { required: true },
              {
                validator: (_, value) => {
                  const startTime = form.getFieldValue('startTime');
                  if (startTime && dayjs(value).isSameOrBefore(dayjs(startTime))) {
                    return Promise.reject(strings.THE_END_TIME_SHOULD_NOT_BE_EQUAL_TO_THE_START_TIME);
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <TimePicker format="HH:mm" allowClear={false} needConfirm={false} onChange={handleDateFieldsChange} />
          </Form.Item>
        </Col>
        <Col lg={10} xs={24}>
          <Form.Item
            name="room"
            label={strings.PLACE}
            rules={[{ required: true, message: `${strings.PLACE} ${strings.IS_REQUIRED}` }]}
          >
            <Select placeholder={strings.SELECT_ROOM} loading={hasLoadingRooms}>
              {rooms.map(room => (
                <Option value={room.id} key={room.id}>
                  <Flex gap={5}>
                    {room.name} <Badge color={room.color} />
                    {room?.isBusy && (
                      <>
                        <StopOutlined style={{ color: '#f5222d' }} /> (busy)
                      </>
                    )}
                  </Flex>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default TimeRoomSlotFields;
