import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Row, Col, Button, Typography, Empty } from 'antd';
import { isEmpty } from 'lodash';
import { Avatar } from 'Global/components/Avatar';
import { Attachment } from 'types/materials';
import { Teacher } from 'types/Teachers/teacher';
import { downloadSomeFile } from 'helpers';
import { useLanguageContext } from '../../contexts/LanguageContext';

const { Text } = Typography;

interface DownloadFormProps {
  title: string;
  teacher: Teacher;
  material: { comment?: string; attachment: Attachment[] };
}

/**
 * @description Формирование форм загрузки домашнего задания для родителя и студента
 * @param {object} props
 * @return {React.ReactNode}
 */
export const DownloadForm = (props: DownloadFormProps) => {
  const { title, material, teacher } = props;
  const [strings] = useLanguageContext();

  /**
   * @description Метод для загрузки материала
   * @param {string} filename
   * @param {number} id
   * @returns {Promise<any>}
   */
  const onMaterialDownload = async (id: number, filename: string) => {
    await downloadSomeFile(`/api/v1/attachment/download/${id}`, filename, null, null, true);
  };

  return (
    <Row>
      <Col lg={22} style={{ background: '#F4F4F4', padding: '20px' }}>
        <Row style={{ margin: '10px 0' }}>
          <b>{title}</b>
        </Row>
        {!isEmpty(material.attachment) ? (
          material.attachment.map(item => (
            <p>
              <Text>{item.displayName}</Text>
              <Button
                icon={<DownloadOutlined />}
                size="small"
                style={{ marginInlineStart: 6, color: '#1890ff' }}
                onClick={() => onMaterialDownload(item.id, item.displayName)}
              />
            </p>
          ))
        ) : (
          <Empty description={strings.NO_DATA} />
        )}
        {material?.comment && (
          <Row align="middle" style={{ margin: '20px 0' }}>
            <Col>
              <Avatar photo={teacher.mainPhoto} size={30} />
            </Col>
            <Col style={{ flex: 1 }}>
              <div style={{ marginInlineStart: 20 }}>{material?.comment}</div>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};
export default { DownloadForm };
