import React from 'react';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Button, Input, Row, Col, Radio, message, Tooltip, Typography } from 'antd';
import { IExpertAssessment } from 'types/accessment';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { scrollTop } from 'helpers/scroll';
import { useLanguageContext } from 'contexts/LanguageContext';
import { isEmpty } from 'lodash';
import { useAssessmentsRepository } from 'repos/AssessmentsRepository';
import { QuickTips } from 'Global/components/QuickTips';
import {
  QUICK_TIPS_EXPERT_ASSESSMENT_CLASSROOM_MATERIALS_AND_HW,
  QUICK_TIPS_EXPERT_ASSESSMENT_LESSON
} from 'Global/constants';
import { useUserContext } from 'contexts/UserContext';
import { StyledForm } from '../index';
import { StyledSeparator } from 'Global/styles';
import styled from 'styled-components';
import { checkTypeForInput } from 'helpers/booleanFomatValues';

const { Text } = Typography;

const StTitleCol = styled(Col)`
  display: flex;
  justify-content: space-between;
`;

const StTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #262626;
`;

export const getRadioBtnGroup = () => (
  <Radio.Group>
    <Radio.Button value="ni">Ni</Radio.Button>
    <Radio.Button value="s">S</Radio.Button>
    <Radio.Button value="g">G</Radio.Button>
  </Radio.Group>
);

interface ExpertAssessmentProps extends FormComponentProps {
  expertAssessment: IExpertAssessment;
}

/**
 * @description ExpertAssessment component
 * @return {React.ReactNode}
 */
const ExpertAssessmentTemplate = (props: ExpertAssessmentProps) => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const hasRiga = user?.branch?.id === 5;
  const assessmentsRepository = useAssessmentsRepository();

  const {
    form: { getFieldDecorator, setFieldsValue, validateFieldsAndScroll },
    expertAssessment: {
      type,
      expertCurriculum,
      expertLessonOutcomes,
      expertLessonConnected,
      expertLessonConsolidation,
      expertMethodsChoice,
      expertTeacherPronounces,
      expertSubjectContradictions,
      expertMaterialsAvailable,
      expertMaterialsCorresponds,
      expertMaterialsInaccuracies,
      expertHomeworkAttached,
      expertHomeworkConnected,
      expertHomeworkHelps,
      expertMaterialsAndHomeworkComment,
      expertFinalMark,
      expertRecommendation,
      id
    }
  } = props;

  /**
   * @description Очистка формы
   * @param {string} fieldName
   * @return {void}
   */
  const clearFieldByName = (fieldName: string): void => setFieldsValue({ [fieldName]: '' });

  /**
   * @description Обработчик на submit формы
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values) => {
      message.loading(strings.EXPERT_ASSESSMENT_UPDATING, 1);
      if (isEmpty(errors)) {
        try {
          await assessmentsRepository.editAssessmentByLessonId(id, { type, ...values });
          message.success(strings.EXPERT_ASSESSMENT_UPDATING);
          scrollTop();
        } catch {}
      }
    });
  };

  return (
    <StyledForm layout="vertical" onSubmit={handleSubmit}>
      <Row>
        <StTitleCol lg={24}>
          <StTitle>
            {strings.LESSON_VIDEO}
            <Tooltip overlayStyle={{ minWidth: 400 }} title={strings.WATCH_SEVERAL_EXCERPTS}>
              <InfoCircleTwoTone style={{ fontSize: 22, margin: 10 }} twoToneColor="#1890ff" />
            </Tooltip>
          </StTitle>
          {!hasRiga && <QuickTips url={QUICK_TIPS_EXPERT_ASSESSMENT_LESSON} />}
        </StTitleCol>
        <Col lg={24}>
          <MyFormItem label={strings.THE_CONTENT_OF_THE_LESSON_CORRESPONDS_TO_THE_CURRICULUM}>
            {getFieldDecorator('expertCurriculum', {
              initialValue: expertCurriculum,
              rules: [{ required: false }]
            })(getRadioBtnGroup())}
            <Button
              size="middle"
              style={{ marginInlineStart: 10 }}
              onClick={() => clearFieldByName('expertCurriculum')}
            >
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem label={strings.THE_LESSON_OUTCOMES_ARE_ACHIEVED}>
            {getFieldDecorator('expertLessonOutcomes', {
              initialValue: expertLessonOutcomes
            })(getRadioBtnGroup())}
            <Button
              size="middle"
              style={{ marginInlineStart: 10 }}
              onClick={() => clearFieldByName('expertLessonOutcomes')}
            >
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem label={strings.THE_LESSON_IS_CONNECTED_WITH_THE_PREVIOUS_LESSONS_OF_THE_CYCLE}>
            {getFieldDecorator('expertLessonConnected', {
              initialValue: expertLessonConnected,
              rules: [{ required: false }]
            })(getRadioBtnGroup())}
            <Button
              size="middle"
              style={{ marginInlineStart: 10 }}
              onClick={() => clearFieldByName('expertLessonConnected')}
            >
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem
            label={strings.THERE_IS_A_CONSOLIDATION_OF_WHAT_HAS_BEEN_LEARNT_AND_FEEDBACK_AT_THE_END_OF_THE_LESSON}
          >
            {getFieldDecorator('expertLessonConsolidation', {
              initialValue: expertLessonConsolidation,
              rules: [{ required: false }]
            })(getRadioBtnGroup())}
            <Button
              size="middle"
              style={{ marginInlineStart: 10 }}
              onClick={() => clearFieldByName('expertLessonConsolidation')}
            >
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem label={strings.THE_TEACHERS_CHOICE_OF_METHODS_IS_OPTIMAL}>
            {getFieldDecorator('expertMethodsChoice', {
              initialValue: expertMethodsChoice,
              rules: [{ required: false }]
            })(getRadioBtnGroup())}
            <Button
              size="middle"
              style={{ marginInlineStart: 10 }}
              onClick={() => clearFieldByName('expertMethodsChoice')}
            >
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem label={strings.THE_TEACHER_PRONOUNCES_THE_TERMS_AND_KEY_WORDS_CORRECTLY}>
            {getFieldDecorator('expertTeacherPronounces', {
              initialValue: expertTeacherPronounces,
              rules: [{ required: false }]
            })(getRadioBtnGroup())}
            <Button
              size="middle"
              style={{ marginInlineStart: 10 }}
              onClick={() => clearFieldByName('expertTeacherPronounces')}
            >
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem label={strings.THERE_ARE_NO_CONTRADICTIONS_WITHIN_THE_SUBJECT}>
            {getFieldDecorator('expertSubjectContradictions', {
              initialValue: expertSubjectContradictions,
              rules: [{ required: false }]
            })(getRadioBtnGroup())}
            <Button
              size="middle"
              style={{ marginInlineStart: 10 }}
              onClick={() => clearFieldByName('expertSubjectContradictions')}
            >
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
      </Row>
      <StyledSeparator />
      <Row>
        <StTitleCol lg={24}>
          <StTitle>
            {strings.CLASSROOM_MATERIALS_AND_HOMEWORK_ATTACHED_TO_THIS_AND_2_PREVIOUS_CLASSES}
            <Tooltip
              title={
                strings.DOWNLOAD_CLASSROOM_AND_HOMEWORK_MATERIALS_ATTACHED_TO_THIS_AND_TWO_PREVIOUS_LESSONS_YOU_CAN_ALSO_CHECK_THE_TEACHERS_REGISTERS_TO_MAKE_SURE_THE_MATERIALS_CORRESPOND_TO_THE_CURRICULUM
              }
            >
              <InfoCircleTwoTone style={{ fontSize: 22, margin: 10 }} twoToneColor="#1890ff" />
            </Tooltip>
          </StTitle>
          {!hasRiga && <QuickTips url={QUICK_TIPS_EXPERT_ASSESSMENT_CLASSROOM_MATERIALS_AND_HW} />}
        </StTitleCol>
        <Col lg={24}>
          <MyFormItem label={strings.THE_CLASSROOM_MATERIALS_ARE_IN_THE_LESSON_THEY_ARE_AVAILABLE_TO_STUDENTS}>
            {getFieldDecorator('expertMaterialsAvailable', {
              initialValue: expertMaterialsAvailable,
              rules: [{ required: false }]
            })(getRadioBtnGroup())}
            <Button
              size="middle"
              style={{ marginInlineStart: 10 }}
              onClick={() => clearFieldByName('expertMaterialsAvailable')}
            >
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem label={strings.THE_MATERIALS_CORRESPOND_TO_THE_PROGRAM}>
            {getFieldDecorator('expertMaterialsCorresponds', {
              initialValue: expertMaterialsCorresponds,
              rules: [{ required: false }]
            })(getRadioBtnGroup())}
            <Button
              size="middle"
              style={{ marginInlineStart: 10 }}
              onClick={() => clearFieldByName('expertMaterialsCorresponds')}
            >
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem label={strings.THERE_ARE_NO_INACCURACIES_OR_OTHER_FLAWS_IN_THE_MATERIALS}>
            {getFieldDecorator('expertMaterialsInaccuracies', {
              initialValue: expertMaterialsInaccuracies,
              rules: [{ required: false }]
            })(getRadioBtnGroup())}
            <Button
              size="middle"
              style={{ marginInlineStart: 10 }}
              onClick={() => clearFieldByName('expertMaterialsInaccuracies')}
            >
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem label={strings.THE_HOMEWORK_IS_ATTACHED_OR_THERE_IS_A_DESCRIPTION_OF_WHAT_TO_DO}>
            {getFieldDecorator('expertHomeworkAttached', {
              initialValue: expertHomeworkAttached,
              rules: [{ required: false }]
            })(getRadioBtnGroup())}
            <Button
              size="middle"
              style={{ marginInlineStart: 10 }}
              onClick={() => clearFieldByName('expertHomeworkAttached')}
            >
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem label={strings.THE_HOMEWORK_IS_CONNECTED_TO_THE_TOPIC_OF_THE_LESSON}>
            {getFieldDecorator('expertHomeworkConnected', {
              initialValue: expertHomeworkConnected,
              rules: [{ required: false }]
            })(getRadioBtnGroup())}
            <Button
              size="middle"
              style={{ marginInlineStart: 10 }}
              onClick={() => clearFieldByName('expertHomeworkConnected')}
            >
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem label={strings.THE_HOMEWORK_HELPS_THE_STUDENT_TO_REVISE_THE_MATERIAL_OF_THE_LESSON}>
            {getFieldDecorator('expertHomeworkHelps', {
              initialValue: expertHomeworkHelps,
              rules: [{ required: false }]
            })(getRadioBtnGroup())}
            <Button
              size="middle"
              style={{ marginInlineStart: 10 }}
              onClick={() => clearFieldByName('expertHomeworkHelps')}
            >
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
        <Col lg={24}>
          <MyFormItem label={strings.COMMENT_FOR_ADM}>
            {getFieldDecorator('expertMaterialsAndHomeworkComment', {
              initialValue: checkTypeForInput(expertMaterialsAndHomeworkComment),
              rules: [{ required: false }]
            })(<Input.TextArea placeholder={strings.COMMENT} />)}
          </MyFormItem>
        </Col>
      </Row>
      <Row>
        <Col lg={24} style={{ background: '#e6f7ff', padding: '10px 20px 30px 20px', borderRadius: '5px' }}>
          <MyFormItem
            label={
              <>
                {strings.SUMMARY_COMMENTS}
                <Text type="secondary" style={{ float: 'right', fontSize: '12px' }}>
                  {strings.ONLY_THIS_PART_IS_VISIBLE_TO_THE_TEACHER}
                </Text>
              </>
            }
          >
            {getFieldDecorator('expertRecommendation', {
              initialValue: checkTypeForInput(expertRecommendation),
              rules: [{ required: false }]
            })(<Input.TextArea style={{ height: 300, width: '100%' }} placeholder={strings.COMMENT} />)}
          </MyFormItem>
          <MyFormItem label={strings.FINAL_MARK}>
            {getFieldDecorator('expertFinalMark', {
              initialValue: expertFinalMark,
              rules: [{ required: false }]
            })(getRadioBtnGroup())}
            <Button size="middle" style={{ marginInlineStart: 10 }} onClick={() => clearFieldByName('expertFinalMark')}>
              {strings.CLEAR}
            </Button>
          </MyFormItem>
        </Col>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Col lg={24}>
          <Button type="primary" size="large" htmlType="submit">
            {strings.SAVE}
          </Button>
        </Col>
      </Row>
    </StyledForm>
  );
};

export const ExpertAssessment = Form.create<ExpertAssessmentProps>({})(ExpertAssessmentTemplate);

export default { ExpertAssessment };
