import React, { useContext } from 'react';
import { UserBlock, NewMessageIndicator } from './styles';
import AvatarWithUserName from '../AvatarWithUserName';
import { Parent } from 'types';
import { Teacher } from 'types/Teachers/teacher';
import { BASE_GRAY } from '../../colors';
import ChatContext from './chatContext';

interface UserProps {
  isThreadActive: boolean;
  isRead: boolean;
  threadId: number;
  showDialogClick: () => void;
  user: Parent | Teacher;
  subject: string;
}

/**
 * @description Информация о пользователе в чате
 * @param {UserProps} props
 * @return {React.ReactNode}
 */
export const User = ({ showDialogClick, isRead, user, threadId, subject }: UserProps) => {
  const { activeThreadId } = useContext(ChatContext);
  const hasNewMessage = !isRead;

  return (
    <UserBlock onClick={showDialogClick} style={{ ...(activeThreadId === threadId && { backgroundColor: BASE_GRAY }) }}>
      <AvatarWithUserName name={user?.name} surname={user?.surname} photo={user?.mainPhoto} extraInfo={subject} />
      {hasNewMessage && <NewMessageIndicator />}
    </UserBlock>
  );
};

export default { User };
