import React, { useState } from 'react';
import { match } from 'react-router';
import { PageHeader } from '@ant-design/pro-layout';
import { Button, message, Row, Spin, Form } from 'antd';
import { Courses } from 'api';
import { CourseGroupParams } from 'types/education';
import * as courses from '../routes';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Container } from 'Global/GlobalStyle';
import { GroupForm } from './GroupForm';

/**
 * @description Компонент редактирования курса
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const EditCourseGroup = () => {
  const [form] = Form.useForm();
  const [strings] = useLanguageContext();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();
  const { push, goBack } = useHistory();

  /**
   * @description Запрос на редактирование Group course
   * @param {object} fields
   * @return {Promise<any>}
   */
  const handleSubmit = async (params: CourseGroupParams): Promise<any> => {
    message.loading(strings.GROUP_COURSE_EDITING);
    setLoading(true);
    try {
      await Courses.editCourseGroup(id, params);
      message.success(strings.GROUP_COURSE_EDIT);
      push(`${courses.BASE_COURSE_GROUP}/${id}`);
    } catch {
      message.error(strings.GROUP_COURSE_EDIT_ERROR);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading} tip={strings.LOADING_DATA}>
      <Container>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <PageHeader style={{ padding: 0 }} onBack={goBack} title={strings.EDIT_GROUP_COURSE} />
          <GroupForm courseGroupId={id} />
          <Row style={{ textAlign: 'right' }}>
            <Button type="primary" size="large" htmlType="submit">
              {strings.EDIT}
            </Button>
          </Row>
        </Form>
      </Container>
    </Spin>
  );
};
export default { EditCourseGroup };
