import React from 'react';
import { Form, Input } from 'antd';
import GradeListMemo from './GradeList';

import { OriginalLessonOutcomeItem } from 'types/TeacherAccount/Courses';
import { IOutcomesForStudent } from './index';

interface OutcomeCellProps {
  record: IOutcomesForStudent;
  recordIndex: number;
  item: OriginalLessonOutcomeItem;
  outcomeIndex: number;
  setHasLessonOutcomesChanged: (val: boolean) => void;
  hasPrimaryOrSchool: boolean;
}
const OutcomeCell: React.FC<OutcomeCellProps> = React.memo(
  ({ record, recordIndex, item, outcomeIndex, setHasLessonOutcomesChanged, hasPrimaryOrSchool }) => (
    <>
      <Form.Item name={['data', recordIndex, 'student']} style={{ display: 'none' }} initialValue={record.student?.id}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name={['data', recordIndex, 'outcomes', outcomeIndex, 'id']}
        style={{ display: 'none' }}
        initialValue={item.id}
      >
        <Input type="hidden" />
      </Form.Item>
      <GradeListMemo
        outcomesRequired={record.outcomesRequired}
        setHasLessonOutcomesChanged={setHasLessonOutcomesChanged}
        name={['data', recordIndex, 'outcomes', outcomeIndex, 'grade']}
        hasColorGradelist={hasPrimaryOrSchool}
      />
    </>
  )
);

export default OutcomeCell;
