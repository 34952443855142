import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, Button, Input, message } from 'antd';
import { ThreadsParams } from 'types/CRM/messages';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { useThreadsRepository } from 'repos/ThreadsRepository';

interface ChangeMessageProps {
  hasShow: boolean;
  hideModal: () => void;
  message: string;
  getThreads: (params?: ThreadsParams) => Promise<any>;
  id: number; // Message id
}

/**
 * @description Компонент редактирования сообщения
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const ChangeMessage = (props: ChangeMessageProps) => {
  const [strings] = useLanguageContext();
  const { locationSearchParams } = useLocationSearchParams();
  const messagesFromParentRepository = useThreadsRepository();
  const [body, setBody] = useState<string>('');
  const { hasShow, hideModal, message: currentMessage, id, getThreads } = props;

  /**
   * @description Запрос на редактирование сообщения
   * @return {Promise<any>}
   */
  const editMessage = async (): Promise<any> => {
    message.loading(strings.EDIT_MESSAGE, 2);
    try {
      await messagesFromParentRepository.editMessage(id, { body });
      message.success(strings.MESSAGE_EDIT, 2);
      hideModal();
      getThreads(locationSearchParams);
    } catch {
      message.success(strings.EDIT_ERROR, 2);
    }
  };

  /**
   * @description Обработчик на редактирование текстового поля
   * @param {React.ChangeEvent<HTMLTextAreaElement>} e
   * @return {void}
   */
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setBody(e.target.value);
  };

  return (
    <div onClick={e => e.stopPropagation()}>
      <Modal
        footer={[
          <Button type="primary" key="btnEdit" onClick={editMessage}>
            {strings.EDIT}
          </Button>,
          <Button key="btnCancel" onClick={hideModal}>
            {strings.CANCEL}
          </Button>
        ]}
        width={650}
        open={hasShow}
        onCancel={hideModal}
      >
        <Form layout="vertical">
          <MyFormItem label={strings.CHANGE_MESSAGE}>
            <Input.TextArea style={{ height: 300 }} onChange={handleChange} defaultValue={currentMessage} />
          </MyFormItem>
        </Form>
      </Modal>
    </div>
  );
};

export default { ChangeMessage };
