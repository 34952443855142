import React from 'react';

import {
  BarsOutlined,
  CarryOutOutlined,
  DollarOutlined,
  ScheduleOutlined,
  ThunderboltOutlined,
  UserOutlined,
  ProjectOutlined
} from '@ant-design/icons';

import { Link } from 'react-router-dom';
import * as teacher from './routes';
import { SCHEDULE } from 'Global/modules/Schedule/routes';
import { branchesWithNoFinances } from 'Teacher/global/constants';
import { User } from 'types/user';
import { BASE_MATERIALS } from '../Admin/Education/routes';
import { hasMoscowBranch } from '../helpers';
import { cleanPath } from 'Global/components/LeftMenu/helper';
import { BASE_ONLINE_ACTIVITIES } from '../Global/routes';
import { BASE_ASSISTANTS_FEEDBACK } from '../Admin/Monitoring/routes';
import { CALENDAR } from '../Student&Parent/routes';

export const menuListForTeacher = (strings, user: User) => {
  const userBranch = user?.branch?.name;
  const hideTeacherFinance = user?.branch?.hideTeacherFinance;

  return [
    {
      key: cleanPath(SCHEDULE.substring(1)),
      title: (
        <Link to={SCHEDULE}>
          <ScheduleOutlined />
          <span>{strings.SCHEDULE}</span>
        </Link>
      )
    },
    hasMoscowBranch(user?.branch) && {
      key: cleanPath(CALENDAR.substring(1)),
      title: (
        <Link to={CALENDAR}>
          <CarryOutOutlined />
          <span>{strings.EVENTS}</span>
        </Link>
      )
    },
    !branchesWithNoFinances.includes(userBranch) &&
      !hideTeacherFinance && {
        key: cleanPath(teacher.BASE_FINANCE.substring(1)),
        title: (
          <Link to={teacher.BASE_FINANCE}>
            <DollarOutlined />
            <span>{strings.FINANCE}</span>
          </Link>
        )
      },
    {
      key: 4,
      title: (
        <span>
          <BarsOutlined />
          <span>{strings.ACADEMIC}</span>
        </span>
      ),
      subMenu: [
        {
          key: cleanPath(teacher.BASE_GROUP_COURSES.substring(1)),
          title: strings.GROUP_COURSES,
          link: teacher.BASE_GROUP_COURSES
        },
        {
          key: cleanPath(teacher.BASE_TEACHER_ORIGINAL_COURSES.substring(1)),
          title: strings.ORIGINAL_COURSES,
          link: teacher.BASE_TEACHER_ORIGINAL_COURSES
        },
        {
          key: cleanPath(BASE_MATERIALS.substring(1)),
          title: (
            <Link to={BASE_MATERIALS}>
              <span>{strings.MATERIALS}</span>
            </Link>
          )
        },
        {
          key: cleanPath(BASE_ONLINE_ACTIVITIES.substring(1)),
          title: (
            <Link to={BASE_ONLINE_ACTIVITIES}>
              <span>{strings.ONLINE_ACTIVITIES}</span>
            </Link>
          )
        },
        {
          key: cleanPath(teacher.BASE_TRAININGS.substring(1)),
          title: (
            <Link to={teacher.BASE_TRAININGS}>
              <span>{strings.EDUCATION}</span>
            </Link>
          )
        },
        {
          key: cleanPath(teacher.BASE_GRADING.substring(1)),
          title: (
            <Link to={teacher.BASE_GRADING}>
              <span>{strings.GRADING}</span>
            </Link>
          )
        }
      ]
    },
    {
      key: cleanPath(teacher.BASE_WORK_PROGRESS.substring(1)),
      title: (
        <Link to={teacher.BASE_WORK_PROGRESS}>
          <ThunderboltOutlined />
          <span>{strings.WORK_PROGRESS}</span>
        </Link>
      )
    },
    {
      key: cleanPath(BASE_ASSISTANTS_FEEDBACK.substring(1)),
      title: (
        <Link to={BASE_ASSISTANTS_FEEDBACK}>
          <UserOutlined />
          <span>{strings.ASSISTANTS_FEEDBACK}</span>
        </Link>
      )
    },
    {
      key: cleanPath(teacher.BASE_PROGRESS_REPORT.substring(1)),
      title: (
        <Link to={teacher.BASE_PROGRESS_REPORT}>
          <ProjectOutlined />
          <span>{strings.PROGRESS_REPORTS}</span>
        </Link>
      )
    }
  ];
};

export default { menuListForTeacher };
