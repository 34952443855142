import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Table, Typography } from 'antd';
import { Grading as StGrading } from '../styles';
import Breadcrumbs from 'Global/components/Breadcrumbs';
import Filter from './Filter';
import { columns } from './columns';
import { Location, History } from 'history';
import { useLastLocation } from 'react-router-last-location';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Title } = Typography;
const { Column } = Table;

interface gradingProps {
  location: Location;
  history: History;
}

/**
 * @param {object} location
 * @param {function} goBack
 * @return {React.ReactNode}
 */
export const Grading = ({ location, history: { goBack } }: gradingProps) => {
  const [strings] = useLanguageContext();
  const handleBack = () => goBack();
  const lastLocation = useLastLocation();

  const tableProps = {
    dataSource: []
  };

  return (
    <StGrading>
      <Breadcrumbs location={location} />
      <Title level={3}>
        {lastLocation && <ArrowLeftOutlined onClick={handleBack} />} {strings.GRADING}
      </Title>
      <Filter />
      <Table {...tableProps}>
        {columns(strings).map(col => (
          <Column key={col.key} {...col} />
        ))}
      </Table>
    </StGrading>
  );
};

export default { Grading };
