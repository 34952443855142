export const PARENT_MAIN = '/main';
export const JOURNAL = '/journal';
export const SCHEDULE = '/schedule';
export const BASE_FINANCE = '/people-finance';
export const NEWS = '/news';
export const BASE_REPORT_ON_PROGRESS = '/people-work-progress';
export const HOW_TO_LG_LINK = '/how-to-lg';
export const UPLOAD_HOMEWORK = '/homework';
export const DOWNLOAD_HOMEWORK = '/schedule/homework';
export const INVOICES = '/invoices';
export const CALENDAR = '/calendar';
export const EDIT_PARENT_PROFILE = '/profile/edit';
export const CONTRACT = '/contract';

export default {
  PARENT_MAIN,
  JOURNAL,
  BASE_FINANCE,
  SCHEDULE,
  NEWS,
  BASE_REPORT_ON_PROGRESS,
  HOW_TO_LG_LINK,
  UPLOAD_HOMEWORK,
  DOWNLOAD_HOMEWORK,
  INVOICES,
  CALENDAR,
  EDIT_PARENT_PROFILE,
  CONTRACT
};
