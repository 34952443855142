import React, { useEffect, useState } from 'react';
import { useUserContext } from 'contexts/UserContext';
import { useThreadsRepository } from '../repos/ThreadsRepository';

const ThreadsContext = React.createContext(null);

export const ThreadsContextProvider = props => {
  const [user] = useUserContext();
  const [unreadThreads, setUnreadThreads] = useState<number>(0);
  const threadsRepository = useThreadsRepository();

  useEffect(() => {
    (async () => {
      // Если пользователь учитель или родитель посчитаем кол-во непрочитаных сообщений
      if (user?.hasRoles?.teacher || user?.hasRoles?.parent) {
        try {
          const {
            data: { items }
          } = await threadsRepository.getMyThreads();
          setUnreadThreads(items.filter(t => !t.isRead).length || 0);
        } catch {}
      }
    })();
  }, [user]);

  const provider = [unreadThreads, setUnreadThreads];

  return <ThreadsContext.Provider value={provider}>{props.children}</ThreadsContext.Provider>;
};

export const useThreadsContext = () => {
  const service = React.useContext(ThreadsContext);

  if (!service) {
    throw new Error('Threads context is unavailable');
  }

  return service;
};
