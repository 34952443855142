import dayjs from 'dayjs';
import { dateFormat } from 'helpers/dates';
import { FamilyCollabHystoryTitleForType } from '../../Family/CollaborationHistory';
import React from 'react';
import { CheckCircleTwoTone, MinusCircleTwoTone } from '@ant-design/icons';

export const getColumns = strings => [
  {
    title: strings.DATE,
    dataIndex: 'date',
    key: 'date',
    width: 120,
    render: (date: string) => date && dayjs(date).format(dateFormat)
  },
  {
    title: strings.TYPE,
    dataIndex: 'type',
    key: 'type',
    width: 100,
    render: (type: string) => FamilyCollabHystoryTitleForType[type] || type
  },
  {
    title: strings.AVAILABLE_TO_TEACHER,
    dataIndex: 'visibleToTeacher',
    key: 'visibleToTeacher',
    width: 200,
    className: 'clmn-center',
    render: (visibleToTeacher: boolean) =>
      visibleToTeacher ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <MinusCircleTwoTone twoToneColor="#f5222d" />
  },
  {
    title: strings.DESCRIPTION,
    dataIndex: 'description',
    key: 'description',
    render: description => <div dangerouslySetInnerHTML={{ __html: description }} />
  }
];

export default { getColumns };
