import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Empty } from 'antd';
import ChatContext from './chatContext';
import { MessagesContainer, ChatHeader, ChatContent } from './styles';
import { LoadingItems } from 'Global/components/LoadingItems';
import { ListMessages } from './ListMessages';
import { Thread } from 'types/ParentJournal';
import { useLanguageContext } from 'contexts/LanguageContext';
import { SendMessageForm } from './SendMessageForm';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useThreadsRepository } from 'repos/ThreadsRepository';

/**
 * @description Диалог
 * @return {React.ReactNode}
 */
export const Dialog = () => {
  const [strings] = useLanguageContext();
  const [isLoadingDialog, setIsLoadingDialog] = useState<boolean>(false);
  const [thread, setThread] = useState<Thread>(null);
  const { activeThreadId, setActiveThreadId, threads, readThread } = useContext(ChatContext);
  const threadsRepository = useThreadsRepository();

  /**
   * @description Получение треда по id
   * @param {number} id
   */
  const getThreadById = useCallback(
    async (id: number) => {
      try {
        setIsLoadingDialog(true);
        const { data } = await threadsRepository.getThreadById(id);
        const activeThread = threads.find((thread: Thread) => thread.id === activeThreadId);
        setThread({
          ...data,
          parent: activeThread.parent,
          teacher: activeThread.teacher
        });

        if (!activeThread.isRead) {
          readThread(activeThreadId);
        }
      } catch {
      } finally {
        setIsLoadingDialog(false);
      }
    },
    [activeThreadId]
  );

  useEffect(() => {
    if (activeThreadId) {
      getThreadById(activeThreadId);
    }
  }, [activeThreadId]);

  // Если тред не выбран, показывать сообщение выберите тред
  if (!activeThreadId) {
    return (
      <ChatContent>
        <Empty style={{ margin: 'auto' }} description={strings.YOU_DONT_HAVE_ACTIVE_CONVERSATIONS} />
      </ChatContent>
    );
  }

  return (
    <ChatContent>
      {isLoadingDialog ? (
        <LoadingItems rows={2} count={2} />
      ) : (
        <>
          <ChatHeader>
            <ArrowLeftOutlined onClick={() => setActiveThreadId(null)} />
            {isLoadingDialog ? <LoadingItems /> : <>{thread?.subject ?? ''}</>}
          </ChatHeader>
          <MessagesContainer>
            <ListMessages thread={thread} />
          </MessagesContainer>
          <SendMessageForm getThreadById={getThreadById} />
        </>
      )}
    </ChatContent>
  );
};

export default { Dialog };
