import { BASE_ADMINS, ADMIN_ADD, BASE_ADMIN_EDIT, BASE_ADMIN } from 'Admin/People/Administrators/routes';
import { Administrators } from 'Admin/People/Administrators';
import { AdminPage } from 'Admin/People/Administrators/Admin';
import { AdminForm } from 'Admin/People/Administrators/Admin/AdminForm';

export const administrators = [
  {
    path: BASE_ADMINS,
    component: Administrators,
    exact: true
  },
  {
    path: [ADMIN_ADD, BASE_ADMIN_EDIT],
    component: AdminForm,
    exact: true
  },
  {
    path: BASE_ADMIN,
    component: AdminPage,
    exact: true
  }
];

export default { administrators };
