import { RateItemType } from 'Admin/People/Families/Family/Invoices/types';

export const pricesNames = {
  PRODUCT_TYPE: 'Product (to balance)',
  PERIOD_TYPE: 'Period',
  TRIAL_TYPE: 'Trial',
  DEPOSIT_TYPE: 'Deposit',
  FLEXIBLE_TYPE: 'Flexible'
};

export enum priceTypesById {
  productType = 1,
  periodType = 2,
  trialType = 3,
  depositType = 4,
  flexibleType = 5
}

export enum RateType {
  WithDiscount,
  WithOutDiscount,
  WithOutMonth
}

export const initialRate: RateItemType = {
  id: 0,
  sum: 0,
  discount: 0,
  quantity: 1,
  afterTrial: false
};
