import React from 'react';
import { CalendarOutlined, FileOutlined, GlobalOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Tooltip, Descriptions, Space } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { HeaderAssessmentItem, LessonAssessmentItem } from 'types/accessment';
import { Link } from 'react-router-dom';
import { BASE_TEACHERS } from '../../../People/Teachers/routes';
import { BASE_COURSE_GROUP } from '../../../Courses/routes';
import dayjs from 'dayjs';
import { dateFormat } from 'helpers';

interface HeaderAssessmentProps {
  header: HeaderAssessmentItem;
  lesson: LessonAssessmentItem;
  type: 'lessonDefault' | 'lessonPrimary' | 'lessonComment' | 'lessonNew';
}

/**
 * @description HeaderAssessment component
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
const HeaderAssessment = (props: HeaderAssessmentProps) => {
  const [strings] = useLanguageContext();
  const { header, lesson, type } = props;
  const { lessonAssessment, hwAssessment, expertAssessment, disciplineAssessment, visibleTeacher } = header || {};
  const { videoAvailable, teacher, courseGroup, startAt } = lesson;

  const dateCommon = startAt ? dayjs(startAt).format(dateFormat) : 'No date';
  const dateVideoAvailable = videoAvailable ? dayjs(videoAvailable).format(dateFormat) : 'No date';
  const visibleToTeacher = visibleTeacher ? '✔' : '✖';
  const lessonAssessmentMark = lessonAssessment?.mark.length ? lessonAssessment.mark : '✖';
  const hwAssessmentMark = hwAssessment?.mark.length ? hwAssessment.mark : '✖';
  const expertAssessmentMark = expertAssessment?.mark.length ? expertAssessment.mark : '✖';
  const disciplineAssessmentMark = disciplineAssessment?.mark.length ? disciplineAssessment.mark : '✖';

  return (
    <Descriptions size="small" style={{ marginBottom: 20 }}>
      <Descriptions.Item label={strings.LESSON_DATE}>
        <Link to={`/le/${lesson?.id}`}>
          <Space size={4}>
            <CalendarOutlined />
            {dateCommon}
          </Space>
        </Link>
      </Descriptions.Item>
      <Descriptions.Item label={strings.VIDEO_AVAILABLE}>
        <Space size={4}>
          <CalendarOutlined /> {dateVideoAvailable}
        </Space>
      </Descriptions.Item>
      <Descriptions.Item label={strings.BRANCH}>
        <Space size={4}>
          <GlobalOutlined /> {courseGroup?.branch?.name}
        </Space>
      </Descriptions.Item>
      <Descriptions.Item label={strings.TEACHER}>
        <Link to={`${BASE_TEACHERS}/${teacher?.id}`}>
          <Space size={4}>
            <UserOutlined /> {teacher?.user?.surname} {teacher?.user?.name}
          </Space>
        </Link>
      </Descriptions.Item>
      <Descriptions.Item label={strings.SUBJECT}>
        <Space size={4}>
          <FileOutlined /> {courseGroup?.subject?.name}
        </Space>
      </Descriptions.Item>
      <Descriptions.Item label={strings.GROUP}>
        <Link to={`${BASE_COURSE_GROUP}/${courseGroup?.id}`}>
          <Space size={4}>
            <TeamOutlined /> {courseGroup?.groupName}
          </Space>
        </Link>
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Tooltip
            title={`${strings.LAST_SAVE} ${lessonAssessment?.markLastSave} ${strings.BY} ${lessonAssessment?.adminFullName} `}
          >
            <span style={{ fontWeight: 'inherit' }}>{strings.LESSON_FINAL_MARK}</span>
          </Tooltip>
        }
      >
        {lessonAssessmentMark}
      </Descriptions.Item>
      {type !== 'lessonPrimary' && (
        <Descriptions.Item
          label={
            <Tooltip
              title={`${strings.LAST_SAVE} ${hwAssessment?.markLastSave} ${strings.BY} ${hwAssessment?.adminFullName} `}
            >
              <span style={{ fontWeight: 'inherit' }}>{strings.HW_FINAL_MARK}</span>
            </Tooltip>
          }
        >
          {hwAssessmentMark}
        </Descriptions.Item>
      )}
      <Descriptions.Item
        label={
          <Tooltip
            title={`${strings.LAST_SAVE} ${expertAssessment?.markLastSave} ${strings.BY} ${expertAssessment?.adminFullName} `}
          >
            <span style={{ fontWeight: 'inherit' }}>{strings.EXPERT_FINAL_MARK}</span>
          </Tooltip>
        }
      >
        {expertAssessmentMark}
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <Tooltip
            title={`${strings.LAST_SAVE} ${disciplineAssessment?.markLastSave} ${strings.BY} ${disciplineAssessment?.adminFullName} `}
          >
            <span style={{ fontWeight: 'inherit' }}>{strings.DISCIPLINE_FINAL_MARK}</span>
          </Tooltip>
        }
      >
        {disciplineAssessmentMark}
      </Descriptions.Item>
      <Descriptions.Item label={strings.VISIBLE_TO_TEACHER}>{visibleToTeacher}</Descriptions.Item>
    </Descriptions>
  );
};

export default HeaderAssessment;
