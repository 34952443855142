import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form } from '@ant-design/compatible';
import { DeleteOutlined, EditOutlined, EyeOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Row, Col, Typography, Collapse, Select, Input, message, Button, Modal, Upload, Spin, Flex } from 'antd';
import { useMetaGoalsRepository } from 'repos/MetaGoalsRepository';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { Branch, Stage } from 'types';
import { getFormData } from 'helpers';
import { AttachmentItem } from 'types/global';
import { Global } from 'api';
import { PreviewFileModal } from 'Global/components/FilePreview';
import { AttachmentType, initialAttachment } from '../../../Monitoring/SummaryTable/Show/AttachmentsList';
import { getPreviewUrl } from 'Global/components/FilePreview/helpers';
import { useUserContext } from 'contexts/UserContext';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useAttachmentRepository } from 'repos/AttachmentRepository';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useHistory } from 'react-router-dom';

const { Panel } = Collapse;

const StyledMetaGoals = styled.div`
  margin: 20px;
  padding: 30px 20px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 46px -15px rgb(0 0 0 / 12%);
`;

const { Option } = Select;
const { Title } = Typography;

const AddMetaLevel = ({ fetchMetaGoals }) => {
  const [strings] = useLanguageContext();
  const [stage, setStage] = useState(null);
  const [name, setName] = useState(null);
  const metaGoalsRepo = useMetaGoalsRepository();
  const { stages } = useGlobalCollectionsContext();
  const [hasCreation, setHasCreation] = useState<boolean>(false);

  const onAddMetaLevel = async () => {
    if (!name || !stage) {
      message.error(strings.META_LEVEL_NAME_AND_STAGE_REQUIRED_FIELDS, 2);
      return;
    }

    try {
      setHasCreation(true);
      await metaGoalsRepo.createMetaGoalsLevel({ name, stage });
      await fetchMetaGoals();
      message.success(strings.META_LEVEL_SUCCESSFULLY_CREATED, 2);
      setHasCreation(false);
    } catch {
      setHasCreation(false);
      message.error(strings.META_LEVEL_CREATION_ERROR_TRY_AGAIN_LATER, 2);
    }
  };

  return (
    <Row gutter={[10, 10]}>
      <Col lg={8}>
        <Input placeholder={strings.LEVEL} onChange={({ target: { value } }) => setName(value)} />
      </Col>
      <Col lg={6}>
        <Select placeholder={strings.STAGE} onChange={stage => setStage(stage)}>
          {stages.map((stage: Stage) => (
            <Option key={stage.id} value={stage.id}>
              {stage.name}
            </Option>
          ))}
        </Select>
      </Col>
      <Col lg={6}>
        <Button loading={hasCreation} type="primary" size="large" block onClick={onAddMetaLevel}>
          {strings.ADD_META_GOAL_LEVEL}
        </Button>
      </Col>
    </Row>
  );
};

interface UpdateLevelProps extends FormComponentProps {
  hasShowModal: boolean;
  setHasShowModal: (hasShow: boolean) => any;
  level: any; // TODO
  fetchMetaGoals: () => Promise<any>;
}

const UpdateLevelModal = Form.create<UpdateLevelProps>()(
  ({
    hasShowModal,
    setHasShowModal,
    level: { goals, metaLevelId, name: levelName },
    fetchMetaGoals,
    form: { getFieldDecorator, validateFields }
  }: UpdateLevelProps) => {
    const [hasEditLevel, setHasEditLevel] = useState<boolean>(false);
    const metaGoalsRepo = useMetaGoalsRepository();
    const [strings] = useLanguageContext();

    const onSubmitForm = () => {
      validateFields(async (err, values) => {
        if (!err) {
          try {
            setHasEditLevel(true);
            const {
              level: { name, goals }
            } = values;
            await metaGoalsRepo.updateMetaLevel(metaLevelId, { name });
            await Promise.all(
              goals.map(goal =>
                metaGoalsRepo.updateMetaGoal(goal.id, {
                  ...goal
                })
              )
            );
            await fetchMetaGoals();
            setHasShowModal(false);
            setHasEditLevel(false);
            message.success(strings.META_LEVEL_AND_META_ITEMS_SUCCESSFULLY_UPDATED, 2);
          } catch {
            setHasEditLevel(false);
            setHasShowModal(false);
            message.success(strings.META_LEVEL_UPDATED_ERROR_TRY_AGAIN_LATER, 2);
          }
        }
      });
    };

    const modalProps = {
      title: `${strings.EDIT} ${levelName}`,
      visible: hasShowModal,
      onCancel: () => setHasShowModal(false),
      footer: [
        <Button onClick={onSubmitForm} type="primary" loading={hasEditLevel}>
          {strings.EDIT}
        </Button>
      ],
      width: 700
    };

    return (
      <Modal {...modalProps}>
        <Form layout="vertical">
          <Row>
            <Col lg={24}>
              <MyFormItem label={strings.LEVEL_NAME}>
                {getFieldDecorator('level.name', {
                  initialValue: levelName,
                  rules: [{ required: true, message: strings.LEVEL_NAME_IS_REQUIRED }]
                })(<Input type="text" />)}
              </MyFormItem>
            </Col>
          </Row>
          {goals.isNotEmpty() &&
            goals.map(({ type, description, id }, key) => (
              <>
                <Row key={id.toString()}>
                  <MyFormItem label={strings.GOAL_TYPE} style={{ display: 'none' }}>
                    {getFieldDecorator(`level.goals[${key}].id`, {
                      initialValue: id
                    })(<Input type="hidden" />)}
                  </MyFormItem>
                  <Col lg={8}>
                    <MyFormItem label={strings.GOAL_TYPE}>
                      {getFieldDecorator(`level.goals[${key}].type`, {
                        initialValue: type,
                        rules: [{ required: true, message: strings.GOAL_TYPE_IS_REQUIRED }]
                      })(<Input type="text" placeholder={strings.ENTER_TYPE} />)}
                    </MyFormItem>
                  </Col>
                  <Col lg={14}>
                    <MyFormItem label={strings.GOAL_DESCRIPTION}>
                      {getFieldDecorator(`level.goals[${key}].description`, {
                        initialValue: description,
                        rules: [{ required: true, message: strings.GOAL_DESCRIPTION_IS_REQUIRED }]
                      })(<Input.TextArea placeholder={strings.ENTER_DESCRIPTION} />)}
                    </MyFormItem>
                  </Col>
                </Row>
              </>
            ))}
        </Form>
      </Modal>
    );
  }
);

export const MetaGoals = () => {
  const [strings] = useLanguageContext();
  const metaGoalsRepo = useMetaGoalsRepository();
  const { branches: branchesList } = useGlobalCollectionsContext();
  const [branches, setBranches] = useState<Branch[]>(branchesList);
  const [user] = useUserContext();
  const hasTopManager = user?.hasRoles?.topManager;
  const myBranch = user?.branch;
  const { goBack } = useHistory();

  const [metaGoals, setMetaGoals] = useState([]);
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const [currentLevel, setCurrentLevel] = useState({ name: '', goals: [] });
  const [metaLevelBranches, setMetaLevelBranches] = useState<number[]>([]);
  const [{ confirm }, contextHolder] = Modal.useModal();

  const fetchMetaGoals = async () => {
    try {
      const { data } = await metaGoalsRepo.getMetaGoalsList();
      setMetaGoals(data);
    } catch {
      setMetaGoals([]);
    }
  };

  /**
   * @description Получить все бранчи
   * @returns {Promise<void>}
   */
  const fetchAllBranches = async (): Promise<void> => {
    const { data } = await Global.getBranches({ all: true });
    setBranches(data);
  };

  /**
   * @description Получить бранчи, для которых показывать Meta Level Picture
   * @return {React.ReactNode}
   */
  const getMetaLevelPictureBranches = async () => {
    try {
      const { data } = await metaGoalsRepo.getMetaLevelPictureBranches();
      setMetaLevelBranches(data.map(item => item.id));
    } catch {
      setMetaLevelBranches([]);
    }
  };

  /**
   * @description Установить бранчи, для которых показывать Meta Level Picture   *
   * @param {number[]} value
   * @return {React.ReactNode}
   */
  const changeMetaLevelPictureBranches = async (value: number[]) => {
    try {
      await metaGoalsRepo.setMetaLevelPictureBranches({ branches: value });
      setMetaLevelBranches(value);
    } catch {
      setMetaLevelBranches([]);
    }
  };

  const deleteMetaGoal = async (goalId: number) => {
    confirm({
      title: strings.ARE_YOU_SURE,
      okType: 'danger',
      onOk: async () => {
        try {
          await metaGoalsRepo.deleteMetaGoal(goalId);
          await fetchMetaGoals();
          message.success(strings.GOAL_SUCCESSFULLY_DELETED, 2);
        } catch {
          message.error(strings.GOAL_DELETION_ERROR, 2);
        }
      }
    });
  };

  const deleteMetaGoalLevel = (levelId: number) => {
    confirm({
      title: strings.ARE_YOU_SURE,
      okType: 'danger',
      onOk: async () => {
        try {
          await metaGoalsRepo.deleteMetaLevel(levelId);
          await fetchMetaGoals();
          message.success(strings.LEVEL_SUCCESSFULLY_DELETED, 2);
        } catch {
          message.error(strings.LEVEL_DELETION_ERROR, 2);
        }
      }
    });
  };

  useEffect(() => {
    fetchMetaGoals();
    getMetaLevelPictureBranches();
    //для не топ-менеджеров тоже получаем все филиалы
    if (!hasTopManager) {
      fetchAllBranches();
    }
  }, []);

  const handleShowModal = level => {
    setCurrentLevel(level);
    setHasShowModal(true);
  };

  const MetaGoal = ({ metaLevelId, goals }) => {
    const [strings] = useLanguageContext();
    const attachmentRepository = useAttachmentRepository();
    const [hasAdded, setHasAdded] = useState<boolean>(false);
    const [description, setDescription] = useState<string>(null);
    const [type, setType] = useState<string>(null);
    const [attachmentList, setAttachmentList] = useState<AttachmentItem[]>([]);
    const [hasUploading, setHasUploading] = useState<boolean>(false);
    const [hasAttachLoading, setHasAttachLoading] = useState<boolean>(false);
    //для превью файла
    const [hasShowPreviewModal, setHasShowPreviewModal] = useState<boolean>(false);
    const [hasLoadPreviewAttachment, setHasLoadPreviewAttachment] = useState<boolean>(false);
    const [currentAttachment, setCurrentAttachment] = useState<AttachmentType>(initialAttachment);
    const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer>('');

    const onAddMetaItem = async (levelId: number) => {
      if (!description || !type) {
        message.error(strings.TYPE_AND_DESCRIPTION_REQUIRED_FIELDS, 2);
      }

      try {
        setHasAdded(true);
        await metaGoalsRepo.createMetaGoalItem(levelId, { type, description });
        await fetchMetaGoals();
        message.success(strings.META_ITEM_SUCCESSFULLY_ADDED, 2);
        setHasAdded(false);
      } catch {
        message.error(strings.META_ITEM_CREATION_ERROR, 2);
        setHasAdded(false);
      }
    };

    /**
     * @description Список файлов metalevelPicture
     * @return {Promise<any>}
     */
    const getAttachmentList = async (): Promise<any> => {
      setHasAttachLoading(true);
      try {
        const {
          data: { list }
        } = await attachmentRepository.getAttachmentList({ type: 'metalevelPicture', id: metaLevelId });
        setAttachmentList(list);
      } catch {
        message.error(strings.ERROR_GET_ATTACHMENTS, 2);
      } finally {
        setHasAttachLoading(false);
      }
    };

    /**
     * @description Удаление файла
     * @return {Promise<any>}
     */
    const handleDeleteAttach = async attachmentId => {
      confirm({
        title: strings.DO_YOU_WANT_TO_DELETE_THIS_FILE,
        okText: strings.DELETE,
        okType: 'danger',
        async onOk() {
          try {
            await Global.deleteAttachment(attachmentId);
            message.success(strings.FILE_DELETED_SUCCESS);
            await getAttachmentList();
          } catch {
            message.error(strings.FILE_DELETION_ERROR);
          }
        }
      });
    };
    /**
     * @description Превью в модалке
     * @param {number} attachmentId
     * @param {string} attachmentName
     * @return {Promise<any>}
     */
    const handleShowModalPreview = async (attachmentId: number, attachmentName: string): Promise<any> => {
      setCurrentAttachment({
        id: attachmentId,
        name: attachmentName
      });
      setHasLoadPreviewAttachment(true);
      try {
        await getPreviewUrl(attachmentId, setPreviewUrl, setHasShowPreviewModal);
      } finally {
        setHasLoadPreviewAttachment(false);
      }
    };

    /**
     * @description upload file
     * @param {object} upload
     * @return {Promise<any>}
     */
    const uploadFile = async (upload): Promise<any> => {
      const { file } = upload;
      if (file) {
        setHasUploading(true);
        try {
          await attachmentRepository.uploadAttachment(
            getFormData({
              type: 'metalevelPicture',
              id: metaLevelId,
              file: file
            }),
            true
          );
          message.success(strings.FILE_UPLOAD, 2);
          await getAttachmentList();
        } catch {
          message.error(strings.UPLOAD_FILE_ERROR, 2);
        } finally {
          setHasUploading(false);
        }
      }
      return false;
    };
    const uploadProps = {
      name: 'file',
      multiple: true,
      beforeUpload: () => false,
      showUploadList: false,
      onChange: uploadFile
    };

    useEffect(() => {
      getAttachmentList();
    }, [metaLevelId]);

    return (
      <>
        <PreviewFileModal
          hasLoadPreviewAttachment={hasLoadPreviewAttachment}
          fileName={currentAttachment.name}
          previewUrl={previewUrl}
          handleHideModal={() => setHasShowPreviewModal(false)}
          hasShowModal={hasShowPreviewModal}
          hasImageFile={true}
        />
        <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
          <Col lg={8}>
            <Input type="text" placeholder={strings.TYPE} onChange={({ target: { value } }) => setType(value)} />
          </Col>
          <Col lg={10}>
            <Input.TextArea
              placeholder={strings.DESCRIPTION}
              onChange={({ target: { value } }) => setDescription(value)}
            />
          </Col>
          <Col lg={4}>
            <Button loading={hasAdded} size="large" onClick={() => onAddMetaItem(metaLevelId)}>
              {strings.ADD_META_GOAL_ITEM}
            </Button>
          </Col>
        </Row>
        {goals.isNotEmpty() && (
          <Row gutter={[10, 10]}>
            <Col lg={24}>
              <Spin spinning={hasAttachLoading}>
                <Upload {...uploadProps}>
                  <Button style={{ marginInlineEnd: 10 }}>
                    {hasUploading ? <LoadingOutlined /> : <UploadOutlined />} {strings.UPLOAD_META_GOAL_LEVEL_IMAGE}
                  </Button>
                </Upload>
                {attachmentList.map(attach => (
                  <span>
                    <span>{attach.name}</span>{' '}
                    <span style={{ fontSize: 12, color: '#bbb' }}>
                      {strings.UPLOADED_AT}
                      {attach.updated_at} by {attach.created_by}
                    </span>
                    <Button
                      size="small"
                      onClick={() => handleShowModalPreview(attach.id, attach.name)}
                      style={{ marginInlineStart: 10 }}
                      icon={<EyeOutlined />}
                      loading={hasLoadPreviewAttachment}
                    />
                    <Button
                      size="small"
                      onClick={() => handleDeleteAttach(attach.id)}
                      style={{ marginInlineStart: 10 }}
                      icon={<DeleteOutlined />}
                    />
                  </span>
                ))}
              </Spin>
            </Col>
            <Col lg={24}>
              <Title level={3}>{strings.META_ITEMS}</Title>
              {goals.map(({ type, description, id }) => (
                <Row key={id.toString()} gutter={[10, 10]} style={{ marginBottom: 10 }}>
                  <Col lg={8} style={{ display: 'flex', gap: 10 }}>
                    <Button danger icon={<DeleteOutlined />} size="small" onClick={() => deleteMetaGoal(id)} />
                    <b>{type}</b>
                  </Col>
                  <Col lg={16}>{description}</Col>
                </Row>
              ))}
            </Col>
          </Row>
        )}
      </>
    );
  };

  return (
    <Container>
      {contextHolder}
      <StPageHeader onBack={goBack} title={strings.META_GOALS} />
      <AddMetaLevel fetchMetaGoals={fetchMetaGoals} />
      <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
        <Col lg={20}>
          <MyFormItem label={strings.CHOOSE_BRANCHES_TO_TURN_ON_THE_METAGOALS_IMAGES_IN_THE_REGISTERS}>
            <Select
              placeholder={strings.BRANCHES}
              value={metaLevelBranches}
              mode="multiple"
              onChange={(value: number[]) => {
                changeMetaLevelPictureBranches(value);
              }}
            >
              {branches.map((branch: Branch) => {
                //админ, который не топ-менеджер, не может изменять филиалы, кроме своего
                const hasDisabled = !hasTopManager && branch.id !== myBranch.id;
                return (
                  <Option disabled={hasDisabled} value={Number(branch.id)} key={branch.id}>
                    {branch.name}
                  </Option>
                );
              })}
            </Select>
          </MyFormItem>
        </Col>
      </Row>
      <Collapse style={{ width: '100%' }}>
        {metaGoals.map(({ name, stage, id: metaLevelId, goals }) => (
          <Panel
            header={`${name} (${stage.name})`}
            key={metaLevelId.toString()}
            extra={
              <Flex gap={10}>
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  size="small"
                  onClick={e => {
                    e.stopPropagation();
                    handleShowModal({ metaLevelId, name, goals });
                  }}
                />
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  size="small"
                  onClick={e => {
                    e.stopPropagation();
                    deleteMetaGoalLevel(metaLevelId);
                  }}
                />
              </Flex>
            }
          >
            <MetaGoal metaLevelId={metaLevelId} goals={goals} />
          </Panel>
        ))}
      </Collapse>
      <UpdateLevelModal
        fetchMetaGoals={fetchMetaGoals}
        hasShowModal={hasShowModal}
        setHasShowModal={setHasShowModal}
        level={currentLevel}
      />
    </Container>
  );
};
