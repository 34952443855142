import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import BaseFilter from 'helpers/BaseFilter';

export const useLocationSearchParams = () => {
  const location = useLocation();
  const { search } = location;
  const FilterService = new BaseFilter();
  const getSearchParams = () => {
    const params = FilterService.getLocationSearchParams(location, {
      parseNumbers: true,
      parseBooleans: true,
      arrayFormat: 'bracket'
    });
    return params;
  };

  const [locationSearchParams, setLocationSearchParams] = useState(getSearchParams);

  useEffect(() => {
    setLocationSearchParams(getSearchParams());
  }, [search]);

  return { locationSearchParams };
};
