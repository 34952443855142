import React, { useMemo, useContext } from 'react';
import { httpService } from 'api/core';

const TemplatesContext = React.createContext<ITemplatesRepository>(null);

interface ITemplatesRepository {
  getContractTemplates: () => Promise<any>;
  addContractTemplate: (params: { name: string; branch: number }) => Promise<any>;
  editContractTemplate: (id: number, params: { name: string; branch: number }) => Promise<any>;
  getContractTemplateById: (id: number) => Promise<any>;
  deleteContractTemplate: (id: number) => Promise<any>;
}

class TemplatesRepository implements ITemplatesRepository {
  getContractTemplates() {
    return httpService.get('/api/v1/contracts/templates', null, null, true);
  }
  getContractTemplateById(id: number) {
    return httpService.get(`/api/v1/contracts/templates/${id}`, null, null, true);
  }
  addContractTemplate(params: { name: string; branch: number }) {
    return httpService.post(`/api/v1/contracts/templates`, params, null, null, true);
  }
  editContractTemplate(id: number, params: { name: string; branch: number }) {
    return httpService.put(`/api/v1/contracts/templates/${id}`, params, null, null, true);
  }
  deleteContractTemplate(id: number) {
    return httpService.delete(`/api/v1/contracts/templates/${id}`, null, null, true);
  }
}

export const useTemplatesRepository = () => {
  const service = useContext(TemplatesContext);

  if (!service) {
    throw new Error('Commentary calendar repository is unavailable');
  }

  return service;
};

export const TemplatesRepositoryProvider = props => {
  const service = useMemo(() => new TemplatesRepository(), []);

  return <TemplatesContext.Provider value={service}>{props.children}</TemplatesContext.Provider>;
};
