import React from 'react';
import dayjs from 'dayjs';
import Avatar from 'Global/components/Avatar';
import { Link } from 'react-router-dom';
import { AvatarContainer, AvatarContainerLeft, AvatarContent, Rainbow } from 'Admin/Courses/styles';
import { Child } from 'types';
import { generateColumn } from '../../helpers';
import { useStudent } from 'hooks/useStudent';
import { useLanguageContext } from 'contexts/LanguageContext';
import { dateMonthFormat } from 'helpers/dates';
import { StTitle } from '../../LessonRegister/styles';

/**
 * @description Рендеринг колонки студента
 * @param {object} student
 * @return {React.ReactNode}
 */
const RegisterStudent = ({ student }: { student: Child }) => {
  const [strings] = useLanguageContext();
  const { fullName, birthday, studentLink } = useStudent(student);
  // Разница в днях для напоминания о грядущем дне рождении
  const diffBirthday = dayjs().diff(birthday, 'days');
  const WEEK = -7; // количество дней перед тем как напоминать о дне рождении
  const birthDayComing = diffBirthday >= WEEK && diffBirthday <= 6;

  return (
    <AvatarContainer>
      <AvatarContainerLeft>
        <Avatar size={40} photo={student.mainPhotoBig} scale={1.5} />
      </AvatarContainerLeft>
      <AvatarContent>
        <Link to={studentLink}>{fullName}</Link>
        {birthDayComing && (
          <Rainbow>
            {strings.BIRTHDAY_IS_ON} {birthday.format(dateMonthFormat)}
          </Rainbow>
        )}
      </AvatarContent>
    </AvatarContainer>
  );
};
const RegisterStudentMemo = React.memo(RegisterStudent);

const COLUMN_WIDTH = 140;

/**
 * @description Генерация колонки студента
 * @return {object}
 */
export const getStudentColumn = strings => ({
  ...generateColumn(
    <StTitle>{strings.STUDENTS}</StTitle>,
    'student',
    'student',
    'table-border-left',
    null,
    COLUMN_WIDTH
  ),
  render: (student: Child) => <RegisterStudentMemo student={student} />
});
