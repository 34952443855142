import styled from 'styled-components';
import { Card } from 'antd';

export const Item = styled.div`
  padding: 0 16px 16px 0;
  height: 100%;
`;

export const CardTitle = styled.div`
  color: #000000;
  font-weight: 500;

  &:hover {
    color: #1890ff;
  }
`;

export const CardWrapper = styled(Card)`
  height: 100%;
  box-shadow: 0 0 10px rgba(208, 216, 243, 0.6);
`;

export const CardDescription = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  color: #555;
`;

// export const AddItemCard = styled(Card)`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
//   background-color: #fafafa;
//   box-shadow: 0 0 10px rgba(208, 216, 243, 0.6);
// `;

export const NewsImage = styled.div`
  & img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;
