import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { Table, Row, Button, message, Col, Card, Statistic, Select, Modal } from 'antd';
import { getColumns, getGlobalDoubtsColumns } from 'Admin/People/Teachers/Teacher/Finance/columns';
import { CreateTransactionModal } from 'Admin/People/Teachers/Teacher/Finance/CreateTransactionModal';
import { hasRussia } from 'helpers';
import { ErrorHandler } from 'helpers/services/ErrorHandler';
import { Teacher } from 'types/Teachers/teacher';
import { Link } from 'react-router-dom';
import { BASE_TEACHERS } from 'Admin/People/Teachers/routes';
import { Forbidden } from 'Global/modules/Errors/403';
import { FinanceHeadingSelect } from 'Admin/People/Teachers/styles';
import { FinanceModal } from 'Admin/People/Teachers/Teacher/Finance/FinanceModal';
import { isEmpty } from 'lodash';
import { TableScroll } from 'Global/styles';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { Teachers } from 'api';
import { solvedDoubt } from 'api/Finance';
import { TeacherFinance, TeacherFinanceItem } from 'types/Teachers/finance';
import { Product, ModalMode } from 'types';
import { SolvedDoubtParams } from 'types/finance';
import { useUserContext } from 'contexts/UserContext';
import { useTeacherRepository } from 'repos/TeacherRepository';
import { useLanguageContext } from 'contexts/LanguageContext';
import styled from 'styled-components';
import { StCard } from 'Global/GlobalStyle';

const { Column } = Table;
const { Option } = Select;

const StyledStatisticCard = styled(Card)`
  border-radius: 5px;
  height: 100%;
`;

const StyledStatisticCol = styled(Col)`
  flex: 1;
`;

interface FinanceProps {
  teacherId: number | string;
  products: Product[];
  teacher: Teacher;
}

/**
 * @description ServiceRates component
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const Finance = (props: FinanceProps) => {
  const [strings] = useLanguageContext();
  const { teacher, teacherId, products } = props || {};
  const [finance, setFinance] = useState<TeacherFinance>(null);
  const [hasFinanceLoaded, setFinanceLoaded] = useState<boolean>(false);
  const [isModalVisibility, setModalVisibility] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Hidden);
  const { summary, currentMonth, previousMonths, globalDoubts } = finance || {};
  const [hasAccess, setHasAccess] = useState<boolean>(false);
  const [hasEditTransition, setEditTransition] = useState<boolean>(false);
  const [operation, setOperation] = useState<TeacherFinanceItem>(null);
  const [showRows, setShowRows] = useState<number>(50);
  const [selectId, setSelectId] = useState<number>(null);
  const [lessonsCount, setLessonsCount] = useState<number>(0);
  const teacherRepository = useTeacherRepository();
  const [{ confirm }, contextHolder] = Modal.useModal();

  const [user] = useUserContext();
  const hasTeacherAccessInfo = user?.teacherAccessInfo;
  const meAdminId = user?.meAdminId;
  const hasAccessToDeleteFinanceOperation = hasTeacherAccessInfo || teacher?.tutor?.id === meAdminId;
  const currency = teacher?.user?.branch && hasRussia(teacher.user.branch) ? 'руб' : '';
  const startBalance = summary?.startBalance;
  const endBalance = summary?.endBalance;

  /**
   * @description октрытие модалки редактирования   *
   * @param {Operation} data
   * @return {void}
   */
  const handleEditTransition = (data: TeacherFinanceItem): void => {
    setEditTransition(true);
    setOperation(data);
  };
  /**
   * @description Удаление фин операции
   * @param {number} operationId
   * @return {Promise<any>}
   * */
  const handleDeleteTransition = async (operationId: number) => {
    try {
      await Teachers.deleteTeacherOperation(operationId);
      message.success(strings.TEACHER_FINANCE_OPERATION_SUCCESSFULLY_DELETE);
      fetchFinanceById();
    } catch {}
  };

  const hideModal = (): void => {
    setModalVisibility(false);
    setEditTransition(false);
  };

  const hideModalFinance = (): void => setModalMode(ModalMode.Hidden);

  const onReload = (id: number): void => {
    setSelectId(id);
    setModalMode(ModalMode.AddCompensation);
  };

  /**
   * @description Получение финансов учителя по id
   * @return {Promise<any>}
   * */
  const fetchFinanceById = async (): Promise<any> => {
    setFinanceLoaded(true);
    try {
      const { data, status } = await teacherRepository.getTeacherFinance(teacherId);
      if (status === 200) {
        setFinanceLoaded(false);
        setFinance(data);
      }
    } catch (err) {
      if (ErrorHandler.checkStatusCode(err, 403)) {
        setHasAccess(true);
      }
      message.error(strings.SORRY_ERROR_GETTING_TEACHERS_FINANCE);
    }
  };

  /**
   * @description Получение количества уроков в предыдущем месяце
   * @return {Promise<any>}
   * */
  const fetchScheduleStatistics = async (): Promise<any> => {
    try {
      const {
        data: { lessonsCount, eventsWithPayment }
      } = await teacherRepository.getTeacherScheduleStatistics(teacherId);
      const totalCount = lessonsCount + eventsWithPayment;
      setLessonsCount(totalCount);
    } catch {}
  };

  /**
   * @description Mark doubt as solved
   * @param {SolvedDoubtParams} params
   * @returns {void}
   */
  const handleSolvedDoubt = (params: SolvedDoubtParams): void => {
    confirm({
      title: strings.DO_YOU_REALLY_WANT_TO_MARK_AS_SOLVED,
      async onOk() {
        await solvedDoubt(params)
          .then(({ status }) => {
            if (status === 200) {
              fetchFinanceById();
            }
          })
          .catch(() => {});
      }
    });
  };

  useEffect(() => {
    fetchFinanceById();
    fetchScheduleStatistics();
  }, [teacherId]); // eslint-disable-line

  if (hasAccess)
    return (
      <Forbidden subTitle={strings.SORRY_YOU_DO_NOT_HAVE_ACCESS_TO_THE_FINANCE_THIS_TEACHER}>
        <Button size="large" type="primary" icon={<ArrowLeftOutlined />}>
          <Link to={BASE_TEACHERS} style={{ color: '#fff' }}>
            {strings.BACK_TO_TEACHER_LIST}
          </Link>
        </Button>
      </Forbidden>
    );

  return (
    <ErrorBoundary>
      <StCard>
        {contextHolder}
        <PageHeader
          style={{ padding: 0 }}
          title={strings.FINANCE}
          extra={[
            <Button
              onClick={() => {
                setOperation(null);
                setModalVisibility(true);
              }}
              style={{ fontSize: '14px' }}
              type="primary"
              size="middle"
            >
              {strings.CREATE_NEW_TRANSACTION}
            </Button>
          ]}
        >
          {!hasFinanceLoaded && (
            <Row align="middle" style={{ marginTop: 20, gap: 10, alignItems: 'stretch' }}>
              <StyledStatisticCol lg={8} xs={24}>
                <StyledStatisticCard>
                  <Statistic
                    title={<span>{strings.CURRENT_ACCOUNT_STATEMENT}</span>}
                    value={`${endBalance} ${currency}`}
                    precision={2}
                    valueStyle={{
                      color: endBalance < 0 ? '#cf1322' : '#3f8600'
                    }}
                  />
                </StyledStatisticCard>
              </StyledStatisticCol>
              <StyledStatisticCol lg={8} xs={24}>
                <StyledStatisticCard>
                  <Statistic
                    title={<span>{strings.OPENING_BALANCE_AT_THE_BEGINNING_OF_THE_MONTH}</span>}
                    value={`${startBalance} ${currency}`}
                    precision={2}
                    valueStyle={{ color: startBalance < 0 ? '#cf1322' : '#3f8600' }}
                  />
                </StyledStatisticCard>
              </StyledStatisticCol>
              <StyledStatisticCol lg={8} xs={24}>
                <StyledStatisticCard>
                  <Statistic
                    title={<span>{strings.NUMBER_OF_LESSONS_IN_THE_PREVOIUS_MONTH}:</span>}
                    value={lessonsCount}
                    valueStyle={{ color: lessonsCount === 0 ? '#cf1322' : '#3f8600' }}
                  />
                </StyledStatisticCard>
              </StyledStatisticCol>
            </Row>
          )}
        </PageHeader>
      </StCard>
      {!isEmpty(globalDoubts) && (
        <StCard>
          <PageHeader style={{ padding: 0 }} title={strings.ERROR_REPORT}>
            <Table dataSource={globalDoubts} pagination={false} style={{ marginBottom: 20 }} rowKey="id">
              {getGlobalDoubtsColumns(handleSolvedDoubt)
                .filter(col => col)
                .map(col => (
                  <Column key={col.key} {...col} />
                ))}
            </Table>
          </PageHeader>
        </StCard>
      )}
      <StCard>
        <PageHeader style={{ padding: 0 }} title={strings.SPENT_THIS_MONTH}>
          <TableScroll style={{ marginBottom: 20 }}>
            <Table dataSource={currentMonth} loading={hasFinanceLoaded} pagination={false} rowKey="id">
              {getColumns({
                hasPreviousMonths: false,
                withoutRemainingBalance: false,
                onReload,
                onEdit: handleEditTransition,
                handleDeleteTransition,
                hasAccessToDeleteFinanceOperation,
                handleSolvedDoubt
              })
                .filter(col => col)
                .map(col => (
                  <Column key={col.key} {...col} />
                ))}
            </Table>
          </TableScroll>
        </PageHeader>
      </StCard>
      <StCard>
        <PageHeader style={{ padding: 0 }} title={strings.SPENT_IN_PREVIOUS_MONTHS}>
          <FinanceHeadingSelect>
            <span>{strings.SHOW_ROWS}</span>
            <Select
              size="large"
              defaultValue={showRows}
              onChange={(row: number) => setShowRows(row)}
              style={{ width: 70 }}
            >
              <Option value={25}>25</Option>
              <Option value={50}>50</Option>
              <Option value={75}>75</Option>
              <Option value={100}>100</Option>
            </Select>
          </FinanceHeadingSelect>
          <Table dataSource={previousMonths} pagination={{ pageSize: showRows }} loading={hasFinanceLoaded} rowKey="id">
            {getColumns({
              hasPreviousMonths: true,
              withoutRemainingBalance: true,
              onReload,
              onEdit: handleEditTransition
            })
              .filter(col => col)
              .map(col => (
                <Column key={col.key} {...col} />
              ))}
          </Table>
        </PageHeader>
      </StCard>
      <CreateTransactionModal
        teacherId={hasEditTransition ? Number(operation?.id) : teacherId}
        defaultVal={operation}
        hideModal={hideModal}
        fetchFinanceById={fetchFinanceById}
        products={products}
        isModalVisibility={isModalVisibility || hasEditTransition}
        edit={hasEditTransition}
      />
      <FinanceModal
        id={teacherId}
        mode={modalMode}
        hideModal={hideModalFinance}
        fetchFinanceById={fetchFinanceById}
        values={
          (finance && [...finance.currentMonth, ...finance.previousMonths].find(({ id }) => id === selectId)) || {}
        }
        financeId={selectId}
        products={products}
      />
    </ErrorBoundary>
  );
};

export default { Finance };
