import React from 'react';
import { Link } from 'react-router-dom';
import { Result, Button } from 'antd';
import Helmet from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { useUserContext } from 'contexts/UserContext';

/**
 * @description Компонент (страница) 404
 * @return {React.ReactNode}
 */
export const NotFound = () => {
  const [user] = useUserContext();
  // Под ролью родитель или ученик, редирект на главную. Паша 18.06
  if (user?.hasRoles?.parent || user?.hasRoles?.student) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Helmet>
        <title>AMI: 404</title>
      </Helmet>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" size="large">
            <Link to="/">Back home</Link>
          </Button>
        }
      />
    </>
  );
};

export default { NotFound };
