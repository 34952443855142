import React, { useState } from 'react';
import dayjs from 'dayjs';
import { isNumber, first, isNull } from 'lodash';
import { RateItemProps, RateItemType } from 'Admin/People/Families/Family/Invoices/types';
import { Button, Col, InputNumber, Row, Select, DatePicker, Typography, Tag, Tooltip, Checkbox, Input } from 'antd';
import { IPrice, Rate } from 'types';
import FamilyService from 'helpers/services/FamilyService';
import { dateFormat } from 'helpers/dates';
import { useLanguageContext } from 'contexts/LanguageContext';
import { priceTypesById } from './constants';
import { discountReasonList } from 'Global/constants';
import { StFormItemWithoutMargin } from 'Global/GlobalStyle';
import { StyledSeparator } from 'Global/styles';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { BranchMoscow, BranchRiga } from 'types/branches';

const { Option } = Select;
const service = new FamilyService();
const { Paragraph } = Typography;

/**
 * @description
 * Компонент рейта с выбором:
 * 1. Самого рейта.
 * 2. Скидки.
 * 3. Количества.
 * 4. Дат (даты становятся не активными, если рейт не триальный).
 *
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
const RateItem = (props: RateItemProps & RateItemType) => {
  const [strings] = useLanguageContext();
  const {
    quantity,
    form,
    endAt,
    startAt,
    pricesList = [],
    priceTypes,
    handleDelete,
    id,
    price,
    sum,
    discount,
    discountReason,
    rate,
    rateType,
    afterTrial,
    index,
    familyBranch
  } = props || {};

  const [afterTrialRate, setAfterTrialRate] = useState<boolean>(afterTrial || false);
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const discountField = getFieldValue(`rates[${String(index)}].discount`) || 0;
  const quantityField = getFieldValue(`rates[${String(index)}].quantity`) || 0;

  const hasMoscow = familyBranch.id === BranchMoscow.id;
  const hasRiga = familyBranch.id === BranchRiga.id;

  const hasMoscowOrRigaFamily = hasMoscow || hasRiga;

  /**
   * @description Функция обработчик на выбор рейта
   * @param {number} id - идентификатор рейта
   * @return {void}
   */
  const handleChange = (id: number): void => {
    const currentRate: Rate = service.getRateById(id, pricesList);
    const { price, discount, afterTrial, startAt, endAt } = currentRate;
    const amountWithDiscount: number = service.getAmountWithDiscount(price, discount);
    const hasStartAt = startAt && !isNull(startAt);
    const hasEndAt = endAt && !isNull(endAt);
    let objBySelectedRate = {};
    objBySelectedRate[`rates[${String(index)}].discount`] = discount || 0;
    objBySelectedRate[`rates[${String(index)}].rate`] = id;
    setAfterTrialRate(afterTrial);
    if (hasStartAt && hasEndAt) {
      objBySelectedRate[`rates[${String(index)}].startAt`] = dayjs(startAt);
      objBySelectedRate[`rates[${String(index)}].endAt`] = dayjs(endAt);
    }
    if (quantityField) {
      objBySelectedRate[`rates[${String(index)}].sum`] = amountWithDiscount * quantityField;
    } else {
      objBySelectedRate[`rates[${String(index)}].sum`] = amountWithDiscount;
    }

    // Если рейт триальный И нет дат, то показываем надпись.
    if (rateType === priceTypesById.trialType && !hasStartAt && !hasEndAt) {
      setShowInfo(true);
    } else {
      setShowInfo(false);
    }
    setFieldsValue(objBySelectedRate);
  };

  /**
   * @description Функция обработчик на скидку
   * @param {number} discount - скидка в процентах
   * @return {void}
   */
  const handleDiscountChange = (discount: number): void => {
    // Если в поле вручную ввели не число, то прерываем функцию.
    if (!isNumber(discount)) return;
    const currentPrice = service.getRatePriceById(getFieldValue(`rates[${String(index)}].rate`), pricesList);
    const amountWithDiscount: number = service.getAmountWithDiscount(currentPrice, discount);

    // Если поле quantity (кол-во) определенно то учитываем его и прерываем функцию.
    if (quantityField) {
      let obj = {};
      obj[`rates[${String(index)}].sum`] = amountWithDiscount * quantityField;
      setFieldsValue(obj);
      return;
    }
    let obj = {};
    obj[`rates[${String(index)}].sum`] = amountWithDiscount;
    setFieldsValue(obj);
  };

  /**
   * @description Функция обработчик на количество рейтов
   * @param {number} quantity - количество
   * @return {void}
   */
  const handleQuantityChange = (quantity: number): void => {
    /** Если в поле вручную ввели не число, или кол-во меньше или равно 0,
     * то прирываем функцию.
     */
    if (quantity <= 0 || !isNumber(quantity)) return;
    const currentPrice = service.getRatePriceById(getFieldValue(`rates[${String(index)}].rate`), pricesList);
    const currentAmount = service.getAmountWithDiscount(currentPrice, discountField);

    let obj = {};
    obj[`rates[${String(index)}].sum`] = currentAmount * quantity;
    setFieldsValue(obj);
  };

  /**
   * @description Функция обработчик на поиск
   * @param value
   */
  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const validatePrice = () => {
    if (rateType === priceTypesById.depositType) {
      return false;
    }
    const currentRateId = getFieldValue(`rates[${String(index)}].rate`);
    const currentPrice = getFieldValue(`rates[${String(index)}].sum`);
    const quantity = getFieldValue(`rates[${String(index)}].quantity`) || 1;
    if (currentRateId) {
      const currentRate: Rate = service.getRateById(currentRateId, pricesList);
      const { price } = currentRate;
      return currentPrice / quantity < price;
    }
    return false;
  };

  const searchRegexp = new RegExp(search, 'ig');

  const filteredRates = React.useMemo(() => {
    return pricesList.filter((rate: IPrice) => {
      return (
        rate.type.id === rateType &&
        (rate.name.match(searchRegexp) || searchRegexp.test(rate.price ? rate.price.toString() : ''))
      );
    });
  }, [pricesList, searchRegexp]);

  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'nowrap', gap: 10 }}>
        <Button
          icon={<DeleteOutlined />}
          danger
          size="large"
          style={{ marginTop: 48, minWidth: 40 }}
          onClick={() => handleDelete(id)}
        />
        <div style={{ flex: 1 }}>
          {getFieldDecorator(`rates[${String(index)}].rateType`, {
            initialValue: rateType
          })(<Input style={{ display: 'none' }} />)}
          {getFieldDecorator(`rates[${String(index)}].id`, {
            initialValue: id
          })(<Input style={{ display: 'none' }} />)}
          {rateType !== priceTypesById.depositType && (
            <Row align="middle" gutter={[10, 10]}>
              <Col lg={10}>
                <StFormItemWithoutMargin label={priceTypes.find(item => item.id === rateType).name}>
                  {getFieldDecorator(`rates[${String(index)}].rate`, {
                    rules: [{ required: true, message: `${strings.RATE} ${strings.IS_REQUIRED}` }],
                    initialValue: rate
                  })(
                    <Select
                      showSearch
                      placeholder={strings.RATE}
                      onChange={handleChange}
                      onSearch={handleSearch}
                      filterOption={false}
                    >
                      {filteredRates.map((rate: Rate) => {
                        return (
                          <Option key={rate.id} value={rate.id}>
                            <Tooltip title={rate.name} placement="top" overlayStyle={{ zIndex: 9999 }}>
                              <div style={{ width: '100%', height: '100%' }}>
                                <Tag>{rate.price}</Tag> {rate.name}
                              </div>
                            </Tooltip>
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </StFormItemWithoutMargin>
              </Col>

              <Col lg={afterTrialRate || !hasMoscowOrRigaFamily ? 3 : 4}>
                <StFormItemWithoutMargin label={strings.SUM}>
                  {getFieldDecorator(`rates[${String(index)}].sum`, {
                    rules: [{ required: false }],
                    initialValue: sum
                  })(<InputNumber placeholder={strings.SUM} />)}
                </StFormItemWithoutMargin>
              </Col>
              {!hasMoscowOrRigaFamily && (
                <Col lg={3}>
                  <StFormItemWithoutMargin label={strings.DISCOUNT}>
                    {getFieldDecorator(`rates[${String(index)}].discount`, {
                      initialValue: discount
                    })(
                      <InputNumber
                        onChange={handleDiscountChange}
                        placeholder={strings.DISCOUNT}
                        formatter={value => `${value} %`}
                      />
                    )}
                  </StFormItemWithoutMargin>
                </Col>
              )}

              {rateType === priceTypesById.productType && (
                <Col lg={4}>
                  <StFormItemWithoutMargin label={strings.QUANTITY}>
                    {getFieldDecorator(`rates[${String(index)}].quantity`, {
                      rules: [{ required: true, message: `${strings.QUANTITY} ${strings.IS_REQUIRED}` }],
                      initialValue: quantity
                    })(<InputNumber onChange={handleQuantityChange} placeholder={strings.QUANTITY} />)}
                  </StFormItemWithoutMargin>
                </Col>
              )}

              {rateType !== priceTypesById.productType && (
                <>
                  <Col lg={4}>
                    <StFormItemWithoutMargin label={strings.START}>
                      {getFieldDecorator(`rates[${String(index)}].startAt`, {
                        rules: [{ required: false }],
                        initialValue: startAt
                      })(<DatePicker disabled={rateType === priceTypesById.periodType} format={dateFormat} />)}
                    </StFormItemWithoutMargin>
                  </Col>
                  <Col lg={4}>
                    <StFormItemWithoutMargin label={strings.END}>
                      {getFieldDecorator(`rates[${String(index)}].endAt`, {
                        rules: [{ required: false }],
                        initialValue: endAt
                      })(<DatePicker disabled={rateType === priceTypesById.periodType} format={dateFormat} />)}
                    </StFormItemWithoutMargin>
                  </Col>
                  {afterTrialRate && (
                    <Col lg={3}>
                      <StFormItemWithoutMargin>
                        {getFieldDecorator(`rates[${index}].afterTrial`, {
                          initialValue: afterTrialRate,
                          valuePropName: 'checked'
                        })(
                          <Checkbox disabled={true} style={{ paddingTop: 48 }}>
                            {strings.AFTER_TRIAL}
                          </Checkbox>
                        )}
                      </StFormItemWithoutMargin>
                    </Col>
                  )}
                </>
              )}
            </Row>
          )}
          <Row gutter={[10, 10]}>
            {rateType === priceTypesById.depositType && (
              <>
                <Col lg={4}>
                  <StFormItemWithoutMargin label={strings.ENTRANCE_FEE_SUM}>
                    {getFieldDecorator(`rates[${String(index)}].rate`, {
                      initialValue: first(filteredRates)?.id
                    })}
                    {getFieldDecorator(`rates[${String(index)}].sum`, {
                      rules: [{ required: false }],
                      initialValue: sum
                    })(<InputNumber placeholder={strings.ENTRANCE_FEE_SUM} />)}
                  </StFormItemWithoutMargin>
                </Col>
                {!hasMoscowOrRigaFamily && (
                  <Col lg={4}>
                    <StFormItemWithoutMargin label={strings.DISCOUNT}>
                      {getFieldDecorator(`rates[${String(index)}].discount`, {
                        initialValue: discount
                      })(
                        <InputNumber
                          onChange={handleDiscountChange}
                          placeholder={strings.DISCOUNT}
                          formatter={value => `${value} %`}
                        />
                      )}
                    </StFormItemWithoutMargin>
                  </Col>
                )}
              </>
            )}
            {hasMoscowOrRigaFamily && (
              <>
                <Col lg={4}>
                  <StFormItemWithoutMargin label={strings.DISCOUNT}>
                    {getFieldDecorator(`rates[${String(index)}].discount`, {
                      initialValue: discount
                    })(
                      <InputNumber
                        onChange={handleDiscountChange}
                        placeholder={strings.DISCOUNT}
                        formatter={value => `${value} %`}
                      />
                    )}
                  </StFormItemWithoutMargin>
                </Col>
                <Col lg={6}>
                  <StFormItemWithoutMargin label={strings.REASON}>
                    {getFieldDecorator(`rates[${String(index)}].discountReason`, {
                      rules: [
                        {
                          required: getFieldValue(`rates[${String(index)}].discount`) !== 0 || validatePrice(),
                          message: `${strings.REASON} ${strings.IS_REQUIRED}`
                        }
                      ],
                      initialValue: discountReason
                    })(
                      <Select>
                        {discountReasonList.map(item => (
                          <Option value={item.value}>{item.name}</Option>
                        ))}
                      </Select>
                    )}
                  </StFormItemWithoutMargin>
                </Col>
              </>
            )}
          </Row>
        </div>
      </div>
      <Row>
        {showInfo && (
          <Paragraph>
            <InfoCircleOutlined
              style={{
                margin: '0 10px 0 10px',

                fontSize: 20,
                color: '#1890ff'
              }}
            />
            {strings.TRIAL_WILL_BE_IDENTIFIED_ACCORDING_TO_THE_SCHEDULE}
          </Paragraph>
        )}
      </Row>
      <StyledSeparator />
    </>
  );
};

export default RateItem;
