import React, { useState } from 'react';
import { Row, Col, message, Button, Spin, Checkbox, Form } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useUserContext } from '../contexts/UserContext';
import { useUserRepository } from 'repos/UserRepository';
import { StPageHeader, Container } from 'Global/GlobalStyle';
import { useHistory } from 'react-router-dom';
import { FormItemWithoutMargin } from 'Global/styles';
import { BranchMoscow } from 'types/branches';
import {IParentProfilePostParams} from "../types/user";

/**
 * @description Форма редактирования родителем своего профиля
 * @param {object} props
 * @return {React.ReactNode}
 */
export const EditParentForm = () => {
  const [form] = Form.useForm();
  const { goBack } = useHistory();

  const userRepository = useUserRepository();

  const [strings] = useLanguageContext();
  const [user, setUser] = useUserContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);

  const { subscribed, smsSubscribed, subscribeToHomework, branch } = user || {};

  /**
   * @description Submit формы (редактирование профиля родителя)
   * @param {React.FormEvent} e
   * @return {void}
   */
  const handleSubmit = async (values: IParentProfilePostParams): Promise<any> => {
    try {
      setHasLoading(true);
      await userRepository.editParentBySelf(values);
      setUser({ ...user, ...values });
      message.success(strings.YOUR_PROFILE_EDITED_SUCCESSFULLY);
    } catch (e) {
      message.error(e?.message);
    } finally {
      setHasLoading(false);
    }
  };

  return (
    <Container>
      <StPageHeader onBack={goBack} title={strings.EDIT_SUBSCRIPTIONS} />
      <Spin spinning={hasLoading}>
        <Form layout="vertical" form={form} onFinish={handleSubmit} initialValues={{ subscribed, smsSubscribed, subscribeToHomework }}>
          <Row gutter={[10, 10]}>
            <Col lg={6} xs={24}>
              <FormItemWithoutMargin name="subscribed" valuePropName="checked">
                <Checkbox>{strings.EMAIL_SUBSCRIPTION}</Checkbox>
              </FormItemWithoutMargin>
            </Col>
            <Col lg={6} xs={24}>
              <FormItemWithoutMargin name="smsSubscribed" valuePropName="checked">
                <Checkbox>{strings.SMS_NOTIFICATIONS}</Checkbox>
              </FormItemWithoutMargin>
            </Col>
            {branch?.id === BranchMoscow.id && (
              <Col lg={6} xs={24}>
                <FormItemWithoutMargin name="subscribeToHomework" valuePropName="checked">
                  <Checkbox>{strings.LGEG_WEEKLY_NEWSLETTER}</Checkbox>
                </FormItemWithoutMargin>
              </Col>
            )}
            <Col lg={24} xs={24}>
              <Button htmlType="submit" type="primary" loading={hasLoading}>
                {strings.EDIT}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Container>
  );
};

export default { EditParentForm };
