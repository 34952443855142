import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import { Spin, message } from 'antd';
import { scrollTop } from 'helpers/scroll';
import { BASE_FAMILIES } from 'Global/routes';
import { Rate, IParent } from 'types';
import { InvoiceStepsProps } from '../types';
import { useInvoicesRepository } from 'repos/InvoicesRepository';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { InvoiceForm } from './InvoiceForm';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useHistory } from 'react-router-dom';
import { InvoiceCreateParams } from './types';

export const StepsTemplate = (props: InvoiceStepsProps) => {
  const { branch, students, parents, id, refetchInvoices } = props;
  const { push } = useHistory();
  const invoicesRepository = useInvoicesRepository();
  const [loading, setLoading] = useState<boolean>(false);
  const [product, setProduct] = useState<Rate[]>([]);
  const [period, setPeriod] = useState<Rate[]>([]);
  const [flexible, setFlexible] = useState<Rate[]>([]);
  const [deposit, setDeposit] = useState<Rate[]>([]);
  const familyRoute = `${BASE_FAMILIES}/${id}/invoices`;
  const payer = parents.find((parent: IParent) => parent?.isPayer && parent?.isPayer === true);
  const [strings] = useLanguageContext();

  const createInvoice = async (params: InvoiceCreateParams): Promise<any> => {
    try {
      await invoicesRepository.generateFamilyInvoice(id, params);
      message.success(strings.INVOICE_CREATED_SUCCESSFULLY, 2);
      scrollTop();
      refetchInvoices();
      setTimeout(() => push(familyRoute), 1000);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const fetchPrices = async (selectedChildId, params): Promise<void> => {
    setLoading(true);
    try {
      const { data } = await invoicesRepository.getNewRatesByChildId(selectedChildId, params);
      setPeriod([...(data.period || []), ...(data.trial || [])]);
      setProduct(data.product || []);
      setFlexible(data.flexible || []);
      setDeposit(data.deposit || []);
    } catch {
      message.error(strings.SORRY_AN_UNEXPECTED_ERROR_OCCURED_WHEN_TRYING_TO_FETCH_RATES_DATA);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ErrorBoundary>
      <Spin spinning={loading} tip={`${strings.LOADING}...`}>
        <InvoiceForm
          payer={payer}
          parents={parents}
          students={students}
          branch={branch}
          prices={[...product, ...period, ...flexible, ...deposit]}
          setLoading={setLoading}
          createInvoice={createInvoice}
          fetchPrices={fetchPrices}
        />
      </Spin>
    </ErrorBoundary>
  );
};

export const InvoiceSteps = Form.create<InvoiceStepsProps>()(StepsTemplate);

export default { InvoiceSteps };
