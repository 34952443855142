import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { Supergroup } from 'types';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { filterOption } from 'helpers/filterSelect';

const { Option } = Select;

type AsyncSelectProps = {
  mode?: 'multiple' | 'tags';
  placeholder?: string;
  value?: number;
  branch?: number;
  onChange?: (value: number) => void;
};

const SupergroupSelect: React.FC<AsyncSelectProps> = ({ value, placeholder, mode, branch, onChange }) => {
  const [strings] = useLanguageContext();
  const coursesRepository = useCoursesRepository();
  const [options, setOptions] = useState<Supergroup[]>([]);
  const [hasLoading, setHasLoading] = useState(false);

  const handleChange = newValue => {
    onChange(newValue);
  };

  const getSuperGroupsByBranch = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await coursesRepository.getSuperGroups({ ...(branch && { branch }), byStages: 0 });
      setOptions(data);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    getSuperGroupsByBranch();
  }, [branch]);

  return (
    <Select
      value={value}
      onChange={handleChange}
      mode={mode}
      showSearch
      filterOption={filterOption}
      loading={hasLoading}
      placeholder={placeholder || strings.SUPERGROUP}
      maxTagCount="responsive"
      allowClear
    >
      {options
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((Supergroup: Supergroup) => (
          <Option key={Supergroup.id} value={Supergroup.id}>
            {Supergroup.name}
          </Option>
        ))}
    </Select>
  );
};

export default SupergroupSelect;
