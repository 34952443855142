import React, { useMemo, useContext } from 'react';
import { httpService } from 'api/core';
import { IRepositoryProvider } from './RepositoryProvider';

const ParentJournalRepositoryContext = React.createContext<IParentJournalRepository>(null);

interface IParentJournalRepository {
  getSubjects: (id: string | number) => Promise<any>;
  getLessonRegister: (
    id: string | number,
    subject: string | number,
    params?: { academicYear?: number }
  ) => Promise<any>;
  getTasksRegistersByChild: (id: string | number, subject: string | number) => Promise<any>;
  setReadParentComment: (id: string | number) => Promise<any>;
}

class ParentJournalRepository implements IParentJournalRepository {
  /**
   * @description Get subjects by child
   * @param {string | number} id id child
   * @return {Promise<any>}
   */
  getSubjects(id: string | number) {
    return httpService.get(`/api/subjects/child/${id}`);
  }

  /**
   * @description Get lesson registers by child and subject
   * @param {string | number} id id child
   * @param {string | number} subject subject
   * @return {Promise<any>}
   */
  getLessonRegister(id: string | number, subject: string | number, params?: { academicYear?: number }) {
    return httpService.get(`/api/v1/lessonregister/child/${id}/subject/${subject}`, params, null, true);
  }

  /**
   * @description Get task registers by child and subject
   * @param {string | number} id id child
   * @param {string | number} subject subject
   * @return {Promise<any>}
   */
  getTasksRegistersByChild(id: string | number, subject: string | number) {
    return httpService.get(`/api/v1/taskregister/child/${id}/subject/${subject}`, null, null, true);
  }

  /**
   * @description  set read parent comment
   * @param {number} id lessonregister
   * @return {Promise<any>}
   */
  setReadParentComment(id: number) {
    return httpService.put(`/api/lessonregister/${id}/read-parent-comment`);
  }
}

export const useParentJournalRepository = () => {
  const service = useContext(ParentJournalRepositoryContext);
  if (!service) {
    throw new Error('Parent Journal repository is unavailable');
  }

  return service;
};

export const ParentJournalRepositoryProvider: React.FC<IRepositoryProvider> = ({ children }) => {
  const service = useMemo(() => new ParentJournalRepository(), []);

  return <ParentJournalRepositoryContext.Provider value={service}>{children}</ParentJournalRepositoryContext.Provider>;
};
