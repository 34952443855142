import { httpService } from 'api/core';
import {RestorePasswordParams, IAccountPostParams} from 'types/user';

/**
 * @description Запрос на JWT токен
 * @param {string} email
 * @param {string} password
 * @return {Promise<any>}
 */
const getUserToken = (email: string, password: string) =>
  httpService.post('/api/v1/login_check', { username: email, password: password }, null, null, true);

/**
 * @description Refresh token
 * @param {string} token
 * @return {Promise<any>}
 */
const refreshToken = token => httpService.get(`/api/user/refresh_token?token=${token}`);

/**
 * @description Информация о текущем юзере
 * @return {Promise<any>}
 */
const getUserInfo = () => httpService.get('/api/v1/user/me', null, null, true);

/**
 * @description Switch user
 * @param email
 * @return {Promise<any>}
 */
const switchUser = (email: string): Promise<any> =>
  httpService.get(`/api/v1/user/switch?targetUser=${email}`, null, null, true);

/**
 * @description Создание нового пользователя
 * @param {object} params
 * @return {Promise<any>}
 */
const createUser = (params: IAccountPostParams) => httpService.post('/api/v1/user/create_account', params, null, null, true);

/**
 * @description Восстановление пароля. Метод посылает письмо на E-Mail с ссылкой на приложение и прикреплённым токеном
 * @param {string} login
 * @returns {Promise<any>}
 */
const sendToken = (login: string) => httpService.post('/api/v1/login/password/reset', { login }, null, null, true);

/**
 * @description Проверка на валидность токена восстановления пароля
 * @param {string} token
 * @returns {Promise<any>}
 */
const checkToken = (token: string) => httpService.get('/api/v1/login/password/reset', { token }, null, true);

/**
 * @description Восстановить пароль
 * @param {RestorePasswordParams} params
 * @returns {Promise<any>}
 */
const restorePassword = (params: RestorePasswordParams) =>
  httpService.post('/api/v1/login/password/reset', params, null, null, true);

export const Auth = {
  getUserToken,
  getUserInfo,
  createUser,
  switchUser,
  sendToken,
  checkToken,
  restorePassword,
  refreshToken
};

export default { Auth };
