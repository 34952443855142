import React, { useState } from 'react';
import { Spin, Typography, Row } from 'antd';
import { Filter } from './Filter';
import { StudentsByStudyProgram, StudentsByTutor } from 'types/Reports';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useHistory } from 'react-router-dom';

const { Title, Paragraph } = Typography;

interface StudentsPayingReportProps {}

/**
 * @description  Student Paying Report component
 * @param {object} props
 * @return {React.ReactNode}
 */
export const StudentsPayingReport = () => {
  const [strings] = useLanguageContext();
  const reportsRepository = useProgressReportsRepository();

  const [totalStudents, setTotalStudents] = useState<number>(null);
  const [studentsByStudyProgram, setStudentsByStudyProgram] = useState<StudentsByStudyProgram[]>([]);
  const [studentsByTutors, setStudentsByTutors] = useState<StudentsByTutor[]>([]);
  const [hasLoading, setHasLoading] = useState<boolean>(false);

  const { goBack } = useHistory();

  /**
   * @description Получение Paying Report
   * @param {object} params
   * @return {Promise<any>}
   */
  const fetchStudentsPayingReport = async (params: {
    startAt: string;
    endAt: string;
    branch?: number;
  }): Promise<any> => {
    setHasLoading(true);
    try {
      const {
        data: { numberOfStudents, studentsByStudyProgram, studentsByTutors }
      } = await reportsRepository.getStudentsPayingReport(params);

      setTotalStudents(numberOfStudents);
      setStudentsByStudyProgram(studentsByStudyProgram);
      setStudentsByTutors(studentsByTutors);
    } finally {
      setHasLoading(false);
    }
  };

  return (
    <Container>
      <StPageHeader onBack={goBack} title={strings.NUMBER_OF_STUDENTS}>
        <Filter fetchStudentsPayingReport={fetchStudentsPayingReport} />
      </StPageHeader>
      <Spin spinning={hasLoading}>
        <Row gutter={[10, 10]}>
          {totalStudents ? (
            <div>
              <Paragraph>
                <b>{strings.TOTAL_STUDENTS}: </b>
                {totalStudents}
              </Paragraph>
              <Paragraph>
                <b>{strings.STUDENTS_BY_STAGE}: </b>
                {studentsByStudyProgram.map((item: StudentsByStudyProgram) => (
                  <p>
                    {item.studyProgram.name}: {item.students.length}
                  </p>
                ))}
              </Paragraph>
              <Paragraph>
                <b>Students by tutors: </b>
                {studentsByTutors.map((item: StudentsByTutor) => (
                  <p>
                    {item.tutor.surname} {item.tutor.name}: {item.students.length}
                  </p>
                ))}
              </Paragraph>
            </div>
          ) : (
            <p>{strings.NOT_FOUND}</p>
          )}
        </Row>
      </Spin>
    </Container>
  );
};

export default { StudentsPayingReport };
