import React from 'react';
import { Modal } from 'antd';
import { NavigationConfirm, HistoryListener } from 'react-router-navigation-confirm';
import { useLanguageContext } from 'contexts/LanguageContext';

/**
 * @description Конферм ухода из журнала (только для учителя)
 * @param {boolean} hasJournalModified
 * @return {React.ReactNode}
 */
export const LessonConfirm = ({ hasJournalModified }) => {
  const [strings] = useLanguageContext();

  if (hasJournalModified) {
    return (
      (<HistoryListener>
        <NavigationConfirm when={hasJournalModified}>
          {({ onConfirm, onCancel }) => (
            <Modal open={true} onOk={onConfirm} onCancel={onCancel} title={strings.ARE_YOU_SURE}>
              <p>{strings.SURE_LEAVE_PAGE}</p>
            </Modal>
          )}
        </NavigationConfirm>
      </HistoryListener>)
    );
  }

  return null;
};
