import React, { useState, useEffect } from 'react';
import { useLanguageContext } from 'contexts/LanguageContext';
import { IAccessType } from 'types/AdminAcess';
import { useUserAccessRepository } from 'repos/UserAccessRepostirory';
import { Button, Col, Row, Select } from 'antd';
import { FilterButtons } from '../../../../Global/components/FilterButtons';

const { Option } = Select;

export const ACCESS_TYPES_MAPPING = {
  originalCourses: 'Original courses',
  teacherFinance: 'Teacher finance',
  teacherList: 'Teacher list',
  financeAccess: 'Finance access',
  adminHiddenComments: 'Admin hidden comments',
  loginAccess: 'Login access',
  adminCollaborationAccess: 'Admin collaboration access',
  monitoringAssessment: 'Monitoring assessment',
  leadFormRequest: 'Lead form request',
  adminAccess: 'Admin access',
  extendedFinanceAccess: 'Extended finance access',
  contractInfoEdit: 'Contract info edit',
  inventoryEdit: 'Inventory edit',
  trainingEdit: 'Training edit',
  teacherAccessInfo: 'Teacher access info',
  teacherCollabAccess: 'Teacher collaboration access',
  assistantCollabAccess: 'Assistant collaboration access',
  assistantFinance: 'Assistant finance',
  returnedInvoices: 'Returned invoices',
  cancelledInvoices: 'Cancelled invoices',
  courseGroups: 'Group courses'
};

export const AdminAccessFilter = ({ fetchAccess }) => {
  const [strings] = useLanguageContext();
  const [accessObjects, setAccessObjects] = useState<IAccessType[]>([]);
  const [accessType, setAccessType] = useState<number>(null);
  const adminAccessRepo = useUserAccessRepository();

  useEffect(() => {
    fetchObjectAccess();
  }, []);

  const handleChange = async (type: number) => {
    setAccessType(type);
    await fetchAccess({ accessObject: type, page: 1 });
  };

  const fetchObjectAccess = async (): Promise<any> => {
    try {
      const {
        data: { items }
      } = await adminAccessRepo.getObjectAccess();
      setAccessObjects(items.sort((a, b) => a.name.localeCompare(b.name)));
    } catch {}
  };

  const handleClear = async (): Promise<any> => {
    setAccessType(null);
    await fetchAccess({ page: 1 });
  };

  return (
    <Row gutter={[10, 10]}>
      <Col lg={8}>
        <Select value={accessType} placeholder={strings.CHOOSE_ACCESS_TYPE} onChange={handleChange}>
          {accessObjects.map(({ id, name }) => (
            <Option key={id} value={id}>
              {ACCESS_TYPES_MAPPING[name]}
            </Option>
          ))}
        </Select>
      </Col>
      <FilterButtons handleClear={handleClear} isSameRow={true} handleSubmit={fetchObjectAccess} />
    </Row>
  );
};
