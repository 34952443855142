export const BASE_NEWS = '/news';
export const FULL_NEWS = `${BASE_NEWS}/:id`;
export const EDIT_NEWS = `${BASE_NEWS}/edit/:id`;
export const CREATE_NEWS = `${BASE_NEWS}/create`;

export default {
  BASE_NEWS,
  EDIT_NEWS,
  CREATE_NEWS
};
