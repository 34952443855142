import React, { useMemo, useState } from 'react';
import { Modal, Button, Select, message, Form, Alert, Flex } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Branch } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';
import { NewReportBranches } from 'types/Reports';

const { Option } = Select;

interface BtnGeneratePDFProps {
  handler: () => Promise<any>;
  reportNewBranches: NewReportBranches[];
}

export const BtnGeneratePDF = ({ handler, reportNewBranches }: BtnGeneratePDFProps) => {
  const [form] = Form.useForm();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const { branches } = useGlobalCollectionsContext();
  const reportsRepository = useProgressReportsRepository();
  const filteredBranches = useMemo(() => reportNewBranches.map(item => item.branch), [reportNewBranches]);

  const [strings] = useLanguageContext();

  const closeModal = (): void => {
    setHasShowModal(false);
    form.resetFields();
  };

  const handleSubmit = async (values): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await reportsRepository.generateProgressReportPDF(values);
      message.warning(data?.message, 3);
      if (data?.warning?.length) {
        const warningContent = data?.warning.map((warning, index) => <p key={index}>{warning}</p>);
        message.warning(warningContent, 3);
      }

      handler();
      closeModal();
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  return (
    <>
      <Button type="primary" onClick={() => setHasShowModal(true)} style={{ margin: 0, fontSize: 'small' }}>
        {`${strings.NEW_REPORT} - ${strings.FOR_BRANCHES}`}
      </Button>
      <Modal
        title={strings.DO_YOU_WANT_TO_GENERATE_NEW_PROGRESS_REPORTS}
        footer={[]}
        open={hasShowModal}
        onCancel={closeModal}
        style={{ top: 20 }}
        width={600}
        destroyOnClose={true}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name="branch"
            label={strings.BRANCHES}
            rules={[{ required: true, message: `${strings.BRANCHES} ${strings.IS_REQUIRED}` }]}
          >
            <Select mode="multiple">
              {branches.map((branch: Branch) => {
                const isDisabled = filteredBranches.some(newBranch => newBranch.id === branch.id);
                return (
                  <Option value={branch.id} key={branch.id} disabled={isDisabled}>
                    {branch.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Alert message={strings.ATTENTION_THIS_ACTION_CANNOT_BE_UNDONE} type="warning" showIcon />
          <Flex justify="end" gap={10} style={{ marginTop: 20 }}>
            <Button key="cancel" onClick={closeModal}>
              {strings.CANCEL}
            </Button>
            <Button type="primary" key="save" htmlType="submit" loading={hasLoading}>
              {strings.GENERATE}
            </Button>
          </Flex>
        </Form>
      </Modal>
    </>
  );
};

export default { BtnGeneratePDF };
