import React, { useState, useEffect } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { Row, Table, Button } from 'antd';
import { getColumns } from './columns';
import { Loader } from 'Global/components/Loader/Loader';
import { TeacherGradingAssessment } from 'types/TeacherAccount/types';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useTeacherRepository } from 'repos/TeacherRepository';
import { useHistory } from 'react-router-dom';
import { Container } from 'Global/GlobalStyle';
import { GradingModal } from './GradingModal';

/**
 * @description Грейдинг учителя
 * @returns {React.ReactNode}
 */
export const Grading = () => {
  const [strings] = useLanguageContext();
  const teacherRepository = useTeacherRepository();
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [grading, setGrading] = useState<TeacherGradingAssessment[]>();
  const [selectedGrading, setSelectedGrading] = useState<TeacherGradingAssessment>();

  const { goBack } = useHistory();

  const getGrading = async () => {
    setLoading(true);
    try {
      const {
        data: { items }
      } = await teacherRepository.getTeacherGrading();
      setGrading(items);
    } catch {
      setGrading([]);
    } finally {
      setLoading(false);
    }
  };
  /**
   * @description showDetails
   * @param {<ModalParams>} details
   * @returns {void}
   */
  const showDetails = (assessmentId): void => {
    const details = grading.find(item => item.assessmentId === assessmentId);
    setSelectedGrading(details);
    setHasShowModal(true);
  };

  const actionClmn = {
    title: '',
    dataIndex: 'assessmentId',
    key: 'assessmentId',
    render: assessmentId => {
      return (
        <Button type="link" onClick={() => showDetails(assessmentId)}>
          {strings.SHOW_DETAILS}
        </Button>
      );
    }
  };

  useEffect(() => {
    getGrading();
  }, []);

  return (
    <Container>
      {selectedGrading && (
        <GradingModal hasShowModal={hasShowModal} setHasShowModal={setHasShowModal} selectedGrading={selectedGrading} />
      )}
      <Loader spinning={loading}>
        <PageHeader onBack={goBack} style={{ padding: 0, marginBottom: 20 }} title={strings.GRADING} />
        <Row>
          <Table dataSource={grading} columns={[...getColumns(strings), actionClmn]} pagination={false} />
        </Row>
      </Loader>
    </Container>
  );
};

export default { Grading };
