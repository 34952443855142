import React from 'react';
import { Link } from 'react-router-dom';
import { LogoutOutlined, MessageTwoTone } from '@ant-design/icons';
import { Row, Dropdown, Badge, Popover } from 'antd';
import { Avatar } from 'Global/components/Avatar';
import {
  HeaderUserResponsive,
  HeaderContainer,
  MobileHeaderGroup,
  headerMobileIconSt
} from 'Global/components/Header/styles';
import { Search } from 'Global/components/Header/Search';
import { Languages } from 'Global/components/Header/HeaderLanguages';
import { UserMenu } from 'Global/components/Header/UserMenu';
import { useUserContext } from 'contexts/UserContext';
import { useThreadsContext } from 'contexts/ThreadsContext';
import { Logo } from 'Global/components/Logo';

interface MobileHeaderProps extends React.ComponentProps<any> {
  handleClickContent: (boolean) => void;
  hasShowPopover: boolean;
  setHasShowPopover: (hasShowPopover: boolean) => void;
  setHasShowTutor: (hasShowTutor: boolean) => void;
  popoverContent: React.ReactElement;
  setHasShowDropdown: (hasShowDropdown: boolean) => void;
  hasShowDropdown: boolean;
  confirmLogout: () => void;
}

/**
 * @description Мобайл версия шапки
 * @param {object} props
 * @return {React.ReactNode}
 */
export const HeaderMobile = (props: MobileHeaderProps) => {
  const {
    handleClickContent,
    hasShowPopover,
    setHasShowPopover,
    setHasShowTutor,
    popoverContent,
    setHasShowDropdown,
    hasShowDropdown,
    confirmLogout
  } = props;

  const [user] = useUserContext();
  const [unreadThreads] = useThreadsContext();

  return (
    <HeaderContainer>
      {props.children}
      <Row align="middle" justify="space-between" style={{ height: 70, gap: 10, flexWrap: 'nowrap' }}>
        <Link to="/" style={{ display: 'flex' }}>
          <Logo />
        </Link>
        <MobileHeaderGroup>
          {user?.hasRoles?.admin && <Search />}
          {(user?.hasRoles?.teacher || user?.hasRoles?.parent) && (
            <Popover
              content={popoverContent}
              placement="bottom"
              trigger="click"
              visible={hasShowPopover}
              onVisibleChange={visible => setHasShowPopover(visible)}
            >
              <Badge
                count={unreadThreads + Number(user?.rejectedCommentsCount || 0)}
                style={{
                  marginTop: '.75rem'
                }}
              >
                <MessageTwoTone
                  style={{ fontSize: 22, cursor: 'pointer', position: 'relative', top: 2 }}
                  twoToneColor="#ccc"
                  className="message-icon"
                />
              </Badge>
            </Popover>
          )}
          {user?.hasRoles?.student && (
            <MessageTwoTone
              onClick={() => handleClickContent(setHasShowTutor)}
              className="message-icon"
              style={headerMobileIconSt}
              twoToneColor="#ccc"
            />
          )}
          <HeaderUserResponsive isAdmin={user?.hasRoles?.admin}>
            <Dropdown
              overlayStyle={{ zIndex: 1008 }}
              overlay={<UserMenu />}
              trigger={['click']}
              open={hasShowDropdown}
              onVisibleChange={visible => setHasShowDropdown(visible)}
            >
              <div
                onClick={e => e.preventDefault()}
                style={{ display: 'flex', gap: 10, alignItems: 'center', cursor: 'pointer' }}
              >
                <Avatar
                  isHeaderAvatar={true}
                  photo={user.mainPhoto}
                  size={30}
                  isClickDisabled
                  userName={user?.name}
                  userSurname={user?.surname}
                ></Avatar>
              </div>
            </Dropdown>
            <LogoutOutlined onClick={confirmLogout} style={{ ...headerMobileIconSt, opacity: 0.7 }} />
          </HeaderUserResponsive>
          <Languages />
        </MobileHeaderGroup>
      </Row>
    </HeaderContainer>
  );
};

export default { HeaderMobile };
