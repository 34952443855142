import React from 'react';
import { Form } from '@ant-design/compatible';
import { Select, Button, DatePicker, Row, Col, Checkbox } from 'antd';
import { dateFormat } from 'helpers/dates';
import { TutorSelect } from 'Global/components/FormComponentsCompatible/Selects/TutorSelect';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';

// Types
import { Branch } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Option } = Select;

interface FilterProps {
  form: WrappedFormUtils;
}

export default Form.create({})((props: FilterProps): any => {
  const {
    form: { getFieldDecorator }
  } = props;

  const [strings] = useLanguageContext();
  const { branches } = useGlobalCollectionsContext();

  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={[10, 10]}>
        <Col lg={4}>
          {getFieldDecorator('from', {
            rules: [{ required: false }]
          })(<DatePicker format={dateFormat} placeholder={strings.MONTH} />)}
        </Col>
        <Col lg={4}>
          {getFieldDecorator('teachers', {
            rules: [{ required: false }]
          })(<Select placeholder={strings.TEACHERS} />)}
        </Col>
        <Col lg={4}>
          {getFieldDecorator('branch', {
            rules: [{ required: false }]
          })(
            <Select placeholder={strings.BRANCH}>
              <Option value="">&nbsp;</Option>
              {branches.map((branch: Branch) => (
                <Option value={branch.id} key={branch.id}>
                  {branch.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col lg={4}>
          <TutorSelect placeholder={strings.ADM} getFieldDecorator={getFieldDecorator} name="tutor" />
        </Col>
        <Col lg={2}>
          {getFieldDecorator('na', {
            rules: [{ required: false }]
          })(<Checkbox>{strings.N_A}</Checkbox>)}
        </Col>
        <Col lg={2}>
          {getFieldDecorator('ni', {
            rules: [{ required: false }]
          })(<Checkbox>{strings.NI}</Checkbox>)}
        </Col>
        <Col lg={2}>
          {getFieldDecorator('s', {
            rules: [{ required: false }]
          })(<Checkbox>{strings.S}</Checkbox>)}
        </Col>
        <Col lg={2}>
          {getFieldDecorator('g', {
            rules: [{ required: false }]
          })(<Checkbox>{strings.G}</Checkbox>)}
        </Col>
      </Row>
      <Row>
        <Col lg={24}>
          <Button type="primary" size="large" htmlType="submit">
            {strings.FILTER}
          </Button>
        </Col>
      </Row>
    </Form>
  );
});
