import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Table, Typography, Row } from 'antd';
import Pagination from 'Global/components/Pagination';
import { mockPagination, MANUAL_INPUT_LIMIT } from 'Global/constants';
import { ManualInputListItem, ManualInputFilterParams } from 'types/finance';
import { PaginationInfo } from 'types/global';
import { Finance } from 'api/Finance';
import BaseFilter from 'helpers/BaseFilter';
import { scrollTop } from 'helpers/scroll';
import { Filter } from './Filter';
import { columns } from './columns';
import { Forbidden } from 'Global/modules/Errors/403';
import { has } from 'lodash';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';

const { Title } = Typography;
const { Column } = Table;

interface ManualInputProps {}

/**
 * @description  Manual Input component
 * @param {object} props
 * @return {React.ReactNode}
 */
export const ManualInput = (props: ManualInputProps) => {
  const [strings] = useLanguageContext();
  const { goBack, push } = useHistory();
  const [manualInputList, setManualInputList] = useState<ManualInputListItem[]>([]);
  const [hasLoadManualInputList, setLoadManualInputList] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationInfo>(mockPagination);

  const [user] = useUserContext();

  const manualInputService = new BaseFilter();
  const limit = MANUAL_INPUT_LIMIT;
  const { locationSearchParams } = useLocationSearchParams();
  const { pageCount, current } = pagination;
  const { branches } = locationSearchParams;
  //параметр branches не может быть string
  if (branches && typeof branches === 'string') {
    locationSearchParams['branches'] = [branches];
  }

  const hasTopManager = user?.hasRoles?.topManager;
  const hasBranchDirector = user?.hasRoles?.branchDirector;
  const hasBranchDirectorOrTopManager = hasTopManager || hasBranchDirector;

  /**
   * @description Пагинация
   * @param {number} page
   * @return {void}
   */
  const handlePage = async (page: number): Promise<any> => {
    const params = manualInputService.getFilterParams(locationSearchParams, page, null, limit);
    await getManualInputList({ ...params });
    push({ search: manualInputService.getQueryStringParams(params) });
    scrollTop();
  };

  /**
   * @description Получение списка Manual input
   * @param {ManualInputFilterParams} params
   * @return {Promise<any>}
   */
  const getManualInputList = async (params: ManualInputFilterParams): Promise<any> => {
    setLoadManualInputList(true);
    if (hasBranchDirector && has(user, 'branch')) {
      //Для ROLE_BRANCH_DIRECTOR отчёт сразу фильтруется по его филиалу
      params['branches'] = [user?.branch?.id];
    }

    await Finance.getManualInputList({ ...params })
      .then(({ data: { items, paginationInfo }, status }) => {
        if (status === 200) {
          setManualInputList(items);
          setPagination(paginationInfo);
          push({ search: manualInputService.getQueryStringParams(params) });
        }
      })
      .catch(() => {})
      .finally(() => setLoadManualInputList(false));
  };

  useEffect(() => {
    getManualInputList(locationSearchParams);
  }, [user]); // eslint-disable-line

  if (!hasBranchDirectorOrTopManager)
    return (
      <Forbidden subTitle={strings.SORRY_YOU_DO_NOT_HAVE_ACCESS_TO_THE_REPORT}>
        <Button size="large" type="primary" icon={<ArrowLeftOutlined />}>
          <Link to="/" style={{ color: '#fff', paddingInlineStart: '10px' }}>
            Back to home
          </Link>
        </Button>
      </Forbidden>
    );

  return (
    <Container>
      <StPageHeader onBack={goBack} title={strings.MANUAL_INPUT}>
        <Filter getManualInputList={getManualInputList} />
      </StPageHeader>
      <Row gutter={[10, 10]}>
        <Table dataSource={manualInputList} pagination={false} bordered={false} loading={hasLoadManualInputList}>
          {columns(strings).map(col => (
            <Column key={col.key} {...col} />
          ))}
        </Table>
        <Pagination count={pageCount} current={current} pageChange={handlePage} />
      </Row>
    </Container>
  );
};

export default { ManualInput };
