import React, { memo } from 'react';
import Helmet from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { Location } from 'history';
import Login from './Login';
import Forgot from './Forgot';
import { AuthSt } from 'Global/modules/Auth/styles';
import { storageService } from 'contexts/StorageContext';
import septemberGirl from 'img/septemberGirl.svg';
// import girlImage from './girl.png';
// import summerGirl from 'img/summerGirl.svg';
// import { SPRING_GIRL } from 'img/springGirl';
// import { NG_GIRL } from 'img/girlNg';
import styled from 'styled-components';
import { BrunerLogo, LgegLogo, RGLogo } from 'Global/components/Logo';
import { keyForTheme, useThemeContext } from 'contexts/ThemeContext';
import logoBruner from 'img/logoBruner.svg';

const StyledGirlContainer = styled.div`
  background: #fff;
  display: none;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 10px 0;
  width: 50%;
  position: relative;
  img {
    width: 60%;
  }
  @media (min-width: 1100px) {
    display: flex;
  }
`;

const StyledFormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e3edfe;
  width: 100%;
  height: max-content;
  padding: 150px 15px;
  @media (min-width: 1100px) {
    width: 50%;
    height: 100vh;
  }
`;

const StyledInspired = styled.div`
  position: absolute;
  bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 0 50px;
  opacity: 0.7;
  svg,
  span,
  a {
    margin: 0 5px;
  }
  span {
    font-weight: 300;
    font-size: 16px;
    line-height: 227.5%;
    color: #333;
    display: flex;
    align-items: center;
    p {
      margin: 10px 0 0 0;
    }
    svg {
      margin-inline-end: 10px;
    }
  }

  a {
    color: #659ae9;
    font-size: 18px;
    text-decoration: underline;
    p {
      margin: 10px 0 0 0;
    }
  }
`;

interface AuthProps {
  isForgot: boolean;
  location?: Location | { state: any };
}

const AuthTemplate = (props: AuthProps) => {
  const { location, isForgot } = props;
  const { from } = location?.state || { from: { pathname: '/' } };
  const { activeThemeKey } = useThemeContext();

  if (storageService.getAccessToken()) {
    return <Redirect to={from} />;
  }

  return (
    <>
      <Helmet>
        <title>AMI auth</title>
      </Helmet>
      <AuthSt>
        <StyledGirlContainer>
          {activeThemeKey === keyForTheme.bruner && (
            <img style={{ height: 40, width: 200, position: 'absolute', top: 60, left: 0 }} src={logoBruner} />
          )}
          <img src={septemberGirl} />
          {activeThemeKey !== keyForTheme.bruner && (
            <StyledInspired>
              <RGLogo />
              <LgegLogo />
              <p style={{ display: 'inherit' }}>
                Inspired by{' '}
                <a href="https://londongates.org" target="_blank">
                  LGEG
                </a>{' '}
                and{' '}
                <a target="_blank" href="https://paris.russiangymnasium.com/">
                  Gymnasium №1
                </a>
              </p>
            </StyledInspired>
          )}
        </StyledGirlContainer>
        <StyledFormContainer>{isForgot ? <Forgot /> : <Login />}</StyledFormContainer>
      </AuthSt>
    </>
  );
};

export const Auth = memo(AuthTemplate);
