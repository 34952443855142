import React from 'react';
import { Skeleton } from 'antd';
import styled from 'styled-components';

const Container = styled.div<{ nopadding?: boolean }>`
  padding: ${props => (props.nopadding ? '0px' : '20px')};
`;

interface LoadingItemsProps {
  avatar?: boolean;
  loading?: boolean;
  rows?: number;
  count?: number;
  nopadding?: boolean;
}

/**
 * @description Content loading
 * @param {boolean} avatar
 * @param {boolean} loading
 * @param {number} count
 * @param {number} rows
 * @param {boolean} nopadding
 * @return {React.ReactNode}
 */
export const LoadingItems = ({
  count = 1,
  rows = 1,
  nopadding = false,
  avatar = true,
  loading = true
}: LoadingItemsProps) => {
  // Если одна строка, тогда скрываем строки показываем только заголовок
  const showOnlyTitle = rows === 1 ? 0 : rows;
  const skeletons = Array(count)
    .fill('')
    .map((_, index) => (
      <Skeleton key={index} active paragraph={{ rows: showOnlyTitle }} avatar={avatar} loading={loading} />
    ));

  return <Container nopadding={nopadding}>{skeletons}</Container>;
};

export default { LoadingItems };
