import React, { useState } from 'react';
import { AttachmentItem } from 'types/global';
import { ZoomInOutlined, LoadingOutlined, DeleteOutlined } from '@ant-design/icons';
import { message, Popconfirm, Spin, Row, Col, Flex } from 'antd';
import { Global } from 'api';
import { PreviewFileModal } from 'Global/components/FilePreview';
import { useLanguageContext } from 'contexts/LanguageContext';
import { getLessonAttachmentPreview } from './FilePreview/helpers';

interface AttachmentsListProps {
  attachments?: any[];
  updateAttachments?: () => Promise<any>;
}

/**
 * @description Файлы с материалами
 * @param {object} props
 * @return {React.ReactNode}
 */
export const AttachmentsList = (props: AttachmentsListProps) => {
  const [strings] = useLanguageContext();
  const { attachments, updateAttachments } = props;
  const [hasShowPreviewModal, setHasShowPreviewModal] = useState<boolean>(false);
  const [hasLoadPreviewAttachment, setHasLoadPreviewAttachment] = useState<boolean>(false);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [currentAttachment, setCurrentAttachment] = useState(null);
  const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer>('');

  const hideModal = () => setHasShowPreviewModal(false);

  /**
   * @description Удаление материала
   *  @return {Promise<any>}
   */
  const handleDeleteAttach = async (attachmentId: number): Promise<any> => {
    setHasLoading(true);
    try {
      await Global.deleteAttachment(attachmentId);
      await updateAttachments();
    } catch {
    } finally {
      setHasLoading(false);
    }
  };
  /**
   * @description Подтверждение на удаление файла
   */
  const confirmDelete = (attachmentId: number) => {
    handleDeleteAttach(attachmentId);
  };

  /**
   * @description Превью в модалке
   * @param {number} attachmentId
   * @param {string} attachmentName
   * @return {Promise<any>}
   */
  const handleShowModal = async (attachmentId: number, attachmentName: string): Promise<any> => {
    setCurrentAttachment({
      id: attachmentId,
      name: attachmentName
    });
    setHasLoadPreviewAttachment(true);
    try {
      await getLessonAttachmentPreview(attachmentId, setPreviewUrl, setHasShowPreviewModal, strings);
    } catch (err) {
      message.error(strings.ERROR_OPENING_PREVIEW_FILE);
    } finally {
      setHasLoadPreviewAttachment(false);
    }
  };

  if (attachments) {
    return (
      <div style={{ marginTop: 10 }}>
        {currentAttachment && (
          <PreviewFileModal
            hasLoadPreviewAttachment={hasLoadPreviewAttachment}
            fileName={currentAttachment?.name}
            previewUrl={previewUrl}
            handleHideModal={hideModal}
            hasShowModal={hasShowPreviewModal}
          />
        )}
        <Spin spinning={hasLoading}>
          {attachments?.map((attach: AttachmentItem) => (
            <Flex align="middle" key={attach.id} gap={10}>
              <Flex gap={5}>
                <Popconfirm
                  title={strings.DO_YOU_WANT_TO_DELETE_THIS_FILE}
                  onConfirm={() => confirmDelete(attach?.id)}
                  okText={strings.YES}
                  cancelText={strings.NO}
                >
                  <DeleteOutlined style={{ color: '#cc1e44' }} />
                </Popconfirm>
                {hasLoadPreviewAttachment && currentAttachment.id === attach.id ? (
                  <LoadingOutlined onClick={() => handleShowModal(attach.id, attach.name)} />
                ) : (
                  <ZoomInOutlined onClick={() => handleShowModal(attach.id, attach.name)} />
                )}
              </Flex>
              <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{attach?.name}</p>
            </Flex>
          ))}
        </Spin>
      </div>
    );
  }
  return null;
};

export default { AttachmentsList };
