import { IReplyParams, INewThread, IMessage, ThreadsParams } from 'types/CRM/messages';
import { httpService } from 'api/core';

/**
 * @description Получить thread по id
 * @param {number} id
 * @return {Promise<any>}
 */
export const getThread = (id: number): Promise<any> => httpService.get(`/api/threads/${id}`);

/**
 * @description Удалить thread по id
 * @param {number} id
 * @return {Promise<any>}
 */
export const deleteThread = (id: number): Promise<any> => httpService.delete(`/api/threads/${id}`);

/**
 * @description
 * @param {number} id
 * @param {IReplyParams} params
 * @return {Promise<any>}
 */
export const replyThread = (id: number, params: IReplyParams): Promise<any> =>
  httpService.post(`/api/threads/${id}/reply`, params);

/**
 * @description Создание треда
 * @param {object} params
 * @return {Promise<any>}
 */
export const newThread = (params: INewThread): Promise<any> => httpService.post('/api/threads', params);

export default {
  getThread,
  deleteThread,
  replyThread,
  newThread
};
