import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Loader } from 'Global/components/Loader/Loader';
import { Row, Col, Tabs, Layout } from 'antd';
import { Admin } from 'types';
import { useAdminRepository } from 'repos/AdminRepository';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { match } from 'react-router';
import Breadcrumbs from 'Global/components/Breadcrumbs';
import { useLanguageContext } from 'contexts/LanguageContext';
import { AdminProfile } from './AdminProfile';
import { UserAccess } from './UserAccess';
import { useUserContext } from 'contexts/UserContext';
import { useMediaQuery } from 'react-responsive';

const { Content } = Layout;
const { TabPane } = Tabs;
export const StyledUserAccess = styled.div``;

export const AdminPage = () => {
  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();
  const [user] = useUserContext();
  const [strings] = useLanguageContext();
  const adminRepository = useAdminRepository();
  const location = useLocation();
  const [admin, setAdmin] = useState<Admin>();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const fetchAdminById = async (): Promise<any> => {
    try {
      setHasLoading(true);
      const { data } = await adminRepository.getAdminById(id);
      setAdmin(data);
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    fetchAdminById();
  }, [id]);

  return (
    <Loader spinning={hasLoading}>
      <Content style={{ margin: 20 }}>
        <Breadcrumbs location={location} />
        <Row align="top" wrap={isMobile} style={{ gap: 20 }}>
          {admin && (
            <AdminProfile admin={admin} updateAdmin={fetchAdminById} hasEmptyTabs={!user?.hasRoles?.topManager} />
          )}
          <Col flex={1}>
            {user?.access?.adminAccess && (
              <Tabs defaultActiveKey={'access'}>
                <TabPane tab={strings.ACCESS} key="access">
                  <UserAccess userId={admin?.user?.id} />
                </TabPane>
              </Tabs>
            )}
          </Col>
        </Row>
      </Content>
    </Loader>
  );
};
