import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { EditOutlined, UploadOutlined } from '@ant-design/icons';
import { Spin, Input, Checkbox, Row, Col, Button, DatePicker, Select, Upload, message, Tooltip, Form } from 'antd';
import { Branch, INews, Role, StudyProgram } from 'types';
import { beforeImageUpload, getBase64 } from 'helpers';
import { Global } from 'api';
import { useNewsRepository } from 'repos/NewsRepository';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { momentFormatValues } from 'helpers/momentFormatValues';
import { BASE_NEWS } from './routes';
import { getImageUrl } from 'helpers/getImageUrl';
import MyJoditEditor from 'Global/components/MyJoditEditor';
import type { UploadFile } from 'antd';
import { IPostNewsParams } from 'types/news';

interface NewsFormProps {
  id?: string;
}

const { Option } = Select;

const editorModules = {
  toolbar: [['bold', { align: [] }, { color: [] }, { background: [] }, 'clean']]
};

export const NewsForm = ({ id }: NewsFormProps) => {
  const [strings] = useLanguageContext();
  const { push } = useHistory();
  const newsRepository = useNewsRepository();
  const [form] = Form.useForm();
  const [hasRolesLoading, setHasRolesLoading] = useState<boolean>(false);
  const [hasStudyProgramsLoading, setHasStudyProgramsLoading] = useState<boolean>(false);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState<Role[]>([]);
  const [studyPrograms, setStudyPrograms] = useState<StudyProgram[]>([]);
  const [previewPhoto, setPreviewPhoto] = useState<string>('');
  const [uploadingFile, setUploadingFile] = useState<UploadFile>(null);
  const { branches } = useGlobalCollectionsContext();
  const [news, setNews] = useState<INews>(undefined);
  const [initValues, setInitValues] = useState<IPostNewsParams>();

  const fetchNewsById = async (id: number) => {
    setHasLoading(true);
    const { data } = await newsRepository.getNewsById(id);
    setNews(data);
    const dateValues: IPostNewsParams = {
      heading: data?.header,
      date: data?.date && dayjs(news?.date),
      branch: data?.branch.map(branch => branch.id) || [],
      roles: data?.roles?.map(role => role.id),
      studyProgram: data?.studyPrograms.map(program => program.id),
      importantNews: data?.important,
      addVideo: data?.hasVideo,
      fixed: data?.fixed,
      text: data?.body
    };
    setInitValues(dateValues);

    if (data?.image) {
      setPreviewPhoto(getImageUrl(data.image));
    }
    setHasLoading(false);
  };

  const fetchRoles = async (): Promise<void> => {
    setHasRolesLoading(true);
    const { data } = await newsRepository.getNewsRoleList();
    setRoles(data);
    setHasRolesLoading(false);
  };

  const fetchStudyPrograms = async (): Promise<void> => {
    setHasStudyProgramsLoading(true);
    try {
      const { data } = await Global.getStudyPrograms();
      setStudyPrograms(data);
    } catch {
      message.error(
        strings.SORRY_AN_UNEXPECTED_ERROR_OCCURED_WHEN_TRYING_TO_FETCH_STUDY_PROGRAMS_PLEASE_TRY_AGAIN_LATER
      );
    } finally {
      setHasStudyProgramsLoading(false);
    }
  };

  const handleSubmit = async (values: IPostNewsParams): Promise<any> => {
    const params = momentFormatValues(values);
    if (uploadingFile) {
      params['photo'] = uploadingFile;
    }
    try {
      setHasLoading(true);
      if (id) {
        await newsRepository.updateNewsById(id, params);
        push(`/news/${id}`);
      } else {
        await newsRepository.createNews(params);
        push(BASE_NEWS);
      }
    } finally {
      setHasLoading(false);
    }
  };

  /**
   * @description Обработчик на загрузку audio
   * @param {File} file
   * @return {Promise<any>}
   **/
  const handleFileChange = async (upload): Promise<any> => {
    const imageHash = await getBase64(upload.file);
    setPreviewPhoto(imageHash);
    setUploadingFile(upload.file);
  };

  const uploadProps = {
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    onChange: handleFileChange,
    beforeUpload: beforeImageUpload
  };

  useEffect(() => {
    fetchRoles();
    fetchStudyPrograms();
    if (id) {
      fetchNewsById(Number(id));
    }
  }, []);

  useEffect(() => {
    if (initValues) {
      form.setFieldsValue(initValues);
    }
  }, [initValues]);

  return (
    <Spin spinning={hasLoading} tip={id ? strings.LOADING_NEWS_TO_EDIT : strings.NEWS_LOADING}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row align="middle" gutter={[10, 10]}>
          <Col xs={24} lg={24}>
            <Form.Item name="heading" label={strings.HEADING} required>
              <Input placeholder={strings.HEADING} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item name="date" label={strings.DATE} required>
              <DatePicker format={'DD-MM-YYYY'} disabledDate={(date: Dayjs) => dayjs().isAfter(date)} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item name="branch" label={strings.BRANCH} required>
              <Select mode="multiple" maxTagCount="responsive">
                {branches.map((branch: Branch) => (
                  <Option key={branch.id} value={branch.id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item name="roles" label={strings.FUNCTIONS}>
              <Select mode="multiple" maxTagCount="responsive" loading={hasRolesLoading}>
                {roles.map((role: Role) => (
                  <Option key={role.id} value={role.id}>
                    {role.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item name="studyProgram" label={strings.STUDY_PROGRAMMES}>
              <Select mode="multiple" maxTagCount="responsive" loading={hasStudyProgramsLoading}>
                {studyPrograms.map((program: StudyProgram) => (
                  <Option key={program.id} value={program.id}>
                    {program.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item name="importantNews" valuePropName="checked">
              <Checkbox>{strings.POP_UP_NEWS}</Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item name="addVideo" valuePropName="checked">
              <Checkbox>{strings.ADD_TO_VIDEO_NEWS_PAGE}</Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item valuePropName="checked">
              <Checkbox>{strings.FIXED_NEWS_HOW_TO}</Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item name="text" label={strings.TEXT}>
              <MyJoditEditor
                key="text-editor"
                toolbar={['bold', 'font', 'fontsize', 'brush', 'paragraph', 'align', 'eraser', 'undo', 'redo']}
                onChange={value => form.setFieldsValue({ text: value })}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item label={strings.PHOTO}>
              {previewPhoto ? (
                <Row align="middle">
                  <div style={{ flex: 1 }}>
                    <img src={previewPhoto} width="100%" />
                  </div>
                  <Upload {...uploadProps}>
                    <Tooltip title={strings.REPLACE_THE_FILE}>
                      <Button size="small" icon={<EditOutlined />} style={{ marginInlineStart: 8, fontSize: 14 }} />
                    </Tooltip>
                  </Upload>
                </Row>
              ) : (
                <Upload {...uploadProps}>
                  <Button>
                    <UploadOutlined /> {strings.UPLOAD}
                  </Button>
                </Upload>
              )}
            </Form.Item>
          </Col>
          <Col xs={24} lg={24} style={{ paddingTop: 40 }}>
            <Button type="primary" htmlType="submit">
              {id ? strings.EDIT : strings.CREATE}
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default { NewsForm };
