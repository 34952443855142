import React, { useContext, useMemo } from 'react';
import { httpService } from 'api/core';
import {
  AssessmentsFilterParams,
  AssessmentStatusesItem,
  IExpertAssessment,
  FeedbackAssessment,
  HwAssessment,
  LessonAssessmentDefault,
  LessonAssessmentPreshool,
  LessonAssessmentPrimary,
  IAssessmentCalendarParams,
  LessonRecordingPostParams
} from 'types/accessment';
import { IRepositoryProvider } from './RepositoryProvider';

const AssessmentsRepositoryContext = React.createContext<IAssessmentsRepository>(null);
interface IAssessmentsRepository {
  getAssessmentList: (params: AssessmentsFilterParams) => Promise<any>;
  getAssessmentByLessonId: (lessonId: number | string) => Promise<any>;
  editAssessmentByLessonId: (
    id: number,
    params: Partial<
      | LessonAssessmentPrimary
      | LessonAssessmentDefault
      | LessonAssessmentPreshool
      | HwAssessment
      | IExpertAssessment
      | FeedbackAssessment
      | LessonRecordingPostParams
    >
  ) => Promise<any>;
  exportAssessmentByLessonId: (id: number | string) => Promise<any>;
  getAssessmentStatuses: () => Promise<any>;
  getAssessmentStatusById: (id: number | string) => Promise<any>;
  deleteAssessmentStatus: (id: number | string) => Promise<any>;
  editAssessmentStatus: (id: number | string, params: AssessmentStatusesItem) => Promise<any>;
  createAssessmentStatus: (params: AssessmentStatusesItem) => Promise<any>;
  getAssessmentCalendar: (params: IAssessmentCalendarParams) => Promise<any>;
  getCalendarLessonsByTeacher: (id: number, params: { dateFrom: string; dateTo: string }) => Promise<any>;
  scheduleAssessments: (params: { lessonsChecked: number[]; lessonsUnchecked: number[] }) => Promise<any>;
}

class AssessmentsRepository implements IAssessmentsRepository{
  getAssessmentList(params: AssessmentsFilterParams) {
    return httpService.get('/api/v1/assessment', params, null, true);
  }

  getAssessmentByLessonId(lessonId: number) {
    return httpService.get(`/api/v1/assessment/lesson/${lessonId}`, null, null, true);
  }

  editAssessmentByLessonId(
    id: number,
    params: Partial<
      | LessonAssessmentPrimary
      | LessonAssessmentDefault
      | LessonAssessmentPreshool
      | HwAssessment
      | IExpertAssessment
      | FeedbackAssessment
      | LessonRecordingPostParams
    >
  ) {
    return httpService.put(`/api/v1/assessment/${id}`, params, null, null, true);
  }

  exportAssessmentByLessonId(id: number | string) {
    return httpService.get(`/api/v1/assessment/lesson/${id}/export`, null, null, true);
  }

  getAssessmentStatuses() {
    return httpService.get('/api/v1/assessment/statuses/list', null, null, true);
  }

  getAssessmentStatusById(id: number) {
    return httpService.get(`/api/v1/assessment/statuses/${id}`, null, null, true);
  }

  editAssessmentStatus(id: number | string, params: AssessmentStatusesItem) {
    return httpService.put(`/api/v1/assessment/statuses/${id}`, params, null, null, true);
  }

  createAssessmentStatus(params: AssessmentStatusesItem) {
    return httpService.post(`/api/v1/assessment/statuses`, params, null, null, true);
  }

  deleteAssessmentStatus(id: number) {
    return httpService.delete(`/api/v1/assessment/statuses/${id}`, null, null, true);
  }

  getAssessmentCalendar(params: IAssessmentCalendarParams) {
    return httpService.get(`/api/v1/assessment/calendar`, params, null, true);
  }

  getCalendarLessonsByTeacher(id, params: { dateFrom: string; dateTo: string }) {
    return httpService.get(`/api/v1/assessment/calendar/teachers/${id}`, params, null, true);
  }

  scheduleAssessments(params: { lessonsChecked: number[]; lessonsUnchecked: number[] }) {
    return httpService.post(`/api/v1/assessment/calendar`, params, null, null, true);
  }
}

export const useAssessmentsRepository = () => {
  const service = useContext(AssessmentsRepositoryContext);

  if (!service) {
    throw new Error('Assessments repository is unavailable');
  }

  return service;
};

export const AssessmentsRepositoryProvider: React.FC<IRepositoryProvider> = ({ children }) => {
  const service = useMemo(() => new AssessmentsRepository(), []);

  return <AssessmentsRepositoryContext.Provider value={service}>{children}</AssessmentsRepositoryContext.Provider>;
};
