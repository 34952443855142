import React, { useMemo, useContext } from 'react';
import { httpService } from 'api/core';
import { ICommentaryCalendarParams, IAddCommentRequestParams, IDeleteCommentRequestParams } from 'types/commentary';

const CommentaryCalendarContext = React.createContext<ICommentaryCalendarRepository>(null);

interface ICommentaryCalendarRepository {
  getCommentaryCalendar: (params: ICommentaryCalendarParams) => Promise<any>;
  addCommentRequest: (params: IAddCommentRequestParams) => Promise<any>;
  deleteCommentRequest: (params: IDeleteCommentRequestParams) => Promise<any>;
}

class CommentaryCalendarRepository implements ICommentaryCalendarRepository {
  getCommentaryCalendar(params: ICommentaryCalendarParams) {
    return httpService.get('/api/v1/group-comments/calendar', params, null, true);
  }
  addCommentRequest(params: IAddCommentRequestParams) {
    return httpService.post('/api/v1/group-comments/calendar', params, null, null, true);
  }
  deleteCommentRequest(params: IDeleteCommentRequestParams) {
    return httpService.post('/api/v1/group-comments/calendar/delete', params, null, null, true);
  }
}

export const useCommentaryCalendarRepository = () => {
  const service = useContext(CommentaryCalendarContext);

  if (!service) {
    throw new Error('Commentary calendar repository is unavailable');
  }

  return service;
};

export const CommentaryCalendarRepositoryProvider = props => {
  const service = useMemo(() => new CommentaryCalendarRepository(), []);

  return <CommentaryCalendarContext.Provider value={service}>{props.children}</CommentaryCalendarContext.Provider>;
};
