import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Row, Col, DatePicker, TimePicker, Modal, Select, Button, message, Checkbox } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { TutorSelect } from 'Global/components/FormComponentsCompatible/Selects/TutorSelect';
import { IStudent } from 'types';
import { dateFormatForBackend, reversedDateFormatForBackend, dateFormatForLesson } from 'helpers/dates';
import { Families } from 'api/Families';
import { CreateContactOrStatusFamilyParams } from 'types/API/families';
import { isEmpty } from 'lodash';
import { StAddContact } from 'Admin/People/Families/Family/CollaborationHistory/styles';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import MyJoditEditor from 'Global/components/MyJoditEditor';

interface FilterHistoryProps extends FormComponentProps {
  isShow: boolean;
  hide: () => void;
  students: IStudent[];
  id: number; // Family id
  fetchCollabHistoryByDates: () => Promise<any>;
  fetchFamilyById: (familyId: number) => Promise<any>;
}

const { Option } = Select;

// Family statuses
const STATUS_IDS_WITH_WHEN_CONTACT_FIELD = [2, 4];
const CONTACT_LATER_STATUS_ID = 2;

/*
 * @description Модальное окно добавление contract
 * @param props - component props
 * @return {React.ReactNode}
 */
const AddContactTemplate = (props: FilterHistoryProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<number>(0);
  const {
    form: { getFieldDecorator, validateFields, setFieldsValue, resetFields },
    isShow,
    hide,
    students,
    id,
    fetchCollabHistoryByDates,
    fetchFamilyById
  } = props;

  const [strings] = useLanguageContext();
  const [user] = useUserContext();

  /*
   * @description Создание контакта
   * @param {number} id
   * @param {CreateContactOrStatusFamilyParams} params
   * @return {Promise<any>}
   */
  const createContactById = async (id: number, params: CreateContactOrStatusFamilyParams): Promise<any> => {
    setLoading(true);
    try {
      await Families.createFamilyContact(id, params);
      message.success(strings.CONTACT_HAS_BEEN_SUCCESSFULLY_CREATED, 2);
      resetFields();
      hide();
      fetchCollabHistoryByDates();
      fetchFamilyById(id);
    } catch (err) {
      if (err?.response?.data?.message) return message.error(err?.response?.data?.message);
      message.error(`${strings.ERROR_TRY_AGAIN_LATER} :(`, 2);
    } finally {
      setLoading(false);
    }
  };

  /*
   * @description Обработчик на submit формы
   * @param {React.FormEvent<EventTarget>} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFields((errors, values) => {
      if (isEmpty(errors)) {
        const { contact_date, contact_time } = values;
        if (values['contact_date']) {
          values['contact_date'] = contact_date.format(dateFormatForBackend);
        }
        if (values['contact_time']) {
          values['contact_time'] = contact_time.format('HH : MM');
        }
        createContactById(id, values);
        setSelectedStatus(0);
      }
    });
  };

  /*
   * @description Обработчик на выбор Tutor
   * @param {number} tutor
   * @return {void}
   */
  const tutorOnChange = (tutor: any): void => setFieldsValue({ tutor: tutor });

  /*
   * @description Обработчик на выбор контакта админа
   * @param {number} tutor
   * @return {void}
   */
  const contactAdminOnChange = (tutor: any): void => setFieldsValue({ contactLaterAdminID: tutor });

  return (
    <Modal
      onCancel={hide}
      title={strings.СONTRACT_CREATION}
      open={isShow}
      width={640}
      style={{ top: 20 }}
      footer={[
        <Button type="primary" onClick={hide} key="cancel">
          {strings.CANCEL}
        </Button>,
        <Button type="default" onClick={handleSubmit} disabled={loading} loading={loading} key="create">
          {strings.CREATE}
        </Button>
      ]}
    >
      <StAddContact>
        <Form layout="vertical" onSubmit={handleSubmit} style={{ margin: '30px 0' }}>
          <Row gutter={[10, 10]}>
            <Col lg={12} xs={24}>
              <TutorSelect
                name="lead_admin"
                initTutor={String(user?.meAdminId)}
                getFieldDecorator={getFieldDecorator}
                handleChange={tutorOnChange}
                label={strings.CONTACT_PERSON}
              />
            </Col>
            <Col lg={12} xs={24}>
              <MyFormItem label={strings.STUDENT}>
                {getFieldDecorator('child', {
                  rules: [{ required: false }]
                })(
                  <Select placeholder={strings.STUDENT}>
                    {students.map((child: IStudent) => (
                      <Option value={child.id} key={child.id}>
                        {child.user.surname} {child.user.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </MyFormItem>
            </Col>
            <Col lg={12} xs={24}>
              <MyFormItem label={strings.DATE}>
                {getFieldDecorator('contact_date', {
                  initialValue: dayjs(),
                  rules: [{ required: true, message: strings.DATE_IS_REQUIRED_FIELD }]
                })(<DatePicker format={reversedDateFormatForBackend} />)}
              </MyFormItem>
            </Col>
            <Col lg={12} xs={24}>
              <MyFormItem label={strings.TIME}>
                {getFieldDecorator('contact_time', {
                  initialValue: dayjs(),
                  rules: [{ required: false }]
                })(<TimePicker format={dateFormatForLesson} needConfirm={false} />)}
              </MyFormItem>
            </Col>
            <Col lg={24}>
              <MyFormItem label={strings.DESCRIPTION}>
                {getFieldDecorator('description', {
                  rules: [{ required: true, message: strings.DESCRIPTION_IS_REQUIRED }]
                })(
                  <MyJoditEditor
                    key="description-editor"
                    height={200}
                    onChange={value => setFieldsValue({ description: value })}
                  />
                )}
              </MyFormItem>
            </Col>
            {selectedStatus === CONTACT_LATER_STATUS_ID && (
              <Col span={24}>
                <MyFormItem label={strings.LEAD_PHONE_CALL_BY_ADMINISTRATOR}>
                  <TutorSelect
                    name="lead_admin"
                    initTutor={String(user?.meAdminId)}
                    getFieldDecorator={getFieldDecorator}
                    handleChange={contactAdminOnChange}
                  />
                </MyFormItem>
              </Col>
            )}
            {STATUS_IDS_WITH_WHEN_CONTACT_FIELD.includes(selectedStatus) && (
              <Col span={24}>
                <div>{strings.WHEN_CONTACT}</div>
                <Col span={12}>
                  <MyFormItem label={strings.DATE}>
                    {getFieldDecorator('contact_date', {
                      initialValue: dayjs(),
                      rules: [{ required: true, message: strings.DATE_IS_REQUIRED_FIELD }]
                    })(<DatePicker format={reversedDateFormatForBackend} />)}
                  </MyFormItem>
                </Col>
                <Col span={12}>
                  <MyFormItem label={strings.TIME}>
                    {getFieldDecorator('contact_time', {
                      initialValue: dayjs(),
                      rules: [{ required: true }]
                    })(<TimePicker format={dateFormatForLesson} needConfirm={false} />)}
                  </MyFormItem>
                </Col>
              </Col>
            )}
            <Col xs={24} lg={24}>
              <MyFormItem>
                {getFieldDecorator('visibleToTeacher', {
                  valuePropName: 'checked'
                })(<Checkbox>{strings.AVAILABLE_TO_TEACHER}</Checkbox>)}
              </MyFormItem>
            </Col>
          </Row>
        </Form>
      </StAddContact>
    </Modal>
  );
};

export const AddContact = Form.create<FilterHistoryProps>({})(AddContactTemplate);

export default { AddContact };
