export default class CacheService {
  private localStorage: Storage = window.localStorage;

  /**
   * @param {object} obj
   * @param {string} name
   * @return {void}
   */
  public setCache(obj: any, name: string): void {
    const value = JSON.stringify(obj);
    this.localStorage.setItem(name, value);
  }

  /**
   * @param {string} name
   * @return {string}
   */
  public getFromCache(name): any {
    if (!name) return;
    if (!localStorage.getItem(name)) return;
    return JSON.parse(localStorage.getItem(name));
  }

  /**
   *
   * @param {string} name
   * @return {void}
   */
  public deleteFromCache(name: string): void {
    this.localStorage.setItem(name, '');
  }
}
