import { httpService } from 'api/core';

/**
 * @description Get list learned from
 * @param {active} boolean | undefined
 * @return {Promise<any>}
 */
const learnedFrom = (active?: boolean | undefined): Promise<any> => httpService.get('/api/learned-from', { active });

/**
 * @description Learned from by ID
 * @param {number} learnedFromId
 * @return {Promise<any>}
 */
const learnedFromById = (learnedFromId: number | string): Promise<any> =>
  httpService.get(`/api/learned-from/${learnedFromId}`);

/**
 * @description Add learned from
 * @param {any} params
 * @return {Promise<any>}
 */
const addLearnedFrom = (params: any): Promise<any> => httpService.post('/api/learned-from', params);

/**
 * @description Edit learned from
 * @param {number} learnedFromId
 * @param {any} params
 * @return {Promise<any>}
 */
const editLearnedFrom = (learnedFromId: number | string, params: any): Promise<any> =>
  httpService.put(`/api/learned-from/${learnedFromId}`, params);

/**
 * @description Delete learned from
 * @param {number} learnedFromId
 * @return {Promise<any>}
 */
const deleteLearnedFrom = (learnedFromId: number | string): Promise<any> =>
  httpService.delete(`/api/learned-from/${learnedFromId}`);

export default { learnedFrom, learnedFromById, addLearnedFrom, editLearnedFrom, deleteLearnedFrom };
