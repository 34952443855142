import React, { useState, useEffect } from 'react';
import { StopOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select, Spin } from 'antd';
import { debounce } from 'lodash';
import { GetFieldDecoratorOptions } from '@ant-design/compatible/lib/form/Form';

import { Teacher } from 'types/Teachers/teacher';
import styled from 'styled-components';
import { useTeacherRepository } from 'repos/TeacherRepository';
import { useLanguageContext } from 'contexts/LanguageContext';

const StSelect = styled.div`
  .ant-select-selection--multiple .ant-select-selection__clear,
  .ant-select-selection--multiple .ant-select-arrow {
    top: 20px;
  }

  input {
    margin: 0;
  }
`;

const { Option } = Select;

interface IBaseTeacher {
  id: string | number;
  name: string;
  surname: string;
}

interface TeacherSelectProps {
  getFieldDecorator?<T extends Object = {}>(
    id: keyof T,
    options?: GetFieldDecoratorOptions
  ): (node: React.ReactNode) => React.ReactNode;
  mode?: 'multiple' | 'tags';
  placeholder?: string;
  name: string;
  label?: string;
  message?: string;
  searchParams?: object;
  required?: boolean;
  disabled?: boolean;
  maxTagCount?: number;
  initTeachersIds?: string[];
  initTeacherId?: string | number;
  initTeacherValue?: IBaseTeacher;
}

/**
 * @description Компонент поиска по учителям
 * @param {object} props
 * @return {React.ReactNode}
 */
export const TeacherSelect = (props: TeacherSelectProps) => {
  const {
    placeholder,
    getFieldDecorator,
    mode,
    name,
    label,
    message,
    initTeacherId,
    searchParams,
    required,
    disabled,
    maxTagCount,
    initTeachersIds,
    initTeacherValue
  } = props;

  const [strings] = useLanguageContext();
  const teacherRepository = useTeacherRepository();
  const [hasLoadTeachers, setHasLoadTeachers] = useState<boolean>(false);
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [baseTeacher, setBaseTeacher] = useState<IBaseTeacher>(undefined);
  const [baseTeachers, setBaseTeachers] = useState<Teacher[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [typing, setTyping] = useState<Boolean>(false);

  /**
   * @description Поиск по учителям
   * @param {string} teacher
   * @return {Promise<void>}
   */
  const fetchTeachers = async (query: string): Promise<any> => {
    setSearchValue(query);
    if (query) {
      setHasLoadTeachers(true);
      try {
        const {
          data: { items }
        } = await teacherRepository.getTeachers({ query, ...searchParams });
        setTeachers(
          items.filter(
            (teacher: Teacher) =>
              teacher?.user?.name !== undefined &&
              teacher?.user?.surname !== undefined &&
              teacher.id !== Number(initTeacherId)
          )
        );
      } catch {
      } finally {
        setHasLoadTeachers(false);
      }
    }
  };

  /**
   * @description Получение учителя по id
   * @param {number} teacherId
   * @return {Promise<any>}
   */
  const getTeacherById = async (teacherId: string | number): Promise<any> => {
    if (!teacherId || mode === 'multiple') return;
    try {
      const {
        data: {
          user: { name, surname }
        }
      } = await teacherRepository.getTeacherById(teacherId);
      setBaseTeacher({ id: teacherId, name: name, surname: surname });
    } catch {}
  };

  /**
   * @description Получение списка базовых учителей, ЕСЛИ select multiple
   * @return {Promise<any>}
   */
  const getTeachersByIds = async (): Promise<any> => {
    if (!(initTeachersIds && mode === 'multiple')) return;
    const teachers = [];
    for (const teacherId of initTeachersIds) {
      try {
        const { data } = await teacherRepository.getTeacherById(teacherId);
        teachers.push(data);
      } catch {}
    }
    try {
      const results = await Promise.all(teachers);
      setBaseTeachers(
        results.map((teacher: Teacher) => ({
          id: teacher.id,
          name: teacher?.user?.name,
          surname: teacher?.user?.surname
        }))
      );
    } catch {}
  };

  /**
   * @description Формирование Node для Select
   * @return {React.ReactNode | React.ReactNode[]}
   */
  const getInitTeacherNode = (): React.ReactNode | React.ReactNode[] => {
    if (baseTeachers && mode === 'multiple') {
      return baseTeachers.map((teacher: Teacher) => (
        <Option value={String(teacher.id)} key={teacher.id}>
          {teacher.name} {teacher?.surname}
        </Option>
      ));
    }

    if (!baseTeacher) return;
    return (
      <Option value={String(baseTeacher.id)} key={baseTeacher.id}>
        {baseTeacher.name} {baseTeacher.surname}
      </Option>
    );
  };

  const options = teachers.map((teacher: Teacher) => {
    return (
      <Option value={String(teacher.id)} key={teacher.id}>
        {teacher?.user?.name} {teacher?.user?.surname}{' '}
        {teacher?.isBusy && (
          <>
            <StopOutlined style={{ color: '#f5222d' }} /> (busy)
          </>
        )}
      </Option>
    );
  });

  useEffect(() => {
    if (searchValue) {
      setTyping(true);
    } else {
      setTyping(false);
    }
  }, [searchValue]);

  const select = (
    <Select
      allowClear={!required}
      showSearch
      mode={mode}
      placeholder={(typing && strings.START_TYPING) || placeholder || strings.TEACHER}
      defaultActiveFirstOption={false}
      filterOption={false}
      onSearch={debounce(fetchTeachers, 800)}
      style={{ width: '100%' }}
      notFoundContent={
        hasLoadTeachers ? <Spin size="small" /> : <p>{!typing ? strings.START_TYPING : strings.NOT_FOUND}</p>
      }
      maxTagCount={maxTagCount || 1}
      disabled={disabled}
      loading={hasLoadTeachers}
    >
      {[getInitTeacherNode(), ...options]}
    </Select>
  );

  useEffect(() => {
    if (initTeacherId) {
      getTeacherById(initTeacherId);
    }
    if (initTeachersIds) {
      getTeachersByIds();
    }
    if (initTeacherValue) {
      setBaseTeacher(initTeacherValue);
    }
    if (!initTeacherValue && !initTeacherId && !initTeachersIds) {
      setBaseTeacher(undefined);
    }
  }, [initTeacherId, initTeachersIds, initTeacherValue]); // eslint-disable-line

  if (label) {
    return (
      <StSelect>
        <MyFormItem label={label}>
          {getFieldDecorator(name, {
            initialValue: baseTeacher && String(baseTeacher.id),
            rules: [{ required: required, message: message ? message : 'Select teacher' }]
          })(select)}
        </MyFormItem>
      </StSelect>
    );
  }

  return (
    <StSelect>
      {getFieldDecorator(name, {
        initialValue: baseTeacher && String(baseTeacher.id),
        rules: [{ required: required, message: message ? message : 'Select teacher' }]
      })(select)}
    </StSelect>
  );
};

export default { TeacherSelect };
