import React, { useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Modal } from 'antd';
import { useUserContext } from 'contexts/UserContext';
import { createTextLinks } from 'helpers';
import { Chameleon } from 'Global/components/Chameleon';
import {
  MessageContaner,
  MessageDateTime,
  MessageItem,
  MessagesContainer,
  MessageText,
  MessageTitle
} from 'Global/components/Chat/styles';
import { Avatar } from 'Global/components/Avatar';
import { useThemeContext } from 'contexts/ThemeContext';
import { dateTimeWithoutSecondFormat } from 'helpers/dates';
import { useLanguageContext } from 'contexts/LanguageContext';
import { MessageAttachments } from './MessageAttachments';

const StyledChameleon = styled.div`
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImageInModal = styled.img`
  width: 100%;
  object-fit: cover;
`;

const AttachmentModal = ({ hasShow, setHasShow, previewAttachment }) => (
  <Modal open={hasShow} onCancel={() => setHasShow(false)} footer={[]}>
    <StyledImageInModal src={previewAttachment} />
  </Modal>
);

export function ChatMessages({ messages, authorId }) {
  const [user] = useUserContext();
  const [, lang] = useLanguageContext();
  const { activeTheme } = useThemeContext();
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const [previewAttachment, setPreviewAttachment] = useState<string>(null);

  return (
    <>
      {previewAttachment && (
        <AttachmentModal previewAttachment={previewAttachment} hasShow={hasShowModal} setHasShow={setHasShowModal} />
      )}
      <MessagesContainer>
        {messages?.map(({ author_id, created_at, html_body, attachments }) => {
          const hasAuthor = author_id === authorId;
          const date = dayjs(created_at).locale(lang).format(dateTimeWithoutSecondFormat);

          return (
            <MessageContaner reverse={hasAuthor}>
              {hasAuthor ? (
                <Avatar
                  photo={user?.mainPhoto}
                  size={40}
                  isClickDisabled
                  userName={user?.name}
                  userSurname={user?.surname}
                />
              ) : (
                <StyledChameleon>
                  <Chameleon />
                </StyledChameleon>
              )}
              <MessageItem>
                <MessageTitle>
                  <strong style={{ color: activeTheme.primaryColor }}>{hasAuthor ? 'You' : 'Support'}</strong>
                  <MessageDateTime>{date}</MessageDateTime>
                </MessageTitle>
                <MessageText>
                  <p dangerouslySetInnerHTML={{ __html: createTextLinks(html_body.split('---')[0], false) }} />
                  {attachments && (
                    <MessageAttachments
                      attachments={attachments}
                      setHasShowModal={setHasShowModal}
                      setPreviewAttachment={setPreviewAttachment}
                    />
                  )}
                </MessageText>
              </MessageItem>
            </MessageContaner>
          );
        })}
      </MessagesContainer>
    </>
  );
}
