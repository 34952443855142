import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Input, Typography, Button, message, Flex, Form } from 'antd';
import { StForm } from 'Global/modules/Auth/styles';
import { Auth as AuthAPI } from 'api/Auth';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { BASE_AUTH } from './routes';
import { passwordRules } from './Login';

const { Title, Paragraph } = Typography;

const Forgot = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const [form] = Form.useForm();
  const forgotTitle = 'Restore password';
  const [restoreStep, setRestoreStep] = useState<number>(1);

  /**
   * @description Проверить наличие и валидность токена в URL
   * @returns {Promise<any>}
   */
  const checkTokenParam = async () => {
    if (search) {
      const params = new URLSearchParams(search);
      const token = params.get('token');
      if (token) {
        const {
          data: { status }
        } = await AuthAPI.checkToken(token);
        if (!status) {
          return message.error('Token is not valid!');
        }

        setRestoreStep(3);
      }
    }
  };
  useEffect(() => {
    checkTokenParam();
  }, []);

  /**
   * @description Обработчик формы восстановления пароля
   * @returns {Promise<void>}
   */
  const restoreStepMiddleware = async (values): Promise<any> => {
    const { email, password, passwordAgain } = values;

    try {
      if (restoreStep === 1) {
        await AuthAPI.sendToken(email);
      }
      if (restoreStep === 3) {
        const params = new URLSearchParams(search);
        const token = params.get('token');
        await AuthAPI.restorePassword({
          token,
          password,
          passwordAgain,
          login: email
        });
        push(BASE_AUTH);
      }

      if (restoreStep !== 3) {
        setRestoreStep(restoreStep + 1);
      }
    } catch (e) {
      message.error(e.message);
    }
  };

  const onCancel = () => {
    form.resetFields();
    push(BASE_AUTH);
  };
  return (
    <div>
      <StForm style={{ width: 450 }}>
        <Form layout="vertical" form={form} onFinish={restoreStepMiddleware}>
          <Title level={3}>{forgotTitle}</Title>
          {restoreStep === 1 && (
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: 'Please input your email!' },
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!'
                }
              ]}
            >
              <Input name="email" placeholder="Email" />
            </Form.Item>
          )}
          {restoreStep === 2 && (
            <>
              <Paragraph>Instructing email has been successfully sent to your email address</Paragraph>
            </>
          )}
          {restoreStep === 3 && (
            <>
              <Form.Item label="New password" name="password" rules={passwordRules}>
                <Input size="large" type="password" minLength={6} name="password" placeholder="New password" />
              </Form.Item>
              <Form.Item
                name="passwordAgain"
                label="Password confirmation"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The new password that you entered do not match!'));
                    }
                  })
                ]}
              >
                <Input
                  type="password"
                  minLength={6}
                  size="large"
                  name="passwordAgain"
                  placeholder="Password confirmation"
                />
              </Form.Item>
            </>
          )}

          <Flex gap={10} justify="space-between">
            {restoreStep !== 2 && (
              <>
                <Button size="large" onClick={onCancel} disabled={restoreStep === 2} icon={<ArrowLeftOutlined />}>
                  Cancel
                </Button>
                <Button size="large" type="primary" htmlType="submit" disabled={restoreStep === 2}>
                  Continue
                </Button>
              </>
            )}
          </Flex>
        </Form>
      </StForm>
    </div>
  );
};

export default Forgot;
