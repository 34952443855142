import React, { useState, useEffect } from 'react';
import Pagination from 'Global/components/Pagination';
import { Table, message, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { Loader } from 'Global/components/Loader/Loader';
import { MaterialsFilter } from 'Global/modules/Materials/Filter';
import { getColumns } from 'Global/modules/Materials/columns';
import { Global } from 'api/Global';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { scrollTop } from 'helpers/scroll';
import { PaginationInfo } from 'types/global';
import { Material, Branch } from 'types';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import queryString from 'query-string';
import { momentFormatValues } from 'helpers/momentFormatValues';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { Container, StPageHeader } from 'Global/GlobalStyle';

/**
 * @description Материалы учителя
 */
export const Materials = () => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const { push, goBack } = useHistory();
  const hasTopManager = user?.hasRoles?.topManager;
  const [loading, setLoading] = useState<boolean>(true);
  const [materials, setMaterials] = useState<Material[]>([]);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>();
  const [branches, setBranches] = useState<Branch[]>([]);
  const { current, pageCount } = paginationInfo || {};
  const [currentParams, setCurrentParams] = useState(null);
  const { locationSearchParams } = useLocationSearchParams();

  //TODO заменить any
  const fetchMaterials = async (values?: any): Promise<any> => {
    setCurrentParams(values);
    const params = momentFormatValues(values);
    params['limit'] = 50;
    setLoading(true);
    try {
      const { data } = await Global.getMaterials(params);
      setMaterials(data.items);
      setPaginationInfo(data.paginationInfo);
      push({ search: queryString.stringify(params) });
    } catch (e) {
      message.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = async (page: number) => {
    await fetchMaterials({ ...locationSearchParams, page });
    scrollTop();
  };

  const onFilter = async (params: object): Promise<any> => {
    await fetchMaterials(params);
  };

  const fetchBranches = async (): Promise<void> => {
    const { data } = await Global.getBranches({ all: true });
    setBranches(data);
  };

  useEffect(() => {
    (async () => {
      const { branch } = locationSearchParams;
      if (!branch) {
        locationSearchParams['branch'] = user.branch.id;
      }
      await fetchMaterials(locationSearchParams);
      await fetchBranches();
    })();
  }, [user]);

  const columns = getColumns(hasTopManager, strings);

  const onTableChange = async (pagination, filters, sorter) => {
    const { order, field } = sorter;
    const isAscending = order === 'ascend';
    const isDescending = order === 'descend';

    switch (field) {
      case 'uploadedWhen':
        if (isAscending) {
          await fetchMaterials({ ...currentParams, direction: 'ASC' });
        }

        if (isDescending) {
          await fetchMaterials({ ...currentParams, direction: 'DESC' });
        }
        break;
      default:
    }
  };

  return (
    <ErrorBoundary>
      <Container>
        <StPageHeader onBack={goBack} title={strings.MATERIALS_DATABASE}>
          <MaterialsFilter onFilter={onFilter} branches={branches} />
        </StPageHeader>
        <Loader spinning={loading}>
          <Row gutter={[10, 10]}>
            <Table
              rowKey="id"
              onChange={onTableChange}
              dataSource={materials}
              bordered={false}
              pagination={false}
              columns={columns}
            />
            <Pagination count={pageCount} current={current} pageChange={handleChangePage} />
          </Row>
        </Loader>
      </Container>
    </ErrorBoundary>
  );
};

export default { Materials };
