import React from 'react';
import { ITeacherTraining } from 'types/education';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Switch, Typography } from 'antd';
import { Link } from 'react-router-dom';
import * as educationRoutes from 'Admin/Education/routes';
const { Text } = Typography;

export const getColumns = (editTeacherTrainingMark: (training: ITeacherTraining, value: boolean) => void) => [
  {
    title: 'Name',
    key: 'name',
    render: (training: ITeacherTraining) => {
      const {
        training: { name, id }
      } = training;
      return <Link to={`${educationRoutes.BASE_TRAININGS}/${id}`}>{name}</Link>;
    }
  },
  {
    title: 'Description',
    key: 'description',
    dataIndex: 'training',
    render: training => {
      return training?.description;
    }
  },
  {
    title: () => (
      <div>
        Essential/
        <br />
        Optional
      </div>
    ),
    key: 'essential',
    dataIndex: 'essential',
    width: 100,
    render: (essential: boolean) => {
      return <Text>{essential ? 'Essential' : 'Optional'}</Text>;
    }
  },
  {
    title: () => (
      <div>
        Finished/
        <br />
        Not finished
      </div>
    ),
    dataIndex: 'done',
    key: 'done',
    width: 120,
    render: (done: boolean, training: ITeacherTraining) => {
      return (
        <>
          <Text>{done ? 'Finished' : 'Not finished'}</Text>
          <br />
          <Switch
            checked={done}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onClick={checked => {
              editTeacherTrainingMark(training, checked);
            }}
          />
        </>
      );
    }
  }
];

export default { getColumns };
