import React from 'react';
import { EditOutlined, LoginOutlined } from '@ant-design/icons';
import { Table, Tooltip, Button, Row, Modal } from 'antd';
import { columns } from './columns';
import { Admin } from 'types';
import { TablePaginationConfig } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { BASE_ADMINS } from 'Admin/People/Administrators/routes';
import { switchUserModal } from 'helpers/switchUser';
import { useLanguageContext } from 'contexts/LanguageContext';
import { getCallPhoneButton } from 'helpers';
import { BtnCreateAccount } from 'Global/components/BtnCreateAccount';

const { Column } = Table;

interface AdminsTableProps {
  admins: Admin[];
  size?: 'default' | 'middle' | 'small';
  pagination?: TablePaginationConfig | false;
  isBorder?: boolean;
  handleUserRefetch: () => void;
}

export const AdminsTable = (props: AdminsTableProps) => {
  const { admins, pagination, handleUserRefetch } = props;
  const [strings] = useLanguageContext();
  const [{ confirm }, contextHolder] = Modal.useModal();

  const optionsCol = {
    title: '',
    dataIndex: '',
    key: 'buttons',
    width: 200,
    render: (admin: Admin) => {
      const { id, loginAs } = admin;
      const adminName = admin?.user?.name;
      const adminSurname = admin?.user?.surname;
      const phone = admin?.user?.phone;
      const email = admin?.user?.email;
      const login = admin?.user?.login;

      return (
        <Row align="middle" style={{ gap: 8, flexWrap: 'nowrap' }}>
          {contextHolder}
          <Link to={`${BASE_ADMINS}/${id}/edit/`}>
            <Button icon={<EditOutlined />} />
          </Link>
          {loginAs && (
            <Tooltip placement="top" title={`${strings.LOGIN_AS} ${adminName} ${adminSurname}`}>
              <Button icon={<LoginOutlined />} onClick={() => switchUserModal(loginAs, confirm)} />
            </Tooltip>
          )}
          <BtnCreateAccount
            id={id}
            userId={admin?.user?.id}
            email={email}
            loginAs={loginAs}
            login={login}
            type="admin"
            handleUserRefetch={handleUserRefetch}
          />
          {getCallPhoneButton(phone)}
        </Row>
      );
    }
  };

  return (
    <Table dataSource={admins} pagination={pagination} rowKey="id" bordered={false}>
      {[...columns(strings), optionsCol]
        .filter(col => col)
        .map(col => (
          <Column {...col} />
        ))}
    </Table>
  );
};

export default { AdminsTable };
