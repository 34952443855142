import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { isString, isEmpty } from 'lodash';

const { SubMenu } = Menu;

interface IMenu {
  key: number;
  title: string | React.ReactElement;
  subMenu?: [];
  link?: string;
  icon?: string;
}

/**
 * @description Функция для формирования меню и подменю.
 * @param {array<Object>} item
 * @return {React.ReactNode}
 */
export const menu = (list: IMenu[]) => {
  // Удаляем пустые пункты меню
  return list.filter(Boolean).map((item: IMenu) => {
    if (item.hasOwnProperty('subMenu')) {
      if (!isEmpty(item.subMenu)) {
        return (
          <SubMenu key={item.key} title={item.title}>
            {menu(item.subMenu)}
          </SubMenu>
        );
      }
      return null;
    }

    // Если item.title - это компонент
    if (!isString(item.title)) {
      return <Menu.Item key={item.key}>{item.title}</Menu.Item>;
    }

    return (
      <Menu.Item key={item.key}>
        {item.link && item.link.includes('https://') ? (
          <a href={item.link} rel="noreferrer noopener" target="_blank">
            {item.title}
          </a>
        ) : (
          <Link to={item.link || ''}>{item.title}</Link>
        )}
      </Menu.Item>
    );
  });
};

export default { menu };
