import React, { useState } from 'react';
import { Typography, Select, Button, message, Table } from 'antd';
import { Inventory } from 'types';
import { useLanguageContext } from 'contexts/LanguageContext';

interface InformationProps {
  listInventories: Inventory[];
  inventories: Inventory[];
  hasLoadingInventories: boolean;
}

const { Title } = Typography;
const { Option } = Select;

/**
 * @description Вкладка Information
 * @param {InformationProps} props
 * @return {React.ReactNode}
 */
export const Information = (props: InformationProps) => {
  const [strings] = useLanguageContext();
  const { listInventories, inventories, hasLoadingInventories } = props;
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const handleSubmit = () => {
    setDisabledButton(true);
    message.error(strings.THE_FUNCTIONALITY_IS_TEMPORARILY_UNAVAILABLE);
  };

  const columns = [
    {
      title: strings.INVENTORY,
      dataIndex: 'name',
      key: 'name'
    }
  ];

  return (
    <>
      <div>
        <Title level={4}>{strings.BOOKS_FOR_STUDENTS}:</Title>
        <Select
          size="large"
          placeholder={strings.SELECT_A_BOOK}
          loading={hasLoadingInventories}
          style={{ maxWidth: 350 }}
        >
          {listInventories.map((inventory: Inventory) => (
            <Option key={inventory.id} value={inventory.id}>
              {inventory.name}
            </Option>
          ))}
        </Select>
        <Button onClick={handleSubmit} size="large" disabled={disabledButton} style={{ marginInlineStart: 8 }}>
          {strings.ADD}
        </Button>
      </div>
      <Table
        dataSource={inventories}
        rowKey="id"
        columns={columns}
        bordered={false}
        pagination={false}
        style={{ marginTop: 20 }}
      />
    </>
  );
};

export default { Information };
