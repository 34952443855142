import React from 'react';
import { Link } from 'react-router-dom';
import { DownOutlined, LogoutOutlined, MessageTwoTone } from '@ant-design/icons';
import { Row, Col, Dropdown, Badge, Popover } from 'antd';
import { Avatar } from 'Global/components/Avatar';
import {
  HeaderUser,
  HeaderUserResponsive,
  HeaderContainer,
  ColLeftStyles,
  headerDesktopIconSt,
  headerMobileIconSt
} from 'Global/components/Header/styles';
import { Search } from 'Global/components/Header/Search';
import { UserMenu } from 'Global/components/Header/UserMenu';
import { Languages } from 'Global/components/Header/HeaderLanguages';
import { useUserContext } from 'contexts/UserContext';
import { useThreadsContext } from 'contexts/ThreadsContext';
import { Logo } from 'Global/components/Logo';
import { useLanguageContext } from 'contexts/LanguageContext';
import { IW_KEY } from 'Admin/Translations';

interface DesktopHeaderProps extends React.ComponentProps<any> {
  handleClickContent: (boolean) => void;
  setHasShowTutor: (hasShowTutor: boolean) => void;
  popoverContent: React.ReactElement;
  setHasShowDropdown: (hasShowDropdown: boolean) => void;
  confirmLogout: () => void;
}

const AvatarName = ({ user }) => {
  const [, lang] = useLanguageContext();
  const { name, surname, nameHebrew, surnameHebrew } = user;

  return (
    <>
      <Avatar
        isHeaderAvatar={true}
        photo={user?.mainPhoto}
        size={40}
        isClickDisabled
        userName={user?.name}
        userSurname={user?.surname}
      />
      <span>
        {lang === IW_KEY && (nameHebrew || surnameHebrew) ? `${nameHebrew} ${surnameHebrew}` : `${name} ${surname}`}
      </span>
    </>
  );
};

/**
 * @description Десктоп версия шапки
 * @param {object} props
 * @return {React.ReactNode}
 */
export const HeaderDesktop = (props: DesktopHeaderProps) => {
  const { confirmLogout, handleClickContent, popoverContent, setHasShowTutor } = props;
  const [user] = useUserContext();
  const [unreadThreads] = useThreadsContext();

  return (
    <HeaderContainer>
      {props.children}
      <Row align="middle" style={{ height: 70 }}>
        <Col lg={6} md={6} style={ColLeftStyles}>
          <Link to="/" style={{ height: 70, display: 'flex', alignItems: 'center' }}>
            <Logo />
          </Link>
          <HeaderUserResponsive>
            <Avatar
              isHeaderAvatar={true}
              photo={user.mainPhoto}
              size={30}
              userName={user?.name}
              userSurname={user?.surname}
            />
            <Dropdown overlay={<UserMenu />}>
              <span className="ant-dropdown-link">
                {user?.name} {user?.surname} <DownOutlined />
              </span>
            </Dropdown>
          </HeaderUserResponsive>
        </Col>
        <Col lg={18} md={18}>
          <Row justify="end" style={{ gap: 20 }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              {user?.hasRoles?.admin && <Search />}
              {(user?.hasRoles?.teacher || user?.hasRoles?.parent) && (
                <Popover content={popoverContent} placement="bottom">
                  <Badge
                    count={unreadThreads + Number(user?.rejectedCommentsCount || 0)}
                    style={{
                      marginTop: '.2rem'
                    }}
                  >
                    <MessageTwoTone style={headerDesktopIconSt} twoToneColor="#ccc" className="message-icon" />
                  </Badge>
                </Popover>
              )}
              {user?.hasRoles?.student && (
                <MessageTwoTone
                  onClick={() => handleClickContent(setHasShowTutor)}
                  className="message-icon"
                  style={headerDesktopIconSt}
                  twoToneColor="#ccc"
                />
              )}
            </div>
            <Languages />
            <HeaderUser>
              {user?.myConnectedAccounts?.isNotEmpty() || user?.hasRoles?.parent ? (
                <Dropdown overlay={<UserMenu />} trigger={['hover']} overlayClassName="dropdown-header">
                  <div
                    onClick={e => e.preventDefault()}
                    style={{ display: 'flex', gap: 10, alignItems: 'center', cursor: 'pointer' }}
                  >
                    <AvatarName user={user} />
                  </div>
                </Dropdown>
              ) : (
                <AvatarName user={user} />
              )}
              <LogoutOutlined
                data-user-id={user?.id}
                onClick={confirmLogout}
                style={{ ...headerMobileIconSt, opacity: 0.7 }}
              />
            </HeaderUser>
          </Row>
        </Col>
      </Row>
    </HeaderContainer>
  );
};

export default { HeaderDesktop };
