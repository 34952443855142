import styled, { css } from 'styled-components';

const Courses = styled.div`
  padding: 20px;

  .ant-col {
    padding: 0 6px;
  }
  .ant-select {
    width: 100%;
  }

  .no-padding {
    & .ant-col {
      padding: 0;
    }
  }
`;

export const LessonContainer = styled(Courses)`
  box-shadow: 0px 0px 46px -15px rgba(0, 0, 0, 0.12);

  & .ant-col {
    padding: 0;
  }

  & .ant-descriptions-view td.discription-item-flex {
    display: flex;
  }
`;

export const GroupCourse = styled(Courses)`
  margin: 20px;
  padding: 30px 20px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 46px -15px rgb(0 0 0 / 12%);
  .ant-col {
    padding: 0 6px;
  }
  .ant-select {
    width: 100%;
  }
  .ant-table-row {
    cursor: pointer;
  }
`;

export const FilterGroupCourse = styled.div`
  margin: 30px 0;
`;

export const FilterOriginalCourses = styled.div`
  margin: 35px 0;
`;

export const Group = styled(Courses)`
  .ant-select {
    width: 100%;
  }
  .ant-btn {
    height: 40px;
  }
`;

export const OriginalCourses = styled(Courses)`
  margin: 20px;
  padding: 30px 20px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 46px -15px rgb(0 0 0 / 12%);

  & .ant-card .ant-card-head-title {
    padding: 0;
  }

  & .ant-card .card-custom-title {
    padding: 16px 0;
    cursor: pointer;
    user-select: none;
  }

  & .ant-card .card-custom-title i {
    margin-inline-start: 6px;
    font-size: 18px;
  }

  & .ant-card .ant-card-body {
    padding: 0;
  }

  & .ant-card.is-active .ant-card-body {
    padding: 24px;
  }
`;

export const AddOriginalCourse = styled(Courses)``;
export const AddLessonPlan = styled(Courses)``;
export const AddCourseGroup = styled(Courses)``;

export const SuperGroups = styled(Courses)`
  margin: 20px;
  padding: 30px 20px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 46px -15px rgb(0 0 0 / 12%);
`;
export const Placement = styled(Courses)`
  margin: 20px;
  padding: 30px 20px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 46px -15px rgb(0 0 0 / 12%);

  li {
    padding-inline-end: 15px;
  }

  @media (max-width: 991px) {
    margin: 10px;
    padding: 20px;

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      display: flex;
      flex-wrap: wrap;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      top: 23px;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
      flex: 0 0 100%;
    }
  }

  @media (max-width: 561px) {
    margin: 0;
  }
`;

export const SuperGroup = styled.div`
  margin: 25px 0;
`;

export const Units = styled(Courses)``;
export const AddLesson = styled(Courses)`
  textarea {
    margin: 10px 0;
  }
`;

export const Smiles = styled.div`
  width: 200px;
  text-align: center;

  & .ant-radio-group {
    display: flex;
  }

  & .ant-radio-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .ant-radio {
    margin-bottom: 4px;
  }

  & img {
    width: 44px;
    height: 44px;
  }
`;

export const FormItemContainer = styled.div`
  margin-bottom: 20px;

  & .ant-legacy-form-item {
    margin-bottom: 0;
  }

  & .ant-upload.ant-upload-disabled {
    color: #aaa;
  }
`;

export const LessonItem = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #ddd;
  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
  .ant-collapse-item > .ant-collapse-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const LessonTitle = styled.div`
  font-weight: 700;
  flex: 0 0 160px;
  padding-inline-end: 10px;
  padding-bottom: 16px;
  box-sizing: border-box;
`;

export const LessonText = styled.div`
  color: #444444;
  flex: 1;
  min-width: 200px;
  word-wrap: anywhere;
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const AvatarContainerLeft = styled.div`
  flex: 0 0 52px;
  margin-inline-end: 5px;
`;

export const AvatarContent = styled.div`
  flex-direction: column;
  word-break: normal;
`;

export const CommentField = styled.div`
  min-width: 120px;
  position: relative;

  & textarea {
    resize: inherit;
    min-height: 70px;
    min-width: 200px;
  }
`;

export const FillItContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const FilterContainer = styled.div`
  .ant-col {
    padding: 0;
  }
`;

export const GroupHeading = styled.div`
  display: flex;

  & .ant-typography {
    margin-bottom: 0;
  }

  & .ant-btn {
    height: auto;
    margin-inline-start: 8px;
    padding: 4px 12px;
  }
`;

export const OldHw = styled.div`
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  input {
    width: 50%;
    margin: 0 10px 0 0;
  }
`;

export const HWAddon = styled.div<{ disabled?: boolean }>`
  background: #fafafa;
  height: 38px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 11px;
  transition: background 0.3s ease-in-out;

  &:hover {
    background: #eaeaea;
  }

  ${props =>
    props.disabled &&
    css`
      background: #f5f5f5;
      cursor: not-allowed;

      &:hover {
        background: #f5f5f5;
      }
    `}
`;

export const Rainbow = styled.div`
  background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
  background-size: 800% 800%;
  color: white;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 4px;
  white-space: nowrap;

  & svg {
    margin-inline-start: 6px;
    fill: white;
    position: relative;
    top: 1px;
  }

  -webkit-animation: rainbow 18s ease infinite;
  -z-animation: rainbow 18s ease infinite;
  -o-animation: rainbow 18s ease infinite;
  animation: rainbow 18s ease infinite;}

@-webkit-keyframes rainbow {
  0%{background-position:0% 82%}
  50%{background-position:100% 19%}
  100%{background-position:0% 82%}
}
@-moz-keyframes rainbow {
  0%{background-position:0% 82%}
  50%{background-position:100% 19%}
  100%{background-position:0% 82%}
}
@-o-keyframes rainbow {
  0%{background-position:0% 82%}
  50%{background-position:100% 19%}
  100%{background-position:0% 82%}
}
@keyframes rainbow {
  0%{background-position:0% 82%}
  50%{background-position:100% 19%}
  100%{background-position:0% 82%}
`;

export const OldRadioContainer = styled.div<{ width?: number }>`
  width: 130px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .ant-radio-group-small .ant-radio-button-wrapper {
    height: 28px;
    padding: 0 10px;
    line-height: 26px;
  }
`;

export const RadioContainer = styled.div<{ width?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;

  .ant-radio-group-small .ant-radio-button-wrapper {
    height: 32px;
    padding: 0 10px;
    line-height: 30px;
  }
`;

export const SupergroupCounter = styled.div`
  display: flex;
  font-size: 13px;
  color: #777;
`;

export const SupergroupCount = styled.div`
  margin-inline-start: 8px;

  &:first-child {
    margin-inline-start: 0;
  }

  & mark {
    color: white;
    background: #35383a;
    font-size: 11px;
    border-radius: 20px;
    display: inline-block;
    padding: 0 10px;
  }
`;

export default {
  GroupCourse,
  FilterGroupCourse,
  Group,
  OriginalCourses,
  FilterOriginalCourses,
  AddOriginalCourse,
  AddCourseGroup,
  AddLessonPlan,
  SuperGroups,
  SuperGroup,
  Units,
  AddLesson,
  LessonContainer,
  Smiles,
  FormItemContainer,
  LessonItem,
  LessonTitle,
  LessonText,
  AvatarContainer,
  AvatarContainerLeft,
  AvatarContent,
  CommentField,
  FilterContainer,
  GroupHeading,
  HWAddon,
  Rainbow,
  RadioContainer,
  FillItContainer,
  SupergroupCounter,
  SupergroupCount
};
