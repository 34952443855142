import React from 'react';
import { Tag } from 'antd';

export const getColumns = strings => [
  {
    title: strings.NAME,
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: strings.SORT,
    dataIndex: 'sort',
    key: 'sort'
  },
  {
    title: strings.COLOR,
    dataIndex: 'color',
    key: 'color',
    render: (color: string) => {
      const statusColor = color.match('#') ? color : '#' + color;
      return <Tag color={statusColor}>{statusColor}</Tag>;
    }
  },
  {
    title: strings.ACTUAL,
    dataIndex: 'active',
    key: 'active',
    render: (active: boolean) => (active ? 'Yes' : 'No')
  },
  {
    title: strings.UNBLOCK,
    dataIndex: 'unblock',
    key: 'unblock',
    render: (unblock: boolean) => (unblock ? 'Yes' : 'No')
  }
];

export default { getColumns };
