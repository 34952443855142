import styled, { css } from 'styled-components';
import { Table } from 'antd';

interface IFilterDate {
  m?: boolean;
}

export const StHeader = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: auto;
  }
`;

export const FilterDate = styled.span<IFilterDate>`
  font-size: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: inline-block;
  padding: 0 7px;
  height: 28px;
  line-height: 28px;
  position: relative;
  top: -1px;

  ${props =>
    props.m &&
    css`
      margin: 0 5px;
    `}
`;

const Monitoring = styled.div`
  margin: 20px;
  padding: 30px 20px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 46px -15px rgb(0 0 0 / 12%);
  .ant-col {
    padding: 0 6px;
  }
  .ant-select {
    width: 100%;
  }
  .ant-checkbox-wrapper {
    width: 100%;
    margin: 0;
    padding: 10px 0;
  }
`;

export const Teachers = styled(Monitoring)``;
export const Lessons = styled(Monitoring)`
  .ant-col.ant-form-legacy-item-control-wrapper {
    padding: 0;
  }
  .ant-checkbox-wrapper {
    width: auto;
    margin: 0;
    padding: 10px 0;
  }
`;

export const TeachersReports = styled(Monitoring)`
  .ant-table-row-expand-icon {
    display: none;
  }
`;
export const StAcademicProgress = styled.div`
  .no-padding {
    & .ant-col {
      padding: 0;
    }
  }
  .ant-legacy-form-item {
    margin-bottom: 0;
  }
  .ant-card-grid {
    width: 100%;
    box-shadow: none;
    padding: 12px 24px;
    word-wrap: break-word;
  }
  .ant-card-grid-hoverable:hover {
    background: rgba(24, 144, 255, 0.16);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .ant-card-head-title {
    padding-bottom: 0;
  }
  .ant-card-head-title a:hover {
    color: #40a9ff;
  }
`;
export const Grading = styled(Monitoring)``;
export const ProgressReport = styled(Monitoring)`
  .ant-table-wrapper {
    overflow: unset;
    overflow-x: auto;
    margin-top: 10px;
  }

  .row-unactive {
    background: #f5f5f5;
  }
`;
export const EditProgressReport = styled(Monitoring)``;
export const MarketingEvents = styled(Monitoring)``;
export const AddMarketingEvents = styled(Monitoring)`
  .ant-time-picker {
    width: 100%;
  }

  .ant-time-picker-input {
    height: 40px;
  }

  .ant-time-picker-icon,
  .ant-time-picker-clear {
    top: 40%;
  }
`;

export const MessagesFromParents = styled(Monitoring)`
  .ant-typography {
    margin-bottom: 0;
  }

  .ant-legacy-form-item {
    margin-bottom: 0;
  }

  .ant-table-wrapper {
    overflow-x: auto;
  }
`;

export const ShowThread = styled(Monitoring)``;
export const SummaryTable = styled(Monitoring)``;
export const ShowAssessment = styled(Monitoring)`
  .ant-statistic {
    margin: 10px 0;
    border: none;
  }

  .ant-collapse-extra {
    width: 20px;
  }

  .ant-form-legacy-item-label > label::after {
    display: none;
  }
`;
export const StyledInvoicesGenerator = styled.div`
  margin: 20px;
  border-radius: 5px;
  .ant-legacy-form-item {
    margin-bottom: 10px;
  }
`;

export const SummaryCalendar = styled(Monitoring)``;
export const Thread = styled(Monitoring)``;
export const ManualInput = styled(Monitoring)``;
export const InvoicesLogs = styled(Monitoring)``;
export const LessonsRegistersLogs = styled(Monitoring)``;
export const StudentsPayingReport = styled(Monitoring)``;
export const InvoiceMoscow = styled(Monitoring)`
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 10px 10px;
  }
`;
export const MonitoringFinance = styled(Monitoring)``;

export const ProductsContainer = styled.div<{ width?: number }>`
  display: flex;
  flex-wrap: wrap;
  width: ${props => (props.width ? props.width : 500)}px;
  margin: 0 -5px;
`;

export const Product = styled.div<{ width?: number }>`
  flex: 0 0 ${props => (props.width ? props.width : 20)}%;
  padding: 0 5px;
  margin-bottom: 10px;
`;

export const ProductTitle = styled.div`
  font-size: 13px;
  color: #666;
`;

export const ProductValue = styled.div`
  font-weight: 700;
`;
export const StatusFeedback = styled.span<{ type: string }>`
  ${props =>
    props.type === 'approved' &&
    css`
      background: #4caf50;
    `}

  ${props =>
    props.type === 'reject' &&
    css`
      background: red;
    `}

  ${props =>
    props.type === 'primary' &&
    css`
      background: #9e9e9e;
    `}
  color: white;
  font-size: 12px;
  padding: 2px 8px;
  display: inline-block;
  margin-inline-start: 4px;
`;

export const TableWithTopCells = styled(Table)`
  .ant-table-row,
  .ant-table-row-cell-break-word {
    vertical-align: top;
  }
`;

export default {
  Teachers,
  Lessons,
  StAcademicProgress,
  Grading,
  ProgressReport,
  EditProgressReport,
  MarketingEvents,
  AddMarketingEvents,
  MessagesFromParents,
  ShowThread,
  SummaryTable,
  ShowAssessment,
  SummaryCalendar,
  Thread,
  InvoiceMoscow,
  ManualInput,
  InvoicesLogs,
  LessonsRegistersLogs,
  StudentsPayingReport,
  FilterDate,
  ProductsContainer,
  Product,
  ProductTitle,
  ProductValue,
  StatusFeedback,
  TableWithTopCells,
  StHeader
};
