import React, { useState, useEffect, useMemo } from 'react';
import { StyledSeparator, StUploadedFieldsRow, StCol, StLabel, StForm } from 'Global/styles';
import { EditOutlined, ReloadOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons';
import { Row, Col, Select, Input, Button, Avatar, Upload, message, Tooltip, DatePicker, Form } from 'antd';
import { Subject, Tag as ITag } from 'types';
import { useUserContext } from 'contexts/UserContext';
import { AMI_5_LOCATION, beforeImageUpload, dateFormat, getAllFormData, getAvatarUrl, getBase64 } from 'helpers';
import { useTeacherRepository } from 'repos/TeacherRepository';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { match } from 'react-router';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useHistory } from 'react-router-dom';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import { booleanFormatValues } from 'helpers/booleanFomatValues';
import { formatDateValues } from 'helpers/dates';
import { TeacherCard } from './TeacherCard';
import { BASE_TEACHERS } from '../routes';
import { FEMALE, MALE } from 'Global/constants';
import { TutorSelect } from 'Global/components/FormComponents';
import { identity, isNull, isUndefined } from 'lodash';
import * as Sentry from '@sentry/browser';
import Breadcrumbs from 'Global/components/Breadcrumbs';
import { AttachmentsList } from 'Global/components/AttachmentsList';
import dayjs from 'dayjs';
import { BranchBruner } from 'types/branches';
import { ITeacherPostParams, Teacher } from 'types/Teachers/teacher';

const { Option } = Select;

export const TeacherForm = () => {
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const [form] = Form.useForm();
  const hasTopManager = user?.hasRoles?.topManager;
  const hasAccessIsInvoiceUnlimitedAccess = user?.isInvoiceUnlimitedAccess;
  const [defaultTeacher, setDefaultTeacher] = useState<Teacher>(null);
  const [selectedTutor, setSelectedTutor] = useState(null);
  const [selectedMentor, setSelectedMentor] = useState(null);
  const [selectedExperts, setSelectedExperts] = useState(null);
  const [contracts, setContracts] = useState<{ id: number; name: string }[]>([]);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<File>(null);
  const [audio, setAudio] = useState<File>(null);
  const [cv, setCV] = useState<any[]>(null);
  const [nda, setNDA] = useState<any[]>(null);
  const [hasLoadingCV, setHasLoadingCV] = useState<boolean>(false);
  const [hasLoadingNDA, setHasLoadingNDA] = useState<boolean>(false);
  const [previewAvatar, setPreviewAvatar] = useState<string>('');
  const [previewAudio, setPreviewAudio] = useState<string>('');
  const { teacherStatuses } = useGlobalCollectionsContext();

  const hasAccessCommentJulia = !defaultTeacher?.hideJuliaComment && defaultTeacher?.showAudio;

  const teacherRepository = useTeacherRepository();
  const globalRequestsRepository = useGlobalRequestsRepository();

  const {
    params: { id: teacherId }
  }: match<{ id: string }> = useRouteMatch();

  const hasEditTeacher = identity(teacherId);
  const { push } = useHistory();
  const location = useLocation();
  const { subjects, branches, teacherTags: teacherTagsList } = useGlobalCollectionsContext();

  const handleSubmit = async (values: ITeacherPostParams): Promise<any> => {
    setHasLoading(true);
    if (hasEditTeacher) {
      try {
        const params = booleanFormatValues(formatDateValues(values));
        // Изменим значения undefined на null, чтобы они не затерлись при put запросе
        for (let i in params) {
          if (isUndefined(params[i])) {
            params[i] = null;
          }
        }
        const { status } = await teacherRepository.editTeacher(Number(teacherId), params);
        if (status === 200) {
          //если изменили аватар
          if (avatar) {
            await uploadAvatar(teacherId);
          }
          if (audio) {
            await uploadFiles({ id: teacherId, file: audio, type: 'teacherAudio' });
          }

          message.success(strings.TEACHER_SUCCESSFULLY_EDITED, 2);
          setTimeout(() => push(`${BASE_TEACHERS}/${teacherId}`), 1500);
        }
      } catch (err) {
        message.error(strings.EDIT_TEACHER_ERROR_TRY_AGAIN_LATER, 2);
        // Отправим конкретную ошибку  в sentry
        Sentry.captureException(err);
        setHasLoading(false);
      }
    } else {
      try {
        const params = booleanFormatValues(formatDateValues(values));
        const {
          data: { id },
          status
        } = await teacherRepository.createTeacher(params);
        if (status === 200) {
          //если изменили аватар
          if (avatar) {
            uploadAvatar(id);
          }
          try {
            await Promise.all([
              cv.isNotEmpty() &&
                cv.forEach(item => {
                  uploadFiles({ id: id, file: item, type: 'teacherResume' });
                }),
              nda.isNotEmpty() &&
                nda.forEach(item => {
                  uploadFiles({ id: id, file: item, type: 'teacherNda' });
                })
            ]);
          } catch {}
          message.success(strings.THE_TEACHER_IS_CREATED_SUCCESSFULLY, 2);
          setTimeout(() => push(`${BASE_TEACHERS}/${id}`), 1500);
        }
      } catch (err) {
        message.error(strings.TEACHER_CREATE_ERROR, 2);
        // Отправим конкретную ошибку  в sentry
        Sentry.captureException(err);
      } finally {
        setHasLoading(false);
      }
    }
  };

  /**
   * @description Запрос на добавление аватарки
   * @param {number} id
   * @return {Promise<any>}
   */
  const uploadAvatar = async (id): Promise<any> => {
    try {
      await globalRequestsRepository.uploadAvatar(getAllFormData({ id: id, type: 'teacher', file: avatar }));
    } catch (e) {
      message.error(e.message);
    }
  };

  /**
   * @description Запрос на добавление файлов
   * @return { id: number; type: string; file: File } params
   * @return {Promise<any>}
   */
  const uploadFiles = async (params): Promise<any> => {
    try {
      await globalRequestsRepository.uploadAttach(getAllFormData(params));
      if (hasEditTeacher) {
        getTeacherAttachments();
      }
    } catch (e) {
      message.error(e.message);
    } finally {
      setHasLoadingCV(false);
      setHasLoadingNDA(false);
    }
  };

  const handleChangeAvatar = async (upload): Promise<any> => {
    const imageHash = await getBase64(upload.file);
    setPreviewAvatar(imageHash);
    setAvatar(upload.file);
  };

  /**
   * @description Обработчик на загрузку audio
   * @param {File} file
   * @return {Promise<any>}
   **/
  const handleAudioChange = async (upload): Promise<any> => {
    const fileHash = await getBase64(upload.file);
    setPreviewAudio(fileHash);
    setAudio(upload.file);
  };

  /**
   * @description Обработчик на загрузку cv
   * @param {File} file
   * @return {Promise<any>}
   **/
  const handleChangeCV = async (upload): Promise<any> => {
    if (hasEditTeacher) {
      setHasLoadingCV(true);
      uploadFiles({ id: teacherId, file: upload.file, type: 'teacherResume' });
    } else {
      //при создании юзера файлы загружаем, когда юзер создан и получен id
      setCV(upload.fileList.map(file => file.originFileObj));
    }
  };

  /**
   * @description Обработчик на загрузку nda
   * @param {File} file
   * @return {Promise<any>}
   **/
  const handleChangeNDA = async (upload): Promise<any> => {
    if (hasEditTeacher) {
      setHasLoadingNDA(true);
      uploadFiles({ id: teacherId, file: upload.file, type: 'teacherNda' });
    } else {
      //при создании юзера файлы загружаем, когда юзер создан и получен id
      setNDA(upload.fileList.map(file => file.originFileObj));
    }
  };

  /**
   * @description Получить контракты учителей
   * @return {Promise<any>}
   **/
  const getTeacherContracts = async (): Promise<any> => {
    try {
      const { data } = await teacherRepository.getTeacherContracts();
      setContracts(data);
    } catch {}
  };

  /**
   * @description Обновить attachments
   * @return {Promise<any>}
   **/
  const getTeacherAttachments = async (): Promise<any> => {
    try {
      const { data } = await teacherRepository.getTeacherById(teacherId);
      const { cv, nda } = data;
      setCV(cv);
      setNDA(nda);
    } catch {}
  };

  useEffect(() => {
    if (teacherId) {
      (async () => {
        try {
          const { data } = await teacherRepository.getTeacherById(teacherId);
          const { mainPhoto, tutor, mentor, experts, audio, cv, nda } = data;
          setDefaultTeacher(data);
          setPreviewAvatar(getAvatarUrl(mainPhoto));
          setCV(cv);
          setNDA(nda);
          if (tutor) {
            const initTutor = `${tutor?.user?.name} ${tutor?.user?.surname}`;
            setSelectedTutor(initTutor);
          }
          if (mentor) {
            const initMentor = `${mentor?.user?.name} ${mentor?.user?.surname}`;
            setSelectedMentor(initMentor);
          }
          if (experts) {
            const initExperts = experts.map(item => `${item?.user?.name} ${item?.user?.surname}`);
            setSelectedExperts(initExperts);
          }
          if (audio) {
            setPreviewAudio(`${AMI_5_LOCATION}${audio}`);
          }
        } catch {}
      })();
    }
  }, [teacherId]);

  useEffect(() => {
    if (hasAccessIsInvoiceUnlimitedAccess) {
      getTeacherContracts();
    }
  }, [user]);

  const filteredMainSubjects = form.getFieldValue('additionalSubjects')?.isNotEmpty()
    ? subjects.filter(item => !form.getFieldValue('additionalSubjects').includes(item.id))
    : subjects;

  const filteredAdditionalSubjects = form.getFieldValue('mainSubjects')?.isNotEmpty()
    ? subjects.filter(item => !form.getFieldValue('mainSubjects').includes(item.id))
    : subjects;

  const avatarField = (
    <Col lg={8} style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ position: 'relative', marginInlineStart: '-10px' }}>
        <Avatar
          src={previewAvatar}
          style={{ backgroundColor: '#e6e6e6', opacity: 0.8 }}
          size={100}
          icon={<UserOutlined />}
        />
        <Upload
          className="uploadAvatar"
          accept={'image/*'}
          multiple={false}
          showUploadList={false}
          onChange={handleChangeAvatar}
          beforeUpload={beforeImageUpload}
        >
          <Button shape="circle" icon={<EditOutlined />} style={{ boxShadow: '0 0 6px rgb(0 0 0 / 30%)' }} />
        </Upload>
      </div>
    </Col>
  );

  const initValues: ITeacherPostParams = useMemo(
    () => ({
      status: defaultTeacher?.status?.id,
      branch: defaultTeacher?.user?.branch?.id || user?.branch?.id,
      gender: defaultTeacher?.user?.gender,
      name: defaultTeacher?.user?.name,
      surname: defaultTeacher?.user?.surname,
      nameHebrew: defaultTeacher?.user?.nameHebrew,
      surnameHebrew: defaultTeacher?.user?.surnameHebrew,
      patronymic: defaultTeacher?.user?.patronymic,
      birthday: defaultTeacher?.user?.birthDate && dayjs(defaultTeacher?.user?.birthDate),
      phone: defaultTeacher?.user?.phone,
      email: defaultTeacher?.user?.email,
      mainSubjects: defaultTeacher?.mainSubjects?.map(subject => subject.id),
      additionalSubjects: defaultTeacher?.additionalSubjects?.map(subject => subject.id),
      teacherTags: defaultTeacher?.teacherTag?.map(tag => tag.id),
      experts: defaultTeacher?.experts?.map(item => item?.id),
      tutor: defaultTeacher?.tutor?.id,
      mentor: defaultTeacher?.mentor?.id,
      org1Id: defaultTeacher?.org1Id,
      prefix1c: defaultTeacher?.prefix1c,
      contract: defaultTeacher?.contract?.id
    }),
    [defaultTeacher]
  );

  useEffect(() => {
    form.setFieldsValue(initValues);
  }, [initValues]);

  const cardValues = Form.useWatch([], form);

  return (
    <Row style={{ margin: 20 }}>
      <Row>
        <Breadcrumbs location={location} />
      </Row>
      <Row gutter={[10, 10]}>
        <Col lg={14} style={{ paddingInlineEnd: 20 }}>
          <StForm form={form} layout="vertical" onFinish={handleSubmit} initialValues={initValues}>
            {hasTopManager && <Row gutter={[10, 10]}>{avatarField}</Row>}
            <Row gutter={[10, 10]}>
              {!hasTopManager && avatarField}
              <Col lg={8}>
                <Form.Item
                  name="status"
                  label={strings.STATUS}
                  rules={[
                    {
                      required: true,
                      message: `${strings.STATUS} ${strings.IS_REQUIRED}`
                    }
                  ]}
                >
                  <Select>
                    {teacherStatuses.map(item => (
                      <Option value={item.id} key={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={hasTopManager ? 8 : 0}>
                <Form.Item
                  name="branch"
                  label={strings.BRANCH}
                  hidden={!hasTopManager}
                  rules={[{ required: true, message: strings.BRANCH_IS_REQUIRED }]}
                >
                  <Select placeholder={strings.BRANCH}>
                    {branches.map(branch => (
                      <Option value={branch.id} key={branch.id}>
                        {branch.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={8}>
                <Form.Item name="gender" label={strings.SEX}>
                  <Select placeholder={strings.SELECT_GENDER}>
                    <Option value={1} key={FEMALE}>
                      {strings.FEMALE}
                    </Option>
                    <Option value={0} key={MALE}>
                      {strings.MALE}
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col lg={8}>
                <Form.Item
                  name="name"
                  label={strings.FIRST_NAME}
                  rules={[{ required: true, message: strings.NAME_IS_REQUIRED }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={8}>
                <Form.Item name="patronymic" label={strings.FATHERS_NAME}>
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={8}>
                <Form.Item name="surname" label={strings.LAST_NAME}>
                  <Input />
                </Form.Item>
              </Col>
              {form.getFieldValue('branch') === BranchBruner.id && (
                <>
                  <Col lg={8}>
                    <Form.Item name="nameHebrew" label={strings.HEBREW_NAME}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={8}>
                    <Form.Item name="surnameHebrew" label={strings.HEBREW_SURNAME}>
                      <Input />
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col lg={8}>
                <Form.Item label={strings.BIRTHDAY}>
                  <DatePicker format={dateFormat} placeholder={strings.BIRTHDAY} />
                </Form.Item>
              </Col>
              <Col lg={8}>
                <Form.Item
                  name="phone"
                  label={strings.MOBILE}
                  rules={[
                    { required: true, message: `${strings.MOBILE} ${strings.IS_REQUIRED}` },
                    { pattern: new RegExp(/^\+?[0-9\b]+$/), message: strings.INVALID_FORMAT }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={8}>
                <Form.Item
                  name="email"
                  label={strings.EMAIL}
                  rules={[{ type: 'email', message: strings.EMAIL_IS_NOT_VALID }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={24}>
                <Form.Item name="mainSubjects" label={strings.MAIN_SUBJECTS}>
                  <Select mode="multiple" maxTagCount={3} placeholder={strings.MAIN_SUBJECTS}>
                    {filteredMainSubjects.map((subject: Subject) => (
                      <Option value={subject.id} key={subject.id}>
                        {subject.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={24}>
                <Form.Item name="additionalSubjects" label={strings.ADDITIONAL_SUBJECTS}>
                  <Select mode="multiple" maxTagCount={3} placeholder={strings.ADDITIONAL_SUBJECTS}>
                    {filteredAdditionalSubjects.map((subject: Subject) => (
                      <Option value={subject.id} key={subject.id}>
                        {subject.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={24}>
                <Form.Item name="teacherTags" label={strings.TAGS}>
                  <Select mode="multiple" maxTagCount={3} placeholder={strings.TAGS}>
                    {teacherTagsList.map((tag: ITag) => (
                      <Option value={tag.id} key={tag.id}>
                        {tag.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={24}>
                <Form.Item name="experts" label={strings.TUTOR}>
                  <TutorSelect
                    initOptions={defaultTeacher?.experts}
                    mode="multiple"
                    placeholder={strings.START_TYPING}
                    isAllBranchActive={true}
                  />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item name="tutor" label={strings.TUTOR}>
                  <TutorSelect
                    initOptions={defaultTeacher?.tutor && [defaultTeacher?.tutor]}
                    placeholder={strings.START_TYPING}
                  />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item name="mentor" label={strings.MENTOR}>
                  <TutorSelect
                    initOptions={defaultTeacher?.mentor && [defaultTeacher?.mentor]}
                    placeholder={strings.START_TYPING}
                    isAllBranchActive={true}
                  />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item name="org1Id" label={strings.ORG_1_ID}>
                  <Input placeholder={strings.ORG_1_ID} />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item name="prefix1c" label={strings.PREFIX_IC}>
                  <Input placeholder={strings.PREFIX_IC} />
                </Form.Item>
              </Col>
              {hasAccessIsInvoiceUnlimitedAccess && (
                <Col lg={12}>
                  <Form.Item name="contract" label={strings.CONTRACT}>
                    <Select placeholder={strings.CONTRACT}>
                      <Option value={null}>&nbsp;</Option>
                      {contracts.map(item => (
                        <Option value={item.id} key={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
            <StyledSeparator />
            <StUploadedFieldsRow>
              {hasAccessCommentJulia && hasEditTeacher && (
                <StCol lg={24}>
                  <StLabel>{strings.AUDIO}:</StLabel>
                  <Row align="middle" style={{ gap: 20 }}>
                    {previewAudio && <audio style={{ flex: 1 }} controls src={previewAudio} />}
                    <Upload
                      accept={'audio/*'}
                      onChange={handleAudioChange}
                      beforeUpload={() => false}
                      showUploadList={false}
                    >
                      {previewAudio ? (
                        <Tooltip title="Replace the file">
                          <Button shape="circle" icon={<EditOutlined />} />
                        </Tooltip>
                      ) : (
                        <Button style={{ width: 180 }} icon={<UploadOutlined />}>
                          {strings.UPLOAD_AUDIO}
                        </Button>
                      )}
                    </Upload>
                  </Row>
                </StCol>
              )}
              <StCol lg={12}>
                <StLabel>{strings.NDA}:</StLabel>
                <Upload
                  beforeUpload={() => false}
                  onChange={handleChangeNDA}
                  showUploadList={hasEditTeacher ? false : true}
                >
                  <Button style={{ width: 180 }} icon={<UploadOutlined />} loading={hasLoadingNDA}>
                    {strings.LOAD_NDA}
                  </Button>
                </Upload>
                {hasEditTeacher && nda && nda.isNotEmpty() && (
                  <AttachmentsList attachments={nda} updateAttachments={getTeacherAttachments} />
                )}
              </StCol>
              <StCol lg={12}>
                <StLabel>{strings.CV}:</StLabel>
                <Upload
                  beforeUpload={() => false}
                  onChange={handleChangeCV}
                  showUploadList={hasEditTeacher ? false : true}
                >
                  <Button style={{ width: 180 }} icon={<UploadOutlined />} loading={hasLoadingCV}>
                    {strings.LOAD_TEACHER_CV}
                  </Button>
                </Upload>
                {hasEditTeacher && cv && cv.isNotEmpty() && (
                  <AttachmentsList attachments={cv} updateAttachments={getTeacherAttachments} />
                )}
              </StCol>
            </StUploadedFieldsRow>
            <Row style={{ textAlign: 'right' }}>
              <Button type="primary" icon={<ReloadOutlined />} loading={hasLoading} htmlType="submit" size="large">
                {teacherId ? strings.SAVE : strings.ADD_A_NEW_TEACHER}
              </Button>
            </Row>
          </StForm>
        </Col>
        <Col lg={10}>
          <TeacherCard
            previewAvatar={previewAvatar}
            selectedTutor={selectedTutor}
            selectedMentor={selectedMentor}
            selectedExperts={selectedExperts}
            {...cardValues}
          />
        </Col>
      </Row>
    </Row>
  );
};
