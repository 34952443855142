import React, { useState, Fragment, useEffect } from 'react';
import ScheduleService from 'helpers/services/ScheduleService';
import dayjs from 'dayjs';
import { Row, notification, message } from 'antd';
import { Schedule } from 'api/Schedule';
import { dateFormatForLesson, hasRole } from 'helpers';
import { dateFormatForBackend, dateTimeFormatForBackend, dayFormat, getStartAndEndDatesOfWeek } from 'helpers/dates';
import { AxiosResponse } from 'axios';
import { ModalMode } from 'types';
import { unionBy, flatMap } from 'lodash';
import { Event, Room, EventSlot, LessonSlot, ScheduleLesson, SearchSlotsParams } from 'types/Schedule';
import { mockEvent, mockLesson } from 'Global/mocks/schedule';
import { ROLE_TEACHER, ROLE_PARENT, ROLE_STUDENT } from 'Global/roles';
import { Calendar, dayjsLocalizer, EventWrapperProps, View } from 'react-big-calendar';
import { EventTeacherModal } from 'Global/modules/Schedule/SlotModals/EventTeacherModal';
import { LessonModal } from 'Global/modules/Schedule/SlotModals/LessonModal';
import EventModal from 'Global/modules/Schedule/SlotModals/EventModal';
import { LessonModalParent } from 'Global/modules/Schedule/SlotModals/LessonModalParent';
import { Toolbar } from 'Global/modules/Schedule/Calendar/Toolbar';
import { CreateSlot } from 'Global/modules/Schedule/SlotModals/Create';
import { EventModalParent } from 'Global/modules/Schedule/SlotModals/EventModalParent';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { CalendarLoaded } from 'Global/modules/Schedule/styles';
import { useUserContext } from 'contexts/UserContext';
import { ScheduleLessonItem } from 'Global/modules/Schedule/ScheduleLessonItem';
import { useStudentRepository } from 'repos/StudentRepository';
import { useLanguageContext } from 'contexts/LanguageContext';
import { BranchBruner } from 'types/branches';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import updateLocale from 'dayjs/plugin/updateLocale';
import localeData from 'dayjs/plugin/localeData';
import { ViewType } from '../index';
dayjs.extend(localeData);
dayjs.extend(updateLocale);

interface CustomEventWrapperProps extends EventWrapperProps<any> {
  children: React.ReactNode;
}

const service = new ScheduleService();
const availableViews: View[] = ['week', 'day'];

interface LessonsCalendarProps {
  events: LessonSlot[] & EventSlot[];
  getEventsByDates: (params: Partial<SearchSlotsParams>) => Promise<any>;
  rooms: Room[];
  hasBulkMode: boolean;
  setBulkLessons: (lessons: number[]) => void;
  bulkLessons: number[];
  setView: (view: ViewType) => void;
  hasLoadEvents: boolean;
  view: ViewType;
}

/**
 * TODO:
 * 2. Вынести в отдельный компонент поиск по всем людям
 * 3. Объеденить в один компонент общие сущности по формам создания и редактирования события
 */

/**
 * @description Компонент календаря
 * @param {object} props
 * @return {React.ReactNode}
 */
export const LessonsCalendar = (props: LessonsCalendarProps) => {
  const [, lang] = useLanguageContext();
  const localizer = dayjsLocalizer(dayjs);

  const { events, getEventsByDates, rooms, hasBulkMode, setBulkLessons, bulkLessons, setView, view, hasLoadEvents } =
    props;

  const studentRepository = useStudentRepository();
  const { locationSearchParams } = useLocationSearchParams();

  const [user] = useUserContext();
  const [currentDay, setCurrentDay] = useState<Date>(dayjs().toDate());
  const [datePickerValue, setDate] = useState(null);
  // Создание урока или события
  const [hasShowCreate, setHasShowCreate] = useState<boolean>(false);
  const [selectSlotDates, setSelectSlotDates] = useState<{ dateStart: string; dateEnd: string }>();

  // Родитель
  const [hasShowParentModal, setHasShowParentModal] = useState<boolean>(false);

  /** Локальный стейт для флага загрузки, данных. И последующего прокидывания в модалку **/
  // Урок
  const [lesson, setLesson] = useState<ScheduleLesson>(mockLesson);
  const [hasLoadLesson, setHasLoadLesson] = useState<boolean>(false);
  const [modalLessonMode, setModalLessonMode] = useState<ModalMode>(ModalMode.Hidden);
  // Событие
  const [event, setEvent] = useState<Event>(mockEvent);
  const [hasLoadEvent, setHasLoadEvent] = useState<boolean>(false);
  const [modalEventMode, setModalEventMode] = useState<ModalMode>(ModalMode.Hidden);
  const [hasTeacherEventModalShow, setHasTeacherEventModalShow] = useState<boolean>(false);
  const [hasParentEventModalShow, setHasParentEventModalShow] = useState<boolean>(false);
  // Переменные для проверки текущей роли
  const hasParent = user?.hasRoles?.parent;
  const hasAdmin = user?.hasRoles?.admin;
  const hasStudent = user?.hasRoles?.student;
  const hasTeacher = user?.hasRoles?.teacher;
  const hasAcademicExpert = user?.hasRoles?.academicExpert;
  const hasBranchOperationDepartament = user?.hasRoles?.branchOperationDepartament;

  /**
   * @description Получение event по id
   * @param {number} eventId
   * @return {Promise<any>}
   */
  const getEventById = async (eventId: number): Promise<any> => {
    setHasLoadEvent(true);
    try {
      const { data: eventData } = await Schedule.getEventById(eventId);
      let participantsWithParents = [];
      const eventParticipants = eventData.participants;
      const childrenIds = getStudentsIds(eventParticipants);
      // Если массив айди с детьми пустой, то не делаем запрос, иначе он будет висеть
      if (childrenIds.isNotEmpty() && !(hasParent || hasStudent)) {
        try {
          const { data } = await studentRepository.getStudents({ ids: childrenIds, withParents: true });
          const fullStudents = data.map(student => ({ ...student, type: 'student' }));
          participantsWithParents = unionBy(fullStudents, eventParticipants, 'id');
          // Примешиваем детей с связью родителей в основной объект с эвентом
          setEvent({ ...eventData, participants: participantsWithParents });
        } catch {
          setEvent(eventData);
        } finally {
          setHasLoadEvent(false);
        }
      } else {
        // Просто устанавливаем инфу об эвенте
        setEvent(eventData);
        setHasLoadEvent(false);
      }
    } catch {}
  };

  /**
   * @description Получение students id
   * @param {any[]} eventParticipants
   * @return {number[]}
   */
  const getStudentsIds = (eventParticipants: any[]): number[] => {
    return flatMap(eventParticipants)
      .filter(participant => participant.type === 'student')
      .map(student => student.id);
  };

  /**
   * @description Получение lesson  по id
   * @param {number} lessonId урока
   */
  const getLessonById = async (lessonId: number): Promise<any> => {
    setHasLoadLesson(true);
    await Schedule.getLessonById(lessonId)
      .then(({ data, status }: AxiosResponse) => {
        if (status === 200) {
          setLesson({ ...data, id: lessonId });
          setHasLoadLesson(false);
        }
      })
      .catch(() => {});
  };

  // Модальное окно которое появляется по клику у админа на урок
  const lessonModalProps = {
    values: lesson,
    mode: modalLessonMode,
    loading: hasLoadLesson,
    hideModal: () => setModalLessonMode(ModalMode.Hidden),
    getLessonById: getLessonById,
    getEvents: getEventsByDates
  };

  // Модальное окно которое появляется по клику у админа на эвент
  const eventModalProps = {
    values: event,
    hasLoadEvent: hasLoadEvent,
    mode: modalEventMode,
    hideModal: () => setModalEventMode(ModalMode.Hidden),
    getEventById: getEventById,
    getEvents: getEventsByDates
  };

  // Модальное окно, которое появляется по клику у родителя
  const parentModalProps = {
    values: lesson,
    loading: hasLoadLesson,
    hideModal: () => setHasShowParentModal(false),
    hasShow: hasShowParentModal
  };

  // Модальное окно, которое появляется по клику у родителя и студента на эвент
  const parentEventModalProps = {
    values: event,
    hideModal: () => setHasParentEventModalShow(false),
    hasLoadEvent: hasLoadEvent,
    hasShow: hasParentEventModalShow
  };

  /**
   * @description Функция клика по событию (уроку)
   * @param {object} slot
   * @return {Promise<any>}
   */
  const handleSlotClick = async (slot): Promise<any> => {
    // TODO: отрефакторить
    const { type, id, editable } = slot;

    if (hasTeacher && type === 'lesson') {
      return; // Уберем лишний запрос на slot в лк учителя. Во избежание лишних 403 запросов
    }

    if (hasTeacher && type === 'event') {
      const {
        event: { id: eventId }
      } = slot;
      setHasTeacherEventModalShow(true);
      await getEventById(eventId);
    }

    if (hasBulkMode) {
      // Если режим Bulk Select
      // В режиме bulk select, мы не можем выбрать событие

      if (!editable) {
        // Если фин. период окончен, выведем сообщение, прервем функцию
        message.error('You can nо longer apply changes to this date, the financial period is closed', 3);
        return;
      }

      if (type === 'event') {
        notification.error({
          message: 'You can only specify events',
          description: 'In multiple change mode, you can only change or delete lessons',
          placement: 'bottomLeft'
        });
        return;
      }
      if (!bulkLessons.includes(id)) {
        setBulkLessons(bulkLessons.concat(id));
      } else {
        setBulkLessons(bulkLessons.filter(lessonId => lessonId !== slot.id));
      }
      return;
    }

    // Если родитель или студент и тип равен событию
    if ((hasParent || hasStudent) && type === 'event') {
      const {
        event: { id: eventId }
      } = slot;
      setHasParentEventModalShow(true);
      await getEventById(eventId);
      return;
    }

    // Если родитель  или студент
    if ((hasParent || hasStudent) && slot?.lesson) {
      const {
        lesson: { id: lessonId }
      } = slot;
      setHasShowParentModal(true);
      await getLessonById(lessonId);
      return;
    }

    // Если урок
    if (type === 'lesson') {
      const {
        lesson: { id: lessonId }
      } = slot;
      setModalLessonMode(ModalMode.Edit);
      await getLessonById(lessonId);

      // Если событие
    } else if (type === 'event') {
      const {
        event: { id: eventId }
      } = slot;
      setModalEventMode(ModalMode.Edit);
      await getEventById(eventId);
    }
  };

  /** Календарь **/
  /**
   * @description Компонент кастомного toolbar
   * @param {object} toolbar
   * @return {React.ReactNode}
   */
  const getCustomToolbar = toolbar => {
    return (
      <Toolbar
        toolbar={toolbar}
        hasLoadEvents={hasLoadEvents}
        handleDate={handleDate}
        setView={setView}
        view={view}
        datePickerValue={datePickerValue}
        setDate={setDate}
      />
    );
  };

  /**
   * @description Установка даты в go to date
   * @param {string} date
   * @return {void}
   */
  const handleDate = async (date: Date): Promise<any> => {
    const goToDate = date ? date : new Date();
    setCurrentDay(goToDate);
    // Текущая неделя
    let startDate = dayjs(goToDate).format(dateFormatForBackend),
      endDate = dayjs(goToDate).format(dateFormatForBackend);
    if (view === 'week') {
      const week = getStartAndEndDatesOfWeek(goToDate);
      startDate = week.startDate;
      endDate = week.endDate;
    }
    const filterFields = { ...locationSearchParams, dateStart: startDate, dateEnd: endDate };
    await getEventsByDates(filterFields);
  };

  /**`
   * @description Функция навигации по датам
   * @param {Array<Date>} dates список дат
   * @return {void}
   */
  const handleDatesNavigate = async (dates: Date[]): Promise<any> => {
    let dateStart, dateEnd, scheduleView;
    // Если это режим просмотра как 1 день
    if (dates.length === 1) {
      dateStart = dayjs(dates[0]).format(dateFormatForBackend);
      dateEnd = dayjs(dates[0]).format(dateFormatForBackend);
      scheduleView = 'day';
    } else {
      // Иначе режим просмотра как неделя (7 дней)
      dateStart = dayjs(dates[0]).format(dateFormatForBackend);
      dateEnd = dayjs(dates[6]).format(dateFormatForBackend);
      scheduleView = 'week';
    }
    setCurrentDay(dates[0]);
    await getEventsByDates({ ...locationSearchParams, dateStart, dateEnd, view: scheduleView });
  };

  // Форматы времени и даты
  const formats = {
    dateFormat: dayFormat,
    timeGutterFormat: dateFormatForLesson,
    timeFormat: dateFormatForLesson,
    selectRangeFormat: ScheduleService.selectRangeFormat
  };

  const eventItemProps = { hasBulkMode, bulkLessons, rooms };

  /**
   * @description Плашка с слотом (эвентом)
   * @param {object} props
   * @return {React.ReactNode}
   */
  const EventItem = props => <ScheduleLessonItem {...props} {...eventItemProps} />;

  /**
   * @description Обертка над плашкой
   * @param {object} event
   * @param {object} children
   * @return {Promise<any>}
   */
  const EventWrapper: React.FC<CustomEventWrapperProps> = ({ event, children }) => {
    if (!React.isValidElement(children)) {
      return null;
    }
    const { title, className } = children.props;
    const { start, end, options } = event;

    return (
      <div
        title={title}
        className={`${className} schedule-event`}
        onClick={async (): Promise<any> => await handleSlotClick(event)}
        style={{
          background: event?.color,
          ...service.getRowStyleByScheduleEvent(start),
          ...options
        }}
      >
        {children.props.children}
      </div>
    );
  };

  const getScheduleStart = () => {
    return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 8);
  };

  const getScheduleEnd = () => {
    return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 22);
  };

  const calendarProps = {
    // Выключение базового tooltip, который появляется при наведении
    tooltipAccessor: null,
    // Слоты времени 4 штуки, по 15 минут
    timeslots: ScheduleService.SCHEDULE_TIME_SLOTS,
    // Шаг между отображением эвентов
    step: ScheduleService.SCHEDULE_STEPS,
    // Начало дня
    min: getScheduleStart(),
    // Конец дня
    max: getScheduleEnd(),
    // Текущий день
    date: currentDay,
    // Форматы времени и даты
    formats: formats,
    // Поддерживаемые отображения
    views: availableViews,
    // Базовое отображение. Правка для учителя Trello #466 + Trello 542 по студенту + по Лк родителя из кликапа
    // В моб версии по умолчанию 'day'
    // defaultView: hasRole(user, [ROLE_TEACHER, ROLE_STUDENT, ROLE_PARENT]) && !hasMobile ? 'week' : 'day',
    view: view,
    // Массив событий
    events: events,
    // Создавать события не могу учителя и родители и студенты и в режиме Bulk Select
    selectable: !hasRole(user, [ROLE_TEACHER, ROLE_PARENT, ROLE_STUDENT]) && !hasBulkMode,
    localizer: localizer,
    onSelectEvent: handleSlotClick,
    onRangeChange: handleDatesNavigate,
    onSelectSlot: ({ start, end }) => {
      setSelectSlotDates({
        dateStart: dayjs(start).format(dateTimeFormatForBackend),
        dateEnd: dayjs(end).format(dateTimeFormatForBackend)
      });
      setHasShowCreate(true);
    },
    components: {
      toolbar: getCustomToolbar,
      event: EventItem,
      eventWrapper: EventWrapper
    }
  };

  /** Создание события **/
  const createModalProps = {
    show: hasShowCreate,
    hideModal: () => setHasShowCreate(false),
    dateStart: selectSlotDates?.dateStart,
    dateEnd: selectSlotDates?.dateEnd,
    getEventsByDates: getEventsByDates,
    service: service
  };

  useEffect(() => {
    if (user?.branch.id !== BranchBruner.id) {
      dayjs.updateLocale(lang, {
        weekStart: 1
      });
    }
  }, [user]);

  useEffect(() => {
    const { dateStart } = locationSearchParams;
    if (dateStart) {
      setCurrentDay(dayjs(dateStart).toDate());
    }
  }, [locationSearchParams]);

  return (
    <Row>
      {(hasAdmin || hasAcademicExpert || hasBranchOperationDepartament) && (
        <Fragment>
          <LessonModal {...lessonModalProps} />
          <EventModal {...eventModalProps} />
          <CreateSlot {...createModalProps} />
        </Fragment>
      )}
      {hasTeacher && (
        <EventTeacherModal
          event={event}
          hasShow={hasTeacherEventModalShow}
          hideModal={() => setHasTeacherEventModalShow(false)}
        />
      )}
      {(hasParent || hasStudent) && (
        <>
          <EventModalParent {...parentEventModalProps} />
          <LessonModalParent {...parentModalProps} />
        </>
      )}
      <CalendarLoaded className="CalendarLoaded" hasLoadEvents={hasLoadEvents}>
        <Calendar culture={lang} {...calendarProps} />
      </CalendarLoaded>
    </Row>
  );
};

export default { LessonsCalendar };
