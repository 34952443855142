import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row, Tabs } from 'antd';
import confetti from 'canvas-confetti';
// import { DownloadHomework } from 'Global/components/DownloadHomework';
import { News } from 'Global/modules/Home/News';
import { UpcomingLessons } from 'Student&Parent/Main/UpcomingLessons';
import { BirthdayModal } from 'Student&Parent/Main/BirthdayModal';
// import { SnowFall } from 'Global/components/Snow';
import { useUserContext } from 'contexts/UserContext';
import {Child, Family, IFamily, IStudent} from 'types';
import { useFamilyContext } from 'contexts/FamilyContext';
import { useConstantsContext } from 'contexts/ConstantsContext';
import { TransparentContainer } from 'Global/GlobalStyle';
import { PreviousLessons } from './PreviousLessons';
import { first } from 'lodash';
import styled from 'styled-components';

const { TabPane } = Tabs;

export const StTitleHomework = styled.span`
  font-size: 20px;
  font-weight: 600;
`;

const Widgets = ({ studentId }: { studentId: number }) => {
  return (
    <Row gutter={15} style={{ marginBottom: 20 }}>
      <Col lg={12}>
        <PreviousLessons studentId={studentId} />
        {/*<DownloadHomework />*/}
      </Col>
      <Col lg={12}>
        <UpcomingLessons studentId={studentId} />
      </Col>
    </Row>
  );
};

export const MainPageParent = () => {
  const [hasShowBirthday, setHasShowBirthday] = useState<boolean>(false);
  const [user] = useUserContext();
  const isCabinetLocked = user?.isCabinetLocked;
  const [family]: [IFamily] = useFamilyContext();
  const { ACTIVE_STUDENT_STATUS_ID } = useConstantsContext();
  const hasParentRole = user?.hasRoles?.parent;
  const hasStudentRole = user?.hasRoles?.student;

  const studentList: IStudent[] = useMemo(
    () => family?.children?.filter((child: IStudent) => child?.status?.id === ACTIVE_STUDENT_STATUS_ID) || [],
    [family?.children]
  );

  useEffect(() => {
    if (hasShowBirthday) {
      for (let i = 0; i < 5; i++) {
        confetti({
          particleCount: 100,
          startVelocity: 30,
          spread: 360,
          origin: {
            x: Math.random(),
            y: Math.random() - 0.2
          }
        });
      }
    }
  }, [hasShowBirthday]);

  return (
    <TransparentContainer>
      {/*<SnowFall />*/}
      {/*{!hasShowBirthday && <SurveyModal />}*/}
      <BirthdayModal hasShowBirthday={hasShowBirthday} setHasShowBirthday={setHasShowBirthday} />
      {!isCabinetLocked && hasStudentRole && <Widgets studentId={user?.meStudentId} />}
      {!isCabinetLocked && hasParentRole && studentList.length === 1 && <Widgets studentId={first(studentList).id} />}
      {!isCabinetLocked && hasParentRole && studentList.length > 1 && (
        <Tabs defaultActiveKey="1" style={{ overflow: 'hidden' }}>
          {studentList?.map(student => (
            <TabPane tab={student?.user?.name} key={String(student.id)}>
              <Widgets studentId={student.id} />
            </TabPane>
          ))}
        </Tabs>
      )}
      <News />
    </TransparentContainer>
  );
};
