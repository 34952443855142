import React, { useMemo, useEffect, useState } from 'react';
import { first } from 'lodash';
import { PageHeader } from '@ant-design/pro-layout';
import { Tabs, Alert } from 'antd';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { IFamily, IStudent } from 'types';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useFamilyContext } from 'contexts/FamilyContext';
import { useConstantsContext } from 'contexts/ConstantsContext';
import { TransparentContainer } from 'Global/GlobalStyle';
import { useHistory } from 'react-router-dom';
import { HomeworkList } from './HomeworkList';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';

const { TabPane } = Tabs;

export interface IHomeworkFilterParams {
  student?: number;
  subject?: number;
  startAt?: string;
  endAt?: string;
  page?: number;
  withHomeworkAssigned?: boolean;
}

/**
 * @description Загрузка домашнего задания учеником
 * @param {object} props
 * @return {React.ReactNode}
 */
export const UploadHomework = () => {
  const { goBack } = useHistory();

  const [strings] = useLanguageContext();
  const { locationSearchParams } = useLocationSearchParams();
  const { student } = locationSearchParams;

  const [user] = useUserContext();
  const [family]: [IFamily] = useFamilyContext();
  const { ACTIVE_STUDENT_STATUS_ID } = useConstantsContext();
  const hasParentRole = user?.hasRoles?.parent;
  const hasStudentRole = user?.hasRoles?.student;

  const studentList: IStudent[] = useMemo(
    () => family?.children?.filter((child: IStudent) => child?.status?.id === ACTIVE_STUDENT_STATUS_ID) || [],
    [family?.children]
  );

  const [activeTab, setActiveTab] = useState<string>();

  const handleTabChange = (tab: string): void => setActiveTab(tab);

  useEffect(() => {
    if (student) {
      setActiveTab(String(student));
    }
  }, []);

  return (
    <ErrorBoundary>
      <TransparentContainer>
        <PageHeader
          onBack={goBack}
          title={strings.HOMEWORK}
          style={{ padding: 0, marginBottom: hasStudentRole || studentList.length === 1 ? 10 : 0 }}
          extra={
            <Alert
              message={strings.DO_NOT_FORGET_TO_BRING_A_HARDCOPY_OF_THE_HOMEWORK_TO_THE_LESSON}
              type="info"
              showIcon
            />
          }
        ></PageHeader>
        {hasStudentRole && <HomeworkList studentId={user?.meStudentId} />}
        {hasParentRole && studentList.length === 1 && <HomeworkList studentId={first(studentList).id} />}
        {hasParentRole && studentList.length > 1 && (
          <Tabs activeKey={activeTab || String(first(studentList)?.id)} onChange={handleTabChange}>
            {studentList?.map(student => (
              <TabPane tab={student?.user?.name} key={String(student.id)}>
                <HomeworkList studentId={student.id} />
              </TabPane>
            ))}
          </Tabs>
        )}
      </TransparentContainer>
    </ErrorBoundary>
  );
};

export default { UploadHomework };
