import React, { useEffect, useState } from 'react';
import { EditOutlined, UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Row, Col, Select, Button, Input, message, DatePicker, Tooltip, Upload } from 'antd';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { match } from 'react-router';
import { BACKEND_LOCATION, beforeImageUpload, dateFormat, getBase64, getFormData } from 'helpers';
import { WEEKLY_EMAIL } from '../routes';
import { useMailingRepository } from 'repos/MailingRepository';
import dayjs from 'dayjs';
import { filterSelect } from 'helpers/filterSelect';
import { Supergroup } from 'types/education';
import { TutorSelect } from 'Global/components/FormComponentsCompatible/Selects/TutorSelect';
import { IWeeklyEmailItem } from 'types/Mailing';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { UploadFile } from 'types/global';
import { QuickTips } from 'Global/components/QuickTips';
import { QUICK_TIPS_WEEKLY_EMAIL } from 'Global/constants';
import { useUserContext } from 'contexts/UserContext';
import { momentFormatValues } from 'helpers/momentFormatValues';
import MyJoditEditor from 'Global/components/MyJoditEditor';

const { Option } = Select;

interface WeeklyEmailFormProps {
  form: WrappedFormUtils;
}

/**
 * @description Форма для Weekly Email
 * @param {WeeklyEmailFormProps} props
 * @returns {React.ReactNode}
 */
export const WeeklyEmailFormTemplate = props => {
  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();

  const {
    form: { getFieldDecorator, validateFieldsAndScroll, setFieldsValue }
  } = props;
  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const mailingRepository = useMailingRepository();
  const coursesRepository = useCoursesRepository();
  const { goBack, push } = useHistory();
  const [superGroups, setSuperGroups] = useState<Supergroup[]>([]);
  const [hasSuperGroupsLoading, setHasSuperGroupsLoading] = useState<boolean>(false);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [defaulValues, setDefaultValues] = useState<IWeeklyEmailItem>(null);
  const [selectedSupergroups, setSelectedSupergroups] = useState<number[]>(
    defaulValues?.supergroups ? defaulValues?.supergroups?.map(item => item.id) : []
  );
  const [selectedTutor, setSelectedTutor] = useState<number>(null);
  const [uploadingFile, setUploadingFile] = useState<UploadFile>(null);
  const [previewPhoto, setPreviewPhoto] = useState<string>('');

  const hasRiga = user?.branch?.id === 5;

  /**
   * @description Submit формы
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const params = momentFormatValues(values);
        setHasLoading(true);
        //инвойсы прикрепляем по умолчанию
        values['invoices'] = 1;
        delete values['tutor'];
        if (uploadingFile) {
          values['photo'] = uploadingFile;
        }
        try {
          if (id) {
            values['_method'] = 'PUT';
            await mailingRepository.editMailing(id, getFormData(params, true));
          } else {
            await mailingRepository.createMailing(getFormData(params));
          }
          push(WEEKLY_EMAIL);
        } catch (error) {
          message.error(error?.data?.message || strings.UNEXPECTED_ERROR);
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  function getActionString() {
    if (id) {
      return strings.EDIT;
    }

    return strings.ADD;
  }

  /**
   * @description Получение списка супер групп по тьютору
   * @param {number} branchId
   * @return {Promise<any>}
   */
  const getSuperGroups = async (): Promise<any> => {
    setHasSuperGroupsLoading(true);
    try {
      const params = { isActive: true, ...(selectedTutor && { tutor: selectedTutor }) };
      const { data } = await coursesRepository.getSuperGroups(params);
      if (selectedTutor) {
        setSelectedSupergroups(data.map(item => item.id));
      } else if (selectedTutor !== null) {
        setSelectedSupergroups([]);
      }
      setSuperGroups(data);
    } catch {
    } finally {
      setHasSuperGroupsLoading(false);
    }
  };
  /**
   * @description Обработчик на загрузку audio
   * @param {File} file
   * @return {Promise<any>}
   **/
  const handleFileChange = async (upload): Promise<any> => {
    const imageHash = await getBase64(upload.file);
    setPreviewPhoto(imageHash);
    setUploadingFile(upload.file);
  };

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          const { data } = await mailingRepository.getMailingById(id);
          setDefaultValues(data);
          setSelectedSupergroups(data?.supergroups?.map(item => item.id));
          if (data?.photo) {
            setPreviewPhoto(`${BACKEND_LOCATION}${data?.photo}`);
          }
        } catch {}
      })();
    }
  }, [id]);

  useEffect(() => {
    getSuperGroups();
  }, [, selectedTutor]);

  const uploadProps = {
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    onChange: handleFileChange,
    beforeUpload: beforeImageUpload
  };

  return (
    <Container>
      <StPageHeader
        onBack={goBack}
        title={strings.WEEKLY_EMAIL}
        subTitle={!hasRiga && <QuickTips url={QUICK_TIPS_WEEKLY_EMAIL} />}
        extra={[
          <Button type="primary" loading={hasLoading} onClick={handleSubmit} size="large">
            {getActionString()}
          </Button>
        ]}
      />
      <Form layout="vertical" style={{ marginTop: 20 }}>
        <Row gutter={[10, 10]}>
          <Col lg={4} xs={24}>
            <MyFormItem label={strings.DATE}>
              {getFieldDecorator('sendAt', {
                initialValue: defaulValues?.sendAt ? dayjs(defaulValues?.sendAt) : dayjs(),
                rules: [{ required: true, message: strings.SELECT_DATE }]
              })(<DatePicker placeholder={`${strings.SELECT_DATE}`} format={dateFormat} />)}
            </MyFormItem>
          </Col>
          <Col lg={8} xs={24}>
            <MyFormItem label={strings.TITLE}>
              {getFieldDecorator('title', {
                initialValue: defaulValues?.title,
                rules: [{ required: true, message: `${strings.TITLE} ${strings.IS_REQUIRED}` }]
              })(<Input />)}
            </MyFormItem>
          </Col>
          <Col lg={4} xs={24}>
            <TutorSelect
              label={strings.TUTOR}
              getFieldDecorator={getFieldDecorator}
              name="tutor"
              placeholder={strings.TUTOR}
              handleChange={value => setSelectedTutor(value)}
            />
          </Col>
          <Col lg={8} xs={24}>
            <MyFormItem label={strings.SUPERGROUPS}>
              {getFieldDecorator('supergroups', {
                rules: [{ required: true }],
                initialValue: selectedSupergroups
              })(
                <Select
                  showSearch
                  filterOption={filterSelect}
                  optionFilterProp="children"
                  loading={hasSuperGroupsLoading}
                  placeholder={strings.SUPERGROUPS}
                  maxTagCount={1}
                  mode="multiple"
                  allowClear={true}
                >
                  {superGroups.map((superGroup: Supergroup) => (
                    <Option key={superGroup.id} value={superGroup.id}>
                      <Tooltip title={superGroup.name} overlayStyle={{ zIndex: 9999 }}>
                        {superGroup.name}
                      </Tooltip>
                    </Option>
                  ))}
                </Select>
              )}
            </MyFormItem>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col lg={12} xs={24}>
            <MyFormItem label={strings.INTRO}>
              {getFieldDecorator('intro', {
                initialValue: defaulValues?.intro ? defaulValues?.intro : ''
              })(
                <MyJoditEditor
                  key="intro-editor"
                  value={defaulValues?.intro}
                  onChange={value => setFieldsValue({ intro: value })}
                  height={200}
                />
              )}
            </MyFormItem>
          </Col>
          <Col lg={12} xs={24}>
            <MyFormItem label={strings.NEXT_WEEK_TO_DO}>
              {getFieldDecorator('nextWeekToDo', {
                initialValue: defaulValues?.nextWeekToDo ? defaulValues?.nextWeekToDo : ''
              })(
                <MyJoditEditor
                  key="nextWeekToDo-editor"
                  value={defaulValues?.nextWeekToDo}
                  onChange={value => setFieldsValue({ nextWeekToDo: value })}
                  height={200}
                />
              )}
            </MyFormItem>
          </Col>
          <Col lg={12} xs={24}>
            <MyFormItem label={strings.NEXT_WEEK_TO_KNOW}>
              {getFieldDecorator('nextWeekToKnow', {
                initialValue: defaulValues?.nextWeekToKnow ? defaulValues?.nextWeekToKnow : ''
              })(
                <MyJoditEditor
                  key="nextWeekToKnow-editor"
                  value={defaulValues?.nextWeekToKnow}
                  onChange={value => setFieldsValue({ nextWeekToKnow: value })}
                  height={200}
                />
              )}
            </MyFormItem>
          </Col>
          <Col lg={12} xs={24}>
            <MyFormItem label={strings.USEFUL_AND_INTERESTING}>
              {getFieldDecorator('usefulAndInteresting', {
                initialValue: defaulValues?.usefulAndInteresting ? defaulValues?.usefulAndInteresting : ''
              })(
                <MyJoditEditor
                  key="usefulAndInteresting-editor"
                  value={defaulValues?.usefulAndInteresting}
                  onChange={value => setFieldsValue({ usefulAndInteresting: value })}
                  height={200}
                />
              )}
            </MyFormItem>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <MyFormItem label={strings.PHOTO}>
              {previewPhoto ? (
                <Row align="middle">
                  <div style={{ flex: 1 }}>
                    <img src={previewPhoto} width="100%" />
                  </div>
                  <Upload {...uploadProps}>
                    <Tooltip title={strings.REPLACE_THE_FILE}>
                      <Button size="small" icon={<EditOutlined />} style={{ marginInlineStart: 8, fontSize: 14 }} />
                    </Tooltip>
                  </Upload>
                </Row>
              ) : (
                <Upload {...uploadProps}>
                  <Button>
                    <UploadOutlined /> {strings.UPLOAD}
                  </Button>
                </Upload>
              )}
            </MyFormItem>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export const WeeklyEmailForm = Form.create<WeeklyEmailFormProps>({})(WeeklyEmailFormTemplate);
export default { WeeklyEmailForm };
