import React, { useCallback, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { BASE_TEACHER_ADD } from 'Admin/People/Teachers/routes';
import { Teachers } from 'api/Teachers';
import { BACKEND_LOCATION, downloadFile, downloadSomeFile, hasMoscowBranch } from 'helpers';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { ITeacherGetParams } from 'types/Teachers/teacher';
import { Filter } from './Filter';
import { QuickTips } from 'Global/components/QuickTips';
import { QUICK_TIPS_NEW_ACCOUNT } from 'Global/constants';
import { StPageHeader } from 'Global/GlobalStyle';
import { useTeacherRepository } from 'repos/TeacherRepository';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';

interface TeachersTitleProps {
  total: number;
  fetchTeachers: (params?: ITeacherGetParams) => Promise<any>;
  defValues: ITeacherGetParams;
}

/**
 * @description Компонент заголовка страницы учителей
 * @param {TeachersTitleProps} props
 * @return {React.ReactNode}
 */
export const TeachersTitle = React.memo(({ total, fetchTeachers, defValues }: TeachersTitleProps) => {
  const [strings] = useLanguageContext();
  const { goBack, push } = useHistory();
  const [user] = useUserContext();
  const [agreementReportLoading, setAgreementReportLoading] = useState<boolean>(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const hasRoleAcademicExpert = user?.hasRoles?.academicExpert;
  const hasRiga = user?.branch?.id === 5;
  const { locationSearchParams } = useLocationSearchParams();

  const teacherRepository = useTeacherRepository();

  /**
   * @description Экспорт и скачивание списка учителей
   * @return {Promise<any>}
   */
  const exportTeachersToExcel = useCallback(async (): Promise<any> => {
    setExportLoading(true);
    try {
      const {
        data: { url }
      } = await teacherRepository.exportTeachers(locationSearchParams);
      const link = `${BACKEND_LOCATION}/${url}`;
      downloadFile(link, '');
      message.success(strings.EXCEL_WAS_CREATED_SUCCESSFULLY, 2);
    } catch {
    } finally {
      setExportLoading(false);
    }
  }, [setExportLoading]);

  /**
   * @description Cкачивание списка учителей, прочитавших неформальный договор
   * @return {Promise<any>}
   */
  const getAgreementReport = useCallback(async (): Promise<any> => {
    setAgreementReportLoading(true);
    try {
      await downloadSomeFile(`/api/report/teachers/agreement/export`, 'Informal agreement read by teachers.xls');
      message.success(strings.EXCEL_FILE_HAS_BEEN_DOWNLOADED, 2);
    } catch {
    } finally {
      setAgreementReportLoading(false);
    }
  }, [setAgreementReportLoading]);

  const getSubtitle = () => {
    if (total) {
      return `${strings.TOTAL_NUMBER_OF_TEACHERS} ${total}`;
    }

    return null;
  };

  return (
    <StPageHeader
      onBack={goBack}
      title={strings.TEACHERS}
      subTitle={getSubtitle()}
      extra={[
        !hasRoleAcademicExpert && (
          <Button type="primary" onClick={() => push(BASE_TEACHER_ADD)}>
            {strings.ADD_TEACHER}
          </Button>
        ),
        <Button type="primary" onClick={exportTeachersToExcel} loading={exportLoading} icon={<DownloadOutlined />}>
          {strings.EXPORT_TO_EXCEL}
        </Button>,
        hasMoscowBranch(user?.branch) && (
          <Button
            type="primary"
            onClick={getAgreementReport}
            loading={agreementReportLoading}
            icon={<DownloadOutlined />}
          >
            {strings.INFORMAL_AGREEMENT}
          </Button>
        ),
        !hasRiga && <QuickTips url={QUICK_TIPS_NEW_ACCOUNT} />
      ]}
    >
      <Filter onFilter={fetchTeachers} defValues={defValues} />
    </StPageHeader>
  );
});

export default { TeachersTitle };
