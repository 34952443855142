import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Descriptions, Typography, Row, Col } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container } from 'Global/GlobalStyle';

interface InformationProps {
  group: any;
}

const { Title } = Typography;

/**
 * @description Вкладка информация
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const Information = (props: InformationProps) => {
  const { group } = props;
  const [strings] = useLanguageContext();
  const {
    name,
    stage,
    durationMeasure,
    monthRate,
    price,
    cost,
    originalCourse,
    weekLessonsCount,
    created,
    updated,
    groupName,
    assistentCost,
    duration,
    isActive
  } = group || {};

  return (
    <Container style={{ margin: 0 }}>
      <Title level={4}>{strings.INFORMATION}</Title>
      <Row>
        <Col lg={12} xs={24}>
          <Descriptions column={1}>
            <Descriptions.Item label={strings.SYSTEM_NAME}>{groupName}</Descriptions.Item>
            <Descriptions.Item label={strings.BASED_ON_THE_ORIGINAL_COURSE}>
              <Link to={`/original_courses/${originalCourse?.id}`}>{originalCourse?.name}</Link>
            </Descriptions.Item>
            <Descriptions.Item label={strings.SUBJECT}>{name}</Descriptions.Item>
            <Descriptions.Item label={strings.STAGE}>{stage?.name}</Descriptions.Item>
            <Descriptions.Item label={strings.CREATED}>{created}</Descriptions.Item>
            <Descriptions.Item label={strings.UPDATED}>{updated}</Descriptions.Item>
            <Descriptions.Item label={strings.CURRENT}>
              {isActive && <CheckCircleTwoTone twoToneColor="#52c41a" />}
              {!isActive && <CloseCircleTwoTone twoToneColor="#f5222d" />}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col lg={12} xs={24}>
          <Descriptions column={1}>
            <Descriptions.Item label={strings.LESSON_DURATION}>
              {duration && `${duration} ${strings.CLASSROOM_HOUR}`}
            </Descriptions.Item>
            <Descriptions.Item label={strings.HOUR_DURATION}>
              {durationMeasure && `${durationMeasure} ${strings.MINUTES}`}
            </Descriptions.Item>
            <Descriptions.Item label={strings.LESSONS_PER_WEEK}>{weekLessonsCount}</Descriptions.Item>
            <Descriptions.Item label={strings.RATE}>
              {price?.name} ({price?.price})
            </Descriptions.Item>
            <Descriptions.Item label={strings.CONDUCTOR_WAGE}>
              {cost?.name} ({cost?.cost})
            </Descriptions.Item>
            <Descriptions.Item label={strings.ASSISTANT_WAGE}>
              {assistentCost ? assistentCost?.cost : '-'}
            </Descriptions.Item>
            <Descriptions.Item label={strings.MONTH_RATE}>
              {monthRate && <CheckCircleTwoTone twoToneColor="#52c41a" />}
              {!monthRate && <CloseCircleTwoTone twoToneColor="#f5222d" />}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </Container>
  );
};

export default { Information };
