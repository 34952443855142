import { Schedule } from 'Global/modules/Schedule';
import * as scheduleRoutes from 'Global/modules/Schedule/routes';

export const schedule = [
  {
    path: scheduleRoutes.SCHEDULE,
    component: Schedule,
    exact: true
  }
];

export default { schedule };
