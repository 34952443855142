import React, { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Button, Col, Row, Select, Spin, message } from 'antd';
import { debounce } from 'lodash';
import { Courses } from 'api';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { OriginalCourse } from 'types/courses';
import { useUserContext } from 'contexts/UserContext';
import { Link } from 'react-router-dom';
import { BASE_COURSE_GROUP } from '../routes';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { useLanguageContext } from '../../../contexts/LanguageContext';

const { Option } = Select;

interface ChangeOriginalCourseProps extends FormComponentProps {
  groupId: number;
  groupOriginalCourse: number;
  originalCourses: OriginalCourse[];
  setGroupOriginalCourse: (originalCourse: number) => void;
  setOriginalCourses: (originalCourses: OriginalCourse[]) => void;
}

export const ChangeOriginalCourse = Form.create<ChangeOriginalCourseProps>({})(
  ({
    form: { getFieldDecorator },
    groupId,
    groupOriginalCourse,
    originalCourses,
    setGroupOriginalCourse,
    setOriginalCourses
  }: ChangeOriginalCourseProps) => {
    const [strings] = useLanguageContext();
    const coursesRepository = useCoursesRepository();

    const handleOriginalCourseChange = (courseId: number) => {
      setGroupOriginalCourse(courseId);
    };

    const [hasLoadCourses, setHasLoadCourses] = useState(false);
    const [hasLoading, setHasLoading] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const [typing, setTyping] = useState<Boolean>(false);
    const [user] = useUserContext();

    const attachOriginalCourse = async (): Promise<any> => {
      setHasLoading(true);
      try {
        await Courses.editCourseGroup(groupId, { originalCourse: groupOriginalCourse });
        message.success(`${strings.SUCCESSFULLY_CHANGED} :)`, 2);
        setHasLoading(false);
      } catch {
        message.error(`${strings.ERROR_TRY_AGAIN_LATER} :(`, 2);
        setHasLoading(false);
      }
    };

    const getOptionsList = () => {
      if (!originalCourses) return [];
      return originalCourses.map(course => (
        <Option value={course.id} key={course.id}>
          {course.name}
        </Option>
      ));
    };

    const fetchOriginalCourses = async (title: string) => {
      setSearchValue(title);
      if (title) {
        try {
          setHasLoadCourses(true);
          const {
            data: { items }
          } = await coursesRepository.getOriginalCourses({ title });
          setOriginalCourses(items);
        } catch {
          setOriginalCourses([]);
        } finally {
          setHasLoadCourses(false);
        }
      }
    };

    useEffect(() => {
      if (searchValue) {
        setTyping(true);
      } else {
        setTyping(false);
      }
    }, [searchValue]);

    if (user?.hasRoles?.teacher) {
      return null;
    }

    return (
      <Col lg={12}>
        <Row justify="end" gutter={6} className="no-padding">
          <Col lg={14} xs={24}>
            {getFieldDecorator('branch', {
              rules: [{ required: false }],
              initialValue: groupOriginalCourse
            })(
              <Select
                showSearch
                allowClear
                placeholder={strings.ORIGINAL_COURSE}
                filterOption={false}
                onSearch={debounce(fetchOriginalCourses, 800)}
                loading={hasLoadCourses}
                notFoundContent={
                  hasLoadCourses ? <Spin size="small" /> : <p>{!typing ? strings.START_TYPING : strings.NOT_FOUND}</p>
                }
                maxTagCount={2}
                onChange={handleOriginalCourseChange}
              >
                {[...getOptionsList()]}
              </Select>
            )}
          </Col>
          <Col>
            <Button loading={hasLoading} type="primary" onClick={attachOriginalCourse}>
              {strings.CHANGE_OC}
            </Button>
          </Col>
          <Col>
            <Link to={`${BASE_COURSE_GROUP}/${groupId}/edit`}>
              <Button icon={<EditOutlined />} type="primary" />
            </Link>
          </Col>
        </Row>
      </Col>
    );
  }
);
