import React, { useState, useEffect } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Select, Spin, message, Empty, Modal } from 'antd';
import { useRouteMatch, Link, useHistory } from 'react-router-dom';
import { match } from 'react-router';
import { GroupItem, GroupItems, SupergroupContainer, SelectContainer, GroupLink } from './styles';
import { Courses } from 'api/Courses';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Supergroup, Group } from 'types';
import { filterSelect } from 'helpers/filterSelect';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { useSupergroupRepository } from 'repos/SupergroupRepository';

const { Option } = Select;

/**
 * @description Супергруппа
 * @param {SupergroupFull} props
 * @return {React.ReactNode}
 */
export const SupergroupFull = () => {
  const [strings] = useLanguageContext();
  const [groups, setGroups] = useState<Group[]>([]);
  const [supergroup, setSupergroup] = useState<Supergroup>();
  const [selectGroups, setSelectGroups] = useState<Group[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<number | undefined>();
  const [loadingGroups, setLoadingGroups] = useState<boolean>(false);
  const [loadingAdd, setLoadingAdd] = useState<boolean>(false);
  const [deleteIds, setDeleteIds] = useState<number[]>([]);
  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();
  const { push, goBack } = useHistory();
  const coursesRepository = useCoursesRepository();
  const supergroupRepository = useSupergroupRepository();
  const [{ confirm }, contextHolder] = Modal.useModal();

  /**
   * @description Список супергрупп
   * @param {number} supergroupdId
   * @return {Promise<any>}
   */
  const getSupergroupInfo = async (supergroupdId: number | string): Promise<any> => {
    setLoading(true);

    const promiseGroups = coursesRepository.getCourseGroups({ supergroup: Number(supergroupdId) });
    const promiseSupergroup = supergroupRepository.getSupergroupById(supergroupdId);
    try {
      const [groups, supergroup] = await Promise.all([promiseGroups, promiseSupergroup]);
      setGroups(groups.data.items);
      setSupergroup(supergroup.data);
      // На основе супергруппы получаю группы для селекта
      try {
        const {
          data: { items }
        } = await coursesRepository.getCourseGroups({
          active: 1,
          branch: supergroup.data.branch.id,
          sortByGroupName: 'ASC',
          limit: 1000
        });
        setSelectGroups(items);
      } catch {
      } finally {
        setLoadingGroups(false);
      }
    } catch {
    } finally {
      setLoading(false);
    }
  };

  /**
   * @description Получение списка актуальных групп
   * @return {Promise<any>}
   */
  const getGroups = async (): Promise<any> => {
    try {
      const {
        data: { items }
      } = await coursesRepository.getCourseGroups({ supergroup: supergroup.id });
      setGroups(items);
    } catch {}
  };

  /**
   * @description Добавление группы в супегруппу
   * @return {Promise<any>}
   */
  const addGroupToSupergroup = async (): Promise<any> => {
    setLoadingAdd(true);
    await Courses.addSupergroupToGroup(selectedGroup, { supergroup: supergroup.id })
      .then(() => {
        message.success(strings.GROUP_ADD_IN_SUPERGROUP_SUCCESSFULLY);
        // Очищаю селект
        setSelectedGroup(undefined);
        getGroups();
      })
      .catch(() => {})
      .finally(() => setLoadingAdd(false));
  };

  /**
   * @description Удаление группы из супегруппы
   * @param {number} groupId
   * @return {Promise<any>}
   */
  const deleteGroup = async (groupId: number): Promise<any> => {
    // Для дизейбла и лоудера в кнопку удаления
    setDeleteIds(ids => ids.concat(groupId));
    try {
      await Courses.removeSupergroupFromGroup(groupId, supergroup.id);
      message.success(strings.GROUP_DELETE_FROM_SUPERGROUP_SUCCESSFULLY);
      setDeleteIds(ids => ids.filter(id => id !== groupId));
      getGroups();
    } catch {}
  };

  /**
   * @description Удаление супергруппы
   * @return {Promise<any>}
   */
  const removeSupergroup = async (): Promise<any> => {
    confirm({
      title: strings.ARE_YOU_SURE_WANT_DELETE_SUPERGROUP,
      async onOk() {
        try {
          await coursesRepository.deleteSupergroup(supergroup.id);
          message.success(strings.SUPERGROUP_DELETED_SUCCESSFULLY);
          push('/supergroup');
        } catch {}
      }
    });
  };

  useEffect(() => {
    getSupergroupInfo(id);
  }, [id]);

  return (
    <Container>
      {contextHolder}
      <StPageHeader
        onBack={goBack}
        title={supergroup?.name}
        extra={[
          <Button>
            <Link to={`/supergroup/${supergroup?.id}/edit`}>{strings.EDIT}</Link>
          </Button>,
          <Button danger onClick={removeSupergroup}>
            {strings.REMOVE}
          </Button>
        ]}
      />
      <Spin spinning={loading} tip={`${strings.LOADING} ${strings.SUPERGROUPS}`}>
        <SupergroupContainer>
          <SelectContainer>
            <Select
              placeholder={strings.SELECT_GROUP}
              onChange={(groupId: number) => setSelectedGroup(groupId)}
              value={selectedGroup}
              loading={loadingGroups}
              filterOption={filterSelect}
              showSearch={true}
            >
              {selectGroups.map(({ name, id }) => (
                <Option value={id} key={id}>
                  {name}
                </Option>
              ))}
            </Select>
            <Button
              size="large"
              type="primary"
              loading={loadingAdd}
              onClick={addGroupToSupergroup}
              disabled={!selectedGroup || loadingAdd}
            >
              {strings.ADD}
            </Button>
          </SelectContainer>
          <GroupItems>
            {groups.map(({ name, id }) => (
              <GroupItem key={id}>
                <GroupLink>
                  <Link to={`/group/${id}`}>{name}</Link>
                </GroupLink>
                <Button
                  icon={<DeleteOutlined />}
                  danger
                  size="small"
                  loading={deleteIds.includes(id)}
                  disabled={deleteIds.includes(id)}
                  onClick={() => deleteGroup(id)}
                />
              </GroupItem>
            ))}
            {!loading && groups.length === 0 && <Empty description={strings.NO_GROUPS} />}
          </GroupItems>
        </SupergroupContainer>
      </Spin>
    </Container>
  );
};

export default { SupergroupFull };
