import React, { useEffect, useState } from 'react';
import { Table, Modal } from 'antd';
import { Loader } from 'Global/components/Loader/Loader';
import { getColumns } from './columns';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { useTrainingsRepository } from 'repos/TrainingsRepository';
import { ITeacherTraining } from 'types/education';
import { useUserContext } from 'contexts/UserContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useHistory } from 'react-router-dom';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Column } = Table;

interface TeacherTrainingProps {
  teacherId: number;
}

/**
 * @description Тренинги учителя
 * @param {TrainingProps} props
 * @return {React.ReactNode}
 */
export const Training = (props: TeacherTrainingProps) => {
  const [user] = useUserContext();
  const { goBack } = useHistory();
  const [strings] = useLanguageContext();
  const teacherId = user?.meTeacherId;
  const trainingsRepository = useTrainingsRepository();
  const [training, setTraining] = useState<ITeacherTraining[]>([]);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [{ confirm }, contextHolder] = Modal.useModal();

  /**
   * @description Получение списка тренингов учителя
   * @return {Promise<any>}
   */
  const fetchTeacherTraining = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await trainingsRepository.getTeacherTraining(teacherId);
      setTraining(data);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  /**
   * @description Edit teacher training mark (обязательный/необязательный, пройден/не пройден)
   * @param {number} teacherId
   * @param {number} trainingId
   * @param { essential?: boolean; done?: boolean } params
   * @return {Promise<any>}
   */
  const editTeacherTrainingMark = async (training: ITeacherTraining, value: boolean): Promise<any> => {
    const {
      training: { id, name }
    } = training;

    const markParam = { done: value };
    const text = value ? 'finished' : 'not finished';

    confirm({
      title: `Do you want to mark training "${name}" as "${text}"?`,
      async onOk() {
        try {
          setHasLoading(true);
          await trainingsRepository.markTrainingDoneByTeacher(id, markParam);
          fetchTeacherTraining();
        } catch {
        } finally {
          setHasLoading(false);
          Modal.destroyAll();
        }
      }
    });
  };

  useEffect(() => {
    if (teacherId) {
      fetchTeacherTraining();
    }
  }, [teacherId]);

  return (
    <ErrorBoundary>
      {contextHolder}
      <Loader size="small" spinning={hasLoading}>
        <Container>
          <StPageHeader onBack={goBack} title={strings.EDUCATION} />
          <Table dataSource={training} pagination={false}>
            {getColumns(editTeacherTrainingMark).map(col => (
              <Column key={col.key} {...col} />
            ))}
          </Table>
        </Container>
      </Loader>
    </ErrorBoundary>
  );
};

export default { Training };
