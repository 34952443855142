import React, { useState } from 'react';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { Modal, Input, message as messageAnt } from 'antd';
import { getAllFormData } from 'helpers';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useSupportRepository } from 'repos/SupportRepository';
import { useLocation } from 'react-router-dom';

interface ModalFeedbackProps {
  visible: boolean;
  title: string;
  setHasShowModalFeedback: (bool: boolean) => void;
}

/**
 * @description Feedback about the lesson from the original course
 * @param {ModalFeedbackProps} props
 * @return {React.ReactNode}
 */
export const ModalFeedback = (props: ModalFeedbackProps) => {
  const [disabledBtn, setDisabledBtn] = useState<boolean>(false);
  const [feedbackMessage, setFeedbackMessage] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const { visible, setHasShowModalFeedback, title } = props;
  const [strings] = useLanguageContext();
  const supportRepo = useSupportRepository();

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (error) {
      setError(false);
    }
    setFeedbackMessage(e.target.value);
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();

    if (!feedbackMessage.length) {
      setError(true);
      return;
    }

    const params = {
      subject: `${strings.NEW_FEEDBACK_ABOUT_THE_LESSON} "${title}"`,
      message: feedbackMessage,
      origin_url: window.location.href
    };

    setDisabledBtn(true);
    try {
      await supportRepo.sendFeedBack(getAllFormData(params));
      setFeedbackMessage('');
      setHasShowModalFeedback(false);
      messageAnt.success(strings.FEEDBACK_SENT_SUCCESSFULLY);
    } finally {
      setDisabledBtn(false);
    }
  };

  return (
    (<Modal
      title={strings.FEEDBACK_ABOUT_ORIGINAL_COURSE}
      open={visible}
      onCancel={() => setHasShowModalFeedback(false)}
      okText="Send"
      okButtonProps={{ disabled: disabledBtn, loading: disabledBtn }}
      onOk={handleSubmit}
    >
      <Input.TextArea
        style={{ height: 220, borderColor: error ? 'red' : '#d9d9d9' }}
        value={feedbackMessage}
        onChange={handleChange}
      />
      {error && (
        <p>
          <InfoCircleTwoTone twoToneColor="#eb2f96" style={{ marginInlineEnd: 6 }} />
          <span>{strings.PLEASE_FILL}</span>
        </p>
      )}
    </Modal>)
  );
};

export default { ModalFeedback };
