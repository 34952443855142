import React, { useCallback, useState } from 'react';
import { Button, Col, message, Row, Spin, Tag, Form, Flex } from 'antd';
import { Participant } from 'types/Schedule';
import { uniqBy } from 'lodash';
import { Courses } from 'api';
import { Child } from 'types';
import { useLanguageContext } from 'contexts/LanguageContext';
import { blockStyles, FormItemWithoutMargin } from 'Global/styles';
import { CourseGroupSelect, UserDataSelect } from 'Global/components/FormComponents';

interface ParticipantsFieldsProps {
  setEventParticipants: (items: Participant[]) => void;
  eventParticipants: Participant[];
  selectedBranch: number;
}

/**
 * @description Добавление участников
 * @param {object} props
 * @return {React.ReactNode}
 */
export const ParticipantsFields = React.memo(({ eventParticipants, setEventParticipants, selectedBranch }: ParticipantsFieldsProps) => {
  const [form] = Form.useForm();
  const [hasLoadingParticipants, setHasLoadingParticipants] = useState<boolean>(false);
  const [strings] = useLanguageContext();

  const handleAddParticipantsFromUsers = useCallback(() => {
    const guests = form.getFieldValue('guests') || [];
    const updatedParticipants = uniqBy([...eventParticipants, ...guests], 'id');
    setEventParticipants(updatedParticipants);
    form.resetFields(['guests']);
  }, [form, eventParticipants, setEventParticipants]);

  const handleAddStudentsFromGroup = useCallback(async () => {
    const group = form.getFieldValue('group');
    setHasLoadingParticipants(true);
    try {
      const { status, data } = await Courses.getStudentsByGroup(group, true);
      if (status === 200) {
        const newParticipants = data
          .map(group => ({ ...group.student, type: 'student' }))
          .filter((student: Child) => student.name || student.surname);
        setEventParticipants(uniqBy([...eventParticipants, ...newParticipants], 'id'));
        form.resetFields(['group']);
      }
    } catch {
      message.error('Error adding students', 2);
    } finally {
      setHasLoadingParticipants(false);
    }
  }, [form, eventParticipants, setEventParticipants]);

  const handleDeleteParticipant = useCallback(
    (userId: number) => {
      setEventParticipants(eventParticipants.filter(participant => participant.id !== userId));
    },
    [eventParticipants, setEventParticipants]
  );

  return (
    <Form form={form} layout="vertical">
      <Row gutter={[10, 10]}>
        <Col lg={12} xs={24}>
          <FormItemWithoutMargin label={strings.ADD_PARTICIPANTS_FROM_USERS}>
            <Flex gap={5}>
              <FormItemWithoutMargin name="guests" style={{ flex: 1 }}>
                <UserDataSelect mode="multiple" placeholder={strings.GUESTS} />
              </FormItemWithoutMargin>
              <Button size="large" type="primary" onClick={handleAddParticipantsFromUsers}>
                {strings.ADD}
              </Button>
            </Flex>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={12} xs={24}>
          <FormItemWithoutMargin label={strings.ADD_PARTICIPANTS_FROM_GROUP}>
            <Flex gap={5}>
              <FormItemWithoutMargin name="group" style={{ flex: 1 }}>
                <CourseGroupSelect placeholder={strings.GROUPS} branch={selectedBranch} />
              </FormItemWithoutMargin>
              <Button size="large" type="primary" onClick={handleAddStudentsFromGroup}>
                {strings.ADD}
              </Button>
            </Flex>
          </FormItemWithoutMargin>
        </Col>
        <Col lg={24} xs={24}>
          <Spin spinning={hasLoadingParticipants}>
            <Flex style={blockStyles} gap={5} wrap="wrap">
              {eventParticipants.map((participant: Participant) => (
                <Tag key={participant.id} color="blue" closable onClose={() => handleDeleteParticipant(participant.id)}>
                  ({participant.type}) {participant?.user ? participant?.user?.name : participant?.name}{' '}
                  {participant?.user ? participant?.user?.surname : participant?.surname}
                </Tag>
              ))}
            </Flex>
          </Spin>
        </Col>
      </Row>
    </Form>
  );
});
export default ParticipantsFields;
