import React, { Fragment } from 'react';
import { Message } from './Message';
import { Branch, IParent } from 'types';
import { ThreadMessage, ThreadsParams, IThread } from 'types/CRM/messages';
import { Link } from 'react-router-dom';
import { BASE_MESSAGES_FROM_PARENTS } from '../routes';
import { Flex, Tag } from 'antd';
import { MessageRole } from './styles';

export const columns = (getThreads: (params?: ThreadsParams) => Promise<any>, current: number, strings) => [
  {
    title: strings.LINK,
    dataIndex: 'id',
    key: 'id',
    className: 'clmn',
    width: 100,
    render: (id: number) => <Link to={`${BASE_MESSAGES_FROM_PARENTS}/${id}`}>{id}</Link>
  },
  {
    title: strings.PARTICIPANTS,
    dataIndex: 'parent',
    key: 'parent',
    className: 'clmn',
    width: 200,
    render: (parent: IParent, { teacher }: IThread) => {
      return (
        <>
          <Flex gap={10} align="center" style={{ whiteSpace: 'nowrap' }}>
            {`${parent?.user?.name ?? ''} ${parent?.user?.surname ?? ''}`}
            <MessageRole>parent</MessageRole>
          </Flex>
          <Flex gap={10} align="center" style={{ whiteSpace: 'nowrap' }}>
            {`${teacher?.user?.name ?? ''} ${teacher?.user?.surname ?? ''}`}
            <MessageRole>teacher</MessageRole>
          </Flex>
        </>
      );
    }
  },
  {
    title: strings.MESSAGES,
    dataIndex: 'messages',
    key: 'messages',
    className: 'clmn',
    width: 400,
    render: (messages: ThreadMessage[], thread: IThread) => (
      <Fragment>
        {messages.map((message: ThreadMessage) => (
          <Message
            messageInfo={message}
            key={message.id}
            getThreads={getThreads}
            branchId={thread.branch.id}
            currentPage={current}
          />
        ))}
      </Fragment>
    )
  },
  {
    title: strings.NOT_APPROVED,
    dataIndex: 'notApprovedMessagesCount',
    key: 'notApprovedMessagesCount',
    className: 'clmn-center',
    width: 80
  },
  {
    title: strings.BRANCH,
    dataIndex: 'branch',
    key: 'branch',
    className: 'clmn',
    width: 80,
    render: (branch: Branch) => branch?.name
  }
];

export default { columns };
