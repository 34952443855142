/**
 * @description Возвращает названиие файла из хедера content-disposition
 * @param {string} disposition
 * @return {string}
 */
export const dispositionFilename = (disposition: string): string => {
  const utf8FilenameRegex = /filename\*=utf-8''([\w%\-\.]+)(?:; ?|$)/i;
  const asciiFilenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

  if (disposition?.indexOf('attachment') !== -1) {
    const utf8Matches = utf8FilenameRegex.exec(disposition);
    const asciiMatches = asciiFilenameRegex.exec(disposition);

    if (utf8Matches && utf8Matches[1]) {
      return decodeURIComponent(utf8Matches[1].replace(/['"]/g, ''));
    }

    if (asciiMatches && asciiMatches[1]) {
      return asciiMatches[1].replace(/['"]/g, '');
    }
  }

  return '';
};

/**
 * @description Раскодирование имя файла из content disposition
 * @param {string} disposition
 * @return {string}
 */
export const decodeDispositionFilename = (disposition: string): string => {
  const splitDisposition = disposition.split("utf-8''")[1];
  const decodeDisposition = decodeURIComponent(splitDisposition);

  return decodeDisposition;
};

export default { dispositionFilename, decodeDispositionFilename };
