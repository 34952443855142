import React, { useEffect, useState } from 'react';
import { Button, Row } from 'antd';
import { AdminFilter } from 'Admin/People/Administrators/Filter';
import { ADMIN_ADD } from 'Admin/People/Administrators/routes';
import { Link } from 'react-router-dom';
import { useAdminRepository } from 'repos/AdminRepository';
import { PaginationInfo } from 'types/global';
import { mockPagination, QUICK_TIPS_NEW_ACCOUNT } from 'Global/constants';
import { useHistory } from 'react-router-dom';
import { useLanguageContext } from 'contexts/LanguageContext';
import { QuickTips } from 'Global/components/QuickTips';
import { useUserContext } from 'contexts/UserContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import queryString from 'query-string';
import { AdminsTable } from 'Global/components/Tables/Admins';
import Pagination from 'Global/components/Pagination';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { isEmpty } from 'lodash';
import { AdminGetParams } from 'types/user';
import Loader from "../../../Global/components/Loader/Loader";

export const Administrators = () => {
  const [strings] = useLanguageContext();
  const [admins, setAdmins] = useState([]);
  const [hasLoadAdmins, setHasLoadAdmins] = useState<boolean>(false);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>(mockPagination);
  const { push, goBack } = useHistory();
  const adminRepository = useAdminRepository();
  const defValues = { limit: 50, isActive: 1, page: 1 };

  const { pageCount, current } = paginationInfo || {};

  const { locationSearchParams } = useLocationSearchParams();
  const [user] = useUserContext();
  const hasRiga = user?.branch?.id === 5;

  const { totalCount } = paginationInfo || {};

  const getAllAdmins = async (params?: AdminGetParams) => {
    try {
      setHasLoadAdmins(true);
      params['limit'] = 50;
      const {
        data: { admins, paginationInfo }
      } = await adminRepository.getAllAdmins(params);
      setAdmins(admins);
      setPaginationInfo(paginationInfo);
      setHasLoadAdmins(false);
      push({ search: queryString.stringify(params, { arrayFormat: 'bracket' }) });
    } catch {}
  };

  const getSubtitle = () => {
    if (totalCount) {
      return `${strings.TOTAL_NUMBER_OF_ADMINISTRATORS} ${totalCount}`;
    }

    return null;
  };

  const handleUserRefetch = (): void => {
    getAllAdmins(locationSearchParams);
  };

  const handleChangePage = (page: number) => {
    getAllAdmins({ ...locationSearchParams, page });
  };

  useEffect(() => {
    if (isEmpty(locationSearchParams)) {
      getAllAdmins(defValues);
    } else {
      getAllAdmins(locationSearchParams);
    }
  }, []);

  return (
    <Container>
      <StPageHeader
        onBack={() => goBack()}
        title={strings.ADMINISTRATORS}
        subTitle={getSubtitle()}
        extra={[
          <Button type="primary">
            <Link to={ADMIN_ADD}>{strings.ADD_NEW_ADMIN}</Link>
          </Button>,
          !hasRiga && <QuickTips label={strings.QUICK_TIPS} url={QUICK_TIPS_NEW_ACCOUNT} />
        ]}
      >
        <AdminFilter getAllAdmins={getAllAdmins} />
      </StPageHeader>
      <Row gutter={[10, 10]} style={{ overflow: 'auto' }}>
        <Loader spinning={hasLoadAdmins}>
          <AdminsTable handleUserRefetch={handleUserRefetch} admins={admins} pagination={false} isBorder={false} />
          <Pagination count={pageCount} current={current} pageChange={handleChangePage} />
        </Loader>
      </Row>
    </Container>
  );
};

export default { Administrators };
