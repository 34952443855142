import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { PageHeader } from '@ant-design/pro-layout';
import { Input, Skeleton, Row, Col, Button, message, Alert, Descriptions, Tag, Select } from 'antd';
import { useRouteMatch, useHistory, Link, useLocation } from 'react-router-dom';
import { match } from 'react-router';
import { useAssistantFeedbackRepository } from 'repos/AssistantsFeedbackRepository';
import { AssistantCommentInterface } from './types';
import { findIndex } from 'lodash';
import { StyledStudentList } from './styles';
import { useUserContext } from 'contexts/UserContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import Breadcrumbs from 'Global/components/Breadcrumbs';
import { Container } from 'Global/GlobalStyle';
import styled from 'styled-components';
import { AlertCollapse } from './AlertCollapse';
import AvatarWithUserName from 'Global/components/AvatarWithUserName';
import { METAGOALS_FOR_TEACHERS } from 'Global/constants';
import {BASE_ASSISTANTS_FEEDBACK} from "../../../Admin/Monitoring/routes";
const { Option } = Select;

const StPageHeaderAlert = styled.span`
  color: #cc1e44;
  font-weight: 600;
`;

const getComment = (comment, fallback) => (comment && comment.length > 0 ? comment : fallback);

const StudentList = ({ rows, handleRowChange, canEdit }) => {
  const [strings] = useLanguageContext();
  return (
    <StyledStudentList>
      {canEdit && (
        <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
          <Col lg={24}>
            <Alert
              message={
                <AlertCollapse
                  title={strings.ACADEMIC_AND_META_SUBJECT_ACHIEVEMENTS_IF_IT_CAN_BE_CAPTURED}
                  description={
                    <>
                      {`${strings.WHAT_THE_CHILD_COULD_DO_AND_HAD_KNOWN_BEFORE_AND_WHAT_HE_KNOWS_NOW_IN_ENGLISH_AND_IN_OTHER_SUBJECTS_FOR_EXAMPLE_THE_CHILD_DID_NOT_KNOW_HOW_TO_READ_IN_ENGLISH_IN_GENERAL_BUT_NOW_HE_READS_SEPARATE_WORDS} ${strings.OR_BEFORE_HE_COULD_NOT_PERFORM_ALONE_ON_STAGE_AT_A_DRAMA_HE_WAS_EMBARRASSED_BUT_NOW_HE_CAN}`}{' '}
                      <Link to={{ pathname: METAGOALS_FOR_TEACHERS }} target="_blank">
                        {strings.METAGOALS_FOR_TEACHERS}
                      </Link>
                    </>
                  }
                />
              }
              type="info"
              showIcon
              style={{ marginBottom: 10 }}
            />
          </Col>
          <Col lg={24}>
            <Alert
              message={
                <AlertCollapse
                  title={strings.SATISFACTION_OF_THE_CHILD}
                  description={`${strings.THE_STUDENTS_EXPERIENCE_AT_SCHOOL_IN_CAMP_WITH_US_THIS_IS_A_MULTIPART_COMPONENT_IT_IS_ABOUT_FRIENDSHIP_AND_UNDERSTANDING_WHAT_IS_HAPPENING_SOCIALIZATION_RELATIONSHIPS_IN_THE_GROUP} ${strings.THE_RELATIONSHIP_WITH_THE_TEACHER_AND_WITH_THE_ASSISTANT_AND_BASIC_STUDENT_IS_HAPPY_STUDENT_SMILES_OFTEN_OR_FEELS_SAD_DOES_NOT_WANT_ANYTHING}`}
                />
              }
              type="info"
              showIcon
              style={{ marginBottom: 10 }}
            />
          </Col>
        </Row>
      )}
      {rows?.map(({ id, student, comment }) => (
        <Row gutter={[10, 10]} align="middle" style={{ marginBottom: 20 }}>
          <Col lg={6}>
            <AvatarWithUserName
              name={student?.user?.name}
              surname={student?.user?.surname}
              link={`/family/${student?.familyId}/student/${student?.id}`}
              photo={student?.mainPhoto}
              size={55}
            />
          </Col>
          <Col lg={18}>
            {canEdit ? (
              <Input.TextArea
                defaultValue={comment}
                onChange={({ target: { value } }) => handleRowChange(id, value)}
                style={{ height: 100 }}
                placeholder={`${strings.ENTER_COMMENT_FOR} ${student?.user?.name}`}
              />
            ) : (
              <p style={{ margin: 0 }}>{getComment(comment, strings.NO_COMMENT_YET)}</p>
            )}
          </Col>
        </Row>
      ))}
    </StyledStudentList>
  );
};

export const EditAssistantFeedback = () => {
  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();
  const [strings] = useLanguageContext();
  const { push } = useHistory();
  const [user] = useUserContext();
  const location = useLocation();
  const assistantFeedbackRepo = useAssistantFeedbackRepository();
  const [tags, setTags] = useState<{ id: number; name: string }[]>([]);
  const [selectedTag, setSelectedTag] = useState<number>();
  const [assistantComment, setAssistantComment] = useState<AssistantCommentInterface>(null);
  const [hasLoadAssistantComment, setHasLoadAssistantComment] = useState<boolean>(false);
  const [generalComment, setGeneralComment] = useState<string>(null);
  const [studentRows, setStudentRows] = useState([]);
  const [hasLoadSave, setHasLoadSave] = useState<boolean>(false);

  const date = assistantComment?.date?.split(' ')[0];
  const assistant = `${assistantComment?.assistant?.user?.name} ${assistantComment?.assistant?.user?.surname}`;
  const title = `${assistantComment?.supergroup?.name} ${date} ${assistant}`;
  const { rows, canEdit, tag } = assistantComment || {};

  const handleSubmit = async () => {
    try {
      setHasLoadSave(true);
      const data = { generalComment: generalComment, rows: studentRows, tag: selectedTag };
      await assistantFeedbackRepo.editAssistantCommentReport(id, data);
      message.success(strings.FEEDBACK_SUCCESSFULLY_UPDATED, 2);
      push(BASE_ASSISTANTS_FEEDBACK);
      setHasLoadSave(false);
    } catch {
      setHasLoadSave(false);
    }
  };

  const getAssistantTags = async (): Promise<any> => {
    try {
      const { data } = await assistantFeedbackRepo.getAssistantCommentTags();
      setTags(data);
    } catch {}
  };

  const handleRowChange = (rowId: number, value: string) => {
    const index = findIndex(studentRows, r => r.id === rowId);
    const newRows = [...studentRows];
    newRows[index].comment = value;
    setStudentRows(newRows);
  };

  const handleChange = ({ target }) => setGeneralComment(target.value);

  useEffect(() => {
    (async () => {
      setHasLoadAssistantComment(true);
      const { data } = await assistantFeedbackRepo.getAssistantCommentsById(id);
      setAssistantComment(data);
      setHasLoadAssistantComment(false);
      setStudentRows(data.rows.map(row => ({ id: row.id, comment: row?.comment })));
      setGeneralComment(data.generalComment);
      setSelectedTag(data.tag?.id);
    })();
    getAssistantTags();
  }, []);

  return (
    <Container>
      {!hasLoadAssistantComment && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      {hasLoadAssistantComment ? (
        Array.from(Array(4).keys()).map(i => <Skeleton paragraph={{ rows: 2 }} active key={i.toString()} />)
      ) : (
        <>
          <Breadcrumbs location={location} />
          <PageHeader
            title={user?.iAssistant ? strings.ADD_COMMENTS : strings.ASSISTANTS_COMMENTS}
            style={{ padding: 0, margin: '0 8px 15px' }}
            subTitle={<StPageHeaderAlert>{strings.THIS_FEEDBACK_IS_VISIBLE_TO_TEACHERS_AND_TUTOR}</StPageHeaderAlert>}
          />
          <Row>
            <Col lg={24}>
              <Descriptions>
                <Descriptions.Item label={strings.SUPERGROUP}>
                  {canEdit ? (
                    assistantComment?.supergroup?.name
                  ) : (
                    <Link to={`/supergroup/${assistantComment?.supergroup?.id}`} target="_blank">
                      {assistantComment?.supergroup?.name}
                    </Link>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label={strings.DATE}>{date}</Descriptions.Item>
                <Descriptions.Item label={strings.ASSISTANT}>
                  {canEdit ? (
                    assistant
                  ) : (
                    <Link to={`/teachers/${assistantComment?.assistant?.id}`} target="_blank">
                      {assistant}
                    </Link>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label={strings.TAG}>
                  {canEdit ? (
                    <Select value={selectedTag} onChange={val => setSelectedTag(val)} style={{ width: 200 }}>
                      {tags.map(item => (
                        <Option value={item.id}>{item.name}</Option>
                      ))}
                    </Select>
                  ) : (
                    <>{tag ? <Tag>{tag?.name}</Tag> : '-'}</>
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col lg={24} xs={24}>
              {canEdit ? (
                <>
                  <Alert
                    message={
                      <AlertCollapse
                        title={strings.WHAT_EXTRAORDINARY_THING_HAPPENED_ON_THE_SHIFT_IF_ANY}
                        description={`${strings.IMPORTANT_REMARKS_AND_COMMENTS_ABOUT_THE_PHYSICAL_SAFETY_OF_CHILDREN_ABOUT_TEACHERS_AND_THEIR_LESSONS_ABOUT_THE_BEHAVIOR_OF_CHILDREN} 
                          ${strings.FOR_EXAMPLE_STRONG_DISAGREEMENTS_AND_QUARRELS_BETWEEN_CHILDREN_IN_THE_GROUP_SWEARING_SOMEONE_FROM_TEACHERS_SWITCHED_TO_RUSSIAN_RUDE_COMMUNICATION_AND_SO_ON}`}
                      />
                    }
                    type="info"
                    showIcon
                    style={{ marginBottom: 10 }}
                  />
                  <Input.TextArea
                    rows={4}
                    defaultValue={assistantComment?.generalComment}
                    placeholder={strings.GENERAL_COMMENT}
                    onChange={handleChange}
                  />
                </>
              ) : (
                <p>{getComment(assistantComment?.generalComment, strings.NO_GENERAL_COMMENT_YET)}</p>
              )}
            </Col>
          </Row>
          <StudentList canEdit={canEdit} rows={rows} handleRowChange={handleRowChange} />
          {canEdit && (
            <Button loading={hasLoadSave} type="primary" style={{ marginTop: 20 }} onClick={handleSubmit}>
              {strings.SAVE}
            </Button>
          )}
        </>
      )}
    </Container>
  );
};
