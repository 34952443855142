import React, { useEffect, useState } from 'react';
import { Select, Button, Form, Flex } from 'antd';
import styled, { css } from 'styled-components';
import dayjs from 'dayjs';
import { dateFormat } from 'helpers/dates';
import { ReportTrimester } from 'types/Reports';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Option } = Select;

interface IFilterDate {
  m?: boolean;
}

export const FilterDate = styled.span<IFilterDate>`
  font-size: 11px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-block;
  padding: 0 7px;
  ${props =>
    props.m &&
    css`
      margin: 0 5px;
    `}
`;

interface ProgressReportFilterProps {
  fetchProgressReportRows: (studentId: number, trimester?: number) => Promise<any>;
  studentId: number;
}

/**
 * @description Фильтр прогресс репорт
 * @param {ProgressReportFilterProps} props
 * @return {React.ReactNode}
 */
export const ProgressReportFilter = (props: ProgressReportFilterProps) => {
  const [strings] = useLanguageContext();
  const [trimesters, setTrimesters] = useState<ReportTrimester[]>([]);
  const [loadingTrimesters, setLoadingTrimesters] = useState<boolean>(false);
  const [selectedTrimester, setSelecterTrimester] = useState<number | null>(null);
  const { fetchProgressReportRows, studentId } = props;
  const reportsRepository = useProgressReportsRepository();

  /**
   * @description Список семестров
   * @return {Promise<any>}
   */
  const fetchTrimesters = async (): Promise<any> => {
    setLoadingTrimesters(true);
    await reportsRepository.getTrimesters().then(({ data }) => {
      setTrimesters(data);
      setLoadingTrimesters(false);
    });
  };

  // Очистить фильтр
  const handleClickReset = () => {
    if (selectedTrimester) {
      setSelecterTrimester(null);
      fetchProgressReportRows(studentId);
    }
  };

  const handleChange = (value: number) => setSelecterTrimester(value);

  const handleClick = () => {
    if (selectedTrimester) {
      fetchProgressReportRows(studentId, selectedTrimester);
    }
  };

  useEffect(() => {
    fetchTrimesters();
  }, []);

  return (
    <Flex gap={10}>
      <Form.Item label={strings.TRIMESTER}>
        <Select onChange={handleChange} loading={loadingTrimesters} style={{ width: 400 }}>
          <Option value="">&nbsp;</Option>
          {trimesters.map((trimester: ReportTrimester) => (
            <Option value={trimester.id} key={trimester.id}>
              {trimester.name}
              <FilterDate>{dayjs(trimester.startAt).format(dateFormat)}</FilterDate>
              <FilterDate>{dayjs(trimester.endAt).format(dateFormat)}</FilterDate>
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Button onClick={handleClickReset} disabled={!selectedTrimester} size="large">
        {strings.CLEAR_FILTER}
      </Button>
      <Button type="primary" onClick={handleClick} size="large">
        {strings.FILTER}
      </Button>
    </Flex>
  );
};

export default { ProgressReportFilter };
