import React from 'react';
import { Row, Col } from 'antd';
import { GetFieldDecoratorOptions } from '@ant-design/compatible/lib/form/Form';
import { TicketTypeSelect } from 'Global/modules/Support/Modal/TicketTypeSelect';
import { SeveritySelect } from 'Global/modules/Support/Modal/SeveritySelect';
import { BranchSelect } from 'Global/modules/Support/Modal/BranchSelect';
import { RoleSelect } from 'Global/modules/Support/Modal/RoleSelect';
import { useUserContext } from 'contexts/UserContext';

interface FeedbackTeacherProps {
  getFieldDecorator<T extends Object = {}>(
    id: keyof T,
    options?: GetFieldDecoratorOptions
  ): (node: React.ReactNode) => React.ReactNode;
}

/**
 * @description Форма отправки фидбэка для учителя
 * @param {object} props
 * @return {React.ReactNode}
 */
export const FeedbackTeacher = (props: FeedbackTeacherProps) => {
  const [user] = useUserContext();
  const { getFieldDecorator } = props;

  return (
    <Row gutter={[10, 10]}>
      <Col xs={24} lg={12}>
        <RoleSelect initialValue="Teacher" getFieldDecorator={getFieldDecorator} />
      </Col>
      <Col xs={24} lg={12}>
        <BranchSelect initialValue={user?.branch?.name} getFieldDecorator={getFieldDecorator} />
      </Col>
      <Col xs={24} lg={24}>
        <TicketTypeSelect getFieldDecorator={getFieldDecorator} />
      </Col>
      <Col xs={24} lg={24}>
        <SeveritySelect getFieldDecorator={getFieldDecorator} />
      </Col>
    </Row>
  );
};

export default { FeedbackTeacher };
