import React, { useState, useEffect } from 'react';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, Spin, Tag, Button, Row, Col } from 'antd';
import { Event, EventFile } from 'types/Schedule';
import { EventAttachments } from 'Global/modules/Schedule/SlotModals/SlotFields/Attachments';
import { useAttachmentRepository } from 'repos/AttachmentRepository';
import { useLanguageContext } from 'contexts/LanguageContext';
import ZoomIcon from 'img/zoom.png';

interface EventModalParentProps {
  values: Event;
  hideModal: () => void;
  hasLoadEvent: boolean;
  hasShow: boolean;
}

/**
 * @description Модальное окно события (для родителя и студента)
 * @param {EventModalParentProps} props
 * @return {React.ReactNode}
 */
export const EventModalParent = (props: EventModalParentProps) => {
  const [strings] = useLanguageContext();
  const attachmentRepository = useAttachmentRepository();
  const [attachmentList, setAttachmentList] = useState<EventFile[]>([]);
  const { hasShow, hideModal, hasLoadEvent, values } = props;
  const {
    name,
    stage,
    slot: { room },
    product,
    master,
    id
  } = values || {};

  /**
   * @description Получение вложений к эвенту
   * @return {Promise<any>}
   */
  const getEventAttachments = async (): Promise<any> => {
    try {
      const {
        data: { list }
      } = await attachmentRepository.getAttachmentList({
        id: id,
        type: 'eventFiles'
      });
      setAttachmentList(list);
    } catch {}
  };

  useEffect(() => {
    if (id !== 0) {
      getEventAttachments();
    }
  }, [id]); // eslint-disable-line

  return (
    (<Modal
      title={name || strings.EVENT}
      open={hasShow}
      onCancel={hideModal}
      footer={[<Button onClick={hideModal}>{strings.CANCEL}</Button>]}
    >
      <Spin tip={strings.LOADING} spinning={hasLoadEvent}>
        <Row>
          {name && (
            <Col lg={12}>
              <MyFormItem label={strings.NAME}>{name}</MyFormItem>
            </Col>
          )}
          {stage && (
            <Col lg={12}>
              <MyFormItem label={strings.STAGE}>{stage?.name}</MyFormItem>
            </Col>
          )}
          {room?.name && (
            <Col lg={12}>
              <MyFormItem label={strings.PLACE}>{room?.name}</MyFormItem>
            </Col>
          )}
          {room?.webLogin && (
            <Col lg={12}>
              <MyFormItem label={strings.ZOOM_LOGIN}>
                <p>{room?.webLogin}</p>
              </MyFormItem>
            </Col>
          )}
          {room?.webPassword && (
            <Col lg={12}>
              <MyFormItem label={strings.ZOOM_PASSWORD}>
                <p>{room?.webPassword}</p>
              </MyFormItem>
            </Col>
          )}
          {room?.webLink && (
            <Col lg={12}>
              <MyFormItem label={strings.LINK}>
                <a href={room?.webLink} rel="noopener noreferrer" target="_blank">
                  <img src={ZoomIcon} alt="zoom link" width={28} height={28} />
                  <span style={{ marginInlineStart: 10 }}>{strings.GO_TO_ZOOM}</span>
                </a>
              </MyFormItem>
            </Col>
          )}
          {product && (
            <Col lg={12}>
              <MyFormItem label={strings.PRODUCT}>{product?.name}</MyFormItem>
            </Col>
          )}
          {master && (
            <Col lg={12}>
              <MyFormItem label={strings.CONDUCTOR}>
                {master?.name} {master?.surname}
                {master.type && <Tag style={{ marginInlineStart: 4 }}>{master.type}</Tag>}
              </MyFormItem>
            </Col>
          )}
          <Col lg={24}>
            <MyFormItem label={strings.FILES}>
              {attachmentList?.isNotEmpty() ? (
                <EventAttachments attachmentList={attachmentList} />
              ) : (
                <p>{strings.NO_FILES}</p>
              )}
            </MyFormItem>
          </Col>
        </Row>
      </Spin>
    </Modal>)
  );
};

export default { EventModalParent };
