import React, { Fragment } from 'react';
import { InvoicesProps } from 'Admin/People/Families/Family/Invoices/types';
import { ManuaAccountBlock } from 'Admin/People/Families/Family/Invoices/ManuaAccountBlock';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { InvoiceMonthFinanceSystem } from './InvoiceMonthFinanceSystem';

/**
 * @description Вкладка Invoices
 * @param {Object} props
 * @return {React.ReactNode}
 */
export const Invoices = (props: InvoicesProps) => {
  const { branch, students, parents, id, manualBlock } = props;

  const getInterfaceByBranchAndFinanceSystem = () => {
    if (branch?.invoiceMonthFinanceSystem) {
      return <InvoiceMonthFinanceSystem parents={parents} id={id} branch={branch} children={students} />;
    }

    return null;
  };

  return (
    <ErrorBoundary>
      <Fragment>
        {getInterfaceByBranchAndFinanceSystem()}
        <ManuaAccountBlock familyId={id} manualBlock={manualBlock} />
      </Fragment>
    </ErrorBoundary>
  );
};

export default { Invoices };
