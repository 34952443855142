import React, { useCallback, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { Table } from 'antd';
import { capitalize } from 'lodash';
import { MetaGoalItem } from 'types/courses';
import { InClassTitle } from './RegisterColumns/RegisterInClass';
import { getStudentColumn } from './RegisterColumns/RegisterStudent';
import { getAttendanceColumn } from './RegisterColumns/RegisterPresence';
import { getHomeworkDoneColumn } from './RegisterColumns/RegisterHomeworkDone';
import { getHomeworkGradeColumn } from './RegisterColumns/RegisterHomeworkGrade';
import { getHomeworkUploadColumn } from './RegisterColumns/RegisterHomeworkUpload';
import { getHomeworkStudentColumn } from './RegisterColumns/RegisterHomeworkStudent';
import { getFeedbackForParentColumn } from './RegisterColumns/RegisterFeedbackForParent';
import { getFeedbackForSupervisorColumn } from './RegisterColumns/RegisterFeedbackForSupervisor';
import { getTaskPercentColumn } from './RegisterColumns/RegisterTestPercent';
import { useLanguageContext } from 'contexts/LanguageContext';
import { changeFieldHwMarkGrade } from '../helpers';
import { ILessonRegister } from 'types/lesson';
import { useLessonRegisterStore } from 'stores/useLessonRegisterStore';
import { StGeneralTitle } from '../LessonRegister/styles';
import { useLessonInfoStore } from 'stores/useLessonInfoStore';

interface RegisterTableProps {
  lessonId: number | string;
  setHasShowUploadHomework: (hasShow: boolean) => void;
  setHasShowTestModal: (hasShow: boolean) => void;
  lessonsMetaGoals: MetaGoalItem[];
}

export const RegisterTable = React.memo(
  ({ lessonId, setHasShowUploadHomework, setHasShowTestModal, lessonsMetaGoals }: RegisterTableProps) => {
    const [strings, lang] = useLanguageContext();
    const lessonInfo = useLessonInfoStore(state => state.lessonInfo);
    const registers = useLessonRegisterStore(state => state.registers);
    const registerColumns = useLessonRegisterStore(state => state.registerColumns);
    const maxTaskGrade = useLessonRegisterStore(state => state.maxTaskGrade);
    const maxHwGrade = useLessonRegisterStore(state => state.maxHwGrade);
    const setMaxHwGrade = useLessonRegisterStore(state => state.setMaxHwGrade);
    const updateSingleRegister = useLessonRegisterStore(state => state.updateSingleRegister);
    const hwButtons = useLessonRegisterStore(state => state.extraRegisterData?.hwButtons);
    const homeworkRequiredForCurrentLesson = useLessonRegisterStore(
      state => state.extraRegisterData?.homeworkRequiredForCurrentLesson
    );

    /**
     * @description Редактирование полей register в стейте для одного студента
     * @param {string} field
     * @param {number} studentId
     * @param {any} value
     * @return {void}
     */
    const handleSetFieldByChildId = useCallback(
      (field: string, studentId: number, value: unknown): void => {
        if (field === 'taskGrade') {
          updateSingleRegister(studentId, 'taskGrade', Number(value));
          updateSingleRegister(studentId, 'taskPercent', Math.round((100 / Number(maxTaskGrade)) * Number(value)));
        } else if (field === 'homeworkMarkGrade') {
          const fieldHwMarkGrade = changeFieldHwMarkGrade(Number(value), maxHwGrade);
          Object.entries(fieldHwMarkGrade).forEach(([key, val]) => {
            updateSingleRegister(studentId, key, val);
          });
        } else if (field === 'presence' && value === false) {
          updateSingleRegister(studentId, 'presence', false);
          updateSingleRegister(studentId, 'homeworkDone', false);
        } else {
          updateSingleRegister(studentId, field, value);
        }
      },
      [maxTaskGrade, maxHwGrade, updateSingleRegister]
    );

    const renderHomeworkTitle = useCallback(() => {
      if (!homeworkRequiredForCurrentLesson) {
        return strings.HOMEWORK_WAS_NOT_ASSIGNED;
      }
      if (lessonInfo?.prevLesson) {
        const date = capitalize(dayjs(lessonInfo?.prevLesson?.startAt).locale(lang).format('MMM DD'));
        return `${strings.HOMEWORK_ASSIGNED_ON} ${date}`;
      }
      return null;
    }, [lessonInfo?.prevLesson?.startAt]);

    const inClassColumns = useMemo(
      () => [
        {
          title: () => <InClassTitle setHasShowTestModal={setHasShowTestModal} />,
          order: 1,
          children: [
            getStudentColumn(strings),
            getAttendanceColumn(
              handleSetFieldByChildId,
              !registerColumns.includes('taskPercent') ? 'table-border-right' : null,
              strings
            ),
            registerColumns.includes('taskPercent') ? getTaskPercentColumn(handleSetFieldByChildId) : null
          ].filter(Boolean)
        }
      ],
      [registerColumns, handleSetFieldByChildId, setHasShowTestModal]
    );

    const homeworkColumns = useMemo(
      () => [
        {
          title: () => <StGeneralTitle>{renderHomeworkTitle()}</StGeneralTitle>,
          order: 2,
          children: [
            registerColumns.includes('homeworkDone') && hwButtons
              ? getHomeworkDoneColumn(strings, hwButtons, handleSetFieldByChildId)
              : null,
            registerColumns.includes('homeworkGrade') ? getHomeworkGradeColumn(handleSetFieldByChildId) : null,
            getHomeworkStudentColumn(lessonId),
            getHomeworkUploadColumn(setHasShowUploadHomework, lessonId)
          ].filter(Boolean)
        }
      ],
      [
        registerColumns,
        hwButtons,
        maxHwGrade,
        handleSetFieldByChildId,
        setHasShowUploadHomework,
        lessonId,
        renderHomeworkTitle
      ]
    );

    const feedbackColumns = useMemo(
      () => [
        {
          title: <StGeneralTitle>{strings.FEEDBACK}</StGeneralTitle>,
          order: 3,
          children: [
            getFeedbackForParentColumn(registerColumns, strings, lessonsMetaGoals),
            getFeedbackForSupervisorColumn(registerColumns, strings)
          ].filter(Boolean)
        }
      ],
      [registerColumns, lessonsMetaGoals]
    );

    const columns = useMemo(() => {
      if (!inClassColumns || !homeworkColumns || !feedbackColumns) return [];
      return [...inClassColumns, ...homeworkColumns, ...feedbackColumns];
    }, [inClassColumns, homeworkColumns, feedbackColumns]);

    return (
      <Table
        dataSource={registers}
        columns={columns}
        rowKey={(record: any) => record.student.id}
        pagination={false}
        className="table-head-center"
        rowClassName={(register: ILessonRegister) =>
          (register.needFeedbackForParents || register.needFeedbackForSupervisor) && 'table-row-height'
        }
      />
    );
  }
);
