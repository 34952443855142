import React from 'react';
import { EyeOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { BASE_SMS } from '../routes';
import dayjs from 'dayjs';
import { dateTimeFormat } from 'helpers/dates';

export const columns = strings => [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: strings.SMS_COUNT,
    dataIndex: 'status',
    key: 'status',
    width: 100,
    render: (status, sms) => sms && `${sms.Send.length}`
  },
  {
    title: strings.STATUS,
    dataIndex: 'status',
    key: 'status',
    width: 150,
    render: (status, sms) => {
      if (sms) {
        // Если кол-во смсок одно, то просто выводим статус
        if (sms.Send.length === 1) {
          const status = sms.Send[0]?.status;

          // Все статусы кроме success выводим в красном цвете
          if (status === 'success') {
            return <Tag color="green">{strings.SUCCESS}</Tag>;
          } else {
            return <Tag color="red">{status}</Tag>;
          }
        } else {
          // Если кол-во смсок больше 1, то выводим их кол-во
          const successSessionsCount = sms.Send.filter(sms => sms.status === 'success').length;
          return (
            <>
              {successSessionsCount} - <Tag color="green">{strings.SUCCESS}</Tag>
            </>
          );
        }
      }

      return null;
    }
  },
  {
    title: strings.DATE,
    dataIndex: 'created_at',
    key: 'date',
    render: date => date && dayjs(date).format(dateTimeFormat)
  },
  {
    title: strings.MESSAGE,
    dataIndex: 'Send',
    key: 'send',
    render: send => send[0]?.text
  },
  {
    title: strings.ACTIONS,
    dataIndex: 'actions',
    key: 'actions',
    render: (actions, sms) => (
      <Link to={`${BASE_SMS}/${sms.id}`}>
        <Button type="primary" icon={<EyeOutlined />} />
      </Link>
    )
  }
];

export default { columns };
