import { Teacher } from '../Teachers/teacher';
import { Group, Lesson } from '../education';
import { Admin, Branch, IStudent, IUserData, Supergroup } from 'types/index';
import { MetaGoalItem } from '../courses';
import { MetaGrade } from '../TeacherAccount/Courses';

export interface ReportStatus {
  value: string;
  text: string;
}

export interface ProgressReport {
  id: number;
  trimester: ReportTrimester;
  teacher: Teacher;
  group: Group;
  status: string;
  name: string;
  active: boolean;
  whatWasLearned?: string;
  rows?: ReportRow[];
  canDelete: boolean;
  updatedAt: string;
  updatedBy: {
    admin: Admin;
    branch: Branch;
    firstName?: string;
    lastName?: string;
    id: number;
    locale: string;
    userName: string;
    name?: string;
    surname?: string;
  };
  goals?: MetaGoalItem[];
}

export interface ReportTrimester {
  id: number;
  name: string;
  startAt?: string;
  endAt?: string;
  active?: boolean;
}
export interface IGeneratorReport {
  trimester: ReportTrimester;
  reportBranches?: ReportBranches[];
  reportNewBranches?: NewReportBranches[];
}

export interface ReportBranches {
  finishDate: string;
  id: number;
  branch: Branch;
}
export interface NewReportBranches {
  pdfCreatedAt: string;
  branch: Branch;
}

export interface Semester {
  id: number;
  name: string;
  nameForRG: string;
  nameHebrew?: string;
  headerForRG: string;
  headerHebrew?: string;
  reportHeaderText: string;
  startAt: string;
  endAt: string;
  active: boolean;
}

export interface ProgressReporByGroupsParams {
  branch: Branch;
  groups: number[];
  subjects: number[];
  stages: number[];
}
export interface ProgressReporByBranchesParams {
  branches: number[];
  finishDate?: string;
}

export interface ReportRow {
  id: number;
  progress: string;
  canDelete: boolean;
  student: IStudent;
  commentForOffice: string;
  goals?: MetaGrade[];
  goalsPrev?: MetaGrade[];
}

export interface ProgressReportChild {
  id: number;
  teacher: Teacher;
  student: any;
  progress: string;
  recommendation: string;
  behavior: string;
  classwork: string;
  homework: string;
}

export interface Student {
  id: number;
  familyId: number;
  name: string;
  patronymic: string;
  surname: string;
  age: string;
}

export interface StudentsByStudyProgram {
  studyProgram: {
    id: number;
    name: string;
  };
  students: Student[];
}

export interface Tag {
  id: number;
  name: string;
}

export interface TagsAge {
  id: number;
  name: string;
}

export interface Tutor {
  id: number;
  name: string;
  surname: string;
  sex: number;
  branch: {
    id: number;
    name: string;
  };
  email: string;
  tags: Tag[];
  tagsAge: TagsAge[];
  tasksCounter: number;
  position: string;
  sipuniNum: string;
  comment: string;
}

export interface StudentsByTutor {
  tutor: Tutor;
  students: Student[];
}

export interface PayingReport {
  numberOfStudents: number;
  studentsByStudyProgram: StudentsByStudyProgram[];
  studentsByTutors: StudentsByTutor[];
}

// Teacher report finance

export interface FinanceReportTeacher {
  teacherId: string;
  teacherName: string;
  startBalance: number;
  endBalance: number;
  chargeOff: number;
  payments: number;
  paymentsWithC1: number;
  chargeForExtras: number;
  totalPayments: number;
  byProducts: any; // с бэка странный формат приходит
  correctionsC1: number;
  correctionsC2: number;
  doubtsCount: number;
  academicHours: number;
  clockHours: number;
  balanceCheck: number;
}

export interface ProgressReportParams {
  limit?: number;
  page?: number;
  trimester?: number;
  stage?: number;
  subject?: number;
  branch?: number;
  status?: unknown;
  teacher?: number;
  teacherTutor?: number;
  studentName?: string;
  familyTutor?: number;
  title?: string;
}

export interface ProgressReportSendParams {
  stage?: number;
  branch?: number;
  supergroups?: number[];
}

export type FeedbackType = 'student' | 'parent' | 'supervisor';
export type IFeedbackParams = { type: FeedbackType; comment: string; comment2?: string };

export enum StatusNewReport {
  new = 0,
  checked = 1,
  sent = 2
}
export type NumberOfComments = '0' | '1' | '2+';
export interface INewReportGetParams {
  branch?: number;
  stage?: number;
  familyTutor?: number;
  student?: number;
  supergroup?: number;
  subject?: number;
  status?: StatusNewReport;
  numberOfComments?: NumberOfComments;
  page?: number;
  limit?: number;
}
export interface INewReportItem {
  student: IStudent;
  supergroups: Supergroup[];
  status: StatusNewReport;
  countComments: number;
  trimester: { name: string; id: number };
  pdf: { id: number } | null;
  sentAt?: string;
  sentByUser?: IUserData;
}

export interface IChangeStatusNewReportParams {
  student: number;
  trimester: number;
  status: StatusNewReport;
}

export interface IGetNewReportByStudentParams {
  student: number;
  trimester: number;
}
export interface IGenerateProgressReporPDF {
  branches: number[];
  trimester: number;
}
export interface InfoNewReportByStudent {
  status: StatusNewReport;
  numberOfComments: NumberOfComments;
  trimester: { name: string; id: number };
  student: IStudent;
}
export interface INewReportByStudent extends InfoNewReportByStudent {
  data: IDataNewReportByStudent[];
}

export interface IDataNewReportByStudent {
  registerId: number;
  lesson: Lesson;
  commentForParent: string | null;
  commentForParent2: string | null;
  useInProgressReport: boolean
}

export interface IProgressReportForParent {
  trimester: { id: number; name: string };
  students: IStudent[];
}
export interface ISendProgressReportParams {
  reports: number[];
}
export interface IRegisterUseInNewProgressReportParams {
  use: boolean;
}

