import React, { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Modal, Button, Input, Row, Col, message, Select, Spin } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Courses } from 'api/Courses';
import { Unit } from 'types/TeacherAccount/Courses';
import { isEmpty } from 'lodash';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useCoursesRepository } from '../../../../repos/CoursesRepository';

interface AddUnitProps extends FormComponentProps {
  addUnitVisible: boolean;
  setAddUnitVisible: (bool: boolean) => void;
  originalCourseId: number | string;
  getOriginalCourseById: (bool: boolean) => Promise<any>;
}

const { Option } = Select;

/**
 * @description Добавление нового юнита
 * @param {AddUnitProps} props
 * @return {React.ReactNode}
 */
export const AddUnitModalTemplate = (props: AddUnitProps) => {
  const [strings] = useLanguageContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [hasLoadUnits, setHasLoadUnits] = useState<boolean>(false);
  const [units, setUnits] = useState<Unit[]>([]);
  const [hasDisabledUnitId, setHasDisabledUnitId] = useState<boolean>(false);
  const [hasDisabledUnitName, setHasDisabledUnitName] = useState<boolean>(false);
  const {
    addUnitVisible,
    setAddUnitVisible,
    originalCourseId,
    getOriginalCourseById,
    form: { getFieldDecorator, validateFields, getFieldsValue }
  } = props;

  const coursesRepository = useCoursesRepository();

  const handleClose = () => setAddUnitVisible(false);

  /**
   * @description Добавление юнита
   * @return {void}
   */
  const handleSubmit = (): void => {
    validateFields(async (errors, values) => {
      if (isEmpty(errors)) {
        setHasLoading(true);
        try {
          const { unitName, unitId } = values;
          const response = await coursesRepository.addOriginalCourseUnit(originalCourseId, {
            unitName,
            unitId
          });

          if (response.status === 200) {
            const { data } = response;
            message.success(`${strings.UNIT} ${data.name} ${strings.USER_WAS_SUCCESSFULLY_ADDED}`);
            handleClose();
            getOriginalCourseById(false);
          }
        } catch {
          message.error(strings.UNIT_CREATION_ERROR_TRY_AGAIN_LATER, 2);
        } finally {
          setHasLoading(false);
        }
      }
    });
  };

  const footerModal = [
    <Button onClick={handleClose} key="cancelBtn">
      {strings.CANCEL}
    </Button>,
    <Button onClick={handleSubmit} type="primary" key="addBtn" disabled={hasLoading} loading={hasLoading}>
      {strings.ADD_UNIT}
    </Button>
  ];

  /**
   * @description Поиск по юнитам
   * @return {void}
   */
  const handleSearch = debounce(async (value: string) => {
    if (value?.length) {
      setHasLoadUnits(true);
      try {
        const { data } = await Courses.searchUnit(value);
        setUnits(data);
      } catch {}
    } else {
      setUnits([]);
    }
  }, 500);

  const options = units?.map((unit: Unit) => (
    <Option value={unit.id} key={unit.id}>
      {unit.name}
    </Option>
  ));

  useEffect(() => {
    const { unitId, unitName } = getFieldsValue();
    if (unitId) {
      setHasDisabledUnitName(true);
    } else {
      setHasDisabledUnitName(false);
    }

    if (unitName) {
      setHasDisabledUnitId(true);
    } else {
      setHasDisabledUnitId(false);
    }
  }, [getFieldsValue()]);

  return (
    (<Modal title={strings.ADD_NEW_UNIT} open={addUnitVisible} onCancel={handleClose} footer={footerModal}>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Row>
          <Col lg={24}>
            <MyFormItem label={strings.EXISTING_UNIT}>
              {getFieldDecorator('unitId')(
                <Select
                  allowClear
                  showSearch
                  disabled={hasDisabledUnitId}
                  placeholder={strings.SEARCH_UNIT}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={handleSearch}
                  notFoundContent={hasLoadUnits ? <Spin size="small" /> : null}
                >
                  {options}
                </Select>
              )}
            </MyFormItem>
            <MyFormItem label={strings.OR_CREATE_NEW}>
              {getFieldDecorator('unitName', {
                rules: [{ required: false }]
              })(<Input disabled={hasDisabledUnitName} />)}
            </MyFormItem>
          </Col>
        </Row>
      </Form>
    </Modal>)
  );
};

export const AddUnitModal = Form.create<AddUnitProps>({})(AddUnitModalTemplate);

export default { AddUnitModal };
