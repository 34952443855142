import React, { useState } from 'react';
import { AttachmentItem, MaterialItem } from 'types/global';
import { DeleteOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Flex, message, Popconfirm } from 'antd';
import { Global } from 'api';
import { PreviewFileModal } from 'Global/components/FilePreview';
import { PrintFile } from 'Global/modules/Lesson/LessonInfo/PrintFile';
import { AttachmentName } from './AttachmentName';
import { initialAttachment, AttachmentType } from '../constants';
import { useLanguageContext } from 'contexts/LanguageContext';

interface AttachmentsListProps {
  attachments?: MaterialItem;
  type?: string;
  stateUpdate?: () => void;
  updateAttachments?: () => Promise<any>;
}

/**
 * @description Файлы с материалами
 * @param {object} props
 * @return {React.ReactNode}
 */
export const AttachmentsList = (props: AttachmentsListProps) => {
  const [strings] = useLanguageContext();
  const { attachments, type, stateUpdate, updateAttachments } = props;
  const [hasShowPreviewModal, setHasShowPreviewModal] = useState<boolean>(false);
  const [hasLoadPreviewAttachment, setHasLoadPreviewAttachment] = useState<boolean>(false);
  const [hasShowPrintModal, setHasShowPrintModal] = useState<boolean>(false);
  const [attachmentIdToPrint, setAttachmentIdToPrint] = useState<number | undefined>();
  const [currentAttachment, setCurrentAttachment] = useState<AttachmentType>(initialAttachment);
  const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer>('');
  const { print } = attachments;

  const hideModal = () => setHasShowPreviewModal(false);
  const hidePrintModal = () => setHasShowPrintModal(false);

  /**
   * @description Открывает модалку для печати
   * @param {number} attachmentId
   * @return {void}
   */
  const showPrintModal = (attachmentId: number): void => {
    setAttachmentIdToPrint(attachmentId);
    setHasShowPrintModal(true);
  };

  /**
   * @description Удаление материала
   *  @return {Promise<any>}
   */
  const deleteMaterial = async (attachmentId: number): Promise<any> => {
    try {
      await Global.deleteAttachment(attachmentId);
      if (type) {
        stateUpdate && stateUpdate();
        updateAttachments && updateAttachments();
        message.success(strings.FILE_DELETED_SUCCESS);
      }
    } catch {}
  };

  /**
   * @description Подтверждение удаления
   * @param {number} attachmentId
   * @return {Promise<any>}
   */
  const confirm = async (attachmentId: number): Promise<any> => {
    await deleteMaterial(attachmentId);
  };

  if (attachments) {
    return (
      <div style={{ marginTop: 5 }}>
        <PreviewFileModal
          hasLoadPreviewAttachment={hasLoadPreviewAttachment}
          fileName={currentAttachment.name}
          previewUrl={previewUrl}
          handleHideModal={hideModal}
          hasShowModal={hasShowPreviewModal}
        />
        <PrintFile
          hasShowPrintModal={hasShowPrintModal}
          hideModal={hidePrintModal}
          attachmentIdToPrint={attachmentIdToPrint}
        />
        {attachments?.list?.map((attachment: AttachmentItem) => {
          const createdBy = `${attachment?.user?.surname} ${attachment?.user?.name}`;
          return (
            <Flex align="baseline" style={{ fontSize: 12, color: '#bbb', marginBottom: 10, gap: 5 }} key={attachment.id}>
              <Popconfirm
                title={strings.ARE_YOU_SURE_DELETE_THIS_MATERIAL}
                onConfirm={() => confirm(attachment.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button size="small" icon={<DeleteOutlined />} />
              </Popconfirm>
              {print && !attachment.printed && (
                <Button
                  size="small"
                  icon={<PrinterOutlined />}
                  type="primary"
                  onClick={() => showPrintModal(attachment.id)}
                />
              )}
              <AttachmentName
                attachment={attachment}
                setPreviewUrl={setPreviewUrl}
                currentAttachment={currentAttachment}
                setCurrentAttachment={setCurrentAttachment}
                setHasShowPreviewModal={setHasShowPreviewModal}
                setHasLoadPreviewAttachment={setHasLoadPreviewAttachment}
              />
              {`${strings.UPLOADED_AT} ${attachment?.updatedAt} ${strings.BY} ${createdBy}`}
            </Flex>
          );
        })}
      </div>
    );
  }
  return null;
};

export default { AttachmentsList };
