import { Sms } from 'api';
import { groupBy } from 'lodash';

import { Participant } from 'types/Schedule';

import { USER_TYPES } from 'Global/modules/Schedule/constants';

/**
 * @description Функция отправки сообщений
 * @param {array} eventParticipants
 * @param {string} smsText
 * @param {boolean} hasSendToParent
 * @return {Promise<any>}
 */
export const sendSms = async (
  eventParticipants: Participant[],
  smsText: string,
  hasSendToParent: boolean
): Promise<any> => {
  if (smsText) {
    const groupedParticipants = groupBy(eventParticipants, 'type');
    let requests = [];

    Object.entries(groupedParticipants).map(async ([participantsGroupType, participants]) => {
      const recipients = getRecipients(participants, hasSendToParent);
      if (recipients.length > 0) {
        requests.push(
          Sms.sendFormDistribution({
            userType: hasSendToParent ? USER_TYPES.parent : USER_TYPES[participantsGroupType],
            message: smsText,
            ids: recipients
          })
        );
      }
    });

    Promise.all(requests);
  }
};

/**
 * @description IDs получателей сообщения
 * @return {array<number>}
 */
const getRecipients = (participants: Participant[], hasSendToParent: boolean) => {
  if (hasSendToParent) return getParentsIds(participants);

  return participants.map(participant => participant.id);
};

/**
 * @description IDs родителей участников
 * @return {array<number>}
 */
const getParentsIds = (participants: Participant[]) => {
  let parentIds = [];
  participants
    .map(recipient => recipient?.parents?.map(parent => parent?.parent?.id || parent.id))
    .filter(item => Boolean(item))
    .map(ids => ids.map(parentId => parentIds.push(parentId)));

  return parentIds;
};
