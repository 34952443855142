import axios from 'axios';
import { storageService } from 'contexts/StorageContext';
import { BACKEND_LOCATION, AMI_5_LOCATION } from 'helpers';
import { message } from 'antd';

class HttpService {
  /**
   * @param userConfig {object}
   * @param hasFormData {boolean}
   * @param hasAmiFive {boolean}
   * @param hasShowErrorMessage {boolean}
   */
  async makeRequest(userConfig, hasFormData, hasAmiFive?: boolean, hasShowErrorMessage?: boolean) {
    try {
      const token = storageService.getAccessToken();
      const authHeaders = token ? { Authorization: 'Bearer ' + token } : {};
      const contentType = hasFormData ? 'multipart/form-data' : 'application/json';
      // Если hasAmiFive, то подставляем новый урл бэкэнда, иначе wombat
      const baseUrl = hasAmiFive ? AMI_5_LOCATION : BACKEND_LOCATION;

      const config = {
        ...userConfig,
        headers: {
          ...authHeaders,
          ...userConfig.headers,
          'Content-Type': contentType
        },
        baseURL: baseUrl
      };

      return await axios.request(config);
    } catch (error) {
      const { response } = error;
      const originalRequest = error.config;
      const { location } = window;
      if (response && response.status === 401 && !originalRequest._retry) {
        if (storageService.getRefreshToken()) {
          try {
            const refreshToken = storageService.getRefreshToken();
            const response = await axios.get(`${BACKEND_LOCATION}/api/user/refresh_token?token=${refreshToken}`);
            storageService.setAccessToken(response?.data?.token);
            storageService.setRefreshToken(response?.data?.refreshToken);
            return this.makeRequest(userConfig, false);
          } catch (error) {
            storageService.removeAccessToken();
            storageService.removeRefreshToken();
            window.location.href = '/auth';
          }
        }

        storageService.removeAccessToken();
        if (!location.href.includes('auth') && !location.href.includes('forgot')) {
          window.location.href = '/auth';
        }
      }

      if (response && response.status !== 401 && hasShowErrorMessage) {
        const errorMsg = response?.data?.message ? response?.data?.message : response?.data;
        message.error(`${errorMsg} Status code: ${response.status}`, 3);
      }

      throw response;
    }
  }

  /**
   * @description Обертка над GET запросом
   * @param {string} url
   * @param {object} params
   * @param {object} options
   * @param {boolean} hasAmiFive
   * @param {boolean} hasShowErrorMessage -
   */
  get(url: string, params?: object, options?: any, hasAmiFive = false, hasShowErrorMessage = true) {
    return this.makeRequest(
      {
        url,
        method: 'get',
        params: params,
        ...options
      },
      false,
      hasAmiFive,
      hasShowErrorMessage
    );
  }

  /**
   * @description Обертка над POST запросом
   * @param {string} url
   * @param {object} options
   * @param {object} data
   * @param {boolean} hasFormData
   * @param {boolean} hasAmiFive
   * @param {boolean} hasShowErrorMessage
   */
  post(url: string, data?: any, options?: any, hasFormData?: boolean, hasAmiFive = false, hasShowErrorMessage = true) {
    return this.makeRequest(
      {
        url,
        method: 'post',
        data,
        ...options
      },
      hasFormData,
      hasAmiFive,
      hasShowErrorMessage
    );
  }

  /**
   * @description Обертка над PUT запросом
   * @param {string} url
   * @param {object} data
   * @param {options} options
   * @param {boolean} hasFormData
   * @param {boolean} hasAmiFive
   * @param {boolean} hasShowErrorMessage
   */
  put(url: string, data?: any, options?: any, hasFormData?: boolean, hasAmiFive = false, hasShowErrorMessage = true) {
    return this.makeRequest(
      {
        url,
        method: 'put',
        data,
        ...options
      },
      hasFormData,
      hasAmiFive,
      hasShowErrorMessage
    );
  }

  /**
   * @description Обертка над DELETE запросом
   * @param {string} url
   * @param {object} data
   * @param {object} options
   * @param {boolean} hasAmiFive
   * @param {boolean} hasShowErrorMessage
   */
  delete(url: string, data?: any, options?: any, hasAmiFive = false, hasShowErrorMessage = true) {
    return this.makeRequest(
      {
        url,
        method: 'delete',
        data,
        ...options
      },
      false,
      hasAmiFive,
      hasShowErrorMessage
    );
  }
}

export const httpService = new HttpService();
