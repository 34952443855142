import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Table, Button, Row } from 'antd';
import { columns } from './columns';
import Pagination from 'Global/components/Pagination';
import { scrollTop } from 'helpers/scroll';
import { Filter } from './Filter';
import CoursesService from 'helpers/services/CoursesService';
import { Link, useHistory } from 'react-router-dom';
import { Loader } from 'Global/components/Loader/Loader';
import { CHOOSE_ORIGINAL_COURSE } from '../routes';
import { useUserContext } from 'contexts/UserContext';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { COURSE_GROUPS_LIMIT } from 'Global/constants';
import { GetCourseGroupsParams } from 'types/education';
import queryString from 'query-string';

const { Column } = Table;

export const GroupCourse = () => {
  const [user] = useUserContext();
  const [strings] = useLanguageContext();
  const coursesRepository = useCoursesRepository();
  const [groups, setGroups] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasLoadGroups, setHasLoadGroups] = useState<boolean>(false);
  const onSelectChange = selectedRowKeys => setSelectedRowKeys(selectedRowKeys);

  const getAllCourseGroups = async (params: GetCourseGroupsParams): Promise<any> => {
    try {
      setHasLoadGroups(true);
      const {
        data: { items, pagination }
      } = await coursesRepository.getCourseGroups(params);
      setGroups(items);
      setPaginationInfo(pagination);
      push({ search: queryString.stringify(params) });
      setHasLoadGroups(false);
    } catch {
      setHasLoadGroups(false);
    }
  };

  const { pageCount, current }: any = paginationInfo || '';
  const courses = new CoursesService();
  const { push, goBack } = useHistory();
  const { locationSearchParams } = useLocationSearchParams();
  const hasTopManagerRole = user?.hasRoles?.topManager;

  const handlePage = async (page: number): Promise<any> => {
    scrollTop();
    const allParams = { ...locationSearchParams, page, limit: COURSE_GROUPS_LIMIT };
    await getAllCourseGroups(allParams);
  };

  useEffect(() => {
    getAllCourseGroups({ active: 1, ...locationSearchParams, limit: COURSE_GROUPS_LIMIT });
  }, []);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  const tableProps: any = {
    dataSource: groups,
    pagination: false,
    rowKey: 'id',
    rowSelection: rowSelection
  };

  return (
    <Container>
      <StPageHeader
        onBack={goBack}
        title={strings.GROUP_COURSES}
        extra={[
          <Link to={CHOOSE_ORIGINAL_COURSE}>
            <Button type="primary" icon={<PlusOutlined />}>
              {strings.ADD}
            </Button>
          </Link>
        ]}
      >
        <Filter
          getAllCourseGroups={getAllCourseGroups}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          hasTopManagerRole={hasTopManagerRole}
        />
      </StPageHeader>
      <Loader spinning={hasLoadGroups}>
        <Row gutter={[10, 10]}>
          <Table {...tableProps}>
            {columns.map(col => (
              <Column key={col.key} {...col} />
            ))}
          </Table>
          <Pagination count={pageCount} current={current} pageChange={handlePage} />
        </Row>
      </Loader>
    </Container>
  );
};

export default { GroupCourse };
