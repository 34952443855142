import { create } from 'zustand';
import { OriginalLessonOutcomeItem } from 'types/TeacherAccount/Courses';

interface LessonOutcomesState {
  originalLessonOutcomes: OriginalLessonOutcomeItem[];
  setOriginalLessonOutcomes: (val: OriginalLessonOutcomeItem[]) => void;
  hasLessonOutcomesChanged: boolean;
  setHasLessonOutcomesChanged: (val: boolean) => void;
}

export const useLessonOutcomesStore = create<LessonOutcomesState>(set => ({
  originalLessonOutcomes: [],
  setOriginalLessonOutcomes: val => set({ originalLessonOutcomes: val }),
  hasLessonOutcomesChanged: false,
  setHasLessonOutcomesChanged: val => set({ hasLessonOutcomesChanged: val })
}));
