import React from 'react';
import { storageService } from 'contexts/StorageContext';
import { Redirect } from 'react-router';

export const withAuthGuard = Component => {
  return function WithAuthGuard(props) {
    if (!storageService.getAccessToken()) {
      return <Redirect to="/auth" />;
    }

    return <Component {...props} />;
  };
};
