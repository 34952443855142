import React, { useEffect, useState } from 'react';
import { Typography, Table } from 'antd';
import { SmsHistory as StSmsHistory } from '../styles';
import { columns } from './columns';
import { Location } from 'history';
import { Sms } from 'api/Sms';
import Pagination from 'Global/components/Pagination';
// FIXME: нужен name export (используется много где)
import { scrollTop } from 'helpers/scroll';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Title } = Typography;
const { Column } = Table;

interface SmsHistoryProps {
  location: Location;
}

/**
 * @param {object} location
 * @return {React.ReactNode}
 */
export const SmsHistory = ({ location }: SmsHistoryProps) => {
  const [strings] = useLanguageContext();
  const [loadSessionsList, setLoadSessionsList] = useState<boolean>(false);
  const [smsSessionsList, setSmsSessionsList] = useState([]);
  const [pagination, setPagination] = useState({});
  const { pageCount, current }: any = pagination || '';

  /**
   * @description Получение Sms history
   * @return {Promise<any>}
   */
  const getSmsHistory = async (page?: number): Promise<any> => {
    try {
      setLoadSessionsList(true);
      const {
        data: { sessions, paginationInfo }
      } = await Sms.getSmsSessionsList(page);
      setSmsSessionsList(sessions);
      setPagination(paginationInfo);
      setLoadSessionsList(false);
    } catch {
      setSmsSessionsList([]);
      setLoadSessionsList(false);
    }
  };

  const handlePage = (page: number): void => {
    getSmsHistory(page);
    scrollTop();
  };

  useEffect(() => {
    getSmsHistory(1);
  }, []);

  return (
    <StSmsHistory>
      <Title level={3}>{strings.SMS_HISTORY}</Title>
      <Table dataSource={smsSessionsList} loading={loadSessionsList} pagination={false}>
        {columns(strings).map(col => (
          <Column key={col.key} {...col} />
        ))}
      </Table>
      <Pagination count={pageCount} current={current} pageChange={handlePage} />
    </StSmsHistory>
  );
};

export default { SmsHistory };
