import styled from 'styled-components';

export const StAddContact = styled.div`
  .ant-col {
    padding: 0 8px 0 0;
  }

  .ant-legacy-form-item {
    margin-bottom: 0;
  }
`;

export const Type = styled.div`
  text-align: left;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
  font-weight: 800;
`;

export const CardTop = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Date = styled.span`
  font-size: 14px;
  color: #444;
  opacity: 0.7;
`;

export const Description = styled.p`
  font-size: 14px;
  color: #222;
  word-break: break-word;
`;

export default {
  Type,
  CardTop,
  Date,
  Description
};
