import React from 'react';
import styled from 'styled-components';
import { News } from 'Global/modules/Home/News';
import { MainPageParent } from 'Student&Parent';
import { OrgWidget } from 'Global/components/Org';
import { useUserContext } from 'contexts/UserContext';
// import { SnowFall } from '../../components/Snow';

const StyledHome = styled.div`
  padding: 20px;
`;

export const Home = () => {
  const [user] = useUserContext();

  if (user?.hasRoles?.parent || user?.hasRoles?.student) {
    return <MainPageParent />;
  }

  return (
    <StyledHome>
      {/*<SnowFall />*/}
      <OrgWidget />
      <News isPage={false} />
    </StyledHome>
  );
};

export default { Home };
