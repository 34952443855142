import styled, { css } from 'styled-components';

export const CardDate = styled.span`
  font-size: 14px;
`;

export const MessageInfo = styled.div`
  flex: 0 0 110px;
  margin-inline-end: 20px;
  text-align: center;

  & img {
    height: 44px;
  }

  @media (max-width: 768px) {
    margin-inline-end: 5px;
  }
`;

export const MessageContent = styled.div`
  flex: 1;
  align-self: flex-start;
  margin-top: 30px;
  display: flex;
`;

export const MessageBubble = styled.div`
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.1);
  background: #fff;
  color: #5a5a5a;
  border-radius: 3px 20px 20px 20px;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px 15px;
  }
`;

export const Message = styled.div<{ reverse?: boolean }>`
  display: flex;
  margin-bottom: 24px;

  ${props =>
    props.reverse &&
    css`
      ${MessageInfo} {
        order: 1;
        margin: 0 0 0 20px;

        @media (max-width: 768px) {
          margin: 0 0 0 5px;
        }
      }

      ${MessageContent} {
        justify-content: flex-end;
      }

      ${MessageBubble} {
        border-radius: 20px 3px 20px 20px;
        text-align: right;
      }
    `}
`;

export const MessageName = styled.div`
  font-weight: bold;
  margin: 4px 0;
  line-height: 17px;
`;

export const MessageRole = styled.div`
  font-size: 12px;
  color: #888;
`;

export const MessageDate = styled.div`
  font-size: 11px;
  color: #bbb;
`;

export default {
  CardDate,
  Message,
  MessageInfo,
  MessageContent,
  MessageName,
  MessageDate,
  MessageBubble
};
