import React, { useState } from 'react';
import { PoweroffOutlined } from '@ant-design/icons';
import { Modal, message, Button, Input, Col, Row, Radio, Tooltip, Form, Flex } from 'antd';
import { capitalize, lowerCase } from 'lodash';
import { Auth } from 'api/Auth';
import { IAccount } from 'types';
import { accountTypes } from 'types/user';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useUserContext } from 'contexts/UserContext';
import { passwordRules } from '../modules/Auth/Login';

interface CreateAccountProps {
  values?: IAccount;
  id: number;
  loginAs: string;
  email: string;
  type: accountTypes;
  handleUserRefetch?: () => void;
  login?: string; //для студентов и родителей login не нужен, тк равен email
  userId: number;
  hasLoginEqualsEmail?: boolean; //для студентов и родителей логин равен имейлу
}

/**
 * При создании обязательно необходимо указывать тип создаваемого аккаунта
 * 'parent' | 'student' | 'teacher' | 'admin'
 */

/**
 * @description - Компонент модального окна создания нового аккаунта
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const BtnCreateAccount = (props: CreateAccountProps) => {
  const [form] = Form.useForm();
  const [{ id: currentUserId }] = useUserContext();
  const [hasManuallyPassword, setHasManuallyPassword] = useState<boolean>(false);
  const [fetchRequest, setFetchRequest] = useState<boolean>(false);
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);

  const { id, email, loginAs, type, login, handleUserRefetch, userId, hasLoginEqualsEmail } = props;

  const [strings] = useLanguageContext();

  const closeModal = (): void => {
    setHasShowModal(false);
    setHasManuallyPassword(false);
    form.resetFields();
  };

  /**
   * @description Сабмит формы, запрос на создание акканута
   * @param {Event} e
   * @return {void}
   */
  const handleSubmit = async (values): Promise<any> => {
    try {
      const { data, status } = await Auth.createUser({ id, type, ...values });
      if (status === 200) {
        closeModal();
        handleUserRefetch();
        message.success(
          `${capitalize(type)} ${data?.firstName ?? ''} ${data?.lastName ?? ''} ${strings.WAS} ${
            loginAs ? lowerCase(strings.UPDATED) : lowerCase(strings.CREATED)
          } ${strings.SUCCESSFULLY} !`
        );
      }
    } catch (error) {
      const { response } = error;
      message.error(response?.data?.message || strings.UNEXPECTED_ERROR);
    } finally {
      setFetchRequest(false);
    }
  };

  /**
   * @description - Вкл/выкл ручной ввод пароля
   * @return {void}
   */
  const handleHasManuallyPassword = (e): void => {
    const flag = e.target.value;
    setHasManuallyPassword(flag);
    if (!flag) {
      form.setFieldsValue({ password: null, passwordAgain: null });
    }
  };

  if (userId === currentUserId) {
    return null;
  }
  const getInitEmailValue = () => {
    if (hasLoginEqualsEmail) {
      return loginAs ? loginAs : email;
    } else return email;
  };

  const initValues = { login, email: getInitEmailValue(), manually: false };

  return (
    <>
      <Tooltip title={loginAs ? strings.UPDATE_ACCOUNT : strings.CREATE_ACCOUNT}>
        <Button icon={<PoweroffOutlined />} onClick={() => setHasShowModal(true)} style={{ margin: 0 }} />
      </Tooltip>
      <Modal
        title={loginAs ? strings.UPDATE_ACCOUNT : strings.CREATE_ACCOUNT}
        footer={false}
        open={hasShowModal}
        onCancel={() => setHasShowModal(false)}
        width={450}
        destroyOnClose={true}
      >
        <Form layout="vertical" form={form} initialValues={initValues} onFinish={handleSubmit}>
          <Row gutter={24}>
            {!hasLoginEqualsEmail && (
              <Col lg={24}>
                <Form.Item name="login" label={strings.LOGIN} rules={[{ required: true }]}>
                  <Input placeholder={strings.LOGIN} />
                </Form.Item>
                <Form.Item
                  name="email"
                  label={strings.EMAIL}
                  rules={[{ required: true, type: 'email', message: strings.EMAIL_IS_NOT_VALID }]}
                >
                  <Input placeholder={strings.EMAIL} />
                </Form.Item>
              </Col>
            )}
            {hasLoginEqualsEmail && (
              <Col lg={24}>
                <Form.Item
                  name="email"
                  label={strings.EMAIL}
                  rules={[{ required: true, type: 'email', message: strings.EMAIL_IS_NOT_VALID }]}
                >
                  <Input placeholder={strings.LOGIN_EMAIL} />
                </Form.Item>
              </Col>
            )}
            <Col lg={24}>
              <Form.Item name="manually">
                <Radio.Group onChange={handleHasManuallyPassword}>
                  <Radio value={false}>{strings.SEND_A_LETTER_WITH_A_PASSWORD_LINK_TO_THE_USER}</Radio>
                  <Radio value={true}>{strings.SET_UP_PASSWORD_MANUALLY}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {hasManuallyPassword && (
              <>
                <Col lg={24}>
                  <Form.Item name="password" label={strings.PASSWORD} rules={passwordRules}>
                    <Input.Password />
                  </Form.Item>
                </Col>
                <Col lg={24}>
                  <Form.Item
                    name="passwordAgain"
                    label={strings.PASSWORD_ONCE_AGAIN}
                    dependencies={['password']}
                    rules={[
                      {
                        required: hasManuallyPassword,
                        message: 'Please confirm your password!'
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The new password that you entered do not match!'));
                        }
                      })
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
              </>
            )}
            <Col lg={24}>
              <Flex gap={10} justify="end">
                <Button key="back" onClick={closeModal}>
                  {strings.CANCEL}
                </Button>
                <Button type="primary" key="action" disabled={fetchRequest} loading={fetchRequest} htmlType="submit">
                  {loginAs ? strings.UPDATE : strings.CREATE}
                </Button>
              </Flex>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default { BtnCreateAccount };
