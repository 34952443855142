import React from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

export const columns = [
  {
    title: 'Student',
    dataIndex: 'student',
    key: 'student',
    render(student) {
      return (
        <Link to={`/family/${student.familyId}/student/${student.id}`}>
          {student.name} {student.surname}
        </Link>
      );
    }
  },
  {
    title: 'Enrollment date',
    dataIndex: 'startAt',
    key: 'enrollmentDate',
    render: date => date && dayjs(date).format('DD.MM.YYYY')
  },
  {
    title: 'Disenrollment date',
    dataIndex: 'endAt',
    key: 'disenrollmentDate',
    render: date => date && dayjs(date).format('DD.MM.YYYY')
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment'
  }
];

export default { columns };
