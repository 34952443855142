import React, { useEffect, useState } from 'react';
import { Modal, Button, Spin } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useMailingRepository } from 'repos/MailingRepository';

interface PreviewWeeklyEmailModalProps {
  hasShowModal: boolean;
  hideModal: () => void;
  id: number;
}

/**
 * @description Предпросмотр рассылки
 * @param {PreviewWeeklyEmailModalProps} props
 * @return {React.ReactNode}
 */
export const PreviewWeeklyEmailModal = (props: PreviewWeeklyEmailModalProps) => {
  const [strings] = useLanguageContext();
  const { hasShowModal, hideModal, id } = props;
  const [template, setTemplate] = useState<string>('');
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const mailingRepository = useMailingRepository();

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          setHasLoading(true);
          const { data } = await mailingRepository.getPreviewMailingById(id);
          setTemplate(data?.template);
        } catch {
        } finally {
          setHasLoading(false);
        }
      })();
    }
  }, [id]);

  return (
    <Modal
      width={800}
      bodyStyle={{ overflow: 'auto' }}
      open={hasShowModal}
      title={strings.PREVIEW_OF_WEEKLY_EMAIL}
      onCancel={hideModal}
      footer={[
        <Button onClick={hideModal} key="okButton">
          {strings.OK}
        </Button>
      ]}
    >
      <Spin spinning={hasLoading}>
        <div dangerouslySetInnerHTML={{ __html: template }} />
      </Spin>
    </Modal>
  );
};

export default { PreviewWeeklyEmailModal };
