import React from 'react';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { Typography, Button } from 'antd';
import { IDeposit } from 'types/Family';
import styled from 'styled-components';
import dayjs from 'dayjs';

interface ILabel {
  color?: string;
}

const Label = styled.span<ILabel>`
  background: ${props => (props.color ? '#27bfec' : '#52c41a')};
  font-size: 12px;
  border-radius: 5px;
  padding: 2px 9px;
  color: #fff;
  margin-inline-end: 10px;
`;

const { Text } = Typography;

const textDelete = (children: any, condition: boolean) => {
  if (condition) {
    return <Text delete>{children()}</Text>;
  } else {
    return children();
  }
};

export const columns = (
  cancelDepositFamily: (depositId: number) => void,
  loadingCancel: number | null,
  setLoadingCancel: (depositId: number | null) => void,
  paidDepositFamily: (depositId: number) => void,
  loadingPaid: number,
  setLoadingPaid: (depositId: number | null) => void,
  setDepositId: (depositId: number | null) => void,
  setModalVisible: (bool: boolean) => void
) => [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 180,
    render: (date: string, deposit: IDeposit) => {
      const dateTemplate = () => (
        <div>
          {dayjs(date).format('D MMM YYYY')} <br /> {dayjs(date).format('HH:mm')}
        </div>
      );

      return textDelete(dateTemplate, deposit.canceled);
    }
  },
  {
    title: 'Sum',
    dataIndex: 'sum',
    width: 100,
    key: 'sum',
    render: (sum: number, deposit: IDeposit) => {
      const sumTemplate = () => <div>{sum} €</div>;
      return textDelete(sumTemplate, deposit.canceled);
    }
  },
  {
    title: 'Admin',
    dataIndex: 'createdBy',
    key: 'createdBy',
    width: 200,
    render: (createdBy: string, record: any) => {
      const nameTemplate = () => <div>{createdBy}</div>;
      return textDelete(nameTemplate, record.canceled);
    }
  },
  {
    title: 'Remaining sum',
    dataIndex: 'balance',
    key: 'balance'
  },
  {
    title: 'History',
    dataIndex: 'priceData',
    key: 'priceData',
    render: priceData => {
      if (priceData) {
        return priceData.map(price => <p>{price}</p>);
      }

      return null;
    }
  },
  {
    dataIndex: 'done',
    key: 'done',
    render: (done: boolean, deposit: IDeposit) => {
      const paidTemplate = () => <Label>paid</Label>;

      const cancelDeposit = async (depositId: number) => {
        setLoadingCancel(depositId);
        await cancelDepositFamily(depositId);
        setLoadingCancel(null);
      };

      const paidDeposit = async (depositId: number) => {
        setLoadingPaid(depositId);
        await paidDepositFamily(depositId);
        setLoadingPaid(null);
      };

      const showModal = () => {
        setModalVisible(true);
        setDepositId(deposit.id);
      };

      return (
        <div>
          {done && (
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              style={{ fontSize: '24px', marginBottom: 12, display: 'block', textAlign: 'left' }}
            />
          )}
          {deposit.paid && textDelete(paidTemplate, deposit.canceled)}
          {!deposit.canceled && !deposit.done && (
            <>
              {deposit.partiallyPaid === 0 && (
                <Button
                  disabled={deposit.paid}
                  loading={loadingPaid === deposit.id}
                  onClick={() => paidDeposit(deposit.id)}
                >
                  Paid
                </Button>
              )}
              {deposit.partiallyPaid > 0 && <Label color="blue">{deposit.partiallyPaid}</Label>}
              <Button onClick={() => showModal()}>Paid Partially</Button>
              <Button loading={loadingCancel === deposit.id} onClick={() => cancelDeposit(deposit.id)}>
                Cancel
              </Button>
            </>
          )}
        </div>
      );
    }
  }
];

export default { columns };
