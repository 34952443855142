import React from 'react';
import { History } from 'history';
import { DeleteOutlined, EditOutlined, FilePdfOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Tag, Select } from 'antd';
import { dateFormat } from 'helpers/dates';
import dayjs from 'dayjs';

// Типы
import { AcceptanceAct, Contract, Holder, TagContract } from 'types/Family';
import { Performer, FamilyContractStatus } from 'types';

const { Option } = Select;

const openModal = (contractId: number, setContractIdModal: any, setModalVision: any) => {
  setModalVision(true);
  setContractIdModal(contractId);
};

interface columnsProps {
  deleteTag: (e: React.MouseEvent, tagId: number, contractId: number) => void;
  setModalVision: (bool: boolean) => void;
  setContractIdModal: (contractId: number) => void;
  downloadContract: (contract: Contract) => Promise<any>;
  statuses: FamilyContractStatus[];
  onStatusChange: (id: number, contractId: number) => Promise<any>;
  deleteContract: (contractId: number) => void;
  history: History;
  hasTopManagerOrBranchDirector: boolean;
}

export const columns = ({
  deleteTag,
  setModalVision,
  setContractIdModal,
  downloadContract,
  statuses,
  onStatusChange,
  deleteContract,
  history,
  hasTopManagerOrBranchDirector
}: columnsProps) => [
  {
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
    render: (date: string) => <p>{date && dayjs(date).format(dateFormat)}</p>
  },
  {
    title: 'Date',
    dataIndex: 'contract_date',
    key: 'contract_date',
    render: (date: string) => <p>{date && dayjs(date).format(dateFormat)}</p>
  },
  {
    title: 'Holder',
    dataIndex: 'holded',
    key: 'holded',
    render: (holded: Holder) => holded?.name ?? ''
  },
  {
    title: 'Number',
    dataIndex: 'contract_number',
    key: 'contract_number'
  },
  {
    title: 'Performer',
    dataIndex: 'performer',
    key: 'performer',
    render: (performer: Performer) => performer?.name ?? ''
  },
  {
    title: 'File',
    dataIndex: 'file',
    key: 'file',
    render: (file: boolean, contract: Contract) =>
      file && <Button icon={<FilePdfOutlined />} onClick={() => downloadContract(contract)} style={{ margin: 0 }} />
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: FamilyContractStatus, contract: Contract) => (
      <Select
        defaultValue={status?.id}
        onChange={(id: number) => onStatusChange(id, contract.id)}
        style={{ minWidth: 120, margin: 0 }}
      >
        {statuses.map(({ id, status }: FamilyContractStatus) => (
          <Option key={id} value={id}>
            {status}
          </Option>
        ))}
      </Select>
    )
  },
  {
    title: 'Tags',
    dataIndex: 'tags',
    key: 'tags',
    render: (tags: TagContract[], contract: Contract) => (
      <>
        <div>
          {tags.map((tag: TagContract) => (
            <Tag key={tag.id} closable onClose={(event: React.MouseEvent) => deleteTag(event, tag.id, contract.id)}>
              {tag.name}
            </Tag>
          ))}
        </div>
        <Button icon={<PlusOutlined />} onClick={() => openModal(contract.id, setContractIdModal, setModalVision)}>
          Add new tag
        </Button>
      </>
    )
  },
  hasTopManagerOrBranchDirector
    ? {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        render: (_: any, contract: Contract) => {
          return (
            <div style={{ display: 'flex', gap: 8 }}>
              <Button
                icon={<EditOutlined />}
                onClick={() => history.push(`/family/${contract.holded?.familyId}/contracts/${contract.id}/edit`)}
              />
              <Button icon={<DeleteOutlined />} danger onClick={() => deleteContract(contract.id)} />
            </div>
          );
        }
      }
    : {}
];

/**
 * @description Колонки для таблицы Acceptance acts
 * @param {Function} deleteAct
 * @param {Function} downloadAct
 * @return {Array}
 */
export const acceptanceActsColumns = (
  deleteAct: (actId: number) => void,
  downloadAct: (actId: number, filename: string) => Promise<any>
) => [
  {
    title: 'Date',
    dataIndex: 'startedAt',
    key: 'startedAt',
    render: (date: string) => {
      return date && dayjs(date).format('MMMM YYYY');
    }
  },
  {
    title: 'File',
    dataIndex: 'file',
    key: 'file',
    width: 100,
    render: (file: boolean, act: AcceptanceAct) => {
      const { id, startedAt } = act;
      const filename = dayjs(startedAt).format('MMMM YYYY');
      return file ? (
        <Button icon={<FilePdfOutlined />} onClick={() => downloadAct(id, filename)} style={{ margin: 0 }} />
      ) : (
        '-'
      );
    }
  },
  {
    dataIndex: 'id',
    key: 'actions',
    width: 80,
    render: (id: number) => (
      <Button icon={<DeleteOutlined />} danger onClick={() => deleteAct(id)} style={{ margin: 0 }} />
    )
  }
];

export default { columns };
