import styled from 'styled-components';

export const StyledWorkProgressItem = styled.div`
  background: #fff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 5px;
  border-top: 4px solid ${({ color }) => color};
  padding: 13px;
  min-height: 150px;
  margin: 10px 0 0 0;
  &:hover {
    background: #f9f9f9;
  }
`;

export const StyledGroupName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledWorkProgressDate = styled.div`
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #979797;
  margin: 5px 0 5px 0;
`;

export const StyledToFillIn = styled.div`
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #333333;
  p {
    margin: 8px 0;
    font-size: 13px;
  }
  span {
    word-break: break-word;
  }
`;

export const StyledWorkProgressItemTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
