import React, { useEffect, useState } from 'react';
import { useScheduleRepository } from 'repos/ScheduleRepostirory';
import { IHomeworkItem } from 'types/homework';
import { PaginationInfo } from 'types/global';
import { BookOutlined } from '@ant-design/icons';
import { Spin, Tag, Alert, Button, Collapse, Row, Col, message } from 'antd';
import { StCard, StPageHeader } from 'Global/GlobalStyle';
import Pagination from 'Global/components/Pagination';
import { downloadSomeFile, scrollTop } from 'helpers';
import { useLanguageContext } from 'contexts/LanguageContext';
import { HomeworkListItem } from '../Homework/HomeworkListItem';
import { useMediaQuery } from 'react-responsive';
import { UPLOAD_HOMEWORK } from '../routes';
import { Link } from 'react-router-dom';
import { StTitleHomework } from './index';
import { getLessonDate, getSubjectNameByLang } from '../helpers';
import { homeworkStatuses } from 'Global/constants';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
const { Panel } = Collapse;

export const PreviousLessons = ({ studentId }: { studentId: number }) => {
  const [strings, lang] = useLanguageContext();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const scheduleRepository = useScheduleRepository();
  const [hwList, setHwList] = useState<IHomeworkItem[]>([]);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [hasDownloading, setHasDownloading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>(null);
  const { subjectsTranslation } = useGlobalCollectionsContext();
  const { pageCount, current } = paginationInfo || {};

  const getStatusData = status => homeworkStatuses.find(item => item.name === status);

  const handleDownloadAllHomework = async (): Promise<any> => {
    setHasDownloading(true);
    try {
      await downloadSomeFile(`/api/v1/schedule/homework/zip`, null, null, null, true);
    } catch {
      message.error(strings.HW_DOWNLOAD_FAILED, 2);
    } finally {
      setHasDownloading(false);
    }
  };

  const handleChangePage = async (page: number) => {
    setCurrentPage(page);
    await getHomework(page);
    scrollTop();
  };

  const getHomework = async (page?: number): Promise<any> => {
    const params = { child: studentId, limit: 6, page: page ? page : currentPage };
    setHasLoading(true);
    try {
      const {
        data: { items, paginationInfo }
      } = await scheduleRepository.getHomeworkList(params);
      setHwList(items);
      setPaginationInfo(paginationInfo);
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  const getHeaderPanel = (item: IHomeworkItem) => {
    const { lesson, status, register } = item;
    const statusData = getStatusData(status);
    const subject = lesson?.courseGroup?.subject;
    return (
      <Row align="middle" gutter={6}>
        <Col lg={4}>{getLessonDate(lesson?.startAt, lang)}</Col>
        <Col lg={16} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>
            <strong>{getSubjectNameByLang(subjectsTranslation, lang, subject?.id)}: </strong>
            {lesson?.title}
          </span>
          {register?.homeworkGrade && <span>{register?.homeworkGrade}%</span>}
        </Col>
        <Col lg={4}>
          <Tag color={statusData?.color}>{statusData.titleByLang[lang] || statusData.title}</Tag>
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    if (studentId) {
      getHomework();
    }
  }, []);

  return (
    <Spin spinning={hasLoading}>
      <StCard>
        <StPageHeader
          title={<StTitleHomework>{strings.PREVIOUS_LESSONS}</StTitleHomework>}
          extra={
            <Link to={UPLOAD_HOMEWORK}>
              <BookOutlined style={{ marginInlineEnd: 5 }} />
              {strings.SEE_ALL_HOMEWORK}
            </Link>
          }
        />
        <Alert
          message={strings.DO_NOT_FORGET_TO_BRING_A_HARDCOPY_OF_THE_HOMEWORK_TO_THE_LESSON}
          type="info"
          showIcon
          style={{ marginBottom: 10 }}
        />
        <Collapse expandIconPosition="right">
          {hwList.map((item: IHomeworkItem, index) => (
            <Panel header={getHeaderPanel(item)} key={index}>
              <HomeworkListItem homework={item} isWidget refetchHW={getHomework} />
            </Panel>
          ))}
        </Collapse>
        <Pagination count={pageCount} current={current} pageChange={handleChangePage} />
        <div style={{ textAlign: 'center' }}>
          {!isMobile && (
            <Button type="primary" loading={hasDownloading} onClick={handleDownloadAllHomework}>
              {strings.DOWNLOAD_ALL_HOMEWORK}
            </Button>
          )}
        </div>
      </StCard>
    </Spin>
  );
};
