import React, { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import { CalendarOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Typography, Spin, Tabs, Row, Col } from 'antd';
import dayjs from 'dayjs';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
// Api
import { Homework } from 'api/Homework';
// Утилиты
import { Location } from 'history';
import BaseFilter from 'helpers/BaseFilter';
import { dateFormat } from 'helpers/dates';
import { useLanguageContext } from 'contexts/LanguageContext';
// Типы
import { Child } from 'types';
import { Lesson, LessonsByStudent } from 'types/homework';
// Компоненты
import { Avatar } from 'Global/components/Avatar';
import { DownloadForm } from './DownloadForm';

const StDownloadHomework = styled.div`
  margin: 40px;
`;

const { Title, Text } = Typography;
const { TabPane } = Tabs;

interface DownloadHomeworkProps {
  location: Location;
}

/**
 * @description Компонент для скачивания домашних заданий и материалов к урокам
 * @param {object} props
 * @return {React.ReactNode}
 */
export const DownloadHomework = (props: DownloadHomeworkProps) => {
  const { location } = props;
  const [lessonsByStudents, setLessonsByStudents] = useState<LessonsByStudent[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [strings] = useLanguageContext();
  const invoicesLogService = new BaseFilter();
  const locationSearchParams = invoicesLogService.getLocationSearchParams(location);

  /**
   * @description Получение данных об уроках и домашках для каждого студента в семье
   * @return {Promise<any>}
   */
  const getHomeworkForEachStudent = async (): Promise<any> => {
    setLoading(true);
    return Homework.getHomeworkForEachStudent(locationSearchParams)
      .then(({ data, status }) => {
        if (status === 200) {
          setLessonsByStudents(data);
        }
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const renderTabTitle = (child: Child) => {
    return (
      <>
        <Avatar photo={child.mainPhoto} size={40} />
        <span style={{ marginInlineStart: 20 }}>
          {child.name} {child.surname}
        </span>
      </>
    );
  };

  useEffect(() => {
    getHomeworkForEachStudent();
  }, []); // eslint-disable-line

  return (
    <ErrorBoundary>
      <Spin spinning={loading}>
        <StDownloadHomework>
          <Title level={4}>{strings.DOWNLOAD_HOMEWORK}</Title>
          <Tabs>
            {lessonsByStudents.map((item: LessonsByStudent) => {
              return (
                <TabPane tab={renderTabTitle(item.child)} key={item.child.id.toString()}>
                  {item.lessons.map((lesson: Lesson) => (
                    <Fragment>
                      <Row style={{ margin: '20px 0' }}>
                        <Col lg={3}>
                          <CalendarOutlined style={{ marginInlineEnd: 10, color: '#1890ff' }} />
                          <b>{dayjs(lesson.date).format(dateFormat)}</b>
                        </Col>
                        <Col>
                          <Text style={{ marginInlineEnd: '10px' }}>
                            <UsergroupAddOutlined style={{ marginInlineEnd: 10, color: '#1890ff' }} />
                            {lesson.group.groupName}
                          </Text>
                          {lesson.title && <Text>|</Text>}
                          <Text style={{ margin: '10px' }}>{lesson.title}</Text>
                        </Col>
                      </Row>
                      <DownloadForm
                        title={strings.LESSON_MATERIALS}
                        teacher={lesson.teacher}
                        material={lesson.material}
                      />
                      <br />
                      <DownloadForm title={strings.HOMEWORK} teacher={lesson.teacher} material={lesson.homework} />
                    </Fragment>
                  ))}
                </TabPane>
              );
            })}
          </Tabs>
        </StDownloadHomework>
      </Spin>
    </ErrorBoundary>
  );
};

export default { DownloadHomework };
