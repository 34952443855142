import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select, Row, Col } from 'antd';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import { TutorSelect } from 'Global/components/FormComponentsCompatible/Selects/TutorSelect';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { Branch } from 'types';
import { ThreadsParams } from 'types/CRM/messages';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { StageSelect } from 'Global/components/FormComponentsCompatible/Selects/StageSelect';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { FilterButtons } from 'Global/components/FilterButtons';
import { useUserContext } from 'contexts/UserContext';

const { Option } = Select;

interface FilterProps extends FormComponentProps {
  onFilter: (params?: ThreadsParams) => void;
}

const treadStatuses = [
  { name: 'approved', title: 'Approved' },
  { name: 'notApproved', title: 'Not approved' },
  { name: 'notAnswered', title: 'Not answered' }
];

/**
 * @description Фильтр Messages from parents
 * @param {FilterProps} props
 * @return {Promise<any>}
 */
const FilterTemplate = (props: FilterProps) => {
  const [strings] = useLanguageContext();
  const {
    form,
    form: { getFieldDecorator, resetFields, validateFields, setFieldsValue },
    onFilter
  } = props;
  const { branches } = useGlobalCollectionsContext();
  const { locationSearchParams } = useLocationSearchParams();
  // Топ-менеджер - все филиалы, остальные только свой.
  const [user] = useUserContext();
  const hasTopManagerRole = user?.hasRoles?.topManager;
  const branch = hasTopManagerRole ? undefined : user?.branch?.id;

  const { tutor, tutorTeacher, stage, status } = locationSearchParams || {};
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);

  /**
   * @description Обработчик на выбор админа
   * @param {string} fieldValue
   * @param {fieldName} string
   * @return {void}
   */
  const tutorOnChange = (fieldValue: string, fieldName: string): void => setFieldsValue({ [fieldName]: fieldValue });

  // Очистка фильтра
  const clearFilter = async (): Promise<any> => {
    resetFields();
    await onFilter({ page: 1, branch: branch });
  };

  /**
   * @description Фильтрация
   * @param {React.FormEvent<HTMLFormElement>} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    validateFields(async (err, values): Promise<any> => {
      if (!err) {
        const formValues = pickBy(values, identity);
        try {
          setHasSubmitLoading(true);
          await onFilter({ ...formValues, page: 1 });
        } finally {
          setHasSubmitLoading(false);
        }
      }
    });
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={[10, 10]}>
        {hasTopManagerRole ? (
          <Col lg={4}>
            {getFieldDecorator('branch', {
              initialValue: branch
            })(
              <Select placeholder={strings.BRANCH}>
                {branches.map((branch: Branch) => (
                  <Option value={branch.id} key={branch.id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            )}
          </Col>
        ) : (
          <>
            {getFieldDecorator('branch', {
              initialValue: user?.branch.id
            })}
          </>
        )}
        <Col lg={4}>
          <StageSelect form={form} initialValue={stage} />
        </Col>
        <Col lg={4}>
          {getFieldDecorator('status', {
            initialValue: status
          })(
            <Select placeholder={strings.STATUS}>
              <Option value={undefined}>&nbsp;</Option>
              {treadStatuses.map(item => (
                <Option value={item.name} key={item.name}>
                  {item.title}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col lg={6}>
          <TutorSelect
            getFieldDecorator={getFieldDecorator}
            name="tutorTeacher"
            placeholder={strings.ADM}
            initTutor={tutorTeacher}
            handleChange={(value: string) => tutorOnChange(value, 'tutorTeacher')}
            style={{ margin: 0 }}
          />
        </Col>
        <Col lg={6}>
          <TutorSelect
            getFieldDecorator={getFieldDecorator}
            name="tutor"
            placeholder={strings.FAMILY_TUTOR}
            initTutor={tutor}
            handleChange={(value: string) => tutorOnChange(value, 'tutor')}
            style={{ margin: 0 }}
          />
        </Col>
      </Row>
      <FilterButtons handleClear={clearFilter} hasSubmitLoading={hasSubmitLoading} />
    </Form>
  );
};

export const Filter = Form.create<FilterProps>({})(FilterTemplate);

export default { Filter };
