import styled, { css } from 'styled-components';

export const MaterialsContainer = styled.div<{ hasShadow?: boolean }>`
  ${props =>
    props.hasShadow &&
    css`
      .ant-list-items {
        border: 1px solid #ddd;
        padding: 10px 20px;
        border-radius: 5px;
        margin-bottom: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
    `}
`;

export const DownloadFile = styled.span`
  font-size: 12px;
  color: #aaaaaa;
  margin-inline-start: 8px;
`;

export const MaterialLesson = styled.div`
  margin: 0 0 2px;
`;

export default { DownloadFile, MaterialLesson, MaterialsContainer };
