import React, { useState } from 'react';
import styled from 'styled-components';
import { ArrowRightOutlined, StopOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Button, Skeleton, Checkbox, Select, Badge, message, Row, Col, Switch, Tag, Flex } from 'antd';
import { Schedule } from 'api/Schedule';
import { TeacherSelect } from 'Global/components/FormComponentsCompatible/Selects/TeacherSelect';
import { BulkUpdateParams, DeleteBulkParams, Room } from 'types/Schedule';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useScheduleRepository } from 'repos/ScheduleRepostirory';
import { Link } from 'react-router-dom';
import { useLocationSearchParams } from '../../../hooks/useLocationSearchParams';

const { Option } = Select;

interface BulkSelectProps extends FormComponentProps {
  rooms: Room[];
  setHasBulkMode: (mode: boolean) => void;
  hasBulkMode: boolean;
  bulkLessons: number[];
  getEventsByDates: (params?: object) => Promise<any>;
  setBulkLessons: (lessons: number[]) => void;
  setOnlyOneLessons: (hasShow: boolean) => void;
}

/**
 * Bulk select - выбор нескольких уроков для изменения параметра
 * (удаление, замена учителя, замена комнаты)
 * Apply to all - все идентичные уроки начиная с выбранной даты и далее
 * По каждому уроку из выбранных находим курс группу
 * И все уроки от выбранного и до конца цепочки в курс группе меняем
 * Only one - все уроки в выбранный день (значит выбираем все уроки в текущий день)
 * Bulk - все уроки в выбранный день недели от выбранного и в будущее
 * Работает только для уроков
 */

const StyledBulkSelect = styled(Form)`
  width: 100%;
  padding: 15px 20px;
  border-radius: 5px;
  background: #fff;
  border: 2px #cc1e44 dashed;
  margin: 10px 0;
  min-height: 170px;
`;

const StyledAffectedItem = styled.div`
  padding: 5px 10px;
  p {
    margin: 0 10px 0 0;
  }
`;

const StyledAffectedGroupName = styled.div`
  display: flex;
  align-items: center;
`;
const StyledSlots = styled.div`
  margin: 5px 0;
`;

const StyledUnaffectedSlots = styled.div`
  padding: 0px 10px;
  p {
    margin: 0;
  }
`;

export const BulkSelectSection = Form.create<BulkSelectProps>({})((props: BulkSelectProps) => {
  const [bulkUpdateInfo, setBulkUpdateInfo] = useState(null);
  const [hasLoadUpdate, setHasLoadUpdate] = useState<boolean>(false);
  const [hasApplyToAll, setHasApplyToAll] = useState<boolean>(false);
  const [isShowConfirm, setIsShowConfirm] = useState<boolean>(false);
  const [bulkParams, setBulkParams] = useState(null);
  const [isLoadInfo, setIsLoadInfo] = useState(false);
  const [strings] = useLanguageContext();
  const scheduleRepo = useScheduleRepository();
  const { locationSearchParams } = useLocationSearchParams();
  const {
    form: { getFieldDecorator, getFieldsValue, resetFields, setFields },
    rooms,
    bulkLessons,
    getEventsByDates,
    setBulkLessons,
    setHasBulkMode
  } = props;

  const { hasDelete } = getFieldsValue();

  const handleBulkUpdate = async (): Promise<any> => {
    if (bulkLessons.isNotEmpty()) {
      setIsLoadInfo(true);
      const { teacher, room, assistant, hasDelete } = getFieldsValue();
      const requestParams: BulkUpdateParams = {
        slots: bulkLessons,
        teacher,
        room,
        assistant,
        applyToAll: Number(hasApplyToAll),
        hasDelete: hasDelete
      };
      setBulkParams(requestParams);
      const { data } = await scheduleRepo.getSlotsBulkUpdateInfo(requestParams);
      setBulkUpdateInfo(data);
      setIsLoadInfo(false);
      setIsShowConfirm(true);
    }
  };

  const onCancelBulkUpdate = () => {
    setBulkUpdateInfo(null);
    setBulkLessons([]);
    setIsShowConfirm(false);
    setHasBulkMode(false);
  };

  const onBulkUpdate = async (): Promise<any> => {
    setHasLoadUpdate(true);
    const { hasDelete } = bulkParams;

    if (hasDelete) {
      const params: DeleteBulkParams = { slots: bulkLessons, applyToAll: Number(hasApplyToAll) };
      await Schedule.deleteBulkSlots(params)
        .then(async (): Promise<any> => {
          message.success(`${strings.SUCCESSFULLY_DELETED} :)`, 2);
          await getEventsByDates(locationSearchParams);
          setBulkLessons([]);
          setHasLoadUpdate(false);
          setHasBulkMode(false);
          setFields({ hasDelete: false });
          resetFields();
        })
        .catch(() => {});
    } else {
      delete bulkParams['hasDelete'];
      await Schedule.setBulkUpdate(bulkParams);
      setHasLoadUpdate(false);
      message.success(`${strings.SUCCESSFULLY_UPDATED} :)`, 2);
      await getEventsByDates(locationSearchParams);
      setBulkLessons([]);
      setHasBulkMode(false);
      resetFields();
    }
  };

  if (isLoadInfo) {
    return (
      <StyledBulkSelect>
        <Skeleton paragraph={{ rows: 2 }} active />
      </StyledBulkSelect>
    );
  }

  if (isShowConfirm) {
    return (
      <StyledBulkSelect layout="vertical">
        {bulkUpdateInfo?.affected?.map(affectedItem => (
          <StyledAffectedItem>
            <StyledAffectedGroupName>
              <p>{strings.GROUP}:</p>{' '}
              <Link to={`group/${affectedItem?.group?.id}`}>
                {affectedItem?.group?.name} ({affectedItem?.group?.groupName})
              </Link>
            </StyledAffectedGroupName>
            <StyledSlots>
              <p>Affected lessons ({affectedItem.lessons.length}): </p>
              <div style={{ margin: '7px 0' }}>
                {affectedItem.lessons.map(lesson => (
                  <Tag key={lesson.id}>
                    {lesson?.title} {lesson.slot.start}
                  </Tag>
                ))}
              </div>
            </StyledSlots>
          </StyledAffectedItem>
        ))}
        <StyledUnaffectedSlots>
          <p>Unaffected lessons ({bulkUpdateInfo?.unaffected?.length}):</p>
          <div style={{ margin: '7px 0' }}>
            {bulkUpdateInfo?.unaffected.map(lesson => (
              <Tag key={lesson.id}>
                {lesson?.title} {lesson.slot.start}
              </Tag>
            ))}
          </div>
        </StyledUnaffectedSlots>
        <Row style={{ marginTop: 10, padding: '0 10px' }}>
          <Col lg={2} style={{ display: 'flex' }}>
            <Button onClick={onCancelBulkUpdate} style={{ marginInlineEnd: 10 }}>
              {strings.CANCEL}
            </Button>
            <Button onClick={onBulkUpdate} type="primary">
              {strings.LETS_DO_IT}
            </Button>
          </Col>
        </Row>
      </StyledBulkSelect>
    );
  }

  return (
    <StyledBulkSelect layout="vertical">
      <Row gutter={10} align="middle">
        <Col lg={5} xs={24}>
          <MyFormItem label={strings.TEACHER} style={{ marginBottom: 0 }}>
            <TeacherSelect
              name="teacher"
              placeholder={strings.CHANGE_TEACHER_TO}
              getFieldDecorator={getFieldDecorator}
              required={false}
              disabled={hasDelete}
            />
          </MyFormItem>
        </Col>
        <Col lg={5} xs={24}>
          <MyFormItem label={strings.ASSISTANT} style={{ marginBottom: 0 }}>
            <TeacherSelect
              name="assistant"
              placeholder={strings.CHANGE_ASSISTANT_TO}
              getFieldDecorator={getFieldDecorator}
              required={false}
              disabled={hasDelete}
            />
          </MyFormItem>
        </Col>
        <Col lg={5} xs={24}>
          <MyFormItem label={strings.ROOM} style={{ marginBottom: 0 }}>
            {getFieldDecorator('room', {
              rules: [{ required: false }]
            })(
              <Select placeholder={strings.CHANGE_ROOM_TO} disabled={hasDelete}>
                {rooms.map((room: Room) => (
                  <Option value={room.id} key={room.id}>
                    {room.name} <Badge color={room.color} />
                    {room?.isBusy && (
                      <>
                        <StopOutlined style={{ color: '#f5222d' }} /> (busy)
                      </>
                    )}
                  </Option>
                ))}
              </Select>
            )}
          </MyFormItem>
        </Col>
        <Col lg={4} xs={12} style={{ marginTop: 35 }}>
          <Switch style={{ marginInlineEnd: 5 }} onChange={checked => setHasApplyToAll(checked)} />
          {strings.APPLY_TO_ALL}
        </Col>
        <Col lg={4} xs={12} style={{ marginTop: 35 }}>
          {getFieldDecorator('hasDelete', {
            initialValue: false,
            rules: [{ required: false }]
          })(<Checkbox checked={hasDelete}>{strings.DELETE}</Checkbox>)}
        </Col>
      </Row>
      <Flex justify="end">
        <Button
          type="primary"
          size="large"
          icon={<ArrowRightOutlined />}
          loading={hasLoadUpdate}
          onClick={handleBulkUpdate}
          disabled={bulkLessons.length === 0}
        >
          Next step
        </Button>
      </Flex>
    </StyledBulkSelect>
  );
});
