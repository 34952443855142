import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import pickBy from 'lodash/pickBy';
import { Form } from '@ant-design/compatible';
import { Select, DatePicker, Row, Col, Button, Checkbox } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { TutorSelect } from 'Global/components/FormComponentsCompatible/Selects/TutorSelect';
import { reversedDateFormatForBackend } from 'helpers/dates';
import { FinanceReportContext } from './index';
import { firstDayOfPrevMonth, lastDayOfPrevMonth } from 'helpers/dates';
import { initalStatuses } from '../helpers';
import { momentFormatValues } from 'helpers/momentFormatValues';
import { booleanFormatValues } from 'helpers/booleanFomatValues';
import { Branch, Status } from 'types';
import { useUserContext } from 'contexts/UserContext';
import { TeacherSelect } from 'Global/components/FormComponentsCompatible/Selects/TeacherSelect';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import queryString from 'query-string';
import { downloadSomeFile } from 'helpers';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { FilterButtons } from 'Global/components/FilterButtons';

const { Option } = Select;

interface TeacherFinanceFilterProps extends FormComponentProps {}

/**
 * @description Фильтр мониторинг финансов учителя
 * @param {TeacherFinanceFilterProps} props
 * @return {React.ReactNode}
 */
const TeacherFinanceFilterTemplate = (props: TeacherFinanceFilterProps) => {
  const [strings] = useLanguageContext();

  const {
    form: { getFieldDecorator, resetFields, validateFields, getFieldsValue }
  } = props;
  const { getFinanceReportTeachers } = useContext(FinanceReportContext);
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);
  const [hasLoadindExportExcel, setHasLoadindExportExcel] = useState<boolean>(false);

  const { branches, teacherStatuses } = useGlobalCollectionsContext();
  const [user] = useUserContext();
  const { locationSearchParams } = useLocationSearchParams();
  const { start, end, branch, status, tutor, doubts } = locationSearchParams || {};
  const initBranch = branch ? branch : user?.branch?.id;
  const initEndDate = end ? dayjs(end) : lastDayOfPrevMonth;
  const initStartDate = start ? dayjs(start) : firstDayOfPrevMonth;

  const clearForm = async (): Promise<any> => {
    resetFields();
    const values = momentFormatValues(getFieldsValue());
    await getFinanceReportTeachers({ ...values, branch: user?.branch?.id });
  };

  /**
   * @description Фильтрация данных
   * @param {React.FormEvent<HTMLFormElement>} e
   */
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    validateFields(async (err, values): Promise<any> => {
      if (!err) {
        const formatValues = momentFormatValues(pickBy(booleanFormatValues(values)));
        try {
          setHasSubmitLoading(true);
          await getFinanceReportTeachers(formatValues);
        } finally {
          setHasSubmitLoading(false);
        }
      }
    });
  };
  /**
   * @description Скачивание файла в excel
   * @return {Promise<any>}
   */
  const downloadExcel = async (): Promise<any> => {
    const fields = momentFormatValues(booleanFormatValues(getFieldsValue()));
    const params = queryString.stringify(fields, { skipNull: true, arrayFormat: 'bracket' });

    setHasLoadindExportExcel(true);
    try {
      await downloadSomeFile(`/api/v1/report/finance/teacher/export?${params}`, null, null, null, true);
    } finally {
      setHasLoadindExportExcel(false);
    }
  };
  const extraBtn = (
    <Button size="large" onClick={downloadExcel} danger loading={hasLoadindExportExcel}>
      {strings.EXPORT_TO_EXCEL}
    </Button>
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={[10, 10]}>
        <Col xs={24} lg={4}>
          {getFieldDecorator('start', {
            initialValue: initStartDate
          })(<DatePicker format={reversedDateFormatForBackend} />)}
        </Col>
        <Col xs={24} lg={4}>
          {getFieldDecorator('end', {
            initialValue: initEndDate
          })(<DatePicker format={reversedDateFormatForBackend} />)}
        </Col>
        <Col xs={24} lg={6}>
          {getFieldDecorator('branch', {
            initialValue: initBranch
          })(
            <Select placeholder={strings.BRANCH}>
              <Option value={undefined}>&nbsp;</Option>
              {branches.map((branch: Branch) => (
                <Option key={branch.id} value={branch.id}>
                  {branch.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col xs={24} lg={6}>
          <TeacherSelect getFieldDecorator={getFieldDecorator} name="teachers" maxTagCount={1} mode="multiple" />
        </Col>
        <Col xs={24} lg={8}>
          {getFieldDecorator('status', {
            initialValue: initalStatuses(status)
          })(
            <Select placeholder={strings.SELECT_STATUS} mode="multiple" maxTagCount={1}>
              {teacherStatuses.map((status: Status) => (
                <Option key={status.id} value={status.id}>
                  {status.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col xs={24} lg={6}>
          <TutorSelect
            initTutor={tutor}
            getFieldDecorator={getFieldDecorator}
            name="tutor"
            placeholder={strings.ADM}
            style={{ margin: 0 }}
          />
        </Col>
        <Col xs={24} lg={4}>
          {getFieldDecorator('doubts', {
            valuePropName: 'checked',
            initialValue: doubts
          })(<Checkbox onChange={e => Number(e.target.checked)}>{strings.ERROR_REPORTS}</Checkbox>)}
        </Col>
      </Row>
      <FilterButtons handleClear={clearForm} hasSubmitLoading={hasSubmitLoading} extra={extraBtn} />
    </Form>
  );
};

export const TeacherFinanceFilter = Form.create<TeacherFinanceFilterProps>({})(TeacherFinanceFilterTemplate);

export default { TeacherFinanceFilter };
