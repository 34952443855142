import React, { useEffect, useState } from 'react';
import { match } from 'react-router';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Button, Row, Col, Input, Spin, message } from 'antd';
import { WrappedFormUtils } from '@ant-design/compatible/es/form/Form';
import { Courses } from 'api/Courses';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import { OriginalCourseLesson } from 'types/courses';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { SubjectGoalsForEditLesson } from './SubjectGoalsForEditLesson';
import { BASE_ORIGINAL_COURSES } from '../routes';
import MyJoditEditor from 'Global/components/MyJoditEditor';

interface OriginalLessonEditProps {
  form: WrappedFormUtils;
}

/**
 * @description Редактирование урока в курсе
 * @param {OriginalLessonEditProps} props
 * @return {React.ReactNode}
 */
export const OriginalLessonEditTemplate = (props: OriginalLessonEditProps) => {
  const coursesRepository = useCoursesRepository();
  const [loading, setLoading] = useState<boolean>(false);
  const [disabledBtn, setDisabledBtn] = useState<boolean>(false);
  const [lesson, setLesson] = useState<OriginalCourseLesson>();
  const [strings] = useLanguageContext();
  const { goBack, push } = useHistory();

  const {
    params: { id, courseId }
  }: match<{ id: string; courseId: string }> = useRouteMatch();

  const {
    form: { getFieldDecorator, validateFieldsAndScroll, setFieldsValue }
  } = props;

  const { topic, outcomes, keyVocabulary, suggestedActivities, suggestedHomework, comment, resources } = lesson || {};

  /**
   * @description Форма
   * @param {React.FormEvent} e
   */
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    validateFieldsAndScroll(async (err, values) => {
      if (isEmpty(err)) {
        setDisabledBtn(true);
        const formValues = pickBy(values, identity);
        try {
          await Courses.updateOriginalLesson(courseId, id, formValues);
          message.success(strings.THE_LESSON_WAS_UPDATED_SUCCESSFULLY);
          push(`${BASE_ORIGINAL_COURSES}/${courseId}`);
        } catch {
        } finally {
          setDisabledBtn(false);
        }
      }
    });
  };

  /**
   * @description Получение урока
   * @param {number} originalLessonId
   * @return {Promise<any>}
   */
  const getOriginalLessonById = async (originalLessonId: number | string): Promise<any> => {
    setLoading(true);
    try {
      const { data } = await coursesRepository.getOriginalLessonById(originalLessonId);
      setLesson(data);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOriginalLessonById(id);
  }, [id]);

  return (
    <Container>
      <Spin tip={strings.LOADING_LESSON} spinning={loading}>
        <StPageHeader onBack={goBack} title={strings.UPDATE_THE_LESSON} />
        <Form layout="vertical" onSubmit={handleSubmit}>
          <Row>
            <Col lg={24}>
              <MyFormItem label={strings.TOPIC}>
                {getFieldDecorator('topic', {
                  initialValue: topic,
                  rules: [{ required: true, message: strings.LESSON_TOPIC_REQUIRED }]
                })(<Input />)}
              </MyFormItem>
              <MyFormItem label={strings.LESSON_OUTCOMES} style={{ marginBottom: 0 }}>
                <SubjectGoalsForEditLesson originalLessonId={id} />
              </MyFormItem>
              <MyFormItem label={strings.OLD_OUTCOMES}>
                {getFieldDecorator('outcomes', {
                  initialValue: outcomes
                })(
                  <MyJoditEditor
                    key="outcomes-editor"
                    value={outcomes}
                    onChange={value => setFieldsValue({ outcomes: value })}
                  />
                )}
              </MyFormItem>
              <MyFormItem label={strings.VOCABULARY}>
                {getFieldDecorator('keyVocabulary', {
                  initialValue: keyVocabulary
                })(
                  <MyJoditEditor
                    key="keyVocabulary-editor"
                    value={keyVocabulary}
                    onChange={value => setFieldsValue({ keyVocabulary: value })}
                  />
                )}
              </MyFormItem>
              <MyFormItem label={strings.SUGGESTED_ACTIVITIES}>
                {getFieldDecorator('suggestedActivities', {
                  initialValue: suggestedActivities
                })(
                  <MyJoditEditor
                    key="suggestedActivities-editor"
                    value={suggestedActivities}
                    onChange={value => setFieldsValue({ suggestedActivities: value })}
                  />
                )}
              </MyFormItem>
              <MyFormItem label={strings.SUGGESTED_HOMEWORK}>
                {getFieldDecorator('suggestedHomework', {
                  initialValue: suggestedHomework
                })(
                  <MyJoditEditor
                    key="suggestedHomework-editor"
                    value={suggestedHomework}
                    onChange={value => setFieldsValue({ suggestedHomework: value })}
                  />
                )}
              </MyFormItem>
              <MyFormItem label={strings.COMMENT}>
                {getFieldDecorator('comment', {
                  initialValue: comment
                })(
                  <MyJoditEditor
                    key="comment-editor"
                    value={comment}
                    onChange={value => setFieldsValue({ comment: value })}
                  />
                )}
              </MyFormItem>
              <MyFormItem label={strings.RESOURCES}>
                {getFieldDecorator('resources', {
                  initialValue: resources
                })(
                  <MyJoditEditor
                    key="resource-editor"
                    value={resources}
                    onChange={value => setFieldsValue({ resources: value })}
                  />
                )}
              </MyFormItem>
            </Col>
            <Col lg={24}>
              <Button type="primary" htmlType="submit" size="large" loading={disabledBtn} disabled={disabledBtn}>
                {strings.EDIT}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Container>
  );
};

export const OriginalLessonEdit = Form.create<OriginalLessonEditProps>({})(OriginalLessonEditTemplate);

export default { OriginalLessonEdit };
