import React from 'react';
import { Badge, Typography } from 'antd';
import { TeachersTable } from '../Tables/Teachers';
import { TableContainer, TableHeading, TableWrapper } from './styles';
import { IStudent } from 'types';
import { Teacher } from 'types/Teachers/teacher';
import { StudentsTable } from 'Global/components/Tables/Children';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useThemeContext } from '../../../contexts/ThemeContext';

const { Title } = Typography;

interface SuggestedResultsProps {
  teachers: Teacher[];
  students: IStudent[];
  handleUserRefetch: () => void;
}

/**
 * @description Показывает список своих учителей и студентов
 * @param {SuggestedResultsProps} props
 * @return {React.ReactNode}
 */
export const SuggestedResults = (props: SuggestedResultsProps) => {
  const [strings] = useLanguageContext();
  const { activeTheme } = useThemeContext();
  const { teachers, students, handleUserRefetch } = props;
  const count = (teachers?.length || 0) + (students?.length || 0);
  return (
    <>
      {count > 0 && (
        <TableWrapper>
          <TableHeading color={activeTheme.primaryColor}>
            <Badge count={count}>
              <Title level={3} style={{ paddingInlineEnd: 10 }}>
                {strings.SUGGESTED}
              </Title>
            </Badge>
          </TableHeading>

          {teachers && teachers?.isNotEmpty() && (
            <TableContainer>
              <Title level={4}>{strings.TEACHERS}</Title>
              <TeachersTable teachers={teachers} handleUserRefetch={handleUserRefetch} />
            </TableContainer>
          )}

          {students && students?.isNotEmpty() && (
            <TableContainer>
              <Title level={4}>{strings.STUDENTS}</Title>
              <StudentsTable students={students} handleUserRefetch={handleUserRefetch} hasSearchResult={true} />
            </TableContainer>
          )}
        </TableWrapper>
      )}
    </>
  );
};

export default { SuggestedResults };
