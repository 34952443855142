import { Event, ScheduleLesson } from 'types/Schedule';
import { mockChild, mockClassRoster, mockGroup, mockStage, mockSubject, mockTeacher } from 'Global/mocks';
import { RegisterType, ReportingType } from '../../types/global';

export const mockEvent: Event = {
  trainings: [],
  id: 0,
  name: '',
  conductor: {
    name: '',
    surname: '',
    id: 0
  }, // FIXME
  stage: {
    id: 0,
    name: '',
    registerType: RegisterType.preschool,
    reportType: ReportingType.main,
    performer: {
      id: 0,
      name: ''
    }
  },
  slot: {
    id: 0,
    startAt: '',
    endAt: '',
    room: {
      id: 0,
      name: '',
      color: ''
    }
  },
  product: {
    id: 0,
    name: ''
  },
  activateDiscount: false,
  participants: [],
  notConfirmedEvents: [],
  master: {
    type: '',
    id: 0,
    surname: '',
    name: '',
    patronymic: '',
    eventJournalRead: true
  },
  cost: {
    id: 0,
    name: '',
    cost: 0
  },
  price: {
    id: 0,
    name: '',
    price: 0,
    trial: false
  }
};

export const mockLesson: ScheduleLesson = {
  id: 0,
  number: 0,
  title: '',
  block: '',
  slot: {
    id: 0,
    startAt: '',
    endAt: '',
    room: {
      id: 0,
      name: '',
      color: ''
    }
  },
  teacher: mockTeacher,
  group: mockGroup,
  subject: mockSubject,
  stage: mockStage,
  classRoster: mockClassRoster,
  noHomework: false,
  homework: '',
  children: [mockChild]
};

export default { mockEvent };
