import React, { useCallback, useMemo, useState } from 'react';
import { Button, Card, Descriptions, Flex, message, Switch, Tag, Tooltip } from 'antd';
import type { DescriptionsProps } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { IDataNewReportByStudent, StatusNewReport } from 'types/Reports';
import { BASE_TEACHERS } from 'Admin/People/Teachers/routes';
import { Link } from 'react-router-dom';
import { BASE_SUPER_GROUPS } from 'Admin/Courses/routes';
import dayjs from 'dayjs';
import { dateFormatForBackend } from 'helpers';
import { EditCommentsModal } from 'Admin/Monitoring/FeedbackManagement/EditCommentsModal';
import { useProgressReportsRepository } from 'repos/ProgressReportsRepository';

interface IEditNewProgressReportItemProps extends IDataNewReportByStudent {
  refetchReports: () => void;
  status: StatusNewReport;
}
const EditNewProgressReportItem: React.FC<IEditNewProgressReportItemProps> = ({
  lesson,
  commentForParent,
  commentForParent2,
  registerId,
  useInProgressReport,
  status,
  refetchReports
}) => {
  const [strings] = useLanguageContext();
  const reportsRepository = useProgressReportsRepository();
  const { teacher, courseGroup, unit, startAt } = lesson;
  const registerDate = dayjs(startAt, dateFormatForBackend).format('MMMM Do, YYYY');
  const [hasShowEditModal, setHasShowEditModal] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(useInProgressReport);

  const items: DescriptionsProps['items'] = useMemo(
    () => [
      {
        key: '1',
        label: strings.TEACHER,
        children: (
          <Link to={`${BASE_TEACHERS}/${teacher.id}`}>
            {teacher?.user?.name} {teacher?.user?.surname}
          </Link>
        )
      },
      {
        key: '2',
        label: strings.SUPERGROUPS,
        children: (
          <Flex wrap="wrap" gap={5}>
            {courseGroup?.supergroups?.map(({ id, name }) => (
              <Tag key={id} style={{ margin: 0 }}>
                <Link to={`${BASE_SUPER_GROUPS}/${id}`}>{name}</Link>
              </Tag>
            ))}
          </Flex>
        )
      },
      {
        key: '3',
        label: strings.UNIT,
        children: <span>{unit?.name}</span>
      },
      {
        key: '4',
        label: strings.COMMENT,
        children: (
          <Flex vertical gap={10}>
            <p>{commentForParent}</p>
            <p>{commentForParent2}</p>
          </Flex>
        )
      }
    ],
    [commentForParent, commentForParent2, lesson]
  );

  const toogleUseInProgressReport = useCallback(async (val: boolean): Promise<any> => {
    setIsChecked(val);
    try {
      await reportsRepository.toggleRegisterUseInNewProgressReport(registerId, { use: val });
      message.success(`${strings.SUCCESSFULLY_CHANGED} :)`, 2);
    } catch {
      setIsChecked(prevState => !prevState);
      message.error(`${strings.ERROR_TRY_AGAIN_LATER} :(`, 2);
    }
  }, []);

  return (
    <Card
      title={
        <Link
          to={`/le/${lesson.id}`}
          target="_blank"
          style={{
            textTransform: 'uppercase',
            fontSize: 14,
            fontWeight: 600,
            borderBottom: '0'
          }}
        >
          {lesson?.courseGroup?.subject?.name + ' - ' + registerDate}
        </Link>
      }
      extra={
        <Flex gap={15} align="center">
          <Flex gap={5} align="baseline">
            <Tooltip title={status !== StatusNewReport.new && strings.CANNOT_EDIT_BECAUSE_STATUS_IS_CHECKED}>
              <Switch
                checked={isChecked}
                checkedChildren={strings.YES}
                unCheckedChildren={strings.NO}
                onChange={toogleUseInProgressReport}
                disabled={status !== StatusNewReport.new}
              />
            </Tooltip>
            <span>{strings.SHOW_IN_PROGRESS_REPORT}</span>
          </Flex>
          <Button type="primary" onClick={() => setHasShowEditModal(true)}>
            {strings.EDIT}
          </Button>
        </Flex>
      }
      style={{ marginBottom: 20 }}
    >
      <EditCommentsModal
        hasShowModal={hasShowEditModal}
        setHasShowModal={setHasShowEditModal}
        registerId={registerId}
        commentForParent={commentForParent}
        commentForParent2={commentForParent2}
        refetchReports={refetchReports}
        hasNeedApprove={true}
        type="parent"
      />
      <Descriptions items={items} />
    </Card>
  );
};

export default EditNewProgressReportItem;
