import React, { useMemo } from 'react';
import { httpService } from 'api/core';
import { IParentProfilePostParams, RestorePasswordParams } from 'types/user';
import CacheService from 'helpers/services/CacheService';
import { message } from 'antd';
import { storageService } from 'contexts/StorageContext';
import { Lang } from '../Admin/Translations';

const cacheService = new CacheService();

const UserRepositoryContext = React.createContext<IUserRepository>(null);
interface IUserRepository {
  getAuthToken: (email: string, password: string) => Promise<any>;
  sendToken: (login: string) => Promise<any>;
  checkUserToken: (token: string) => Promise<any>;
  restorePassword: (params: RestorePasswordParams) => Promise<any>;
  setUserReadAgreement: () => Promise<any>;
  getMyFamily: () => Promise<any>;
  editParentBySelf: (params: IParentProfilePostParams) => Promise<any>;
  userLogout: () => void;
  setUserLocale: (params: { locale: Lang }) => void;
}

class UserRepository implements IUserRepository {
  async getAuthToken(email: string, password: string) {
    try {
      const {
        data: { token, refreshToken }
      } = await httpService.post('/api/v1/login_check', { username: email, password: password }, null, null, true);
      storageService.setAccessToken(token);
      storageService.setRefreshToken(refreshToken);
    } catch (e) {
      message.error('Incorrect login or password', 2);
    }
  }

  /**
   * @description Восстановление пароля. Метод посылает письмо на E-Mail с ссылкой на приложение и прикреплённым токеном
   * @param login
   */
  sendToken(login: string) {
    return httpService.post('/api/v1/login/password/reset', { login }, null, null, true);
  }

  /**
   * @description Проверка на валидность токена восстановления пароля
   * @param token
   */
  checkUserToken(token: string) {
    return httpService.get('/api/v1/login/password/reset', { token }, null, true);
  }

  restorePassword(params: RestorePasswordParams) {
    return httpService.post('/api/v1/login/password/reset', params, null, null, true);
  }

  userLogout() {
    storageService.removeAccessToken();
    storageService.removeRefreshToken();
    cacheService.deleteFromCache('user');
  }

  setUserReadAgreement() {
    return httpService.post('/api/user/read-agreement');
  }

  getMyFamily() {
    return httpService.get('/api/v1/family/my', null, null, true);
  }

  /**
   * @description Редактирование родителем своего профиля
   * @param params
   */
  editParentBySelf(params: IParentProfilePostParams) {
    return httpService.put('/api/v1/parent_self_edit', params, null, null, true);
  }
  setUserLocale(params: { locale: Lang }) {
    return httpService.post('/api/v1/user/locale', params, null, null, true);
  }
}

export const UserRepositoryProvider = props => {
  const service = useMemo(() => new UserRepository(), []);

  return <UserRepositoryContext.Provider value={service}>{props.children}</UserRepositoryContext.Provider>;
};

export const useUserRepository = () => {
  const service = React.useContext(UserRepositoryContext);

  if (!service) {
    throw new Error('User repository is unavailable');
  }

  return service;
};
