import React, { useEffect } from 'react';
import { Loader } from 'Global/components/Loader/Loader';
import { SearchFilter } from 'Global/components/SearchFilter';
import { TeachersTable } from 'Global/components/Tables/Teachers';
import { PaginationInfo } from 'types/global';
import { ITeacherGetParams, Teacher } from 'types/Teachers/teacher';
import { useUserContext } from 'contexts/UserContext';
import { isEmpty } from 'lodash';
import { Row } from 'antd';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';

interface TableTeachersProps {
  paginationInfo?: PaginationInfo;
  teachers: Teacher[];
  getTeachers: (params?: ITeacherGetParams) => Promise<any>;
  isLoading: boolean;
  defValues: ITeacherGetParams;
  handleUserRefetch: () => void;
}

/**
 * @description Компонент таблицы учителей
 * @param {object} props - component props
 * @return {React.ReactNode}
 */
export const TableForTeachers = ({
  paginationInfo,
  teachers,
  getTeachers,
  isLoading,
  handleUserRefetch
}: TableTeachersProps) => {
  return (
    <Loader spinning={isLoading}>
      <Row gutter={[10, 10]}>
        <SearchFilter getUsers={getTeachers} isLoading={isLoading} />
        <TeachersTable
          teachers={teachers}
          paginationInfo={paginationInfo}
          getTeachers={getTeachers}
          handleUserRefetch={handleUserRefetch}
        />
      </Row>
    </Loader>
  );
};

export default { TableForTeachers };
