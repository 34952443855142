import React from 'react';
import dayjs from 'dayjs';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  DeleteOutlined,
  EditOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { dateFormat } from 'helpers/dates';
import { Doubt, GlobalDoubts, TeacherFinanceItem } from 'types/Teachers/finance';
import { SolvedDoubtParams } from 'types/finance';

export const getColumns = (props: {
  hasPreviousMonths?: boolean;
  withoutRemainingBalance?: boolean;
  onReload?: (id: number, boolean) => void;
  onEdit?: (data: TeacherFinanceItem) => void;
  handleDeleteTransition?: (operationId: number) => Promise<any>;
  hasAccessToDeleteFinanceOperation?: boolean;
  handleSolvedDoubt?: (params: SolvedDoubtParams) => void;
}) => {
  const {
    hasPreviousMonths,
    withoutRemainingBalance,
    onReload,
    onEdit,
    handleDeleteTransition,
    hasAccessToDeleteFinanceOperation,
    handleSolvedDoubt
  } = props;
  return [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date: string) => date && dayjs(date).format(dateFormat)
    },
    {
      title: 'Type of operation',
      dataIndex: 'typeOfOperation',
      key: 'typeOfOperation'
    },
    {
      title: 'Payment type',
      dataIndex: 'paymentType',
      key: 'paymentType'
    },
    {
      title: 'Sum',
      dataIndex: 'sum',
      key: 'sum'
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment'
    },
    {
      title: 'Remaining balance',
      dataIndex: 'remainingBalance',
      key: 'remainingBalance',
      render: (remainingBalance: number) =>
        remainingBalance
          ? Number(remainingBalance)
              .toFixed(1)
              .replace(/\.?0+$/, '')
          : '-'
    },
    {
      title: 'Doubt',
      dataIndex: 'doubt',
      key: 'doubt',
      render: (doubt: Doubt) =>
        doubt &&
        (doubt.isSolved ? (
          <p>
            <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 22, paddingInlineEnd: 8 }} />
            {doubt.comment}
          </p>
        ) : (
          <p>
            {!hasPreviousMonths ? (
              <Button
                type="primary"
                onClick={() => handleSolvedDoubt({ entityType: 'teacher', doubtType: 'operation', id: doubt?.id })}
              >
                Mark as resolved
              </Button>
            ) : (
              <CloseCircleTwoTone style={{ fontSize: 22, paddingInlineEnd: 8 }} twoToneColor="#f5222d" />
            )}
            {doubt.comment}
          </p>
        ))
    },
    {
      title: '',
      key: 'operation',
      render(operation) {
        return operation?.editable && <Button onClick={() => onEdit && onEdit(operation)} icon={<EditOutlined />} />;
      }
    },
    !hasPreviousMonths && {
      key: 'actions',
      render({ id, removable }) {
        if (removable && hasAccessToDeleteFinanceOperation) {
          return (
            <div style={{ width: 75 }}>
              <Button onClick={() => onReload(id, withoutRemainingBalance)} icon={<ReloadOutlined />} type="default" />
              <Popconfirm
                title="Are you sure delete this operation?"
                placement="left"
                onConfirm={() => handleDeleteTransition(id)}
                okText="Yes"
                cancelText="No"
              >
                <Button icon={<DeleteOutlined />} danger style={{ marginInlineStart: 6 }} />
              </Popconfirm>
            </div>
          );
        }
      }
    }
  ];
};

export const getGlobalDoubtsColumns = (handleSolvedDoubt?: (params: SolvedDoubtParams) => void) => [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: string) => date && dayjs(date).format(dateFormat)
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment'
  },
  {
    title: '',
    dataIndex: '',
    key: 'doubt',
    render: (doubt: GlobalDoubts) => {
      return (
        <Button
          type="primary"
          onClick={() => handleSolvedDoubt({ entityType: 'teacher', doubtType: 'global', id: doubt.id })}
        >
          Mark as solved
        </Button>
      );
    }
  }
];
export default { getColumns, getGlobalDoubtsColumns };
