import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Typography, Row, Input, Button, Collapse, Table } from 'antd';
import { Units as StUnits } from '../styles';
import { columns } from './columns';
import { BASE_ORIGINAL_COURSES_UNITS_NEW } from '../routes';
import { useLanguageContext } from 'contexts/LanguageContext';

// Components
import Breadcrumbs from '../../../Global/components/Breadcrumbs';
import { AddUnit } from './AddUnit';
import { EditUnit } from './EditUnit';

const { Title } = Typography;
const { Panel } = Collapse;
const { Column } = Table;

export const Units = (props: any) => {
  const [strings] = useLanguageContext();
  const { location } = props;
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleHideShowModal = () => setShowAddModal(false);
  const handleShowModal = () => setShowAddModal(true);

  const handleHideEditModal = () => setShowEditModal(false);
  const handleShowEditModal = () => setShowEditModal(true);

  return (
    <StUnits>
      <AddUnit cancel={handleHideShowModal} show={showAddModal} />
      <EditUnit cancel={handleHideEditModal} show={showEditModal} />
      <Breadcrumbs location={location} />
      <Row>
        <Col lg={12}>
          <Title level={3}>Original course units</Title>
        </Col>
        <Col lg={12} style={{ textAlign: 'right' }}>
          <Button type="primary" onClick={handleShowModal}>
            {strings.ADD_NEW}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col lg={7}>
          <Input placeholder={strings.NAME} />
        </Col>
        <Col lg={4}>
          <Button type="primary" size="large">
            {strings.FILTER}
          </Button>
        </Col>
      </Row>
      <Row style={{ margin: '20px 0' }}>
        <Collapse defaultActiveKey={['1']}>
          <Panel header="A" key="1">
            <Table dataSource={[]} pagination={false} bordered>
              {columns(strings).map(col => (
                <Column key={col.key} {...col} />
              ))}
            </Table>
            <Row style={{ margin: '20px 0' }}>
              <Button type="primary" onClick={handleShowEditModal}>
                {strings.EDIT}
              </Button>
              <Button type="primary" style={{ marginInlineStart: '10px' }}>
                <Link to={BASE_ORIGINAL_COURSES_UNITS_NEW}>Add lesson</Link>
              </Button>
            </Row>
          </Panel>
          <Panel header="B" key="2" />
        </Collapse>
      </Row>
    </StUnits>
  );
};

export default { Units };
