import React from 'react';
import { Route, Switch } from 'react-router';
import { BASE_FAMILIES } from 'Global/routes';
import { InvoiceSteps } from 'Admin/People/Families/Family/Invoices/Create/Steps';
import { useFamilyInvoices } from 'hooks/useFamilyInvoices';
import { Branch, IParent, IStudent } from 'types';
import { History } from 'history';
import { InvoicesList } from './InvoiceList';

interface InvoiceMonthFinanceSystemProps {
  id: number;
  branch: Branch;
  parents: IParent[];
  children: IStudent[];
}

/**
 * @description Инвойсы Москвы
 * @param {Object} InvoiceMonthFinanceSystemProps
 * @return {React.ReactNode}
 */
export const InvoiceMonthFinanceSystem = (props: InvoiceMonthFinanceSystemProps) => {
  const { branch, children, parents, id } = props;
  const familyRoute: string = `${BASE_FAMILIES}/${id}/invoices`;
  const createInvoiceRoute: string = `${BASE_FAMILIES}/${id}/create-invoice`;
  const { invoicesList, isLoadInvoices, refetchInvoices } = useFamilyInvoices(id);

  //для создания инвойса у семьи должны быть дети со статусом Student(status id - 15) или Seasonal(status id - 18)
  const filterStudents = children?.filter((child: IStudent) => child?.status?.id === 15 || child?.status?.id === 18);

  const Create = () => (
    <InvoiceSteps
      parents={parents}
      id={id}
      branch={branch}
      students={filterStudents}
      refetchInvoices={refetchInvoices}
    />
  );

  return (
    <Switch>
      <Route
        path={familyRoute}
        component={() => (
          <InvoicesList
            invoicesList={invoicesList}
            refetchInvoices={refetchInvoices}
            isLoadInvoices={isLoadInvoices}
            familyId={id}
            branch={branch}
            hasAccessCreating={filterStudents?.isNotEmpty()}
          />
        )}
        exact={true}
      />
      <Route path={createInvoiceRoute} component={Create} exact={true} />
    </Switch>
  );
};

export default { InvoiceMonthFinanceSystem };
