import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Form } from '@ant-design/compatible';
import { Row, Col, Input, Select, Checkbox } from 'antd';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { Tag, Branch, Role } from 'types';
import { useUserContext } from 'contexts/UserContext';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { FilterButtons } from 'Global/components/FilterButtons';
import { useHistory } from 'react-router-dom';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import dayjs from 'dayjs';
import { momentFormatValues } from 'helpers/momentFormatValues';
import { AdminGetParams } from 'types/user';
import { booleanFormatValues } from 'helpers/booleanFomatValues';

const { Option } = Select;

interface FilterAdminsProps {
  form: WrappedFormUtils;
  getAllAdmins: (params: AdminGetParams) => Promise<any>;
}

const LIMIT_ADMINS = 50;

const months = Array.from({ length: 12 }, (_, i) => dayjs().month(i).format('MMMM'));

const AdminFilterTemplate = (props: FilterAdminsProps) => {
  const {
    form: { getFieldDecorator, validateFields, resetFields },
    getAllAdmins
  } = props;

  const [strings] = useLanguageContext();
  const [user] = useUserContext();
  const { push } = useHistory();
  const hasTopManager = user?.hasRoles?.topManager;
  const { branches, roles, adminTags } = useGlobalCollectionsContext();
  const { locationSearchParams } = useLocationSearchParams();
  const { branch, query, role, tags: tagsInit, isActive, birthdayMonths } = locationSearchParams || {};

  const handleClear = async (): Promise<any> => {
    resetFields();
    push({ search: '' });
    await getAllAdmins({});
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    validateFields(async (err, values): Promise<any> => {
      if (isEmpty(err)) {
        const params = momentFormatValues(booleanFormatValues(values));
        params['page'] = 1;
        await getAllAdmins(params);
      }
    });
  };

  const getInitialBranch = () => {
    if (!user?.hasRoles?.topManager) {
      return user?.branch?.id;
    }

    return branch && Number(branch);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={[10, 10]}>
        {hasTopManager && (
          <Col xs={24} lg={6}>
            {getFieldDecorator('branch', {
              initialValue: getInitialBranch()
            })(
              <Select placeholder={strings.BRANCH}>
                <Option value="">&nbsp;</Option>
                {branches.map((branch: Branch) => (
                  <Option value={branch.id} key={branch.id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            )}
          </Col>
        )}
        <Col xs={24} lg={6}>
          {getFieldDecorator('query', {
            initialValue: query
          })(<Input placeholder={strings.ENTER_NAME} />)}
        </Col>
        <Col xs={24} lg={6}>
          {getFieldDecorator('role', {
            initialValue: role,
            rules: [{ required: false }]
          })(
            <Select placeholder={strings.ROLE}>
              <Option value="">&nbsp;</Option>
              {roles.map((role: Role) => (
                <Option value={role.sysName} key={role.name}>
                  {role.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col xs={24} lg={6}>
          {getFieldDecorator('tags', {
            initialValue: tagsInit,
            rules: [{ required: false }]
          })(
            <Select
              mode="multiple"
              maxTagCount="responsive"
              placeholder={strings.SELECT_TAGS}
              style={{ width: '100%' }}
            >
              {adminTags.map((tag: Tag) => (
                <Option value={String(tag.id)} key={tag.id}>
                  {tag.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col lg={6}>
          {getFieldDecorator('birthdayMonths', {
            initialValue: birthdayMonths
          })(
            <Select mode="multiple" maxTagCount="responsive" placeholder={strings.BIRTHDAY} style={{ width: '100%' }}>
              {months.map((item: string, index: number) => (
                <Option value={index + 1} key={item}>
                  {item}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col xs={24} lg={4}>
          {getFieldDecorator('isActive', {
            initialValue: isActive && Number(isActive),
            valuePropName: 'checked'
          })(<Checkbox style={{ marginTop: 10 }}>{strings.CURRENT}</Checkbox>)}
        </Col>
      </Row>
      <FilterButtons handleClear={handleClear} />
    </Form>
  );
};

export const AdminFilter = Form.create<FilterAdminsProps>({})(AdminFilterTemplate);

export default { AdminFilter };
