import React from 'react';
import { Switch, message, Row, Typography, Flex } from 'antd';
import { Families } from 'api/Families';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Text } = Typography;

interface ManuaAccountBlockProps {
  familyId: number;
  manualBlock: boolean;
}

/**
 * @description Отключает или включает мануалблок
 * @param {ManuaAccountBlockProps} props
 * @return {React.ReactNode}
 */
export const ManuaAccountBlock = (props: ManuaAccountBlockProps) => {
  const [strings] = useLanguageContext();
  const { familyId, manualBlock } = props;
  /**
   * @description Включение или выключение мануалблока
   * @param {number} familyId
   * @param {number} block
   * @return {Promise<any>}
   */
  const updateManualAccountBlock = async (familyId: number, block: number): Promise<any> => {
    try {
      const { data } = await Families.manualAccountBlock(familyId, { block });
      const status = data.manualBlock ? 'enabled' : 'disabled';
      message.success(`Manual block is ${status}`);
    } catch {}
  };

  const handleChange = (value: boolean) => {
    updateManualAccountBlock(familyId, Number(value));
  };
  return (
    <Flex style={{ marginTop: 20 }} gap={10}>
      <Text>{strings.MANUAL_ACCOUNT_BLOCK} </Text>
      <Switch
        style={{ width: 65 }}
        defaultChecked={manualBlock}
        checkedChildren="ON"
        unCheckedChildren="OFF"
        onChange={handleChange}
      />
    </Flex>
  );
};

export default { ManuaAccountBlock };
