import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { Group } from 'types';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useCoursesRepository } from 'repos/CoursesRepository';
import { filterOption } from 'helpers/filterSelect';

const { Option } = Select;

type AsyncSelectProps = {
  mode?: 'multiple' | 'tags';
  placeholder?: string;
  value?: number;
  branch?: number;
  onChange?: (value: number) => void;
  disabled?: boolean;
};

export const CourseGroupSelect: React.FC<AsyncSelectProps> = ({
  value,
  placeholder,
  mode,
  branch,
  onChange,
  disabled = false
}) => {
  const [strings] = useLanguageContext();
  const coursesRepository = useCoursesRepository();
  const [options, setOptions] = useState<Group[]>([]);
  const [hasLoading, setHasLoading] = useState(false);

  const handleChange = newValue => {
    onChange(newValue);
  };

  const getCourseGroupsByBranch = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const {
        data: { items }
      } = await coursesRepository.getCourseGroups({ limit: 500, branch, active: 1 });
      setOptions(items);
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    if (branch) {
      getCourseGroupsByBranch();
    }
  }, [branch]);

  return (
    <Select
      value={value}
      onChange={handleChange}
      mode={mode}
      disabled={disabled}
      showSearch
      filterOption={filterOption}
      loading={hasLoading}
      placeholder={placeholder || strings.CourseGroup}
      maxTagCount="responsive"
      allowClear
    >
      {options
        ?.sort((a, b) => a.groupName.localeCompare(b.groupName))
        .map((item: Group) => (
          <Option key={item.id} value={item.id}>
            {item.groupName}
          </Option>
        ))}
    </Select>
  );
};

export default CourseGroupSelect;
