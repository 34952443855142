import React, { useState } from 'react';
import { Modal, Radio } from 'antd';
import { UnitName } from 'types/courses';

interface ModalTransferLessonProps {
  unitNames: UnitName[];
  visibleModalTransfer: boolean;
  fetchToMove: boolean;
  unitId: number;
  setVisibleModalTransfer: (bool: boolean) => void;
  moveLessonToAnotherUnit: (unitId: number) => Promise<any>;
}

/**
 * @description Перенос урока в другой юнит
 * @param {ModalTransferLessonProps} props
 * @return {React.ReactNode}
 */
export const ModalTransferLesson = (props: ModalTransferLessonProps) => {
  const { unitNames, visibleModalTransfer, setVisibleModalTransfer, fetchToMove, moveLessonToAnotherUnit, unitId } =
    props;
  const [selected, setSelected] = useState<number | null>(null);
  const [error, setError] = useState<boolean>(false);

  const handleChange = (e: any) => {
    setSelected(e.target.value);
  };

  const handleOk = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();

    if (selected) {
      setError(false);
      moveLessonToAnotherUnit(selected);
    } else {
      setError(true);
    }
  };

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px'
  };

  return (
    (<Modal
      title="Choose unit for move lesson"
      open={visibleModalTransfer}
      onCancel={() => setVisibleModalTransfer(false)}
      okText="Move to"
      onOk={handleOk}
      confirmLoading={fetchToMove}
    >
      <Radio.Group onChange={handleChange}>
        {unitNames.map((unitName: UnitName) => {
          return (
            unitId !== unitName.id && (
              <Radio value={unitName.id} key={unitName.id} style={radioStyle}>
                {unitName.name}
              </Radio>
            )
          );
        })}
      </Radio.Group>
      {error && <p style={{ margin: '8px 0 0', color: 'red' }}>Choose unit you want move lesson</p>}
    </Modal>)
  );
};

export default { ModalTransferLesson };
