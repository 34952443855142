import React, { useEffect, useState } from 'react';
import { Row, Table, Button, message, Spin, Tooltip, Flex, Popconfirm, Tag } from 'antd';
import { Filter } from './Filter';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { Link, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { isEmpty } from 'lodash';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { ICommentaryCalendarData } from 'types/commentary';
import { IStagesGetParams } from 'types/global';
import { BASE_STAGES, STAGES_NEW } from '../routes';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { getColumns } from './columns';
import { useStagesRepository } from 'repos/StagesRepository';

export const Stages = () => {
  const [strings] = useLanguageContext();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [stagesList, setStagesList] = useState<ICommentaryCalendarData[]>([]);
  const { goBack, push } = useHistory();
  const stagesRepository = useStagesRepository();
  const { locationSearchParams } = useLocationSearchParams();

  const getStagesList = async (params: IStagesGetParams = {}): Promise<any> => {
    setHasLoading(true);
    try {
      const { data } = await stagesRepository.getStages({ ...params, withDetails: 1 });
      setStagesList(data);
      push({ search: queryString.stringify(params, { arrayFormat: 'bracket' }) });
    } catch {
    } finally {
      setHasLoading(false);
    }
  };

  const handleDelete = async (id): Promise<any> => {
    setHasLoading(true);
    try {
      await stagesRepository.deleteStage(id);
      getStagesList(locationSearchParams);
    } finally {
      setHasLoading(false);
    }
  };

  const getActionColumns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'action',
      render: (id: number) => (
        <Flex gap={10}>
          <Link to={`${BASE_STAGES}/${id}/edit`}>
            <Button icon={<EditOutlined />} size="small" />
          </Link>
          <Popconfirm
            title={strings.ARE_YOU_SURE}
            placement="left"
            okText={strings.YES}
            cancelText={strings.NO}
            onConfirm={() => handleDelete(id)}
          >
            <Button icon={<DeleteOutlined />} size="small" danger />
          </Popconfirm>
        </Flex>
      )
    }
  ];

  useEffect(() => {
    if (isEmpty(locationSearchParams)) {
      getStagesList();
    } else {
      getStagesList(locationSearchParams);
    }
  }, []);

  return (
    <Container>
      <StPageHeader
        onBack={goBack}
        title={strings.STAGES}
        extra={
          <Link to={STAGES_NEW}>
            <Button type="primary">{strings.ADD}</Button>
          </Link>
        }
      >
        <Filter onFilter={getStagesList} />
      </StPageHeader>
      <Spin spinning={hasLoading}>
        <Row gutter={[10, 10]}>
          <Table dataSource={stagesList} columns={[...getColumns(strings), ...getActionColumns]} pagination={false} />
        </Row>
      </Spin>
    </Container>
  );
};

export default { Stages };
