/* eslint-disable */
import BaseFilter from '../BaseFilter';
import { COURSE_GROUPS_LIMIT } from '../../Global/constants';

export default class CoursesService extends BaseFilter {
  constructor() {
    super();
  }

  static courseGroupsLimit = COURSE_GROUPS_LIMIT;
}
