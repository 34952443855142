import React, { useEffect, useState } from 'react';
import { Modal, Button, Spin, Card, Typography, Row, Col } from 'antd';
import { LessonsComment } from 'types/courses';
import { Link } from 'react-router-dom';
import { dateFormat, downloadSomeFile } from 'helpers';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Courses } from 'api/Courses';
import { useLanguageContext } from '../../contexts/LanguageContext';

const { Text } = Typography;

const StAttach = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #4091f7;
  cursor: pointer;
`;

const StCreatedBy = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  color: #4091f7;
  opacity: 0.7;
`;

interface ModalLessonsCommentsProps {
  lessonId: number;
  typeComments: 'hw' | 'classwork';
  visibleModalComments: boolean;
  setVisibleModalComments: (bool: boolean) => void;
}

/**
 * @description All lesson comments
 * @param {ModalLessonsCommentsProps} props
 * @return {React.ReactNode}
 */
export const ModalLessonsComments = (props: ModalLessonsCommentsProps) => {
  const [strings] = useLanguageContext();
  const { visibleModalComments, setVisibleModalComments, lessonId, typeComments } = props;
  const [comments, setComments] = useState<LessonsComment[]>([]);
  const [hasLoading, setHasLoading] = useState<boolean>(false);

  /**
   * @description Скачка прикрепленного материала
   * @param {number} fileId
   * @param {string} fileName
   * @return {React.ReactNode}
   */
  const downloadAttachment = async (fileId: number, fileName: string): Promise<any> => {
    await downloadSomeFile(`/api/v1/attachment/download/${fileId}`, fileName, null, null, true);
  };

  const fetchComments = async (): Promise<any> => {
    setHasLoading(true);
    if (typeComments === 'hw') {
      try {
        const { data } = await Courses.getHWComments(lessonId);
        setComments(data);
      } catch {
      } finally {
        setHasLoading(false);
      }
    } else {
      try {
        const { data } = await Courses.getClassWorkComments(lessonId);
        setComments(data);
      } catch {
      } finally {
        setHasLoading(false);
      }
    }
  };
  useEffect(() => {
    if (lessonId && typeComments) {
      fetchComments();
    }
  }, [lessonId, typeComments]);

  return (
    <Modal
      className="no-footer-border word-wrap"
      title="All lesson comments for original lesson"
      open={visibleModalComments}
      footer={<Button onClick={() => setVisibleModalComments(false)}>Ok</Button>}
      onCancel={() => setVisibleModalComments(false)}
      bodyStyle={{ padding: 0 }}
    >
      <Spin spinning={hasLoading}>
        {comments?.isEmpty() && <p style={{ padding: 10 }}>{strings.NO_COMMENT_YET}</p>}
        {comments.map((comment: LessonsComment) => {
          const { attachments } = comment;
          return (
            <Card
              bordered={false}
              bodyStyle={{ borderBottom: '1px solid #e8e8e8', paddingTop: '15px', paddingBottom: '15px' }}
            >
              <Row style={{ paddingBottom: '5px' }}>
                <Text strong>
                  <Link to={`/le/${comment?.lesson?.id}`}>{comment?.lesson?.title}</Link>
                </Text>
              </Row>
              <Row align="middle" className="no-padding" gutter={6}>
                {attachments.isNotEmpty() && (
                  <Col lg={8}>
                    {attachments.map(attach => (
                      <Row>
                        <StAttach key={attach.id} onClick={() => downloadAttachment(attach.id, attach.name)}>
                          {attach.name}
                          <StCreatedBy>{` ${dayjs(attach?.createdAt).format(dateFormat)} by ${
                            attach?.createdBy
                          }`}</StCreatedBy>
                        </StAttach>
                      </Row>
                    ))}
                  </Col>
                )}
                <Col style={{ flex: 1 }}>
                  <div dangerouslySetInnerHTML={{ __html: comment?.comment }} />
                </Col>
              </Row>
            </Card>
          );
        })}
      </Spin>
    </Modal>
  );
};

export default { ModalLessonsComments };
