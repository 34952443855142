import React, { useEffect, memo, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { first } from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { Tabs, Spin, Tag, Button, Modal, Tooltip, message, Descriptions } from 'antd';
import { Families } from 'api/Families';
import { Invoices } from 'Admin/People/Families/Family/Invoices';
import { scrollTop } from 'helpers/scroll';
import { History } from 'history';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { IFamily, FamilyTag, IStudent } from 'types';
import { ErrorHandler } from 'helpers/services/ErrorHandler';
import { People } from 'Admin/People/Families/Family/People';
import { Deposit } from 'Admin/People/Families/Family/Deposit';
import Breadcrumbs from 'Global/components/Breadcrumbs';
import { ExistingContracts } from 'Admin/People/Families/Family/ExistingContracts';
import { CollaborationHistory } from 'Admin/People/Families/Family/CollaborationHistory';
import { Finance } from 'Admin/People/Families/Family/Finance/Finance';
import { Discounts } from 'Admin/People/Families/Family/Finance/Discounts';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useFamilyRepository } from 'repos/FamilyRepository';
import { Container } from 'Global/GlobalStyle';
import { BASE_FAMILIES } from 'Global/routes';
import { QuickTips } from 'Global/components/QuickTips';
import { QUICK_TIPS_NEW_ACCOUNT } from 'Global/constants';
import { useUserContext } from 'contexts/UserContext';

const { TabPane } = Tabs;

interface familyProps {
  match: any;
  history: History;
  location: Location;
}

/*
  Вопрос: стоит ли привязывать этот список к компоненту <Tabs> через цикл?
  Тогда придётся вставлять эту константу в компонент,
  чтобы обеспечивать children <TabPane> нужными пропсами из родительского компонента Family
*/
const TABS_PARAM_NAMES = {
  '1': 'people',
  '2': 'invoices',
  '3': 'deposit',
  '4': 'contracts',
  '5': 'finance',
  '6': 'collaboration'
};

export const Family = memo((props: familyProps) => {
  const [strings] = useLanguageContext();
  const [{ confirm }, contextHolder] = Modal.useModal();
  const familyRepository = useFamilyRepository();
  const [family, setFamily] = useState<IFamily | undefined>(undefined);
  // activeTab допускается быть undefined для назначения defaultKey в useEffect, когда бранч обновляется
  const [activeTab, setActiveTab] = useState<string | undefined>(undefined);
  const [showFamily, setShowFamily] = useState(false);
  const [hasStudent, setHasStudent] = useState(false);
  const [financeShouldUpdate, setFinanceShouldUpdate] = useState(false);
  const [hasForbidden, setHasForbidden] = useState<boolean>(false);
  const [user] = useUserContext();
  const hasRiga = user?.branch?.id === 5;

  const {
    match: {
      params: { id: familyId, tab: paramTab }
    },
    history,
    location
  } = props;
  const { children, branch, created, parents, manualBlock, isDeletable } = family || {};

  const { goBack } = useHistory();

  const getPayerFullName = () => {
    const payerParent = family?.parents.find(parent => parent?.isPayer);
    if (payerParent) {
      return (
        <span style={{ opacity: '.7', fontSize: 18, marginInlineStart: 10 }}>
          ({payerParent?.user?.name} {payerParent?.user?.surname})
        </span>
      );
    }
    return '';
  };

  /**
   * @description Информация о семье
   * @param {number} familyId
   * @return {Promise<any>}
   */
  const fetchFamilyById = async (familyId: number): Promise<any> => {
    try {
      const { data, status } = await familyRepository.getFamilyById(familyId);
      if (status === 200) {
        setFamily(data);
        setShowFamily(true);
      }
    } catch (err) {
      if (ErrorHandler.checkStatusCode(err, 403)) {
        setHasForbidden(true);
      }
    }
  };

  /**
   * @description Удаление семьи
   * @param {number} familyId
   * @return {void}
   */
  const deleteFamily = (familyId: number): void => {
    confirm({
      title: strings.ARE_YOU_SURE_YOU_WANT_DELETE_THIS_FAMILY,
      maskClosable: true,
      async onOk() {
        await Families.deleteFamily(familyId).then(() => {
          message.success(strings.FAMILY_DELETE_SUCCESSFULLY);

          setTimeout(() => {
            history.push('/family');
          }, 1500);
        });
      }
    });
  };

  /**
   * @description Установка активного таба
   * @param {string} tab
   * @return {void}
   */
  const setTab = (tab: string): void => {
    history.push(`/family/${familyId}/${TABS_PARAM_NAMES[tab]}`);
    setActiveTab(tab);
  };

  useEffect(() => {
    fetchFamilyById(familyId);
    scrollTop();
  }, [familyId]);

  const DEFAULT_ACTIVE_KEY = '1';

  useEffect(() => {
    // Для предотвращения изменения табы из воздуха (как только обновляется бранч)
    // Чекаем, стоит ли уже activeTab, и если ещё нет (значит должен вставляться дефолт), вставляем дефолт
    if (!activeTab && branch) {
      setActiveTab(
        first(
          Object.entries(TABS_PARAM_NAMES).find(entry => {
            const param = entry[1];
            if (param === 'invoices') {
              return false;
            } else if (param === 'deposit' && branch?.name !== 'Riga') {
              return false;
            }

            return param === paramTab;
          }) || []
        ) || DEFAULT_ACTIVE_KEY
      );
    }
  }, [branch]); // eslint-disable-line

  useEffect(() => {
    if (children) {
      //для создания контракта у семьи должны быть студенты, ребенок со статусом 21 не студент
      const filterStudents = children?.filter((child: IStudent) => child?.status?.id !== 21);
      setHasStudent(filterStudents.isNotEmpty());
    }
  }, [children]);

  const handleFinanceShouldUpdate = () => {
    setFinanceShouldUpdate(true);
  };

  const handleFinanceUpdated = () => {
    setFinanceShouldUpdate(false);
  };

  if (hasForbidden) {
    return <Redirect to="/forbidden" />;
  }
  return (
    <Spin spinning={!showFamily} tip={strings.LOADING_FAMILY}>
      <Container>
        {contextHolder}
        <Breadcrumbs location={location} />
        <PageHeader
          onBack={goBack}
          style={{ padding: 0 }}
          title={`${strings.FAMILY} ${familyId}`}
          subTitle={getPayerFullName()}
          extra={[
            !hasRiga && <QuickTips label={strings.QUICK_TIPS} url={QUICK_TIPS_NEW_ACCOUNT} />,
            <Button>
              <Link to={`${BASE_FAMILIES}/${familyId}/edit`}>{strings.GO_TO_EDIT_PROFILE}</Link>
            </Button>,
            <>
              <Button danger disabled={!isDeletable} onClick={() => deleteFamily(familyId)}>
                {strings.DELETE_FAMILY}
              </Button>
              {!isDeletable && (
                <Tooltip
                  placement="topLeft"
                  title={strings.YOU_CANT_DELETE_A_FAMILY_WITH_PARENTS_OR_CHILDREN_IN_IT_OR_EXISTING_CONTRACTS}
                >
                  <InfoCircleOutlined style={{ fontSize: 20 }} />
                </Tooltip>
              )}
            </>
          ]}
        >
          <Descriptions size="small" column={1}>
            <Descriptions.Item label={strings.CURRENT_STATUS}>{family?.status?.name}</Descriptions.Item>
            {family && family?.familyTags?.isNotEmpty() && (
              <Descriptions.Item label="Family tags">
                {family?.familyTags.map((tag: FamilyTag) => (
                  <Tag color="blue" key={tag.id}>
                    {tag.name}
                  </Tag>
                ))}
              </Descriptions.Item>
            )}
          </Descriptions>
        </PageHeader>
        <Tabs activeKey={activeTab || DEFAULT_ACTIVE_KEY} onChange={setTab}>
          <TabPane tab={strings.PEOPLE} key="1">
            <People
              parents={parents}
              students={children}
              familyId={familyId}
              handleUserRefetch={() => fetchFamilyById(familyId)}
            />
          </TabPane>
          {/*скрыть инвойсы у Парижа*/}
          {branch?.id !== 3 && (
            <TabPane tab={strings.INVOICES} key="2">
              <Invoices
                id={familyId}
                branch={branch}
                students={children}
                history={history}
                parents={parents}
                manualBlock={manualBlock}
              />
              <Discounts id={familyId} students={children} />
            </TabPane>
          )}
          {branch?.name === 'Riga' && (
            <TabPane tab={strings.DEPOSIT} key="3">
              <Deposit id={familyId} setFinanceShouldUpdate={handleFinanceShouldUpdate} />
            </TabPane>
          )}
          <TabPane tab={strings.EXISTING_CONTRACTS} key="4">
            <ExistingContracts id={familyId} branch={branch} hasStudent={hasStudent} />
          </TabPane>
          <TabPane tab={strings.FINANCE} key="5">
            {children && (
              <ErrorBoundary>
                <Finance
                  id={familyId}
                  students={children}
                  branch={branch}
                  created={created}
                  financeShouldUpdate={financeShouldUpdate}
                  handleFinanceUpdated={handleFinanceUpdated}
                />
              </ErrorBoundary>
            )}
          </TabPane>
          <TabPane tab={strings.COLLABORATION_HISTORY} key="6">
            {children && <CollaborationHistory id={familyId} students={children} fetchFamilyById={fetchFamilyById} />}
          </TabPane>
        </Tabs>
      </Container>
    </Spin>
  );
});

export default { Family };
