import React, { useEffect, useState } from 'react';
import { isEmpty, pickBy } from 'lodash';
import { Form } from '@ant-design/compatible';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { Select, Row, Col, Checkbox, message } from 'antd';
import { Location } from 'history';
import { FormComponentProps, WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { Stage, Subject } from 'types';
import { GetCourseGroupsParams } from 'types/education';
import { Teachers } from 'api/Teachers';
import { useUserContext } from 'contexts/UserContext';
import { getTeacherStages } from 'api/Global';
import { useLanguageContext } from 'contexts/LanguageContext';
import { FilterButtons } from 'Global/components/FilterButtons';
import { booleanFormatValues } from 'helpers/booleanFomatValues';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';

const { Option } = Select;

interface FilterProps extends FormComponentProps {
  form: WrappedFormUtils;
  onFilter: (params?: GetCourseGroupsParams) => Promise<any>;
}

/**
 * @description Компонент фильтра Academic Progress
 * @param {object} props
 * @return {React.ReactNode}
 */
export const FilterTemplate = (props: FilterProps) => {
  const {
    form: { getFieldDecorator, validateFieldsAndScroll, resetFields },
    onFilter
  } = props;
  const [user] = useUserContext();
  const [strings] = useLanguageContext();
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [stages, setStages] = useState<Stage[]>([]);
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);
  const { locationSearchParams } = useLocationSearchParams();
  const { subject, stage, active } = locationSearchParams || {};

  const getTeachersSubjects = async (): Promise<any> => {
    try {
      const {
        data: { mainSubjects, additionalSubjects }
      } = await Teachers.getSubjectsByTeacherId(user?.meTeacherId);
      setSubjects([...mainSubjects, ...additionalSubjects]);
    } catch (e) {
      message.error(e.message);
    }
  };

  /**
   * @description Функция для получения stages
   * @return {Promise<any>}
   */
  const getTeachersStages = async (): Promise<any> => {
    try {
      const { data } = await getTeacherStages(user?.meTeacherId);
      setStages(data);
    } catch (e) {
      message.error(e.message);
    }
  };

  /**
   * @description Submit формы поиска
   * @param {EventTarget} e
   * @return {void}
   */
  const handleSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    validateFieldsAndScroll(async (errors, values) => {
      if (isEmpty(errors)) {
        const filterParams = pickBy(booleanFormatValues(values));
        try {
          setHasSubmitLoading(true);
          await onFilter({ ...filterParams, page: 1 });
        } catch {
        } finally {
          setHasSubmitLoading(false);
        }
      }
    });
  };

  const handleClear = async () => {
    resetFields();
    await onFilter({ active: 1 });
  };

  useEffect(() => {
    getTeachersSubjects();
    getTeachersStages();
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={[10, 10]}>
        <Col xs={24} lg={6}>
          {getFieldDecorator('subject', {
            initialValue: subject
          })(
            <Select placeholder={strings.SUBJECT}>
              <Option value={undefined}>&nbsp;</Option>
              {subjects.map((subject: Subject) => (
                <Option value={subject.id} key={subject.id}>
                  {subject.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col xs={24} lg={4}>
          {getFieldDecorator('stage', {
            initialValue: stage
          })(
            <Select placeholder={strings.STAGE}>
              <Option value={undefined}>&nbsp;</Option>
              {stages.map((item: Stage) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col xs={24} lg={3}>
          {getFieldDecorator('active', {
            initialValue: active,
            valuePropName: 'checked'
          })(<Checkbox>{strings.ACTIVE}</Checkbox>)}
        </Col>
        <FilterButtons handleClear={handleClear} isSameRow={true} hasSubmitLoading={hasSubmitLoading} />
      </Row>
    </Form>
  );
};

export const Filter = Form.create<FilterProps>({})(FilterTemplate);

export default { Filter };
