import React from 'react';
import { Row, Col } from 'antd';
import { GetFieldDecoratorOptions } from '@ant-design/compatible/lib/form/Form';
import { TicketTypeSelect } from 'Global/modules/Support/Modal/TicketTypeSelect';

interface FeedbackParentProps {
  getFieldDecorator<T extends Object = {}>(
    id: keyof T,
    options?: GetFieldDecoratorOptions
  ): (node: React.ReactNode) => React.ReactNode;
}

/**
 * @description Форма отправки фидбэка для родителя и студента
 * @param {object} props
 * @return {React.ReactNode}
 */
export const FeedbackParent = (props: FeedbackParentProps) => {
  const { getFieldDecorator } = props;

  return (
    <Row gutter={[10, 10]}>
      <Col xs={24} lg={24}>
        <TicketTypeSelect getFieldDecorator={getFieldDecorator} />
      </Col>
    </Row>
  );
};
