import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Table, Spin, Row, Col, Collapse, Checkbox, message, Button } from 'antd';
import FilterMemo from './Filter';
import { Families } from 'api/Families';
import { getColumns } from './columns';
import { dateFormatForBackend, firstDayOfPrevMonth, lastDayOfPrevMonth } from 'helpers/dates';
import { ErrorBoundary } from 'Global/components/ErrorBoundary';
import { ReportFinanceFamilyApi, ReportFinanceFamily } from 'types/Family';
import { Product } from 'types';
import { useUserContext } from 'contexts/UserContext';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { useHistory } from 'react-router-dom';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import queryString from 'query-string';
import { downloadSomeFile } from 'helpers';
import { isEmpty } from 'lodash';

const { Panel } = Collapse;

/**
 * @description Мониторинг финансов семьи
 * @return {React.ReactNode}
 */
export const FamilyFinance = () => {
  const [strings] = useLanguageContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [hasExportLoading, setHasExportLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [families, setFamilies] = useState<ReportFinanceFamily[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<number>(null);
  const [columnsVisibility, setColumnsVisibility] = useState({
    hasShowChargeOff: false,
    hasShowCompensation: false,
    hasShowEndBalance: false,
    hasShowDoubtsCount: false,
    hasShowDepositReceived: false,
    hasShowTotalAmount: false
  });

  const [user] = useUserContext();
  const globalRequestsRepository = useGlobalRequestsRepository();
  const { push, goBack } = useHistory();
  const { locationSearchParams } = useLocationSearchParams();

  const defValues: ReportFinanceFamilyApi = useMemo(
    () => ({
      branch: user?.branch?.id,
      end: lastDayOfPrevMonth.format(dateFormatForBackend),
      start: firstDayOfPrevMonth.format(dateFormatForBackend)
    }),
    [user]
  );

  /**
   * @description Финансовый репорт семьи
   * @param {object} params
   * @return {Promise<any>}
   */
  const fetchReportFinance = useCallback(async (params?: ReportFinanceFamilyApi): Promise<any> => {
    setLoading(true);
    const { branch, statuses } = params;
    setSelectedBranch(branch);
    push({ search: queryString.stringify(params, { arrayFormat: 'bracket' }) });
    try {
      const { data } = await Families.getReportFinanceFamily(params);
      setFamilies(data.data);
    } catch {
    } finally {
      setLoading(false);
    }
  }, []);

  /**
   * @description Products
   * @return {Promise<any>}
   */
  const getProducts = useCallback(async (branch: number | string): Promise<any> => {
    setLoading(true);
    try {
      const { data } = await globalRequestsRepository.getProducts(branch);
      setProducts(data);
    } catch {
      setProducts([]);
    }
  }, []);

  const exportFinance = useCallback(async (): Promise<any> => {
    if (selectedBranch) {
      setHasExportLoading(true);
      const params = locationSearchParams;
      const { status } = params;
      //TODO пока не переписали Families.getReportFinanceFamily на ами5, status заменяем на statuses
      if (status) {
        params['statuses'] = status;
        delete params['status'];
      }
      const paramsQueryString = queryString.stringify(params, { skipNull: true, arrayFormat: 'bracket' });
      try {
        await downloadSomeFile(`/api/v1/report/finance/families/export?${paramsQueryString}`, null, null, null, true);
      } catch (err) {
        message.error(err.response?.data?.message);
      } finally {
        setHasExportLoading(false);
      }
    } else {
      message.error(strings.CHOOSE_ANY_BRANCH_FOR_DOWNLOAD_FINANCE_FAMILY);
    }
  }, [locationSearchParams]);

  useEffect(() => {
    fetchReportFinance(isEmpty(locationSearchParams) ? defValues : locationSearchParams);
  }, []);

  useEffect(() => {
    if (selectedBranch !== null) {
      getProducts(selectedBranch);
    }
  }, [selectedBranch]);

  const toggleColumnVisibility = (field: keyof typeof columnsVisibility) => {
    setColumnsVisibility(prev => ({ ...prev, [field]: !prev[field] }));
  };

  return (
    <ErrorBoundary>
      <Container>
        <StPageHeader
          onBack={goBack}
          title={strings.FINANCE_FAMILIES}
          extra={
            <Button onClick={exportFinance} loading={hasExportLoading}>
              {strings.EXPORT_TO_EXCEL}
            </Button>
          }
        >
          <FilterMemo
            setFamilies={setFamilies}
            fetchReportFinance={fetchReportFinance}
            setProducts={setProducts}
            defValues={defValues}
          />
        </StPageHeader>
        <Row gutter={[10, 10]}>
          <Collapse
            style={{ background: 'none', marginBottom: 20 }}
            bordered={false}
            expandIcon={({ isActive }) => {
              return isActive ? <MinusOutlined /> : <PlusOutlined />;
            }}
          >
            <Panel header={strings.EXTRA_COLUMNS} key="1">
              <Row>
                {[
                  { label: strings.CHARGE_OFF_SUM, field: 'hasShowChargeOff' },
                  { label: strings.COMPENSATION_SUM, field: 'hasShowCompensation' },
                  { label: strings.BALANCE_BY_THE_END_OF_PERIOD, field: 'hasShowEndBalance' },
                  { label: strings.ERROR_REPORTS, field: 'hasShowDoubtsCount' },
                  { label: strings.DEPOSIT_RECEIVED, field: 'hasShowDepositReceived' },
                  { label: strings.CHARGE_OFF_SUM_C_1_C_2_COMPENSATION_SUM, field: 'hasShowTotalAmount' }
                ].map(({ label, field }) => (
                  <Col lg={12} key={field}>
                    <Checkbox
                      onChange={() => toggleColumnVisibility(field as keyof typeof columnsVisibility)}
                      checked={columnsVisibility[field as keyof typeof columnsVisibility]}
                    >
                      {label}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Panel>
          </Collapse>
        </Row>
        <Row style={{ overflow: 'auto' }}>
          <Spin tip={strings.LOADING_FINANCE_REPORT_FAMILIES} spinning={loading}>
            <Table
              className="table-vertical-top"
              bordered
              dataSource={families}
              columns={getColumns(products, columnsVisibility, strings).filter(col => col)}
              pagination={false}
            />
          </Spin>
        </Row>
      </Container>
    </ErrorBoundary>
  );
};

export default { FamilyFinance };
