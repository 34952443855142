import { create } from 'zustand';
import { ILessonInfo } from 'types/courses';

interface LessonInfoState {
  lessonInfo: ILessonInfo | null;
  setLessonInfo: (val: ILessonInfo) => void;
}

export const useLessonInfoStore = create<LessonInfoState>(set => ({
  lessonInfo: null,
  setLessonInfo: val => set({ lessonInfo: val })
}));
