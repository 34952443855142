import React, { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import localeEn from 'antd/es/locale/en_GB';
import localeRu from 'antd/es/locale/ru_RU';
import localeLv from 'antd/es/locale/lv_LV';
import localeIL from 'antd/es/locale/he_IL';
import localeGe from 'antd/es/locale/ka_GE';
import { defLang } from 'Global/constants';
import { Branch } from '../types';
import { BranchBruner } from '../types/branches';
import { EN_KEY, GE_KEY, IW_KEY, Lang, LV_KEY, RU_KEY } from '../Admin/Translations';
import { useUserRepository } from '../repos/UserRepository';

export const LanguageContext = React.createContext(null);

export const AVAILABLE_LANGS = {
  'en-US': EN_KEY,
  'ru-RU': RU_KEY,
  'lv-LV': LV_KEY,
  'ka-GE': GE_KEY,
  'he-IL': IW_KEY
};

const getCalendarLocale = (language: string) => {
  switch (language) {
    case AVAILABLE_LANGS[RU_KEY]:
      return localeRu;
    case AVAILABLE_LANGS[LV_KEY]:
      return localeLv;
    case AVAILABLE_LANGS[GE_KEY]:
      return localeGe;
    case AVAILABLE_LANGS[IW_KEY]:
      return localeIL;
    default:
      return localeEn;
  }
};

export const LanguageContextProvider = props => {
  const [lang, setLang] = useState(AVAILABLE_LANGS[EN_KEY]);
  const [antdLocale, setAntdLocal] = useState(localeEn);
  const [allLangStrings, setAllLangStrings] = useState({});
  const [strings, setStrings] = useState<Record<string, string>>({});
  const LOCALE_STORAGE_LANG_KEY = 'ami_language';

  const userRepository = useUserRepository();

  const postNewLocale = useCallback(
    async (locale: Lang): Promise<any> => {
      try {
        await userRepository.setUserLocale({ locale });
      } catch {}
    },
    [userRepository]
  );

  const setNewLanguage = useCallback(
    (locale: string, userBranch?: Branch): void => {
      const localeIsoCode = AVAILABLE_LANGS[locale] || defLang;
      setLang(localeIsoCode);
      if (allLangStrings[localeIsoCode]) {
        setStrings(allLangStrings[localeIsoCode]);
      } else {
        setStrings({});
      }

      localStorage.setItem(LOCALE_STORAGE_LANG_KEY, locale);
      let momentLocale = localeIsoCode;

      if (localeIsoCode === IW_KEY) {
        momentLocale = 'he';
      } else if (localeIsoCode === EN_KEY && userBranch?.id !== BranchBruner.id) {
        //'en' начало недели устанавливается на воскресенье по умолчанию, 'en-gb' на пн
        //для Брунера оставляем 'en'
        momentLocale = 'en-gb';
      }

      dayjs.locale(momentLocale);
      setAntdLocal(getCalendarLocale(locale));
      postNewLocale(localeIsoCode);
    },
    [allLangStrings, postNewLocale]
  );

  const provider = [
    strings,
    lang,
    setNewLanguage,
    setAllLangStrings,
    setStrings,
    allLangStrings,
    LOCALE_STORAGE_LANG_KEY,
    antdLocale
  ];

  return <LanguageContext.Provider value={provider}>{props.children}</LanguageContext.Provider>;
};

export const useLanguageContext = () => {
  const service = React.useContext(LanguageContext);

  if (!service) {
    throw new Error('Language context is not available');
  }

  return service;
};
