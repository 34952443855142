import React, { useState, useEffect } from 'react';
import { match } from 'react-router';
import { Col, Spin, Button, message, Input, Row, Select, Form, InputNumber } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Container, StPageHeader } from 'Global/GlobalStyle';
import { ISubjectPostParams } from 'types/education';
import { BASE_STAGES } from '../routes';
import { Branch, Performer, Product, Stage, StudyProgram } from 'types';
import { useStagesRepository } from 'repos/StagesRepository';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { RegisterType, ReportingType } from 'types/global';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
const { Option } = Select;

export const StageForm = () => {
  const [strings] = useLanguageContext();
  const { goBack, push } = useHistory();
  const [form] = Form.useForm();
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const [hasPerformerLoading, setHasPerformerLoading] = useState<boolean>(false);
  const [performers, setPerformers] = useState<Performer[]>([]);
  const { branches, studyPrograms, products } = useGlobalCollectionsContext();

  const {
    params: { id }
  }: match<{ id: string }> = useRouteMatch();

  const stagesRepository = useStagesRepository();
  const globalRequestsRepository = useGlobalRequestsRepository();

  const getStageById = async (): Promise<any> => {
    setHasLoading(true);
    try {
      const { data }: { data: Stage } = await stagesRepository.getStageById(id);
      const { name, performer, registerType, reportType, branches, studyProgram, product } = data;
      const initVal = {
        name,
        performer: performer?.id,
        studyProgram: studyProgram?.id,
        product: product?.id,
        registerType,
        reportType,
        branches: branches.map(item => item.id)
      };
      form.setFieldsValue(initVal);
    } finally {
      setHasLoading(false);
    }
  };
  const fetchPerformers = async (): Promise<any> => {
    setHasPerformerLoading(true);
    try {
      const { data } = await globalRequestsRepository.getPerformers({ isActive: 1, showAll: 1 });
      setPerformers(data);
    } catch {
    } finally {
      setHasPerformerLoading(false);
    }
  };
  const onSubmit = async (params: ISubjectPostParams): Promise<any> => {
    setHasLoading(true);
    try {
      if (id) {
        await stagesRepository.editStage(id, params);
        message.success(strings.SUCCESSFULLY_UPDATED, 2);
      } else {
        await stagesRepository.createStage(params);
        message.success(strings.SUCCESSFULLY_CREATED, 2);
      }
      push(BASE_STAGES);
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getStageById();
    }
  }, [id]);

  useEffect(() => {
    fetchPerformers();
  }, []);

  return (
    <Container>
      <StPageHeader onBack={goBack} title={id ? strings.EDIT : strings.CREATE} />
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Spin spinning={hasLoading}>
          <Row gutter={[10, 10]} align="middle">
            <Col xs={24} lg={8}>
              <Form.Item
                name="name"
                label={strings.NAME}
                rules={[{ required: true, message: `${strings.NAME} ${strings.IS_REQUIRED}` }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item name="registerType" label={strings.REGISTER_TYPE}>
                <Select placeholder={strings.REGISTER_TYPE} allowClear>
                  {Object.values(RegisterType).map((item: string) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item name="reportType" label={strings.REPORTING_TYPE}>
                <Select placeholder={strings.REPORTING_TYPE} allowClear>
                  {Object.values(ReportingType).map((item: string) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item name="product" label={strings.PRODUCT}>
                <Select placeholder={strings.SELECT_PRODUCT}>
                  {products.map((product: Product) => (
                    <Option value={product.id}>{product.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item name="studyProgram" label={strings.STUDY_PROGRAM}>
                <Select placeholder={strings.STUDY_PROGRAM} allowClear>
                  {studyPrograms.map((studyProgram: StudyProgram) => (
                    <Option value={studyProgram.id} key={studyProgram.id}>
                      {studyProgram.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item name="performer" label={strings.PERFORMER}>
                <Select placeholder={strings.PERFORMER} loading={hasPerformerLoading}>
                  {performers.map((performer: Performer) => (
                    <Option value={performer.id} key={performer.id}>
                      {performer.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={16}>
              <Form.Item name="branches" label={strings.BRANCHES}>
                <Select placeholder={strings.BRANCHES} mode="multiple" maxTagCount="responsive" allowClear>
                  {branches.map((branch: Branch) => (
                    <Option key={branch.id} value={branch.id}>
                      {branch.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={6}>
              <Button type="primary" size="large" htmlType="submit" loading={hasLoading}>
                {id ? strings.EDIT : strings.ADD}
              </Button>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Container>
  );
};
