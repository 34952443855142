import React, { useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Modal, Button, Upload, Col, Row, message } from 'antd';
import { first } from 'lodash';
import { getFormData } from 'helpers';
import { UploadFile } from 'antd/lib/upload/interface';
import { useGlobalRequestsRepository } from 'repos/GlobalRequestsRepository';
import { PricesParams } from 'types/API/global';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Dragger } = Upload;

interface CreateFromExcelButtonProps {
  fetchList: (params) => Promise<any>;
  defaultValues: PricesParams;
}

export const CreateFromExcelButton = (props: CreateFromExcelButtonProps) => {
  const [strings] = useLanguageContext();
  const { fetchList, defaultValues } = props;
  const [hasShowModal, setHasShowModal] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [hasLoading, setHasLoading] = useState<boolean>(false);
  const globalRequestsRepository = useGlobalRequestsRepository();

  const handleClick = () => {
    setHasShowModal(true);
  };

  const handleChange = async upload => {
    if (!upload.file) return;
    if (upload.file.status === 'removed') {
      setFileList([]);
    } else {
      //в списке может быть только 1 файл
      setFileList([upload.file]);
    }
    return false;
  };

  const handleCreateFromExcel = async () => {
    try {
      setHasLoading(true);
      const params = getFormData({
        file: first(fileList)
      });
      await globalRequestsRepository.uploadExcelFile(params);
      message.success(`${strings.SUCCESS} :)`, 2);
      fetchList(defaultValues);
      setHasLoading(false);
      setHasShowModal(false);
    } catch {
      setHasLoading(false);
      message.error(`${strings.ERROR_TRY_AGAIN_LATER} :(`, 2);
    }
  };

  return (<>
    <Modal
      footer={[]}
      title={strings.CREATE_FROM_EXCEL}
      open={hasShowModal}
      onCancel={() => setHasShowModal(false)}
    >
      <Row>
        <Col lg={24}>
          <Dragger
            beforeUpload={() => {
              return false;
            }}
            fileList={fileList}
            showUploadList={true}
            onChange={handleChange}
            multiple={false}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p>{strings.UPLOAD_EXCEL_FILE}</p>
          </Dragger>
        </Col>
        <Col lg={24} style={{ marginTop: 20, textAlign: 'right' }}>
          <Button
            disabled={fileList.isEmpty()}
            loading={hasLoading}
            type="primary"
            size="large"
            onClick={handleCreateFromExcel}
          >
            {strings.CREATE}
          </Button>
        </Col>
      </Row>
    </Modal>
    <Button onClick={handleClick}>{strings.CREATE_FROM_EXCEL}</Button>
  </>);
};
