import React from 'react';
import dayjs from 'dayjs';
import { upperFirst, toUpper } from 'lodash';
import { Tag } from 'antd';
import {
  StyledWorkProgressItem,
  StyledGroupName,
  StyledWorkProgressDate,
  StyledToFillIn,
  StyledWorkProgressItemTop
} from './styles';
import { Link } from 'react-router-dom';
import { useLanguageContext } from 'contexts/LanguageContext';

export const WorkProgressItem = ({ lessonId, hasOverdue, startAt, groupName, tag, disciplines }) => {
  const [strings, lang] = useLanguageContext();
  const primaryColor = hasOverdue ? '#F5222D' : '#FAAD14';
  const formatDate = toUpper(dayjs(startAt).locale(lang).format('MMM DD, YYYY'));

  const getTooFillIn = () => {
    let fillInList = [];
    const { commentaries, homeworkGrade, lessonMaterials, students } = disciplines;

    if (!commentaries.filled) {
      fillInList.unshift(strings.COMMENTS);
    }

    if (!homeworkGrade.filled) {
      fillInList.unshift(strings.HOMEWORK);
    }

    if (!lessonMaterials.filled) {
      fillInList.unshift(strings.LESSON_MATERIALS);
    }

    if (!students.filled) {
      fillInList.unshift(strings.STUDENTS);
    }

    if (fillInList.length === 0) return null;

    return (
      <StyledToFillIn>
        <p>TO FILL IN</p>
        <span>{upperFirst(fillInList.join(', '))}</span>
      </StyledToFillIn>
    );
  };

  return (
    <Link to={`/le/${lessonId}`}>
      <StyledWorkProgressItem color={primaryColor}>
        <StyledWorkProgressItemTop>
          <StyledGroupName>{groupName}</StyledGroupName>
          {hasOverdue ? <Tag color="red">{tag}</Tag> : <Tag color="volcano">{tag}</Tag>}
        </StyledWorkProgressItemTop>
        <StyledWorkProgressDate>{formatDate}</StyledWorkProgressDate>
        {getTooFillIn()}
      </StyledWorkProgressItem>
    </Link>
  );
};
