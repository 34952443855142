import React, { useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import { Username } from 'Student&Parent/Main/styles';
import { ITutor } from 'types';
import { AxiosResponse } from 'axios';
import Avatar from 'Global/components/Avatar';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useFamilyRepository } from 'repos/FamilyRepository';
import styled from 'styled-components';

export const StTutorItems = styled.span`
  word-break: break-all;
  line-height: 1.2;
`;

export const StTutorItem = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  padding: 10px 0;
`;

export const StUserContact = styled.div`
  font-size: 10pt;
  line-height: 1.2;
  white-space: break-spaces;
`;
export const TutorItem = (props: { tutor: ITutor }) => {
  const { tutor } = props;
  return (
    <StTutorItem>
      <Avatar
        scale={2}
        photo={tutor?.mainPhoto}
        size={20}
        userName={tutor?.user?.name}
        userSurname={tutor?.user?.surname}
      />
      <div>
        <Username>
          {tutor?.user?.name} {tutor?.user?.surname}
        </Username>
        {tutor?.user?.phone && (
          <StUserContact>
            <a href={`tel:${tutor?.user?.phone}`}>{tutor?.user?.phone}</a>
          </StUserContact>
        )}
        {tutor?.user?.email && (
          <StUserContact>
            <a href={`mailto:${tutor?.user?.email}`}>{tutor?.user?.email}</a>
          </StUserContact>
        )}
      </div>
    </StTutorItem>
  );
};

export function Tutors() {
  const [strings] = useLanguageContext();
  const familyRepository = useFamilyRepository();
  const [tutors, setTutors] = useState<ITutor[]>([]);
  const [hasLoadTutors, setHasLoadTutors] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        setHasLoadTutors(true);
        const { data }: AxiosResponse = await familyRepository.getTutors();
        setTutors(data);
        setHasLoadTutors(false);
      } catch {}
    })();
  }, []);

  if (hasLoadTutors) {
    return <Skeleton active={true} />;
  }

  return (
    <StTutorItems>
      {tutors.map(tutor => (
        <TutorItem tutor={tutor} />
      ))}
    </StTutorItems>
  );
}
