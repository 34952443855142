import React, { useState } from 'react';
import { Modal, Input, Button, message, Form, Flex } from 'antd';
import { useLanguageContext } from 'contexts/LanguageContext';
import { ICreateThread } from 'types/ParentJournal';
import { useThreadsRepository } from 'repos/ThreadsRepository';

interface ModalCreateThreadProps {
  visible: boolean;
  hideModal: () => void;
  thread: ICreateThread | null;
}

/**
 * @description Создание треда
 */
const ModalCreateThread = ({ visible, hideModal, thread }: ModalCreateThreadProps) => {
  const [isSend, setIsSend] = useState<boolean>(false);
  const [strings] = useLanguageContext();
  const threadsRepository = useThreadsRepository();
  const [form] = Form.useForm();

  const handleClose = (): void => {
    form.resetFields();
    hideModal();
  };

  /**
   * @description Отправка формы
   */
  const handleSubmit = async (values): Promise<any> => {
    try {
      setIsSend(true);
      const { status } = await threadsRepository.createThread({ ...thread, ...values });
      if (status === 200) {
        message.success(strings.THREAD_CREATED_SUCCESSFULLY);
        handleClose();
      }
    } catch {
      message.error(strings.THREAD_WAS_NOT_CREATED_AN_ERROR_HAS_OCCURRED);
    } finally {
      setIsSend(false);
    }
  };

  return (
    <Modal title={strings.CREATE_THREAD} open={visible} onCancel={handleClose} footer={null}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label={strings.MESSAGE}
          name="body"
          rules={[{ required: true, message: strings.MESSAGE_IS_REQUIRED }]}
        >
          <Input.TextArea rows={6} />
        </Form.Item>
        <Flex gap={10} justify="end">
          <Button onClick={handleClose} key="cancelBtn">
            {strings.CANCEL}
          </Button>
          <Button disabled={isSend} loading={isSend} htmlType="submit" type="primary" key="sendBtn">
            {strings.SEND}
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

export default ModalCreateThread;
