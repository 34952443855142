import { Col, Form, FormListFieldData, InputNumber, Row, Select } from 'antd';
import { Rate, Subject } from 'types';
import React from 'react';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { NamePath } from 'antd/lib/form/interface';
import { useLanguageContext } from 'contexts/LanguageContext';

const { Option } = Select;

type OrganizingLessonFormProps = {
  field?: FormListFieldData;
  rates: Rate[];
};

export const OrganizingLessonForm: React.FC<OrganizingLessonFormProps> = ({ field, rates }) => {
  const [strings] = useLanguageContext();
  const { subjects } = useGlobalCollectionsContext();
  const getName = (name): NamePath => (field ? [field.name, name] : name);

  return (
    <Row gutter={10} style={{ flex: 1 }}>
      <Col lg={6} xs={24}>
        <Form.Item name={getName('subject')} label={strings.SUBJECT}>
          <Select>
            {subjects.map((subject: Subject) => (
              <Option key={subject.id} value={subject.id}>
                {subject.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col lg={4} xs={24}>
        <Form.Item name={getName('type')} label={strings.FORM}>
          <Select>
            <Option value="Индивидуальные">Индивидуальные</Option>
            <Option value="Групповые">Групповые</Option>
          </Select>
        </Form.Item>
      </Col>
      <Col lg={4} xs={12}>
        <Form.Item name={getName('countWeek')} label={strings.HOURS_PER_WEEK}>
          <InputNumber />
        </Form.Item>
      </Col>
      <Col lg={4} xs={12}>
        <Form.Item name={getName('countPeriod')} label={strings.WEEK_AMOUNT}>
          <InputNumber />
        </Form.Item>
      </Col>
      <Col lg={6} xs={24}>
        <Form.Item name={getName('price')} label={strings.PRICE_PER_HOUR}>
          <Select>
            {rates.map((rate: Rate) => (
              <Option key={rate.id} value={rate.id}>
                {rate.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default { OrganizingLessonForm };
