import styled, { createGlobalStyle } from 'styled-components';
import MyFormItem from 'Global/components/FormComponentsCompatible/MyFormItem';
import { PageHeader } from '@ant-design/pro-layout';

export const GlobalStyle = createGlobalStyle`
    html {
        scroll-behavior: smooth;
    }
    body {
        background-color: #F9F9F9;
        font-family: 'Open Sans', sans-serif, -apple-system,BlinkMacSystemFont,"Segoe UI","PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
      
        p, span, a {
          font-weight: 500;
        }
        p, h3, h4, h5 {
          margin: 0;
        }
        margin: 0 !important;
        
        .list-style-type-none li, .list-style-type-none{
          list-style-type: none;
        }
    }

    #root,
    .fullheight {
      //height: max-content;
      // min-height: 100%;
       height: 100vh;
    }

    .ant-layout-header {
      background: #fff;
    }
    
    
    .hide-column {
      display: none;
    }

    .ant-tooltip {
      z-index: 1010;
    }

    .ant-tooltip.fullwide {
      max-width: none;
    }
    
    .ant-modal-wrap {
      z-index: 1009;
    }

    .dropdown-menu-item-line {
      border-bottom: 1px solid #eee;
      line-height: normal !important;
      
      &:hover {
        color: #000;
      }
    }

    .dropdown-menu-item-line:last-child {
      border-bottom: none;
    }
    
    .ant-time-picker-icon, .ant-time-picker-clear {
      top: 40%;
    }
    
    .ant-modal-mask {
      z-index: 1008;
    }
    
    .ant-select-dropdown {
      z-index: 2010;
    }
    
    .ant-dropdown {
      z-index: 1005;
    }

    .ant-dropdown.dropdown-header {
    z-index: 1100;
  }

  .modal-photo .ant-modal-body {
    padding: 40px;
  }

  .modal-photo .ant-modal-close-x {
    width: 44px;
    height: 44px;
    line-height: 44px;
  }

  .table-vertical-top td {
    vertical-align: top;
  }

  .table-head-center thead th {
    text-align: left;
  }

  .ant-popover-arrow {
    border-right-color: #fff !important;
    border-bottom-color: #fff !important;
  }

  .hover-links {
    & p {
      transition: color .3s ease-in-out;
      cursor: pointer;
      
      &:hover {
        color: #1990ff;
      }
    }
  }

    .editor {
      height: 275px;
      border: 1px solid #F1F1F1;
      padding: 5px;
      border-radius: 2px;
    }

    .editor-wrapper {
      width: 100%;
      display: block;
      height: 400px;
    }
    
    .ant-layout {
      background: transparent
    }

    .row-clickable {
      cursor: pointer;
    }
    
    .editor {
      border: 1px solid #d9d9d9;
      padding: 5px 15px;
    }
    
    .ant-table-thead > tr > th {
      padding: 16px 12px;
    }
    .ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
        padding: 10px 16px;
    }

    .ant-select-selection--multiple {
        min-height: 40px;
    }
    .ant-select-search__field {
        margin: 0 0 0 5px;
    }
    .ant-select-auto-complete .ant-select-search__field {
        margin: 0px;
        font-size: 14px;
    } 
    .ant-select-auto-complete .ant-select-search__field::placeholder { 
        font-size: 14px;
    } 
    .ant-select-selection__rendered {
        margin-top: 5px;
    }

    input,
    .ant-calendar-picker,
    .ant-input-number,
    .ant-input-number-input,
    .ant-input-number-input-wrap,
    .ant-input[type='color'],
    :not(.ant-input-affix-wrapper) >input.ant-input{
      height: 40px;
      line-height: 40px;
      font-weight: 400;
    }
    
    .ant-time-picker-input,
    .ant-picker,
    .ant-picker input,
    .ant-select,
    .ant-select-selection--multiple,
    .ant-select-selector,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-input-search button {
        min-height: 40px;
        line-height: 40px;
        height: 40px;
    }
    
    .ant-picker .ant-picker-input >input{
      font-size: 16px;
    }
    
    .ant-pagination-options .ant-select-selector {
        min-height: 32px;
        line-height: 32px;
        height: 32px;
    }    
    
    .ant-picker-input > input{
      margin-bottom: 0;
    }
    
    .ant-input-affix-wrapper.ant-input-password {
      padding: 0;
    } 
    .ant-input-affix-wrapper.ant-input-password .ant-input-suffix {
      margin-inline-start: -20px;
      z-index: 10;
    }

    .ant-input-password input {
      margin: 0;
      padding-inline-start: 11px !important;
    }
    
    .ant-select-selection__placeholder {
      color: #777;
    }

    .without-margin input.ant-input { margin-bottom: 0; }

    .ant-input-number-input, 
    .ant-input-number,
    .ant-picker,
    .ant-time-picker,
    .ant-calendar-picker {
      width: 100%;
    }
    
    .ant-select,
    .ant-calendar-picker {
      margin-top: 0;
    }
    
    .ant-select {
      width: 100%;
    }
    
    .ant-legacy-form label:not(.ant-radio-button-wrapper) {
      font-size: 16px;
      line-height: 40px;
    }
    
    .ant-legacy-form-horizontal .ant-legacy-form-item{
      margin-bottom: 0;
    }
    
    .ant-legacy-form-item-label>label::after  {
      content: ":";
      position: relative;
      top: -0.5px;
      margin: 0 8px 0 2px;
    }
    
    .ant-row.ant-legacy-form-item .ant-col.ant-legacy-form-item-label > label::after {
      content: ":";
      position: relative;
      top: -0.5px;
      margin: 0 8px 0 2px;
    }
    
    .ant-page-header .ant-page-header-heading-extra >*{
      flex-wrap: wrap;
    }


    .ant-table-wrapper {
      overflow: scroll;
      width: 100%;
    
      p {
        margin-bottom: 0;
      }

      @media only screen and (max-width: 1366px) {
        overflow: scroll;
      }
      
    }

    .popover-content p {
      cursor: pointer;
    }
    
    .ant-table-wrapper, .ant-tabs {
      overflow: auto;    
      width: 100%;
       
      @media(max-width: 768px) {
        overflow: scroll;
      }
    }

    .ant-select-selection-selected-value .verion-editedby {
      display: none;
    }
    
    .preview-material-modal {
      .ant-modal-body {
        padding: 0
      }
    }
    p img{
      max-width: 100%;
    }

    /* Chat modal */

    .chat-modal {
      height: 100%;
      top: 0;
      margin: 0;
      padding: 24px;
      width: 100%;
    }

    .chat-modal .ant-modal-content {
      height: 100%;
    }

    .chat-modal .ant-modal-body {
      height: 100%;
      padding: 0;
    }

    @media(max-width: 767px) {
      .chat-modal {
        max-width: 100%;
      }
      
      .chat-modal .ant-modal-content {
        overflow: hidden;
      }

      .chat-modal {
        padding: 0;
      }

      .chat-modal .ant-modal-content {
        border-radius: 0;
      }

      .chat-modal .ant-modal-close {
        border: 1px solid #eee;
        background: #fff;
      }

      .chat-modal .ant-modal-close-x {
        width: 32px;
        height: 32px;
        line-height: 32px;
      }
    }
    
    .no-padding {
      & .ant-col {
        padding: 0;
      }
    }
    
    .no-form-item {
      margin: 0;
    }
      
    .bordered {    
      background: #ffffff;
      border: 1px solid #d9d9d9;
      box-sizing: border-box;
      border-radius: 5px;
    }
    .ant-btn.activated{
      border-color: #52c41a;
      color: #52c41a;
    }
    .ant-btn.activated:hover{
      border-color: #3e9a11;
      color: #3e9a11;
    }
    
      th.clmn {
        padding: 16px 10px !important;
      }
      td.clmn {
        padding: 16px 10px !important;
        word-break: normal;
      }  
      th.clmn-center {
        text-align: center !important;
        padding: 16px 10px !important;
        word-break: normal;
      }
      td.clmn-center {
        text-align: center !important;
        padding: 16px 10px !important;
      }
      th.clmn-bottom {
        vertical-align: bottom;
      }
      td.clmn-no-padding {
        padding: 0 !important;
      }

  .no-footer-border .ant-modal-footer {
    border-top: none;
  }
  
  .word-wrap .ant-col{
    word-wrap: anywhere;
  }
  
  [contenteditable='true']:focus {
    outline: 1px solid #cc1e44;
  }
  :disabled {
    opacity: 0.6;
   }
     
  .uploadAvatar {
    position: absolute;
    top: 60%;
    left: 80%;
    opacity: 1;
    z-index: 100;
  }
  
  .ant-btn-sm, .ant-btn-icon-only.ant-btn-sm{
    font-size: 14px;
  }
  
  .ant-btn.ant-btn-icon-only{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-input-affix-wrapper >input.ant-input{
    border-radius: inherit;
  }
  .ant-modal-footer{
    display: flex;
    justify-content: end;
  }
  
  h5{
    margin-top: 0;
  }
  .ant-form-vertical .ant-form-item-label >label::after{
    display: none;
  }

  .ql-toolbar.ql-snow {
    border-radius: 5px 5px 0 0;
  }

  .ql-container.ql-snow, .ql-editor {
    border-radius: 0 0 5px 5px;
    font-size: inherit;
  }
  .ql-editor a {
    display: inline;
  }
  .ql-container {
    height: 100%
  }
  .ql-undo, .ql-redo {
    font-size: 16px;
  }
  
`;

export const Container = styled.div`
  margin: 20px;
  padding: 30px 25px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 46px -15px rgb(0 0 0 / 12%);
  min-height: 100%;

  .ant-table-wrapper {
    min-width: 100%;
    width: 100%;
  }
`;

export const TransparentContainer = styled.div`
  padding: 20px;
  height: 100%;
`;

export const StPageHeader = styled(PageHeader)`
  margin-bottom: 20px;
  padding: 0;
  width: 100%;
  && .ant-page-header-content {
    margin: 0 -6px;
  }
  && .ant-page-header-heading-title {
    white-space: break-spaces;
  }
`;

export const StCard = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 46px -15px rgb(0 0 0 / 12%);
`;

export const StFormItemWithoutMargin = styled(MyFormItem)`
  margin: 0;
`;

export default {
  GlobalStyle,
  StPageHeader,
  Container,
  TransparentContainer,
  StCard,
  StFormItemWithoutMargin
};
