import React, { useState } from 'react';
import { Select, Form, Row, Col, DatePicker } from 'antd';
import { useLocationSearchParams } from 'hooks/useLocationSearchParams';
import { useLanguageContext } from 'contexts/LanguageContext';
import { Subject } from 'types';
import { useGlobalCollectionsContext } from 'contexts/GlobalCollectionsContext';
import { ILessonOutcomesFilterParams } from 'types/Student';
import { FilterButtons } from 'Global/components/FilterButtons';
import { momentFormatValues } from 'helpers/momentFormatValues';
import { dateFormat } from 'helpers/dates';

const { Option } = Select;

interface LessonOutcomesFilterProps {
  onFilter: (params?: ILessonOutcomesFilterParams) => void;
}

export const Filter = (props: LessonOutcomesFilterProps) => {
  const [strings] = useLanguageContext();
  const [form] = Form.useForm();
  const { onFilter } = props;
  const { locationSearchParams } = useLocationSearchParams();
  const { subjects: subjectList } = useGlobalCollectionsContext();

  const { subjects, start, end } = locationSearchParams || {};
  const [hasSubmitLoading, setHasSubmitLoading] = useState<boolean>(false);

  /**
   * @description Очистка фильтра
   * @return {void}
   */
  const handleClear = async (): Promise<any> => {
    form.resetFields();
    await onFilter();
  };

  /**
   * @description Отправка формы
   * @return {void}
   */
  const handleSubmit = async (values: ILessonOutcomesFilterParams): Promise<any> => {
    try {
      setHasSubmitLoading(true);
      const params = momentFormatValues(values);
      await onFilter(params);
    } finally {
      setHasSubmitLoading(false);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={{ subjects: subjects, start, end }}
      style={{ marginBottom: 20 }}
    >
      <Row gutter={[10, 10]}>
        <Col lg={8} xs={24}>
          <Form.Item name="subjects" style={{ margin: 0 }}>
            <Select placeholder={strings.SUBJECTS} mode="multiple" maxTagCount="responsive" allowClear>
              <Option value={undefined}>&nbsp;</Option>
              {subjectList.map((subject: Subject) => (
                <Option value={subject.id} key={subject.id}>
                  {subject.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={4} xs={24}>
          <Form.Item name="start" style={{ margin: 0 }}>
            <DatePicker format={dateFormat} placeholder={strings.START} />
          </Form.Item>
        </Col>
        <Col lg={4} xs={24}>
          <Form.Item name="end" style={{ margin: 0 }}>
            <DatePicker format={dateFormat} placeholder={strings.END} />
          </Form.Item>
        </Col>
        <FilterButtons handleClear={handleClear} hasSubmitLoading={hasSubmitLoading} isSameRow={true} />
      </Row>
    </Form>
  );
};
export default { Filter };
