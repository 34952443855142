import { httpService } from 'api/core';

/**
 * @description Получение event по id
 * @param {number} eventId
 * @return {Promise<any>}
 *
 */
export const getEventById = (eventId: number): Promise<any> => httpService.get(`/api/slots/event/${eventId}`);

/**
 * @description Создание event
 * @param {object} params
 * @return {Promise<any>}
 */
// TODO тип параметров
export const createEvent = (params: any): Promise<any> => httpService.post(`/api/slots/event`, params);

/**
 * @description Редактирование event
 * @param {number} eventId
 * @param {object} params
 * @return {Promise<any>}
 */
// TODO тип параметров
export const editEventById = (eventId: number, params: any): Promise<any> =>
  httpService.put(`/api/slot/${eventId}/event`, params);

/**
 * @description Удаление event
 * @param {number} eventId
 * @param {object} params
 * @return {Promise<any>}
 */
export const deleteEvent = (eventId: number, params): Promise<any> =>
  httpService.delete(`/api/slot/${eventId}/event`, params);

export default {
  getEventById,
  createEvent,
  editEventById,
  deleteEvent
};
