import styled, { css } from 'styled-components';
import { Flex } from 'antd';
import { BASE_GRAY_TEXT } from '../../colors';

export const ChatContent = styled(Flex)`
  flex: 1;
  flex-direction: column;
`;

export const SideBar = styled(Flex)`
  width: 300px;
  border-right: 1px solid #eeeeee;
`;

export const ChatContainer = styled.div<{ threadActive?: boolean | null }>`
  display: flex;
  height: 100%;
  background: #fff;
  border-radius: 5px;

  @media (max-width: 767px) {
    ${ChatContent} {
      display: none;
    }
    ${SideBar} {
      width: 100%;
      display: flex;
    }

    ${props =>
      props.threadActive &&
      css`
        ${SideBar} {
          display: none;
        }

        ${ChatContent} {
          display: flex;
        }
      `}
  }
`;

// Sidebar

export const UserBlock = styled.div`
  cursor: pointer;
  padding: 15px;
  display: flex;
  align-items: center;
  transition: background 0.3s ease-in-out;
  position: relative;

  p {
    margin: 0;
  }

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const NewMessageIndicator = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #f44336;
`;

// Dialog

export const ChatHeader = styled.div`
  min-height: 60px;
  padding: 10px;
  box-shadow: 0px 5px 4px -5px rgba(0, 0, 0, 0.08);
  z-index: 10;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const MessagesContainer = styled.div<{ centered?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  overflow: auto;
  padding: 20px;
  background-color: #f6f6f6;
  .ant-empty {
    margin: auto;
  }
`;

export const MessageAvatar = styled.div`
  flex: 0 0 50px;
  margin: 20px 10px 0 0;

  @media (max-width: 571px) {
    flex: 0 0 30px;
    margin-inline-end: 10px;

    & .ant-avatar {
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
    }

    & .ant-avatar-string {
      line-height: 30px;
    }
  }
`;

export const MessageTitle = styled.div`
  font-size: 14px;
  margin-bottom: 2px;
  display: flex;
  gap: 5px;
  align-items: baseline;
`;

export const MessageItem = styled.div`
  flex: 1;
`;

export const MessageDateTime = styled.span`
  color: ${BASE_GRAY_TEXT};
  font-size: 11px;
`;

export const MessageText = styled.div`
  border-radius: 0px 12px 12px 12px;
  padding: 12px 12px 15px;
  position: relative;
  border: 1px solid #f1f1f1;
  background: #fff;
  white-space: pre-wrap;
  font-size: 14px;
`;

export const MessageContaner = styled(Flex)<{ reverse?: boolean | undefined }>`
  margin-bottom: 20px;
  gap: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  ${props =>
    props.reverse &&
    css`
      flex-flow: row-reverse;

      ${MessageAvatar} {
        margin-inline-end: 0;
        margin-inline-start: 10px;
      }

      ${MessageTitle} {
        justify-content: end;
      }

      ${MessageText} {
        border-radius: 12px 0px 12px 12px;
      }
    `}
`;

// Send message form

export const FormMessage = styled.div`
  box-shadow: 0 -7px 5px -5px rgba(0, 0, 0, 0.08);
  display: flex;
  padding: 12px 15px;
  flex-shrink: 0;
  z-index: 10;
  gap: 10px;
  align-items: center;
`;
